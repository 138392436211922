import { Get } from 'config/apiServiceConfig'

type GetFeatureServiceBulkUploadRequestType = {
  code: string
}

export type GetFeatureServiceBulkUploadResponseType = {
  data: {
    id: number
    bulkUploadServiceId: number
    name: string
    templateUrl: string
  }
  error: {
    status: boolean
    message: string
    code: number
  }
}

export const getFeatureServiceBulkUploadAPI = (params: GetFeatureServiceBulkUploadRequestType) =>
  Get<GetFeatureServiceBulkUploadResponseType>({
    url: '/api/bulk-upload/service-feature',
    params,
  })
