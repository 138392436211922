import { memo } from 'react'
import styled from 'styled-components'
import { Typography } from '@astro-ui/components'
import icInstagram from 'assets/images/icInstagram.svg'
import { ReactComponent as PecahBelah } from 'assets/images/mdi_glass-fragile.svg'
import { ReactComponent as Telur } from 'assets/images/egg-crack-fill.svg'
import { ReactComponent as Cair } from 'assets/images/Cair.svg'
import { ReactComponent as Busuk } from 'assets/images/Busuk_2.svg'
import QRCode from 'react-qr-code'
import { strLimit } from 'utils/string'

function maskPhoneNumber(phoneNumber) {
  let showText = ''
  for (let i = 0; i < phoneNumber.length; i++) {
    if (i < 6 || i > phoneNumber.length - 3) {
      showText += phoneNumber[i]
    } else {
      showText += '*'
    }
  }
  return showText
}

function Body({ data, packageOrderNumber, packageId, orderPackage }) {
  const uniquePID = packageId.split('-')[packageId.split('-').length - 1]
  return (
    <BodyContainer>
      <CustomerInfo>
        <Typography
          fontSize={'4.1vw'}
          fontFamily="Montserrat"
          fontWeight={900}
          textTransform="uppercase"
          lineHeight={1}
        >
          {strLimit(data?.order_customer_address.customer_name, 30)}
        </Typography>
        <Typography color="pureBlack" fontSize={'2vw'} fontWeight={800}>
          {data?.order_invoice}
        </Typography>
        <DividerLong />
        {data.timeslotData && (
          <Typography
            color="pureBlack"
            fontSize={'2.2vw'}
            fontWeight={900}
            lineHeight={1.5}
            textTransform="uppercase"
          >
            Pengambilan Paket
          </Typography>
        )}
        {data.timeslotData && (
          <Typography color="pureBlack" fontSize={'2.2vw'} lineHeight={1.5}>
            {data.timeslotData}
          </Typography>
        )}
        {data.hubs && (
          <Typography color="pureBlack" fontSize={'2.2vw'} lineHeight={1.5}>
            {data.hubs}
          </Typography>
        )}
        <Divider />
        {!!data?.order_customer_address?.customer_address_label && (
          <Typography
            color="pureBlack"
            fontSize={'2.2vw'}
            fontWeight={900}
            lineHeight={1.5}
            textTransform="uppercase"
          >
            Label Alamat
          </Typography>
        )}
        {!!data?.order_customer_address?.customer_address_label && (
          <Typography color="pureBlack" fontSize={'2.2vw'} lineHeight={1.5}>
            {strLimit(data?.order_customer_address?.customer_address_label, 94)}
          </Typography>
        )}

        <Typography
          color="pureBlack"
          fontSize={'2.2vw'}
          fontWeight={900}
          lineHeight={1.5}
          textTransform="uppercase"
        >
          Alamat Lengkap
        </Typography>
        <Typography color="pureBlack" fontSize={'2.2vw'} lineHeight={1.5}>
          {strLimit(data?.order_customer_address?.customer_address_place, 94)}
          {data?.order_customer_address.customer_address_detail
            ? `, ${strLimit(data?.order_customer_address.customer_address_detail, 94)}`
            : '-'}
        </Typography>
        <Divider />
        <Typography color="pureBlack" fontSize={'2.2vw'} lineHeight={1.5}>
          <b style={{ fontWeight: 800 }}>Detil alamat: </b>

          {data?.order_customer_address?.customer_address_description
            ? strLimit(data?.order_customer_address?.customer_address_description, 94)
            : '-'}
        </Typography>
        <Divider />
        <Typography color="pureBlack" fontSize={'2.2vw'} lineHeight={1.5}>
          {maskPhoneNumber(data?.order_customer_address.customer_phone_number)}
        </Typography>
        <DividerLong />
        <Typography color="pureBlack" fontSize={'2vw'} fontWeight={900} textTransform="uppercase">
          Instruksi
        </Typography>
        <Typography color="pureBlack" fontSize={'2.2vw'} lineHeight={1.5}>
          {data?.order_customer_address.customer_address_instruction
            ? `${strLimit(data?.order_customer_address.customer_address_instruction, 108)}.`
            : '-'}
          <br />
          {data?.order_note ? ' Order note:  ' + strLimit(data?.order_note, 108) : ''}
        </Typography>
        <IGContainer>
          <IGIcon src={icInstagram} />
          <Typography color="pureBlack" fontSize={'2.2vw'} lineHeight={1.5} fontWeight={900}>
            @astronauts.id
          </Typography>
        </IGContainer>
      </CustomerInfo>
      <OrderInfo>
        <QRInfoContainer>
          <QRInfo>
            <PaketInfo>
              {!orderPackage?.packageLabel ? (
                <Typography
                  color="pureBlack"
                  fontSize={'2vw'}
                  fontWeight={900}
                  textTransform="uppercase"
                >
                  {`${data.stagingArea ? `${strLimit(data.stagingArea, 25)} / ` : ''} Paket ${
                    data.hubType
                  } ${packageOrderNumber} ${data.hubData ? `- ${strLimit(data.hubData, 25)}` : ''}`}
                </Typography>
              ) : (
                <PackageLabel>
                  <Typography
                    color="pureBlack"
                    fontSize={'2vw'}
                    lineHeight={1}
                    fontWeight={600}
                    textTransform="uppercase"
                  >
                    {`${data.stagingArea ? `${strLimit(data.stagingArea, 25)} / ` : ''} Paket ${
                      data.hubType
                    } ${packageOrderNumber} ${
                      data.hubData ? `- ${strLimit(data.hubData, 25)}` : ''
                    }`}
                  </Typography>
                  <Typography
                    color="pureBlack"
                    fontSize={'2.5vw'}
                    lineHeight={1.5}
                    fontWeight={900}
                    textTransform="uppercase"
                  >
                    {strLimit(orderPackage.packageLabel, 25)}
                  </Typography>
                </PackageLabel>
              )}
              {data?.order_customer_address.customer_location_quadrant > 0 ? (
                <QuadrantContainer>
                  <Typography
                    color="white"
                    fontFamily={'Montserrat'}
                    fontSize={'2.2vw'}
                    fontWeight={900}
                    textTransform="uppercase"
                    lineHeight={1}
                  >
                    {data?.order_customer_address.customer_location_quadrant}
                  </Typography>
                </QuadrantContainer>
              ) : null}
            </PaketInfo>
            <QRCode
              value={packageId || data.order_invoice}
              style={{
                height: 'auto',
                maxWidth: 100,
                width: 100,
              }}
              size={100}
            />
            <Typography color="pureBlack" fontSize={'2.2vw'} fontWeight={900} marginTop="-4px">
              KODE UNIK: {uniquePID.toUpperCase()}
            </Typography>
          </QRInfo>
        </QRInfoContainer>
        {data?.delivery_handling ? (
          <DeliveryHandlingContainer>
            <Typography
              fontFamily={'Montserrat'}
              fontSize={'3.6vw'}
              fontWeight={900}
              lineHeight={1}
              fontStyle={'italic'}
              style={{ paddingLeft: 4 }}
            >
              AWAS! PERHATIKAN
            </Typography>
            <Typography
              fontFamily={'Montserrat'}
              fontSize={'5.2vw'}
              fontWeight={900}
              lineHeight={1}
              fontStyle={'italic'}
            >
              POSISI PAKET
            </Typography>
            <IconRow>
              <Telur className="dh-icons" />
              <PecahBelah className="dh-icons" />
              <Cair className="dh-icons" />
              <Busuk className="dh-icons" />
            </IconRow>
            <Typography
              fontFamily={'Montserrat'}
              fontSize={'2.4vw'}
              fontWeight={900}
              lineHeight={1}
              fontStyle={'italic'}
              style={{ paddingLeft: 4 }}
            >
              MUDAH PECAH / CAIR / BUSUK
            </Typography>
          </DeliveryHandlingContainer>
        ) : null}
      </OrderInfo>
    </BodyContainer>
  )
}

export default memo(Body)

const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 83vw;
`
const CustomerInfo = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 50%;
  min-height: 100%;
  padding-top: 3.9vw;
  padding-right: 3.9vw;
  border-right: 1px dashed black;
`
const OrderInfo = styled.div`
  display: flex;
  flex-direction: column;
  min-width: calc(50% - 1px);
  min-height: 100%;
`

const Divider = styled.div`
  min-height: 1vw;
`
const DividerLong = styled.div`
  min-height: 2vw;
`

const IGContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  bottom: 0;
  left: 0;
`

const IGIcon = styled.img`
  height: 4vh;
  width: auto;
`

const QRInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 50%;
  border-bottom: 1px dashed black;
  justify-content: center;
  align-items: center;
`
const QRInfo = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: column;
  align-items: center;
  gap: 4px 0;
`
const PaketInfo = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  margin-top: 2vh;
`
const QuadrantContainer = styled.div`
  padding: 4px;
  border-radius: 2px;
  background-color: black;
`

const DeliveryHandlingContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const IconRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 3.2vw;
  .dh-icons {
    height: 8vh;
    width: auto;
  }
`

const PackageLabel = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`
