import { memo } from "react";
import styled from "styled-components";
import { TextBody, SystemIcon, Button, InputDropDown } from "components";

const listPageSize = [
  {
    name: "5",
    value: 5
  },
  {
    name: "10",
    value: 10
  },
  {
    name: "100",
    value: 100
  },
  {
    name: "1000",
    value: 1000
  }
];

function PaginationV2({
  data,
  handleChangePage,
  className,
  totalInfo,
  handleChangeSize
}) {
  const startItem = data.currentPage * data.pageSize + 1;
  return (
    <Container className={className}>
      {data.currentPage !== undefined && (
        <>
          {data.currentItem ? (
            <TextBody size="smaller" weight="light">
              Menampilkan {data.totalData !== 0 ? startItem : 0}-
              {startItem + data.currentItem - 1} dari {data.totalData}
            </TextBody>
          ) : (
            <div />
          )}
          <WrapperRightContent>
            {data.currentItem && (
              <>
                <TextBody size="smaller" weight="light">
                  {totalInfo}
                </TextBody>
                <InputDropDown
                  listOptions={listPageSize}
                  value={data.pageSize}
                  disabled
                  handleClickOption={handleChangeSize}
                />
              </>
            )}
            <ButtonWrapper>
              <Button
                disabled={data.currentPage === 0}
                variant="wrapper"
                onClick={() => handleChangePage("prev")}
              >
                <PagiButton type="prev">
                  <SystemIcon
                    iconName="dropdown"
                    colorIcon={data.currentPage === 0 ? "border" : "greySoft"}
                  />
                </PagiButton>
              </Button>
              <CurrentPage>{Number(data.currentPage) + 1}</CurrentPage>
              <Button
                disabled={data.currentPage + 1 === data.totalPage || data.last}
                variant="wrapper"
                onClick={() => handleChangePage("next")}
              >
                <PagiButton>
                  <SystemIcon
                    iconName="dropdown"
                    colorIcon={
                      data.currentPage + 1 === data.totalPage || data.last
                        ? "border"
                        : "greySoft"
                    }
                  />
                </PagiButton>
              </Button>
            </ButtonWrapper>
          </WrapperRightContent>
        </>
      )}
    </Container>
  );
}

PaginationV2.defaultProps = {
  data: {},
  handleChangePage: () => {},
  totalInfo: "default totalInfo",
  handleChangeSize: () => {}
};

export default memo(PaginationV2);

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  margin-left: 16px;
  display: flex;
  gap: 8px;
`;

const PagiButton = styled.div`
  width: fit-content;
  height: fit-content;
  height: 32px;
  width: 32px;
  display: grid;
  place-items: center;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 50%;
  border: 1px solid #f3f4f5;
  transform: ${({ type }) =>
    type === "prev" ? "rotate(90deg)" : "rotate(-90deg)"};
`;

const CurrentPage = styled.div`
  height: 32px;
  width: 32px;
  display: grid;
  place-items: center;
  background-color: ${({ theme: { colors } }) => colors.mainFaded};
  border: 1px solid ${({ theme: { colors } }) => colors.main};
  border-radius: 50%;
  color: ${({ theme: { colors } }) => colors.main};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  padding-bottom: 2px;
`;

const WrapperRightContent = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  .input-drop-down {
    max-width: 80px;
  }
`;
