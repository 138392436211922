import { memo, useContext } from "react";
import styled from "styled-components";
import Header from "./Header";
import Body from "./Body";
import store from "storeContext";

function TemplateInboundCompleteSO({ isSuratJalan }) {
  const { state: statePrintArea } = useContext(store.PrintAreaContext);

  return (
    <Container className="">
      <Header
        data={statePrintArea?.dataCetakListSO}
        isSuratJalan={isSuratJalan}
        selectedData={statePrintArea.selectedDataInboundSO}
      />
      <Body
        //data={statePrintArea?.dataCetakListSO.supply_order_items}
        //data={statePrintArea.dataCetakInboundSO.inbound_item_list}
        data={statePrintArea.dataCetakInboundSO.inbound_item_list}
        selectedData={statePrintArea.selectedDataInboundSO}
        isSuratJalan={isSuratJalan}
      />
    </Container>
  );
}

TemplateInboundCompleteSO.defaultProps = {
  isSuratJalan: false,
  selectedData: {}
};

export default memo(TemplateInboundCompleteSO);

const Container = styled.div`
  //   padding: 32px;
  // width: 21cm;
  //   height: 450px;
`;
