import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

export type GetBannerThematicListRequestType = {
  params: {
    name?: string
    page_index: number
    page_size: number
    location_id?: number
  }
}
export type GetBannerThematicListResponseType = {
  data: {
    is_active: boolean
    font_color: string
    browse_thematic_hubs: [
      {
        is_active: boolean
        start_time: string
        browse_thematic_id: number
        location_name: string
        location_id: number
        end_time: string
      },
      {
        is_active: boolean
        start_time: string
        browse_thematic_id: number
        location_name: string
        location_id: number
        end_time: string
      },
    ]
    bg_animated_url: string
    component_sequence: string
    id: number
    browse_thematic_images: {
      url_medium: string
      url_small: string
      id: number
      component: string
      url_large: string
    }
    name: string
    targeting_type: number
  }[]
}
export const getBannerThematicList = ({ params }: GetBannerThematicListRequestType) =>
  axiosInstanceApiGateway.Get<GetBannerThematicListResponseType, true>({
    url: '/browse/internal/v1/thematics',
    params,
  })

export type PutBannerThematicStatusRequestType = {
  id: string
}
export const putBannerThematicStatus = ({ id }: PutBannerThematicStatusRequestType) =>
  axiosInstanceApiGateway.Put({
    url: `/browse/internal/v1/thematic/active/${id}`,
  })
