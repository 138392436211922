import type {
  TicketDetailType,
  TicketReasonsType,
  TicketComplaintsType,
  CSOrderDetailActionType,
} from 'features/HCU/types'
import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit'
import { withLoadingReducer } from '../../../../utils/reducerHandler'
import {
  SLICE_NAME,
  fetchSLAIDs,
  fetchTicketList,
  fetchTicketDetail,
  fetchComplaintReasons,
  updateApproveTicket,
  updateRejectTicket,
  updateReviewTicket,
} from './thunk'

export type HCUSliceType = {
  isLoading: boolean
  slaIDs: {
    active: boolean
    available: boolean
    id: number
    maximum_duration: number
    minimum_duration: number
    name: string
    total_duration: number
  }[]
  tickets: {
    reporter_name: string
    responder_name: string
    max_arrive_at: number
    status: string
    id: number
    complaint: string
    location_id: number
    order: {
      id: number
      type: string
    }
    timing: {
      name: string
      id: number
      type: string
    }
    order_type: string
  }[]
  pagination: {
    page: number
    page_size: number
    has_next: boolean
    has_prev: boolean
  }
  ticketDetail: Nullable<TicketDetailType>
  reasons: TicketReasonsType[]
  complaints: TicketComplaintsType[]
  orderDoneDetail: CSOrderDetailActionType
  orderCancelDetail: CSOrderDetailActionType
  orderSkipLocation: Nullable<{ id: number; order_type: string }>
}

const initialState: HCUSliceType = {
  isLoading: false,
  slaIDs: [],
  tickets: [],
  pagination: {
    page: 1,
    page_size: 20,
    has_next: false,
    has_prev: false,
  },
  ticketDetail: null,
  reasons: [],
  complaints: [],
  orderDoneDetail: null,
  orderCancelDetail: null,
  orderSkipLocation: null,
}

const HCUSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setIsLoadingHCU(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    setTicketData(
      state,
      action: PayloadAction<{
        tickets: HCUSliceType['tickets']
        pagination: HCUSliceType['pagination']
      }>,
    ) {
      state.tickets = action.payload.tickets
      state.pagination = action.payload.pagination
    },
    closeDetail(state) {
      state.ticketDetail = null
    },
    setOrderDoneDetail(state, action: PayloadAction<HCUSliceType['orderDoneDetail']>) {
      state.orderDoneDetail = action.payload
    },
    closeDone(state) {
      state.orderDoneDetail = null
    },
    setOrderCancelDetail(state, action: PayloadAction<HCUSliceType['orderCancelDetail']>) {
      state.orderCancelDetail = action.payload
    },
    closeCancel(state) {
      state.orderCancelDetail = null
    },
    setOrderIDSkipLocation(state, action: PayloadAction<HCUSliceType['orderSkipLocation']>) {
      state.orderSkipLocation = action.payload
    },
    closeSkipLocation(state) {
      state.orderSkipLocation = null
    },
  },
  extraReducers: withLoadingReducer(
    (builder: ActionReducerMapBuilder<HCUSliceType>) => {
      builder
        .addCase(fetchSLAIDs.fulfilled, (state, action) => {
          if (action.payload) {
            state.slaIDs = action.payload.data.sla
          }
        })
        .addCase(fetchTicketList.fulfilled, (state, action) => {
          if (action.payload.data) {
            const { tickets, ...pagination } = action.payload.data
            state.tickets = tickets
            state.pagination = pagination.pagination
          }
        })
        .addCase(fetchTicketDetail.pending, (state) => {
          state.isLoading = true
        })
        .addCase(fetchTicketDetail.fulfilled, (state, action) => {
          state.isLoading = false
          if (action.payload) {
            state.ticketDetail = {
              ...action.payload.data,
              id: action.meta.arg.ticketId,
              orderType: action.meta.arg.orderType,
            }
          }
        })
        .addCase(fetchTicketDetail.rejected, (state) => {
          state.isLoading = false
        })
        .addCase(fetchComplaintReasons.pending, (state) => {
          state.reasons = []
          state.isLoading = true
        })
        .addCase(fetchComplaintReasons.fulfilled, (state, action) => {
          state.isLoading = false
          if (action.payload) {
            state.reasons = action.payload.data.reasons
          }
        })
        .addCase(fetchComplaintReasons.rejected, (state) => {
          state.isLoading = false
        })
        .addCase(updateApproveTicket.pending, (state) => {
          state.isLoading = true
        })
        .addCase(updateApproveTicket.fulfilled, (state) => {
          state.isLoading = false
          state.ticketDetail = null
        })
        .addCase(updateApproveTicket.rejected, (state) => {
          state.isLoading = false
        })
        .addCase(updateRejectTicket.pending, (state) => {
          state.isLoading = true
        })
        .addCase(updateRejectTicket.fulfilled, (state) => {
          state.isLoading = false
          state.ticketDetail = null
        })
        .addCase(updateRejectTicket.rejected, (state) => {
          state.isLoading = false
        })
        .addCase(updateReviewTicket.pending, (state) => {
          state.isLoading = true
        })
        .addCase(updateReviewTicket.fulfilled, (state) => {
          state.isLoading = false
        })
        .addCase(updateReviewTicket.rejected, (state) => {
          state.isLoading = false
        })
    },
    [
      fetchSLAIDs,
      fetchTicketList,
      fetchComplaintReasons,
      fetchTicketDetail,
      updateRejectTicket,
      updateApproveTicket,
      updateReviewTicket,
    ],
  ),
})

export const {
  setIsLoadingHCU,
  closeDetail,
  setTicketData,
  closeDone,
  setOrderDoneDetail,
  setOrderCancelDetail,
  closeCancel,
  setOrderIDSkipLocation,
  closeSkipLocation,
} = HCUSlice.actions

export default HCUSlice.reducer
