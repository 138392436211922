import { Get } from 'config/apiServiceConfig'
import {
  type ProductType,
  type ProductPaginationType,
} from 'features/Order/redux/CreateOrder/slice'

export type KitchenProductsRequestType = {
  locationId?: number
  pageIndex?: number
  searchProduct?: string
  cloudKitchen?: Nullable<boolean>
  bomCategory?: string
}

export const getKitchenProducts = async ({
  locationId,
  pageIndex,
  searchProduct,
  cloudKitchen,
  bomCategory,
}: KitchenProductsRequestType) =>
  Get<{
    data: ProductType[]
    pagination: ProductPaginationType
  }>({
    url: `/api/ims/v2/inventories?type=HUB,VENDING+MACHINE,MEGAHUB`,
    params: {
      locationId: locationId || '',
      // type: 'HUB,VENDING+MACHINE,MEGAHUB',
      pageIndex: pageIndex || 1,
      searchProduct: searchProduct || '',
      cloudKitchen,
      bomCategory,
    },
  })
