import { createAsyncThunk } from '@reduxjs/toolkit'

import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'
import { callErrorMsg } from 'helpers/errorMsg'
import { dateFormat } from 'utils/helpers/date'

import { queryParamsURL } from '@astronautsid/wpe-utils'

import generateGRNTemplate from 'features/AssetManagement/helpers/generateGRNTemplate'
import generatePDF from 'features/AssetManagement/helpers/generatePDF'

import {
  AssetPurchaseOrderResponseInterface,
  AssetPurchaseOrderDetailResponseInterface,
  AssetPurchaseOrderParamsInterface,
} from 'features/AssetManagement/@types/typePurchaseOrder'

import {
  getPurchaseOrderListAPI,
  getPurchaseOrderDetailAPI,
  postUpdatePurchaseOrderAPI,
} from 'features/AssetManagement/services/purchaseOrder'

import { toastSuccess } from 'utils/toast'
import { setHasNext, setPurchaseOrderList, setIsLoading } from './slice'

const SLICE_NAME = 'purchaseOrderAsset'

type GetDetailThenPrintParamType = {
  id: number
  dataToPrint: {
    vendor: string
    poDate: string
    poNumber: string
    location: string
  }
}

type PostUpdatePurchaseOrderParamsType = {
  po_id: number
  status: string
  po_number: string
  checker_id: number
}

export const getPurchaseOrderList = createAsyncThunk(
  `${SLICE_NAME}/getPurchaseOrderList`,
  async (param: AssetPurchaseOrderParamsInterface, { rejectWithValue, dispatch }) => {
    dispatch(setIsLoading(true))
    try {
      let newParam = param
      if (!param.page_index) {
        newParam = { ...newParam, page_index: 0 }
      }
      if (!param.page_size) {
        newParam = { ...newParam, page_size: 10 }
      }
      const res = await getPurchaseOrderListAPI({
        ...newParam,
      })

      const resData: AssetPurchaseOrderResponseInterface = res.data

      dispatch(setPurchaseOrderList(resData.data))
      dispatch(setHasNext(resData.has_next))

      dispatch(setIsLoading(false))

      return true
    } catch (error) {
      dispatch(setIsLoading(false))
      callErrorMsg(error)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const getDetailThenPrint = createAsyncThunk(
  `${SLICE_NAME}/getPurchaseOrderDetail`,
  async (param: GetDetailThenPrintParamType, { rejectWithValue }) => {
    try {
      const res = await getPurchaseOrderDetailAPI(param.id)

      const resData: AssetPurchaseOrderDetailResponseInterface = res.data

      const { items, detail } = resData

      const receivedAt = `${dateFormat({
        date: new Date(detail.received_at),
        format: `YYYY-MM-DD`,
      })}`

      const expirationDate = `${dateFormat({
        date: new Date(detail.expiration_date),
        format: `YYYY-MM-DD`,
      })}`

      const itemsData = items.map((item) => ({
        skuNumber: item.asset_sku,
        description: item.asset_name,
        orderQty: item.request_qty,
        receivedQty: item.actual_qty,
        uom: item.uom,
        status: `Available`,
        expirationDate: expirationDate === `Invalid Date` ? '-' : expirationDate,
      }))
      const pdfData = {
        ...param.dataToPrint,
        grnDate: receivedAt === `Invalid Date` ? '-' : receivedAt,
        grnBy: detail.received_by,
        itemsData,
        grnNumber: detail.grn_number,
        note: detail.note,
      }

      const templatePdf = generateGRNTemplate(pdfData)
      generatePDF(templatePdf)
      return true
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const postUpdatePurchaseOrderStatus = createAsyncThunk(
  `${SLICE_NAME}/postUpdatePurchaseOrder`,
  async (param: PostUpdatePurchaseOrderParamsType, { rejectWithValue, dispatch }) => {
    dispatch(setIsLoading(true))
    try {
      const paramsUpdate = {
        po_id: param.po_id,
        status: param.status,
        po_number: param.po_number,
        checker_id: param.checker_id,
      }

      await postUpdatePurchaseOrderAPI(paramsUpdate)

      dispatch(setIsLoading(false))
      const queryParams = queryParamsURL.get()
      toastSuccess('Berhasil Mengubah Status PO')
      dispatch(getPurchaseOrderList(queryParams))

      if (param.status === 'receiving') {
        window.location.href = `/dashboard/asset/purchase-order-assets/check-quantity/${param.po_id}`
      }

      return true
    } catch (error) {
      dispatch(setIsLoading(false))
      callErrorMsg(error)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)
