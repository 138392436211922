import { memo } from 'react'
import Header from './Header'
import Body from './Body'
import styled from 'styled-components'

function TemplateGrabMart({ data, packageOrderNumber, packageId, handleList }) {
  return (
    <Wrapper>
      <Header data={data} handleList={handleList} />
      <Body data={data} packageOrderNumber={packageOrderNumber} packageId={packageId} />
    </Wrapper>
  )
}

export default memo(TemplateGrabMart)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4vh;
  width: 100%;
  height: 100%;
  padding-bottom: 0px;
  @media print {
    page-break-inside: avoid;
    break-inside: avoid;
    clear: both;
    @page {
      size: 101.6mm 101.6mm;
      margin: 0mm;
    }
  }
`
