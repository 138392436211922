import { createAsyncThunk } from '@reduxjs/toolkit'
import { downloadFileUrl } from '@astronautsid/wpe-utils'

import { callErrorMsg } from 'helpers/errorMsg'
import {
  fetchLocations,
  fetchMPBudgetList,
  postMPBudget,
  putMPBudgetStatus,
  fetchExportMPBudget,
  fetchTemplateMPBudget,
  type FetchMPBudgetParamsType,
  type PostMPBudgetPayloadType,
  type PutMPBudgetStatusPayloadType,
} from 'features/Enterprise/HRIS/services'

import {
  sliceName,
  setIsLoading,
  setLocationList,
  setBudgetList,
  setPopUpType,
} from './mpBudgetSlice'

export const getLocationList = createAsyncThunk(
  `${sliceName}/getLocations`,
  async (type: string, { dispatch }) => {
    try {
      dispatch(setIsLoading(true))
      const { data } = await fetchLocations(type)
      if (data?.length) {
        dispatch(
          setLocationList({
            type,
            locations: data,
          }),
        )
      }
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const getMPBudgetList = createAsyncThunk(
  `${sliceName}/getMPBudgetList`,
  async (_, { dispatch, getState }) => {
    try {
      dispatch(setIsLoading(true))
      const {
        mpBudget: { query },
      } = getState() as StoreStateType
      const { data } = await fetchMPBudgetList(query)
      dispatch(setBudgetList(data?.data?.map((budget) => ({ ...budget, is_show: true }))))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const upsertMPBudget = createAsyncThunk(
  `${sliceName}/createMPBudget`,
  async (payload: PostMPBudgetPayloadType, { dispatch }) => {
    try {
      dispatch(setIsLoading(true))
      await postMPBudget(payload)
      dispatch(getMPBudgetList())
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setPopUpType(''))
      dispatch(setIsLoading(false))
    }
  },
)

export const updateMPBudgetStatus = createAsyncThunk(
  `${sliceName}/updateMPBudgetStatus`,
  async (payload: PutMPBudgetStatusPayloadType, { dispatch }) => {
    try {
      dispatch(setIsLoading(true))
      await putMPBudgetStatus(payload)
      dispatch(getMPBudgetList())
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setIsLoading(false))
      dispatch(setPopUpType(''))
    }
  },
)

export const exportMPBudget = createAsyncThunk(
  `${sliceName}/exportMPBudget`,
  async (urlParams: FetchMPBudgetParamsType, { dispatch }) => {
    try {
      dispatch(setIsLoading(true))
      const { data } = await fetchExportMPBudget(urlParams)
      if (data.data?.file_url) {
        downloadFileUrl(data.data.file_url, data.data.file_url.split('/')[5])
      }
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setIsLoading(false))
      dispatch(setPopUpType(''))
    }
  },
)

export const downloadTemplateMPBudget = createAsyncThunk(
  `${sliceName}/downloadTemplateMPBudget`,
  async (urlParams: FetchMPBudgetParamsType, { dispatch }) => {
    try {
      dispatch(setIsLoading(true))
      const { data } = await fetchTemplateMPBudget(urlParams)
      if (data.data?.file_url) {
        downloadFileUrl(data.data.file_url, data.data.file_url.split('/')[5])
      }
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setIsLoading(false))
      dispatch(setPopUpType(''))
    }
  },
)

export const createMPBudget = createAsyncThunk(
  `${sliceName}/createMPBudget`,
  async (payload: PostMPBudgetPayloadType) => {
    try {
      const { data } = await postMPBudget(payload)
      return data
    } catch (error) {
      callErrorMsg(error)
      return error
    }
  },
)
