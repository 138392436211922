import { useRef, useState } from 'react'
import styled from 'styled-components'

import { SystemIcon, TextBody } from 'components'
import listenClickOutside from 'customHooks/outSideClick'
//import { filterMethod } from "helpers";

function InputDropDownMulti({
  listOptions,
  label,
  color,
  mandatory,
  className,
  width,
  handleCloseOptions,
  placeholder,
  posList,
  inputValue,
  handleClickMultiOption,
}) {
  const refOutside = useRef(null)

  //INITIAL STATE
  const initIsListShow = false

  //LOCAL STATE
  const [isListShow, setIsListShow] = useState(initIsListShow)

  //FUNCTION HELPER
  listenClickOutside(
    refOutside,
    () => {
      handleCloseOptions()
      setIsListShow(false)
    },
    isListShow,
  )

  //FUNCTION HANDLER
  const handleChangeSearch = () => {
    //DISABLE SEARCH ON MULTIPLE CHOICE
  }
  const handleSelectedOption = (itemListOption, index) => {
    handleClickMultiOption(itemListOption, index)
  }

  return (
    <div className="input-drop-down" ref={refOutside}>
      {label && (
        <>
          <Label>
            <TextBody className="mb-7" color={color}>
              {label}
            </TextBody>
          </Label>
          <Span>{mandatory}</Span>
        </>
      )}
      <Container
        onClick={() => setIsListShow(true)}
        className={className}
        active={isListShow}
        width={width}
      >
        <InputWrapper>
          <Input
            color={color}
            value={inputValue.join(', ')}
            placeholder={placeholder}
            onChange={handleChangeSearch}
          />
          <IconWrapper>
            <SystemIcon iconName="dropdown" colorIcon="grey" fontSize="smaller" />
          </IconWrapper>
        </InputWrapper>
        {isListShow && (
          <ListContainer posList={posList}>
            {listOptions.map((items, index) => (
              <ItemDropdownContainer
                key={items.name + 'asas' + index}
                onClick={() => handleSelectedOption(items, index)}
                //value={index}
              >
                <BoxIcon active={items.status}>
                  {items.status && (
                    <SystemIcon iconName="checklist" colorIcon="white" fontSize="smaller" />
                  )}
                </BoxIcon>
                <ItemDropdownText>
                  <TextBody
                    size="bigger"
                    weight="lightest"
                    color={items.status ? 'text' : 'secondaryText'}
                    className="dropdown-name"
                  >
                    {items.name}
                  </TextBody>
                </ItemDropdownText>
              </ItemDropdownContainer>
            ))}
          </ListContainer>
        )}
      </Container>
    </div>
  )
}

InputDropDownMulti.defaultProps = {
  listOptions: [],
  label: '',
  color: 'secondaryText',
  mandatory: '',
  className: '',
  handleCloseOptions: () => {},
  placeholder: '',
  posList: 'down',
  inputValue: [],
  handleClickMultiOption: () => {},
  triggerForUpdateValue: [],
}

export default InputDropDownMulti

const BoxIcon = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  height: 20px;
  background: ${({ theme: { colors }, active }) => (active ? colors.main : '#E4EAF3')};
  width: 20px;
  border-radius: 6px;
  border: 1px solid ${({ theme: { colors }, active }) => (active ? colors.main : '#E4EAF3')};
  margin-left: 10px;
  i::before {
    top: -5px;
    left: 2px;
    position: relative;
    font-size: 0.6rem;
  }
`
const ItemDropdownText = styled.div`
  height: 25px;
  margin-top: auto;
  margin-bottom: auto;
`

const ItemDropdownContainer = styled.div`
  padding: 8px 0px;
  display: flex;
  cursor: pointer;
`
const Container = styled.div`
  width: ${({ width }) => width || '-webkit-fill-available'};
  border: 1px solid ${({ theme: { colors }, active }) => (active ? colors.main : '#E4EAF3')};
  padding: 10px 12px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
  position: relative;
`

const Label = styled.div`
  display: inline-block;
`
const Span = styled.span`
  color: #f08227;
  margin-left: 2px;
  vertical-align: middle;
`

const InputWrapper = styled.div`
  width: 100%;
  display: flex;
`

const Input = styled.input`
  background: none;
  cursor: pointer;
  border: none;

  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  color: ${({ theme: { colors } }) => colors.textSoft};

  width: 95%;
  :focus {
    outline: none;
  }
  ::placeholder {
    opacity: 1;
    color: #aab4c8;
  }
`

const ListContainer = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  max-height: 200px;
  z-index: 2;
  overflow: auto;
  width: fit-content;
  border: 1px solid #e4eaf3;
  min-width: 100%;
  border-radius: 6px;
  position: absolute;
  left: 0;
  /* top: 40px; */
  top: ${({ posList }) => (posList === 'down' ? '40px' : 'auto')};
  bottom: ${({ posList }) => (posList === 'down' ? 'auto' : '40px')};
  background-color: ${({ theme: { colors } }) => colors.white};
  box-shadow: 0px 2px 16px 0px rgba(202, 211, 225, 0.4);
  -webkit-box-shadow: 0px 2px 16px 0px rgba(202, 211, 225, 0.4);
  -moz-box-shadow: 0px 2px 16px 0px rgba(202, 211, 225, 0.4);
  p {
    white-space: nowrap;
  }
  > div:first-child {
    padding-top: 16px;
  }
  > div:last-child {
    padding-bottom: 16px;
  }
`
const IconWrapper = styled.div`
  width: 5%;
  transform: rotate(0deg);
  display: grid;
  place-items: center;
`
