import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getSlottingList,
  getLocationList,
  getGenerateTemplate,
} from '../../services/homepageSlotting'
import type { GetSlottingListRequestType } from '../../@types'

export const SLICE_NAME = 'HomepageSlotting'

const validateParamsValue = (
  key: keyof GetSlottingListRequestType['params'],
  value: number | null,
) =>
  value
    ? {
        [key]: value,
      }
    : {}

export const fetchSlottingList = createAsyncThunk(
  `${SLICE_NAME}/fetchSlottingList`,
  async (_, { rejectWithValue, getState }) => {
    const {
      homepageSlotting: { query },
    } = getState() as StoreStateType

    try {
      const res = await getSlottingList({
        params: {
          page: query.pageIndex,
          ...validateParamsValue('locationId', query.location.location_id),
          size: query.pageSize,
        },
      })

      return res.data.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchLocationList = createAsyncThunk(
  `${SLICE_NAME}/fetchLocationList`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getLocationList()

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchGenerateTemplate = createAsyncThunk(
  `${SLICE_NAME}/fetchGenerateTemplate`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getGenerateTemplate()

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)
