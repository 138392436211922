import apiServices from 'config/apiServiceApiGateway'
import {
  GetApprovalInjectionListRequestType,
  GetApprovalInjectionListResponseType,
  PutCustomerBalanceInjectionRequestPayloadType,
  PutCustomerBalanceInjectionResponseType,
} from 'features/Payment/@types/approvalInjection'

const { Get, Put } = apiServices

export const getApprovalInjectionList = ({ params }: GetApprovalInjectionListRequestType) =>
  Get<GetApprovalInjectionListResponseType, true>({
    url: `/payment/internal/v1/balance/injection`,
    params,
  })

export const putCustomerBalanceInjection = (data: PutCustomerBalanceInjectionRequestPayloadType) =>
  Put<PutCustomerBalanceInjectionResponseType>({
    url: `/payment/internal/:version/balance/injection`,
    version: 'v1',
    data,
  })
