import { memo } from "react";
import styled from "styled-components";
import { Radio } from "components";

function ButtonRadio({ children, onClick, checked }) {
  return (
    <Button onClick={onClick} checked={checked}>
      <Radio checked={checked} />
      {children}
    </Button>
  );
}

ButtonRadio.defaultProps = {
  checked: false,
  onClick: () => {},
};

export default memo(ButtonRadio);

const Button = styled.button`
  cursor: pointer;
  background-color: ${({ theme: { colors } }) => colors.white};
  border: 1px solid
    ${({ checked, theme: { colors } }) =>
      checked ? colors.main : colors.border};
  outline: none;
  padding: 8px 16px;
  border-radius: 6px;
  color: ${({ theme: { colors } }) => colors.textSoft};
  display: flex;
  align-items: center;
  label {
    margin-right: 10px;
  }
`;
