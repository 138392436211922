import {
  SET_IS_START_WAREHOUSE_DETAIL,
  SET_IS_FAILED_WAREHOUSE_DETAIL,
  SET_IS_SUCCESS_WAREHOUSE_DETAIL,
  SET_IS_NEED_TO_RELOAD_WAREHOUSE_DETAIL,
  SET_PAGINATION_DATA_WAREHOUSE_DETAIL,
  SET_SELECT_PRODUCT_DATA_WAREHOUSE_DETAIL,
  SET_IS_START_WAREHOUSE_DETAIL_HISTORY,
  SET_IS_FAILED_WAREHOUSE_DETAIL_HISTORY,
  SET_IS_SUCCESS_WAREHOUSE_DETAIL_HISTORY,
  SET_PAGINATION_DATA_WAREHOUSE_DETAIL_HISTORY,
} from 'storeContext/actionsType/warehouseDetail'

export const initialStateWarehouseDetail = {
  isLoading: false,
  warehouseDetailData: [],
  warehouseDetailHistoryData: [],
  paginationData: {},
  paginationDataStockHistory: {
    pageIndex: 1,
    pageSize: 20,
    numberOfElements: 0,
  },
  needToReload: 1,
  selectedProduct: {},
}

export default function warehouseDetail(state = initialStateWarehouseDetail, { type, payload }) {
  switch (type) {
    case SET_IS_START_WAREHOUSE_DETAIL_HISTORY:
    case SET_IS_START_WAREHOUSE_DETAIL:
      return {
        ...state,
        isLoading: true,
      }

    case SET_IS_FAILED_WAREHOUSE_DETAIL_HISTORY:
    case SET_IS_FAILED_WAREHOUSE_DETAIL:
      return {
        ...state,
        isLoading: false,
      }
    case SET_IS_SUCCESS_WAREHOUSE_DETAIL:
      return {
        ...state,
        isLoading: false,
        warehouseDetailData: payload.content,
      }

    case SET_IS_SUCCESS_WAREHOUSE_DETAIL_HISTORY:
      return {
        ...state,
        isLoading: false,
        warehouseDetailHistoryData: payload,
      }

    case SET_IS_NEED_TO_RELOAD_WAREHOUSE_DETAIL:
      return {
        ...state,
        needToReload: state.needToReload + 1,
      }
    case SET_PAGINATION_DATA_WAREHOUSE_DETAIL:
      return {
        ...state,
        paginationData: payload,
      }

    case SET_PAGINATION_DATA_WAREHOUSE_DETAIL_HISTORY:
      return {
        ...state,
        paginationDataStockHistory: payload,
      }

    case SET_SELECT_PRODUCT_DATA_WAREHOUSE_DETAIL:
      return {
        ...state,
        selectedProduct: {
          ...state.selectedProduct,
          ...payload,
        },
      }
    default:
      return {
        ...state,
      }
  }
}
