import {
  SET_IS_START_SETPOS,
  SET_IS_FAILED_SETPOS,
  SET_IS_SUCCESS_SETPOS,
  SET_IS_PAGINATION_DATA_SETPOS,
  SET_IS_SUCCESS_SETPOS_CATEGORY,
  SET_IS_PAGINATION_DATA_SETPOS_CATEGORY,
  SET_IS_SUCCESS_SETPOS_PRODUCT,
  SET_IS_PAGINATION_DATA_SETPOS_PRODUCT,
  SET_IS_NEEN_TO_RELOAD_SETPOS,
  SET_IS_ALL_PRODUCT_ENTRY_POINT_SETPOS_CATEGORY
} from "storeContext/actionsType/setPosition";

export const initialStateSetPosition = {
  isLoading: false,
  setPosData: [],
  setPosPaginationData: {},
  setPosCatData: [],
  setPosCatAllProductEntryPointData: {},
  setPosCatPaginationData: {},
  setPosProdData: [],
  setPosProdPaginationData: {},
  needToReload: 1
};

export default function setPosition(
  state = initialStateSetPosition,
  { type, payload }
) {
  switch (type) {
    case SET_IS_START_SETPOS:
      return {
        ...state,
        isLoading: true
      };
    case SET_IS_FAILED_SETPOS:
      return {
        ...state,
        isLoading: false
      };
    case SET_IS_SUCCESS_SETPOS:
      return {
        ...state,
        isLoading: false,
        setPosData: payload
      };
    case SET_IS_PAGINATION_DATA_SETPOS:
      return {
        ...state,
        setPosPaginationData: payload
      };
    case SET_IS_SUCCESS_SETPOS_CATEGORY:
      return {
        ...state,
        isLoading: false,
        setPosCatData: payload.content
      };
    case SET_IS_PAGINATION_DATA_SETPOS_CATEGORY:
      return {
        ...state,
        setPosCatPaginationData: payload
      };
    case SET_IS_SUCCESS_SETPOS_PRODUCT:
      return {
        ...state,
        isLoading: false,
        setPosProdData: payload.content
      };
    case SET_IS_PAGINATION_DATA_SETPOS_PRODUCT:
      return {
        ...state,
        setPosProdPaginationData: payload
      };
    case SET_IS_NEEN_TO_RELOAD_SETPOS:
      return {
        ...state,
        needToReload: state.needToReload + 1
      };
    case SET_IS_ALL_PRODUCT_ENTRY_POINT_SETPOS_CATEGORY:
      return {
        ...state,
        setPosCatAllProductEntryPointData: payload
      };
    default:
      return {
        ...state
      };
  }
}
