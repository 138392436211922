import { createAsyncThunk } from '@reduxjs/toolkit'
import { getFpoTrimmingPackingDetail } from 'utils/apiList/freshPurchaseOrderTrimmingPacking'
import { callErrorMsg } from 'helpers/errorMsg'

export const SLICE_NAME = 'freshPurchaseTrimmingPackingDetail'

export const fetchTrimmingPackingDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchTrimmingPackingDetail`,
  async (id: number, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await getFpoTrimmingPackingDetail(id)

      const convertToKg = (qtyInGram: number) => qtyInGram / 1000

      return {
        ...data,
        conversions: data.conversions.map((itemConversion) => ({
          ...itemConversion,
          usedQty: convertToKg(itemConversion.usedQty),
          recycleQty: convertToKg(itemConversion.recycleQty),
          disposalQty: convertToKg(itemConversion.disposalQty),
          returnQty: convertToKg(itemConversion.returnQty),
          overgramQty: convertToKg(itemConversion.overgramQty),
        })),
      }
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
