import { memo, useState, useEffect, useContext } from 'react'
import { Pill } from '@astro-ui/components'
import styled from 'styled-components'
import { TextBody, Toggle, Input, Button /* InputDropDown */ } from 'components'
import { useHistory } from 'react-router-dom'
// import storeContext from "storeContext";
import { numberOnly, formatMoneyRMG } from 'helpers'
import { productWarehouseProductDetail, productWarehouseStockDetail } from 'config/routes'
import store from 'storeContext'
import { selectProductData } from 'storeContext/actions/warehouseDetail'
import { objectToQueryParams } from 'utils/getParams'
// import { actGetRakOptions_HUB } from "storeContext/actions/warehouse/wareHouseTable";
import { styleBusinessTagging } from 'features/Product/constant/product'

function RowWarehouse({
  data,
  handleChangeActive,
  handleClickSave,
  allowedForStockTrailing,
  allowedForEditPrice,
  allowedForEditRack,
  allowedForStock,
  allowedForToggleStatus,
}) {
  const history = useHistory()

  const [formValue, setFormValue] = useState({})

  const { dispatch: dispatchWarehouseDetail } = useContext(store.WarehouseDetailContext)

  useEffect(() => {
    setFormValue(data)
  }, [data])
  const handleChangeForm = (e) => {
    const {
      target: { value, name },
    } = e
    if (name === 'inventoryStock' || name === 'inventoryPrice') {
      setFormValue({
        ...formValue,
        [name]: numberOnly(value), //removeZeroOnFirst(numberOnly(value))
      })
    } else {
      setFormValue({ ...formValue, [name]: value })
    }
  }

  const handleClickProductDetail = () => {
    dispatchWarehouseDetail(
      selectProductData({
        ...data,
      }),
    )
    const object = {
      product_id: data.productId,
      product_name: data.productName,
      location_id: data.locationId,
      location_name: data.locationName,
      location_type: data.locationType,
      sku: data.productSkuNumber,
    }
    const query = objectToQueryParams(object)
    history.push(`${productWarehouseProductDetail}${query}`)
  }

  const handleClickStockDetail = () => {
    dispatchWarehouseDetail(
      selectProductData({
        ...data,
      }),
    )

    const objectToQuery = {
      product_id: data.productId,
      product_name: data.productName,
      location_id: data.locationId,
      location_name: data.locationName,
    }

    const query = objectToQueryParams(objectToQuery)

    history.push(`${productWarehouseStockDetail}${query}`)
  }

  return (
    <Tr>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {data.locationName}
        </TextBody>
      </Td>
      <Td ratio="4">
        <ProductWrapper>
          <ImgCategory size="32px" alt="box" src={data.productImage} />
          <TextBody weight="light" color="textSoft">
            {data.productName}
          </TextBody>
        </ProductWrapper>
      </Td>
      <Td ratio="2">
        <TextBody weight="light" color="textSoft">
          {data.productSkuNumber}
        </TextBody>
        {data.businessTaggings.length > 0 && (
          <>
            {data.businessTaggings.map((taggingItem) => (
              <Pill
                key={taggingItem.key}
                content={taggingItem.value}
                sx={{ marginTop: '5px', ...styleBusinessTagging[taggingItem.key] }}
              />
            ))}
          </>
        )}
      </Td>
      <Td ratio="2" pr="20px">
        <Input
          disabled={!allowedForEditPrice}
          customIcon="Rp"
          bgIcon="body"
          iconPos="left"
          value={formatMoneyRMG(formValue.inventoryPrice, '')}
          name="inventoryPrice"
          onChange={handleChangeForm}
        />
      </Td>
      <Td ratio="2" pr="20px">
        <TextBody
          onClick={() => {
            allowedForStock && handleClickProductDetail()
          }}
          weight="light"
          className={allowedForStock && 'pointer'}
          color={allowedForStock && 'main'}
        >
          {formValue.inventoryStock}
        </TextBody>
      </Td>
      <Td ratio="2" pr="20px">
        <TextBody
          weight="light"
          className={allowedForStock && 'pointer'}
          color={allowedForStock && 'main'}
        >
          {formValue.inventoryTotalStock}
        </TextBody>
      </Td>
      <Td ratio="1" pr="20px">
        <TextBody weight="light" color="textSoft">
          {data?.inventoryDiscountStock}
        </TextBody>
      </Td>
      <Td ratio="2">
        <TextBody weight="light" color="textSoft">
          {data.inventoryReversedStock}
        </TextBody>
      </Td>
      <Td ratio="1">
        <WrapperActions>
          <Toggle
            active={data.inventoryActive}
            onClick={() => handleChangeActive(data)}
            disabled={!allowedForToggleStatus}
          />
        </WrapperActions>
      </Td>
      <Td ratio="1">
        <WrapperActions>
          <Button variant="wrapper" onClick={handleClickStockDetail}>
            <TextBody weight="light" className="pointer" color="main">
              Detail
            </TextBody>
          </Button>
          {(formValue.rackName !== data.rackName ||
            formValue.inventoryStock?.toString() !== data.inventoryStock?.toString() ||
            formValue.inventoryPrice !== data.inventoryPrice?.toString()) && (
            <Button size="small" onClick={() => handleClickSave(formValue)}>
              Simpan
            </Button>
          )}
        </WrapperActions>
      </Td>
    </Tr>
  )
}

export default memo(RowWarehouse)

const Tr = styled.tr`
  display: flex;
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
  .input-container {
    width: calc(100% - 20px);
  }
`
const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  /* padding-right: ${({ pr }) => pr}; */
`
const ImgCategory = styled.img`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  border-radius: 5px;
  background-color: ${({ theme: { colors } }) => colors.mainFaded};
  border: none;
`

const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const WrapperActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
