import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type {
  GetStockOpnameDetailInfoType,
  GetStockOpnameDetailListType,
  PostCancelStockOpnameRequestType,
} from 'utils/apiList/stockOpname'

import {
  fetchWarehouseLocationsList,
  fetchStockOpnameGroupList,
  fetchStockOpnameList,
  cancelStockOpname,
  submitStockOpname,
  fetchStockOpnameDetail,
  fetchStockOpnameDetailList,
  fetchCheckerList,
  submitAssignChecker,
  adjustStockOpname,
} from './stockOpnameListThunk'

export interface STOListDataType {
  action: string[]
  assignee: { id: number; name: string }
  createdAt: number
  gap: number
  id: number
  status: string
  cancelledBy: string
  stockOpnameGroupId: number
  stockOpnameNumber: string
  isRecount: boolean
  totalRack: number
  uniqueKey: string
  latestCountVersion: number
}

export interface STOGroupListDataType {
  action: string[]
  completedAt: number
  createdAt: number
  expiredAt: number
  gap: number
  id: number
  location: { id: number; name: string }
  startAt: number
  stockOpnameGroupNumber: string
  latestCountVersion: number
  taskComplete: number
  taskCount: number
  totalRack: number
  status: string
  cancelledBy: string
  uniqueKey: string
  child: {
    data: STOListDataType[]
    isLoading: boolean
    query: {
      pageSize: number
      pageIndex: number
      countVersion?: number
    }
    pagination: {
      pageIndex: number
      pageSize: number
      numberOfElements: number
    }
  }
}

export type LocationListType = {
  location_id: number
  location_name: string
  location_type: string
  name: string
}

export interface QueryStateType {
  stockOpnameNumber: string
  searchProduct: string
  searchRack: string
  location: LocationListType
  pageSize: number
  pageIndex: number
  status: string
}

interface StageCancelSTOType {
  id: number
  number: string
  type: PostCancelStockOpnameRequestType['type']
}

export interface AssignCheckerType {
  data: {
    id: number
    name: string
    uniqueKey: string
    isChecked: boolean
  }[]
  query: {
    location: {
      id: number
      name: string
    }
    stockOpnameId: number
    search: string
    type: 'ASSIGN' | 'REASSIGN'
    assignee: STOListDataType['assignee']
  }
}

export interface StageConfirmAdjustmentType {
  stockOpnameId: number
}

export interface StockOpnameListStateType {
  isLoading: boolean
  isShowModalAssignChecker: boolean
  assignChecker: AssignCheckerType
  isShowModalConfirmAdjustment: boolean
  stageConfirmAdjusmtent: StageConfirmAdjustmentType
  query: QueryStateType
  locationList: LocationListType[]
  STOList: {
    data: STOGroupListDataType[]
    pagination: null
  }
  stageCancelSTO: StageCancelSTOType
  needToReloadList: number
  needToReloadGroup: number
  STODetail: {
    isLoading: boolean
    info: GetStockOpnameDetailInfoType
    list: GetStockOpnameDetailListType[]
  }
  modalConfirmationInAdjustment: {
    isOpen: boolean
    id: number
    type: '' | 'ADJUST' | 'CANCEL' | 'PRINT_PREVIEW_REPORT' | 'RECOUNT'
    title: string
    description: string
    stockOpnameNumber: string
  }
}

export const initialState: StockOpnameListStateType = {
  isLoading: false,
  isShowModalAssignChecker: false,
  assignChecker: {
    data: [],
    query: {
      location: { id: 0, name: '' },
      stockOpnameId: 0,
      search: '',
      type: 'ASSIGN',
      assignee: { id: 0, name: '' },
    },
  },
  isShowModalConfirmAdjustment: false,
  stageConfirmAdjusmtent: { stockOpnameId: 0 },
  query: {
    location: { location_id: 0, location_name: '', location_type: '', name: '' },
    stockOpnameNumber: '',
    searchProduct: '',
    searchRack: '',
    pageSize: 10,
    pageIndex: 1,
    status: '',
  },
  locationList: [],
  STOList: { data: [], pagination: null },
  stageCancelSTO: {
    id: 0,
    number: '',
    type: 'GROUP',
  },
  needToReloadList: 0,
  needToReloadGroup: 0,
  STODetail: {
    isLoading: true,
    info: {
      action: [],
      id: 0,
      stockOpnameNumber: '-',
      createdAt: 0,
      stockOpnameGroupNumber: '-',
      totalSloc: 0,
      gap: 0,
      accuracy: 0,
      status: '-',
      location: {
        id: 1,
        name: '-',
        type: '-',
      },
      assignee: {
        id: 1,
        name: '-',
      },
      activities: [],
      assignActivities: [],
    },
    list: [],
  },
  modalConfirmationInAdjustment: {
    isOpen: false,
    id: 0,
    type: '',
    title: '',
    description: '',
    stockOpnameNumber: '',
  },
}

const uniqueVoucherList = createSlice({
  name: 'stockOpnameList',
  initialState,
  reducers: {
    resetState: () => initialState,
    setIsLoadingStoList: (state, action) => {
      state.isLoading = action.payload
    },
    setQuery: (state, action: PayloadAction<Partial<StockOpnameListStateType['query']>>) => {
      state.query = {
        ...state.query,
        ...action.payload,
      }
    },
    setQueryAssignChecker: (
      state,
      action: PayloadAction<{
        fieldName: keyof StockOpnameListStateType['assignChecker']['query']
        value: string | number | AssignCheckerType['query']['location']
      }>,
    ) => {
      const { value, fieldName } = action.payload
      state.assignChecker.query[fieldName] = value as never
    },
    setIsShowModalAssignChecker: (state, action: PayloadAction<{ value: boolean }>) => {
      state.isShowModalAssignChecker = action.payload.value
    },
    setSelectedAssignCheckerData: (
      state,
      action: PayloadAction<{
        uniqueKey: StockOpnameListStateType['assignChecker']['data'][0]['uniqueKey']
      }>,
    ) => {
      const { uniqueKey } = action.payload
      state.assignChecker.data = state.assignChecker.data.map((el) => ({
        ...el,
        isChecked: uniqueKey === el.uniqueKey ? !el.isChecked : false,
      }))
    },
    setStageCancelSTO: (state, action: PayloadAction<StageCancelSTOType>) => {
      state.stageCancelSTO = action.payload
    },
    setIsShowModalConfirmAdjustment: (state, action: PayloadAction<{ value: boolean }>) => {
      const { value } = action.payload
      state.isShowModalConfirmAdjustment = value
      if (!value) {
        state.stageConfirmAdjusmtent = initialState.stageConfirmAdjusmtent
      }
    },
    setStageCongfirmAdjustment: (
      state,
      action: PayloadAction<{
        fieldName: keyof StockOpnameListStateType['stageConfirmAdjusmtent']
        value: number
      }>,
    ) => {
      const { fieldName, value } = action.payload
      state.stageConfirmAdjusmtent[fieldName] = value
    },
    setModalConfirmationInAdjustment: (
      state,
      action: PayloadAction<Partial<StockOpnameListStateType['modalConfirmationInAdjustment']>>,
    ) => {
      state.modalConfirmationInAdjustment = {
        ...state.modalConfirmationInAdjustment,
        ...action.payload,
      }
    },
    resetModalConfirmationInAdjustment: (state) => {
      state.modalConfirmationInAdjustment = initialState.modalConfirmationInAdjustment
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchWarehouseLocationsList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchWarehouseLocationsList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchWarehouseLocationsList.fulfilled, (state, action) => {
        state.locationList = action.payload.map((el) => ({ ...el, name: el.location_name }))
      })
      .addCase(fetchStockOpnameGroupList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchStockOpnameGroupList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchStockOpnameGroupList.fulfilled, (state, action) => {
        state.STOList = {
          data: action.payload.data,
          pagination: action.payload.pagination,
        }
        state.query.pageIndex = action.payload.pageIndexParent
        state.isLoading = false
      })
      .addCase(fetchStockOpnameList.pending, (state, action) => {
        const { arg } = action.meta
        const indexStoParent = state.STOList.data.findIndex(
          (el) => el.id === arg.stockOpnameGroupID,
        )
        if (indexStoParent !== -1) {
          state.STOList.data[indexStoParent].child.isLoading = true
        }
      })
      .addCase(fetchStockOpnameList.rejected, (state, action) => {
        const { arg } = action.meta
        const indexStoParent = state.STOList.data.findIndex(
          (el) => el.id === arg.stockOpnameGroupID,
        )
        if (indexStoParent !== -1) {
          state.STOList.data[indexStoParent].child.isLoading = false
        }
      })
      .addCase(fetchStockOpnameList.fulfilled, (state, action) => {
        const { arg } = action.meta
        const { data, pagination, shouldResetList } = action.payload

        const indexStoParent = state.STOList.data.findIndex(
          (el) => el.id === arg.stockOpnameGroupID,
        )
        if (indexStoParent !== -1) {
          const listChildData: STOListDataType[] = data.map((el) => ({
            ...el,
            uniqueKey: window.crypto.randomUUID(),
          }))

          state.STOList.data[indexStoParent].child.isLoading = false
          if (shouldResetList) {
            state.STOList.data[indexStoParent].child.data = listChildData
          } else {
            state.STOList.data[indexStoParent].child.data = [
              ...state.STOList.data[indexStoParent].child.data,
              ...listChildData,
            ]
          }

          state.STOList.data[indexStoParent].child.pagination = {
            pageSize: pagination.pageSize,
            pageIndex: pagination.pageIndex,
            numberOfElements: pagination.numberOfElements,
          }
          state.STOList.data[indexStoParent].child.query.pageIndex = pagination.pageIndex
        }
      })
      .addCase(cancelStockOpname.fulfilled, (state) => {
        state.stageCancelSTO = initialState.stageCancelSTO
      })
      .addCase(submitStockOpname.pending, (state) => {
        state.isLoading = true
      })
      .addCase(submitStockOpname.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(submitStockOpname.fulfilled, (state) => {
        state.isLoading = false
        state.needToReloadGroup += 1
      })
      .addCase(fetchStockOpnameDetail.pending, (state) => {
        state.STODetail.isLoading = true
      })
      .addCase(fetchStockOpnameDetail.fulfilled, (state, action) => {
        state.STODetail.info = action.payload.data
        state.STODetail.isLoading = false
      })
      .addCase(fetchStockOpnameDetailList.pending, (state) => {
        state.STODetail.isLoading = true
      })
      .addCase(fetchStockOpnameDetailList.fulfilled, (state, action) => {
        state.STODetail.list = action.payload.data
        state.STODetail.isLoading = false
      })
      .addCase(fetchCheckerList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchCheckerList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchCheckerList.fulfilled, (state, action) => {
        state.isLoading = false
        state.assignChecker.data = action.payload.data.map((el) => ({
          ...el,
          uniqueKey: window.crypto.randomUUID(),
          isChecked: false,
        }))
      })
      .addCase(submitAssignChecker.pending, (state) => {
        state.isLoading = true
      })

      .addCase(submitAssignChecker.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(submitAssignChecker.fulfilled, (state) => {
        state.isLoading = false
        state.assignChecker = initialState.assignChecker
        state.isShowModalAssignChecker = false
      })
      .addCase(adjustStockOpname.pending, (state) => {
        state.isLoading = false
      })
      .addCase(adjustStockOpname.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(adjustStockOpname.fulfilled, (state) => {
        state.isLoading = false
        state.isShowModalConfirmAdjustment = false
        state.stageConfirmAdjusmtent = initialState.stageConfirmAdjusmtent
      })
  },
})

export const {
  resetState,
  setQuery,
  setIsLoadingStoList,
  setStageCancelSTO,
  setIsShowModalAssignChecker,
  setIsShowModalConfirmAdjustment,
  setQueryAssignChecker,
  setSelectedAssignCheckerData,
  setStageCongfirmAdjustment,
  setModalConfirmationInAdjustment,
  resetModalConfirmationInAdjustment,
} = uniqueVoucherList.actions
export default uniqueVoucherList.reducer
