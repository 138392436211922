import { createAsyncThunk } from '@reduxjs/toolkit'

import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'

import { callErrorMsg } from 'helpers/errorMsg'

import { getAssetMasterListAPI } from 'features/AssetManagement/services/assetMaster'

import {
  setAssetMasterList,
  setHasNext,
  setIsLoading,
} from 'features/AssetManagement/redux/assetMaster/slice'

const SLICE_NAME = 'assetMasterList'

export const getAssetMasterLists = createAsyncThunk(
  `${SLICE_NAME}/getAssetMasterLists`,
  async (_, { rejectWithValue, getState, dispatch }) => {
    const {
      assetMaster: { assetMasterParam },
    } = getState() as StoreStateType

    dispatch(setIsLoading(true))
    try {
      const res = await getAssetMasterListAPI({
        ...assetMasterParam,
      })

      const resData = res.data.data

      dispatch(setAssetMasterList(resData))
      dispatch(setHasNext(res.data.has_next))
      dispatch(setIsLoading(false))

      return true
    } catch (error) {
      callErrorMsg(error)
      dispatch(setIsLoading(false))
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)
