import { createAsyncThunk } from '@reduxjs/toolkit'
import { getFPOIList, getWarehouseList } from 'utils/apiList/freshPurchaseOrderInbound'
import { callErrorMsg } from 'helpers/errorMsg'

const SLICE_NAME = 'freshPurchaseInbound'

export const fetchFPOIList = createAsyncThunk(
  `${SLICE_NAME}/fetchFreshPurchaseOrderList`,
  async (params: string, { rejectWithValue }) => {
    try {
      const res = await getFPOIList(`?${params}`)
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchWarehouseLocationsList = createAsyncThunk(
  `${SLICE_NAME}/fetchFreshPurchaseOrderWarehouseLocationList`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getWarehouseList()

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
