import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  GetTimeSlotInterface,
  TimeSlotParamType,
  TimeSlotRequestParamType,
  LoadingDockTimeSlotType,
} from 'features/Enterprise/CapacityHandler/services/timeSlot'

import { SLICE_NAME, fetchTimeSlotList, updateTimeSlot } from './timeSlotThunk'

// eslint-disable-next-line no-shadow
export enum TimeSlotDialogStateEnum {
  CLOSED,
  OPEN,
}
interface TimeSlotStateInterface {
  isLoading: boolean
  timeSlotList: GetTimeSlotInterface['data'] | null
  query: TimeSlotParamType
  updateTimeSlotDialogState: TimeSlotDialogStateEnum
  selectedTimeSlot: LoadingDockTimeSlotType | null
}

const initialState: TimeSlotStateInterface = {
  isLoading: false,
  timeSlotList: null,
  query: {
    loadingDockId: null,
  },
  updateTimeSlotDialogState: TimeSlotDialogStateEnum.CLOSED,
  selectedTimeSlot: null,
}

const timeSlotSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    updateQuery: (state, { payload }: PayloadAction<TimeSlotRequestParamType>) => {
      state.query = {
        ...state.query,
        ...payload,
      }
    },
    setTimeSlotDialogState: (state, { payload }: PayloadAction<TimeSlotDialogStateEnum>) => {
      state.updateTimeSlotDialogState = payload
    },
    setSelectedTimeSlot: (state, { payload }: PayloadAction<LoadingDockTimeSlotType | null>) => {
      state.selectedTimeSlot = payload
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTimeSlotList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchTimeSlotList.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.timeSlotList = payload.data
      })
      .addCase(fetchTimeSlotList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateTimeSlot.fulfilled, (state) => {
        state.updateTimeSlotDialogState = TimeSlotDialogStateEnum.CLOSED
      })
  },
})

export const { resetState, updateQuery, setTimeSlotDialogState, setSelectedTimeSlot, setLoading } =
  timeSlotSlice.actions
export default timeSlotSlice.reducer
