import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import { getOrderTypes, getAllOrder, getOrderLocations } from 'features/Order/services/allOrder'
import { RootStateType } from 'store'
import {
  authAdminChecker,
  AUTH_CS_CAPTAIN,
  AUTH_CUSTOMER_SERVICE,
  AUTH_MARKETING,
  AUTH_CS_AGENT,
  AUTH_HO_CS_LEADER,
} from 'middleware/privateRoute'
import { roleUserChecker } from 'middleware/filterLocation'
import { toastFailed } from 'utils/toast'

const ucFirst = (str: string) =>
  str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')

export const fetchOrderTypes = createAsyncThunk(
  `allOrderV2/fetchOrderTypeList`,
  async (_, { fulfillWithValue, rejectWithValue }) => {
    try {
      const response = await getOrderTypes()
      let opts = [{ label: 'Semua Tipe', value: '' }]
      if (response.data) {
        opts = [
          ...opts,
          ...response.data.data.map((item) => ({
            label: ucFirst(item),
            value: item,
          })),
        ]
      }
      return fulfillWithValue(opts)
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchOrderLocations = createAsyncThunk(
  `allOrderV2/fetchAllOrderLocations`,
  async (_, { fulfillWithValue, rejectWithValue, getState }) => {
    try {
      const {
        auth: { userData },
      } = getState() as RootStateType
      const response = await getOrderLocations()
      let locations = response.data.data
      locations = locations.filter((location) => location.location_type !== 'WAREHOUSE')
      const checkAdmin = authAdminChecker(userData)
      const checkRole = roleUserChecker(userData, [
        AUTH_CS_CAPTAIN,
        AUTH_CUSTOMER_SERVICE,
        AUTH_MARKETING,
        AUTH_CS_AGENT,
        AUTH_HO_CS_LEADER,
      ])

      const isUserCs = roleUserChecker(userData, [
        AUTH_CS_CAPTAIN,
        AUTH_CUSTOMER_SERVICE,
        AUTH_CS_AGENT,
        AUTH_HO_CS_LEADER,
      ])

      if ((!checkAdmin || checkRole) && !isUserCs) {
        locations = locations.filter((location) =>
          userData.location_roles.some((role) => role.location_id === location.location_id),
        )
      }

      return fulfillWithValue({
        locations,
        isUserCs,
        hasDefaultLocation: !checkAdmin || checkRole,
      })
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

type FetchAllOrderParamsType = {
  query: string
  /**
   * Not gonna used in thunk but will be used at fetchAllOrder.pending
   * thus only query is called. No, it's not wasted.
   * @default true
   */
  showLoading?: boolean
}

export const fetchAllOrder = createAsyncThunk(
  `allOrderV2/fetchAllOrder`,
  async ({ query }: FetchAllOrderParamsType, { fulfillWithValue, rejectWithValue, getState }) => {
    try {
      const {
        allOrderV2: { defaultLocation, orderLocations, isFetchingLocation },
      } = getState() as RootStateType
      const params = new URLSearchParams(query)

      /**
       * Prevent race condition where user has default location
       * and location is not fast enough to complete yet we already fired
       * get order request
       */
      if (isFetchingLocation && !defaultLocation) {
        return rejectWithValue('Fetching location pending')
      }

      if (
        params.get('locationId') &&
        defaultLocation &&
        orderLocations.every((location) => location.id !== Number(params.get('locationId')))
      ) {
        throw new Error('Location not found')
      }

      if (!query.includes('locationId') && defaultLocation?.id) {
        params.set('locationId', String(defaultLocation?.id))
      }

      const response = await getAllOrder(`?${params.toString().replace('+', '%20')}`)
      const { content, ...pagination } = response.data
      return fulfillWithValue({
        content,
        pagination,
      })
    } catch (err) {
      if (err instanceof Error) {
        toastFailed(err.message)
        return rejectWithValue(err)
      }

      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
