import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'
import {
  getHistoryCoin,
  getTemplateBulkUploadCoin,
  postBulkUploadHistoryCoin,
  postInjectCoin,
} from 'utils/apiList/historyCoin'
import { toastFailed, toastSuccess } from 'utils/toast'
import {
  GetHistoryCoinResponseType,
  GetTemplateResponseType,
  LoyaltyHistoryCoinType,
} from './loyaltyHistoryCoin.types'

const initialState: LoyaltyHistoryCoinType = {
  params: {
    search: '',
    fromDate: dayjs(new Date()).valueOf(),
    toDate: dayjs(new Date()).valueOf(),
    sort: 'created_at',
    direction: 'desc',
    pageSize: 10,
    pageIndex: 0,
  },
  pagination: {
    pageSize: 10,
    currentItem: 0,
    currentPage: 0,
    totalData: 0,
    last: false,
  },
  form: {
    customerId: '',
    coin: 0,
    typeTrx: '',
  },
  isLoading: false,
  data: [],
}

const SLICE_NAME = 'loyaltyHistoryCoin'

const loyaltyHistoryCoin = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<LoyaltyHistoryCoinType['isLoading']>) => {
      state.isLoading = action.payload
    },
    setData: (state, action: PayloadAction<LoyaltyHistoryCoinType['data']>) => {
      state.data = action.payload
    },
    setPagination: (state, action: PayloadAction<LoyaltyHistoryCoinType['pagination']>) => {
      state.pagination = action.payload
    },
    setParams: (state, action: PayloadAction<LoyaltyHistoryCoinType['params']>) => {
      state.params = action.payload
    },
    setForm: (state, action: PayloadAction<LoyaltyHistoryCoinType['form']>) => {
      state.form = action.payload
    },
    resetForm: (state) => {
      state.form = initialState.form
    },
    reset: (state) => {
      Object.assign(state, { ...initialState })
    },
  },
})

export const { reset, setPagination, setIsLoading, setData, setParams, setForm, resetForm } =
  loyaltyHistoryCoin.actions

export const getData = createAsyncThunk<GetHistoryCoinResponseType, undefined, RejectValueType>(
  `${SLICE_NAME}/getData`,
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      dispatch(setIsLoading(true))
      const {
        loyaltyHistoryCoin: { params },
      } = getState() as StoreStateType

      const res: AxiosResponse<GetHistoryCoinResponseType> = await getHistoryCoin(params)
      const {
        data: {
          data,
          pagination: { pageSize, pageIndex, totalPages, totalElement, numberOfElements },
        },
      } = res
      dispatch(setData(data))
      dispatch(
        setPagination({
          pageSize,
          currentItem: numberOfElements,
          currentPage: pageIndex,
          totalData: totalElement,
          last: pageIndex === totalPages,
        }),
      )
      return res.data
    } catch (error) {
      toastFailed('Gagal Load Data')
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const submitForm = createAsyncThunk<boolean | string, () => void, RejectValueType>(
  `${SLICE_NAME}/postInjectCoin`,
  async (cb, { dispatch, rejectWithValue, getState }) => {
    try {
      const {
        loyaltyHistoryCoin: { form },
      } = getState() as StoreStateType

      await postInjectCoin(form)

      toastSuccess('Berhasil submit inject coin')
      dispatch(resetForm())
      if (cb !== undefined) {
        cb()
      }

      return true
    } catch (error) {
      toastFailed('Gagal submit inject coin')
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchUrlTemplateBulkUpload = createAsyncThunk<
  GetTemplateResponseType,
  undefined,
  RejectValueType
>(`${SLICE_NAME}/fetchUrlTemplateBulkUpload`, async (_, { rejectWithValue }) => {
  try {
    const { data } = await getTemplateBulkUploadCoin()
    return data?.data
  } catch (error) {
    toastFailed('Gagal load data')
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const submitBulkUpload = createAsyncThunk(
  `${SLICE_NAME}/submitBulkUpload`,
  async (file: File, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          // eslint-disable-next-line @typescript-eslint/naming-convention, sonarjs/no-duplicate-string
          'Content-Type': 'multipart/form-data',
        },
      }
      const formData = new FormData()
      formData.append('file', file)
      const { data } = await postBulkUploadHistoryCoin(formData, config)
      if (data?.data?.status === 'Contains Duplicate') {
        toastFailed('Terdapat data yang duplikat')
      } else {
        toastSuccess('Berhasil submit inject coin')
      }
      return data?.data
    } catch (error) {
      toastFailed('Gagal submit inject coin')
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export default loyaltyHistoryCoin.reducer
