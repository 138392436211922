import {
  PostValidateTicketRequestType,
  PostValidateTicketResponseType,
} from 'features/AstroEvent/@types'

import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

const { Post } = axiosInstanceApiGateway

export const postValidateTicket = ({ payload }: PostValidateTicketRequestType) =>
  Post<PostValidateTicketResponseType, true>({
    url: '/loyalty/internal/v1/event/validate',
    data: payload,
  })
