import {
  SET_IS_LOADING,
  SET_PAGINATION_DATA_PROMOTION,
  SET_PROMOTION_DATA,
  SET_LAST_QUERY,
} from 'storeContext/actionsType/promotion'

export const initialStatePromotion = {
  isLoading: false,
  promotionData: [],
  paginationData: {},
  lastQuery: {},
}

export default function promotion(state = initialStatePromotion, { type, payload }) {
  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case SET_PAGINATION_DATA_PROMOTION:
      return {
        ...state,
        paginationData: payload,
      }
    case SET_PROMOTION_DATA:
      return {
        ...state,
        promotionData: payload,
      }
    case SET_LAST_QUERY:
      return {
        ...state,
        lastQuery: payload,
      }
    default:
      return state
  }
}
