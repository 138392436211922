import {
  AUTH_CENTRAL_SUPERVISOR,
  AUTH_MERCHANDISING_ADMIN,
  AUTH_MERCHANDISING_WAREHOUSE,
  AUTH_CAPTAIN,
  AUTH_MERCHANDISING_HUB,
  AUTH_PRICING,
  AUTH_SUPPLY_CHAIN,
  AUTH_STOCK_COUNT_AUDITOR,
  AUTH_PRODUCT_MANAGER,
  AUTH_QUALITY_ENGINEERING,
  AUTH_HO_TAX,
  AUTH_HO_MERCHANDISING,
  AUTH_HO_FINANCE,
} from 'middleware/privateRoute'

const product = {
  bulkUpload: [
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_MERCHANDISING_ADMIN,
    AUTH_MERCHANDISING_WAREHOUSE,
    AUTH_CAPTAIN,
    AUTH_MERCHANDISING_HUB,
    AUTH_SUPPLY_CHAIN,
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_HO_MERCHANDISING,
  ],
  addProduct: [
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_MERCHANDISING_ADMIN,
    AUTH_MERCHANDISING_WAREHOUSE,
    AUTH_CAPTAIN,
    AUTH_MERCHANDISING_HUB,
    AUTH_SUPPLY_CHAIN,
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_HO_MERCHANDISING,
  ],
  export: [
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_MERCHANDISING_ADMIN,
    AUTH_MERCHANDISING_WAREHOUSE,
    AUTH_CAPTAIN,
    AUTH_MERCHANDISING_HUB,
    AUTH_SUPPLY_CHAIN,
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_HO_MERCHANDISING,
  ],
  toggleStatus: [
    AUTH_MERCHANDISING_ADMIN,
    AUTH_MERCHANDISING_WAREHOUSE,
    AUTH_MERCHANDISING_HUB,
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_HO_MERCHANDISING,
  ],
  detail: [
    AUTH_PRICING,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_MERCHANDISING_ADMIN,
    AUTH_MERCHANDISING_WAREHOUSE,
    AUTH_CAPTAIN,
    AUTH_MERCHANDISING_HUB,
    AUTH_SUPPLY_CHAIN,
    AUTH_STOCK_COUNT_AUDITOR,
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_HO_TAX,
    AUTH_HO_MERCHANDISING,
    AUTH_HO_FINANCE,
  ],
  edit: [
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_MERCHANDISING_ADMIN,
    AUTH_MERCHANDISING_WAREHOUSE,
    AUTH_CAPTAIN,
    AUTH_MERCHANDISING_HUB,
    AUTH_SUPPLY_CHAIN,
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_HO_MERCHANDISING,
  ],
}

export default product
