import {
  MilestoneUserTypeOptionType,
  MilestoneRewardType,
} from 'features/Promotion/@types/MilestoneAddEdit'

export const userTypeOptions: MilestoneUserTypeOptionType[] = [
  {
    name: 'Early User',
    value: 'EARLY_USER',
  },
  {
    name: 'Global User',
    value: 'RETURNING_USER',
  },
]

export const backgroundColorOptions = [
  {
    name: 'Biru',
    value: '#0084FF',
  },
  {
    name: 'Cyan',
    value: '#00FFFF',
  },
  {
    name: 'Hijau',
    value: '#00FF00',
  },
  {
    name: 'Ungu',
    value: '#A020F0',
  },
  {
    name: 'Violet',
    value: '#7F00FF',
  },
  {
    name: 'Custom',
    value: 'custom',
  },
]

export const rewardTypeOptions: MilestoneRewardType[] = ['VOUCHER']
