import { memo } from "react";
import styled from "styled-components";

function Alert({ className }) {
  return (
    <Container className={className}>
      <TitleCon>Status Driver .</TitleCon>
    </Container>
  );
}

export default memo(Alert);

const Container = styled.div`
  background-color: ${({ theme: { colors } }) => colors.yellowFaded};
`;

const TitleCon = styled.div`
  padding: 14px 12px;
  background-color: ${({ theme: { colors } }) => colors.alertBg};
  width: fit-content;
`;
