import apiServices from 'config/apiServiceApiGateway'
import type { UserListType } from 'features/Account/@types/authenticationControll'

const { Get, Delete } = apiServices

export type GetUserListResponseType = {
  data: { user_auths: UserListType[] }
}

export type GetUserListRequestType = {
  params: { page_size: number; page_index: number; name?: string; user_type?: string; id?: string }
}

export const getUserList = ({ params }: GetUserListRequestType) =>
  Get<GetUserListResponseType, true>({
    url: '/auth/internal/v1/user/auth',
    params,
  })

export type DeleteRemoveTokenRequestType = {
  params: {
    keys: string
  }
}
export const deleteRemoveToken = ({ params }: DeleteRemoveTokenRequestType) =>
  Delete({
    url: '/auth/internal/v1/token/remove/by-key',
    params,
  })
