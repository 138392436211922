import { SET_IS_ORDER_POOLING_DATA } from "storeContext/actionsType/packer";
import { setIsLoading, setDrivers } from "./index";
import { toastSuccess } from "utils/toast";

import { getDrivers } from "utils/api";
import { postOrderPooling_OrderPooling } from "utils/apiList/packer";
import { callErrorMsg } from "helpers/errorMsg";

export const setOrderPoolingData = (data) => {
  return {
    type: SET_IS_ORDER_POOLING_DATA,
    payload: data
  };
};

export const actHandleCallDriverForOrderPooling =
  (filterLocationID) => (dispatch) => {
    dispatch(setIsLoading(true));
    getDrivers(filterLocationID)
      .then(({ data: { data } }) => {
        const dataFormated = [...data].map((el) => {
          return { ...el, name: el.driver_full_name };
        });
        dataFormated.push({ name: "Request Grab / Gojek", id: "online" });
        dispatch(setDrivers(dataFormated));
        //dispatch(setSelectedPacker(packer));
        //dispatch(setIsPopupCallDriverShow(true));
      })
      .catch((err) => {
        //toastFailed("terjadi masalah, coba beberapa saat lagi");
        callErrorMsg(err);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };

export const actHandlePutOrderPooling =
  (dataToSend, localState = {}) =>
  (dispatch) => {
    dispatch(setIsLoading(true));

    return postOrderPooling_OrderPooling(dataToSend)
      .then(({ data }) => {
        const arrayResult = [...data.data.result].map((items) => {
          return items.order_id;
        });
        //console.log(arrayResult);
        dispatch(setOrderPoolingData([]));

        toastSuccess(
          `order nomer ${arrayResult.join(", ")} berhasil di assign ke driver`
        );
        localState.handleCloseModal();
      })
      .catch((err) => {
        //toastFailed("terjadi masalah, coba beberapa saat lagi");
        callErrorMsg(err);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };
