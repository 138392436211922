const tableConfig = {
  ratio: {
    noSKU: 10,
    Description: 20,
    orderQTY: 5,
    orderReceived: 5,
    difference: 5,
    uom: 8,
    dateExp: 10,
    status: 10,
    note: 15
  }
};

export default tableConfig;
