import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getVoucherBenefit,
  getVoucherTargeted,
  getMechanism,
  getInternalPaymentChannel,
  getVoucherById,
  postVoucher,
  putVoucher,
  type PostPutVoucherDetailRequestType,
  type VoucherUploadTargetUsersRequestType,
  postVoucherUploadTargetUsers,
  type GetUserTargetedListRequestType,
  getUserTargetedList,
  deleteCustomerVoucher,
  getUserSegmentList,
} from 'utils/apiList/voucher'
import { callErrorMsg } from 'helpers/errorMsg'
import { toastSuccess } from 'utils/toast'

export const SLICE_NAME = 'addEditVoucher'

export const fetchVoucherBenefit = createAsyncThunk(
  `${SLICE_NAME}/fetchVoucherBenefit`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getVoucherBenefit()

      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar benefit')
      return rejectWithValue(err)
    }
  },
)

export const fetchVoucherTargeted = createAsyncThunk(
  `${SLICE_NAME}/fetchVoucherTargeted`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getVoucherTargeted()

      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar target user')
      return rejectWithValue(err)
    }
  },
)

export const fetchVoucherMechanism = createAsyncThunk(
  `${SLICE_NAME}/fetchVoucherMechanism`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getMechanism()

      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar mekanisme')
      return rejectWithValue(err)
    }
  },
)

export const fetchUserSegment = createAsyncThunk(
  `${SLICE_NAME}/fetchUserSegment`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getUserSegmentList()

      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar user segment')
      return rejectWithValue(err)
    }
  },
)

export const fetchVoucherPaymentChannel = createAsyncThunk(
  `${SLICE_NAME}/fetchVoucherPaymentChannel`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getInternalPaymentChannel()

      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar payment channel')
      return rejectWithValue(err)
    }
  },
)

export const getVoucherDetail = createAsyncThunk(
  `${SLICE_NAME}/getVoucherDetail`,
  async (id: number, { rejectWithValue }) => {
    try {
      const {
        data: {
          data: { content },
        },
      } = await getVoucherById(id)

      return content
    } catch (err) {
      callErrorMsg(err, 'Gagal detail voucher')
      return rejectWithValue(err)
    }
  },
)

export const createVoucher = createAsyncThunk(
  `${SLICE_NAME}/createVoucher`,
  async (payload: PostPutVoucherDetailRequestType, { rejectWithValue }) => {
    try {
      const { data } = await postVoucher(payload)

      toastSuccess('Buat voucher baru berhasil')

      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal membuat voucher baru')
      return rejectWithValue(err)
    }
  },
)

export const updateVoucher = createAsyncThunk(
  `${SLICE_NAME}/updateVoucher`,
  async (
    { id, payload }: { id: number; payload: PostPutVoucherDetailRequestType },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await putVoucher(id, payload)

      toastSuccess('Update voucher berhasil')

      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal update voucher')
      return rejectWithValue(err)
    }
  },
)

export const updateTargetedUsers = createAsyncThunk(
  `${SLICE_NAME}/fetchUploadTargetedUsers`,
  async (params: VoucherUploadTargetUsersRequestType, { rejectWithValue }) => {
    try {
      const formData: any = new FormData() // eslint-disable-line
      formData.append('file', params.data)

      const res = await postVoucherUploadTargetUsers({ data: formData })
      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchUserTargetedList = createAsyncThunk(
  `${SLICE_NAME}/fetchUserTargetedList`,
  async (id: string, { rejectWithValue, getState }) => {
    const rootState = getState() as StoreStateType
    const {
      userTargeted: { query },
    } = rootState.addEditVoucher

    const params: GetUserTargetedListRequestType['params'] = {
      pageIndex: query.pageIndex.toString(),
      phoneNumber: query.phoneNumber,
      sort: 'createdAt',
      direction: 'asc',
    }
    try {
      const { data } = await getUserTargetedList({ id, params })

      return data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const removeCustomerVoucher = createAsyncThunk(
  `${SLICE_NAME}/removeCustomerVoucher`,
  async ({ customerId }: { customerId: number }, { rejectWithValue, getState }) => {
    const rootState = getState() as StoreStateType

    const { detailPromotion } = rootState.addEditVoucher

    try {
      const { data } = await deleteCustomerVoucher({
        payload: {
          customer_id: customerId,
          voucher_id: detailPromotion?.voucher_id ? detailPromotion.voucher_id : 0,
        },
      })

      toastSuccess('User berhasil di hapus')

      return data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
