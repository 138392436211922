import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import { callErrorMsg } from 'helpers/errorMsg'
import { getLocationsByType } from 'utils/api'

type FilterLocationListsStateType = {
  locationLists: {
    location_id: number
    location_name: string
    location_type: string
  }[]
}

const reducerName = 'filterLocationLists'

const initialState: FilterLocationListsStateType = {
  locationLists: [],
}

const appSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    reset: () => initialState,
    setLocations: (state, action: PayloadAction<FilterLocationListsStateType['locationLists']>) => {
      state.locationLists = action.payload
    },
  },
})

const { actions, reducer } = appSlice

export const { reset, setLocations } = actions

export const initFilterLocations = createAsyncThunk(
  `${reducerName}/initFilter`,
  async (params: { locationType: string; canSelectAll?: boolean }, { dispatch, getState }) => {
    const {
      filterLocationLists: { locationLists },
    } = getState() as StoreStateType
    const { locationType } = params
    try {
      const locationTypeOnLists = locationLists[0]?.location_type || ``
      if (
        !locationLists.length ||
        locationType?.toLowerCase() === undefined ||
        locationTypeOnLists !== locationType?.toLowerCase()
      ) {
        // TODO: Commented due to omitting some function. will uncomment when the backend is ready
        // dispatch(getLocations({ locationType: locationType || `hub`, canSelectAll }))
        const { data } = await getLocationsByType(locationType?.toLowerCase() || `hub`)
        dispatch(setLocations(data))
      }
    } catch (err) {
      callErrorMsg(err)
    }
  },
)

export const getLocations = createAsyncThunk(
  `${reducerName}/getLocations`,
  async (params: { locationType: string; canSelectAll?: boolean }, { dispatch }) => {
    try {
      const { locationType } = params
      const { data } = await getLocationsByType(locationType)

      // TODO: Commented due to omitting some function. will uncomment when the backend is ready
      // const allLocationsObject = {
      //   location_id: 0,
      //   location_name: `ALL ${data?.[0]?.location_type}`,
      //   location_type: data?.[0]?.location_type,
      // }

      // const locationListsReworked = canSelectAll ? [allLocationsObject, ...data] : data

      dispatch(setLocations(data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export default reducer
