import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type {
  GetFpoListResponseType,
  GetFpoListRequestType,
  StatusToBeType,
  ActionListType,
} from 'utils/apiList/freshPurchaseOrder'
import {
  SLICE_NAME,
  fetchFreshPurchaseOrder,
  fetchWarehouseList,
  updateStatusOfFpo,
} from './freshPurchaseOrderThunk'

export type FpoItemType = GetFpoListResponseType['data'][number] & { actionList: ActionListType }

export interface FreshPurchaseOrderInterface {
  isLoading: boolean
  query: GetFpoListRequestType
  freshPurchaseOrderList: FpoItemType[]
  warehouseList: {
    name: string
    value: number
  }[]
  selectedDate: string
  modal: {
    message: string
    isOpen: boolean
    idToCancelOrConfirm: number
    statusToBe: StatusToBeType | ''
  }
}

const initialState: FreshPurchaseOrderInterface = {
  isLoading: true,
  query: {
    pageIndex: 1,
    pageSize: 20,
    date: '',
    fpoNumber: '',
    locationId: 0,
    status: '',
    referenceNumber: '',
  },
  selectedDate: '',
  freshPurchaseOrderList: [],
  warehouseList: [],
  modal: {
    message: '',
    isOpen: false,
    idToCancelOrConfirm: 0,
    statusToBe: '',
  },
}

const freshPurchaseOrderSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setQuery: (
      state,
      action: PayloadAction<{
        fieldName: keyof FreshPurchaseOrderInterface['query']
        value: string | number
      }>,
    ) => {
      const { fieldName, value } = action.payload
      state.query[fieldName] = value as never
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload
    },
    toggleModal: (state, action: PayloadAction<FreshPurchaseOrderInterface['modal']>) => {
      state.modal = action.payload
    },
    resetModal: (state) => {
      state.modal = initialState.modal
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFreshPurchaseOrder.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFreshPurchaseOrder.fulfilled, (state, action) => {
        state.isLoading = false
        state.freshPurchaseOrderList = action.payload
      })
      .addCase(fetchFreshPurchaseOrder.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchWarehouseList.fulfilled, (state, action) => {
        state.warehouseList = action.payload.map((itemData) => ({
          name: itemData.location_name,
          value: itemData.location_id,
        }))
      })
      .addCase(updateStatusOfFpo.fulfilled, (state) => {
        freshPurchaseOrderSlice.caseReducers.resetModal(state)
      })
  },
})
export default freshPurchaseOrderSlice
