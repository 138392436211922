import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getScheduleClose,
  postScheduleClose,
  putScheduleClose,
  deleteScheduleClose,
  type PostScheduleClosePayloadType,
} from 'features/Location/services/globalScheduleClose'
import { getLocationTypeList, getLocationListByType } from 'features/Location/services/location'
import { getCleanQueryObj } from 'utils/queryParamsURL'
import { callErrorMsg } from 'helpers/errorMsg'
import { getCloseMessage, getCloseMessageById } from 'features/Location/services/closeMessageConfig'
import {
  SCHEDULE_CLOSE_SLICE_NAME,
  setIsLoading,
  setLocationTypes,
  setSchedules,
  setLocations,
  setCloseReason,
} from './slice'

export const fetchScheduleClose = createAsyncThunk(
  `${SCHEDULE_CLOSE_SLICE_NAME}/fetchScheduleClose`,
  async (_, { dispatch, getState }) => {
    const {
      scheduleClose: { query },
    } = getState() as StoreStateType

    dispatch(setIsLoading(true))
    try {
      const {
        data: { data },
      } = await getScheduleClose(getCleanQueryObj(query))
      dispatch(setSchedules(data.global_scheduled_close))
    } catch (err) {
      callErrorMsg(err)
    }
    dispatch(setIsLoading(false))
  },
)

export const createScheduleClose = createAsyncThunk(
  `${SCHEDULE_CLOSE_SLICE_NAME}/createScheduleClose`,
  async (payload: PostScheduleClosePayloadType) => {
    try {
      await postScheduleClose(payload)

      return true
    } catch (err) {
      callErrorMsg(err)
      return false
    }
  },
)

export const updateScheduleClose = createAsyncThunk(
  `${SCHEDULE_CLOSE_SLICE_NAME}/updateScheduleClose`,
  async ({ id, payload }: { payload: PostScheduleClosePayloadType; id: string | number }) => {
    try {
      await putScheduleClose(id, payload)

      return true
    } catch (err) {
      callErrorMsg(err)
      return false
    }
  },
)

export const removeScheduleClose = createAsyncThunk(
  `${SCHEDULE_CLOSE_SLICE_NAME}/removeScheduleClose`,
  async (id: number) => {
    try {
      await deleteScheduleClose(id)

      return true
    } catch (err) {
      callErrorMsg(err)
      return false
    }
  },
)

export const fetchLocationType = createAsyncThunk(
  `${SCHEDULE_CLOSE_SLICE_NAME}/fetchLocationType`,
  async (_, { dispatch }) => {
    try {
      const { data } = await getLocationTypeList()

      dispatch(setLocationTypes(data.data))
    } catch (err) {
      callErrorMsg(err)
    }
  },
)

export const fetchLocations = createAsyncThunk(
  `${SCHEDULE_CLOSE_SLICE_NAME}/fetchLocations`,
  async (type: string, { dispatch }) => {
    try {
      const { data } = await getLocationListByType(type)

      dispatch(setLocations(data))
    } catch (err) {
      callErrorMsg(err)
    }
  },
)

export const fetchCloseMessage = createAsyncThunk(
  `${SCHEDULE_CLOSE_SLICE_NAME}/fetchCloseMessage`,
  async (search: string, { dispatch }) => {
    try {
      const {
        data: { data },
      } = await getCloseMessage({ page: 0, limit: 20, search })
      dispatch(setCloseReason(data.close_reason))
    } catch (err) {
      callErrorMsg(err)
    }
  },
)

export const fetchCloseMessageDetail = createAsyncThunk(
  `${SCHEDULE_CLOSE_SLICE_NAME}/fetchCloseMessageDetail`,
  async (id: number) => {
    try {
      const {
        data: { data },
      } = await getCloseMessageById(id)
      return data
    } catch (err) {
      callErrorMsg(err)
      return null
    }
  },
)
