import { Get, Post, Put } from 'config/apiServiceConfig'

export type GetDirectPutawayResponseType = {
  data: {
    id: number
    inbound_date: number
    status: string
    location: {
      location_id: number
      location_name: string
      location_type: string
    }
    package_id: {
      id: number
      package_label: string
      created_at: null
    }
    product_expiry_date: number
    product: {
      id: number
      product_sku_no: string
      product_name: string
      uom: string
      primary_category: {
        id: number
        name: string
      }
    }
    rack: {
      rack_id: number
      rack_name: string
      rack_position: null | number
    } | null
    staff: {
      id: number
      full_name: string
      phone_number: string
    } | null
    quantity: number
    start_at: number | null
    end_at: number | null
    action: string[]
    created_at: number
    created_by: string
    updated_at: string | null
    updated_by: string | null
    device: string
  }[]
  pagination: {
    sort: string
    direction: string
    page_size: number
    page_index: number
    number_of_elements: number
  }
  error: {
    status: boolean
    message: string
    code: number
  }
}

export type GetDirectPutawayRequestType = {
  params: {
    page_index?: number
    page_size?: number
    sort?: string
    location_ids?: string
    sku_number?: string
    package_label?: string
    product_category_id?: number
    status?: string
    inbound_date?: string
  }
}

export type GetEligibleRacksResponseType = {
  data: {
    active: boolean
    id: number
    rackName: string
  }[]
}

export type GetStaffResponseType = {
  data: {
    staffId: number
    staffFullName: string
    staffPhoneNumber: string
  }
}

export type UpdatePutAwayPayloadType = {
  staff_id: number
  rack_id?: number
}

export type PostValidatePoCompleteResponseType = {
  data: true | null
  error: {
    status: false
    message: ''
    code: 0
  }
  message: ''
}

export const getDirectPutaway = (params: GetDirectPutawayRequestType['params']) =>
  Get<GetDirectPutawayResponseType>({
    url: '/api/wms/:version/putaway-task',
    version: 'v1',
    params,
  })

export const completePutAway = (putawayTaskID: number, data: UpdatePutAwayPayloadType) =>
  Put({
    url: `/api/wms/:version/putaway-task/${putawayTaskID}/complete`,
    version: 'v1',
    data,
  })

export const updatePutAway = (putawayTaskID: number, data: UpdatePutAwayPayloadType) =>
  Put({
    url: `/api/wms/:version/putaway-task/${putawayTaskID}`,
    version: 'v1',
    data,
  })

export const resetPutaway = (putawayTaskID: number) =>
  Put({
    url: `/api/wms/:version/putaway-task/${putawayTaskID}/reset`,
    version: 'v1',
  })

export const getEligibleRacks = ({
  locationId = 0,
  productId = 0,
  search = '',
}: {
  locationId: number
  productId: number
  search?: string
}) =>
  Get<GetEligibleRacksResponseType>({
    url: `/api/ims/:version/eligible-racks?locationId=${locationId}&statusId=1&productId=${productId}&search=${search}`,
    version: 'v2',
  })

export const getStaffByPhone = (phone_number: string) =>
  Post<GetStaffResponseType>({
    url: 'api/staff-management/staff/phone',
    version: 'v2',
    data: {
      phone_number,
    },
  })

export const validatePoComplete = (package_id: number) =>
  Post<PostValidatePoCompleteResponseType>({
    url: '/api/wms/v2/purchase-order/validate-complete',
    data: {
      package_id,
    },
  })
