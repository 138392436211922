import { createAsyncThunk } from '@reduxjs/toolkit'
import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'
import {
  getReplenishmentTaskDetailAPI,
  getReplenishmentTaskItemAPI,
} from 'features/SupplyOrder/services/replenishment'
import { callErrorMsg } from 'helpers/errorMsg'

export const SLICE_NAME = 'REPLENISHMENT_TASK_DETAIL'

export const fetchGetReplenishmentTaskDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchGetReplenishmentTaskDetail`,
  async (params: Parameters<typeof getReplenishmentTaskDetailAPI>[0], { rejectWithValue }) => {
    try {
      const response = await getReplenishmentTaskDetailAPI(params)

      return response.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchGetReplenishmentTaskItem = createAsyncThunk(
  `${SLICE_NAME}/fetchGetReplenishmentTaskItem`,
  async (params: Parameters<typeof getReplenishmentTaskItemAPI>[0], { rejectWithValue }) => {
    try {
      const response = await getReplenishmentTaskItemAPI(params)

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)
