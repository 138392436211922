import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  type BlackAndWhiteListCustomerType,
  type BlackAndWhiteListDeviceType,
} from 'utils/apiList/blackAndWhiteList'
import {
  SLICE_NAME,
  addSingleBlacklistCustomer,
  addSingleBlacklistDevice,
  addBulkBlacklistCustomer,
  addBulkBlacklistDevice,
  addSingleWhitelistCustomer,
  addSingleWhitelistDevice,
  addBulkWhitelistCustomer,
  addBulkWhitelistDevice,
  fetchBlacklistCustomer,
  fetchBlacklistDevice,
  fetchWhitelistCustomer,
  fetchWhitelistDevice,
  RestrictionByEnum,
  type RestrictionByOptionType,
} from './blackAndWhiteListThunk'

export type FlagUserAndDeviceType = BlackAndWhiteListCustomerType & BlackAndWhiteListDeviceType

export const RESTRICTION_BY_OPTIONS: RestrictionByOptionType[] = [
  {
    name: 'User ID',
    value: RestrictionByEnum.user,
  },
  {
    name: 'Device ID',
    value: RestrictionByEnum.device,
  },
]

export type BlackAndWhiteListStateType = {
  isLoading: boolean
  query: {
    search?: string
    restrictionBy?: RestrictionByEnum
    fromDate?: string
    toDate?: string
    pageIndex?: number
  }
  flagUserAndDeviceList: FlagUserAndDeviceType[]
  pagination: {
    pageIndex: number
    pageSize: number
    sort?: 'createdAt' | 'status'
    direction?: 'ASC' | 'DESC'
    totalElement: number
  }
  needToReload: number
}

const initialState: BlackAndWhiteListStateType = {
  isLoading: false,
  query: {
    search: '',
    restrictionBy: RestrictionByEnum.user,
    fromDate: '',
    toDate: '',
    pageIndex: 0,
  },
  flagUserAndDeviceList: [],
  pagination: {
    pageIndex: 0,
    pageSize: 15,
    sort: 'createdAt',
    direction: 'DESC',
    totalElement: 0,
  },
  needToReload: 0,
}

const blackAndWhiteList = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setFlagUserAndDeviceList: (state, action: PayloadAction<FlagUserAndDeviceType[]>) => {
      state.flagUserAndDeviceList = action.payload
    },
    setQuery: (
      state,
      action: PayloadAction<BlackAndWhiteListStateType['query'] & { needToReload?: boolean }>,
    ) => {
      const { needToReload, ...rest } = action.payload

      state.query = {
        ...state.query,
        ...rest,
      }

      if (needToReload === undefined || needToReload) {
        state.needToReload += 1
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // == blacklsit == //
      .addCase(addSingleBlacklistCustomer.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addSingleBlacklistCustomer.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(addSingleBlacklistCustomer.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(addSingleBlacklistDevice.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addSingleBlacklistDevice.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(addSingleBlacklistDevice.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(addBulkBlacklistCustomer.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addBulkBlacklistCustomer.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(addBulkBlacklistCustomer.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(addBulkBlacklistDevice.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addBulkBlacklistDevice.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(addBulkBlacklistDevice.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchBlacklistCustomer.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchBlacklistCustomer.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchBlacklistCustomer.fulfilled, (state, action) => {
        const { payload } = action
        state.flagUserAndDeviceList = payload.data?.blacklistCustomer || []

        const { pagination } = payload

        state.pagination = pagination
        state.isLoading = false
      })
      .addCase(fetchBlacklistDevice.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchBlacklistDevice.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchBlacklistDevice.fulfilled, (state, action) => {
        const { payload } = action
        state.flagUserAndDeviceList = payload.data?.blacklistDevice || []

        const { pagination } = payload

        state.pagination = pagination
        state.isLoading = false
      })
      // == whitelist == //
      .addCase(addSingleWhitelistCustomer.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addSingleWhitelistCustomer.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(addSingleWhitelistCustomer.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(addSingleWhitelistDevice.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addSingleWhitelistDevice.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(addSingleWhitelistDevice.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(addBulkWhitelistCustomer.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addBulkWhitelistCustomer.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(addBulkWhitelistCustomer.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(addBulkWhitelistDevice.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addBulkWhitelistDevice.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(addBulkWhitelistDevice.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchWhitelistCustomer.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchWhitelistCustomer.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchWhitelistCustomer.fulfilled, (state, action) => {
        const { payload } = action
        state.flagUserAndDeviceList = payload.data?.whitelistCustomer || []

        const { pagination } = payload

        state.pagination = pagination
        state.isLoading = false
      })
      .addCase(fetchWhitelistDevice.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchWhitelistDevice.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchWhitelistDevice.fulfilled, (state, action) => {
        const { payload } = action
        state.flagUserAndDeviceList = payload.data?.whitelistDevice || []

        const { pagination } = payload

        state.pagination = pagination
        state.isLoading = false
      })
  },
})

export const { reset, setFlagUserAndDeviceList, setQuery } = blackAndWhiteList.actions
export default blackAndWhiteList.reducer
