import { memo } from "react";
import styled from "styled-components";
import { SystemIcon, NoData, RowProductDiscount, Checkbox } from "components";

function TableProductDiscount({ data }) {
  return (
    <>
      <Container>
        <thead>
          <Tr>
            <Th>
              <WrapperCheck>
                <Checkbox />
              </WrapperCheck>
            </Th>
            <Th>
              Nama Hub
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th>
              Nama Produk
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th>
              Harga
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th>Stock</Th>
            <Th>Harga Discount</Th>
            <Th>Diskon</Th>
            <Th>Stock Discount</Th>
            <Th>Tanggal Mulai</Th>
            <Th>Tanggal Berakhir</Th>
            <Th>Qty/Hari</Th>
          </Tr>
        </thead>
        <tbody>
          {data.map((el, idx) => (
            <RowProductDiscount key={idx} />
          ))}
        </tbody>
      </Container>
      {data.length === 0 && <NoData withAstroIcon />}
    </>
  );
}

TableProductDiscount.defaultProps = {
  data: []
};

export default memo(TableProductDiscount);

const Container = styled.table`
  width: 100%;
  border-collapse: collapse;
  height: fit-content;
`;
const Tr = styled.tr`
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`;
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  text-align: start;
  padding: 16px;
  padding-right: 20px;
`;

const WrapperCheck = styled.div`
  display: grid;
  place-items: center;
`;
