import { Box, TextFieldBase, Icon } from '@astro-ui/components'

export type SearchFieldPropsType = {
  search: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  searchOnEnd?: boolean
}

const SearchField = ({
  onChange,
  search,
  placeholder = 'Search',
  searchOnEnd = true,
}: SearchFieldPropsType) => (
  <Box
    position="sticky"
    width="100%"
    p="12px 16px"
    mt="-8px"
    top="0px"
    zIndex={1}
    bg="white"
    borderBottom="1px solid #eeeeee"
  >
    <TextFieldBase
      fullWidth
      placeholder={placeholder}
      value={search}
      onChange={onChange}
      onKeyDown={(e) => e.stopPropagation()}
      InputProps={{
        endAdornment: searchOnEnd ? <Icon icon="search" color="grey4" /> : null,
        startAdornment: !searchOnEnd ? <Icon icon="search" color="grey4" /> : null,
      }}
      autoFocus
    />
  </Box>
)

export default SearchField
