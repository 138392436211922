/* eslint-disable no-undef */
import axios from 'axios'
import printJS from 'print-js'
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'
import { queryParamsURL } from 'utils/queryParamsURL'

export const numberOnly = (value) => {
  return value.replace(/[^0-9]/g, '')
}

export const numberOnlyCannotStartFromZero = (value) => {
  const numberOnlyValue = numberOnly(value)
  const numberNotStartFromZeroValue = numberOnlyValue.replace(/^[^1-9][^0-9]*/g, '')

  return numberNotStartFromZeroValue
}

export const numberDecimalOnly = (value) => {
  return value.replace(/[^\d.]/g, '').replace(/(\..*)\./g, '$1')
}

export const numberDecimalOnlyComa = (value) => {
  // eslint-disable-next-line no-useless-escape
  return value.replace(/[^\d,]+/g, '').replace(/(\,.*)\,/g, '$1')
}

export const formatToDropDownList = (arrOptions, keyLabel) => {
  const result = []
  arrOptions.forEach((el) => {
    result.push({
      ...el,
      name: el[keyLabel],
    })
  })
  return result
}

export const filterMethod = (data, query, field = 'name') => {
  return data.filter((item) => {
    return query
      .toLowerCase()
      .split(' ')
      .every((v) => item[field].toLowerCase().includes(v))
  })
}

export const transformQuerytoObj = (query) => {
  if (!query) return {}
  const search = query.split('?')[1]
  const listQuery = search.split('&')
  let queryObj = {}
  listQuery.forEach((el) => {
    if (el.split('=')[1]) {
      const objKey = el.split('=')[0]
      const objValue = el.split('=')[1]
      queryObj[objKey] = objValue.split('%20').join(' ')
    }
  })
  return queryObj
}
export const isValidHttpUrl = (string) => {
  let url
  try {
    url = new URL(string)
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export const getBase64Image = async (url) => {
  const data = await fetch(url)
  const blob = await data.blob()
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      const base64data = reader.result
      resolve(base64data)
    }
  })
}

export const transformObjtoQuery = (obj) => {
  let result = '?'
  for (const proper in obj) {
    if (result === '?') {
      result += `${proper}=${obj[proper]}`
    } else {
      result += `&${proper}=${obj[proper]}`
    }
  }
  return result
}

export function formatMoneyRMG(amount = '0', currency = 'Rp') {
  if (!amount) return `${currency} -`
  if (amount === -1) return `${currency} ****`
  const symbol = currency
  const numberString = amount !== null ? amount.toString() : '0'
  const split = numberString.split('.')
  let decimal = split[1]
  const formatted = split[0].replace(/(\d)(?=(\d{3})+$)/g, '$&.')

  if (decimal === undefined) {
    decimal = '00'
  } else if (decimal[1] === undefined) {
    decimal = `${decimal}`
  }
  return `${symbol} ${formatted}${decimal !== '00' ? `,${decimal}` : ''}`
}

export function formatMoneyComa(amount = '0', currency = '') {
  const symbol = currency
  const numberString = amount !== null ? amount.toString() : '0'
  const split = numberString.split('.')
  let decimal = split[1]
  const formatted = split[0].replace(/(\d)(?=(\d{3})+$)/g, '$&,')

  if (decimal === undefined) {
    decimal = '00'
  } else if (decimal[1] === undefined) {
    decimal = `${decimal}0`
  }
  return `${symbol} ${formatted}`
}

export const mergeCategory = (payload, allChilds, field = 'category_childs') => {
  if (!payload.category_name) {
    return ''
  }
  let result = payload.category_name
  payload[field].forEach((el) => {
    if (el.checked || allChilds) {
      result += ` - ${el.category_name}`
    }
  })
  return result
}

export const selectedCategoryFormat = (payload) => {
  const result = []
  payload.forEach((el) => {
    const category = {
      ...el,
      childs: [],
      checked: true,
    }
    el.category_childs.forEach((elc) => {
      category.childs.push({ ...elc, checked: true })
    })
    result.push(category)
  })
  return result
}

export const categoryMasterFormat = (masterData, defaultData) => {
  const result = []
  const idParent = [...defaultData].map((el) => el.category_id)
  masterData.forEach((el) => {
    if (idParent.includes(el.category_id)) {
      const indexParent = idParent.indexOf(el.category_id)
      const idChilds = [...defaultData][indexParent].category_childs.map((el) => el.category_id)
      if (idChilds.length > 0) {
        const subCategory = {
          ...el,
          checked: true,
          childs: [],
        }
        el.childs.forEach((elc) => {
          if (idChilds.includes(elc.category_id)) {
            subCategory.childs.push({ ...elc, checked: true })
          } else {
            subCategory.childs.push(elc)
          }
        })
        result.push({ ...subCategory })
      } else {
        result.push({ ...el, checked: true })
      }
    } else {
      result.push(el)
    }
  })
  return result
}

export const checkCategoryFormat = (pos, data, child, filterCategories, isSingle) => {
  const categoriesUpdated = []
  const selected = []
  let singleSelected = {}
  filterCategories.forEach((ctg) => {
    if (data.category_id === ctg.category_id) {
      // untuk yg tidak pny childs
      if (pos === 'parent' && !ctg.childs.length) {
        if (!ctg.checked) {
          singleSelected = { ...ctg, checked: !ctg.checked }
          selected.push({ ...ctg, checked: !ctg.checked })
        }
        categoriesUpdated.push({ ...ctg, checked: !ctg.checked })
      } else if (pos === 'child') {
        const newCtg = { ...ctg, childs: [] }
        let listChcked = 0
        ctg.childs.forEach((el) => {
          if (el.checked) {
            listChcked += 1
          }
          if (el.category_id === child.category_id) {
            if (el.checked) {
              listChcked -= 1
            } else {
              listChcked += 1
            }
            newCtg.childs.push({ ...el, checked: !el.checked })
          } else {
            if (isSingle) {
              return newCtg.childs.push({ ...el, checked: false })
            }
            newCtg.childs.push(el)
          }
        })
        if (listChcked > 0) {
          if (isSingle) {
            singleSelected = { ...newCtg, checked: listChcked > 0 }
          }
          selected.push({ ...newCtg, checked: listChcked > 0 })
        }
        categoriesUpdated.push({ ...newCtg, checked: listChcked > 0 })
      } else {
        const newCtgParent = { ...ctg, checked: !ctg.checked, childs: [] }
        ctg.childs.forEach((el) => {
          newCtgParent.childs.push({ ...el, checked: !ctg.checked })
        })

        if (!ctg.checked) {
          selected.push(newCtgParent)
        }

        categoriesUpdated.push(newCtgParent)
      }
    } else {
      if (ctg.checked && !isSingle) {
        selected.push(ctg)
      }
      if (isSingle) {
        categoriesUpdated.push({
          ...ctg,
          checked: false,
          childs: ctg.childs.map((el) => {
            return { ...el, checked: false }
          }),
        })
      } else {
        categoriesUpdated.push(ctg)
      }
    }
  })
  return {
    categoriesUpdated,
    selected,
    singleSelected,
  }
}

export const mappingColorTag = (color) => {
  let result = {}
  switch (color) {
    case 'yellow':
      result = {
        color: 'orange',
        bg: 'yellow',
      }
      break
    case 'red':
      result = {
        color: 'pink',
        bg: 'pinkFaded',
      }
      break
    case 'green':
      result = {
        color: 'grass',
        bg: 'grassFaded',
      }
      break
    case 'grey':
      result = {
        color: 'grey',
        bg: 'body',
      }
      break
    case 'blueTag':
      result = {
        color: 'white',
        bg: 'blueTag',
      }
      break
    case 'redTag':
      result = {
        color: 'white',
        bg: 'redTag',
      }
      break
    case 'greenTag':
      result = {
        color: 'white',
        bg: 'grass',
      }
      break
    case 'gray':
      result = {
        color: '#6D7588',
        bg: 'body',
      }
      break
    case 'blueSoft':
      result = {
        color: 'blueSoft',
        bg: 'blueTosca',
      }
      break
    default:
      break
  }
  return result
}

export function downloadURI(uri, name, cb = () => {}) {
  // const link = document.createElement("a");
  // link.download = name;
  // link.href = uri;
  // link.target = "_blank";
  // link.setAttribute("download", name);
  // document.body.appendChild(link);
  // // link.click();
  // // document.body.removeChild(link);
  axios
    .request({
      url: uri,
      method: 'GET',
      responseType: 'blob', //important
    })
    .then(({ data }) => {
      const downloadUrl = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', `${name}.pdf`) //any other extension
      document.body.appendChild(link)
      link.click()
      link.remove()
      cb(true)
    })
    .catch(() => {
      cb(false)
    })
}

export const formatTimeCountDownMaxHour = (second) => {
  const hours = Math.floor(second / (60 * 60))
  const mins = Math.floor(second / 60)
  const secs = Math.floor(second)
  const parsedMins = mins - hours * 60
  const parsedSec = secs - mins * 60
  const parsedOneDigit = (parsed) => (Math.abs(parsed) < 10 ? `0${parsed}` : parsed)
  const displayedHours = parsedOneDigit(hours)
  const displayedMins = parsedOneDigit(parsedMins)
  const displayedSec = parsedOneDigit(parsedSec)

  return `${displayedHours} : ${displayedMins} : ${displayedSec}`
}

export const formatTime = (timer) => {
  let minutes = parseInt(timer / 60, 10)
  let seconds = parseInt(timer % 60, 10)

  minutes = minutes < 10 ? `0${minutes}` : minutes
  seconds = seconds < 10 ? `0${seconds}` : seconds

  return `${minutes}:${seconds}`
}

export const printPdfLink = (url) => {
  printJS(url)
}

export const removeZeroOnFirst = (data) => {
  if (!data) return ''
  const value = String(data)?.split('')
  if (String(value[0]) !== '0') {
    return data
  } else {
    return value.slice(1, value.length).join('')
  }
}

export const allowZeroOnFirst = (data) => {
  if (!data) return ''
  const value = String(data)?.split('')
  if (String(value[0]) !== '0') {
    return data
  } else {
    return value.length > 1 ? value.slice(1, 2) : value.join('')
  }
}

export const indoTime = (time, format) => {
  const extend = dayjs.extend(updateLocale)
  extend.updateLocale('en', {
    weekdays: ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu'],
  })
  return extend(time).locale('en').format(format)
}

export const formatDateRange = (dateData, location, [requestFrom, requestTo]) => {
  const { endDate, startDate } = dateData
  const startStamp = new Date(startDate).getTime()
  const endStamp = new Date(endDate).getTime()
  const query = transformQuerytoObj(location)
  query[requestFrom] = startStamp
  query[requestTo] = endStamp
  const newQuery = transformObjtoQuery(query)
  const formatStart = dayjs(startDate).format('DD MMM')
  const formatEnd = dayjs(endDate).format('DD MMM')
  return {
    newQuery,
    valueFormat: `${formatStart} - ${formatEnd}`,
  }
}

export const formatDateRangeInit = (requestFrom, requestTo) => {
  const formatStart = dayjs(requestFrom).format('DD MMM')
  const formatEnd = dayjs(requestTo).format('DD MMM')
  return `${formatStart} - ${formatEnd}`
}

export const capitalizeEachWord = (word, splitBy) => {
  const splittedText = word.split(splitBy)
  const newList = splittedText.map((text) => capitalizeFirstLetter(text.toLowerCase()))
  return newList.join(' ')
}

export const capitalizeFirstLetter = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const mapStatus = (stat) => {
  const capitalize = capitalizeFirstLetter(stat.toLowerCase())
  switch (capitalize) {
    case 'Confirm':
      return 'blueSoft'
    case 'Complete':
      return 'green'
    case 'Canceled':
      return 'red'
    case 'Edit':
      return 'yellow'
    default:
      break
  }
}

export const sumArrayofObject = (arr, key) => {
  return arr.reduce((acc, el) => {
    return acc + el[key]
  }, 0)
}

export const filterCategoryArr = (arr, selcted, category) => {
  const selecredParentId = selcted.map((el) => el?.selected?.category_id)
  const filtered = arr.filter((el) => {
    if (!selecredParentId.includes(el.category_id) || category.category_id === el.category_id) {
      return true
    } else {
      return false
    }
  })
  return filtered
}

export const formatEditProductErp = (categories, selected) => {
  const result = []
  const arrIdChilds = selected.category_childs.map((el) => el.category_id)
  categories.forEach((el) => {
    if (el.category_id === selected.category_id) {
      const updated = {
        ...el,
        checked: true,
        childs: el.childs.map((elc) => {
          return {
            ...elc,
            checked: arrIdChilds.includes(elc.category_id),
          }
        }),
      }
      result.push(updated)
    } else {
      result.push(el)
    }
  })
  return result
}

export const validateEmail = (email) => {
  return email.match(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )
}

export const transformArrayToUrlPath = (pathArray) => {
  let newPathname = ''
  let i
  for (i = 0; i < pathArray.length; i++) {
    newPathname += '/'
    newPathname += pathArray[i]
  }
  return newPathname
}

export const inputNumber = (num) => {
  const str = numberOnly(String(num))
  return +removeZeroOnFirst(str)
}

export const compose = (...funcs) => {
  if (funcs.length === 0) return (arg) => arg
  if (funcs.length === 1) return funcs[0]
  return funcs.reduce(
    (a, b) =>
      (...args) =>
        a(b(...args)),
  )
}

export const getChangePageIndex = (type) => {
  const { pageIndex } = queryParamsURL.get()
  const index = type === 'next' ? Number(pageIndex) + 1 : Number(pageIndex) - 1
  return index
}

export const rupiahFormat = (price) => {
  const currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  return currency.format(price)
}

export const inputNumberAllowMinus = (val) => {
  if (val.charAt(0) === '-') {
    const convert = inputNumber(val)

    return +`-${convert}`
  } else if (val === '0-') {
    return -1
  } else {
    return inputNumber(val)
  }
}

export const filterListOptions = (options = [], next_action) => {
  if (options.length && next_action.length)
    return options.filter(({ id, name }) => next_action.includes(name))
  if (!next_action.length) return next_action
  return options
}

export const validatePhone = (phone) => {
  return phone.match(
    // eslint-disable-next-line no-useless-escape
    /^(0|\+[1-9][0-9]*)[0-9]*$/,
  )
}

export const validatePhoneStartWithZero = (phone) => {
  const regex = /^0/
  return !regex.test(phone) && phone.length > 8 && phone.length < 16
}
