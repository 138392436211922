import { createAsyncThunk } from '@reduxjs/toolkit'

import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'

import { callErrorMsg } from 'helpers/errorMsg'

import { toastSuccess, toastFailed } from 'utils/toast'

import {
  getAssetMasterDetailInventoryAssetAPI,
  postUpdateAssetAPI,
} from 'features/AssetManagement/services/assetMaster'
import { getProductAssetByIDAPI } from 'features/AssetManagement/services/productList'

import { AssetMasterInventoryAssetUpdatePayloadInterface } from 'features/AssetManagement/@types/typeAssetMaster'

import {
  setIsLoading,
  setAssetMasterDetailGeneralData,
  setAssetMasterInventoryList,
  setHasNext,
} from './slice'

const SLICE_NAME = 'assetMasterDetail'

export const getAssetMasterDetail = createAsyncThunk(
  `${SLICE_NAME}/getAssetMasterDetail`,
  async (id: number, { rejectWithValue, dispatch }) => {
    dispatch(setIsLoading(true))
    try {
      const res = await getProductAssetByIDAPI(id)

      const resData = res.data

      const categoryString = resData.asset_categories.map((item) => item.category_name).join(', ')

      const generalData = {
        name: resData.name,
        category: categoryString,
        klassifikasi: resData.classification.name || ``,
        sku: resData.sku_number,
        depreciation: `${resData.classification.depreciation_period || '-'} Bulan`,
        type: resData.type === 'fixed' ? 'Fixed Asset' : 'Moveable Asset',
        uom: resData.uom,
        po_number: resData,
      }

      dispatch(setAssetMasterDetailGeneralData(generalData))

      return true
    } catch (error) {
      callErrorMsg(error)
      dispatch(setIsLoading(false))
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const getAssetMasterDetailInventory = createAsyncThunk(
  `${SLICE_NAME}/getAssetMasterDetailInventory`,
  async (id: number, { rejectWithValue, getState, dispatch }) => {
    dispatch(setIsLoading(true))
    const {
      assetMasterDetail: { assetMasterInventoryParam },
    } = getState() as StoreStateType
    try {
      const res = await getAssetMasterDetailInventoryAssetAPI({
        asset_id: id,
        ...assetMasterInventoryParam,
      })

      const resData = res.data.data

      dispatch(setAssetMasterInventoryList(resData))
      dispatch(setHasNext(res.data.has_next))

      dispatch(setIsLoading(false))
      return true
    } catch (error) {
      callErrorMsg(error)
      dispatch(setIsLoading(false))
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const updateAssetInventory = createAsyncThunk(
  `${SLICE_NAME}/updateAssetInventory`,
  async (
    params: { data: AssetMasterInventoryAssetUpdatePayloadInterface; id: number },
    { rejectWithValue, dispatch },
  ) => {
    dispatch(setIsLoading(true))
    try {
      const res = await postUpdateAssetAPI(params.data)

      if (res.data.error.status) {
        toastFailed(res.data.error.message)
        dispatch(setIsLoading(false))
        return rejectWithValue(SOMETHING_WHEN_WRONG)
      }
      toastSuccess('Berhasil mengubah data asset')
      dispatch(getAssetMasterDetailInventory(Number(params.id)))
      dispatch(setIsLoading(false))
      return true
    } catch (error) {
      callErrorMsg(error)
      dispatch(setIsLoading(false))
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)
