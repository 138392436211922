import { memo } from "react";
import styled from "styled-components";
import { RowSupplyOrder, SystemIcon, NoData } from "components";

function TableSupplyOrder({
  data,
  handlePrint,
  handleCheckQty,
  handleEdit,
  handlePrintPickingList,
  handlePrintSuratJalan
}) {
  return (
    <>
      <Container>
        <thead>
          <Tr>
            <Th ratio="3">
              NOMOR SO
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th ratio="3">
              CREATED TIME
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th ratio="2">
              ASAL
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th ratio="2">
              TUJUAN
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th ratio="2">CREATED BY</Th>
            <Th ratio="2">
              STATUS
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th ratio="2">AKSI</Th>
          </Tr>
        </thead>
        <tbody>
          {data.map((el) => (
            <RowSupplyOrder
              data={el}
              key={el.supply_order_id}
              handlePrint={handlePrint}
              handleCheckQty={handleCheckQty}
              handleEdit={handleEdit}
              handlePrintPickingList={handlePrintPickingList}
              handlePrintSuratJalan={handlePrintSuratJalan}
            />
          ))}
        </tbody>
      </Container>
      {data.length === 0 && <NoData />}
    </>
  );
}

export default memo(TableSupplyOrder);

const Container = styled.table`
  width: 100%;
`;
const Tr = styled.tr`
  display: flex;
  padding: 16px 32px;
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`;
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`;
