import axios from 'axios'
const { REACT_APP_BASE_URL_API } = process.env
const baseUrl = REACT_APP_BASE_URL_API

export const getExternalDriverRates = ({ orderID }, params) =>
  axios.get(`${baseUrl}/api/order/${orderID}/external-driver/rates`, { params })

export const postExternalDriver = (payload, params) =>
  axios.post(`${baseUrl}/api/order/${payload.orderID}/external-driver/assign`, payload, { params })

export const postExternalDriverV2 = (payload, params) =>
  axios.post(`${baseUrl}/api/v2/order/${payload.orderID}/external-driver/assign`, payload, {
    params,
  })

export const cancelExternalDriver = (payload) =>
  axios.put(`${baseUrl}/api/order/${payload.orderID}/external-driver/cancel`, payload)

export const cancelExternalDriverV2 = (payload) =>
  axios.put(`${baseUrl}/api/v2/order/${payload.orderID}/external-driver/cancel`, payload)

export const getDriverHistory = (payload) =>
  axios.get(`${baseUrl}/api/order/${payload.orderID}/external-driver`, payload)

export const putContactCustomer = (payload) =>
  axios.put(`${baseUrl}/api/customer-support/orders/${payload}/contact-customer`)

export const putContinueOrder = (order_id) =>
  axios.put(`${baseUrl}/api/order/${order_id}/approve-partial-fulfill`)
