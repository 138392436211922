import { ThemeOptions } from '@mui/material'

import colors from '../fondations/colors'
import fonts from '../fondations/fonts'

export const variant = {
  h0: {
    fontWeight: 800,
    fontSize: '28px',
    lineHeight: '42px',
    letterSpacing: '-0.3px',
  },
  h1: {
    fontWeight: 800,
    fontSize: '24px',
    lineHeight: '36px',
    letterSpacing: '-0.3px',
  },
  h2: {
    fontWeight: 800,
    fontSize: '20px',
    lineHeight: '30px',
    letterSpacing: '-0.1px',
  },
  h3: {
    fontWeight: 800,
    fontSize: '18px',
    lineHeight: '27px',
  },
  h4: {
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
  },
  h5: {
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '21px',
  },
  h6: {
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '18px',
  },
  body1: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },
  body2: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
  },
  body3: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
  },
  smallRegular: {
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '15px',
  },
  smallBold: {
    fontWeight: 700,
    fontSize: '10px',
    lineHeight: '15px',
  },
}

export const MuiTypographyCustom: ThemeOptions['components'] = {
  MuiTypography: {
    defaultProps: {
      variant: 'body1',
      color: colors.grey8,
    },
    styleOverrides: {
      root: {
        fontFamily: fonts.fontFamily.nunitoSans,
      },
    },
    variants: [
      {
        props: { variant: 'h0' },
        style: variant.h0,
      },
      {
        props: { variant: 'h1' },
        style: variant.h1,
      },
      {
        props: { variant: 'h2' },
        style: variant.h2,
      },
      {
        props: { variant: 'h3' },
        style: variant.h3,
      },
      {
        props: { variant: 'h4' },
        style: variant.h4,
      },
      {
        props: { variant: 'h5' },
        style: variant.h5,
      },
      {
        props: { variant: 'h6' },
        style: variant.h6,
      },
      {
        props: { variant: 'body1' },
        style: variant.body1,
      },
      {
        props: { variant: 'body2' },
        style: variant.body2,
      },
      {
        props: { variant: 'body3' },
        style: variant.body3,
      },
      {
        props: { variant: 'smallRegular' },
        style: variant.smallRegular,
      },
      {
        props: { variant: 'smallBold' },
        style: variant.smallBold,
      },
    ],
  },
}
