import { createAsyncThunk } from '@reduxjs/toolkit'
import { deleteFlashSale, getFlashSaleList } from 'features/Promotion/services/flashSale'
import { callErrorMsg } from 'helpers/errorMsg'
import { getCleanQueryObj } from 'utils/queryParamsURL'
import { SLICE_NAME, setIsLoading, setFlashSaleList, setPagination } from './slice'

export const fetchFlashSale = createAsyncThunk(
  `${SLICE_NAME}/fetchFlashSale`,
  async (_, { dispatch, getState }) => {
    const {
      flashSale: { query },
    } = getState() as StoreStateType

    dispatch(setIsLoading(true))

    try {
      const { data } = await getFlashSaleList(getCleanQueryObj(query))
      dispatch(setFlashSaleList(data.data))
      dispatch(setPagination(data.pagination))
    } catch (error) {
      callErrorMsg(error)
    }

    dispatch(setIsLoading(false))
  },
)

export const removeFlashSale = createAsyncThunk(
  `${SLICE_NAME}/removeFlashSale`,
  async (flashSaleId: number) => {
    try {
      await deleteFlashSale(flashSaleId)
      return true
    } catch (error) {
      callErrorMsg(error)
      return false
    }
  },
)
