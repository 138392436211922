import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PackingRecordingInitialStateType } from 'features/SupplyOrder/@types/PackingRecording'

const initialState: PackingRecordingInitialStateType = {
  isLoading: false,
  packingRecordingOnCheckQty: {
    needToGetStatusRecording: false,
    isUsingCameraLaptop: false,
    isLoadingHitApiRecording: false,
    isRecordingStarted: false,
    expectedRecordingToStart: false,
    userAbleToStopRecording: false,
    koliId: 0,
    koliNumber: '',
    soId: 0,
    isFeatureFlagActiveOnLocation: false,
    isPackingRecordingEnabled: false,
    isGetRecordingStatusHaveIssues: false,
    countingToDetermineCameraIssues: 0,
    startTimeRecordingStarted: 0,
    ipAddress: '',
    packingLineNumber: 0,
  },
  modalConfirmation: {
    isOpen: false,
    type: '',
    title: '',
    description: '',
    data: {
      id: '',
      detailId: 0,
    },
  },
  modalLeavePageConfirmation: {
    isOpen: false,
    onOk: false,
    path: '',
  },
  modalVideoPackingFootage: {
    isOpen: false,
    videoUrl: '',
  },
  modalScanIpAddress: {
    isOpen: false,
  },
  inputPackingLineNumber: '',
}
const packingRecordingSlice = createSlice({
  name: 'packingRecording',
  initialState,
  reducers: {
    setPackingRecordingOnCheckQty: (
      state,
      action: PayloadAction<
        Partial<PackingRecordingInitialStateType['packingRecordingOnCheckQty']>
      >,
    ) => {
      state.packingRecordingOnCheckQty = {
        ...state.packingRecordingOnCheckQty,
        ...action.payload,
      }
    },
    resetPackingRecordingOnCheckQty: (state) => {
      state.packingRecordingOnCheckQty = initialState.packingRecordingOnCheckQty
    },
    setModalLeavePageConfirmation: (state, action) => {
      state.modalLeavePageConfirmation = {
        ...state.modalLeavePageConfirmation,
        ...action.payload,
      }
    },
    resetModalLeavePageConfirmation: (state) => {
      state.modalLeavePageConfirmation = initialState.modalLeavePageConfirmation
    },
    setModalConfirmation: (state, action) => {
      state.modalConfirmation = { ...state.modalConfirmation, ...action.payload }
    },
    resetModalConfirmation: (state) => {
      state.modalConfirmation = initialState.modalConfirmation
    },
    setModalVideoPackingFootage: (state, action) => {
      state.modalVideoPackingFootage = {
        ...state.modalVideoPackingFootage,
        ...action.payload,
      }
    },
    setCountingToDetermineCameraIssues: (state, action) => {
      state.packingRecordingOnCheckQty.countingToDetermineCameraIssues = action.payload
    },
    resetModalVideoPackingFootage: (state) => {
      state.modalVideoPackingFootage = initialState.modalVideoPackingFootage
    },
    setModalScanIpAddress: (
      state,
      action: PayloadAction<PackingRecordingInitialStateType['modalScanIpAddress']>,
    ) => {
      state.modalScanIpAddress = {
        ...state.modalScanIpAddress,
        ...action.payload,
      }
    },
    setInputPackingLineNumber: (state, { payload }: PayloadAction<string>) => {
      state.inputPackingLineNumber = payload
    },
  },
})
export default packingRecordingSlice.reducer

export const {
  setPackingRecordingOnCheckQty,
  resetPackingRecordingOnCheckQty,
  setModalLeavePageConfirmation,
  resetModalLeavePageConfirmation,
  setModalConfirmation,
  resetModalConfirmation,
  setModalVideoPackingFootage,
  setCountingToDetermineCameraIssues,
  resetModalVideoPackingFootage,
  setModalScanIpAddress,
  setInputPackingLineNumber,
} = packingRecordingSlice.actions
