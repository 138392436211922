import { memo } from 'react'
import styled, { createGlobalStyle } from 'styled-components'
import { HeadInformation, HeadLabel, TableMain, TermCondition } from './components'

function TemplatePurchaseOrderLite() {
  return (
    <>
      <Container>
        <GlobalStyle />
        <HeadLabel />
        <HeadInformation />
        <TableMain />
        <TermCondition />
      </Container>
    </>
  )
}

export default memo(TemplatePurchaseOrderLite)

const GlobalStyle = createGlobalStyle`
//   @page :left {
//     size: landscape;
//     margin: 5cm;
//     }

// @page:left{
//     size: landscape;

//     @top-left {
//         content: "foo";
//         color: blue;
//       }
//   }


`

const Container = styled.div`
  // width: 100vw;
  -webkit-print-color-adjust: exact !important;

  .bel {
    position: absolute;
    bottom: 0;
  }

  @media print {
    padding: 20px;
    background: white;
  }
`
