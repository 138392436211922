import { Backdrop as MuiBackdrop, BackdropProps as MuiBackdropProps } from '@mui/material'
import styled from 'styled-components'

export type BackdropPropsType = MuiBackdropProps

const Backdrop = styled(MuiBackdrop)<BackdropPropsType>`
  color: white;
`

export default Backdrop
