import { memo, useState, useRef } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { TextBody, Tag, Button, CounterUp } from 'components'
import { SystemIcon } from 'components/atoms'
import listenClickOutside from 'customHooks/outSideClick'
import { TopCheckBox } from './component'
import usePrintLabelCloudKitchen from 'customHooks/usePrintLabelCloudKitchen'
import { Typography } from '@astro-ui/components'
import { externalDriverValidator, parse3plLogo } from 'utils/3plValidator'

function OrderCard({
  textMainAction,
  handleClickMainAction,
  isWaitingDriver,
  isPacker,
  listDropDown,
  data,
  handleClickCancel,
  handleClickOption,
  timer,
  keyTimer,
  isWithoutRack,
  topCheckBoxOrderPooling,
  handleChangePicker,
  enableChangePicker = false,
  showPrintCloudKitchen = false,
}) {
  const refOutside = useRef(null)
  const [isShowDropDown, setIsShowDropDown] = useState(false)
  let orderPickerPackerName = isPacker
    ? data?.order_packer?.driver_full_name
    : data?.order_picker?.driver_full_name

  if (data?.order_status === 'Waiting Driver') {
    orderPickerPackerName =
      data?.order_driver?.driver_full_name + ' - ' + data?.order_driver?.driver_phone_number
    if (!data?.order_driver?.driver_full_name) {
      orderPickerPackerName = ''
    }
  }

  const { onPrintCloudKitchenLabel } = usePrintLabelCloudKitchen()

  listenClickOutside(
    refOutside,
    () => {
      setIsShowDropDown(false)
    },
    isShowDropDown,
  )

  const renderRack = ({ isWithoutRack, rack, brandName, isCLoudKitchen }) => {
    if (isWithoutRack) return null
    return <Td>{isCLoudKitchen ? <CloudKitchenInfoDiv>{brandName}</CloudKitchenInfoDiv> : rack}</Td>
  }

  const handleDisableUbahDriver = (data) => {
    if (
      data.order_status == 'Waiting Driver' &&
      data.order_driver &&
      !data.order_driver.driver_internal
    ) {
      return true
    }
    return false
  }

  return (
    <Container>
      {/* {topCheckBoxOrderPooling && <TopCheckBox data={data} />}s */}
      <TopCheckBox isWaitingDriver={topCheckBoxOrderPooling} data={data} />
      {isWaitingDriver && (
        <Alert>
          <Text color="textSoft">Menunggu driver</Text>
          <strong>
            <Text color="red"> 10 detik</Text>
          </strong>
        </Alert>
      )}

      <Header>
        <SpaceBetween>
          <CounterUp
            startFromTime={data?.order_payment_verified_at}
            isHeader={isPacker ? 'header' : 'headerSmaller'}
          />
          <ButtonWrapper>
            <ButtonPrint
              onClick={() => handleClickMainAction(data)}
              disabled={handleDisableUbahDriver(data)}
            >
              {textMainAction}
            </ButtonPrint>
            {isPacker ? (
              <WrapperThreeDot ref={refOutside}>
                <Button variant="wrapper" onClick={() => setIsShowDropDown(!isShowDropDown)}>
                  <ThreeDot>
                    <IconWrapper isShowDropDown={isShowDropDown}>
                      <SystemIcon iconName={isShowDropDown ? 'plus' : 'threeDots'} />
                    </IconWrapper>
                  </ThreeDot>
                </Button>
                {isShowDropDown && (
                  <Ul>
                    {listDropDown.map((el, index) => (
                      <Button
                        variant="wrapper"
                        onClick={() => handleClickOption(el, data)}
                        key={index}
                      >
                        <Li {...el}>{el.name}</Li>
                      </Button>
                    ))}
                  </Ul>
                )}
              </WrapperThreeDot>
            ) : (
              <ButtonCancel onClick={() => handleClickCancel(data)}>Batalkan</ButtonCancel>
            )}
          </ButtonWrapper>
        </SpaceBetween>
        <TagWrapper>
          {data.order_service_level_name && (
            <Tag color={data.order_service_level_name.includes('15') ? 'blueTag' : 'redTag'}>
              {data.order_service_level_name}
            </Tag>
          )}
          {data.is_new_customer && <Tag color="yellow">New Buyer</Tag>}
          {data.order_status == 'Packing' && !data.order_packer && (
            <Tag color="green">Siap Dikemas</Tag>
          )}
        </TagWrapper>
        <SpaceBetween>
          <TextBody size="smaller">{data.order_invoice}</TextBody>
          <TextBody size="smaller" color="secondaryText" weight="light">
            {dayjs(data.order_created_at).format('DD MMM YYYY, HH:mm')}
          </TextBody>
        </SpaceBetween>
        {enableChangePicker && orderPickerPackerName && (
          <SpaceBetween>
            <RowDriver>
              <TextBody size="smaller">
                {data?.order_status === 'Waiting Driver'
                  ? 'Driver'
                  : isPacker
                  ? 'Packer'
                  : 'Picker'}
              </TextBody>
              <TextBody size="smaller" weight="light">
                : {orderPickerPackerName}
              </TextBody>
            </RowDriver>
            {data?.order_status !== 'Waiting Driver' &&
              window.location.pathname !== '/dashboard/order-packer/picking' && (
                <TextBody
                  style={{ cursor: 'pointer', color: '#246ee5' }}
                  size="smaller"
                  onClick={() => {
                    window.scrollTo(0, 0)
                    handleChangePicker(data)
                  }}
                >
                  Ganti {isPacker ? 'Packer' : 'Picker'}
                </TextBody>
              )}
          </SpaceBetween>
        )}
        {data.order_driver && !data.order_driver.driver_internal && (
          <RowDriver>
            <TextBody size="smaller">Kurir Instan</TextBody>
            <TextBody size="smaller" weight="light">
              : {parse3plLogo(data.order_driver?.driver_external_provider_name)}
            </TextBody>
          </RowDriver>
        )}

        {externalDriverValidator(data?.order_third_party_logistic_term, data?.order_status) &&
          !data?.order_driver && (
            <CourierWarningContainer>
              <NotifWarningSimiliarOrder>
                <Typography variant="h5">Pesanan ini boleh panggil kurir instan</Typography>
                <Typography variant="body3" fontWeight="400">
                  Hubungi Shift Lead atau SPV untuk panggil kurir
                </Typography>
              </NotifWarningSimiliarOrder>
            </CourierWarningContainer>
          )}
      </Header>
      <Content>
        <Table>
          <thead>
            <Tr>
              {!isWithoutRack && <Th>Rak</Th>}
              <Th>Produk</Th>
              <Th>Qty</Th>
            </Tr>
          </thead>
          <Tbody>
            {data?.order_line_items?.slice(0, 3).map((el) => {
              return (
                <TrRow
                  key={el.order_line_item_id}
                  bgColor={
                    el.cloud_kitchen ? 'linear-gradient(180deg, #FEF2E6 -21.75%, #FDD6B2 100%)' : ''
                  }
                >
                  {renderRack({
                    isWithoutRack,
                    rack: el.order_product_rack,
                    isCLoudKitchen: el.cloud_kitchen,
                    brandName: el.brand_name,
                  })}
                  <Td>
                    <ItemWrapper>
                      {el.order_product_name}{' '}
                      {el.cloud_kitchen && showPrintCloudKitchen && (
                        <IconWrapper
                          onClick={() =>
                            onPrintCloudKitchenLabel(
                              data.order_id,
                              el.order_product_id,
                              el.order_product_quantity,
                            )
                          }
                          style={{ cursor: 'pointer' }}
                        >
                          <SystemIcon withCircleBorder colorIcon="mainV2" iconName="printer" />
                        </IconWrapper>
                      )}
                    </ItemWrapper>
                  </Td>
                  <Td>{el.order_product_quantity}</Td>
                </TrRow>
              )
            })}
          </Tbody>
        </Table>
      </Content>
      {(data?.order_line_items?.length > 3 || false) && (
        <ButtonMoreWrapper>
          <ButtonMore onClick={() => handleClickMainAction(data, true)}>
            + {data.order_line_items.length - 3} produk lainnya
          </ButtonMore>
        </ButtonMoreWrapper>
      )}
    </Container>
  )
}

OrderCard.defaultProps = {
  textMainAction: 'Default',
  handleClickMainAction: () => {},
  isWaitingDriver: false,
  isPacker: false,
  listDropDown: [],
  handleClickCancel: () => {},
  data: {},
  handleClickOption: () => {},
  handleChangePicker: () => {},
  timer: 120,
  keyTimer: 'order_created_at',
  isWithoutRack: false,
  topCheckBoxOrderPooling: false,
}

export default memo(OrderCard)

const Content = styled.div`
  display: flex;
  gap: 8px;
`

const Header = styled.div`
  padding-bottom: 16px;
  border-bottom: 1px solid #e4eaf3;
  padding: 16px;
  padding-bottom: 12px;
`

const Container = styled.div`
  border-radius: 8px;
  background-color: ${({ theme: { colors } }) => colors.white};
  width: 340px;
  height: fit-content;
  min-height: 300px;
`

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
`
const ButtonPrint = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px 12px;
  border: 1px solid ${({ theme: { colors } }, disabled) => (disabled ? colors.grey : colors.main)};
  border-radius: 6px;
  height: fit-content;
  background-color: ${({ theme: { colors } }) => colors.white};
  color: ${({ theme: { colors } }, disabled) => (disabled ? colors.grey : colors.main)};
  display: flex;
  align-items: center;
  gap: 5px;
`

const ButtonCancel = styled(ButtonPrint)`
  color: ${({ theme: { colors } }) => colors.secondaryText};
  border-color: ${({ theme: { colors } }) => colors.grey};
`

const ButtonMore = styled.button`
  background-color: ${({ theme: { colors } }) => colors.white};
  border: 1px solid #e5ecf0;
  padding: 8px 0;
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.light};
  color: ${({ theme: { colors } }) => colors.secondaryText};
  width: 100%;
  border-radius: 6px;
  cursor: pointer;
`

const TagWrapper = styled.div`
  margin-top: 4px;
  margin-bottom: 12px;
  display: flex;
  gap: 4px;
`

const ButtonWrapper = styled.div`
  display: flex;
  gap: 8px;
`

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const Tr = styled.tr`
  background-color: ${({ theme: { colors } }) => colors.softGrey};
`

const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.light};
  text-align: start;
  padding: 8px 8px;
`

const TrRow = styled.tr`
  background: ${({ theme: { colors }, bgColor }) => (bgColor ? bgColor : colors.white)};
`

const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.textSoft};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.light};
  padding: 8px 8px;
  border-bottom: 1px solid #eff3f9;
`

const Tbody = styled.tbody``

const ButtonMoreWrapper = styled.div`
  padding: 16px;
  padding-top: 12px;
`

const Alert = styled.div`
  padding: 12px;
  background-color: ${({ theme: { colors } }) => colors.yellowAlert};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
`

const Text = styled.span`
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  color: ${({ theme: { colors }, color }) => colors[color]};
`

const ThreeDot = styled.div`
  border: 1px solid #aab4c8;
  padding: 3.5px 12px;
  border-radius: 6px;
  height: fit-content;
`

const WrapperThreeDot = styled.div`
  height: fit-content;
  width: fit-content;
  position: relative;
`

const Ul = styled.ul`
  position: absolute;
  background-color: ${({ theme: { colors } }) => colors.white};
  min-width: 110px;
  right: 0;
  box-shadow: 0px 2px 16px 0px rgba(202, 211, 225, 0.4);
`
const Li = styled.li`
  text-align: left;
  list-style: none;
  padding: 8px 12px;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  color: ${({ theme: { colors }, color }) => colors[color]};
  white-space: nowrap;
`

const IconWrapper = styled.div`
  transform: ${({ isShowDropDown }) => (isShowDropDown ? 'rotate(45deg)' : 'rotate(0deg)')};
  transition: all ease 0.3s;
`

const CloudKitchenInfoDiv = styled.div`
  background-color: #f97b06;
  padding: 4px;
  color: white;
  text-align: center;
  border-radius: 4px;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
`

const CourierWarningContainer = styled.div`
  width: 100%;
  margin-top: 10px;
`

const NotifWarningSimiliarOrder = styled.div`
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff7ea;
  border-radius: 8px;
  display: flex;
  padding-left: 16px;
  border-left-style: solid;
  border-left-color: #ffc400;
  border-left-width: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
`
const RowDriver = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`
