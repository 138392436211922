import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit'
import { GetSupplyOrderPickingResponseType } from 'utils/apiList/supplyOrderPicking'
import { withLoadingReducer } from 'utils/reducerHandler'
import { fetchGetDeliveryOrder, fetchPutCompletePicking } from './SupplyOrderPickingAsyncThunk'

export type SupplyOrderPickingSliceType = {
  isLoading: boolean
  data: GetSupplyOrderPickingResponseType['data'] | null
}

const initialState: SupplyOrderPickingSliceType = {
  isLoading: false,
  data: null,
}

const supplyOrderPickingSlice = createSlice({
  name: 'supplyOrderPicking',
  initialState,
  reducers: {
    setQty: (state) => {
      state.isLoading = false
    },
    setReset: () => initialState,
  },
  extraReducers: withLoadingReducer(
    (builder: ActionReducerMapBuilder<SupplyOrderPickingSliceType>) => {
      builder.addCase(fetchGetDeliveryOrder.fulfilled, (state, action) => {
        state.data = action.payload.data
      })
    },
    [fetchGetDeliveryOrder, fetchPutCompletePicking],
  ),
})

export const { setQty, setReset } = supplyOrderPickingSlice.actions
export default supplyOrderPickingSlice.reducer
