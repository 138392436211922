import axios from 'axios'
import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

type BusinessTaggingValueType = 'SCHEDULE_DELIVERY' | 'ASTRO_GO' | 'QUICK_COMMERCE'

export interface ProductResponseType {
  businessTaggings: {
    id: number
    key: BusinessTaggingValueType
    type: string
    value: string
  }[]
  productId: number
  productImage: string
  productName: string
  productSku: string
  productStock: number
  productInventoryStatusId: number
  productInventoryStatus: string
  productInventoryStatusNote: string
  productInventoryStatusNoteId: number
}

export interface ProductItemResponseType extends ProductResponseType {
  productDetailId: number
  productExpiryDate: string
  productInventoryNoteId: number
  productInventoryNote: string
  productQrCode: string
  productUniqueKey: string
}

export interface GetProductListForSoDirectResponseType {
  data: ProductResponseType[]
  pagination: PaginationResponseType
}

export interface GetProductItemsForSoDirectResponseType {
  data: ProductItemResponseType[]
  pagination: PaginationResponseType
}

export interface GetProductListForSoDirectRequestType {
  pageSize: number
  pageIndex: number
  locationId: number
  productName?: string
  businessTaggings?: BusinessTaggingValueType[]
  hideBusinessTaggingNull?: boolean
  statusId: number
  statusNoteId?: number
  productTypeIds?: string
}

export interface GetProductItemsForSoDirectRequestType
  extends Omit<
    GetProductListForSoDirectRequestType,
    'productName' | 'statusId' | 'statusNoteId' | 'productTypeId'
  > {
  productId: number
}

export interface SoRequestType {
  createdBy: string
  locationDestinationId: number
  locationOriginId: number
  remarks: string
  requestBy: string
  shippingInstructions: string | null
  soDate: number
  soReff: string | null
  soType: string
  status: string
}

export interface SoItemType {
  productId: number
  productQrCode: string
  productRequestQuantity: number
  productStatusId: number
  productStatusNoteId: number | null
  productStock: number
}

export interface PostAddSoProductRequestType {
  createdBy: string
  locationDestinationId: number
  locationOriginId: number
  remarks: string
  requestBy: string
  shippingInstructions: string
  soDate: number
  soType: string
  soReff: string
  status: string
  supplyOrderItems: SoItemType[]
}

export interface PutSoProductRequestType {
  supplyOrderRequest: SoRequestType
  supplyOrderItemRequest: SoItemType[]
}

export interface ErrorResponseType {
  status: boolean
  message: string
  code: number
}

export interface PostAPiResponseType {
  data: {
    locationDestinationName: string
    locationOriginId: number
    locationOriginName: string
    remarks: string
    supplyOrderCreatedAt: number
    supplyOrderCreatedBy: string
    supplyOrderDate: number
    supplyOrderId: number
    supplyOrderNumber: string
    supplyOrderStatus: string
    supplyOrderType: string
    totalItem: number
    totalQty: number
  }
  error: ErrorResponseType
}

export interface GetConfigSoDirectResponseType {
  hide_business_tagging_null: boolean
  business_taggings: BusinessTaggingValueType[]
}

const baseUrl = process.env.REACT_APP_BASE_URL_API
const apiGetProductListForSoDirect = '/api/supply-order-grand/product-group'
const apiGetProductItemsForSoDirect = '/api/supply-order-grand/product-group/detail'
const apiPostAddProductSo = '/api/wms/v2/supply-order'
const apiGetConfigSo = `/wms/internal/v1/supply-order/business-tagging/config/:destinationId`

export const getProductListForSoDirect = (params: GetProductListForSoDirectRequestType) =>
  axios.get<GetProductListForSoDirectResponseType>(`${baseUrl}${apiGetProductListForSoDirect}`, {
    params: {
      ...params,
      businessTaggings: params?.businessTaggings?.toString(),
    },
  })

export const getConfigSoDirect = (destinationId: number) =>
  axiosInstanceApiGateway.Get<GetConfigSoDirectResponseType>({
    url: apiGetConfigSo,
    urlParams: {
      destinationId,
    },
  })

export const getProductItemsForSoDirect = (params: GetProductItemsForSoDirectRequestType) =>
  axios.get<GetProductItemsForSoDirectResponseType>(`${baseUrl}${apiGetProductItemsForSoDirect}`, {
    params,
  })

export const postAddSoProduct = (body: PostAddSoProductRequestType) =>
  axios.post<PostAPiResponseType>(`${baseUrl}${apiPostAddProductSo}`, body)

export const putUpdateSoProduct = (id: number, body: PutSoProductRequestType) =>
  axios.put(`${baseUrl}${apiPostAddProductSo}/${id}`, body)
