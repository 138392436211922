import apiServices from 'config/apiServiceApiGateway'
import type {
  GetMilestoneResponseType,
  GetMilestoneRequestType,
  PutMilestoneStatusRequestType,
  GetMilestonePriorityListResponseType,
  PostMilestonePriorityType,
} from 'features/Promotion/@types/miletstoneList'

const { Get, Put, Post } = apiServices

export const getMilestoneList = ({ params }: GetMilestoneRequestType) =>
  Get<GetMilestoneResponseType, true>({
    url: '/promo/internal/v1/milestone/list',
    version: 'v1',
    params,
  })

export const putMilestoneStatus = ({ payload }: PutMilestoneStatusRequestType) =>
  Put({
    url: '/promo/internal/v1/milestone/status',
    version: 'v1',
    data: payload,
  })

export const getMilestonePriorityList = () =>
  Get<GetMilestonePriorityListResponseType, true>({
    url: `/promo/internal/v1/milestone/pinning/list`,
  })

export const postMilestonePriority = ({ payload }: PostMilestonePriorityType) =>
  Post({
    url: '/promo/internal/v1/milestone/pinning',
    version: 'v1',
    data: payload,
  })
