import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  SLICE_NAME,
  fetchCampaignLoyaltyProductCandidateList,
  createCampaignLoyaltyProduct,
  fetchLocationHubList,
} from './campaignLoyaltyProductAddManualThunk'

export type LocationHubType = {
  location_id: number
  location_name: string
  location_type: string
  name: string
}

export interface QueryType {
  name: string
  pageSize: number
  pageIndex: number
  status: {
    name: string
    value: string
  }
  location: LocationHubType
}

export type ProductCandidateStateType = {
  id: number
  locationId: number
  locationName: string
  productId: number
  productName: string
  coin: number
  quota: number
  isSpecial: boolean
  isActive: boolean
  uniqueKey: string
}

export interface CampaignLoyaltyProductAddManualStateType {
  isLoading: boolean
  query: QueryType
  productCandidateList: ProductCandidateStateType[]
  selectedProductCandidate: ProductCandidateStateType[]
  locationHubList: LocationHubType[]
}

export const initialState: CampaignLoyaltyProductAddManualStateType = {
  isLoading: false,
  query: {
    pageIndex: 0,
    name: '',
    pageSize: 20,
    status: {
      name: '',
      value: '',
    },
    location: { location_id: 0, location_name: '', location_type: '', name: '' },
  },
  productCandidateList: [],
  selectedProductCandidate: [],
  locationHubList: [],
}

const campaignLoyaltyProductAddManual = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,

    setQuery: (
      state,
      action: PayloadAction<{
        name: keyof QueryType
        value: boolean | number | string | QueryType['location']
      }>,
    ) => {
      const { name, value } = action.payload

      state.query[name] = value as never
    },
    setSelectedProductCandidate: (
      state,
      action: PayloadAction<{
        type: 'add' | 'remove' | 'bulkAdd' | 'bulkRemove'
        id: number
        value: ProductCandidateStateType
      }>,
    ) => {
      const { type, value, id } = action.payload

      if (type === 'add') {
        state.selectedProductCandidate.push(value)
      } else if (type === 'remove') {
        state.selectedProductCandidate = state.selectedProductCandidate.filter((el) => el.id !== id)
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCampaignLoyaltyProductCandidateList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchCampaignLoyaltyProductCandidateList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchCampaignLoyaltyProductCandidateList.fulfilled, (state, action) => {
        state.isLoading = false
        state.productCandidateList = action.payload.data.campaignProductCandidates.map((el) => ({
          ...el,
          uniqueKey: window.crypto.randomUUID(),
        }))
      })
      .addCase(createCampaignLoyaltyProduct.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createCampaignLoyaltyProduct.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(createCampaignLoyaltyProduct.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchLocationHubList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchLocationHubList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchLocationHubList.fulfilled, (state, actions) => {
        state.locationHubList = actions.payload.map((el) => ({ ...el, name: el.location_name }))
        state.isLoading = false
      })
  },
})

export const { reset, setQuery, setSelectedProductCandidate } =
  campaignLoyaltyProductAddManual.actions
export default campaignLoyaltyProductAddManual.reducer
