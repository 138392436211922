import { toastFailed, toastSuccess } from 'utils/toast'
import {
  SET_IS_SUCCESS_CAMPAIGN_ADD,
  SET_PAGINATION_DATA_CAMPAIGN_ADD,
  SET_IS_NEED_TO_RELOAD_CAMPAIGN_ADD,
  SET_IS_DETAIL_DATA_CAMPAIGN_ADD,
  RESET_CAMPAIGN,
} from 'storeContext/actionsType/campaign'
import { campaignStart, campaignStop } from './index'
import {
  getCampaign_CAMPAIGN_ADD,
  putUpdateDataCampaign_CAMPAIGN_ADD,
  postCreateCampaign_CAMPAIGN_ADD,
  postBulkUploadCampaign_CAMPAIGN_ADD,
  putEditAllCampaign_CAMPAIGN_ADD,
  deleteRemoveInventoriesCampaign_CAMPAIGN_ADD,
  getBulkUploadInventoriesCampaign_CAMPAIGN_ADD,
} from 'utils/apiList/campaign'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getInventoryListById,
  putInventoryDiscountById,
} from 'features/CampaignAndDiscount/services/campaignAddEdit'

export const setData_CampaignAdd = (data) => {
  return {
    type: SET_IS_SUCCESS_CAMPAIGN_ADD,
    payload: data,
  }
}

export const setPaginationData_CampaignAdd = (data) => {
  return {
    type: SET_PAGINATION_DATA_CAMPAIGN_ADD,
    payload: data,
  }
}

export const needToRelaod_CampaignAdd = () => {
  return {
    type: SET_IS_NEED_TO_RELOAD_CAMPAIGN_ADD,
  }
}

export const setDetailData_CampaignAdd = (data) => {
  return {
    type: SET_IS_DETAIL_DATA_CAMPAIGN_ADD,
    payload: data,
  }
}

export const resetState = (data) => {
  return {
    type: RESET_CAMPAIGN,
  }
}

export const getListOfCampaignInventory_CAMPAIGN_ADD =
  (campaign_id, localState = {}) =>
  (dispatch) => {
    const { search } = localState

    return getInventoryListById({
      id: campaign_id,
      params: {
        page_index: search.pageIndex,
        page_size: search.pageSize,
        categoryId: search.categorySelected.toString(),
        location_id: search.location.location_id ? search.location.location_id : '',
        name: search.name,
      },
    })
      .then(({ data }) => {
        if (!!data?.error?.status === false) {
          const { content, pagination: resPagination } = data.data

          const pagination = {
            currentItem: resPagination.number_of_elements,
            currentPage: resPagination.page_index,
            pageSize: resPagination.page_size,
            totalData: resPagination.total_elements,
            totalPage: resPagination.total_pages,
          }

          const modifiedContent = content.map((el) => {
            const campaignPriceDetails = el.campaign_price_details.map((ell) => {
              return {
                // ...ell,
                campaignInventoryId: ell.campaign_inventory_id,
                astroPercentage: ell.astro_percentage,
                dailyQuota: ell.daily_quota,
                discountPercentage: ell.discount_percentage,
                discountQuota: ell.discount_quota,
                discountedPrice: ell.discounted_price,
                uniqueId: window.crypto.randomUUID(),
                isShowRow: true,
                segmentData: {
                  segmentId: ell.segment_data.segment_id,
                  segmentName: ell.segment_data.segment_name,
                },
                userSegment: ell.user_segment,
                tier: !ell?.tier
                  ? null
                  : ell?.tier?.map((elTier) => {
                      return {
                        discountedPrice: elTier.discounted_price,
                        discountPercentage: elTier.discount_percentage,
                        threshold: elTier.threshold,
                        uniqueId: window.crypto.randomUUID(),
                      }
                    }),
              }
            })

            return {
              locationId: el.location_id,
              locationName: el.location_name,
              astroPercentage: el.astro_percentage,
              originalPrice: el.original_price,
              productId: el.product_id,
              productName: el.product_name,
              productSkuNumber: el.product_sku_number,
              productStock: el.product_stock,
              uniqueId: window.crypto.randomUUID(),
              isShowRow: true,
              isFlashSale: el.is_flash_sale,
              campaignPriceDetails,
            }
          })

          localState.getInventoryProductList({ value: modifiedContent })
          dispatch(setData_CampaignAdd(modifiedContent))
          dispatch(setPaginationData_CampaignAdd(pagination))
        } else {
          toastFailed(data.error.message)
        }
      })
      .catch(() => {
        toastFailed(`terjadi kesalahan, coba beberapa saat lagi`)
      })
  }

export const getListOfCampaignAdd =
  (campaign_id, localState = {}) =>
  (dispatch, getState) => {
    const { query } = localState
    dispatch(campaignStart())

    return getCampaign_CAMPAIGN_ADD(campaign_id, query)
      .then(({ data }) => {
        if (data.error.status === false) {
          localState.getCampaignDetail({ ...data.data, campaignType: data.data.campaign_type })

          // dispatch()
          dispatch(setDetailData_CampaignAdd(data.data))
        } else {
          toastFailed(data.error.message)
        }

        dispatch(campaignStop())
      })
      .catch(() => {
        toastFailed(`terjadi kesalahan, coba beberapa saat lagi`)
        dispatch(campaignStop())
      })
  }

export const updateCampaignData_CAMPAIGN_ADD =
  (campaign_id, localState = {}) =>
  (dispatch) => {
    dispatch(campaignStart())
    const { dataToSend } = localState

    return putUpdateDataCampaign_CAMPAIGN_ADD(campaign_id, dataToSend)
      .then(({ data }) => {
        if (!!data?.error?.status === false) {
          toastSuccess('Campaign Berhasil dirubah')

          dispatch(needToRelaod_CampaignAdd())
        } else {
          toastFailed(data.error.message)
        }
        dispatch(campaignStop())
      })
      .catch((err) => {
        const { data } = err.response

        if (data.error.status) {
          toastFailed(` ${data.error.message}, code : ${data.error.code} `)
        } else {
          toastFailed(`terjadi kesalahan, coba beberapa saat lagi`)
        }

        dispatch(campaignStop())
      })
  }

export const createNewCampaign_CAMPAIGN_ADD =
  (localState = {}) =>
  (dispatch) => {
    dispatch(campaignStart())
    const { dataToSend, history, getParams } = localState

    return postCreateCampaign_CAMPAIGN_ADD(dataToSend)
      .then(({ data }) => {
        if (data?.error?.message) {
          toastFailed(` ${data.error.message} `)
        } else {
          history.replace({
            search: `?campaignId=${data.data.id}&type=${getParams('type')}`,
          })
          toastSuccess('Campaign Berhasil dibuat')
        }
        dispatch(campaignStop())
      })
      .catch((err) => {
        if (err?.response?.data) {
          const { data } = err.response

          if (data.error.status) {
            toastFailed(` ${data.error.message} `)
          } else {
            toastFailed(`terjadi kesalahan, coba beberapa saat lagi`)
          }
        }
        dispatch(campaignStop())
      })
  }

export const deleteCampaingSingleandAll_CAMPAIGN_ADD =
  (campaign_id, localState = {}) =>
  (dispatch) => {
    return deleteRemoveInventoriesCampaign_CAMPAIGN_ADD(campaign_id, localState.dataToSend)
      .then(({ data }) => {
        toastSuccess('Campaign Berhasil Di delete')
        dispatch(needToRelaod_CampaignAdd())
        dispatch(campaignStop())
        localState.handleClickClose()
      })
      .catch((err) => {
        //const { error } = err.response.data;
        toastFailed(`terjadi kesalahan, coba beberapa saat lagi`)
        //toastFailed(`code : ${error.code} , ${error.message}`);
        dispatch(campaignStop())
      })
  }

export const editAllCampaign_CAMPAIGN_ADD =
  (campaign_id, localState = {}) =>
  (dispatch) => {
    return putEditAllCampaign_CAMPAIGN_ADD(campaign_id, localState.dataToSend)
      .then(() => {
        toastSuccess('Semua Diskon Berhasil Di update')
        dispatch(needToRelaod_CampaignAdd())
        localState.handleClickClose()
        dispatch(campaignStop())
      })
      .catch(() => {
        toastFailed(`terjadi kesalahan, coba beberapa saat lagi`)
        dispatch(campaignStop())
      })
  }

export const uploadByBulkCampaign_CAMPAIGN_ADD =
  (campaign_id, localState = {}, componentState = {}) =>
  async (dispatch) => {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }

    try {
      await postBulkUploadCampaign_CAMPAIGN_ADD(campaign_id, localState.dataToSend.file, config)
      componentState.setProgress('Uploading ...')
    } catch (error) {
      localState.setFileCSV((prev) => {
        return {
          ...prev,
          status: localState.upStatus.ON_CHOOSE_FILE,
        }
      })
      toastFailed('terjadi masalah, coba beberapa saat lagi')
    }
  }

export const getTemplateOfBulkUploadCampaignInventories_CAMPAIGN_ADD =
  (localState = {}) =>
  (dispatch) => {
    return getBulkUploadInventoriesCampaign_CAMPAIGN_ADD({ params: localState.params })
      .then(({ data }) => {
        localState.setUrlTemplate(data.data.url)
      })
      .catch(() => {
        toastFailed('gagal mendapatkan template, coba beberapa saat lagi')
      })
  }

export const editCampaignInventoryByItem_CAMPAIGN_ADD =
  (campaign_inventory_id, localState = {}) =>
  (dispatch) => {
    dispatch(campaignStart())

    return putInventoryDiscountById({
      id: campaign_inventory_id,
      payload: localState.dataToSend,
    })
      .then(({ data }) => {
        if (data?.error?.message) {
          toastFailed(data?.error?.message)
        } else {
          dispatch(needToRelaod_CampaignAdd())
        }
        dispatch(campaignStop())
      })
      .catch((err) => {
        callErrorMsg(err)
        dispatch(campaignStop())
      })
  }
