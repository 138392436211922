import {
  CampaignSegmentOptionType,
  CampaignTypeOptionType,
} from 'features/Promotion/@types/flexiComboAddEdit'

export const CAMPAIGN_SEGMENT_OPTION: CampaignSegmentOptionType[] = [
  { name: 'Global', value: 'global_user' },
  { name: 'New User', value: 'new_user' },
  { name: 'Targeted User', value: 'targeted_user' },
]

export const CAMPAIGN_TYPE_OPTION: CampaignTypeOptionType[] = [
  { name: 'Final Price', value: 'final_price' },
  { name: 'Discount Percentage', value: 'discount_percentage' },
]

export const MAX_TOTAL_DISCOUNT_PERCENTAGE = 98
export const MIN_TOTAL_DISCOUNT_PERCENTAGE = 2
