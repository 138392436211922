export const SET_IS_LOADING = 'SET_IS_LOADING/VOUCHER'
export const SET_IS_FETCHING_PAYMENT_CHANNEL = 'SET_IS_FETCHING_PAYMENT_CHANNEL/VOUCHER'
export const SET_PAGINATION_DATA_VOUCHER = 'SET_PAGINATION_DATA_VOUCHER/VOUCHER'
export const SET_VOUCHER_DATA = 'SET_VOUCHER_DATA/VOUCHER'
export const SET_LAST_QUERY = 'SET_LAST_QUERY/VOUCHER'
export const SET_BENEFITS = 'SET_BENEFITS/VOUCHER'
export const SET_TARGETS = 'SET_TARGETS/VOUCHER'
export const SET_MECHANISMS = 'SET_MECHANISMS/VOUCHER'
export const SET_DETAIL_VOUCHER = 'SET_DETAIL_VOUCHER/VOUCHER'
export const SET_PAYMENT_CHANNEL = 'SET_PAYMENT_CHANNEL/VOUCHER'
export const SET_RESET = 'SET_RESET/VOUCHER'
