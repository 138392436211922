import React from "react";
import styled from "styled-components";

const CheckBox = ({ ...rest }) => {
  return (
    <Label {...rest}>
      <RadioContainer {...rest} type="checkbox" />
      <RadioCustom {...rest} />
    </Label>
  );
};

CheckBox.defaultProps = {
  checked: false,
  onChange: () => {},
  size: "normal"
};

export default React.memo(CheckBox);

const RadioCustom = styled.span`
  left: 0;
  position: absolute;
  height: ${({ size }) => (size === "small" ? "13px" : "18px")};
  width: ${({ size }) => (size === "small" ? "13px" : "18px")};
  transition: all ease 0.5s;
  border: 2px solid
    ${({ theme: { colors }, checked }) => (checked ? colors.main : colors.grey)};
  border-radius: 10px;
  &:after {
    content: "";
    top: ${({ size }) => (size === "small" ? "1px" : "3px")};
    position: absolute;
    height: ${({ size }) => (size === "small" ? "7px" : "8px")};
    width: ${({ size }) => (size === "small" ? "7px" : "8px")};
    background-color: ${({ theme: { colors } }) => colors.main};
    border-radius: 8px;
    left: ${({ size }) => (size === "small" ? "1px" : "3px")};
    display: ${({ checked }) => (checked ? "block" : "none")};
  }
`;

const Label = styled.label`
  position: relative;
  width: ${({ size }) => (size === "small" ? "13px" : "18px")};
  height: ${({ size }) => (size === "small" ? "13px" : "18px")};
  cursor: pointer;
`;

const RadioContainer = styled.input`
  position: absolute;
  opacity: 0;
  border: 1px solid #aab4c8;
  cursor: pointer;
`;
