import { shallowEqual, useSelector } from 'react-redux'

const filterSelectState = (state: StoreStateType) => {
  const { typeTobePrinted } = state.printArea

  return {
    typeTobePrinted,
  }
}
export const usePrintArea = () => {
  const selectedState = useSelector(filterSelectState, shallowEqual)

  return {
    state: {
      ...selectedState,
    },
  }
}
