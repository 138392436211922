import { useState, useEffect } from 'react'
import { shallowEqual, useAppSelector } from 'store'
import { useHistory } from 'react-router-dom'

import { RolesKeyType, rolesWhichCanAccessAllPage } from 'constant/roles'
import useAppPusher from 'utils/customHooks/useAppPusher'

import FreezePage from 'components/organisms/FreezePage'

export type PrivateRouterType = {
  children: JSX.Element
  allowedRoles: RolesKeyType[]
  isUsingRBAC?: boolean
}

export const PrivateRouter = ({ children, allowedRoles, isUsingRBAC }: PrivateRouterType) => {
  const history = useHistory()
  const [isAllowedByRole, setIsAllowed] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const { userData } = useAppSelector((state) => state.auth, shallowEqual)
  const { isFreezeApp } = useAppPusher(userData)
  const isAuthenticated = !!Object.keys(userData || {}).length

  useEffect(() => {
    if (isAuthenticated) {
      if (isUsingRBAC) {
        // is rbac page id exsist, this page use new RBAC, skip checking on legacy role
        setIsAllowed(true)
      } else {
        // check allowed page based on legacy role
        const userRoles = userData.authorities.map((each) => each.name)
        const isAllowed =
          rolesWhichCanAccessAllPage.some((role) => userRoles.includes(role)) ||
          allowedRoles.some((role) => userRoles.includes(role))
        if (isAllowed) {
          setIsAllowed(true)
        }
      }
      setIsLoading(false)
      return
    }
    history.replace('/')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, userData])

  if (isFreezeApp) return FreezePage()

  if (isLoading || !isAllowedByRole) return null

  return children
}

export default PrivateRouter
