import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getLocations,
  getLocationList,
  getSLAConfig,
  putSLAConfig,
  getKongLocationDetail,
  getLocationDetail,
  getKongLocationAll,
  putKongLocation,
  postKongLocation,
  type PutKongLocationRequestType,
  type PostKongLocationRequestType,
} from 'features/Location/services/location'
import { FetchLocationAllParamType } from 'features/Location/@types/location'
import { toastSuccess } from 'utils/toast'

export const SLICE_NAME = 'location'

export const fetchGetLocations = createAsyncThunk(
  `${SLICE_NAME}/fetchGetLocations`,
  async (params: Parameters<typeof getLocations>[0], { rejectWithValue }) => {
    try {
      const res = await getLocations(params)

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchLocationList = createAsyncThunk(
  `${SLICE_NAME}/fetchLocationList`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getLocationList({ params: { active: true, type: 'HUB' } })

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchSLAConfig = createAsyncThunk(
  `${SLICE_NAME}/fetchSLAConfig`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getSLAConfig()

      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateSLAConfig = createAsyncThunk(
  `${SLICE_NAME}/updateSLAConfig`,
  async (_, { rejectWithValue, getState }) => {
    const {
      location: { formModalDeliveryConfig },
    } = getState() as StoreStateType
    try {
      const validateStartDate =
        formModalDeliveryConfig.startDate && formModalDeliveryConfig.startTime
      const validateEndDate = formModalDeliveryConfig.endDate && formModalDeliveryConfig.endTime
      const res = await putSLAConfig({
        payload: {
          end_date: validateEndDate
            ? `${formModalDeliveryConfig.endDate} ${formModalDeliveryConfig.endTime}`
            : '',
          start_date: validateStartDate
            ? `${formModalDeliveryConfig.startDate} ${formModalDeliveryConfig.startTime}`
            : '',
          instant: formModalDeliveryConfig.instant,
          locations: formModalDeliveryConfig.selectedLocation.map((el) => el.id),
          regular: formModalDeliveryConfig.twoHourOnly,
        },
      })

      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchKongLocationDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchKongLocationDetail`,
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const { data } = await getKongLocationDetail({ id })

      return { ...data.data, detail: { ...data.data.detail } }
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchLocationDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchLocationDetail`,
  async ({ id }: { id: number }, { rejectWithValue }) => {
    try {
      const res = await getLocationDetail(id)

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchLocationAll = createAsyncThunk(
  `${SLICE_NAME}/fetchLocationAll`,
  async ({ params }: FetchLocationAllParamType, { rejectWithValue }) => {
    try {
      const res = await getKongLocationAll({
        params,
      })

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateKongLocation = createAsyncThunk(
  `${SLICE_NAME}/updateKongLocation`,
  async ({ id, payload }: PutKongLocationRequestType, { rejectWithValue }) => {
    try {
      const res = await putKongLocation({
        id,
        payload,
      })
      toastSuccess(`Location ${payload.location.name}  berhasil diupdate.`)

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const createKongLocation = createAsyncThunk(
  `${SLICE_NAME}/createKongLocation`,
  async ({ payload }: PostKongLocationRequestType, { rejectWithValue }) => {
    try {
      const res = await postKongLocation({
        payload,
      })

      toastSuccess(`${payload.location.name} berhasil ditambahkan.`)

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
