import React from 'react'
import styled from 'styled-components'
import 'assets/font.css'

const SystemIcon = ({
  iconName,
  colorIcon,
  fontSize,
  className,
  withCircleBorder,
  circleBackgroundColor,
}) => {
  if (withCircleBorder) {
    return (
      <CircleWrapper circleBackgroundColor={circleBackgroundColor} fontSize={fontSize}>
        <Icon
          className={`icon-${iconName} ${className}`}
          colorIcon={colorIcon}
          fontSize={fontSize}
        />
      </CircleWrapper>
    )
  }
  return (
    <Icon className={`icon-${iconName} ${className}`} colorIcon={colorIcon} fontSize={fontSize} />
  )
}

SystemIcon.defaultProps = {
  colorIcon: 'grey',
  fontSize: 'bigger',
  className: '',
  withCircleBorder: false,
  circleBackgroundColor: false,
}

export default React.memo(SystemIcon)

const Icon = styled.i`
  &::before {
    color: ${({ theme: { colors }, colorIcon }) => colors[colorIcon]};
    font-size: ${({ theme: { fontSizes }, fontSize }) => fontSizes[fontSize]};
  }
`

const CircleWrapper = styled.div`
  display: flex;
  font-size: ${({ theme: { fontSizes }, fontSize }) => fontSizes[fontSize]};
  padding: ${({ theme: { fontSizes }, fontSize }) => {
    const valueSize = +fontSizes[fontSize].split('r')[0]
    return `${valueSize - 0.1}rem`
  }};
  border-radius: 50%;
  background-color: ${({ theme: { colors }, circleBackgroundColor }) =>
    colors[circleBackgroundColor] || colors.white};
  height: fit-content;
  width: fit-content;
  i {
    line-height: unset;
  }
`
