import { memo, useState } from "react";
import styled, { css } from "styled-components";
import { Button } from "components";

function PrintArea({ children, preview, Document }) {
  const [isPrinting, seIisPrinting] = useState(false);

  const handlePrint = () => {
    seIisPrinting(true);
    setTimeout(() => {
      // const content = document.getElementById("print-content");
      // const pri = document.getElementById("print");
      // pri.innerHTML = content;
      window.print();
      seIisPrinting(false);
    }, 300);
  };
  return (
    <>
      <Button onClick={handlePrint} variant="wrapper">
        {children}
      </Button>
      {(preview || isPrinting) && (
        <Container className="print-area" preview={preview} id="print-content">
          {Document}
        </Container>
      )}
    </>
  );
}

export default memo(PrintArea);

const Container = styled.div`
  display: none;
  ${({ preview }) =>
    preview &&
    css`
      position: fixed;
      left: 0px;
      top: 0px;
      background: white;
      z-index: 10;
      min-height: 100vh;
      min-width: 100vw;
    `}
`;
