import { memo } from "react";
import styled from "styled-components";
import { NoData, RowIntegration } from "components";

function TableIntegration({ data }) {
  return (
    <>
      <Container>
        <thead>
          <Tr>
            <Th max="30px">No.</Th>
            <Th>Date Time</Th>
            <Th>Item Code</Th>
            <Th>Quantity</Th>
            <Th>Remarks</Th>
          </Tr>
        </thead>
        <tbody>
          {data.map((el) => (
            <RowIntegration key={el} data={el} />
          ))}
        </tbody>
      </Container>
      {data.length === 0 && <NoData />}
    </>
  );
}

TableIntegration.defaultProps = {
  data: []
};

export default memo(TableIntegration);

const Container = styled.table`
  width: 100%;
  border-collapse: collapse;
  height: fit-content;
  border: 1px solid #d6dfeb;
  border-radius: 8px;
`;
const Tr = styled.tr`
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`;
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.textSoft};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.toast};
  text-align: start;
  padding: 16px 32px;
  padding-right: 16px;
  max-width: ${({ max }) => max};
`;
