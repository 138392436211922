import React from 'react'
import styled, { keyframes } from 'styled-components'

const Loader = ({ isSmall }) => {
  return <LoaderComponent isSmall={isSmall} />
}

Loader.defaultProps = {
  isSmall: false,
}

export default React.memo(Loader)

const spin = keyframes`
  0% { 
    transform: rotate(0deg); 
  }
  100% { 
    transform: rotate(360deg); 
  }
`

const LoaderComponent = styled.div`
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid ${({ theme: { colors } }) => colors.main};
  width: ${(props) => (props.isSmall ? '15px' : '30px')};
  height: ${(props) => (props.isSmall ? '15px' : '30px')};
  -webkit-animation: ${spin} 2s linear infinite; /* Safari */
  animation: ${spin} 2s linear infinite;
`
