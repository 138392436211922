import { memo, useState, useRef, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { SystemIcon, Button, TextBody } from 'components'
import listenClickOutside from 'customHooks/outSideClick'
import { DateRangePicker, Calendar } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file

function DateRange({
  className,
  value,
  handleSetDate,
  handleClear,
  label,
  mandatory,
  width,
  defaultValue,
  single,
  date,
  disabled,
  placeHolder,
  minDate,
  maxDate,
  size,
  customSelectDateRangeOnChange,
  shouldStopFocusOnChangeRange,
}) {
  const refOutside = useRef(null)
  const [isShow, setIsShow] = useState(false)
  const [singleDate, setSingleDate] = useState(new Date())
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ])
  listenClickOutside(
    refOutside,
    () => {
      setIsShow(false)
    },
    isShow,
  )
  useEffect(() => {
    if (date) {
      setSingleDate(date)
    }
  }, [date])
  useEffect(() => {
    if (defaultValue && !single) {
      setDateRange([
        {
          ...defaultValue,
          key: 'selection',
        },
      ])
    }
  }, [defaultValue])
  const handleClick = () => {
    setIsShow(false)
    const payload = single ? singleDate : dateRange
    handleSetDate(payload)
  }

  const handleSelect = (prop) => {
    setSingleDate(prop)
  }

  const handleClearDate = () => {
    setIsShow(false)
    handleClear()
  }

  const triggerAutomaticFocusDateRange = () =>
    shouldStopFocusOnChangeRange
      ? {
          onRangeFocusChange: undefined,
        }
      : {}

  return (
    <Wrapper
      width={size === 'full' ? '100%' : width}
      ref={refOutside}
      className="wrapper-date-range"
    >
      {label && (
        <Label className="label-input">
          <TextBody color="textSoft">
            {label} <Span>{mandatory}</Span>
          </TextBody>
        </Label>
      )}
      <Button
        className="input-button"
        variant="wrapper"
        onClick={() => setIsShow(!isShow)}
        size={size}
      >
        <Container disabled={disabled} width="100%" className={`${className} date-range`}>
          <SystemIcon fontSize="header" iconName="calendar" />
          <Text>{value || placeHolder}</Text>
          <SystemIcon fontSize="header" iconName="dropdown" />
        </Container>
      </Button>
      {isShow && !disabled && (
        <WrapperDate>
          {single ? (
            <Calendar date={singleDate} onChange={handleSelect} />
          ) : (
            <DateRangePicker
              minDate={minDate}
              maxDate={maxDate}
              onChange={(item) => {
                setDateRange(
                  customSelectDateRangeOnChange
                    ? [{ ...customSelectDateRangeOnChange(item.selection), key: 'selection' }]
                    : [item.selection],
                )
              }}
              showSelectionPreview={false}
              moveRangeOnFirstSelection={false}
              months={1}
              ranges={dateRange}
              showDateDisplay={false}
              direction="horizontal"
              {...triggerAutomaticFocusDateRange()}
            />
          )}
          <WrapperButton>
            <SStyledButton onClick={handleClearDate}>Clear</SStyledButton>
            <SStyledButton onClick={handleClick}>Terapkan</SStyledButton>
          </WrapperButton>
        </WrapperDate>
      )}
    </Wrapper>
  )
}

DateRange.defaultProps = {
  handleSetDate: () => {},
  handleClear: () => {},
  single: false,
  date: new Date(),
  disabled: false,
  placeHolder: '',
  size: '',
  customSelectDateRangeOnChange: undefined,
  shouldStopFocusOnChangeRange: false,
}

export default memo(DateRange)

const Container = styled.div`
  width: ${({ width }) => width};
  border: 1px solid #bfc9d9;
  border-radius: 8px;
  background-color: ${({ theme: { colors } }) => colors.white};
  position: relative;
  height: fit-content;

  padding: 9px 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme: { colors } }) => colors.grey};
      background-color: #fafafa;
      border-color: #e4eaf3;
    `}
`

const Text = styled.span`
  font-size: 14px;
  color: #31353b;
  font-weight: 400;
  flex: 1;
  text-align: start;
`

const WrapperDate = styled.div`
  position: absolute;
  top: 70px;
  left: 0;
  z-index: 1;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 8px;
  box-shadow: 8px 16px 48px 0px #2e31371f;
`

const Wrapper = styled.div`
  position: relative;
  width: ${({ width }) => width};
  .rdrCalendarWrapper {
    width: 336px;
  }
`

const WrapperButton = styled.div`
  border-top: 1px solid #f3f4f5;
  display: flex;
  justify-content: flex-end;
  padding: 8px 16px;
  button {
    font-size: 12px;
    font-weight: 400;
    padding: 12px 24px;
  }
`

const Label = styled.div`
  /* display: inline-block; */
  margin-bottom: 7px;
`

const Span = styled.span`
  color: #f08227;
  margin-left: 2px;
  vertical-align: middle;
`

const SStyledButton = styled(Button)`
  width: 104px;
`
