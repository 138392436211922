import { createAsyncThunk } from '@reduxjs/toolkit'

import { putResetUserAccountOTP } from 'features/Account/services/admin'

import { callErrorMsg } from 'helpers/errorMsg'
import { toastSuccess } from 'utils/toast'

export const SLICE_NAME = 'admin'

export const resetUserAccount = createAsyncThunk(
  `${SLICE_NAME}/resetAccount`,
  async (_, { rejectWithValue, getState }) => {
    const {
      admin: { formResetAccount },
    } = getState() as StoreStateType

    try {
      const res = await putResetUserAccountOTP({
        payload: { phone_number: formResetAccount.phoneNumber },
      })
      toastSuccess(`Akun Berhasil di Reset`)
      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)
