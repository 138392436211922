import { memo } from "react";
import styled, { keyframes } from "styled-components";
import { TextBody } from "components";

const OnUploadProgres = ({ progress }) => (
  <DescriptionContainer>
    <IconUploadContainer>
      <LoaderComponent />
    </IconUploadContainer>
    <TextDescription>
      <FileDescription>
        <TextBody weight="light" className="pointer" color="black">
          {progress}
        </TextBody>
      </FileDescription>
    </TextDescription>
  </DescriptionContainer>
);

export default memo(OnUploadProgres);

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconUploadContainer = styled.div`
  display: flex;
  width: 64px;
  height: 64px;
  border-radius: 100px;
  background: #ffffff;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  box-shadow: 0px 1px 12px rgba(105, 110, 118, 0.12);
`;

const TextDescription = styled.div`
  text-align: center;
`;
const FileDescription = styled.div`
  margin-top: 8px;
`;

const spin = keyframes`
    0% { 
      transform: rotate(0deg); 
    }
    100% { 
      transform: rotate(360deg); 
    }
  `;

const LoaderComponent = styled.div`
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid ${({ theme: { colors } }) => colors.main};
  width: 30px;
  height: 30px;
  -webkit-animation: ${spin} 2s linear infinite; /* Safari */
  animation: ${spin} 2s linear infinite;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
`;
