import { memo } from 'react'
import styled from 'styled-components'
import { RowPurchaseOrdersLite, SystemIcon, NoData } from 'components'
import { actionlistOption } from 'constant/purchaseOrder'

function TablePurchaseOrdersLite({
  data,
  handleClickPrint,
  handleClickOpenPopupDelivery,
  handleClickOpenPopupConfirmUpdateExpiredDate,
  isAbleToAccessPageCheckQty,
  isUserFinance,
}) {
  const filteredOptions = isUserFinance
    ? actionlistOption.filter((opts) => ['print', 'printGRN', 'checkQTY'].includes(opts.id))
    : actionlistOption

  return (
    <>
      <Container>
        <thead>
          <Tr>
            <Th ratio="3">
              NOMOR PO
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th ratio="2">
              VENDOR
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th ratio="3">
              TANGGAL DIBUAT
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th ratio="2">
              JUMLAH ITEM
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th ratio="2">
              TUJUAN
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th ratio="2">DIBUAT OLEH</Th>
            <Th ratio="2">
              STATUS
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th ratio="3">TANGGAL KIRIM</Th>
            <Th ratio="3">TANGGAL EXP.</Th>
            <Th ratio="2">AKSI</Th>
          </Tr>
        </thead>
        <tbody>
          {data.map((el) => (
            <RowPurchaseOrdersLite
              data={el}
              key={el.id}
              handleClickPrint={handleClickPrint}
              handleClickOpenPopupDelivery={handleClickOpenPopupDelivery}
              handleClickOpenPopupConfirmUpdateExpiredDate={
                handleClickOpenPopupConfirmUpdateExpiredDate
              }
              listOption={filteredOptions}
              isAbleToAccessPageCheckQty={isAbleToAccessPageCheckQty}
            />
          ))}
        </tbody>
      </Container>
      {data.length === 0 && <NoData />}
    </>
  )
}

export default memo(TablePurchaseOrdersLite)

const Container = styled.table`
  width: 100%;
`
const Tr = styled.tr`
  display: flex;
  padding: 16px 32px;
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`
