/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */

import axios, { AxiosError, AxiosRequestConfig } from 'axios'

import { getTokenCookies } from 'config/cookies'
import { getParsedErrorResponse } from 'helpers/errorMsg'
import { log } from 'utils/helpers/dataDogLog'
import { generateXDeviceId, getRequestXDevice } from 'utils/helpers/userAgent'

const {
  REACT_APP_BASE_URL_API,
  REACT_APP_DATADOG_LOG_HTTP_CODE,
  REACT_APP_BASE_URL_API_IMAGE_SERVICE,
  REACT_APP_NAME,
  REACT_APP_BASE_URL_KONG_API,
  REACT_APP_SYSTEM_MAINTENANCE_URL,
  REACT_APP_MAINTENANCE,
} = process.env

const commonHeadersConfig = {
  'Access-Control-Allow-Origin':
    process.env.NODE_ENV !== 'production' ? 'http://localhost:3000' : undefined,
}

export const axiosInstance = axios.create({
  baseURL: REACT_APP_BASE_URL_API,
  headers: {
    ...commonHeadersConfig,
    'Access-Control-Max-Age': 7200,
  },
})

export const axiosInstanceImageService = axios.create({
  baseURL: REACT_APP_BASE_URL_API_IMAGE_SERVICE,
  headers: {
    'X-Service-Name': REACT_APP_NAME,
  },
})

export const axiosInstanceAPIGateway = axios.create({
  baseURL: REACT_APP_BASE_URL_KONG_API,
  headers: {
    'X-Service-Name': REACT_APP_NAME,
  },
})

export const axiosErrorLoggerInterceptor = async (err: AxiosError) => {
  const errorStatusCodes = REACT_APP_DATADOG_LOG_HTTP_CODE
    ? REACT_APP_DATADOG_LOG_HTTP_CODE.split(',')
    : []

  const allowLogging =
    err.response &&
    !!errorStatusCodes.length &&
    errorStatusCodes.includes(String(err.response.status))

  if (err.isAxiosError && allowLogging) {
    const httpMethod = err.config?.method
    const httpUrl = err.config?.url
    const httpBody = err.config?.data

    // Server was able to send us a response, so this is an API Error.
    if (err.response) {
      const message = getParsedErrorResponse(err.response)

      const bodyLogMessage = `${message}. ${httpMethod} ${httpUrl}`

      log.error(bodyLogMessage, {
        customInfo: {
          origin: 'network-interceptor',
          data: {
            method: httpMethod && httpMethod.toLocaleUpperCase(),
            url: httpUrl,
            body: httpBody,
            statusCode: err.response.status,
          },
        },
      })
    }
  }
}

const handleRejectedRequest = (error: AxiosError) => {
  if (error?.request.status === 302 || REACT_APP_MAINTENANCE === 'true') {
    window.location.assign(`${REACT_APP_SYSTEM_MAINTENANCE_URL}?callback=${window.location.href}`)
  }
  axiosErrorLoggerInterceptor(error)

  return Promise.reject(error)
}

const handleRequestInterceptor = async (config: AxiosRequestConfig<unknown>) => {
  const token = getTokenCookies()
  const headers = config.headers as Exclude<typeof config.headers, undefined>
  /* This way requests are allowed to override interceptor */
  headers.Authorization =
    headers && headers.Authorization !== undefined ? headers.Authorization : `Bearer ${token}`
  config.headers = headers
  ;(config.headers as Exclude<typeof config.headers, undefined>)['x-device'] = getRequestXDevice()
  ;(config.headers as Exclude<typeof config.headers, undefined>)['x-device-id'] =
    await generateXDeviceId()
  return config
}

axiosInstance.interceptors.request.use(handleRequestInterceptor, (error) => Promise.reject(error))

axiosInstanceAPIGateway.interceptors.request.use(handleRequestInterceptor, (error) =>
  Promise.reject(error),
)

axiosInstance.interceptors.response.use((response) => {
  if (REACT_APP_MAINTENANCE === 'true') {
    window.location.assign(`${REACT_APP_SYSTEM_MAINTENANCE_URL}?callback=${window.location.href}`)
  }
  return response
}, handleRejectedRequest)

axiosInstanceImageService.interceptors.response.use((response) => response, handleRejectedRequest)

axiosInstanceAPIGateway.interceptors.response.use((response) => response, handleRejectedRequest)
