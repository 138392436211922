import { saveAs } from 'file-saver'

import {
  SoSchedulesTableHead,
  SoParametersTableHead,
  SoReportsTableHead,
} from '../constants/tableHead'
import { SOParamsDataType, SOSchedulesDataType, SOReportsDataType } from '../services/apiList'

const downloadSoAutoCsv = (
  type: 'parameters' | 'schedules' | 'reports',
  data: SOParamsDataType[] | SOSchedulesDataType[] | SOReportsDataType[],
) => {
  let content = ''

  if (type === 'parameters') {
    const mapData = [
      SoParametersTableHead.filter((head) => head.head !== 'Action')?.map((head) => head.head),
      ...(data as SOParamsDataType[])?.map((param) => [
        param.hub_id,
        param.product_id,
        param.total_allocation,
        param.optimal_stock,
        param.reorder_point,
        param.safety_stock,
        param.unit,
        param.multiplier,
        param.requester_email,
        param.wh_id,
        param.product_type,
        param.priority,
        param.so_group,
        param.status,
      ]),
    ]

    content = mapData.map((e) => e.join(',')).join('\n')
  }

  if (type === 'schedules') {
    const mapData = [
      SoSchedulesTableHead.filter((head) => head.head !== 'Action')?.map((head) => head.head),
      ...(data as SOSchedulesDataType[])?.map((schedule) => [
        schedule.id,
        schedule.product_type,
        schedule.schedule_days,
        schedule.schedule_time,
        schedule.wh_id,
        schedule.hub_ids,
        schedule.so_group,
      ]),
    ]

    content = mapData.map((e) => e.join(',')).join('\n')
  }

  if (type === 'reports') {
    const mapData = [
      SoReportsTableHead.filter((head) => head.head !== 'Action')?.map((head) => head.head),
      ...(data as SOReportsDataType[])?.map((report) => [
        report.id,
        report.file_path,
        report.created_at,
      ]),
    ]

    content = mapData.map((e) => e.join(',')).join('\n')
  }

  const csvBlob = new Blob([content], { type: 'text/csv;charset=utf-8;' })
  saveAs(csvBlob, `SO auto ${type}`)
}

export default downloadSoAutoCsv
