import { memo } from "react";
import styled from "styled-components";
import { RowCheckQty, NoData } from "components";
import { LastRow } from "./components";
import { useLocation } from "react-router-dom";

function TableCheckQty({
  data,
  handleChangeQty,
  handleChangeNote,
  noOriginStock,
  listOfOptionNotes,
  handleSelectOptionNote,
  handleClickSLOCOption,
  handleChangeKoli,
  actHandleGetSLOC,
  rawData
}) {
  const location = useLocation();

  const isEditInbound = () => {
    const pathNameArray = location.pathname.split("/");
    if (pathNameArray[2] === "editInbound") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Container>
        <thead>
          <Tr>
            <Th>NO.</Th>
            <Th>NOMOR SKU</Th>
            <Th>NAMA PRODUK</Th>
            <Th>Order QTY</Th>
            <Th>Incoming QTY</Th>

            <Th colSpan="3">
              <CenterText>Picking: {rawData?.location_origin_name}</CenterText>
            </Th>
            {/* {!noOriginStock && <Th>ORIGIN STOCK</Th>} */}
            {/* <Th>Nomer Koli</Th> */}
            <Th>
              <CenterText>Nomor Koli</CenterText>
            </Th>
            <Th>Remarks / Catatan</Th>
          </Tr>
          <Tr>
            <Th></Th>
            <Th></Th>
            <Th></Th>
            <Th></Th>
            <Th></Th>
            <Th>
              <CenterText>SLOC</CenterText>
            </Th>
            <Th>
              <CenterText>Checking Qty</CenterText>
            </Th>
            <Th>
              <CenterText>Checker</CenterText>
            </Th>
            {/* <Th></Th> */}
            <Th></Th>
            <Th></Th>

            {/* {!noOriginStock && <Th>ORIGIN STOCK</Th>} */}
          </Tr>
        </thead>
        <tbody>
          {data.map((el, index) => (
            <RowCheckQty
              data={el}
              dIndex={index}
              key={`${el.productId}--${el.supply_order_item_id}`}
              handleChangeQty={handleChangeQty}
              handleChangeNote={handleChangeNote}
              noOriginStock={noOriginStock}
              listOfOptionNotes={listOfOptionNotes}
              handleSelectOptionNote={handleSelectOptionNote}
              isEditInbound={isEditInbound()}
              handleClickSLOCOption={handleClickSLOCOption}
              actHandleGetSLOC={actHandleGetSLOC}
              handleChangeKoli={handleChangeKoli}
            />
          ))}
          <LastRow data={data} />
        </tbody>
      </Container>
      {data.length === 0 && <NoData />}
    </>
  );
}

TableCheckQty.defaultProps = {
  handleClickSLOCOption: function () {},
  actHandleGetSLOC: function () {},
  rawData: {},
  handleChangeKoli: () => {}
};

export default memo(TableCheckQty);

const Container = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #eff3f9;
  border-radius: 12px;
`;
const Tr = styled.tr`
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`;
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.toast};
  text-align: start;
  padding: 16px 32px;
`;

const CenterText = styled.p`
  text-align: center;
`;
