import { shallowEqual, useSelector } from 'react-redux'

const filterSelectState = (state: StoreStateType) => {
  const { dataPrintQR } = state.printArea
  return { dataPrintQR }
}
export const useTemplatePrintQRv2 = () => {
  const selectedState = useSelector(filterSelectState, shallowEqual)
  const { dataPrintQR } = selectedState

  const nameProduct = () => {
    const maxLength = 74
    if (dataPrintQR?.product_name.length > maxLength) {
      return `${dataPrintQR?.product_name.substr(0, maxLength)}...`
    }
    return dataPrintQR?.product_name
  }
  return {
    state: { ...selectedState, nameProduct },
  }
}
