export const SET_IS_LOADING = 'bomAddProduct/SET_IS_LOADING'
export const SET_QUERY = 'bomAddProduct/SET_QUERY'
export const RESET_QUERY = 'bomAddProduct/RESET_QUERY'
export const SET_RESET = 'bomAddProduct/SET_RESET'
export const SET_CHECKED_ALL = 'bomAddProduct/SET_CHECKED_ALL'
export const SET_CHECKED_ITEM = 'bomAddProduct/SET_CHECKED_ITEM'
export const SET_PRODUCTS = 'bomAddProduct/SET_PRODUCTS'
export const SET_CURRENT_PRODUCTS = 'bomAddProduct/SET_CURRENT_PRODUCTS'
export const SET_QTY = 'bomAddProduct/SET_QTY'
export const SET_SELECTED_PRODUCT_FOR_FILTER = 'bomAddProduct/SET_SELECTED_PRODUCT_FOR_FILTER'
