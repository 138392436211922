import {
  DynamicChannelBackgroundColorOptionType,
  ProductHighlightLabelEnum,
  ProductHighlightLabelType,
} from 'features/CategoryAndPosisi/@types/DynamicChannelAddEdit'

export const labelTypeOptions: ProductHighlightLabelType[] = [
  {
    id: ProductHighlightLabelEnum.HEMAT,
    name: 'Hemat (Auto Generated)',
  },
  {
    id: ProductHighlightLabelEnum.FREE_TEXT,
    name: 'Free Text',
  },
]

export const backgroundColorOptions: DynamicChannelBackgroundColorOptionType[] = [
  {
    value: 'biru',
    name: 'Biru',
    color: 'blue',
  },
  {
    value: 'hijau',
    name: 'Hijau',
    color: 'green',
  },
  {
    value: 'kuning',
    name: 'Kuning',
    color: 'yellow',
  },
  {
    value: 'merah',
    name: 'Merah',
    color: 'red',
  },
  {
    value: 'orange',
    name: 'Orange',
    color: 'orange',
  },
  {
    value: 'ungu',
    name: 'Ungu',
    color: 'purple',
  },
]
