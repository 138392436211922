import styled, { css } from 'styled-components'
import { layout, space, LayoutProps, SpaceProps } from 'styled-system'

export type ColumnPropsType = LayoutProps &
  SpaceProps & {
    xs?: number
    sm?: number
    md?: number
    lg?: number
    xl?: number
  }

const getWidth = (span: number) => {
  if (!span) return undefined

  const width = (span / 12) * 100
  return `width: ${width}%`
}

const Column = styled.div<ColumnPropsType>`
  ${layout}
  ${space}
  
  float: left;
  padding: 2px;

  ${({ theme, xs, sm, md, lg, xl }) => css`
    ${xs ? getWidth(xs) : 'width: 100%;'}

    @media only screen and (min-width: ${theme.breakpoints.sm}) {
      ${sm && getWidth(sm)}
    }

    @media only screen and (min-width: ${theme.breakpoints.md}) {
      ${md && getWidth(md)}
    }

    @media only screen and (min-width: ${theme.breakpoints.lg}) {
      ${lg && getWidth(lg)}
    }

    @media only screen and (min-width: ${theme.breakpoints.xl}) {
      ${xl && getWidth(xl)}
    }
  `}
`

export default Column
