import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const bomRBAC = {
  pageID: 'a2c92d92d360c92ce0878fd6958ddc51',
  elementID: {
    TableList: 'a60e9ee25f62c31fc879777a064beae3',
    Toggle: 'cea9beb3a73644f234f7c52f9471a435',
    ButtonEdit: 'c247074b5e858aedd0ad5ea12cbb6505',
    ButtonBuatBOMBaru: '1d2ec6da303158839fd6ada98bf67055',
    ButtonBOMDetail: '80859ff1c674395fa92092b24602a266',
  },
} as const

export const useRBACBomPage = createUseRBAC(bomRBAC.elementID)

export type UseRBACBomPageType = ReturnType<typeof useRBACBomPage>
