import { memo, useState } from "react";
import styled from "styled-components";
import { SystemIcon } from "components";

function Contact({ className, phoneNumber }) {
  const [appear, setAppear] = useState(false);
  return (
    <Container
      className={className}
      onMouseLeave={() => setAppear(false)}
      onMouseEnter={() => setAppear(true)}
    >
      <SystemIcon
        fontSize="headerSmaller"
        iconName="contact"
        colorIcon="text"
      />
      {appear && phoneNumber && (
        <PhoneNumber>
          <span>{phoneNumber}</span>
        </PhoneNumber>
      )}
    </Container>
  );
}

export default memo(Contact);

const Container = styled.span`
  position: relative;
`;

const PhoneNumber = styled.div`
  position: absolute;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: ${({ theme: { colors } }) => colors.black};
  color: ${({ theme: { colors } }) => colors.white};
  left: -15px;
  top: 25px;
  span {
    z-index: 1;
    position: relative;
  }
  ::before {
    content: "";
    height: 20px;
    width: 20px;
    background-color: ${({ theme: { colors } }) => colors.black};
    position: absolute;
    transform: rotate(45deg);
    border-radius: 3px;
    top: -5px;
  }
`;
