import { Delete, Get } from 'config/apiServiceConfig'

export type DeleteCategoryRequestType = {
  id: number
}

export type GetHubListResponseType = {
  data: {
    location_id: number
    location_name: string
    product_id: number
    product_image: string
    product_name: string
    product_sku_number: string
    product_price: string
    product_total_stock: number
    product_stock: number
    product_discount_stock: number
    product_reversed_stock: number
    rack_name: null
    rack_position: number
    inventory_active: boolean
    cloud_kitchen: boolean
    bom_category: string
    is_virtual_bom_component: boolean
  }[]
}

export const deleteCategory = ({ id }: DeleteCategoryRequestType) =>
  Delete({ url: `/api/category/${id.toString()}` })

export const getHubList = () => Get<GetHubListResponseType>({ url: `/api/locations/order` })
