import { createAsyncThunk } from '@reduxjs/toolkit'
import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  GetRouteConfigResponseType,
  PostOriginDestinationType,
  GetRouteMasterDetailResponseType,
  getRouteConfig,
  getRouteMasterDetail,
  postRouteMaster,
  putRouteMaster,
  GetVendorResponseType,
  VendorQueryType,
  getVendor,
} from 'utils/apiList/routeAndSchedule'

export const SLICE_NAME = 'routeAndScheduleAddEdit'

export const fetchRouteConfig = createAsyncThunk<GetRouteConfigResponseType['data']>(
  `${SLICE_NAME}/fetchRouteConfig`,
  async (_, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await getRouteConfig()

      return data
    } catch (error) {
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const addRouteMaster = createAsyncThunk<{ message: string }, undefined, RejectValueType>(
  `${SLICE_NAME}/addRouteMaster`,
  async (_, { rejectWithValue, getState }) => {
    const {
      routeAndScheduleAddEdit: { formData, routeOrigins, routeDestinations },
    } = getState() as StoreStateType
    const payload = {
      ...formData,
      routeOrigins: clearStatusAndId(routeOrigins),
      routeDestinations: clearStatusAndId(routeDestinations),
    }

    try {
      const { data } = await postRouteMaster(payload)

      return data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

const clearStatusAndId = (datas: PostOriginDestinationType[]) =>
  datas.map(({ id, status, ...data }) => {
    const updatedData = data as PostOriginDestinationType

    if (status === 'UPDATE') {
      updatedData.id = id
    }

    return updatedData
  })

export const fetchRouteMasterDetail = createAsyncThunk<
  GetRouteMasterDetailResponseType['data'],
  string | number,
  RejectValueType
>(`${SLICE_NAME}/fetchRouteMasterDetail`, async (id) => {
  try {
    const {
      data: { data },
    } = await getRouteMasterDetail(id)

    return data
  } catch (error) {
    return Promise.reject(error)
  }
})

export const updateRouteMaster = createAsyncThunk<
  { message: string },
  number | string,
  RejectValueType
>(`${SLICE_NAME}/updateRouteMaster`, async (id, { rejectWithValue, getState }) => {
  const {
    routeAndScheduleAddEdit: { formData, routeOrigins, routeDestinations },
  } = getState() as StoreStateType
  const payload = {
    ...formData,
    routeOrigins: clearStatusAndId(routeOrigins),
    routeDestinations: clearStatusAndId(routeDestinations),
  }

  try {
    const { data } = await putRouteMaster(id, payload)

    return data
  } catch (error) {
    callErrorMsg(error)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchVendor = createAsyncThunk<GetVendorResponseType['data'], VendorQueryType>(
  `${SLICE_NAME}/fetchVendor`,
  async (params, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await getVendor(params)

      return data
    } catch (error) {
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)
