export const SET_IS_START_ALLORDER = 'SET_IS_START_ALLORDER'
export const SET_IS_FAILED_ALLORDER = 'SET_IS_FAILED_ALLORDER'
export const SET_IS_SUCCESS_ALLORDER = 'SET_IS_SUCCESS_ALLORDER'
export const SET_IS_NEED_TO_RELOAD_ALLORDER = 'SET_IS_NEED_TO_RELOAD_ALLORDER'
export const SET_PAGINATION_DATA_ALLORDER = 'SET_PAGINATION_DATA_ALLORDER'
export const SET_DETAIL_DATA_ALLORDER = 'SET_DETAIL_DATA_ALLORDER'
export const SET_STATUS_DETAIL_DATA_ALLORDER = 'SET_STATUS_DETAIL_DATA_ALLORDER'
export const SET_LIST_OF_HUB_ALLORDER = 'SET_LIST_OF_HUB_ALLORDER'
export const SET_LIST_OF_DRIVER_ORDER_POOLING = 'SET_LIST_OF_DRIVER_ORDER_POOLING'
export const SET_IS_CHECKED_VERIFIY = 'SET_IS_CHECKED_VERIFIY'
export const SET_IS_DATA_EDIT_ADDRESS = 'SET_IS_DATA_EDIT_ADDRESS'
export const SET_WAITING_CONFIRMATION_ORDER_ID_FOCUS = 'SET_WAITING_CONFIRMATION_ORDER_ID_FOCUS'
export const SET_ORDER_TYPE_LIST = 'SET_ORDER_TYPE_LIST'
