import { Box, Image, Typography } from '@astro-ui/components'
import Image404 from 'assets/images/404.svg'

function FreezePage() {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
      <Box width={400} textAlign="center">
        <Image src={Image404} />
        <Typography fontWeight={700} fontSize={20} mt={24} mb={12}>
          Sedang ada Stock Opname
        </Typography>
        <Typography>
          Maaf, kamu tidak bisa mengakses halaman ini sementara. Silahkan kembali beberapa saat
        </Typography>
      </Box>
    </Box>
  )
}

export default FreezePage
