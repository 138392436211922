import { Get } from 'config/apiServiceConfig'
import { ProductHighlightLabelEnum } from '../@types/DynamicChannelAddEdit'

type GetBannerListResponseType = {
  content: {
    banner_id: number
    banner_name: string
    banner_image: string
    banner_url: string
    banner_date_start: number
    banner_date_end: number
    url_image: string
  }[]
}

export type BannerHighlightPayloadType = {
  id?: number
  category_id: number
  position: number
  hubs: number[]
  start_date: number
  end_date: number
  banner_category_highlight_image: Nullable<{
    url_large: string
    url_medium: string
    url_small: string
  }>
  is_active: boolean
  is_deleted: boolean
}

export type ProductHighlightPayloadType = {
  id?: number
  product_id: number
  position: number
  label_type: ProductHighlightLabelEnum
  hubs: number[]
  default_text: string
  free_text: string
  start_date: number
  end_date: number
  is_active: boolean
  is_deleted: boolean
}

export const getBannerList = ({ params }: { params: { name: string; pageSize: number } }) =>
  Get<GetBannerListResponseType>({ url: '/api/banner', params })
