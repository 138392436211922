import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  GetProductAssortmentLocationQueryType,
  GetPAPQueryType,
  getAllLocationByTypeAPI,
  getProductAssortmentData,
  getProductCategories,
  getProductTypes,
  postProductAssortment,
} from 'utils/apiList/productAssortment'
import { callErrorMsg } from 'helpers/errorMsg'
import { getCleanQueryObj } from 'utils/queryParamsURL'
import { getLocationTypeAPI } from 'utils/apiList/constant'
import {
  SLICE_NAME,
  setProductCategories,
  setProductTypes,
  setLoadingPerItem,
  setPaginationIndex,
  addMoreItemsFromLoadMore,
  tickAllItemsOfParentGroup,
} from './productAssortmentSlice'

export const fetchCategoryOptions = createAsyncThunk(
  `${SLICE_NAME}/fetchCategoryOptions`,
  async (_, { dispatch }) => {
    try {
      const { data } = await getProductCategories()
      const categories = data.map((category) => {
        const { category_childs = [], category_name, category_id } = category

        return {
          name: category_name,
          value: category_id,
          childs: category_childs.map((child) => ({
            name: child.category_name,
            value: child.category_id,
          })),
        }
      })

      dispatch(setProductCategories(categories))
      return categories
    } catch (error) {
      callErrorMsg(error)
      return []
    }
  },
)

export const fetchTypeOptions = createAsyncThunk(
  `${SLICE_NAME}/fetchTypeOptions`,
  async (_, { dispatch }) => {
    try {
      const {
        data: { data },
      } = await getProductTypes()
      const productTypes = data.map((type) => ({
        name: type.product_type_name,
        value: type.product_type_id,
      }))
      dispatch(setProductTypes(productTypes))
      return productTypes
    } catch (error) {
      callErrorMsg(error)
      return []
    }
  },
)

export const fetchProductAssortmentData = createAsyncThunk(
  `${SLICE_NAME}/fetchProductAssortmentData`,
  async (
    { index, isLoadMore = false }: { index: number; isLoadMore?: boolean },
    { dispatch, getState },
  ) => {
    const {
      productAssortment: { productAssortmentList, selectedProductType, skuName, status },
    } = getState() as StoreStateType
    const selected = productAssortmentList[index]
    const query = getCleanQueryObj({
      pageIndex: selected.pagination.pageIndex + 1,
      pageSize: selected.pagination.pageSize,
      active: status,
      productNameOrSku: skuName,
      productTypeId: selectedProductType?.value,
    }) as GetPAPQueryType

    dispatch(setLoadingPerItem({ index, value: true }))
    try {
      const {
        data: { data, pagination },
      } = await getProductAssortmentData(selected.categoryId, query)
      dispatch(setPaginationIndex({ index, pageIndex: pagination.pageIndex }))

      if (data.length) {
        const list = data.map((pap) => ({
          isChecked: false,
          productId: pap.productId,
          productName: pap.productName,
          skuNumber: pap.productSku,
          count: pap.totalDistribute,
        }))

        dispatch(
          addMoreItemsFromLoadMore({
            categoryId: selected.categoryId,
            list,
            resetItems: !isLoadMore,
          }),
        )
      }
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoadingPerItem({ index, value: false }))
    }
  },
)

export const checkRefetchProductAssortmentData = createAsyncThunk(
  `${SLICE_NAME}/checkRefetchProductAssortmentData`,
  (_, { dispatch, getState }) => {
    const {
      productAssortment: { productAssortmentList },
    } = getState() as StoreStateType

    if (productAssortmentList.length) {
      productAssortmentList.forEach((pap, index) => {
        dispatch(setPaginationIndex({ index, pageIndex: 0 }))
        dispatch(tickAllItemsOfParentGroup({ indexParent: index, purpose: 'untick' }))
        dispatch(
          addMoreItemsFromLoadMore({
            categoryId: pap.categoryId,
            list: [],
            resetItems: true,
          }),
        )

        if (pap.isExpanded) {
          dispatch(fetchProductAssortmentData({ index }))
        }
      })
    }
  },
)

export const fetchLocationType = createAsyncThunk(`${SLICE_NAME}/fetchLocationType`, async () => {
  try {
    const {
      data: { data },
    } = await getLocationTypeAPI()

    return data
  } catch (error) {
    callErrorMsg(error)
    return []
  }
})

export const fetchAllLocationByType = createAsyncThunk(
  `${SLICE_NAME}/fetchAllLocationByType`,
  async ({
    productId,
    query,
  }: {
    productId: number
    query: GetProductAssortmentLocationQueryType
  }) => {
    try {
      const {
        data: { data },
      } = await getAllLocationByTypeAPI(productId, query)

      return data
    } catch (error) {
      callErrorMsg(error)
      return []
    }
  },
)

export const createProductAssortmentData = createAsyncThunk(
  `${SLICE_NAME}/createProductAssortmentData`,
  async (_, { getState }) => {
    const {
      productAssortment: {
        modalConfirmation: { product },
        selectedLocationIds,
      },
    } = getState() as StoreStateType
    const payload = {
      productIds: product.map(({ productId }) => productId),
      locationIds: selectedLocationIds,
    }

    try {
      const { data } = await postProductAssortment(payload)
      return { data }
    } catch (error) {
      callErrorMsg(error)
      return { error }
    }
  },
)
