import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  getRolesListApi,
  postCreateRoleApi,
  getPagePermissionsApi,
  putCreateRoleApi,
  getRoleByIdApi,
  deleteRoleApi,
  type PostCreateRoleParamsType,
  type PutCreateRoleParamsType,
  type RoleDetailInterface,
  type PaginationInterface,
  type GetPagePermissionContentInterface,
} from 'features/Account/services/adminAccess/roles'

import { callErrorMsg } from 'helpers/errorMsg'

import { toastSuccess } from 'utils/toast'

const SLICE_NAME = 'roleAdminAccess'

export const getRolesLists = createAsyncThunk(
  `${SLICE_NAME}/getRolesLists`,
  async (_, { rejectWithValue, getState }) => {
    const {
      roleAdminAccess: { formRolesList },
    } = getState() as StoreStateType

    try {
      const res = await getRolesListApi({
        ...formRolesList,
      })
      const resData: { roles: RoleDetailInterface[]; pagination: PaginationInterface } = {
        roles: res.data.data.roles,
        pagination: res.data.data.pagination,
      }
      return resData
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const getPagePermissions = createAsyncThunk(
  `${SLICE_NAME}/getPagePermissions`,
  async (_, { rejectWithValue, getState }) => {
    const {
      roleAdminAccess: { formPagePermission },
    } = getState() as StoreStateType

    try {
      const res = await getPagePermissionsApi({
        ...formPagePermission,
      })

      const resData: {
        permissions: GetPagePermissionContentInterface[]
        pagination: PaginationInterface
      } = { permissions: res.data.data.page_permissions, pagination: res.data.data.pagination }
      return resData
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const postCreateRole = createAsyncThunk(
  `${SLICE_NAME}/postCreateRole`,
  async (data: PostCreateRoleParamsType, { dispatch, rejectWithValue }) => {
    try {
      const res = await postCreateRoleApi(data)
      const messageError = res.data.error.message
      if (messageError !== '') {
        throw new Error(messageError)
      }
      toastSuccess(`Role ${data.name} Berhasil dibuat`)
      dispatch(getRolesLists())

      return true
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const putCreateRole = createAsyncThunk(
  `${SLICE_NAME}/putCreateRole`,
  async (params: { data: PutCreateRoleParamsType; id: number }, { dispatch, rejectWithValue }) => {
    try {
      const res = await putCreateRoleApi(params.data, params.id)
      const errorMsg = res.data.error.message

      if (errorMsg !== ``) {
        throw new Error(errorMsg)
      }
      dispatch(getRolesLists())
      toastSuccess(`Role ${params.data.name} Berhasil di update`)
      return true
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const deleteRole = createAsyncThunk(
  `${SLICE_NAME}/deleteRole`,
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const res = await deleteRoleApi(id)
      const isSuccess = res.data.data.success

      if (isSuccess) {
        toastSuccess(`Role Berhasil dihapus`)
        dispatch(getRolesLists())
        return true
      }
      return false
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const getRoleById = createAsyncThunk(
  `${SLICE_NAME}/getRoleById`,
  async (id: number, { rejectWithValue }) => {
    try {
      const res = await getRoleByIdApi(id)

      return res.data.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)
