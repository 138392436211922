import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit'
import { withLoadingReducer } from 'utils/reducerHandler'

import {
  ProductAssetComponentsStatusInterface,
  ProductAssetComponentsClassificationInterface,
  ProductAssetComponentsTypeInterface,
  LocationInterface,
  AssetCategoryDataInterface,
} from 'features/AssetManagement/@types/global'

import { getAssetListsComponent, getAllLocation, getAssetCategories } from './thunk'

interface ProductAssetListListStateType {
  isLoading: boolean
  assetClassification: ProductAssetComponentsClassificationInterface[]
  assetProductType: ProductAssetComponentsTypeInterface[]
  assetStatus: ProductAssetComponentsStatusInterface[]
  assetCategories: AssetCategoryDataInterface[]
  assetRejectReason: string[]
  allLocations: LocationInterface[]
}

const initialState: ProductAssetListListStateType = {
  isLoading: false,
  assetClassification: [],
  assetProductType: [],
  assetStatus: [],
  assetRejectReason: [],
  assetCategories: [],
  allLocations: [],
}
const assetGlobalRedux = createSlice({
  name: 'assetGlobalRedux',
  initialState,
  reducers: {},
  extraReducers: withLoadingReducer(
    (builder: ActionReducerMapBuilder<ProductAssetListListStateType>) => {
      builder
        .addCase(getAssetListsComponent.fulfilled, (state, action) => {
          state.assetClassification = action.payload.classification
          state.assetProductType = action.payload.asset_type
          state.assetStatus = action.payload.asset_status
          state.assetRejectReason = action.payload.po_reject_reason
        })
        .addCase(getAllLocation.fulfilled, (state, action) => {
          state.allLocations = action.payload
        })
        .addCase(getAssetCategories.fulfilled, (state, action) => {
          state.assetCategories = action.payload
        })
    },
    [getAssetListsComponent],
  ),
})

export default assetGlobalRedux.reducer
