import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AssetPurchaseOrderDataInterface } from 'features/AssetManagement/@types/typePurchaseOrder'

interface PurchaseOrderAssetStateType {
  isLoading: boolean
  purchaseOrderList: AssetPurchaseOrderDataInterface[]
  hasNext: boolean
}

const initialState: PurchaseOrderAssetStateType = {
  isLoading: false,
  purchaseOrderList: [],
  hasNext: false,
}

const purchaseOrderAssetSlice = createSlice({
  name: 'purchaseOrderAsset',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setPurchaseOrderList: (
      state,
      action: PayloadAction<PurchaseOrderAssetStateType['purchaseOrderList']>,
    ) => {
      state.purchaseOrderList = action.payload
    },
    setHasNext: (state, action: PayloadAction<boolean>) => {
      state.hasNext = action.payload
    },
  },
})

export const { setIsLoading, setHasNext, setPurchaseOrderList } = purchaseOrderAssetSlice.actions

export default purchaseOrderAssetSlice.reducer
