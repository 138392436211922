import {
  SET_IS_START_DRIVER,
  SET_IS_FAILED_DRIVER,
  SET_IS_SUCCESS_DRIVER,
  SET_IS_NEED_TO_RELOAD_DRIVER,
  SET_PAGINATION_DATA_DRIVER,
  SET_IS_START_DRIVER_BY_ROLE,
  SET_IS_SUCCESS_DRIVER_BY_ROLE,
  SET_IS_FAILED_DRIVER_BY_ROLE,
  SET_IS_REQUEST_EXPORT_STAFF,
  SET_IS_SUCCESS_EXPORT_STAFF,
  SET_IS_FAILED_EXPORT_STAFF,
  SET_QUERY_DATA_DRIVER,
  SET_RESET_DRIVERS_DATA,
} from 'storeContext/actionsType'

export const initialStateDriver = {
  isLoading: false,
  driversData: [],
  driversPicker: [],
  exportData: {},
  exportDataLoading: false,
  currentPage: 0,
  paginationData: {},
  needToReload: 1,
  query: {
    pageIndex: 0,
    search: '',
  },
}

export default function driver(state = initialStateDriver, { type, payload }) {
  switch (type) {
    case SET_IS_START_DRIVER_BY_ROLE:
    case SET_IS_START_DRIVER:
      return {
        ...state,
        isLoading: true,
      }

    case SET_IS_REQUEST_EXPORT_STAFF:
      return {
        ...state,
        exportDataLoading: true,
      }

    case SET_IS_FAILED_DRIVER_BY_ROLE:
    case SET_IS_FAILED_DRIVER:
      return {
        ...state,
        isLoading: false,
      }

    case SET_IS_FAILED_EXPORT_STAFF:
      return {
        ...state,
        exportDataLoading: false,
      }

    case SET_IS_NEED_TO_RELOAD_DRIVER:
      return {
        ...state,
        needToReload: state.needToReload + 1,
      }

    case SET_PAGINATION_DATA_DRIVER:
      return {
        ...state,
        paginationData: payload,
      }

    case SET_IS_SUCCESS_DRIVER_BY_ROLE:
      return {
        ...state,
        driversPicker: payload.data,
      }
    case SET_IS_SUCCESS_DRIVER:
      return {
        ...state,
        isLoading: false,
        driversData: payload.data,
      }

    case SET_IS_SUCCESS_EXPORT_STAFF:
      return {
        ...state,
        exportDataLoading: false,
        exportData: payload,
      }

    case SET_QUERY_DATA_DRIVER:
      return {
        ...state,
        query: payload,
      }

    case SET_RESET_DRIVERS_DATA:
      return {
        ...state,
        driversData: payload,
      }

    default:
      return state
  }
}
