import { createAsyncThunk } from '@reduxjs/toolkit'
import { getFlexiComboList, postFlexiComboStatus } from 'features/Promotion/services/flexiCombo'
import { callErrorMsg } from 'helpers/errorMsg'
import { getCleanQueryObj } from 'utils/queryParamsURL'
import {
  FlexiComboQueryType,
  UpdateFlexiComboStatusParamsType,
} from 'features/Promotion/@types/FlexiCombo'
import { SLICE_NAME, setIsLoading, setFlexiComboList } from './slice'

export const fetchFlexiComboList = createAsyncThunk(
  `${SLICE_NAME}/fetchFlexiComboList`,
  async (_, { dispatch, getState }) => {
    const {
      flexiCombo: { query },
    } = getState() as StoreStateType

    const queryParams: Partial<FlexiComboQueryType> = { ...query }

    if (query.status === 'Semua Status') {
      queryParams.status = undefined
    }

    dispatch(setIsLoading(true))
    try {
      const {
        data: {
          data: { get_flexi_campaigns },
        },
      } = await getFlexiComboList(getCleanQueryObj(queryParams))
      dispatch(setFlexiComboList(get_flexi_campaigns))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const updateFlexiComboStatus = createAsyncThunk(
  `${SLICE_NAME}/updateFlexiComboStatus`,
  async ({ payload }: UpdateFlexiComboStatusParamsType, { dispatch }) => {
    dispatch(setIsLoading(true))
    try {
      await postFlexiComboStatus({ payload })
      await dispatch(fetchFlexiComboList())
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)
