import { ImageRulesType } from 'utils/apiList/images'
import { SegmentOptionType } from 'features/CategoryAndPosisi/components/Segment'

export type CatalogueImageStateType = {
  regular: {
    oldImage: File | null
    oldImageUrl: string
    oldImageUrlLarge: string
    oldImageUrlMedium: string
    oldImageUrlSmall: string
    oldImagePreviewUrl: string
    newImage: File | null
    newImageUrl: string
    newImageUrlLarge: string
    newImageUrlMedium: string
    newImageUrlSmall: string
    newImagePreviewUrl: string
  }
  icon: {
    image: File | null
    imageUrl: string
    imageUrlLarge: string
    imageUrlMedium: string
    imageUrlSmall: string
    imagePreviewUrl: string
  }
  big: {
    newImage: File | null
    newImageUrl: string
    newImageUrlLarge: string
    newImageUrlMedium: string
    newImageUrlSmall: string
    newImagePreviewUrl: string
  }
  vertical: {
    newImage: File | null
    newImageUrl: string
    newImageUrlLarge: string
    newImageUrlMedium: string
    newImageUrlSmall: string
    newImagePreviewUrl: string
  }
  subCatalogue: {
    image: File | null
    imageUrl: string
    imageUrlLarge: string
    imageUrlMedium: string
    imageUrlSmall: string
    imagePreviewUrl: string
  }
}
export type LocationType = {
  location_id: number
  name: string
  location_type: string
}

export type FormSegmentType = {
  type_id: number
  segment_type_id: number
  segment_type_name: string
  segment_type_total_user: number
  name: string
}

export type FormsStateType = {
  categoryLabel: string | null
  segment: FormSegmentType[]
}

export type FormBannerStateType = {
  bannerImage: string
  bannerLink: string
  bannerTitle: string
  bannerCategory: Nullable<{ category_name: string; category_id: number }>
  bannerFile: Nullable<File>
  destinationBanner: 'url' | 'category'
  endDate: string
  endTime: string
  startDate: string
  startTime: string
}

export enum CatalogueBackgroundTypeEnum {
  PLAIN = 1,
  WITH_BACKGROUND = 2,
}

export type CatalogueBackgroundFormType = {
  title: string
  bg_image_file: Nullable<File>
  bg_image_preview: string
  bg_type: CatalogueBackgroundTypeEnum
}

export type CatalogBackgroundType = {
  id: number
  title: string
  bg_image: {
    bg_url_large: string
    bg_url_small: string
    bg_url_medium: string
  }
  bg_type: CatalogueBackgroundTypeEnum
}

export type InitialStateType = {
  isLoading: boolean
  selectedHub: LocationType[]
  catalogueImage: CatalogueImageStateType
  imageRules: ImageRulesType
  imageBigRules: ImageRulesType
  imageVerticalRules: ImageRulesType
  imageBannerRules: {
    oldBanner: ImageRulesType
    newBanner: ImageRulesType
  }
  imageBackgroundRules: ImageRulesType
  forms: FormsStateType
  formBanner: FormBannerStateType
  catalogueTypes: CatalogueImageType[]
  selectedCatalogType: Nullable<CatalogueImageType>
  background: {
    isBackgroundDialogOpen: boolean
    form: CatalogueBackgroundFormType
    detail: CatalogBackgroundType | ObjectNullType
  }
  segmentOptions: SegmentOptionType[]
}

export type SetBackgroundFormPayloadActionType = {
  key?: keyof CatalogueBackgroundFormType
  value:
    | CatalogueBackgroundFormType[keyof CatalogueBackgroundFormType]
    | CatalogueBackgroundFormType
}

export type SetCatalogueImagePayloadActionType = {
  name: keyof CatalogueImageStateType
  value: CatalogueImageStateType[keyof CatalogueImageStateType]
}
export type SetImageRulesPayloadActionType = {
  value: InitialStateType['imageRules']
}

export type SetImageBannerRulesPayloadActionType = {
  value: InitialStateType['imageRules']
  name: keyof InitialStateType['imageBannerRules']
}
export type SetFormsPayloadActionType = {
  name: keyof FormsStateType
  value: FormsStateType[keyof FormsStateType]
}
export type SetFormBannerPayloadActionType = {
  name: keyof FormBannerStateType
  value: FormBannerStateType[keyof FormBannerStateType]
}

export type HandleChangeImageRegularParamsType = {
  file: File
  name: keyof Pick<CatalogueImageStateType['regular'], 'newImage' | 'oldImage'>
}
export type HandleChangeImageIconParamsType = {
  file: File
}
export type HandleChangeImageBigParamsType = {
  file: File
}

export type HandleChangeImageVerticalParamsType = {
  file: File
}

export type HandleChangeImageRegularType = (params: HandleChangeImageRegularParamsType) => void
export type HandleChangeImageIconType = (params: HandleChangeImageIconParamsType) => void
export type HandleDeleteImageRegularType = (params: {
  name: keyof Pick<CatalogueImageStateType['regular'], 'newImage' | 'oldImage'>
}) => void
export type HandleChangeImageBigType = (params: HandleChangeImageBigParamsType) => void
export type HandleDeleteImageBigType = () => void

export type HandleChangeImageVerticalType = (params: HandleChangeImageVerticalParamsType) => void
export type HandleDeleteImageVerticalType = () => void

export type CatalogueImagePickerPropType = {
  title?: string
  subTitle?: string
  imageToPreview: string
  onChange: (file: File) => void
  onDelete: () => void
  withBorder?: boolean
  textHelper?: string
}
export type ImageSectionPropsType = {
  catalogueType:
    | {
        name: 'Katalog'
        value: 1
      }
    | {
        name: 'Icon'
        value: 2
      }
    | {
        name: 'Big'
        value: 3
      }
}

export type CreateCatalogueRequestType = {
  banner_image_old: {
    category_id: number
    image_url_small: string
    image_url_medium: string
    image_url_large: string
    category_type_id: number
    version_type_id: number
  }
  banner_image_new: {
    category_id: number
    image_url_small: string
    image_url_medium: string
    image_url_large: string
    category_type_id: number
    version_type_id: number
  }
}

export type CatalogueImageType = {
  name: string
  id: number
}
