import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getHubs } from 'utils/api'
import {
  getRefund,
  getRefundDetail,
  getRejectReason,
  putUpdateRefundStatus,
  getRefundableProducts,
  postCreateRefund,
  getRefundComplaintReason,
} from 'utils/apiList/refund'
import { getImageRules, postImage } from 'utils/apiList/images'
import { toastFailed, toastSuccess } from 'utils/toast'
import { callErrorMsg } from 'helpers/errorMsg'

const SLICE_NAME = 'refunds'

const initialState = {
  params: {
    orderId: '',
    locationId: '',
    status: '',
    createdDateFrom: '',
    createdDateTo: '',
    pageSize: 20,
    pageIndex: 1,
  },
  pagination: {
    pageSize: 10,
    currentItem: 0,
    currentPage: 0,
    totalData: 0,
    last: false,
  },
  detail: null,
  form: null,
  isLoading: false,
  isOpenForm: false,
  isRejectAction: false,
  rejectReason: '',
  imagePreview: '',
  rejectReasonList: [],
  typeRefund: '',
  data: [],
  hub: [],
  isOpenPopupCreate: false,
  // create refund form data
  dataRefundableProducts: null,
  complaintReasonList: [],
  refundImageRules: null,
}

const refunds = createSlice({
  name: 'refunds',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setIsOpenForm: (state, action) => {
      state.isOpenForm = action.payload
      if (!action.payload) {
        state.form = null
        state.detail = null
      }
    },
    setData: (state, action) => {
      state.data = action.payload
    },
    setHub: (state, action) => {
      state.hub = action.payload || []
    },
    setDetail: (state, action) => {
      state.detail = action.payload
    },
    setForm: (state, action) => {
      state.form = action.payload
    },
    setPagination: (state, action) => {
      state.pagination = action.payload
    },
    setParams: (state, action) => {
      state.params = action.payload
    },
    setImagePreview: (state, action) => {
      state.imagePreview = action.payload
    },
    setIsRejectAction: (state, action) => {
      state.isRejectAction = action.payload
      if (!action.payload) {
        state.rejectReason = ''
        state.rejectReasonList = []
      }
    },
    setRejectReason: (state, action) => {
      state.rejectReason = action.payload
    },
    setRejectReasonList: (state, action) => {
      state.rejectReasonList = action.payload
    },
    setTypeRefund: (state, action) => {
      state.typeRefund = action.payload
    },
    setDataRefundableProducts: (state, action) => {
      state.dataRefundableProducts = action.payload
    },
    setIsOpenPopupCreate: (state, action) => {
      state.isOpenPopupCreate = action.payload
    },
    setComplaintReasonList: (state, action) => {
      state.complaintReasonList = action.payload
    },
    setRefundImageRules: (state, action) => {
      state.refundImageRules = action.payload
    },
    reset: (state, _) => {
      Object.assign(state, { ...initialState })
    },
  },
})

export const {
  reset,
  setPagination,
  setIsOpenForm,
  setForm,
  setDetail,
  setIsLoading,
  setData,
  setParams,
  setImagePreview,
  setIsRejectAction,
  setRejectReason,
  setRejectReasonList,
  setHub,
  setTypeRefund,
  setDataRefundableProducts,
  setIsOpenPopupCreate,
  setComplaintReasonList,
  setRefundImageRules,
} = refunds.actions

export const getData = createAsyncThunk(
  `${SLICE_NAME}/getData`,
  async (_, { dispatch, rejectWithValue, getState }) => {
    const {
      refunds: {
        params: { orderId, locationId, status, createdDateFrom, createdDateTo, ...params },
      },
    } = getState()
    try {
      dispatch(setIsLoading(true))
      dispatch(setData(initialState.data))
      dispatch(setPagination(initialState.pagination))

      const {
        data: {
          data,
          pagination: { pageSize, pageIndex, totalPages, totalElement, numberOfElements },
        },
      } = await getRefund({
        ...(orderId && { orderId }),
        ...(locationId && { locationId }),
        ...(status && { status }),
        ...((createdDateFrom || createdDateFrom > 0) && { createdDateFrom }),
        ...((createdDateTo || createdDateTo > 0) && { createdDateTo }),
        ...params,
      })
      dispatch(setData(data.refunds))
      dispatch(
        setPagination({
          pageSize,
          currentItem: numberOfElements,
          currentPage: pageIndex,
          totalData: totalElement,
          last: pageIndex === totalPages,
        }),
      )
    } catch (error) {
      toastFailed(orderId ? 'Mohon lakukan pencarian berdasarkan Order ID' : 'Gagal Load Data')
      return rejectWithValue(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const getLocationHub = createAsyncThunk(
  `${SLICE_NAME}/getLocationHub`,
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await getHubs()
      dispatch(setHub(data))
    } catch (error) {
      toastFailed('Gagal Load Data Hub')
      return rejectWithValue(error)
    }
  },
)

export const getRejectionStatus = createAsyncThunk(
  `${SLICE_NAME}/getRejectionStatus`,
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setIsLoading(true))

      const {
        data: {
          data: { refund_rejection_reason_list },
        },
      } = await getRejectReason()
      dispatch(setRejectReasonList(refund_rejection_reason_list))
      dispatch(setIsRejectAction(true))
    } catch (error) {
      toastFailed('Gagal Load Data Reject Reason')
      return rejectWithValue(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const getDetail = createAsyncThunk(
  `${SLICE_NAME}/getDetail`,
  async (id, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setIsLoading(true))
      const {
        data: { data },
      } = await getRefundDetail(id)
      dispatch(setDetail(data))
      dispatch(
        setForm({
          id: data.refund.id,
          order_id: data.refund.order_id,
          status: data.refund.status,
          status_name: data.refund.status_fmt,
          refund_method: data.refund.refund_method,
          refund_method_name: data.refund.refund_method_fmt,
          product_list: data.refund_line_items || [],
          notes: data.refund.notes || '-',
          proofs: data.refund_proofs || [],
          complaint_reason_name: data?.refund?.complaint_reason_fmt || '',
          user_level: data.user_level || '',
          refund_proof_msg: data.refund_proof_msg || '',
        }),
      )
      dispatch(setIsOpenForm(true))
    } catch (error) {
      dispatch(setIsOpenForm(false))
      toastFailed('Gagal Load Detail Refund')
      return rejectWithValue(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const updateRefundStatus = createAsyncThunk(
  `${SLICE_NAME}/putUpdateRefundStatus`,
  async ({ id, status, reject_reason, refund_method }, { dispatch, rejectWithValue, getState }) => {
    const {
      refunds: { isRejectAction },
    } = getState()

    try {
      dispatch(setIsLoading(true))

      const {
        data: {
          data: { message },
        },
      } = await putUpdateRefundStatus(id, {
        status,
        reject_reason,
        refund_method,
      })

      toastSuccess(message)
      dispatch(getData())
    } catch (err) {
      toastFailed(err?.response?.data?.error?.message || err.response.data.error_message)
    } finally {
      if (isRejectAction) dispatch(setIsRejectAction(false))
      dispatch(setIsOpenForm(false))
      dispatch(setIsLoading(false))
    }
  },
)

export const getRefundableProductsByInvoiceId = createAsyncThunk(
  `${SLICE_NAME}/getRefundableProductsByInvoiceId`,
  async (invoiceId, { dispatch, rejectWithValue }) => {
    dispatch(setDataRefundableProducts(null))

    try {
      dispatch(setIsLoading(true))
      const response = await getRefundableProducts({
        invoice: invoiceId,
      })

      dispatch(setDataRefundableProducts(response.data.data))
    } catch (err) {
      const errMessage = err?.response?.data?.error_message
      callErrorMsg(err, errMessage)

      rejectWithValue(err)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const uploadImageProof = createAsyncThunk(
  `${SLICE_NAME}/uploadImageProof`,
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await postImage('refund-images', data)

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const createRefund = createAsyncThunk(
  `${SLICE_NAME}/createRefund`,
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setIsLoading(true))
      const result = await postCreateRefund(payload)
      toastSuccess('Pengembalian pesanan berhasil dibuat')

      dispatch(setIsLoading(false))

      return result.data
    } catch (err) {
      dispatch(setIsLoading(false))

      const errMessage = err?.response?.data?.error_message
      callErrorMsg(err, errMessage)

      return rejectWithValue(err)
    }
  },
)

export const getComplaintReasons = createAsyncThunk(
  `${SLICE_NAME}/getComplaintReasons`,
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const result = await getRefundComplaintReason()

      const data = result?.data?.data?.refund_complaint_reason_list || []

      const transformedData = data.map((item) => ({
        name: item.reason,
        value: item.refund_complaint_reason_id,
        isTextMandatory: item.is_text_mandatory,
      }))

      dispatch(setComplaintReasonList(transformedData))
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar alasan refund')

      return rejectWithValue(err)
    }
  },
)

export const getRefundImageRules = createAsyncThunk(
  `${SLICE_NAME}/getRefundImageRules`,
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const result = await getImageRules('refund-images')

      const data = result?.data?.data || null

      dispatch(setRefundImageRules(data))

      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan refund image rules')

      return rejectWithValue(err)
    }
  },
)

export default refunds.reducer
