import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const DriverMonitoringRolesRBAC = {
  pageID: 'a1c47a0786e9f54ab5d06d180d1139fd',
  elementID: {
    ViewDriverMonitoring: '63760c251548f5e451de330a138be008',
  },
} as const

export const useDriverMonitoringRBACPage = createUseRBAC(DriverMonitoringRolesRBAC.elementID)

export type UseDriverMonitoringRBACType = ReturnType<typeof useDriverMonitoringRBACPage>
