export const SET_IS_LOADING = 'bomAddEdit/SET_IS_LOADING'
export const SET_QUERY = 'bomAddEdit/SET_QUERY'
export const SET_RESET = 'bomAddEdit/SET_RESET'
export const SET_BOM_ITEM = 'bomAddEdit/SET_BOM_ITEM'
export const SET_FORM = 'bomAddEdit/SET_FORM'
export const SET_COMPONENTS = 'bomAddEdit/SET_COMPONENTS'
export const SET_SELECTED_COMPONENTS = 'bomAddEdit/SET_SELECTED_COMPONENTS'
export const SET_REMOVE_COMPONENT = 'bomAddEdit/SET_REMOVE_COMPONENT'
export const SET_PRODUCTS = 'bomAddEdit/SET_PRODUCTS'
export const SET_PRODUCT_DETAIL = 'bomAddEdit/SET_PRODUCT_DETAIL'
export const SET_QTY = 'bomAddEdit/SET_QTY'
export const SET_IS_SHOW_PRODUCT_HUB = 'bomAddEdit/SET_IS_SHOW_PRODUCT_HUB'
export const SET_HUBS_BY_PRODUCT = 'bomAddEdit/SET_HUBS_BY_PRODUCT'
export const SET_FINISH_ADD_HUB_TO_PRODUCT = 'bomAddEdit/SET_FINISH_ADD_HUB_TO_PRODUCT'
export const SET_SELECTED_PRODUCT_DETAIL = 'bomAddEdit/SET_SELECTED_PRODUCT_DETAIL'
