import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootStateType } from 'store'
import {
  getTop25Sku,
  recountStoAdjustment,
  postSubmitStockOpname,
  getReviewStoGroup,
  getReviewStoChild,
  StockOpnameReviewDataItemType,
} from 'utils/apiList/stockOpname'
import { callErrorMsg } from 'helpers/errorMsg'
import { toastSuccess } from 'utils/toast'

import {
  getProductIdsWithConditionIsSelectAll,
  getParentAndChildItemIdIfGapOnly as getParentAndChildItemIdIfGapOnlyV2,
} from './stockOpnameCheckAdjustmentUtilV2'

const SLICE_NAME = 'stockOpnameCheckAdjustment'

export const recountStockOpname = createAsyncThunk(
  `${SLICE_NAME}/recountStockOpname`,
  async ({ stockOpnameGroupId }: { stockOpnameGroupId: number }, { rejectWithValue, getState }) => {
    const {
      stockOpnameCheckAdjustment: {
        selectedParentProductId,
        selectedStockOpnameReviewState: { isGapOnly, isSelectedAll },
        excludedParentProductId,
      },
    } = getState() as StoreStateType

    const typeRecount = () => {
      if (isSelectedAll) {
        return 'ALL'
      }

      if (isGapOnly) {
        return 'GAP'
      }

      return 'SELECTED'
    }

    try {
      const res = await recountStoAdjustment(stockOpnameGroupId, {
        ids: typeRecount() === 'SELECTED' ? [...selectedParentProductId] : [],
        type: typeRecount(),
        excludeIds: [...excludedParentProductId],
      })

      toastSuccess(res.data.message)
      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const submitStockOpname = createAsyncThunk(
  `${SLICE_NAME}/SubmitStockOpname`,
  async ({ groupId }: { groupId: number }, { rejectWithValue }) => {
    try {
      const res = await postSubmitStockOpname({ groupId })
      toastSuccess(res.data.message)
      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchTop25SkuList = createAsyncThunk(
  `${SLICE_NAME}/getTop25Sku`,
  async (stoId: number, { getState, rejectWithValue }) => {
    const {
      modalTop25Gap: { queryParams },
    } = (getState() as RootStateType).stockOpnameCheckAdjustment
    try {
      const {
        data: { data },
      } = await getTop25Sku(stoId, queryParams as never)
      return data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const submitTop25SkuList = createAsyncThunk(
  `${SLICE_NAME}/submitTop25SkuList`,
  async (stoGroupId: number, { getState, rejectWithValue }) => {
    const {
      modalTop25Gap: { selectedIds },
    } = (getState() as StoreStateType).stockOpnameCheckAdjustment

    try {
      const { data } = await recountStoAdjustment(stoGroupId, {
        type: 'SELECTED',
        ids: selectedIds,
        excludeIds: [],
      })
      return data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

type FetchStoReviewListType = {
  type: 'group' | 'item'
  id: string
}

export const fetchStoReviewList = createAsyncThunk(
  `${SLICE_NAME}/fetchStoReviewList`,
  async ({ type, id }: FetchStoReviewListType, { getState, rejectWithValue }) => {
    const {
      paramStockOpnameReviewDataV2,
      selectedStockOpnameReviewState,
      excludedParentProductId,
      selectedParentProductId,
      selectedChildItemId,
    } = (getState() as StoreStateType).stockOpnameCheckAdjustment

    const apiToCall = type === 'group' ? getReviewStoGroup : getReviewStoChild
    try {
      const { data } = await apiToCall({
        params: paramStockOpnameReviewDataV2,
        stockOpnameId: id,
      })

      let listParentProductIdToAdd: number[] = []
      let listChildIdToAdd: string[] = []

      if (selectedStockOpnameReviewState.isSelectedAll) {
        const { listProductIdToAdd, listChildIdToAdd: childIdsToAdd } =
          getProductIdsWithConditionIsSelectAll<StockOpnameReviewDataItemType[]>(
            data.data.list,
            excludedParentProductId,
            selectedParentProductId,
          )
        listParentProductIdToAdd = [...listProductIdToAdd]
        listChildIdToAdd = [...childIdsToAdd]
      }

      if (selectedStockOpnameReviewState.isGapOnly) {
        const { listChildIdToAdd: childIdsToAdd, listProductIdToAdd } =
          getParentAndChildItemIdIfGapOnlyV2<StockOpnameReviewDataItemType[]>(
            data.data.list,
            selectedChildItemId,
            excludedParentProductId,
            selectedParentProductId,
          )
        listParentProductIdToAdd = [...listProductIdToAdd]
        listChildIdToAdd = [...childIdsToAdd]
      }

      return {
        responseData: data,
        listParentProductIdToAdd,
        listChildIdToAdd,
      }
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
