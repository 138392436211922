import { memo, useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { InputDropDown } from "components";

const listDropDown = [
  {
    name: "Atur"
  },
  {
    name: "View Detail"
  },
  {
    name: "View PO"
  }
];

const mockup = [
  {
    name: "Putaway",
    link: "/dashboard/put-away",
    planned: 100,
    completed: 90,
    pending: 5,
    discrepancies: 5
  },
  {
    name: "Receiving",
    link: "/dashboard/receiving",
    planned: 100,
    completed: 90,
    pending: 5,
    discrepancies: 5
  },
  {
    name: "Damage Putaway",
    link: "/dashboard/damage-put-away",
    planned: 100,
    completed: 90,
    pending: 5,
    discrepancies: 5
  },
  {
    name: "GRN",
    link: "/dashboard/grn",
    planned: 100,
    completed: 90,
    pending: 5,
    discrepancies: 5
  }
];

function RowTableActivityMonitoring() {
  /**
   * This to store the selected dropdown inside our table
   */
  const [selectedAction, setSelectedAction] = useState([
    "Atur",
    "Atur",
    "Atur",
    "Atur",
    "Atur"
  ]);

  return mockup.map((value, index) => {
    const { name, planned, completed, pending, discrepancies, link } = value;
    return (
      <Tr key={index}>
        <Td>{index + 1}.</Td>
        <Td color="mainV2">
          <Link to={link}>{name}</Link>
        </Td>
        <Td center>{planned}</Td>
        <Td center>{completed}</Td>
        <Td center>{pending}</Td>
        <Td center>{discrepancies}</Td>
        <Td>
          <InputDropDown
            handleClickOption={(e) => {
              setSelectedAction([
                ...selectedAction,
                (selectedAction[index] = e.name)
              ]);
            }}
            listOptions={listDropDown}
            disabled
            value={selectedAction[index]}
          />
        </Td>
      </Tr>
    );
  });
}

RowTableActivityMonitoring.defaultProps = {};

export default memo(RowTableActivityMonitoring);

const Tr = styled.tr`
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
  .input-container {
    width: calc(100% - 20px);
  }
`;

const Td = styled.td`
  color: ${({ theme: { colors }, color = "textSoft" }) => colors[color]};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  padding: 16px 32px;
  min-width: ${({ min }) => min};
  ${({ center }) =>
    center &&
    css`
      text-align: center;
    `};
  .input-drop-down {
    width: 130px;
    input {
      padding: 6px 0;
    }
  }
`;

// const StyledInputDropdown = styled(InputDropDown)`
//   /* width: 110px; */
// `;
