import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  SLICE_NAME,
  fetchDynamicLanding,
  updateDynamicLandingStatus,
  duplicateDynamicLanding,
  removeDynamicLanding,
} from './dynamicLandingListThunk'

export type ModalDataType = {
  type: 'delete'
  info: {
    data: { id: number; name: string }
  }
}

export type DynamicLandingDataListStateType = {
  id: number
  uniqueKey: string
  name: string
  urlDomain: string
  iosLink: string
  androidLink: string
  seoStatus: {
    id: number
    name: string
  }
  ogImageId: number
  ogImageUrl: string
  metaDescription: string
  metaKeyword: string
  isActive: boolean
  components: {
    id: number
    type: string
  }[]
}
export interface DynamicLandingListStateType {
  isLoading: boolean
  modalData: ModalDataType | null
  query: {
    name: string
    pageIndex: number
    pageSize: number
  }
  needToReload: number
  dataList: DynamicLandingDataListStateType[]
}

export const initialState: DynamicLandingListStateType = {
  isLoading: false,
  modalData: null,
  query: { name: '', pageIndex: 1, pageSize: 20 },
  needToReload: 0,
  dataList: [],
}

const dynamicLandingSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setQuery: (
      state,
      action: PayloadAction<{
        fieldName: keyof DynamicLandingListStateType['query']
        value: string | number
        reload?: boolean
      }>,
    ) => {
      const { fieldName, value, reload } = action.payload
      state.query[fieldName] = value as never

      if (reload === undefined) {
        state.needToReload += 1
      }
    },
    setModalData: (state, action: PayloadAction<ModalDataType | null>) => {
      state.modalData = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDynamicLanding.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchDynamicLanding.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchDynamicLanding.fulfilled, (state, action) => {
        state.isLoading = false

        state.query = {
          ...state.query,
          pageIndex: action.payload.number,
        }

        state.dataList = action.payload.content.map((el) => ({
          ...el,
          uniqueKey: window.crypto.randomUUID(),
        }))
      })

      .addCase(updateDynamicLandingStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateDynamicLandingStatus.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateDynamicLandingStatus.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(duplicateDynamicLanding.pending, (state) => {
        state.isLoading = true
      })
      .addCase(duplicateDynamicLanding.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(duplicateDynamicLanding.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(removeDynamicLanding.pending, (state) => {
        state.isLoading = true
      })
      .addCase(removeDynamicLanding.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(removeDynamicLanding.fulfilled, (state) => {
        state.isLoading = false
      })
  },
})

export const { reset, setQuery, setModalData } = dynamicLandingSlice.actions
export default dynamicLandingSlice.reducer
