import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toastSuccess } from 'utils/toast'
import {
  getWarehouseList,
  getSourcingPicList,
  getPurchaseRequestList,
  exportPurchaseRequestList,
  cancelPurchaseRequestById,
  getPurchaseRequestType,
} from 'utils/apiList/purchaseRequest'
import { getCleanQueryObj, queryParamsURL } from 'utils/queryParamsURL'
import { callErrorMsg } from 'helpers/errorMsg'
import { capitalizeEachWord } from 'helpers'

const SLICE_NAME = 'purchaseRequest'

export const getPurchaseRequestTypeList = createAsyncThunk(
  `${SLICE_NAME}/getPurchaseRequestTypeList`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await getPurchaseRequestType()
      return response.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const exportPurchaseRequest = createAsyncThunk(
  `${SLICE_NAME}/exportPurchaseRequest`,
  async (_, { getState, rejectWithValue }) => {
    const { query } = getState().purchaseRequest
    const cleanQueryObj = getCleanQueryObj(query)

    try {
      const response = await exportPurchaseRequestList(cleanQueryObj)
      toastSuccess(response.data.message)
      return
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchWarehouseList = createAsyncThunk(
  `${SLICE_NAME}/fetchWarehouseList`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await getWarehouseList()
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchUserList = createAsyncThunk(
  `${SLICE_NAME}/fetchUserList`,
  async (params, { rejectWithValue }) => {
    try {
      const response = await getSourcingPicList(params)
      return response.data.content
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchPurchaseRequest = createAsyncThunk(
  `${SLICE_NAME}/fetchPurchaseRequest`,
  async (_, { getState, rejectWithValue }) => {
    const { query } = getState().purchaseRequest

    const cleanQueryObj = getCleanQueryObj(query)
    try {
      const response = await getPurchaseRequestList(cleanQueryObj)
      queryParamsURL.set(query)
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const cancelPurchaseRequest = createAsyncThunk(
  `${SLICE_NAME}/cancelPurchaseRequest`,
  async (id, { rejectWithValue }) => {
    try {
      await cancelPurchaseRequestById(id)
      window.location.reload()
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

const initialState = {
  isLoading: true,
  selectedDate: '',
  prTypeList: [],
  query: {
    pageIndex: 1,
    pageSize: 20,
    purchaseRequestNo: '',
    locationId: '',
    productSkuNo: '',
    date: '',
    sourcingPic: '',
    status: '',
    type: '',
  },
  selectedSouringPicName: '',
  pagination: {
    page: 0,
    limit: 20,
    totalData: 0,
  },
  warehouseList: [],
  sourcingPic: {
    selectedName: '',
    listData: [],
    query: {
      pageSize: 10,
      pageIndex: 0,
      name: '',
    },
  },
  prType: {
    selectedName: '',
  },
  purchaseRequestList: [],
  purchaseReqToCancel: {
    id: 0,
    purchaseRequestNo: '',
  },

  isModalCancelOpen: false,
  isCancellingPurchaseRequest: false,
}

export const purchaseRequest = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload
    },
    setSelectedSourcingPicName: (state, action) => {
      state.sourcingPic.selectedName = action.payload
    },
    setSelectedPrTypeName: (state, action) => {
      state.prType.selectedName = action.payload
    },
    setQuery: (state, action) => {
      const { fieldName, value } = action.payload
      state.query[fieldName] = value
    },
    resetQuery: (state) => {
      state.query = initialState.query
      state.selectedDate = initialState.selectedDate
    },
    toggleModalCancelConfirmation: (state, action) => {
      state.isModalCancelOpen = action.payload
    },
    setPurchaseReqToCancel: (state, action) => {
      const { id, purchaseRequestNo } = action.payload
      state.purchaseReqToCancel.purchaseRequestNo = purchaseRequestNo
      state.purchaseReqToCancel.id = id
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPurchaseRequest.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPurchaseRequest.fulfilled, (state, action) => {
        state.isLoading = false
        state.purchaseRequestList = action.payload.data
        state.pagination.page = action.payload.pagination.pageIndex
        state.pagination.limit = action.payload.pagination.pageSize
        state.pagination.totalData = action.payload.pagination.numberOfElements
      })
      .addCase(fetchPurchaseRequest.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchWarehouseList.fulfilled, (state, action) => {
        state.warehouseList = action.payload.map((item) => ({
          name: item.location_name,
          value: item.location_id,
        }))
      })
      .addCase(fetchUserList.fulfilled, (state, action) => {
        state.sourcingPic.listData = action.payload.map((userData) => ({
          value: userData.id,
          name: userData.full_name,
        }))
      })
      .addCase(cancelPurchaseRequest.pending, (state) => {
        state.isCancellingPurchaseRequest = true
      })
      .addCase(cancelPurchaseRequest.rejected, (state, action) => {
        state.isCancellingPurchaseRequest = false
      })
      .addCase(getPurchaseRequestTypeList.fulfilled, (state, action) => {
        state.prTypeList = action.payload.map((item) => ({
          name: capitalizeEachWord(item, '_'),
          value: item,
        }))
      })
  },
})

export const {
  resetState,
  setSelectedDate,
  setQuery,
  resetQuery,
  toggleModalCancelConfirmation,
  setPurchaseReqToCancel,
  setSelectedSourcingPicName,
  setSelectedPrTypeName,
} = purchaseRequest.actions
export default purchaseRequest.reducer
