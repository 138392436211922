import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'

import { callErrorMsg } from 'helpers/errorMsg'
import { unixToDateObject } from 'utils/helpers/date'
import {
  getPromoWidget,
  postPromooWidget,
  type PromoWidgetType,
  type PostPromoWidgetPayloadType,
} from 'utils/apiList/promoWidget'
import {
  getImageRules,
  postImage,
  type ImageRulesType,
  type ImageVariantIdType,
  type PostImageRequestPayload,
} from 'utils/apiList/images'
import { toastSuccess } from 'utils/toast'

export type { ImageRulesType, PostPromoWidgetPayloadType }

const SLICE_NAME = 'promoWidget'

export type ImageFileVariantType = 'background' | 'thumbnail'

export interface PromoWidgetStateInterface {
  isLoading: boolean
  promoWidgetData: PromoWidgetType | null
  constants: {
    promoWidgetBannerRules: ImageRulesType | null
    promoWidgetImageRules: ImageRulesType | null
  }
  form: {
    backgroundImageFilePath: string
    backgroundImageUrl: string
    title: string
    description: string
    thumbnailFilePath: string
    thumbnailUrl: string
    link: string
    startDate: string
    endDate: string
    startTime: string
    endTime: string
  }
}

export const initialState: PromoWidgetStateInterface = {
  isLoading: false,
  promoWidgetData: null,
  constants: {
    promoWidgetBannerRules: null,
    promoWidgetImageRules: null,
  },
  form: {
    backgroundImageFilePath: '',
    backgroundImageUrl: '',
    title: '',
    description: '',
    thumbnailFilePath: '',
    thumbnailUrl: '',
    link: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
  },
}

const promoWidgetSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setForm: (
      state,
      action: PayloadAction<{ key: keyof PromoWidgetStateInterface['form']; value: unknown }>,
    ) => {
      const { payload } = action

      state.form = {
        ...state.form,
        [payload.key]: payload.value,
      }
    },
    setConstants: (
      state,
      action: PayloadAction<{
        key: keyof PromoWidgetStateInterface['constants']
        value: ImageRulesType | null
      }>,
    ) => {
      const { payload } = action

      state.constants = {
        ...state.constants,
        [payload.key]: payload.value,
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPromoWidget.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPromoWidget.fulfilled, (state, action: PayloadAction<PromoWidgetType>) => {
        const data = action.payload
        state.promoWidgetData = data

        const parsedStartTime = unixToDateObject(data.startTime / 1000)
        const parsedEndTime = unixToDateObject(data.endTime / 1000)

        state.form = {
          ...state.form,
          backgroundImageFilePath: data.backgroundImageFilePath,
          backgroundImageUrl: data.backgroundImageURL,
          title: data.title,
          description: data.description,
          thumbnailFilePath: data.thumbnailFilePath,
          thumbnailUrl: data.thumbnailURL,
          link: data.link,
          startDate: parsedStartTime.format('YYYY-MM-DD'),
          endDate: parsedEndTime.format('YYYY-MM-DD'),
          startTime: parsedStartTime.format('HH:mm'),
          endTime: parsedEndTime.format('HH:mm'),
        }
        state.isLoading = false
      })
      .addCase(fetchPromoWidget.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchBannerImageRules.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchBannerImageRules.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchBannerImageRules.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchThumbnailImageRules.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchThumbnailImageRules.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchThumbnailImageRules.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(uploadImage.pending, (state) => {
        state.isLoading = true
      })
      .addCase(uploadImage.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(uploadImage.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(createPromoWidget.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createPromoWidget.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(createPromoWidget.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const { setForm, setConstants } = promoWidgetSlice.actions
export default promoWidgetSlice.reducer

export const fetchPromoWidget = createAsyncThunk(
  `${SLICE_NAME}/fetchPromoWidget`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getPromoWidget()

      return data?.data || null
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchBannerImageRules = createAsyncThunk(
  `${SLICE_NAME}/fetchBannerImageRules`,
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const result = await getImageRules('promo-widget-banners')

      const data = result?.data?.data || null

      dispatch(setConstants({ key: 'promoWidgetBannerRules', value: data }))

      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan banner image rules')

      return rejectWithValue(err)
    }
  },
)

export const fetchThumbnailImageRules = createAsyncThunk(
  `${SLICE_NAME}/fetchThumbnailImageRules`,
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const result = await getImageRules('promo-widget-images')

      const data = result?.data?.data || null

      dispatch(setConstants({ key: 'promoWidgetImageRules', value: data }))

      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan thumbnail image rules')

      return rejectWithValue(err)
    }
  },
)

export const uploadImage = createAsyncThunk(
  `${SLICE_NAME}/uploadImage`,
  async (
    payload: {
      typeId: ImageVariantIdType
      data: PostImageRequestPayload
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await postImage(payload.typeId, payload.data)

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const createPromoWidget = createAsyncThunk(
  `${SLICE_NAME}/createPromoWidget`,
  async (payload: PostPromoWidgetPayloadType, { rejectWithValue }) => {
    try {
      const response = await postPromooWidget(payload)

      toastSuccess('Widget berhasil ditambahkan')

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
