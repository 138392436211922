import { Backdrop, CircularProgress } from '@astro-ui/components'

export type LoadingOverlayPropsType = {
  isLoading: boolean
}

const LoadingOverlay = ({ isLoading }: LoadingOverlayPropsType) => (
  <Backdrop open={isLoading} sx={{ zIndex: 1999 }}>
    <CircularProgress color="inherit" />
  </Backdrop>
)

export default LoadingOverlay
