import { useEffect } from "react";
import useSWR from "swr";

import { getAllCategoriesChild } from "utils/api";
import { toastFailed } from "utils/toast";

const useGetAllCategories = () => {
  const { data, error } = useSWR("/api/categories/child", () =>
    getAllCategoriesChild().then((res) => res.data)
  );

  const isLoading = !data && !error;

  useEffect(() => {
    if (error) {
      toastFailed("Something error when get all category");
    }
  }, [error]);

  return {
    data,
    isLoading,
    error
  };
};

export default useGetAllCategories;
