import React from 'react'
import { CustomTheme } from '@mui/material'
import { ThemeProvider } from 'styled-components'

import defaultTheme from '../theme'
import injectGlobalStyles from './injectGlobalStyles'

export interface AstroUIProviderPropsType {
  /**
   * If you want to extend the global styles set to `true` and inject them
   * manually via `injectGlobalStyles`.
   */
  disableInjection?: boolean
  /** Custom theme object. */
  theme?: CustomTheme
  children: React.ReactNode
}

const { GlobalStyles } = injectGlobalStyles()

const AstroUIProvider: React.FC<AstroUIProviderPropsType> = ({
  children,
  disableInjection,
  theme = defaultTheme,
}) => (
  <ThemeProvider theme={theme}>
    {!disableInjection && <GlobalStyles />}
    {children}
  </ThemeProvider>
)

AstroUIProvider.displayName = 'AstroUIProvider'

export default AstroUIProvider
