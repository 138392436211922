import colors, { ColorsType } from './colors'
import breakpoints, { BreakpointsInterface } from './breakpoints'
import fonts, { FontsType } from './fonts'

export type { ColorsType, BreakpointsInterface, FontsType }

const fondations = {
  colors,
  breakpoints,
  ...fonts,
}

export { colors, breakpoints, fonts }
export default fondations
