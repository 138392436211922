import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const SupplyOrderDetailRBACPage = {
  pageID: '91fdb041c904375973ae6545dbdfc249',
  elementID: {
    AcceptOrRejectButton: 'd77a6839e1333d4cf054999a3a5b1cb1',
    SaveButton: '7f436c15dc508647b70140bd41b64ecf',
  },
} as const

export const useSupplyOrderDetailRBACPagePage = createUseRBAC(SupplyOrderDetailRBACPage.elementID)

export type UseSupplyOrderDetailRBACPagePagePageType = ReturnType<
  typeof useSupplyOrderDetailRBACPagePage
>
