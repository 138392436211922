import { Get, Post } from 'config/apiServiceConfig'

export type ErrorType = {
  status: boolean
  message: string
  code: number
}

export type PaginationType = {
  sort: string
  direction: string
  pageSize: number
  pageIndex: number
  totalElement: number
  numberOfElements: number
  totalPages: number
}

export type GetFraudReferralListResponseType = {
  data: {
    responses: {
      customerId: number
      counter: number
      createdAt: string
      isReferralFraud: boolean
      campaignReferralId: number
    }[]
  }
  error: ErrorType
  pagination: PaginationType
}

export type GetFraudReferralListRequestType = {
  params: {
    campaignReferralId: number
    customerId?: number
    pageSize?: number
    pageIndex?: number
    sort?: string
    direction?: string
  }
}

export type PostReleaseFraudReferralRequestType = {
  payload: {
    customerId: number
    campaignReferralId: number
  }
}

export const getFraudReferralList = ({ params }: GetFraudReferralListRequestType) =>
  Get<GetFraudReferralListResponseType>({
    url: '/fraud/referral',
    params,
  })

export const postReleaseFraudReferral = ({ payload }: PostReleaseFraudReferralRequestType) =>
  Post({ url: '/fraud/referral/release', data: payload })
