export const getValueInDecimal = (value: number) => {
  if (value) {
    const isValueContainsComma = value.toString().includes('.')
    const splittedNumber = value.toString().split('.')

    const numberString: string = splittedNumber[0]
    const rest = numberString.length % 3
    let rupiah = numberString.substring(0, rest)
    const thousand = numberString.substring(rest).match(/\d{3}/g)

    if (thousand) {
      const separator = rest ? '.' : ''
      rupiah += separator + thousand.join('.')
    }
    if (isValueContainsComma) return rupiah.concat(`,${splittedNumber[1]}`)
    return rupiah
  }
  return 0
}

export const isDecimal = (value: number) => {
  const result = value.toString().split('.')
  return result.length > 1
}

export const decimalToFix = (value: number, fixNumber: number) => {
  if (isDecimal(value)) return value.toFixed(fixNumber)
  return value
}

export const tidyNumber = (value: number) => {
  const formatter = new Intl.NumberFormat('id-ID', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  return formatter.format(value)
}
