import styled from 'styled-components'
import { TextBody, Input } from 'components'

const InputFormWithLabel = ({
  label,
  mandatory,
  className = '',
  textLabelProps,
  styleContainer,
  noSpan,
  ...rest
}) => {
  return (
    <Container style={{ ...styleContainer }} className={`input-form-with-label ${className}`}>
      {label && (
        <Label className="label-input">
          <TextBody color="textSoft" {...textLabelProps}>
            {label}
          </TextBody>
        </Label>
      )}
      {!noSpan && <Span>{mandatory}</Span>}
      <Input {...rest} />
    </Container>
  )
}

InputFormWithLabel.defaultProps = {
  label: '',
  textLabelProps: {},
  styleContainer: {},
  noSpan: false,
}

export default InputFormWithLabel

const Container = styled.div``
const Label = styled.div`
  display: inline-block;
  margin-bottom: 7px;
`
const Span = styled.span`
  color: #f08227;
  margin-left: 5px;
  margin-bottom: 7px;
`
