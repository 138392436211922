import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import {
  createCampaignLoyalty,
  SLICE_NAME,
  fetchCampaignLoyaltyById,
  updateCampaignLoyaltyById,
  fetchCampaignLoyaltyProductCandidateList,
  fetchCampaignLoyaltyProductList,
  removeCampaignLoyaltyProductById,
  updateCampaignLoyaltyProductById,
  fetchLocationHubList,
  type CampaignVariantType,
} from './campaignLoyaltyAddAndEditThunk'

const FORMAT_DATE = 'YYYY-MM-DD'
const FORMAT_TIME = 'HH:mm'

export interface FormCampaignStateType {
  startDate: string
  id: number
  endDate: string
  startTime: string
  endTime: string
  name: string
  isFullDay: boolean
  selectedCampaignType: CampaignVariantType | null
}

export type LocationHubType = {
  location_id: number
  location_name: string
  location_type: string
  name: string
}

export type QueryType = {
  pageSize: number
  pageIndex: number
  name: string
  location: LocationHubType
}

export type ProductListType = {
  id: number
  uniqueId: string
  locationId: number
  locationName: string
  productId: number
  productName: string
  coin: number
  productQuota: number
  discountCoin: number
  discountPercentage: number
  quota: number
  userQuota: number
  isSpecial: boolean
}

export interface CampaignLoyaltyListStateType {
  isLoading: boolean
  formCampaign: FormCampaignStateType
  productList: {
    data: ProductListType[]
    isLoading: boolean
  }
  query: QueryType
  locationHubList: LocationHubType[]
}

export const initialState: CampaignLoyaltyListStateType = {
  isLoading: false,
  query: {
    pageIndex: 0,
    pageSize: 20,
    name: '',
    location: { location_id: 0, location_name: '', location_type: '', name: '' },
  },
  formCampaign: {
    name: '',
    id: 0,
    startDate: dayjs().format(FORMAT_DATE),
    endDate: dayjs().format(FORMAT_DATE),
    startTime: dayjs().format(FORMAT_TIME),
    endTime: dayjs().format(FORMAT_TIME),
    isFullDay: false,
    selectedCampaignType: null,
  },
  productList: { data: [], isLoading: false },
  locationHubList: [],
}

const campaignLoyaltyList = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setQuery: (
      state,
      action: PayloadAction<{
        name: keyof QueryType
        value: number | string | LocationHubType
      }>,
    ) => {
      const { name, value } = action.payload

      state.query[name] = value as never
    },
    setFormCampaign: (
      state,
      action: PayloadAction<{
        name: keyof FormCampaignStateType
        value: string | number | boolean
      }>,
    ) => {
      const { name, value } = action.payload
      if (name === 'isFullDay') {
        state.formCampaign[name] = value as never
        state.formCampaign = {
          ...state.formCampaign,
          startDate: dayjs().format(FORMAT_DATE),
          endDate: dayjs().format(FORMAT_DATE),
          startTime: '00:00',
          endTime: dayjs().format('23:59'),
        }
      } else {
        state.formCampaign[name] = value as never
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createCampaignLoyalty.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createCampaignLoyalty.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(createCampaignLoyalty.fulfilled, (state) => {
        state.isLoading = false
      })

      .addCase(updateCampaignLoyaltyById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateCampaignLoyaltyById.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateCampaignLoyaltyById.fulfilled, (state) => {
        state.isLoading = false
      })

      .addCase(fetchCampaignLoyaltyProductCandidateList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchCampaignLoyaltyProductCandidateList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchCampaignLoyaltyProductCandidateList.fulfilled, (state) => {
        state.isLoading = false
      })

      .addCase(fetchCampaignLoyaltyById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchCampaignLoyaltyById.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchCampaignLoyaltyById.fulfilled, (state, action) => {
        const { data } = action.payload
        state.isLoading = false
        state.formCampaign = {
          ...data,
          endDate: data.endDate,
          endTime: data.endTime,
          startDate: data.startDate,
          startTime: data.startTime,
          isFullDay: false,
          name: data.name,
          selectedCampaignType: data.campaignType,
        }
      })
      .addCase(fetchCampaignLoyaltyProductList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchCampaignLoyaltyProductList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchCampaignLoyaltyProductList.fulfilled, (state, action) => {
        state.isLoading = false

        state.productList.data = action.payload.data.campaignProducts.map((el) => ({
          ...el,
          uniqueId: window.crypto.randomUUID(),
        }))
      })

      .addCase(removeCampaignLoyaltyProductById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(removeCampaignLoyaltyProductById.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(removeCampaignLoyaltyProductById.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(updateCampaignLoyaltyProductById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateCampaignLoyaltyProductById.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateCampaignLoyaltyProductById.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchLocationHubList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchLocationHubList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchLocationHubList.fulfilled, (state, actions) => {
        state.locationHubList = actions.payload.map((el) => ({ ...el, name: el.location_name }))
        state.isLoading = false
      })
  },
})

export const { reset, setFormCampaign, setQuery } = campaignLoyaltyList.actions
export default campaignLoyaltyList.reducer
