export const SoParametersTableHead = [
  { head: 'Hub ID', key: 'hub_id' },
  { head: 'Product ID', key: 'product_id' },
  { head: 'Max Qty on Hub', key: 'total_allocation' },
  { head: 'Optimal Stock', key: 'optimal_stock' },
  { head: 'Reorder Point', key: 'reorder_point' },
  { head: 'Safety Stock', key: 'safety_stock' },
  { head: 'Unit', key: 'unit' },
  { head: 'Multiplier', key: 'multiplier' },
  { head: 'Requester Email', key: 'requester_email' },
  { head: 'WH Code', key: 'wh_id' },
  { head: 'Type', key: 'product_type' },
  { head: 'Priority', key: 'priority' },
  { head: 'Group', key: 'so_group' },
  { head: 'Status', key: 'status' },
]

export const SoSchedulesTableHead = [
  { head: 'Schedule ID', key: 'id' },
  { head: 'Type', key: 'product_type' },
  { head: 'Days', key: 'schedule_days' },
  { head: 'Times', key: 'schedule_time' },
  { head: 'WH', key: 'wh_id' },
  { head: 'Hub', key: 'hub_ids' },
  { head: 'Group', key: 'so_group' },
  { head: 'Action', key: 'Delete' },
]

export const SoReportsTableHead = [
  { head: 'ID', key: 'id' },
  { head: 'Report File', key: 'file_path' },
  { head: 'Date', key: 'created_at' },
  { head: 'Action', key: 'Download' },
]
