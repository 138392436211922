import axiosInstanceApiGateway from 'config/apiServiceApiGateway'
import axiosInstanceFileService from 'config/apiServiceFile'

export type ReasonType = {
  id: number | 'other'
  reason: string
}

export type GetRequestReasonResponseType = {
  data: ReasonType[]
  error?: {
    message: string
    code: number
    status: boolean
  }
}

export const getRequestReason = () =>
  axiosInstanceApiGateway.Get<GetRequestReasonResponseType, true>({
    url: '/pricing-engine/internal/v1/reasons/request',
  })

export type PriceChangeItemType = {
  price_before: string
  price_after: string
}

export type ValidatePricePayloadType = {
  data: PriceChangeItemType[]
}

export type ValidatePriceResponseType = {
  message: string
  error?: {
    message: string
    code: number
    status: boolean
  }
}

export const postValidatePrice = (payload: ValidatePricePayloadType) =>
  axiosInstanceApiGateway.Post<ValidatePriceResponseType, true>({
    url: '/pricing-engine/internal/v1/price-changes/validation',
    data: payload,
  })

export type PriceChangeType = {
  location_id: number
  price: string
}

export type SubmitPricePayloadType = {
  product_id: number
  reason: string
  cogs: string
  file_url?: string
  price_changes: PriceChangeType[]
}

export type SubmitPriceResponseType = {
  message: string
  validation_error?: {
    details: {
      location_id: number
      location_name: string
      message: string
    }[]
    message: string
  }
  error?: {
    message: string
    code: number
    status: boolean
  }
}

export const postSubmitPrice = (payload: SubmitPricePayloadType) =>
  axiosInstanceApiGateway.Post<SubmitPriceResponseType, true>({
    url: '/pricing-engine/internal/v1/price-changes',
    data: payload,
  })

export const updateSubmitPrice = (requestId: number | string, payload: SubmitPricePayloadType) =>
  axiosInstanceApiGateway.Put<SubmitPriceResponseType, true>({
    url: `/pricing-engine/internal/v1/price-changes/${requestId}`,
    data: payload,
  })

export type RequestListParamsType = {
  location_ids?: number[]
  category_ids?: number[]
  brand_ids?: number[]
  is_price_zero?: boolean
  sku?: string
  product_name?: string
  date_from?: string
  date_to?: string
  order_by?: 'date_request' | 'requested_price' | 'existing_price'
  order_direction?: 'asc' | 'desc'
  page_size?: number
  cursor?: string
}

export type ProductType = {
  id: number
  name: string
  sku: string
  grammation: string
  image_url: string
}

export type RequestLocationType = {
  id: number
  name: string
}

export type RequestListItemType = {
  id: number
  product: ProductType
  min_price: string
  max_price: string
  request_min_price: string
  request_max_price: string
  request_locations: RequestLocationType[]
  status: 'PENDING' | 'REVIEW' | 'APPROVED' | 'REJECTED'
  updated_at: string
  created_at: string
}

export type RequestListResponseType = {
  data: RequestListItemType[]
  pagination: {
    total_elements: number
    prev_cursor: string
    next_cursor: string
  }
  error?: {
    message: string
    code: number
    status: boolean
  }
}

export const getRequestList = (params: RequestListParamsType) =>
  axiosInstanceApiGateway.Get<RequestListResponseType, true>({
    url: '/pricing-engine/internal/v1/price-changes',
    params,
  })

export type DownloadRequestListResponseType = {
  data: {
    file_url: string
  }
  error: {
    message: string
    code: number
    status: boolean
  }
}

export const downloadRequestList = (ids: string[]) =>
  axiosInstanceApiGateway.Get<DownloadRequestListResponseType, true>({
    url: '/pricing-engine/internal/v1/price-changes/download',
    params: { request_id_statuses: ids.join(',') },
  })

export type MarginType = {
  category_id: number
  bussiness_tagging: string
  margin_min: number
  margin_target: number
}

export type PriceChangeDetailType = {
  id: number
  location_id: number
  location_name: string
  price: string
  price_index: number
}

export type PricingEngineRequestDetailType = {
  id: number
  updated_by: string
  reason: string
  created_by: string
  details: {
    original_price: string
    location_name: string
    id: number
    requested_price: string
    price_index: string
    location_id: number
    status: string
  }[]
  het_min: string
  het_max: string
  min_margin: string
  target_margin: string
  proposed_cogs: string
  file_url: string
  updated_at: string
  created_at: string
}

export type GetPriceChangeDetailResponseType = {
  data: PricingEngineRequestDetailType
  error?: {
    message: string
    code: number
    status: boolean
  }
}

export const getPriceChangeDetail = (requestId: number | string, statuses: string | undefined) =>
  axiosInstanceApiGateway.Get<GetPriceChangeDetailResponseType, true>({
    url: `/pricing-engine/internal/v1/price-changes/${requestId}`,
    params: { statuses },
  })

export type UpdateRequestStatusPayloadType = {
  reject_reason?: string
  action: 'REVIEW' | 'APPROVE' | 'REJECT'
  location_ids: number[]
}

export type UpdateRequestStatusResponseType = {
  message: string
  error?: {
    message: string
    code: number
    status: boolean
  }
}

export const updateRequestStatus = (
  requestId: number | string,
  payload: UpdateRequestStatusPayloadType,
) =>
  axiosInstanceApiGateway.Post<UpdateRequestStatusResponseType, true>({
    url: `/pricing-engine/internal/v1/price-changes/${requestId}/review`,
    data: payload,
  })

export type GetRejectReasonResponseType = {
  data: ReasonType[]
  error?: {
    message: string
    code: number
    status: boolean
  }
}

export const getRejectReason = () =>
  axiosInstanceApiGateway.Get<GetRejectReasonResponseType, true>({
    url: '/pricing-engine/internal/v1/reasons/reject',
  })

export type GetRejectionDetailResponseType = {
  data: {
    product: {
      id: number
      name: string
      sku: string
      grammation: string
      image_url: string
    }
    cogs: string
    details: Array<{
      id: number
      location_name: string
      existing_price: string
      requested_price: string
      reason: string
      status: string
      updated_at: string
      updated_by: string
    }>
  }
  error?: {
    message: string
    code: number
    status: boolean
  }
}

export const getRejectionDetail = (requestId: number | string) =>
  axiosInstanceApiGateway.Get<GetRejectionDetailResponseType, true>({
    url: `/pricing-engine/internal/v1/price-changes/${requestId}/detail-approval/rejected`,
  })

export type UploadAttachmentResponseType = {
  data: {
    fileName: string
    fileUrl: string
    filePath: string
  }
  error?: {
    message: string
    code: number
    status: boolean
  }
}

export const uploadAttachment = (payload: FormData) =>
  axiosInstanceFileService.Post<UploadAttachmentResponseType, true>({
    url: '/internal/v1/pricing-engine-price-change-reason-attachment',
    data: payload,
    config: {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'multipart/form-data',
      },
    },
  })
