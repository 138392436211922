import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const AdminAccessPageRBAC = {
  pageID: 'aa8b5bf8670923672d78eb97e22ca7d1',
  elementID: {
    ViewList: '89e3f75dc700a224f72423a74fff43a3',
    AddNewPage: '007c72939d3ffdd6ec100eee2577f671',
  },
} as const

export const useRBACAdminAccessPagePage = createUseRBAC(AdminAccessPageRBAC.elementID)

export type UseRBACAdminAccessPagePageType = ReturnType<typeof useRBACAdminAccessPagePage>
