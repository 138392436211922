import useAllowedAccessFor from './useAllowedAccessFor'
import { useAppSelector, shallowEqual } from 'store'

export const hasAccess = (access) => {
  const { authorities } = useAppSelector(
    ({
      auth: {
        userData: { authorities },
      },
    }) => ({ authorities }),
    shallowEqual,
  )
  return useAllowedAccessFor(authorities, access)
}
