import { Get } from 'config/apiServiceConfig'

export type BannerType = {
  banner_id: number
  category: {
    category_id: number
    category_name: string
    primary_category: string
    category_hubs: string
    category_hidden: boolean
  }
  category_active?: boolean
  banner_name: string
  banner_description: string
  banner_position: number
  total_position: number
  banner_image: string
  banner_hubs: {
    location_id: number
    name: string
  }[]
  banner_url: string | null
  fallback_url: string
  banner_date_start: number
  banner_date_end: number
  banner_active: boolean
  targeting_type: number
  list_user_id: number[]
  list_current_targeted_users: {
    user_id: number
    user_name: string
  }[]
  banner_text_color?: string
  banner_image_old: {
    image_url_small: string
    image_url_medium: string
    image_url_large: string
    version_type_id: number
  }
  banner_image_new: {
    image_url_small: string
    image_url_medium: string
    image_url_large: string
    version_type_id: number
  }
  banner_segmentations: {
    type_id: number
    segment_type_id: number
    segment_type_name: string
    segment_type_total_user: number
  }[]
}

export const getBanner = (id: number) => Get({ url: `/api/banner/${id}` })
