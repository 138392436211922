import ApiGateway from 'config/apiServiceApiGateway'
import { BlacklistAndWhitelistStatusType } from '../@types/BlacklistAndWhitelist'

export type BlacklistAndWhitelistCustomerPayloadType = {
  customer_id: number
  status: BlacklistAndWhitelistStatusType
  notes: string
  api: string
}

export type BlacklistAndWhitelistDevicePayloadType = {
  device_id: number | string
  status: BlacklistAndWhitelistStatusType
  notes: string
  api: string
}

/** WHITELIST */
// customer
export const postWhitelistCustomer = (data: BlacklistAndWhitelistCustomerPayloadType) =>
  ApiGateway.Post({
    url: '/fraud/internal/:version/whitelist/customer',
    version: 'v1',
    data,
  })

export const putWhitelistCustomer = (id: number, data: BlacklistAndWhitelistCustomerPayloadType) =>
  ApiGateway.Put({
    url: '/fraud/internal/:version/whitelist/customer/:id',
    version: 'v1',
    urlParams: { id },
    data,
  })

// device
export const postWhitelistDevice = (data: BlacklistAndWhitelistDevicePayloadType) =>
  ApiGateway.Post({
    url: '/fraud/internal/:version/whitelist/device',
    version: 'v1',
    data,
  })

export const putWhitelistDevice = (id: number, data: BlacklistAndWhitelistDevicePayloadType) =>
  ApiGateway.Put({
    url: '/fraud/internal/:version/whitelist/device/:id',
    version: 'v1',
    urlParams: { id },
    data,
  })

/** BLACKLIST */
// customer
export const postBlacklistCustomer = (data: BlacklistAndWhitelistCustomerPayloadType) =>
  ApiGateway.Post({
    url: '/fraud/internal/:version/blacklist/customer',
    version: 'v1',
    data,
  })

export const putBlacklistCustomer = (id: number, data: BlacklistAndWhitelistCustomerPayloadType) =>
  ApiGateway.Put({
    url: '/fraud/internal/:version/blacklist/customer/:id',
    version: 'v1',
    urlParams: { id },
    data,
  })

// device
export const postBlacklistDevice = (data: BlacklistAndWhitelistDevicePayloadType) =>
  ApiGateway.Post({
    url: '/fraud/internal/:version/blacklist/device',
    version: 'v1',
    data,
  })

export const putBlacklistDevice = (id: number, data: BlacklistAndWhitelistDevicePayloadType) =>
  ApiGateway.Put({
    url: '/fraud/internal/:version/blacklist/device/:id',
    version: 'v1',
    urlParams: { id },
    data,
  })
