import freshPurchaseOrderTrimmingPackingSlice from './freshPurchaseOrderTrimmingPackingSlice'

export * from './freshPurchaseOrderTrimmingPackingThunk'

export type {
  FpoTrimmingPackingInterface,
  StatusItemType,
} from './freshPurchaseOrderTrimmingPackingSlice'

export const {
  resetInboundFpoAndPackingNumber,
  setQuery,
  setSelectedFilterForm,
  setSelectedSearchField,
} = freshPurchaseOrderTrimmingPackingSlice.actions
export default freshPurchaseOrderTrimmingPackingSlice.reducer
