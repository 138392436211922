import { memo } from 'react'
import styled from 'styled-components'
import Barcode from 'react-barcode'

import { Typography } from '@astro-ui/components'

function BarcodeSection({ data }) {
  return (
    <div>
      <BarcodeContainer>
        <Barcode value={data?.code} displayValue={false} height={85} />
      </BarcodeContainer>
      <Typography marginTop={-12} marginLeft={12} fontWeight="bold">
        {data?.code}
      </Typography>
    </div>
  )
}

export default memo(BarcodeSection)

const BarcodeContainer = styled.div`
  width: 200px;
  display: flex;
  margin-top: -22px;
  margin-right: -6px;
`
