import { memo } from "react";
import styled from "styled-components";
import { SystemIcon, Checkbox, TextBody, Input } from "components";

function RowProductDiscount({ data }) {
  return (
    <Tr>
      <Td>
        <WrapperCheck>
          <Checkbox />
        </WrapperCheck>
      </Td>
      <Td>
        <TextBody size="smaller" weight="light">
          Senayan
        </TextBody>
      </Td>
      <Td>
        <OverFlowSingle line="1">
          Pisang Sunpride Kuning Man 50...
        </OverFlowSingle>
      </Td>
      <Td>
        <TextBody size="smaller" weight="light">
          Rp 10.000
        </TextBody>
      </Td>
      <Td>
        <TextBody size="smaller" weight="light">
          50
        </TextBody>
      </Td>
      <Td>
        <StyledInput value="Rp 1.000" max="115px" />
      </Td>
      <Td>
        <StyledInput value="10 %" max="65px" />
      </Td>
      <Td>
        <StyledInput placeholder="0" max="65px" />
      </Td>
      <Td>
        <StyledInput value="31 Aug 2021..." max="122px" />
      </Td>
      <Td>
        <StyledInput value="Pilih Tanggal" max="122px" />
      </Td>

      <Td>
        <ActionWrapper>
          <StyledInput placeholder="0" max="55px" />
          <SystemIcon iconName="trash" />
        </ActionWrapper>
      </Td>
    </Tr>
  );
}

RowProductDiscount.defaultProps = {
  data: {}
};

export default memo(RowProductDiscount);

const Tr = styled.tr`
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
`;
const Td = styled.td`
  color: ${({ theme: { colors }, color = "textSoft" }) => colors[color]};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  padding: 16px;
  padding-right: 20px;
  min-width: ${({ min }) => min};
`;

// const OverFlowText = styled.div`
//   -webkit-line-clamp: ${({ line }) => line};
//   -webkit-box-orient: vertical;
//   white-space: pre-wrap;
//   display: -webkit-box;
//   overflow: hidden;
//   max-width: 200px;
// `;

const OverFlowSingle = styled.div`
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  i {
    margin-left: 20px;
  }
`;

const WrapperCheck = styled.div`
  display: grid;
  place-items: center;
`;

const StyledInput = styled(Input)`
  max-width: ${({ max }) => max};
  input {
    padding: 6px 12px;
    font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  }
`;
