import { memo, useContext } from "react";
import styled from "styled-components";
import Header from "./Header";
import Body from "./Body";
import store from "storeContext";

function TemplateSupplyOrderPickingList({ isSuratJalan }) {
  const { state: statePrintArea } = useContext(store.PrintAreaContext);

  // console.log(isSuratJalan);
  return (
    <Container className="">
      <Header
        data={statePrintArea?.dataCetakListSO}
        isSuratJalan={isSuratJalan}
      />

      <Body
        data={statePrintArea?.dataCetakListSO.supply_order_items}
        selectedData={statePrintArea.selectedDataSO}
        isSuratJalan={isSuratJalan}
      />

      {/* {isSuratJalan && (
        <Body
          data={statePrintArea?.dataCetakListSO.inbound_item_list}
          selectedData={statePrintArea.selectedDataSO}
          isSuratJalan={isSuratJalan}
        />
      )} */}
    </Container>
  );
}

TemplateSupplyOrderPickingList.defaultProps = {
  isSuratJalan: false
};

export default memo(TemplateSupplyOrderPickingList);

const Container = styled.div`
  //   padding: 32px;
  // width: 21cm;
  //   height: 450px;
`;
