import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import { toastSuccess } from 'utils/toast'

import { putStatusBuyer, getCustomerList } from 'features/Account/services/buyer'
import { UpdateBuyerStatusPayloadType } from 'features/Account/@types/buyer'

export const SLICE_NAME = 'buyer'

export const fetchBuyerList = createAsyncThunk(
  `${SLICE_NAME}/fetchBuyerList`,
  async (_, { rejectWithValue, getState }) => {
    const {
      buyer: { query },
    } = getState() as StoreStateType

    try {
      const { data } = await getCustomerList({
        params: {
          page_index: query.pageIndex,
          page_size: query.pageSize,
          search: query.name,
          sort: 'id',
          direction: 'desc',
        },
      })

      return data.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const updateBuyerStatus = createAsyncThunk(
  `${SLICE_NAME}/updateBuyerStatus`,
  async (payload: UpdateBuyerStatusPayloadType, { rejectWithValue }) => {
    try {
      const res = await putStatusBuyer(payload)
      toastSuccess(`Berhasil merubah status customer ${res.data.customer_first_name}`)

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)
