export const SET_IS_LOADING_WAREHOUSE = 'SET_IS_LOADING_WAREHOUSE/warehouse-page'
export const SET_WAREHOUSES = 'SET_WAREHOUSES/warehouse-page'
export const SET_SELECTED_WAREHOUSE = 'SET_SELECTED_WAREHOUSE/warehouse-page'
export const SET_CHECKALL = 'SET_CHECKALL/warehouse-page'
export const SET_ID_SELECTED_PRODUCT = 'SET_ID_SELECTED_PRODUCT/warehouse-page'
export const SET_DATA_PAGINATION_PRODUCT = 'SET_DATA_PAGINATION_PRODUCT/warehouse-page'
export const SET_PRODUCTS_CAN_ADD = 'SET_PRODUCTS_CAN_ADD/warehouse-page'
export const SET_INVENTORIES = 'SET_INVENTORIES/warehouse-page'
export const SET_PAGINATION_DATA_INVENTORIES = 'SET_PAGINATION_DATA_INVENTORIES/warehouse-page'
export const SET_LAST_QUERY_WAREHOUSE = 'SET_LAST_QUERY_WAREHOUSE/warehouse-page'
export const SET_SELECTED_WAREHOUSE_FILTER = 'SET_SELECTED_WAREHOUSE_FILTER/warehouse-page'
export const SET_RAK_OPTIONS_WAREHOUSE = 'SET_RAK_OPTIONS_WAREHOUSE/warehouse-page'
export const SET_QUERY_PARAMS_INVENTORY_WAREHOUSE =
  'SET_QUERY_PARAMS_INVENTORY_WAREHOUSE/warehouse-page'
