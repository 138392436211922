import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  fetchFeatureFlags,
  GetFeatureFlagsParamsInterface,
  fetchLocations,
  GetFlagLocationParamsInterface,
  updateFeatureFlag,
  UpdateFeatureFlagInterface,
} from 'utils/apiList/flagManagement'
import { callErrorMsg } from 'helpers/errorMsg'
import { SLICE_NAME, setFeatureFlags, setLoading, setLocations } from './flagManagementSlice'
import type { LocationResponseType } from './flagManagementSlice'

export const getFeatureFlags = createAsyncThunk(
  `${SLICE_NAME}/getFeatureFlags`,
  async (query: Partial<GetFeatureFlagsParamsInterface>, { rejectWithValue, dispatch }) => {
    dispatch(setLoading(true))
    try {
      const response = await fetchFeatureFlags(query)
      dispatch(setFeatureFlags(response.data.data))
      return response.data.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const getFeatureDetails = createAsyncThunk(
  `${SLICE_NAME}/getFeatureDetails`,
  async (
    {
      feature_id,
      query,
    }: {
      feature_id: number
      query: Partial<GetFlagLocationParamsInterface>
    },
    { rejectWithValue, dispatch },
  ) => {
    dispatch(setLoading(true))
    try {
      const defaultParams: Partial<GetFlagLocationParamsInterface> = {
        page_size: 10,
        page_index: 0,
      }

      const params = { ...defaultParams, ...query }
      if (params.location_name) {
        delete params.active
      }

      const { data } = await fetchLocations(feature_id, params)
      const rawData = data.data as LocationResponseType
      dispatch(setLocations(rawData as LocationResponseType))
      return rawData
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const updateFeatureFlagLocations = createAsyncThunk(
  `${SLICE_NAME}/updateFeatureFlagLocations`,
  async (
    { feature_id, payload }: { feature_id: number; payload: UpdateFeatureFlagInterface },
    { rejectWithValue, dispatch },
  ) => {
    dispatch(setLoading(true))
    try {
      const { data } = await updateFeatureFlag(feature_id, payload)
      dispatch(
        getFeatureDetails({
          feature_id,
          query: { location_type: 'HUB', device_id: payload.device_id },
        }),
      )
      return data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)
