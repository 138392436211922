import { Get } from 'config/apiServiceConfig'
import AxiosInstance from 'config/apiServiceApiGateway'

import { LocationType } from './loadingDock'

const getInboundScheduleEndpoint = '/api/sm/:version/inbound-schedule/'
const getDetailLocationEndpoint = '/api/location/:locationId'
const getCountScheduledEndpoint = '/api/wms/:version/purchase-order/count-scheduled'

export type InboundScheduleActionType = 'CREATE' | 'UPDATE' | 'DELETE' | 'VIEW'

export type InboundScheduleTimeSlotType = {
  timeSlotId: number
  action: InboundScheduleActionType[]
  inboundScheduleData: {
    scheduleId: number
    vendor: {
      vendorId: number
      vendorName: string
    } | null
    totalPo: number
    totalPcs: number
    maxPcs: number
  } | null
  isSameAsAbove?: boolean
  date?: string
}

export type InboundScheduleType = {
  date: string
  timeSlotData: InboundScheduleTimeSlotType[]
}

export type GetInboundScheduleType = {
  data: InboundScheduleType[]
}

export type GetInboundScheduleParamsType = {
  startDate: string
  endDate: string
  locationId: number
  loadingDockId: number
}

export type GetCountScheduledType = {
  data: {
    unscheduledPo: number
    unscheduledVendor: number
    scheduledPo: number
    scheduledVendor: number
  }
}

export const getInboundSchedule = (params: GetInboundScheduleParamsType) =>
  Get<GetInboundScheduleType>({ url: getInboundScheduleEndpoint, version: 'v1', params })

export const getLocationDetail = (locationId: string) =>
  Get<LocationType>({ url: getDetailLocationEndpoint, urlParams: { locationId } })

export const getCountScheduled = () =>
  Get<GetCountScheduledType>({ url: getCountScheduledEndpoint, version: 'v1' })

export const deleteInboundSchedule = (scheduleId: number) =>
  AxiosInstance.Delete({
    url: '/staff-management/internal/:version/inbound-schedule/:scheduleId',
    version: 'v1',
    urlParams: { scheduleId },
  })
