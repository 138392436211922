import { memo, useState, useEffect, useContext, useRef } from 'react'
import styled from 'styled-components'
import { InputDropDown } from 'components'
import { actGetRakOption } from 'storeContext/actions/rack/rakDropDown'
import store from 'storeContext'

function RakDropdown({
  isDisabled,
  loacionID,
  defaultValueRak,
  handleClick,
  label,
  hasError = false,
}) {
  const { dispatch } = useContext(store.RakContext)
  const refRak = useRef(null)
  /***
   * INITIAL STATE
   */
  const initRakOptions = []
  const initSelectedRak = {
    active: true,
    id: 0,
    name: '',
    position: 1,
    rack_name: '',
    ...defaultValueRak,
  }

  /***
   * LOCAL STATE / USE STATE
   */
  const [rakOptions, setRakOptions] = useState(initRakOptions)
  const [selectedRak, setSelectedRak] = useState(initSelectedRak)

  /**
   *  FUNCTION HANDLER
   */

  useEffect(() => {
    setSelectedRak((prev) => ({ ...prev, ...defaultValueRak }))
  }, [defaultValueRak])

  const setRakOptionWrapper = (dataIn) => {
    setRakOptions(dataIn)
  }

  const handleFirsClickRak = () => {
    const localState = {
      setRakOptions: setRakOptionWrapper,
    }
    dispatch(actGetRakOption(loacionID, localState))
  }

  const handleClickSelectRakThis = (rack) => {
    setSelectedRak(rack)
    handleClick(rack)
  }

  const handleSearchOptionRak = (e) => {
    const { value } = e.target

    clearTimeout(refRak.current)

    refRak.current = setTimeout(() => {
      const localState = {
        query: `?active=true&name=${value}`,
        setRakOptions: setRakOptionWrapper,
      }
      dispatch(actGetRakOption(loacionID, localState))
    }, 500)
  }

  useEffect(() => {}, [])

  return (
    <Container>
      <InputDropDown
        isManualFilter
        isFirstClick
        handleFirsClick={handleFirsClickRak}
        listOptions={rakOptions}
        value={selectedRak.rack_name === null ? '' : selectedRak.rack_name}
        handleSearchOption={handleSearchOptionRak}
        handleClickOption={handleClickSelectRakThis}
        label={label}
        error={hasError}
        errorText="Field wajib diisi"
        disabledDropDown={isDisabled}
      />
    </Container>
  )
}

RakDropdown.defaultProps = {
  setSelectedRak: () => {},
  defaultValueRak: {
    active: true,
    id: 0,
    name: '',
    position: 1,
    rack_name: '',
  },
  handleClick: () => {},
  label: '',
}

export default memo(RakDropdown)

const Container = styled.div``
