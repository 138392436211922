import apiServices from 'config/apiServiceApiGateway'
import { type GetFlashSaleResponseType, type QueryType } from 'features/Promotion/@types/FlashSale'

const { Get, Delete } = apiServices

export const getFlashSaleList = (params: Partial<QueryType>) =>
  Get<GetFlashSaleResponseType, true>({
    url: '/campaign/internal/:version/flash-sale',
    version: 'v1',
    params,
  })

export const deleteFlashSale = (flashSaleId: number) =>
  Delete({
    url: '/campaign/internal/:version/flash-sale/:flashSaleId',
    version: 'v1',
    urlParams: { flashSaleId },
  })
