import { useRef } from 'react'
import styled from 'styled-components'
import { Upload, Attach, Trash } from '@astronautsid/wpe-icons'

import { Box, Button, CircularProgress, Typography } from '@astro-ui/components'
import { colors } from '@astro-ui/fondations'
import previewFile from 'assets/images/preview-file.svg'
import DropZone from './Dropzone'

export type UploadFileType =
  | '.csv'
  | '.xlsx'
  | '.pdf'
  | '.jpg'
  | '.jpeg'
  | '.png'
  | '.webp'
  | '.svg'
  | '.xlx'
  | 'application/*'

interface UploadFileDropZonePropsInterface {
  fileData: File | null
  accept: UploadFileType[]
  isUploading: boolean
  errorMsg?: string
  minHeight?: number
  uploadedFile?: { fileUrl: string; fileName: string }
  handleDropFile: (event: File[]) => void
  handleClickBtnUpload?: () => void
  handleClickChooseOtherFile: () => void
  onClickPreviewImage?: () => void
  handleClickRemoveFile?: () => void
}

const UploadFileDropZone = ({
  fileData,
  isUploading,
  accept,
  errorMsg,
  minHeight = 350,
  uploadedFile,
  handleDropFile,
  handleClickBtnUpload,
  handleClickChooseOtherFile,
  onClickPreviewImage,
  handleClickRemoveFile,
}: UploadFileDropZonePropsInterface) => {
  const areaRef = useRef<HTMLDivElement | null>(null)

  const renderDropzonUploadFileContent = () => {
    if (uploadedFile?.fileUrl) {
      return (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap="16px"
          p="12px"
          borderRadius="8px"
          border={`1px solid ${colors.softGrey3}`}
          onClick={(e) => {
            e.stopPropagation()
            areaRef?.current?.click()
          }}
          style={{ cursor: 'pointer' }}
        >
          <Box display="flex" gap="8px">
            <Box onClick={(e) => e.stopPropagation()}>
              <img src={previewFile} alt="preview" onClick={onClickPreviewImage} />
            </Box>
            <Box
              style={{
                maxWidth: '429px',
                whiteSpace: 'wrap',
                wordBreak: 'break-word',
              }}
            >
              <S.EllipsisText>{uploadedFile?.fileName}</S.EllipsisText>
            </Box>
          </Box>
          <Box
            minWidth="18px"
            onClick={(e) => {
              e.stopPropagation()
              if (handleClickRemoveFile) handleClickRemoveFile()
            }}
          >
            <Trash size={18} type="solid" />
          </Box>
        </Box>
      )
    }

    return (
      <S.AreaUpload minHeight={minHeight} className="dropzone-area">
        <Upload color={errorMsg ? colors.red : colors.blueTag} />
        {errorMsg ? (
          <Box>
            <Typography color={colors.red} textAlign="center">
              File yang kamu pilih tidak sesuai kriteria. {errorMsg}
            </Typography>
            <Button variant="outlined" size="small" mt="8px" onClick={handleClickChooseOtherFile}>
              Ganti File
            </Button>
          </Box>
        ) : (
          <Box>
            <Typography textAlign="center" color={colors.grey64} fontSize="14px">
              Pilih file atau drag and drop file
            </Typography>
            <Typography fontWeight="600" textAlign="center" color={colors.blueTag} fontSize="14px">
              {accept.join(', ')}
            </Typography>
          </Box>
        )}
      </S.AreaUpload>
    )
  }

  const renderDropzoneUploadFile = () => (
    <DropZone accept={accept} onDrop={handleDropFile}>
      <Box ref={areaRef as never}>{renderDropzonUploadFileContent()}</Box>
    </DropZone>
  )

  const renderBtnUpload = () => {
    if (isUploading) {
      return (
        <>
          <span>Uploading</span>
          <CircularProgress size="1rem" color="inherit" ml="2" />
        </>
      )
    }
    return <span>Upload File</span>
  }

  const renderChosenFile = () => (
    <S.AreaUpload minHeight={minHeight}>
      <Attach size={1.5} />
      <Typography variant="body2" textAlign="center" mb="2" color={colors.blueTag} fontWeight="600">
        {fileData?.name}
      </Typography>
      <Typography variant="body2" mb="2" textAlign="center" color={colors.grey64}>
        Pastikan file sudah benar. Data yg sudah diupload tidak bisa kamu batalkan.
      </Typography>
      <Box display="flex" justifyContent="center" mt="2">
        <Button
          disabled={isUploading}
          variant="outlined"
          className="mr-5"
          size="small"
          onClick={handleClickChooseOtherFile}
        >
          Ganti File
        </Button>
        {handleClickBtnUpload && (
          <Button variant="contained" disabled={isUploading} onClick={handleClickBtnUpload}>
            {renderBtnUpload()}
          </Button>
        )}
      </Box>
    </S.AreaUpload>
  )

  return (
    <Box>
      {fileData && renderChosenFile()}
      {!fileData && renderDropzoneUploadFile()}
    </Box>
  )
}

const S = {
  AreaUpload: styled.div<{ minHeight: number }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px dashed #e4eaf3;
    border-radius: 8px;
    min-height: ${(props) => props.minHeight}px;
    padding: 15px;
    word-break: break-all;
    gap: 8px;
  `,
  EllipsisText: styled(Typography)`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  `,
}

export default UploadFileDropZone
