import styled from 'styled-components'

const { TextBody } = require('components')

export const S = {
  Container: styled.table`
    display: table;
    flex-wrap: wrap;
    width: 100%;
  `,
  QRWrapper: styled.tr<{ odd: number }>`
    display: inline-block;
    width: 40%;
    margin-left: ${({ odd }) => (odd === 1 ? `2%` : `3%`)};
    margin-right: ${({ odd }) => (odd === 1 ? `3%` : `2%`)};
  `,
  QRCardContainer: styled.div`
    display: flex;
    height: 18mm;
    flex-direction: column;
  `,
  BarcodeSection: styled.div`
    width: 100%;
    display: flex;
  `,
  ProductName: styled.div`
    z-index: 10;
    position: relative;
    bottom: -2px;
  `,
  Day: styled.div`
    transform: rotate(90deg);
    position: relative;
    left: -5px;
  `,
  StyledTBody: styled(TextBody)`
    text-align: center;
    font-size: 0.6rem;
  `,
  BarcodeText: styled.div`
    margin-top: 2px;
  `,
}
