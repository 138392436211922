import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  PostRouteMasterDataType,
  PostOriginDestinationType,
  DistanceType,
  TripType,
  LocationType,
  RouteMasterLogType,
} from 'utils/apiList/routeAndSchedule'
import {
  SLICE_NAME,
  addRouteMaster,
  fetchRouteConfig,
  fetchRouteMasterDetail,
  updateRouteMaster,
} from './routeAndScheduleAddEditThunk'

export type PageType = 'ADD' | 'EDIT'
export type SetFormDataPayloadType = {
  key: keyof RouteAndScheduleAddEditStateInterface['formData']
  value: RouteAndScheduleAddEditStateInterface['formData'][keyof RouteAndScheduleAddEditStateInterface['formData']]
}
export type SetOriginDestinationPayloadType = {
  id: string
  key: keyof PostOriginDestinationType
  value: PostOriginDestinationType[keyof PostOriginDestinationType]
}
export interface RouteAndScheduleAddEditStateInterface {
  isLoading: boolean
  pageType: PageType
  formData: Omit<PostRouteMasterDataType, 'routeOrigins' | 'routeDestinations'>
  routeOrigins: PostRouteMasterDataType['routeOrigins']
  routeDestinations: PostRouteMasterDataType['routeDestinations']
  distanceList: DistanceType[number][]
  tripTypeList: TripType[number][]
  locationTypeList: LocationType[number][]
  logs: RouteMasterLogType[]
}

const getRouteOriginDestinationState = (): PostOriginDestinationType => ({
  id: window.crypto.randomUUID(),
  type: '',
  locationID: null,
  estimatedTimeOfArrival: '',
  estimatedTimeOfDeparture: '',
  status: 'CREATE',
})

const initialState: RouteAndScheduleAddEditStateInterface = {
  isLoading: false,
  pageType: 'ADD',
  formData: {
    name: '',
    distanceType: '',
    tripType: '',
    active: false,
  },
  routeOrigins: [getRouteOriginDestinationState()],
  routeDestinations: [getRouteOriginDestinationState()],
  distanceList: [],
  tripTypeList: [],
  locationTypeList: [],
  logs: [],
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    setPageType: (state, { payload }: PayloadAction<PageType>) => {
      state.pageType = payload
    },
    setFormData: (state, { payload }: PayloadAction<SetFormDataPayloadType>) => {
      const { key, value } = payload

      state.formData[key] = value as never
    },
    setRouteOriginForm: (state, { payload }: PayloadAction<SetOriginDestinationPayloadType>) => {
      const { id, key, value } = payload
      const index = state.routeOrigins.findIndex((origin) => origin.id === id)

      if (index > -1) {
        state.routeOrigins[index][key] = value as never
      }
    },
    setRouteDestinationForm: (
      state,
      { payload }: PayloadAction<SetOriginDestinationPayloadType>,
    ) => {
      const { id, key, value } = payload
      const index = state.routeDestinations.findIndex((origin) => origin.id === id)

      if (index > -1) {
        state.routeDestinations[index][key] = value as never
      }
    },
    addRouteOriginDestinationForm: (
      state,
      { payload }: PayloadAction<'routeOrigins' | 'routeDestinations'>,
    ) => {
      state[payload].push(getRouteOriginDestinationState())
    },
    deleteRouteOriginDestinationForm: (
      state,
      { payload }: PayloadAction<{ id: string; field: 'routeOrigins' | 'routeDestinations' }>,
    ) => {
      const { id, field } = payload
      state[field] = state[field].filter((item) => item.id !== id)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRouteConfig.fulfilled, (state, { payload }) => {
        state.distanceList = payload.distanceType
        state.tripTypeList = payload.tripType
        state.locationTypeList = payload.locationType
      })
      .addCase(addRouteMaster.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addRouteMaster.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(addRouteMaster.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateRouteMaster.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateRouteMaster.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(updateRouteMaster.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchRouteMasterDetail.fulfilled, (state, { payload }) => {
        const { routeOrigins, routeDestinations, logs, ...restPayload } = payload
        state.formData = { ...state.formData, ...restPayload }
        state.routeOrigins = routeOrigins.map(({ id, locationType, ...origin }) => ({
          ...origin,
          id: `${id}`,
          type: locationType,
          status: 'UPDATE',
        })) as PostOriginDestinationType[]
        state.routeDestinations = routeDestinations.map(({ id, locationType, ...destination }) => ({
          ...destination,
          id: `${id}`,
          type: locationType,
          status: 'UPDATE',
        })) as PostOriginDestinationType[]
        state.logs = logs
      })
  },
})

export const {
  resetState,
  setPageType,
  setFormData,
  setRouteOriginForm,
  setRouteDestinationForm,
  addRouteOriginDestinationForm,
  deleteRouteOriginDestinationForm,
} = slice.actions
export default slice.reducer
