import axios from 'axios'
import { Get } from 'config/apiServiceConfig'

const { REACT_APP_BASE_URL_API } = process.env
const baseUrl = REACT_APP_BASE_URL_API
import { transformObjtoQuery, transformArrayToUrlPath } from 'helpers'

export const getHubDetailList_HD = (id, query = '') =>
  axios.get(`${baseUrl}/api/logs/product/${id}${query}`)

/** api to get the history detail*/

export const getHubHistory = (payload, search) => {
  const { location_id, product_id, pageIndex, status_id } = payload
  const {
    date_range: { createdDateFrom, createdDateTo },
  } = search

  return Get({
    url: '/api/ims/v2/inventories/stock-history',
    params: {
      pageIndex: pageIndex,
      productId: product_id,
      locationId: location_id,
      createdDateFrom: createdDateFrom,
      createdDateTo: createdDateTo,
      ...(status_id
        ? {
            statusId: status_id,
          }
        : {}),
    },
  })
}

export const getInventoryLocation = (payload, search) => {
  const { location_id, product_id, pageIndex } = payload
  const {
    date_range: { createdDateFrom, createdDateTo },
  } = search
  const query = transformObjtoQuery({
    pageIndex: pageIndex,
    createdDateFrom: createdDateFrom,
    createdDateTo: createdDateTo,
  })
  const url = transformArrayToUrlPath(['api', 'inventory', 'location', location_id, product_id])
  return axios.get(`${baseUrl}${url}${query}`)
}

export const getInventoryStatus = () => {
  const url = transformArrayToUrlPath(['api', 'inventory-status'])
  return axios.get(`${baseUrl}${url}`)
}

export const getInventoryNote = () => {
  return axios.get(`${baseUrl}/api/inventory-status-notes`)
}

export const getInventoryNoteByStatus = (payload) => {
  const { status_id } = payload

  const query = transformObjtoQuery({
    inventory_status_id: status_id,
  })
  const url = transformArrayToUrlPath(['api', 'inventory-status-notes'])
  return axios.get(`${baseUrl}${url}${query}`)
}

export const saveInventoryLocation = (data) => {
  return axios.post(`${baseUrl}/api/ims/v2/inventories/detail`, data)
}

export const updateInventoryLocation = (data, id) => {
  return axios.put(`${baseUrl}/api/ims/v2/inventories/detail/${id}`, data)
}

export const transferProductDetailApi = (data, id) => {
  return axios.put(`${baseUrl}/api/ims/v2/inventories/detail/${id}/transfer`, data)
}

export const getProductDetail_HD = (id) => axios.get(`${baseUrl}/api/inventories/hub/${id}`)
