import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  visibleChangePackerAuthorization: false,
  isChangePacker: false,
}

const packer = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setVisibleChangePackerAuthorization: (state, action) => {
      state.visibleChangePackerAuthorization = action.payload
    },
    setIsChangePacker: (state, action) => {
      state.isChangePacker = action.payload
    },
  },
})

export const { setVisibleChangePackerAuthorization, setIsChangePacker } = packer.actions

export default packer.reducer
