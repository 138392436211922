export const SET_IS_LOADING_SO = 'SET_IS_LOADING_SO/so-page'
export const SET_PRODUCTS = 'SET_PRODUCTS/so-page'
export const SET_SELECTED_PRODUCTS = 'SET_SELECTED_PRODUCTS/so-page'
export const SET_ORIGIN_HUBS = 'SET_ORIGIN_HUBS/so-page'
export const SET_DESTINATION_HUBS = 'SET_DESTINATION_HUBS/so-page'
export const SET_SELECTED_ORIGIN_HUB = 'SET_SELECTED_ORIGIN_HUB/so-page'
export const SET_SELECTED_DESTINATION_HUB = 'SET_SELECTED_DESTINATION_HUB/so-page'
export const SET_IS_CHECK_ALL = 'SET_IS_CHECK_ALL/so-page'
export const SET_DATA_PAGINATION_ADD = 'SET_DATA_PAGINATION_ADD/so-page'
export const SET_SUPPLY_ORDERS = 'SET_SUPPLY_ORDERS/so-page'
export const SET_DATA_PAGINATION_SO = 'SET_DATA_PAGINATION_SO/so-page'
export const SET_SELECTED_SO = 'SET_SELECTED_SO/so-page'
export const SET_IS_POPUP_CHECK_QTY_SHOW = 'SET_IS_POPUP_CHECK_QTY_SHOW/so-page'
export const SET_LAST_QUERY = 'SET_LAST_QUERY/so-page'
export const SET_PURCHASE_ORDER = 'SET_PURCHASE_ORDER/so-page'
export const SET_LAST_PARAMS = 'SET_LAST_PARAMS/so-page'
export const SET_VENDORS = 'SET_VENDORS/so-page'
export const SET_SELECTED_PURCHASE_ORDER = 'SET_SELECTED_PURCHASE_ORDER/s-page'
export const SET_LINK_TEMPLATE_CSV_PO = 'SET_LINK_TEMPLATE_CSV_PO/s-page'
export const SET_TYPE = 'SET_TYPE/s-page'
export const SET_IS_NEED_TO_RELOAD = 'SET_IS_NEED_TO_RELOAD'
export const SET_RAK_OPTIONS_PURCHASE_ORDER_LITE =
  'SET_RAK_OPTIONS_PURCHASE_ORDER_LITE/po-lite-page'
export const SET_LIST_LOCATION_RAW = 'SET_LIST_LOCATION_RAW'
export const SET_REMARKS_SUPPLY_ORDER = 'SET_REMARKS_SUPPLY_ORDER'
export const SET_CHECKER = 'SET_CHECKER'
export const SET_FEATURE_FLAG = 'SET_FEATURE_FLAG'
export const SET_PO_REFERENCE_DIALOG_STATE = 'SET_PO_REFERENCE_DIALOG_STATE/so-page'
export const SET_SELECTED_PO_REFERENCE_DATA = 'SET_SELECTED_PO_REFERENCE_DATA/so-page'
export const SET_CREATE_PO_REFERENCE_LOADING = 'SET_CREATE_PO_REFERENCE_LOADING/so-page'
export const TOGGLE_MODAL_CONFIRMATION_SUPPLY_ORDER =
  'TOGGLE_MODAL_CONFIRMATION_SUPPLY_ORDER/so-page'
export const SET_REASONS_SUPPLY_ORDER = 'SET_REASONS_SUPPLY_ORDER/so-page'
export const RESET_MODAL_CONFIRMATION_SUPPLY_ORDER = 'RESET_MODAL_CONFIRMATION_SUPPLY_ORDER/so-page'
export const SET_ROUTE_MASTER_LIST = 'SET_ROUTE_MASTER_LIST/so-page'
export const SET_CHECKED_SO_LIST = 'SET_CHECKED_SO_LIST/so-page'
