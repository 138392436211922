import axios, { AxiosRequestConfig, AxiosProgressEvent } from 'axios'

const baseUrl = process.env.REACT_APP_BASE_URL_API

const apiFpoList = '/api/wms/fresh-purchase/v1/list'
const apiGetWarehouseList = `/api/locations/warehouse`
const apiDownloadTemplateFpo = '/api/wms/fresh-purchase/v1/template'
const apiBulkUploadFpo = '/api/wms/fresh-purchase/v1/create/bulk'

const apiGetFpoDetail = (id: number) => `/api/wms/fresh-purchase/v1/${id}`
const apiProcessInboundFpo = (id: number) => `/api/wms/fresh-purchase/v1/process/${id}`
const apiPostUpdateStatusFpo = (id: number) => `/api/wms/fresh-purchase/v1/update-status/${id}`
const apiPostPricingFpo = (id: number) => `/api/wms/fresh-purchase/v1/process/pricing/${id}`

export type StatusFpoType =
  | 'CREATED'
  | 'CANCEL'
  | 'ON_PURCHASE'
  | 'PARTIAL_RECEIVED'
  | 'DONE'
  | 'RECEIVED'
  | 'PARTIAL_RECEIVED'
  | 'WH_RECEIVING'
  | 'PROCESSING_IN_PROGRESS'

export type ActionNameType =
  | 'INBOUND'
  | 'RETURN TO DRAFT'
  | 'CONFIRM'
  | 'CANCEL'
  | 'PRINT GRN'
  | 'PRICING'

export type ActionValueType = 'created' | 'confirm' | 'cancel' | 'inbound' | 'pricing' | 'print_grn'

export type StatusToBeType = Extract<ActionValueType, 'confirm' | 'cancel' | 'created'>
export type PricingStatusType = 'INCOMPLETE' | 'COMPLETE'

export type ActionListType = {
  name: ActionNameType
  value: ActionValueType
  message: string
  isShow: boolean
}[]
export interface PaginationResponseType {
  sort: string
  direction: string
  pageSize: number
  pageIndex: number
  numberOfElements: number
}
export interface ErrorResponseType {
  status: boolean
  message: string
  code: number
}

export interface GetFpoListResponseType {
  data: {
    id: number
    fpoNumber: string
    vendor: {
      vendorId: number
      vendorName: string
    }
    date: string
    location: {
      locationId: number
      locationName: string
      locationType: string
    }
    referenceNumber: string
    totalItem: number
    pricingStatus: PricingStatusType
    status: StatusFpoType
    createdAt: number
    createdBy: string
    updatedAt: number
    updatedBy: string
    action: ActionNameType[]
  }[]
  error: ErrorResponseType
  pagination: PaginationResponseType
}

export interface GetWarehouseListResponseType {
  location_id: number
  location_name: string
  location_type: string
}

export interface GetFpoListRequestType {
  pageIndex: number
  pageSize: number
  status?: string
  date?: string
  fpoNumber?: string
  locationId?: number
  referenceNumber?: string
}

export interface GetTemplateFreshPurchaseOrderType {
  data: {
    fileURL: string
  }
  error: ErrorResponseType
}

export interface PostBulkUploadFpoSuccessResponseType {
  data: {
    status: 'success' | 'failed'
    fileURL: string
    totalData: number
    failedData: number
    successData: number
  }
  message: string
  error: ErrorResponseType
}
export interface GetFpoDetailResponseType {
  data: {
    id: number
    fpoNumber: string
    vendor: {
      vendorId: number
      vendorName: string
    }
    location: {
      locationId: number
      locationName: string
      locationType: string
    }
    totalItem: number
    status: StatusFpoType
    items: {
      id: number
      product: {
        product_id: number
        product_name: string
        product_sku_number: string
        base_uom: string
      }
      plannedQty: number
      remainingQty: number
      receivedQty: number | null
      usedQty: number
      uom: string
      estimatedPrice: number
      totalEstimatedPrice: number
      actualPrice: number | null
      suggestedPrice: number
      totalActualPrice: number
    }[]
    action: ActionNameType[]
    pricingStatus: PricingStatusType
    createdAt: number
    createdBy: string
    updatedAt: number
    updatedBy: string
  }
  error: ErrorResponseType
}

export interface PostUpdateStatusFpoRequestType {
  status: 'created' | 'confirm' | 'cancel'
}

export interface PostUpdateStatusFpoResponseType {
  error: ErrorResponseType
  message: string
}

export interface PostProcessInboundFpoRequestType {
  fullProcess: boolean
  items: {
    freshPurchaseItemId: number
    receivedQuantity: number
  }[]
}

export interface PostPricingFpoRequestType {
  items: {
    freshPurchaseItemId: number
    actualPrice: number
  }[]
}

export interface PostAPiResponseType {
  error: ErrorResponseType
  message: string
}

export interface ConfigMultipartFormDataType {
  headers: AxiosRequestConfig['headers']
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void
}

export const getFreshPurchaseOrderList = (params: GetFpoListRequestType) =>
  axios.get<GetFpoListResponseType>(`${baseUrl}${apiFpoList}`, { params })

export const getWarehouseList = () => axios.get(`${baseUrl}${apiGetWarehouseList}`)

export const getTemplateFreshPurchaseOrder = () => axios.get(`${baseUrl}${apiDownloadTemplateFpo}`)

export const postBulkUploadFreshPurchaseOrder = (
  data: string,
  config: ConfigMultipartFormDataType,
) => axios.post(`${baseUrl}${apiBulkUploadFpo}`, data, config as AxiosRequestConfig<string>)

export const getFreshPurchaseOrderDetail = (id: number) =>
  axios.get<GetFpoDetailResponseType>(`${baseUrl}${apiGetFpoDetail(id)}`)

export const postUpdateStatusFpo = (id: number, body: PostUpdateStatusFpoRequestType) =>
  axios.post(`${baseUrl}${apiPostUpdateStatusFpo(id)}`, body)

export const postProcessInboundFpo = (id: number, data: PostProcessInboundFpoRequestType) =>
  axios.post(`${baseUrl}${apiProcessInboundFpo(id)}`, data)

export const postPricingFpo = (id: number, data: PostPricingFpoRequestType) =>
  axios.post<PostAPiResponseType>(`${baseUrl}${apiPostPricingFpo(id)}`, data)
