import { memo, useState, useEffect } from 'react'
import styled from 'styled-components'
import { TextBody, Checkbox } from 'components'
import corner_down_right from 'assets/images/corner-down-right.svg'

function OrderIDContainer({
  data,
  handleChangeOrderPool,
  setOrderPoolingData,
  orderPoolingData,
  location,
}) {
  //INITIAL STATE
  const initCheckoutOrderPValue = false

  //STATE HOOKS
  const [checkoutOrderPValue, setCheckoutOrderPValue] = useState(initCheckoutOrderPValue)

  //EFFECT HOOKS
  useEffect(() => {
    const initCheckoutOrderPValueOnFirstRender = orderPoolingData.some((val) => {
      return val.order_id === data.order_id
    })
    if (initCheckoutOrderPValueOnFirstRender) {
      setCheckoutOrderPValue(true)
    } else {
      setCheckoutOrderPValue(false)
    }
  }, [orderPoolingData])

  //FUNCTION HANDLER
  const handleClickCheckBox = (e) => {
    const { value } = e.target
    const getCurrentRealValue = value === 'true' ? false : true

    setCheckoutOrderPValue((prev) => {
      //handleChangeOrderPool(!prev, data);

      return !prev
    })

    if (getCurrentRealValue) {
      ///IF getCurrentRealValue === TRUE ? ADD data to orderPooling Array

      setOrderPoolingData((prev) => {
        return [...prev, data]
      })
    } else {
      setOrderPoolingData((prev) => {
        const newOrderPooling = [...prev]
        const filteredOrderPooling = newOrderPooling.filter((val) => {
          return val.order_id !== data.order_id
        })

        return [...filteredOrderPooling]
      })
    }
  }

  return (
    <Container>
      <CheckBoxContainer>
        {location.id ? (
          <Checkbox
            onChange={handleClickCheckBox}
            checked={checkoutOrderPValue}
            value={checkoutOrderPValue}
          />
        ) : (
          <DisabledCheckbox />
        )}
      </CheckBoxContainer>
      <IDContainer>
        <div
          style={{
            fontWeight: 'bold',
            fontSize: '14px',
            color: 'rgba(49, 53, 59, 0.96)',
          }}
        >
          {data?.order_invoice}
        </div>
        {data?.order_reference && (
          <OrderIDRefContainer>
            <OrderIDIconContainer>
              <img alt="arrow-right" src={corner_down_right} />
            </OrderIDIconContainer>
            <OrderIDRefNumbContainer>
              <TextBody
                weight="light"
                color="grey"
                size="smallest"
                style={{ marginTop: 'auto', marginBottom: 'auto' }}
              >
                {`Ref: ${data?.order_reference}`}
              </TextBody>
            </OrderIDRefNumbContainer>
          </OrderIDRefContainer>
        )}
      </IDContainer>
    </Container>
  )
}

export default memo(OrderIDContainer)

const Container = styled.div`
  padding-right: 10px;
  display: flex;
  flex-direction: row;
`

const CheckBoxContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 12px;
  width: 21px;
  height: 21px;
`

const IDContainer = styled.div`
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  width: 210px;
  overflow-wrap: break-word;
`

const OrderIDRefContainer = styled.div`
  display: flex;
`

const OrderIDIconContainer = styled.div``

const OrderIDRefNumbContainer = styled.div`
  display: flex;
`

const DisabledCheckbox = styled(Checkbox)`
  cursor: not-allowed;
`
