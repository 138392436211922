import { createAsyncThunk } from '@reduxjs/toolkit'

import { deleteCatalogue, getHubList } from 'utils/apiList/catalogue'

import { callErrorMsg } from 'helpers/errorMsg'
import { toastSuccess } from 'utils/toast'

export const SLICE_NAME = 'catalogue'

export const removeCatalogue = createAsyncThunk(
  `${SLICE_NAME}/removeCatalogue`,
  async ({ id }: { id?: number }, { rejectWithValue, getState }) => {
    const {
      catalogue: { selectedCatalogue },
    } = getState() as StoreStateType
    try {
      const res = await deleteCatalogue({ id: id || selectedCatalogue.category_id })
      toastSuccess(`berhasil menghapus ${selectedCatalogue.category_name}`)

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchHubList = createAsyncThunk(
  `${SLICE_NAME}/fetchHubList`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getHubList()
      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)
