import React from 'react'
import { Dialog as MuiDialog, DialogProps as MuiDialogProps, Zoom, Slide } from '@mui/material'
import styled from 'styled-components'
import {
  Box,
  Divider,
  DialogContent,
  IconButton,
  Icon,
  Typography,
  DialogContentPropsType,
} from '@astro-ui/components'
import { TransitionProps } from '@mui/material/transitions'

export type CloseReasonType = 'backdropClick' | 'escapeKeyDown' | 'closeButtonClick'

type EffectType = 'slide' | 'zoom'

export type DialogPropsType = Omit<MuiDialogProps, 'onClose'> & {
  onClose?: (reason: CloseReasonType) => void
  dividers?: boolean
  withHeader?: boolean
  backdropClick?: boolean
  titleProps?: React.CSSProperties
  effect?: EffectType
  dialogContentProps?: DialogContentPropsType
  DialogActionComponent?: React.ReactNode
}

const TransitionZoom = React.forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Zoom {...props} ref={ref} />
})

const TransitionSlide = React.forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return <Slide {...props} direction="left" ref={ref} />
})

const Dialog = ({
  title,
  titleProps,
  dividers = true,
  withHeader = true,
  children,
  onClose,
  backdropClick = false,
  effect = 'zoom',
  dialogContentProps = {},
  DialogActionComponent,
  ...rest
}: DialogPropsType) => (
  <DialogBase
    keepMounted
    TransitionComponent={effect === 'zoom' ? TransitionZoom : TransitionSlide}
    onClose={(_, reason) => {
      if (!backdropClick && reason === 'backdropClick') return
      if (onClose) {
        onClose(reason)
      }
    }}
    {...rest}
  >
    {withHeader && (
      <Box
        className="dialog-title"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p="16px 24px"
        style={titleProps}
      >
        <Typography variant="h2">{title}</Typography>

        {onClose ? (
          <IconButton onClick={() => onClose('closeButtonClick')} transform="rotate(45deg)">
            <Icon icon="plus" color="grey9" size={24} />
          </IconButton>
        ) : null}
      </Box>
    )}

    {dividers && <Divider />}

    <DialogContent {...dialogContentProps}>{children}</DialogContent>
    {DialogActionComponent}
  </DialogBase>
)

export default Dialog

export const DialogBase = styled(MuiDialog)<MuiDialogProps>`
  .MuiDialog-paper {
    border-radius: 8px;
  }
`
