import { memo, useMemo } from 'react'
import styled from 'styled-components'

import { TextBody, Toggle, Tooltip } from 'components'
import { Select, Box } from '@astro-ui/components'
import { colors } from '@astro-ui/fondations'

function RowDriver({
  dataDriver,
  vendorsStaff = [],
  allowRejoinAction,
  handlerClickEdit,
  handleChangeActiveRole,
  handleChangeVerify,
  handleChangeActive,
}) {
  const displayRole = useMemo(() => {
    if (!dataDriver) return '-'

    return dataDriver?.role?.map((data) => data.authority_role)?.join(', ')
  }, [dataDriver])

  const currentSelectedRole = useMemo(() => {
    return dataDriver?.role.find((item) => item.active)
  }, [dataDriver])

  const getStatusColor = useMemo(() => {
    if (dataDriver?.terminationStatusCode === 4) {
      return {
        textColor: 'success',
        bgColor: `${colors.success}20`,
      }
    }
    if (dataDriver?.terminationStatusCode === 3) {
      return {
        textColor: 'grey64',
        bgColor: `${colors.grey64}20`,
      }
    }
    if (dataDriver?.terminationStatusCode === 2) {
      return {
        textColor: 'warning',
        bgColor: `${colors.warning}20`,
      }
    }
    return {
      textColor: 'blueTag',
      bgColor: `${colors.blueTag}20`,
    }
  }, [dataDriver])

  const actionOptions = useMemo(() => {
    if (dataDriver?.terminationStatusCode === 3 && !dataDriver?.isBlacklist)
      return allowRejoinAction ? ['Ubah', 'Rejoin Request'] : ['Ubah']

    return ['Ubah', 'Termination Request']
  }, [dataDriver])

  return (
    <Tr>
      <Td width="200px">
        <TextWrapper>
          <TextBody weight="light">{dataDriver?.driver_full_name}</TextBody>
        </TextWrapper>
      </Td>
      <Td width="120px">
        <TextBody style={{ width: 100, overflowWrap: 'break-word' }} weight="light">
          {dataDriver?.tracker_id ? dataDriver?.tracker_id : '-'}
        </TextBody>
      </Td>
      <Td width="150px">
        <TextBody weight="light">{dataDriver?.driver_phone_number}</TextBody>
      </Td>
      <Td width="120px">
        <TextBody weight="light">{dataDriver?.driver_plate_number}</TextBody>
      </Td>

      <Td width="180px">
        <TextBody weight="light">{dataDriver?.driver_location}</TextBody>
      </Td>
      <Td width="200px">
        <TextWrapper>
          <TextBody weight="light">{displayRole}</TextBody>
        </TextWrapper>
      </Td>
      <Td width="120px">
        <TextBody weight="light">
          {vendorsStaff?.find((vendor) => vendor?.id === dataDriver?.vendor)?.name}
        </TextBody>
      </Td>
      <Td width="250px">
        <Select
          options={dataDriver?.role}
          displayKey="authority_role"
          value={currentSelectedRole}
          required
          placeholder="Unset"
          onChange={(value) =>
            handleChangeActiveRole({
              driverId: dataDriver?.driver_id,
              value,
            })
          }
        />
      </Td>
      <Td width="180px">
        <Box
          width="fit-content"
          p="2px 6px"
          borderRadius="4px"
          margin="auto"
          backgroundColor={getStatusColor.bgColor}
        >
          <TextBody color={getStatusColor.textColor} weight="light">
            {dataDriver?.terminationStatus}
          </TextBody>
        </Box>
      </Td>
      <Td width="150px">
        <ActionWrapper>
          <Tooltip
            tooltipsType="hover"
            content={
              dataDriver?.driver_status === 'Not Available'
                ? 'Driver tidak dapat menerima order'
                : 'Driver siap menerima order'
            }
            direction="bottom"
          >
            <Toggle
              disabled={dataDriver?.terminationStatusCode === 3}
              active={dataDriver?.driver_status !== 'Not Available'}
              onClick={() =>
                handleChangeVerify({
                  ...dataDriver,
                  driver_verified: dataDriver?.driver_status !== 'Not Available',
                })
              }
            />
          </Tooltip>
        </ActionWrapper>
      </Td>
      <Td width="120px">
        <Select
          placeholder="Atur"
          value="Atur"
          disableAutoSelectedOnClick
          required
          style={{ minWidth: '130px' }}
          options={actionOptions}
          onChange={(action) => handlerClickEdit(dataDriver, action)}
        />
      </Td>
    </Tr>
  )
}

export default memo(RowDriver)

const Tr = styled.tr`
  display: flex;
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
`
const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`

const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  i {
    margin-left: 50px;
  }
`

const TextWrapper = styled.div`
  width: 200px;
  overflow-wrap: break-word;
`
