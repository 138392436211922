import createReducer from 'storeContext/reducers/createReducer'
import {
  SET_IS_LOADING,
  SET_QUERY,
  RESET_QUERY,
  SET_RESET,
  SET_PRODUCTS,
  SET_CURRENT_PRODUCTS,
  SET_CHECKED_ALL,
  SET_CHECKED_ITEM,
  SET_QTY,
  SET_SELECTED_PRODUCT_FOR_FILTER,
} from 'storeContext/actionsType/bomAddProduct'

export const initialStateBoMAddProduct = {
  isLoading: false,
  query: {
    pageIndex: 1,
  },
  pagination: {
    number_of_elements: 0,
    page_index: 1,
    page_size: 20,
  },
  data: [],
  tempData: {},
  dataSelected: {},
  itemQty: {},
  checkedItems: [],
  selectedProductForFilter: null,
}

const bomAddProductReducer = createReducer({
  [SET_IS_LOADING]: (state, action) => {
    state.isLoading = action.payload
  },
  [SET_QUERY]: (state, action) => {
    state.isLoading = true
    state.query = {
      ...state.query,
      ...action.payload.query,
    }
  },
  [RESET_QUERY]: (state) => {
    state.query = {
      pageIndex: 1,
    }
  },
  [SET_PRODUCTS]: (state, action) => {
    const { data, pagination } = action.payload
    const ids = Object.keys(state.dataSelected)

    data.forEach((item) => {
      state.tempData[item.product_id] = item

      const productId = item.product_id.toString()
      if (ids.includes(productId)) {
        state.itemQty[productId] = state.dataSelected[productId].qty
      } else {
        delete state.itemQty[productId]
      }
    })

    state.data = data.map((item) => ({ ...item, qty: 0 }))
    state.pagination = {
      number_of_elements: pagination.number_of_elements,
      page_index: pagination.page_index,
      page_size: pagination.page_size,
    }

    state.isLoading = false
  },
  [SET_CURRENT_PRODUCTS]: (state, action) => {
    const ids = Object.keys(action.payload)

    state.dataSelected = action.payload
    state.checkedItems = ids.map(Number)
  },
  [SET_CHECKED_ALL]: (state, action) => {
    const checked = action.payload

    if (checked) {
      state.checkedItems = [
        ...state.checkedItems,
        ...state.data.map(({ product_id }) => product_id),
      ]
    } else {
      state.checkedItems = []
    }
  },
  [SET_CHECKED_ITEM]: (state, action) => {
    const id = action.payload
    const isExist = state.checkedItems.some((itemId) => itemId === id)

    if (isExist) {
      state.checkedItems = state.checkedItems.filter((itemId) => itemId !== id)
    } else {
      state.checkedItems = [...state.checkedItems, id]
    }
  },
  [SET_QTY]: (state, action) => {
    const { id, value } = action.payload

    state.itemQty[id] = value
  },
  [SET_RESET]: () => {
    return initialStateBoMAddProduct
  },
  [SET_SELECTED_PRODUCT_FOR_FILTER]: (state, action) => {
    state.selectedProductForFilter = action.payload
  },
})

export default bomAddProductReducer
