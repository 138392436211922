import {
  Typography,
  MenuItem,
  MenuItemPropsType,
  ListItemText,
  ListItemIcon,
  Checkbox,
} from '@astro-ui/components'

export type SelectAllPropsType = MenuItemPropsType & {
  selectAllText?: string
  checked: boolean
  indeterminate: boolean
  onClick: React.MouseEventHandler<HTMLLIElement>
}

const SelectAll = ({
  selectAllText,
  checked,
  indeterminate,
  onClick,
  ...rest
}: SelectAllPropsType) => (
  <MenuItem {...rest} onClick={onClick}>
    <ListItemText>
      <Typography variant="h5">{selectAllText || 'Select All'}</Typography>
    </ListItemText>
    <ListItemIcon>
      <Checkbox checked={checked} indeterminate={indeterminate} />
    </ListItemIcon>
  </MenuItem>
)

export default SelectAll
