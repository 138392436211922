import { Button, InputDropDown, InputWithLabel, SystemIcon, TextBody, Toggle } from 'components'
import { memo, useContext, useRef, useState } from 'react'
import storeContext from 'storeContext'
import styled from 'styled-components'

import { formatMoneyRMG } from 'helpers'
import { actHandleAddCustomer, actUploadCsv, setFindUser } from 'storeContext/actions/refferal'

import { EnumTargetType } from '..'
import InputDropdownPayment from './InputDropdownPayment'
import TableUserID from './VoucherDetailComponent/TableUserID'

const discountType = [
  { name: 'Rp', value: 'Rp' },
  { name: '%', value: '%' },
]

function VoucherDetailRender({
  forms,
  formUserID,
  setFormUserID,
  handlerList,
  handleChangeIcon,
  handleUploadCsv,
  handleToggleQuotaUnlimited,
  handleToggleQuotaRedemption,
}) {
  const refSearch = useRef(null)
  const {
    state: { benefits, paymentChannelList },
  } = useContext(storeContext.VoucherContext)

  const {
    state: { userList, isLoadingGetUser, dataPaginationUser },
    dispatch: dispatchRef,
  } = useContext(storeContext.RefferalContext)
  const [searchCustomer, setSearchCustomer] = useState('')
  //FUNCTION HANDLER
  const handleChangeForm = (e) => {
    handlerList.handleChangeForm(e)
  }

  const handleSelectOption = (option, type) => {
    handlerList.handleSelectOption(option, type)
  }

  const handleSearchCustomer = (e) => {
    const {
      target: { value },
    } = e
    setSearchCustomer(value)
    clearTimeout(refSearch.current)

    refSearch.current = setTimeout(() => {
      dispatchRef(setFindUser({ number: value }))
    }, 500)
  }
  const handleAddUser = (customer) => {
    dispatchRef(actHandleAddCustomer(customer))
  }
  const handleNextPage = () => {
    const nextPage = dataPaginationUser.currentPage + 1
    if (nextPage < dataPaginationUser.totalPage) {
      dispatchRef(setFindUser({ ...dataPaginationUser.lastParams, pageIndex: nextPage }, true))
    }
  }
  const handleUploadUser = (e) => {
    const payload = new FormData()
    const file = e.target.files[0]
    payload.append('file', file)
    dispatchRef(
      actUploadCsv(payload, (data) => {
        const formated = data.map((el) => {
          return {
            ...el,
            name: `${el.customer_phone_number} ${el.customer_name ? '-' : ''} ${
              el.customer_name || ''
            }`,
          }
        })
        handleUploadCsv(formated)
      }),
    )
  }
  return (
    <Container>
      <Content>
        <Section ratio="5">
          <TextBody>Voucher Detail</TextBody>
        </Section>
        <Section ratio="6">
          <Row width="100%">
            <CostumInputWithLabel
              label="Kode Voucher / Nama Voucher"
              placeholder="Kode voucher atau nama voucher"
              className="mt-16"
              name="voucher_code"
              value={forms.voucher_code}
              onChange={handleChangeForm}
            />
          </Row>
          <Row width="100%">
            <TextBody className="mt-16">Benefit Type</TextBody>
            <InputDropDown
              disabled
              listOptions={benefits}
              handleClickOption={(option) => {
                handleSelectOption(option, 'benefit_type')
              }}
              value={forms.benefit_type.name}
              placeholder="Pilih Benefit Type"
            />
          </Row>
          {forms.benefit_type.name && (
            <Row width="100%">
              <TextBody className="mt-16 mb-4">Benefit Icon</TextBody>
              <UploadContainer>
                <UploadBox className="uploadBox">
                  <IconBenefit src={forms.benefit_type.icon} alt="icon" />
                  <label htmlFor="upload-icon-benefit">
                    <OverLay>Edit</OverLay>
                  </label>
                  <input onChange={handleChangeIcon} id="upload-icon-benefit" type="file" hidden />
                </UploadBox>
              </UploadContainer>
            </Row>
          )}
          {forms?.benefit_type?.name === 'Free Ongkir' && (
            <Row width="100%">
              <CostumInputWithLabel
                label="Maximal Free Shipping"
                className="mt-16"
                name="max_free_shipping"
                value={formatMoneyRMG(forms.max_free_shipping, '')}
                onChange={handleChangeForm}
                customIcon="Rp"
                iconPos="left"
                bgIcon="body"
              />
            </Row>
          )}
          {(forms?.benefit_type?.name === 'Diskon' || forms?.benefit_type?.name === 'Cashback') && (
            <Row width="100%" flex>
              <Row width="40%">
                <CostumInputWithLabel
                  label="Discount"
                  placeholder="0"
                  className="mt-16"
                  name="voucher_discount_total"
                  value={forms.voucher_discount_total}
                  onChange={handleChangeForm}
                />
              </Row>
              <Row width="10%" style={{ marginLeft: 'auto', marginTop: 'auto' }}>
                <InputDropDown
                  disabled
                  listOptions={discountType}
                  handleClickOption={(option) => {
                    handleSelectOption(option, 'discount_type')
                  }}
                  value={forms.discount_type.name}
                />
              </Row>
              <Row width="40%" style={{ marginLeft: 'auto' }}>
                <CostumInputWithLabel
                  disabled={forms.discount_type.name === 'Rp'}
                  customIcon="Rp"
                  iconPos="left"
                  bgIcon="body"
                  label="Max. Diskon"
                  placeholder="0"
                  className="mt-16"
                  name="voucher_discount_max"
                  value={formatMoneyRMG(forms.voucher_discount_max, '')}
                  onChange={handleChangeForm}
                />
              </Row>
            </Row>
          )}
          {forms.targeting_type?.name === EnumTargetType.partnershipPayment && (
            <>
              <Row width="100%">
                <TextBody className="mt-16">Partnership Payment</TextBody>
                <InputDropdownPayment
                  value={forms.payment_channel_id}
                  listOptions={paymentChannelList}
                  onSelectData={(option) => handleSelectOption(option, 'payment_channel')}
                />
              </Row>
              <QuotaPaymentRow>
                <QuotaPaymenColumn>
                  <TextBody className="mt-16">Is Quota Unlimited</TextBody>
                  <CustomToggle
                    active={forms.is_quota_unlimited}
                    onClick={handleToggleQuotaUnlimited}
                  />
                </QuotaPaymenColumn>
                <QuotaPaymenColumn>
                  <CostumInputWithLabel
                    disabled={forms.is_quota_unlimited}
                    label="Voucher Quota"
                    placeholder="Kuota untuk partnership payment"
                    className="mt-16"
                    name="quota_all"
                    value={forms.quota_all}
                    onChange={handleChangeForm}
                  />
                </QuotaPaymenColumn>
              </QuotaPaymentRow>
            </>
          )}
          {forms.targeting_type.name === EnumTargetType.targetedUser && (
            <Row width="100%">
              <WrapperUser>
                <InputDropDown
                  isManualFilter
                  handleSearchOption={handleSearchCustomer}
                  isLoading={isLoadingGetUser}
                  handleClickOption={handleAddUser}
                  listOptions={userList}
                  placeholder="Cari Phonenumber +6282XXXXX"
                  value={searchCustomer}
                  noIconDrop
                  iconSearch
                  handleNextPage={handleNextPage}
                />
                <CusButton>
                  <Label htmlFor="upload-user-promotion" />
                  <WrapperIcon>
                    <SystemIcon colorIcon="grey" iconName="logout" />
                  </WrapperIcon>
                  Upload CSV
                </CusButton>
                <input
                  onChange={handleUploadUser}
                  type="file"
                  hidden
                  id="upload-user-promotion"
                  value=""
                />
              </WrapperUser>
              <TableUserID formUserID={formUserID} setFormUserID={setFormUserID} />
            </Row>
          )}
          <Row width="100%">
            <CostumInputWithLabel
              label="Coin Amount*"
              placeholder="0"
              className="mt-16"
              name="coin_amount"
              value={forms.coin_amount}
              onChange={handleChangeForm}
            />
          </Row>
          <Row width="100%">
            <CostumInputWithLabel
              label="Quota/User*"
              placeholder="0"
              className="mt-16"
              name="quota_user"
              value={forms.quota_user}
              onChange={handleChangeForm}
            />
          </Row>
          <Row>
            <QuotaVoucherWrapper>
              <CostumInputWithLabel
                label="Quota Voucher*"
                placeholder="0"
                className="mt-16"
                name="quota_redemption"
                value={forms.quota_redemption}
                onChange={handleChangeForm}
              />
              <SwitchWrapper>
                <CustomToggle
                  active={forms.is_unlimited_redemption}
                  onClick={handleToggleQuotaRedemption}
                />
                <TextBody>Unlimited</TextBody>
              </SwitchWrapper>
            </QuotaVoucherWrapper>
          </Row>
          <Row width="100%">
            <CostumInputWithLabel
              customIcon="Rp"
              iconPos="left"
              bgIcon="body"
              label="Minimal Spend"
              placeholder="0"
              className="mt-16"
              name="minimum_purchase"
              value={formatMoneyRMG(forms.minimum_purchase, '')}
              onChange={handleChangeForm}
            />
          </Row>
        </Section>
      </Content>
    </Container>
  )
}

export default memo(VoucherDetailRender)

const Container = styled.div`
  width: 100%;
  margin-bottom: 24px;
  padding: 32px;
  background: #ffffff;
  box-shadow: 0px 1px 12px rgba(105, 110, 118, 0.12);
  border-radius: 16px;
`
const Content = styled.div`
  display: flex;
`

const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: ${({ ratio }) => ratio};
`

const Row = styled.div`
  width: ${({ width }) => (width ? width : '100%')};
  ${({ flex }) => (flex ? 'display:flex;' : '')}
`

const CostumInputWithLabel = styled(InputWithLabel)``

const UploadContainer = styled.div``
const UploadBox = styled.div`
  user-select: none;
  width: 64px;
  height: 64px;
  background: #ffffff;
  position: relative;
  border: 2px dashed #d6dfeb;
  border-radius: 8px;
`
const IconBenefit = styled.img`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 50px;
  height: 50px;
`

const WrapperUser = styled.div`
  display: flex;
  margin-top: 20px;
  .input-drop-down {
    flex: 1;
    width: 100%;
  }
`

const CusButton = styled(Button)`
  background-color: transparent;
  color: ${({ theme: { colors } }) => colors.textSoft};
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
  border: 1px solid ${({ theme: { colors } }) => colors.border};
  margin-left: 16px;
  position: relative;
`

const WrapperIcon = styled.div`
  transform: rotate(-90deg);
  margin-right: 8px;
`

const OverLay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  background-color: ${({ theme: { colors } }) => colors.blackFaded};
  cursor: pointer;
  color: white;
  display: grid;
  place-items: center;
  opacity: 0;
  transition: ease 0.3s all;
  :hover {
    opacity: 1;
  }
`

const Label = styled.label`
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
`

const CustomToggle = styled(Toggle)`
  margin-top: 10px;
`

const QuotaPaymentRow = styled.div`
  width: 100%;
  display: flex;
  margin: 5px 0;
`
const QuotaPaymenColumn = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`

const QuotaVoucherWrapper = styled.div`
  display: flex;
  align-items: center;
  > * + * {
    margin-left: 32px;
  }
`

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;
  > * + * {
    margin-left: 8px;
    margin-top: 5px;
  }
`
