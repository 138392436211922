import {
  SET_IS_LOADING,
  SET_BENEFITS,
  SET_TARGETS,
  SET_MECHANISMS,
  SET_USERLIST,
  SET_IS_LOADING_GET_USER,
  SET_DATA_PAGINATION_USER,
  SET_SELECTED_CUSTOMERS,
  SET_DATA_REFFERAL
} from "storeContext/actionsType/refferal";

import {
  getBenefits,
  getTargets,
  getMechanisms,
  getCustomer,
  getRefferalSettingBuyer,
  putRefferalSettingBuyer,
  postUploadedTargettedUSer
} from "utils/api";

import { toastFailed, toastSuccess } from "utils/toast";

export function setDataRefferal(payload) {
  return {
    type: SET_DATA_REFFERAL,
    payload: payload
  };
}

export function setSelectedCustomers(payload) {
  return {
    type: SET_SELECTED_CUSTOMERS,
    payload: payload
  };
}
export function setIsLoadingGetUser(payload) {
  return {
    type: SET_IS_LOADING_GET_USER,
    payload: payload
  };
}
export function setDataPaginationUser(payload) {
  return {
    type: SET_DATA_PAGINATION_USER,
    payload: payload
  };
}

export function setIsLoading(payload) {
  return {
    type: SET_IS_LOADING,
    payload: payload
  };
}

export function setBenefits(payload) {
  return {
    type: SET_BENEFITS,
    payload: payload
  };
}

export function setTargets(payload) {
  return {
    type: SET_TARGETS,
    payload: payload
  };
}

export function setMechanisms(payload) {
  return {
    type: SET_MECHANISMS,
    payload: payload
  };
}

export function setUserList(payload) {
  return {
    type: SET_USERLIST,
    payload: payload
  };
}

export const actGetDropDownList = () => (dispatch) => {
  dispatch(setIsLoading(true));
  const arrPending = [getBenefits(), getTargets(), getMechanisms()];
  Promise.all(arrPending)
    .then(
      ([
        { data: benefitsData },
        { data: targetsData },
        { data: mechanismsData }
      ]) => {
        dispatch(setBenefits(benefitsData));
        dispatch(setTargets(targetsData));
        dispatch(setMechanisms(mechanismsData));
      }
    )
    .finally(() => {
      dispatch(setIsLoading(false));
    });
};

export const setFindUser =
  (query = {}, isNextPage = false) =>
  (dispatch, getState) => {
    dispatch(setIsLoadingGetUser(true));
    getCustomer({ ...query, newBuyer: true })
      .then(({ data }) => {
        let dataCustomer = data.content.map((el) => {
          return {
            ...el,
            name: `${el.customer_phone_number} ${el.customer_name ? "-" : ""} ${
              el.customer_name || ""
            }`
          };
        });
        if (isNextPage) {
          const { userList } = getState().stateRefferal;
          dataCustomer = [...userList, ...dataCustomer];
        }
        const pagination = {
          pageSize: data.size,
          totalData: data.totalElements,
          totalPage: data.totalPages,
          currentPage: data.number,
          currentItem: data.numberOfElements,
          lastParams: query
        };
        dispatch(setDataPaginationUser(pagination));
        dispatch(setUserList(dataCustomer));
      })
      .finally(() => {
        dispatch(setIsLoadingGetUser(false));
      });
  };

export const actHandleAddCustomer = (option) => (dispatch, getState) => {
  const { userList, listSelectedCustomers } = getState().stateRefferal;
  dispatch(setSelectedCustomers([...listSelectedCustomers, option]));
  const filtedUser = userList.filter(
    (el) => el.customer_id !== option.customer_id
  );
  dispatch(setUserList(filtedUser));
};

export const actHandleRemoveCustomer = (option) => (dispatch, getState) => {
  const { userList, listSelectedCustomers } = getState().stateRefferal;
  dispatch(setUserList([...userList, option]));
  const filtedUser = listSelectedCustomers.filter(
    (el) => el.customer_id !== option.customer_id
  );
  dispatch(setSelectedCustomers([...filtedUser]));
};

export const actGetRefferalData = () => (dispatch, getState) => {
  dispatch(setIsLoading(true));
  getRefferalSettingBuyer()
    .then(({ data }) => {
      dispatch(setDataRefferal(data));
    })
    .finally(() => {
      dispatch(setIsLoading(false));
    });
};

export const actSubmitRefferal = (payload) => (dispatch, getState) => {
  const { dataRefferal } = getState().stateRefferal;
  dispatch(setIsLoading(true));
  putRefferalSettingBuyer(dataRefferal.id, payload)
    .then(({ data }) => {
      toastSuccess("Update berhasil");
      dispatch(setDataRefferal(data));
    })
    .catch(() => {
      toastFailed("Update gagal");
    })
    .finally(() => {
      dispatch(setIsLoading(false));
    });
};

export const actUploadCsv =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setIsLoading(true));
    postUploadedTargettedUSer(payload)
      .then(({ data }) => {
        cb(data);
      })
      .catch(() => {
        toastFailed("Upload user gagal");
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };
