import { createAsyncThunk } from '@reduxjs/toolkit'

import { AssetSupplyOrderParamsInterface } from 'features/AssetManagement/@types/typeSupplyOrder'

import {
  getSupplyOrderListAPI,
  postSupplyOrderUpdateAPI,
} from 'features/AssetManagement/services/supplyOrder'

import { callErrorMsg } from 'helpers/errorMsg'
import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'

import { setSupplyOrderResponse, setIsLoading } from './slice'

const SLICE_NAME = 'supplyOrderAssetList'

export const getSupplyOrderList = createAsyncThunk(
  `${SLICE_NAME}/getSupplyOrderList`,
  async (params: AssetSupplyOrderParamsInterface, { rejectWithValue, dispatch }) => {
    dispatch(setIsLoading(true))

    try {
      const response = await getSupplyOrderListAPI(params)

      dispatch(setSupplyOrderResponse(response.data))
      dispatch(setIsLoading(false))

      return true
    } catch (error) {
      dispatch(setIsLoading(false))

      callErrorMsg(error)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const postSupplyOrderUpdateStatus = createAsyncThunk(
  `${SLICE_NAME}/postSupplyOrderUpdateStatus`,
  async (
    params: {
      payload: { so_id: string; so_number: string; status: string }
      callback?: () => void
    },
    { rejectWithValue, dispatch },
  ) => {
    dispatch(setIsLoading(true))

    try {
      await postSupplyOrderUpdateAPI(params.payload)
      dispatch(setIsLoading(false))
      if (params.callback) {
        params.callback()
      }

      return true
    } catch (error) {
      dispatch(setIsLoading(false))

      callErrorMsg(error)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)
