/* eslint-disable react/display-name */
import PopupConfirmation from './PopupConfirmation'
import { ConfirmationPopupContext, useConfirmation } from './usePopupConfirmation'

const withPopupConfirmation =
  <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<P> =>
  (props) => {
    const state = useConfirmation()

    return (
      <ConfirmationPopupContext.Provider value={state}>
        <PopupConfirmation />
        <Component {...props} />
      </ConfirmationPopupContext.Provider>
    )
  }

export default withPopupConfirmation
