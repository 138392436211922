import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getInfiniteScrollTabbing,
  getLocations,
  deleteInfiniteScrollTabbing,
} from 'features/CategoryAndPosisi/services/infiniteScrollTab'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  SLICE_NAME_INFINITE_SCROLL_TAB,
  setLocationOptions,
  setInfiniteScrollTabList,
  setIsLoading,
} from './slice'
import { setFlag } from '../infiniteScrollTabAddEdit'

export const fetchLocations = createAsyncThunk(
  `${SLICE_NAME_INFINITE_SCROLL_TAB}/fetchLocations`,
  async (_, { dispatch }) => {
    try {
      const {
        data: { data },
      } = await getLocations()
      dispatch(setLocationOptions(data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchInfiniteScrollTabList = createAsyncThunk(
  `${SLICE_NAME_INFINITE_SCROLL_TAB}/fetchInfiniteScrollTabList`,
  async (_, { dispatch, getState }) => {
    const {
      infiniteScrollTab: { query },
    } = getState() as StoreStateType

    dispatch(setIsLoading(true))

    try {
      const {
        data: { data, flag },
      } = await getInfiniteScrollTabbing(query)

      dispatch(setInfiniteScrollTabList(data))
      dispatch(setFlag(flag))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const removeInfiniteScrollTabbing = createAsyncThunk(
  `${SLICE_NAME_INFINITE_SCROLL_TAB}/removeInfiniteScrollTabbing`,
  async (tabId: number) => {
    try {
      await deleteInfiniteScrollTabbing(tabId)
      return true
    } catch (error) {
      callErrorMsg(error)
      return false
    }
  },
)
