import {
  SET_TYPE_PRINT_AREA,
  SET_IS_FAIL_PRINT_AREA,
  SET_IS_START_PRINT_AREA,
  SET_IS_READY_PRINT_PRINT_AREA,
  SET_IMAGE_BARCODE_BLOB_PRINT_AREA,
  SET_SELECTED_DATA_SO_PRINT_AREA,
  SET_IMAGE_QRCODE_BLOB_PRINT_AREA,
  SET_PO_LITE_QR_QTY_PRINT_AREA,
  SET_PRINT_CONTENT
} from "storeContext/actionsType/printArea";

export const printAreaStart = () => {
  return {
    type: SET_IS_START_PRINT_AREA
  };
};

export const printAreaStop = () => {
  return {
    type: SET_IS_FAIL_PRINT_AREA
  };
};

export const setReadyPrint = () => {
  return {
    type: SET_IS_READY_PRINT_PRINT_AREA,
    payload: true
  };
};

export const setNotReadyPrint = () => {
  return {
    type: SET_IS_READY_PRINT_PRINT_AREA,
    payload: false
  };
};

export const setTypePrintArea = (data) => {
  return {
    type: SET_TYPE_PRINT_AREA,
    payload: data
  };
};

export const setPrintContent = (data) => {
  return {
    type: SET_PRINT_CONTENT,
    payload: data
  };
};

export const clearTypePrintArea = () => {
  return {
    type: SET_TYPE_PRINT_AREA,
    payload: ""
  };
};

export const setImageBarcode = (data) => {
  return {
    type: SET_IMAGE_BARCODE_BLOB_PRINT_AREA,
    payload: data
  };
};

export const setSelectedDataSO = (data) => {
  return {
    type: SET_SELECTED_DATA_SO_PRINT_AREA,
    payload: data
  };
};

export const setImageQRCode = (data) => {
  return {
    type: SET_IMAGE_QRCODE_BLOB_PRINT_AREA,
    payload: data
  };
};

export const setPOLiteQRQty = (data) => {
  return {
    type: SET_PO_LITE_QR_QTY_PRINT_AREA,
    payload: data
  };
};
