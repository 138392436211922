import {
  SET_DATA_LOCATIONS,
  SET_IS_LOADING_PACKER,
  SET_DATA_PAGINATION,
  SET_DATA_PACKERS,
  SET_IS_POPUP_DETAIL_SHOW,
  SET_SELECTED_PACKER,
  SET_IS_POPUP_CHECK_SHOW,
  SET_LAST_PARAMS,
  SET_SELECTED_LOCATION,
  SET_LOCATIONS,
  SET_IS_POPUP_CALL_DRIVER_SHOW,
  SET_IS_POPUP_CANCEL_SHOW,
  SET_DRIVERS,
  SET_IS_POPUP_CHANGE_DRIVER_SHOW,
  SET_IS_POPUP_CHECK_RESI_SHOW,
  SET_IS_POPUP_PRINT_LIST_SHOW,
  SET_IS_FROM_QTY,
  SET_IS_ORDER_POOLING_DATA,
  SET_IS_POPUP_CALL_DRIVER_V2_SHOW,
  SET_IS_POPUP_CALL_EXTERNAL_DRIVER_V2_SHOW,
  SET_IS_POPUP_TYPE_DRIVER_POPUP_V2,
  SET_IS_POPUP_INPUT_AUTH_SHOW,
  SET_IS_SKU_MANUAL_INPUT_NOTES,
  SET_IS_SKU_MANUAL_INPUT_AUTH,
  SET_SELECTED_PRODUCT,
  SET_INTERSECT_HUB,
  SET_IS_POPUP_CANCEL_AUTO_ASSIGN_SHOW,
} from 'storeContext/actionsType/packer'

export const initialStatePacker = {
  isLoading: false,
  dataLocations: [],
  dataPagination: {},
  datapackers: [],
  isPopupDetailShow: false,
  selectedPacker: {},
  isPopupCheckShow: false,
  lastParams: {},
  locations: [],
  selectedLocation: {},
  isPopupCallDriverShow: false,
  isPopupChangeDriverShow: false,
  isPopupCheckResiShow: false,
  isPopupCancelShow: false,
  isPopupPrintListShow: false,
  isPopupInputAuthShow: false,
  drivers: [],
  isFromCheckQty: false,
  orderPoolingData: [],
  isPopupCallDriverV2Show: false,
  isPopupCallExternalDriverV2Show: false,
  typePopupDriverV2: '',
  skuManualInputNotes: [],
  isSkuManualInputAuth: false,
  manualInputProductId: '',
  intersectHubs: [],
  isPopUpCancelAutoAssignShow: false,
}

export default function packer(state = initialStatePacker, { type, payload }) {
  switch (type) {
    case SET_DATA_LOCATIONS:
      return {
        ...state,
        dataLocations: payload,
      }
    case SET_IS_LOADING_PACKER:
      return {
        ...state,
        isLoading: payload,
      }
    case SET_DATA_PAGINATION:
      return {
        ...state,
        dataPagination: payload,
      }
    case SET_DATA_PACKERS:
      return {
        ...state,
        datapackers: payload,
      }
    case SET_IS_POPUP_DETAIL_SHOW:
      return {
        ...state,
        isPopupDetailShow: payload,
      }
    case SET_SELECTED_PACKER:
      return {
        ...state,
        selectedPacker: payload,
      }
    case SET_IS_POPUP_CHECK_SHOW:
      return {
        ...state,
        isPopupCheckShow: payload,
        isSkuManualInputAuth: false,
      }
    case SET_LAST_PARAMS:
      return {
        ...state,
        lastParams: payload,
      }
    case SET_SELECTED_LOCATION:
      return {
        ...state,
        selectedLocation: payload,
      }
    case SET_LOCATIONS:
      return {
        ...state,
        locations: payload,
      }
    case SET_IS_POPUP_CALL_DRIVER_SHOW:
      return {
        ...state,
        isPopupCallDriverShow: payload,
      }
    case SET_IS_POPUP_CANCEL_SHOW:
      return {
        ...state,
        isPopupCancelShow: payload,
      }
    case SET_DRIVERS:
      return {
        ...state,
        drivers: payload,
      }
    case SET_IS_POPUP_CHANGE_DRIVER_SHOW:
      return {
        ...state,
        isPopupChangeDriverShow: payload,
      }
    case SET_IS_POPUP_CHECK_RESI_SHOW:
      return {
        ...state,
        isPopupCheckResiShow: payload,
      }
    case SET_IS_POPUP_PRINT_LIST_SHOW:
      return {
        ...state,
        isPopupPrintListShow: payload,
      }
    case SET_IS_FROM_QTY:
      return {
        ...state,
        isFromCheckQty: payload,
      }
    case SET_IS_ORDER_POOLING_DATA:
      return {
        ...state,
        orderPoolingData: payload,
      }
    case SET_IS_POPUP_CALL_DRIVER_V2_SHOW:
      return {
        ...state,
        isPopupCallDriverV2Show: payload,
      }
    case SET_IS_POPUP_CALL_EXTERNAL_DRIVER_V2_SHOW:
      return {
        ...state,
        isPopupCallExternalDriverV2Show: payload,
      }
    case SET_IS_POPUP_TYPE_DRIVER_POPUP_V2:
      return {
        ...state,
        typePopupDriverV2: payload,
      }
    case SET_IS_POPUP_INPUT_AUTH_SHOW:
      return {
        ...state,
        isPopupInputAuthShow: payload,
      }
    case SET_IS_SKU_MANUAL_INPUT_NOTES:
      return {
        ...state,
        skuManualInputNotes: payload,
      }
    case SET_IS_SKU_MANUAL_INPUT_AUTH:
      return {
        ...state,
        isSkuManualInputAuth: payload,
      }
    case SET_SELECTED_PRODUCT:
      return {
        ...state,
        manualInputProductId: payload,
      }
    case SET_INTERSECT_HUB:
      return {
        ...state,
        intersectHubs: payload,
      }
    case SET_IS_POPUP_CANCEL_AUTO_ASSIGN_SHOW:
      return {
        ...state,
        isPopUpCancelAutoAssignShow: payload,
      }
    default:
      return state
  }
}
