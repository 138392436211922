import { createSlice } from '@reduxjs/toolkit'
import { GetTrimmingPackingDetailResponseType } from 'utils/apiList/freshPurchaseOrderTrimmingPacking'
import { SLICE_NAME, fetchTrimmingPackingDetail } from './freshPurchaseTrimmingPackingDetailThunk'

export interface FpoTrimmingPackingDetailInterface {
  isLoading: boolean
  data: GetTrimmingPackingDetailResponseType['data'] | null
}

const initialState: FpoTrimmingPackingDetailInterface = {
  isLoading: true,
  data: null,
}

const fpoTrimmingPackingDetailSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTrimmingPackingDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchTrimmingPackingDetail.fulfilled, (state, action) => {
        state.isLoading = false
        state.data = action.payload
      })
      .addCase(fetchTrimmingPackingDetail.rejected, (state) => {
        state.isLoading = false
      })
  },
})
export default fpoTrimmingPackingDetailSlice
