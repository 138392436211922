import { memo } from 'react'
import styled from 'styled-components'
import { SystemIcon, RowOnDelivery, NoData } from 'components'

function TableOnDelivery({
  data,
  handleClickDetail,
  handleClickTrack,
  handleClickChangeDriver,
  handlePrintResi,
}) {
  return (
    <>
      <Container>
        <thead>
          <Tr>
            <Th>
              DRIVER NAME
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th>
              PICK UP TIME
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th>
              TIME DRIVER
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th>
              ORDER ID
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th>AKSI</Th>
          </Tr>
        </thead>
        <tbody>
          {data.map((el) => (
            <RowOnDelivery
              key={el.order_id}
              data={el}
              handleClickChangeDriver={handleClickChangeDriver}
              handleClickDetail={handleClickDetail}
              handleClickTrack={handleClickTrack}
              handlePrintResi={handlePrintResi}
            />
          ))}
        </tbody>
      </Container>
      {data.length === 0 && <NoData />}
    </>
  )
}

TableOnDelivery.defaultProps = {
  data: [],
}

export default memo(TableOnDelivery)

const Container = styled.table`
  width: 100%;
  border-collapse: collapse;
  height: fit-content;
`
const Tr = styled.tr`
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  text-align: start;
  padding: 16px 32px;
`
