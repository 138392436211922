import { lazy } from 'react'

import generateRouterDetail from 'utils/helpers/generateRouterDetail'

const UserFraudStatus = lazy(() => import('../pages/UserFraudStatus'))
const WatchtowerAreaDetection = lazy(() => import('../pages/WatchtowerAreaDetection'))
const FraudDetection = lazy(() => import('../pages/FraudDetection'))
const WatchTowerRule = lazy(() => import('../pages/WatchTowerRule'))

export const UserFraudStatusRouter: RouterFeatureInterface<
  'UserFraudStatusRouter',
  '/dashboard/user-fraud-status'
> = {
  name: 'UserFraudStatusRouter',
  Component: UserFraudStatus,
  path: '/dashboard/user-fraud-status',
  routeType: 'PRIVATE',
  allowedRoles: ['AUTH_FRAUD'],
  isDashboard: true,
}

export const WatchtowerAreaDetectionRouter: RouterFeatureInterface<
  'WatchtowerAreaDetectionRouter',
  '/dashboard/fraud/watchtower/area-detection'
> = {
  name: 'WatchtowerAreaDetectionRouter',
  Component: WatchtowerAreaDetection,
  path: '/dashboard/fraud/watchtower/area-detection',
  routeType: 'PRIVATE',
  allowedRoles: ['AUTH_FRAUD'],
  isDashboard: true,
}

const FraudDetectionRouter: RouterFeatureInterface<
  'FraudDetectionRouter',
  '/dashboard/fraud-detection'
> = {
  name: 'FraudDetectionRouter',
  Component: FraudDetection,
  path: '/dashboard/fraud-detection',
  routeType: 'PRIVATE',
  allowedRoles: ['AUTH_FRAUD'],
  isDashboard: true,
}

export const WatchTowerRuleRouter: RouterFeatureInterface<
  'WatchTowerRuleRouter',
  '/dashboard/fraud/watchtower/rule'
> = {
  name: 'WatchTowerRuleRouter',
  Component: WatchTowerRule,
  path: '/dashboard/fraud/watchtower/rule',
  routeType: 'PRIVATE',
  allowedRoles: ['AUTH_FRAUD'],
  isDashboard: true,
}

export const WatchTowerAutoCancelRuleListRouter: RouterFeatureInterface<
  'WatchTowerAutoCancelRuleListRouter',
  '/dashboard/fraud/watchtower/auto-cancel-rule-list'
> = {
  name: 'WatchTowerAutoCancelRuleListRouter',
  Component: WatchTowerRule,
  path: '/dashboard/fraud/watchtower/auto-cancel-rule-list',
  routeType: 'PRIVATE',
  allowedRoles: ['AUTH_FRAUD'],
  isDashboard: true,
}

const FraudRouter = [
  UserFraudStatusRouter,
  FraudDetectionRouter,
  WatchtowerAreaDetectionRouter,
  WatchTowerRuleRouter,
  WatchTowerAutoCancelRuleListRouter,
]

export const FraudRouterDetail = generateRouterDetail(FraudRouter)

export default FraudRouter
