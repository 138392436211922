import styled from 'styled-components'
import LogoImage from 'assets/images/astro.svg'
import QRCode from 'react-qr-code'

const SectionQr = ({ packageLabel }: { packageLabel: string }) => (
  <Container>
    <ImageLogo src={LogoImage} />
    <ImageQR>
      <QRCode value={packageLabel} size={55} />
    </ImageQR>
  </Container>
)

export default SectionQr

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 25mm;
  box-sizing: border-box;
`

const ImageQR = styled.div`
  width: 55px;
  margin: 0;
  padding: 0;
`

const ImageLogo = styled.img`
  width: 40px;
  margin-top: 15px;
  max-height: 15px;
  margin-bottom: 5px;
`
