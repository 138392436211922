import { shallowEqual, useSelector } from 'react-redux'

const filterSelectState = (state: StoreStateType) => {
  const { dataPrintBarcode } = state.printArea
  return { dataPrintBarcode }
}
export const useTemplatePrintQRv2 = () => {
  const selectedState = useSelector(filterSelectState, shallowEqual)
  const { dataPrintBarcode } = selectedState

  const nameProduct = () => {
    const maxLength = 28
    if (dataPrintBarcode?.productName.length > maxLength) {
      return `${dataPrintBarcode?.productName.substr(0, maxLength)}...`
    }
    return dataPrintBarcode?.productName
  }

  return {
    state: { ...selectedState, nameProduct },
  }
}
