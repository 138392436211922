import styled from 'styled-components'

import { SystemIcon, Title } from 'components'

function HeaderTitle({ handleGoBack, title }) {
  return (
    <Header>
      <span onClick={handleGoBack}>
        <SystemIcon
          iconName="arrow-left"
          colorIcon="textSoft"
          fontSize="headerBigger"
          className="mr-20 pointer"
        />
      </span>
      <Title>{title}</Title>
    </Header>
  )
}

const Header = styled.div`
  display: flex;
  align-items: center;
`

export default HeaderTitle
