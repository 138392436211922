import axios from 'axios'

const { REACT_APP_BASE_URL_API } = process.env

export interface UVCampaignListResponseType {
  id: number
  campaignName: string
  prefix: string
  benefitType: string
  dateStart: string
  dateEnd: string
  quota: number
  quantity: number
  minimumPurchase: number
  amount: number
  discountPercentage: number
  active: true
  status: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
}

export interface UVCampaignListPaginationResponseType {
  sort: string
  direction: string
  pageSize: number
  pageIndex: number
  numberOfElements: number
  totalElements: number
  totalPages: number
}

export interface ActiveUVVoucherRequestType {
  payload: { is_active: boolean }
  id: string
}

export interface ActiveUVVoucherResponseType {
  id: number
  active: boolean
}

export interface GetUVVoucherListResponseType {
  voucher_id: number
  voucher_code: string
  campaign_name: string
  expired_at: string
  quota: number
  used: number
  quota_label: string
  status: string
  active: boolean
}

interface UVoucherListDetailResponseType {
  content: GetUVVoucherListResponseType[]
  empty: boolean
  first: boolean
  last: boolean
  number: number
  numberOfElements: number
  size: number
  totalElements: number
  totalPages: number
}

export interface GetUVDetailByIdResponseType {
  id: number
  campaignName: string
  prefix: string
  benefitType: string
  dateStart: string
  dateEnd: string
  quota: number
  quantity: number
  minimumPurchase: number
  amount: number
  discountPercentage: number
  active: boolean
  status: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  userTargetType: string
  mechanismType: string
  rules: [
    {
      key: string
      value_int: number[]
      items: [
        {
          id: number
          name: string
        },
      ]
      is_exclude: boolean
    },
  ]
  content: {
    description: string
    tnc: string
    image_banner: string
  }
  cost_setting: {
    astro_percentage: number
    partner_name: string[]
  }
}

export type GetUVBenefitResponseType = {
  name: string
  icon: string
}

export type GetUVTargetTypeResponseType = {
  name: string
}

export type GetUVMechanismTypeResponseType = {
  name: string
}

export interface PutUVPromoRequestType {
  id: number
  campaignName: string
  prefix: string
  benefitType: string
  userTargetType: string
  mechanismType: string
  dateStart: number
  dateEnd: number
  quota: number
  quantity: number
  minimumPurchase: number
  discountPercentage: number
  amount: number
}

export interface PostUVPromoRequestType {
  campaignName: string
  prefix: string
  benefitType: string
  userTargetType: string
  mechanismType: string
  dateStart: number
  dateEnd: number
  quota: number
  quantity: number
  minimumPurchase: number
  discountPercentage: number
  amount: number
}

export interface GetUVOrderDetailType {
  orderId: 7264
}

export type GetUVoucherStatusListType = {
  value: string
}

export interface GetCSVDownloadUrlResponseType {
  data: {
    url: string
  }
  error: {
    status: boolean
    message: string
    code: number
  }
  pagination: null
}

export const getUVCampaignList = (params: string) =>
  axios.get<{
    data: { uniqueVoucherList: UVCampaignListResponseType[] }
    pagination: UVCampaignListPaginationResponseType
  }>(`${REACT_APP_BASE_URL_API}/api/promo/unique-vouchers${params}`)

export const putActiveUVVoucher = ({ id, payload }: ActiveUVVoucherRequestType) =>
  axios.put<{ data: ActiveUVVoucherResponseType }>(
    `${REACT_APP_BASE_URL_API}/api/promo/unique-vouchers/${id}/active`,
    payload,
  )

export const getUVVoucherList = (params: string) =>
  axios.get<UVoucherListDetailResponseType>(
    `${REACT_APP_BASE_URL_API}/api/voucher/unique?${params}`,
  )

export const putActiveVoucher = ({ id, payload }: ActiveUVVoucherRequestType) =>
  axios.put(`${REACT_APP_BASE_URL_API}/api/voucher/${id}/active`, payload)

export const getUVDetailById = ({ id }: { id: string }) =>
  axios.get<{ data: GetUVDetailByIdResponseType }>(
    `${REACT_APP_BASE_URL_API}/api/promo/unique-vouchers/${id}`,
  )

export const getUVBenefitType = () =>
  axios.get<GetUVBenefitResponseType[]>(`${REACT_APP_BASE_URL_API}/api/voucher/benefit`)

export const getUVTargetType = () =>
  axios.get<GetUVTargetTypeResponseType[]>(`${REACT_APP_BASE_URL_API}/api/voucher/targeted`)

export const getUVMechanismType = () =>
  axios.get<GetUVMechanismTypeResponseType[]>(`${REACT_APP_BASE_URL_API}/api/voucher/mechanism`)

export const putUVPromo = (payload: PutUVPromoRequestType) =>
  axios.put(`${REACT_APP_BASE_URL_API}/api/promo/unique-vouchers`, payload)

export const postUVPromo = (payload: PostUVPromoRequestType) =>
  axios.post(`${REACT_APP_BASE_URL_API}/api/promo/unique-vouchers`, payload)

export const getUVOrderDetailList = (id: string) =>
  axios.get<GetUVOrderDetailType[]>(`${REACT_APP_BASE_URL_API}/api/voucher/${id}/order-detail`)

export const getUVoucherStatusList = () =>
  axios.get<{ data: GetUVoucherStatusListType[] }>(
    `${REACT_APP_BASE_URL_API}/api/voucher/unique/status`,
  )

export const getCSVDownloadUrl = (id: string) =>
  axios.get<GetCSVDownloadUrlResponseType>(
    `${REACT_APP_BASE_URL_API}/api/promo/unique-vouchers/${id}/download`,
  )
