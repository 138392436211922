import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  getCampaignLoyaltyProductCandidateList,
  postCampaignLoyaltyCreateProduct,
  getLocationHubs,
} from 'utils/apiList/campaignLoyalty'
import type {
  PostCampaignLoyaltyCreateProductRequestType,
  GetCampaignLoyaltyProductCandidateListRequestType,
} from 'utils/apiList/campaignLoyalty'
import { callErrorMsg } from 'helpers/errorMsg'
import { toastSuccess } from 'utils/toast'
import { validateParamsValue } from '../helper'

export const SLICE_NAME = 'loyalty/CampaignLoyaltyProductAddManualStateType'

export const fetchCampaignLoyaltyProductCandidateList = createAsyncThunk(
  `${SLICE_NAME}/fetchCampaignLoyaltyProductCandidateList`,
  async (_, { rejectWithValue, getState }) => {
    const {
      campaignLoyaltyProductAddManual: { query },
    } = getState() as StoreStateType

    const params: GetCampaignLoyaltyProductCandidateListRequestType['params'] = {
      pageSize: query.pageSize,
      pageIndex: query.pageIndex,
      ...validateParamsValue('param', query.name),
      ...validateParamsValue('locationId', query.location.location_id),
    }

    try {
      const res = await getCampaignLoyaltyProductCandidateList({ params })

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const createCampaignLoyaltyProduct = createAsyncThunk(
  `${SLICE_NAME}/createCampaignLoyaltyProduct`,
  async (
    {
      campaignId,
    }: { campaignId: PostCampaignLoyaltyCreateProductRequestType['payload']['campaignId'] },
    { rejectWithValue, getState },
  ) => {
    const {
      campaignLoyaltyProductAddManual: { selectedProductCandidate },
    } = getState() as StoreStateType

    const payload: PostCampaignLoyaltyCreateProductRequestType['payload'] = {
      campaignId,
      redeemRewards: selectedProductCandidate.map((el) => ({
        id: el.id,
        coin: el.coin,
      })),
    }

    try {
      const res = await postCampaignLoyaltyCreateProduct({ payload })
      toastSuccess('Berhasil menambahkan product')
      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchLocationHubList = createAsyncThunk(
  `${SLICE_NAME}/fetchLocationHubList`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getLocationHubs()

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
