import { Get } from 'config/apiServiceConfig'
import AxiosInstance from 'config/apiServiceApiGateway'

import {
  LocationInterface,
  ProductAssetComponentsResponseInterface,
  AssetCategoryResponseInterface,
} from 'features/AssetManagement/@types/global'

export const getLocationsAPI = () =>
  Get<LocationInterface[]>({
    url: '/api/locations/all',
  })

export const getProductAssetComponentAPI = () =>
  AxiosInstance.Get<ProductAssetComponentsResponseInterface, true>({
    url: '/ams/internal/v1/assets/components',
  })

export const getAssetCategoriesAPI = () =>
  AxiosInstance.Get<AssetCategoryResponseInterface, true>({
    url: '/ams/internal/v1/categories',
  })
