import { memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import { RowBanner, SystemIcon } from 'components'

function TableBanner({
  isHubFilterActive,
  data,
  handlChangeActive,
  handleClickEdit,
  onUpdateBlur,
}) {
  const [dataCollection, setDataCollection] = useState([])
  useEffect(() => {
    setDataCollection(data)
  }, [data])

  return (
    <Container>
      <thead>
        <Tr>
          <Th ratio="1">ID</Th>
          <Th ratio="3">Image</Th>
          <Th ratio="5">Nama Banner</Th>
          <Th ratio="5">Hub</Th>
          <Th ratio="4">
            Start Time
            <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
          </Th>
          <Th ratio="4">
            End Time
            <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
          </Th>
          <Th ratio="3">Status</Th>
          <Th ratio="3">Posisi Banner</Th>
          <Th ratio="3">Action</Th>
        </Tr>
      </thead>
      <tbody>
        {data.map((el) => (
          <RowBanner
            onUpdateBlur={onUpdateBlur}
            setDataCollection={setDataCollection}
            dataCollection={dataCollection}
            isHubFilterActive={isHubFilterActive}
            data={el}
            key={el.banner_id}
            handlChangeActive={handlChangeActive}
            handleClickEdit={handleClickEdit}
          />
        ))}
      </tbody>
    </Container>
  )
}

export default memo(TableBanner)

const Container = styled.table`
  width: 100%;
`
const Tr = styled.tr`
  display: flex;
  padding: 16px 32px;
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`
const Th = styled.th`
  color: #212121;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`
