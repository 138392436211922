/* eslint-disable consistent-return */
import { callErrorMsg } from 'helpers/errorMsg'
import { useState, useRef } from 'react'
import { getElligibleRacks } from 'utils/apiList/rak'

export interface GetElligibleRacksParamInterface {
  locationId: number
  statusId: number
  productId: number
  search?: string
  isAutofillRequest?: boolean
}

export interface SlocItemInterface {
  active: boolean
  id: number
  isDeleted: boolean
  locationId: number
  position: number
  rackName: string
}

const useGetElligibleRack = () => {
  const slocRef = useRef<SlocItemInterface>()
  const [isSlocOpen, setIsSlocOpen] = useState<boolean>(false)
  const [slocNoDataText, setSlocNoDataText] = useState('')
  const [slocList, setSlocList] = useState<SlocItemInterface[]>([])
  const [queryParams, setQueryParams] = useState<GetElligibleRacksParamInterface | null>(null)

  const fetchElligibleRack = async (paramData: GetElligibleRacksParamInterface) => {
    const result = await getElligibleRacks(paramData)
    return result?.data?.data
  }

  const initializeSloc = async ({
    locationId,
    statusId,
    productId,
    isAutofillRequest,
  }: Omit<GetElligibleRacksParamInterface, 'search'>) => {
    try {
      if (productId && locationId && statusId) {
        const listData = await fetchElligibleRack({
          locationId,
          statusId,
          productId,
          isAutofillRequest,
        })
        setQueryParams({
          locationId,
          statusId,
          productId,
        })
        setSlocList(listData)

        return listData
      }
    } catch (err) {
      callErrorMsg(err)
    }
  }

  const getIsSlocValid = (selectedItem: SlocItemInterface) =>
    selectedItem.active && !selectedItem.isDeleted

  const setSelectedSloc = (selectedItem: SlocItemInterface) => {
    slocRef.current = selectedItem
  }

  const handleValidateSloc = () => {
    setSlocNoDataText('')

    if (slocRef.current && !getIsSlocValid(slocRef.current)) {
      setIsSlocOpen(true)
      setSlocList([])
    } else {
      setIsSlocOpen(false)
    }

    if (slocRef.current?.isDeleted) {
      setSlocNoDataText('Rack tidak ditemukan karena sudah dihapus')
    } else if (!slocRef.current?.active) {
      setSlocNoDataText('Rack tidak ditemukan karena sudah nonaktif')
    }
  }

  const handleSearchSloc = async (searchName: string) => {
    try {
      const paramData = queryParams as GetElligibleRacksParamInterface
      const listData = await fetchElligibleRack({ ...paramData, search: searchName })

      slocRef.current = undefined
      setSlocNoDataText('')

      if (
        listData?.length === 1 &&
        listData.some(
          (item: SlocItemInterface) =>
            item.rackName.toLowerCase() === searchName.toLowerCase() && item.isDeleted,
        )
      ) {
        setSlocNoDataText('Rack tidak ditemukan karena sudah dihapus')
        setSlocList([])
        return
      }
      if (
        listData?.length === 1 &&
        listData.some(
          (item: SlocItemInterface) =>
            item.rackName.toLowerCase() === searchName.toLowerCase() && !item.active,
        )
      ) {
        setSlocNoDataText('Rack tidak ditemukan karena sudah nonaktif')
        setSlocList([])
        return
      }
      setSlocList(listData)
    } catch (err) {
      callErrorMsg(err)
    }
  }

  return {
    slocList,
    setSlocList,
    isSlocOpen,
    setIsSlocOpen,
    slocNoDataText,
    initializeSloc,
    handleSearchSloc,
    getIsSlocValid,
    setSelectedSloc,
    handleValidateSloc,
    setQueryParams,
  }
}
export default useGetElligibleRack
