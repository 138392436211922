import { type VendorType } from 'features/Enterprise/Vendor/services/addEditVendor'

export const supplierSchedule = [
  {
    label: 'Senin',
    value: '1',
  },
  {
    label: 'Selasa',
    value: '2',
  },
  {
    label: 'Rabu',
    value: '3',
  },
  {
    label: 'Kamis',
    value: '4',
  },
  {
    label: 'Jumat',
    value: '5',
  },
  {
    label: 'Sabtu',
    value: '6',
  },
  {
    label: 'Minggu',
    value: '7',
  },
]

export const paymentTerms = [
  '10 DAYS',
  '14 DAYS',
  '15 DAYS',
  '20 DAYS',
  '21 DAYS',
  '26 DAYS',
  '28 DAYS',
  '3 DAYS',
  '30 DAYS',
  '30% then 40% then 30%',
  '45 DAYS',
  '5 DAYS',
  '60 DAYS',
  '7 DAYS',
  '90 DAYS',
  'CBD',
  'COD',
  'Consignment',
  'DP 50 % then 50% COD',
  'DP 50%',
]

export const vendorCategory = [
  { value: '1', label: 'Sayur & Buah' },
  { value: '2', label: 'Makanan Segar (Daging dan Seafood)' },
  { value: '3', label: 'Susu & Olahan Susu' },
  { value: '4', label: 'Sarapan' },
  { value: '5', label: 'Snack & Es Krim' },
  { value: '6', label: 'Minuman' },
  { value: '7', label: 'Alkohol dan Rokok' },
  { value: '8', label: 'Makanan dan Minuman Siap Makan' },
  { value: '9', label: 'Kebutuhan Pokok' },
  { value: '10', label: 'Bahan Masak dan Bumbu' },
  { value: '11', label: 'Tepung & Bahan Kue' },
  { value: '12', label: 'Makanan Beku' },
  { value: '13', label: 'Ibu & Bayi' },
  { value: '14', label: 'Vitamin' },
  { value: '15', label: 'Obat-obatan' },
  { value: '16', label: 'Alat Kesehatan' },
  { value: '17', label: 'Kebersihan Diri' },
  { value: '18', label: 'Perawatan Diri' },
  { value: '19', label: 'Kecantikan' },
  { value: '20', label: 'Perawatan Rumah' },
  { value: '21', label: 'Perlengkapan Hewan' },
  { value: '22', label: 'Elektronik' },
  { value: '23', label: 'Alat Tulis Kantor' },
  { value: '24', label: 'Fashion & Aksesoris' },
  { value: '25', label: 'Astro Kitchen - Fixed Assets' },
  { value: '26', label: 'Astro Kitchen - Packaging Outer' },
  { value: '27', label: 'Astro Kitchen - Raw Material Packaging' },
  { value: '28', label: 'Astro Kitchen - Supplies' },
  { value: '29', label: 'Dry' },
  { value: '30', label: 'Fixed assets' },
  { value: '31', label: 'General merchandise' },
  { value: '32', label: 'Merchandise Astro Club' },
  { value: '33', label: 'Office household' },
  { value: '34', label: 'Others - Inventories' },
  { value: '35', label: 'Packaging' },
  { value: '36', label: 'Renovation & maintenance' },
  { value: '37', label: 'Rental' },
  { value: '38', label: 'Stationery' },
  { value: '39', label: 'Supplies' },
]

export const ppn = ['11', '12']

export const currency = ['IDR', 'USD']

export const inventoryType = {
  Inventory: 'Inventory',
  NonInventory: 'Non-Inventory',
}

export const pkpType = {
  pkp: {
    label: 'PKP',
    value: 'pkp',
  },
  nonPkp: {
    label: 'Non-PKP',
    value: 'non-pkp',
  },
}

export const vendorType = [
  {
    label: 'PT',
    value: 'PT',
  },
  {
    label: 'CV',
    value: 'CV',
  },
  {
    label: 'UD',
    value: 'UD',
  },
  {
    label: 'Individual',
    value: 'INDIVIDU',
  },
]

export const vendorStatusOptions = [
  { display: 'Menunggu Direview', key: 'PENDING_APPROVAL' },
  { display: 'Sedang Direview', key: 'REVIEWED' },
  { display: 'Menunggu Revisi', key: 'REJECTED' },
  { display: 'Dibatalkan', key: 'CANCELLED' },
]

export const vendorPicOptions = [
  { display: 'Head Catman', key: 'HEAD_CATMAN' },
  { display: 'VP Commercial', key: 'VP_COMMERCIAL' },
  { display: 'Legal', key: 'LEGAL' },
  { display: 'Finance', key: 'FINANCE' },
]

export const complimentaryDocList = (type: VendorType) => [
  {
    document_type: 'ktp',
    file_name: '',
    file_path: '',
    company_id: 0,
    required: type === 'UD' || type === 'INDIVIDU' ? 1 : 0,
    label: 'KTP',
  },
  {
    document_type: 'npwp',
    file_name: '',
    file_path: '',
    company_id: 0,
    required: 1,
    label: 'NPWP',
  },
  {
    document_type: 'credit_limit',
    file_name: '',
    file_path: '',
    company_id: 0,
    required: 1,
    label: 'Limit Kredit',
  },
  {
    document_type: 'nomor_induk_usaha',
    file_name: '',
    file_path: '',
    company_id: 0,
    required: type === 'PT' || type === 'CV' ? 1 : 0,
    label: 'Nomor Induk Berusaha (NIB)',
  },
  {
    document_type: 'bank_guarantee',
    file_name: '',
    file_path: '',
    company_id: 0,
    required: 0,
    label: 'Bank Guarantee',
  },
  {
    document_type: 'account_book',
    file_name: '',
    file_path: '',
    company_id: 0,
    required: 1,
    label: 'Halaman Depan Buku Rekening',
  },
  {
    document_type: 'akta_pendirian',
    file_name: '',
    file_path: '',
    company_id: 0,
    required: type === 'PT' || type === 'CV' ? 1 : 0,
    label: 'Akta Pendirian + SK Kemenkumham',
  },
  {
    document_type: 'akta_perubahan',
    file_name: '',
    file_path: '',
    company_id: 0,
    required: 0,
    label: 'Akta Perubahan + SK Kemenkumham',
  },
  {
    document_type: 'trading_term',
    file_name: '',
    file_path: '',
    company_id: 0,
    required: 0,
    label: 'Trading Terms',
  },
  {
    document_type: 'product_license',
    file_name: '',
    file_path: '',
    company_id: 0,
    required: 0,
    label: 'Product License',
  },
]

export const initialSingleRoleForm = [
  {
    name: '',
    phone_number: '',
    email: '',
    preferred_channel: '',
    role: 'SALES',
    idx: 0,
  },
  {
    name: '',
    phone_number: '',
    email: '',
    preferred_channel: '',
    role: 'FINANCE',
    idx: 1,
  },
  {
    name: '',
    email: '',
    phone_number: '',
    preferred_channel: '',
    role: 'ADMIN-PO',
    idx: 2,
  },
]

export const initialDoubleForm = [
  {
    name: '',
    phone_number: '',
    email: '',
    preferred_channel: '',
    role: 'MULTI-ROLE',
    idx: 0,
  },
]
