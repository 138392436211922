import { Get, Put } from 'config/apiServiceConfig'
import { Outbound } from '../@types'

export type ValidateTruckInformationType = {
  driver_id: number
  driver_provider_id: Nullable<number>
  driver_name: string
  plate_number: string
  vehicle_type: string
  start_fresh_temperature: number
  end_fresh_temperature: number
  driver_type: string
}

export type GetConfigurationResponseType = {
  data: {
    cancelLoadingReasons: {
      code: string
      name: string
    }[]
    needToSetTemperatureLocationIds: number[]
    supportedVehicleType: {
      key: string
      value: string
    }[]
  }
}

type Get3PLResponseInterface = {
  data: {
    id: number
    name: string
  }[]
}

type GetDriversAstroResponseType = {
  data: Outbound.DriverAstroType[]
}

type PutOnDeliveryPayloadType = Omit<
  Outbound.ReadyToShipFormType,
  'driver' | 'vehicleType' | 'selectedAstroDriver'
> & {
  driverId: Nullable<number>
  vehicleType: string
  loadingIDs: number[]
}

export const getValidateInformationTruck = (loadingIds: number[]) =>
  Get<{ data: ValidateTruckInformationType }>({
    url: '/api/wms/:version/supply-order/loading/validate-truck-information',
    version: 'v1',
    params: {
      supply_order_loading_ids: loadingIds.join(','),
    },
  })

export const getConfiguration = () =>
  Get<GetConfigurationResponseType>({
    url: `/api/wms/:version/supply-order/loading/configurations`,
    version: 'v1',
  })

export const get3PL = () =>
  Get<Get3PLResponseInterface>({
    url: '/api/wms/:version/supply-order/loading/3pl-providers',
    version: 'v1',
  })

export const getDriversAstro = (locationId: number) =>
  Get<GetDriversAstroResponseType>({
    url: '/api/wms/:version/supply-order/loading/drivers',
    version: 'v1',
    params: { locationId },
  })

export const putOnDelivery = (data: PutOnDeliveryPayloadType) =>
  Put<{ message: string }>({
    url: '/api/wms/:version/supply-order/update-on-delivery',
    version: 'v2',
    data,
  })

export const putSupplyOrderGrandStatus = (
  supplyOrderId: Outbound.SupplyOrderType['supplyOrderId'],
  payload: { status: string },
) =>
  Put({
    url: '/api/wms/:version/supply-order/:supplyOrderId/status',
    version: 'v2',
    urlParams: { supplyOrderId },
    data: payload,
  })
