import { createAsyncThunk } from '@reduxjs/toolkit'
import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  GetLocationResponseType,
  GetRouteAndScheduleResponseType,
  GetRouteConfigResponseType,
  LocationQueryType,
  RouteAndScheduleQueryType,
  RouteAndScheduleType,
  getLocation,
  getRouteAndQueryList,
  getRouteConfig,
  putRouteMasterStatus,
} from 'utils/apiList/routeAndSchedule'
import { getCleanQueryObj } from 'utils/queryParamsURL'

export const SLICE_NAME = 'routeAndSchedule'

export const fetchRouteAndSchedule = createAsyncThunk<
  GetRouteAndScheduleResponseType,
  undefined,
  RejectValueType
>(`${SLICE_NAME}/fetchRouteAndSchedule`, async (_, { rejectWithValue, getState }) => {
  const {
    routeAndSchedule: { query },
  } = getState() as StoreStateType
  const apiQuery = getCleanQueryObj(query) as Partial<RouteAndScheduleQueryType>

  try {
    const { data } = await getRouteAndQueryList(apiQuery)

    return data
  } catch (error) {
    callErrorMsg(error)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchRouteConfig = createAsyncThunk<GetRouteConfigResponseType['data']>(
  `${SLICE_NAME}/fetchRouteConfig`,
  async (_, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await getRouteConfig()

      return data
    } catch (error) {
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchLocation = createAsyncThunk<
  GetLocationResponseType['content'],
  LocationQueryType
>(`${SLICE_NAME}/fetchLocation`, async (params, { rejectWithValue }) => {
  try {
    const {
      data: { content },
    } = await getLocation(params)

    return content
  } catch (error) {
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const updateRouteMasterStatus = createAsyncThunk<
  { message: string },
  Pick<RouteAndScheduleType, 'active' | 'id'>
>(`${SLICE_NAME}/updateRouteMasterStatus`, async (params, { rejectWithValue }) => {
  const { id, ...updateData } = params
  try {
    const { data } = await putRouteMasterStatus(id, updateData)

    return data
  } catch (error) {
    callErrorMsg(error)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})
