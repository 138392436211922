import {
  getConversionList,
  postSaveConversionRule,
  putUpdateConversionRule,
} from 'features/Product/services/conversionRule'
import { getProductV2API } from 'utils/apiList/productV2'
import { AppDispatchType, RootStateType } from 'store'
import { callErrorMsg } from 'helpers/errorMsg'
import { toastSuccess } from 'utils/toast'
import { AxiosResponse } from 'axios'
import {
  setConversionRuleList,
  setModalAddEditRule,
  resetModalAddEditRule,
  resetQueryParamSearch,
  ConversionRuleInitialStateType,
} from './slice'

export const fetchConversionList =
  () => async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { queryParams } = getState().conversionRule
    try {
      const {
        data: { data },
      } = await getConversionList(queryParams)

      const formattedData = data.map((item) => ({
        uniqueId: crypto.randomUUID(),
        ...item,
      }))
      dispatch(setConversionRuleList(formattedData))
    } catch (error) {
      callErrorMsg(error)
    }
  }

export const fetchProductList = (searchedName: string) => async (dispatch: AppDispatchType) => {
  try {
    const {
      data: { data },
    } = await getProductV2API({
      pageSize: 20,
      name: searchedName,
    })

    dispatch(setModalAddEditRule({ productList: data }))
  } catch (error) {
    callErrorMsg(error)
  }
}

const getDataForSubmit = (
  modalAddEditRule: ConversionRuleInitialStateType['modalAddEditRule'],
) => ({
  rule_name: modalAddEditRule.reference,
  type: modalAddEditRule.conversionType.value,
  product_origins: modalAddEditRule.skuOriginList.map((item) => ({
    product_id: item.selectedSku?.productID as never,
    quantity: item.qty ? +item.qty : 0,
  })),
  product_destinations: modalAddEditRule.skuDestinationList.map((item) => ({
    product_id: item.selectedSku?.productID as never,
    quantity: item.qty ? +item.qty : 0,
  })),
})

export const addConversionRule =
  () => async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { modalAddEditRule } = getState().conversionRule
    const dataToSubmit = getDataForSubmit(modalAddEditRule)

    try {
      const { data } = (await postSaveConversionRule(dataToSubmit)) as unknown as AxiosResponse<{
        message: string
      }>
      dispatch(resetModalAddEditRule())
      dispatch(resetQueryParamSearch())
      dispatch(fetchConversionList())
      toastSuccess(data.message)
    } catch (error) {
      callErrorMsg(error)
    }
  }

export const updateConversionRule =
  (id: number) => async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    try {
      const { modalAddEditRule } = getState().conversionRule
      const dataToSubmit = getDataForSubmit(modalAddEditRule)
      const { data } = (await putUpdateConversionRule(
        id,
        dataToSubmit,
      )) as unknown as AxiosResponse<{ message: string }>
      dispatch(resetModalAddEditRule())
      dispatch(resetQueryParamSearch())
      dispatch(fetchConversionList())
      toastSuccess(data.message)
    } catch (error) {
      callErrorMsg(error)
    }
  }
