import { memo, useEffect, useState } from 'react'
import styled from 'styled-components'
import { SystemIcon, Checkbox, RowCampaign } from 'components'

function TableCampaign({ data, actionList, stateCollections }) {
  const { initListOfSelectedCampaign } = stateCollections.initial
  const { listOfSelectedCampaign } = stateCollections.getter
  const { setListOfSelectedCampaign } = stateCollections.setter

  //INITIAL STATE
  const initIsChecked = false

  //STATE HOOKS
  const [isChecked, setIsChecked] = useState(initIsChecked)

  //EFFECT HOOKS
  useEffect(() => {
    if (listOfSelectedCampaign.length > 0) {
      setIsChecked(true)
    } else {
      setIsChecked(false)
    }
  }, [listOfSelectedCampaign.length])

  const handleChangeAllCampaign = () => {
    setIsChecked((prev) => {
      const allData = data.map((itemes) => {
        return itemes
      })
      if (listOfSelectedCampaign.length > 0) {
        setListOfSelectedCampaign(initListOfSelectedCampaign)
      } else {
        setListOfSelectedCampaign(allData)
      }
      return !prev
    })
  }
  return (
    <Container>
      <thead>
        <Tr>
          <Th>
            <WrapperCheck>
              <Checkbox onChange={handleChangeAllCampaign} checked={isChecked} />
            </WrapperCheck>
          </Th>
          <Th>
            Nama Campaign
            <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
          </Th>{' '}
          <Th>
            Tipe Campaign
            <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
          </Th>
          <Th>
            Tanggal Mulai
            <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
          </Th>
          <Th>
            Tanggal Selesai
            <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
          </Th>
          <Th>Jam Mulai</Th>
          <Th>Jam Selesai</Th>
          <Th>Diskon</Th>
          <Th>Harga Final</Th>
          <Th>Kuota</Th>
          <Th>Kuota / Hari</Th>
          <Th>
            Status
            <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
          </Th>
          <Th>Aktifkan</Th>
        </Tr>
      </thead>
      <tbody>
        {data.map((el, idx) => (
          <RowCampaign
            actionList={actionList}
            key={`sas${idx}`}
            data={el}
            dIndex={idx}
            stateCollections={stateCollections}
          />
        ))}
      </tbody>
    </Container>
  )
}

export default memo(TableCampaign)

const Container = styled.table`
  width: 100%;
  border-collapse: collapse;
  height: fit-content;
`
const Tr = styled.tr`
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  text-align: start;
  padding: 16px;
  padding-right: 20px;
`

const WrapperCheck = styled.div`
  display: grid;
  place-items: center;
`
