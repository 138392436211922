import { Drawer as MuiDrawer, DrawerProps as MuiDrawerProps } from '@mui/material'
import styled from 'styled-components'
import {
  Box,
  Divider,
  DialogContent,
  Typography,
  IconButton,
  Icon,
  BoxPropsType,
} from '@astro-ui/components'

export type DrawerPropsType = MuiDrawerProps & {
  width?: BoxPropsType['width']
  title?: string
  titleProps?: React.CSSProperties
  withHeader?: boolean
  onClose?: () => void
}

const Drawer = ({
  children,
  title = '',
  titleProps,
  width = {
    mobile: '100vw',
    tablet: '40vw',
  },
  withHeader = true,
  onClose,
  ...rest
}: DrawerPropsType) => (
  <DrawerBase anchor="right" {...rest}>
    <Box
      className="drawer-wrapper"
      width={width}
      height="100%"
      display="flex"
      flexDirection="column"
      flex="1 1 auto"
    >
      {withHeader && (
        <Box
          className="drawer-title"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="16px 24px"
          style={titleProps}
        >
          <Typography variant="h2">{title}</Typography>

          {onClose ? (
            <IconButton onClick={onClose} transform="rotate(45deg)">
              <Icon icon="plus" color="grey9" size={24} />
            </IconButton>
          ) : null}
        </Box>
      )}

      <Divider />

      <DialogContent>{children}</DialogContent>
    </Box>
  </DrawerBase>
)

export default Drawer

export type DrawerBasePropsType = MuiDrawerProps

export const DrawerBase = styled(MuiDrawer)<DrawerBasePropsType>``
