import React, { memo } from 'react'
import styled from 'styled-components'
import imgNoData from 'assets/images/no-data.png'
import imgNoDataAstro from 'assets/images/no-data-astro.png'
import { TextBody } from 'components'

interface NoDataInterface {
  withAstroIcon?: boolean
  TextHeading?: React.ReactNode
  TextDescription?: string
  ActionComponent?: React.ReactNode
  containerStyle?: React.CSSProperties
  imgStyle?: React.CSSProperties
}
function NoData({
  withAstroIcon = false,
  TextHeading,
  TextDescription,
  ActionComponent,
  containerStyle,
  imgStyle,
}: NoDataInterface) {
  return (
    <Container style={containerStyle}>
      <Img
        withAstroIcon={withAstroIcon}
        src={withAstroIcon ? imgNoDataAstro : imgNoData}
        alt="no data"
        style={imgStyle}
      />
      {withAstroIcon && (
        <TextBody size="headerBigger" className="mt-24 mb-8">
          {TextHeading}
        </TextBody>
      )}
      <TextBody color="secondaryText" weight="light">
        {withAstroIcon ? TextDescription : 'Tidak ada data'}
      </TextBody>
      {!!ActionComponent && ActionComponent}
    </Container>
  )
}

export default memo(NoData)

type ImgPropsType = {
  withAstroIcon: boolean
}

const Img = styled.img<ImgPropsType>`
  width: ${({ withAstroIcon }) => (withAstroIcon ? 'auto' : '50px')};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  width: 100%;
`
