import { Typography } from '@astro-ui/components'

export type RenderValueType = {
  value: string
  placeholder?: string
}

const RenderValue = ({ value, placeholder }: RenderValueType) => {
  if (!value)
    return (
      <Typography color={!value ? 'grey2' : 'grey9'} variant="body2" lineHeight="24px">
        {placeholder}
      </Typography>
    )
  return <>{value}</>
}

export default RenderValue
