import { Delete, Get, Post, Put } from 'config/apiServiceConfig'

export type LoyaltyEarnCoinParamsType = {
  param: string
  sort: string
  direction: string
  pageSize: number
  pageIndex: number
}

export type LoyaltyEarnCoinProductParamsType = LoyaltyEarnCoinParamsType & {
  categoryId: number
  hubId: number
}

export type LoyaltyEarnCoinListType = {
  active: boolean
  createdAt: string
  createdBy: string
  earnCoin: number
  id: number
  name: string
  price: string
  productId: number
  sku: string
  updatedAt: string
  updatedBy: string
}

export type LoyaltyEarnCoinProductType = {
  name: string
  price: string
  productId: number
  sku: string
}

export type LoyaltyEarnCoinPaginationResponseType = {
  direction: string
  numberOfElements: number
  pageIndex: number
  pageSize: number
  sort: string
  totalElement: number
  totalPages: number
}

export type LoyaltyEarnCoinBasicResponseType<Data, Pagination> = {
  data: Data
  pagination: Pagination
}

export type LoyaltyEarnCoinCategoryResponseType = {
  content: Array<LoyaltyEarnCoinCategoryListType>
  empty: boolean
  first: boolean
  last: boolean
  number: number
  numberOfElements: number
  size: number
  totalElements: number
  totalPages: number
  sort: { sorted: boolean; unsorted: boolean; empty: boolean }
  sorted: boolean
  unsorted: boolean
  pageable: {
    offset: number
    pageNumber: number
    pageSize: number
    paged: boolean
    sort: { sorted: boolean; unsorted: boolean; empty: boolean }
    unpaged: boolean
  }
}

export type GetEarnCoinResponseType = LoyaltyEarnCoinBasicResponseType<
  { earnCoins: Array<LoyaltyEarnCoinListType> },
  LoyaltyEarnCoinPaginationResponseType
>

export type GetProductCandidateResponseType = LoyaltyEarnCoinBasicResponseType<
  { products: Array<LoyaltyEarnCoinProductType> },
  LoyaltyEarnCoinPaginationResponseType
>

export type LoyaltyEarnCoinCategoryListType = {
  category_id: number
  category_name: string
}

export type LoyaltyEarnCoinHubListType = {
  location_id: number
  location_name: string
  location_type: string
}

export type BodyLoyaltyEarnCoinCreateEarnType = {
  productIdList: Array<number>
}

export type BodyLoyaltyEarnCoinUpdateEarnType = BodyLoyaltyEarnCoinCreateEarnType & {
  earnCoin: number
}

export type BodyLoyaltyEarnCoinUpdateEarnStatusType = {
  isAvailable: boolean
}

export type LoyaltyEarnCoinResponseType = {
  data: {
    status: string
  }
}

const earnCoinEndpoint = '/api/loyalty/earn-coin'

export const getEarnCoin = (params: LoyaltyEarnCoinParamsType) =>
  Get<GetEarnCoinResponseType>({ url: earnCoinEndpoint, params })
export const getEarnCoinProductCandidate = (params: LoyaltyEarnCoinProductParamsType) =>
  Get<GetProductCandidateResponseType>({ url: `${earnCoinEndpoint}/product-candidate`, params })
export const deleteEarnCoin = (id: number) =>
  Delete<LoyaltyEarnCoinResponseType>({ url: `${earnCoinEndpoint}/${id}` })
export const updateEarnCoin = (body: BodyLoyaltyEarnCoinUpdateEarnType) =>
  Put<LoyaltyEarnCoinResponseType>({ url: earnCoinEndpoint, data: body })
export const updateEarnCoinStatus = (id: number, body: BodyLoyaltyEarnCoinUpdateEarnStatusType) =>
  Put<LoyaltyEarnCoinResponseType>({ url: `${earnCoinEndpoint}/update-status/${id}`, data: body })
export const createEarnCoin = (body: BodyLoyaltyEarnCoinCreateEarnType) =>
  Post<LoyaltyEarnCoinResponseType>({ url: earnCoinEndpoint, data: body })
export const getEarnCoinCategory = () =>
  Get<LoyaltyEarnCoinCategoryResponseType>({ url: '/api/category' })
