import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  SLICE_NAME,
  fetchListReferralVIP,
  fetchCustomerList,
  addReferralVIP,
  removeReferralVIP,
} from './ReferralVIPGetListThunk'

export interface QueryStateType {
  userID: string
  pageIndex: number
  pageSize: number
}

export type ReferralVIPType = {
  uniqueID: string
  user_id: number
  name: string
  group_type: string
  created_by: string
  created_at: string
  referral_code: string
}

export type CustomerListType = {
  list: {
    customer_active: boolean
    customer_email: string
    customer_first_name: string
    customer_id: number
    customer_is_fraud: boolean
    customer_is_fraud_referral: boolean
    customer_last_name: string
    customer_phone_number: string
    customer_point: number
    customer_referral_code: string
    customer_refund_point: number
    customer_refund_point_fmt: string
    name: string
  }[]
  query: {
    name: string
    pageIndex: number
    pageSize: number
  }
}
export interface ReferralVIPListStateType {
  isLoading: boolean
  referralVIPList: ReferralVIPType[]
  isShowModalDelete: boolean
  isShowModalAddReferralVIP: boolean
  selectedReferralVIP: ReferralVIPType
  query: QueryStateType
  customerList: CustomerListType
}

export const initialState: ReferralVIPListStateType = {
  isLoading: false,
  referralVIPList: [],
  isShowModalDelete: false,
  isShowModalAddReferralVIP: false,
  selectedReferralVIP: {
    created_at: '',
    created_by: '',
    group_type: '',
    name: '',
    referral_code: '',
    uniqueID: '',
    user_id: 0,
  },
  query: {
    userID: '',
    pageIndex: 0,
    pageSize: 30,
  },
  customerList: {
    list: [],
    query: { name: '', pageIndex: 0, pageSize: 30 },
  },
}

export type SetQueryPayloadType = {
  name: keyof QueryStateType
  value: QueryStateType[keyof QueryStateType]
}

const referralVIPList = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setIsShowModalDelete: (state, action: PayloadAction<{ value: boolean }>) => {
      const { value } = action.payload
      state.isShowModalDelete = value
    },
    setIsShowModalAddReferralVIP: (state, action: PayloadAction<{ value: boolean }>) => {
      const { value } = action.payload
      state.isShowModalAddReferralVIP = value
    },
    setSelectedReferralVIP: (
      state,
      action: PayloadAction<{ value: ReferralVIPListStateType['selectedReferralVIP'] }>,
    ) => {
      const { value } = action.payload
      state.selectedReferralVIP = value
    },
    setQuery: (state, action: PayloadAction<SetQueryPayloadType>) => {
      const { value, name } = action.payload

      state.query[name] = value as never
    },
  },

  extraReducers(builder) {
    builder
      .addCase(fetchListReferralVIP.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchListReferralVIP.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchListReferralVIP.fulfilled, (state, action) => {
        state.isLoading = false
        state.referralVIPList = action?.payload?.map((el) => ({
          ...el,
          uniqueID: window.crypto.randomUUID(),
        }))
      })
      .addCase(removeReferralVIP.pending, (state) => {
        state.isLoading = true
      })
      .addCase(removeReferralVIP.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(removeReferralVIP.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(addReferralVIP.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addReferralVIP.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(addReferralVIP.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchCustomerList.fulfilled, (state, action) => {
        state.isLoading = false
        state.customerList.list = action.payload.map((el) => ({
          ...el,
          name: el.customer_first_name,
        }))
      })
  },
})

export const {
  reset,
  setIsShowModalDelete,
  setQuery,
  setIsShowModalAddReferralVIP,
  setSelectedReferralVIP,
} = referralVIPList.actions
export default referralVIPList.reducer
