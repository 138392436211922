import { memo } from "react";
import styled, { css } from "styled-components";
import { NoData, RowTableActivityMonitoring } from "components";

function TableActivityMonitoring({ data }) {
  return (
    <>
      <Container>
        <thead>
          <Tr>
            <Th>No</Th>
            <Th>ACTIVITY NAME</Th>
            <ThQuantity>QUANTITY</ThQuantity>
            <Th>AKSI</Th>
          </Tr>
          <Tr>
            <Th />
            <Th />
            <Th center>PLANNED</Th>
            <Th center>COMPLETED</Th>
            <Th center>PENDING</Th>
            <Th center>DISCREPANCIES</Th>
            <Th />
          </Tr>
        </thead>
        <tbody>
          {data.map((el) => (
            <RowTableActivityMonitoring key={el} />
          ))}
        </tbody>
      </Container>
      {data.length === 0 && <NoData />}
    </>
  );
}

TableActivityMonitoring.defaultProps = {
  data: []
};

export default memo(TableActivityMonitoring);

const Container = styled.table`
  width: 100%;
  border-collapse: collapse;
  height: fit-content;
`;
const Tr = styled.tr`
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`;
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  text-align: start;
  padding: 16px 32px;
  ${({ center }) =>
    center &&
    css`
      text-align: center;
    `}
`;

const ThQuantity = styled.th.attrs({ colSpan: 4 })`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  text-align: start;
  padding: 16px 32px;
  text-align: center;
`;
