import {
  AUTH_HO_MERCHANDISING,
  AUTH_MERCHANDISING_HUB,
  AUTH_MERCHANDISING_WAREHOUSE,
  AUTH_PRODUCT_MANAGER,
  AUTH_QUALITY_ENGINEERING,
} from 'middleware/privateRoute'

const catalogue = {
  add: [
    AUTH_MERCHANDISING_HUB,
    AUTH_MERCHANDISING_WAREHOUSE,
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_HO_MERCHANDISING,
  ],
  edit: [
    AUTH_MERCHANDISING_HUB,
    AUTH_MERCHANDISING_WAREHOUSE,
    AUTH_QUALITY_ENGINEERING,
    AUTH_PRODUCT_MANAGER,
    AUTH_HO_MERCHANDISING,
  ],
  bulkUpload: [
    AUTH_MERCHANDISING_HUB,
    AUTH_MERCHANDISING_WAREHOUSE,
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_HO_MERCHANDISING,
  ],
}

export default catalogue
