import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type {
  GetFpoDetailResponseType,
  StatusToBeType,
  ActionListType,
} from 'utils/apiList/freshPurchaseOrder'
import { SLICE_NAME, fetchFpoDetail, updateStatusOfFpo } from './freshPurchaseOrderDetailThunk'

export type DataType = GetFpoDetailResponseType['data'] & { actionList: ActionListType }

export interface FpoDetailInterface {
  isLoading: boolean
  searchSku: string
  data: null | DataType
  itemList: GetFpoDetailResponseType['data']['items']
  modal: {
    modalType: 'cancel' | 'confirm' | ''
    message: string
    isOpen: boolean
    idToCancelOrConfirm: number
    statusToBe: StatusToBeType | ''
  }
}

const initialState: FpoDetailInterface = {
  isLoading: true,
  searchSku: '',
  data: null,
  itemList: [],
  modal: {
    modalType: '',
    message: '',
    isOpen: false,
    idToCancelOrConfirm: 0,
    statusToBe: '',
  },
}

const freshPurchaseOrderDetailSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    searchItemsBySkuName: (state, action: PayloadAction<string>) => {
      const skuName = action.payload
      state.searchSku = skuName
      state.itemList =
        state.data?.items.filter((itemData) =>
          itemData.product.product_name.toLocaleLowerCase().includes(skuName.toLocaleLowerCase()),
        ) || []
    },
    toggleModal: (state, action: PayloadAction<FpoDetailInterface['modal']>) => {
      state.modal = action.payload
    },
    resetModal: (state) => {
      state.modal = initialState.modal
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFpoDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFpoDetail.fulfilled, (state, action) => {
        state.isLoading = false
        state.data = action.payload
      })
      .addCase(fetchFpoDetail.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateStatusOfFpo.fulfilled, (state) => {
        freshPurchaseOrderDetailSlice.caseReducers.resetModal(state)
      })
  },
})
export default freshPurchaseOrderDetailSlice
