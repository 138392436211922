import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  GetLocationTypeResponseType,
  GetLocationListByTypeResponseType,
} from 'features/Location/@types/location'
import {
  StorageListType,
  StorageItemType,
  SelectedCapacityUomType,
  UomListType,
} from 'features/Rack/@types/rack'
import { GetAreaListResponseType } from 'features/Rack/services/area'
import {
  GetDetailRackMultipleResponseType,
  GetRackDetailInkongResponseType,
} from 'features/Rack/services/rackAddEdit'
import { ProductListType } from 'features/Product/@types/Product'

export const errorMessageWording = {
  selected: 'Field wajib dipilih',
  greaterThanZero: 'Field wajib lebih besar dari 0',
  required: 'Field wajib diisi',
}

type FormErrorMessageType = {
  selectedLocationType: string
  selectedLocationName: string
  selectedStorageEnvironment: string
  selectedStorageType: string
  selectedArea: string
  selectedVolumeUom: string
  selectedQtyUom: string
  selectedWeightUom: string
  maxQuantity: string
  maxVolume: string
  maxWeight: string
  maxSku: string
  rack: {
    rackName: string
    position: string
    productSkuNo: string
    minReplenishQty: string
    maxReplenishQty: string
    replenishQty: string
  }[]
}

export interface RackAddEditInitialType {
  formType: 'add' | 'update'
  locationTypeList: GetLocationTypeResponseType['data']
  locationList: GetLocationListByTypeResponseType[]
  volumeUomList: UomListType
  qtyUomList: UomListType
  weightUomList: UomListType
  storageEnvironmentList: StorageListType
  storageTypeList: StorageListType
  areaList: GetAreaListResponseType
  selectedLocationType: Nullable<GetLocationTypeResponseType['data'][number]>
  selectedLocationName: Nullable<GetLocationListByTypeResponseType>
  selectedStorageEnvironment: Nullable<StorageItemType>
  selectedStorageType: Nullable<StorageItemType>
  selectedVolumeUom: Nullable<SelectedCapacityUomType>
  selectedQtyUom: Nullable<SelectedCapacityUomType>
  selectedWeightUom: Nullable<SelectedCapacityUomType>
  selectedArea: Nullable<Pick<GetAreaListResponseType[number], 'id' | 'name'>>
  maxQuantity: number
  maxVolume: number
  maxWeight: number
  maxSku: number
  mixAttribute: boolean
  mixProduct: boolean
  active: boolean
  racks: {
    uniqueId: string
    rackName: string
    position: Nullable<number>
    selectedSku: Nullable<Pick<ProductListType[number], 'product_id' | 'product_sku_no'>>
    minReplenishQty: Nullable<number>
    maxReplenishQty: Nullable<number>
    replenishQty: Nullable<number>
  }[]
  skuList: ProductListType
  selectedSku: Nullable<Pick<ProductListType[number], 'product_id' | 'product_sku_no'>>
  errorMessage: FormErrorMessageType
  rackSingleDetailResponse: Nullable<GetRackDetailInkongResponseType>
}

const initialState: RackAddEditInitialType = {
  formType: 'add',
  locationTypeList: [],
  locationList: [],
  volumeUomList: [],
  qtyUomList: [],
  weightUomList: [],
  storageEnvironmentList: [],
  storageTypeList: [],
  areaList: [],
  selectedLocationType: null,
  selectedLocationName: null,
  selectedStorageEnvironment: null,
  selectedStorageType: null,
  selectedVolumeUom: null,
  selectedQtyUom: null,
  selectedWeightUom: null,
  selectedArea: null,
  maxQuantity: 0,
  maxVolume: 0,
  maxWeight: 0,
  maxSku: 0,
  mixAttribute: false,
  mixProduct: false,
  active: false,
  racks: [
    {
      uniqueId: crypto.randomUUID(),
      rackName: '',
      position: null,
      selectedSku: null,
      minReplenishQty: null,
      maxReplenishQty: null,
      replenishQty: null,
    },
  ],
  skuList: [],
  selectedSku: null,
  errorMessage: {
    selectedLocationType: '',
    selectedLocationName: '',
    selectedStorageEnvironment: '',
    selectedStorageType: '',
    selectedArea: '',
    selectedVolumeUom: '',
    selectedQtyUom: '',
    selectedWeightUom: '',
    maxQuantity: '',
    maxVolume: '',
    maxWeight: '',
    maxSku: '',
    rack: [
      {
        rackName: '',
        position: '',
        productSkuNo: '',
        minReplenishQty: '',
        maxReplenishQty: '',
        replenishQty: '',
      },
    ],
  },
  rackSingleDetailResponse: null,
}

const rackAddEdit = createSlice({
  name: 'rackAddEdit',
  initialState,
  reducers: {
    setFormType: (state, action: PayloadAction<RackAddEditInitialType['formType']>) => {
      state.formType = action.payload
    },
    setStorageList: (
      state,
      action: PayloadAction<{
        fieldName: 'storageTypeList' | 'storageEnvironmentList'
        listData: StorageListType
      }>,
    ) => {
      const { fieldName, listData } = action.payload
      state[fieldName] = listData
    },
    setAreaList: (state, action: PayloadAction<GetAreaListResponseType>) => {
      state.areaList = action.payload
    },
    setUomList: (
      state,
      action: PayloadAction<{
        fieldName: 'volumeUomList' | 'qtyUomList' | 'weightUomList'
        listData: UomListType
      }>,
    ) => {
      const { fieldName, listData } = action.payload
      state[fieldName] = listData
    },
    setLocationTypeList: (state, action: PayloadAction<GetLocationTypeResponseType['data']>) => {
      state.locationTypeList = action.payload
    },
    setLocationList: (state, action: PayloadAction<GetLocationListByTypeResponseType[]>) => {
      state.locationList = action.payload
    },
    setSelectedLocationName: (state, action) => {
      state.selectedLocationName = action.payload
    },
    setSelectedLocationType: (state, action) => {
      state.selectedLocationType = action.payload
    },
    setQtyOfCapacity: (
      state,
      action: PayloadAction<{
        fieldName: 'maxQuantity' | 'maxVolume' | 'maxWeight'
        value: number
      }>,
    ) => {
      const { fieldName, value } = action.payload
      state[fieldName] = value
    },
    setMaxSku: (state, action: PayloadAction<number>) => {
      state.maxSku = action.payload
    },
    setSelectedStorage: (
      state,
      action: PayloadAction<{
        fieldName: 'selectedStorageType' | 'selectedStorageEnvironment'
        selectedData: StorageItemType
      }>,
    ) => {
      const { fieldName, selectedData } = action.payload
      state[fieldName] = selectedData
    },
    setUomOfCapacity: (
      state,
      action: PayloadAction<{
        fieldName: 'selectedVolumeUom' | 'selectedQtyUom' | 'selectedWeightUom'
        selectedData: SelectedCapacityUomType
      }>,
    ) => {
      const { fieldName, selectedData } = action.payload
      state[fieldName] = selectedData
    },
    setAdditionalInformationField: (
      state,
      action: PayloadAction<{
        fieldName: 'mixAttribute' | 'mixProduct' | 'active'
        value: boolean
      }>,
    ) => {
      const { fieldName, value } = action.payload
      state[fieldName] = value
    },
    addRacks: (state) => {
      state.racks.push({
        uniqueId: crypto.randomUUID(),
        rackName: '',
        position: null,
        selectedSku: null,
        minReplenishQty: null,
        maxReplenishQty: null,
        replenishQty: null,
      })
      state.errorMessage.rack.push({
        rackName: '',
        position: '',
        productSkuNo: '',
        minReplenishQty: '',
        maxReplenishQty: '',
        replenishQty: '',
      })
    },
    deleteRacks: (
      state,
      action: PayloadAction<{
        index: number
      }>,
    ) => {
      const { index } = action.payload
      state.racks.splice(index, 1)
      state.errorMessage.rack.splice(index, 1)
    },
    setPositionOfRackAtIndex: (
      state,
      action: PayloadAction<{
        index: number
        fieldName: 'rackName' | 'position' | 'minReplenishQty' | 'maxReplenishQty' | 'replenishQty'
        value: string | number
      }>,
    ) => {
      const { index, fieldName, value } = action.payload
      state.racks[index][fieldName] = value as never
    },
    setErrorMessage: (state, action: PayloadAction<Partial<FormErrorMessageType>>) => {
      state.errorMessage = {
        ...state.errorMessage,
        ...action.payload,
      }
    },
    setErrorMessageOfRack: (
      state,
      action: PayloadAction<{
        index: number
        fieldName:
          | 'rackName'
          | 'position'
          | 'minReplenishQty'
          | 'maxReplenishQty'
          | 'replenishQty'
          | 'productSkuNo'
        message: string
      }>,
    ) => {
      const { index, fieldName, message } = action.payload
      state.errorMessage.rack[index][fieldName] = message as string
    },
    setFormDataOnGetDetailRackMultiple: (
      state,
      action: PayloadAction<GetDetailRackMultipleResponseType['data']>,
    ) => {
      const { location, racks } = action.payload
      state.selectedLocationType = {
        id: 0,
        name: location.location_type,
      }
      state.selectedLocationName = location
      if (racks.content.length) {
        state.selectedStorageEnvironment = {
          rackStorageId: racks.content[0].storageEnvironmentId,
          rackStorageName: racks.content[0].storageEnvironment,
        }
        state.selectedStorageType = {
          rackStorageId: racks.content[0].storageTypeId,
          rackStorageName: racks.content[0].storageType,
        }
        state.selectedVolumeUom = {
          id: racks.content[0].maxVolumeUomId,
          description: racks.content[0].maxVolumeUom,
        }
        state.selectedQtyUom = {
          id: racks.content[0].maxQtyUomId,
          description: racks.content[0].maxQtyUom,
        }
        state.selectedWeightUom = {
          id: racks.content[0].maxWeightUomId,
          description: racks.content[0].maxWeightUom,
        }
        state.maxQuantity = racks.content[0].maxQty
        state.maxVolume = racks.content[0].maxVolume
        state.maxWeight = racks.content[0].maxWeight
        state.mixAttribute = racks.content[0].mixAttribute
        state.mixProduct = racks.content[0].mixProduct
        state.active = racks.content[0].active
        // state.racks = racks.content.map((item) => ({
        //   uniqueId: crypto.randomUUID(),
        //   rackName: item.rack_name,
        //   position: item.position,
        //   selectedSku: item?.productId
        //     ? {
        //         productID: item?.productId,
        //         productName: item?.productName,
        //         productSkuNo: item?.productSku,
        //       }
        //     : null,

        //   minReplenishQty: item.minReplenishQty || null,
        //   maxReplenishQty: item.maxReplenishQty || null,
        //   replenishQty: item.replenishQty || null,
        // }))
        state.errorMessage.rack = Array.from({ length: racks.content.length }, () => ({
          rackName: '',
          position: '',
          productSkuNo: '',
          minReplenishQty: '',
          maxReplenishQty: '',
          replenishQty: '',
        }))
      }
    },
    setRackSingleDetailResponse: (
      state,
      action: PayloadAction<GetRackDetailInkongResponseType>,
    ) => {
      state.rackSingleDetailResponse = action.payload
    },
    setSkuList: (state, action: PayloadAction<ProductListType>) => {
      state.skuList = action.payload
    },
    setSelectedSku: (state, action: PayloadAction<RackAddEditInitialType['selectedSku']>) => {
      state.selectedSku = action.payload
    },
    setSelectedSkuOfRackAtIndex: (
      state,
      action: PayloadAction<{
        index: number
        selectedData: RackAddEditInitialType['racks'][number]['selectedSku']
      }>,
    ) => {
      const { index, selectedData } = action.payload
      state.racks[index].selectedSku = selectedData
    },
    setSelectedArea: (state, action: PayloadAction<RackAddEditInitialType['selectedArea']>) => {
      state.selectedArea = action.payload
    },
    resetState: () => initialState,
  },
})
export const {
  setFormType,
  setStorageList,
  setAreaList,
  setUomList,
  setLocationTypeList,
  setLocationList,
  setSelectedLocationName,
  setSelectedLocationType,
  setSelectedStorage,
  setQtyOfCapacity,
  setMaxSku,
  setUomOfCapacity,
  setAdditionalInformationField,
  addRacks,
  deleteRacks,
  setPositionOfRackAtIndex,
  setErrorMessage,
  setErrorMessageOfRack,
  setFormDataOnGetDetailRackMultiple,
  resetState,
  setRackSingleDetailResponse,
  setSkuList,
  setSelectedSku,
  setSelectedSkuOfRackAtIndex,
  setSelectedArea,
} = rackAddEdit.actions
export default rackAddEdit.reducer
