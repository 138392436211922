import axios from "axios";
const { REACT_APP_BASE_URL_API } = process.env;
const baseUrl = REACT_APP_BASE_URL_API;

export const getSericeLEvelList_SL = (query = "") =>
  axios.get(`${baseUrl}/api/service-level?${query}`);

export const getDetailServiceLevel_SL = (id) =>
  axios.get(`${baseUrl}/api/service-level/${id}`);

export const postServiceLevel_SL = (data) =>
  axios.post(`${baseUrl}/api/service-level`, data);

export const putServiceLevel_SL = (id, data) =>
  axios.put(`${baseUrl}/api/service-level/${id}`, data);
