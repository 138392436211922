import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { createPackageId, getPackageId, checkPackageId } from 'utils/apiList/packageId'
import { getTokenCookies } from 'config/cookies'

const { REACT_APP_BASE_URL_API } = process.env
export const fullfilmentApi = createApi({
  reducerPath: 'fullfilmentApi',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_BASE_URL_API,
    prepareHeaders: (headers, { getState }) => {
      const token = getTokenCookies()
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    getAllPackageId: builder.query({
      query: (query = '') => `${getPackageId()}${query}`,
    }),
    getSinglePackageId: builder.query({
      query: ({ path, query }) => `${getPackageId()}/${path}${query}`,
    }),
    checkPackageId: builder.query({
      query: (query = '') => `${checkPackageId()}${query}`,
    }),
    createPackageId: builder.mutation({
      query: (packageID) => ({
        url: createPackageId(),
        method: 'POST',
        body: packageID,
      }),
    }),
  }),
})

export const {
  useGetAllPackageIdQuery,
  useGetSinglePackageIdQuery,
  useCheckPackageIdQuery,
  useCreatePackageIdMutation,
} = fullfilmentApi
