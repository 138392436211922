import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getDynamicLandingList,
  putDynamicLandingStatus,
  postDynamicLandingCopy,
  deleteDynamicLanding,
} from 'utils/apiList/dynamicLanding'
import type {
  PutDynamicLandingStatusRequestType,
  PostDynamicLandingCopyRequestType,
} from 'utils/apiList/dynamicLanding'
import { toastSuccess } from 'utils/toast'

export const SLICE_NAME = 'dynamicLandingList'

export const fetchDynamicLanding = createAsyncThunk(
  `${SLICE_NAME}/fetchDynamicLanding`,
  async (_, { rejectWithValue, getState }) => {
    const { dynamicLandingList } = getState() as StoreStateType

    const { query } = dynamicLandingList

    try {
      const { data } = await getDynamicLandingList({
        pageSize: query.pageSize,
        pageIndex: query.pageIndex,
        search: query.name,
      })

      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar landing page')
      return rejectWithValue(err)
    }
  },
)

export const updateDynamicLandingStatus = createAsyncThunk(
  `${SLICE_NAME}/updateDynamicLandingStatus`,
  async (params: PutDynamicLandingStatusRequestType, { rejectWithValue }) => {
    try {
      const { data } = await putDynamicLandingStatus(params)

      toastSuccess('Berhasil memperbarui status')
      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal  memperbarui status')
      return rejectWithValue(err)
    }
  },
)

export const duplicateDynamicLanding = createAsyncThunk(
  `${SLICE_NAME}/duplicateDynamicLanding`,
  async (params: PostDynamicLandingCopyRequestType, { rejectWithValue }) => {
    try {
      const { data } = await postDynamicLandingCopy(params)

      toastSuccess(`Berhasil mengcopy Landing Page ${data.data.name}`)
      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal mengcopy Landing Page')
      return rejectWithValue(err)
    }
  },
)

export const removeDynamicLanding = createAsyncThunk(
  `${SLICE_NAME}/removeDynamicLanding`,
  async (params: { id: number; name: string }, { rejectWithValue }) => {
    try {
      await deleteDynamicLanding(params.id)

      toastSuccess(`Berhasil menghapus Landing Page ${params.name}`)
      return null
    } catch (err) {
      callErrorMsg(err, 'Gagal menghapus Landing Page')
      return rejectWithValue(err)
    }
  },
)
