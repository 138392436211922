import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  fetchWatchTowerConfig,
  updateWatchTowerConfig,
  fetchLocationHubList,
  createHubExclusion,
  fetchHubExclusionList,
  updateHubExclusion,
  destroyHubExclusion,
} from './fraudSettingThunk'

const SLICE_NAME = 'fraudSetting'

export type WatchTowerConfigType = {
  filterWatchTower: string
  filterNewBuyer: string
  filterSembako: string
  maxOrderAddress: string
  discountLimit: string
  orderPercentage: string
  minimumTransaction: string
  category: string
  hubExclusions: {
    locationName: string
    uniqueKey: string
    id: number
    locationType: string
    locationId: number
  }[]
}

export type LociationHubListType = {
  locationId: number
  locationType: string
  locationName: string
  name: string
  uniqueKey: string
}
export interface FreaudSettingStateType {
  isLoading: boolean
  isShowModalFilterSetting: boolean
  watchTowerConfig: WatchTowerConfigType
  locationHubList: LociationHubListType[]
}

export const initialState: FreaudSettingStateType = {
  isLoading: false,
  isShowModalFilterSetting: false,
  watchTowerConfig: {
    category: '',
    discountLimit: '',
    filterNewBuyer: '',
    filterSembako: '',
    filterWatchTower: '',
    maxOrderAddress: '',
    minimumTransaction: '',
    orderPercentage: '',
    hubExclusions: [
      {
        uniqueKey: window.crypto.randomUUID(),
        id: 0,
        locationId: 0,
        locationName: '',
        locationType: '',
      },
    ],
  },
  locationHubList: [],
}

const fraudSetting = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setIsShowModalFilterSetting: (state, action: PayloadAction<{ isShow: boolean }>) => {
      state.isShowModalFilterSetting = action.payload.isShow
    },
    setWatchTowerConfig: (
      state,
      action: PayloadAction<{
        fieldName: keyof FreaudSettingStateType['watchTowerConfig']
        value: string | FreaudSettingStateType['watchTowerConfig']['hubExclusions']
      }>,
    ) => {
      state.watchTowerConfig[action.payload.fieldName] = action.payload.value as never
    },
    setAddHubExclusion: (
      state,
      action: PayloadAction<{
        data: FreaudSettingStateType['watchTowerConfig']['hubExclusions'][0]
      }>,
    ) => {
      const {
        payload: { data },
      } = action
      state.watchTowerConfig.hubExclusions = [...state.watchTowerConfig.hubExclusions, data]
    },
    setHubExclusion: (
      state,
      action: PayloadAction<{
        data: FreaudSettingStateType['watchTowerConfig']['hubExclusions'][0]
        index: number
      }>,
    ) => {
      const {
        payload: { data, index },
      } = action
      state.watchTowerConfig.hubExclusions[index] = data
    },
    setDeleteHubExclusion: (
      state,
      action: PayloadAction<{
        index: number
      }>,
    ) => {
      const { index } = action.payload
      state.watchTowerConfig.hubExclusions.splice(index, 1)
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchWatchTowerConfig.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchWatchTowerConfig.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchWatchTowerConfig.fulfilled, (state, action) => {
        state.isLoading = false
        state.watchTowerConfig = {
          ...action.payload,
          hubExclusions: state.watchTowerConfig.hubExclusions,
        }
        state.isShowModalFilterSetting = true
      })
      .addCase(updateWatchTowerConfig.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateWatchTowerConfig.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateWatchTowerConfig.fulfilled, (state) => {
        state.isLoading = false
        state.isShowModalFilterSetting = false
      })
      .addCase(fetchLocationHubList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchLocationHubList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchLocationHubList.fulfilled, (state, action) => {
        state.isLoading = false
        state.locationHubList = action.payload.map((el) => ({
          locationId: el.location_id,
          locationType: el.location_type,
          locationName: el.location_name,
          name: el.location_name,
          uniqueKey: window.crypto.randomUUID(),
        }))
      })
      .addCase(createHubExclusion.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createHubExclusion.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(createHubExclusion.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(updateHubExclusion.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateHubExclusion.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateHubExclusion.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(destroyHubExclusion.pending, (state) => {
        state.isLoading = true
      })
      .addCase(destroyHubExclusion.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(destroyHubExclusion.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchHubExclusionList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchHubExclusionList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchHubExclusionList.fulfilled, (state, action) => {
        state.isLoading = false
        state.watchTowerConfig.hubExclusions = action.payload.data.whitelistLocation.map((el) => ({
          id: el.id,
          locationId: el.locationId,
          locationName: el.locationName,
          locationType: 'HUB',
          uniqueKey: window.crypto.randomUUID(),
        }))
      })
  },
})

export default fraudSetting.reducer
export const {
  reset,
  setIsShowModalFilterSetting,
  setWatchTowerConfig,
  setAddHubExclusion,
  setHubExclusion,
  setDeleteHubExclusion,
} = fraudSetting.actions
