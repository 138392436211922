import { memo } from "react";
import styled from "styled-components";
// import storeContext from "storeContext";
import { SystemIcon, Button } from "components";
import { formatMoneyRMG } from "helpers";
import { Toggle } from "components/atoms";

function RowDetailPackage({
  data,
  handleClickEdit,
  handleDelete,
  handleToggleSellingUnit
}) {
  // const {
  //   state: { selectedProduct }
  // } = useContext(storeContext.ProductContext);
  return (
    <Tr>
      <Td isSticky left="0px">
        {data.multiplier}
      </Td>
      <Td isSticky left="74px">
        {data.to_unit_id.description}
      </Td>
      <Td isSticky left="155px">
        {data.barcode}
      </Td>
      <Td>{formatMoneyRMG(data.height, "")}</Td>
      <Td>{formatMoneyRMG(data.length, "")}</Td>
      <Td>{formatMoneyRMG(data.width, "")}</Td>
      <Td>{data.dimension_unit.description}</Td>
      <Td>{formatMoneyRMG(data.purchase_price)}</Td>
      <Td>{formatMoneyRMG(data.volume, "")}</Td>
      <Td>{data.volume_unit.description}</Td>
      <Td>{formatMoneyRMG(data.net_weight, "")}</Td>
      <Td>{formatMoneyRMG(data.gross_weight, "")}</Td>
      <Td>{data.weight_unit.description}</Td>
      <Td>
        <WrapperAction>
          <Button variant="wrapper" onClick={() => handleClickEdit(data)}>
            <WrapperIcon>
              <SystemIcon iconName="edit" />
            </WrapperIcon>
          </Button>
          <Button variant="wrapper" onClick={() => handleDelete(data)}>
            <WrapperIcon>
              <SystemIcon iconName="trash" />
            </WrapperIcon>
          </Button>
        </WrapperAction>
      </Td>
      <Td>
        <Toggle
          active={data?.is_selling_unit}
          onClick={() => handleToggleSellingUnit(data)}
        />
      </Td>
    </Tr>
  );
}

RowDetailPackage.defaultProps = {
  handleClickOption: () => {}
};

export default memo(RowDetailPackage);

const Tr = styled.tr`
  padding: 12px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
  position: relative;
`;
const Td = styled.td`
  background-color: ${({ theme: { colors } }) => colors.white};
  color: ${({ theme: { colors }, color = "textSoft" }) => colors[color]};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  padding: 16px;
  min-width: ${({ min }) => min};
  white-space: nowrap;
  width: ${({ width }) => width};
  ${({ isSticky, left }) =>
    isSticky && {
      position: "sticky",
      left: left
    }}
`;

const WrapperIcon = styled.div`
  height: 32px;
  width: 32px;
  display: grid;
  place-items: center;
  border-radius: 8px;
  border: 1px solid #bfc9d9;
`;

const WrapperAction = styled.div`
  display: flex;
  gap: 16px;
`;
