import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getProductVariantList,
  deleteProductVariant,
} from 'features/Product/services/productVariant'
import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'
import { callErrorMsg } from 'helpers/errorMsg'
import { SLICE_NAME } from './state'

export const fetchProductVariant = createAsyncThunk(
  `${SLICE_NAME}/fetchProductVariant`,
  async (_, { rejectWithValue, getState }) => {
    const {
      productVariant: { query },
    } = getState() as StoreStateType

    try {
      const { data } = await getProductVariantList(query)

      return data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const removeProductVariant = createAsyncThunk(
  `${SLICE_NAME}/removeProductVariant`,
  async (id: number) => {
    try {
      const {
        data: { message },
      } = await deleteProductVariant(id)

      return message
    } catch (error) {
      callErrorMsg(error)
      return ''
    }
  },
)
