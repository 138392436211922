import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit'
import { withLoadingReducer } from 'utils/reducerHandler'
import { AllOrderResponseType } from 'features/Order/services/allOrder'
import { fetchOrderTypes, fetchAllOrder, fetchOrderLocations } from './thunk'

export type OrderPoolType = {
  order_id: number
  order_location_quadrant: number
  order_status: string
  pool_number: number
}

export type OrderType = 'INSTANT' | 'GRAB_MART' | 'SUPER' | 'KITCHEN' | 'VENDING'

export type OrderItemType = {
  cloud_kitchen: boolean
  confirm_timestamp: number
  external_booking_id: string | number
  external_order_id: string | number
  fulfill_status: string
  late_potential: boolean
  order_arrived_at: number
  order_cancel_at: number
  order_completed_at: number
  order_created_at: number
  order_customer_address: string
  order_customer_name: string
  order_id: number
  order_invoice: string
  order_location_name: string
  order_location_quadrant: number
  order_note: string
  order_payment_at: number
  order_payment_status: string
  order_placement_at: number
  order_pool: OrderPoolType[]
  order_reference: string
  order_service_level_name: string
  order_status: string
  order_third_party_logistic_term: string
  order_third_party_logistic_used: boolean
  order_timer: null
  order_timeslot: null
  order_total_fulfill_quantity: number
  order_total_price: string
  order_total_quantity: number
  order_type: OrderType
  pool_number: number
  sla: number
}

export type OrderTypeOptionType =
  | {
      label: 'Semua Tipe'
      value: ''
    }
  | {
      label: string
      value: OrderType
    }

export type OrderLocationOptionType =
  | {
      id: Nullable<number>
      label: 'Semua Lokasi'
    }
  | {
      id: number
      label: string
      is_new_ui?: boolean
      is_auto_assign?: boolean
      location_type?: string
      is_auto_pool?: boolean
      is_upcoming_enable?: boolean
    }

export type AllOrderStateType = {
  isLoadingOrder: boolean
  isFetchingLocation: boolean
  orderTypes: OrderTypeOptionType[]
  orders: OrderItemType[]
  orderLocations: OrderLocationOptionType[]
  defaultLocation: Nullable<OrderLocationOptionType>
  pagination: Nullable<Omit<AllOrderResponseType, 'content'>>
}

const initialState: AllOrderStateType = {
  isLoadingOrder: false,
  isFetchingLocation: false,
  orderTypes: [],
  orders: [],
  orderLocations: [],
  defaultLocation: null,
  pagination: {
    empty: true,
    first: true,
    last: false,
    number: 0,
    numberOfElements: 0,
    pageable: {
      sort: {
        sorted: false,
        unsorted: true,
        empty: true,
      },
      offset: 0,
      pageSize: 20,
      pageNumber: 0,
      paged: false,
      unpaged: true,
    },
    size: 0,
    sort: {
      sorted: false,
      unsorted: false,
      empty: true,
    },
  },
}

const createAllOrderSlice = createSlice({
  name: 'allOrderV2',
  initialState,
  reducers: {
    setIsLoadingOrder(state, action: PayloadAction<boolean>) {
      state.isLoadingOrder = action.payload
    },
  },
  extraReducers: withLoadingReducer(
    (builder: ActionReducerMapBuilder<AllOrderStateType>) => {
      builder
        .addCase(fetchOrderTypes.fulfilled, (state, action) => {
          if (action.payload) {
            state.orderTypes = action.payload as OrderTypeOptionType[]
          }
        })
        .addCase(fetchAllOrder.pending, (state, { meta }) => {
          state.isLoadingOrder = !!(typeof meta.arg.showLoading === 'undefined')
        })
        .addCase(fetchAllOrder.rejected, (state) => {
          state.isLoadingOrder = false
          state.orders = []
        })
        .addCase(fetchAllOrder.fulfilled, (state, action) => {
          if (action.payload) {
            state.isLoadingOrder = false
            state.orders = action.payload.content as OrderItemType[]
            state.pagination = action.payload.pagination
          }
        })
        .addCase(fetchOrderLocations.pending, (state) => {
          state.isLoadingOrder = true
          state.isFetchingLocation = true
        })
        .addCase(fetchOrderLocations.rejected, (state) => {
          state.isLoadingOrder = false
          state.isFetchingLocation = false
        })
        .addCase(fetchOrderLocations.fulfilled, (state, action) => {
          if (action.payload) {
            state.isLoadingOrder = false
            const locations: OrderLocationOptionType[] = action.payload.locations.map(
              (location) => ({
                id: location.location_id,
                label: location.location_name,
                ...location,
              }),
            )
            state.orderLocations =
              /* If cs or admin */
              !action.payload.hasDefaultLocation || action.payload.isUserCs
                ? [{ id: null, label: 'Semua Lokasi' }, ...locations]
                : locations

            const [defaultLocation] = state.orderLocations
            state.defaultLocation = defaultLocation
            state.isFetchingLocation = false
          }
        })
    },
    [fetchOrderTypes, fetchAllOrder, fetchOrderLocations],
  ),
})

export const { setIsLoadingOrder } = createAllOrderSlice.actions
export default createAllOrderSlice.reducer
