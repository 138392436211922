import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  BannerThematicListStateType,
  ThematicLocationHubType,
  SetQueryPayloadType,
  ThematicListType,
} from 'features/BannerAndTicker/@types/bannerThematicList'

export const SLICE_NAME = 'bannerThematicList'

const initialState: BannerThematicListStateType = {
  isLoading: false,
  query: { location: { id: 0, name: '' }, name: '', pageIndex: 0, pageSize: 20 },
  locationHubOptions: [],
  thematicList: [],
}

const bannerThematicList = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setQuery: (state, { payload: { name, value } }: PayloadAction<SetQueryPayloadType>) => {
      state.query[name] = value as never
    },
    setLocationHubOptions: (state, { payload }: PayloadAction<ThematicLocationHubType[]>) => {
      state.locationHubOptions = payload
    },
    setThematicList: (state, { payload }: PayloadAction<ThematicListType[]>) => {
      state.thematicList = payload
    },
  },
})

export const { reset, setIsLoading, setLocationHubOptions, setQuery, setThematicList } =
  bannerThematicList.actions
export default bannerThematicList.reducer
