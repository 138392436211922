import AxiosInstance from 'config/apiServiceApiGateway'

import { type VendorDetailsInterface } from './addEditVendor'

export interface VendorListInterface {
  category_vendor: string
  company_codes: string[]
  company_name: string
  credit_limit: string
  id: number
  status: boolean
  terms: string
  waiting_time: number
}

export interface VendorListPaginatioInterface {
  direction: string
  number_of_elements: number
  page_index: number
  page_size: string
  sort: string
  total_elements: number
  total_pages: number
}

export interface GetVendorListResponseInterface {
  data: VendorListInterface[]
  pagination: VendorListPaginatioInterface
  error: { code: number; status: boolean; message: string }
}

export type GetVendorQueryType = {
  page_index: number
  company_name: string
  category_vendor: string
}

export interface VendorRequestResponseInterface {
  data: VendorRequestType[]
  pagination: VendorPaginationType
}

export type VendorRequestType = {
  id: number
  vendor_name: string
  company_codes: string[]
  vendor_category: string
  created_at: string
  status: 'DRAFT' | 'PENDING_APPROVAL' | 'REVIEWED' | 'APPROVED' | 'REJECTED' | 'CANCELLED'
  approval_pic: string
}

export type VendorPaginationType = {
  sort: string
  direction: string
  page_size: string
  page_index: number
  number_of_elements: number
  total_elements: number
  total_pages: number
}

export type VendorRequestParamsType = {
  order_by?: string
  order_direction?: string
  page_size?: string
  page_index?: number
  vendor_name?: string
  status?: string
  approval_pic?: string
  company_name?: string
  category_vendor?: string
  request_pic?: string
}

export interface VendorRequestDetailInterface {
  id: number
  status: string
  approval_pic: string
  revision: number
  reason: string
  reject_pic: string
  request_data: VendorDetailsInterface
  existing_data: VendorDetailsInterface
}

export const getVendors = (params: string) =>
  AxiosInstance.Get<GetVendorListResponseInterface>({
    url: `/erp/internal/v2/vendors?${params}`,
  })

export const getVendorDetails = (vendor_id: number) =>
  AxiosInstance.Get({
    url: `/erp/internal/v2/vendor/${vendor_id}`,
  })

export const getVendorRequest = (params: VendorRequestParamsType) =>
  AxiosInstance.Get<VendorRequestResponseInterface>({
    url: '/erp/internal/:version/vendor/requests',
    version: 'v2',
    params,
  })

export const getVendorRequestDetail = (id: number) =>
  AxiosInstance.Get<VendorRequestDetailInterface>({
    url: '/erp/internal/:version/vendor/requests/:id',
    version: 'v2',
    urlParams: { id },
  })
