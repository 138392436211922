import { Get } from 'config/apiServiceConfig'

export type PromoUserSegmentType = {
  id: number
  madeBy: string
  updatedAt: number
  name: string
}

export type GetPromoUserSegmentParamsType = {
  search?: string
  /**
   * index based
   */
  page?: number
  limit?: number
}

export type GetPromoUserSegmentResponseType = {
  data: {
    data: {
      list: PromoUserSegmentType[]
    }
    pagination: {
      sort: string
      direction: 'DESC' | 'ASC'
      pageSize: number
      pageIndex: number
      numberOfElements: number
      totalElements: number
      totalPages: number
    }
  }
}

export type GetPromoUserSegmentCSVDownloadResponseType = {
  data: {
    url: string
  }
}

export const getPromoUserSegment = (params: GetPromoUserSegmentParamsType) =>
  Get<GetPromoUserSegmentResponseType>({ url: '/api/promo/user-segment', params })

export const getPromoUserSegmentCSVDownload = (segmentId: number) =>
  Get<GetPromoUserSegmentCSVDownloadResponseType>({
    url: '/api/promo/user-segment/:segmentId/download',
    urlParams: { segmentId },
  })
