import {
  TableFooter as MuiTableFooter,
  TableFooterProps as MuiTableFooterProps,
} from '@mui/material'
import styled from 'styled-components'

export type TableFooterPropsType = MuiTableFooterProps

const TableFooter = styled(MuiTableFooter)<TableFooterPropsType>``
export default TableFooter
