import { memo } from 'react'
import { Box } from '@astro-ui/components'
import styled from 'styled-components'

import type { BoxPropsType } from '../Box'

const Card = styled(Box)<BoxPropsType>`
  padding: 24px;
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
`

export default memo(Card)
