import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import { getNonLoginExperimentTypes } from 'features/ABAdmin/services/ABAdminAddEdit'

export const SLICE_NAME_AB_ADMIN_ADD_EDIT = 'abAdminAddEdit'

/** Non-Login */
export const fetchNonLoginExperimentType = createAsyncThunk(
  `${SLICE_NAME_AB_ADMIN_ADD_EDIT}/fetchNonLoginExperimentType`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getNonLoginExperimentTypes()

      return data?.data?.types || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar tipe eksperimen')
      return rejectWithValue(err)
    }
  },
)
