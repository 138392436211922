import axios from 'axios'
import { Get } from 'config/apiServiceConfig'
import { getNeededAttributeFromQueryObj } from 'utils/queryObj'

const { REACT_APP_BASE_URL_API } = process.env
const baseUrl = REACT_APP_BASE_URL_API

export const getRakList = (params = {}) => axios.get(`${baseUrl}/api/racks`, { params })

export const getRackListByLocationId = (locationId, params = {}) =>
  axios.get(`${baseUrl}/api/racks/v2/${locationId}`, {
    params: getNeededAttributeFromQueryObj(params),
  })

export const getRacksExport = (locationId, queryParams) =>
  axios.get(`${baseUrl}/api/racks/${locationId}/export`, {
    params: getNeededAttributeFromQueryObj(queryParams),
  })

export const getRackCsvTemplate = () => axios.get(`${baseUrl}/api/racks/csv/template`)

export const postRackImport = (data, config) =>
  axios.post(`${baseUrl}/api/racks/import`, data, config)

export const putActiveDeactivateRackItem = (rackId) =>
  axios.put(`${baseUrl}/api/racks/active-toggle/${rackId}`)

export const getSingleRackDetail = (rackId) => axios.get(`${baseUrl}/api/racks/single/${rackId}`)

export const putSingleRackDetail = (locationId, data) =>
  axios.put(`${baseUrl}/api/racks/single/${locationId}`, data)

export const getRackStorage = (params = {}) => axios.get(`${baseUrl}/api/racks/storage`, { params })

export const getLocationAllRack = (params) => axios.get(`${baseUrl}/api/location`, { params })

export const getListLocationOfByLocationType = (locationType) =>
  axios.get(`${baseUrl}/api/locations/${locationType}`)

export const postNewRack = (locationId, formData) =>
  axios.post(`${baseUrl}/api/racks/${locationId}`, formData)

export const putRack = (locationId, formData) =>
  axios.put(`${baseUrl}/api/racks/${locationId}`, formData)

export const getLocationOfHub_Rak = () => axios.get(`${baseUrl}/api/locations/hub`)

export const getLocationOfWarehouse_Rak = () => axios.get(`${baseUrl}/api/locations/warehouse`)

export const postUpdateRakInLocation_Rak = (location_id, data) =>
  axios.post(`${baseUrl}/api/location/${location_id}/racks`, data)

export const getRakFromLocationID_Rak = (location_id, query = '') =>
  axios.get(`${baseUrl}/api/racks/${location_id}${query}`)

export const putRakNumberAndPosition = (location_id, data) =>
  axios.put(`${baseUrl}/api/racks/${location_id}`, data)

export const deleteRack = (locationId, params) =>
  axios.delete(`${baseUrl}/api/racks/${locationId}`, { params })

export const postNewRak = (location_id, data) =>
  axios.post(`${baseUrl}/api/racks/${location_id}`, data)

export const getElligibleRacks = (params) =>
  Get({ url: '/api/ims/:version/eligible-racks', version: 'v2', params })

export const confirmSingleSloc = (id, params) =>
  Get({
    url: '/api/ims/:version/inventories/detail/:id/confirm-single-sloc',
    version: 'v2',
    urlParams: { id },
    params,
  })

// api lama
export const getRakList_Rak = (query = '') => axios.get(`${baseUrl}/api/racks${query}`)

export const getLocationAll_Rak = (query = '') => axios.get(`${baseUrl}/api/location${query}`)

export const deleteRak = (location_id, rak_id) =>
  axios.delete(`${baseUrl}/api/racks/${location_id}?id=${rak_id}`)
