import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getDetailFlexiCampaign,
  getProductListFlexiCampaign,
  postFlexiCampaign,
  type PostFlexiCampaignRequestType,
  getAllSegments,
} from 'features/Promotion/services/flexiComboAddEdit'
import { ProductMappingType } from 'features/Promotion/@types/flexiComboAddEdit'
import getParams from 'utils/getParams'

export const SLICE_NAME = 'flexiComboAddEdit'

export const fetchDetailFlexiCampaign = createAsyncThunk(
  `${SLICE_NAME}/fetchDetailFlexiCampaign`,
  async ({ id }: { id: string }, { rejectWithValue, getState }) => {
    const {
      flexiComboAddEdit: { formDetail },
    } = getState() as StoreStateType
    const originPage = getParams('origin')

    try {
      const { data } = await getDetailFlexiCampaign({ id })
      const { product_mapping, ...rest } = data.data.get_flexi_campaign

      return {
        ...rest,
        product_mapping:
          originPage === 'addProductManual' ? formDetail.product_mapping : product_mapping,
      }
    } catch (error) {
      callErrorMsg(error)

      return rejectWithValue(error)
    }
  },
)

export const fetchProductListFlexiCampaign = createAsyncThunk(
  `${SLICE_NAME}/fetchListFlexiCampaign`,
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const { data } = await getProductListFlexiCampaign({ id })

      return data
    } catch (error) {
      callErrorMsg(error)

      return rejectWithValue(error)
    }
  },
)

export const fetchAllSegment = createAsyncThunk(
  `${SLICE_NAME}/fetchAllSegment`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getAllSegments()

      return data
    } catch (error) {
      callErrorMsg(error)

      return rejectWithValue(error)
    }
  },
)

const composeUnix = (date: string) => new Date(date).valueOf()

const composeProduct: (
  productMapping: ProductMappingType[],
) => PostFlexiCampaignRequestType['data']['products'] = (productMapping) => {
  const product: PostFlexiCampaignRequestType['data']['products'] = []

  productMapping.forEach((el) => {
    el.location_quota_map.forEach((ell) => {
      product.push({
        global_product_campaign_quota: el.global_product_campaign_quota,
        product_id: el.product_id,
        product_image_url: el.product_image_url,
        product_name: el.product_name,
        product_sku: el.product_sku,
        location_id: ell.location_id,
        customer_product_quota: ell.customer_product_quota,
        product_price: ell.original_product_price,
        status: ell.status,
      })
    })
  })

  return product
}

export const createUpdateFlexiCampaign = createAsyncThunk(
  `${SLICE_NAME}/createUpdateFlexiCampaign`,
  async (_, { rejectWithValue, getState }) => {
    const {
      flexiComboAddEdit: { formDetail: form },
    } = getState() as StoreStateType
    try {
      const { data } = await postFlexiCampaign({
        data: {
          id: form.id,
          campaign_name: form.campaign_name,
          campaign_type: form.campaign_type.value,
          end_time: composeUnix(`${form.end_date} ${form.end_time}`),
          end_date: composeUnix(`${form.end_date} ${form.end_time}`),
          start_date: composeUnix(`${form.start_date} ${form.start_time}`),
          start_time: composeUnix(`${form.start_date} ${form.start_time}`),
          treshold_qty: form.treshold_qty,
          final_price: form.campaign_type.value === 'final_price' ? form.final_price : 0,
          discount_percentage:
            form.campaign_type.value === 'discount_percentage' ? form.discount_percentage : 0,
          customer_campaign_quota: form.customer_campaign_quota,
          quota_campaign: +form.quota_campaign,
          campaign_segment: form.campaign_segment.value,
          products: composeProduct(form.product_mapping),
          user_segment: form.user_segment.map((el) => ({
            segment_id: el.id,
            segment_name: el.name,
          })),
          campaign_title: form.campaign_title,
        },
      })

      return data
    } catch (error) {
      callErrorMsg(error)

      return rejectWithValue(error)
    }
  },
)
