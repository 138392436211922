import { Delete, Get, Post, Put } from 'config/apiServiceConfig'

export type DiscoveryLabelType = {
  id: number
  masterLabelTypeId: number
  masterLabelTypeName: string
  masterLabelColorId: number
  masterLabelColorName: string
  masterLabelColorHexafont: string
  masterLabelColorHexabase: string
  masterLabelColorDescription: string
  filePath: string
  logoUrl: string
  name: string
}

export type GetDiscoveryLabelResponseType = {
  totalElements: number
  totalPages: number
  numberOfElements: number
  size: number
  number: number
  content: DiscoveryLabelType[]
}

export type GetDiscoveryLabelReqParamsType = Partial<{
  name: string
  pageIndex: number
  pageSize: number
  typeId: number
}>

export type GetDiscoveryLabelByIdResponseType = DiscoveryLabelType

export type PostPutDiscoveryLabelPayloadType = {
  masterLabelTypeId: number | null
  masterLabelColorId: number | null
  filePath: string
  logoUrl: string
  name: string
}

export type MasterLabelVariantType = {
  id: number
  name: string
}

export type GetMasterLabelVariantResponseType = MasterLabelVariantType[]

export type DiscoveryLabelColorType = {
  id: number
  name: string
  hexacode: string
  hexafont: string
  description: string
}

export type GetMasterLabelColorResponseType = DiscoveryLabelColorType[]

const DISCOVERY_LABEL_ID_PATH = '/api/discovery-label/:id'

export const getDiscoveryLabel = (params: GetDiscoveryLabelReqParamsType) =>
  Get<GetDiscoveryLabelResponseType>({ url: '/api/discovery-label', params })

export const getDiscoveryLabelById = (id: number) =>
  Get<GetDiscoveryLabelByIdResponseType>({ url: DISCOVERY_LABEL_ID_PATH, urlParams: { id } })

export const postDiscoveryLabel = (payload: PostPutDiscoveryLabelPayloadType) =>
  Post({ url: '/api/discovery-label', data: payload })

export const putDiscoveryLabel = (id: number, payload: PostPutDiscoveryLabelPayloadType) =>
  Put({ url: DISCOVERY_LABEL_ID_PATH, urlParams: { id }, data: payload })

export const deleteDiscoveryLabel = (id: number) =>
  Delete({ url: DISCOVERY_LABEL_ID_PATH, urlParams: { id } })

export const getMasterLabelType = () =>
  Get<GetMasterLabelVariantResponseType>({ url: '/api/master-label/type' })

export const getMasterLabelColor = () =>
  Get<GetMasterLabelColorResponseType>({ url: '/api/master-label/color' })
