export const populateChosenProductIdList = (
  vendorList,
  productId,
  listProductId
) => {
  const isAtLeastOneVendorIsChecked = vendorList.some(
    (vendorItem) => vendorItem.isChecked
  );
  if (!listProductId.includes(productId) && isAtLeastOneVendorIsChecked) {
    listProductId.push(productId);
  }
};

export const populateChosenVendorIdList = (vendorList, listVendorId) => {
  vendorList.forEach((vendorItem) => {
    if (
      vendorItem.isChecked &&
      vendorItem.vendorId !== 0 &&
      !listVendorId.includes(vendorItem.vendorId)
    ) {
      listVendorId.push(vendorItem.vendorId);
    }
  });
};

export const getCountOfPurchaseRequestAndConfirmationList = (
  listData,
  locationId,
  getItemForConfirmation
) => {
  const confirmationListData = [];
  const warehouseWithVendorList = new Map();

  const populateWarehouseWithVendorList = (locationId, vendorId) => {
    const listVendorId = warehouseWithVendorList.has(locationId)
      ? warehouseWithVendorList.get(locationId)
      : [];
    if (!listVendorId.includes(vendorId)) {
      listVendorId.push(vendorId);
      warehouseWithVendorList.set(locationId, listVendorId);
    }
  };

  listData.forEach((itemData) => {
    itemData.vendorList.forEach((itemVendor) => {
      if (itemVendor.isChecked) {
        const vendorId = itemVendor.vendorId;
        populateWarehouseWithVendorList(locationId, vendorId);
        confirmationListData.push(getItemForConfirmation(itemData, itemVendor));
      }
    });
  });

  let count = 0;
  for (const item of warehouseWithVendorList.values()) {
    count += item.length;
  }
  return { confirmationListData, count };
};

export const isHeaderChecked = (listData, indexVendor) => {
  let isChecked = false;

  for (let i = 0; i < listData.length; i++) {
    const itemData = listData[i];
    const vendorWithSpecificIndex = itemData.vendorList[indexVendor];

    const vendorSpecificIndexIsChecked =
      vendorWithSpecificIndex.isChecked &&
      vendorWithSpecificIndex.vendorId !== 0;

    if (vendorSpecificIndexIsChecked) {
      isChecked = true;
    }

    const vendorSpecificIndexIsUnchecked =
      !vendorWithSpecificIndex.isChecked &&
      vendorWithSpecificIndex.vendorId !== 0;
    if (vendorSpecificIndexIsUnchecked) {
      isChecked = false;
      break;
    }
  }
  return isChecked;
};

const generateUniqueId = () =>
  Date.now().toString(36) + Math.random().toString(36).substring(2);

export const getVendorList = (itemReorder, listVendorId, listProductId) => {
  const maxVendor = 3;
  const listVendor = itemReorder.vendorList.map((vendorItem) => {
    if (vendorItem.isChecked && !listVendorId.includes(vendorItem.vendorId)) {
      listVendorId.push(vendorItem.vendorId);
    }
    if (
      !listProductId.includes(itemReorder.product.productId) &&
      vendorItem.isChecked
    ) {
      listProductId.push(itemReorder.product.productId);
    }

    return {
      ...vendorItem,
      uniqueId: generateUniqueId()
    };
  });

  const restCountVendor = maxVendor - listVendor.length;

  let count = 0;
  while (count < restCountVendor) {
    listVendor.push({
      isChecked: false,
      uniqueId: generateUniqueId(),
      vendorId: 0,
      vendorName: "",
      price: 0,
      qty: 0
    });
    count += 1;
  }
  return listVendor;
};
