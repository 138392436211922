import { Post } from 'config/apiServiceConfig'

export type PostAuthenticateRequestType = {
  username: string
  password: string
}

export type PostAuthenticateResponseType = {
  token: string
}

export type PostValidateUserLocationRequestType = {
  destination_method_uri: string
  destination_uri: string
  location_id: string
  phone_number: string
  pin: string
}

export type PostValidateUserLocationResponseType = {
  data: {
    id: number
    is_valid: boolean
  }
}

export const postAuthenticate = (payload: PostAuthenticateRequestType) =>
  Post<PostAuthenticateResponseType>({
    url: '/api/authenticate',
    data: payload,
  })

export const postValidateUserLocation = (payload: PostValidateUserLocationRequestType) =>
  Post<PostValidateUserLocationResponseType>({
    config: {
      baseURL: process.env.REACT_APP_BASE_URL_KONG_API,
    },
    url: '/auth/internal/v1/validate/user/location',
    data: payload,
  })
