import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  QueryType,
  UpdateLoginStoryParamsType,
  LoginStoryType,
} from 'features/CategoryAndPosisi/@types/onboardingMedia'
import { getImageRules, postImage, ImageVariantIdType } from 'utils/apiList/images'
import { getFileRules, FileVariantIdType, postFile } from 'utils/apiList/files'
import {
  getLoginStories,
  putLoginStory,
  GetLoginStoriesResponseType,
} from '../../services/onboardingMedia'
import {
  SLICE_NAME,
  setIsloading,
  setLoginStories,
  setBackgroundImages,
  setMediaImageRule,
  setMediaFileRule,
  LOTTIE_OPTION_DEFAULT,
} from './slice'

export const fetchLoginStories = createAsyncThunk(
  `${SLICE_NAME}/fetchLoginStories`,
  async (
    { type = '' }: Partial<Pick<QueryType, 'type'>>,
    { rejectWithValue, getState, dispatch },
  ) => {
    const {
      onboardingMedia: { query },
    } = getState() as StoreStateType

    dispatch(setIsloading(true))

    const mapStory: (
      val: GetLoginStoriesResponseType['data']['login_stories'][0],
    ) => LoginStoryType = (val) => ({
      ...val,
      background_image_file: null,
      illustration_image_file: null,
      illustration_image_preview: val.illustration_image_url,
      background_image_preview: val.background_image_url,
      uniqueID: window.crypto.randomUUID(),
      formTitle: val.title,
    })

    try {
      const res = await getLoginStories({
        params: { page_size: query.page_size, page_index: query.page_index, type },
      })

      if (type === 'product_image') {
        dispatch(setLoginStories(res.data.data.login_stories.map((el) => ({ ...mapStory(el) }))))
      }

      if (type === 'animated_image') {
        dispatch(
          setBackgroundImages(
            res.data.data.login_stories.map((el) => ({
              ...mapStory(el),
              lottieOptions: LOTTIE_OPTION_DEFAULT,
            })),
          ),
        )
      }

      dispatch(setIsloading(false))
      return res.data
    } catch (error) {
      callErrorMsg(error)
      dispatch(setIsloading(false))
      return rejectWithValue(error)
    }
  },
)

export const updateLoginStory = createAsyncThunk(
  `${SLICE_NAME}/updateLoginStory`,
  async ({ id, loginStory }: UpdateLoginStoryParamsType, { rejectWithValue, dispatch }) => {
    dispatch(setIsloading(true))

    try {
      const res = await putLoginStory({
        id,
        payload: {
          active: loginStory.active,
          background_image_url: loginStory.background_image_preview,
          illustration_image_url: loginStory.illustration_image_preview,
          position: loginStory.position,
          subtitle: loginStory.subtitle,
          title: loginStory.formTitle,
          type: loginStory.type,
        },
      })

      dispatch(setIsloading(false))
      return res.data
    } catch (error) {
      callErrorMsg(error)
      dispatch(setIsloading(false))
      return rejectWithValue(error)
    }
  },
)

export const fetchImageRules = createAsyncThunk(
  `${SLICE_NAME}/fetchImageRules`,
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setIsloading(true))

    try {
      const { data } = await getImageRules('product-placement-new-login-stories')

      if (!data.error.status) {
        dispatch(setMediaImageRule({ key: 'loginStory', rule: data.data }))
      }

      dispatch(setIsloading(false))
      return data
    } catch (error) {
      callErrorMsg(error)
      dispatch(setIsloading(false))
      return rejectWithValue(error)
    }
  },
)

export const fetchFileRules = createAsyncThunk(
  `${SLICE_NAME}/fetchFileRules`,
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setIsloading(true))

    try {
      const { data } = await getFileRules('new-login-stories-image-lottie')

      if (!data.error.status) {
        dispatch(setMediaFileRule({ key: 'background', rule: data.data }))
      }

      dispatch(setIsloading(false))
      return data
    } catch (error) {
      callErrorMsg(error)
      dispatch(setIsloading(false))
      return rejectWithValue(error)
    }
  },
)

export const uploadImage = createAsyncThunk(
  `${SLICE_NAME}/uploadImage`,
  async (
    {
      file,
      imageVariantId,
    }: { file: File; imageVariantId: ImageVariantIdType } & Pick<LoginStoryType, 'uniqueID'>,
    { rejectWithValue, dispatch, getState },
  ) => {
    dispatch(setIsloading(true))
    const {
      auth: { userData },
    } = getState() as StoreStateType

    try {
      const { data } = await postImage(imageVariantId, {
        created_by: userData.id,
        file_input: file,
      })

      dispatch(setIsloading(false))
      return data
    } catch (error) {
      callErrorMsg(error)
      dispatch(setIsloading(false))
      return rejectWithValue(error)
    }
  },
)

export const uploadFile = createAsyncThunk(
  `${SLICE_NAME}/uploadFile`,
  async (
    { file, fileVariantId }: { file: File; fileVariantId: FileVariantIdType },
    { rejectWithValue, dispatch },
  ) => {
    dispatch(setIsloading(true))

    try {
      const { data } = await postFile({
        typeId: fileVariantId,
        data: { file_input: file },
      })

      dispatch(setIsloading(false))
      return data
    } catch (error) {
      callErrorMsg(error)
      dispatch(setIsloading(false))

      return rejectWithValue(error)
    }
  },
)
