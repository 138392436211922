import {
  SwipeableDrawer as MuiSwipeableDrawer,
  SwipeableDrawerProps as MuiSwipeableDrawerProps,
} from '@mui/material'
import styled from 'styled-components'
import {
  Box,
  Divider,
  DialogContent,
  Typography,
  IconButton,
  Icon,
  BoxPropsType,
} from '@astro-ui/components'

export type SwipeableDrawerPropsType = MuiSwipeableDrawerProps & {
  width?: BoxPropsType['width']
  title?: string
  withHeader?: boolean
  dividers?: boolean
  onClose?: () => void
}

const SwipeableDrawer = ({
  children,
  title = '',
  width = '100%',
  withHeader = true,
  dividers = true,
  onClose,
  ...rest
}: SwipeableDrawerPropsType) => (
  <SwipeableDrawerBase
    anchor="bottom"
    onClose={onClose}
    ModalProps={{
      keepMounted: true,
    }}
    {...rest}
  >
    <Box width={width}>
      <Puller />

      {withHeader && (
        <Box
          className="dialog-title"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p="16px 24px"
        >
          <Typography variant="h2">{title}</Typography>

          {onClose ? (
            <IconButton onClick={onClose} transform="rotate(45deg)">
              <Icon icon="plus" color="grey9" size={24} />
            </IconButton>
          ) : null}
        </Box>
      )}

      {dividers && <Divider />}

      <DialogContent>{children}</DialogContent>
    </Box>
  </SwipeableDrawerBase>
)

export default SwipeableDrawer

const SwipeableDrawerBase = styled(MuiSwipeableDrawer)<SwipeableDrawerPropsType>`
  & .MuiDrawer-paperAnchorBottom {
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
  }
`

const Puller = styled(Box)<BoxPropsType>`
  width: 48px;
  height: 6px;
  background-color: ${({ theme }) => theme.colors.grey4};
  border-radius: 4px;
  position: absolute;
  top: 8px;
  left: calc(50% - 15px);
`
