import createReducer from "storeContext/reducers/createReducer";

export const RACK_ADD_EDIT_RESET = "rackAddEdit/RESET";

export const RACK_ADD_EDIT_FETCH_LIST_LOCATION =
  "rackAddEdit/FETCH_LIST_LOCATION";
export const RACK_ADD_EDIT_FETCH_LIST_LOCATION_SUCCESS =
  "rackAddEdit/FETCH_LIST_LOCATION_SUCCESS";
export const RACK_ADD_EDIT_FETCH_LIST_LOCATION_FAILURE =
  "rackAddEdit/FETCH_LIST_LOCATION_FAILURE";

export const RACK_ADD_EDIT_SET_IS_BUSY_SUBMITTING_DATA =
  "rackAddEdit/SET_IS_BUSY_SUBMITTING_DATA";

export const RACK_ADD_EDIT_FETCH_RACK_DETAIL = "rackAddEdit/FETCH_RACK_DETAIL";
export const RACK_ADD_EDIT_FETCH_RACK_DETAIL_SUCCESS =
  "rackAddEdit/FETCH_RACK_DETAIL_SUCCESS";
export const RACK_ADD_EDIT_FETCH_RACK_DETAIL_FAILURE =
  "rackAddEdit/FETCH_RACK_DETAIL_FAILURE";

export const RACK_ADD_EDIT_SET_STORAGE = "rackAddEdit/SET_STORAGE";

export const initialStateRackAddEdit = {
  isLoading: true,
  isLoadingLocationList: true,
  isBusySubmittingData: false,
  locationList: [],
  locationTypeName: "",
  pagination: {
    page: 0,
    limit: 20,
    totalData: 0
  },
  formData: {
    locationId: 0,
    storageType: 0,
    storageTypeName: "",
    storageEnvironment: 0,
    storageEnvironmentName: "",
    maxVolume: 0,
    maxVolumeUom: "",
    maxQuantity: 0,
    maxQuantityUom: "",
    maxWeight: 0,
    maxWeightUom: "",
    mixAttribute: false,
    mixProduct: false,
    active: false,
    racks: [
      {
        rack_name: "",
        position: 0,
        productId: 0,
        productSku: "",
        minReplenishQty: 0,
        maxReplenishQty: 0,
        replenishQty: 0,
      },
    ],
  }
};

const rackAddEditReducer = createReducer({
  [RACK_ADD_EDIT_RESET]: () => initialStateRackAddEdit,
  [RACK_ADD_EDIT_FETCH_RACK_DETAIL]: (state) => {
    state.isLoading = true;
  },
  [RACK_ADD_EDIT_FETCH_RACK_DETAIL_SUCCESS]: (state, action) => {
    const { formData, pageData } = action.payload;
    state.isLoading = false;
    state.formData = formData;
    state.pagination = pageData;
  },
  [RACK_ADD_EDIT_FETCH_RACK_DETAIL_FAILURE]: (state) => {
    state.isLoading = false;
  },
  [RACK_ADD_EDIT_FETCH_LIST_LOCATION]: (state) => {
    state.isLoadingLocationList = true;
  },
  [RACK_ADD_EDIT_FETCH_LIST_LOCATION_SUCCESS]: (state, action) => {
    const { locationType, locationList } = action.payload;
    state.isLoadingLocationList = false;
    state.locationList = locationList;
    state.locationTypeName = locationType;
  },
  [RACK_ADD_EDIT_FETCH_LIST_LOCATION_FAILURE]: (state) => {
    state.isLoadingLocationList = false;
  },
  [RACK_ADD_EDIT_SET_IS_BUSY_SUBMITTING_DATA]: (state, action) => {
    state.isBusySubmittingData = action.payload;
  },
  [RACK_ADD_EDIT_SET_STORAGE]: (state, action) => {
    const { type, name } = action.payload;
    if (type === "type") {
      state.formData.storageTypeName = name;
    } else if (type === "environment") {
      state.formData.storageEnvironmentName = name;
    }
  }
});

export const rackAddEditFetchRackDetail = () => ({
  type: RACK_ADD_EDIT_FETCH_RACK_DETAIL
});

export const rackAddEditFetchRackDetailSuccess = (formData, pageData) => ({
  type: RACK_ADD_EDIT_FETCH_RACK_DETAIL_SUCCESS,
  payload: {
    formData,
    pageData
  }
});

export const rackAddEditFetchRackDetailFailure = () => ({
  type: RACK_ADD_EDIT_FETCH_RACK_DETAIL_FAILURE
});

export const rackAddEditSetIsBusySubmittingData = (isLoading) => ({
  type: RACK_ADD_EDIT_SET_IS_BUSY_SUBMITTING_DATA,
  payload: isLoading
});

export const rackAddEditFetchListLocation = () => ({
  type: RACK_ADD_EDIT_FETCH_LIST_LOCATION
});

export const rackAddEditFetchListLocationSuccess = (
  locationType,
  locationList
) => ({
  type: RACK_ADD_EDIT_FETCH_LIST_LOCATION_SUCCESS,
  payload: {
    locationType,
    locationList
  }
});

export const rackAddEditFetchListLocationFailure = () => ({
  type: RACK_ADD_EDIT_FETCH_LIST_LOCATION_FAILURE
});

export const rackAddEditReset = () => ({
  type: RACK_ADD_EDIT_RESET
});

export const rackAddEditSetStorage = (type, name) => ({
  type: RACK_ADD_EDIT_SET_STORAGE,
  payload: {
    type,
    name
  }
});

export default rackAddEditReducer;
