import { toastSuccess, toastFailed } from "utils/toast";
import {
  SET_IS_START_SERVICE_LEVEL,
  SET_IS_FAILED_SERVICE_LEVEL,
  SET_IS_SUCCESS_SERVICE_LEVEL,
  SET_IS_NEED_TO_RELOAD_SERVICE_LEVEL,
  SET_PAGINATION_DATA_SERVICE_LEVEL
} from "storeContext/actionsType/serviceLevel";
import {
  getSericeLEvelList_SL,
  getDetailServiceLevel_SL,
  postServiceLevel_SL,
  putServiceLevel_SL
} from "utils/apiList/serviceLevel";

export const serviceLevelStart = () => {
  return {
    type: SET_IS_START_SERVICE_LEVEL
  };
};

export const serviceLevelStop = () => {
  return {
    type: SET_IS_FAILED_SERVICE_LEVEL
  };
};

export const getAllServiceLevel = (data) => {
  return {
    type: SET_IS_SUCCESS_SERVICE_LEVEL,
    payload: data
  };
};

export const setPagination = (data) => {
  return {
    type: SET_PAGINATION_DATA_SERVICE_LEVEL,
    payload: data
  };
};

export const reloadServiceLevelList = () => {
  return {
    type: SET_IS_NEED_TO_RELOAD_SERVICE_LEVEL
  };
};

export const getListOfServiceLevel =
  (query, localState = {}) =>
  (dispatch) => {
    dispatch(serviceLevelStart());
    return getSericeLEvelList_SL(query)
      .then(({ data }) => {
        //console.log(data);

        const pagination = {
          currentItem: data.numberOfElements,
          currentPage: data.number,
          pageSize: data.size,
          totalData: data.totalElements,
          totalPage: data.totalPages
        };

        dispatch(setPagination(pagination));
        dispatch(getAllServiceLevel(data));
        //console.log(data);
      })
      .catch(() => {
        dispatch(serviceLevelStop());
        toastFailed("terjadi masalah, coba beberapa saat lagi");
      });
  };

export const getServiceDetail =
  (id, localState = {}) =>
  (dispatch) => {
    dispatch(serviceLevelStart());

    return getDetailServiceLevel_SL(id)
      .then(({ data }) => {
        //console.log(data);
        localState.handleClickEdit(data);
        dispatch(serviceLevelStop());
      })
      .catch(() => {
        toastFailed("terjadi masalah, coba beberapa saat lagi");
        dispatch(serviceLevelStop());
      });
  };

export const createServiceLevel =
  (dataToSend, localState = {}) =>
  (dispatch) => {
    dispatch(serviceLevelStart());

    return postServiceLevel_SL(dataToSend)
      .then(({ data }) => {
        //console.log(data);
        toastSuccess("Service Level baru berhasil di buat");
        dispatch(serviceLevelStop());
        localState.handleClosePopupAdd();
        dispatch(reloadServiceLevelList());
      })
      .catch(() => {
        toastFailed("terjadi masalah, coba beberapa saat lagi");
        dispatch(serviceLevelStop());
      });
  };

export const updateServiceLevel =
  (id, dataToSend, localState = {}) =>
  (dispatch) => {
    dispatch(serviceLevelStart());

    return putServiceLevel_SL(id, dataToSend)
      .then(({ data }) => {
        //console.log(data);
        toastSuccess(
          `Service Level ${data.service_level_name} berhasil di perbarui`
        );
        localState.handleClosePopupAdd();
        dispatch(serviceLevelStop());
        dispatch(reloadServiceLevelList());
      })
      .catch(() => {
        toastFailed("terjadi masalah, coba beberapa saat lagi");
        dispatch(serviceLevelStop());
      });
  };
