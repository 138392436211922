import { memo } from "react";
import styled from "styled-components";
import Barcode from "react-barcode";

import { TextBody } from "components";
function BarcodeSection({ data }) {
  return (
    <Container>
      <BarcodeSpace>
        <StyleTextBody
          style={{ fontSize: "2.2rem" }}
          color="black"
          weight="bold"
        >
          {data?.packageLabel.slice(-5)}
        </StyleTextBody>
        {/* <SVGBarcode
          className={`barcode-for-package-id-${data?.packageLabel}-${uniqDate}`}
        ></SVGBarcode> */}
        {/* <Barcode /> */}
        <BarcodeContainer>
          <Barcode
            value={data?.packageLabel}
            displayValue={false}
            height={96}
          />
        </BarcodeContainer>
        <StyleTextBody size="normal" color="black" weight="bold">
          {data?.packageLabel}
        </StyleTextBody>
      </BarcodeSpace>
    </Container>
  );
}

export default memo(BarcodeSection);
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  width: 100%;
`;

const BarcodeContainer = styled.div`
  // height: 104px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  svg {
    margin-left: auto;
    margin-right: auto;
  }
`;

const StyleTextBody = styled(TextBody)`
  margin-left: auto;
  margin-right: ${({ mr }) => (mr ? mr : "auto")};

  margin-top: auto;
`;

const BarcodeSpace = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
`;
