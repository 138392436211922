import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit'
import {
  getFreshPurchaseRequestDetail,
  GetFreshPurchaseRequestDetailInterface,
  FreshPurchaseRequestDetailQueryType,
  FreshPurchaseRequestIdType,
  GetFPRWithoutDetailInterface,
  getFreshPurchaseRequestWithoutDetail,
} from 'utils/apiList/freshPurchaseRequestDetail'
import { callErrorMsg } from 'helpers/errorMsg'
import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'
import { getCleanQueryObj, queryParamsURL } from 'utils/queryParamsURL'
import { RootStateType } from 'store'

export const SLICE_NAME = 'freshPurchaseRequestDetail'
const PAGINATION_LIMIT = 20

export type FreshPurchaseRequestPaginationType = {
  page: number
  limit: number
  numberOfElements: number
}

type FreshPurchaseRequestStateType = {
  isLoading: boolean
  details: GetFreshPurchaseRequestDetailInterface['data']
  data: GetFPRWithoutDetailInterface['data'] | null
  query: FreshPurchaseRequestDetailQueryType
  pagination: FreshPurchaseRequestPaginationType
}

const initialState: FreshPurchaseRequestStateType = {
  isLoading: true,
  details: [],
  data: null,
  query: {
    pageIndex: 1,
    pageSize: PAGINATION_LIMIT,
  },
  pagination: {
    page: 1,
    limit: PAGINATION_LIMIT,
    numberOfElements: 0,
  },
}

export const fetchFreshPurchaseRequestDetail = createAsyncThunk<
  GetFreshPurchaseRequestDetailInterface,
  FreshPurchaseRequestIdType,
  RejectValueType
>(`${SLICE_NAME}/fetchFreshPurchaseRequestDetail`, async (id, { getState, rejectWithValue }) => {
  const { query } = (getState() as RootStateType)[SLICE_NAME]
  const cleanQuery = getCleanQueryObj(query)

  try {
    const response = await getFreshPurchaseRequestDetail(id, cleanQuery)
    queryParamsURL.set(query)
    return response.data
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchFreshPurchaseRequestWithoutDetail = createAsyncThunk<
  GetFPRWithoutDetailInterface,
  FreshPurchaseRequestIdType,
  RejectValueType
>(`${SLICE_NAME}/fetchFreshPurchaseRequestWithoutDetail`, async (id, { rejectWithValue }) => {
  try {
    const response = await getFreshPurchaseRequestWithoutDetail(id)
    return response.data
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const freshPurchaseRequestDetail = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    updateQuery: (state, action: PayloadAction<Partial<FreshPurchaseRequestDetailQueryType>>) => {
      const { payload } = action
      state.query = { ...state.query, ...payload }
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<FreshPurchaseRequestStateType>) => {
    builder
      .addCase(fetchFreshPurchaseRequestDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFreshPurchaseRequestDetail.fulfilled, (state, action) => {
        const { data, pagination } = action.payload
        state.isLoading = false
        state.details = data
        state.pagination.page = pagination?.pageIndex || 1
        state.pagination.limit = pagination?.pageSize || PAGINATION_LIMIT
        state.pagination.numberOfElements = pagination?.numberOfElements || 0
      })
      .addCase(fetchFreshPurchaseRequestDetail.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchFreshPurchaseRequestWithoutDetail.fulfilled, (state, action) => {
        const { data } = action.payload
        state.data = data
      })
  },
})

export const { resetState, updateQuery } = freshPurchaseRequestDetail.actions
export default freshPurchaseRequestDetail.reducer
