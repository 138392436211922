import { memo } from 'react'
import styled from 'styled-components'
import { TextBody } from 'components'
import iconMassUpload from 'assets/images/icon-mass-upload.svg'

function OnChooseFile({ isMini, showFileType, customTextSelectFile }) {
  return (
    <DescriptionContainer>
      {!isMini && (
        <IconUploadContainer>
          <IconUpload src={iconMassUpload} />
        </IconUploadContainer>
      )}
      <TextDescription>
        {isMini && <IconUploadMini src={iconMassUpload} />}
        {customTextSelectFile ? (
          <TextBody weight="light" className="pointer" color="main">
            {customTextSelectFile}
          </TextBody>
        ) : (
          <TextBody weight="light" className="pointer" color="main">
            Upload File {!showFileType && `Excel`}
          </TextBody>
        )}
      </TextDescription>
    </DescriptionContainer>
  )
}

export default memo(OnChooseFile)

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const IconUploadContainer = styled.div`
  display: flex;
  width: 64px;
  height: 64px;
  border-radius: 100px;
  background: #ffffff;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  box-shadow: 0px 1px 12px rgba(105, 110, 118, 0.12);
`

const IconUpload = styled.img`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  height: 34px;
`

const IconUploadMini = styled.img`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  height: 21px;
`

const TextDescription = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 10px;
`
