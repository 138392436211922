import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const AdminAccessViewCreateEditPagePageRBAC = {
  pageID: '9b8954a47c80688a46acf8eaa5c08321',
  elementID: {
    ViewCreateEditPagePage: 'd34fc365fa75c668998aa664aefaf035',
    SaveEditButton: '64726819d121c3984a8bf83f5e73aa13',
    SaveCreateButton: 'f7a2ce23ab95364ab7c0e24693ab431b',
  },
} as const

export const useRBACAdminAccessViewCreateEditPage = createUseRBAC(
  AdminAccessViewCreateEditPagePageRBAC.elementID,
)

export type UseRBACAdminAccessViewCreateEditPageType = ReturnType<
  typeof useRBACAdminAccessViewCreateEditPage
>
