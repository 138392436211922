import { memo } from "react";
import styled from "styled-components";
import { TextBody, SystemIcon } from "components";
function DashboardCard() {
  return (
    <Container>
      <Wrapper>
        <TextBody weight="light" size="header">
          150
        </TextBody>
        <TextBody weight="light" color="secondaryText" className="mt-5">
          Jumlah Pesanan Belum di Proses
        </TextBody>
      </Wrapper>
      <IconWrapper>
        <SystemIcon colorIcon="main" iconName="cart" size="headerBigger" />
      </IconWrapper>
    </Container>
  );
}

export default memo(DashboardCard);

const IconWrapper = styled.div`
  min-width: 50px;
  min-height: 50px;
  background-color: ${({ theme: { colors } }) => colors.mainFaded};
  border-radius: 100%;
  display: grid;
  place-items: center;
  margin-left: 8px;
`;

const Wrapper = styled.div``;

const Container = styled.div`
  padding: 16px 20px;
  border-radius: 8px;
  background-color: ${({ theme: { colors } }) => colors.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
