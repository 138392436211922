import { GetProductCategoryResponseType } from 'features/Enterprise/ReorderList/services/reorderList'
import { ProductType } from 'features/Product/@types/Product'
import { type LocationType } from 'utils/apiList/dynamicChannel'
import { type ImageVariantIdType, type ImageRulesType } from 'utils/apiList/images'

export enum ProductHighlightLabelEnum {
  HEMAT = 1,
  FREE_TEXT = 2,
}

export type DynamicChannelBackgroundColorsType =
  | 'merah'
  | 'orange'
  | 'hijau'
  | 'biru'
  | 'kuning'
  | 'ungu'

export type DynamicChannelBackgroundColorOptionType = {
  color: string
  value: DynamicChannelBackgroundColorsType
  name: string
}

export type ImageConfigType = {
  url_large: string
  url_medium: string
  url_small: string
}

export type BannerHighlightType = {
  id: number
  category_id: number
  position: number
  hubs: LocationType[]
  start_date: number
  end_date: number
  banner_category_highlight_image: Nullable<ImageConfigType>
  is_active: boolean
  is_deleted: boolean
}

export type ProductHighlightType = {
  id: number
  product_id: number
  position: number
  hubs: LocationType[]
  start_date: number
  end_date: number
  free_text: string
  default_text: string
  is_active: boolean
}

export type ProductCategoryType = GetProductCategoryResponseType['content'][number]

export type BannerHighlightFormType = {
  id?: number
  is_active: boolean
  is_deleted: boolean
  imageFile: Nullable<File>
  imagePreview: string
  startDate: string
  startTime: string
  endDate: string
  endTime: string
  category: Nullable<ProductCategoryType>
  hubs: LocationType[]
}

export type BannerHighlightListType = BannerHighlightFormType &
  Partial<Pick<BannerHighlightType, 'banner_category_highlight_image'>> & { uniqueKey: string }

export type ProductHighlightLabelType = {
  id: ProductHighlightLabelEnum
  name: string
}

export type ProductHighlightFormType = {
  id?: number
  is_active: boolean
  is_deleted: boolean
  startDate: string
  startTime: string
  endDate: string
  endTime: string
  hubs: LocationType[]
  default_text: string
  free_text: string
  labelType: Nullable<ProductHighlightLabelType>
  product: Nullable<ProductType>
}

export type ProductHighlightListType = ProductHighlightFormType & { uniqueKey: string }

export type DynamicChannelFormType = {
  category: Nullable<ProductCategoryType>
  bgColor: Nullable<DynamicChannelBackgroundColorOptionType>
  hubs: LocationType[]
  startDate: string
  startTime: string
  endDate: string
  endTime: string
}

export type InitialStateType = {
  form: DynamicChannelFormType
  bannerHighlight: {
    isFormShow: boolean
    selectedBanner: Nullable<BannerHighlightListType>
    form: BannerHighlightFormType
    list: BannerHighlightListType[]
    categories: ProductCategoryType[]
  }
  productHighlight: {
    isFormShow: boolean
    selectedProduct: Nullable<ProductHighlightListType>
    form: ProductHighlightFormType
    list: ProductHighlightListType[]
    products: ProductType[]
  }
  imageRules: Record<ImageVariantIdType, ImageRulesType> | ObjectNullType
}

export type SetBannerHighlightFormPayloadActionType = {
  field: keyof BannerHighlightFormType
  value: BannerHighlightFormType[keyof BannerHighlightFormType]
}

export type SetProductHighlightFormPayloadActionType = {
  field: keyof ProductHighlightFormType
  value: ProductHighlightFormType[keyof ProductHighlightFormType]
}

export type SetProductHighlightProductsPayloadActionType = ProductType[]

export type SetFormPayloadActionType = {
  field: keyof DynamicChannelFormType
  value: DynamicChannelFormType[keyof DynamicChannelFormType]
}
