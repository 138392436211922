import axiosInstanceApiGateway from 'config/apiServiceApiGateway'
import { PaymentGatewayAdminStateType } from '../@types/paymentGatewayAdmin'

const { Get, Put } = axiosInstanceApiGateway

export enum PaymentChannelTypeEnum {
  creditCard = 'CC',
  virtualAccount = 'VA',
  eWallet = 'E-Wallet',
}

export type PaymentChannelType = {
  id: number
  name: string
  image_url: string
  active: boolean
  minimum_amount: string
  discount_amount: number
  payment_channel_gateway_failover: string
  allowed_payment_channel_gateway: string[]
  payment_channel_gateway: string
  code: string
  default_payment_method: boolean
  sub_name: string
  label: string
}

export type PaymentChanneiByProviderType = {
  title: string
  type: string
  payment_channel: PaymentChannelType[]
}

export type GetPaymentChannelV2ResponseType = {
  data: {
    payment_channel_v2: PaymentChanneiByProviderType[]
  }
}

export type GetPaymentChannelV2ParamsType = {
  payment_channel_order_type: 'marketplace' | 'tipping' | 'topup'
}

export type UpdatePGPayloadType = Omit<PaymentGatewayAdminStateType['form'], 'status'> & {
  status: string
}

export const getPaymentChannelV2 = (
  params: GetPaymentChannelV2ParamsType | undefined = { payment_channel_order_type: 'marketplace' },
) =>
  Get<GetPaymentChannelV2ResponseType, true>({
    url: '/payment/internal/v1/channel/list',
    params,
  })

export const putPaymentGateway = (id: number, data: Partial<UpdatePGPayloadType>) =>
  Put({
    url: '/payment/internal/v1/payment-channel/:id',
    urlParams: { id },
    data,
  })
