import {
  SET_IS_START_REFERRAL_SETTING,
  SET_IS_FAILED_REFERRAL_SETTING,
  SET_IS_SUCCESS_REFERRAL_SETTING,
  SET_IS_NEED_TO_RELOAD_REFERRAL_SETTING
} from "storeContext/actionsType/referralSetting";

export const initialStateReferralSetting = {
  isLoading: false,
  referralSettingData: [],
  //currentPage: 0,
  //paginationData: {},
  needToReload: 1
};

export default function referralSetting(
  state = initialStateReferralSetting,
  { type, payload }
) {
  switch (type) {
    case SET_IS_START_REFERRAL_SETTING:
      return {
        ...state,
        isLoading: true
      };
    case SET_IS_FAILED_REFERRAL_SETTING:
      return {
        ...state,
        isLoading: false
      };
    case SET_IS_SUCCESS_REFERRAL_SETTING:
      return {
        ...state,
        isLoading: false,
        referralSettingData: payload
      };
    case SET_IS_NEED_TO_RELOAD_REFERRAL_SETTING:
      return {
        ...state,
        needToReload: state.needToReload + 1
      };
    default:
      return { ...state };
  }
}
