import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import { fetchOrderDetailV2, fetchOrderProducts, fetchOrderActivities } from 'utils/api'
import { getDriverHistory } from 'utils/apiList/externalDriver'
import {
  SLICE_NAME,
  setOrderDetail,
  setOrderProducts,
  setOrderActivities,
  setDriverHistory,
} from './orderDetailSlice'

export const getOrderDetail = createAsyncThunk(
  `${SLICE_NAME}/getOrderDetail`,
  async (orderId: string, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const { data } = await fetchOrderDetailV2(orderId)
      dispatch(setOrderDetail(data))
      return fulfillWithValue(data)
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const getOrderProducts = createAsyncThunk(
  `${SLICE_NAME}/getOrderProducts`,
  async (orderId: number, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const { data } = await fetchOrderProducts(orderId)
      dispatch(setOrderProducts(data))
      return fulfillWithValue(data)
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const getOrderActivities = createAsyncThunk(
  `${SLICE_NAME}/getOrderActivities`,
  async (orderId: number, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const { data } = await fetchOrderActivities(orderId)
      dispatch(setOrderActivities(data))
      return fulfillWithValue(data)
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const getExternalDriverHistory = createAsyncThunk(
  `${SLICE_NAME}/getExternalDriverHistory`,
  async (orderID: number, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const {
        data: { data: result },
      } = await getDriverHistory({ orderID })
      dispatch(setDriverHistory(result))
      return fulfillWithValue(result)
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)
