import { memo } from "react";
import styled from "styled-components";
import Row from "./Row";

function Table() {
  const testData = ["", "", "", ""];
  return (
    <Container>
      <thead>
        <Tr>
          <Th ratio="2">
            <TitleWrapper>RAK</TitleWrapper>
          </Th>
          <Th ratio="4" style={{ borderLeft: "1px solid black" }}>
            <TitleWrapper>PRODUCT</TitleWrapper>
          </Th>
          <Th ratio="2">
            <TitleWrapper style={{ borderLeft: "1px solid black" }}>
              Qty
            </TitleWrapper>
          </Th>
        </Tr>
      </thead>
      <tbody>
        {testData.map((items, index) => {
          return (
            <Row
              dataCount={testData.length}
              itemIndex={index}
              key={"sada" + index}
            />
          );
        })}
      </tbody>
    </Container>
  );
}

export default memo(Table);

const Container = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Tr = styled.tr`
  display: flex;
  //   padding: 16px 32px;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
`;
const TitleWrapper = styled.div`
  padding: 16px 32px;
`;

const Th = styled.th`
  color: black;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.light};
  flex: ${({ ratio }) => ratio};
  text-align: center;
`;
