import {
  AUTH_PRICING,
  AUTH_CENTRAL_SUPERVISOR,
  AUTH_MERCHANDISING_ADMIN,
  AUTH_MERCHANDISING_WAREHOUSE,
  AUTH_CAPTAIN,
  AUTH_MERCHANDISING_HUB,
  AUTH_SUPPLY_CHAIN,
  AUTH_INVENTORY_CONTROLLER,
  AUTH_STOCK_COUNT_AUDITOR,
  AUTH_PRODUCT_MANAGER,
  AUTH_QUALITY_ENGINEERING,
  AUTH_HUB_AREA_MANAGER,
  AUTH_HUB_INV_MANAGER,
  AUTH_HUB_INV_SPV,
  AUTH_HUB_INV_STAFF,
  AUTH_HUB_AREA_MANAGER_VIEWONLY,
  AUTH_HUB_INV_MANAGER_VIEWONLY,
  AUTH_QA_MANAGER,
  AUTH_QA_SUPERVISOR,
  AUTH_QA_STAFF,
  AUTH_SUPER_AREA_MANAGER,
  AUTH_WH_MANAGER,
  AUTH_WH_OUTBOUND_SPV,
  AUTH_WH_INV_MANAGER,
  AUTH_WH_INV_SPV,
  AUTH_WH_MANAGER_VIEWONLY,
  AUTH_WH_INBOUND_SPV,
  AUTH_WH_INBOUND_STAFF,
  AUTH_WH_INV_MANAGER_VIEWONLY,
  AUTH_WH_INV_STAFF,
  AUTH_HO_MERCHANDISING,
  AUTH_HO_ADJUSTMENT,
  AUTH_HO_STOCK_COUNT_AUDITOR,
  AUTH_HO_ASTRO_KITCHEN_MANAGER,
  AUTH_HO_ASTRO_KITCHEN_STAFF,
  AUTH_HO_FINANCE,
  AUTH_HO_TAX,
  AUTH_HO_GA,
  AUTH_HO_EXPANSION,
  AUTH_HO_LP,
  AUTH_HO_CAMPAIGN,
  AUTH_HO_PARTNERSHIP,
  AUTH_HO_SUPPLY_CHAIN,
  AUTH_HO_QUALITY_ENGINEERING,
  AUTH_HO_PRODUCT_MANAGER,
  AUTH_ADJUSTMENT,
  AUTH_EXPANSION,
  AUTH_LP,
  AUTH_INBOUND_STAFF,
  AUTH_OUTBOUND_STAFF,
  AUTH_QA_FRESH_INBOUND,
  AUTH_QA_FRESH_OUTBOUND,
} from 'middleware/privateRoute'

const productWarehouse = {
  bulkUpload: [
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_PRICING,
    AUTH_MERCHANDISING_ADMIN,
    AUTH_MERCHANDISING_WAREHOUSE,
    AUTH_MERCHANDISING_HUB,
    AUTH_SUPPLY_CHAIN,
    AUTH_STOCK_COUNT_AUDITOR,

    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
    AUTH_HO_MERCHANDISING,
    AUTH_HO_ADJUSTMENT,
    AUTH_HO_SUPPLY_CHAIN,

    // Old Role
    AUTH_ADJUSTMENT,
  ],
  bulkUploadTransfer: [
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_STOCK_COUNT_AUDITOR,

    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
  ],
  addProductWarehouse: [
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_MERCHANDISING_ADMIN,
    AUTH_MERCHANDISING_WAREHOUSE,
    AUTH_CAPTAIN,
    AUTH_SUPPLY_CHAIN,

    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
    AUTH_WH_INV_SPV,
    AUTH_WH_INV_STAFF,
    AUTH_HO_SUPPLY_CHAIN,

    // Old Role
    AUTH_ADJUSTMENT,
    AUTH_INVENTORY_CONTROLLER,
    AUTH_HO_MERCHANDISING,
  ],

  toggleStatus: [
    AUTH_MERCHANDISING_ADMIN,
    AUTH_MERCHANDISING_HUB,
    AUTH_SUPPLY_CHAIN,

    AUTH_HO_MERCHANDISING,
    AUTH_QA_MANAGER,
    AUTH_QA_SUPERVISOR,
    AUTH_QA_STAFF,

    //Old Role
    AUTH_QA_FRESH_INBOUND,
    AUTH_QA_FRESH_OUTBOUND,
  ],
  detailStockTrail: [
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_MERCHANDISING_ADMIN,
    AUTH_MERCHANDISING_WAREHOUSE,
    AUTH_CAPTAIN,
    AUTH_SUPPLY_CHAIN,

    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
  ],
  editStock: [
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_MERCHANDISING_ADMIN,
    AUTH_MERCHANDISING_WAREHOUSE,
    AUTH_CAPTAIN,
    AUTH_SUPPLY_CHAIN,
    AUTH_INVENTORY_CONTROLLER,
    AUTH_STOCK_COUNT_AUDITOR,

    AUTH_HUB_AREA_MANAGER,
    AUTH_HUB_AREA_MANAGER_VIEWONLY,
    AUTH_HUB_INV_MANAGER,
    AUTH_HUB_INV_MANAGER_VIEWONLY,
    AUTH_HUB_INV_SPV,
    AUTH_HUB_INV_STAFF,
    AUTH_QA_MANAGER,
    AUTH_QA_SUPERVISOR,
    AUTH_QA_STAFF,
    AUTH_SUPER_AREA_MANAGER,
    AUTH_WH_MANAGER,
    AUTH_WH_MANAGER_VIEWONLY,
    AUTH_WH_INBOUND_SPV,
    AUTH_WH_INBOUND_STAFF,
    AUTH_WH_OUTBOUND_SPV,
    AUTH_WH_INV_MANAGER,
    AUTH_WH_INV_MANAGER_VIEWONLY,
    AUTH_WH_INV_SPV,
    AUTH_WH_INV_STAFF,
    AUTH_HO_MERCHANDISING,
    AUTH_HO_FINANCE,
    AUTH_HO_TAX,
    AUTH_HO_ADJUSTMENT,
    AUTH_HO_STOCK_COUNT_AUDITOR,
    AUTH_HO_GA,
    AUTH_HO_EXPANSION,
    AUTH_HO_LP,
    AUTH_HO_CAMPAIGN,
    AUTH_HO_PARTNERSHIP,
    AUTH_HO_ASTRO_KITCHEN_MANAGER,
    AUTH_HO_ASTRO_KITCHEN_STAFF,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
    AUTH_HO_SUPPLY_CHAIN,

    // Old Role
    AUTH_EXPANSION,
    AUTH_LP,
    AUTH_INBOUND_STAFF,
    AUTH_OUTBOUND_STAFF,
    AUTH_QA_FRESH_INBOUND,
    AUTH_QA_FRESH_OUTBOUND,
    AUTH_ADJUSTMENT,
  ],
  editPrice: [
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_PRICING,

    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
  ],
  editRack: [
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_MERCHANDISING_ADMIN,
    AUTH_MERCHANDISING_WAREHOUSE,
    AUTH_CAPTAIN,
    AUTH_SUPPLY_CHAIN,
  ],
}

export default productWarehouse
