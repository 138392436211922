import { RolesKeyType } from 'constant/roles'
import { lazy } from 'react'

export type ModifierNamedRoutesType =
  | 'ModifierRouter'
  | 'ModifierConnectionRouter'
  | 'ModifierAddRouter'
  | 'ModifierEditRouter'
export type ModifierPathListsType =
  | '/dashboard/modifier'
  | '/dashboard/modifier-connection'
  | '/dashboard/modifier/add'
  | '/dashboard/modifier/edit/:modifierId'

type RouterInstanceType = RouterFeatureInterface<ModifierNamedRoutesType, ModifierPathListsType>

const ModifierPage = lazy(() => import('../pages/Modifier'))
const ModifierConnectionPage = lazy(() => import('../pages/ModifierConnection'))
const ModifierAdd = lazy(() => import('../pages/ModifierAdd'))

export const roleViewEditCreate: RolesKeyType[] = [
  'AUTH_HO_MERCHANDISING',
  'AUTH_MERCHANDISING_HUB',
  'AUTH_MERCHANDISING_WAREHOUSE',
  'AUTH_HO_PRODUCT_MANAGER',
  'AUTH_HO_QUALITY_ENGINEERING',
]

export const roleViewOnly: RolesKeyType[] = [
  'AUTH_SUPPLY_CHAIN',
  'AUTH_HO_ASTRO_KITCHEN_MANAGER',
  'AUTH_HO_ASTRO_KITCHEN_SOURCING',
  'AUTH_HO_ASTRO_KITCHEN_SUPPLY_CHAIN',
  'AUTH_HO_ASTRO_KITCHEN_STAFF',
]

const ModifierRouter: RouterInstanceType = {
  name: 'ModifierRouter',
  Component: ModifierPage,
  path: '/dashboard/modifier',
  routeType: 'PRIVATE',

  allowedRoles: [...roleViewOnly, ...roleViewEditCreate],
  isDashboard: true,
}

const ModifierConnectionRouter: RouterInstanceType = {
  name: 'ModifierConnectionRouter',
  Component: ModifierConnectionPage,
  path: '/dashboard/modifier-connection',
  routeType: 'PRIVATE',

  allowedRoles: [...roleViewOnly, ...roleViewEditCreate],
  isDashboard: true,
}

const ModifierAddRouter: RouterInstanceType = {
  name: 'ModifierAddRouter',
  Component: ModifierAdd,
  path: '/dashboard/modifier/add',
  routeType: 'PRIVATE',

  allowedRoles: [...roleViewEditCreate],
  isDashboard: true,
}

const ModifierEditRouter: RouterInstanceType = {
  name: 'ModifierEditRouter',
  Component: ModifierAdd,
  path: '/dashboard/modifier/edit/:modifierId',
  routeType: 'PRIVATE',

  allowedRoles: [...roleViewEditCreate],
  isDashboard: true,
}

const ModifierRouterList: RouterInstanceType[] = [
  ModifierRouter,
  ModifierConnectionRouter,
  ModifierAddRouter,
  ModifierEditRouter,
]

export default ModifierRouterList
