import { memo, useContext, useMemo } from 'react'
import styled from 'styled-components'
import storeContext from 'storeContext'
import SectionQr from './SectionQr'
import SectionBarcode from './SectionBarcode'

const isMultipleOfFour = (value: number) => value % 4 === 0

const TemplatePrintPackageIdMobile = () => {
  const {
    state: {
      printContent: { data },
    },
  } = useContext(storeContext.PrintAreaContext) as unknown as {
    dispatch: StoreDispatchType
    state: {
      printContent: {
        data: {
          id: number
          package_label: string
          packageId: number
          packageLabel: string
        }[]
      }
    }
  }

  const fieldNameToUse = useMemo(() => {
    if (data.length > 0) {
      const objectFields = Object.keys(data[0])
      const fieldName = objectFields.includes('packageLabel') ? 'packageLabel' : 'package_label'
      return fieldName as 'packageLabel' | 'package_label'
    }
    return 'packageLabel'
  }, [data])

  return (
    <Container>
      <PaperToSacrifice>LEWATI HALAMAN INI</PaperToSacrifice>
      {data.map((el, index) => (
        <StyledWrapperItem
          isMultipleOfFour={isMultipleOfFour(index + 1)}
          key={`${el?.packageId || el?.id}`}
        >
          <SectionQr packageLabel={el[fieldNameToUse]} />
          <SectionBarcode packageLabel={el[fieldNameToUse]} />
        </StyledWrapperItem>
      ))}
    </Container>
  )
}

export default memo(TemplatePrintPackageIdMobile)

const Container = styled.table`
  display: flex;
  flex-direction: column;
  background: white;
  width: 100%;
  padding: 0;
  margin: 0 0 0 0;
`

const PaperToSacrifice = styled.div`
  display: flex;
  flex-direction: column;
  width: 79mm;
  height: 50mm;
  justify-content: center;
  margin-bottom: 8mm;

  @media print {
    page-break-inside: avoid;
  }
`

const StyledWrapperItem = styled.div<{ isMultipleOfFour: boolean }>`
  display: flex;
  flex-direction: column;
  width: 79mm;
  height: ${(props) => (props.isMultipleOfFour ? '72mm' : '61mm')};
  justify-content: center;
  margin-bottom: ${(props) => (props.isMultipleOfFour ? '0' : '4mm')};

  @media print {
    page-break-inside: avoid;
  }
`
