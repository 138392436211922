import { memo, useMemo } from "react";
import styled from "styled-components";
import { NoData, RowPo } from "components";
import { sumArrayofObject, formatMoneyComa } from "helpers";
import { shallowEqual, useSelector } from "react-redux";

function TablePo({ data, className }) {
  const { isSidebarOpen } = useSelector(
    (state) => state.app.layout,
    shallowEqual
  );
  const totalQuantity = useMemo(() => {
    return formatMoneyComa(sumArrayofObject(data, "quantity"), "");
  }, [data]);
  const totalGrossWeight = useMemo(() => {
    return formatMoneyComa(sumArrayofObject(data, "totalGrossWeight"), "");
  }, [data]);
  const totalChargeableWeight = useMemo(() => {
    return formatMoneyComa(sumArrayofObject(data, "chargeableWeight"), "");
  }, [data]);
  return (
    <>
      <WrapperTable isExpand={isSidebarOpen}>
        <Container cellspacing="0" className={className}>
          <thead>
            <Tr>
              <Th isSticky left="0px">
                Sr No.
              </Th>
              <Th isSticky left="58px">
                Code
              </Th>
              <Th isSticky left="127px">
                Product Description
              </Th>
              <Th>Quantity</Th>
              <Th>UOM</Th>
              <Th>Dimensions</Th>
              <Th>UOM</Th>
              <Th>Volume</Th>
              <Th>UOM</Th>
              <Th>Net Weight</Th>
              {/* // */}
              <Th>Volume Weight</Th>
              <Th>Volume</Th>
              <Th>Gross Weight</Th>
              <Th>Chargeable Weight</Th>
              <Th>Aksi</Th>
            </Tr>
          </thead>
          <tbody>
            {data.map((el, index) => (
              <RowPo key={`${index}--product-po`} data={el} />
            ))}
            <Tr bg="yellowFaded">
              <Td isSticky left="0px">
                Total
              </Td>
              <Td />
              <Td />
              <Td>{totalQuantity}</Td>
              <Td />
              <Td />
              <Td />
              <Td />
              <Td />
              <Td />
              <Td />
              <Td />
              <Td>{totalGrossWeight}</Td>
              <Td>{totalChargeableWeight}</Td>
              <Td />
            </Tr>
          </tbody>
        </Container>

        {data.length === 0 && <NoData />}
      </WrapperTable>
    </>
  );
}

TablePo.defaultProps = {
  data: []
};

export default memo(TablePo);

const WrapperTable = styled.div`
  overflow: scroll;
  max-width: ${({ isExpand }) =>
    isExpand ? "calc(100vw - 746px)" : "calc(100vw - 508px)"};
  transition: all ease 0.3s;
`;

const Container = styled.table`
  /* width: 100%; */
  border-collapse: collapse;
  height: fit-content;
  border: 1px solid #d6dfeb;
  border-radius: 8px;
  border-spacing: 0;
  border-collapse: collapse;
`;
const Tr = styled.tr`
  background-color: ${({ theme: { colors }, bg }) => colors[bg || "tableHead"]};
`;
const Th = styled.th`
  ::after {
    content: "";
    background-color: ${({ theme: { colors } }) => colors.tableHead};
    height: 20px;
    position: absolute;
    width: 2px;
    right: -1px;
  }
  color: ${({ theme: { colors } }) => colors.softText};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.toast};
  text-align: start;
  padding: 16px 12px;
  white-space: nowrap;
  background-color: ${({ theme: { colors } }) => colors.tableHead};
  ${({ isSticky, left }) =>
    isSticky && {
      position: "sticky",
      left: left
    }}
`;

const Td = styled.td`
  /* border-right: 1px solid red; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.toast};
  background-color: ${({ theme: { colors }, bg }) => colors.yellowFaded};
  text-align: start;
  padding: 16px 12px;
  ${({ isSticky, left }) =>
    isSticky && {
      position: "sticky",
      left: left
    }}
`;
