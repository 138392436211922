export const SET_IS_START_WAREHOUSE_DETAIL = "SET_IS_START_WAREHOUSE_DETAIL";
export const SET_IS_FAILED_WAREHOUSE_DETAIL = "SET_IS_FAILED_WAREHOUSE_DETAIL";
export const SET_IS_SUCCESS_WAREHOUSE_DETAIL =
  "SET_IS_SUCCESS_WAREHOUSE_DETAIL";

export const SET_IS_START_WAREHOUSE_DETAIL_HISTORY = "SET_IS_START_WAREHOUSE_DETAIL_HISTORY";
export const SET_IS_FAILED_WAREHOUSE_DETAIL_HISTORY = "SET_IS_FAILED_WAREHOUSE_DETAIL_HISTORY";
export const SET_IS_SUCCESS_WAREHOUSE_DETAIL_HISTORY =
    "SET_IS_SUCCESS_WAREHOUSE_DETAIL_HISTORY";

export const SET_IS_NEED_TO_RELOAD_WAREHOUSE_DETAIL =
  "SET_IS_NEED_TO_RELOAD_WAREHOUSE_DETAIL";

export const SET_PAGINATION_DATA_WAREHOUSE_DETAIL =
  "SET_PAGINATION_DATA_WAREHOUSE_DETAIL";

export const SET_PAGINATION_DATA_WAREHOUSE_DETAIL_HISTORY =
    "SET_PAGINATION_DATA_WAREHOUSE_DETAIL_HISTORY";



export const SET_SELECT_PRODUCT_DATA_WAREHOUSE_DETAIL =
  "SET_SELECT_PRODUCT_DATA_WAREHOUSE_DETAIL";
