import { useAppSelector, shallowEqual } from 'store'

type ExtractRouterType<T> = T extends Record<infer U, string> ? U : never

export const createUseRBAC = <T extends Record<string, string>>(params: T) => {
  type AllowedElementIDRBACType = {
    readonly [k in ExtractRouterType<typeof params> as `isAllowedFor${k}`]: boolean
  }

  return () => {
    const { RBAC } = useAppSelector(({ auth: { RBAC } }) => ({ RBAC }), shallowEqual)
    if (!RBAC.element_ids) return {} as AllowedElementIDRBACType
    const mappedRBAC = Object.entries(params).reduce((prev, [key, value]) => {
      const isAllowed = RBAC.element_ids.find((each) => each === value)
      return {
        ...prev,
        [`isAllowedFor${key}`]: !!isAllowed,
      }
    }, {})
    return mappedRBAC as AllowedElementIDRBACType
  }
}
