import styled from 'styled-components'

import { useAppSelector, shallowEqual } from 'store'
import { Box } from '@astro-ui/components'
import { withPopupConfirmation } from 'components/molecules/PopupConfirmation'
import { useLocation } from 'react-router-dom'
import { poolingMap } from 'config/routes'

import NavBar from './NavBar'
import Sidebar from './Sidebar'
import PrintArea from './PrintArea'

import './print_area.css'

export type DashboardPropsType = {
  children: JSX.Element
}

const Dashboard = ({ children }: DashboardPropsType) => {
  const isSidebarOpen = useAppSelector(({ app }) => app.layout.isSidebarOpen, shallowEqual)
  const location = useLocation()
  return (
    <Container>
      <NavBar className="print-none" />
      <Content className="print-none">
        <Sidebar />
        <Wrapper
          id="dashboard-content"
          p={
            location.pathname === poolingMap
              ? '0px'
              : {
                  mobile: '24px 16px',
                  desktop: '24px 32px',
                }
          }
          ml={{
            desktop: isSidebarOpen ? '240px' : '0px',
          }}
        >
          {children}
        </Wrapper>
      </Content>
      <PrintArea />
    </Container>
  )
}

export default withPopupConfirmation(Dashboard)

const Wrapper = styled(Box)`
  flex: 1;
  overflow: auto;
  height: calc((var(--vh, 1vh) * 100) - 72px);

  &.stop-scrolling {
    overflow: hidden;
  }
`
const Content = styled.div`
  display: flex;
  margin-top: 72px;
`
const Container = styled.section`
  overflow: hidden;
`
