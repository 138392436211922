import React from "react";
import styled from "styled-components";

const ButtonFilter = ({ children, onClick, ...rest }) => {
  return (
    <ButtonMain {...rest} onClick={onClick}>
      {children}
    </ButtonMain>
  );
};

ButtonFilter.defaultProps = {
  size: "normal",
  active: false
};

export default React.memo(ButtonFilter);

const ButtonMain = styled.button`
  .counter {
    margin-left: 4px;
  }
  cursor: pointer;
  background: none;
  border: none;
  box-shadow: none;
  padding: 8px 16px;
  height: fit-content;
  width: fit-content;
  min-width: fit-content;
  background-color: ${({ theme: { colors }, active }) =>
    active ? colors.mainFaded : colors.white};
  color: ${({ theme: { colors }, active }) =>
    active ? colors.main : colors.grey};
  border: 1px solid
    ${({ theme: { colors }, active }) => (active ? colors.main : colors.grey)};
  font-size: 0.9rem;
  border-radius: 20px;
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.normal};
  width: ${({ size }) => (size === "full" ? "100%" : "fit-content")};
  transition: all ease 0.3s;
  :hover {
    color: ${({ theme: { colors } }) => colors.main};
    border-color: ${({ theme: { colors } }) => colors.main};
    background-color: ${({ theme: { colors } }) => colors.mainFaded};
  }
  :focus {
    outline: none;
  }
  :disabled {
    background-color: ${({ theme: { colors } }) => colors.greySecondry};
    border: 1px solid ${({ theme: { colors } }) => colors.greySecondry};
    color: ${({ theme: { colors } }) => colors.noteText};
  }
`;
