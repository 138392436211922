import { memo } from "react";
import styled from "styled-components";
import { TextBody, Button } from "components";
import iconClose from "assets/images/icon_close.svg";
//import { Link } from "react-router-dom";

const OnUploadFailed = ({ onResetFile, resultUpload }) => (
  <DescriptionContainer>
    <IconUploadContainer>
      <IconUpload src={iconClose} />
    </IconUploadContainer>

    <TextDescription>
      <TextBody weight="bold" className="pointer" color="red">
        Oops, terjadi kesalahan
      </TextBody>
      <FileDescription>
        <TextBody weight="light" className="pointer" color="black">
          Silahkan cek kembali file yang sudah di upload
        </TextBody>
      </FileDescription>
      <NavigationContainer>
        <ButtonWhite
          onClick={onResetFile}
          style={{
            marginLeft: "auto",
            marginRight: "auto"
          }}
        >
          Upload Lagi
        </ButtonWhite>
        <ButtonBlue
          href={resultUpload.urlDownload}
          download
          style={{ marginRight: "auto" }}
        >
          Cek Kesalahan
        </ButtonBlue>
      </NavigationContainer>
    </TextDescription>
  </DescriptionContainer>
);

export default memo(OnUploadFailed);

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const IconUploadContainer = styled.div`
  display: flex;
  width: 64px;
  height: 64px;
  border-radius: 100px;
  background: #ffffff;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  box-shadow: 0px 1px 12px rgba(105, 110, 118, 0.12);
`;

const IconUpload = styled.img`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  height: 34px;
`;

const TextDescription = styled.div`
  text-align: center;
`;
const FileDescription = styled.div`
  margin-top: 8px;
`;
const NavigationContainer = styled.div`
  display: flex;
  margin-top: 24px;
`;

const ButtonWhite = styled(Button)`
  background: white;
  color: #246ee5;
  border: 1px solid #246ee5;
  min-width: 128px;
`;

const ButtonBlue = styled.a`
  background: #246ee5;
  color: white;
  border: 1px solid #246ee5;
  min-width: 128px;
  align-items: center;
  display: flex;
  height: fit-content;
  width: ${({ size }) => (size === "full" ? "100%" : "fit-content")};
  cursor: pointer;
  justify-content: center;
  border: none;
  box-shadow: none;
  padding: ${({ size }) => (size === "small" ? "5px 12px" : "10px 16px")};
  font-size: ${({ size }) => (size === "small" ? "0.7rem" : "0.9rem")};
  border-radius: 6px;
  font-weight: ${({ theme: { fontWeights }, size }) =>
    size === "small" ? fontWeights.light : fontWeights.normal};
  :focus {
    outline: none;
  }
  :disabled {
    background-color: ${({ theme: { colors } }) => colors.body};
    border: 1px solid ${({ theme: { colors } }) => colors.body};
    color: ${({ theme: { colors } }) => colors.grey};
    cursor: not-allowed;
  }
`;
