import {
  SET_IS_LOADING,
  SET_BENEFITS,
  SET_TARGETS,
  SET_MECHANISMS,
  SET_USERLIST,
  SET_IS_LOADING_GET_USER,
  SET_DATA_PAGINATION_USER,
  SET_SELECTED_CUSTOMERS,
  SET_DATA_REFFERAL
} from "storeContext/actionsType/refferal";

export const initialStateRefferal = {
  isLoading: false,
  benefits: [],
  targets: [],
  mechanisms: [],
  userList: [],
  isLoadingGetUser: false,
  dataPaginationUser: {},
  listSelectedCustomers: [],
  dataRefferal: {}
};

export default function refferal(
  state = initialStateRefferal,
  { type, payload }
) {
  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_BENEFITS:
      return {
        ...state,
        benefits: payload
      };
    case SET_TARGETS:
      return {
        ...state,
        targets: payload
      };
    case SET_MECHANISMS:
      return {
        ...state,
        mechanisms: payload
      };
    case SET_USERLIST:
      return {
        ...state,
        userList: payload
      };
    case SET_IS_LOADING_GET_USER:
      return {
        ...state,
        isLoadingGetUser: payload
      };
    case SET_DATA_PAGINATION_USER:
      return {
        ...state,
        dataPaginationUser: payload
      };
    case SET_SELECTED_CUSTOMERS:
      return {
        ...state,
        listSelectedCustomers: payload
      };
    case SET_DATA_REFFERAL:
      return {
        ...state,
        dataRefferal: payload
      };
    default:
      return state;
  }
}
