import React from 'react'
import styled, { css } from 'styled-components'

const TextBody = ({ children, ...rest }) => {
  return <Container {...rest}>{children}</Container>
}

TextBody.defaultProps = {
  size: 'normal',
  weight: 'normal',
  color: 'textSoft',
}

export default React.memo(TextBody)

const Container = styled.p`
  color: ${({ theme: { colors }, color }) => colors[color]};
  font-size: ${({ theme: { fontSizes }, size }) => fontSizes[size]};
  font-weight: ${({ theme: { fontWeights }, weight }) => fontWeights[weight]};
  cursor: ${({ cursor }) => cursor || undefined};
  ${({ theme: { fontFamily }, fontFamily: type }) =>
    type &&
    css`
      font-family: ${fontFamily[type]};
    `}
`
