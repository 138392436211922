import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import { GetPromoUserSegmentParamsType } from 'features/CampaignAndDiscount/@types/campaignSegmentList'
import {
  getPromoUserSegment,
  getPromoUserSegmentCSVDownload,
} from 'features/CampaignAndDiscount/services/campaignSegmentList'

export const SLICE_NAME = 'campaignSegment'

export const fetchPromoUserSegment = createAsyncThunk(
  `${SLICE_NAME}/fetchPromoUserSegment`,
  async (params: GetPromoUserSegmentParamsType = {}, { rejectWithValue, getState }) => {
    try {
      const rootState = getState() as StoreStateType

      const { query, pagination } = rootState.campaignSegment

      const newParams = {
        page: pagination.pageIndex,
        limit: pagination.pageSize,
        ...query,
        ...params,
      }

      const { data } = await getPromoUserSegment(newParams)

      return data?.data
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan data segmen user')
      return rejectWithValue(err)
    }
  },
)

export const fetchromoUserSegmentCSVById = createAsyncThunk(
  `${SLICE_NAME}/fetchromoUserSegmentCSVById`,
  async (segmentId: number, { rejectWithValue }) => {
    try {
      const { data } = await getPromoUserSegmentCSVDownload(segmentId)

      return data.data.url
    } catch (err) {
      callErrorMsg(err, 'Gagal mengunduh list segment user')
      return rejectWithValue(err)
    }
  },
)
