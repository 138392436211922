import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { CatalogueTypeOptionsType } from 'pages/Catalogue/CatalogueConstant'
import { SLICE_NAME, removeCatalogue, fetchHubList } from './catalogueThunk'

export type SelectedCatalogueStateType = {
  category_id: number
  category_name: string
}

export type HubListStateType = {
  locationId: number
  name: string
  location_type: string
}

export type QueryType = {
  selectedHub: HubListStateType
  catalogueType: CatalogueTypeOptionsType
}

export interface CatalogueStateType {
  isLoading: boolean
  isShowModalDelete: boolean
  selectedCatalogue: SelectedCatalogueStateType
  hubList: HubListStateType[]
  query: QueryType
}

export const initialState: CatalogueStateType = {
  isLoading: false,
  isShowModalDelete: false,
  selectedCatalogue: { category_id: 0, category_name: '' },
  hubList: [],
  query: {
    selectedHub: { locationId: 0, name: '', location_type: '' },
    catalogueType: { name: '', value: 0 },
  },
}

export type SetQueryPayloadType = {
  value: QueryType['selectedHub'] | QueryType['catalogueType']
  name: keyof QueryType
}

const catalogue = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setIsShowModalDelete: (state, action: PayloadAction<{ value: boolean }>) => {
      const { value } = action.payload
      state.isShowModalDelete = value
    },
    setSelectedCatalogue: (state, action: PayloadAction<{ value: SelectedCatalogueStateType }>) => {
      const { value } = action.payload
      state.selectedCatalogue = value
    },
    setQuery: (state, action: PayloadAction<SetQueryPayloadType>) => {
      const { name, value } = action.payload
      state.query[name] = value as never
    },
  },
  extraReducers(builder) {
    builder
      .addCase(removeCatalogue.pending, (state) => {
        state.isLoading = true
      })
      .addCase(removeCatalogue.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(removeCatalogue.fulfilled, (state) => {
        state.isLoading = false
        state.isShowModalDelete = false
        state.selectedCatalogue = initialState.selectedCatalogue
      })
      .addCase(fetchHubList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchHubList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchHubList.fulfilled, (state, action) => {
        const data = action.payload
        state.isLoading = false
        state.hubList = data.data.map((el) => ({
          name: el.location_name,
          locationId: el.location_id,
          location_type: el.location_type,
        }))
      })
  },
})

export const { reset, setIsShowModalDelete, setSelectedCatalogue, setQuery } = catalogue.actions
export default catalogue.reducer
