import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAreaListAPI } from 'features/Rack/services/area'
import { callErrorMsg } from 'helpers/errorMsg'

export const SLICE_NAME = 'MASTER_AREA/list'

export const fetchGetAreaList = createAsyncThunk(
  `${SLICE_NAME}/fetchGetAreaList`,
  async (params: Parameters<typeof getAreaListAPI>[0], { rejectWithValue }) => {
    try {
      const { data } = await getAreaListAPI(params)

      return data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)
