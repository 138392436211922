import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getListBulkUpload,
  getListOfServices,
  getListOfFeatureByService,
  postCreateBulkUploadTransaction,
  PostResponseType,
} from 'utils/apiList/bulkUploadV2'
import { callErrorMsg } from 'helpers/errorMsg'
import { toastSuccess } from 'utils/toast'

export const SLICE_NAME = 'bulkUploadV2'

export const fetchServiceList = createAsyncThunk(
  `${SLICE_NAME}/fetchServiceList`,
  async (_, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await getListOfServices()
      return data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchFeatureList = createAsyncThunk(
  `${SLICE_NAME}/fetchFeatureList`,
  async (
    param: { serviceId: number; featureIdToTriggerFetchList?: number },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const { serviceId, featureIdToTriggerFetchList } = param
      const {
        data: { data },
      } = await getListOfFeatureByService(serviceId)

      const featureIdIsInList = data.some((item) => item.id === featureIdToTriggerFetchList)
      if (featureIdIsInList && featureIdToTriggerFetchList) {
        dispatch(fetchBulkUploadTransactionList({ featureId: featureIdToTriggerFetchList }))
      }

      return {
        data,
        featureIdToTriggerFetchList,
      }
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

type FetchBulkUploadParamType = {
  featureId: number
}
export const fetchBulkUploadTransactionList = createAsyncThunk(
  `${SLICE_NAME}/fetchBulkUploadTransactionList`,
  async (params: FetchBulkUploadParamType | undefined, { getState, rejectWithValue }) => {
    try {
      const {
        bulkUploadV2: { queryParams },
      } = getState() as StoreStateType

      const queryData = {
        ...queryParams,
        ...(params && { service_feature_id: params.featureId }),
      }
      const {
        data: { data },
      } = await getListBulkUpload(queryData)

      return data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const createBulkUploadTransaction = createAsyncThunk(
  `${SLICE_NAME}/createBulkUploadTransaction`,
  async (formData: FormData, { rejectWithValue }) => {
    try {
      const response = await postCreateBulkUploadTransaction(formData)
      const responseData = response.data as unknown as PostResponseType
      toastSuccess(responseData.message)
      return responseData.message
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
