import {
  PinProductType,
  PinProductPaginationType,
  PinProductQueryType,
  PinProductTypeType,
  FilterDropdownOptionType,
} from 'features/CategoryAndPosisi/@types/PinProduct'
import apiServices from 'config/apiServiceApiGateway'
import { Get as GetAPILegacy } from 'config/apiServiceConfig'

const { Get, Put, Post } = apiServices

type GetPinProductResponseType = {
  data: PinProductType[]
  pagination: PinProductPaginationType
}

export const getPinProducts = (params: Partial<PinProductQueryType>) =>
  Get<GetPinProductResponseType, true>({
    url: '/search/internal/:version/pin-product',
    version: 'v1',
    params,
  })

type GetPinProductTypeResponseType = {
  data: { types: PinProductTypeType[] }
  pagination: PinProductPaginationType
}

export const getPinProductType = () =>
  Get<GetPinProductTypeResponseType, true>({
    url: '/search/internal/:version/pin-product/type',
    version: 'v1',
  })

type GetPinProductDetailRequestType = {
  id: string
}

export type GetPinProductDetailResponseType = {
  data: {
    master_pin_product: {
      created_at: '2024-09-05 12:37:28'
      id: 78
      type_id: 1
      ref_id: 0
      ref_value: 'UPDATED buah segar'
      end_date: '2024-09-10 12:00:00'
      type_name: 'keyword'
      created_by: 'admin'
      updated_at: '2024-09-05 14:47:13'
      start_date: '2024-09-10 10:00:00'
      notes: 'UPDATED buah segar campain'
      is_active: false
      updated_by: 'admin'
      status: 'Archived'
    }

    items: {
      updated_by: string
      product_id: number
      master_id: number
      sequences: number
      image: string
      name: string
      updated_at: string
      created_by: string
      id: number
      created_at: string
      product_sku: string
      sku: string
      active: boolean
    }[]
  }
}

export type PutUpdatePinProductRequestType = {
  payload: {
    user_id: number
    id: number
    type_id: number
    ref_value: string
    ref_id: number
    status: string
    start_date: string
    end_date: string
    notes: string
    is_active: boolean
    items: {
      product_id: number
      sequences: number
    }[]
  }
  id: string
}

type GetFilterAttributesResponseType = {
  filters: {
    filter_id: number
    filter_label: string
    filter_input_type: string
    param_name: string
    components: FilterDropdownOptionType[]
  }[]
}

export const getPinProductDetail = ({ id }: GetPinProductDetailRequestType) =>
  Get<GetPinProductDetailResponseType, true>({
    url: `/search/internal/:version/pin-product/${id}`,
    version: 'v1',
  })

export const getPinProductFilterAttribute = () =>
  Get<GetFilterAttributesResponseType>({
    url: '/search/internal/:version/pin-product/filter-attribute',
    version: 'v1',
  })

export const putUpdatePinProduct = ({ id, payload }: PutUpdatePinProductRequestType) =>
  Put({
    url: `/search/internal/:version/pin-product/${id}`,
    version: 'v1',
    data: payload,
  })

export type PostPinProductRequestType = {
  payload: {
    type_id: number
    ref_value: string
    ref_id: number
    start_date: string
    end_date: string
    notes?: string
    items: {
      product_id: number
      sequences: number
    }[]
  }
  user_id?: number
}

export const postPinProduct = ({ payload }: PostPinProductRequestType) =>
  Post({
    url: `/search/internal/:version/pin-product`,
    version: 'v1',
    data: payload,
  })

export type PutPinProductStatusType = {
  payload: {
    id: number
    state: Nullable<boolean>
    action: number
    notes: string
  }
  id: string
}
export const putPinProductStatus = ({ id, payload }: PutPinProductStatusType) =>
  Put({
    url: `/search/internal/:version/pin-product/${id}/status`,
    version: 'v1',
    data: payload,
  })

export type GetProductListV2RequestType = Partial<{
  sort: string
  direction: string
  pageSize: number
  name: string
  categoryIDs: number
  isActive: boolean
}>

export type GetProductListV2ResponseType = {
  data: {
    baseUom: string
    productID: number
    productSkuNo: string
    productName: string
    categories: {
      categoryId: number
      categoryName: string
      categoryType: string
    }[]
    active: boolean
    images: {
      imageURLLarge: string
      imageURLMedium: string
      imageURLSmall: string
    }[]
  }[]
  error: {
    status: boolean
    message: string
    code: number
  }
  message: string | null
  pagination: {
    sort: string
    direction: string
    pageSize: number
    pageIndex: number
    numberOfElements: number
  }
}

export const getProductV2API = (params: GetProductListV2RequestType | undefined) =>
  GetAPILegacy<GetProductListV2ResponseType>({
    url: '/api/ims/v2/products',
    params,
  })

export type GetPINProductSimilarResponseType = {
  data: {
    items: {
      name: string
      updated_by: string
      product_id: number
      image: string
      active: boolean
      created_by: string
      created_at: string
      updated_at: string
      id: number
      sku: string
      master_id: number
      sequences: number
    }[]
  }
}

export type GetPINProductSimilarRequestType = {
  params: { type_id: number; ref_value: string; start_date: string }
}

export const getPINProductSimilar = ({ params }: GetPINProductSimilarRequestType) =>
  Get<GetPINProductSimilarResponseType, true>({
    url: '/search/internal/v1/pin-product/similar',
    params,
  })
