/* eslint-disable no-loop-func */
/* eslint-disable sonarjs/no-unused-collection */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useMemo } from 'react'
import { collection, onSnapshot, documentId, query, where } from 'firebase/firestore'

import { db } from 'config/firebase'
import { toastFailed } from 'utils/toast'

interface UseAppPusherInterface {
  location_roles: {
    location_id: number
    location_name: string
    location_type: string
    role_names: string[]
  }[]
  id: number
  authorities: {
    name: string
  }[]
  roles: {
    name: string
  }[]
}

interface MapLocationRolesInterface {
  [key: string]: {
    [key: string]: true
  }
}

function useAppPusher(auth: UseAppPusherInterface) {
  const [isFreezeApp, setIsFreezeApp] = useState<boolean>(false)

  const dataDocumentIds = auth.location_roles?.length
    ? auth.location_roles.map((el) => `${el.location_id}`)
    : []

  const mapLocationRoles = useMemo(() => {
    const data: MapLocationRolesInterface = {}

    auth.location_roles?.forEach((el) => {
      data[`${el.location_id}`] = el.role_names.reduce(
        (obj, item) => Object.assign(obj, { [item]: true }),
        {},
      )
    })

    return data
  }, [auth.location_roles])

  useEffect(() => {
    if (dataDocumentIds.length) {
      try {
        while (dataDocumentIds.length) {
          const batch = dataDocumentIds.splice(0, 10)
          onSnapshot(
            query(collection(db, 'stock_opname_locations'), where(documentId(), 'in', batch)),
            (snap) => {
              const locationFreezeStoActive: boolean[] = []
              const locationFreezeStoNonActive: boolean[] = []

              const ids: string[] = []

              let allowedRoles: string[] = []
              snap.docs.forEach((d) => {
                ids.push(d.id)
                const item = d.data()

                const allowedUsers = item?.allowed_users?.length ? item.allowed_users : []
                allowedRoles = item?.allowed_roles?.length ? item.allowed_roles : []

                const myRoleExistsInAllowedRoles = allowedRoles.some(
                  (role) => mapLocationRoles?.[d.id]?.[role],
                )

                if (!item.is_sto_active) {
                  locationFreezeStoNonActive.push(false)
                } else if (
                  item.is_sto_active &&
                  !myRoleExistsInAllowedRoles &&
                  !allowedUsers.includes(auth.id)
                ) {
                  locationFreezeStoActive.push(true)
                } else {
                  locationFreezeStoActive.push(false)
                }
              })

              if (snap.docs?.length) {
                const resultOfStoActive = locationFreezeStoActive.length
                  ? locationFreezeStoActive.every((el) => el)
                  : false

                const userRoles = auth.roles.map((item) => item.name)
                const isFreeze =
                  [...locationFreezeStoNonActive, resultOfStoActive].some(
                    (isFreezeActive) => isFreezeActive,
                  ) && !allowedRoles.some((role) => userRoles.includes(role))
                setIsFreezeApp(isFreeze)
              }
            },
          )
        }
      } catch (error) {
        toastFailed(`${error}`)
      }
    }
  }, [])

  return { isFreezeApp }
}

export default useAppPusher
