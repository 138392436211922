import { ActionNameType, ActionValueType } from 'utils/apiList/freshPurchaseOrder'

export const getValueForActionName = (actionName: ActionNameType): ActionValueType => {
  if (actionName === 'RETURN TO DRAFT') return 'created'
  if (actionName === 'CONFIRM') return 'confirm'
  if (actionName === 'CANCEL') return 'cancel'
  if (actionName === 'INBOUND') return 'inbound'
  if (actionName === 'PRICING') return 'pricing'
  return 'print_grn'
}

export const getMessageByActionName = (actionName: ActionNameType) => {
  if (actionName === 'CONFIRM') {
    return 'Apakah Anda yakin ingin konfirmasi fresh purchase order ini ?'
  }
  if (actionName === 'CANCEL')
    return 'Apakah Anda yakin ingin membatalkan fresh purchase order ini ?'
  if (actionName === 'RETURN TO DRAFT')
    return 'Apakah Anda yakin ingin mengembalikan status fresh purchase order ke created?'
  return ''
}
