import { Patch, Get, Post, Delete, Put } from 'config/apiServiceConfig'
import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

export type CampaignVariantType = {
  text: string
  key: 'EXCLUSIVE' | 'DISCOUNT'
}

export interface GetCampaignLoyaltyListResponseType {
  data: {
    campaigns: {
      id: number
      name: string
      start_date: string
      end_date: string
      start_time: string
      end_time: string
      is_active: boolean
      campaign_type: CampaignVariantType
    }[]
  }
}

export type GetCampaignLoyaltyListRequestType = {
  params: {
    page_size?: number
    page_index?: number
    status?: string
    from_date?: string
    to_date?: string
    name?: string
    campaign_type?: CampaignVariantType['key']
    sort?: string
    direction?: string
  }
}

export interface PostCampaignLoyaltyResponseType {
  data: {
    id: number
  }
}

export type PostCampaignLoyaltyRequestType = {
  payload: {
    name: string
    start_at: number
    end_at: number
    campaign_type: CampaignVariantType['key']
  }
}

export type DeleteCampaignLoyaltyRequestType = {
  id: string
}

export type DeleteCampaignLoyaltyResponseType = {
  id: string
}

export type GetCampaignLoyaltyByIdRequestType = {
  id: string
}

export type GetCampaignLoyaltyByIdResponseType = {
  data: {
    id: number
    name: string
    startDate: string
    endDate: string
    startTime: string
    endTime: string
    isActive: boolean
    campaignType: CampaignVariantType
  }
}

export type PutCampaignLoyaltyByIdRequestType = {
  id: string
  payload: {
    name: string
    start_at: number
    end_at: number
  }
}

export type PutCampaignLoyaltyByIdResponseType = {
  data: {
    id: number
    name: string
    startDate: string
    endDate: string
    startTime: string
    endTime: string
    isActive: boolean
  }
}

export type GetCampaignLoyaltyProductCandidateListRequestType = {
  params: {
    pageSize?: number
    pageIndex?: number
    status?: string
    param?: string
  }
}

export type GetCampaignLoyaltyProductCandidateListResponseType = {
  data: {
    campaignProductCandidates: {
      id: number
      locationId: number
      locationName: string
      productId: number
      productName: string
      coin: number
      quota: number
      isSpecial: boolean
      isActive: boolean
    }[]
  }
  error: {
    status: boolean
    message: string
    code: number
  }
  pagination: {
    sort: string
    direction: string
    pageSize: number
    pageIndex: number
    totalElement: number
    numberOfElements: number
    totalPages: number
  }
}

export type PutCampaignLoyaltyStatusByIdRequestType = {
  id: string
  payload: boolean
}

export type PutCampaignLoyaltyStatusByIdResponseType = {
  id: string
  payload: boolean
}

export type GetCampaignLoyaltyProductListRequestType = {
  params: {
    pageSize?: number
    pageIndex?: number
    campaignId?: number
    param?: string
    locationId?: number
  }
}

export type GetCampaignLoyaltyProductListResponseType = {
  data: {
    campaignProducts: {
      id: number
      locationId: number
      locationName: string
      productId: number
      productName: string
      coin: number
      productQuota: number
      discountCoin: number
      discountPercentage: number
      quota: number
      userQuota: number
      isSpecial: boolean
    }[]
  }
  error: {
    status: boolean
    message: string
    code: number
  }
  pagination: {
    sort: string
    direction: string
    pageSize: number
    pageIndex: number
    totalElement: number
    numberOfElements: number
    totalPages: number
  }
}

export type PostCampaignLoyaltyCreateProductRequestType = {
  payload: {
    campaignId: number
    redeemRewards: {
      id: number
      coin: number
    }[]
  }
}

export type DeleteCampaignLoyaltyProductRequestType = {
  id: number
}

export type DeleteCampaignLoyaltyProductResponseType = {
  data: boolean
  error: {
    status: boolean
    message: string
    code: number
  }
  pagination: null
}

export type PutCampaignLoyaltyProductRequestType = {
  payload: {
    campaignId: number
    campaignDiscounts: {
      id: number
      originalCoin: number
      finalCoin: number
      discountPercentage: number
      quota: number
      userQuota: number
    }[]
  }
}

export type GetLocationHubsResponseType = {
  location_id: number
  location_name: string
  location_type: string
}

export type GetLoyaltyCampaignVariantResponseType = {
  data: {
    types: CampaignVariantType[]
  }
}

export const getCampaignLoyaltyList = ({ params }: GetCampaignLoyaltyListRequestType) =>
  axiosInstanceApiGateway.Get<GetCampaignLoyaltyListResponseType, true>({
    url: `/loyalty/internal/v1/campaign`,
    params,
  })

export const postCampaignLoyaltyList = ({ payload }: PostCampaignLoyaltyRequestType) =>
  axiosInstanceApiGateway.Post<PostCampaignLoyaltyResponseType, true>({
    url: `/loyalty/internal/v1/campaign`,
    data: payload,
    convertResponse: true,
  })

export const deleteCampaignLoyalty = ({ id }: DeleteCampaignLoyaltyRequestType) =>
  Delete<DeleteCampaignLoyaltyResponseType>({ url: `/api/loyalty/campaign/${id}` })

export const getCampaignLoyaltyById = ({ id }: GetCampaignLoyaltyByIdRequestType) =>
  axiosInstanceApiGateway.Get<GetCampaignLoyaltyByIdResponseType, true>({
    url: `/loyalty/internal/v1/campaign/${id}`,
    convertResponse: true,
  })

export const putCampaignLoyalty = ({ id, payload }: PutCampaignLoyaltyByIdRequestType) =>
  axiosInstanceApiGateway.Put<PutCampaignLoyaltyByIdResponseType, true>({
    url: `/loyalty/internal/v1/campaign/${id}`,
    data: payload,
    convertResponse: true,
  })

export const getCampaignLoyaltyProductCandidateList = ({
  params,
}: GetCampaignLoyaltyProductCandidateListRequestType) =>
  Get<GetCampaignLoyaltyProductCandidateListResponseType>({
    url: `/api/loyalty/campaign/product/candidate`,
    params,
  })

export const putCampaignLoyaltyStatus = ({
  id,
  payload,
}: PutCampaignLoyaltyStatusByIdRequestType) =>
  Patch<PutCampaignLoyaltyStatusByIdResponseType>({
    url: `/api/loyalty/campaign/${id}`,
    data: payload,
  })

export const getCampaignLoyaltyProductList = ({
  params,
}: GetCampaignLoyaltyProductListRequestType) =>
  Get<GetCampaignLoyaltyProductListResponseType>({
    url: `/api/loyalty/campaign/product`,
    params,
  })

export const postCampaignLoyaltyCreateProduct = ({
  payload,
}: PostCampaignLoyaltyCreateProductRequestType) =>
  Post({ url: '/api/loyalty/campaign/product', data: payload })

export const deleteCampaignLoyaltyProduct = ({ id }: DeleteCampaignLoyaltyProductRequestType) =>
  Delete<DeleteCampaignLoyaltyProductResponseType>({ url: `/api/loyalty/campaign/product/${id}` })

export const putCampaignLoyaltyProduct = ({ payload }: PutCampaignLoyaltyProductRequestType) =>
  Put({ url: `/api/loyalty/campaign/product`, data: payload })

export const getLocationHubs = () =>
  Get<GetLocationHubsResponseType[]>({ url: `/api/locations/hub` })

export const getLoyaltyCampaignType = () =>
  axiosInstanceApiGateway.Get<GetLoyaltyCampaignVariantResponseType, true>({
    url: `/loyalty/internal/v1/campaign/type`,
  })
