import { memo } from "react";
import styled from "styled-components";
import { TextBody, SystemIcon } from "components";
import { indoTime } from "helpers";
function Progress({ data }) {
  return (
    <Container>
      <TextBody color="text">Detail Waktu Buyer</TextBody>
      <StepsWrapper>
        {data?.map((el, index) => {
          return (
            <Step key={el.activity_id}>
              <StepContent>
                <Indicator isActive={el.activity_status}>
                  {el.activity_status ? (
                    <ActiveDot />
                  ) : (
                    <SystemIcon iconName="check" fontSize="medium" />
                  )}
                </Indicator>
                <StepInfo>
                  <TextBody size="smaller">{el.activity_name}</TextBody>
                  <TextBody
                    size="smaller"
                    color="grey"
                    className="mt-5"
                    weight="light"
                  >
                    {indoTime(
                      el.activity_created_at,
                      "dddd, DD MMM YYYY, HH:mm"
                    )}
                  </TextBody>
                </StepInfo>
              </StepContent>
              {index !== data.length - 1 && (
                <StepLine isActive={el.activity_status} />
              )}
            </Step>
          );
        })}
      </StepsWrapper>
    </Container>
  );
}

Progress.defaultProps = {
  data: [],
};

export default memo(Progress);

const Container = styled.div`
  width: 100%;
  height: 20px;
`;

const StepsWrapper = styled.div`
  margin-top: 16px;
`;

const Step = styled.div``;

const StepContent = styled.div`
  display: flex;
  align-items: center;
`;

const StepLine = styled.div`
  height: 28px;
  position: relative;
  ::before {
    content: " ";
    height: 45px;
    position: absolute;
    left: 11px;
    width: 1px;
    border-left: ${({ isActive }) =>
      isActive ? "3px solid #2455b0" : "3px dashed #CAD3E1"};
    top: ${({ isActive }) => (isActive ? "-12px" : "-3px")};
  }
`;

const Indicator = styled.div`
  position: relative;
  z-index: 2;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: ${({ theme: { colors }, isActive }) =>
    isActive ? colors.blueFaded : colors.white};
  display: grid;
  place-items: center;
  margin-right: 16px;
  border: ${({ isActive }) => (!isActive ? "1px solid #BFC9D9" : "none")};
`;

const StepInfo = styled.div``;

const ActiveDot = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: ${({ theme: { colors } }) => colors.blue};
`;
