/* eslint-disable @typescript-eslint/naming-convention */
import { AxiosRequestConfig, Method } from 'axios'
import UrlPattern from 'url-pattern'

import {
  axiosInstance,
  axiosInstanceImageService,
  axiosInstanceAPIGateway,
} from './apiServicesInstanceAndInterceptors'

interface ServiceConfigInstanceRequestInterface
  extends Pick<AxiosRequestConfig, 'data' | 'params'> {
  url: Exclude<AxiosRequestConfig['url'], undefined>
  urlParams?: Record<string, unknown>
  version?: 'v1' | 'v2'
  contentType?: 'application/json' | 'multipart/form-data'
  config?: Omit<AxiosRequestConfig, 'params' | 'url' | 'method'>
  serviceTarget?: 'main' | 'image-service' | 'gateway'
}

const urlAndParamsGenerator = (
  url: ServiceConfigInstanceRequestInterface['url'],
  urlParams: ServiceConfigInstanceRequestInterface['urlParams'] = {},
  version: ServiceConfigInstanceRequestInterface['version'],
) => new UrlPattern(url).stringify({ ...urlParams, version })

const getSelectedAxiosInstance = (
  serviceTarget: ServiceConfigInstanceRequestInterface['serviceTarget'],
) => {
  switch (serviceTarget) {
    case 'image-service':
      return axiosInstanceImageService
    case 'gateway':
      return axiosInstanceAPIGateway
    default:
      return axiosInstance
  }
}

const services = <ArgsType extends ServiceConfigInstanceRequestInterface, Response>(
  args: ArgsType,
  type: Extract<Method, 'PUT' | 'PATCH' | 'GET' | 'POST' | 'DELETE'>,
) => {
  const {
    url,
    version,
    data,
    params,
    urlParams,
    contentType = 'application/json',
    config,
    serviceTarget = 'main',
  } = args

  const headers: AxiosRequestConfig['headers'] = {
    'Content-Type': contentType,
  }

  const configCall = {
    params,
    headers: {
      ...config?.headers,
      ...headers,
    },
    ...config,
  }

  const axios = getSelectedAxiosInstance(serviceTarget)

  const serviceMethod = {
    GET: () => axios.get<Response>(urlAndParamsGenerator(url, urlParams, version), configCall),
    POST: () =>
      axios.post<Response>(urlAndParamsGenerator(url, urlParams, version), data, configCall),
    PUT: () =>
      axios.put<Response>(urlAndParamsGenerator(url, urlParams, version), data, configCall),
    PATCH: () =>
      axios.patch<Response>(urlAndParamsGenerator(url, urlParams, version), data, configCall),
    DELETE: () =>
      axios.delete<Response>(urlAndParamsGenerator(url, urlParams, version), configCall),
  }

  return serviceMethod[type]()
}

export const Get = <Response>(
  args: Omit<ServiceConfigInstanceRequestInterface, 'data' | 'contentType'>,
) => services<typeof args, Response>(args, 'GET')

export const Post = <Response>(args: ServiceConfigInstanceRequestInterface) =>
  services<typeof args, Response>(args, 'POST')

export const Patch = <Response>(args: ServiceConfigInstanceRequestInterface) =>
  services<typeof args, Response>(args, 'PATCH')

export const Put = <Response>(args: ServiceConfigInstanceRequestInterface) =>
  services<typeof args, Response>(args, 'PUT')

export const Delete = <Response>(
  args: Omit<ServiceConfigInstanceRequestInterface, 'data' | 'contentType'>,
) => services<typeof args, Response>(args, 'DELETE')
