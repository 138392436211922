import styled from "styled-components";
import { Loader } from "components";

const LoadingPage = ({ isLoading, ...rest }) => {
  if (!isLoading) return <Span />;
  return (
    <Container {...rest}>
      <Loader />
    </Container>
  );
};

export default LoadingPage;

LoadingPage.defaultProps = {
  bg: false,
  isLoading: false
};

const Span = styled.span`
  position: fixed;
  z-index: -1;
`;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme: { colors } }) => colors.blackFaded};
`;
