import {
  getAllReferralVIP,
  deleteReferralVIP,
  postReferralVIP,
  getCustomerList,
} from 'utils/apiList/referralVIP'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'

export const SLICE_NAME = 'ReferralVIP'

export const fetchListReferralVIP = createAsyncThunk(
  `${SLICE_NAME}/fReferralStatus`,
  async (_, { rejectWithValue, getState }) => {
    const {
      referralVIPList: { query },
    } = getState() as StoreStateType
    try {
      const res = await getAllReferralVIP({
        params: { userID: query.userID, pageIndex: query.pageIndex, pageSize: query.pageSize },
      })

      return res.data?.data?.referral_group_list
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const addReferralVIP = createAsyncThunk(
  `${SLICE_NAME}/addReferralVIP`,
  async (_, { rejectWithValue, getState }) => {
    const {
      referralVIPList: { selectedReferralVIP },
    } = getState() as StoreStateType
    try {
      const res = await postReferralVIP({
        payload: [{ group_type: 'VIP', user_id: selectedReferralVIP?.user_id }],
      })

      return res.data?.data || {}
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const removeReferralVIP = createAsyncThunk(
  `${SLICE_NAME}/removeReferralVIP`,
  async (_, { rejectWithValue, getState }) => {
    const {
      referralVIPList: { selectedReferralVIP },
    } = getState() as StoreStateType
    try {
      const res = await deleteReferralVIP({
        userID: selectedReferralVIP.user_id?.toString(),
      })

      return res.data?.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchCustomerList = createAsyncThunk(
  `${SLICE_NAME}/fetchCustomerList`,
  async ({ name }: { name: string }, { rejectWithValue, getState }) => {
    const {
      referralVIPList: {
        customerList: { query },
      },
    } = getState() as StoreStateType

    try {
      const res = await getCustomerList({
        params: { pageIndex: query.pageIndex, pageSize: query.pageSize, name },
      })

      return res.data?.content
    } catch (error) {
      callErrorMsg(error, 'gagal mendapatkan customer list')
      return rejectWithValue(error)
    }
  },
)
