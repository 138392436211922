import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Maintenance } from '@astronautsid/wpe-utils'

import { AstroUIProvider, injectGlobalStyles } from '@astro-ui/provider'
import { requestInterceptors, responseInterceptors } from 'middleware/interceptors'
import storeContext from 'storeContext'
import globalStyles from 'theme/globalStyles'
import ErrorBoundary from 'components/ErrorBoundary'
import ValidateJWT from 'middleware/ValidateJWT'

import App from './App'
import reportWebVitals from './reportWebVitals'
import * as serviceWorker from './serviceWorker'
import { store, history } from './store'

const { StoreContextProvider } = storeContext

requestInterceptors()
responseInterceptors()

const { GlobalStyles } = injectGlobalStyles(globalStyles)

const root = createRoot(document.getElementById('root'))

root.render(
  <Maintenance
    isMaintenance={process.env.REACT_APP_MAINTENANCE === 'true'}
    redirectURL={process.env.REACT_APP_SYSTEM_MAINTENANCE_URL}
  >
    <AstroUIProvider disableInjection>
      <GlobalStyles />
      <ErrorBoundary>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <StoreContextProvider>
              <ValidateJWT>
                <App />
              </ValidateJWT>
            </StoreContextProvider>
          </ConnectedRouter>
        </Provider>
      </ErrorBoundary>
    </AstroUIProvider>
  </Maintenance>,
)

serviceWorker.register()
reportWebVitals()
