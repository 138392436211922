import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit'
import {
  GetReplenishmentTaskItemResponseType,
  GetReplenishmentTaskDetailResponseType,
} from 'features/SupplyOrder/services/replenishment'
import { withLoadingReducer } from 'utils/reducerHandler'
import { SLICE_NAME, fetchGetReplenishmentTaskDetail, fetchGetReplenishmentTaskItem } from './thunk'

export type ReplenishmentTaskDetailSliceType = {
  isLoading: boolean
  header: GetReplenishmentTaskDetailResponseType['data']['replenishment_task'] | null
  detailList: GetReplenishmentTaskItemResponseType
  activityList: GetReplenishmentTaskDetailResponseType['data']['replenishment_task_activity']
}

const initialState: ReplenishmentTaskDetailSliceType = {
  isLoading: false,
  header: null,
  detailList: {
    data: [],
    pagination: {
      page_size: 20,
      page_index: 1,
      number_of_elements: 0,
    },
  },
  activityList: [],
}

const replenishmentTaskDetailSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetReplenishmentTaskDetail: () => initialState,
  },
  extraReducers: withLoadingReducer(
    (builder: ActionReducerMapBuilder<ReplenishmentTaskDetailSliceType>) => {
      builder
        .addCase(fetchGetReplenishmentTaskDetail.fulfilled, (state, action) => {
          state.header = action.payload.replenishment_task
          state.activityList = action.payload.replenishment_task_activity
        })
        .addCase(fetchGetReplenishmentTaskItem.fulfilled, (state, action) => {
          state.detailList = action.payload
        })
    },
    [],
  ),
})

export const { resetReplenishmentTaskDetail } = replenishmentTaskDetailSlice.actions
export default replenishmentTaskDetailSlice.reducer
