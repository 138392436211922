import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/internal'
import {
  GetCountScheduledType,
  GetInboundScheduleType,
  InboundScheduleTimeSlotType,
} from 'features/Enterprise/CapacityHandler/services/inboundSchedule'
import {
  LoadingDockType,
  LocationType,
} from 'features/Enterprise/CapacityHandler/services/loadingDock'
import {
  GetTimeSlotInterface,
  LoadingDockTimeSlotType,
} from 'features/Enterprise/CapacityHandler/services/timeSlot'
import {
  SLICE_NAME,
  fetchInboundSchedule,
  fetchLoadingDockTimeSlot,
  fetchCountScheduled,
  removeInboundSchedule,
} from './inboundScheduleThunk'

export type SetFilterPayloadType<K extends keyof InboundScheduleStateType['filters']> = {
  fieldName: K
  value: InboundScheduleStateType['filters'][K]
}

export type FilterDateType = [Date, Date]

export interface InboundScheduleStateType {
  isLoading: boolean
  filters: {
    location: LocationType | null
    loadingDock: LoadingDockType | null
    date: FilterDateType
  }
  data: GetInboundScheduleType['data']
  loadingDockTimeSlotData: GetTimeSlotInterface['data'] | ObjectNullType
  countScheduled: GetCountScheduledType['data'] | ObjectNullType
  selectedInboundScheduleToDelete:
    | (InboundScheduleTimeSlotType & { loadingDock: LoadingDockTimeSlotType; text: string })
    | null
  locationBlockedOnSunday: number[]
}

const initialState: InboundScheduleStateType = {
  isLoading: false,
  filters: {
    location: null,
    loadingDock: null,
    date: [new Date(), new Date()],
  },
  data: [],
  loadingDockTimeSlotData: {},
  countScheduled: {},
  selectedInboundScheduleToDelete: null,
  locationBlockedOnSunday: [40, 160],
}

const inboundScheduleSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    setFilter: <K extends keyof InboundScheduleStateType['filters']>(
      state: WritableDraft<InboundScheduleStateType>,
      { payload }: PayloadAction<SetFilterPayloadType<K>>,
    ) => {
      state.filters[payload.fieldName] = payload.value
    },
    setSelectedInboundScheduleToDelete: (
      state,
      { payload }: PayloadAction<InboundScheduleStateType['selectedInboundScheduleToDelete']>,
    ) => {
      state.selectedInboundScheduleToDelete = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInboundSchedule.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchInboundSchedule.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.data = payload.data
      })
      .addCase(fetchInboundSchedule.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchLoadingDockTimeSlot.fulfilled, (state, { payload }) => {
        state.loadingDockTimeSlotData = payload.data
      })
      .addCase(fetchCountScheduled.fulfilled, (state, { payload }) => {
        state.countScheduled = payload.data
      })
      .addCase(removeInboundSchedule.fulfilled, (state) => {
        state.selectedInboundScheduleToDelete = null
      })
  },
})

export const { resetState, setFilter, setSelectedInboundScheduleToDelete } =
  inboundScheduleSlice.actions
export default inboundScheduleSlice.reducer
