import React, { useState } from 'react'
import './style.css'
import styled from 'styled-components'
import { TextBody, Button } from 'components'
import icon_close_grey from 'assets/images/icon_close_grey.svg'

function Tooltip({ children, delay, direction, content, tooltipsType, white }) {
  return (
    <>
      {tooltipsType === 'hover' && (
        <TooltipHover direction={direction} content={content} delay={delay} white>
          {children}
        </TooltipHover>
      )}
      {tooltipsType === 'click' && (
        <TooltipClick direction={direction} content={content} delay={delay}>
          {children}
        </TooltipClick>
      )}
    </>
  )
}

function TooltipHover({ children, delay, direction, content, white }) {
  let timeout
  const [active, setActive] = useState(false)

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true)
    }, delay || 400)
  }

  const hideTip = () => {
    clearInterval(timeout)
    setActive(false)
  }

  return (
    <div
      className="Tooltip-Wrapper"
      // When to show the tooltip
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {/* Wrapping */}
      {children}
      {active && (
        <div
          className={
            white ? `Tooltip-Tip-white ${direction || 'top'}` : `Tooltip-Tip ${direction || 'top'}`
          }
        >
          {/* Content */}
          {content}
        </div>
      )}
    </div>
  )
}

function TooltipClick({ children, delay, direction, content }) {
  const [active, setActive] = useState(false)

  const hanleClickShowTip = (e) => {
    setActive(true)
  }

  const handleClickCloseTip = () => {
    setActive(false)
  }
  return (
    <div className="Tooltip-Wrapper">
      {/* Wrapping */}
      <div onClick={hanleClickShowTip}>{children}</div>

      {active && (
        <TooltipTipClick className={`Tooltip-Tip click ${direction || 'top'}`}>
          {/* Content */}
          <TextBody style={{ paddingTop: '2px' }} color="#FFFFFF" weight="light">
            {content}
          </TextBody>
          <Button onClick={handleClickCloseTip} variant="wrapper" style={{ marginLeft: '12px' }}>
            <img src={icon_close_grey} />
          </Button>
        </TooltipTipClick>
      )}
    </div>
  )
}

Tooltip.defaultProps = {
  tooltipsType: 'hover',
}

export default Tooltip

const TooltipTipClick = styled.div`
  display: flex;
`
