import { css } from 'styled-components'

const globalStyles = css`
  * {
    padding: 0;
    margin: 0;
    font-family: 'Noto Sans JP', sans-serif;
    box-sizing: border-box;
    transition: all ease 0.3s;
  }

  body > iframe {
    pointer-events: none;
  }

  body > div.MuiTooltip-popperArrow {
    transition: all 0s;
  }

  .Toastify__toast-container {
    padding: 12px;
    width: fit-content;
    min-width: 300px;
    top: 1em;
    left: 50%;
    transform: translateX(-50%);
  }

  .Toastify__toast {
    box-shadow: none;
    padding: 0;
    min-height: 0;
  }

  .Toastify__toast-body {
    padding: 0;
  }

  .Toastify__toast--default {
    background: #f3f6fa;
    border-radius: 6px;
  }

  .Toastify__toast--success {
    background: none;
    background-color: rgba(0, 186, 108, 0.08);
    border-radius: 6px;
  }

  .ReactModal__Overlay {
    z-index: 1400 !important;
  }

  .pointer {
    cursor: pointer;
  }

  .text-over {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .flex-row {
    display: flex;
    align-items: center;
  }

  .p-15 {
    padding: 15px;
  }

  .w-100 {
    width: 100%;
  }

  .w-48 {
    width: 48%;
  }

  .w-20 {
    width: 20%;
  }

  .w-15 {
    width: 15%;
  }

  .w-10 {
    width: 10%;
  }

  .mt-2 {
    margin-top: 2px;
  }

  .mt-3 {
    margin-top: 3px;
  }

  .mt-4 {
    margin-top: 4px;
  }

  .mt-5 {
    margin-top: 5px;
  }

  .mt-8 {
    margin-top: 8px;
  }

  .mt-10 {
    margin-top: 10px;
  }

  .mt-12 {
    margin-top: 12px;
  }

  .mt-16 {
    margin-top: 16px;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .mt-24 {
    margin-top: 24px;
  }

  .mt-32 {
    margin-top: 32px;
  }

  .mt-40 {
    margin-top: 40px;
  }

  .mt-auto {
    margin-top: auto;
  }

  .mb-15 {
    margin-bottom: 15px;
  }

  .ml-3 {
    margin-left: 3px;
  }

  .ml-5 {
    margin-left: 5px;
  }

  .ml-10 {
    margin-left: 10px;
  }

  .ml-16 {
    margin-left: 16px;
  }

  .ml-auto {
    margin-left: auto;
  }

  .mb-4 {
    margin-bottom: 4px;
  }

  .mb-7 {
    margin-bottom: 7px;
  }

  .mb-8 {
    margin-bottom: 8px;
  }

  .mb-18 {
    margin-bottom: 18px;
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  .mb-12 {
    margin-bottom: 12px;
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  .mb-24 {
    margin-bottom: 24px;
  }

  .mb-auto {
    margin-bottom: auto;
  }

  .mr-5 {
    margin-right: 5px;
  }

  .mr-8 {
    margin-right: 8px;
  }

  .mr-10 {
    margin-right: 10px;
  }

  .mr-12 {
    margin-right: 12px;
  }

  .mr-20 {
    margin-right: 20px;
  }

  .mr-auto {
    margin-right: auto;
  }

  .p-15 {
    padding: 15px;
  }

  .left-auto {
    margin-left: auto;
  }

  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .icon-close {
    transform: rotate(45deg);
    cursor: pointer;
  }

  .z-index-5 {
    z-index: 5 !important;
  }

  .barcode-icon {
    cursor: pointer;
  }

  .barcode-icon:hover {
    opacity: 0.7;
  }

  .popup-barcode {
    z-index: 1000;
  }

  .table-col-1 {
    width: 8.33%;
  }
  .table-col-2 {
    width: 16.67%;
  }
  .table-col-3 {
    width: 25%;
  }
  .table-col-4 {
    width: 33.32%;
  }
  .table-col-5 {
    width: 41.65%;
  }
  .table-col-6 {
    width: 50%;
  }
  .table-col-7 {
    width: 58.3%;
  }
  .table-col-8 {
    width: 66.65%;
  }
  .table-col-9 {
    width: 75%;
  }
  .table-col-10 {
    width: 83.3%;
  }
  .table-col-11 {
    width: 91.63%;
  }
  .table-col-12 {
    width: 100%;
  }
  .shadow-box {
    filter: drop-shadow(0px 1px 12px rgba(105, 110, 118, 0.12));
  }
  .hover-item:hover {
    background-color: #f3f4f5;
  }
`

export default globalStyles
