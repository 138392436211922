export const SET_IS_LOADING = "SET_LOADING_AUTH/ticker-page";
export const SET_LAST_PARAMS = "SET_LAST_PARAMS/ticker-page";
export const SET_IS_POPUP_SHOW = "SET_IS_POPUP_SHOW/ticker-page";
export const SET_TICKERS = "SET_TICKERS/ticker-page";
export const SET_SELECTED_TICKER = "SET_SELECTED_TICKER/ticker-page";
export const SET_HUBS = "SET_HUBS/ticker-page";
export const SET_DATA_PAGINATION = "SET_DATA_PAGINATION/ticker-page";
export const SET_IS_POPUP_SHOW_EDIT = "SET_IS_POPUP_SHOW_EDIT/ticker-page";
export const SET_SELECTED_LOCATION = "SET_SELECTED_LOCATION/ticker-page";
export const SET_PAGES = "SET_PAGES/ticker-page";
export const SET_APP_LINKS = "SET_APP_LINKS/ticker-page";
