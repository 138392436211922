import { Get, Post, Put } from 'config/apiServiceConfig'

export type ParameterDataType = {
  id: number
  name: string
  field: string
  fieldType: 'STRING' | 'INTEGER' | 'LIST' | 'BOOLEAN' | ''
}

export type ParametersParamsType = {
  sort?: string
  direction?: string
  pageSize: number
  pageIndex: number
  totalElement?: number
  numberOfElements?: number
  totalPages?: number
}

export type GetWatchTowerParameterResponseType = {
  data: {
    parameters: ParameterDataType[]
  }
  pagination: ParametersParamsType
}

export type CreateUpdateParameterResponseType = {
  data: ParameterDataType
}

export type PostParameterPayloadType = {
  name: string
  field: string
  fieldType: string
}

export const getParameterList = (params: ParametersParamsType) =>
  Get<GetWatchTowerParameterResponseType>({
    url: '/fraud/watchtower/parameter',
    params,
  })

export const postParameter = (data: PostParameterPayloadType) =>
  Post<CreateUpdateParameterResponseType>({
    url: '/fraud/watchtower/parameter',
    data,
  })

export const putParameter = (data: PostParameterPayloadType, id: number) =>
  Put<CreateUpdateParameterResponseType>({
    url: `/fraud/watchtower/parameter/${id}`,
    data,
  })
