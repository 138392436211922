import adminReducer, { initialStateAdmin } from './admin'
import allorderReducer, { initialStateAllorder } from './allOrder'
import authReducer, { initialStateAuth } from './auth'
import buyerReducer, { initialStateBuyer } from './buyer'
import campaignReducer, { initialStateCampaign } from './campaign'
import catalogueReducer, { initialStateCatalogue } from './catalogue'
import categoryReducer, { initialStateCategory } from './category'
import damagePutAwayReducer, { initialState as initialStateDamagePutAway } from './damagePutAway'
import driverReducer, { initialStateDriver } from './driver'
import fraudReducer, { initialStateFraud } from './fraud'
import grnReducer, { initialState as initialStateGrn } from './grn'
import gwpReducer, { initialStateGwp } from './gwp'
import hubReducer, { initialStateHub } from './hub'
import hubDetailReducer, { initialStateHubDetail } from './hubDetail'
import inboundReducer, { initialStateInbound } from './inbound'
import inboundWarehouseReducer, { initialStateInboundWarehouse } from './inboundWarehouse'
import locationReducer, { initialStateLocation } from './location'
import massUploadReducer, { initialStateMassUpload } from './massUpload'
import packerReducer, { initialStatePacker } from './packer'
import pickerReducer, { initialStatePicker } from './picker'
import printAreaReducer, { initialPrintArea } from './printArea'
import productReducer, { initialStateProduct } from './product'
import productAddEditReducer, { initialStateProductAddEdit } from './productAddEdit'
import promotionReducer, { initialStatePromotion } from './promotion'
import purchaseOrderReducer, { initialStatePurchaseOrder } from './purchaseOrder'
import putAwayReducer, { initialState as initialStatePutAway } from './putAway'
import rackAddEditReducer, { initialStateRackAddEdit } from './rackAddEdit'
import rackDetailReducer, { initialStateRackDetail } from './rackDetail'
import rackDetailEditReducer, { initialStateRackDetailEdit } from './rackDetailEdit'
import rakReducer, { initialStateRack } from './rak'
import receivingReducer, { initialState as initialStateReceiving } from './receiving'
import refferalReducer, { initialStateRefferal } from './referral'
import referralSettingReducer, { initialStateReferralSetting } from './referralSetting'
import serviceLevelReducer, { initialStateServiceLevel } from './serviceLevel'
import setPositionReducer, { initialStateSetPosition } from './setPosition'
import settingsReducer, { initialStateSettings } from './settings'
import supplyOrderReducer, { initialStateSupplyOrder } from './supplyOrder'
import tickerReducer, { initialStateTicker } from './ticker'
import uomReducer, { initialStateUom } from './uom'
import voucherReducer, { initialStateVoucher } from './voucher'
import warehouseReducer, { initialStateWarehouse } from './warehouse'
import warehouseDetailReducer, { initialStateWarehouseDetail } from './warehouseDetail'
import bomReducer, { initialStateBoM } from './bom'
import bomAddEditReducer, { initialStateBoMAddEdit } from './bom/bomAddEdit'
import bomAddProductReducer, { initialStateBoMAddProduct } from './bom/bomAddProduct'

export const initialState = {
  auth: initialStateAuth,
  product: initialStateProduct,
  productAddEdit: initialStateProductAddEdit,
  category: initialStateCategory,
  catalogue: initialStateCatalogue,
  location: initialStateLocation,
  hub: initialStateHub,
  warehouse: initialStateWarehouse,
  supplyOrder: initialStateSupplyOrder,
  inbound: initialStateInbound,
  driver: initialStateDriver,
  buyer: initialStateBuyer,
  picker: initialStatePicker,
  packer: initialStatePacker,
  admin: initialStateAdmin,
  allorder: initialStateAllorder,
  settings: initialStateSettings,
  gwp: initialStateGwp,
  inboundWarehouse: initialStateInboundWarehouse,
  promotion: initialStatePromotion,
  ticker: initialStateTicker,
  serviceLevel: initialStateServiceLevel,
  referralSetting: initialStateReferralSetting,
  warehouseDetail: initialStateWarehouseDetail,
  hubDetail: initialStateHubDetail,
  refferal: initialStateRefferal,
  rackDetail: initialStateRackDetail,
  rak: initialStateRack,
  massUpload: initialStateMassUpload,
  setPosition: initialStateSetPosition,
  uom: initialStateUom,
  printArea: initialPrintArea,
  purchaseOrder: initialStatePurchaseOrder,
  campaign: initialStateCampaign,
  putAway: initialStatePutAway,
  receiving: initialStateReceiving,
  grn: initialStateGrn,
  damagePutAway: initialStateDamagePutAway,
  rackDetailEdit: initialStateRackDetailEdit,
  rackAddEdit: initialStateRackAddEdit,
  fraud: initialStateFraud,
  voucher: initialStateVoucher,
  bom: initialStateBoM,
  bomAddEdit: initialStateBoMAddEdit,
  bomAddProduct: initialStateBoMAddProduct,
}

const reducers = {
  authReducer,
  productReducer,
  productAddEditReducer,
  categoryReducer,
  catalogueReducer,
  locationReducer,
  hubReducer,
  warehouseReducer,
  supplyOrderReducer,
  inboundReducer,
  driverReducer,
  buyerReducer,
  pickerReducer,
  packerReducer,
  adminReducer,
  allorderReducer,
  settingsReducer,
  inboundWarehouseReducer,
  promotionReducer,
  tickerReducer,
  serviceLevelReducer,
  referralSettingReducer,
  warehouseDetailReducer,
  hubDetailReducer,
  refferalReducer,
  gwpReducer,
  rackDetailReducer,
  rakReducer,
  massUploadReducer,
  setPositionReducer,
  uomReducer,
  printAreaReducer,
  purchaseOrderReducer,
  campaignReducer,
  putAwayReducer,
  receivingReducer,
  grnReducer,
  damagePutAwayReducer,
  rackDetailEditReducer,
  rackAddEditReducer,
  fraudReducer,
  voucherReducer,
  bomReducer,
  bomAddEditReducer,
  bomAddProductReducer,
}
export default reducers
