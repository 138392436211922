import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const AreaCreatePageRBAC = {
  pageID: 'a6eee82552b57d6357df354e2c3d0a20',
  elementID: {
    ButtonSubmitArea: 'a9c11fe02d5c12b1d60bfa6c041b0f9f',
  },
} as const

export const AreaEditPageRBAC = {
  pageID: '9c726d598214cbed38a1bc012d28d5f0',
  elementID: {
    ButtonSubmitEditArea: 'f1681d8d448d9bdc8519cf4014f20a1b',
  },
} as const

export const useRBACAreaCreatePage = createUseRBAC(AreaCreatePageRBAC.elementID)
export const useRBACAreaEditPage = createUseRBAC(AreaEditPageRBAC.elementID)

export type UseRBACAreaCreatePageType = ReturnType<typeof useRBACAreaCreatePage>
export type UseRBACAreaEditPageType = ReturnType<typeof useRBACAreaEditPage>
