import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  BannerHighlightFormType,
  BannerHighlightListType,
  InitialStateType,
  ProductHighlightFormType,
  ProductHighlightListType,
  SetBannerHighlightFormPayloadActionType,
  SetProductHighlightFormPayloadActionType,
  ProductCategoryType,
  SetProductHighlightProductsPayloadActionType,
  SetFormPayloadActionType,
  DynamicChannelFormType,
} from 'features/CategoryAndPosisi/@types/DynamicChannelAddEdit'

export const SLICE_NAME = 'dynamicChannelAddEdit'

export const initialState: InitialStateType = {
  form: {
    category: null,
    bgColor: null,
    hubs: [],
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
  },
  bannerHighlight: {
    selectedBanner: null,
    isFormShow: false,
    form: {
      imageFile: null,
      imagePreview: '',
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      category: null,
      hubs: [],
      is_active: true,
      is_deleted: false,
    },
    list: [],
    categories: [],
  },
  productHighlight: {
    selectedProduct: null,
    isFormShow: false,
    form: {
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      hubs: [],
      is_active: true,
      is_deleted: false,
      default_text: '',
      free_text: '',
      product: null,
      labelType: null,
    },
    list: [],
    products: [],
  },
  imageRules: {},
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    setForm: (state, { payload }: PayloadAction<SetFormPayloadActionType>) => {
      const { field, value } = payload
      state.form[field] = value as never
    },
    resetForm: (state, { payload }: PayloadAction<DynamicChannelFormType | undefined>) => {
      state.form = payload ? { ...initialState.form, ...payload } : { ...initialState.form }
    },

    /** banner category highlight */
    setBannerHighlightFormShow: (state, { payload }: PayloadAction<boolean>) => {
      state.bannerHighlight.isFormShow = payload
    },
    setBannerHighlightForm: (
      state,
      { payload }: PayloadAction<SetBannerHighlightFormPayloadActionType>,
    ) => {
      state.bannerHighlight.form[payload.field] = payload.value as never
    },
    resetBannerHighlightForm: (
      state,
      { payload }: PayloadAction<BannerHighlightFormType | undefined>,
    ) => {
      state.bannerHighlight.form = payload
        ? { ...payload }
        : { ...initialState.bannerHighlight.form }
    },
    setSelectedBannerHighlight: (
      state,
      { payload }: PayloadAction<Nullable<BannerHighlightListType>>,
    ) => {
      state.bannerHighlight.selectedBanner = payload ? { ...payload } : null
    },
    addBannerHighlightList: (state, { payload }: PayloadAction<BannerHighlightListType>) => {
      state.bannerHighlight.list = [...state.bannerHighlight.list, { ...payload }]
    },
    setBannerHighlightList: (state, { payload }: PayloadAction<BannerHighlightListType[]>) => {
      state.bannerHighlight.list = [...payload]
    },
    editBannerHighlight: (
      state,
      { payload }: PayloadAction<{ key: string; value: BannerHighlightListType }>,
    ) => {
      const { key, value } = payload
      state.bannerHighlight.list = state.bannerHighlight.list.map((list) => {
        if (key === list.uniqueKey) {
          return { ...list, ...value }
        }

        return list
      })
    },
    deleteBannerHighlightList: (state, { payload: uniqueKey }: PayloadAction<string>) => {
      state.bannerHighlight.list = state.bannerHighlight.list.map((item) => ({
        ...item,
        is_deleted: item.uniqueKey === uniqueKey ? true : item.is_deleted,
      }))
    },
    setBannerHighlightCategories: (state, { payload }: PayloadAction<ProductCategoryType[]>) => {
      state.bannerHighlight.categories = payload
    },
    /** end of banner category highlight */

    /** product category highlight */
    setProductHighlightFormShow: (state, { payload }: PayloadAction<boolean>) => {
      state.productHighlight.isFormShow = payload
    },
    setProductHighlightForm: (
      state,
      { payload }: PayloadAction<SetProductHighlightFormPayloadActionType>,
    ) => {
      state.productHighlight.form[payload.field] = payload.value as never
    },
    resetProductHighlightForm: (
      state,
      { payload }: PayloadAction<ProductHighlightFormType | undefined>,
    ) => {
      state.productHighlight.form = payload
        ? { ...payload }
        : { ...initialState.productHighlight.form }
    },
    setSelectedProductHighlight: (
      state,
      { payload }: PayloadAction<Nullable<ProductHighlightListType>>,
    ) => {
      state.productHighlight.selectedProduct = payload ? { ...payload } : null
    },
    addProductHighlightList: (state, { payload }: PayloadAction<ProductHighlightListType>) => {
      state.productHighlight.list = [...state.productHighlight.list, { ...payload }]
    },
    setProductHighlightList: (state, { payload }: PayloadAction<ProductHighlightListType[]>) => {
      state.productHighlight.list = [...payload]
    },
    editProductHighlight: (
      state,
      { payload }: PayloadAction<{ key: string; value: ProductHighlightListType }>,
    ) => {
      const { key, value } = payload
      state.productHighlight.list = state.productHighlight.list.map((list) =>
        key === list.uniqueKey ? { ...list, ...value } : list,
      )
    },
    deleteProductHighlightList: (state, { payload: uniqueKey }: PayloadAction<string>) => {
      state.productHighlight.list = state.productHighlight.list.map((item) => ({
        ...item,
        is_deleted: item.uniqueKey === uniqueKey ? true : item.is_deleted,
      }))
    },
    setProductHighlightProducts: (
      state,
      { payload }: PayloadAction<SetProductHighlightProductsPayloadActionType>,
    ) => {
      state.productHighlight.products = payload
    },
    /** end of product category highlight */

    setImageRules: (state, { payload }: PayloadAction<InitialStateType['imageRules']>) => {
      state.imageRules = { ...payload }
    },
  },
})

export const {
  resetState,
  setForm,
  resetForm,
  setBannerHighlightFormShow,
  setBannerHighlightForm,
  resetBannerHighlightForm,
  addBannerHighlightList,
  setBannerHighlightList,
  editBannerHighlight,
  deleteBannerHighlightList,
  setSelectedBannerHighlight,
  setBannerHighlightCategories,
  setProductHighlightFormShow,
  setProductHighlightForm,
  resetProductHighlightForm,
  setSelectedProductHighlight,
  addProductHighlightList,
  setProductHighlightList,
  editProductHighlight,
  deleteProductHighlightList,
  setProductHighlightProducts,
  setImageRules,
} = slice.actions
export default slice.reducer
