/**
 * No api call
 */

export const SET_SELECT_PRODUCT_FOR_CREATE_GWP = 'SET_SELECT_PRODUCT_FOR_CREATE_GWP'
export const SET_SELECT_GIFT_FOR_CREATE_GWP = 'SET_SELECT_GIFT_FOR_CREATE_GWP'

export const SET_GET_PRODUCT_REQUEST = 'SET_GET_PRODUCT_REQUEST'
export const SET_GET_PRODUCT_SUCCESS = 'SET_GET_PRODUCT_SUCCESS'
export const SET_GET_PRODUCT_FAILED = 'SET_GET_PRODUCT_FAILED'

export const SET_GET_GIFT_REQUEST = 'SET_GET_GIFT_REQUEST'
export const SET_GET_GIFT_SUCCESS = 'SET_GET_GIFT_SUCCESS'
export const SET_GET_GIFT_FAILED = 'SET_GET_GIFT_FAILED'

export const SET_GET_LIST_REQUEST = 'SET_GET_LIST_REQUEST'
export const SET_GET_LIST_SUCCESS = 'SET_GET_LIST_SUCCESS'
export const SET_GET_LIST_FAILED = 'SET_GET_LIST_FAILED'

export const SET_GET_DETAIL_REQUEST = 'SET_GET_DETAIL_REQUEST'
export const SET_GET_DETAIL_SUCCESS = 'SET_GET_DETAIL_SUCCESS'
export const SET_GET_DETAIL_FAILED = 'SET_GET_DETAIL_FAILED'

export const SET_GET_CAMPAIGN_TYPE_REQUEST = 'SET_GET_CAMPAIGN_TYPE_REQUEST'
export const SET_GET_CAMPAIGN_TYPE_SUCCESS = 'SET_GET_CAMPAIGN_TYPE_SUCCESS'
export const SET_GET_CAMPAIGN_TYPE_FAILED = 'SET_GET_CAMPAIGN_TYPE_FAILED'

export const SET_UPDATE_GWP_REQUEST = 'SET_UPDATE_GWP_REQUEST'
export const SET_UPDATE_GWP_SUCCESS = 'SET_UPDATE_GWP_SUCCESS'
export const SET_UPDATE_GWP_FAILED = 'SET_UPDATE_GWP_FAILED'

export const SET_UPDATE_GWP_STATUS_REQUEST = 'SET_UPDATE_GWP_STATUS_REQUEST'
export const SET_UPDATE_GWP_STATUS_SUCCESS = 'SET_UPDATE_GWP_STATUS_SUCCESS'
export const SET_UPDATE_GWP_STATUS_FAILED = 'SET_UPDATE_GWP_STATUS_FAILED'

export const SET_CREATE_GWP_REQUEST = 'SET_CREATE_GWP_REQUEST'
export const SET_CREATE_GWP_SUCCESS = 'SET_CREATE_GWP_SUCCESS'
export const SET_CREATE_GWP_FAILED = 'SET_CREATE_GWP_FAILED'

export const SET_UPDATE_GIFT = 'SET_UPDATE_GIFT'

export const SET_PAGINATION_PRODUCT = 'SET_PAGINATION_PRODUCT'

export const SET_CREATE_GIFT = 'SET_CREATE_GIFT'
export const SET_DELETE_GIFT = 'SET_DELETE_GIFT'

export const SET_GWP_FORM = 'SET_GWP_FORM'
export const SET_GWP_RESET = 'SET_GWP_RESET'
export const SET_GWP_LOADING = 'SET_GWP_LOADING'
export const SET_GWP_PARAMS_FILTER = 'SET_GWP_PARAMS_FILTER'
