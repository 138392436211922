import { memo } from 'react'
import styled from 'styled-components'
import { TextBody, Button } from 'components'
import iconFileCSV from 'assets/images/icon_file_csv.svg'

const OnFileSelected = ({
  file = {},
  onResetFile,
  handleUploadFile,
  useGetFileOnly,
  isMini,
  showFileType,
}) => (
  <DescriptionContainer>
    {!isMini && (
      <IconUploadContainer>
        <IconUpload src={iconFileCSV} />
      </IconUploadContainer>
    )}

    <TextDescription>
      <TextBody weight="light" className="pointer" color="main">
        {file.name}
      </TextBody>
      {!isMini && (
        <FileDescription>
          <TextBody weight="light" className="pointer" color="black">
            Pastikan file Excel sudah benar yang sudah di-upload tidak bisa kamu batalkan
          </TextBody>
        </FileDescription>
      )}
      <NavigationContainer isMini={isMini}>
        <ButtonWhite
          onClick={onResetFile}
          style={{ marginRight: useGetFileOnly ? 'unset' : '24px' }}
        >
          Ganti File
        </ButtonWhite>
        {!useGetFileOnly && <ButtonBlue onClick={handleUploadFile}>Upload</ButtonBlue>}
      </NavigationContainer>
    </TextDescription>
  </DescriptionContainer>
)

export default memo(OnFileSelected)

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const IconUploadContainer = styled.div`
  display: flex;
  width: 64px;
  height: 64px;
  border-radius: 100px;
  background: #ffffff;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  box-shadow: 0px 1px 12px rgba(105, 110, 118, 0.12);
`

const IconUpload = styled.img`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  height: 34px;
`

const TextDescription = styled.div`
  text-align: center;
`
const FileDescription = styled.div`
  margin-top: 8px;
`
const NavigationContainer = styled.div`
  display: flex;
  margin-top: ${({ isMini }) => (isMini ? '13px' : '24px')};
  justify-content: center;
`

const ButtonWhite = styled(Button)`
  background: white;
  color: #246ee5;
  border: 1px solid #246ee5;
  min-width: 128px;
`

const ButtonBlue = styled(Button)`
  background: #246ee5;
  color: white;
  border: 1px solid #246ee5;
  min-width: 128px;
`
