import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getAreaDetailAPI,
  getCategoriesAPI,
  postAreaAPI,
  putAreaAPI,
} from 'features/Rack/services/area'
import { callErrorMsg } from 'helpers/errorMsg'

export const SLICE_NAME = 'MASTER_AREA/addEdit'

export const fetchGetAreaDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchGetAreaDetail`,
  async (params: Parameters<typeof getAreaDetailAPI>[0], { rejectWithValue }) => {
    try {
      const { data } = await getAreaDetailAPI(params)

      return data.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchPostArea = createAsyncThunk(
  `${SLICE_NAME}/fetchPostArea`,
  async (
    {
      payload,
      callback,
    }: {
      payload: Parameters<typeof postAreaAPI>[0]
      callback?: () => void
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await postAreaAPI(payload)

      if (callback) {
        callback()
      }

      return data.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchPutArea = createAsyncThunk(
  `${SLICE_NAME}/fetchPutArea`,
  async (
    {
      payload,
      callback,
    }: {
      payload: Parameters<typeof putAreaAPI>[0]
      callback?: () => void
    },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await putAreaAPI(payload)

      if (callback) {
        callback()
      }

      return data.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchGetCategories = createAsyncThunk(
  `${SLICE_NAME}/fetchGetCategories`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getCategoriesAPI()

      return data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)
