import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  GetPicListResponseType,
  GetWarehouseListResponseType,
  GetReorderListRequestParamType,
  GetReorderListConfirmedResponseType,
  GetVendorsByProductIdResponseType,
  VendorItemType,
  UpdateRlRequestType,
  GetRlPreviewResponseType,
  ProductRlType,
  GetAllVendorsParamType,
  PutSaveCheckedReorderListResponseType,
  DeleteRlReasonResponseType,
} from 'features/Enterprise/ReorderList/services/reorderList'

export type ReorderItemType = GetReorderListConfirmedResponseType['data'][number] & {
  isOpen: boolean
  isShowTooltip: boolean
}

export type VendorItemComboboxType = Omit<
  GetVendorsByProductIdResponseType['data'][number],
  'picSourcing'
> & {
  vendor_sourcing_pic: number
}

type VendorsInTableType = Omit<
  VendorItemType,
  'checked' | 'id' | 'required_qty' | 'sourcing_pic'
> & {
  id?: number
  selectedReason?: {
    id: number
    reason: string
  }
  isError?: boolean
  reorder_list_reason_id: number
  vendor_sourcing_pic: number
}

type ExtendedProductItemType = ProductRlType & {
  group: number
  selectedReason: {
    id: number
    reason: string
  }
}

export type PreviewRlItemDataType = Omit<GetRlPreviewResponseType['data'][number], 'products'> & {
  uniqueId: string
  products: ExtendedProductItemType[]
}

export type ModalListReorderToConfirmDataType = Omit<
  GetReorderListConfirmedResponseType['data'][number],
  'est_shipping_date'
> & {
  est_shipping_date: Date
}

export interface SelectCategoryFieldType {
  triggerResetCategory: boolean
  selectedCategory: string[]
  search: string
  listData: {
    categoryId: number
    categoryName: string
    items: {
      itemId: number
      itemName: string
    }[]
  }[]
}

type FilterParamReorderListType = Omit<GetReorderListRequestParamType, 'category_ids'> & {
  category_ids: number[]
}

type AllVendorItemType = {
  company_name: string
  id: number
}

interface InitialStateType {
  pageForRole: 'sc' | 'sourcing' | ''
  isLoading: boolean
  isSavingCheckedRl: boolean
  createdDate: null | [Date | null, Date | null]
  filter: FilterParamReorderListType
  sourcingPic: {
    selectedName: string
    listData: GetPicListResponseType['content']
    query: {
      pageSize: number
      pageIndex: number
      name: string
    }
  }
  vendor: {
    selectedVendor?: AllVendorItemType | null
    listData: AllVendorItemType[]
    query: GetAllVendorsParamType
  }
  warehouse: {
    selectedName: string
    listData: GetWarehouseListResponseType['data']
  }
  category: SelectCategoryFieldType
  confirmedReorderList: {
    allUniqueIdInTable: {
      [key: number]: string[]
    }
    allReorderListVendorId: {
      [key: number]: number[]
    }
    listData: ReorderItemType[]
    countOfProduct: number
    countOfVendor: number
    selectedIdInTable: string[]
    originalSelectedReorderListVendorId: number[]
    selectedReorderListVendorId: number[]
  }
  modalAddEditVendor: {
    isOpen: boolean
    maxQty: number
    reorderListId: number
    productId: number
    locationId: number
    shippingDate: string
    listReason: {
      id: number
      reason: string
    }[]
    vendorCombobox: {
      searchVendorName: string
      selectedIds: number[]
      selectedVendorList: VendorItemComboboxType[]
      listData: VendorItemComboboxType[]
    }
    vendorsInTable: VendorsInTableType[]
    updatedVendorsInTable: VendorsInTableType[]
  }
  modalEditRl: {
    reorderListId: number
    isOpen: boolean
    oldData: UpdateRlRequestType
    data: UpdateRlRequestType
    shippingDate: string
  }
  previewReorderList: {
    data: PreviewRlItemDataType[]
  }
  modalListReorderToConfirm: {
    isOpen: boolean
    scPic: {
      selectedName: string
      listData: GetPicListResponseType['content']
      searchName?: string
    }
    filter: GetReorderListRequestParamType
    countOfData: number
    data: ModalListReorderToConfirmDataType[]
    category: SelectCategoryFieldType
    productSkuName: string
    selectedCategoryIds: number[]
  }
  modalSavePreviewConfirmation: {
    isOpen: boolean
    countPR: number
    rlList: PreviewRlItemDataType[]
  }
  modalSkuIsSaved: {
    isOpen: boolean
    reorderListItem?: PutSaveCheckedReorderListResponseType['reorder_list_detail']
  }
  rlVendorIds: {
    checked: boolean
    reorder_list_vendor_id: number
  }[]
  modalBulkDelete: {
    isOpen: boolean
    ids: number[]
  }
  modalAutoPoConfirmation: {
    isOpen: boolean
    rlList: PreviewRlItemDataType[]
    withDownloadCsv: boolean
  }
  totalPoDraft: number
  deleteReasons: DeleteRlReasonResponseType['data']
  isCheckBulkDelete: boolean
}

const initialState: InitialStateType = {
  pageForRole: '',
  isLoading: false,
  isSavingCheckedRl: false,
  createdDate: null,
  filter: {
    from: '',
    to: '',
    sourcing_pic: null,
    sku: '',
    location_id: null,
    category_ids: [],
    page_index: 1,
    page_size: 10,
    status: 'CONFIRMED',
    order_by: 'created_at',
    order_direction: 'asc',
  },
  sourcingPic: {
    selectedName: '',
    listData: [],
    query: {
      pageSize: 10,
      pageIndex: 0,
      name: '',
    },
  },
  vendor: {
    listData: [],
    query: {
      page_size: 30,
      page_index: 1,
    },
  },
  warehouse: {
    selectedName: '',
    listData: [],
  },
  category: {
    triggerResetCategory: false,
    selectedCategory: ['All Selected'],
    search: '',
    listData: [],
  },
  confirmedReorderList: {
    allUniqueIdInTable: {},
    allReorderListVendorId: {},
    listData: [],
    countOfProduct: 0,
    countOfVendor: 0,
    selectedIdInTable: [],
    originalSelectedReorderListVendorId: [],
    selectedReorderListVendorId: [],
  },
  modalAddEditVendor: {
    isOpen: false,
    maxQty: 0,
    reorderListId: 0,
    productId: 0,
    locationId: 0,
    shippingDate: '',
    listReason: [],
    vendorCombobox: {
      searchVendorName: '',
      selectedIds: [],
      selectedVendorList: [],
      listData: [],
    },
    vendorsInTable: [],
    updatedVendorsInTable: [],
  },
  modalEditRl: {
    reorderListId: 0,
    isOpen: false,
    oldData: {
      id: 0,
      price: 0,
      discount: 0,
    },
    data: {
      id: 0,
      price: 0,
      discount: 0,
    },
    shippingDate: '',
  },
  previewReorderList: {
    data: [],
  },
  modalListReorderToConfirm: {
    isOpen: false,
    scPic: {
      selectedName: '',
      listData: [],
      searchName: '',
    },
    filter: {
      page_index: 0,
      page_size: 50,
      sc_pic: 0,
      status: 'CREATED',
      order_by: 'created_at',
      order_direction: 'asc',
    },
    countOfData: 0,
    data: [],
    category: {
      triggerResetCategory: false,
      selectedCategory: ['All Selected'],
      search: '',
      listData: [],
    },
    productSkuName: '',
    selectedCategoryIds: [],
  },
  modalSavePreviewConfirmation: {
    isOpen: false,
    countPR: 0,
    rlList: [],
  },
  modalSkuIsSaved: {
    isOpen: false,
  },
  rlVendorIds: [],
  modalBulkDelete: {
    isOpen: false,
    ids: [],
  },
  modalAutoPoConfirmation: {
    isOpen: false,
    rlList: [],
    withDownloadCsv: false,
  },
  totalPoDraft: 0,
  deleteReasons: [],
  isCheckBulkDelete: false,
}

export const reorderListV2Slice = createSlice({
  name: 'reorderListV2',
  initialState,
  reducers: {
    setPageForRole: (state, action: PayloadAction<InitialStateType['pageForRole']>) => {
      state.pageForRole = action.payload
    },
    setIsSavingCheckedRl: (state, action) => {
      state.isSavingCheckedRl = action.payload
    },
    setCreatedDate: (state, action: PayloadAction<InitialStateType['createdDate']>) => {
      state.createdDate = action.payload
    },
    setPicList: (
      state,
      action: PayloadAction<{ listData: GetPicListResponseType['content']; searchName: string }>,
    ) => {
      const { listData, searchName } = action.payload
      state.sourcingPic.listData = listData
      state.sourcingPic.query.name = searchName
    },
    setSelectedPic: (state, action: PayloadAction<{ name: string }>) => {
      const { name } = action.payload
      state.sourcingPic.selectedName = name
    },
    setFilterParameter: (state, action: PayloadAction<Partial<InitialStateType['filter']>>) => {
      state.filter = { ...state.filter, ...action.payload }
    },
    setWarehouseList: (state, action: PayloadAction<GetWarehouseListResponseType['data']>) => {
      state.warehouse.listData = action.payload
    },
    setSelectedWarehouse: (state, action) => {
      state.warehouse.selectedName = action.payload
    },
    setCategory: (state, action: PayloadAction<Partial<InitialStateType['category']>>) => {
      state.category = { ...state.category, ...action.payload }
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    toggleBtnDetailReorderGroup: (state, action: PayloadAction<number>) => {
      const rowIndex = action.payload
      state.confirmedReorderList.listData[rowIndex].isOpen =
        !state.confirmedReorderList.listData[rowIndex].isOpen
    },
    setModalAddEditVendor: (
      state,
      action: PayloadAction<Partial<InitialStateType['modalAddEditVendor']>>,
    ) => {
      state.modalAddEditVendor = { ...state.modalAddEditVendor, ...action.payload }
    },
    setQtyOfVendorsInTable: (state, action: PayloadAction<{ index: number; qty: number }>) => {
      const { index, qty } = action.payload
      state.modalAddEditVendor.updatedVendorsInTable[index].confirm_qty = qty
    },
    setIsErrorVendorsInTable: (
      state,
      action: PayloadAction<{ index: number; isError: boolean }>,
    ) => {
      const { index, isError } = action.payload
      const newList = state.modalAddEditVendor.updatedVendorsInTable.map((item) => ({
        ...item,
        isError: false,
      }))
      newList[index].isError = isError
      state.modalAddEditVendor.updatedVendorsInTable = newList
    },
    setSelectedReasonInModalAddEditRL: (
      state,
      action: PayloadAction<{
        index: number
        selectedReason: InitialStateType['modalAddEditVendor']['listReason'][number]
      }>,
    ) => {
      const { index, selectedReason } = action.payload
      state.modalAddEditVendor.updatedVendorsInTable[index].selectedReason = selectedReason
      state.modalAddEditVendor.updatedVendorsInTable[index].reorder_list_reason_id =
        selectedReason.id
    },
    setVendorComboboxOfModalAddEditRl: (
      state,
      action: PayloadAction<Partial<InitialStateType['modalAddEditVendor']['vendorCombobox']>>,
    ) => {
      state.modalAddEditVendor.vendorCombobox = {
        ...state.modalAddEditVendor.vendorCombobox,
        ...action.payload,
      }
    },
    setModalEditRlData: (
      state,
      action: PayloadAction<Partial<InitialStateType['modalEditRl']['data']>>,
    ) => {
      state.modalEditRl.data = { ...state.modalEditRl.data, ...action.payload }
    },
    setModalEditRl: (state, action: PayloadAction<Partial<InitialStateType['modalEditRl']>>) => {
      state.modalEditRl = { ...state.modalEditRl, ...action.payload }
    },
    resetModalAddEditVendor: (state) => {
      state.modalAddEditVendor = initialState.modalAddEditVendor
    },
    resetModalEditRlState: (state) => {
      state.modalEditRl = initialState.modalEditRl
    },
    setPreviewReorderList: (
      state,
      action: PayloadAction<Partial<InitialStateType['previewReorderList']>>,
    ) => {
      state.previewReorderList = {
        ...state.previewReorderList,
        ...action.payload,
      }
    },
    setPreviewGrandTotalDataOfGroup: (
      state,
      action: PayloadAction<{ parentIndex: number; value: number }>,
    ) => {
      const { parentIndex, value } = action.payload
      state.previewReorderList.data[parentIndex].po_rebate = value
    },
    setPreviewReorderListDataOfReason: (
      state,
      action: PayloadAction<{
        parentIndex: number
        productIndex: number
        selectedReason: ExtendedProductItemType['selectedReason']
      }>,
    ) => {
      const { parentIndex, productIndex, selectedReason } = action.payload
      state.previewReorderList.data[parentIndex].products[productIndex].selectedReason =
        selectedReason
    },
    calculateCountOfProductAndVendor: (state) => {
      const listVendorId: number[] = []
      const listProductId: number[] = []

      state.confirmedReorderList.selectedIdInTable.forEach((combinedId) => {
        const arrayId = combinedId.split('-')
        const productId = +arrayId[1]
        const vendorId = +arrayId[2]

        if (!listProductId.includes(productId)) listProductId.push(productId)
        if (!listVendorId.includes(vendorId)) listVendorId.push(vendorId)
      })

      state.confirmedReorderList.countOfVendor = listVendorId.length
      state.confirmedReorderList.countOfProduct = listProductId.length
    },
    setConfirmedReorderList: (
      state,
      action: PayloadAction<Partial<InitialStateType['confirmedReorderList']>>,
    ) => {
      state.confirmedReorderList = { ...state.confirmedReorderList, ...action.payload }
    },
    resetIdsOfConfirmedReorderList: (state) => {
      state.confirmedReorderList.selectedIdInTable = []
      state.confirmedReorderList.selectedReorderListVendorId = []
    },
    setModalListReorderToConfirm: (
      state,
      action: PayloadAction<Partial<InitialStateType['modalListReorderToConfirm']>>,
    ) => {
      state.modalListReorderToConfirm = { ...state.modalListReorderToConfirm, ...action.payload }
    },
    deleteListReorderToConfirmDataAtSpecificIndex: (
      state,
      action: PayloadAction<{
        index: number
      }>,
    ) => {
      const { index } = action.payload
      state.modalListReorderToConfirm.data.splice(index, 1)
    },
    setListReorderToConfirmDataAtSpecificIndex: (
      state,
      action: PayloadAction<{
        index: number
        fieldName: 'required_qty' | 'est_shipping_date'
        value: never
      }>,
    ) => {
      const { index, fieldName, value } = action.payload
      state.modalListReorderToConfirm.data[index][fieldName] = value
    },
    setCategoryOfModalListReorderToConfirm: (
      state,
      action: PayloadAction<Partial<SelectCategoryFieldType>>,
    ) => {
      state.modalListReorderToConfirm.category = {
        ...state.modalListReorderToConfirm.category,
        ...action.payload,
      }
    },
    resetModalListReorderToConfirm: (state) => {
      state.modalListReorderToConfirm = initialState.modalListReorderToConfirm
    },
    setModalSavePreviewConfirmation: (
      state,
      action: PayloadAction<Partial<InitialStateType['modalSavePreviewConfirmation']>>,
    ) => {
      state.modalSavePreviewConfirmation = {
        ...state.modalSavePreviewConfirmation,
        ...action.payload,
      }
    },
    setVendor: (state, action) => {
      state.vendor = { ...state.vendor, ...action.payload }
    },
    setMModalSkuIsSaved: (state, action) => {
      state.modalSkuIsSaved = { ...state.modalSkuIsSaved, ...action.payload }
    },
    setRlVendorIds: (state, action) => {
      state.rlVendorIds = action.payload
    },
    resetMModalSkuIsSaved: (state) => {
      state.modalSkuIsSaved = initialState.modalSkuIsSaved
    },
    setModalBulkDelete: (state, action) => {
      state.modalBulkDelete = { ...state.modalBulkDelete, ...action.payload }
    },
    resetModalBulkDelete: (state) => {
      state.modalBulkDelete = initialState.modalBulkDelete
    },
    setUpdatedPreviewRlList: (state, action: PayloadAction<PreviewRlItemDataType[]>) => {
      state.previewReorderList = { data: action.payload }
    },
    setTotalPoDraft: (state, action: PayloadAction<number>) => {
      state.totalPoDraft = action.payload
    },
    setDeleteReasons: (state, action: PayloadAction<DeleteRlReasonResponseType['data']>) => {
      state.deleteReasons = action.payload
    },
    setIsCheckBulkDelete: (state, action: PayloadAction<boolean>) => {
      state.isCheckBulkDelete = action.payload
    },
    resetState: () => initialState,
  },
})

export default reorderListV2Slice.reducer
export const {
  setPageForRole,
  setIsSavingCheckedRl,
  setCreatedDate,
  setPicList,
  setSelectedPic,
  setFilterParameter,
  setWarehouseList,
  setSelectedWarehouse,
  setCategory,
  setIsLoading,
  toggleBtnDetailReorderGroup,
  setModalAddEditVendor,
  setQtyOfVendorsInTable,
  setModalEditRlData,
  setModalEditRl,
  setSelectedReasonInModalAddEditRL,
  setVendorComboboxOfModalAddEditRl,
  resetModalAddEditVendor,
  resetModalEditRlState,
  setPreviewReorderList,
  setPreviewGrandTotalDataOfGroup,
  setPreviewReorderListDataOfReason,
  setConfirmedReorderList,
  resetIdsOfConfirmedReorderList,
  setModalListReorderToConfirm,
  setListReorderToConfirmDataAtSpecificIndex,
  setCategoryOfModalListReorderToConfirm,
  resetModalListReorderToConfirm,
  deleteListReorderToConfirmDataAtSpecificIndex,
  calculateCountOfProductAndVendor,
  setIsErrorVendorsInTable,
  setModalSavePreviewConfirmation,
  setVendor,
  setMModalSkuIsSaved,
  setRlVendorIds,
  resetMModalSkuIsSaved,
  setModalBulkDelete,
  resetModalBulkDelete,
  setUpdatedPreviewRlList,
  setTotalPoDraft,
  setDeleteReasons,
  setIsCheckBulkDelete,
  resetState,
} = reorderListV2Slice.actions
