import { lazy } from 'react'

import { roles } from 'constant/roles'
import generateRouterDetail from 'utils/helpers/generateRouterDetail'

const HubStaffSchedule = lazy(() => import('../pages/HubStaffSchedule'))
const MasterApproval = lazy(() => import('../pages/MasterApproval'))
const MasterShift = lazy(() => import('../pages/MasterShift'))

export const combineScheduleRolesAccess = [
  roles.AUTH_DRIVER_COORDINATOR,
  roles.AUTH_CENTRAL_SUPERVISOR,
  roles.AUTH_EXPANSION,
  roles.AUTH_MARKETING,
  roles.AUTH_HO_HR,
  roles.AUTH_HO_CS_LEADER,
  roles.AUTH_SPV_HUB,
  roles.AUTH_HO_OPX_MID_MILE,
  roles.AUTH_HO_OPS_MID_MILE_SPV,
  roles.AUTH_HO_LP,
  roles.AUTH_HO_OPX_HUB,
  roles.AUTH_TEMP_MASTER_APPROVAL,
  roles.AUTH_HUB_SPV,
  roles.AUTH_ASTRO_GO_SPV,
  roles.AUTH_ASTRO_GO_MANAGER,
  roles.AUTH_WH_MANAGER,
]

export const ScheduleRouter: RouterFeatureInterface<
  'ScheduleRouter',
  '/dashboard/hub-staff/schedule'
> = {
  name: 'ScheduleRouter',
  Component: () => <HubStaffSchedule type="schedule" />,
  path: '/dashboard/hub-staff/schedule',
  routeType: 'PRIVATE',
  allowedRoles: [
    roles.AUTH_DRIVER_COORDINATOR,
    roles.AUTH_CENTRAL_SUPERVISOR,
    roles.AUTH_EXPANSION,
    roles.AUTH_MARKETING,
    roles.AUTH_HO_HR,
    roles.AUTH_HO_CS_LEADER,
    roles.AUTH_SPV_HUB,
    roles.AUTH_HO_OPX_MID_MILE,
    roles.AUTH_HO_OPS_MID_MILE_SPV,
    roles.AUTH_HO_LP,
    roles.AUTH_HO_OPX_HUB,
    roles.AUTH_TEMP_MASTER_APPROVAL,
    roles.AUTH_HUB_SPV,
    roles.AUTH_ASTRO_GO_SPV,
    roles.AUTH_ASTRO_GO_MANAGER,
  ],
  isDashboard: true,
}

export const AttendanceRouter: RouterFeatureInterface<
  'AttendanceRouter',
  '/dashboard/hub-staff/attendance'
> = {
  name: 'AttendanceRouter',
  Component: () => <HubStaffSchedule type="attendance" />,
  path: '/dashboard/hub-staff/attendance',
  routeType: 'PRIVATE',
  allowedRoles: [
    roles.AUTH_DRIVER_COORDINATOR,
    roles.AUTH_CENTRAL_SUPERVISOR,
    roles.AUTH_EXPANSION,
    roles.AUTH_MARKETING,
    roles.AUTH_HO_HR,
    roles.AUTH_HO_CS_LEADER,
    roles.AUTH_SPV_HUB,
    roles.AUTH_HO_OPX_MID_MILE,
    roles.AUTH_HO_OPS_MID_MILE_SPV,
    roles.AUTH_HO_LP,
    roles.AUTH_HO_OPX_HUB,
    roles.AUTH_TEMP_MASTER_APPROVAL,
    roles.AUTH_HUB_SPV,
    roles.AUTH_ASTRO_GO_SPV,
    roles.AUTH_ASTRO_GO_MANAGER,
  ],
  isDashboard: true,
}

export const MasterApprovalRouter: RouterFeatureInterface<
  'MasterApproval',
  '/dashboard/hub-staff/master-approval'
> = {
  name: 'MasterApproval',
  Component: MasterApproval,
  path: '/dashboard/hub-staff/master-approval',
  routeType: 'PRIVATE',

  allowedRoles: [
    roles.AUTH_SPV_HUB,
    roles.AUTH_WH_MANAGER,
    roles.AUTH_PRODUCT_MANAGER,
    roles.AUTH_HO_HR,
    roles.AUTH_HO_OPX_MID_MILE,
    roles.AUTH_HO_OPX_HUB,
    roles.AUTH_HO_OPS_MID_MILE_SPV,
    roles.AUTH_TEMP_MASTER_APPROVAL,
    roles.AUTH_HUB_SPV,
    roles.AUTH_ASTRO_GO_SPV,
    roles.AUTH_ASTRO_GO_MANAGER,
  ],
  isDashboard: true,
}

export const MasterShiftRouter: RouterFeatureInterface<'MasterShift', '/dashboard/master-shift'> = {
  name: 'MasterShift',
  Component: MasterShift,
  path: '/dashboard/master-shift',
  routeType: 'PRIVATE',
  allowedRoles: [
    roles.AUTH_DRIVER_COORDINATOR,
    roles.AUTH_CENTRAL_SUPERVISOR,
    roles.AUTH_MARKETING,
    roles.AUTH_HO_HR,
    roles.AUTH_HO_CS_LEADER,
    roles.AUTH_HO_OPX_MID_MILE,
    roles.AUTH_HO_OPS_MID_MILE_SPV,
    roles.AUTH_PRODUCT_MANAGER,
    roles.AUTH_HO_LP,
    roles.AUTH_HO_OPX_HUB,
    roles.AUTH_TEMP_MASTER_APPROVAL,
    roles.AUTH_ASTRO_GO_SPV,
    roles.AUTH_ASTRO_GO_MANAGER,
  ],
  isDashboard: true,
}

const SchedulesRouter = [ScheduleRouter, AttendanceRouter, MasterApprovalRouter, MasterShiftRouter]

export const SchedulesRouterDetail = generateRouterDetail(SchedulesRouter)

export default SchedulesRouter
