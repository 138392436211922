import { useMemo, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector, shallowEqual } from 'store'
import styled from 'styled-components'

import listButton from 'config/sidebar'
import { rolesWhichCanAccessAllPage } from 'constant/roles'
import useOutsideClick from 'customHooks/useOutsideClick'
import useDeviceDetect from 'customHooks/useDeviceDetect'
import { setSidebarOpen } from 'redux/app'
import ButtonSideBar from './ButtonSideBar'

const Sidebar = () => {
  const history = useHistory()

  const sidebarRef = useRef()
  const { authorities, location_type, pages } = useAppSelector(
    ({ auth }) => auth.userData,
    shallowEqual,
  )
  const isSidebarOpen = useAppSelector(({ app: { layout } }) => layout.isSidebarOpen, shallowEqual)

  const dispatchRedux = useAppDispatch()

  const { isDesktop } = useDeviceDetect()

  const handleClickSideBar = (link: string) => {
    history.push(link)
  }

  useOutsideClick(sidebarRef, () => {
    if (isSidebarOpen && !isDesktop) {
      dispatchRedux(setSidebarOpen(false))
    }
  })

  const renderListButtonSidebar = useMemo(
    () => () =>
      listButton.map((el) => {
        const currentUserAuth = authorities ? authorities?.map((items) => items.name) : []

        return (
          <ButtonSideBar
            middleware={!rolesWhichCanAccessAllPage.some((each) => currentUserAuth.includes(each))}
            currentUserAuth={currentUserAuth}
            location_type={location_type}
            key={el.name}
            data={el}
            allowedPageID={pages}
            onClick={handleClickSideBar}
          />
        )
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authorities, pages],
  )

  return (
    <>
      <Container ref={sidebarRef as never} hide isSidebarOpen={isSidebarOpen}>
        {renderListButtonSidebar()}
      </Container>
      <BackdropOverlay isSidebarOpen={!isDesktop && isSidebarOpen} />
    </>
  )
}

export default Sidebar

const Container = styled.div<{
  hide: boolean
  isSidebarOpen: boolean
}>`
  min-width: 241px;
  width: 241px;
  margin-left: ${({ hide, isSidebarOpen }) => (hide && !isSidebarOpen ? '-240px' : '0')};
  background-color: ${({ theme: { colors } }) => colors.white};
  border-right: 1px solid #cad3e1;
  padding: 24px 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
  transition: all ease 0.3s;
  position: fixed;
  z-index: 2;
  overflow: auto;
  height: calc((var(--vh, 1vh) * 100) - 72px);
`

const BackdropOverlay = styled.div<{
  isSidebarOpen: boolean
}>`
  position: fixed;
  left: ${({ isSidebarOpen }) => (isSidebarOpen ? '240px' : '0')};
  width: 100vw;
  height: 100vh;
  background-color: rgba(33, 37, 41, 0.35);
  z-index: 2;
  visibility: ${({ isSidebarOpen }) => (isSidebarOpen ? 'visible' : 'hidden')};
`
