/* eslint-disable @typescript-eslint/naming-convention */
import AxiosImage from 'config/apiServiceImage'
import { ImageVariantIdType as WPEImageVariantIdType } from '@astronautsid/wpe-utils'

export type ImageVariantIdType =
  | 'refund-images'
  | 'promo-widget-banners'
  | 'promo-widget-images'
  | 'product-label'
  | 'loyalty-widget-special'
  | 'loyalty-widget-bg'
  | 'open-graph'
  | 'product-images'
  | 'unloading-incomplete'
  | 'flash-sale-banner'
  | 'category-images'
  | 'homepage-banner'
  | 'homepage-catalog-big'
  | 'homepage-catalog'
  | 'homepage-category'
  | 'homepage-catalog-vertical'
  | 'homepage-catalog-background'
  | 'homepage-banner-category-highlight'
  | 'milestone-home-background'
  | 'infinite-scroll-tabbing-image'
  | WPEImageVariantIdType
  | 'product-placement-new-login-stories'

export type ImageRulesType = {
  typeId: ImageVariantIdType
  maxFileSize: number
  width: {
    min: number
    max: number
  }
  height: {
    min: number
    max: number
  }
  extensions: string[]
}

type GetImageRulesResponseType = {
  data: ImageRulesType
  error: {
    status: boolean
    msg: string
    code: number
  }
}
export type PostImageResponseType = {
  data: {
    images: {
      fileName: string
      filePath: string
      imageUrl: string
      size: {
        width: number
        height: number
      }
      type: 'real' | 'small' | 'medium' | 'large'
    }[]
  }
}
export type PostImageRequestPayload = { file_input: File; created_by: number }

const createFormData = (payload: Record<string, unknown>) => {
  const formData = new FormData()
  Object.entries(payload).forEach((each) => {
    const [key, value] = each
    formData.append(key, value as string | Blob)
  })
  return formData
}

export const getImageRules = (typeId: ImageVariantIdType) =>
  AxiosImage.Get<GetImageRulesResponseType, true>({
    url: `/internal/v1/${typeId}/rules`,
  })

export const postImage = (typeId: ImageVariantIdType, data: PostImageRequestPayload) => {
  const formData = createFormData(data)

  return AxiosImage.Post<PostImageResponseType, true>({
    url: `/internal/v1/${typeId}`,
    data: formData,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  })
}
