import type { ProductType } from 'utils/apiList/stockOpname'
import type { CategoryL1Type } from 'features/Product/@types/Product'

export enum StockOpnameTypeEnum {
  SLOC = 'SLOC',
  SKU = 'SKU',
}

export type LocationListType = {
  location_id: number
  location_name: string
  location_type: string
  name: string
}

export type RackListType = {
  rackStorageId: number
  rackStorageName: string
  rackStorageType: string
}

export type SLOCListType = {
  id: number
  locationId: number
  rackName: string
  position: number
  active: boolean
  isDeleted: boolean
  isEmpty: boolean
  storageEnvironment: { id: number; name: string }
  storageType: { id: number; name: string }
}

export type ProductInventoryType = {
  location_id: number
  sku_number: string
  uom: string
  location_name: string
  product_type: {
    product_type_id: number
    product_type_name: string
  }
  reserved_stock: number
  bom_category: string
  price: string
  is_active: boolean
  product_name: string
  update_price: boolean
  location_type: string
  total_stock: number
  product_id: number
  rack_position: number
  cloud_kitchen: boolean
  rack_name: string
  business_taggings: {
    value: string
    type: string
    id: number
    key: string
  }[]
  stock: number
  discount_stock: number
  is_virtual_bom_component: boolean
  product_image: string
}

export type StockOpnameAddStateType = {
  query: {
    locationId: number
    locationName: string
    showSelectSLOC: boolean
    showSelectSKU: boolean
  }
  previousLocationId: number
  submitParam: {
    date: string
    taskGenerated: number
    SLOCConfiguration: string
    locationType: string
    time: string
    rackStorageType: number[]
    productTypes: number[]
    selectedType: StockOpnameTypeEnum
  }
  rackLocation: {
    listData: RackListType[]
    previousDataList: RackListType[]
    selectedDataList: RackListType[]
  }
  productType: {
    listData: ProductType[]
    selectedDataList: ProductType[]
  }
  productCategory: {
    listData: CategoryL1Type[]
    selectedDataList: CategoryL1Type[]
  }
  locationList: LocationListType[]
  rackEnvironment: RackListType[]
  slocList: SLOCListType[]
  selectedSlocList: SLOCListType[]
  slocListPaginationData: { totalElement: number; pageSize: number }
  isLoading: boolean
  product: {
    productList: ProductInventoryType[]
    selectedProducts: ProductInventoryType[]
    isLoading: boolean
    query: {
      page_index: number
      page_size: number
    }
  }
}
