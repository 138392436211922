import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Modifier } from 'features/Product/@types'

export const SLICE_NAME = 'modifier'

export const initialState: Modifier.InitialStateType = {
  isLoading: false,
  query: {
    page_index: 1,
    page_size: 20,
    search: '',
    sort: 'id',
    direction: 'desc',
  },
  modifier: {
    pagination: null,
    data: [],
  },
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,

  reducers: {
    resetState: () => initialState,
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setQuery: (state, { payload }: PayloadAction<Partial<Modifier.QueryType>>) => {
      state.query = {
        ...state.query,
        ...payload,
      }
    },
    setModifierData: (state, { payload }: PayloadAction<Modifier.InitialStateType['modifier']>) => {
      state.modifier = payload
    },
  },
})

export const { resetState, setIsLoading, setQuery, setModifierData } = slice.actions
export default slice.reducer
