import { createGlobalStyle, css } from 'styled-components'

const injectGlobalStyles = (additionalStyles?: string | ReturnType<typeof css>) => {
  const GlobalStyles = createGlobalStyle`
    body {
      background-color: #F3F6FA;
      overflow: hidden;
    }

    * {
      padding: 0;
      margin: 0;
      font-family: 'Nunito Sans', sans-serif;
      box-sizing: border-box;
      transition: all ease .3s;
    }

    a {
      text-decoration: none;
      :hover{
        text-decoration: none;
      }
      :visited{
        text-decoration: none;
      }
      color: #246EE5;
    }

    *::-webkit-scrollbar {
      width: 1px;
      height: 4px;
      border-radius: 5px;
    }

    *::-webkit-scrollbar-thumb {
      background-color: #BFC9D9;
    }

    .MuiPopover-paper {
      margin-top: 6px;
      border-radius: 8px !important;
      max-height: 324px !important;
    }
   
    ${additionalStyles}
  `

  return { GlobalStyles }
}

export default injectGlobalStyles
