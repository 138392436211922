import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  InitialStateType,
  SetFormDetailPayloadActionType,
  SetProductMappingPayloadActionType,
  ProductMappingType,
  SetGlobalProductCampaignQuotaPayloadActionType,
  SetCustomerProductQuotaPayloadActionType,
  SetErrorFormDetailPayloadActionType,
  SetProductMappingRawPayloadActionType,
} from 'features/Promotion/@types/flexiComboAddEdit'
import {
  CAMPAIGN_SEGMENT_OPTION,
  CAMPAIGN_TYPE_OPTION,
} from 'features/Promotion/constant/flexiComboAddEdit'
import { dateFormat } from 'utils/helpers/date'

import {
  SLICE_NAME,
  fetchDetailFlexiCampaign,
  fetchProductListFlexiCampaign,
  createUpdateFlexiCampaign,
  fetchAllSegment,
} from './thunk'

const initialState: InitialStateType = {
  isLoading: false,
  formDetail: {
    id: 0,
    campaign_name: '',
    campaign_quota: 0,
    campaign_segment: { name: '', value: '' },
    campaign_type: { name: '', value: '' },
    campaign_title: '',
    start_date: '',
    end_date: '',
    start_time: '',
    end_time: '',
    title: '',
    campaign_label: '',
    treshold_qty: 0,
    discount_price: 0,
    discount_percentage: 0,
    discount_percentage_fmt: '',
    final_price: 0,
    customer_campaign_quota: 0,
    product_count: 0,
    is_new_user: false,
    status: '',
    created_at: '',
    updated_at: '',
    created_by: '',
    updated_by: '',
    product_mapping: [],
    quota_campaign: 0,
    user_segment: [],
  },
  errorFormDetail: { campaign_quota: '', discount_percentage: '', treshold_qty: '' },
  productList: [],
  segmentList: [],
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setFormDetail: (state, action: PayloadAction<SetFormDetailPayloadActionType>) => {
      const { name, value } = action.payload

      state.formDetail[name] = value as never
    },
    setErrorFormDetail: (state, action: PayloadAction<SetErrorFormDetailPayloadActionType>) => {
      const { name, value } = action.payload

      state.errorFormDetail[name] = value as never
    },
    setGlobalProductCampaignQuota: (
      state,
      action: PayloadAction<SetGlobalProductCampaignQuotaPayloadActionType>,
    ) => {
      const { value, index } = action.payload

      state.formDetail.product_mapping[index].global_product_campaign_quota = value
    },
    setCustomerProductQuota: (
      state,
      action: PayloadAction<SetCustomerProductQuotaPayloadActionType>,
    ) => {
      const { value, locationIndex, productIndex, name } = action.payload

      state.formDetail.product_mapping[productIndex].location_quota_map[locationIndex][name] =
        value as never
    },
    setProductMapping: (state, action: PayloadAction<SetProductMappingPayloadActionType>) => {
      const { value } = action.payload

      const productMapping: ProductMappingType[] = [...state.formDetail.product_mapping]
      const counterProductId: number[] = []

      value.forEach((el) => {
        const haveSameProductId = counterProductId.some((elSome) => elSome === el.product_id)
        if (haveSameProductId) {
          productMapping.forEach((elEach, elEachIndex) => {
            if (elEach.product_id === el.product_id) {
              productMapping[elEachIndex].location_quota_map = [
                ...productMapping[elEachIndex].location_quota_map,
                {
                  location_id: el.location_id,
                  customer_product_quota: el.customer_product_quota,
                  location_name: el.location_name,
                  original_product_price: el.original_product_price,
                  original_product_price_fmt: el.original_product_price_fmt,
                  status: el.status,
                  stock: el.stock,
                },
              ]
            }
          })
        } else {
          counterProductId.push(el.product_id)
          productMapping.push({
            global_product_campaign_quota: 0,
            product_id: el.product_id,
            product_image_url: el.product_image_url,
            product_name: el.product_name,
            product_sku: el.product_sku,
            location_quota_map: [
              {
                location_id: el.location_id,
                customer_product_quota: el.customer_product_quota,
                location_name: el.location_name,
                original_product_price: el.original_product_price,
                original_product_price_fmt: el.original_product_price_fmt,
                status: el.status,
                stock: el.stock,
              },
            ],
          })
        }
      })
      state.formDetail.product_mapping = productMapping
    },
    setProductMappingRaw: (state, action: PayloadAction<SetProductMappingRawPayloadActionType>) => {
      state.formDetail.product_mapping = action.payload.value
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDetailFlexiCampaign.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchDetailFlexiCampaign.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchDetailFlexiCampaign.fulfilled, (state, actions) => {
        const get_flexi_campaign = actions.payload

        state.formDetail = {
          ...state.formDetail,
          ...get_flexi_campaign,
          end_time: dateFormat({
            date: new Date(get_flexi_campaign.end_time),
            format: 'HH:mm',
            utc: true,
          }),
          start_time: dateFormat({
            date: new Date(get_flexi_campaign.start_time),
            format: 'HH:mm',
            utc: true,
          }),
          start_date: dateFormat({
            date: new Date(get_flexi_campaign.start_date),
            format: 'YYYY-MM-DD',
            utc: true,
          }),
          end_date: dateFormat({
            date: new Date(get_flexi_campaign.end_date),
            format: 'YYYY-MM-DD',
            utc: true,
          }),
          campaign_segment: CAMPAIGN_SEGMENT_OPTION.find(
            (el) => el.value === get_flexi_campaign.campaign_segment,
          ) || { name: '', value: '' },
          campaign_type: CAMPAIGN_TYPE_OPTION.find(
            (el) => el.value === get_flexi_campaign.campaign_type,
          ) || { name: '', value: '' },
          user_segment: get_flexi_campaign.user_segment.map((el) => ({
            id: el.segment_id,
            name: el.segment_name,
            total_user: 0,
          })),
        }

        state.isLoading = false
      })
      .addCase(fetchProductListFlexiCampaign.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchProductListFlexiCampaign.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchProductListFlexiCampaign.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(createUpdateFlexiCampaign.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createUpdateFlexiCampaign.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(createUpdateFlexiCampaign.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchAllSegment.fulfilled, (state, action) => {
        const { data } = action.payload
        state.segmentList = data
      })
  },
})

export const {
  reset,
  setFormDetail,
  setProductMapping,
  setCustomerProductQuota,
  setGlobalProductCampaignQuota,
  setErrorFormDetail,
  setProductMappingRaw,
} = slice.actions
export default slice.reducer
