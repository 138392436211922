import apiServices from 'config/apiServiceApiGateway'
import axios from 'axios'
import { CampaignType, CampaignSegmentType } from 'features/Promotion/@types/flexiComboAddEdit'

const { Get, Post } = apiServices

export type GetDetailFlexiCampaignRequestType = {
  id: string
}

export type GetDetailFlexiCampaignResponseType = {
  data: {
    get_flexi_campaign: {
      is_new_user: boolean
      location_id: number
      campaign_label: string
      products: []
      product_mapping: []
      created_at: string
      updated_at: ''
      user_segment: {
        segment_id: number
        segment_name: string
      }[]
      quota_campaign: number
      treshold_qty: number
      campaign_segment: CampaignSegmentType
      final_price: number
      product_count: number
      campaign_type: CampaignType
      end_time: number
      banner_background_url: string
      location_ids: []
      campaign_title: string
      id: number
      customer_campaign_quota: number
      discount_percentage: number
      status: string
      campaign_name: string
      end_date: number
      start_date: number
      updated_by: string
      title: string
      created_by: string
      start_time: number
    }
  }
}

export const getDetailFlexiCampaign = ({ id }: GetDetailFlexiCampaignRequestType) =>
  Get<GetDetailFlexiCampaignResponseType, true>({
    url: `/campaign/internal/v1/flexi-campaign/detail`,
    params: { product_page_size: -1, product_page_index: 0, flexi_campaign_id: id },
  })

export type GetProductListFlexiCampaignRequestType = {
  id: string
}

export type GetProductListFlexiCampaignResponseType = {
  data: {
    product_id: number
    location_id: number
    product_price: number
    product_sku: number
    product_name: string
    product_image_url: string
    customer_product_quota: number
    global_product_campaign_quota: number
    is_must_include: boolean
    status: string
  }[]
}

export const getProductListFlexiCampaign = ({ id }: GetProductListFlexiCampaignRequestType) =>
  axios.get<GetProductListFlexiCampaignResponseType>(
    `http://localhost:3000/fcEdit/listFlexiCampaign${id}.json`,
  )

export type PostFlexiCampaignRequestType = {
  data: {
    campaign_name: string
    campaign_type: CampaignType
    start_date: number
    end_date: number
    start_time: number
    end_time: number
    id: number
    treshold_qty: number
    final_price: number
    customer_campaign_quota: number
    discount_percentage: number
    quota_campaign: number
    campaign_segment: CampaignSegmentType
    status?: string
    products: {
      product_id: number
      location_id: number
      product_price: number
      product_sku: string
      product_name: string
      product_image_url: string
      customer_product_quota: number
      global_product_campaign_quota: number
      status: string
    }[]
    user_segment: {
      segment_id: number
      segment_name: string
    }[]
    campaign_title: string
  }
}

export const postFlexiCampaign = ({ data }: PostFlexiCampaignRequestType) =>
  Post({ url: `/campaign/internal/v1/flexi-campaign`, data })

export type GetAllSegmentsResponseType = {
  name: string
  id: number
  total_user: number
}[]

export const getAllSegments = () =>
  Get<GetAllSegmentsResponseType>({ url: `/segmentation/internal/v1/segment` })
