import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import { GetCampaignListRequestType } from 'features/CampaignAndDiscount/@types'
import { FetchCampaignListParamsType } from 'features/Promotion/@types/addEditFlashSale'
import { getCampaignList } from 'features/CampaignAndDiscount/services/campaignList'
import {
  postFlashSale,
  putFlashSale,
  getFlashSaleDetail,
} from 'features/Promotion/services/addEditFlashSale'
import { withUtc } from 'utils/helpers/date'

export const SLICE_NAME = 'addEditFlashSale'

const validateParamsValue = (
  key: keyof GetCampaignListRequestType['params'],
  value: string | number | Date | null,
) =>
  value
    ? {
        [key]: value,
      }
    : {}

const parseTimeUnixUTC = (time: string, unixMillisecond = true) => {
  if (unixMillisecond) {
    return withUtc(new Date(time), true).valueOf()
  }
  return withUtc(new Date(time), true).unix()
}

export const fetchCampaignList = createAsyncThunk(
  `${SLICE_NAME}/fetchCampaignList`,
  async (
    { name = '', index = 0, filterTimeBy = 'PERIODE' }: FetchCampaignListParamsType,
    { rejectWithValue, getState },
  ) => {
    const {
      addEditFlashSale: { forms },
    } = getState() as StoreStateType

    const validateFilterTimeBy: (params: 'START' | 'END') => number = (type) => {
      if (filterTimeBy === 'PERIODE') {
        return type === 'START'
          ? parseTimeUnixUTC(`${forms.start_date} ${forms.start_time}`, false)
          : parseTimeUnixUTC(`${forms.end_date} ${forms.end_time}`, false)
      }
      return type === 'START'
        ? parseTimeUnixUTC(`${forms.details[index].date} ${forms.details[index].start_time}`, false)
        : parseTimeUnixUTC(`${forms.details[index].date} ${forms.details[index].end_time}`, false)
    }

    try {
      const res = await getCampaignList({
        params: {
          pageIndex: 0,
          pageSize: 500,
          ...validateParamsValue('name', name),
          ...validateParamsValue('startTime', validateFilterTimeBy('START')),
          ...validateParamsValue('endTime', validateFilterTimeBy('END')),
          campaignType: 'DISCOUNT_CAMPAIGN',
        },
      })
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

const parseDetail = (details: StoreStateType['addEditFlashSale']['forms']['details']) =>
  details.map((el) => ({
    campaign_ids: el.campaign_ids.map((ell) => ell.id),
    date: new Date(`${el.date}`).valueOf(),
    end_time: new Date(`${el.date} ${el.end_time}`).valueOf(),
    start_time: new Date(`${el.date} ${el.start_time}`).valueOf(),
  }))

export const createFlashSale = createAsyncThunk(
  `${SLICE_NAME}/createFlashSale`,
  async (_, { rejectWithValue, getState }) => {
    const {
      addEditFlashSale: { forms },
    } = getState() as StoreStateType

    try {
      const res = await postFlashSale({
        payload: {
          name: forms.name,
          background_url: forms.image.imageUrl,
          end_period: parseTimeUnixUTC(`${forms.end_date} ${forms.end_time}`),
          start_period: parseTimeUnixUTC(`${forms.start_date} ${forms.start_time}`),
          details: parseDetail(forms.details),
        },
      })
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateFlashSale = createAsyncThunk(
  `${SLICE_NAME}/updateFlashSale`,
  async (id: string, { rejectWithValue, getState }) => {
    const {
      addEditFlashSale: { forms },
    } = getState() as StoreStateType

    try {
      const res = await putFlashSale({
        id,
        payload: {
          name: forms.name,
          background_url: forms.image.imageUrl,
          end_period: parseTimeUnixUTC(`${forms.end_time} ${forms.end_date}`),
          start_period: parseTimeUnixUTC(`${forms.start_time} ${forms.start_date}`),
          details: parseDetail(forms.details),
        },
      })
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchFlashSaleDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchFlashSaleDetail`,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await getFlashSaleDetail({ id })
      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
