import React, { memo } from "react";
import styled from "styled-components";
import { TextBody } from "components";

const InputTextArea = ({ label, ...rest }) => {
  return (
    <Wrapper>
      {label && (
        <Label className="label-input">
          <TextBody color="textSoft">{label}</TextBody>
        </Label>
      )}
      <TextArea {...rest} />
    </Wrapper>
  );
};

export default memo(InputTextArea);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextArea = styled.textarea`
  padding: 12px;
  border: 1px solid rgb(191, 201, 217);
  box-sizing: border-box;
  border-radius: 8px;
  min-height: 120px;
  &:focus-visible {
    outline: unset;
  }
  &::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #4285f4;
  }
`;

const Label = styled.div`
  display: inline-block;
  margin-bottom: 7px;
`;
