import { memo, useContext } from 'react'
import styled from 'styled-components'
import logoQr from 'assets/images/logo_qr.svg'
import { TextBody } from 'components'
import store from 'storeContext'
import QRCode from 'react-qr-code'
import dayjs from 'dayjs'

function TemplateCetakQR() {
  const {
    state: { POLiteQRQty, dataPOLiteCetakQR },
  } = useContext(store.PrintAreaContext)

  const nameProduct = () => {
    const maxLength = 74
    if (dataPOLiteCetakQR?.product_name.length > maxLength) {
      return `${dataPOLiteCetakQR?.product_name.substr(0, maxLength)}...`
    } else {
      return dataPOLiteCetakQR?.product_name
    }
  }

  const renderQRCard = () => {
    const tmpData = []

    for (let i = 0; i < POLiteQRQty; i++) {
      tmpData.push(<QRCard nameProduct={nameProduct} index={i + 1} key={i + 'sas'} />)
    }

    return tmpData
  }

  return <Container>{renderQRCard()}</Container>
}

function QRCard({ nameProduct, index }) {
  const {
    state: { dataPOLiteCetakQR, vendorId },
  } = useContext(store.PrintAreaContext)

  return (
    <QRWrapper odd={index % 2}>
      <QRCardContainer>
        <ImageSection>
          <ImageTitleContainer>
            <img src={logoQr} style={{ width: '46px', paddingBottom: '6px' }} />
          </ImageTitleContainer>
          <ImageBodyContainer>
            <QRCode value={dataPOLiteCetakQR?.qr_code} size="46" />
          </ImageBodyContainer>
        </ImageSection>
        <TextSection>
          <ProductContainer>
            <TextBody
              size="smallest"
              style={{ fontSize: '0.4rem' }}
              color="pureBlack"
              weight="normal"
            >
              {nameProduct()}
            </TextBody>
          </ProductContainer>
          <TextBody style={{ fontSize: '0.4rem' }} color="pureBlack" weight="bold">
            ExpDate {dayjs(dataPOLiteCetakQR?.expiry_date).format('DD-MMM-YYYY')}
          </TextBody>

          <TextBody style={{ fontSize: '0.4rem' }} color="pureBlack" weight="light">
            {vendorId ? `VID: ${vendorId}` : `Prod By : ${dataPOLiteCetakQR?.produced_by}`}
          </TextBody>

          {!dataPOLiteCetakQR?.product_relabeled_in_hub && (
            <TextBody style={{ fontSize: '0.4rem' }} color="pureBlack" weight="light">
              {dataPOLiteCetakQR?.qr_code}
            </TextBody>
          )}

          {!dataPOLiteCetakQR?.product_relabeled_in_hub && (
            <TextBody style={{ fontSize: '0.4rem' }} color="pureBlack" weight="light">
              MFG{' '}
              {dataPOLiteCetakQR?.manufacture_date
                ? dayjs(dataPOLiteCetakQR?.manufacture_date).format('DD-MMM-YYYY')
                : '-'}
            </TextBody>
          )}

          <TextBody style={{ fontSize: '0.4rem' }} color="pureBlack" weight="light">
            {dataPOLiteCetakQR?.storage_notes}
          </TextBody>
        </TextSection>
      </QRCardContainer>
    </QRWrapper>
  )
}

export default memo(TemplateCetakQR)

const Container = styled.div`
  display: table;
  flex-wrap: wrap;
  width: 100%;
`

const QRWrapper = styled.div`
  display: inline-block;
  width: ${({ odd }) => {
    return odd === 1 ? `48%` : `49%`
  }};
  margin-left: ${({ odd }) => {
    return odd === 1 ? `2%` : `1%`
  }};
`

const QRCardContainer = styled.div`
  display: flex;
`

const ImageSection = styled.div`
  display: flex;
  flex-direction: column;
`

const ImageTitleContainer = styled.div`
  display: flex;
`
const ImageBodyContainer = styled.div``

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 60px;
  padding-left: 8px;
  padding-right: 12px;
`

const ProductContainer = styled.div`
  height: 30%;
  box-sizing: border-box;
`
