import dayjs from 'dayjs'
import logo from 'assets/images/logo-black.png'
import getDataUri from './getDataUri'
import textToBase64Barcode from './textToBase64'
import { toastFailed } from './toast'

export const printPickListDocument = (picker) => {
  if (!window.pdfMake) {
    toastFailed('PdfMake is not ready')
    return
  }

  const { pdfMake } = window
  getDataUri(logo, function (dataUri) {
    const barcode = textToBase64Barcode(picker.order_invoice)
    const orderCreatedAt = dayjs(picker.order_created_at).format('DD MMM YYYY, HH:mm:ss')
    const headerTable = ['RAK', 'NOMOR SKU', 'NAMA PRODUK', 'QTY']
    const dataTable = picker.order_line_items.map((each) => {
      const {
        order_product_name,
        order_product_rack,
        order_product_quantity,
        order_product_sku,
        cloud_kitchen,
        brand_name,
      } = each
      return [
        {
          border: [true, false, true, true],
          text: (cloud_kitchen ? `  ${brand_name}  ` : order_product_rack) || '-',
          style: cloud_kitchen ? 'blockedText' : 'tableText',
        },
        {
          border: [false, false, true, true],
          text: order_product_sku || '-',
          style: 'tableText',
        },
        {
          border: [false, false, true, true],
          text: order_product_name || '-',
          style: 'tableText',
        },
        {
          border: [false, false, true, true],
          text: order_product_quantity || 0,
          style: 'tableText',
        },
      ]
    })
    const bodyDataIntoTable = [headerTable, ...dataTable]
    const pageMargins = picker.order_note?.length >= 180 ? 165 : 130
    const documentDefinition = {
      pageSize: 'A4',
      pageMargins: [20, pageMargins, 20, 20],
      pageOrientation: 'portrait',
      footer: function (currentPage, pageCount) {
        return {
          columns: [
            {
              fontSize: 9,
              text: [
                {
                  text: `${picker.order_invoice} | ${currentPage.toString()} of ${pageCount}`,
                },
              ],
              alignment: 'center',
            },
          ],
        }
      },
      header: {
        alignment: 'justify',
        margin: [20, 10, 20, 20],
        columns: [
          [
            {
              columns: [
                [
                  {
                    image: dataUri,
                    width: 80,
                    margin: [0, 10, 0, 0],
                  },
                  {
                    style: 'header',
                    text: picker.order_invoice,
                    margin: [0, 10, 0, 0],
                  },
                  {
                    text: orderCreatedAt,
                    margin: [0, 5, 0, 0],
                  },
                ],
                {
                  image: barcode,
                  width: 150,
                  height: 50,
                },
              ],
            },
            {
              text: `Catatan Pembeli: ${picker.order_note || '-'}`,
              margin: [0, 5, 0, 0],
              alignment: 'left',
            },
          ],
        ],
      },
      content: [
        {
          style: 'tableContent',
          table: {
            dontBreakRows: true,
            headerRows: 1,
            widths: [90, 110, 280, 40],
            body: bodyDataIntoTable,
          },
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        tableContent: {
          margin: [0, 0, 0, 15],
          alignment: 'center',
        },
        tableText: {
          margin: [0, 5, 0, 10],
        },
        blockedText: {
          margin: [0, 5, 0, 10],
          background: '#000000',
          color: '#FFFFFF',
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black',
        },
      },
    }
    pdfMake.createPdf(documentDefinition).print({}, window.frames['printPdf'])
    /**
         *  Safari
            pdfMake.createPdf(content).open({}, window.frames['printPdf']);
                setTimeout(function() {
                window.frames['printPdf'].focus();
                window.frames['printPdf'].print();
            }, 2000)
         */
  })
}

export const printCloudKitchenModifierLabel = async (order, productQty) => {
  if (!window.pdfMake) {
    toastFailed('PdfMake is not ready')
    return
  }

  const { pdfMake } = window
  let receiptAstroKitchen = []

  const orderProductNameParser = (productName, i, withModifier) => {
    let productNameParsed = productName
    const MAX_WORD_LENGTH = withModifier ? 35 : 55
    if (productName.length > MAX_WORD_LENGTH) {
      productNameParsed = productName.substr(0, MAX_WORD_LENGTH) + '...'
    }
    const splitProductNameBy = productName.split('-')
    const lastWord = splitProductNameBy[splitProductNameBy.length - 1]
    const variant = lastWord.toLowerCase().includes('large')
      ? 'LARGE'
      : lastWord.toLowerCase().includes('regular')
      ? 'REGULAR'
      : ''
    let stack = [
      {
        text: productNameParsed,
        fontSize: 9,
        bold: true,
        margin: [0, 7, 0, variant !== '' ? 0 : 4],
        ...(i !== 0 && { pageBreak: 'before' }),
      },
    ]
    if (variant !== '') {
      stack = [
        ...stack,
        {
          table: {
            widths: 'auto',
            body: [
              [
                {
                  border: [true, true, true, true],
                  text: variant,
                  bold: true,
                  style: {
                    fontSize: 5,
                    padding: 1,
                  },
                },
              ],
            ],
          },
          margin: [0, 2, 0, 4],
        },
      ]
    }
    return stack
  }
  const modifiersParser = (modifiersList) => {
    const catatan = {
      text: `Catatan (${modifiersList.length})`,
      fontSize: 5,
      bold: true,
      margin: [0, 0, 0, 2],
    }
    const listModifiers = []
    modifiersList.slice(0, 5).map((variant) => {
      let variantTypeParsed = variant.modifier_variants.map(
        (variantType, i) =>
          `${variantType.name} ${i === variant.modifier_variants.length - 1 ? '' : ', '}`,
      )
      if (variantTypeParsed[0].length > 20) {
        variantTypeParsed[0] = variantTypeParsed[0].substr(0, 20) + '...'
      }
      listModifiers.push([
        {
          text: `${variant.name}: ${variantTypeParsed}`,
          margin: [0, 0, 0, 2],
        },
      ])
    })
    if (modifiersList.length === 0) return [catatan]
    return [
      catatan,
      {
        ul: listModifiers,
        fontSize: 5,
      },
    ]
  }
  const addOnsParser = (addOns) => {
    const TOPPING_CHAR_LIMIT = 43
    const AddOnsOverFlowInfo = {
      stack: [
        { text: '- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -' },
        {
          text: '* CATATAN LENGKAP LIHAT APLIKASI *',
          bold: true,
          italics: true,
          margin: [5, 0, 0, 0],
        },
      ],
      style: {
        fontSize: 5,
      },
    }
    let topping = addOns
      .map((addOn) => addOn.modifier_variants.map((variant) => variant.name).join(','))
      .join(',')
    const tambahan = {
      text: `Tambahan (${addOns.length})`,
      fontSize: 5,
      bold: true,
      margin: [0, 0, 0, 2],
    }
    if (addOns.length === 0)
      return {
        stack: [tambahan],
        absolutePosition: { x: 5, y: 90 },
      }

    if (topping.length >= TOPPING_CHAR_LIMIT) {
      topping = topping.substr(0, TOPPING_CHAR_LIMIT) + '...'
    }
    let stackParsed = [
      tambahan,
      {
        text: topping,
        margin: [0, 0, 0, 2],
        fontSize: 5,
      },
    ]
    if (topping.length >= TOPPING_CHAR_LIMIT) {
      stackParsed = [...stackParsed, AddOnsOverFlowInfo]
    }
    return {
      stack: stackParsed,
      absolutePosition: topping.length >= TOPPING_CHAR_LIMIT ? { x: 5, y: 83 } : { x: 5, y: 90 },
    }
  }

  const CustomerInfoAndQR = (customer, itemId, orderId, customerNote) => {
    return {
      stack: [
        {
          text: '- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -',
          fontSize: 5,
        },
        {
          columns: [
            {
              stack: [
                { text: `${customer}`, bold: true },
                { text: `Item ID: ${itemId}` },
                { text: `Order ID: ${orderId}` },
              ],
              style: {
                fontSize: 6,
              },
            },
            { qr: `kitchen-${itemId}`, fit: 42, alignment: 'center' },
          ],
        },
      ],
      style: {
        margin: [2, 0, 0, 0],
      },
      absolutePosition: { x: 5, y: 110 },
    }
  }

  order.forEach((item, index) => {
    const { customer, customerNote, modifiers, productName, itemId, orderId, addOns } = item

    receiptAstroKitchen = [
      ...receiptAstroKitchen,
      orderProductNameParser(productName, index, modifiers.length > 0),
      ...modifiersParser(modifiers),
      addOnsParser(addOns),
      CustomerInfoAndQR(customer, itemId, orderId, customerNote),
    ]
  })

  const documentDefinition = {
    pageSize: { width: 113.386, height: 170.079 },
    pageMargins: [5, 5, 5, 5],
    pageOrientation: 'portrait',
    content: receiptAstroKitchen,
  }

  pdfMake.createPdf(documentDefinition).print({}, window.frames['printPdf'])
}

export const printCloudKitchenLabel = async (order, productQty) => {
  if (!window.pdfMake) {
    toastFailed('PdfMake is not ready')
    return
  }

  const { pdfMake } = window
  let content = []
  order.forEach((d, index) => {
    const { invoice, customer, sku, name, product_note, page, total } = d
    const barcode = textToBase64Barcode(sku)
    content = [
      ...content,
      {
        columns: [
          [
            {
              fontSize: 5,
              margin: [0, 0, 10, 5],
              width: 100,
              text: invoice,
              pageBreak: index === 0 ? '' : 'before',
            },
            {
              fontSize: 8,
              bold: true,
              margin: [0, 0, 10, 5],
              text: customer,
            },
            {
              image: barcode,
              width: 80,
              margin: [-3, 0, 0, 5],
            },
            {
              fontSize: 5,
              margin: [0, 0, 2, 5],
              text: `SKU: ${sku}`,
            },
            {
              fontSize: 6,
              bold: true,
              margin: [0, 0, 150, 5],
              text: `${page} / ${total}`,
            },
            {
              fontSize: 8,
              bold: true,
              margin: [0, 0, 210, 5],
              text: name,
            },
            {
              fontSize: 5,
              bold: true,
              margin: [0, 0, 210, 5],
              text: product_note,
            },
          ],
        ],
      },
    ]
  })

  const documentDefinition = {
    pageSize: 'A6',
    pageMargins: [5, 10, 5, 5],
    pageOrientation: 'portrait',
    content: content,
  }

  pdfMake.createPdf(documentDefinition).print({}, window.frames['printPdf'])
}

export const printPDF = async ({
  pageSize = 'A5',
  pageMargins = [24, 24, 24, 24],
  pageOrientation = 'portrait',
  content = [],
  styles = {},
}) => {
  try {
    if (!window.pdfMake) {
      toastFailed('PdfMake is not ready')
      return
    }

    const { pdfMake } = window
    const documentDefinition = {
      pageSize,
      pageMargins,
      pageOrientation,
      content,
      styles,
    }

    pdfMake.createPdf(documentDefinition).print({}, window.frames['printPdf'])
  } catch {
    // do nothing
  }
}
