const newDispatch = (dispatch, state) => (action) => {
  return newThunk(action, dispatch, state);
};

const newThunk = (action, dispatch, state) => {
  const getState = () => {
    return state;
  };
  if (action === undefined) return false;
  typeof action === "function"
    ? action(newDispatch(dispatch, state), getState)
    : dispatch(action);
};

export default newThunk;
