import {
  AccordionDetails as MuiAccordionDetails,
  AccordionDetailsProps as MuiAccordionDetailsProps,
} from '@mui/material'
import styled from 'styled-components'

export type AccordionDetailsPropsType = MuiAccordionDetailsProps

const AccordionDetails = styled(MuiAccordionDetails)<AccordionDetailsPropsType>``

export default AccordionDetails
