import { memo } from 'react'
import S from './index.styles'

const NoDataContainer = ({ title, description, withIcon }) => (
  <S.NoDataWrapper>
    <S.NoDataStyled withAstroIcon={withIcon} TextHeading={title} TextDescription={description} />
  </S.NoDataWrapper>
)

NoDataContainer.defaultProps = {
  withIcon: false,
  description: '',
  title: 'Belum ada data',
}

export default memo(NoDataContainer)
