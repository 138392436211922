import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getRolesAPI,
  getUserDetailAPI,
  getUserListAPI,
  patchActivateAccountAPI,
  patchDeactivateAccountAPI,
  postUserAPI,
  putResetAccountAPI,
  putUserAPI,
} from 'features/Account/services/user'
import { serializeQueryToURL } from 'utils/queryParamsURL'
import { toastSuccess } from 'utils/toast'
import { getLocationDetail } from 'features/Location/services/location'
import type { AppDispatchType } from 'store'

export const SLICE_NAME = 'ADMIN_ACCESS/user'

export const fetchGetLocationSelected = createAsyncThunk(
  `${SLICE_NAME}/fetchGetLocationSelected`,
  async (locationIds: number[], { rejectWithValue }) => {
    try {
      const locationPromises = locationIds.map((locationId) => getLocationDetail(locationId))
      const res = await Promise.all(locationPromises)

      return res.map(({ data }) => ({
        location_id: data.location_id,
        location_name: data.location_name,
        location_type: data.location_type,
      }))
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchGetUserList = createAsyncThunk(
  `${SLICE_NAME}/fetchGetUserList`,
  async (params: Parameters<typeof getUserListAPI>[0], { rejectWithValue }) => {
    try {
      const res = await getUserListAPI(params)

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchGetUserDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchGetUserDetail`,
  async (userId: Parameters<typeof getUserDetailAPI>[0], { rejectWithValue, dispatch }) => {
    try {
      const res = await getUserDetailAPI(userId)

      if (res.data.data.roles.length) {
        const locationIds = res.data.data.roles[0].location_ids
        dispatch(fetchGetLocationSelected(locationIds))
      }

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const actGetUserList =
  (params?: Parameters<typeof getUserListAPI>[0]) => (dispatch: StoreDispatchType) => {
    const qs = serializeQueryToURL({ page_index: 0, ...params })
    dispatch(fetchGetUserList(qs))
  }

export const fetchPostUser = createAsyncThunk(
  `${SLICE_NAME}/fetchPostUser`,
  async (callback: (() => void) | undefined, { rejectWithValue, getState, dispatch }) => {
    try {
      const { userAdminAccess } = getState() as StoreStateType
      const { form } = userAdminAccess

      const payload: Parameters<typeof postUserAPI>[0] = {
        name: form.name,
        email: form.email,
        phone_number: form.phoneNumber,
        position: form.position,
        locations: form.locations.map((item) => ({
          id: item.location_id as number,
        })),
        roles: [
          {
            id: form.roles?.id as number,
          },
        ],
        is_super_admin: false,
      }

      const res = await postUserAPI(payload)

      if (callback) {
        callback()
      }

      ;(dispatch as AppDispatchType)(actGetUserList())

      toastSuccess(`User ${form.name} berhasil ditambahkan`)
      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchPutUser = createAsyncThunk(
  `${SLICE_NAME}/fetchPutUser`,
  async (callback: (() => void) | undefined, { rejectWithValue, getState, dispatch }) => {
    try {
      const { userAdminAccess } = getState() as StoreStateType
      const {
        form,
        feature: {
          drawerAddAccount: { data },
        },
      } = userAdminAccess

      const mapIdsToStatus = (
        currentIds: number[],
        selectedItems: number[],
      ): {
        id: number
        is_deleted: boolean
      }[] => {
        const selectedIds = new Set(selectedItems)

        return Array.from(new Set([...currentIds, ...selectedItems])).map((id) => ({
          id,
          is_deleted: !selectedIds.has(id),
        }))
      }

      const payload: Parameters<typeof putUserAPI>[0]['payload'] = {
        name: form.name,
        email: form.email,
        phone_number: form.phoneNumber,
        position: form.position,
        locations: mapIdsToStatus(
          data?.roles.length ? data.roles[0].location_ids : [],
          form.locations.map((item) => item.location_id),
        ),
        roles: mapIdsToStatus(
          data?.roles.length ? [data.roles[0].id] : [],
          form.roles ? [form.roles.id] : [],
        ),
        is_super_admin: false,
      }

      const res = await putUserAPI({
        id: form.id as number,
        payload,
      })

      if (callback) {
        callback()
      }

      ;(dispatch as AppDispatchType)(actGetUserList())

      toastSuccess(`User ${form.name} berhasil diubah`)
      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchPutResetAccount = createAsyncThunk(
  `${SLICE_NAME}/fetchPutResetAccount`,
  async (
    data: {
      payload: { phoneNumber: string }
      callback?: () => void
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { payload, callback } = data
      const res = await putResetAccountAPI({
        phone_number: payload.phoneNumber,
      })

      if (callback) {
        callback()
      }

      ;(dispatch as AppDispatchType)(actGetUserList())

      toastSuccess(`Akun Berhasil di Reset`)
      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchPatchActivateAccount = createAsyncThunk(
  `${SLICE_NAME}/fetchPatchActivateAccount`,
  async (
    params: {
      userId: number
      name: string
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { userId, name } = params
      const res = await patchActivateAccountAPI(userId)

      ;(dispatch as AppDispatchType)(actGetUserList())
      toastSuccess(`User ${name} berhasil diaktifkan`)
      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchPatchDeactivateAccount = createAsyncThunk(
  `${SLICE_NAME}/fetchPatchDeactivateAccount`,
  async (
    payload: {
      params: {
        userId: number
        name: string
      }
      callback?: () => void
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { params, callback } = payload
      const { userId, name } = params
      const res = await patchDeactivateAccountAPI(userId)

      ;(dispatch as AppDispatchType)(actGetUserList())
      if (callback) {
        callback()
      }
      toastSuccess(`User ${name} berhasil dinon-aktifkan`)
      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchGetRoles = createAsyncThunk(
  `${SLICE_NAME}/fetchGetRoles`,
  async (params: Parameters<typeof getRolesAPI>[0], { rejectWithValue }) => {
    try {
      const res = await getRolesAPI(params)
      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)
