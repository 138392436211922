export const handleCheckHub = (data, item, keyObj, existingId = []) => {
  const result = [];
  let selectedId = existingId;
  let countCheck = 0;
  data.forEach((el) => {
    if (el[keyObj] === item[keyObj]) {
      result.push({ ...el, checked: !el.checked });
      if (!el.checked) {
        countCheck++;
        if (!selectedId.includes(el[keyObj])) {
          selectedId.push(el[keyObj]);
        }
      } else {
        selectedId = selectedId.filter((elc) => elc !== el[keyObj]);
      }
    } else {
      if (el.checked) {
        countCheck++;
        if (!selectedId.includes(el[keyObj])) {
          selectedId.push(el[keyObj]);
        }
      }
      result.push(el);
    }
  });
  return {
    data: result,
    checkAll: countCheck === data.length,
    selectedId
  };
};

export const handleCheckSo = (products, item, existingCheck = []) => {
  const result = [];
  let selectedProductsUpdated = [...existingCheck];
  let countCheck = 0;
  products.forEach((el) => {
    if (el.productDetailId === item.productDetailId) {
      result.push({ ...item, checked: !el.checked });
      if (!el.checked) {
        countCheck++;
      }
    } else {
      if (el.checked) {
        countCheck++;
      }
      result.push(el);
    }
  });
  if (!item.checked) {
    selectedProductsUpdated.push({ ...item, checked: !item.checked });
  } else {
    selectedProductsUpdated = selectedProductsUpdated.filter(
      (el) => el.productDetailId !== item.productDetailId
    );
  }
  return {
    data: result,
    checkAll: countCheck === products.length,
    selectedProductsUpdated
  };
};
