/* eslint-disable no-undef */
import axios from 'axios'

const { REACT_APP_BASE_URL_API } = process.env
const baseUrl = REACT_APP_BASE_URL_API

export type GetSupplyOrderPickingRequestType = {
  id: number
}

export type GetSupplyOrderPickingResponseType = {
  data: {
    picking_activity: {
      name: string
      activity: string
      duration: string
      status: string
    }[]
    singleKoli: boolean
    supply_order_created_by: string
    supply_order_date: string
    supply_order_destination: string
    supply_order_number: string
    supply_order_origin: string
    supply_order_items: {
      supply_order_item_id: number
      product_name: string
      product_sku: string
      product_rack: string
      product_request_quantity: number
      outbound_picker_quantity: number
      product_destination_rack: string
      pickface: string
      product_expiry_date: number
    }[]
  }
}

export type PutUpdateQtyRequestType = {
  soId: number
  soItemId: number
  payload: {
    outboundPickerQuantity: number
  }
}

export type PutUpdateQtyResponseType = {
  data: {
    supplyOrderId: number
    outboundPickerQuantity: number
  }
}

export type PutStartPickingRequestType = {
  id: number
}

export type PutCompletePickingRequestType = {
  id: number
}

export type PutCompletePickingResponseType = {
  data: {
    supplyOrderId: number
    outboundPickerQuantity: number
  }
}

export const getSupplyOrderGrandData = (id: GetSupplyOrderPickingRequestType['id']) =>
  axios.get(`${baseUrl}/api/supply-order-grand/${id}/data`, {
    params: {
      action: 'ambil-barang',
    },
  })

export const putUpdateQty = ({ soId, soItemId, payload }: PutUpdateQtyRequestType) =>
  axios.put(
    `${baseUrl}/api/supply-order-grand/${soId}/${soItemId}/outbound-picker-quantity`,
    payload,
  )

export const putCompletePicking = ({ id }: PutCompletePickingRequestType) =>
  axios.put(`${baseUrl}/api/supply-order-grand/${id}/complete-picking`)

export const putStartPicking = ({ id }: PutStartPickingRequestType) =>
  axios.put(`${baseUrl}/api/supply-order-grand/${id}/start-picking`)
