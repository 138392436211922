import { lazy } from 'react'

import generateRouterDetail from 'utils/helpers/generateRouterDetail'

const CampaignList = lazy(() => import('../pages/CampaignList'))
const CampaignSegmentList = lazy(() => import('../pages/CampaignSegmentList'))

export const CampaignListRouter: RouterFeatureInterface<
  'CampaignListRouter',
  '/dashboard/campaign'
> = {
  name: 'CampaignListRouter',
  Component: CampaignList,
  path: '/dashboard/campaign',
  routeType: 'PRIVATE',
  allowedRoles: [
    'AUTH_MARKETING',
    'AUTH_HO_FRAUD',
    'AUTH_HO_SOURCING_MANAGERIAL_LEVEL',
    'AUTH_HO_CAMPAIGN',
    'AUTH_HO_PRODUCT_MANAGER',
    'AUTH_HO_QUALITY_ENGINEERING',
  ],
  isDashboard: true,
}

export const CampaignSegmentListRouter: RouterFeatureInterface<
  'CampaignSegmentListRouter',
  '/dashboard/campaign/segment'
> = {
  name: 'CampaignSegmentListRouter',
  Component: CampaignSegmentList,
  path: '/dashboard/campaign/segment',
  routeType: 'PRIVATE',
  allowedRoles: [
    'AUTH_MARKETING',
    'AUTH_HO_CAMPAIGN',
    'AUTH_HO_PRODUCT_MANAGER',
    'AUTH_HO_QUALITY_ENGINEERING',
  ],
  isDashboard: true,
}

const CampaignAndDiscountRouter = [CampaignListRouter, CampaignSegmentListRouter]

export const CampaignAndDiscountRouterDetail = generateRouterDetail(CampaignAndDiscountRouter)

export default CampaignAndDiscountRouter
