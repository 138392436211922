import { memo } from 'react'
import styled from 'styled-components'
import { Box } from '@astro-ui/components'

import { Title } from 'components'
import logo from 'assets/images/logo.png'

function AuthContainer({ children, title }) {
  return (
    <Wrapper>
      <Logo src={logo} alt="logo" />
      <Container
        width={{
          mobile: '100%',
          tablet: '415px',
        }}
        p={{
          mobile: '32px 12px',
          tablet: '32px',
        }}
      >
        <Title>{title}</Title>
        <div className="mt-24">{children}</div>
      </Container>
    </Wrapper>
  )
}

AuthContainer.defaultProps = {
  title: 'title',
}

export default memo(AuthContainer)

const Container = styled(Box)`
  margin: 0 12px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 6px;
  box-shadow: 2px 4px 16px 0px rgba(228, 234, 243, 1);
  border-top: 5px solid ${({ theme: { colors } }) => colors.main};
  position: relative;
  h2 {
    text-align: center;
  }
`

const Logo = styled.img`
  width: 202px;
  margin-bottom: 32px;
`

const Wrapper = styled.div`
  width: 100%;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
