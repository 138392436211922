import { memo } from "react";
import styled from "styled-components";
import { NoData, RowDetailPackage } from "components";
import { shallowEqual, useSelector } from "react-redux";

function TableDetailPackage({
  data,
  className,
  handleClickEdit,
  handleDelete,
  handleToggleSellingUnit
}) {
  const { isSidebarOpen } = useSelector(
    (state) => state.app.layout,
    shallowEqual
  );

  return (
    <>
      <WrapperTable isExpand={isSidebarOpen}>
        <Container cellspacing="0" className={className}>
          <thead>
            <Tr>
              <Th width="120px" isSticky left="0">
                Quantity
              </Th>
              <Th width="120px" isSticky left="74px">
                Packaging
              </Th>
              <Th width="150px" isSticky left="155px">
                Barcode
              </Th>
              <Th width="80px">Height</Th>
              <Th width="80px">Length</Th>
              <Th width="90px">Width</Th>
              <Th width="80px">UOM</Th>
              <Th width="120px">Price</Th>
              <Th>Volume</Th>
              <Th width="150px">UOM</Th>
              <Th>Net Weight</Th>
              {/* // */}
              <Th>Gross Weight</Th>
              <Th>UOM</Th>
              <Th>Aksi</Th>
              <Th>Selling Units</Th>
            </Tr>
          </thead>
          <tbody>
            {data.map((el) => (
              <RowDetailPackage
                key={el.id}
                data={el}
                handleClickEdit={handleClickEdit}
                handleDelete={handleDelete}
                handleToggleSellingUnit={handleToggleSellingUnit}
              />
            ))}
          </tbody>
        </Container>

        {data.length === 0 && <NoData />}
      </WrapperTable>
    </>
  );
}

TableDetailPackage.defaultProps = {
  data: []
};

export default memo(TableDetailPackage);

const WrapperTable = styled.div`
  overflow: scroll;
  max-width: ${({ isExpand }) =>
    isExpand ? "calc(100vw - 300px)" : "calc(100vw - 64px)"};
  transition: all ease 0.3s;
`;

const Container = styled.table`
  width: 100%;
  border-collapse: collapse;
  height: fit-content;
  border: 1px solid #d6dfeb;
  border-radius: 8px;
  border-spacing: 0;
  border-collapse: collapse;
`;
const Tr = styled.tr`
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`;
const Th = styled.th`
  ::after {
    content: "";
    background-color: ${({ theme: { colors } }) => colors.tableHead};
    height: 20px;
    position: absolute;
    width: 2px;
    right: -1px;
  }
  color: ${({ theme: { colors } }) => colors.softText};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.toast};
  text-align: start;
  padding: 16px 12px;
  white-space: nowrap;
  /* width: ${({ width }) => width || "100px"}; */
  background-color: ${({ theme: { colors } }) => colors.tableHead};
  ${({ isSticky, left }) =>
    isSticky && {
      position: "sticky",
      left: left
    }}
`;
