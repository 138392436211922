import { createAsyncThunk } from '@reduxjs/toolkit'
import { getVoucherList } from 'utils/apiList/referralMission'
import { getVoucherPriority, postVoucherPriority } from 'utils/apiList/promotion'
import type { PostVoucherPriorityRequestType } from 'utils/apiList/promotion'
import { callErrorMsg } from 'helpers/errorMsg'

export const SLICE_NAME = 'PromotionList'

export type FetchVoucherListParamsType = { code?: string }

export const fetchVoucherList = createAsyncThunk(
  `${SLICE_NAME}/fetchVoucherList`,
  async ({ code = '' }: FetchVoucherListParamsType, { rejectWithValue }) => {
    try {
      const res = await getVoucherList({ params: { code } })

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)
export const fetchVoucherPriority = createAsyncThunk(
  `${SLICE_NAME}/fetchVoucherPriority`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getVoucherPriority()

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const updateVoucherPriority = createAsyncThunk(
  `${SLICE_NAME}/updateVoucherPriority`,
  async (_, { rejectWithValue, getState }) => {
    const {
      promotionList: {
        popupVocherPriority: { voucherPriorityList },
      },
    } = getState() as StoreStateType
    try {
      const validatedPayload: PostVoucherPriorityRequestType['payload']['data'] =
        voucherPriorityList.map((el, i) => ({ voucherId: el.id, voucherPriority: i + 1 }))
      const res = await postVoucherPriority({ payload: { data: validatedPayload } })

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)
