import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const SupplyOrderListRBACPage = {
  pageID: '2e1488e90f376b559e358c0ed426ea66',
  elementID: {
    ViewOnly: 'eceaadd09f718d0b29270ee3f317c09a',
  },
} as const

export const useSupplyOrderListRBACPage = createUseRBAC(SupplyOrderListRBACPage.elementID)

export type UseSupplyOrderListRBACPageType = ReturnType<typeof useSupplyOrderListRBACPage>
