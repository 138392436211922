import {
  AUTH_CS_CAPTAIN,
  AUTH_MARKETING,
  AUTH_PRODUCT_MANAGER,
  AUTH_QUALITY_ENGINEERING,
  AUTH_HO_CAMPAIGN,
  AUTH_HO_PRODUCT_MANAGER,
  AUTH_HO_QUALITY_ENGINEERING,
} from 'middleware/privateRoute'

const promo = {
  addVoucher: [
    AUTH_CS_CAPTAIN,
    AUTH_MARKETING,
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_HO_CAMPAIGN,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
  ],
  toggleStatus: [
    AUTH_CS_CAPTAIN,
    AUTH_MARKETING,
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_HO_CAMPAIGN,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
  ],
  edit: [
    AUTH_CS_CAPTAIN,
    AUTH_MARKETING,
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_HO_CAMPAIGN,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
  ],
  setPriority: [
    AUTH_CS_CAPTAIN,
    AUTH_MARKETING,
    AUTH_PRODUCT_MANAGER,
    AUTH_QUALITY_ENGINEERING,
    AUTH_HO_CAMPAIGN,
    AUTH_HO_PRODUCT_MANAGER,
    AUTH_HO_QUALITY_ENGINEERING,
  ],
}

export default promo
