import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { dateFormat, dateManipulation } from 'utils/helpers/date'

import {
  type LocationType,
  type MPBudgetType,
  type FetchMPBudgetParamsType,
} from 'features/Enterprise/HRIS/services'

export type PopUpType = 'edit' | 'confirmation' | 'export' | 'download' | 'filter' | ''

export interface MPBudgetInitialStateInterface {
  isLoading: boolean
  locationList: LocationType[]
  popUpType: PopUpType
  budgetList: MPBudgetType[]
  selectedPopUpEditData: MPBudgetType
  query: FetchMPBudgetParamsType
  hubList: LocationType[]
  whList: LocationType[]
}

export const sliceName = 'mpBudget'

const initialState: MPBudgetInitialStateInterface = {
  isLoading: false,
  locationList: [],
  hubList: [],
  whList: [],
  popUpType: '',
  budgetList: [],
  selectedPopUpEditData: {} as MPBudgetType,
  query: {
    start_date: dateFormat({
      date: dateManipulation(new Date()).subtract(6, 'day').toDate(),
      format: 'YYYYMMDD',
    }),
    end_date: dateFormat({
      date: new Date(),
      format: 'YYYYMMDD',
    }),
  },
}

const appSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    reset: () => initialState,
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setLocationList: (
      state,
      action: PayloadAction<{
        locations: LocationType[]
        type: string
      }>,
    ) => {
      if (action.payload.type === 'hub') state.hubList = action.payload.locations
      else state.whList = action.payload.locations
    },
    setPopUpType: (state, action: PayloadAction<PopUpType>) => {
      state.popUpType = action.payload
    },
    setSelectedPopUpEditData: (state, action: PayloadAction<MPBudgetType>) => {
      state.selectedPopUpEditData = action.payload
    },
    setBudgetList: (state, action: PayloadAction<MPBudgetType[]>) => {
      state.budgetList = action.payload
    },
    setQuery: (state, action: PayloadAction<FetchMPBudgetParamsType>) => {
      state.query = action.payload
    },
  },
})

export const {
  setIsLoading,
  setLocationList,
  setPopUpType,
  setBudgetList,
  setSelectedPopUpEditData,
  setQuery,
} = appSlice.actions

export default appSlice.reducer
