import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  UserFraudStatusType,
  SetQueryPayloadActionType,
} from 'features/Fraud/@types/userFraudStatus'
import {
  fetchCampaignTypeList,
  fetchRejectOrderReasons,
  SLICE_NAME,
  patchFraudStatus,
} from './thunk'

const initialState: UserFraudStatusType = {
  isLoading: false,
  query: { pageIndex: 0, pageSize: 20, direction: '', filter: '', sort: '', customer_id: '' },
  userFraudList: [],
  popUpFraudAction: false,
  reasonRejectList: [],
  fraudActionCustomerId: 0,
}

const campaignSegment = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    reset: () => initialState,
    setQuery: (state, action: PayloadAction<SetQueryPayloadActionType>) => {
      const { name, value } = action.payload
      state.query[name] = value as never
    },
    setPopUpFraudOpen: (state, action: PayloadAction<boolean>) => {
      state.popUpFraudAction = action.payload
    },
    setFraudActionCustomerId: (state, action: PayloadAction<number>) => {
      state.fraudActionCustomerId = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCampaignTypeList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchCampaignTypeList.fulfilled, (state, action) => {
        state.isLoading = false
        state.userFraudList = action.payload.customer_info.map((el) => ({
          ...el,
          uniqueKey: window.crypto.randomUUID(),
        }))
      })
      .addCase(fetchCampaignTypeList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchRejectOrderReasons.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchRejectOrderReasons.fulfilled, (state, action) => {
        state.isLoading = false
        state.reasonRejectList = [...action.payload.data.reasons, `Other`]
      })
      .addCase(fetchRejectOrderReasons.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(patchFraudStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(patchFraudStatus.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(patchFraudStatus.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const { setIsLoading, reset, setQuery, setPopUpFraudOpen, setFraudActionCustomerId } =
  campaignSegment.actions
export default campaignSegment.reducer
