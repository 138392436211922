import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  GetCategoriesResponseType,
  GetReplenishmentScheduleListRequestType,
  GetReplenishmentScheduleListResponseType,
  GetWarehouseLocationsResponseType,
} from 'features/SupplyOrder/services/replenishment'
import { withLoadingReducer } from 'utils/reducerHandler'
import {
  FormScheduleDetailType,
  SLICE_NAME,
  fetchGetReplenishmentSchedule,
  fetchGetReplenishmentScheduleConstants,
  fetchGetReplenishmentScheduleDetail,
  fetchPostReplenishmentSchedule,
  setReplenishmentScheduleFilter,
} from './thunk'

type OptionsType = {
  name: string
  value: string
}

export type ReplenishmentScheduleSliceType = {
  isLoading: boolean
  filter: GetReplenishmentScheduleListRequestType
  pagination: {
    pageSize: number
    pageIndex: number
    numberOfElements: number
  }
  data: GetReplenishmentScheduleListResponseType['data']
  constants: {
    warehouseList: GetWarehouseLocationsResponseType
    categories: GetCategoriesResponseType
    scheduleTypeOptions: OptionsType[]
    scheduleOptions: OptionsType[]
    dayOptions: OptionsType[]
    timeOptions: {
      no: number
      name: string
      disabled: boolean
    }[]
    skuMaxOptions: string[]
  }
  popupAddEditSchedule: {
    isOpen: boolean
    form: FormScheduleDetailType
  }
}

const initialState: ReplenishmentScheduleSliceType = {
  isLoading: false,
  filter: {},
  pagination: {
    pageIndex: 1,
    pageSize: 20,
    numberOfElements: 0,
  },
  data: [],
  constants: {
    warehouseList: [],
    categories: [],
    scheduleOptions: [],
    scheduleTypeOptions: [],
    dayOptions: [],
    timeOptions: [],
    skuMaxOptions: [],
  },
  popupAddEditSchedule: {
    isOpen: false,
    form: {
      daylist: [],
      timelist: [],
      categoryIdList: [],
      scheduleType: null,
      location: null,
      maxSku: '',
      startDate: '',
      endDate: '',
      unlimited: null,
      scheduleOption: '',
    },
  },
}

const replenishmentScheduleSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetReplenishmentSchedule: () => initialState,
    resetReplenishmentScheduleFilter: (state) => {
      state.filter = initialState.filter
    },
    setOpenAddSchedulePopup: (state, action: PayloadAction<boolean>) => {
      state.popupAddEditSchedule.isOpen = action.payload
      if (!action.payload) {
        state.popupAddEditSchedule.form = initialState.popupAddEditSchedule.form
      }
    },
    setAddEditScheduleFormPopup: (
      state,
      action: PayloadAction<{
        key: keyof ReplenishmentScheduleSliceType['popupAddEditSchedule']['form']
        value: unknown
      }>,
    ) => {
      const { key, value } = action.payload

      if (key === 'scheduleType') {
        state.popupAddEditSchedule.form.startDate = ''
        state.popupAddEditSchedule.form.endDate = ''
        state.popupAddEditSchedule.form.daylist = []
        state.popupAddEditSchedule.form.timelist = []
        state.popupAddEditSchedule.form.unlimited = false
      }

      if (key === 'unlimited') {
        state.popupAddEditSchedule.form.endDate = ''
      }

      state.popupAddEditSchedule.form[key] = value as never
    },
  },
  extraReducers: withLoadingReducer(
    (builder: ActionReducerMapBuilder<ReplenishmentScheduleSliceType>) => {
      builder
        .addCase(setReplenishmentScheduleFilter, (state, action) => {
          state.filter = action.payload
        })
        .addCase(fetchGetReplenishmentScheduleConstants.fulfilled, (state, action) => {
          state.constants = action.payload
        })
        .addCase(fetchGetReplenishmentSchedule.fulfilled, (state, action) => {
          state.data = action.payload.data
          state.pagination = action.payload.pagination
        })
        .addCase(fetchGetReplenishmentScheduleDetail.fulfilled, (state, action) => {
          state.popupAddEditSchedule.form = action.payload
        })
        .addCase(fetchPostReplenishmentSchedule.fulfilled, (state) => {
          state.popupAddEditSchedule = initialState.popupAddEditSchedule
        })
    },
    [
      fetchGetReplenishmentSchedule,
      fetchGetReplenishmentScheduleDetail,
      fetchPostReplenishmentSchedule,
    ],
  ),
})

export const {
  resetReplenishmentSchedule,
  resetReplenishmentScheduleFilter,
  setOpenAddSchedulePopup,
  setAddEditScheduleFormPopup,
} = replenishmentScheduleSlice.actions
export default replenishmentScheduleSlice.reducer
