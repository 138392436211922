import { Column, Typography } from '@astro-ui/components'
import { memo, useContext } from 'react'
import storeContext from 'storeContext'
import styled from 'styled-components'

const TemplateGiftNote = () => {
  const { state: statePrintArea } = useContext(storeContext.PrintAreaContext)

  if (!statePrintArea.dataGiftNote) return null

  const { orderRecipientName, orderSenderName, orderNote, listReadyToPrint } =
    statePrintArea.dataGiftNote
  return listReadyToPrint.map((d, i) => {
    return (
      <Wrapper key={i + 2}>
        <Column>
          <Font fontSize={'10px'} color="pureBlack" lineHeight={1}>
            Dari
          </Font>
          <Font
            fontSize={'12px'}
            color="pureBlack"
            fontFamily="montserrat"
            fontWeight={700}
            lineHeight={1.2}
            marginBottom={2}
          >
            {orderSenderName}
          </Font>
          <Font fontSize={'10px'} color="pureBlack" lineHeight={1}>
            Untuk
          </Font>
          <Font
            fontSize={'12px'}
            color="pureBlack"
            fontFamily="montserrat"
            fontWeight={700}
            lineHeight={1.2}
          >
            {orderRecipientName}
          </Font>
        </Column>
        <Column>
          <Font fontSize={'18px'} fontFamily="montserrat" fontWeight={800}>
            {orderNote}
          </Font>
        </Column>
      </Wrapper>
    )
  })
}

export default memo(TemplateGiftNote)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4vh;
  width: 100%;
  height: 100%;
  padding-bottom: 0px;
  justify-content: center;
  align-items: center;
  gap: 17px;
  @media print {
    page-break-inside: avoid;
    break-inside: avoid;
    clear: both;
    @page {
      size: 101.6mm 101.6mm;
      margin: 0mm;
    }
  }
`
const Font = styled(Typography)``
