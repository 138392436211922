import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getCloseMessage,
  postCloseMessage,
  putCloseMessage,
  deleteCloseMessage,
  type PostCloseMessagePayloadType,
} from 'features/Location/services/closeMessageConfig'
import { getCleanQueryObj } from 'utils/queryParamsURL'
import { callErrorMsg } from 'helpers/errorMsg'
import { CLOSE_MESSAGE_SLICE_NAME, setIsLoading, setCloseMessages } from './slice'

export const fetchCloseMessage = createAsyncThunk(
  `${CLOSE_MESSAGE_SLICE_NAME}/fetchCloseMessage`,
  async (_, { dispatch, getState }) => {
    const {
      closeMessageConfig: { query },
    } = getState() as StoreStateType

    dispatch(setIsLoading(true))
    try {
      const {
        data: { data },
      } = await getCloseMessage(getCleanQueryObj(query))
      dispatch(setCloseMessages(data.close_reason))
    } catch (err) {
      callErrorMsg(err)
    }
    dispatch(setIsLoading(false))
  },
)

export const createCloseMessage = createAsyncThunk(
  `${CLOSE_MESSAGE_SLICE_NAME}/createCloseMessage`,
  async (payload: PostCloseMessagePayloadType) => {
    try {
      await postCloseMessage(payload)

      return true
    } catch (err) {
      callErrorMsg(err)
      return false
    }
  },
)

export const updateCloseMessage = createAsyncThunk(
  `${CLOSE_MESSAGE_SLICE_NAME}/updateCloseMessage`,
  async ({ id, payload }: { id: number; payload: PostCloseMessagePayloadType }) => {
    try {
      await putCloseMessage(id, payload)

      return true
    } catch (err) {
      callErrorMsg(err)
      return false
    }
  },
)

export const removeCloseMessage = createAsyncThunk(
  `${CLOSE_MESSAGE_SLICE_NAME}/removeCloseMessage`,
  async (id: number) => {
    try {
      await deleteCloseMessage(id)

      return true
    } catch (err) {
      callErrorMsg(err)
      return false
    }
  },
)
