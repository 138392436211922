import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ModifierAdd } from 'features/Product/@types'

export const SLICE_NAME = 'modifierAdd'

const initialState: ModifierAdd.InitialStateType = {
  pageType: 'ADD',
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    setPageType: (state, { payload }: PayloadAction<ModifierAdd.PageType>) => {
      state.pageType = payload
    },
  },
})

export const { resetState, setPageType } = slice.actions
export default slice.reducer
