import axiosInstanceApiGateway from 'config/apiServiceApiGateway'
import { Get } from 'config/apiServiceConfig'

export interface PostResponseType {
  success: boolean
}

export interface GetStatusRecordingResponseType {
  recording: boolean
}

export interface GetIsPackingLineActiveResponseType {
  line_number: number
  packing_line_id: number
  recording_enabled: boolean
  device: string
}

export type PackingRecordingActionValueType = 'start' | 'stop' | 'force_stop'

export interface RealStartStopRecordingRequestType {
  action: PackingRecordingActionValueType
  device: string
  koli_id: number
}

type GetHubFeatureFlagRequestType = {
  feature: string
}

type GetHubFeatureFlagResponseType = {
  data: {
    active: boolean
    flag: boolean
  }
}

type GetFeatureFlagByLocationIdResponseType = {
  data: {
    features: {
      packing_recording?: boolean
    }
  }
}

type RealGetStatusRecordingResponseType = {
  status: string
  location_id: number
  footage_url: string
}

export type GetPackingLineDevicesResponseType = {
  devices: string[]
}

export const getHubFeatureFlag = (params: GetHubFeatureFlagRequestType) =>
  Get<GetHubFeatureFlagResponseType>({ url: '/api/feature-flag/hub-feature', params })

export const getFeatureFlagByLocationID = (locationId: number) =>
  Get<GetFeatureFlagByLocationIdResponseType>({ url: `/api/feature-flag/${locationId}/1` })

export const getIsPackingLineActiveV2 = (locationId: number, ipAddress: string) =>
  axiosInstanceApiGateway.Get<GetIsPackingLineActiveResponseType>({
    url: `/wms/internal/v2/supply-order/packing-lines/${locationId}`,
    params: {
      device: ipAddress,
    },
  })

export const realStartStopRecording = (soId: number, data: RealStartStopRecordingRequestType) =>
  axiosInstanceApiGateway.Post<PostResponseType>({
    url: `/wms/internal/v1/supply-order/${soId}/packing-recording`,
    data,
  })

export const realGetStatusRecording = (soId: number, params: { koli_id: number }) =>
  axiosInstanceApiGateway.Get<RealGetStatusRecordingResponseType>({
    url: `/wms/internal/v1/supply-order/${soId}/packing-recording/sessions`,
    params,
  })

export const getPackingLineDevices = (locationId: number) =>
  axiosInstanceApiGateway.Get<GetPackingLineDevicesResponseType, true>({
    url: '/wms/internal/:version/supply-order/packing-lines/:locationId/devices',
    version: 'v1',
    urlParams: { locationId },
  })
