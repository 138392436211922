import apiServiceApiGateway from 'config/apiServiceApiGateway'
import { Post } from 'config/apiServiceConfig'

export type PostLoginRequestType = {
  username: string
  password: string
}

interface VerifiedPhoneNumberResponseInterface {
  is_verified: boolean
  is_pin_exist: boolean
  is_totp_exist: boolean
}

export interface GenerateOtpResponseInterface {
  message: string
}

export interface ValidateOtpResponseInterface {
  token: string
}

export interface ValidatePinResponseInterface {
  token: string
  need_reset: boolean
  phone_number: string
  is_valid: boolean
}

export interface GenerateTOTPResponseInterface {
  totp_secret: string
  user_id: string
  totp_url: string
}

export interface ValidateTOTPResponseInterface {
  user_id: number
  token: string
  is_valid: boolean
}

export const postLogin = (payload: PostLoginRequestType) =>
  Post<{ token: string }>({
    url: '/api/authenticate',
    data: payload,
  })

export const getVerifiedPhoneNumber = (phone: string) =>
  apiServiceApiGateway.Get<VerifiedPhoneNumberResponseInterface>({
    url: `/accounts/internal/v1/user/login?phone_number=${phone}`,
  })

export const postGenerateUserOTP = (data: { phone_number: string }) =>
  apiServiceApiGateway.Post<GenerateOtpResponseInterface>({
    url: `/accounts/internal/v1/user/otp/generate`,
    data,
  })

export const postValidateOtpService = (data: { phone_number: string; otp: string }) =>
  apiServiceApiGateway.Post<ValidateOtpResponseInterface>({
    url: `/accounts/internal/v1/user/otp/validate`,
    data,
  })

export const postValidatePinService = (data: { phone_number: string; pin: string }) =>
  apiServiceApiGateway.Post<ValidatePinResponseInterface>({
    url: `/accounts/internal/v1/user/pin/validate`,
    data,
  })

export const putSubmitPinService = (data: { pin: string; confirm_pin: string }, token: string) =>
  apiServiceApiGateway.Put<{ message: string }>({
    url: `/accounts/internal/v1/user/pin`,
    config: {
      headers: {
        /* NOTE: auth token different from usual login token */
        Authorization: `Bearer ${token}`,
      },
    },
    data,
  })

export const postGenerateTotpService = (token: string) =>
  apiServiceApiGateway.Post<GenerateTOTPResponseInterface>({
    url: `/accounts/internal/v1/user/totp/generate`,
    config: {
      headers: {
        /* NOTE: auth token different from usual login token */
        Authorization: `Bearer ${token}`,
      },
    },
  })

export const postValidateTotpService = (
  data: { totp: string; is_sign_up: boolean },
  token: string,
) =>
  apiServiceApiGateway.Post<ValidateTOTPResponseInterface>({
    url: `/accounts/internal/v1/user/totp/validate`,
    config: {
      headers: {
        /* NOTE: auth token different from usual login token */
        Authorization: `Bearer ${token}`,
      },
    },
    data,
  })

export const getFlagHideEmail = () =>
  apiServiceApiGateway.Get<{ active: boolean }>({
    url: '/accounts/internal/v1/featureflag/key/loginAdminNew',
  })
