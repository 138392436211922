import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PricingEngineHistoryItemType } from 'features/PricingEngine/services/history'
import { fetchPricingEngineHistory } from './thunk'

export interface PricingEngineHistoryStateType {
  isLoading: boolean
  history: PricingEngineHistoryItemType[]
  pagination: {
    total_elements: number
    prev_cursor: string
    next_cursor: string
  }
}

const initialState: PricingEngineHistoryStateType = {
  isLoading: false,
  history: [],
  pagination: {
    total_elements: 0,
    prev_cursor: '',
    next_cursor: '',
  },
}

export const pricingEngineHistorySlice = createSlice({
  name: 'pricingEngineHistory',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setHistory: (state, action: PayloadAction<PricingEngineHistoryItemType[]>) => {
      state.history = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPricingEngineHistory.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchPricingEngineHistory.fulfilled, (state, action) => {
      state.isLoading = false
      state.history = action.payload.data
      state.pagination = action.payload.pagination
    })
  },
})

export const { setIsLoading, setHistory } = pricingEngineHistorySlice.actions

export default pricingEngineHistorySlice.reducer
