import apiServices from 'config/apiServiceApiGateway'

const { Get, Post } = apiServices

export type GetAllSegmentsResponseType = {
  name: string
  id: number
  total_user: number
}[]

export const getAllSegments = () =>
  Get<GetAllSegmentsResponseType>({ url: `/segmentation/internal/v1/segment` })

export type PostAddCampaignInventoryRequestType = {
  id: string
  payload: {
    inventory_discount_list: {
      product_id: number
      location_id: number
      user_segment: string
      segment_id: string
      segment_name: string
    }[]
    source: 'admin'
  }
}

export const postAddCampaignInventory = ({ id, payload }: PostAddCampaignInventoryRequestType) =>
  Post({ url: `/campaign/internal/v1/${id}/add-inventory`, data: payload })
