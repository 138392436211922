import { toastFailed } from "utils/toast";

import {
  getLocationOfWarehouse_Rak,
  getLocationOfHub_Rak,
  getRakFromLocationID_Rak
} from "utils/apiList/rak";

export const getListOfWarehouseLocation = (localState) => () => {
  return getLocationOfWarehouse_Rak()
    .then(({ data }) => {
      const formatedData = data.map((items) => ({
        ...items,
        name: items.location_name
      }));
      localState.setDataList((prev) => {
        return {
          ...prev,
          location: formatedData
        };
      });
    })
    .catch(() => {
      toastFailed(
        "terjadi masalah saat call Warehouse location list, coba beberapa saat lagi"
      );
    });
};

export const getListOfHubLocation = (localState) => () => {
  return getLocationOfHub_Rak()
    .then(({ data }) => {
      const formatedData = data.map((items) => ({
        ...items,
        name: items.location_name
      }));
      localState.setDataList((prev) => {
        return {
          ...prev,
          location: formatedData
        };
      });
    })
    .catch(() => {
      toastFailed(
        "terjadi masalah saat call Warehouse location list, coba beberapa saat lagi"
      );
    });
};

export const getRakByLocationID =
  (location_id, localState, query) => (dispatch, getState) => {
    return getRakFromLocationID_Rak(location_id, query)
      .then(({ data }) => {
        localState.setFormsListOfNumbAndPosRak(data.racks);
      })
      .catch(() => {
        toastFailed("terjadi masalah, coba beberapa saat lagi");
      });
  };
