import { Get, Put, Post } from 'config/apiServiceConfig'
import axiosInstanceApiGateway from 'config/apiServiceApiGateway'
import { ProductUomItemType } from 'features/Product/@types/Product'
import { LocationType, RackItemType } from 'features/Rack/@types/rack'

interface AddNewMultipleRackRequestType {
  storageType: number
  storageEnvironment: number
  locationId: number
  maxQuantity: number
  maxVolume: number
  maxWeight: number
  maxVolumeUom: string
  maxQuantityUom: string
  maxWeightUom: string
  racks: {
    rack_name: string
    position: number
  }[]
}

interface AddNewMultipleRackResponseType {
  success: {
    id: number
    rackName: string
    locationId: number
    position: number
    active: boolean
    createdBy: string
    updatedBy: string
    maxVolume: number
    maxVolumeUom: string
    maxQuantity: number
    maxQuantityUom: string
    maxWeight: number
    maxWeightUom: string
    mixAttribute: boolean
    mixProduct: boolean
    storageEnvironment: number
    storageType: number
    productId: number
  }[]
}

export interface GetDetailRackMultipleResponseType {
  data: {
    location: LocationType
    racks: {
      content: RackItemType[]
      totalElements: number
      totalPages: number
      numberOfElements: number
    }
  }
}

export interface UpdateSingleRackRequestType {
  storageType: number
  storageEnvironment: number
  maxVolume: number
  maxQuantity: number
  maxWeight: number
  maxVolumeUom: string
  maxQuantityUom: string
  maxWeightUom: string
  racks: {
    rack_name: string
    position: number
    id: number
  }[]
  active: boolean
}
export interface UpdateSingleRackInKongRequestType {
  rack_name: string
  position: number
  active: boolean
  max_volume: number
  max_volume_uom: string
  max_quantity: number
  max_quantity_uom: string
  max_weight: number
  max_weight_uom: string
  max_sku: number
  mix_attribute: boolean
  mix_product: boolean
  storage_environment: number
  storage_type: number
  area_id: number
  product_id?: number
  minimum_replenish_quantity?: number
  maximum_replenish_quantity?: number
  replenish_quantity?: number
}

export interface CreateMultipleRackRequestType {
  location_id: number
  storage_environment: number
  storage_type: number
  area_id: number
  max_volume: number
  max_volume_uom: string
  max_quantity: number
  max_quantity_uom: string
  max_weight: number
  max_weight_uom: string
  max_sku: number
  mix_attribute: boolean
  mix_product: boolean
  active: boolean
  rack_details: {
    position: number
    rack_name: string
    product_id?: number
    minimum_replenish_quantity?: number
    maximum_replenish_quantity?: number
    replenish_quantity?: number
  }[]
}

export type GetRackDetailInkongResponseType = {
  id: number
  max_volume: number
  max_volume_uom: string
  max_quantity: number
  max_quantity_uom: string
  max_weight: number
  max_weight_uom: string
  mix_attribute: boolean
  mix_product: boolean
  max_volume_uom_data: ProductUomItemType
  max_quantity_uom_data: ProductUomItemType
  max_weight_uom_data: ProductUomItemType
  storage_environment_data: {
    name: string
    id: number
  }
  storage_type_data: {
    name: string
    id: number
  }
  position: number
  rack_name: string
  active: boolean
  area_id: number
  area_name: string
  max_sku: number
  product_id: number
  product_sku: string
  minimum_replenish_quantity: number
  maximum_replenish_quantity: number
  replenish_quantity: number
}

export const getDetailRackMultipleByLocationId = (locationId: number) =>
  Get<GetDetailRackMultipleResponseType>({ url: `/api/racks/v2/${locationId}` })

export const addNewMultipleRack = (locationId: number, data: AddNewMultipleRackRequestType) =>
  Post<AddNewMultipleRackResponseType>({ url: `/api/racks/${locationId}`, data })

export const postCreateRacks = (data: CreateMultipleRackRequestType) =>
  axiosInstanceApiGateway.Post({ url: '/ims/internal/v1/rack', data })

export const updateMultipleRack = (locationId: number, data: AddNewMultipleRackRequestType) =>
  Put<AddNewMultipleRackResponseType>({ url: `/api/racks/${locationId}`, data })

export const getRackDetailInKong = (rackId: number) =>
  axiosInstanceApiGateway.Get<GetRackDetailInkongResponseType>({
    url: `/ims/internal/v1/rack/single/${rackId}`,
  })

export const putUpdateSingleRackInKong = (
  rackId: number,
  data: UpdateSingleRackInKongRequestType,
) => axiosInstanceApiGateway.Put({ url: `/ims/internal/v1/rack/single/${rackId}`, data })
