import {
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  RadioGroup,
  RadioGroupPropsType,
  Radio,
  RadioPropsType,
} from '@astro-ui/components'

export type RadioButtonPropsType = {
  required?: boolean
  errorMessage?: string
  supportText?: string
  fullWidth?: boolean
  label?: string
  row?: boolean
  value?: string | number
  options?: {
    name: string
    value: string | number
    disbaled?: boolean
  }[]
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void
  radioProps?: RadioPropsType
  radioGroupProps?: RadioGroupPropsType
}

function RadioButton({
  required,
  errorMessage,
  supportText,
  fullWidth,
  row = true,
  label,
  options,
  value,
  onChange,
  radioProps,
  radioGroupProps,
}: RadioButtonPropsType) {
  return (
    <FormControl
      variant="standard"
      required={required}
      error={!!errorMessage}
      fullWidth={fullWidth}
    >
      {label && <FormLabel>{label}</FormLabel>}

      <RadioGroup row={row} value={value} onChange={onChange} {...radioGroupProps}>
        {options?.map((item) => (
          <FormControlLabel
            key={item.name}
            value={item.value}
            control={<Radio {...radioProps} />}
            label={item.name}
            disabled={item.disbaled}
          />
        ))}
      </RadioGroup>

      {(!!errorMessage || supportText) && (
        <FormHelperText>{errorMessage || supportText}</FormHelperText>
      )}
    </FormControl>
  )
}

export default RadioButton
