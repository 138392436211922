import React from 'react'
import styled from 'styled-components'

const Title = ({ children, ...rest }) => {
  return <H2 {...rest}>{children}</H2>
}

Title.defaultProps = {
  size: 'header',
  weight: 'normal',
  color: 'text',
}

export default React.memo(Title)

const H2 = styled.h2`
  color: ${({ theme: { colors }, color }) => colors[color]};
  font-size: ${({ theme: { fontSizes }, size }) => fontSizes[size]};
  font-weight: ${({ theme: { fontWeights }, weight }) => fontWeights[weight]};
`
