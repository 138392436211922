import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

const { Put } = axiosInstanceApiGateway

export type PutResetUserAccountOTPRequestType = { payload: { phone_number: string } }

export type PutResetUserAccountOTPResponseType = {
  data: {
    is_success: boolean
  }
  error: {
    status: boolean
    message: string
    code: number
  }
}

export const putResetUserAccountOTP = ({ payload }: PutResetUserAccountOTPRequestType) =>
  Put<PutResetUserAccountOTPResponseType>({
    url: '/accounts/internal/v1/user/totp/reset',
    data: payload,
  })
