import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getRequestList,
  RequestListParamsType,
  RequestListResponseType,
  downloadRequestList,
  DownloadRequestListResponseType,
  getPriceChangeDetail,
  GetPriceChangeDetailResponseType,
  updateRequestStatus,
  UpdateRequestStatusResponseType,
  UpdateRequestStatusPayloadType,
  getRejectReason,
  GetRejectReasonResponseType,
  getRejectionDetail,
  GetRejectionDetailResponseType,
} from '../../services/request'

export const fetchRequests = createAsyncThunk<RequestListResponseType, RequestListParamsType>(
  'pricingEngineRequests/fetchRequests',
  async (params, { rejectWithValue }) => {
    try {
      const newParams = { ...params }
      if (!newParams.page_size) {
        newParams.page_size = 10
      }

      // Adjust dates if provided
      if (newParams.date_from) {
        const dateFrom = new Date(newParams.date_from)
        dateFrom.setHours(dateFrom.getHours() - 7)
        newParams.date_from = dateFrom.toISOString()
      }

      if (newParams.date_to) {
        const dateTo = new Date(newParams.date_to)
        dateTo.setHours(23, 59, 59, 999)
        newParams.date_to = dateTo.toISOString()
      }

      const response = await getRequestList(newParams)
      return response.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue((error as Error).message)
    }
  },
)

export const downloadRequestsThunk = createAsyncThunk<DownloadRequestListResponseType, string[]>(
  'pricingEngineRequests/downloadRequests',
  async (ids, { rejectWithValue }) => {
    try {
      const response = await downloadRequestList(ids)
      return response.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue((error as Error).message)
    }
  },
)

export const fetchRequestDetail = createAsyncThunk<
  GetPriceChangeDetailResponseType,
  { requestId: number | string; statuses?: string }
>(
  'pricingEngineRequests/fetchRequestDetail',
  async ({ requestId, statuses }, { rejectWithValue }) => {
    try {
      const response = await getPriceChangeDetail(requestId, statuses)
      const locationIds = response.data.data.details
        .filter((detail) => ['REVIEW', 'PENDING'].includes(detail.status))
        .map((detail) => detail.location_id)

      if (locationIds.length > 0) {
        await updateRequestStatus(requestId, { action: 'REVIEW', location_ids: locationIds })
      }

      return response.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue((error as Error).message)
    }
  },
)

export const updateRequestStatusThunk = createAsyncThunk<
  UpdateRequestStatusResponseType,
  { requestId: number | string; payload: UpdateRequestStatusPayloadType }
>(
  'pricingEngineRequests/updateRequestStatus',
  async ({ requestId, payload }, { rejectWithValue }) => {
    try {
      const response = await updateRequestStatus(requestId, payload)
      return response.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue((error as Error).message)
    }
  },
)

export const fetchRejectReason = createAsyncThunk<GetRejectReasonResponseType, void>(
  'pricingEngineRequests/fetchRejectReason',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getRejectReason()
      return response.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue((error as Error).message)
    }
  },
)

export const fetchRejectionDetail = createAsyncThunk<
  GetRejectionDetailResponseType,
  number | string
>('pricingEngineRequests/fetchRejectionDetail', async (requestId, { rejectWithValue }) => {
  try {
    const response = await getRejectionDetail(requestId)
    return response.data
  } catch (error) {
    callErrorMsg(error)
    return rejectWithValue((error as Error).message)
  }
})
