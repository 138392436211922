import styled, { css } from "styled-components";
import { useRef, useState, useEffect } from "react";

import { SystemIcon, Category, Input as InputComponent } from "components";
import listenClickOutside from "customHooks/outSideClick";
import { filterMethod } from "helpers";
import { Button } from "components/atoms";

const InputDropDownCategory = ({
  className,
  noIconDrop,
  width,
  iconSearch,
  listOptions,
  handleClickOption,
  grouping,
  label,
  color,
  mandatory,
  value,
  handleSearchOption,
  isManualFilter,
  hasChildsAndCheck,
  handleCheck,
  handleCloseOptions,
  posList,
  handleClickClear,
  hasClearIcon,
  noSetOption,
  initialKey,
  isNoSearchInit,
  isLoading,
  handleNextPage,
  ...rest
}) => {
  const [listFiltered, setListFiltered] = useState([]);
  const [showList, setShowList] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const refOutside = useRef(null);

  useEffect(() => {
    if (!showList) {
      setSearchKey(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  useEffect(() => {
    if (!showList) {
      setSearchKey(value);
    } else if (showList && isNoSearchInit) {
      setSearchKey("");
      setListFiltered(listOptions);
    }
  }, [showList]);
  useEffect(() => {
    if (hasChildsAndCheck) {
      const filteref = filterMethod(listOptions, searchKey, "name");
      setListFiltered(filteref);
    } else {
      setListFiltered(listOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOptions]);
  listenClickOutside(
    refOutside,
    () => {
      handleCloseOptions();
      setShowList(false);
    },
    showList
  );
  const handleSearch = (e) => {
    const {
      target: { value }
    } = e;
    if (isManualFilter) {
      handleSearchOption(e);
    } else {
      const filteref = filterMethod(listOptions, value, "name");
      setListFiltered(filteref);
    }
    setSearchKey(value);
  };
  return (
    <div className="input-drop-down" ref={refOutside}>
      <Container className={className} active={showList} width={width}>
        {iconSearch && (
          <IconSearch>
            <SystemIcon iconName="search" colorIcon="grey" fontSize="bigest" />
          </IconSearch>
        )}
        <InputWrapper onClick={() => setShowList(true)}>
          <Input
            {...rest}
            autoComplete="nope"
            value={searchKey}
            onChange={handleSearch}
          />
        </InputWrapper>
        {!noIconDrop && (
          <IconWrapper>
            <Button variant="wrapper" onClick={() => setShowList(!showList)}>
              <SystemIcon
                iconName="dropdown"
                colorIcon="grey"
                fontSize="smaller"
              />
            </Button>
          </IconWrapper>
        )}

        {/* dropDown subcategory check */}
        {showList && (
          <ListContainer posList={posList}>
            <ContainerSerach>
              <InputComponent
                colorIcon="grey"
                iconName="search"
                bgIcon=""
                iconPos="left"
                placeholder="Cari Kategori"
              />
            </ContainerSerach>
            <WrapperCategory>
              <Wrapper hasBorderRight>
                {listFiltered.map((el, idx) => {
                  return (
                    <Category
                      key={`${el.name}-idx-${idx}`}
                      data={el}
                      handleCheck={handleCheck}
                    />
                  );
                })}
              </Wrapper>
              <Wrapper>
                {listFiltered.map((el, idx) => {
                  return (
                    <Category
                      key={`${el.name}-idxcc-${idx}`}
                      data={el}
                      handleCheck={handleCheck}
                    />
                  );
                })}
              </Wrapper>
            </WrapperCategory>
          </ListContainer>
        )}
      </Container>
    </div>
  );
};

InputDropDownCategory.defaultProps = {
  size: "normal",
  noIconDrop: false,
  width: "",
  iconSearch: false,
  value: "",
  listOptions: [],
  handleClickOption: () => {},
  grouping: false,
  label: "",
  color: "secondaryText",
  mandatory: "",
  handleSearchOption: () => {},
  isManualFilter: false,
  hasChildsAndCheck: false,
  handleCloseOptions: () => {},
  posList: "down",
  handleClickClear: () => {},
  hasClearIcon: false,
  noSetOption: false,
  isNoSearchInit: false,
  isLoading: false,
  handleNextPage: () => {}
};

export default InputDropDownCategory;

const IconSearch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;

// const Img = styled.img`
//   width: 50px;
//   height: 25px;
// `;

const ListContainer = styled.div`
  max-height: 300px;
  z-index: 2;
  overflow: scroll;
  width: fit-content;
  border: 1px solid #e4eaf3;
  min-width: 250px;
  border-radius: 6px;
  position: absolute;
  left: 0;
  /* top: 40px; */
  top: ${({ posList }) => (posList === "down" ? "40px" : "auto")};
  bottom: ${({ posList }) => (posList === "down" ? "auto" : "40px")};
  background-color: ${({ theme: { colors } }) => colors.white};
  box-shadow: 0px 2px 16px 0px rgba(202, 211, 225, 0.4);
  -webkit-box-shadow: 0px 2px 16px 0px rgba(202, 211, 225, 0.4);
  -moz-box-shadow: 0px 2px 16px 0px rgba(202, 211, 225, 0.4);
  p {
    white-space: nowrap;
  }
`;

const IconWrapper = styled.div`
  transform: rotate(0deg);
  display: grid;
  place-items: center;
`;

const Input = styled.input`
  background: none;
  cursor: pointer;
  border: none;
  padding: 10px 0;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  color: ${({ theme: { colors } }) => colors.textSoft};
  width: 100%;
  :focus {
    outline: none;
  }
  ::placeholder {
    opacity: 1;
    color: #aab4c8;
  }
`;

const InputWrapper = styled.div`
  width: 100%;
`;

const Container = styled.div`
  width: ${({ width }) => width || "-webkit-fill-available"};
  border: 1px solid
    ${({ theme: { colors }, active }) => (active ? colors.main : "#E4EAF3")};
  padding: 0px 12px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
  position: relative;
`;

const ContainerSerach = styled.div`
  padding: 12px;
  width: 100%;
`;

const WrapperCategory = styled.div`
  display: flex;
`;

const Wrapper = styled.div`
  min-width: 200px;
  ${({ hasBorderRight }) =>
    hasBorderRight &&
    css`
      border-right: solid 1px #bfc9d9;
    `}
`;
