import { memo } from "react";
import styled from "styled-components";
import { SystemIcon, RowHubDetail, RowHubDetailHistory } from "components";

function TableHubDetail({ history,data, handleChangeActive, handleClickSave }) {
  return (
    <>
      <Container>
        <thead>
          <Tr>
            <Th ratio="4">
              TANGGAL DAN WAKTU
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th ratio="2">
              STOK
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th ratio="3">
              TOTAL STOK
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th ratio="3">
              TYPE
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th ratio="4">OLEH</Th>
            <Th ratio="4">Status Dari</Th>
            <Th ratio="4">Status ke</Th>
            <Th ratio="4">ASAL</Th>
            <Th ratio="4">TUJUAN</Th>
            <Th ratio="4">NOMER INVOICE/SO</Th>
          </Tr>
        </thead>
        <tbody>
          {data?.map((items, index) => (
              history ? (
                  <RowHubDetailHistory data={items} key={index + "sas"} />
              ):(
              <RowHubDetail data={items} key={index + "sas"} />
          )
          ))}
        </tbody>
      </Container>
    </>
  );
}

export default memo(TableHubDetail);

const Container = styled.table`
  width: 100%;
`;
const Tr = styled.tr`
  display: flex;
  padding: 16px 32px;
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`;
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`;
