import { initializeApp } from 'firebase/app'

import { getFirestore } from 'firebase/firestore'

const {
  REACT_APP_FIRESTORE_API_KEY,
  REACT_APP_FIRESTORE_AUTH_DOMAIN,
  REACT_APP_FIRESTORE_PROJECT_ID,
  REACT_APP_FIRESTORE_STORAGE_BUCKET,
  REACT_APP_FIRESTORE_MESSAGING_SENDER_ID,
  REACT_APP_FIRESTORE_APP_ID,
} = process.env

const firebaseConfig = {
  apiKey: REACT_APP_FIRESTORE_API_KEY,
  authDomain: REACT_APP_FIRESTORE_AUTH_DOMAIN,
  projectId: REACT_APP_FIRESTORE_PROJECT_ID,
  storageBucket: REACT_APP_FIRESTORE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIRESTORE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIRESTORE_APP_ID,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

export const db = getFirestore(app)
