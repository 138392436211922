import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CampaignStateType,
  SetQueryPayloadActionType,
  SetIsShowPopupPayloadActionType,
  SetSelectedCampaignListPayloadActionType,
  SetBulkSelectedCampaignListPayloadActionType,
  SetIsCheckedCampaignPayloadActionType,
} from 'features/CampaignAndDiscount/@types'
import {
  fetchCampaignTypeList,
  SLICE_NAME,
  fetchCampaignList,
  removeCampaign,
  updateCampaign,
  updateStatusCampaign,
} from './thunk'

const initialState: CampaignStateType = {
  isLoading: false,
  isShowPopup: '',
  campaignTypeList: [],
  campaignList: [],
  query: {
    campaignType: { name: '', value: '' },
    pageIndex: 0,
    pageSize: 20,
    status: { name: '', value: '' },
    date: [null, null],
    name: '',
  },
  bulkSelectedCampaignList: [],
  selectedCampaignList: {
    isChecked: false,
    active: false,
    campaignType: { label: '', value: '' },
    endDate: '',
    endTime: '',
    id: 0,
    maxDailyQuota: 0,
    maxDiscountQuota: 0,
    maxFinalPrice: 0,
    maxPercentage: 0,
    minDailyQuota: 0,
    minDiscountQuota: 0,
    minFinalPrice: 0,
    minPercentage: 0,
    name: '',
    startDate: '',
    startTime: '',
    status: '',
    uniqueKey: '',
    isFlashSale: false,
  },
}

const campaignList = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    resetSelectedCampaign: (state) => {
      state.selectedCampaignList = initialState.selectedCampaignList
    },
    resetBulkSelectedCampaign: (state) => {
      state.bulkSelectedCampaignList = initialState.bulkSelectedCampaignList
    },
    setQuery: (state, action: PayloadAction<SetQueryPayloadActionType>) => {
      const { name, value } = action.payload
      state.query[name] = value as never
    },
    setIsShowPopup: (state, action: PayloadAction<SetIsShowPopupPayloadActionType>) => {
      const { value } = action.payload
      state.isShowPopup = value
    },
    setSelectedCampaign: (
      state,
      action: PayloadAction<SetSelectedCampaignListPayloadActionType>,
    ) => {
      const { value } = action.payload
      state.selectedCampaignList = value
    },
    setBulkSelectedCampaign: (
      state,
      action: PayloadAction<SetBulkSelectedCampaignListPayloadActionType>,
    ) => {
      const { value, type } = action.payload
      if (type === 'ADD') {
        state.bulkSelectedCampaignList = [...state.bulkSelectedCampaignList, ...value]
      }
      if (type === 'DELETE') {
        state.bulkSelectedCampaignList = state.bulkSelectedCampaignList.filter(
          (el) => !value.some((elSome) => elSome.id === el.id),
        )
      }
    },
    setIsCheckedCampaign: (state, action: PayloadAction<SetIsCheckedCampaignPayloadActionType>) => {
      const { index, type } = action.payload
      if (type === 'CHECK_ALL' || type === 'UNCHECK_ALL') {
        state.campaignList = state.campaignList.map((el) => ({
          ...el,
          isChecked: type === 'CHECK_ALL',
        }))
      }
      if (type === 'CHECK_SINGLE' || type === 'UNCHECK_SINGLE') {
        state.campaignList[index].isChecked = type === 'CHECK_SINGLE'
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampaignList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchCampaignList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchCampaignList.fulfilled, (state, action) => {
        const { content } = action.payload
        state.campaignList = content.map((el) => ({
          ...el,
          uniqeKey: window.crypto.randomUUID(),
          isChecked: state.bulkSelectedCampaignList.some((elSome) => el.id === elSome.id),
        }))
        state.isLoading = false
      })
      .addCase(updateStatusCampaign.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateStatusCampaign.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateStatusCampaign.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(updateCampaign.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateCampaign.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateCampaign.fulfilled, (state) => {
        state.isShowPopup = initialState.isShowPopup
        state.isLoading = false
      })
      .addCase(removeCampaign.pending, (state) => {
        state.isLoading = true
      })
      .addCase(removeCampaign.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(removeCampaign.fulfilled, (state) => {
        state.isLoading = false
        state.isShowPopup = initialState.isShowPopup
      })
      .addCase(fetchCampaignTypeList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchCampaignTypeList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchCampaignTypeList.fulfilled, (state, actions) => {
        state.isLoading = false

        state.campaignTypeList = actions.payload.content.map((el) => ({
          ...el,
          uniqueKey: window.crypto.randomUUID(),
          name: el.label,
        }))
      })
  },
})

export default campaignList.reducer
export const {
  reset,
  setQuery,
  setIsShowPopup,
  setSelectedCampaign,
  resetSelectedCampaign,
  setBulkSelectedCampaign,
  setIsCheckedCampaign,
  resetBulkSelectedCampaign,
} = campaignList.actions
