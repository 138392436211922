import { lazy } from 'react'

import generateRouterDetail from 'utils/helpers/generateRouterDetail'
import { AdminAccessUserRBAC } from '../pages/AdminAccess/User/rbac'
import { AdminAccessRolesRBAC } from '../pages/AdminAccess/Roles/rbac'
import { AdminAccessPageRBAC } from '../pages/AdminAccess/Page/rbac'
import { AdminAccessViewCreateEditPagePageRBAC } from '../pages/AdminAccess/AddEditPageID/rbac'

const AdminAccess = lazy(() => import('../pages/AdminAccess'))
const AddEditPageID = lazy(() => import('../pages/AdminAccess/AddEditPageID'))
const Buyer = lazy(() => import('../pages/Buyer'))
const AuthenticationControll = lazy(() => import('../pages/AuthenticationControll'))

export const BuyerRouter: RouterFeatureInterface<'BuyerRouter', '/dashboard/buyer'> = {
  name: 'BuyerRouter',
  Component: Buyer,
  path: '/dashboard/buyer',
  routeType: 'PRIVATE',
  allowedRoles: ['AUTH_CUSTOMER_SERVICE', 'AUTH_MARKETING', 'AUTH_HO_CS_LEADER', 'AUTH_CS_AGENT'],
  isDashboard: true,
}

export const AuthenticationControllRouter: RouterFeatureInterface<
  'AuthenticationControllRouter',
  '/dashboard/authentication-controll'
> = {
  name: 'AuthenticationControllRouter',
  Component: AuthenticationControll,
  path: '/dashboard/authentication-controll',
  routeType: 'PRIVATE',
  allowedRoles: ['AUTH_HO_IT'],
  isDashboard: true,
}

export const AdminAccessRouter: RouterFeatureInterface<
  'AdminAccessRouter',
  '/dashboard/admin-access/:id'
> = {
  name: 'AdminAccessRouter',
  Component: AdminAccess,
  path: '/dashboard/admin-access/:id',
  multiplePageID: {
    keySegment: 'id',
    pageIDmatchWithKeySegments: {
      user: AdminAccessUserRBAC.pageID,
      roles: AdminAccessRolesRBAC.pageID,
      page: AdminAccessPageRBAC.pageID,
    },
  },
  routeType: 'PRIVATE',
  allowedRoles: [],
  isDashboard: true,
}

export const AdminAccessAddPageIDRouter: RouterFeatureInterface<
  'AdminAccessAddPageIDRouter',
  '/dashboard/admin-access/page/add'
> = {
  name: 'AdminAccessAddPageIDRouter',
  Component: AddEditPageID,
  path: '/dashboard/admin-access/page/add',
  routeType: 'PRIVATE',
  rbacPageID: AdminAccessViewCreateEditPagePageRBAC.pageID,
  allowedRoles: [],
  isDashboard: true,
}

export const AdminAccessEditPageIDRouter: RouterFeatureInterface<
  'AdminAccessEditPageIDRouter',
  '/dashboard/admin-access/page/edit/:id'
> = {
  name: 'AdminAccessEditPageIDRouter',
  Component: AddEditPageID,
  path: '/dashboard/admin-access/page/edit/:id',
  routeType: 'PRIVATE',
  rbacPageID: AdminAccessViewCreateEditPagePageRBAC.pageID,
  allowedRoles: [],
  isDashboard: true,
}

const AccountRouter = [
  BuyerRouter,
  AuthenticationControllRouter,
  AdminAccessRouter,
  AdminAccessEditPageIDRouter,
  AdminAccessAddPageIDRouter,
]

export const AccountRouterDetail = generateRouterDetail(AccountRouter)
export default AccountRouter
