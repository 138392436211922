import { memo } from "react";
import styled from "styled-components";
import { Row, THead, RowFooter } from "../index";

function TableSuratJalan({ data, isSuratJalan, selectedData }) {
  return (
    <>
      {/* <div style={{ breakAfter: "page" }}>dsda</div> */}

      <TableContainer>
        <THead selectedData={selectedData} />
      </TableContainer>

      {/* <footer style={{ position: "relative" }}>dad</footer> */}
      <TableContainer>
        <TBody>
          {data.map((items, index) => {
            // console.log(items);

            return (
              <Row
                data={items}
                selectedData={selectedData}
                //setData={setData}
                dIndex={index}
                key={index + "sads"}
                isSuratJalan={isSuratJalan}
              />
            );
          })}
          <RowFooter
            allData={data}
            isSuratJalan={isSuratJalan}
            selectedData={selectedData}
          />
        </TBody>
      </TableContainer>

      {isSuratJalan && (
        <TTDPicContainer>
          <TTDPicWrapper>
            <TTDTitle>TTD PIC</TTDTitle>
            <TTDBodyContainer>
              <TTDBy>
                <TTDByTitle>Dibuat Oleh,</TTDByTitle>
              </TTDBy>
              <TTDBy>
                <TTDByTitle>Disetujui Oleh,</TTDByTitle>
              </TTDBy>
              <TTDBy lastBR>
                <TTDByTitle>Diterima Oleh,</TTDByTitle>
              </TTDBy>
            </TTDBodyContainer>
          </TTDPicWrapper>
        </TTDPicContainer>
      )}

      {/* <div
        className="pagebreak"
        style={{ breakAfter: "page", position: "fixed", bottom: 0 }}
      >
        fsad
      </div> */}
      {/* <TableContainer>
        <TBody></TBody>
      </TableContainer> */}
    </>
  );
}

export default memo(TableSuratJalan);

// const Container = styled.table`
//   width: 100%;
//   border-collapse: collapse;
//   height: fit-content;
//   border-radius: 8px;
// `;

const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
  height: fit-content;
  border-radius: 8px;
`;

// const THead = styled.thead``;
const TBody = styled.tbody``;

const TTDPicContainer = styled.div`
  margin-top: 42px;
  display: flex;
  width: 100%;
  page-break-inside: avoid;
`;

const TTDPicWrapper = styled.div`
  border: 1px solid #d6dfeb;
  box-sizing: border-box;
  border-radius: 8px;
  width: 60%;
  margin-right: auto;
  background: #ffffff;
`;

const TTDTitle = styled.div`
  border-bottom: 1px solid #d6dfeb;
  height: 46px;
  padding: 12px 24px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
`;

const TTDBodyContainer = styled.div`
  display: flex;
  /* Mobile/Heading 6 */
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  /* Typography/[600] Low Emphasis */
  color: #6d7588;
`;
const TTDBy = styled.div`
  width: 33%;
  ${({ lastBR }) => !lastBR && "border-right: 1px solid #d6dfeb;"};
`;

const TTDByTitle = styled.div`
  padding: 24px;
  margin-bottom: 42px;
`;

// const Tr = styled.tr`
//   display: flex;
//   //padding: 16px 32px;
//   //padding: 0px 8px;
//   ${({ pt }) => (pt ? `padding-top: ${pt};` : "")}
//   background: #e6e7e8;
//   border-radius: ${({ topCurvedt }) => {
//     return topCurvedt ? topCurvedt : "";
//   }};
//   ${({ borderLineTop }) => borderLineTop && `border-top: 1px solid #bfc9d6;`}
//   ${({ borderLineLeft }) => borderLineLeft && `border-left: 1px solid #bfc9d6;`}
//   ${({ borderLineRight }) =>
//     borderLineRight && `border-right: 1px solid #bfc9d6;`}
// `;
// const Th = styled.th`
//   color: ${({ theme: { colors } }) => colors.grey};
//   /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
//   font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
//   //flex: ${({ ratio }) => ratio};
//   width: ${({ width }) => (width ? width : "10%")};
//   text-align: start;
//   ${({ lastBR }) => !lastBR && `border-right: 1px solid #bfc9d6;`}
// `;

// const CenterText = styled.p`
//   text-align: center;
// `;

// const THWrapper = styled.div`
//   padding: 12px 8px;
// `;

// const THBottomWrapper = styled.div`
//   border-top: 1px solid #bfc9d6;
//   padding-bottom: 8px;
//   padding-top: 8px;
// `;
