import {
  SET_IS_LOGIN_AUTH,
  SET_IS_LOADING_AUTH,
  SET_USER_DATA,
  SET_IS_EXPAND,
} from 'storeContext/actionsType/auth'
import { toastFailed, toastSuccess } from 'utils/toast'
import { postForgetPassword, postUpdatePassword } from 'utils/api'

export function setIsLoading(payload) {
  return {
    type: SET_IS_LOADING_AUTH,
    payload: payload,
  }
}

export function setIsLogin(payload) {
  return {
    type: SET_IS_LOGIN_AUTH,
    payload: payload,
  }
}

export function setUserData(payload) {
  return {
    type: SET_USER_DATA,
    payload: payload,
  }
}

export function setIsExpand(payload) {
  return {
    type: SET_IS_EXPAND,
    payload: payload,
  }
}

export const requestForgetPassword =
  (mail, localState = {}) =>
  (dispatch) => {
    dispatch(setIsLoading(true))
    //console.log(mail)

    const payload = mail

    return postForgetPassword(payload)
      .then((res) => {
        const { data } = res
        //console.log(data);
        localState.setIsSuccess()
        toastSuccess(`${data.message}`)
      })
      .catch((error) => {
        toastFailed(`terjadi kesalahan, coba beberapa saat lagi`)
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

export const updateNewPassword =
  (forms, token, localState = {}) =>
  (dispatch) => {
    dispatch(setIsLoading(true))
    const payload = forms
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }

    return postUpdatePassword(payload, headers)
      .then(({ data }) => {
        toastSuccess('Password berhasil terupdate')
        localState.history.push('/')
      })
      .catch((error) => {
        toastFailed(`terjadi kesalahan, coba beberapa saat lagi`)
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }
