import { lazy } from 'react'
import generateRouterDetail from 'utils/helpers/generateRouterDetail'

type NamedRoutesType =
  | 'FlashSaleRouter'
  | 'MilestoneAddEditRouter'
  | 'MilestoneListRouter'
  | 'FlashSaleRouter'
  | 'FlexiComboRouter'
type PathListsType =
  | '/dashboard/flash-sale'
  | '/dashboard/milestone/:prefix(add|edit)/:milestoneId(\\d+)?'
  | '/dashboard/milestone-list'
  | '/dashboard/flash-sale'
  | '/dashboard/flexi-combo'

type RouterInstanceType = RouterFeatureInterface<NamedRoutesType, PathListsType>

const FlashSalePage = lazy(() => import('../pages/FlashSale'))
const AddEditFlashSale = lazy(() => import('../pages/AddEditFlashSale'))
const MilestoneAddEdit = lazy(() => import('../pages/MilestoneAddEdit'))
const MilestoneList = lazy(() => import('../pages/MilestoneList'))
const FlexiComboPage = lazy(() => import('../pages/FlexiCombo'))
const FlexiComboAddEdit = lazy(() => import('../pages/FlexiComboAddEdit'))

export const FlashSaleRouter: RouterInstanceType = {
  name: 'FlashSaleRouter',
  Component: FlashSalePage,
  path: '/dashboard/flash-sale',
  routeType: 'PRIVATE',

  allowedRoles: ['AUTH_MARKETING', 'AUTH_MERCHANDISING_WAREHOUSE'],
  isDashboard: true,
}

export const AddEditFlashSaleRouter: RouterFeatureInterface<
  'AddEditFlashSaleRouter',
  '/dashboard/flash-sale/:prefix/:id'
> = {
  name: 'AddEditFlashSaleRouter',
  Component: AddEditFlashSale,
  path: '/dashboard/flash-sale/:prefix/:id',
  routeType: 'PRIVATE',

  allowedRoles: ['AUTH_MARKETING', 'AUTH_MERCHANDISING_WAREHOUSE'],
  isDashboard: true,
}

export const MilestoneAddEditRouter: RouterInstanceType = {
  name: 'MilestoneAddEditRouter',
  Component: MilestoneAddEdit,
  path: '/dashboard/milestone/:prefix(add|edit)/:milestoneId(\\d+)?',
  routeType: 'PRIVATE',
  allowedRoles: [
    'AUTH_CS_CAPTAIN',
    'AUTH_PRICING',
    'AUTH_SUPPLY_CHAIN',
    'AUTH_MARKETING',
    'AUTH_HO_CS_LEADER',
    'AUTH_HO_FRAUD',
    'AUTH_HO_CAMPAIGN',
    'AUTH_HO_PRODUCT_MANAGER',
    'AUTH_HO_QUALITY_ENGINEERING',
  ],
  isDashboard: true,
}

export const MilestoneListRouter: RouterInstanceType = {
  name: 'MilestoneListRouter',
  Component: MilestoneList,
  path: '/dashboard/milestone-list',
  routeType: 'PRIVATE',
  allowedRoles: [
    'AUTH_CS_CAPTAIN',
    'AUTH_PRICING',
    'AUTH_SUPPLY_CHAIN',
    'AUTH_MARKETING',
    'AUTH_HO_CS_LEADER',
    'AUTH_HO_FRAUD',
    'AUTH_HO_CAMPAIGN',
    'AUTH_HO_PRODUCT_MANAGER',
    'AUTH_HO_QUALITY_ENGINEERING',
  ],
  isDashboard: true,
}

export const FlexiComboRouter: RouterInstanceType = {
  name: 'FlexiComboRouter',
  Component: FlexiComboPage,
  path: '/dashboard/flexi-combo',
  routeType: 'PRIVATE',
  allowedRoles: ['AUTH_MARKETING', 'AUTH_MERCHANDISING_WAREHOUSE'],
  isDashboard: true,
}

export const FlexiComboAddEditRouter: RouterFeatureInterface<
  'FlexiComboAddEdit',
  '/dashboard/flexi-combo-add-edit/:prefix/:id'
> = {
  name: 'FlexiComboAddEdit',
  Component: FlexiComboAddEdit,
  path: '/dashboard/flexi-combo-add-edit/:prefix/:id',
  routeType: 'PRIVATE',
  allowedRoles: ['AUTH_MARKETING', 'AUTH_MERCHANDISING_WAREHOUSE'],
  isDashboard: true,
}

const PromotionRouter = [
  FlashSaleRouter,
  AddEditFlashSaleRouter,
  MilestoneAddEditRouter,
  MilestoneListRouter,
  FlexiComboRouter,
  FlexiComboAddEditRouter,
]

export const PromotionRouterDetail = generateRouterDetail(PromotionRouter)

export default PromotionRouter
