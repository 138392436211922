import { Delete, Get, Post, Put } from 'config/apiServiceConfig'

export type GetReplenishmentTaskListRequestType =
  | Partial<{
      page_index: string | number
      page_size: string | number
      sort: string
      direction: string
      sku_number: string
      replenishment_task_no: string
      status: string
      location_ids: string
    }>
  | undefined

export type GetReplenishmentTaskListResponseType = {
  data: {
    id: number
    replenishment_task_no: string
    type: string
    created_at: number
    expiry_at: number
    total_sku: number
    total_quantity: number
    status: string
    location_name: string
    replenishment_schedule_no: string
    replenishment_product_category: string
    actions: string[]
    location_id: number
    staff: Nullable<{
      id: number
      full_name: string
    }>
  }[]
  pagination: {
    sort: string
    direction: string
    page_size: number
    page_index: number
    number_of_elements: number
  }
}

export type PutReplenishmentTaskRequestType = {
  id: number
  payload: {
    forkliftOperator?: string
    items?: {
      replenishmentTaskItemId: number
      productId: number
      productDetailId: number
      replQty: number
      actualQty: number
    }[]
    status?: string
  }
  meta?: Partial<{
    callback: () => void
  }>
}

export type PutReplenishmentTaskResponseType = {
  message: string
}

export type GetReplenishmentTaskItemRequestType = {
  id: number
  params?: Partial<{
    sku_or_name: string
    rack_label: string
    print_slip: boolean
    page_size: number
    page_index: number
  }>
}

export type GetReplenishmentTaskItemResponseType = {
  data: {
    id: number
    from_rack: string
    package_label: string
    expiry_date: number
    base_uom: string
    repl_qty: number
    to_rack: string
    product_name: string
    product_sku: string
    product_id: number
    product_detail_id: number
  }[]
  pagination: {
    page_size: number
    page_index: number
    number_of_elements: number
  }
}

export type GetReplenishmentTaskDetailRequestType = {
  id: number
  params?: Partial<{
    print_slip: boolean
    rack_label: string
  }>
}

export type GetReplenishmentTaskDetailResponseType = {
  data: {
    replenishment_task_activity: {
      status: string
      start_date: number
      end_date: number
      duration: string
      created_by: string
    }[]
    replenishment_task_item_detail: GetReplenishmentTaskItemResponseType['data']
    replenishment_task: GetReplenishmentTaskListResponseType['data'][number]
  }
}

export type GetPrintReplenishmentTaskRequestType = {
  id: number
}

export type GetPrintReplenishmentTaskResponseType = GetReplenishmentTaskDetailResponseType

export type GetReplenishmentTaskPartialRequestType = GetReplenishmentTaskItemRequestType

export type GetReplenishmentTaskPartialResponseType = GetReplenishmentTaskItemResponseType

export type GetStaffByRoleAndLocationRequestType = {
  locationId: number
  params?: Partial<{
    name: string
  }>
}

export type GetStaffByRoleAndLocationResponseType = {
  data: {
    id: number
    fullName: string
  }[]
}

export type PutUpdateStaffReplenishmentTaskRequestType = {
  id: number
  payload: {
    staff_id: number
  }
}

export type PutUpdateStaffReplenishmentTaskResponseType = {
  message: string
}

export type GetReplenishmentScheduleListRequestType =
  | Partial<{
      pageIndex: string | number
      pageSize: string | number
      replenishmentNo: string
      locationId: string | number
      locationIds: string
      startDate: string | number
      endDate: string | number
      startTime: string
      endTime: string
      scheduleType: string
    }>
  | undefined

export type GetReplenishmentScheduleListResponseType = {
  data: {
    id: number
    locationId: number
    locationName: string
    scheduleNo: string
    date: string
    time: string
    scheduleType: string
    maxSku: number
    day: string
    category: string
  }[]
  pagination: {
    pageSize: number
    pageIndex: number
    numberOfElements: number
  }
}

export type GetReplenishmentScheduleDetailRequestType = {
  id: number
}

export type GetReplenishmentScheduleDetailResponseType = {
  data: {
    id: number
    locationId: number
    locationName: string
    scheduleNo: string
    startDate: string
    endDate: string
    time: string[]
    scheduleType: string
    maxSku: number
    day: string[]
    categoryId: number[]
    isNow: boolean
    infinite: boolean
  }
}

export type DeleteReplenishmentScheduleRequestType = {
  id: number
}

export type DeleteReplenishmentScheduleResponseType = {
  message: string
}

export type PostReplenishmentScheduleRequestType = {
  dayList?: string[]
  timeList?: string[]
  categoryIdList?: number[]
  scheduleType?: string
  locationId?: number
  maxSku?: number
  createdBy?: string
  startDate?: string
  endDate?: string
  unlimited?: boolean
  isNow?: boolean
}

export type PostReplenishmentScheduleResponseType = {
  message: string
}

export type PutReplenishmentScheduleRequestType = {
  id: number
  payload: {
    dayList?: string[]
    timeList?: string[]
    categoryIdList?: number[]
    scheduleType?: string
    locationId?: number
    maxSku?: number
    createdBy?: string
    startDate?: string
    endDate?: string
    unlimited?: boolean
    isNow?: boolean
  }
}

export type PutReplenishmentScheduleResponseType = {
  message: string
}

export type GetWarehouseLocationsResponseType = {
  location_id: number
  location_name: string
}[]

export type GetCategoriesResponseType = {
  category_id: number
  category_name: string
}[]

export const getReplenishmentTaskListAPI = (params: GetReplenishmentTaskListRequestType) =>
  Get<GetReplenishmentTaskListResponseType>({
    url: '/api/ims/v2/replenishment-task',
    params,
  })

export const getPrintReplenishmentTaskDetailAPI = ({ id }: GetPrintReplenishmentTaskRequestType) =>
  Get<GetPrintReplenishmentTaskResponseType>({
    url: `/api/ims/v2/replenishment-task/${id}`,
    params: { printSlip: true },
  })

export const getReplenishmentTaskDetailAPI = ({ id }: GetReplenishmentTaskDetailRequestType) =>
  Get<GetReplenishmentTaskDetailResponseType>({
    url: `/api/ims/v2/replenishment-task/${id}`,
  })

export const getReplenishmentTaskItemAPI = ({ id, params }: GetReplenishmentTaskItemRequestType) =>
  Get<GetReplenishmentTaskItemResponseType>({
    url: `/api/ims/v2/replenishment-task/${id}/item`,
    params,
  })

export const putReplenishmentTaskAPI = ({ id, payload }: PutReplenishmentTaskRequestType) =>
  Put<PutReplenishmentTaskResponseType>({
    url: `/api/ims/replenishment-task/v1/${id}`,
    data: payload,
  })

export const getReplenishmentScheduleListAPI = (params: GetReplenishmentScheduleListRequestType) =>
  Get<GetReplenishmentScheduleListResponseType>({
    url: '/api/ims/replenishment/v1',
    params,
  })

export const getReplenishmentScheduleDetailAPI = ({
  id,
}: GetReplenishmentScheduleDetailRequestType) =>
  Get<GetReplenishmentScheduleDetailResponseType>({
    url: `/api/ims/replenishment/v1/${id}`,
  })

export const postReplenishmentScheduleAPI = (payload: PostReplenishmentScheduleRequestType) =>
  Post<PostReplenishmentScheduleResponseType>({
    url: `/api/ims/replenishment/v1`,
    data: payload,
  })

export const putReplenishmentScheduleAPI = ({ id, payload }: PutReplenishmentScheduleRequestType) =>
  Put<PutReplenishmentScheduleResponseType>({
    url: `/api/ims/replenishment/v1/${id}`,
    data: payload,
  })

export const deleteReplenishmentScheduleAPI = ({ id }: DeleteReplenishmentScheduleRequestType) =>
  Delete<DeleteReplenishmentScheduleResponseType>({
    url: `/api/ims/replenishment/v1/${id}`,
  })

export const getWarehouseLocationsAPI = () =>
  Get<GetWarehouseLocationsResponseType>({
    url: `/api/locations/warehouse`,
  })

export const getCategoriesAPI = () =>
  Get<GetCategoriesResponseType>({
    url: `/api/categories/child`,
  })

export const getStaffByRoleAndLocationAPI = ({
  locationId,
  params,
}: GetStaffByRoleAndLocationRequestType) =>
  Get<GetStaffByRoleAndLocationResponseType>({
    url: `/api/staff-management/staff/${locationId}/AUTH_INVENTORY_CONTROLLER`,
    params,
  })

export const putUpdateStaffReplenishmentTaskAPI = ({
  id,
  payload,
}: PutUpdateStaffReplenishmentTaskRequestType) =>
  Put<PutUpdateStaffReplenishmentTaskResponseType>({
    url: `/api/ims/v2/replenishment-task/${id}/staff`,
    data: payload,
  })
