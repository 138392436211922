import { memo, useContext } from 'react'
import styled from 'styled-components'
import store from 'storeContext'
import LogoBlack from 'assets/images/logo_astro_black.svg'
import { TextBody } from '../../../../atoms'
import { Box } from '@astro-ui/components'

const TUKAR_GULING_MAX_ROW = 5

function TemplateSupplyOrderDeliveryNoteOld() {
  const { state: statePrintArea } = useContext(store.PrintAreaContext)
  const forMap = Object.keys(statePrintArea.dataDeliveryNoteBulk)
  const header = [
    {
      label: 'Nama Driver',
    },
    {
      label: 'KM Awal',
    },
    {
      label: 'Jam Tiba',
    },
    {
      label: 'Seal',
    },
    {
      label: 'Plat Mobil',
    },
    {
      label: 'Suhu Sebelum Loading',
    },
    {
      label: 'Jam Berangkat',
    },
    {
      label: 'Rit',
    },
    {
      label: 'Tanggal',
    },
    {
      label: 'Suhu Setelah Loading',
    },
    {
      label: 'Suhu Wayback',
    },
    {
      label: 'Rute',
    },
  ]
  const RenderHeaderColiInformation = () => {
    return (
      <TableContainer fullWidth>
        <Tr borderTop>
          <Td borderRight>Jmlh</Td>
          <Td borderRight>TTD</Td>
          <Td>Nama</Td>
        </Tr>
      </TableContainer>
    )
  }
  const RenderEmptyColi = () => {
    return (
      <TableContainer fullWidth>
        <Tr>
          <Td borderRight width={'48px'}>
            &nbsp;
          </Td>
          <Td borderRight>&nbsp;</Td>
          <Td>&nbsp;</Td>
        </Tr>
      </TableContainer>
    )
  }
  const renderUnderscore = () => (
    <>
      <TextBody
        color="pureBlack"
        size="normal"
        weight="normal"
        style={{
          marginLeft: '5px',
          marginRight: '10px',
          fontSize: '7px',
        }}
      >
        :
      </TextBody>
      <TextBody color="pureBlack" size="normal" weight="normal">
        _________
      </TextBody>
    </>
  )

  const renderUnderscoreForKmField = () => (
    <>
      <TextBody
        color="pureBlack"
        size="normal"
        weight="normal"
        style={{
          marginLeft: '5px',
          marginRight: '10px',
          fontSize: '7px',
        }}
      >
        :
      </TextBody>
      <TextBody color="pureBlack" size="normal" weight="normal">
        ____
      </TextBody>
    </>
  )

  return (
    <Container>
      <LogoWrapper>
        <div>
          <Logo src={LogoBlack} />
        </div>
        <DeliveryNoteText>Surat Jalan</DeliveryNoteText>
        <NoForm>No form</NoForm>
      </LogoWrapper>
      <HeaderWrapper>
        {header.map((value, key) => {
          return (
            <HeaderLabelWrapper key={key}>
              <Label>
                <TextBody
                  color="pureBlack"
                  style={{
                    textTransform: 'uppercase',
                    flexBasis: '50%',
                    marginLeft: '10px',
                    fontSize: '7px',
                  }}
                  size="normal"
                  weight="normal"
                >
                  {value.label}
                </TextBody>

                {value.label === 'KM Awal' && renderUnderscoreForKmField()}

                {value.label !== 'KM Awal' && renderUnderscore()}

                {value.label === 'KM Awal' && (
                  <>
                    <TextBody
                      color="pureBlack"
                      style={{
                        marginLeft: '10px',
                        textTransform: 'uppercase',
                        flexBasis: '50%',
                        fontSize: '7px',
                      }}
                      size="normal"
                      weight="normal"
                    >
                      KM Akhir
                    </TextBody>
                    {renderUnderscoreForKmField()}
                  </>
                )}
              </Label>
            </HeaderLabelWrapper>
          )
        })}
      </HeaderWrapper>
      {forMap.map((valueForMap, keyValueForMap) => {
        return (
          <TableContainer style={{ marginBottom: '4px' }} fullWidth key={keyValueForMap}>
            <Tr bordered>
              <Td colored borderRight style={{ paddingLeft: '5px' }}>
                Hub: {valueForMap}
              </Td>
              <Td colored borderRight style={{ paddingLeft: '5px' }}>
                Jam Tiba:
              </Td>
              <Td colored borderRight style={{ paddingLeft: '5px' }}>
                Suhu Box Fleet:
              </Td>
              <Td colored bordered spacedLeft style={{ paddingLeft: '5px' }}>
                Nomor seal hub:
              </Td>
            </Tr>
            <Tr height={'40px'}>
              <Td colored bordered centerText>
                Nomor SO
              </Td>
              <Td colored centerText bordered>
                Koli Dispatch
                <RenderHeaderColiInformation />
              </Td>
              <Td colored centerText bordered>
                Koli Dikirim
                <RenderHeaderColiInformation />
              </Td>
              <Td colored centerText bordered>
                Koli Diterima
                <RenderHeaderColiInformation />
              </Td>
            </Tr>

            {statePrintArea.dataDeliveryNoteBulk[valueForMap].map(function (value) {
              return value.detail.map((valueChild, keyChild) => {
                return (
                  <Tr key={keyChild}>
                    <Td bordered style={{ paddingLeft: '12px' }}>
                      {valueChild.soNumber}
                    </Td>
                    <Td bordered>
                      <TableContainer fullWidth>
                        <Tr>
                          <Td centerText width={'49px'} borderRight>
                            {valueChild.totalKoli}
                          </Td>
                          <Td width={'40px'} borderRight>
                            &nbsp;
                          </Td>
                          <Td>&nbsp;</Td>
                        </Tr>
                      </TableContainer>
                    </Td>
                    <Td bordered>
                      <RenderEmptyColi />
                    </Td>
                    <Td bordered>
                      <RenderEmptyColi />
                    </Td>
                  </Tr>
                )
              })
            })}
            <Tr>
              <Td
                style={{
                  paddingLeft: '12px',
                  borderLeft: '1px solid #BFC9D9',
                  borderRight: '1px solid #BFC9D9',
                  borderBottom: '1px solid #BFC9D9',
                }}
              >
                Catatan
              </Td>
              <Td colSpan="3">
                <TableContainer
                  style={{
                    borderRight: '1px solid #BFC9D9',
                    borderBottom: '1px solid #BFC9D9',
                  }}
                  fullWidth
                >
                  <Tr>
                    <Td>No.So</Td>
                  </Tr>
                  <Tr>
                    <Td>Return</Td>
                  </Tr>
                  <Tr>
                    <Td>Badstock</Td>
                  </Tr>
                </TableContainer>
              </Td>
            </Tr>
          </TableContainer>
        )
      })}
      <Box display="flex" justifyContent="center">
        <Footer>
          <Box display="flex" gap="16px">
            <TableContainer width={'252px'} height={'60px'}>
              <Tr>
                <Td bordered colored centerText>
                  Wayback Checking
                </Td>
                <Td bordered colored centerText width={'50px'}>
                  ADA
                </Td>
                <Td bordered colored centerText width={'50px'}>
                  TIDAK
                </Td>
              </Tr>
              <Tr>
                <Td bordered colored centerText>
                  e-toll card
                </Td>
                <Td bordered />
                <Td bordered />
              </Tr>
              <Tr>
                <Td bordered colored centerText>
                  bon bensin
                </Td>
                <Td bordered />
                <Td bordered />
              </Tr>
            </TableContainer>
            <Checker>Diperiksa Oleh SPV:</Checker>
          </Box>
          <TableContainer width={'252px'} className="mt-10">
            <Tr>
              <Td bordered colored centerText>
                Box Tukar Guling Checking
              </Td>
              <Td bordered colored width={'70px'} style={{ padding: '0 4px' }}>
                Jml Ditagihkan
              </Td>
              <Td bordered colored width={'70px'} style={{ padding: '0 4px' }}>
                Jml Diberikan
              </Td>
            </Tr>
            {Array.from({ length: TUKAR_GULING_MAX_ROW }).map((_, i) => (
              <Tr key={`tukar_guling_${i + 1}`}>
                <Td bordered colored height="20px" />
                <Td bordered />
                <Td bordered />
              </Tr>
            ))}
          </TableContainer>
        </Footer>
      </Box>
    </Container>
  )
}

export default memo(TemplateSupplyOrderDeliveryNoteOld)

const Container = styled.div``

const TableContainer = styled.table`
  ${({ fullWidth }) => fullWidth && ` width: 100%;`}
  width: ${(props) => (props.width ? `${props.width}` : '')};
  height: ${(props) => (props.height ? `${props.height}` : '')};
  border-collapse: collapse;
  font-size: 12px;
  font-weight: 700;
  font-family: Nunito Sans;
`

const Tr = styled.tr`
  font-size: 12px;
  height: ${(props) => (props.height ? `${props.height}` : '')};
  ${({ bordered }) => bordered && `border: 1px solid #BFC9D9`}
  ${({ borderTop }) => borderTop && `border-top: 1px solid #BFC9D9`}
`
const Td = styled.td`
  font-size: 12px;
  width: ${(props) => (props.width ? `${props.width}` : '')};
  ${({ colored }) => colored && `background:#F3F4F5;`}
  ${({ centerText }) => centerText && `text-align: center;`}
  ${({ bordered }) => bordered && `border: 1px solid #BFC9D9`}
  ${({ borderLeft }) => borderLeft && `border-left: 1px solid #BFC9D9`}
  ${({ borderRight }) => borderRight && `border-right: 1px solid #BFC9D9`}
  ${({ borderBottom }) => borderBottom && `border-bottom: 1px solid #BFC9D9`}
  ${({ borderTop }) => borderTop && `border-top: 1px solid #BFC9D9`}
  ${({ spacedLeft }) => spacedLeft && `padding-left: 12px`}
`

const Footer = styled.div`
  margin-top: 8px;
`

const Checker = styled.div`
  border: 1px solid #8d96aa;
  border-radius: 8px;
  width: 220px;
  height: 60px;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  padding: 8px;
`
const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 10px;
  grid-gap: 8px;
  align-items: end;
`

const Logo = styled.img`
  width: 157.54px;
  height: 32px;
`
const DeliveryNoteText = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.5px;
`
const NoForm = styled.div`
  border: 1px solid #8d96aa;
  border-radius: 8px;
  width: 192px;
  height: 48px;
  font-weight: 700;
  font-size: 12px;
  padding: 8px;
`
const LogoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid #8d96aa;
  box-sizing: border-box;
`
const Label = styled.div`
  margin-bottom: 4px;
  display: flex;
  align-items: flex-end;
`
const HeaderLabelWrapper = styled.div`
  position: relative;
`
