import { memo } from "react";
import styled from "styled-components";
import { TextBody } from "components";
import dayjs from "dayjs";

function RowHubDetail({ data }) {
  return (
    <Tr>
      <Td ratio="4">
        <TextBody weight="light" color="textSoft">
          {data ? dayjs(data.created_at).format("DD MMM YYYY - HH:mm") : ""}
        </TextBody>
      </Td>
      <Td ratio="2">
        <TextBody weight="light" color="textSoft">
          {data?.operator}
          {data?.product_quantity}
        </TextBody>
      </Td>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {data?.product_stock}
        </TextBody>
      </Td>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {data?.type}
        </TextBody>
      </Td>
      <Td ratio="4">
        <TextBody weight="light" color="textSoft">
          {data?.action_by}
        </TextBody>
      </Td>
      <Td ratio="4">
        <TextBody weight="light" color="textSoft">
          {data?.origin_location_name}
        </TextBody>
      </Td>
      <Td ratio="4">
        <TextBody weight="light" color="textSoft">
          {data?.destination_location_name === null
            ? "-"
            : data?.destination_location_name}
        </TextBody>
      </Td>
      <Td ratio="4">
        <TextBody weight="light" color="textSoft">
          {data?.invoice_number}
        </TextBody>
      </Td>
    </Tr>
  );
}

export default memo(RowHubDetail);

const Tr = styled.tr`
  display: flex;
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
  .input-container {
    width: calc(100% - 20px);
  }
`;
const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  /* padding-right: ${({ pr }) => pr}; */
`;
