import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { postStagingAreaThunk } from './thunk'

const initialState = {
  showPopupStagingArea: false,
  isLoadingStaging: false,
}

const ongoingOrderSlice = createSlice({
  name: 'ongoingOrders',
  initialState,
  reducers: {
    setShowPopupStagingArea: (state, action: PayloadAction<boolean>) => {
      state.showPopupStagingArea = action.payload
    },
  },
  extraReducers(builder) {
    builder.addCase(postStagingAreaThunk.fulfilled, (state) => {
      state.isLoadingStaging = false
    })
    builder.addCase(postStagingAreaThunk.rejected, (state) => {
      state.isLoadingStaging = false
    })
    builder.addCase(postStagingAreaThunk.pending, (state) => {
      state.isLoadingStaging = true
    })
  },
})

export const { setShowPopupStagingArea } = ongoingOrderSlice.actions
export default ongoingOrderSlice.reducer
