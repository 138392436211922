import axios from 'axios'

const baseUrl = process.env.REACT_APP_BASE_URL_API

const apiGetTrimmingPackingList = '/api/wms/fresh-purchase/trimming-packing/v1/list'

const apiGetFpoTrimmingPackingDetail = (id: number) =>
  `/api/wms/fresh-purchase/trimming-packing/v1/conversion/${id}`
const apiGetGrnTrimmingPacking = (id: number) =>
  `/api/wms/fresh-purchase/trimming-packing/v1/grn/${id}`

export type TrimmingPackingStatusType = '' | 'NEW' | 'DONE' | 'RECEIVED'
export type ActionNameTrimmingPackingType = 'CHECK QUANTITIY' | 'PRINT GRN'
export type ActionValueTrimmingPackingType = 'check_quantity' | 'print_grn'
export interface TrimmingPackingItemType {
  id: number
  packingNumber: string
  inboundNumber: string
  fpoNumber: string
  location: {
    locationId: number
    locationName: string
  }
  totalItem: number
  status: TrimmingPackingStatusType
  createdAt: number
}

export interface PaginationResponseType {
  sort: string
  direction: string
  pageSize: number
  pageIndex: number
  numberOfElements: number
}
export interface ErrorResponseType {
  status: boolean
  message: string
  code: number
}
export interface GetTrimmingPackingResponseType {
  data: TrimmingPackingItemType[]
  error: ErrorResponseType
  pagination: PaginationResponseType
}

export type GetTrimmingPackingRequestType = Partial<
  Pick<TrimmingPackingItemType, 'inboundNumber' | 'fpoNumber' | 'packingNumber'>
> &
  Pick<PaginationResponseType, 'pageIndex' | 'pageSize'> & {
    status: TrimmingPackingStatusType | ''
    date?: string
    locationId?: number
  }

export interface TrimmingPackingDetaiConversionItemType {
  id: number
  freshPurchasePackingId: number
  product: {
    id: number
    name: string
    sku: string
    weight: number
    age: number
    baseUom: string[]
  }
  uom: string
  usedUom: string
  convertedQty: number
  usedQty: number
  recycleQty: number
  disposalQty: number
  overgramQty: number
  returnQty: number
  cogs: number
  createdAt: number
}
export interface GetTrimmingPackingDetailResponseType {
  data: {
    id: number
    freshPurchaseInboundId: number
    freshPurchaseId: number
    packingNumber: string
    inboundNumber: string
    fpoNumber: string
    fpoCreatedAt: number
    vendor: {
      vendorId: number
      vendorName: string
    }
    location: {
      locationId: number
      locationName: string
      locationType: string
    }
    status: TrimmingPackingStatusType
    createdAt: number
    createdBy: string
    conversions: TrimmingPackingDetaiConversionItemType[]
  }
}

export interface GetGrnFpoResponseType {
  data: {
    id: number
    grnNumber: string
    packingNumber: string
    fpoDate: number
    fpoNumber: string
    vendor: {
      vendorId: number
      vendorName: string
    }
    location: {
      locationId: number
      locationName: string
    }
    grnDate: number
    createdBy: string
    items: {
      product: {
        id: number
        name: string
        sku: string
        age: number
      }
      rack: {
        rackId: number
        rackName: string
      }
      qtySent: number
      qtyReceived: number
      uom: string
      expiredDate: number
      status: string
    }[]
  }
}

export const getTrimmingPackingList = (params: GetTrimmingPackingRequestType) =>
  axios.get<GetTrimmingPackingResponseType>(`${baseUrl}${apiGetTrimmingPackingList}`, { params })

export const getFpoTrimmingPackingDetail = (id: number) =>
  axios.get<GetTrimmingPackingDetailResponseType>(`${baseUrl}${apiGetFpoTrimmingPackingDetail(id)}`)

export const getGrnFpo = (id: number) =>
  axios.get<GetGrnFpoResponseType>(`${baseUrl}${apiGetGrnTrimmingPacking(id)}`)
