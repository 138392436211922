import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProductDataType, ProductDetailDataType } from '../../services/products'
import { fetchProducts, fetchProductDetail } from './thunk'

export interface ProductsStateType {
  products: ProductDataType[]
  selectedProducts: number[]
  detailProduct: ProductDetailDataType | null
  pagination: {
    total_elements: number
    prev_cursor: string
    next_cursor: string
  }
  isLoading: boolean
  isLoadingDetail: boolean
  error: string | null
}

const initialState: ProductsStateType = {
  products: [],
  selectedProducts: [],
  detailProduct: null,
  pagination: {
    total_elements: 0,
    prev_cursor: '',
    next_cursor: '',
  },
  isLoading: false,
  isLoadingDetail: false,
  error: null,
}

const productsSlice = createSlice({
  name: 'pricingEngineProducts',
  initialState,
  reducers: {
    selectProduct: (state, action: PayloadAction<number>) => {
      if (!state.selectedProducts.includes(action.payload)) {
        state.selectedProducts.push(action.payload)
      }
    },
    deselectProduct: (state, action: PayloadAction<number>) => {
      state.selectedProducts = state.selectedProducts.filter((id) => id !== action.payload)
    },
    selectAllProducts: (state, action: PayloadAction<number[]>) => {
      state.selectedProducts = action.payload
    },
    clearSelectedProducts: (state) => {
      state.selectedProducts = []
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.isLoading = false
        state.products = action.payload.data
        state.pagination = action.payload.pagination
        state.error = null
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message || 'An error occurred'
      })
      .addCase(fetchProductDetail.pending, (state) => {
        state.isLoadingDetail = true
        state.error = null
      })
      .addCase(fetchProductDetail.fulfilled, (state, action) => {
        state.isLoadingDetail = false
        state.detailProduct = action.payload
        state.error = null
      })
      .addCase(fetchProductDetail.rejected, (state, action) => {
        state.isLoadingDetail = false
        state.error = action.error.message || 'An error occurred while fetching product detail'
      })
  },
})

export const { selectProduct, deselectProduct, selectAllProducts, clearSelectedProducts } =
  productsSlice.actions
export default productsSlice.reducer
