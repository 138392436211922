import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { initDatadogLog } from 'utils/helpers/dataDogLog'

const {
  REACT_APP_DATADOG_CLIENT_TOKEN,
  REACT_APP_DATADOG_SITE,
  REACT_APP_DATADOG_SESSION_SAMPLE_RATE,
  REACT_APP_ENV,
  REACT_APP_VERSION,
  REACT_APP_NAME,
  REACT_APP_ENABLE_DATADOG,
} = process.env

const useAppInit = () => {
  const location = useLocation()

  useEffect(() => {
    if (REACT_APP_ENABLE_DATADOG) {
      initDatadogLog({
        clientToken: REACT_APP_DATADOG_CLIENT_TOKEN as string,
        site: REACT_APP_DATADOG_SITE,
        forwardErrorsToLogs: false,
        sessionSampleRate: Number(REACT_APP_DATADOG_SESSION_SAMPLE_RATE),
        env: REACT_APP_ENV === 'production' ? 'production' : 'development',
        service: REACT_APP_NAME,
        version: REACT_APP_VERSION,
      })
    }
  }, [])

  useEffect(() => {
    const onUpdateServiceWorker = async () => {
      if ('serviceWorker' in navigator) {
        const registration = await navigator.serviceWorker.getRegistration()
        if (registration) {
          registration.update()
        }
      }
    }

    onUpdateServiceWorker()
  }, [location.pathname])
}

export default useAppInit
