import {
  SET_IS_LOADING_HUB,
  SET_PRODUCTS_CAN_ADD,
  SET_ID_PRODUCT_SELECTED,
  SET_HUBS,
  SET_SELECTED_HUB,
  SET_CHECK_ALL,
  SET_INVENTORIES,
  SET_DATA_PAGINATION_HUB,
  SET_DATA_PAGINATION_INVENTORY,
  SET_LAST_QUERY_HUB,
  SET_SELECTED_HUB_FILTER,
  SET_RAK_OPTIONS_HUB,
  SET_QUERY_PARAMS_INVENTORY,
} from 'storeContext/actionsType'

export const initialStateHub = {
  isLoading: false,
  dataPaginationInventory: {},
  queryParamsInventory: {
    location_id: 0,
    search_product: '',
    pagination: {
      page_index: 1,
      page_size: 20,
      direction: 'desc',
      sort: 'created_at',
    },
  },
  productsCanAdd: [],
  idProductsSelected: [],
  hubs: [],
  selectedHub: {},
  isCheckAll: false,
  inventories: [],
  dataPaginationHub: {},
  lastQueryHub: '',
  selectedHubFilter: {},
  rakOptionsData: [],
}

export default function hub(state = initialStateHub, { type, payload }) {
  switch (type) {
    case SET_QUERY_PARAMS_INVENTORY:
      return {
        ...state,
        queryParamsInventory: {
          ...state.queryParamsInventory,
          ...payload,
        },
      }
    case SET_IS_LOADING_HUB:
      return {
        ...state,
        isLoading: payload,
      }
    case SET_PRODUCTS_CAN_ADD:
      return {
        ...state,
        productsCanAdd: payload,
      }
    case SET_ID_PRODUCT_SELECTED:
      return {
        ...state,
        idProductsSelected: payload,
      }
    case SET_HUBS:
      return {
        ...state,
        hubs: payload,
      }
    case SET_SELECTED_HUB:
      return {
        ...state,
        selectedHub: payload,
      }
    case SET_CHECK_ALL:
      return {
        ...state,
        isCheckAll: payload,
      }
    case SET_DATA_PAGINATION_INVENTORY:
      return {
        ...state,
        dataPaginationInventory: payload,
      }
    case SET_INVENTORIES:
      return {
        ...state,
        inventories: payload,
      }
    case SET_DATA_PAGINATION_HUB:
      return {
        ...state,
        dataPaginationHub: payload,
      }
    case SET_LAST_QUERY_HUB:
      return {
        ...state,
        lastQueryHub: payload,
      }
    case SET_SELECTED_HUB_FILTER:
      return {
        ...state,
        selectedHubFilter: payload,
      }
    case SET_RAK_OPTIONS_HUB:
      return {
        ...state,
        rakOptionsData: payload,
      }
    default:
      return state
  }
}
