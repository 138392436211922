import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type SelectedOptionType = {
  name: string
  value: string | number
  childs?: SelectedOptionType[]
}

export type MultipleSelectFieldNameType = 'selectedProductType' | 'selectedProductCategory'

export interface ItemsProductGroupInterface {
  productId: number
  productName: string
  skuNumber: string
  count: number
}

export type ProductAssortmentPaginationType = {
  pageSize: number
  pageIndex: number
}
export interface ProductAssortmentDataInterface {
  categoryId: number
  categoryName: string
  isExpanded: boolean
  items: ItemsProductGroupInterface[]
  isChecked: boolean
  pagination: ProductAssortmentPaginationType
  isLoading: boolean
}

export interface ProductAssortmentSliceInterface {
  isLoading: boolean
  selectedProductType: Nullable<SelectedOptionType>
  selectedProductCategory: SelectedOptionType[]
  skuName: string
  status: string
  productAssortmentList: ProductAssortmentDataInterface[]
  selectedIds: number[]
  filterErrorMessages: {
    selectedProductCategory: string
  }
  isModalLocationOpen: boolean
  selectedLocationType: {
    name: string
    value: number
  }
  filterModalLocation: {
    locationName: string
    locationTypeId: number
  }
  selectedLocationIds: number[]
  modalConfirmation: {
    isOpen: boolean
    product: {
      productId: number
      productName: string
      qtyLocation: number
    }[]
    qtyLocationNew: number
  }
  productCategories: SelectedOptionType[]
  productTypes: SelectedOptionType[]
}

export const SLICE_NAME = 'productAssortment'
export const MAX_CATEGORY = 3

const initialState: ProductAssortmentSliceInterface = {
  isLoading: false,
  productCategories: [],
  productTypes: [],
  selectedProductType: null,
  selectedProductCategory: [],
  skuName: '',
  status: '',
  productAssortmentList: [],
  selectedIds: [],
  filterErrorMessages: {
    selectedProductCategory: '',
  },
  isModalLocationOpen: false,
  selectedLocationType: {
    name: '',
    value: 0,
  },
  filterModalLocation: {
    locationName: '',
    locationTypeId: 0,
  },
  selectedLocationIds: [],
  modalConfirmation: {
    isOpen: false,
    product: [],
    qtyLocationNew: 0,
  },
}

const productAssortmentSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setSkuName: (state, action: PayloadAction<string>) => {
      state.skuName = action.payload
    },
    setSelectedCombobox: (
      state,
      action: PayloadAction<{
        fieldName: MultipleSelectFieldNameType
        selected: never
      }>,
    ) => {
      const { fieldName, selected } = action.payload
      state[fieldName] = selected
    },
    validateFilterForm: (state) => {
      state.filterErrorMessages.selectedProductCategory = ''
      if (!state.skuName && !state.selectedProductCategory.length) {
        state.filterErrorMessages.selectedProductCategory = 'Product Category wajib dipilih'
      } else if (state.selectedProductCategory.length > MAX_CATEGORY) {
        state.filterErrorMessages.selectedProductCategory = 'Max 3 kategori'
      }
    },
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload
    },
    setProductAssortmentListData: (
      state,
      action: PayloadAction<ProductAssortmentDataInterface[]>,
    ) => {
      state.productAssortmentList = action.payload
    },
    addMoreItemsFromLoadMore: (
      state,
      action: PayloadAction<{
        categoryId: number
        list: ItemsProductGroupInterface[]
        resetItems?: boolean
      }>,
    ) => {
      const { categoryId, list, resetItems = false } = action.payload
      const index = state.productAssortmentList.findIndex((item) => item.categoryId === categoryId)

      state.productAssortmentList[index].items = [
        ...(resetItems ? [] : state.productAssortmentList[index].items),
        ...list,
      ]
    },
    toggleProductGroup: (state, action: PayloadAction<number>) => {
      const index = action.payload
      state.productAssortmentList[index].isExpanded = !state.productAssortmentList[index].isExpanded
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setLoadingPerItem: (state, { payload }: PayloadAction<{ index: number; value: boolean }>) => {
      const { index, value } = payload
      state.productAssortmentList[index].isLoading = value
    },
    setPaginationIndex: (
      state,
      { payload }: PayloadAction<{ index: number; pageIndex: number }>,
    ) => {
      const { index, pageIndex } = payload
      state.productAssortmentList[index].pagination.pageIndex = pageIndex
    },
    tickItemsInProductGroup: (state, action: PayloadAction<ItemsProductGroupInterface>) => {
      const { productId, productName, count } = action.payload

      const indexFound = state.selectedIds.findIndex((id) => id === productId)
      const indexProductOnConfimModal = state.modalConfirmation.product.findIndex(
        (item) => item.productId === productId,
      )

      if (indexFound === -1) {
        state.selectedIds.push(productId)

        if (indexProductOnConfimModal === -1)
          state.modalConfirmation.product.push({
            productId,
            productName,
            qtyLocation: count,
          })
        return
      }

      const idxInModalConfirmationToDelete = state.modalConfirmation.product.findIndex(
        (productItem) => productItem.productId === productId,
      )
      state.selectedIds.splice(indexFound, 1)
      state.modalConfirmation.product.splice(idxInModalConfirmationToDelete, 1)
    },
    tickAllItemsOfParentGroup: (
      state,
      action: PayloadAction<{ indexParent: number; purpose: 'tick' | 'untick' }>,
    ) => {
      const { indexParent, purpose } = action.payload
      const allItemIds = state.productAssortmentList[indexParent].items.map(
        (item) => item.productId,
      )

      if (purpose === 'untick') {
        state.selectedIds = state.selectedIds.filter((id) => !allItemIds.includes(id))
        state.modalConfirmation.product = state.modalConfirmation.product.filter((item) =>
          state.selectedIds.includes(item.productId),
        )
        return
      }

      const idsThatAreNotInSelectedIdsYet = allItemIds.filter(
        (id) => !state.selectedIds.includes(id),
      )
      const listitemDataNotSelectedYet = state.productAssortmentList[indexParent].items
        .filter((itemData) => idsThatAreNotInSelectedIdsYet.includes(itemData.productId))
        .map((item) => ({
          productId: item.productId,
          productName: item.productName,
          qtyLocation: item.count,
        }))

      state.selectedIds = [...state.selectedIds, ...idsThatAreNotInSelectedIdsYet]
      state.modalConfirmation.product = [
        ...state.modalConfirmation.product,
        ...listitemDataNotSelectedYet,
      ]
    },
    resetTickItemsInProductGroup: (state) => {
      state.selectedIds = initialState.selectedIds
      state.modalConfirmation.product = initialState.modalConfirmation.product
    },
    setIsModalLocationOpen: (state, action: PayloadAction<boolean>) => {
      state.isModalLocationOpen = action.payload
    },
    setSelectedLocationType: (
      state,
      action: PayloadAction<{
        name: string
        value: number
      }>,
    ) => {
      state.selectedLocationType = action.payload
      state.filterModalLocation.locationTypeId = action.payload.value
    },
    setFilterModalLocation: (
      state,
      action: PayloadAction<{
        fieldName: keyof ProductAssortmentSliceInterface['filterModalLocation']
        value: string | number
      }>,
    ) => {
      const { fieldName, value } = action.payload
      state.filterModalLocation[fieldName] = value as never
    },
    tickLocationIdInModalLocation: (state, action: PayloadAction<number>) => {
      const locationId = action.payload
      const indexFound = state.selectedLocationIds.findIndex((id) => id === locationId)

      if (indexFound === -1) {
        state.selectedLocationIds.push(locationId)
        return
      }
      state.selectedLocationIds = state.selectedLocationIds.filter((id) => id !== locationId)
    },
    resetTickLocationInModalLocation: (state) => {
      state.selectedLocationIds = []
    },
    setModalConfirmationData: (
      state,
      action: PayloadAction<Partial<ProductAssortmentSliceInterface['modalConfirmation']>>,
    ) => {
      const { product, qtyLocationNew, isOpen } = action.payload
      if (isOpen?.toString()) state.modalConfirmation.isOpen = isOpen
      if (product) state.modalConfirmation.product = product
      if (qtyLocationNew) state.modalConfirmation.qtyLocationNew = qtyLocationNew
    },
    setProductCategories: (state, { payload }: PayloadAction<SelectedOptionType[]>) => {
      state.productCategories = [...payload]
    },
    setProductTypes: (state, { payload }: PayloadAction<SelectedOptionType[]>) => {
      state.productTypes = [...payload]
    },
    resetState: () => initialState,
  },
})

export const {
  setSkuName,
  setSelectedCombobox,
  setStatus,
  setProductAssortmentListData,
  toggleProductGroup,
  tickItemsInProductGroup,
  tickAllItemsOfParentGroup,
  addMoreItemsFromLoadMore,
  validateFilterForm,
  setIsModalLocationOpen,
  setSelectedLocationType,
  setFilterModalLocation,
  tickLocationIdInModalLocation,
  setModalConfirmationData,
  setProductCategories,
  setProductTypes,
  resetState,
  setLoading,
  setLoadingPerItem,
  setPaginationIndex,
  resetTickLocationInModalLocation,
  resetTickItemsInProductGroup,
} = productAssortmentSlice.actions

export default productAssortmentSlice.reducer
