import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

const { Get, Post, Put } = axiosInstanceApiGateway

type GetWatchtowerAreaListResponseType = {
  watch_tower: {
    radius: number
    status: boolean
    id: string
    name: string
    end: string
    use_period: boolean
    start: string
    coordinate: string
  }[]
}

type GetWatchtowerAreaListRequestType = {
  params: {
    limit: number
    page: number
  }
}

type PostWatchtowerAreaResquestType = {
  payload: {
    radius: string
    coordinate: string
    name: string
    start: string
    end: string
  }
}

type PutWatchtowerAreaResquestType = { id: string } & PostWatchtowerAreaResquestType

type GetWatchtowerAreaByIDRequestType = {
  id: string
}
type GetWatchtowerAreaByIDResponseType = {
  watch_tower: {
    radius: string
    status: boolean
    id: string
    name: string
    end: string
    use_period: boolean
    start: string
    coordinate: string
  }
}

type PutWatchtowerAreaStatusRequestType = {
  payload: {
    status: boolean
  }
  id: string
}

export const getWatchtowerAreaList = ({ params }: GetWatchtowerAreaListRequestType) =>
  Get<GetWatchtowerAreaListResponseType>({ url: `/location/internal/v1/watchtower-area`, params })

export const postWatchtowerArea = ({ payload }: PostWatchtowerAreaResquestType) =>
  Post({ url: '/location/internal/v1/watchtower-area', data: payload })

export const putWatchtowerArea = ({ payload, id }: PutWatchtowerAreaResquestType) =>
  Put({ url: `/location/internal/v1/watchtower-area/${id}`, data: payload })

export const getWatchtowerAreaByID = ({ id }: GetWatchtowerAreaByIDRequestType) =>
  Get<GetWatchtowerAreaByIDResponseType>({ url: `/location/internal/v1/watchtower-area/${id}` })

export const putWatchtowerAreaStatus = ({ payload, id }: PutWatchtowerAreaStatusRequestType) =>
  Put({ url: `/location/internal/v1/watchtower-area/status/${id}`, data: payload })
