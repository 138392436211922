import React from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'

function DropZone({ onDrop, children, accept, multiple, style, disabled, options = {} }) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
    multiple,
    disabled,
  })

  return (
    <Container {...getRootProps({ style })} active={isDragActive}>
      <input {...getInputProps()} />
      {children}
    </Container>
  )
}

DropZone.defaultProps = {
  onDrop: () => {},
  accept: undefined,
  options: {},
  multiple: true,
  style: {},
  disabled: false,
}

export default DropZone

const Container = styled.div`
  background-color: ${({ theme: { colors }, active }) =>
    active ? colors.mainFaded : 'transparent'};
  transition: all ease 0.5s;
`
