import axios from 'axios'

const baseUrl = process.env.REACT_APP_BASE_URL_API

export type BlackAndWhiteListStatusType = 'ACTIVE' | 'INACTIVE'

export type BlackAndWhiteListCreatorType = 'admin' | 'system'

export type GetBlackAndWhiteListCustomerParamsType = {
  fromDate?: string
  toDate?: string
  customerId?: string
  status?: BlackAndWhiteListStatusType
  pageSize?: number
  sort?: 'createdAt' | 'status'
  direction?: 'ASC' | 'DESC'
}

export type PostPutBlackAndWhiteListCustomerPayloadType = {
  customerId?: number
  status: BlackAndWhiteListStatusType
  notes?: string
  reason?: string
}

export type GetBlackAndWhiteListDeviceParamsType = {
  fromDate?: string
  toDate?: string
  deviceId?: string
  status?: BlackAndWhiteListStatusType
}

export type PostPutBlackAndWhiteListDevicePayloadType = {
  deviceId: string
  status: BlackAndWhiteListStatusType
  notes?: string
  reason?: string
}

export type BlackAndWhiteListCustomerType = {
  id: number
  customerId: number
  status: BlackAndWhiteListStatusType
  createdAt: string
  createdBy: BlackAndWhiteListCreatorType
  updatedAt: string
  updatedBy: string
}

export type BlackAndWhiteListDeviceType = {
  id: number
  deviceId: string
  status: BlackAndWhiteListStatusType
  createdAt: string
  createdBy: BlackAndWhiteListCreatorType
  updatedAt: string
  updatedBy: string
}

export type GetBlacklistCustomerResponseType = {
  data: {
    blacklistCustomer: BlackAndWhiteListCustomerType
  }
  pagination: GetBlackAndWhiteListCustomerParamsType
}

// === whitelist customer === //
export const getWhitelistCustomer = (params: GetBlackAndWhiteListCustomerParamsType) =>
  axios.get(`${baseUrl}/fraud/whitelist/customer`, { params })

export const postBulkWhitelistCustomer = (payload: FormData) =>
  axios.post(`${baseUrl}/fraud/whitelist/customer/bulk`, payload)

// === whitelist device === //
export const getWhitelistDevice = (params: GetBlackAndWhiteListDeviceParamsType) =>
  axios.get(`${baseUrl}/fraud/whitelist/device`, { params })

export const postBulkWhitelistDevice = (payload: FormData) =>
  axios.post(`${baseUrl}/fraud/whitelist/device/bulk`, payload)

// === blacklist customer === //
export const getBlacklistCustomer = (params: GetBlackAndWhiteListCustomerParamsType) =>
  axios.get(`${baseUrl}/fraud/blacklist/customer`, { params })

export const postBulkBlacklistCustomer = (payload: FormData) =>
  axios.post(`${baseUrl}/fraud/blacklist/customer/bulk`, payload)

// === blacklist device === //
export const getBlacklistDevice = (params: GetBlackAndWhiteListDeviceParamsType) =>
  axios.get(`${baseUrl}/fraud/blacklist/device`, { params })

export const postBulkBlacklistDevice = (payload: FormData) =>
  axios.post(`${baseUrl}/fraud/blacklist/device/bulk`, payload)

// === others === //
export const getBulkTemplateDevice = () =>
  axios.get(`${baseUrl}/api/fraud/bulk/device/csv/template`)

export const getBulkTemplateCustomer = () =>
  axios.get(`${baseUrl}/api/fraud/bulk/customer/csv/template`)
