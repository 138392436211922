import AxiosInstance from 'config/apiServiceApiGateway'
// PARAMS INTERFACES
// Role List
type GetRolesListParamsType = {
  sort?: string
  direction?: string
  size?: number
  index?: number
  name?: string
}

// Page Permission
export type GetPagePermissionParamsType = {
  name?: string
  page_index?: number
  page_size?: number
}

// POST Create Role

export type PostCreateRoleParamsType = {
  name: string
  permission_ids: number[]
}

// Put Create Role

export type PutCreateRoleParamsType = {
  name: string
  permissions: {
    is_deleted: boolean
    id: string
  }[]
}

// END OF PARAMS INTERFACES

// RESPONSE INTERFACES

interface ApiErrorInterface {
  message: string
  status: boolean
  code: number
}

export interface PaginationInterface {
  number_of_elements: number
  page_size: number
  total_pages: number
  sort: string
  direction: string
  total_elements: number
  page_index: number
}

// Role List
interface GetRolesListResponseInterface {
  data: GetRolesListDataInterface
  error: ApiErrorInterface
}

interface GetRolesListDataInterface {
  roles: RoleDetailInterface[]
  pagination: PaginationInterface
}

export interface RoleDetailInterface {
  created_at: number
  updated_at: number
  permissions: RolesPermissionInterface[]
  name: string
  updated_by: string
  id: number
  created_by: string
}

export interface RolesPermissionInterface {
  name: string
  id: number
}

// Post Create Role

interface PostCreateRoleResponseInterface {
  data: PostCreateRoleDataInterface
  error: ApiErrorInterface
}

interface PostCreateRoleDataInterface {
  name: string
  id: number
}

// Put Create Role

interface PutCreateRoleResponseInterface {
  data: PutCreateRoleDataInterface
  error: ApiErrorInterface
}

interface PutCreateRoleDataInterface {
  success: boolean
  name: string
  id: number
}

// Get Role By Id.

export interface GetRolesByIdResponseInterface {
  data: GetRolesByIdDataInterface
  error: ApiErrorInterface
}

export interface GetRolesByIdDataInterface {
  role: RoleDetailInterface
}

// END OF Role List Response Interfaces

// Page Permission Interfaces
export interface GetPagePermissionResponseInterface {
  data: GetPagePermissionDataInterface
  error: ApiErrorInterface
}

export interface GetPagePermissionDataInterface {
  page_permissions: GetPagePermissionContentInterface[]
  pagination: PaginationInterface
}

export interface GetPagePermissionContentInterface {
  name: string
  id: number
  permissions: GetPagePermissionDetailInterface[]
}

export interface GetPagePermissionDetailInterface {
  permission_id: number
  permission_name: string
}
// END OF Page Permission Interfaces

export const getRolesListApi = (params: GetRolesListParamsType) =>
  AxiosInstance.Get<GetRolesListResponseInterface, true>({
    url: '/auth/internal/v1/role',
    version: 'v1',
    params,
  })

export const getRoleByIdApi = (id: number) =>
  AxiosInstance.Get<GetRolesByIdResponseInterface, true>({
    url: `/auth/internal/v1/role/${id}`,
  })

// there is a possibility that this can be using the same function api call in other services (page).
// but for now, we will create a new function.
export const getPagePermissionsApi = (params: GetPagePermissionParamsType) =>
  AxiosInstance.Get<GetPagePermissionResponseInterface, true>({
    url: '/auth/internal/v1/page/permission',
    version: 'v1',
    params,
  })

export const postCreateRoleApi = (payload: PostCreateRoleParamsType) =>
  AxiosInstance.Post<PostCreateRoleResponseInterface, true>({
    url: '/auth/internal/v1/role',
    data: payload,
  })

export const putCreateRoleApi = (payload: PutCreateRoleParamsType, id: number) =>
  AxiosInstance.Put<PutCreateRoleResponseInterface, true>({
    url: `/auth/internal/v1/role/${id}`,
    data: payload,
  })

export const deleteRoleApi = (id: number) =>
  AxiosInstance.Delete<PutCreateRoleResponseInterface, true>({
    url: `/auth/internal/v1/role/${id}`,
  })
