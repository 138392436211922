import axiosInstanceApiGateway from 'config/apiServiceApiGateway'
import { Get } from 'config/apiServiceConfig'

const { Post } = axiosInstanceApiGateway
export type PayloadStagingAreaType = {
  location_id: string
  staging_area: string
}

type GetOrderHubDataResponseType = {
  data: {
    address: string
    city: string
    customerId: number
    defaultAddress: boolean
    description: string
    district: string
    fullName: string
    id: number
    isActive: boolean
    labelAddress: string
    latitude: string
    locationId: number
    locationOpen: string
    locationStatus: boolean
    longitude: string
    phone: string
    placeName: string
    province: string
    serviceLevelId: number
    serviceLevelName: Nullable<string>
    serviceLevelTotalDuration: number
    shippingInstruction: string
    subDistrict: string
    zip: string
  }
}

export const postStagingArea = async (orderId: string, payload: PayloadStagingAreaType) =>
  Post({
    url: `fulfillment/internal/v1/post-packing/orders/${orderId}/staging-area`,
    data: payload,
  })

export const getOrderHubData = async (payload: { addressId: number; customerId: number }) => {
  const { customerId, addressId } = payload
  return Get<GetOrderHubDataResponseType>({
    url: `/api/customers/${customerId}/addresses/${addressId}`,
  })
}
