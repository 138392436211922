import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import {
  GetAllPageIDListResponsesInterface,
  QueryURLPageIDListType,
} from 'features/Account/@types/adminAccessPageIDList'

export const SLICE_NAME = 'adminAccessPageIDSliceName'

export interface AdminAccessPageIDStateInterface {
  isLoadingList: boolean
  dialogState: 'CLOSED' | 'OPEN_DIALOG_UPLOAD_JSON'
  isUploadingJSON: boolean
  pageIDListData: GetAllPageIDListResponsesInterface
  queryURL: QueryURLPageIDListType
}

export const initialState: AdminAccessPageIDStateInterface = {
  isLoadingList: false,
  dialogState: 'CLOSED',
  isUploadingJSON: false,
  queryURL: { sort: `updated_at`, direction: `DESC`, page_size: 10, page_index: 0, name: `` },
  pageIDListData: {
    pages: [],
    pagination: {
      direction: 'asc',
      number_of_elements: 0,
      page_index: 0,
      page_size: 0,
      sort: '',
      total_elements: 0,
      total_pages: 0,
    },
  },
}

const adminAccessPageIDSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setLoadingList: (
      state,
      action: PayloadAction<AdminAccessPageIDStateInterface['isLoadingList']>,
    ) => {
      state.isLoadingList = action.payload
    },
    setAdminAccessPageIDList: (
      state,
      action: PayloadAction<GetAllPageIDListResponsesInterface>,
    ) => {
      state.pageIDListData = action.payload
    },
    setAdminURLQuery: (
      state,
      action: PayloadAction<AdminAccessPageIDStateInterface['queryURL']>,
    ) => {
      state.queryURL = action.payload
    },
    toggleDialogUploadJSON: (
      state,
      action: PayloadAction<AdminAccessPageIDStateInterface['dialogState']>,
    ) => {
      state.dialogState = action.payload
    },
    setIsUploadingJSON: (
      state,
      action: PayloadAction<AdminAccessPageIDStateInterface['isUploadingJSON']>,
    ) => {
      state.isUploadingJSON = action.payload
    },
  },
})

export const {
  reset,
  setAdminAccessPageIDList,
  setLoadingList,
  setAdminURLQuery,
  toggleDialogUploadJSON,
  setIsUploadingJSON,
} = adminAccessPageIDSlice.actions

export default adminAccessPageIDSlice.reducer
