import { useState, useEffect } from 'react'
import dayjs from 'dayjs'

export interface TimeTickerPropsType {
  order_status: string
  order_payment_at: number
  order_created_at: number
  order_cancel_at: number
  order_arrived_at: number
  order_completed_at: number
  order_payment_status: string
}

const useTimeTicker = ({
  order_status,
  order_payment_at,
  order_created_at,
  order_cancel_at,
  order_arrived_at,
  order_completed_at,
  order_payment_status,
}: TimeTickerPropsType) => {
  const timerConfig = {
    limitMinute: 2300,
    limiter: true,
    timeEnded: !!(order_status === 'Cancelled' || order_status === 'Completed'),
  }

  // INTIAL STATE
  const initTimer = () => {
    let start
    start = dayjs(order_payment_at)

    if (order_payment_at) {
      start = dayjs(order_payment_at)
    } else {
      start = dayjs(order_created_at)
    }

    let end = dayjs()

    if (order_status === 'Cancelled') {
      end = dayjs(order_cancel_at)
    } else if (order_status === 'Payment Expired') {
      end = dayjs(order_cancel_at)
    } else if (order_status === 'Completed') {
      end = order_arrived_at ? dayjs(order_arrived_at) : dayjs(order_completed_at)
    } else if (order_status === 'Arrived') {
      end = order_arrived_at ? dayjs(order_arrived_at) : dayjs(order_completed_at)
    } else {
      end = dayjs()
    }

    const second = end.diff(start, 's')
    const minute = Math.floor(second / 60)
    const hour = Math.floor(minute / 60)

    return {
      second: second - minute * 60,
      minute: minute - hour * 60,
      hour,
    }
  }

  const [timer, setTimer] = useState(initTimer)
  const [isTimerStop, setIsTimerStop] = useState(false)

  useEffect(() => {
    const ticker = setTimeout(() => {
      if (timerConfig.timeEnded) {
        setTimer(initTimer)
        setIsTimerStop(true)

        if (isTimerStop) {
          clearTimeout(ticker)
        }
      } else {
        setTimer(initTimer)
      }
    }, 1000)
    return () => clearTimeout(ticker)
  })

  const renderTimer = () => {
    let renderHour = 'no status'
    let renderminute = ''
    let renderSecond = ''

    function changeRenderTime(hour: string, minute: string, second: string) {
      renderHour = hour
      renderminute = minute
      renderminute = minute.length === 1 ? `0${minute}` : minute
      renderSecond = second.length === 1 ? `0${second}` : second
    }

    if (order_payment_status === 'Payment Verified') {
      changeRenderTime(String(timer.hour), String(timer.minute), String(timer.second))
    } else {
      changeRenderTime('0', '00', '00')
    }
    const hourParsed = renderHour === '0' ? '' : `${renderHour} :`
    return `${hourParsed} ${renderminute} : ${renderSecond}`
  }

  return {
    renderTimer,
  }
}

export default useTimeTicker
