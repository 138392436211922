import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const AdminAccessRolesRBAC = {
  pageID: '7b09d55e3d76538f03ec0b827a0c4c33',
  elementID: {
    ButtonSaveEdit: '341f3deae5f803b0db93c464458bba71',
    DeleteRole: '98bb2917011b3f899145f566c12d316e',
    ViewRoleList: '096a0fb6497a63aeeed8412e798e3410',
    ButtonViewEditRole: '069abae11798a83fdae17ac05bd43edb',
    AddRoleButton: '26e23a3beb1b5569048b21e3f7d65606',
  },
} as const

export const useRBACAdminAccessRolesPage = createUseRBAC(AdminAccessRolesRBAC.elementID)

export type UseRBACAdminAccessRolesPageType = ReturnType<typeof useRBACAdminAccessRolesPage>
