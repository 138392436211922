import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
  SLICE_NAME,
  fetchReferralMissionList,
  fetchFraudReferralList,
  updateReleaseFraudReferral,
} from './fraudReferralThunk'
import { MissionStateType } from '../referralMission/list'

type ReferralMissionDataType = MissionStateType['list'][0] & { name: string }

export type FraudReferralStateType = {
  isLoading: boolean
  data: {
    customerId: number
    counter: number
    createdAt: string
    isReferralFraud: boolean
    uniqueKey: string
    campaignReferralId: number
  }[]
  query: {
    customerId: number
    pageIndex: number
    campaignReferralId: number
    campaignReferralName: string
  }
  referralMission: {
    data: ReferralMissionDataType[]
    query: {
      pageIndex: number
      missionName: string
    }
  }
}

const initialState: FraudReferralStateType = {
  isLoading: false,
  data: [],
  query: {
    customerId: 0,
    pageIndex: 0,
    campaignReferralId: 0,
    campaignReferralName: '',
  },
  referralMission: { data: [], query: { missionName: '', pageIndex: 0 } },
}

export type SetQueryPayloadType = {
  name: keyof FraudReferralStateType['query']
  value: string | number
}

export type SetReferralMissionQueryType = {
  name: keyof FraudReferralStateType['referralMission']['query']
  value: string | number
}

const blackAndWhiteList = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setQuery: (state, action: PayloadAction<SetQueryPayloadType>) => {
      const { name, value } = action.payload
      state.query[name] = value as never
    },
    setReferralMissionQuery: (state, action: PayloadAction<SetReferralMissionQueryType>) => {
      const { name, value } = action.payload
      state.referralMission.query[name] = value as never
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchReferralMissionList.fulfilled, (state, action) => {
        const { data } = action.payload
        state.referralMission.data = data.list.map((el) => ({
          ...el,
          uniqueId: window.crypto.randomUUID(),
          name: `${el.id} - ${el.missionName}`,
        }))
      })
      .addCase(fetchFraudReferralList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFraudReferralList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchFraudReferralList.fulfilled, (state, action) => {
        const { responses } = action.payload
        state.isLoading = false
        state.data = responses.map((el) => ({ ...el, uniqueKey: window.crypto.randomUUID() }))
      })
      .addCase(updateReleaseFraudReferral.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateReleaseFraudReferral.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(updateReleaseFraudReferral.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const { reset, setQuery, setReferralMissionQuery } = blackAndWhiteList.actions
export default blackAndWhiteList.reducer
