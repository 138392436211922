import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'

export type ChipPropsType = MuiChipProps & SpaceProps

const Chip = styled(MuiChip)<ChipPropsType>`
  ${space}

  font-weight: 700;
`

export default Chip
