export const SET_IS_START_CAMPAIGN = 'SET_IS_START_CAMPAIGN'
export const SET_IS_FAILED_CAMPAIGN = 'SET_IS_FAILED_CAMPAIGN'

export const SET_IS_HUB_LIST_DATA_CAMPAIGN_ADD_MANUAL = 'SET_IS_HUB_LIST_DATA_CAMPAIGN_ADD_MANUAL'
export const SET_IS_CATEGORY_LIST_DATA_CAMPAIGN = 'SET_IS_CATEGORY_LIST_DATA_CAMPAIGN'

export const RESET_CAMPAIGN = 'RESET_CAMPAIGN'

//CAMPAIGN
export const SET_IS_SUCCESS_CAMPAIGN = 'SET_IS_SUCCESS_CAMPAIGN'
export const SET_PAGINATION_DATA_CAMPAIGN = 'SET_PAGINATION_DATA_CAMPAIGN'
export const SET_IS_NEED_TO_RELOAD_CAMPAIGN = 'SET_IS_NEED_TO_RELOAD_CAMPAIGN'

//CAMPAIGN ADD
export const SET_IS_SUCCESS_CAMPAIGN_ADD = 'SET_IS_SUCCESS_CAMPAIGN_ADD'
export const SET_IS_DETAIL_DATA_CAMPAIGN_ADD = 'SET_IS_DETAIL_DATA_CAMPAIGN_ADD'
export const SET_PAGINATION_DATA_CAMPAIGN_ADD = 'SET_PAGINATION_DATA_CAMPAIGN_ADD'
export const SET_IS_NEED_TO_RELOAD_CAMPAIGN_ADD = 'SET_IS_NEED_TO_RELOAD_CAMPAIGN_ADD'

//CAMPAIGN ADD MANUAL
export const SET_IS_SUCCESS_CAMPAIGN_ADD_MANUAL = 'SET_IS_SUCCESS_CAMPAIGN_ADD_MANUAL'
export const SET_PAGINATION_DATA_CAMPAIGN_ADD_MANUAL = 'SET_PAGINATION_DATA_CAMPAIGN_ADD_MANUAL'
export const SET_IS_NEED_TO_RELOAD_CAMPAIGN_ADD_MANUAL = 'SET_IS_NEED_TO_RELOAD_CAMPAIGN_ADD_MANUAL'
