import { memo } from 'react'
import { Box } from '@astro-ui/components'
import styled from 'styled-components'

import { type CustomerType } from 'redux/banner'

const { Button, SystemIcon, TextBody } = require('components')

interface TableTargetedUserPropsInterface {
  selectedCustomerList: CustomerType[]
  onDeleteCustomer: (customer: TableTargetedUserPropsInterface['selectedCustomerList'][0]) => void
}

const MAX_ROW = 40

const TableTargetedUser = ({
  selectedCustomerList,
  onDeleteCustomer,
}: TableTargetedUserPropsInterface) => (
  <S.Container>
    <S.Table>
      <thead>
        <S.Tr>
          <S.Th ratio="2">User id</S.Th>
          <S.Th ratio="4">username</S.Th>
          <S.Th ratio="1" />
        </S.Tr>
      </thead>
      <tbody>
        {[...selectedCustomerList].slice(0, MAX_ROW).map((customer) => {
          const key = customer.customer_id

          return (
            <S.Tr key={key}>
              <S.Td ratio="2">
                <TextBody weight="light"> {customer.customer_id}</TextBody>
              </S.Td>
              <S.Td ratio="4">
                <TextBody weight="light">{customer.customer_name}</TextBody>
              </S.Td>
              <S.Td ratio="1">
                <Button variant="wrapper" onClick={() => onDeleteCustomer(customer)}>
                  <S.ActionWrapper>
                    <SystemIcon iconName="trash" colorIcon="secondaryText" />
                  </S.ActionWrapper>
                </Button>
              </S.Td>
            </S.Tr>
          )
        })}
        {selectedCustomerList.length > MAX_ROW && (
          <Box textAlign="center" my="16px">
            <TextBody weight="light">
              {`+${selectedCustomerList.length - MAX_ROW} More items`}
            </TextBody>
          </Box>
        )}
      </tbody>
    </S.Table>
  </S.Container>
)

export default memo(TableTargetedUser)

const S = {
  Container: styled.div`
    margin-top: 12px;
    margin-bottom: 32px;
  `,
  Table: styled.table`
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px solid #bfc9d9;
    border-radius: 8px;
    thead tr {
      background-color: ${({ theme: { colors } }) => colors.tableHead};
    }

    tbody tr {
      background-color: ${({ theme: { colors } }) => colors.white};
    }
  `,
  Tr: styled.tr`
    display: flex;
    padding: 12px 24px;
    width: 100%;
  `,
  Th: styled.th`
    color: ${({ theme: { colors } }) => colors.grey};
    font-weight: ${({ theme: { fontWeights } }) => fontWeights.light};
    font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
    text-align: start;
    flex: ${({ ratio }: { ratio: string }) => ratio};
  `,
  ActionWrapper: styled.div`
    margin-left: auto;
    text-align: right;
  `,
  Td: styled.td`
    color: ${({ theme: { colors } }) => colors.grey};
    /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
    font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
    flex: ${({ ratio }: { ratio: string }) => ratio};
    text-align: start;
  `,
}
