import NewUser from 'assets/images/user_regular.png'
import VIPRefund from 'assets/images/vip_need_refund.png'
import VIPIC from 'assets/images/vip_normal_user.png'
import RegularUser from 'assets/images/new user.png'
import styled from 'styled-components'
import { Tooltip } from '@astro-ui/components'
import { tooltipClasses, Fade } from '@mui/material'

type IconUserSegmentPropsType = {
  userSegment: 'NEW_USER' | 'REGULAR_USER' | 'VIP_USER' | 'VIP_PRIO_USER'
}
const IconUserSegment = ({ userSegment }: IconUserSegmentPropsType) => {
  const toolTipInfoAndIcon = {
    NEW_USER: {
      desc: 'New User',
      icon: NewUser,
    },
    VIP_USER: {
      desc: 'VIP User',
      icon: VIPIC,
    },
    VIP_PRIO_USER: {
      desc: 'VIP User. Perlu bukti refund/validasi',
      icon: VIPRefund,
    },
    REGULAR_USER: {
      desc: 'Regular User',
      icon: RegularUser,
    },
  }
  return (
    <AstroToolTip title={toolTipInfoAndIcon[userSegment]?.desc} placement="bottom">
      <AstroIcon src={toolTipInfoAndIcon[userSegment]?.icon} />
    </AstroToolTip>
  )
}

export default IconUserSegment

const AstroToolTip = styled(({ className, ...props }) => (
  <Tooltip
    TransitionComponent={Fade}
    PopperProps={{
      disablePortal: true,
      keepMounted: true,
      placement: 'bottom',
      sx: {
        transition: 'none',
      },
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 0],
          },
        },
      ],
    }}
    arrow
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.text.white,
    boxShadow: theme.shadows[1],
    maxWidth: 'none',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
    margin: '4px 0',
  },
}))
const AstroIcon = styled.img`
  margin-top: 2px;
`
