import { lazy } from 'react'

import { roles } from 'constant/roles'

import generateRouterDetail from 'utils/helpers/generateRouterDetail'

const LoadingDock = lazy(() => import('../pages/LoadingDock'))
const TimeSlot = lazy(() => import('../pages/TimeSlot'))
const InboundSchedule = lazy(() => import('../pages/InboundSchedule'))
const InboundScheduleAdd = lazy(() => import('../pages/InboundScheduleAdd'))

const LoadingDockRouter: RouterFeatureInterface<'LoadingDockRouter', '/dashboard/loading-dock'> = {
  name: 'LoadingDockRouter',
  Component: LoadingDock,
  path: '/dashboard/loading-dock',
  routeType: 'PRIVATE',
  allowedRoles: [roles.AUTH_SHIFT_LEAD, roles.AUTH_CENTRAL_SUPERVISOR, roles.AUTH_HO_LP],
  isDashboard: true,
}

const TimeSlotRouter: RouterFeatureInterface<'TimeSlotRouter', '/dashboard/time-slot'> = {
  name: 'TimeSlotRouter',
  Component: TimeSlot,
  path: '/dashboard/time-slot',
  routeType: 'PRIVATE',
  allowedRoles: [roles.AUTH_SHIFT_LEAD, roles.AUTH_CENTRAL_SUPERVISOR, roles.AUTH_HO_LP],
  isDashboard: true,
}

const InboundScheduleRouter: RouterFeatureInterface<
  'InboundScheduleRouter',
  '/dashboard/inbound-schedule'
> = {
  name: 'InboundScheduleRouter',
  Component: InboundSchedule,
  path: '/dashboard/inbound-schedule',
  routeType: 'PRIVATE',
  allowedRoles: [
    roles.AUTH_SOURCING,
    roles.AUTH_MERCHANDISING_WAREHOUSE,
    roles.AUTH_SOURCING_MANAGER,
    roles.AUTH_INVENTORY_CONTROLLER,
    roles.AUTH_MERCHANDISING_HUB,
    roles.AUTH_INBOUND_STAFF,
    roles.AUTH_HO_LP,
  ],
  isDashboard: true,
}

const InboundScheduleAddRouter: RouterFeatureInterface<
  'InboundScheduleAddRouter',
  '/dashboard/inbound-schedule/:type'
> = {
  name: 'InboundScheduleAddRouter',
  Component: InboundScheduleAdd,
  path: '/dashboard/inbound-schedule/:type',
  routeType: 'PRIVATE',
  allowedRoles: [
    roles.AUTH_SOURCING,
    roles.AUTH_MERCHANDISING_WAREHOUSE,
    roles.AUTH_SOURCING_MANAGER,
    roles.AUTH_INVENTORY_CONTROLLER,
    roles.AUTH_MERCHANDISING_HUB,
    roles.AUTH_INBOUND_STAFF,
    roles.AUTH_HO_LP,
  ],
  isDashboard: true,
}

const InboundScheduleEditAndDetailRouter: RouterFeatureInterface<
  'InboundScheduleEditAndDetailRouter',
  '/dashboard/inbound-schedule/:type/:id'
> = {
  name: 'InboundScheduleEditAndDetailRouter',
  Component: InboundScheduleAdd,
  path: '/dashboard/inbound-schedule/:type/:id',
  routeType: 'PRIVATE',
  allowedRoles: [
    roles.AUTH_SOURCING,
    roles.AUTH_MERCHANDISING_WAREHOUSE,
    roles.AUTH_SOURCING_MANAGER,
    roles.AUTH_INVENTORY_CONTROLLER,
    roles.AUTH_MERCHANDISING_HUB,
    roles.AUTH_INBOUND_STAFF,
    roles.AUTH_HO_LP,
  ],
  isDashboard: true,
}

const CapacityHandlerRouter = [
  LoadingDockRouter,
  TimeSlotRouter,
  InboundScheduleRouter,
  InboundScheduleAddRouter,
  InboundScheduleEditAndDetailRouter,
]

export const CapacityHandlerRouterDetail = generateRouterDetail(CapacityHandlerRouter)

export default CapacityHandlerRouter
