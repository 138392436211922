export const actionlistOption = [
  {
    name: 'Edit',
    id: 'editSo',
  },
  {
    name: 'Confirm',
    id: 'confirm',
  },
  {
    name: 'Print',
    id: 'print',
  },
  {
    name: 'Delivery',
    id: 'delivery',
  },
  {
    name: 'Arrived',
    id: 'arrived',
  },
  {
    name: 'Check Quantity',
    id: 'checkQTY',
  },
  {
    name: 'Print GRN',
    id: 'printGRN',
  },
  {
    name: 'Approve',
    id: 'approve',
  },
  {
    name: 'Reject',
    id: 'reject',
  },
  {
    name: 'Cancel',
    id: 'cancel',
  },
  {
    name: 'Create PO Ref',
    id: 'poReference',
  },
]
