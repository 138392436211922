import axios from 'axios'
import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

const { REACT_APP_BASE_URL_API } = process.env
const baseUrl = REACT_APP_BASE_URL_API

export const getPlanningBulkUploadTemplate = (params) =>
  axios.get(`${baseUrl}/api/staff-management/budget/planning/template`, { params })

export const getBudgetBulkUploadTemplate = (params) =>
  axios.get(`${baseUrl}/api/staff-management/budget/location/template`, { params })

export const getWorkingScheduleBulkUploadTemplate = (params) =>
  axiosInstanceApiGateway.Get({
    url: '/staff-management/internal/:version/schedule/generate-template',
    version: 'v1',
    params,
  })

export const getRateCardBulkUploadTemplate = (params) =>
  axios.get(`${baseUrl}/api/staff-management/budget/rate-card/template`, { params })

export const postUploadManPowerRateCard = (data, config) =>
  axios.post(`${baseUrl}/api/staff-management/budget/rate-card`, data, config)

export const postUploadManPowerBudget = (data) =>
  axios.post(`${baseUrl}/api/staff-management/budget/location`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

export const postUploadWorkingSchedule = (data) =>
  axiosInstanceApiGateway.Post({
    url: '/staff-management/internal/:version/schedule/bulk-upload',
    version: 'v1',
    data,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
    convertResponse: true,
  })

export const postUploadManPowerPlanning = (data) =>
  axios.post(`${baseUrl}/api/staff-management/budget/planning`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
