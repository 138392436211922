import {
  SET_GET_PRODUCT_REQUEST,
  SET_GET_PRODUCT_SUCCESS,
  SET_GET_PRODUCT_FAILED,
  SET_GET_GIFT_REQUEST,
  SET_GET_GIFT_SUCCESS,
  SET_GET_GIFT_FAILED,
  SET_GET_LIST_REQUEST,
  SET_GET_LIST_SUCCESS,
  SET_GET_LIST_FAILED,
  SET_GET_DETAIL_REQUEST,
  SET_GET_DETAIL_SUCCESS,
  SET_GET_DETAIL_FAILED,
  SET_GET_CAMPAIGN_TYPE_SUCCESS,
  SET_GET_CAMPAIGN_TYPE_FAILED,
  SET_UPDATE_GWP_REQUEST,
  SET_UPDATE_GWP_SUCCESS,
  SET_UPDATE_GWP_FAILED,
  SET_UPDATE_GWP_STATUS_FAILED,
  SET_CREATE_GWP_REQUEST,
  SET_CREATE_GWP_SUCCESS,
  SET_CREATE_GWP_FAILED,
  SET_SELECT_PRODUCT_FOR_CREATE_GWP,
  SET_SELECT_GIFT_FOR_CREATE_GWP,
  SET_UPDATE_GIFT,
  SET_CREATE_GIFT,
  SET_PAGINATION_PRODUCT,
  SET_DELETE_GIFT,
  SET_GWP_FORM,
  SET_GWP_RESET,
  SET_GWP_LOADING,
  SET_GWP_PARAMS_FILTER,
} from 'storeContext/actionsType/gwp'

export const initialStateGwp = {
  isLoading: false,
  product: [],
  gift: [],
  list: [],
  productDetail: {},
  campaignType: [],
  productForCreateGwp: [],
  formGwp: {
    campaign_list: [],
    campaign: [],
    skuRelated: [],
    gift: [],
    giftForCreateGwp: [],
    campaign_name: '',
    start_date: '',
    start_time: '',
    end_date: '',
    end_time: '',
    is_active: false,
    is_all_day: false,
    minimum_buy_total: '',
    limit_user_per_campaign: '',
    detail_toc: '',
    link: '',
    gwp_type: '',
    is_targeted: false,
    is_shown: false,
  },
  giftForCreateGwp: [],
  paginationProduct: null,
  paramsFilter: {
    search: '',
    gwpType: '',
    isActive: '',
    startDate: 0,
    endDate: 0,
    pageIndex: 1,
    orderBy: 'created_at',
    orderDirection: 'DESC',
  },
  dropdownGwpType: [
    { id: 'BAU', name: 'BAU' },
    { id: 'Flush Out', name: 'Flush Out' },
    { id: 'Exclusive Launch', name: 'Exclusive Launch' },
    { id: 'Virtual Bundling', name: 'Virtual Bundling' },
    { id: 'Grab Mart', name: 'Grab Mart' },
  ],
  dropdownStatus: [
    { id: 'true', name: 'Aktif' },
    { id: 'false', name: 'Tidak Aktif' },
  ],
}

export default function gwpReducer(state = initialStateGwp, { type, payload }) {
  switch (type) {
    case SET_PAGINATION_PRODUCT:
      return {
        ...state,
        isLoading: false,
        paginationProduct: payload,
      }

    case SET_CREATE_GIFT:
      return {
        ...state,
        isLoading: false,
        giftForCreateGwp: payload,
      }

    case SET_DELETE_GIFT:
      return {
        ...state,
        isLoading: false,
        giftForCreateGwp: [
          {
            ...state.giftForCreateGwp[0],
            gwp_detail_location_hub: [],
          },
        ],
      }

    case SET_UPDATE_GIFT:
      return {
        ...state,
        isLoading: true,
        giftForCreateGwp: [
          {
            ...state.giftForCreateGwp[0],
            gwp_detail_location_hub: payload,
          },
        ],
      }

    case SET_GET_PRODUCT_REQUEST:
    case SET_GET_GIFT_REQUEST:
    case SET_GET_LIST_REQUEST:
    case SET_GET_DETAIL_REQUEST:
    case SET_UPDATE_GWP_REQUEST:
    case SET_CREATE_GWP_REQUEST:
      return {
        ...state,
        isLoading: true,
      }

    case SET_GET_PRODUCT_FAILED:
    case SET_GET_GIFT_FAILED:
    case SET_GET_LIST_FAILED:
    case SET_GET_DETAIL_FAILED:
    case SET_GET_CAMPAIGN_TYPE_FAILED:
    case SET_UPDATE_GWP_FAILED:
    case SET_UPDATE_GWP_STATUS_FAILED:
    case SET_CREATE_GWP_FAILED:
    case SET_UPDATE_GWP_SUCCESS:
    case SET_CREATE_GWP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      }

    case SET_SELECT_PRODUCT_FOR_CREATE_GWP:
      return {
        ...state,
        productForCreateGwp: state.productForCreateGwp.push(payload),
      }

    case SET_SELECT_GIFT_FOR_CREATE_GWP:
      return {
        ...state,
        giftForCreateGwp: state.giftForCreateGwp.push(payload),
      }

    case SET_GET_PRODUCT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        product: payload,
      }

    case SET_GET_GIFT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        gift: payload,
      }
    case SET_GET_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: payload,
      }
    case SET_GET_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        productDetail: payload,
      }
    case SET_GET_CAMPAIGN_TYPE_SUCCESS:
      return {
        ...state,
        campaignType: payload,
      }
    case SET_GWP_FORM:
      return {
        ...state,
        formGwp: {
          ...state.formGwp,
          ...payload,
        },
      }
    case SET_GWP_LOADING:
      return { ...state, isLoading: payload }

    case SET_GWP_RESET:
      return initialStateGwp

    case SET_GWP_PARAMS_FILTER:
      return {
        ...state,
        paramsFilter: payload,
      }

    default:
      return {
        ...state,
      }
  }
}
