import { memo } from "react";
import styled from "styled-components";
import { SystemIcon, NoData, RowTicker } from "components";

function TableTicker({
  data,
  handleChangeStatus,
  handleEdit,
  handlePopupDelete
}) {
  return (
    <>
      <Container>
        <thead>
          <Tr>
            <Th>JUDUL TICKER</Th>
            <Th>ISI TICKER</Th>
            <Th>URL LINK</Th>
            <Th>HALAMAN</Th>
            <Th>
              TANGGAL
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th>WAKTU</Th>
            <Th>STATUS</Th>
          </Tr>
        </thead>
        <tbody>
          {data.map((el) => (
            <RowTicker
              key={el.ticker_id}
              data={el}
              handleChangeStatus={handleChangeStatus}
              handleEdit={handleEdit}
              handlePopupDelete={handlePopupDelete}
            />
          ))}
        </tbody>
      </Container>
      {data.length === 0 && <NoData />}
    </>
  );
}

TableTicker.defaultProps = {
  data: []
};

export default memo(TableTicker);

const Container = styled.table`
  width: 100%;
  border-collapse: collapse;
  height: fit-content;
`;
const Tr = styled.tr`
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`;
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  text-align: start;
  padding: 16px 32px;
`;
