import { memo, useContext, useMemo } from 'react'
import styled from 'styled-components'
import { TextBody, Tooltip } from 'components'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import './style.css'
import store from 'storeContext'
import { getDetailOrder } from 'storeContext/actions/allOrder'
import note_img from 'assets/images/note.svg'
import time from 'assets/images/time.svg'
import checked_img from 'assets/images/check_green.svg'
import IconInformation from 'assets/images/icInformation.svg'
import IconWallet from 'assets/images/icWallet.svg'
import { TimeTicker } from './component'
import { strLimit } from 'utils/string'
import { getEditAddress } from 'storeContext/actions/allOrder/editAddress'
import { dateFormat } from 'utils/helpers/date'
import { externalDriverValidator } from 'utils/3plValidator'
import { getExternalDriverHistory } from 'redux/externalDriver'
import { OrderID } from '..'
import {
  AUTH_CS_AGENT,
  AUTH_CS_CAPTAIN,
  AUTH_CUSTOMER_SERVICE,
  AUTH_HO_CS_LEADER,
} from 'middleware/privateRoute'
import useAllowedAccessFor, { useViewOnly } from 'customHooks/useAllowedAccessFor'
import allowedRoleList from 'middleware/allowedRoleOnFeature/order/allOrder'
import AstroOrder from 'assets/images/icAstroOrder.svg'
import GrabOrder from 'assets/images/icGrabOrder.svg'
import KitchenOrder from 'assets/images/LogoKitchen.svg'
import {
  Box,
  Icon,
  MenuAction,
  TableCell,
  TableRow,
  Typography,
  Tooltip as AstroTooltip,
} from '@astro-ui/components'
import { tooltipClasses, Fade } from '@mui/material'
import SLA from 'features/Order/components/SLA'
import IconUserSegment from './component/IconUserSegment'

function RowAllOrder({
  actionList,
  data,
  setSelectedDataOrder,
  handleChangeOrderPool,
  setOrderPoolingData,
  orderPoolingData,
  ids,
  handleClickCallInstantCourier,
  location,
  dashDown,
  dashUp,
  isAllOrderSuper = false,
}) {
  const { dispatch: dispatchAllorder } = useContext(store.AllorderContext)
  const dispatchRedux = useDispatch()
  //  FUNCTION HELPER
  const authorities = useSelector(({ auth: { userData } }) => userData.authorities, shallowEqual)
  const allowedOnlyForCSCaptOrCS = useAllowedAccessFor(authorities, [
    AUTH_CS_CAPTAIN,
    AUTH_CUSTOMER_SERVICE,
  ])
  const allowedRoleForIcon = useAllowedAccessFor(authorities, [
    AUTH_CS_CAPTAIN,
    AUTH_CUSTOMER_SERVICE,
    AUTH_HO_CS_LEADER,
    AUTH_CS_AGENT,
  ])
  const isViewOnly = useViewOnly(allowedRoleList.viewOnly)
  const dataDropDownMaps = useMemo(() => {
    if (['kitchen', 'astro_balance'].includes(data?.order_type?.toLowerCase())) {
      return actionList.filter((each) => {
        if ([6, 0].includes(each.id)) {
          return each
        }
      })
    }

    if (isViewOnly) {
      return actionList
    }

    let menuDefault = actionList.map((item, index) => {
      const isChecked = ids.includes(data.order_id) && item.checked

      return {
        ...item,
        name: item.name,
        id: item.id,
        handleClick: item.handleClick,
        checked: isChecked,
        suffix: isChecked && (
          <Box ml="8px">
            <Icon icon="checklist" size={16} color="#256ee5" />
          </Box>
        ),
      }
    })

    if (data?.order_third_party_logistic_used) {
      menuDefault.splice(
        menuDefault.findIndex((i) => i.id == 1),
        1,
      )
    } else {
      menuDefault.splice(
        menuDefault.findIndex((i) => i.id == 5),
        1,
      )
    }

    if (data?.order_type === 'GRAB_MART') {
      menuDefault = menuDefault.filter((item) => {
        /* Hide 3PL & cancel 3PL */
        const excluded = [1, 3, 4, 5, 99]
        if (!excluded.includes(item.id)) return item
      })
    }

    menuDefault.filter((each) => {
      if (each.id === 1 && !allowedOnlyForCSCaptOrCS) {
        return undefined
      }
      return each
    })

    if (data.order_status.toLowerCase() == 'upcoming order') {
      return menuDefault.filter((each) => {
        if ([2, 6, 0].includes(each.id)) {
          return each
        }
        return undefined
      })
    }
    const statusOrder = data.order_status.toLowerCase()
    if (['arrived', 'on hold'].includes(statusOrder)) {
      if (statusOrder === 'arrived') {
        menuDefault = menuDefault.filter((item) => item.id !== 103)
      } else {
        menuDefault = menuDefault.filter((item) => item.id !== 101)
      }
    } else {
      menuDefault = menuDefault.filter((each) => {
        const excluded = [101, 102, 103]
        if (!excluded.includes(each.id)) return each
      })
    }

    return menuDefault
  }, [actionList, ids, data?.order_type, data?.order_status, data.order_id])

  // FUNCTION HELPER
  const convertToDate = () => {
    if (isAllOrderSuper) {
      return `${dateFormat({
        date: data?.order_timeslot?.start_timestamp,
        format: 'DD MMM YYYY',
        locale: 'id',
      })} `
    }
    return `${dateFormat({
      date: data?.order_created_at,
      format: 'DD MMM YYYY - HH:mm',
      locale: 'id',
    })} `
  }

  // FUNCTION HANDLER
  const handleGetCourierHistory = async (data) => {
    let payload = {
      orderID: data.order_id,
    }
    await dispatchRedux(getExternalDriverHistory(payload))
  }
  const handleClickOption = (action) => {
    if (action.id === 0) {
      dispatchAllorder(getDetailOrder(data?.order_id))
      handleGetCourierHistory(data)
      if (isAllOrderSuper) {
        action.handleClick(data)
      }
    }

    if (action.name === `Edit Alamat`) {
      const localStateForEditAddress = {
        handleClick: () => action.handleClick(data),
        orderId: data?.order_id,
      }

      dispatchAllorder(getEditAddress(localStateForEditAddress))
    } else {
      action.handleClick(data, location)
    }

    setSelectedDataOrder(data)
  }

  const currencyFormat = () => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    return formatter.format(data?.order_total_price) /* $2,500.00 */
  }

  const handleClickCourierStatus = () => {
    if (!data?.order_third_party_logistic_used) {
      if (data?.order_status.toLowerCase() == 'cancelled') {
        return null
      }
      return handleClickCallInstantCourier(data, location)
    }
    return null
  }

  // FUNCTION RENDER
  const renderStatus = () => {
    const classNameStatus = data?.order_status.toLowerCase().replaceAll(' ', '_')
    const parseIndonesian = () => {
      switch (classNameStatus) {
        case 'new_order':
          return 'Baru'
        case 'picking':
          return 'Mengambil'
        case 'packing':
          return 'Mengemas'
        case 'waiting_driver':
          return 'Menunggu Driver'
        case 'waiting_confirmation':
          return 'Menunggu Respons Customer'
        default:
          return data?.order_status
      }
    }
    return (
      <>
        <StatusTextWrapper>
          <div
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '1px' }}
          >
            <StatusTextContainer>
              <StatusText className={classNameStatus}>{parseIndonesian()}</StatusText>
            </StatusTextContainer>
            {classNameStatus === 'payment_expired' && (
              <StatusImg>
                <Tooltip direction="bottom" content={data?.order_note}>
                  <img src={note_img} alt="note" />
                </Tooltip>
              </StatusImg>
            )}
            {classNameStatus === 'cancelled' && (
              <StatusImg>
                <Tooltip direction="bottom" content={data?.order_note}>
                  <img src={note_img} alt="note" />
                </Tooltip>
              </StatusImg>
            )}
          </div>
        </StatusTextWrapper>
      </>
    )
  }

  const renderStatusPayment = () => {
    const statusPayment = data?.order_payment_status
    if (statusPayment === 'Payment Verified') {
      return (
        <ChecklistWrapper>
          <img
            style={{
              width: '16px',
              height: '16px',
            }}
            src={checked_img}
            alt="note"
          />
        </ChecklistWrapper>
      )
    }

    return null
  }

  const renderOrderIcon = (orderType, userSegment) => {
    const iconForUserSegment = ['NEW_USER', 'VIP_USER', 'VIP_PRIO_USER', 'REGULAR_USER'].includes(
      userSegment,
    )
    switch (orderType) {
      case 'GRAB_MART':
        return (
          <CustomTooltip title="GrabMart" placement="bottom">
            <OrderIcon src={GrabOrder} />
          </CustomTooltip>
        )
      case 'KITCHEN':
        return (
          <CustomTooltip title="Non-Astro" placement="bottom">
            <OrderIcon src={KitchenOrder} />
          </CustomTooltip>
        )
      case 'ASTRO_BALANCE':
        return (
          <CustomTooltip title="Astro Balance" placement="bottom">
            <OrderIcon src={IconWallet} />
          </CustomTooltip>
        )
      default:
        return allowedRoleForIcon && iconForUserSegment ? (
          <IconUserSegment userSegment={userSegment} />
        ) : (
          <CustomTooltip title="Instant" placement="bottom">
            <OrderIcon src={AstroOrder} />
          </CustomTooltip>
        )
    }
  }

  const renderCustomerAddress = (orderType) => {
    switch (orderType) {
      case 'GRAB_MART':
        return (
          <Box>
            <TextBody weight="bold">
              {data?.external_booking_id ? data?.external_booking_id : '-'}
            </TextBody>
            <Address>Kode Booking GrabMart</Address>
          </Box>
        )
      case 'ASTRO_BALANCE':
        return (
          <Box>
            <TextBody weight="bold">{data?.order_customer_name}</TextBody>
            <Address>-</Address>
          </Box>
        )
      default:
        return (
          <Box>
            <TextBody weight="bold">{data?.order_customer_name}</TextBody>
            <Address title={data.order_customer_address}>
              {strLimit(data.order_customer_address, 60)}
            </Address>
          </Box>
        )
    }
  }

  return (
    <Tr dashDown={dashDown}>
      <Td ratio="1" center dashDown={dashDown}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <OrderID
            data={data}
            setOrderPoolingData={setOrderPoolingData}
            orderPoolingData={orderPoolingData}
            isAllOrderSuper={isAllOrderSuper}
            isAllOrder
            location={location}
            dashUp={dashUp}
            dashDown={dashDown}
            noCheckbox={
              (dashUp && dashDown) ||
              (dashUp && !dashDown) ||
              data?.order_type == 'GRAB_MART' ||
              isViewOnly
            }
          />
        </div>
      </Td>
      <Td ratio="2" leftText>
        <PemesanContainer>
          {renderOrderIcon(data?.order_type, data?.user_segment)}
          {renderCustomerAddress(data?.order_type)}
        </PemesanContainer>
      </Td>
      <Td ratio="1">
        <div
          style={{
            fontSize: '14px',
            color: '#212121',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '6px',
            textAlign: 'center',
          }}
        >
          <span>{convertToDate()}</span>
          {isAllOrderSuper && (
            <TimeslotContainer>
              <TextBody color="textSoft" size="smaller" weight="light">
                {dateFormat({
                  date: data?.order_timeslot?.start_timestamp,
                  format: 'HH:mm',
                  locale: 'id',
                })}{' '}
                -{' '}
                {dateFormat({
                  date: data?.order_timeslot?.end_timestamp,
                  format: 'HH:mm',
                  locale: 'id',
                })}
              </TextBody>
            </TimeslotContainer>
          )}
        </div>
      </Td>
      <Td ratio="1" style={{ width: 150 }}>
        {data?.order_type == 'ASTRO_BALANCE' ? (
          '-'
        ) : (
          <>
            {data.order_service_level_name && data?.sla ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '3px',
                  alignItems: 'center',
                }}
              >
                {!isAllOrderSuper && (
                  <SLA
                    additional_sla={data?.additional_sla}
                    label={data.order_service_level_name}
                    orderType={data.order_type}
                  />
                )}
                <TextBody
                  as="div"
                  weight="light"
                  color="red"
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0 4px',
                  }}
                >
                  <TimeTicker data={data} />
                </TextBody>
                {!!data.late_potential && !isAllOrderSuper && !data?.order_timeslot && (
                  <SLateWrapper>
                    <img src={time} />
                    <TextBody weight="bold" color="red" size="toast">
                      Potensi Telat
                    </TextBody>
                  </SLateWrapper>
                )}
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '3px',
                  alignItems: 'center',
                }}
              >
                {!isAllOrderSuper &&
                  (data?.order_timeslot?.id || data?.order_timeslot?.timeslot_id) && (
                    <>
                      <TextBody color="textSoft" weight="light">
                        {dateFormat({
                          date: data?.order_timeslot?.start_timestamp,
                          format: 'DD MMM YYYY',
                          locale: 'id',
                        })}
                      </TextBody>
                      <TimeslotContainer>
                        <TextBody color="textSoft" size="smaller" weight="light">
                          {dateFormat({
                            date: data?.order_timeslot?.start_timestamp,
                            format: 'HH:mm',
                            locale: 'id',
                          })}{' '}
                          -{' '}
                          {dateFormat({
                            date: data?.order_timeslot?.end_timestamp,
                            format: 'HH:mm',
                            locale: 'id',
                          })}
                        </TextBody>
                      </TimeslotContainer>
                    </>
                  )}
                <TextBody
                  as="div"
                  weight="light"
                  color={
                    data?.order_timeslot && data.order_status.toLowerCase() == 'upcoming order'
                      ? 'grey'
                      : 'red'
                  }
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '0 4px',
                  }}
                >
                  <TimeTicker data={data} />
                  {data?.order_timeslot && data.order_status.toLowerCase() == 'upcoming order' && (
                    <CustomTooltip
                      TransitionComponent={Fade}
                      PopperProps={{
                        disablePortal: true,
                        keepMounted: true,
                        placement: 'bottom',
                        sx: {
                          transition: 'none',
                        },
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: [70, 0],
                            },
                          },
                        ],
                      }}
                      placement="bottom"
                      title="Timer akan berjalan ketika status order pindah ke New Order"
                      disableFocusListener
                      disableTouchListener
                      arrow
                    >
                      <IconSvg src={IconInformation} alt="info" />
                    </CustomTooltip>
                  )}
                </TextBody>
              </div>
            )}
          </>
        )}
      </Td>
      <Td ratio="1">
        {!isAllOrderSuper ? (
          <TextBody weight="light" color="textSoft">
            {data?.order_location_name}
          </TextBody>
        ) : (
          <TextBody weight="light" color="textSoft">
            {[data?.order_location_megahub_name, data?.order_location_name]
              .filter(Boolean)
              .join(', ')}
          </TextBody>
        )}
      </Td>
      <Td ratio="2" textAlign="center" className="status-wrapper">
        <StatusContainer>
          {renderStatus()}
          {renderStatusPayment()}
        </StatusContainer>
        {data?.order_type != 'ASTRO_BALANCE' && (
          <>
            {data?.confirm_timestamp > 0 && data?.order_status == 'Waiting Confirmation' && (
              <PartialFullfilStatusContainer>
                <Typography variant="smallBold">Chat Berlangsung</Typography>
              </PartialFullfilStatusContainer>
            )}
            {data?.fulfill_status !== null && (
              <PartialFullfilStatusContainer>
                <StatusText className={data?.fulfill_status?.toLowerCase().replaceAll(' ', '_')}>
                  {data?.fulfill_status}
                </StatusText>
              </PartialFullfilStatusContainer>
            )}
            {data.order_type !== 'GRAB_MART' &&
              externalDriverValidator(
                data?.order_third_party_logistic_term,
                data?.order_status,
                data?.order_third_party_logistic_used,
              ) && (
                <PartialFullfilStatusContainer
                  onClick={() => handleClickCourierStatus()}
                  style={{
                    cursor: data?.order_third_party_logistic_used ? 'text' : 'pointer',
                  }}
                >
                  {data?.order_third_party_logistic_used ? (
                    <SInstant>
                      <TextBody weight="bold" color="blueTag" size="toast" cursor="pointer">
                        Kurir Instan
                      </TextBody>
                      <ChecklistWrapper>
                        <img
                          style={{
                            width: '16px',
                            height: '16px',
                          }}
                          src={checked_img}
                          alt="note"
                        />
                      </ChecklistWrapper>
                    </SInstant>
                  ) : (
                    !isViewOnly &&
                    data.order_status !== 'Cancelled' && (
                      <TextBody weight="bold" color="blueTag" size="toast" cursor="pointer">
                        Panggil Kurir Instan
                      </TextBody>
                    )
                  )}
                </PartialFullfilStatusContainer>
              )}
          </>
        )}
      </Td>
      <Td ratio="1">
        <div style={{ color: '#212121' }}>{currencyFormat()}</div>
      </Td>
      <Td ratio="1" style={{ width: 80 }}>
        <TextBody weight="light" color="textSoft">
          {data?.order_total_fulfill_quantity} / {data?.order_total_quantity}
        </TextBody>
      </Td>

      <Td ratio="1">
        <div
          style={{
            alignItems: 'center',
            flexDirection: 'row',
            display: 'flex',
            gap: '8px',
          }}
        >
          <MenuAction
            label="Atur"
            className="all-order-action"
            options={dataDropDownMaps}
            onSelect={handleClickOption}
            active={data?.confirm_timestamp > 0 && data?.order_status == 'Waiting Confirmation'}
          />
        </div>
      </Td>
    </Tr>
  )
}

export default memo(RowAllOrder)

const Tr = styled(TableRow)`
  background-color: white;
  border-bottom: ${({ dashDown }) => (dashDown ? 'none' : '1px solid rgba(224,224,224,1)')};
  padding-top: 8px;
  padding-bottom: 8px;
`
const Td = styled(TableCell)`
  text-align: ${({ leftText }) => (leftText ? 'left' : 'center')};
  padding-top: 0px;
  padding-bottom: 0px;
  border-bottom: ${({ dashDown }) => (dashDown ? 'none' : '1px solid rgba(224,224,224,1);')};
  background-color: white;
  border-right: none;
  border-collapse: collapse;
`

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* padding-right: 32px; */
  gap: 4px;
  width: 100%;
`

const StatusTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const StatusTextContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const PartialFullfilStatusContainer = styled.div`
  margin-top: 10px;
`

const StatusText = styled.span`
  padding: 5px;
  font-size: 10px;
  font-weight: bold;
`

const ChecklistWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: 24px;
  height: 24px;
  background-color: #d6ffde;
`

const Address = styled.div`
  font-size: 12px;
  color: #212121;
`

const StatusImg = styled.div``

const SLateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`
const SInstant = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
`

const TimeslotContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #e9e9e9;
  border-radius: 4px;
  padding: 4px;
`
const PemesanContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
`

const OrderIcon = styled.img`
  margin-top: 2px;
`

const IconSvg = styled.img`
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: relative;
`

const CustomTooltip = styled(({ className, ...props }) => (
  <AstroTooltip
    TransitionComponent={Fade}
    PopperProps={{
      disablePortal: true,
      keepMounted: true,
      placement: 'bottom',
      sx: {
        transition: 'none',
      },
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 0],
          },
        },
      ],
    }}
    arrow
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.text.white,
    boxShadow: theme.shadows[1],
    maxWidth: 'none',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltipPlacementBottom}`]: {
    margin: '4px 0',
  },
}))
