import { Get } from 'config/apiServiceConfig'
import axiosInstanceApiGateway from 'config/apiServiceApiGateway'
import {
  BannerThematicAddComponentType,
  BannerThematicComponentType,
  BannerThematicType,
  ImagePayloadType,
  TargetingType,
  ThematicCategoryType,
} from '../@types/bannerThematic'

export type TargetingTypeResponseType = TargetingType[]

export type BannerThematicGeneralPayloadType = {
  name: string
  bg_animated_url: string
  browse_thematic_images: ImagePayloadType
  is_active: boolean
  targeting_type: Nullable<number>
  component_sequence: string
  font_color: string
  start_date: string
  end_date: string
  location_ids: number[]
  targeted_user_ids: number[]
}

export type BannerThematicComponentPayloadType = {
  id?: number
  name: string
  slug: string
  is_active: boolean
  animated_url: string
  browse_thematic_images: ImagePayloadType
  sequence: number
  category: Nullable<Partial<ThematicCategoryType>>
  start_date: string
  end_date: string
  location_ids: number[]
  thematic_id: number
  destination_url: string
  targeting_type?: Nullable<number>
  component: BannerThematicComponentType
}

export const getTargetingType = () => Get<TargetingTypeResponseType>({ url: '/api/targeting-type' })

export const postBannerThematicGaneral = (data: BannerThematicGeneralPayloadType) =>
  axiosInstanceApiGateway.Post<BannerThematicType, true>({
    url: '/browse/internal/v1/thematic',
    data,
  })

export const putBannerThematicGaneral = (
  thematicId: number,
  data: BannerThematicGeneralPayloadType,
) =>
  axiosInstanceApiGateway.Put<BannerThematicType, true>({
    url: '/browse/internal/v1/thematic/:thematicId',
    data,
    urlParams: { thematicId },
  })

export const getDetailBannerThematic = (id: number) =>
  axiosInstanceApiGateway.Get<BannerThematicType, true>({
    url: '/browse/internal/v1/thematic/:id',
    urlParams: { id },
  })

export const getCategories = () =>
  Get<ThematicCategoryType[]>({
    url: '/api/categories/child',
  })

export const postBannerThematicComponent = (data: BannerThematicComponentPayloadType) =>
  axiosInstanceApiGateway.Post<BannerThematicAddComponentType>({
    url: '/browse/internal/v1/thematic/component',
    data,
  })

export const putBannerThematicComponent = (
  componentId: number,
  data: BannerThematicComponentPayloadType,
) =>
  axiosInstanceApiGateway.Put<BannerThematicAddComponentType>({
    url: '/browse/internal/:version/thematic/component/:componentId',
    version: 'v1',
    urlParams: {
      componentId,
    },
    data,
  })
