import { colors, ColorsType } from '@astro-ui/fondations'
import { useMemo } from 'react'

import IcoMoon, { IconProps as IcoMoonProps } from 'react-icomoon'

const iconSet = require('./selection.json')

export type IconPropsType = IcoMoonProps & {
  color?: keyof ColorsType | string
  icon?:
    | 'information'
    | 'outbound'
    | 'campaign'
    | 'store'
    | 'pin'
    | 'edit-v2'
    | 'box'
    | 'filter'
    | 'delete-v2'
    | 'calendar-v2'
    | 'persentage'
    | 'rack'
    | 'hamburger'
    | 'threeDots'
    | 'contact'
    | 'clockFull'
    | 'calendar'
    | 'location'
    | 'upload'
    | 'group'
    | 'printer'
    | 'card'
    | 'time'
    | 'checklist'
    | 'up-and-down'
    | 'arrow-left'
    | 'product'
    | 'chat'
    | 'check'
    | 'dropdown'
    | 'person'
    | 'plus'
    | 'search'
    | 'dashboard'
    | 'cart'
    | 'statistic'
    | 'notification'
    | 'open-eye'
    | 'close-eye'
    | 'trash'
    | 'logout'
    | 'setting'
    | 'promotion'
    | 'inBox'
    | 'arrowv2'
    | 'edit'
    | 'image'
    | 'category'
    | 'flag'
    | 'cross'
    | 'chevron-left'
    | 'chevron-right'
    | 'chevron-up'
    | 'chevron-down'
    | 'triangle-up'
    | 'triangle-down'
    | 'paperclip'
    | 'astro'
    | 'pdf'
    | 'celcius'
    | 'scan-barcode'
    | 'close-circle'
    | 'flag-v2'
    | 'link'
    | 'bullet-list'
    | 'list-ordered'
    | 'bold'
    | 'italic'
    | 'underline'
    | 'align-right'
    | 'align-center'
    | 'align-left'
    | 'h-4'
    | 'h-3'
    | 'h-2'
    | 'circle-info-filled'
    | 'package'
    | 'drawer'
    | 'code'
    | 'gear'
    | 'ic-upload'
    | 'plus-circle'
}

const Icon = ({ color, icon, size = 20, ...rest }: IconPropsType) => {
  const colorSelected = useMemo(() => {
    if (color && !color.includes('#')) {
      return colors[color as keyof ColorsType]
    }
    return color
  }, [color])

  return <IcoMoon iconSet={iconSet} icon={icon} color={colorSelected} size={size} {...rest} />
}

export default Icon
