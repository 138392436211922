import { Get, Post } from 'config/apiServiceConfig'

export type ProductCategoryType = {
  category_id: number
  category_name: string
  category_childs: ProductCategoryType[]
}

export type ProductType = {
  product_type_id: number
  product_type_name: string
}

export type GetPAPQueryType = {
  pageSize: number
  pageIndex: number
  active?: 'true' | 'false'
  productNameOrSku?: string
  productTypeId?: number
}

export type GetPAPResponseType = {
  data: ProductAssortmentType[]
  pagination: PaginationType
}

export type ProductAssortmentType = {
  productName: string
  productSku: string
  totalDistribute: number
  productId: number
}

type PaginationType = {
  pageSize: number
  pageIndex: number
  numberOfElements: number
}

export type GetProductAssortmentLocationQueryType = {
  locationType: string
  locationName: string
}

export type GetProductAssortmentLocationType = {
  data: ProductAssortmentLocationType[]
}

export interface ProductAssortmentLocationType {
  productId: number
  productName: string
  distribute: boolean
  locationId: number
  locationName: string
}

export type PostProductAssortmentDataType = {
  productIds: number[]
  locationIds: number[]
}

export const getProductCategories = () =>
  Get<ProductCategoryType[]>({
    url: '/api/categories/child',
  })

export const getProductTypes = () =>
  Get<{ data: ProductType[] }>({
    url: '/api/product/type',
  })

export const getProductAssortmentData = (categoryId: number, params: GetPAPQueryType) =>
  Get<GetPAPResponseType>({
    url: '/api/:version/ims/inventory-attribute/pap/:categoryId',
    version: 'v1',
    urlParams: { categoryId },
    params,
  })

export const getAllLocationByTypeAPI = (
  productId: number,
  params: GetProductAssortmentLocationQueryType,
) =>
  Get<GetProductAssortmentLocationType>({
    url: `/api/:version/ims/inventory-attribute/pap/location/:productId`,
    version: 'v1',
    urlParams: { productId },
    params,
  })

export const postProductAssortment = (data: PostProductAssortmentDataType) =>
  Post<{ message: string }>({
    url: '/api/:version/ims/inventory-attribute/pap',
    version: 'v1',
    data,
  })
