import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ImageRulesType, ImageVariantIdType } from 'utils/apiList/images'
import {
  BannerFormType,
  CategoryFormType,
  CategorySchedulerType,
  ImageStateType,
  InitialStateType,
  PayloadActionBannerFormType,
  PayloadSetFormType,
  SetCategorySchedulerPayloadType,
  CategoryTypeEnum,
  PayloadActionUpdateBannerType,
  CategoryBannerListType,
} from 'features/CategoryAndPosisi/@types/CategoryAddEdit'
import { fetchSegmentList } from 'features/BannerAndTicker/redux/banner'

export const SLICE_NAME = 'categoryAddEdit'
export { fetchSegmentList }

const initialState: InitialStateType = {
  isLoading: false,
  imageRules: {},
  categoryImage: {
    categoryImageNew: {
      image: null,
      file: null,
      imageToPreview: '',
    },
    categoryImageOld: {
      image: null,
      file: null,
      imageToPreview: '',
    },
  },
  banner: {
    isAddNewBanner: false,
    selectedBannerToEdit: null,
    form: {
      uniqueKey: '',
      bannerImageFile: null,
      bannerImagePreview: '',
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      banner_active: false,
      banner_name: '',
      banner_url: '',
    },
    list: [],
  },
  scheduler: {
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
  },
  form: {
    category_label: '',
    category_type: CategoryTypeEnum.INSTANT,
    segment: [],
  },
  segmentOptions: [],
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setImageRules: (
      state,
      { payload }: PayloadAction<{ key: ImageVariantIdType; value: ImageRulesType }>,
    ) => {
      const { key, value } = payload
      state.imageRules[key] = value
    },
    setCategoryImage: (
      state,
      {
        payload,
      }: PayloadAction<{ key: keyof InitialStateType['categoryImage']; value: ImageStateType }>,
    ) => {
      state.categoryImage[payload.key] = { ...payload.value }
    },
    setNewBanner: (state, { payload }: PayloadAction<boolean>) => {
      state.banner.isAddNewBanner = payload
    },
    setBannerForm: (state, { payload }: PayloadAction<PayloadActionBannerFormType>) => {
      const { key, value } = payload

      state.banner.form[key] = value as never
    },
    setSelectedBannerToEdit: (
      state,
      { payload }: PayloadAction<InitialStateType['banner']['selectedBannerToEdit']>,
    ) => {
      state.banner.selectedBannerToEdit = payload
    },
    addEditBanner: (state, { payload }: PayloadAction<PayloadActionUpdateBannerType>) => {
      const { selectedBannerToEdit, list } = state.banner
      if (selectedBannerToEdit) {
        state.banner.list = list.map((item) =>
          item.uniqueKey === selectedBannerToEdit.uniqueKey
            ? { ...selectedBannerToEdit, ...payload }
            : item,
        )
      } else {
        state.banner.list = [...state.banner.list, { ...payload }]
      }
    },
    resetBannerForm: (state, { payload }: PayloadAction<BannerFormType | undefined>) => {
      state.banner.form = payload ? { ...payload } : initialState.banner.form
    },
    setBannerList: (state, { payload }: PayloadAction<CategoryBannerListType[]>) => {
      state.banner.list = [...payload]
    },
    setCategoryScheduler: (state, { payload }: PayloadAction<SetCategorySchedulerPayloadType>) => {
      const { key, value } = payload

      if (!key) {
        state.scheduler = { ...(value as CategorySchedulerType) }
        return
      }

      state.scheduler[key] = value as string
    },
    setForm: (state, { payload }: PayloadAction<PayloadSetFormType>) => {
      const { key, value } = payload

      if (key) {
        state.form[key] = value as never
      } else {
        state.form = {
          ...(value as CategoryFormType),
        }
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSegmentList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchSegmentList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchSegmentList.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.segmentOptions = payload
      })
  },
})

export const {
  resetState,
  setImageRules,
  setCategoryImage,
  setNewBanner,
  setBannerForm,
  setSelectedBannerToEdit,
  addEditBanner,
  resetBannerForm,
  setCategoryScheduler,
  setForm,
  setIsLoading,
  setBannerList,
} = slice.actions
export default slice.reducer
