import { memo } from "react";
import styled from "styled-components";
// import { Toggle, SystemIcon, Button } from "components";

function RowIntegration() {
  return (
    <Tr>
      <Td max="30px">1.</Td>
      <Td>DD MM YYY HH:MM:SS PM / AM</Td>
      <Td>Code A</Td>
      <Td>22</Td>
      <Td min="300px">Update Sedikit</Td>
    </Tr>
  );
}

RowIntegration.defaultProps = {};

export default memo(RowIntegration);

const Tr = styled.tr`
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
  .input-container {
    width: calc(100% - 20px);
  }
`;
const Td = styled.td`
  color: ${({ theme: { colors }, color = "textSoft" }) => colors[color]};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  text-align: start;
  padding: 16px 32px;
  padding-right: 16px;
  min-width: ${({ min }) => min};
  max-width: ${({ max }) => max};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.light};
`;
