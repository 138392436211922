import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  GetTrimmingPackingRequestType,
  TrimmingPackingStatusType,
  TrimmingPackingItemType,
} from 'utils/apiList/freshPurchaseOrderTrimmingPacking'
import {
  SLICE_NAME,
  fetchWarehouseList,
  fetchTrimmingPackingList,
} from './freshPurchaseOrderTrimmingPackingThunk'

export interface SetQueryPayloadInterface {
  fieldName: keyof GetTrimmingPackingRequestType
  value: string | number
}

export type StatusItemType = {
  value: TrimmingPackingStatusType
  name: 'All Status' | 'New' | 'Received' | 'Done'
}

type FilterSearchByNameType = 'No. Packing FPO' | 'No. Inbound FPO' | 'No. FPO'
type FilterSearchByValueType = Extract<
  keyof GetTrimmingPackingRequestType,
  'packingNumber' | 'inboundNumber' | 'fpoNumber'
>

interface SetSelectedFilterFormInterface {
  name: Extract<
    keyof FpoTrimmingPackingInterface['selectedFilterForm'],
    'date' | 'location' | 'searchInput'
  >
  value: string
}

type OptionNameType = {
  [key: string]: string
}

export interface FpoTrimmingPackingInterface {
  isLoading: boolean
  query: GetTrimmingPackingRequestType
  trimmingPackingList: TrimmingPackingItemType[]
  warehouseList: OptionNameType[]
  selectedFilterForm: {
    search: {
      name: FilterSearchByNameType
      value: FilterSearchByValueType
    }
    date: string
    location: string
    searchInput: string
  }
}

const initialState: FpoTrimmingPackingInterface = {
  isLoading: true,
  query: {
    inboundNumber: '',
    fpoNumber: '',
    packingNumber: '',
    pageIndex: 1,
    pageSize: 20,
    status: '',
    date: '',
    locationId: 0,
  },
  selectedFilterForm: {
    search: {
      name: 'No. Packing FPO',
      value: 'packingNumber',
    },
    date: '',
    location: '',
    searchInput: '',
  },
  trimmingPackingList: [],
  warehouseList: [],
}

const freshPurchaseOrderInboundSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetInboundFpoAndPackingNumber: (state) => {
      state.query.inboundNumber = ''
      state.query.fpoNumber = ''
      state.query.packingNumber = ''
    },
    setQuery: (state, action: PayloadAction<SetQueryPayloadInterface>) => {
      const { fieldName, value } = action.payload
      state.query[fieldName] = value as never
    },
    setSelectedFilterForm: (state, action: PayloadAction<SetSelectedFilterFormInterface>) => {
      const { name, value } = action.payload
      state.selectedFilterForm[name] = value as never
    },
    setSelectedSearchField: (
      state,
      action: PayloadAction<{ name: FilterSearchByNameType; value: FilterSearchByValueType }>,
    ) => {
      state.selectedFilterForm.search = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWarehouseList.fulfilled, (state, action) => {
        state.warehouseList = action.payload
      })
      .addCase(fetchTrimmingPackingList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchTrimmingPackingList.fulfilled, (state, action) => {
        state.trimmingPackingList = action.payload
        state.isLoading = false
      })
      .addCase(fetchTrimmingPackingList.rejected, (state) => {
        state.isLoading = false
      })
  },
})
export default freshPurchaseOrderInboundSlice
