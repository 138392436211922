import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'
import { getLocationDetail } from 'features/Location/services/location'
import { getOrderLocations } from 'features/Order/services/allOrder'
import { GetListDriverResponseType, getListDriverAPI } from 'features/Order/services/driver'
import { getOrderDetail } from 'features/Order/services/order'
import { callErrorMsg } from 'helpers/errorMsg'

export const SLICE_NAME = 'driverMonitoring'

export const fetchGetListLocation = createAsyncThunk(
  `${SLICE_NAME}/fetchGetListLocation`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await getOrderLocations()

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchGetLocationDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchGetLocationDetail`,
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await getLocationDetail(id)

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchGetOrderDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchGetOrderDetail`,
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await getOrderDetail(id)

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchGetListDriver = createAsyncThunk(
  `${SLICE_NAME}/fetchGetListDriver`,
  async (params: Parameters<typeof getListDriverAPI>[0], { rejectWithValue }) => {
    try {
      const response: AxiosResponse<GetListDriverResponseType> = (await getListDriverAPI(
        params,
      )) as never

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)
