import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppSelector, shallowEqual } from 'store'

type PublicRouteType = {
  children: JSX.Element
}

const PublicRouter = ({ children }: PublicRouteType) => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const { userData } = useAppSelector((state) => state.auth, shallowEqual)
  const isAuthenticated = !!Object.keys(userData || {}).length

  useEffect(() => {
    if (isAuthenticated) {
      history.replace('/dashboard')
      return
    }
    setLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  if (loading) return null

  return children
}

export default PublicRouter
