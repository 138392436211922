import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'
import { callErrorMsg } from 'helpers/errorMsg'
import { AUTH_ADMIN } from 'middleware/privateRoute'
import {
  getInventoryLocationAPI,
  getCheckPackageIdAPI,
  getInventoryStatusAPI,
  getInventoryStatusNotesAPI,
  putTransferProductDetailAPI,
  getHubLocationsAPI,
  getWarehouseLocationsAPI,
} from 'utils/apiList/productTanggungRenteng'
import type {
  GetCheckPackageIdRequestType,
  GetCheckPackageIdResponseType,
  GetInventoryLocationAPIRequestType,
  GetInventoryLocationAPIResponseType,
  GetInventoryStatusResponseType,
  GetInventoryStatusNotesResponseType,
  GetHubLocationsResponseType,
  GetWarehouseLocationsResponseType,
  PutTransferProductDetailResponseType,
  PutTransferProductDetailRequestType,
} from 'utils/apiList/productTanggungRenteng'
import { serializeQueryToURL } from 'utils/queryParamsURL'
import { toastSuccess } from 'utils/toast'

export const SLICE_NAME = 'hubProductTanggungRenteng'

export const setQuery = createAction<GetInventoryLocationAPIRequestType>(`${SLICE_NAME}/setQuery`)
export const actSetQuery =
  (query: GetInventoryLocationAPIRequestType) => (dispatch: StoreDispatchType) => {
    dispatch(setQuery(query))
    dispatch(fetchGetProductStockList(query))
  }

export const fetchGetProductStockList = createAsyncThunk<
  GetInventoryLocationAPIResponseType,
  GetInventoryLocationAPIRequestType,
  RejectValueType
>(`${SLICE_NAME}/fetchGetProductStockList`, async (params, { rejectWithValue }) => {
  try {
    const qs = serializeQueryToURL(params)
    const response = await getInventoryLocationAPI(qs)

    return response.data
  } catch (error) {
    callErrorMsg(error)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchGetPackageLabel = createAsyncThunk<
  GetCheckPackageIdResponseType,
  GetCheckPackageIdRequestType,
  RejectValueType
>(`${SLICE_NAME}/fetchGetPackageLabel`, async (params, { rejectWithValue }) => {
  try {
    const response = await getCheckPackageIdAPI(params)

    return response.data
  } catch (error) {
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchGetCheckPackageId = createAsyncThunk<
  GetCheckPackageIdResponseType,
  GetCheckPackageIdRequestType,
  RejectValueType
>(`${SLICE_NAME}/fetchGetCheckPackageId`, async (params, { rejectWithValue }) => {
  try {
    const response = await getCheckPackageIdAPI(params)

    return response.data
  } catch (error) {
    callErrorMsg(error)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchGetInventoryStatus = createAsyncThunk<
  GetInventoryStatusResponseType,
  undefined,
  RejectValueType
>(`${SLICE_NAME}/fetchGetInventoryStatus`, async (_, { rejectWithValue }) => {
  try {
    const response = await getInventoryStatusAPI()

    return response.data
  } catch (error) {
    callErrorMsg(error)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchGetInventoryStatusNotes = createAsyncThunk<
  GetInventoryStatusNotesResponseType,
  undefined,
  RejectValueType
>(`${SLICE_NAME}/fetchGetInventoryStatusNotes`, async (_, { rejectWithValue }) => {
  try {
    const response = await getInventoryStatusNotesAPI()

    return response.data
  } catch (error) {
    callErrorMsg(error)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchPutTransferProductDetail = createAsyncThunk<
  PutTransferProductDetailResponseType,
  PutTransferProductDetailRequestType,
  RejectValueType
>(
  `${SLICE_NAME}/fetchPutTransferProductDetail`,
  async ({ id, payload }, { rejectWithValue, dispatch }) => {
    try {
      const response = await putTransferProductDetailAPI({ id, payload })

      dispatch(
        fetchGetProductStockList({
          locationId: payload.locationId,
          pageIndex: 1,
        }),
      )

      toastSuccess(response.data.message)

      return response.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchGetHubLocations = createAsyncThunk<
  GetHubLocationsResponseType['data'],
  undefined,
  RejectValueType
>(`${SLICE_NAME}/fetchGetHubLocations`, async (_, { rejectWithValue, getState }) => {
  try {
    const {
      auth: { userData },
    } = getState() as never

    const response = await getHubLocationsAPI()
    const { data } = response.data

    const isAdmin = (userData as { authorities: { name: string }[] }).authorities.some(
      (item) => item.name === AUTH_ADMIN,
    )
    const listUserLocationId = (
      userData as { location_roles: { location_id: number }[] }
    ).location_roles.map((item) => item.location_id)

    return isAdmin ? data : data.filter((item) => listUserLocationId.includes(item.location_id))
  } catch (error) {
    callErrorMsg(error)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchGetWarehouseLocations = createAsyncThunk<
  GetWarehouseLocationsResponseType,
  undefined,
  RejectValueType
>(`${SLICE_NAME}/fetchGetWarehouseLocations`, async (_, { rejectWithValue, getState }) => {
  try {
    const {
      auth: { userData },
    } = getState() as never

    const response = await getWarehouseLocationsAPI()
    const { data } = response

    const isAdmin = (userData as { authorities: { name: string }[] }).authorities.some(
      (item) => item.name === AUTH_ADMIN,
    )
    const listUserLocationId = (
      userData as { location_roles: { location_id: number }[] }
    ).location_roles.map((item) => item.location_id)

    return isAdmin ? data : data.filter((item) => listUserLocationId.includes(item.location_id))
  } catch (error) {
    callErrorMsg(error)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})
