import styled from 'styled-components'
import Barcode from 'react-barcode'

const SectionBarcode = ({ packageLabel }: { packageLabel: string }) => (
  <Container>
    <StyleTextBody style={{ fontSize: '17px' }}>{packageLabel.slice(-5)}</StyleTextBody>
    <BarcodeContainer>
      <Barcode value={packageLabel} displayValue={false} height={55} />
    </BarcodeContainer>
    <StyleTextBody style={{ fontSize: '14px' }}>{packageLabel}</StyleTextBody>
    <BarcodeSpace>&nbsp;</BarcodeSpace>
  </Container>
)

export default SectionBarcode

const Container = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 33.3mm;
  box-sizing: border-box;
`

const BarcodeContainer = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  svg {
    margin-left: auto;
    margin-right: auto;
  }
`

const StyleTextBody = styled.p`
  margin: 0;
  padding: 0;
  text-align: center;
`

const BarcodeSpace = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  height: 2mm;
  align-items: center;
`
