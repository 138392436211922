import { memo } from 'react'
import styled from 'styled-components'
import logoAstro from 'assets/images/AstroResi.svg'
import icLabelUsia from 'assets/images/icLabelUsia.svg'
import grabmart from 'assets/images/grabmart.png'
import { Typography } from '@astro-ui/components'
function Header({ data }) {
  return (
    <HeaderContainer>
      <LogoContainer>
        <LogoAstro src={logoAstro} />
        <LogoAstro src={grabmart} />
      </LogoContainer>
      {data?.is_twenty_one_plus && (
        <CategoryContainer>
          <UsiaLogo src={icLabelUsia} />
          <TextContainer>
            <Typography fontSize={'1.8vh'} lineHeight={1.25} textAlign="left">
              <b>PENTING:</b> PAKET HANYA BISA
            </Typography>
            <Typography fontSize={'1.8vh'} lineHeight={1.25} textAlign="left">
              DITERIMA SESUAI NAMA PEMBELI
            </Typography>
          </TextContainer>
        </CategoryContainer>
      )}
    </HeaderContainer>
  )
}

export default memo(Header)

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px dashed black;
  padding-bottom: 1vh;
`

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`

const LogoAstro = styled.img`
  width: 22vh;
  height: auto;
`

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  align-items: center;
  gap: 4px;
`

const UsiaLogo = styled.img`
  width: 5vh;
  height: 5vh;
`
const TextContainer = styled.div`
  max-width: 30vh;
`
