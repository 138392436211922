import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getFPOInboundDetail,
  getFPOInboundConversion,
  getLocationList,
  getUOMList,
  postFPOIProcessAll,
  postFPOIProcessPartial,
} from 'utils/apiList/freshPurchaseOrderInbound'
import { callErrorMsg } from 'helpers/errorMsg'
import type {
  FPOIProcessAllRequestType,
  FPOIConversionRequestType,
  FPOIDetailResponseType,
} from 'utils/apiList/freshPurchaseOrderInbound'
import { toastSuccess } from 'utils/toast'

const SLICE_NAME = 'freshPurchaseInboundTrimmingPacking'

type FPOIItemListType = FPOIDetailResponseType['items']

const getModifiedItem = (item: FPOIDetailResponseType['items'][number]) => ({
  ...item,
  beforeProcessedQty: item.remainingQty / 1000,
  startQty: item.startQty / 1000,
  plannedQty: item.plannedQty / 1000,
  isSelected: false,
  recycleQty: 0,
  disposalQty: 0,
  sisaQty: 0,
  overgramQty: 0,
  defaultOvergram: 0,
  gradeAQty: 0,
  returQty: 0,
  product: {
    ...item.product,
    selectedUOM: item.product.baseUom[0],
  },
})

const getMappedItemsWithLocalStorage = (itemList: FPOIItemListType, stringifyItemList: string) => {
  const listItemLocalStorage: FPOIItemListType = JSON.parse(stringifyItemList)

  const listIds = listItemLocalStorage.map((item) => item.id)

  const itemsWithSameIdWithLocalStorage = itemList
    .filter((item) => listIds.includes(item.id))
    .map((itemData) => {
      const itemDataInLocalStorage = listItemLocalStorage.find(
        (itemInStorage) => itemInStorage.id === itemData.id,
      ) as FPOIItemListType[number]

      const modifiedItem = getModifiedItem(itemData)
      return {
        ...modifiedItem,
        usedQty: itemDataInLocalStorage.usedQty,
        gradeAQty: itemDataInLocalStorage.gradeAQty,
        recycleQty: itemDataInLocalStorage.recycleQty,
        disposalQty: itemDataInLocalStorage.disposalQty,
        returQty: itemDataInLocalStorage.returQty,
        overgramQty: itemDataInLocalStorage.overgramQty,
      }
    })

  const itemsExcludeIdInLocalStorage = itemList
    .filter((item) => !listIds.includes(item.id))
    .map((item) => getModifiedItem(item))
  return [...itemsWithSameIdWithLocalStorage, ...itemsExcludeIdInLocalStorage]
}

export const fetchFPOIDetails = createAsyncThunk(
  `${SLICE_NAME}/fetchFreshPurchaseOrderDetail`,
  async ({ id }: { id: string | undefined }, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await getFPOInboundDetail(id)

      const key = `${data.fpoNumber}_inbound-trim-pack`
      const stringifyItemList = localStorage.getItem(key)
      if (stringifyItemList) {
        const newItemList = getMappedItemsWithLocalStorage(data.items, stringifyItemList)
        return {
          ...data,
          items: newItemList,
        }
      }

      return {
        ...data,
        items: data.items.map((el) => getModifiedItem(el)),
      }
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchFPOIConversion = createAsyncThunk(
  `${SLICE_NAME}/fetchFreshPurchaseOrderConversion`,
  async (req: FPOIConversionRequestType, { rejectWithValue }) => {
    try {
      const res = await getFPOInboundConversion(req)
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchLocationList = createAsyncThunk(
  `${SLICE_NAME}/fetchLocationList`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getLocationList(`name=bad`)
      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchUOMList = createAsyncThunk(
  `${SLICE_NAME}/fetchUOMList`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getUOMList()
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

const removeOrSetLocalStorageOfItems = (key: string, items: FPOIProcessAllRequestType['items']) => {
  const stringifyItemList = localStorage.getItem(key)
  if (stringifyItemList) {
    const listIdsSubmitted = items.map((item) => item.id)
    const listItemLocalStorage: FPOIItemListType = JSON.parse(stringifyItemList)
    const listItemLocalStorageNotSubmitted = listItemLocalStorage.filter(
      (itemStorage) => !listIdsSubmitted.includes(itemStorage.id),
    )
    if (!listItemLocalStorageNotSubmitted.length) localStorage.removeItem(key)
    else localStorage.setItem(key, JSON.stringify(listItemLocalStorageNotSubmitted))
  }
}

export const fetchFPOIProcessAll = createAsyncThunk(
  `${SLICE_NAME}/fetchProcessAllFPO`,
  async (
    {
      payload,
      id,
      fpoNumber,
    }: { payload: FPOIProcessAllRequestType; id: undefined | string; fpoNumber: string },
    { rejectWithValue },
  ) => {
    try {
      const res = await postFPOIProcessAll(id, payload)

      const key = `${fpoNumber}_inbound-trim-pack`
      removeOrSetLocalStorageOfItems(key, payload.items)

      toastSuccess(res.data.message)
      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchFPOIProcessPartial = createAsyncThunk(
  `${SLICE_NAME}/fetchProcessPartialFPOI`,
  async (
    {
      payload,
      id,
      fpoNumber,
    }: { payload: FPOIProcessAllRequestType; id: undefined | string; fpoNumber: string },
    { rejectWithValue },
  ) => {
    try {
      const res = await postFPOIProcessPartial(id, payload)

      const key = `${fpoNumber}_inbound-trim-pack`
      removeOrSetLocalStorageOfItems(key, payload.items)

      toastSuccess(res.data.error.message)
      return res
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
