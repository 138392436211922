import { memo } from "react";
import styled from "styled-components";
import { TextBody, SystemIcon, Button } from "components";
function Pagination({ data, handleChangePage, className, showTotalOnly }) {
  const startItem = data.currentPage * data.pageSize + 1;
  return (
    <Container className={className}>
      {Boolean(data.currentItem) && (
        <>
          {Boolean(data.totalData) && (
            <TextBody size="smaller" color="grey" weight="light">
              Menampilkan {data.totalData !== 0 ? startItem : 0}-
              {startItem + data.currentItem - 1} dari {data.totalData}
            </TextBody>
          )}
          {!showTotalOnly && (
            <ButtonWrapper>
              <Button
                disabled={data.currentPage <= 0}
                variant="wrapper"
                onClick={() => handleChangePage("prev")}
              >
                <PagiButton type="prev">
                  <SystemIcon iconName="dropdown" />
                </PagiButton>
              </Button>

              {data.currentPage + 1 < data.totalPage && (
                <Button
                  variant="wrapper"
                  onClick={() => handleChangePage("next")}
                >
                  <PagiButton>
                    <SystemIcon iconName="dropdown" />
                  </PagiButton>
                </Button>
              )}
              {data.last !== undefined && <P>{data.currentPage + 1}</P>}
              {data.last !== undefined && (
                <Button
                  disabled={data.last === true}
                  variant="wrapper"
                  onClick={() => handleChangePage("next")}
                >
                  <PagiButton>
                    <SystemIcon iconName="dropdown" />
                  </PagiButton>
                </Button>
              )}
            </ButtonWrapper>
          )}
        </>
      )}
    </Container>
  );
}

Pagination.defaultProps = {
  data: {},
  handleChangePage: () => {}
};

export default memo(Pagination);

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  margin-left: 16px;
  display: flex;
  align-items: center;
  // gap: 8px;
`;

const P = styled.p`
  padding: 0px 1rem;
  font-size: 14px;
  color: ${({ theme: { colors } }) => colors.grey70};
`;

const PagiButton = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 9px 10px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 6px;
  box-shadow: 2px 4px 20px 0px rgba(229, 236, 240, 1);
  transform: ${({ type }) =>
    type === "prev" ? "rotate(90deg)" : "rotate(-90deg)"};
`;
