import { InputLabel as MuiInputLabel, InputLabelProps as MuiInputLabelProps } from '@mui/material'
import styled from 'styled-components'

export type InputLabelPropsType = MuiInputLabelProps

const InputLabel = styled(MuiInputLabel)<InputLabelPropsType>`
  transform: translate(2px, -6px) scale(1);
  font-size: 14px;
  color: ${({ theme }) => theme.colors.grey9};
`

export default InputLabel
