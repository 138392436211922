import { Put, Get } from 'config/apiServiceConfig'

export type GetKoliLostRequestType = {
  pageIndex?: number
  pageSize?: number
  supplyOrderNumber?: string
  status?: string
}

export type LabeKoliLostType =
  | 'NOT_FOUND'
  | 'FOUND'
  | 'LOST'
  | 'PARTIAL_FOUND'
  | 'NEW'
  | 'IN_PROGRESS'

export type GetKoliLostResponseType = {
  data: {
    createdAt: number
    destination: string
    lostCount: string
    origin: string
    status: LabeKoliLostType
    supplyOrderID: number
    supplyOrderNumber: string
  }[]
  pagination: {
    pageSize: number
    pageIndex: number
    numberOfElements: number
  }
}

export type GetKoliLostDetailResponseType = {
  data: {
    koliID: number
    koliNumber: string
    skuList: {
      qty: number
      skuNumber: string
      status: 'SCAN' | 'UNSCAN' | 'LOST'
    }[]
  }[]
}

export type PutKoliFoundRequestType = {
  supplyOrderID: number
  koliIDs: number[]
}

export const getKoliLostAPI = (params: GetKoliLostRequestType) =>
  Get<GetKoliLostResponseType>({
    url: '/api/wms/v1/supply-order/loading/lost-koli-staging',
    params,
  })

export const getKoliLostDetailAPI = (payload: number) =>
  Get<GetKoliLostDetailResponseType>({
    url: `/api/wms/v1/supply-order/loading/lost-koli-staging/${payload}`,
  })

export const putKoliFoundAPI = (data: PutKoliFoundRequestType) =>
  Put({ url: '/api/wms/v1/supply-order/loading/lost-koli-staging/found', data })
