import AxiosInstance from 'config/apiServiceApiGateway'

import {
  AssetMasterListInterface,
  AssetMasterParamsInterface,
  AssetMasterInventoryAssetParamsInterface,
  AssetMasterInventoryAssetResponseInterface,
  AssetMasterInventoryAssetUpdatePayloadInterface,
  AssetMasterInventoryAssetUpdateResponseInterface,
} from 'features/AssetManagement/@types/typeAssetMaster'

export const getAssetMasterListAPI = (query: AssetMasterParamsInterface) =>
  AxiosInstance.Get<AssetMasterListInterface, true>({
    url: '/ams/internal/v1/assets_master/list',
    params: query,
  })

export const getAssetMasterDetailInventoryAssetAPI = (
  params: AssetMasterInventoryAssetParamsInterface,
) =>
  AxiosInstance.Get<AssetMasterInventoryAssetResponseInterface, true>({
    url: '/ams/internal/v1/assets/inventories',
    params,
  })

// export const getAssetMasterDetailAPI =

export const postUpdateAssetAPI = (payload: AssetMasterInventoryAssetUpdatePayloadInterface) =>
  AxiosInstance.Post<AssetMasterInventoryAssetUpdateResponseInterface, true>({
    url: '/ams/internal/v1/assets_inventory/update',
    data: payload,
  })
