import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { ImageRulesType } from 'utils/apiList/images'

import { callErrorMsg } from 'helpers/errorMsg'
import { type BannerType, getBanner } from 'utils/apiList/banner'
import {
  getAllCategories,
  getCustomer,
  getTargetingType,
  postUploadedTargettedUSer,
  getBanners,
  putBannerActive,
  putBanner,
  postBanner,
  getOrderLocation,
} from 'utils/api'
import { toastFailed, toastSuccess } from 'utils/toast'
import { getAllSegments } from 'features/BannerAndTicker/sevices/banner'

export type { BannerType }

const SLICE_NAME = 'Banner'

export type CategoryType = {
  category_id: number
  category_name: string
}

export type CustomerType = {
  customer_id: number
  customer_name: string
  customer_phone_number: number
}

export type TargetingKindType = {
  name: string
  value: number
}

export type LocationType = {
  location_id: number
  location_name: string
  location_type: string
}

export type PostPutPayloadBannerType = {
  banner_name: string
  banner_image: string | null
  banner_date_start: number
  banner_date_end: number
  banner_hubs: {
    location_id: number
    name: string
    position: number
    status: number
  }[]
  banner_position?: number
  targeting_type?: number
  list_user_id: number[]
  category_id?: number | null
  banner_url?: string | null
  total_position?: number
  category_active?: boolean
  fallback_url?: string
  banner_text_color?: string
  banner_image_old?: {
    image_url_small: string
    image_url_medium: string
    image_url_large: string
    version_type_id: number
  }
  banner_image_new?: {
    image_url_small: string
    image_url_medium: string
    image_url_large: string
    version_type_id: number
  }
  banner_segmentations?: {
    type_id: number
    segment_type_id: number
  }[]
}

export type SegmentOptionType = {
  name: string
  id: number
  total_user: number
}

export type BannersType = Omit<BannerType, 'banner_segmentation'>

export type BannerStateType = {
  isLoading: boolean
  paginationData: {
    pageSize: number
    totalData: number
    totalPage: number
    currentPage: number
    currentItem: number
  }
  banners: BannersType[]
  selectedBanner: BannerType | null
  categories: CategoryType[]
  customerList: CustomerType[]
  selectedCustomerList: CustomerType[]
  targetingTypeList: TargetingKindType[]
  imageRules: ImageRulesType
  oldImageRules: ImageRulesType
  segmentOptions: SegmentOptionType[]
}

export const initialState: BannerStateType = {
  isLoading: false,
  paginationData: {
    pageSize: 25,
    totalData: 0,
    totalPage: 0,
    currentPage: 0,
    currentItem: 0,
  },
  banners: [],
  // modal form states
  selectedBanner: null,
  categories: [],
  customerList: [],
  selectedCustomerList: [],
  targetingTypeList: [],
  imageRules: {
    extensions: [],
    height: { max: 0, min: 0 },
    maxFileSize: 0,
    typeId: 'homepage-banner',
    width: { max: 0, min: 0 },
  },
  oldImageRules: {
    extensions: [],
    height: { max: 0, min: 0 },
    maxFileSize: 0,
    typeId: 'banner-images',
    width: { max: 0, min: 0 },
  },
  segmentOptions: [],
}

const bannerSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setSelectedBanner: (state, action) => {
      state.selectedBanner = action.payload
    },
    setCategories: (state, action) => {
      state.categories = action.payload
    },
    setHubs: (state, action) => {
      state.categories = action.payload
    },
    setCustomerList: (state, action) => {
      state.customerList = action.payload
    },
    setSelectedCustomerList: (state, action) => {
      state.selectedCustomerList = action.payload
    },
    setTargetingTypeList: (state, action) => {
      state.targetingTypeList = action.payload
    },
    setBanners: (state, action) => {
      state.banners = action.payload
    },
    setPaginationData: (state, action) => {
      state.paginationData = action.payload
    },
    setImageRules: (state, action: PayloadAction<{ value: BannerStateType['imageRules'] }>) => {
      const { value } = action.payload

      state.imageRules = value
    },
    setOldImageRules: (state, action: PayloadAction<{ value: BannerStateType['imageRules'] }>) => {
      const { value } = action.payload

      state.oldImageRules = value
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actGetDetailBanner.pending, (state) => {
        state.isLoading = true
      })
      .addCase(actGetDetailBanner.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(actGetDetailBanner.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(uploadCsvTargetedUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(uploadCsvTargetedUser.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(uploadCsvTargetedUser.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(actGetBanners.pending, (state) => {
        state.isLoading = true
      })
      .addCase(actGetBanners.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(actGetBanners.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(actChangeActiveBanner.pending, (state) => {
        state.isLoading = true
      })
      .addCase(actChangeActiveBanner.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(actChangeActiveBanner.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(actUpdateBanner.pending, (state) => {
        state.isLoading = true
      })
      .addCase(actUpdateBanner.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(actUpdateBanner.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(actAddBanner.pending, (state) => {
        state.isLoading = true
      })
      .addCase(actAddBanner.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(actAddBanner.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchSegmentList.fulfilled, (state, action) => {
        state.segmentOptions = action.payload
      })
  },
})

export const {
  setSelectedBanner,
  setCategories,
  setHubs,
  setCustomerList,
  setTargetingTypeList,
  setSelectedCustomerList,
  setBanners,
  setPaginationData,
  setImageRules,
  setOldImageRules,
} = bannerSlice.actions
export default bannerSlice.reducer

const getTransformedCustomerList = (data: CustomerType[]) =>
  data.map((item) => ({
    ...item,
    customer_name: `${item.customer_phone_number} ${item.customer_name ? '-' : ''} ${
      item.customer_name || ''
    }`,
  }))

export const actGetDetailBanner = createAsyncThunk(
  `${SLICE_NAME}/actGetDetailBanner`,
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await getBanner(id)

      dispatch(setSelectedBanner(data))

      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan data banner')
      return rejectWithValue(err)
    }
  },
)

export const actGetAllCategories = createAsyncThunk(
  `${SLICE_NAME}/actGetAllCategories`,
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await getAllCategories()
      dispatch(setCategories(data))

      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar kategori')

      return rejectWithValue(err)
    }
  },
)

export const actGetHubs = createAsyncThunk(
  `${SLICE_NAME}/actGetHubs`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getOrderLocation()

      return data?.data || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar hub')

      return rejectWithValue(err)
    }
  },
)

export const actGetCustomerlist = createAsyncThunk(
  `${SLICE_NAME}/actGetCustomerlist`,
  async (params: { number: string } | undefined, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await getCustomer({ ...params, pageSize: 100 })

      const customerList = (data?.content || []) as CustomerType[]

      const transformedCustomerList = getTransformedCustomerList(customerList)

      dispatch(setCustomerList(transformedCustomerList))

      return transformedCustomerList
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar customer')

      return rejectWithValue(err)
    }
  },
)

export const actGetTargetingType = createAsyncThunk(
  `${SLICE_NAME}/actGetTargetingType`,
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await getTargetingType()

      const normalizedTargetingTypeList = data.map((item: { details: string; id: number }) => ({
        name: item.details,
        value: item.id,
      }))

      dispatch(setTargetingTypeList(normalizedTargetingTypeList))

      return normalizedTargetingTypeList
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar customer')

      return rejectWithValue(err)
    }
  },
)

export const actAddCustomer = createAsyncThunk(
  `${SLICE_NAME}/actAddCustomer`,
  async (customer: CustomerType, { dispatch, getState }) => {
    const rootState = getState() as StoreStateType

    const { selectedCustomerList } = rootState.banner

    const isCustomerSelected = !!selectedCustomerList.find(
      (item) => item.customer_id === customer.customer_id,
    )

    if (isCustomerSelected) {
      return
    }

    dispatch(setSelectedCustomerList([...selectedCustomerList, customer]))
  },
)

export const actDeleteCustomer = createAsyncThunk(
  `${SLICE_NAME}/actDeleteCustomer`,
  async (customer: CustomerType, { dispatch, getState }) => {
    const rootState = getState() as StoreStateType

    const { selectedCustomerList } = rootState.banner

    const filteredCustomer = selectedCustomerList.filter(
      (item) => item.customer_id !== customer.customer_id,
    )

    dispatch(setSelectedCustomerList(filteredCustomer))
  },
)

export const uploadCsvTargetedUser = createAsyncThunk(
  `${SLICE_NAME}/uploadCsvTargetedUser`,
  async (formData: FormData, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await postUploadedTargettedUSer(formData)
      const customerList = (data || []) as CustomerType[]

      const transformedCustomerList = getTransformedCustomerList(customerList)

      dispatch(setSelectedCustomerList(transformedCustomerList))
      return data
    } catch (err) {
      toastFailed('Upload user gagal')
      return rejectWithValue(err)
    }
  },
)

export const actGetBanners = createAsyncThunk(
  `${SLICE_NAME}/actGetBanners`,
  async (query: string | undefined, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await getBanners(query)
      const banners = data.content
      const pagination = {
        pageSize: data.size,
        totalData: data.totalElements,
        totalPage: data.totalPages,
        currentPage: data.number,
        currentItem: data.numberOfElements,
      }

      dispatch(setPaginationData(pagination))
      dispatch(setBanners(banners))

      return data
    } catch (err) {
      toastFailed('Terjadi kesalahan silahkan refresh halaman')
      return rejectWithValue(err)
    }
  },
)

export const actChangeActiveBanner = createAsyncThunk(
  `${SLICE_NAME}/actChangeActiveBanner`,
  async (
    {
      id,
      payload,
      query,
    }: { id: number; payload: { is_active: boolean; banner_name: string }; query: string },
    { dispatch, rejectWithValue },
  ) => {
    try {
      await putBannerActive(id, payload)
      toastSuccess(`${payload.banner_name} berhasil diupdate.`)
      dispatch(actGetBanners(query))

      return null
    } catch (err) {
      toastFailed('Banner gagal diupdate')
      return rejectWithValue(err)
    }
  },
)

export const actUpdateBanner = createAsyncThunk(
  `${SLICE_NAME}/actUpdateBanner`,
  async (
    {
      id,
      payload,
      query,
    }: { id: number; payload: Partial<PostPutPayloadBannerType>; query: string },
    { dispatch, rejectWithValue },
  ) => {
    try {
      await putBanner(id, payload)
      toastSuccess(`${payload.banner_name} berhasil diupdate.`)
      dispatch(actGetBanners(query))

      return null
    } catch (err) {
      toastFailed('Banner gagal diupdate')
      return rejectWithValue(err)
    }
  },
)

export const actAddBanner = createAsyncThunk(
  `${SLICE_NAME}/actAddBanner`,
  async (
    { payload, query }: { payload: Partial<PostPutPayloadBannerType>; query: string },
    { dispatch, rejectWithValue },
  ) => {
    try {
      await postBanner(payload)
      toastSuccess(`${payload.banner_name} berhasil ditambahkan`)
      dispatch(actGetBanners(query))

      return null
    } catch (err) {
      toastFailed('Banner gagal ditambahkan')
      return rejectWithValue(err)
    }
  },
)

export const fetchSegmentList = createAsyncThunk(
  `${SLICE_NAME}/fetchSegmentList`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getAllSegments()

      return data.data || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan user segment list')
      return rejectWithValue(err)
    }
  },
)
