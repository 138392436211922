import { lazy } from 'react'
import generateRouterDetail from 'utils/helpers/generateRouterDetail'
import { directPutawayRolesRBAC } from '../pages/DirectPutaway/rbac'

type NamedRoutesType = 'DirectPutawayRouter'

type PathListsType = '/dashboard/direct-put-away'

type RouterInstanceType = RouterFeatureInterface<NamedRoutesType, PathListsType>

const DirectPutawayPage = lazy(() => import('pages/DirectPutaway'))

export const DirectPutawayRouter: RouterInstanceType = {
  name: 'DirectPutawayRouter',
  Component: DirectPutawayPage,
  path: '/dashboard/direct-put-away',
  routeType: 'PRIVATE',
  allowedRoles: [],
  rbacPageID: directPutawayRolesRBAC.pageID,
  isDashboard: true,
}

const PurchaseOrderRouter: RouterInstanceType[] = [DirectPutawayRouter]

export const PurchaseOrderRouterDetail = generateRouterDetail(PurchaseOrderRouter)

export default PurchaseOrderRouter
