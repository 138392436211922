import apiServiceApiGateway from 'config/apiServiceApiGateway'

import {
  GetAllPageIDListResponsesInterface,
  QueryURLPageIDListType,
  CreateUpadatePageIDResponseType,
  PostPageIDRequestType,
  PutPageIDRequestType,
  GetPagePermissionOptionsResponseType,
  GetDetailPageIDResponseType,
  GetJSONPageIDDetailsResponseType,
} from 'features/Account/@types/adminAccessPageIDList'

export const getAllPageIDList = async (query: QueryURLPageIDListType) =>
  apiServiceApiGateway.Get<GetAllPageIDListResponsesInterface>({
    url: '/auth/internal/v1/page',
    params: query,
  })

export const getDetailPageIDAPI = async (pageId: string) =>
  apiServiceApiGateway.Get<GetDetailPageIDResponseType>({
    url: `/auth/internal/v1/page/${pageId}`,
  })

export const postPageIDAPI = (payload: PostPageIDRequestType) =>
  apiServiceApiGateway.Post<CreateUpadatePageIDResponseType>({
    url: '/auth/internal/v1/page',
    data: payload,
  })

export const putPageIDAPI = ({ id, payload }: { id: string; payload: PutPageIDRequestType }) =>
  apiServiceApiGateway.Put<CreateUpadatePageIDResponseType>({
    url: `/auth/internal/v1/page/${id}`,
    data: payload,
  })

export const getPagePermissionOptionsAPI = () =>
  apiServiceApiGateway.Get<GetPagePermissionOptionsResponseType>({
    url: '/auth/internal/v1/permission/option',
  })

export const getJSONPageIDDetailsAPI = (id: string) =>
  apiServiceApiGateway.Get<GetJSONPageIDDetailsResponseType>({
    url: '/auth/internal/v1/page/json/:id',
    urlParams: { id },
  })

export const postUploadJSONPageDetailsAPI = (data: { page_json: string }) =>
  apiServiceApiGateway.Post<GetJSONPageIDDetailsResponseType>({
    url: '/auth/internal/v1/page/json',
    data,
  })
