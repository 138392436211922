import {
  FormControl as MuiFormControl,
  FormControlProps as MuiFormControlProps,
} from '@mui/material'
import styled from 'styled-components'

export type FormControlPropsType = MuiFormControlProps

const FormControl = styled(MuiFormControl)<FormControlPropsType>``

export default FormControl
