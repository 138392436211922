import styled from 'styled-components'
import { Property as CSS } from 'csstype'
import colors from '@astro-ui/fondations/colors'
import {
  space,
  typography,
  grid,
  color,
  border,
  shadow,
  layout,
  flexbox,
  position,
  compose,
  SpaceProps,
  TypographyProps,
  GridProps,
  ColorProps,
  BorderProps,
  ShadowProps,
  LayoutProps,
  FlexboxProps,
  PositionProps,
  system,
} from 'styled-system'

export type BoxPropsType = SpaceProps &
  LayoutProps &
  TypographyProps &
  GridProps &
  FlexboxProps &
  ColorProps &
  BorderProps &
  ShadowProps &
  PositionProps & {
    /**
     * Extended color props. We need this because default `color` prop clashes with `styled-system`.
     */
    color?: keyof typeof colors | CSS.Color
    gap?: CSS.Gap
  }

const Box = styled.div<BoxPropsType>(
  compose(
    space,
    layout,
    typography,
    flexbox,
    grid,
    color,
    border,
    shadow,
    position,
    system({
      gap: true,
    }),
  ),
)

export default Box
