import AxiosInstance from 'config/apiServiceApiGateway'
import { Get } from 'config/apiServiceConfig'

export interface GetPicListResponseType {
  content: {
    email: string
    full_name: string
    id: number
  }[]
}

export interface GetWarehouseListResponseType {
  data: {
    location_id: number
    location_name: string
    location_type: string
  }[]
}

export interface GetProductCategoryResponseType {
  content: {
    category_id: number
    category_name: string
    category_childs: {
      category_id: number
      category_name: string
    }[]
  }[]
}

export interface VendorItemType {
  id: number
  checked: boolean
  vendor_id: number
  vendor_name: string
  price: number
  discount: number
  required_qty: number
  confirm_qty: number
  shipping_date: string
  reorder_list_reason_id: number
  sourcing_pic: number
  downloaded: boolean
  downloaded_at: string
  auto_po_schedule: string
}

type GetReorderListItemWithVendorsType = GetReorderListItemType & {
  vendor_list: VendorItemType[]
}

export interface GetReorderListConfirmedResponseType {
  data: GetReorderListItemWithVendorsType[]
}

export interface GetReorderListRequestParamType {
  category_ids?: string
  from?: string
  to?: string
  location_id?: number | null
  sc_pic?: number | null
  sourcing_pic?: number | null
  sku?: string
  vendor_id?: number
  page_size: number
  page_index: number
  order_by?: string
  order_direction?: 'asc' | 'desc'
  status: 'CREATED' | 'CONFIRMED'
}

export interface GetVendorsByProductIdResponseType {
  data: {
    vendor: {
      vendorId: number
      vendorName: string
    }
    price: number
    rebate: number
    picSourcing: {
      picId: number
      picName: string
    }
  }[]
  pagination: {
    direction: 'asc' | 'desc'
    numberOfElements: number
    pageIndex: number
    pageSize: number
    sort: string
    totalElements: number
    totalPages: number
  }
}

export interface PostPutResponseType {
  data: { message: string }
}

export interface AddOrRemoveRlVendorRequestType {
  reorder_list_reason_id: number
  shipping_date: string
  id?: number
  price: number
  discount: number
  confirm_qty: number
  vendor_id: number
  vendor_sourcing_pic: number
}

export interface GetVendorsByProductIdParamsType {
  locationId: number
  vendorName?: string
}

export interface GetReasonListResponseType {
  data: {
    id: number
    reason: string
  }[]
}

export interface UpdateRlRequestType {
  id: number
  price: number
  discount: number
}

export interface ProductRlType {
  product_id: number
  product_sku_no: string
  product_name: string
  uom: string
  shipping_date: string
  price: number
  discount: number
  final_price: number
  required_qty: number
  confirm_qty: number
  reorder_list_id: number
  is_fulfilled: boolean
  reorder_list_vendor_id: number
  sell_ppn: number
}

export interface GetRlPreviewResponseType {
  data: {
    vendor_id: number
    vendor_name: string
    location_id: number
    location_name: string
    shipping_date: string
    products: ProductRlType[]
    po_rebate: number
    auto_po_schedule: string
    downloaded_at: string
    downloaded: boolean
    type: string
  }[]
}

export interface PutSaveCheckedReorderListRequestType {
  data: {
    reorder_list_vendor_id: number
  }[]
}

export interface GetReorderListItemType {
  created_at: string
  product: {
    product_id: number
    product_sku_no: string
    product_name: string
    uom: string
    product_description: string
  }
  id: number
  category: {
    id: number
    name: string
    type: string
  }
  processed_qty: number
  required_qty: number
  est_shipping_date: string
  confirmed_at: string
  updated_at: string
  location: {
    location_name: string
    location_type: string
    location_id: number
  }
  sc_pic: {
    id: string
    name: string
    email: string
  }
}

export interface PutConfirmRlPreviewRequestType {
  data: {
    reorder_list_id: number
    location_id: number
    product_id: number
    required_qty: number
    est_shipping_date: string
  }[]
}

export interface PostCreatePurchaseRequestRequestType {
  data: {
    vendor_id: number
    location_id: number
    shipping_date: string
    po_rebate: number
    details: {
      price: number
      product_id: number
      reorder_list_id: number
      qty: number
      group: number
    }[]
  }[]
}

export type GetReasonType = 'REORDER_LIST'

export interface GetReorderListVendorCheckedResponseType {
  data: {
    total_sku: number
    total_vendor: number
    reorder_list_vendors: {
      id: number
      reorder_list_id: number
      product_id: number
      vendor_id: number
    }[]
  }
}

export interface GetPicListParamsType {
  name: string
  pageSize?: number
  pageIndex?: number
}

export type GetAllVendorsParamType = {
  page_size: number
  page_index: number
  company_name?: string
}

export type PutSaveCheckedReorderListResponseType = {
  reorder_list_detail?: {
    id: number
    product_name: string
    product_sku: string
    reorder_list_vendor_id: number
    reorder_list_vendor_checked_by: string
  }[]
  message: string
}

export type PostPoDraftPayloadType = {
  data: {
    vendor_id: number
    location_id: number
    shipping_date: string
    po_rebate: number
  }[]
}

export type DeleteRlReasonResponseType = {
  data: {
    id: number
    reason: string
  }[]
}

export const getPicList = (params: GetPicListParamsType) =>
  Get<GetPicListResponseType>({
    url: `/api/product/catalog/pic`,
    params,
  })

export const getLocationList = (locationType: 'hub' | 'warehouse') =>
  Get<GetWarehouseListResponseType['data']>({ url: `/api/locations/${locationType}` })

export const getProductCategory = (name: string) =>
  Get<GetProductCategoryResponseType>({ url: '/api/category', params: { name } })

export const getReorderList = (params: GetReorderListRequestParamType) =>
  AxiosInstance.Get<GetReorderListConfirmedResponseType, true>({
    url: `/commercial/internal/v1/reorder-list`,
    params,
  })

export const getVendorsByProductId = (productId: number, params: GetVendorsByProductIdParamsType) =>
  Get<GetVendorsByProductIdResponseType>({
    url: '/api/product/:productId/catalogs',
    urlParams: { productId },
    params,
  })

export const putRlVendor = (reorderListId: number, body: AddOrRemoveRlVendorRequestType[]) =>
  AxiosInstance.Put<PostPutResponseType>({
    url: `/commercial/internal/v1/reorder-list/vendor/upsert/:reorderListId`,
    urlParams: { reorderListId },
    data: { data: body },
  })

export const getReasonList = (type: GetReasonType) =>
  AxiosInstance.Get<GetReasonListResponseType, true>({
    url: '/commercial/internal/v1/reason/:type',
    urlParams: { type },
  })

export const putUpdateRlVendor = (reorderListId: number, data: UpdateRlRequestType) =>
  AxiosInstance.Put<PostPutResponseType>({
    url: '/commercial/internal/v1/reorder-list/vendor/:reorderListId',
    urlParams: { reorderListId },
    data,
  })

export const getRlPreview = () =>
  AxiosInstance.Get<GetRlPreviewResponseType, true>({
    url: '/commercial/internal/v1/reorder-list/preview',
  })

export const putSaveCheckedReorderList = (data: PutSaveCheckedReorderListRequestType) =>
  AxiosInstance.Put<PutSaveCheckedReorderListResponseType>({
    url: `/commercial/internal/v2/reorder-list/save`,
    data,
  })

export const putConfirmRlPreview = (data: PutConfirmRlPreviewRequestType) =>
  AxiosInstance.Put<PostPutResponseType, true>({
    url: `/commercial/internal/v1/reorder-list/confirm`,
    data,
  })

export const deleteRlUnconfirmed = (id: number) =>
  AxiosInstance.Delete({
    url: '/commercial/internal/v1/reorder-list/:id',
    urlParams: { id },
  })

export const postCreatePurchaseRequest = (data: PostCreatePurchaseRequestRequestType) =>
  AxiosInstance.Post({ url: '/commercial/internal/v1/purchase-request', data })

export const getReorderListVendorChecked = () =>
  AxiosInstance.Get<GetReorderListVendorCheckedResponseType, true>({
    url: '/commercial/internal/v1/reorder-list/vendor/checked',
  })

export const deleteRl = (id: number) =>
  AxiosInstance.Delete<PostPutResponseType, true>({
    url: `/commercial/internal/v1/reorder-list/:id`,
    urlParams: { id },
  })

export const getAllVendors = (params: GetAllVendorsParamType) =>
  AxiosInstance.Get({
    url: `/erp/internal/v2/vendors`,
    params,
  })

export const putBulkDelete = (data: { ids: number[]; reason_id: number }) =>
  AxiosInstance.Put({
    url: '/commercial/internal/v1/reorder-list/vendors/delete',
    data,
  })

export const postPODraft = (data: PostPoDraftPayloadType) =>
  AxiosInstance.Post({
    url: `/commercial/internal/v1/po-draft`,
    data,
  })

export const fetchPODraftTotal = () =>
  AxiosInstance.Get<{ total_po_draft: number }>({
    url: `/commercial/internal/v1/po-draft/total`,
  })

export const fetchRlDeleteReasons = () =>
  AxiosInstance.Get<DeleteRlReasonResponseType, true>({
    url: `/commercial/internal/v1/reason/REORDER_LIST`,
  })
