import { memo } from "react";
import styled from "styled-components";
import { TextBody } from "components";

function RowFooter({ allData, isSuratJalan, selectedData }) {
  // const checkStatusForQTY = (dataFromMapping) => {
  //   //console.log(selectedData.supply_order_status);

  //   switch (selectedData.supply_order_status) {
  //     case "CREATED":
  //       return dataFromMapping.product_request_quantity;
  //     case "INCOMING":
  //       return dataFromMapping.product_incoming_quantity;
  //     case "COMPLETED":
  //       return dataFromMapping.product_actual_quantity;
  //     case "PARTIALLY_FULFILLED":
  //       return dataFromMapping.product_actual_quantity;
  //   }
  // };

  const calcReqQTY = () => {
    let totalReqQty = 0;
    [...allData].map((items) => {
      totalReqQty += items.product_request_quantity;
      //totalReqQty += checkStatusForQTY(items);
      //console.log(items.product_request_quantity);
      return items.product_incoming_quantity;
    });

    return totalReqQty;
  };

  const calcActualQTY = () => {
    let totalReqQty = 0;
    [...allData].map((items) => {
      totalReqQty += items.product_incoming_quantity;
      //console.log(items.product_request_quantity);
      return items.product_actual_quantity;
    });

    return totalReqQty;
  };

  return (
    <Tr
      //   className="pagebreak"
      topCurvedt=" 0px 0px 18px 18px"
      borderLineLeft
      borderLineRight
      borderLineBottom
    >
      <Td width="4%">
        <TDWrapper>
          <TextBody size="toast"></TextBody>
        </TDWrapper>
      </Td>
      <Td width="16%">
        <TDWrapper>
          <TextBody size="toast"></TextBody>
        </TDWrapper>
      </Td>
      <Td width="23%">
        <TDWrapper></TDWrapper>
      </Td>
      <Td width="12%">
        <TDWrapper>
          <CenterText>{calcReqQTY()}</CenterText>
        </TDWrapper>
      </Td>
      <Td width="7.5%">
        <TDWrapper>
          <CenterText>{isSuratJalan && calcActualQTY()}</CenterText>
        </TDWrapper>
      </Td>
      <Td width="7.5%">
        <TDWrapper>
          <CenterText></CenterText>
        </TDWrapper>
      </Td>
      <Td width="7.5%">
        <TDWrapper>
          <CenterText></CenterText>
        </TDWrapper>
      </Td>

      <Td width="7.5%">
        <TDWrapper>
          <CenterText></CenterText>
        </TDWrapper>
      </Td>
      <Td width="7.5%">
        {/* <TDWrapper>
          <CenterText>{isSuratJalan && calcActualQTY()}</CenterText>
        </TDWrapper> */}
      </Td>
      <Td width="7.5%">
        <TDWrapper>
          <CenterText></CenterText>
        </TDWrapper>
      </Td>
      {/* <Td width="15%" lastBR={true}>
        <TDWrapper></TDWrapper>
      </Td> */}
    </Tr>
  );
}

export default memo(RowFooter);

const Tr = styled.tr`
  display: flex;
  //padding: 12px 32px;
  background-color: #fffae6;
  border-bottom: 1px solid #f3f6fa;
  align-items: stretch;
  ${({ borderLineTop }) => borderLineTop && `border-top: 1px solid #bfc9d6;`}
  ${({ borderLineLeft }) => borderLineLeft && `border-left: 1px solid #bfc9d6;`}
  ${({ borderLineRight }) =>
    borderLineRight && `border-right: 1px solid #bfc9d6;`}

    ${({ borderLineBottom }) =>
    borderLineBottom && `border-bottom: 1px solid #bfc9d6;`}
  

    border-radius: ${({ topCurvedt }) => {
    return topCurvedt ? topCurvedt : "";
  }};
`;
const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.black};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  //   flex: ${({ ratio }) => ratio};
  //   text-align: start;
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  //flex: ${({ ratio }) => ratio};
  width: ${({ width }) => (width ? width : "10%")};
  text-align: start;
  ${({ lastBR }) => !lastBR && `border-right: 1px solid #FFFAE6;`}
`;

const TDWrapper = styled.div`
  padding: 12px 8px;
  //   height: 64px;
`;
const CenterText = styled.p`
  text-align: center;
`;
