import { Button, SystemIcon } from 'components'
import listenClickOutside from 'customHooks/outSideClick'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

const InputDropdownPayment = ({
  value,
  listOptions = [],
  onSelectData,
  placeholder = 'Pilih Partnership',
}) => {
  const refOutside = useRef(null)

  const [selectedData, setSelectedData] = useState({
    value: '0',
    name: placeholder,
  })
  const [showlistOptions, setShowlistOptions] = useState(false)

  listenClickOutside(refOutside, () => {
    setShowlistOptions(false)
  })

  const getSelectedData = (value) => {
    const selectedData = listOptions.find((optionData) => optionData.value === value)
    if (selectedData) return selectedData
    return { value: '0', name: placeholder }
  }

  const toggleOptionsList = () => {
    setShowlistOptions((prevState) => !prevState)
  }

  const handleClickOptionData = (optionData) => {
    setShowlistOptions(false)
    onSelectData(optionData)
  }

  const handleResetOptionData = () => {
    onSelectData({
      value: 0,
      name: placeholder,
    })
  }

  useEffect(() => {
    if (listOptions.length) {
      const name = getSelectedData(value)
      setSelectedData(name)
    }
  }, [value, listOptions])

  return (
    <Container ref={refOutside}>
      <SelectedWrapper>
        <Input onClick={toggleOptionsList}>
          {selectedData.image && <SelectedImg src={selectedData.image} />}
          <Text isDataSelected={selectedData.value !== '0'}>{selectedData.name}</Text>
        </Input>
        <Button variant="wrapper">
          <SystemIcon iconName="dropdown" fontSize="smaller" />
        </Button>
      </SelectedWrapper>

      <OptionWrapper show={showlistOptions}>
        <Option onClick={handleResetOptionData}>{placeholder}</Option>
        {listOptions.map((optionData) => (
          <Option key={optionData.value} onClick={() => handleClickOptionData(optionData)}>
            <ImgOptionWrapper>
              <Img src={optionData.image} />
            </ImgOptionWrapper>
            {optionData.name}
          </Option>
        ))}
      </OptionWrapper>
    </Container>
  )
}
export default InputDropdownPayment

const Text = styled.span`
  color: ${({ isDataSelected }) => (isDataSelected ? 'black' : '#BFC9D9')};
`
const SelectedWrapper = styled.div`
  position: relative;

  button {
    position: absolute;
    right: 15px;
    top: 13px;
  }
`

const Container = styled.div`
  position: relative;
  width: 100%;
`

const Input = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #e4eaf3;
  padding: 10px;
  box-sizing: border-box;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  cursor: pointer;
`

const OptionWrapper = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: column;
  border: 1px solid #e4eaf3;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  width: 100%;
  position: absolute;
  top: 100%;
  z-index: 2;
  background-color: white;
`

const Option = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  :hover {
    background-color: ${({ theme: { colors } }) => colors.body};
  }
  :not(:last-child) {
    border-bottom: 1px solid #e4eaf3;
  }
`

const ImgOptionWrapper = styled.div`
  width: 40px;
  height: 25px;
  margin-right: 15px;
`
const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const SelectedImg = styled(Img)`
  width: 40px;
  height: 25px;
  margin-right: 10px;
`
