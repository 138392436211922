import printJS from 'print-js'
import {
  SET_IS_LOADING,
  SET_QUERY,
  RESET_QUERY,
  SET_IS_OPEN_POPUP_CHECKER,
  SET_INBOUNDS,
  SET_PAGINATION_DATA,
  SET_SELECTED_INBOUND,
  SET_LAST_QUERY,
  SET_HUBS,
  SET_SELECTED_HUB,
  SET_DATA_SLOC_OPTIONS,
  SET_CHECKER,
  SET_SELECTED_CHECKER,
  SET_RESET,
  SET_SELECTED_INVENTORY_CONTROLLER,
  SET_MODAL_CONVERSION_RULE,
  RESET_MODAL_CONVERSION_RULE,
  SET_MODAL_CONFIRM_START_UNLOADING,
  RESET_MODAL_CONFIRM_START_UNLOADING,
} from 'storeContext/actionsType/inbound'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getInbounds,
  getInbound,
  putInbound,
  getPrintInbound,
  getHubs,
  getInboundSoV2,
  getManufacturingOrder,
  putSupplyOrderGrandStatus,
  postInboundPutaway,
  getStaffManagement,
  postStartUnloadingActivity,
  getSoConversionItems,
  applySoConversion,
} from 'utils/api'

import { toastFailed, toastSuccess } from 'utils/toast'

import { filterLocationList, authAdminChecker } from 'middleware/privateRoute'
import actionWithSideEffect from 'storeContext/middleware/actionWithSideEffect'
import { queryParamsURL, serializeQueryToURL } from 'utils/queryParamsURL'
import { initialStateInbound } from 'storeContext/reducers/inbound'

export function setSelectedHub(payload) {
  return {
    type: SET_SELECTED_HUB,
    payload: payload,
  }
}

export function setHubs(payload) {
  return {
    type: SET_HUBS,
    payload: payload,
  }
}

export function setIsLoading(payload) {
  return {
    type: SET_IS_LOADING,
    payload: payload,
  }
}

export function setReset(payload) {
  return {
    type: SET_RESET,
    payload: payload,
  }
}

export const setQuery = actionWithSideEffect(
  (query, params) => () => ({
    type: SET_QUERY,
    payload: { query, params },
  }),
  (action) => (dispatch) => {
    const { query } = action.payload
    const qs = serializeQueryToURL(query)

    dispatch(actHandleGetInboundsSO(qs))
  },
)

export const resetQuery = actionWithSideEffect(
  (query, params) => () => ({
    type: RESET_QUERY,
    payload: { query, params },
  }),
  (action) => (dispatch) => {
    queryParamsURL.set(() => initialStateInbound.query)
    dispatch(actHandleGetInboundsSO(initialStateInbound.query))
  },
)

export function setIsOpenPopupChecker(payload) {
  return {
    type: SET_IS_OPEN_POPUP_CHECKER,
    payload: payload,
  }
}

export function setSelectedChecker(payload) {
  return {
    type: SET_SELECTED_CHECKER,
    payload: payload,
  }
}

export function setSelectedInventoryController(payload) {
  return {
    type: SET_SELECTED_INVENTORY_CONTROLLER,
    payload: payload,
  }
}

export function setInbounds(payload) {
  return {
    type: SET_INBOUNDS,
    payload: payload,
  }
}

export function setPaginationData(payload) {
  return {
    type: SET_PAGINATION_DATA,
    payload: payload,
  }
}

export function setSelectedInbound(payload) {
  return {
    type: SET_SELECTED_INBOUND,
    payload: payload,
  }
}

export function setLastQuery(payload) {
  return {
    type: SET_LAST_QUERY,
    payload: payload,
  }
}

export function setSLOCOptionsData(payload) {
  return {
    type: SET_DATA_SLOC_OPTIONS,
    payload: payload,
  }
}

export function setChecker(payload) {
  return {
    type: SET_CHECKER,
    payload,
  }
}

export function setModalConfirmStartUnloading(payload) {
  return {
    type: SET_MODAL_CONFIRM_START_UNLOADING,
    payload,
  }
}
export function resetModalConfirmStartUnloading() {
  return {
    type: RESET_MODAL_CONFIRM_START_UNLOADING,
  }
}

export function setModalConversionRule(payload) {
  return {
    type: SET_MODAL_CONVERSION_RULE,
    payload,
  }
}

export function resetModalConversionRule() {
  return {
    type: RESET_MODAL_CONVERSION_RULE,
  }
}

export const actGetSoConversionItems = (soId) => async (dispatch) => {
  try {
    const {
      data: { data },
    } = await getSoConversionItems(soId)

    dispatch(
      setModalConversionRule({
        isOpen: true,
        supplyOrderId: soId,
        data,
      }),
    )
  } catch (err) {
    callErrorMsg(err)
  }
}

export const actApplySoConversion = () => async (dispatch, getState) => {
  const { stateInbound } = getState()

  try {
    const { data } = await applySoConversion({
      supply_order_id: stateInbound.modalConversionRule.supplyOrderId,
    })
    toastSuccess(data.message)
    dispatch(resetModalConversionRule())
    dispatch(actHandleGetInboundsSO(stateInbound.query))
  } catch (err) {
    callErrorMsg(err)
  }
}

export const actGetChecker =
  ({ locationId, role }) =>
  (dispatch) => {
    return getStaffManagement(locationId, role)
      .then(({ data }) => {
        dispatch(setChecker(data.data))
      })
      .catch((err) => {
        callErrorMsg(err)
      })
  }

export const actPostInboundPutaway =
  ({ id, status, hubStaffIdList }, onSuccess, onRejected) =>
  (dispatch) => {
    return postInboundPutaway(status, {
      supplyOrderItemIdList: id,
      hubStaffIdList,
    })
      .then(() => {
        toastSuccess('Update inventory controller berhasil.')
        onSuccess()
      })
      .catch((err) => {
        callErrorMsg(err)
        onRejected()
      })
  }

export const actHandleGetInbounds =
  (query = '') =>
  (dispatch, getState) => {
    dispatch(setIsLoading(true))
    const { stateAuth } = getState()

    let locationID = ``
    if (query === '?type=hub&status=incoming' && !authAdminChecker(stateAuth.userData)) {
      locationID += `&locationId=${stateAuth.userData.location_roles[0]?.location_id}`
    }

    return getInbounds(`${query}${locationID}`)
      .then(({ data }) => {
        const inbounds = data.content
        const pagination = {
          pageSize: data.size,
          totalData: data.totalElements,
          totalPage: data.totalPages,
          currentPage: data.number,
          currentItem: data.numberOfElements,
        }
        dispatch(setInbounds(inbounds))
        dispatch(setPaginationData(pagination))
        dispatch(setLastQuery(query))
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

const getInboundSoSuccessCallback = (listInbound, data) => (dispatch) => {
  const pagination = {
    pageSize: data.size,
    totalData: 0,
    totalPage: 0,
    currentPage: data.number,
    currentItem: data.numberOfElements,
    last: data.last,
    first: data.first,
  }
  dispatch(setInbounds(listInbound))
  dispatch(setPaginationData(pagination))
}

export const actHandleGetInboundsSO = (params) => async (dispatch, getState) => {
  dispatch(setIsLoading(true))

  const {
    stateSupplyOrder: { destinationHubs },
  } = getState()

  const query = {
    ...params,
    ...(destinationHubs.length && {
      destination: params?.destination || destinationHubs.map((item) => item.location_id).join(','),
    }),
  }

  try {
    const responseInboundSo = await getInboundSoV2(query)
    const newResponseList = responseInboundSo.data.data.content.map((item) => ({ ...item }))

    if (params.status === 'CONVERSION') {
      for (const [index, element] of responseInboundSo.data.data.content.entries()) {
        try {
          await getManufacturingOrder({
            invoice_reference: element.supplyOrderNumber,
          })
          newResponseList[index].needConversion = false
        } catch (err) {
          newResponseList[index].needConversion = true
        }
      }
      dispatch(getInboundSoSuccessCallback(newResponseList, responseInboundSo.data.data))
      return
    }

    dispatch(getInboundSoSuccessCallback(newResponseList, responseInboundSo.data.data))
  } catch (error) {
    callErrorMsg(error)
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const actHandleGetInbound =
  (id, next = () => {}) =>
  (dispatch) => {
    dispatch(setIsLoading(true))
    return getInbound(id)
      .then(({ data }) => {
        dispatch(setSelectedInbound(data))
        next(data)
      })
      .catch(() => {
        toastFailed('Data tidak tersedia')
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

export const actHandleUpdateInbound =
  (payload, next = () => {}) =>
  (dispatch, getState) => {
    const { selectedInbound } = getState().stateInbound
    dispatch(setIsLoading(true))
    return putInbound(selectedInbound.supply_order_id, payload)
      .then(({ data }) => {
        next()
        toastSuccess('Update data berhasil.')
      })
      .catch(() => {
        toastFailed('Update data gagal.')
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

export const actHandlePrint = (id) => (dispatch) => {
  dispatch(setIsLoading(true))
  return getPrintInbound(id)
    .then(({ data }) => {
      printJS(data.file_url)
    })
    .catch(() => {
      toastFailed('Data gagal didapatkan')
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actGetHubs = (locationId) => (dispatch, getState) => {
  dispatch(setIsLoading(true))
  const stateAuth = getState().stateAuth

  return getHubs()
    .then(({ data }) => {
      const formatedData = []

      data.forEach((el) => {
        formatedData.push({
          ...el,
          name: el.location_name,
        })

        if (Number(locationId) === el.location_id && !authAdminChecker(stateAuth.userData)) {
          dispatch(setSelectedHub({ ...el, name: el.location_name }))
        }
      })

      const filteredData = filterLocationList(
        formatedData,
        stateAuth.userData,
        'location_id',
        'HUB',
      )
      /*
      filteredData.forEach((el) => {
        //console.log(el);
        if (Number(locationId) === el.location_id) {
          dispatch(setSelectedHub({ ...el, name: el.location_name }));
        }
      });
      */

      dispatch(setHubs(filteredData))

      //ORIGINAL
      //dispatch(setHubs(formatedData));
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actHandleUpdateStatus =
  (id, status, cb = () => {}) =>
  (dispatch) => {
    dispatch(setIsLoading(true))
    return putSupplyOrderGrandStatus(id, { status })
      .then(() => {
        cb()
      })
      .catch((err) => {
        callErrorMsg(err)
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

export const actStartUnloading =
  (id, cb = () => {}) =>
  async (dispatch) => {
    dispatch(setIsLoading(true))

    try {
      await postStartUnloadingActivity(id)
      cb()
    } catch (error) {
      callErrorMsg(error)
    }

    dispatch(setIsLoading(false))
  }
