import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { InboundScheduleActionType } from 'features/Enterprise/CapacityHandler/services/inboundSchedule'
import {
  AvailableTimeSlotType,
  GetInboundScheduleDetailType,
  GetSlotListType,
  GetVendorsType,
  PoNumberType,
  VendorType,
  GetAuditTrailsDataType,
  GetReasonResponseType,
} from 'features/Enterprise/CapacityHandler/services/inboundScheduleAdd'
import { LocationType } from 'features/Enterprise/CapacityHandler/services/loadingDock'

export type PageType = Exclude<InboundScheduleActionType, 'DELETE'> | 'INITIAL'
export type PrevStateType = {
  locationId: number
  loadingDockId: number
  date: string
}

interface InboundScheduleAddStateType {
  location: LocationType | ObjectNullType
  pageType: PageType
  prevState: PrevStateType | null
  vendorList: GetVendorsType['data']
  slotList: GetSlotListType['data']
  availableTimeSlot: AvailableTimeSlotType[]
  scheduleDate: Date | null
  vendor: VendorType | null
  slot: GetSlotListType['data'][0] | null
  timeSlot: AvailableTimeSlotType | null
  poNumberList: Pick<
    GetInboundScheduleDetailType['purchase_order'][0],
    'purchase_order_id' | 'purchase_order_number' | 'reason' | 'total_pcs'
  >[]
  selectedPoNumber: GetInboundScheduleDetailType['purchase_order']
  selectedPoNumberToDelete: GetInboundScheduleDetailType['purchase_order'][0] | null
  inboundScheduleDetail: GetInboundScheduleDetailType | ObjectNullType
  isLoadingFetchDetail: boolean
  isPoReasonOpen: boolean
  isAuditTrailOpen: boolean
  auditTrails: GetAuditTrailsDataType | ObjectNullType
  reasons: GetReasonResponseType[]
}

const initialState: InboundScheduleAddStateType = {
  location: {},
  pageType: 'INITIAL',
  prevState: null,
  vendorList: [],
  slotList: [],
  availableTimeSlot: [],
  scheduleDate: null,
  vendor: null,
  slot: null,
  timeSlot: null,
  poNumberList: [],
  selectedPoNumber: [],
  selectedPoNumberToDelete: null,
  inboundScheduleDetail: {},
  isLoadingFetchDetail: false,
  isPoReasonOpen: false,
  isAuditTrailOpen: false,
  auditTrails: {},
  reasons: [],
}

export const SLICE_NAME = 'inboundScheduleAdd'

const inboundScheduleAddSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    setPageType: (state, { payload }: PayloadAction<PageType>) => {
      state.pageType = payload
    },
    setPrevState: (state, { payload }: PayloadAction<PrevStateType>) => {
      state.prevState = { ...payload }
      state.scheduleDate = payload.date ? new Date(payload.date) : new Date()
    },
    setVendorList: (
      state,
      { payload }: PayloadAction<InboundScheduleAddStateType['vendorList']>,
    ) => {
      state.vendorList = [...payload]
    },
    setLocation: (state, { payload }: PayloadAction<LocationType>) => {
      state.location = { ...payload }
    },
    setSlotList: (state, { payload }: PayloadAction<InboundScheduleAddStateType['slotList']>) => {
      state.slotList = [...payload]
    },
    setAvailableTimeSlot: (
      state,
      { payload }: PayloadAction<InboundScheduleAddStateType['availableTimeSlot']>,
    ) => {
      state.availableTimeSlot = [...payload]
    },
    setScheduleDate: (state, { payload }: PayloadAction<Date>) => {
      state.scheduleDate = payload
    },
    setVendor: (state, { payload }: PayloadAction<InboundScheduleAddStateType['vendor']>) => {
      state.vendor = payload
    },
    setSlot: (state, { payload }: PayloadAction<InboundScheduleAddStateType['slot']>) => {
      state.slot = payload
    },
    setTimeSlot: (state, { payload }: PayloadAction<InboundScheduleAddStateType['timeSlot']>) => {
      state.timeSlot = payload
    },
    setPoNumberList: (state, { payload }: PayloadAction<PoNumberType[]>) => {
      state.poNumberList = payload.map((x) => ({
        purchase_order_id: x.purchaseOrderId,
        purchase_order_number: x.purchaseOrderNumber,
        reason: '',
        total_pcs: x.totalPcs,
      }))
    },
    setSelectedPoNumber: (
      state,
      { payload }: PayloadAction<InboundScheduleAddStateType['selectedPoNumber']>,
    ) => {
      state.selectedPoNumber = [...payload]
    },
    setSelectedPoNumberToDelete: (
      state,
      { payload }: PayloadAction<InboundScheduleAddStateType['selectedPoNumberToDelete']>,
    ) => {
      state.selectedPoNumberToDelete = payload
    },
    setInboundScheduleDetail: (
      state,
      { payload }: PayloadAction<InboundScheduleAddStateType['inboundScheduleDetail']>,
    ) => {
      state.inboundScheduleDetail = { ...payload }
    },
    setLoadingFetchDetail: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingFetchDetail = payload
    },
    setAddPoReasonOpen: (state, action: PayloadAction<boolean>) => {
      state.isPoReasonOpen = action.payload
    },
    setAuditTrailsData: (
      state,
      action: PayloadAction<InboundScheduleAddStateType['auditTrails']>,
    ) => {
      state.auditTrails = action.payload
    },
    setAuditTrailOpen: (state, action: PayloadAction<boolean>) => {
      state.isAuditTrailOpen = action.payload
    },
    setReasons: (state, action: PayloadAction<GetReasonResponseType[]>) => {
      state.reasons = action.payload
    },
  },
})

export const {
  resetState,
  setPageType,
  setPrevState,
  setLocation,
  setVendorList,
  setSlotList,
  setAvailableTimeSlot,
  setScheduleDate,
  setSlot,
  setTimeSlot,
  setVendor,
  setPoNumberList,
  setSelectedPoNumber,
  setSelectedPoNumberToDelete,
  setInboundScheduleDetail,
  setLoadingFetchDetail,
  setAddPoReasonOpen,
  setAuditTrailOpen,
  setAuditTrailsData,
  setReasons,
} = inboundScheduleAddSlice.actions
export default inboundScheduleAddSlice.reducer
