import { SET_LAST_PARAMS, SET_DATA_PRINT } from 'storeContext/actionsType/inbound'

import { setIsLoading, setInbounds, setPaginationData } from './index'

import { getInboundPo, getInboundPoDataPrint } from 'utils/api'

export function setLastParams(payload) {
  return {
    type: SET_LAST_PARAMS,
    payload: payload,
  }
}

export function setDataPrint(payload) {
  return {
    type: SET_DATA_PRINT,
    payload: payload,
  }
}

export const actHandleGetInboundsPo = (params) => (dispatch) => {
  dispatch(setIsLoading(true))
  return getInboundPo(params)
    .then(({ data }) => {
      const inbounds = data.content
      const pagination = {
        pageSize: data.size,
        totalData: data.totalElements,
        totalPage: data.totalPages,
        currentPage: data.number,
        currentItem: data.numberOfElements,
      }
      dispatch(setInbounds(inbounds))
      dispatch(setPaginationData(pagination))
      dispatch(setLastParams(params))
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actHandleGetInboundPrintData =
  (id, cb = () => {}) =>
  (dispatch) => {
    dispatch(setIsLoading(true))
    return getInboundPoDataPrint(id)
      .then(({ data }) => {
        dispatch(setDataPrint(data))
        cb()
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }
