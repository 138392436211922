import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { downloadFileUrl } from 'utils/downloadFileUrl'
import {
  GetStockOpnameListResponseType,
  getLocationList,
  getStockOpnameList,
  getStockOpnameGroupList,
  postCancelStockOpname,
  postSubmitStockOpname,
  getStockOpnameDetail,
  getStockOpnameDetailList,
  getCheckerList,
  postAssignChecker,
  getReportSTO,
  postAdjustStockOpname,
  getStockOpnamePrintBeritaAcara,
  cancelStoAdjustment,
  recountStoAdjustment,
} from 'utils/apiList/stockOpname'
import { setDataPrintStockOpname } from 'redux/printArea'
import type {
  GetStockOpnameGroupListRequestType,
  GetStockOpnameListResquestType,
} from 'utils/apiList/stockOpname'
import { callErrorMsg } from 'helpers/errorMsg'
import { toastSuccess } from 'utils/toast'

const SLICE_NAME = 'stockOpnameCheckList'

const validateParamsValue = (
  key: keyof GetStockOpnameGroupListRequestType['params'],
  value: string | number,
) =>
  value
    ? {
        [key]: value,
      }
    : {}

export const fetchWarehouseLocationsList = createAsyncThunk(
  `${SLICE_NAME}/fetchStockOpnameListWarehouseLocationList`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getLocationList()

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

type FetchStockOpnameListParamsType = {
  stockOpnameGroupID: number
  pageIndex?: number
  count?: number
}
export const fetchStockOpnameList = createAsyncThunk(
  `${SLICE_NAME}/fetchStockOpnameList`,
  async (
    { stockOpnameGroupID, pageIndex, count }: FetchStockOpnameListParamsType,
    { rejectWithValue, getState },
  ) => {
    const {
      stockOpnameList: { query },
    } = getState() as StoreStateType
    const params: GetStockOpnameListResquestType['params'] = {
      stockOpnameGroupID,
      ...(pageIndex !== undefined && { pageIndex }),
      ...(count !== undefined && { countVersion: count }),
      pageSize: 20,
      ...validateParamsValue('locationId', query.location.location_id),
      ...validateParamsValue('status', query.status),
      ...validateParamsValue('stockOpnameNumber', query.stockOpnameNumber),
      ...validateParamsValue('searchProduct', query.searchProduct),
      ...validateParamsValue('searchRack', query.searchRack),
    }

    try {
      const res = await getStockOpnameList({ params })
      return {
        ...res.data,
        shouldResetList: pageIndex !== undefined && pageIndex === 1,
      }
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

interface STOListDataType {
  action: string[]
  assignee: { id: number; name: string }
  createdAt: number
  gap: number
  id: number
  status: string
  stockOpnameGroupId: number
  stockOpnameNumber: string
  isRecount: boolean
  totalRack: number
  uniqueKey: string
}

interface STOGroupListDataType {
  action: string
  completedAt: number
  createdAt: number
  expiredAt: number
  gap: number
  id: number
  location: { id: number; name: string }
  startAt: number
  stockOpnameGroupNumber: string
  taskComplete: number
  taskCount: number
  totalRack: number

  uniqueKey: string
  child: {
    data: STOListDataType[]
    isLoading: boolean
    query: {
      pageSize: number
      pageIndex: number
    }
    pagination: {
      pageIndex: number
      pageSize: number
      numberOfElements: number
    }
  }
}

interface ListStoChildType {
  [key: number]: STOGroupListDataType['child']
}

export const fetchStockOpnameGroupList = createAsyncThunk(
  `${SLICE_NAME}/fetchStockOpnameGroupList`,
  async (_, { rejectWithValue, getState }) => {
    const {
      stockOpnameList: { query },
    } = getState() as StoreStateType

    const commonObjectParams = {
      ...validateParamsValue('locationId', query.location.location_id),
      ...validateParamsValue('status', query.status),
      ...validateParamsValue('searchProduct', query.searchProduct),
      ...validateParamsValue('searchRack', query.searchRack),
      ...validateParamsValue('stockOpnameNumber', query.stockOpnameNumber),
    }

    const stoGroupParams: GetStockOpnameGroupListRequestType['params'] = {
      pageSize: query.pageSize,
      pageIndex: query.pageIndex,
      ...commonObjectParams,
    }

    try {
      const res = await getStockOpnameGroupList({ params: stoGroupParams })

      const listStoGroupIds = res.data.data.map((item) => item.id)

      const listResponseOfStoChildList: ListStoChildType[] = []
      const listOfGetStoListRequest: Promise<
        AxiosResponse<GetStockOpnameListResponseType, unknown>
      >[] = []

      // eslint-disable-next-line no-restricted-syntax
      for (const id of listStoGroupIds) {
        const paramsForStoChildList: GetStockOpnameListResquestType['params'] = {
          stockOpnameGroupID: id,
          pageIndex: 1,
          pageSize: 20,
          ...commonObjectParams,
        }
        listOfGetStoListRequest.push(getStockOpnameList({ params: paramsForStoChildList }))
      }

      const responses = await Promise.all(listOfGetStoListRequest)
      responses.forEach((responseItem) => {
        const {
          config: { params },
          data,
        } = responseItem
        const childListData =
          data.data.map((element) => ({
            ...element,
            uniqueKey: window.crypto.randomUUID(),
          })) || []

        listResponseOfStoChildList.push({
          [params.stockOpnameGroupID]: {
            data: childListData,
            pagination: {
              pageSize: data.pagination.pageSize,
              pageIndex: data.pagination.pageIndex,
              numberOfElements: data.pagination.numberOfElements,
            },
            isLoading: false,
            query: {
              pageSize: data.pagination.pageSize,
              pageIndex: data.pagination.pageIndex,
            },
          },
        })
      })

      const getStoChildFromList = (id: number) => {
        const result = listResponseOfStoChildList.find((stoChildItem) => stoChildItem[id]) as never
        return result[id]
      }

      return {
        data: res.data.data.map((elParent) => ({
          ...elParent,
          uniqueKey: window.crypto.randomUUID(),
          child: getStoChildFromList(elParent.id),
        })),
        pagination: res.data.pagination,
        pageIndexParent: query.pageIndex,
      }
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const cancelStockOpname = createAsyncThunk(
  `${SLICE_NAME}/cancelStockOpname`,
  async (_, { rejectWithValue, getState }) => {
    const {
      stockOpnameList: {
        stageCancelSTO: { id, type },
      },
    } = getState() as StoreStateType

    try {
      const res = await postCancelStockOpname({ id, type })
      toastSuccess('Stok Opname No STO2022/02984 berhasil dibatalkan.')
      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const submitStockOpname = createAsyncThunk(
  `${SLICE_NAME}/SubmitStockOpname`,
  async (payload: { groupId: number }, { rejectWithValue }) => {
    try {
      const res = await postSubmitStockOpname(payload)
      toastSuccess('Stok Opname Berhasil di submit')
      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchStockOpnameDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchStockOpnameDetail`,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await getStockOpnameDetail(id)
      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchStockOpnameDetailList = createAsyncThunk(
  `${SLICE_NAME}/fetchStockOpnameDetailList`,
  async ({ id, params }: { id: string; params: object }, { rejectWithValue }) => {
    try {
      const res = await getStockOpnameDetailList(id, params)
      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchCheckerList = createAsyncThunk(
  `${SLICE_NAME}/fetchCheckerList`,
  async (_, { rejectWithValue, getState }) => {
    const {
      stockOpnameList: {
        assignChecker: {
          query: { location, type, assignee },
        },
      },
    } = getState() as StoreStateType

    try {
      const res = await getCheckerList({
        locationId: location.id.toString(),
        params: { checkerId: type === 'REASSIGN' ? assignee.id : undefined },
      })

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const submitAssignChecker = createAsyncThunk(
  `${SLICE_NAME}/SubmitAssignChecker`,
  async (_, { rejectWithValue, getState }) => {
    const {
      stockOpnameList: {
        assignChecker: {
          query: { stockOpnameId, type },
          data,
        },
      },
    } = getState() as StoreStateType

    const assigneeId = data.filter((el) => el.isChecked).map((el) => el.id)

    try {
      const res = await postAssignChecker({
        payload: { id: stockOpnameId, assigneeId, isReAssign: type === 'REASSIGN' },
      })
      toastSuccess(res.data.message)
      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchPrintSTO = createAsyncThunk(
  `${SLICE_NAME}/fetchPrintSTO`,
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const {
        data: { data },
      } = await getReportSTO(id)
      const newResponseData = {
        ...data,
        stockOpnameItems: data.stockOpnameItems.map((itemData) => ({
          ...itemData,
          uniqueId: crypto.randomUUID(),
        })),
      }
      dispatch(setDataPrintStockOpname({ doc: 'STO', data: newResponseData }))

      return data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const adjustStockOpname = createAsyncThunk(
  `${SLICE_NAME}/adjustStockOpname`,
  async (_, { rejectWithValue, getState }) => {
    const {
      stockOpnameList: {
        stageConfirmAdjusmtent: { stockOpnameId },
      },
    } = getState() as StoreStateType

    try {
      const res = await postAdjustStockOpname({ stockOpnameId: stockOpnameId.toString() })
      toastSuccess(res.data.message)
      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchPrintBeritaAcara = createAsyncThunk(
  `${SLICE_NAME}/fetchPrintBeritaAcara`,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await getStockOpnamePrintBeritaAcara(id)
      if (res.data?.data?.message) {
        downloadFileUrl(res.data.data.message)
      }
      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

type AdjustStoParamType = {
  stoGroupId: number
  callbackOnSuccess: () => void
}

export const adjustStockOpnameAdjustmentPage = createAsyncThunk(
  `${SLICE_NAME}/adjustStockOpnameAdjustmentPage`,
  async ({ stoGroupId, callbackOnSuccess }: AdjustStoParamType, { dispatch, rejectWithValue }) => {
    try {
      const res = await postAdjustStockOpname({ stockOpnameId: stoGroupId.toString() })
      callbackOnSuccess()
      toastSuccess(res.data.message)
      dispatch(fetchStockOpnameGroupList())
      return res
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const recountStockOpnameAdjustmentPage = createAsyncThunk(
  `${SLICE_NAME}/recountStockOpnameAdjustmentPage`,
  async ({ stoGroupId, callbackOnSuccess }: AdjustStoParamType, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await recountStoAdjustment(stoGroupId, {
        type: 'ALL',
        ids: [],
        excludeIds: [],
      })
      callbackOnSuccess()
      toastSuccess(data.message)
      dispatch(fetchStockOpnameGroupList())
      return data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
export const cancelStockOpnameAdjustmentPage = createAsyncThunk(
  `${SLICE_NAME}/cancelStockOpnameAdjustmentPage`,
  async ({ stoGroupId, callbackOnSuccess }: AdjustStoParamType, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await cancelStoAdjustment(stoGroupId)
      callbackOnSuccess()
      toastSuccess(data.message)
      dispatch(fetchStockOpnameGroupList())
      return data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
