import { useContext } from 'react'
import { Box, Typography } from '@astro-ui/components'
import { formatMoneyRMG } from 'helpers'
import store from 'storeContext'
import { StyledRoundedCard, StyledTermConditonSection, StyledNotes } from '../../style'

const LabelAndContentTemplate = ({ label, content }) => {
  return (
    <Box display="flex" justifyContent="space-between">
      <Typography variant="body2" fontWeight="500">
        {label}
      </Typography>
      <Typography variant="body2">{content}</Typography>
    </Box>
  )
}

const termsAndConditionList = [
  {
    id: 1,
    description:
      'Penjual akan memeriksa ketersediaan Produk yang dipesan dan akan mengkonfirmasi PO tersebut dalam waktu 1 hari kerja setelah menerima PO.',
  },
  {
    id: 2,
    description:
      'Penjual dapat mengajukan revisi/penyesuaian pada PO terkait jumlah pemesanan Produk yang dapat dipenuhi paling lambat 1 (satu) hari kerja setelah PO dikirim oleh Pembeli. Permintaan perubahan atas PO yang telah lewat dari 1 (satu) hari kerja tidak dapat diterima oleh Pembeli dan PO akan dianggap telah disetujui oleh Penjual.',
  },
  {
    id: 3,
    description:
      'Produk yang dikirimkan oleh Penjual wajib memenuhi ketentuan Minimum Shelf Life on Receiving (MSLOR) sebagaimana diatur dalam Surat Pemberitahuan Nomor 017/LEGASTRO/VII/2022 tertanggal 19 Juli 2022 (Khusus Produk Inventory).',
  },
  {
    id: 4,
    description:
      'Pada saat Produk sampai di lokasi pengiriman, Pembeli wajib melakukan pemeriksaan atas Produk sebelum melakukan penerimaan barang. Untuk pemeriksaan dari barang yang terkategori sebagai barang inventory akan dilakukan secara sampling, sedangkan pemeriksaan kuantitas dan kualitas dari barang yang terkategori sebagai barang non-inventory dilakukan secara menyeluruh. ',
  },
  {
    id: 5,
    description:
      'Dalam hal pada saat pemeriksaan tidak ditemukan masalah kualitas pada Produk (kotor, berlubang, sobek, terbuka, ada coretan/tempelan, penyok, basah, dan/atau pecah/retak (dalam), tidak sesuai spesifikasi, rusak pada kemasan atau Produk (selanjutnya akan disebut “Barang Cacat”)) atau kuantitas Produk yang dikirimkan kurang dari kuantitas produk yang dipesan dalam PO (“Kekurangan Barang”), maka Pembeli akan menerbitkan Good Received Notes.',
  },
  {
    id: 6,
    description:
      'Khusus untuk metode pembayaran TOP dan COD, dalam hal pada saat pemeriksaan ditemukan adanya Barang Cacat atau Kekurangan Barang, maka Pembeli akan melakukan revisi terhadap Surat Jalan dan dokumen tagihan. ',
  },
  {
    id: 7,
    description:
      'Khusus untuk metode pembayaran CBD, dalam hal pada saat pemeriksaan ditemukan adanya Barang Cacat atau Kekurangan Barang, maka Penjual wajib mengembalikan uang (refund)/menjadikan deposit/retur barang atas sejumlah harga Barang Cacat dan/atau Kekurangan Barang yang ditemukan pada saat pemeriksaan dilakukan.',
  },
  {
    id: 8,
    description:
      'Apabila pada saat pemeriksaan terdapat kelebihan kuantitas Produk dari jumlah Produk yang dipesan oleh Pembeli melalui PO, maka Pembeli wajib untuk menolak kelebihan Produk tersebut.',
  },
]

const getPageBreakValueByItemLength = (length) => {
  if (length > 0 && length < 3) return 'avoid'
  const itemsPerPage = 5
  const currentPage = Math.ceil((length - 5) / itemsPerPage)

  const isEven = currentPage % 2 === 0
  if ((length >= 3 && length <= 5) || isEven) return 'always'
  return 'avoid'
}

const TermCondition = () => {
  const {
    state: { dataPOLiteCreatePO },
  } = useContext(store.PrintAreaContext)

  return (
    <StyledTermConditonSection
      pageBreak={getPageBreakValueByItemLength(dataPOLiteCreatePO.items.length)}
    >
      <Box width="60%" paddingRight="10px">
        <Typography variant="body2" fontWeight="700">
          Terms & Conditions
        </Typography>
        <Box display="flex" flexDirection="column">
          {termsAndConditionList.map((item) => (
            <Box display="flex" key={`terms-condition-${item.id}`}>
              <Box
                display="inline-block"
                marginRight="5px"
                borderBottom="5px"
                style={{ fontSize: '10px' }}
              >
                {item.id}
              </Box>

              <Box style={{ fontSize: '10px' }}>{item.description}</Box>
            </Box>
          ))}
        </Box>
      </Box>
      <Box width="40%">
        <StyledRoundedCard>
          <Box padding="8px 12px">
            <LabelAndContentTemplate
              label="SUBTOTAL"
              content={formatMoneyRMG(dataPOLiteCreatePO?.sub_total) || '-'}
            />
            <LabelAndContentTemplate
              label="TOTAL DISKON"
              content={formatMoneyRMG(dataPOLiteCreatePO?.discount) || '-'}
            />
            <LabelAndContentTemplate
              label="TOTAL PAJAK"
              content={formatMoneyRMG(dataPOLiteCreatePO?.tax) || '-'}
            />
            <LabelAndContentTemplate
              label="BIAYA PENGIRIMAN"
              content={formatMoneyRMG(dataPOLiteCreatePO?.shipping_cost) || '-'}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            backgroundColor="#E9E9E9"
            padding="8px 12px"
          >
            <Typography fontSize="16px" fontWeight="800">
              TOTAL
            </Typography>
            <Typography fontSize="16px" fontWeight="800">
              {formatMoneyRMG(dataPOLiteCreatePO?.total) || '-'}
            </Typography>
          </Box>
        </StyledRoundedCard>
        <Typography variant="body2" fontWeight="700" marginTop="10px">
          Other Notes:
        </Typography>
        <StyledNotes>{dataPOLiteCreatePO?.note || '-'}</StyledNotes>
      </Box>
    </StyledTermConditonSection>
  )
}
export default TermCondition
