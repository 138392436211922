export const SET_IS_LOADING_PRODUCT = 'SET_IS_LOADING_PRODUCT/product-page'
export const SET_CATEGORIES = 'SET_CATEGORIES/product-page'
export const SET_PRODUCTS = 'SET_PRODUCTS/product-page'
export const SET_DATA_PAGINATION = 'SET_DATA_PAGINATION/product-page'
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT/product-page'
export const SET_LAST_QUERY = 'SET_LAST_QUERY/product-page'
export const SET_FILTER_CATEGORIES = 'SET_FILTER_CATEGORIES/product-page'
export const SET_LIST_ID_CATEGORIES = 'SET_LIST_ID_CATEGORIES/product-page'
export const SET_LOADING_LOAD_MORE = 'SET_LOADING_LOAD_MORE/product-page'
export const SET_DATA_STRUCTURE = 'SET_DATA_STRUCTURE/product-page'
export const SET_LAST_PARAMS = 'SET_LAST_PARAMS/product-page'
export const SET_BULK_PRICE_PRODUCTS = 'SET_BULK_PRICE_PRODUCTS'
