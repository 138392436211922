import {
  DialogTitle as MuiDialogTitle,
  DialogTitleProps as MuiDialogTitleProps,
} from '@mui/material'
import styled from 'styled-components'

export type DialogTitlePropsType = MuiDialogTitleProps

const DialogTitle = styled(MuiDialogTitle)<DialogTitlePropsType>``

export default DialogTitle
