import {
  TableContainer as MuiTableContainer,
  TableContainerProps as MuiTableContainerProps,
} from '@mui/material'
import styled from 'styled-components'

export type TableContainerPropsType = MuiTableContainerProps

const TableContainer = styled(MuiTableContainer)<TableContainerPropsType>``
export default TableContainer
