import { toastFailed } from "utils/toast";

export const getParams = (paramsKey) => {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get(paramsKey);
  return myParam;
  //console.log(myParam);
};

export const checkMaxPriceAfterDiscount = (current_price, max_price) => {
  // console.log(current_price);
  // console.log(max_price);
  if (current_price > max_price) {
    toastFailed("harga diskon tidak boleh melebihi harga original");
    return false;
  } else {
    return true;
  }
};

export const caclulatePercentage = (current_price, original_price) => {
  const resultPerc = (current_price / original_price) * 100;

  // console.log(current_price);
  // console.log(original_price);
  // console.log(resultPerc);
  return resultPerc;
};

export const calcPricFromPercent = (percentage, origin_price) => {
  const percentPriceAfterDisc = (percentage / 100) * origin_price;
  //console.log(percentPriceAfterDisc);
  const resultFinalPrice = percentPriceAfterDisc - 100;
  // console.log(resultFinalPrice);
  return resultFinalPrice;
};
