import { memo } from "react";
import styled from "styled-components";

function THeadPickingList({ selectedData }) {
  return (
    <Container>
      <Tr
        topCurvedt="18px 18px 0px 0px"
        borderLineTop
        borderLineLeft
        borderLineRight
      >
        <Th width="4%" rowSpan="2">
          <THWrapper>No</THWrapper>
        </Th>
        <Th width="16%">
          <THWrapper>Barcode</THWrapper>
        </Th>
        <Th width="23%">
          <THWrapper>Nama Produk</THWrapper>
        </Th>
        <Th width="12%">
          <THWrapper>Order Qty</THWrapper>
        </Th>
        <Th width="30%" colSpan="3">
          <THWrapper>
            <CenterText>
              Picking: {selectedData?.location_origin_name}
            </CenterText>
          </THWrapper>
        </Th>
        <Th width="15%" lastBR={true}>
          <THWrapper>Remarks / Notes</THWrapper>
        </Th>
      </Tr>
      <Tr pt="0px" borderLineLeft borderLineRight>
        <Th width="4%"></Th>
        <Th width="16%"></Th>
        <Th width="23%"></Th>
        <Th width="12%"></Th>

        <Th width="10%">
          <THBottomWrapper>
            <CenterText>SLOC</CenterText>
          </THBottomWrapper>
        </Th>
        <Th width="10%">
          <THBottomWrapper>
            <CenterText>Qty</CenterText>
          </THBottomWrapper>
        </Th>
        <Th width="10%">
          <THBottomWrapper>
            <CenterText>Checker</CenterText>
          </THBottomWrapper>
        </Th>

        <Th width="15%" lastBR={true}></Th>
      </Tr>
    </Container>
  );
}

export default memo(THeadPickingList);

// const Container = styled.table`
//   width: 100%;
//   border-collapse: collapse;
//   height: fit-content;
//   border-radius: 8px;
// `;

const Container = styled.thead``;
// const TBody = styled.tbody``;

const Tr = styled.tr`
  display: flex;
  //padding: 16px 32px;
  //padding: 0px 8px;
  ${({ pt }) => (pt ? `padding-top: ${pt};` : "")}
  background: #e6e7e8;
  border-radius: ${({ topCurvedt }) => {
    return topCurvedt ? topCurvedt : "";
  }};
  ${({ borderLineTop }) => borderLineTop && `border-top: 1px solid #bfc9d6;`}
  ${({ borderLineLeft }) => borderLineLeft && `border-left: 1px solid #bfc9d6;`}
  ${({ borderLineRight }) =>
    borderLineRight && `border-right: 1px solid #bfc9d6;`}
`;
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.black};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  //flex: ${({ ratio }) => ratio};
  width: ${({ width }) => (width ? width : "10%")};
  text-align: start;
  ${({ lastBR }) => !lastBR && `border-right: 1px solid #bfc9d6;`}
`;

const CenterText = styled.p`
  text-align: center;
`;

const THWrapper = styled.div`
  padding: 12px 8px;
`;

const THBottomWrapper = styled.div`
  border-top: 1px solid #bfc9d6;
  padding-bottom: 8px;
  padding-top: 8px;
`;
