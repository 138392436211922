import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit'
import { withLoadingReducer } from 'utils/reducerHandler'
import { GetFeatureServiceBulkUploadResponseType } from 'features/BulkUpload/services/bulkUpload'
import { fetchGetFeatureServiceBulkUpload } from 'features/BulkUpload/redux'
import { GetUserDetailResponseType, GetUserListResponseType } from 'features/Account/services/user'
import { fetchGetLocations } from 'features/Location/redux/location'
import { GetLocationListResponseType } from 'features/Location/@types/location'
import {
  SLICE_NAME,
  fetchGetRoles,
  fetchGetUserList,
  fetchPatchActivateAccount,
  fetchPatchDeactivateAccount,
  fetchPutResetAccount,
  fetchGetUserDetail,
  fetchGetLocationSelected,
} from './thunk'

export type UserAdminAccessSliceType = {
  isLoading: boolean
  feature: {
    bulkUpload: Nullable<GetFeatureServiceBulkUploadResponseType['data']>
    modalResetAccount: {
      isOpen: boolean
    }
    drawerAddAccount: {
      isOpen: boolean
      data?: GetUserDetailResponseType
    }
  }
  constants: {
    locations: GetLocationListResponseType['content']
    roles: {
      id: number
      name: string
    }[]
  }
  data: GetUserListResponseType['users']
  pagination: {
    pageSize: number
    pageIndex: number
    numberOfElements: number
  }
  form: {
    id?: number
    name: string
    phoneNumber: string
    email: string
    position: string
    locations: {
      location_id: number
      location_name: string
      location_type: string
    }[]
    roles: Nullable<{
      id: number
      name: string
    }>
  }
}

const initialState: UserAdminAccessSliceType = {
  isLoading: false,
  feature: {
    bulkUpload: null,
    modalResetAccount: {
      isOpen: false,
    },
    drawerAddAccount: {
      isOpen: false,
    },
  },
  constants: {
    locations: [],
    roles: [],
  },
  data: [],
  pagination: {
    pageSize: 20,
    pageIndex: 0,
    numberOfElements: 0,
  },
  form: {
    name: '',
    phoneNumber: '',
    email: '',
    position: '',
    locations: [],
    roles: null,
  },
}

const userAdminAccess = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    resetFormDetailInformation: (state) => {
      state.form.id = undefined
      state.form.name = ''
      state.form.phoneNumber = ''
      state.form.email = ''
      state.form.position = ''
    },
    setOpenModalResetAccount: (state, action) => {
      state.feature.modalResetAccount.isOpen = action.payload
    },
    setOpenDrawerAddAccount: (
      state,
      action: PayloadAction<UserAdminAccessSliceType['feature']['drawerAddAccount']>,
    ) => {
      state.feature.drawerAddAccount = action.payload

      const { isOpen } = action.payload
      if (!isOpen) {
        state.form = initialState.form
      }
    },
    setAccountForm: (
      state,
      action: PayloadAction<{
        key: keyof UserAdminAccessSliceType['form']
        value: never
      }>,
    ) => {
      const { key, value } = action.payload
      state.form[key] = value
    },

    setCheckedLocation: (
      state,
      action: PayloadAction<UserAdminAccessSliceType['form']['locations'][0]>,
    ) => {
      const locationSelected = action.payload
      const isExist = state.form.locations.some(
        (item) => item.location_id === locationSelected.location_id,
      )
      if (isExist) {
        state.form.locations = state.form.locations.filter(
          (item) => item.location_id !== locationSelected.location_id,
        )
      } else {
        state.form.locations.push(locationSelected)
      }
    },
  },
  extraReducers: withLoadingReducer(
    (builder: ActionReducerMapBuilder<UserAdminAccessSliceType>) => {
      builder
        .addCase(fetchGetFeatureServiceBulkUpload.fulfilled, (state, action) => {
          state.feature.bulkUpload = action.payload
        })
        .addCase(fetchGetLocations.fulfilled, (state, action) => {
          state.constants.locations = action.payload.content
        })
        .addCase(fetchGetRoles.fulfilled, (state, action) => {
          state.constants.roles = action.payload.data.roles
        })
        .addCase(fetchGetUserList.fulfilled, (state, action) => {
          const { data, pagination } = action.payload

          state.data = data.users

          if (pagination) {
            state.pagination = {
              pageSize: pagination.page_size as number,
              pageIndex: pagination.page_index as number,
              numberOfElements: pagination.number_of_elements as number,
            }
          }
        })
        .addCase(fetchGetUserDetail.fulfilled, (state, action) => {
          const { full_name, email, id, phone_number, position, roles } = action.payload.data

          state.feature.drawerAddAccount.data = action.payload.data

          state.form.id = id
          state.form.name = full_name
          state.form.email = email
          state.form.phoneNumber = phone_number
          state.form.position = position

          if (roles.length) {
            state.form.roles = {
              id: roles[0].id,
              name: roles[0].name,
            }
          }
        })
        .addCase(fetchGetLocationSelected.fulfilled, (state, action) => {
          state.form.locations = action.payload
        })
    },
    [
      fetchGetUserList,
      fetchGetUserDetail,
      fetchPutResetAccount,
      fetchPatchActivateAccount,
      fetchPatchDeactivateAccount,
    ],
  ),
})

export default userAdminAccess.reducer
export const {
  reset,
  resetFormDetailInformation,
  setOpenModalResetAccount,
  setOpenDrawerAddAccount,
  setAccountForm,
  setCheckedLocation,
} = userAdminAccess.actions
