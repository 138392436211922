import { memo, useState, useEffect } from 'react'
import dayjs from 'dayjs'

function TimeTicker({ data }) {
  //VARIABLE HELPER
  const timerConfig = {
    limitMinute: 2300,
    limiter: true,
    timeEnded:
      data.order_status === 'Cancelled' || data.order_status === 'Completed' ? true : false,
  }

  //INTIAL STATE
  const initTimer = () => {
    let start
    start = dayjs(data?.order_payment_at)

    if (data?.order_payment_at) {
      //console.log(start);
      start = dayjs(data?.order_payment_at)
    } else {
      start = dayjs(data?.order_created_at)
    }
    //const start = dayjs(data?.order_created_at);

    /* Override start if scheduled instant */
    if (
      data?.order_type === 'INSTANT' &&
      (data?.order_timeslot?.id > 0 || data?.order_timeslot?.timeslot_id > 0)
    ) {
      start = dayjs(data?.order_placement_at)
    }

    let end = ''

    if (data.order_status === 'Cancelled') {
      end = dayjs(data.order_cancel_at)
    } else if (data.order_status === 'Payment Expired') {
      end = dayjs(data.order_cancel_at)
    } else if (data.order_status === 'Completed') {
      end = data.order_arrived_at
        ? dayjs(data.order_arrived_at)
        : dayjs(data.order_completed_at || data.order_complete_at)
    } else if (data.order_status === 'Arrived') {
      end = data.order_arrived_at
        ? dayjs(data.order_arrived_at)
        : dayjs(data.order_completed_at || data.order_complete_at)
    } else {
      end = dayjs()
    }

    const second = end.diff(start, 's')
    const minute = Math.floor(second / 60)
    const hour = Math.floor(minute / 60)

    return {
      second: second - minute * 60,
      minute: minute - hour * 60,
      hour: hour,
    }
  }

  // STATE HOOKS
  const [timer, setTimer] = useState(initTimer)
  const [isTimerStop, setIsTimerStop] = useState(false)

  useEffect(() => {
    const ticker = setTimeout(() => {
      if (timerConfig.timeEnded) {
        setTimer(initTimer)
        setIsTimerStop(true)
        if (isTimerStop) {
          clearTimeout(ticker)
        }
      } else {
        setTimer(initTimer)
      }
    }, 1000)
    return () => clearTimeout(ticker)
  })

  // RENDER FUNCTION
  const renderTimer = () => {
    let renderHour = 'no status'
    let renderminute = ''
    let renderSecond = ''

    function changeRenderTime(hour, minute, second) {
      renderHour = hour
      renderminute = minute
      renderminute = String(minute).length === 1 ? `0${String(minute)}` : minute
      //renderSecond = second;
      renderSecond = String(second).length === 1 ? `0${String(second)}` : second
      //console.log(String(second).length);
    }

    if (data?.order_payment_status === 'Payment Verified') {
      if (
        data?.order_status === 'Upcoming Order' &&
        (data?.order_timeslot?.id > 0 || data?.order_timeslot?.timeslot_id > 0)
      ) {
        changeRenderTime('0', '00', '00')
      } else {
        changeRenderTime(timer.hour, timer.minute, timer.second)
      }
    } else {
      changeRenderTime('0', '00', '00')
    }

    return `${
      renderHour === '0' || renderHour === 0 ? '' : renderHour + ' :'
    } ${renderminute} : ${renderSecond}`
  }

  return <>{renderTimer()}</>
}

export default memo(TimeTicker)
