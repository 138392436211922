import { memo } from "react";
import styled from "styled-components";
import { TextBody } from "components";

function Row({ data, dataCount, itemIndex }) {
  const lastData = () => {
    return dataCount - 1 === itemIndex;
  };

  return (
    <Tr borderBottom={lastData()}>
      <Td ratio="2">
        <TextBody weight="light" color="pureBlack">
          L1-A3-T6-5
        </TextBody>
      </Td>
      <Td ratio="4">
        <TextBody weight="light" color="pureBlack">
          Garuda Kacang Kulit 200g 89927751101262412512321541234
        </TextBody>
      </Td>
      <Td ratio="2">
        <TextBody weight="light" color="pureBlack">
          16
        </TextBody>
      </Td>
    </Tr>
  );
}

export default memo(Row);

const Tr = styled.tr`
  display: flex;
  padding: 12px 32px;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-top: 1px solid black;
  ${({ borderBottom }) =>
    borderBottom ? "border-bottom: 1px solid black;" : ""}
  align-items: center;
`;

const Td = styled.td`
  color: black;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: center;
`;
