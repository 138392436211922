import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  BannerThematicComponentType,
  BannerThematicStateType,
  SetInformationFormPayloadType,
  ThematicLocationHubType,
  TargetingType,
  BannerThematicType,
  InformationFormType,
  SetComponentFormType,
  ThematicCategoryType,
  SetImageRulePayloadActionType,
  BannerThematicAddComponentType,
  AddComponentContentStateType,
  ComponentTypeFieldNameType,
  ComponentFormType,
} from 'features/BannerAndTicker/@types/bannerThematic'
import { ImageRulesType } from 'utils/apiList/images'
import { FileRulesType } from 'utils/apiList/files'

export const SLICE_NAME = 'bannerThematic'

const INITIAL_LOTTIE_RULES: FileRulesType = {
  extensions: [],
  maxFileSize: 0,
  typeId: 'banner-animation',
}

const INITIAL_IMAGE_RULES: ImageRulesType = {
  extensions: [],
  height: { max: 0, min: 0 },
  maxFileSize: 0,
  typeId: 'banner-images',
  width: { max: 0, min: 0 },
}

const initialState: BannerThematicStateType = {
  isLoading: false,
  informationThematicExpanded: true,
  addComponentExpenaded: false,
  activeStepperIndex: 0,
  activeComponentForm: [],
  locationHubOptions: [],
  bannerThematicDetail: {},
  informationForm: {
    name: '',
    bg_animated_url: '',
    bg_animated_file: null,
    browse_thematic_images: {
      file: null,
      imageToPreview: '',
      image: null,
    },
    is_active: false,
    targeting_type: null,
    component_sequence: '',
    font_color: '',
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    targeted_user_ids: [],
    location_ids: [],
  },
  targetingTypeOptions: [],
  tilesImage: {
    key: 'tile',
    componentState: 'CREATE_FORM',
    form: {
      name: '',
      slug: '',
      component: 'tile',
      browse_thematic_images: {
        file: null,
        imageToPreview: '',
        image: null,
      },
      animated_url: '',
      animated_file: null,
      sequence: 0,
      location_ids: [],
      category: null,
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      destination_url: '',
      is_active: false,
    },
    list: [],
  },
  bannerImage: {
    key: 'banner',
    componentState: 'CREATE_FORM',
    form: {
      name: '',
      slug: '',
      component: 'banner',
      browse_thematic_images: {
        file: null,
        imageToPreview: '',
        image: null,
      },
      animated_url: '',
      animated_file: null,
      sequence: 0,
      location_ids: [],
      targeting_type: null,
      category: null,
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      destination_url: '',
      is_active: false,
    },
    list: [],
  },
  categoryOptions: [],
  imagesRules: {
    bannerLottie: INITIAL_LOTTIE_RULES,
    bannerStatis: INITIAL_IMAGE_RULES,
    bgLottie: INITIAL_LOTTIE_RULES,
    bgStatis: INITIAL_IMAGE_RULES,
    tileLottie: INITIAL_LOTTIE_RULES,
    tileStatis: INITIAL_IMAGE_RULES,
  },
  preview: {
    type: 'JSON',
    url: '',
  },
}

const bannerThematic = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    toggleExpandStepperAccordion: (
      state,
      { payload }: PayloadAction<'informationThematicExpanded' | 'addComponentExpenaded'>,
    ) => {
      state[payload] = !state[payload]
    },
    setActiveStepperIndex: (state, { payload }: PayloadAction<number>) => {
      state.activeStepperIndex = payload
    },
    setActiveComponentForm: (state, { payload }: PayloadAction<BannerThematicComponentType>) => {
      state.activeComponentForm = [...state.activeComponentForm, payload]
    },
    deleteActiveComponentForm: (state, { payload }: PayloadAction<BannerThematicComponentType>) => {
      state.activeComponentForm = state.activeComponentForm.filter(
        (activeForm) => activeForm !== payload,
      )
    },
    setInformationForm: (state, { payload }: PayloadAction<SetInformationFormPayloadType>) => {
      const { key, value } = payload

      if (key) {
        state.informationForm[key] = value as never
      } else {
        state.informationForm = {
          ...state.informationForm,
          ...(value as Partial<InformationFormType>),
        }
      }
      // if (key) {
      //   state.informationForm.endDate = value as never
      // }
    },
    setLocationHubOptions: (state, { payload }: PayloadAction<ThematicLocationHubType[]>) => {
      state.locationHubOptions = payload
    },
    setTargetingType: (state, { payload }: PayloadAction<TargetingType[]>) => {
      state.targetingTypeOptions = payload
    },
    setBannerThematicDetail: (state, { payload }: PayloadAction<BannerThematicType>) => {
      state.bannerThematicDetail = { ...payload }
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setTilesImageForm: (state, { payload }: PayloadAction<SetComponentFormType>) => {
      const { key, value } = payload
      state.tilesImage.form[key] = value as never
    },
    setBannerImageForm: (state, { payload }: PayloadAction<SetComponentFormType>) => {
      const { key, value } = payload
      state.bannerImage.form[key] = value as never
    },
    storeComponentForm: (
      state,
      {
        payload,
      }: PayloadAction<{
        key: ComponentTypeFieldNameType
        value: BannerThematicAddComponentType | BannerThematicAddComponentType[]
      }>,
    ) => {
      const { key, value } = payload
      if (Array.isArray(value)) {
        state[key].list = [...value]
      } else {
        state[key].list = [...state[key].list, { ...value }]
      }
    },
    resetComponentForm: (state, { payload }: PayloadAction<ComponentTypeFieldNameType>) => {
      state[payload].form = { ...initialState[payload].form }
    },
    setComponentForm: (
      state,
      { payload }: PayloadAction<{ key: ComponentTypeFieldNameType; value: ComponentFormType }>,
    ) => {
      const { key, value } = payload
      state[key].form = {
        ...state[key].form,
        ...value,
      }
    },
    setComponentContentState: (
      state,
      {
        payload,
      }: PayloadAction<{ key: ComponentTypeFieldNameType; value: AddComponentContentStateType }>,
    ) => {
      const { key, value } = payload
      state[key].componentState = value
    },
    setCategoryOptions: (state, { payload }: PayloadAction<ThematicCategoryType[]>) => {
      state.categoryOptions = payload
    },
    setImageRule: (
      state,
      { payload: { name, value } }: PayloadAction<SetImageRulePayloadActionType>,
    ) => {
      state.imagesRules[name] = value as never
    },
    setPreview: (state, { payload }: PayloadAction<BannerThematicStateType['preview']>) => {
      state.preview = { ...payload }
    },
    setAutoFillComponentTilesAndBanner: (state) => {
      const { endDate, endTime, startDate, startTime, location_ids, targeting_type } =
        state.informationForm
      state.tilesImage.form = {
        ...state.tilesImage.form,
        endDate,
        endTime,
        startDate,
        startTime,
        location_ids,
        targeting_type,
      }
      state.bannerImage.form = {
        ...state.bannerImage.form,
        endDate,
        endTime,
        startDate,
        startTime,
        location_ids,
        targeting_type,
      }
    },
  },
})

export const {
  reset,
  toggleExpandStepperAccordion,
  setActiveStepperIndex,
  setActiveComponentForm,
  deleteActiveComponentForm,
  setInformationForm,
  setLocationHubOptions,
  setTargetingType,
  setBannerThematicDetail,
  setIsLoading,
  setTilesImageForm,
  resetComponentForm,
  setCategoryOptions,
  storeComponentForm,
  setBannerImageForm,
  setImageRule,
  setPreview,
  setComponentContentState,
  setAutoFillComponentTilesAndBanner,
  setComponentForm,
} = bannerThematic.actions
export default bannerThematic.reducer
