import { Get } from 'config/apiServiceConfig'
import { OrderLineItemsType } from '../redux/PopupCheckQTY/slice'

interface OrderDriverType {
  driver_id: number
  driver_full_name: string
  driver_plate_number: Nullable<string>
  driver_phone_number: Nullable<string>
}

interface OrderPackerType {
  driver_id: number
  driver_full_name: string
  driver_plate_number: Nullable<string>
  driver_phone_number: Nullable<string>
}

interface OrderPickerType {
  driver_id: number
  driver_full_name: string
  driver_plate_number: Nullable<string>
  driver_phone_number: Nullable<string>
}

export interface OrderTypeResponseInterface {
  order_line_items_gift: OrderLineItemsType[]
  fulfill_status: string
  gift_note: string
  is_new_customer: boolean
  is_picking_from_app_enable: Nullable<boolean>
  order_created_at: number
  order_customer_address: string
  order_driver: Nullable<OrderDriverType>
  order_id: number
  order_invoice: string
  order_kitchen_status: 'COMPLETED' | 'IN PROGRESS' | 'CANCELLED'
  order_line_items: OrderLineItemsType[]
  order_line_items_consumable: (OrderLineItemsType & { recommendation_quantity: number })[]
  order_line_items_extra_consumable: OrderLineItemsType[]
  order_location_id: number
  order_location_quadrant: number
  order_maximum_duration: number
  order_note: string
  order_packer: Nullable<OrderPackerType>
  order_payment_status: string
  order_payment_verified_at: number // Unix timestamp
  order_picked_at: number // Unix timestamp
  order_picker: Nullable<OrderPickerType>
  order_recipient_name: string
  order_sender_name: string
  order_service_level_name: string
  order_status:
    | 'New Order'
    | 'Picking'
    | 'Packing'
    | 'Waiting Driver'
    | 'Ready For Delivery'
    | 'Back To Hub'
    | 'Completed'
    | 'Cancelled'
    | 'In Review'
  order_type: 'INSTANT' | 'SUPER'
  report_timestamp: number
  total_relabeled_item: number
}

export const getPackingDetailByInvoice = (params: { invoice: string }) =>
  Get<{ data: OrderTypeResponseInterface }>({ url: '/api/packer/order/detail', params })
