import { ActionCreatorWithPayload } from '@reduxjs/toolkit'
import { downloadFileUrl } from 'utils/downloadFileUrl'
import { toastSuccess } from 'utils/toast'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getStoAutoRecountList,
  getStoPrintBeritaAcaraPreview,
  getStoPrintBeritaAcaraTransferAndAdjustment,
  getStoPrintBeritaAcaraWriteOff,
  getStoPrintBeritaAcaraPreviewExcel,
  getStoPrintBeritaAcaraTransferAndAdjustmentExcel,
  getStoPrintBeritaAcaraWriteOffExcel,
  recountStoAdjustment,
} from 'utils/apiList/stockOpname'
import { AxiosResponse } from 'axios'
import {
  setIdsOfParentChildAndExcludedV2,
  fetchStoAutoRecountListOnSuccess,
  resetModalAutoRecount,
  StockOpnameReviewDataV2ItemType,
} from './stockOpnameCheckAdjustmentSlice'
import {
  getProductIdsWithConditionIsSelectAll,
  getParentAndChildItemIdIfGapOnly,
  removeSelectedParentAndChildIdsByProductId,
  getListChildIdsByProductId,
  getListAfterBeingRemoveById,
  getChildIdsWithConditionGapOnly,
} from './stockOpnameCheckAdjustmentUtilV2'

export const actHandleSelectAll =
  () => (dispatch: StoreDispatchType, getState: () => StoreStateType) => {
    const {
      stockOpnameReviewDataV2,
      excludedParentProductId,
      selectedParentProductId,
      selectedChildItemId,
    } = getState().stockOpnameCheckAdjustment

    const { listChildIdToAdd, listProductIdToAdd } = getProductIdsWithConditionIsSelectAll<
      StockOpnameReviewDataV2ItemType[]
    >(stockOpnameReviewDataV2.list, excludedParentProductId, selectedParentProductId)

    const newParentProductIds = [...selectedParentProductId, ...listProductIdToAdd]
    const newChildIds = [...selectedChildItemId, ...listChildIdToAdd]

    dispatch(
      setIdsOfParentChildAndExcludedV2({
        selectedParentProductId: newParentProductIds,
        selectedChildItemId: newChildIds,
      }),
    )
  }

export const actHandleGapOnly =
  () => (dispatch: StoreDispatchType, getState: () => StoreStateType) => {
    const {
      stockOpnameReviewDataV2,
      excludedParentProductId,
      selectedParentProductId,
      selectedChildItemId,
    } = getState().stockOpnameCheckAdjustment

    const { listChildIdToAdd, listProductIdToAdd } = getParentAndChildItemIdIfGapOnly<
      StockOpnameReviewDataV2ItemType[]
    >(
      stockOpnameReviewDataV2.list,
      selectedChildItemId,
      excludedParentProductId,
      selectedParentProductId,
    )

    const newParentProductIds = [...selectedParentProductId, ...listProductIdToAdd]
    const newChildIds = [...selectedChildItemId, ...listChildIdToAdd]
    dispatch(
      setIdsOfParentChildAndExcludedV2({
        selectedParentProductId: newParentProductIds,
        selectedChildItemId: newChildIds,
      }),
    )
  }

export const actHandleTickStoGroup =
  (productId: number) => (dispatch: StoreDispatchType, getState: () => StoreStateType) => {
    const {
      stockOpnameReviewDataV2,
      excludedParentProductId,
      selectedParentProductId,
      selectedChildItemId,
      selectedStockOpnameReviewState,
    } = getState().stockOpnameCheckAdjustment

    const isRemoval = selectedParentProductId.includes(productId)
    if (isRemoval) {
      const { parentIdList, childIdList } = removeSelectedParentAndChildIdsByProductId(
        productId,
        stockOpnameReviewDataV2.list,
        selectedParentProductId,
        selectedChildItemId,
      )

      dispatch(
        setIdsOfParentChildAndExcludedV2({
          selectedParentProductId: parentIdList,
          selectedChildItemId: childIdList,
        }),
      )
    }

    if (
      isRemoval &&
      (selectedStockOpnameReviewState.isGapOnly || selectedStockOpnameReviewState.isSelectedAll)
    ) {
      const newExcludedParentProductId = [...excludedParentProductId]
      newExcludedParentProductId.push(productId)
      dispatch(
        setIdsOfParentChildAndExcludedV2({
          excludedParentProductId: newExcludedParentProductId,
        }),
      )
    }

    if (
      !isRemoval &&
      (selectedStockOpnameReviewState.isGapOnly || selectedStockOpnameReviewState.isSelectedAll)
    ) {
      const newExcludedParentProductId = getListAfterBeingRemoveById(
        excludedParentProductId,
        productId,
      )
      dispatch(
        setIdsOfParentChildAndExcludedV2({
          excludedParentProductId: newExcludedParentProductId,
        }),
      )
    }

    const isSelectManual =
      !selectedStockOpnameReviewState.isGapOnly && !selectedStockOpnameReviewState.isSelectedAll

    if (!isRemoval && selectedStockOpnameReviewState.isGapOnly) {
      const newParentList = [...selectedParentProductId]
      newParentList.push(productId)

      let newChildList = [...selectedChildItemId]
      const childIdsToAdd = getChildIdsWithConditionGapOnly(stockOpnameReviewDataV2.list, productId)
      newChildList = [...newChildList, ...childIdsToAdd]

      dispatch(
        setIdsOfParentChildAndExcludedV2({
          selectedParentProductId: newParentList,
          selectedChildItemId: newChildList,
        }),
      )
    } else if (!isRemoval && (selectedStockOpnameReviewState.isSelectedAll || isSelectManual)) {
      const newParentList = [...selectedParentProductId]
      newParentList.push(productId)

      const listChildIds = getListChildIdsByProductId(stockOpnameReviewDataV2.list, productId)
      const newChildList = [...selectedChildItemId, ...listChildIds]

      dispatch(
        setIdsOfParentChildAndExcludedV2({
          selectedParentProductId: newParentList,
          selectedChildItemId: newChildList,
        }),
      )
    }
  }

type FetchStoAutoRecountListRequestType = {
  stoGroupId: number
  shouldResetList?: boolean
  callbackFunction?: () => void
}

export const actFetchStoAutoRecountList =
  ({ stoGroupId }: FetchStoAutoRecountListRequestType) =>
  async (dispatch: StoreDispatchType) => {
    try {
      const {
        data: { data, pagination },
      } = await getStoAutoRecountList(stoGroupId)

      dispatch(
        fetchStoAutoRecountListOnSuccess({
          data,
          pagination,
        }),
      )
    } catch (err) {
      callErrorMsg(err)
    }
  }

export const actSubmitAutoRecountList =
  (stoGroupId: number) => async (dispatch: StoreDispatchType) => {
    try {
      await recountStoAdjustment(stoGroupId, {
        type: 'AUTO_RECOUNT',
        ids: [],
        excludeIds: [],
      })
      dispatch(resetModalAutoRecount())
    } catch (err) {
      callErrorMsg(err)
    }
  }

type ActionTypeNameType =
  | 'stockOpnameCheckAdjustment/setIsLoadingStoAdjustmentList'
  | 'stockOpnameList/setIsLoadingStoList'

type BeritaAcaraPdfType = 'preview' | 'adjustmentTransfer' | 'writeOff'
type BeritaAcaraExcelType = 'previewExcel' | 'adjustmentTransferExcel' | 'writeOffExcel'

export const actPrintBeritaAcaraByType =
  (
    stoGroupId: number,
    beritaAcaraType: BeritaAcaraPdfType | BeritaAcaraExcelType,
    loadingActionType: ActionCreatorWithPayload<boolean, ActionTypeNameType>,
  ) =>
  async (dispatch: StoreDispatchType) => {
    const excelReportType = ['previewExcel', 'adjustmentTransferExcel', 'writeOffExcel']

    const apiToCall = {
      preview: getStoPrintBeritaAcaraPreview,
      adjustmentTransfer: getStoPrintBeritaAcaraTransferAndAdjustment,
      writeOff: getStoPrintBeritaAcaraWriteOff,
    }

    const kongApi = {
      previewExcel: getStoPrintBeritaAcaraPreviewExcel,
      adjustmentTransferExcel: getStoPrintBeritaAcaraTransferAndAdjustmentExcel,
      writeOffExcel: getStoPrintBeritaAcaraWriteOffExcel,
    }

    dispatch(loadingActionType(true))

    if (excelReportType.includes(beritaAcaraType)) {
      try {
        const {
          data: { data, message },
        } = (await kongApi[beritaAcaraType as BeritaAcaraExcelType](
          stoGroupId,
        )) as unknown as AxiosResponse<{ data: { file_url: string }; message: string }>
        if (data.file_url) {
          downloadFileUrl(data.file_url)
        }
        if (message) toastSuccess(message)
      } catch (err) {
        callErrorMsg(err)
      } finally {
        dispatch(loadingActionType(false))
      }
    } else {
      try {
        const {
          data: { data, message },
        } = await apiToCall[beritaAcaraType as BeritaAcaraPdfType](stoGroupId)

        if (data.fileUrl) {
          downloadFileUrl(data.fileUrl)
        }
        if (message) toastSuccess(message)
      } catch (err) {
        callErrorMsg(err)
      } finally {
        dispatch(loadingActionType(false))
      }
    }
  }
