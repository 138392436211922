import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  type SOParamsDataType,
  type SOSchedulesDataType,
  type SOReportsDataType,
  type SOAutomationQueryType,
} from 'features/Enterprise/SOAutomation/services/apiList'

const sliceName = 'soAutomation'

export interface InitialStateType {
  isLoading: boolean
  soParamsData: SOParamsDataType[]
  soSchedulesData: SOSchedulesDataType[]
  soReportsData: SOReportsDataType[]
  query: SOAutomationQueryType
}

export const initialState: InitialStateType = {
  isLoading: false,
  soParamsData: [],
  soSchedulesData: [],
  soReportsData: [],
  query: {
    sort: 'product_id',
    direction: 'DESC',
    page_size: 25,
    page_index: 1,
    number_of_elements: 0,
    total_elements: 0,
    total_pages: 0,
    search: '',
  },
}

const appSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    reset: () => initialState,
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setSoParamsData: (state, action: PayloadAction<SOParamsDataType[]>) => {
      state.soParamsData = action.payload
    },
    setSoSchedulesData: (state, action: PayloadAction<SOSchedulesDataType[]>) => {
      state.soSchedulesData = action.payload
    },
    setSoReportsData: (state, action: PayloadAction<SOReportsDataType[]>) => {
      state.soReportsData = action.payload
    },
    setQuery: (state, action: PayloadAction<SOAutomationQueryType>) => {
      state.query = action.payload
    },
  },
})

export const {
  reset,
  setIsLoading,
  setSoParamsData,
  setSoSchedulesData,
  setSoReportsData,
  setQuery,
} = appSlice.actions

export default appSlice.reducer
