import { Get, Post } from 'config/apiServiceConfig'

export type GetUnloadingDetailResponseType = {
  data: {
    supply_order_unloading_id: number
    total_supply_order: number
    total_koli: number
    plate_number: string
    unloader_name: string
    items: {
      supply_order_id: number
      supply_order_number: string
      kolis: {
        id: number
        code: string
        scanned: boolean
      }[]
    }[]
  }
}
export type PostPutRDeleteResponseType = {
  data: {
    message: string
  }
  error: {
    status: false
    message: ''
    code: 0
  }
}

export type PostScanKoliOnUnloadingRequestType = {
  supply_order_unloading_id: number
  koli_ids: number[]
}

export type PostSubmitUnloadingRequestType = {
  supply_order_unloading_id: number
  chronology?: string
  evidence_image_urls?: string[]
  device_type: string
}

export type PostUploadImageResponseType = {
  images: {
    fileName: string
    filePath: string
    imageUrl: string
    size: {
      width: number
      height: number
    }
    type: 'small' | 'medium' | 'large' | 'real'
  }[]
}

export const getUnloadingDetail = (soUnloadingId: number) =>
  Get<GetUnloadingDetailResponseType>({
    url: `/api/wms/v1/supply-order/unloading/${soUnloadingId}/items`,
  })

export const postScanKoliOnUnloading = (data: PostScanKoliOnUnloadingRequestType) =>
  Post<PostPutRDeleteResponseType>({ url: `/api/wms/v1/supply-order/unloading/scan`, data })

export const postSubmitUnloading = (data: PostSubmitUnloadingRequestType) =>
  Post<PostPutRDeleteResponseType>({ url: `/api/wms/v1/supply-order/unloading/submit`, data })
