import { Badge, BadgePropsType } from '@astro-ui/components'

const themeList = {
  success: {
    backgroundColor: '#C9FDE0',
    color: '#098A4E',
  },
  danger: {
    backgroundColor: '#FFEAEF',
    color: '#FF5C84',
  },
  warning: {
    backgroundColor: '#FFF1BA',
    color: '#F36819',
  },
  info: {
    backgroundColor: '#C5FCFF',
    color: '#0479B9',
  },
  secondary: {
    backgroundColor: '#E4EBF5',
    color: '#6D7588',
  },
}

export type PillPropsType = BadgePropsType & {
  content: string
  theme?: 'success' | 'danger' | 'warning' | 'info' | 'secondary'
}

const Pill = ({ theme, content, ...rest }: PillPropsType) => (
  <Badge
    {...rest}
    sx={{
      ...(theme && themeList[theme]),
      padding: '5px 10px',
      borderRadius: '5px',
      fontSize: '12px',
      ...rest?.sx,
    }}
  >
    {content}
  </Badge>
)

export default Pill
