import { memo, ReactNode } from 'react'
import { Typography, Box, Icon } from '@astro-ui/components'

interface HeaderPropsType {
  RightComponent?: ReactNode
  title?: string
  withBackButton?: boolean
  handleGoBack?: () => void
}

const Header = ({
  RightComponent,
  title = 'Title',
  withBackButton = false,
  handleGoBack = () => {
    //
  },
}: HeaderPropsType) => (
  <Box display="flex" marginBottom="24px">
    <Box display="flex">
      {withBackButton && (
        <Icon
          onClick={handleGoBack}
          className="pointer"
          style={{ marginTop: 'auto', marginBottom: 'auto', marginRight: '12px' }}
          icon="chevron-left"
        />
      )}
      <Typography variant="h1">{title}</Typography>
    </Box>
    <Box marginLeft="auto">{RightComponent}</Box>
  </Box>
)

export default memo(Header)
