import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HomepageSlottingStateType } from 'features/CategoryAndPosisi/@types'
import { SLICE_NAME, fetchLocationList, fetchSlottingList, fetchGenerateTemplate } from './thunk'

export const initialState: HomepageSlottingStateType = {
  isLoading: false,
  slotting: [],
  query: {
    name: '',
    pageIndex: 1,
    pageSize: 20,
    location: {
      is_new_ui: false,
      is_upcoming_enable: false,
      location_id: 1,
      location_name: '',
      location_type: '',
      name: '',
    },
  },
  locationList: [],
}

const homepageSlotting = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setQuery: (
      state,
      action: PayloadAction<{
        key: keyof HomepageSlottingStateType['query']
        value: HomepageSlottingStateType['query'][keyof HomepageSlottingStateType['query']]
      }>,
    ) => {
      const { key, value } = action.payload

      state.query = {
        ...state.query,
        [key]: value,
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLocationList.fulfilled, (state, action) => {
        state.locationList = action.payload.data.map((el) => ({ ...el, name: el.location_name }))
      })
      .addCase(fetchSlottingList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchSlottingList.fulfilled, (state, action) => {
        const { content } = action.payload
        state.isLoading = false
        state.slotting = content.map((el) => ({ ...el, uniqueKey: window.crypto.randomUUID() }))
      })
      .addCase(fetchSlottingList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchGenerateTemplate.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchGenerateTemplate.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchGenerateTemplate.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const { reset, setQuery } = homepageSlotting.actions
export default homepageSlotting.reducer
