/* eslint-disable @typescript-eslint/no-explicit-any */
import produce, { original, current } from 'immer'

export type PayloadActionType<P> = {
  type: string
  payload: P
}

type CreateReducerType<S> = {
  [key: string]: (
    state: S,
    action: {
      type: string
      payload: any
    },
    options: {
      original: typeof original
      current: typeof current
    },
  ) => void
}

const createReducer = <S>(reducer: CreateReducerType<S>) =>
  produce((draft, { type, payload }) => {
    const callback = reducer[type]
    if (callback) {
      return callback(draft, { type, payload }, { original, current })
    }
    return draft
  })

export default createReducer
