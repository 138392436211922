import axios from "axios";
const { REACT_APP_BASE_URL_API } = process.env;
const baseUrl = REACT_APP_BASE_URL_API;

export const getPPCatParent_SL = (query = "") =>
  axios.get(`${baseUrl}/api/product-position/categories/parent${query}`);

export const getPPCatChild_SL = (category_id, query = "") =>
  axios.get(
    `${baseUrl}/api/product-position/categories/child/${category_id}${query}`
  );

export const getProductPosition = (category_id, query = "") =>
  axios.get(`${baseUrl}/api/product-position/${category_id}${query}`);

export const postAddProductPosition = (data) =>
  axios.post(`${baseUrl}/api/product-position`, data);
