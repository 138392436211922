import { Get, Put } from 'config/apiServiceConfig'
import apiServiceApiGateway from 'config/apiServiceApiGateway'

import {
  GetBuyerListResponseType,
  GetBuyerListRequestType,
  PutStatusBuyerRequestType,
  PutStatusBuyerResponseType,
  GetCustomerListResponseType,
  GetCustomerListRequestType,
} from 'features/Account/@types/buyer'

export type PutResetUserAccountOTPRequestType = { payload: { phone_number: string } }

export type PutResetUserAccountOTPResponseType = {
  data: {
    is_success: boolean
  }
  error: {
    status: boolean
    message: string
    code: number
  }
}

export const getBuyerList = ({ params }: GetBuyerListRequestType) =>
  Get<GetBuyerListResponseType>({
    url: '/api/customer',
    params,
  })

export const putStatusBuyer = ({ params, customerId }: PutStatusBuyerRequestType) =>
  Put<PutStatusBuyerResponseType>({
    url: `/api/customer/${customerId}/active`,
    data: params,
  })

export const getCustomerList = ({ params }: GetCustomerListRequestType) =>
  apiServiceApiGateway.Get<GetCustomerListResponseType>({
    url: `/accounts/internal/v1/customer`,
    params,
  })
