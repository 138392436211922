import { lazy } from 'react'

import generateRouterDetail from 'utils/helpers/generateRouterDetail'

const BannerThematicAddEdit = lazy(() => import('../pages/BannerThematicAddEdit'))
const BannerThematic = lazy(() => import('../pages/BannerThematic'))
const Banner = lazy(() => import('../pages/Banner'))

export const BannerThematicRouter: RouterFeatureInterface<
  'BannerThematicRouter',
  '/dashboard/banner-thematic'
> = {
  name: 'BannerThematicRouter',
  Component: BannerThematic,
  path: '/dashboard/banner-thematic',
  routeType: 'PRIVATE',
  allowedRoles: [
    'AUTH_CS_CAPTAIN',
    'AUTH_CUSTOMER_SERVICE',
    'AUTH_CENTRAL_SUPERVISOR',
    'AUTH_HO_CS_LEADER',
    'AUTH_CS_AGENT',
    'AUTH_MERCHANDISING_HUB',
    'AUTH_MERCHANDISING_WAREHOUSE',
    'AUTH_SUPPLY_CHAIN',
  ],
  isDashboard: true,
}

export const BannerThematicAddEditRouter: RouterFeatureInterface<
  'BannerThematicAddEditRouter',
  '/dashboard/banner-thematic-add-edit/:prefix/:id'
> = {
  name: 'BannerThematicAddEditRouter',
  Component: BannerThematicAddEdit,
  path: '/dashboard/banner-thematic-add-edit/:prefix/:id',
  routeType: 'PRIVATE',
  allowedRoles: [
    'AUTH_HO_QUALITY_ENGINEERING',
    'AUTH_CS_CAPTAIN',
    'AUTH_CUSTOMER_SERVICE',
    'AUTH_CENTRAL_SUPERVISOR',
    'AUTH_HO_CS_LEADER',
    'AUTH_CS_AGENT',
    'AUTH_MERCHANDISING_HUB',
    'AUTH_MERCHANDISING_WAREHOUSE',
    'AUTH_SUPPLY_CHAIN',
  ],
  isDashboard: true,
}

export const BannerRouter: RouterFeatureInterface<'BannerRouter', '/dashboard/banner'> = {
  name: 'BannerRouter',
  Component: Banner,
  path: '/dashboard/banner',
  routeType: 'PRIVATE',
  allowedRoles: [
    'AUTH_CS_CAPTAIN',
    'AUTH_CUSTOMER_SERVICE',
    'AUTH_CENTRAL_SUPERVISOR',
    'AUTH_HO_CS_LEADER',
    'AUTH_CS_AGENT',
  ],
  isDashboard: true,
}

const BannerAndTickerRouter = [BannerThematicAddEditRouter, BannerThematicRouter, BannerRouter]

export const BannerAndTickerRouterDetail = generateRouterDetail(BannerAndTickerRouter)

export default BannerAndTickerRouter
