import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CloseMessageType } from 'features/Location/@types/CloseMessageConfig'
import {
  InitialStateType,
  ScheduleCLoseDialogStateType,
  QueryType,
  ScheduleCloseType,
  ScheduleCloseFormType,
  SetFormPayloadType,
  SetLocationTypePayloadType,
  SetLocationsPayloadType,
  SetSelectedScheduleClosePayloadType,
} from 'features/Location/@types/GlobalScheduledClose'

export const SCHEDULE_CLOSE_SLICE_NAME = 'scheduleClose'

export const initialState: InitialStateType = {
  isLoading: false,
  dialogState: 'CLOSED',
  query: {
    page: 1,
    limit: 20,
    name: '',
    start_date: '',
    end_date: '',
  },
  schedules: [],
  form: {
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    sla: '0',
    locationType: null,
    location: [],
    closeReason: null,
  },
  locationTypes: [],
  locations: [],
  closeReasons: [],
  selectedScheduleClose: null,
}

const location = createSlice({
  name: SCHEDULE_CLOSE_SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    setDialogState: (state, { payload }: PayloadAction<ScheduleCLoseDialogStateType>) => {
      state.dialogState = payload
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setQuery: (state, { payload }: PayloadAction<QueryType>) => {
      state.query = { ...payload }
    },
    setSchedules: (state, { payload }: PayloadAction<ScheduleCloseType[]>) => {
      state.schedules = payload
    },
    setForm: (state, { payload }: PayloadAction<SetFormPayloadType>) => {
      const { field, value } = payload
      state.form[field] = value as never
    },
    resetForm: (state, { payload }: PayloadAction<ScheduleCloseFormType | undefined>) => {
      state.form = { ...(payload || initialState.form) }
    },
    setLocationTypes: (state, { payload }: PayloadAction<SetLocationTypePayloadType>) => {
      state.locationTypes = payload
    },
    setLocations: (state, { payload }: PayloadAction<SetLocationsPayloadType>) => {
      state.locations = payload
    },
    setCloseReason: (state, { payload }: PayloadAction<CloseMessageType[]>) => {
      state.closeReasons = payload
    },
    setSelectedScheduleClose: (
      state,
      { payload }: PayloadAction<SetSelectedScheduleClosePayloadType>,
    ) => {
      state.selectedScheduleClose = payload
    },
  },
})

export default location.reducer
export const {
  resetState,
  setDialogState,
  setQuery,
  setSchedules,
  setIsLoading,
  setForm,
  resetForm,
  setLocationTypes,
  setLocations,
  setCloseReason,
  setSelectedScheduleClose,
} = location.actions
