import styled from "styled-components";
import { useRef, useState, useEffect } from "react";

import { SystemIcon, TextBody, Category } from "components";
import checked_img from "assets/images/check-blue.svg";
import listenClickOutside from "customHooks/outSideClick";
import { filterMethod } from "helpers";

const InputDropDownAllOrder = ({
  style,
  className,
  noIconDrop,
  width,
  iconSearch,
  listOptions,
  handleClickOption,
  grouping,
  label,
  color,
  mandatory,
  value,
  handleSearchOption,
  isManualFilter,
  hasChildsAndCheck,
  handleCheck,
  handleCloseOptions,
  posList,
  handleClickClear,
  hasClearIcon,
  checkedVerify,
  ...rest
}) => {
  const [listFiltered, setListFiltered] = useState([]);
  const [showList, setShowList] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const refOutside = useRef(null);
  useEffect(() => {
    if (value && !showList) {
      setSearchKey(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);
  useEffect(() => {
    if (hasChildsAndCheck) {
      const filteref = filterMethod(listOptions, searchKey, "name");
      setListFiltered(filteref);
    } else {
      setListFiltered(listOptions);
    }
    if (!showList) {
      setSearchKey(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showList, listOptions]);
  listenClickOutside(
    refOutside,
    () => {
      handleCloseOptions();
      setShowList(false);
    },
    showList
  );
  const handleSearch = (e) => {
    const {
      target: { value }
    } = e;
    if (isManualFilter) {
      handleSearchOption(e);
    } else {
      const filteref = filterMethod(listOptions, value, "name");
      setListFiltered(filteref);
    }
    setSearchKey(value);
  };
  const handleClear = () => {
    handleClickClear();
    setSearchKey("");
  };
  return (
    <div style={style} className="input-drop-down" ref={refOutside}>
      {label && (
        <>
          <Label>
            <TextBody className="mb-7" color={color}>
              {label}
            </TextBody>
          </Label>
          <Span>{mandatory}</Span>
        </>
      )}
      <Container
        onClick={() => setShowList(true)}
        className={className}
        active={showList}
        width={width}
      >
        <span style={{ color: "#212121", fontSize: "14px" }}>Atur</span>
        {iconSearch && (
          <IconSearch>
            <SystemIcon iconName="search" colorIcon="grey" fontSize="bigest" />
          </IconSearch>
        )}
        <InputWrapper
        //onClick={() => setShowList(true)}
        >
          <Input
            {...rest}
            autoComplete="nope"
            value={searchKey}
            onChange={handleSearch}
          />
        </InputWrapper>
        {!noIconDrop && (
          <IconWrapper>
            <SystemIcon
              iconName="dropdown"
              colorIcon="grey"
              fontSize="smaller"
            />
          </IconWrapper>
        )}

        {/* dropDown Normal */}
        {showList && !hasChildsAndCheck && (
          <ListContainer posList={posList}>
            {listFiltered.map((el, idx) => {
              return (
                <Option
                  className="dropDown-list"
                  key={`${el.name}-idx-${idx}`}
                  onClick={() => {
                    setSearchKey(el.name);
                    setShowList(false);
                    handleClickOption(el);
                  }}
                >
                  <TextBody
                    size="bigger"
                    weight="lightest"
                    color="#778092"
                    className="dropdown-name mr-5"
                  >
                    {el.name}
                  </TextBody>
                  {el.subName && (
                    <TextBody
                      size="bigger"
                      weight="lightest"
                      color="#778092"
                      className="dropdown-subName"
                    >
                      {el.subName}
                    </TextBody>
                  )}
                  {el.checked && (
                    <img
                      style={{
                        width: "16px",
                        height: "16px",
                        marginLeft: "auto"
                      }}
                      src={checked_img}
                      alt="note"
                    />
                  )}
                </Option>
              );
            })}
          </ListContainer>
        )}

        {/* dropDown subcategory check */}
        {showList && hasChildsAndCheck && (
          <ListContainer posList={posList}>
            {listFiltered.map((el, idx) => {
              return (
                <Category
                  key={`${el.name}-idx-${idx}`}
                  data={el}
                  handleCheck={handleCheck}
                />
              );
            })}
          </ListContainer>
        )}
        {hasClearIcon && !showList && value && (
          <IconClear onClick={handleClear}>
            <SystemIcon
              colorIcon="secondaryText"
              iconName="plus"
              fontSize="smallest"
            />
          </IconClear>
        )}
      </Container>
    </div>
  );
};

InputDropDownAllOrder.defaultProps = {
  size: "normal",
  noIconDrop: false,
  width: "",
  iconSearch: false,
  value: "",
  listOptions: [],
  handleClickOption: () => {},
  grouping: false,
  label: "",
  color: "secondaryText",
  mandatory: "",
  handleSearchOption: () => {},
  isManualFilter: false,
  hasChildsAndCheck: false,
  handleCloseOptions: () => {},
  posList: "down",
  handleClickClear: () => {},
  hasClearIcon: false
};

export default InputDropDownAllOrder;

const IconSearch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;

// const Img = styled.img`
//   width: 50px;
//   height: 25px;
// `;

const Option = styled.div`
  cursor: pointer;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  :hover {
    background-color: ${({ theme: { colors } }) => colors.body};
  }
`;

const ListContainer = styled.div`
  max-height: 200px;
  z-index: 2;
  overflow: auto;
  // width: fit-content;
  border: 1px solid #e4eaf3;
  min-width: 100%;
  border-radius: 6px;
  position: absolute;
  left: auto;
  right: 0;
  /* top: 40px; */
  top: ${({ posList }) => (posList === "down" ? "40px" : "auto")};
  bottom: ${({ posList }) => (posList === "down" ? "auto" : "40px")};
  background-color: ${({ theme: { colors } }) => colors.white};
  box-shadow: 0px 2px 16px 0px rgba(202, 211, 225, 0.4);
  -webkit-box-shadow: 0px 2px 16px 0px rgba(202, 211, 225, 0.4);
  -moz-box-shadow: 0px 2px 16px 0px rgba(202, 211, 225, 0.4);
  p {
    white-space: nowrap;
  }
`;

const IconWrapper = styled.div`
  transform: rotate(0deg);
  display: grid;
  place-items: center;
`;

const Input = styled.input`
  background: none;
  cursor: pointer;
  border: none;
  padding: 10px 0;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  /*color: ${({ theme: { colors } }) => colors.textSoft};*/
  color: transparent;
  width: 100%;
  :focus {
    outline: none;
  }
  ::placeholder {
    opacity: 1;
    color: #aab4c8;
  }
`;

const InputWrapper = styled.div`
  width: 0px;
`;

const Container = styled.div`
  width: ${({ width }) => width || "-webkit-fill-available"};
  border: 1px solid
    ${({ theme: { colors }, active }) => (active ? colors.main : "#E4EAF3")};
  padding: 0px 12px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
  position: relative;
  cursor: pointer;
`;

const Label = styled.div`
  display: inline-block;
`;
const Span = styled.span`
  color: #f08227;
  margin-left: 2px;
  vertical-align: middle;
`;

const IconClear = styled.div`
  border: 1px solid ${({ theme: { colors } }) => colors.secondaryText};
  position: absolute;
  cursor: pointer;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  right: 33px;
  top: 12px;
  transform: rotate(45deg);
  i {
    position: absolute;
    right: 1px;
    top: -4.7px;
  }
`;
