import { getInventoryStatus } from 'utils/api'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  setErrorMessageProduct,
  setIsHeaderCheckboxIndeterminate,
  setListData,
  setIsHeaderChecked,
  setSelectedProductGroupIds,
  setSelectedProductItemIds,
  setHeaderCheckboxProductListState,
  saveDataToTempVariable,
  setBadStatusNotesList,
  SoAddProductDirectInterface,
} from './suppyOrderAddProductDirectSlice'

type ProductListOrItemType = 'productList' | 'productItemList'

type ParamValidateQtyProductType = {
  index: number
  type: ProductListOrItemType | 'selectedItemList'
  isChildItems: boolean
}

export const validateQtyProduct =
  (params: ParamValidateQtyProductType) =>
  (dispatch: StoreDispatchType, getState: () => StoreStateType) => {
    const { supplyOrderAddProductDirect } = getState()
    const { index, type } = params

    const {
      modalProduct: { indexProductList },
    } = supplyOrderAddProductDirect

    let product
    if (type === 'selectedItemList') {
      product = supplyOrderAddProductDirect.productList[indexProductList].selectedItemList[index]
    } else product = supplyOrderAddProductDirect[type][index]

    const { qty, productStock, isChecked } = product

    if (isChecked && (!qty || +qty === 0)) {
      dispatch(
        setErrorMessageProduct({
          indexItem: index,
          errorMessage: 'Qty harus diisi atau tidak boleh 0',
          type,
        }),
      )
      return
    }

    if (isChecked && qty > productStock) {
      dispatch(
        setErrorMessageProduct({
          indexItem: index,
          errorMessage: 'Qty tidak boleh melebihi produk stok',
          type,
        }),
      )
      return
    }

    dispatch(
      setErrorMessageProduct({
        indexItem: index,
        errorMessage: '',
        type,
      }),
    )
  }

const getIfHeaderIsIndeterminate = (products: Record<string, unknown>[]) => {
  const isHeaderChecked = products.every((itemData) => itemData.isChecked)
  const atLeastOneItemIsChecked = products.some((itemData) => itemData.isChecked)
  return !isHeaderChecked && atLeastOneItemIsChecked
}

export const defineIsHeaderCheckboxIndeterminate =
  (params: { type: ProductListOrItemType }) =>
  (dispatch: StoreDispatchType, getState: () => StoreStateType) => {
    const { supplyOrderAddProductDirect } = getState()
    const { type } = params

    const products = supplyOrderAddProductDirect[type] as unknown as Record<string, unknown>[]

    const isIndeterminate = getIfHeaderIsIndeterminate(products)
    dispatch(setIsHeaderCheckboxIndeterminate({ type, isIndeterminate }))
  }

export const defineIsHeaderCheckboxChecked =
  (params: { type: 'productItemList' }) =>
  (dispatch: StoreDispatchType, getState: () => StoreStateType) => {
    const { supplyOrderAddProductDirect } = getState()
    const { productItemList } = supplyOrderAddProductDirect
    const { type } = params

    let isChecked = false
    if (productItemList.length) isChecked = productItemList.every((item) => item.isChecked)

    dispatch(
      setIsHeaderChecked({
        type,
        isChecked,
      }),
    )
  }

export const toggleHeaderCheckbox =
  (params: { type: 'productItemList' }) =>
  (dispatch: StoreDispatchType, getState: () => StoreStateType) => {
    const {
      supplyOrderAddProductDirect: { productItemList, isHeaderChecked },
    } = getState()
    const { type } = params

    const currentStateHeaderCb = isHeaderChecked[type]

    const listData = productItemList.map((item) => ({
      ...item,
      isChecked: !currentStateHeaderCb,
    }))

    dispatch(setListData({ type, listData }))
    dispatch(setIsHeaderChecked({ type, isChecked: !currentStateHeaderCb }))
    listData.forEach((item, index) => {
      dispatch(
        validateQtyProduct({
          index,
          type,
          isChildItems: true,
        }),
      )
      dispatch(saveDataToTempVariable({ indexItem: index, type }))
      dispatch(updateSelectedProductIds({ type, index, uniqueId: item.productUniqueKey }))
    })
  }

export const toggleHeaderCheckboxProductList =
  () => (dispatch: StoreDispatchType, getState: () => StoreStateType) => {
    const {
      supplyOrderAddProductDirect: { productList, headerCheckboxProductListState },
    } = getState()

    const isChecked = headerCheckboxProductListState
    const listData = productList.map((item) => ({
      ...item,
      isChecked: !item.isInputDisabled ? !isChecked : false,
    }))

    dispatch(setListData({ type: 'productList', listData }))
    dispatch(setHeaderCheckboxProductListState(!isChecked))

    listData.forEach((item, index) => {
      const { productInventoryStatusId, productId, productInventoryStatusNoteId } = item
      const uniqueId =
        +productInventoryStatusId === 1
          ? `${productInventoryStatusId}-${productId}`
          : `${productInventoryStatusId}-${productId}-${productInventoryStatusNoteId}`

      dispatch(
        validateQtyProduct({
          index,
          type: 'productList',
          isChildItems: false,
        }),
      )
      dispatch(saveDataToTempVariable({ indexItem: index, type: 'productList' }))
      dispatch(updateSelectedProductIds({ type: 'productList', index, uniqueId }))
    })
  }

export const updateSelectedProductIds =
  (params: { type: 'productItemList' | 'productList'; index: number; uniqueId: string }) =>
  (dispatch: StoreDispatchType, getState: () => StoreStateType) => {
    const { type, index, uniqueId } = params
    const { supplyOrderAddProductDirect } = getState()
    const { selectedProductGroupIds, selectedProductItemIds } = supplyOrderAddProductDirect

    let newIds = []
    if (type === 'productList') newIds = [...selectedProductGroupIds]
    else newIds = [...selectedProductItemIds]
    const { isChecked } = supplyOrderAddProductDirect[type][index]

    if (isChecked) newIds.push(uniqueId)
    else {
      const idxToRemove = selectedProductGroupIds.findIndex((id) => id === uniqueId)
      newIds.splice(idxToRemove, 1)
    }

    if (type === 'productList') {
      dispatch(setSelectedProductGroupIds(newIds))
      return
    }
    dispatch(setSelectedProductItemIds(newIds))
  }

export const actGetInventoryStatusNote =
  (statusId: number) => async (dispatch: StoreDispatchType) => {
    try {
      const {
        data: { data },
      } = await getInventoryStatus()

      const badStatusNotes = data.notes.filter(
        (item: SoAddProductDirectInterface['badStatusNotesList'][number]) =>
          item.statusId === statusId,
      )
      dispatch(setBadStatusNotesList(badStatusNotes))
    } catch (err) {
      callErrorMsg(err)
    }
  }
