import { Post, Put, Get } from 'config/apiServiceConfig'

import type { DriverType } from 'redux/loadingGoods'

export interface PostNewGroupSOPayloadInterface {
  supplyOrderIds: number[]
}

export type GetLoaderPayloadInterface = {
  locationId: number
  search?: string
}

export type GetLoaderResponseInterface = {
  data: {
    id: number
    fullName: string
  }[]
}

export interface DriverItemSOInterface {
  id: number
  name: string
  plateNumber: string
  type: string
  vehicle: {
    code: string
    name: string
  }
}

export type GetDriverResponseInterface = {
  data: DriverItemSOInterface[]
}

export type Get3PLResponseInterface = {
  data: {
    id: number
    name: string
  }[]
}

export type GetLoadingDetailResponseType = {
  data: {
    groupId: string
    driver: {
      id: number
      name: string
      plateNumber: string
      type: string
      vehicle: {
        code: string
        name: string
      }
    }
    driverProvider: {
      name: string
      id: number
    } | null
    loadingId: number
    freshInitialTemperature: number
    freshFinalTemperature: number | null
    loaders: {
      fullName: string
      id: number
    }[]
  }
}

export type GetDetailLoadingItemsInterface = {
  data: {
    supplyOrderId: number
    supplyOrderNumber: string
    totalScanned: number
    kolis: {
      alreadyScanned: boolean
      destination: {
        locationId: number
        locationName: string
        locationType: string
      }
      koli: {
        code: string
        createdDate: string
        id: string
      }
    }[]
  }[]
}

export type PostLoadingPayloadType = {
  groupId: string
  driverType: DriverType
  loaderIds: number[]
  driverId?: number
  driverProviderId: number | null
  driverName?: string
  plateNumber?: string
  vehicleType?: string
  startFreshTemperature?: number
}

export type PostScanKoliPayloadType = {
  supplyOrderId: number
  koliNumber: string
  loadingId: string
  forceScan?: boolean
  soIndex?: number
  koliIndex?: number
}

export type PostScanKoliResponseType = {
  data: {
    valid: boolean
    skippedSupplyOrders?: {
      id: number
      invoiceNumber: string
      totalSkippedKoli: 2
    }[]
  }
  message: string
}

export type GetConfigurationResponseType = {
  data: {
    cancelLoadingReasons: {
      code: string
      name: string
    }[]
    needToSetTemperatureLocationIds: number[]
    supportedVehicleType: {
      key: string
      value: string
    }[]
  }
}

export type PostCancelLoadingRequestType = {
  loadingId: number
  supplyOrderId: number
  supplyOrderNumber?: string
  kolis: {
    koliNumber: string
    unloadingReason: string
  }[]
}

export type PutUpdateGroupOnDeliveryType = {
  loadingID: number
  freshFinalTemperature: number
  driverName?: string
  plateNumber?: string
}

export const postNewGroupSO = (payload: PostNewGroupSOPayloadInterface) =>
  Post({ url: '/api/wms/v1/supply-order/loading/grouping-so', data: payload })

export const putCancelGroupSO = (payload: string) =>
  Put<{ message: string }>({ url: `/api/wms/v1/supply-order/loading/${payload}/cancel` })

export const getLoader = (params: GetLoaderPayloadInterface) =>
  Get<GetLoaderResponseInterface>({ url: '/api/wms/v1/supply-order/loading/loaders', params })

export const getDriver = (params: GetLoaderPayloadInterface) =>
  Get<GetDriverResponseInterface>({
    url: '/api/wms/v1/supply-order/loading/drivers',
    params,
  })

export const get3PL = () =>
  Get<Get3PLResponseInterface>({
    url: '/api/wms/v1/supply-order/loading/3pl-providers',
  })

export const postLoading = (data: PostLoadingPayloadType) =>
  Post({ url: '/api/wms/v1/supply-order/loading', data })

export const getDetailLoading = (payload: string) =>
  Get<GetLoadingDetailResponseType>({
    url: `/api/wms/v1/supply-order/loading/${payload}`,
  })

export const getDetailLoadingItems = (payload: string) =>
  Get<GetDetailLoadingItemsInterface>({
    url: `/api/wms/v1/supply-order/loading/${payload}/items`,
  })

export const postScanKoli = ({ loadingId, ...data }: PostScanKoliPayloadType) =>
  Post<PostScanKoliResponseType>({
    url: `/api/wms/v1/supply-order/loading/${loadingId}/scan`,
    data,
  })

export const getConfiguration = () =>
  Get<GetConfigurationResponseType>({
    url: `/api/wms/v1/supply-order/loading/configurations`,
  })

export const postCancelLoading = ({
  loadingId,
  supplyOrderId,
  ...data
}: PostCancelLoadingRequestType) =>
  Post({
    url: `/api/wms/v1/supply-order/loading/${loadingId}/${supplyOrderId}/cancel`,
    data,
  })

export const postSubmitLoading = (payload: number) =>
  Post({
    url: `/api/wms/v1/supply-order/loading/submit/${payload}`,
  })

export const putUpdateGroupOnDelivery = (data: PutUpdateGroupOnDeliveryType) =>
  Put({ url: `/api/wms/v2/supply-order/update-on-delivery`, data })

export const getPrintSuratJalan = (loadingId: number) =>
  Get({
    url: `/api/wms/v2/supply-order/print-delivery-document/${loadingId}`,
  })
