import { useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { Category, Loader, SystemIcon, TextBody } from 'components'
import { Button } from 'components/atoms'
import listenClickOutside from 'customHooks/outSideClick'
import { filterMethod } from 'helpers'

const InputDropDown = ({
  className,
  noIconDrop,
  width,
  iconSearch,
  listOptions,
  handleClickOption,
  grouping,
  label,
  color,
  mandatory,
  value,
  handleSearchOption,
  isManualFilter,
  hasChildsAndCheck,
  handleCheck,
  handleCloseOptions,
  posList,
  handleClickClear,
  hasClearIcon,
  noSetOption,
  initialKey,
  isNoSearchInit,
  isLoading,
  handleNextPage,
  iconName,
  selected,
  disabledDropDown,
  iconDrop,
  name,
  multiSelect,
  isFirstClick,
  handleFirsClick,
  imadiate,
  radio,
  containerClick,
  displayKey,
  size,
  error,
  errorText,
  valueObject,
  showScrollbar,
  isDisplayNameOnPlaceholder,
  isTruncate,
  maxCount,
  onFocus,
  styleListContainer,
  prefix,
  hideParentCheck = false,
  ...rest
}) => {
  const [listFiltered, setListFiltered] = useState([])
  const [showList, setShowList] = useState(false)
  const [searchKey, setSearchKey] = useState('')
  const refOutside = useRef(null)
  const searchKeyAsArray = useMemo(() => {
    if (!searchKey) return []
    return String(searchKey)?.split(',')
  }, [searchKey])

  useEffect(() => {
    if (!showList || imadiate) {
      setSearchKey(valueObject && value ? value[displayKey] || '' : value)
    }
  }, [value])
  useEffect(() => {
    if (!showList) {
      setSearchKey(valueObject && value ? value[displayKey] || '' : value)
    } else if (showList && isNoSearchInit) {
      setSearchKey('')
      setListFiltered(listOptions)
    }
  }, [showList])
  useEffect(() => {
    if (hasChildsAndCheck && !isManualFilter) {
      const filteref = filterMethod(listOptions, searchKey, displayKey || 'name')
      setListFiltered(filteref)
    } else {
      setListFiltered(listOptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOptions])
  listenClickOutside(
    refOutside,
    () => {
      handleCloseOptions()
      setShowList(false)
    },
    showList,
  )
  const handleSearch = (e) => {
    const {
      target: { value },
    } = e
    if (isManualFilter) {
      handleSearchOption(e)
    } else {
      const filteref = filterMethod(listOptions, value, displayKey || 'name')
      setListFiltered(filteref)
    }
    setSearchKey(value)
  }
  const handleClear = () => {
    handleClickClear()
    setSearchKey('')
    setListFiltered(listOptions)
  }
  const handlePagination = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget
    if (scrollHeight - scrollTop === clientHeight) {
      handleNextPage()
    }
  }

  return (
    <div style={{ width }} className="input-drop-down" ref={refOutside}>
      {label && (
        <>
          <Label className="label-input">
            <TextBody size={size} className="mb-7" color={color}>
              {label}
            </TextBody>
          </Label>
          <Span>{mandatory}</Span>
        </>
      )}
      <Container
        className={className}
        error={error}
        active={showList}
        width={width}
        disabledDropDown={disabledDropDown}
        onClick={() => {
          if (containerClick) {
            containerClick()
          }
          containerClick && containerClick()
        }}
      >
        {iconSearch && (
          <IconSearch className="mt-2">
            <SystemIcon iconName="search" colorIcon="grey" fontSize="bigger" />
          </IconSearch>
        )}
        {iconName && (
          <IconSearch>
            <SystemIcon iconName={iconName} colorIcon="grey" fontSize="bigest" />
          </IconSearch>
        )}
        {prefix && <PrefixWrapper>{prefix}</PrefixWrapper>}
        <InputWrapper onClick={() => setShowList(true)} isTruncate={isTruncate}>
          <TagWrapper isTruncate={isTruncate}>
            {multiSelect &&
              searchKeyAsArray &&
              searchKeyAsArray.slice(0, maxCount).map((value, index) => {
                if (isDisplayNameOnPlaceholder) {
                  const selected = listOptions.find((i) => i.key === value)
                  return (
                    <Tag isTruncate={isTruncate} key={index}>
                      {selected?.name}
                    </Tag>
                  )
                }
                return (
                  <Tag isTruncate={isTruncate} key={index}>
                    {value}
                  </Tag>
                )
              })}
            {multiSelect && searchKeyAsArray.length > maxCount && (
              <Tag isTruncate={isTruncate}>
                +{searchKeyAsArray.length - maxCount} {!isTruncate && 'lainnya'}
              </Tag>
            )}
          </TagWrapper>
          <Input
            {...rest}
            value={multiSelect ? '' : searchKey}
            autoComplete="nope"
            onChange={disabledDropDown ? () => {} : handleSearch}
            disabledDropDown={disabledDropDown}
            onFocus={onFocus}
            onClick={() => {
              isFirstClick && handleFirsClick()
            }}
          />
        </InputWrapper>
        {!noIconDrop && (
          <IconWrapper>
            <Button variant="wrapper" onClick={() => setShowList(!showList)}>
              <SystemIcon iconName={iconDrop} colorIcon="grey" fontSize="smaller" />
            </Button>
          </IconWrapper>
        )}
        {/* dropDown Normal */}
        {showList && !hasChildsAndCheck && !disabledDropDown && (
          <ListContainer
            onScroll={handlePagination}
            posList={posList}
            className="list-container"
            showScrollbar={showScrollbar}
            {...(styleListContainer && { style: styleListContainer })}
          >
            {listFiltered.map((el, idx) => {
              const renderedName = el[displayKey] || el.name
              return (
                <Option
                  key={`${renderedName}-idx-${idx}-${initialKey}`}
                  onClick={() => {
                    if (!noSetOption) {
                      setSearchKey(renderedName)
                    }
                    setShowList(false)
                    handleClickOption(el, displayKey || name)
                  }}
                >
                  <TextBody size="bigger" weight="lightest" className="dropdown-name">
                    {renderedName}
                  </TextBody>
                  {selected === renderedName && (
                    <SystemIcon iconName="checklist" colorIcon="main" fontSize="smallest" />
                  )}
                </Option>
              )
            })}
            {listFiltered.length === 0 && !isLoading && (
              <WrapperNoData>
                <TextBody color="secondaryText" weight="light">
                  Data tidak ditemukan
                </TextBody>
              </WrapperNoData>
            )}

            <Sparator>{isLoading && <Loader />}</Sparator>
          </ListContainer>
        )}
        {/* dropDown subcategory check */}
        {showList && hasChildsAndCheck && !disabledDropDown && (
          <ListContainer posList={posList} showScrollbar={showScrollbar}>
            {listFiltered.map((el, idx) => {
              return (
                <Category
                  key={`${el.name}-idx-${idx}`}
                  data={el}
                  handleCheck={handleCheck}
                  radio={radio}
                  hideParentCheck={hideParentCheck}
                />
              )
            })}
            <Sparator />
          </ListContainer>
        )}
        {hasClearIcon && !showList && value && (
          <IconClear onClick={handleClear} noIconDrop={noIconDrop}>
            <SystemIcon colorIcon="secondaryText" iconName="plus" fontSize="smallest" />
          </IconClear>
        )}
      </Container>
      {error && (
        <TextBody
          weight="light"
          style={{
            marginTop: '4px',
            color: '#E25450',
          }}
          size="smaller"
        >
          {errorText}
        </TextBody>
      )}
    </div>
  )
}

InputDropDown.defaultProps = {
  size: 'normal',
  noIconDrop: false,
  width: '',
  iconSearch: false,
  value: '',
  listOptions: [],
  handleClickOption: () => {},
  grouping: false,
  label: '',
  color: 'secondaryText',
  mandatory: '',
  handleSearchOption: () => {},
  isManualFilter: false,
  hasChildsAndCheck: false,
  handleCloseOptions: () => {},
  posList: 'down',
  handleClickClear: () => {},
  hasClearIcon: false,
  noSetOption: false,
  isNoSearchInit: false,
  isLoading: false,
  handleNextPage: () => {},
  iconName: '',
  iconDrop: 'dropdown',
  multiSelect: false,
  isFirstClick: false,
  handleFirsClick: () => {},
  imadiate: false,
  radio: false,
  valueObject: false,
  showScrollbar: false,
  isDisplayNameOnPlaceholder: false,
  isTruncate: false,
  maxCount: 6,
  onFocus: () => {},
  prefix: '',
}

export default InputDropDown

const IconSearch = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`

// const Img = styled.img`
//   width: 50px;
//   height: 25px;
// `;

const Option = styled.div`
  cursor: pointer;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  :hover {
    background-color: ${({ theme: { colors } }) => colors.body};
  }
`

const ListContainer = styled.div`
  max-height: 200px;
  z-index: 2;
  overflow: scroll;
  width: fit-content;
  border: 1px solid #e4eaf3;
  min-width: 100%;
  border-radius: 6px;
  position: absolute;
  left: 0;
  /* top: 40px; */
  top: ${({ posList }) => (posList === 'down' ? '40px' : 'auto')};
  bottom: ${({ posList }) => (posList === 'down' ? 'auto' : '40px')};
  background-color: ${({ theme: { colors } }) => colors.white};
  box-shadow: 0px 2px 16px 0px rgba(202, 211, 225, 0.4);
  -webkit-box-shadow: 0px 2px 16px 0px rgba(202, 211, 225, 0.4);
  -moz-box-shadow: 0px 2px 16px 0px rgba(202, 211, 225, 0.4);
  ::-webkit-scrollbar {
    width: ${({ showScrollbar }) => (showScrollbar ? '5px' : '1px')};
    height: 4px;
    border-radius: 5px;
  }
  p {
    white-space: nowrap;
  }
`

const IconWrapper = styled.div`
  transform: rotate(0deg);
  display: grid;
  place-items: center;
`

const Input = styled.input`
  background: none;
  cursor: pointer;
  border: none;
  padding: 10px 0;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  color: ${({ theme: { colors } }) => colors.textSoft};
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
  width: 100%;
  max-width: 100%;
  ${({ disabledDropDown }) =>
    disabledDropDown &&
    css`
      color: ${({ theme: { colors } }) => colors.grey};
    `}
  :focus {
    outline: none;
  }
  ::placeholder {
    opacity: 1;
    color: #aab4c8;
  }
`

const InputWrapper = styled.div`
  width: 100%;
  ${({ isTruncate }) =>
    isTruncate &&
    css`
      position: relative;
      padding-right: 12px;
      cursor: pointer;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`

const Container = styled.div`
  width: ${({ width }) => width || '-webkit-fill-available'};
  border: 1px solid ${({ theme: { colors }, active }) => (active ? colors.main : '#E4EAF3')};
  padding: 0px 12px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
  position: relative;
  ${({ disabledDropDown }) =>
    disabledDropDown &&
    css`
      background-color: #fafafa;
    `}
  ${({ error }) =>
    error &&
    css`
      border: 1px solid #e25450;
    `}
`

const Label = styled.div`
  display: inline-block;
`
const Span = styled.span`
  color: #f08227;
  margin-left: 5px;
`

const IconClear = styled.div`
  border: 1px solid ${({ theme: { colors } }) => colors.secondaryText};
  position: absolute;
  cursor: pointer;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  right: ${({ noIconDrop }) => (noIconDrop ? '15px' : '33px')};
  top: 14px;
  transform: rotate(45deg);
  z-index: 1;
  i {
    position: absolute;
    right: 1px;
    top: -4.7px;
  }
`

const Sparator = styled.div`
  width: 100%;
  min-height: 15px;
  display: grid;
  place-items: center;
`

const WrapperNoData = styled.div`
  padding: 10px;
  display: grid;
  place-items: center;
`

const TagWrapper = styled.div`
  display: flex;
  //gap: 5px;
  flex-wrap: wrap;
  ${({ isTruncate }) =>
    isTruncate &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-wrap: nowrap;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    `}
`

const Tag = styled.div`
  padding: 2px 8px;
  color: #212121;
  background-color: #f3f6fa;
  border-radius: 3px;
  font-size: 12px;
  ${({ isTruncate }) =>
    isTruncate &&
    css`
      background-color: white;
      padding-left: 8px;
      padding-right: 0;
      position: relative;
      :first-of-type {
        padding-left: 0;
      }
      :first-of-type::before {
        content: '';
      }
      :before {
        content: ',';
        position: absolute;
        left: 0;
        padding-right: 4px;
      }
    `}
`

const PrefixWrapper = styled.div`
  padding: 10px 12px 10px 0;
  background-color: ${({ theme: { colors }, bgIcon }) => colors[bgIcon]};
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.light};
  font-family: 'Noto Sans JP', sans-serif !important;
  white-space: nowrap;
`
