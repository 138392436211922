import {
  SET_IS_START_BUYER,
  SET_IS_FAILED_BUYER,
  SET_IS_SUCCESS_BUYER,
  SET_IS_NEED_TO_RELOAD_BUYER,
  SET_PAGINATION_DATA_BUYER,
  SET_IS_SUCCESS_BUYERPOINTS_BUYER,
  SET_PAGINATION_DATA_BUYERPOINTS_BUYER,
  SET_DETAILS_ORDER_DATA_BUYERPOINTS_BUYER,
  SET_IS_SUCCESS_ASTROSALDOHISTORY_BUYER,
  SET_IS_SHOW_MODAL_INJECT_SALDO_BUYER,
  SET_FORM_MODAL_INJECT_SALDO_BUYER,
  SET_IS_SHOW_MODAL_CONFIRMATION_INJECT_SALDO_BUYER,
  RESET_FORM_MODAL_INJECT_SALDO_BUYER,
} from 'storeContext/actionsType'

export const initialStateBuyer = {
  isLoading: false,
  buyerData: [],
  paginationData: {},
  buyerSaldoAstroHistoryData: [],
  buyerPointsData: [],
  buyerPointsPaginationData: {},
  buyerPointsDetailsData: {},
  buyerPointsStatusDetailsData: false,
  needToReload: 1,
  isShowModalInjectSaldo: false,
  formModalInjectSaldo: {
    nominal: 0,
    orderId: '',
    action: { name: '', value: '' },
    notes: '',
  },
  isShowModalConfirmationInjectSaldo: false,
}

export default function buyer(state = initialStateBuyer, { type, payload }) {
  switch (type) {
    case SET_IS_START_BUYER:
      return {
        ...state,
        isLoading: true,
      }
    case SET_IS_FAILED_BUYER:
      return {
        ...state,
        isLoading: false,
      }
    case SET_IS_SUCCESS_BUYER:
      return {
        ...state,
        isLoading: false,
        buyerData: payload.content,
      }
    case SET_IS_NEED_TO_RELOAD_BUYER:
      return {
        ...state,
        needToReload: state.needToReload + 1,
      }
    case SET_PAGINATION_DATA_BUYER:
      return {
        ...state,
        paginationData: payload,
      }
    case SET_IS_SUCCESS_BUYERPOINTS_BUYER:
      return {
        ...state,
        isLoading: false,
        buyerPointsData: payload.content,
      }
    case SET_IS_SUCCESS_ASTROSALDOHISTORY_BUYER:
      return {
        ...state,
        isLoading: false,
        buyerSaldoAstroHistoryData: payload,
      }
    case SET_PAGINATION_DATA_BUYERPOINTS_BUYER:
      return {
        ...state,
        buyerPointsPaginationData: payload,
      }
    case SET_DETAILS_ORDER_DATA_BUYERPOINTS_BUYER:
      return {
        ...state,
        buyerPointsDetailsData: payload,
        buyerPointsStatusDetailsData: true,
      }
    case SET_IS_SHOW_MODAL_INJECT_SALDO_BUYER:
      return {
        ...state,
        isShowModalInjectSaldo: payload,
      }
    case SET_FORM_MODAL_INJECT_SALDO_BUYER:
      return {
        ...state,
        formModalInjectSaldo: {
          ...state.formModalInjectSaldo,
          [payload.name]: payload.value,
        },
      }
    case SET_IS_SHOW_MODAL_CONFIRMATION_INJECT_SALDO_BUYER:
      return {
        ...state,
        isShowModalConfirmationInjectSaldo: payload,
      }

    case RESET_FORM_MODAL_INJECT_SALDO_BUYER:
      return {
        ...state,
        formModalInjectSaldo: initialStateBuyer.formModalInjectSaldo,
      }

    default:
      return state
  }
}
