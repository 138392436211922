import URLPattern from 'url-pattern'

export type URLPatternExtractorType = {
  pattern: string
  url: string
  segmentKey: string
}

export const URLPatternExtractorSingleSegment = ({
  pattern,
  segmentKey,
  url,
}: URLPatternExtractorType) => {
  const testPattern = new URLPattern(pattern)
  const value = testPattern.match(url) as Record<string, string>
  return value ? value[segmentKey] : null
}
