import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getServiceLevelList,
  getServiceLevelDetail,
  postServiceLevel,
  putServiceLevel,
  deleteServiceLevel,
} from 'features/Location/services/serviceLevel'
import { callErrorMsg } from 'helpers/errorMsg'
import { setIsLoading, SLICE_NAME, setSLA, setPopupFormSLA } from './slice'

export const fetchServiceLevelList = createAsyncThunk(
  `${SLICE_NAME}/fetchServiceLevelList`,
  async (_, { dispatch, getState, rejectWithValue }) => {
    const {
      serviceLevel: { query },
    } = getState() as StoreStateType

    dispatch(setIsLoading(true))
    try {
      const {
        data: { data },
      } = await getServiceLevelList({
        params: { limit: query.limit, page: query.page, name: query.name },
      })

      dispatch(setSLA(data.sla))
      dispatch(setIsLoading(false))

      return data
    } catch (error) {
      callErrorMsg(error)
      dispatch(setIsLoading(false))

      return rejectWithValue(error)
    }
  },
)

export const fetchServiceLevelDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchServiceLevelDetail`,
  async ({ id }: { id: number }, { dispatch, rejectWithValue }) => {
    dispatch(setIsLoading(true))
    try {
      const {
        data: { data },
      } = await getServiceLevelDetail({ id: id.toString() })

      dispatch(setPopupFormSLA(data.sla))
      dispatch(setIsLoading(false))
      return data
    } catch (error) {
      callErrorMsg(error)
      dispatch(setIsLoading(false))

      return rejectWithValue(error)
    }
  },
)

export const createServiceLevel = createAsyncThunk(
  `${SLICE_NAME}/createServiceLevel`,
  async (_, { dispatch, rejectWithValue, getState }) => {
    const {
      serviceLevel: {
        popup: { formSLA },
      },
    } = getState() as StoreStateType

    dispatch(setIsLoading(true))

    try {
      const {
        data: { data },
      } = await postServiceLevel({
        payload: {
          active: true,
          available: true,
          display_name: formSLA.display_name,
          maximum_duration: formSLA.maximum_duration,
          minimum_duration: 0,
          name: formSLA.name,
          total_duration: formSLA.total_duration,
        },
      })

      dispatch(setIsLoading(false))
      return data
    } catch (error) {
      callErrorMsg(error)
      dispatch(setIsLoading(false))

      return rejectWithValue(error)
    }
  },
)

export const updateServiceLevel = createAsyncThunk(
  `${SLICE_NAME}/updateServiceLevel`,
  async (_, { dispatch, rejectWithValue, getState }) => {
    const {
      serviceLevel: {
        popup: { formSLA },
      },
    } = getState() as StoreStateType

    dispatch(setIsLoading(true))

    try {
      const {
        data: { data },
      } = await putServiceLevel({
        payload: {
          active: formSLA.active,
          available: formSLA.available,
          display_name: formSLA.display_name,
          maximum_duration: formSLA.maximum_duration,
          minimum_duration: formSLA.minimum_duration,
          name: formSLA.name,
          total_duration: formSLA.total_duration,
        },
        id: formSLA.id.toString(),
      })

      dispatch(setIsLoading(false))
      return data
    } catch (error) {
      callErrorMsg(error)
      dispatch(setIsLoading(false))

      return rejectWithValue(error)
    }
  },
)

export const removeServiceLevel = createAsyncThunk(
  `${SLICE_NAME}/removeServiceLevel`,
  async ({ id }: { id: number }, { dispatch, rejectWithValue }) => {
    dispatch(setIsLoading(true))

    try {
      const {
        data: { data },
      } = await deleteServiceLevel({
        id: id.toString(),
      })

      dispatch(setIsLoading(false))
      return data
    } catch (error) {
      callErrorMsg(error)
      dispatch(setIsLoading(false))

      return rejectWithValue(error)
    }
  },
)
