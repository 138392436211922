import axios from 'axios'

const { REACT_APP_BASE_URL_API } = process.env
const baseUrl = REACT_APP_BASE_URL_API

export type FreshPurchaseRequestDetailQueryType = {
  pageIndex: number
  pageSize: number
}

export type PrStatusType = 'CREATED' | 'PRINTED' | 'CONFIRMED' | 'FAILED' | 'CANCELLED'
export type PrTypeType = 'REPACK' | 'NON_REPACK'

export type ProductDetailType = {
  detailId: number
  product: {
    productId: number
    productSkuNo: string
    productName: string
    uom: string
    weight: number
  }
  pendingFpoQty: number
  price: number
  neededQty: number
  requiredQty: number
}

type PaginationType = {
  sort: string
  direction: string
  pageSize: number
  numberOfElements: number
  pageIndex: number
  totalElements: number
}

export interface GetFPRWithoutDetailInterface {
  data: {
    id: number
    purchaseRequestNo: string
    location: {
      locationId: number
      locationName: string
      locationType: 'WAREHOUSE'
    }
    vendor: {
      vendorId: number
      vendorName: string
    }
    sourcingPic: {
      picId: number
      picName: string
      picEmail: string
    }
    createdDate: number
    expiryDate: number
    status: PrStatusType
    type: PrTypeType
  }
}
export interface GetFreshPurchaseRequestDetailInterface {
  data: ProductDetailType[]
  pagination: PaginationType | null
}

export type FreshPurchaseRequestIdType = string | number

export type FreshPurchaseRequestApiParamType = Partial<FreshPurchaseRequestDetailQueryType>

const endpoint = `${baseUrl}/api/purchase-requests/fresh`

export const getFreshPurchaseRequestDetail = (
  id: FreshPurchaseRequestIdType,
  params: FreshPurchaseRequestApiParamType,
) => axios.get<GetFreshPurchaseRequestDetailInterface>(`${endpoint}/${id}/details`, { params })

export const getFreshPurchaseRequestWithoutDetail = (id: FreshPurchaseRequestIdType) =>
  axios.get<GetFPRWithoutDetailInterface>(`${endpoint}/${id}`)
