import { Get } from 'config/apiServiceConfig'
import {
  GetPromoUserSegmentCSVDownloadResponseType,
  GetPromoUserSegmentParamsType,
  GetPromoUserSegmentResponseType,
} from 'features/CampaignAndDiscount/@types/campaignSegmentList'

export const getPromoUserSegment = (params: GetPromoUserSegmentParamsType) =>
  Get<GetPromoUserSegmentResponseType>({ url: '/api/promo/user-segment', params })

export const getPromoUserSegmentCSVDownload = (segmentId: number) =>
  Get<GetPromoUserSegmentCSVDownloadResponseType>({
    url: '/api/promo/user-segment/:segmentId/download',
    urlParams: { segmentId },
  })
