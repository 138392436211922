import { memo, useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { Checkbox, TextBody, Toggle, ButtonDropDown } from 'components'
import { formatMoneyRMG } from 'helpers'
import store from 'storeContext'
import { useHistory } from 'react-router-dom'
import { campaignAdd } from 'config/routes'
import { updateStatusActive_CAMPAIGN } from 'storeContext/actions/campaign/campaign'
import './style.css'

function RowCampaign({ data, dIndex, actionList, stateCollections }) {
  const { state: stateCanpaign, dispatch: dispatchCampaign } = useContext(store.CampaignContext)

  const { listOfSelectedCampaign } = stateCollections.getter
  const { setListOfSelectedCampaign } = stateCollections.setter

  const history = useHistory()

  //INITIAL STATE
  const initStatusValue = false

  //STATE HOOKS
  const [checkedValue, setCheckedValue] = useState(false)
  const [statusValue, setStatusValue] = useState(initStatusValue)

  useEffect(() => {
    setStatusValue(data?.active)
  }, [stateCanpaign.campaignData])

  useEffect(() => {
    const initCheckedOnFirstRender = listOfSelectedCampaign.some((val) => {
      return val.id === data.id
    })

    if (initCheckedOnFirstRender) {
      setCheckedValue(true)
    } else {
      setCheckedValue(false)
    }
  }, [stateCanpaign.campaignData, listOfSelectedCampaign])

  //FUNCTION HELPER
  const actionListOption = [
    {
      name: 'Edit Campaign',
      value: '',
      function: () => {
        history.push(`${campaignAdd}?campaignId=${data?.id}&type=EDIT`)
      },
    },
    {
      name: 'Atur Produk',
      value: '',
      function: () => {
        actionList.handleOpenPopupEdit(data)
      },
    },
    {
      name: 'Hapus Campaign',
      value: '',
      color: '#E25450',
      function: () => {
        actionList.handleOpenPopupConfirmDelete(data)
      },
    },
  ]

  //FUNCTION HANDLE
  const handleChecked = (e) => {
    const { value } = e.target
    const getCurrentRealValue = value === 'true' ? false : true
    setCheckedValue((prev) => !prev)

    if (getCurrentRealValue) {
      setListOfSelectedCampaign((prev) => [...prev, data])
    } else {
      setListOfSelectedCampaign((prev) => {
        const newSelectedData = [...prev].filter((items) => {
          return data.id !== items.id
        })
        return newSelectedData
      })
    }
  }

  const handleToggle = () => {
    const localState = {
      dataToSend: {
        is_active: !data?.active,
      },
    }
    dispatchCampaign(updateStatusActive_CAMPAIGN(data.id, localState))
  }

  return (
    <Tr>
      <Td>
        <WrapperCheck>
          <Checkbox onChange={handleChecked} checked={checkedValue} value={checkedValue} />
        </WrapperCheck>
      </Td>
      <Td>
        <TextBody size="smaller" weight="light">
          {data?.name}
        </TextBody>
      </Td>{' '}
      <Td>
        <TextBody size="smaller" weight="light">
          {data?.campaignType?.label}
        </TextBody>
      </Td>
      <Td>
        <TextBody size="smaller" weight="light">
          {data?.startDate}
        </TextBody>
      </Td>
      <Td>
        <TextBody size="smaller" weight="light">
          {data?.endDate}
        </TextBody>
      </Td>
      <Td>
        <TextBody size="smaller" weight="light">
          {data?.startTime}
        </TextBody>
      </Td>
      <Td>
        <TextBody size="smaller" weight="light">
          {data?.endTime}
        </TextBody>
      </Td>
      <Td>
        <Discount data={data} />
      </Td>
      <Td>
        <FinalPrice data={data} />
      </Td>
      <Td>
        <Quota data={data} />
      </Td>
      <Td>
        <DailyQouta data={data} />
      </Td>
      <Td>
        <StatusText className={data?.status.toLowerCase().replaceAll(' ', '_')}>
          {data?.status}
        </StatusText>
      </Td>
      <Td>
        <ActionWrapper>
          <Toggle onClick={handleToggle} active={statusValue} />
          <ButtonDropDown
            listOption={actionListOption}
            style={{ marginLeft: '16px' }}
            width={'150px'}
          />
        </ActionWrapper>
      </Td>
    </Tr>
  )
}

function Discount({ data }) {
  return (
    <TextBody size="smaller" weight="light">
      {data?.maxPercentage
        ? `${data?.minPercentage}% - ${data?.maxPercentage}%`
        : `${data?.minPercentage}%`}
    </TextBody>
  )
}

function FinalPrice({ data }) {
  return (
    <TextBody size="smaller" weight="light">
      {data?.maxFinalPrice
        ? `${formatMoneyRMG(data?.minFinalPrice, 'Rp')} - ${formatMoneyRMG(
            data?.maxFinalPrice,
            'Rp',
          )}`
        : `${formatMoneyRMG(data?.minFinalPrice, 'Rp')}`}
    </TextBody>
  )
}

function Quota({ data }) {
  return (
    <TextBody size="smaller" weight="light">
      {data?.maxDiscountQuota
        ? `${data?.minDiscountQuota} - ${data?.maxDiscountQuota}`
        : `${data?.maxDiscountQuota}`}
    </TextBody>
  )
}

function DailyQouta({ data }) {
  return (
    <TextBody size="smaller" weight="light">
      {data?.maxDailyQuota
        ? `${data?.minDailyQuota} - ${data?.maxDailyQuota}`
        : `${data?.maxDailyQuota}`}
    </TextBody>
  )
}

RowCampaign.defaultProps = {
  data: {},
}

export default memo(RowCampaign)

const Tr = styled.tr`
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
`
const Td = styled.td`
  color: ${({ theme: { colors }, color = 'textSoft' }) => colors[color]};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  padding: 16px;
  padding-right: 20px;
  min-width: ${({ min }) => min};
`
const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  i {
    margin-left: 20px;
  }
`

const WrapperCheck = styled.div`
  display: grid;
  place-items: center;
`

const StatusText = styled.span`
  padding: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
`
