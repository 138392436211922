/* eslint-disable @typescript-eslint/naming-convention */
import { AppDispatchType, RootStateType } from 'store'
import { getRackStorage, getUoms, GetRackStorageParamsType } from 'features/Rack/services/rackList'
import { getAreaListAPI } from 'features/Rack/services/area'
import {
  RackAddEditInitialType,
  errorMessageWording,
  setStorageList,
  setAreaList,
  setLocationTypeList,
  setUomList,
  setLocationList,
  setErrorMessage,
  setErrorMessageOfRack,
  setFormDataOnGetDetailRackMultiple,
  setSkuList,
  setRackSingleDetailResponse,
} from 'features/Rack/redux/rackAddEdit'
import { getLocationTypeList, getLocationListByType } from 'features/Location/services/location'
import {
  postCreateRacks,
  getDetailRackMultipleByLocationId,
  putUpdateSingleRackInKong,
  getRackDetailInKong,
  GetRackDetailInkongResponseType,
  UpdateSingleRackInKongRequestType,
} from 'features/Rack/services/rackAddEdit'
import { getProductList } from 'features/Product/services/product'
import { UomListType } from 'features/Rack/@types/rack'
import { callErrorMsg } from 'helpers/errorMsg'
import { toastSuccess } from 'utils/toast'

export const fetchLocationTypeList = () => async (dispatch: AppDispatchType) => {
  try {
    const {
      data: { data },
    } = await getLocationTypeList()
    dispatch(setLocationTypeList(data))
  } catch (err) {
    callErrorMsg(err)
  }
}

export const fetchLocationListByType =
  (locationType: string) => async (dispatch: AppDispatchType) => {
    try {
      const { data } = await getLocationListByType(locationType)
      dispatch(setLocationList(data))
    } catch (err) {
      callErrorMsg(err)
    }
  }

export const fetchStorageByType =
  (type: GetRackStorageParamsType['type']) => async (dispatch: AppDispatchType) => {
    try {
      const {
        data: { data },
      } = await getRackStorage({ type })
      if (type === 'Environment')
        dispatch(setStorageList({ fieldName: 'storageEnvironmentList', listData: data.content }))
      else if (type === 'Type')
        dispatch(setStorageList({ fieldName: 'storageTypeList', listData: data.content }))
    } catch (err) {
      callErrorMsg(err)
    }
  }

export const fetchRackSingleDetail =
  (rackId: number, callbackFunction?: (data: GetRackDetailInkongResponseType) => void) =>
  async (dispatch: AppDispatchType) => {
    try {
      const {
        data: { data },
      } = await getRackDetailInKong(rackId)
      dispatch(setRackSingleDetailResponse(data))
      if (callbackFunction) callbackFunction(data)
    } catch (err) {
      callErrorMsg(err)
    }
  }

export const fetchUomList =
  (callbackFunction?: (uomList: UomListType) => void) => async (dispatch: AppDispatchType) => {
    try {
      const {
        data: { data },
      } = await getUoms({ pageSize: 50 })
      const qtyUomList = data.unit_of_measurements.content.filter((item) => item.group === 'ITEM')
      const weightUomList = data.unit_of_measurements.content.filter(
        (item) => item.group === 'WEIGHT',
      )
      const volumeUomList = data.unit_of_measurements.content.filter(
        (item) => item.group === 'LENGTH',
      )
      dispatch(setUomList({ fieldName: 'qtyUomList', listData: qtyUomList }))
      dispatch(setUomList({ fieldName: 'weightUomList', listData: weightUomList }))
      dispatch(setUomList({ fieldName: 'volumeUomList', listData: volumeUomList }))
      if (callbackFunction) callbackFunction(data.unit_of_measurements.content)
    } catch (err) {
      callErrorMsg(err)
    }
  }

const dropdownFields = [
  'selectedLocationType',
  'selectedLocationName',
  'selectedStorageEnvironment',
  'selectedStorageType',
  'selectedVolumeUom',
  'selectedQtyUom',
  'selectedWeightUom',
  'selectedArea',
] as const

const textNumberFields = ['maxQuantity', 'maxVolume', 'maxWeight'] as const

const allFields = [...dropdownFields, ...textNumberFields, 'rack'] as const

type DropdownFieldType = (typeof dropdownFields)[number]
type TextNumberFieldsType = (typeof textNumberFields)[number]
type FieldToValidateType = DropdownFieldType | TextNumberFieldsType

const isDropdown = (fieldName: DropdownFieldType) => dropdownFields.includes(fieldName)

export const validateFormRackAddEdit =
  (fieldName: FieldToValidateType) =>
  async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { rackAddEdit } = getState()
    let errorMessage = ''
    if (fieldName && !rackAddEdit[fieldName]) {
      errorMessage = isDropdown(fieldName as DropdownFieldType)
        ? errorMessageWording.selected
        : errorMessageWording.greaterThanZero
    }

    dispatch(
      setErrorMessage({
        [fieldName]: errorMessage,
      }),
    )
  }

const fieldNumber = ['position', 'minReplenishQty', 'maxReplenishQty', 'replenishQty']
export const validateArrayOfRack =
  (
    index: number,
    fieldName: 'rackName' | 'position' | 'minReplenishQty' | 'maxReplenishQty' | 'replenishQty',
  ) =>
  async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { rackAddEdit } = getState()
    let errorMessage = ''
    if (fieldName && !rackAddEdit.racks[index][fieldName]) {
      const isNumberField = fieldNumber.includes(fieldName)
      errorMessage = isNumberField
        ? errorMessageWording.greaterThanZero
        : errorMessageWording.required
    }
    dispatch(
      setErrorMessageOfRack({
        index,
        fieldName,
        message: errorMessage,
      }),
    )
  }

export const validateSku =
  (index: number) => (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { rackAddEdit } = getState()
    let errorMessage = ''
    if (!rackAddEdit.racks[index].selectedSku) errorMessage = errorMessageWording.selected
    dispatch(
      setErrorMessageOfRack({
        index,
        fieldName: 'productSkuNo',
        message: errorMessage,
      }),
    )
  }

const isAllFieldReplenishQtyFilled = (
  rackAtSpecificIndex: RackAddEditInitialType['racks'][number],
) =>
  !!(
    rackAtSpecificIndex.minReplenishQty &&
    rackAtSpecificIndex.maxReplenishQty &&
    rackAtSpecificIndex.replenishQty
  )

const getReplenishQty = (qty: number | null) => qty || 0
export const actValidateMinReplenishQty =
  (index: number) => (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { rackAddEdit } = getState()
    const rackAtSpecificIndex = rackAddEdit.racks[index]

    let errorMessage = ''
    if (!rackAtSpecificIndex?.minReplenishQty) {
      errorMessage = errorMessageWording.greaterThanZero
    } else if (
      isAllFieldReplenishQtyFilled(rackAtSpecificIndex) &&
      getReplenishQty(rackAtSpecificIndex?.minReplenishQty) >
        getReplenishQty(rackAtSpecificIndex?.maxReplenishQty)
    ) {
      errorMessage = 'Min Replenish Qty must smaller than Max Replenish Qty'
    } else if (
      isAllFieldReplenishQtyFilled(rackAtSpecificIndex) &&
      getReplenishQty(rackAtSpecificIndex?.minReplenishQty) >=
        getReplenishQty(rackAtSpecificIndex?.replenishQty)
    ) {
      errorMessage = 'Min Replenish Qty must smaller or equal than Replenish Qty'
    }
    dispatch(
      setErrorMessageOfRack({
        index,
        fieldName: 'minReplenishQty',
        message: errorMessage,
      }),
    )
  }

export const actValidateReplenishQty =
  (index: number) => (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { rackAddEdit } = getState()
    const rackAtSpecificIndex = rackAddEdit.racks[index]

    let errorMessage = ''
    if (!rackAtSpecificIndex?.replenishQty) {
      errorMessage = errorMessageWording.greaterThanZero
    } else if (
      isAllFieldReplenishQtyFilled(rackAtSpecificIndex) &&
      getReplenishQty(rackAtSpecificIndex?.replenishQty) >
        getReplenishQty(rackAtSpecificIndex?.maxReplenishQty)
    ) {
      errorMessage = 'Replenish Qty must smaller than Max Replenish Qty'
    }
    dispatch(
      setErrorMessageOfRack({
        index,
        fieldName: 'replenishQty',
        message: errorMessage,
      }),
    )
  }

const isStoragePickface = (storageType: string) => storageType.toLowerCase() === 'pickface'

export const validateRackForm =
  () => async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { rackAddEdit } = getState()
    dropdownFields.forEach((field) => {
      dispatch(validateFormRackAddEdit(field))
    })
    textNumberFields.forEach((field) => {
      dispatch(validateFormRackAddEdit(field))
    })
    rackAddEdit.racks.forEach((_, index) => {
      dispatch(validateArrayOfRack(index, 'position'))
      dispatch(validateArrayOfRack(index, 'rackName'))

      if (isStoragePickface(rackAddEdit.selectedStorageType?.rackStorageName || '')) {
        dispatch(validateArrayOfRack(index, 'maxReplenishQty'))
        dispatch(validateSku(index))
        dispatch(actValidateMinReplenishQty(index))
        dispatch(actValidateReplenishQty(index))
      }
    })
  }

type AllFieldsType = typeof allFields

export const saveRackForm =
  (_type: 'add' | 'update', callbackFunction: () => void) =>
  async (_dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { rackAddEdit } = getState()
    const fieldNameList = Object.keys(rackAddEdit.errorMessage) as unknown as AllFieldsType

    const isFieldHasError = fieldNameList.some(
      (fieldName) => fieldName !== 'rack' && rackAddEdit.errorMessage[fieldName],
    )

    const isArrayOfRackHasError = rackAddEdit.errorMessage.rack.some((rackItem) => {
      const isNotValid = rackItem.position || rackItem.rackName

      let isPickfaceInfoNotValid = false
      if (isStoragePickface(rackAddEdit.selectedStorageType?.rackStorageName || '')) {
        isPickfaceInfoNotValid = !!(
          rackItem.productSkuNo ||
          rackItem.replenishQty ||
          rackItem.minReplenishQty ||
          rackItem.maxReplenishQty
        )
      }
      return isNotValid || isPickfaceInfoNotValid
    })
    if (isFieldHasError || isArrayOfRackHasError) return

    const dataToSubmit = {
      location_id: rackAddEdit.selectedLocationName?.location_id as number,
      storage_environment: rackAddEdit.selectedStorageEnvironment?.rackStorageId as number,
      storage_type: rackAddEdit.selectedStorageType?.rackStorageId as number,
      area_id: rackAddEdit.selectedArea?.id as number,
      max_volume: rackAddEdit.maxVolume,
      max_volume_uom: rackAddEdit.selectedVolumeUom?.id as string,
      max_quantity: rackAddEdit.maxQuantity,
      max_quantity_uom: rackAddEdit.selectedQtyUom?.id as string,
      max_weight: rackAddEdit.maxWeight,
      max_weight_uom: rackAddEdit.selectedWeightUom?.id as string,
      max_sku: rackAddEdit.maxSku,
      mix_attribute: rackAddEdit.mixAttribute,
      mix_product: rackAddEdit.mixProduct,
      active: rackAddEdit.active,
      rack_details: rackAddEdit.racks.map((item) => ({
        rack_name: item.rackName,
        position: item.position as number,
        ...(isStoragePickface(rackAddEdit.selectedStorageType?.rackStorageName || '') && {
          product_id: item.selectedSku?.product_id as number,
          maximum_replenish_quantity: item.maxReplenishQty as number,
          minimum_replenish_quantity: item.minReplenishQty as number,
          replenish_quantity: item.replenishQty as number,
        }),
      })),
    }

    try {
      await postCreateRacks(dataToSubmit)
      toastSuccess('Rak berhasil ditambahkan')
      if (callbackFunction) callbackFunction()
    } catch (error) {
      callErrorMsg(error)
    }
  }

export const validateAndSaveRackForm =
  (type: 'add' | 'update', callbackFunction: () => void) => async (dispatch: AppDispatchType) => {
    dispatch(validateRackForm())
    dispatch(saveRackForm(type, callbackFunction))
  }

export const fetchDetailRackMultiple =
  (locationId: number) => async (dispatch: AppDispatchType) => {
    try {
      const response = await getDetailRackMultipleByLocationId(locationId)
      dispatch(setFormDataOnGetDetailRackMultiple(response.data.data))
    } catch (err) {
      callErrorMsg(err)
    }
  }

export const updateSingleRack =
  (rackId: number, dataToSubmit: UpdateSingleRackInKongRequestType, callbackFunction: () => void) =>
  async () => {
    try {
      await putUpdateSingleRackInKong(rackId, dataToSubmit)
      toastSuccess('Rak berhasil diperbarui')
      if (callbackFunction) callbackFunction()
    } catch (err) {
      callErrorMsg(err)
    }
  }

export const fetchAreaList =
  (searchName: string, locationId: number) => async (dispatch: AppDispatchType) => {
    try {
      const paramsType = {
        page_index: 1,
        page_size: 20,
        search_name: searchName,
        location_id: locationId,
      }

      const {
        data: { data },
      } = await getAreaListAPI(paramsType)
      dispatch(setAreaList(data))
    } catch (err) {
      callErrorMsg(err)
    }
  }

export const fetchProductList = (name: string) => async (dispatch: AppDispatchType) => {
  try {
    const {
      data: { data },
    } = await getProductList({
      'pagination.page_size': 20,
      'pagination.page_index': 1,
      'filter.name': name,
    })
    dispatch(setSkuList(data))
  } catch (err) {
    callErrorMsg(err)
  }
}
