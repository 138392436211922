import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  GetSoApprovalRequestType,
  GetSoApprovalResponseType,
  GetUsersResponseType,
  GetSoListResponseType,
  SupplyOrderApprovalDetailV2ItemType,
} from 'utils/apiList/supplyOrderApproval'
import { withLoadingReducer } from 'utils/reducerHandler'
import {
  fetchGetSupplyOrderApproval,
  fetchGetUsers,
  fetchPutSupplyOrderApprovalDetail,
  fetchSoList,
} from './supplyOrderApprovalAsyncThunk'

type FormType = {
  soPartialItemId: number
  icQuantity: number
  evidence: string
  chronology: string
  icReason: string
  lpTarget: string
}

export type SupplyOrderApprovalSliceType = {
  isLoading: boolean
  queryData: GetSoApprovalRequestType
  pagination: {
    page_size: number
    page_index: number
    number_of_elements: number
  }
  popup: {
    isOpen: boolean
    type: 'REVIEW' | 'DETAIL'
    selected?: GetSoApprovalResponseType['data'][number]
  }
  data: GetSoApprovalResponseType['data']
  detailsV2: SupplyOrderApprovalDetailV2ItemType[]
  formV2: FormType[]
  users: GetUsersResponseType['content']
  searchSoNumber: string
  selectedSoNumber: {
    id: number
    name: string
  }
  soList: GetSoListResponseType['data']['content']
  allowedLocationIds: number[]
}

const initialState: SupplyOrderApprovalSliceType = {
  isLoading: false,
  queryData: {
    page_index: 1,
    supply_order_id: 0,
  },
  pagination: {
    page_size: 20,
    page_index: 1,
    number_of_elements: 0,
  },
  popup: {
    isOpen: false,
    type: 'REVIEW',
  },
  data: [],
  detailsV2: [],
  formV2: [],
  users: [],
  searchSoNumber: '',
  selectedSoNumber: {
    id: 0,
    name: '',
  },
  soList: [],
  allowedLocationIds: [],
}

const supplyOrderApprovalSlice = createSlice({
  name: 'supplyOrderApproval',
  initialState,
  reducers: {
    setReset: () => initialState,
    setQueryData: (state, action) => {
      state.queryData = { ...state.queryData, ...action.payload }
    },
    setSelectedSoNumber: (state, action) => {
      state.selectedSoNumber = action.payload
    },
    setOpenPopup: (
      state,
      action: PayloadAction<{
        type: SupplyOrderApprovalSliceType['popup']['type']
        selected?: SupplyOrderApprovalSliceType['popup']['selected']
      }>,
    ) => {
      const { type, selected } = action.payload

      state.popup.isOpen = true
      state.popup.type = type
      state.popup.selected = selected
    },
    setClosePopup: (state) => {
      state.popup.isOpen = false
    },
    setFormV2: (
      state,
      action: PayloadAction<{
        index: number
        key: keyof SupplyOrderApprovalSliceType['formV2'][number]
        value: unknown
      }>,
    ) => {
      const { index, key, value } = action.payload
      state.formV2[index][key] = value as never
    },
    setSearchSoNumber: (state, action: PayloadAction<string>) => {
      state.searchSoNumber = action.payload
    },
    setAllowedLocationIds: (state, action: PayloadAction<number[]>) => {
      state.allowedLocationIds = action.payload
    },
    setDataOnFetchDetailSucceed: (
      state,
      action: PayloadAction<SupplyOrderApprovalDetailV2ItemType[]>,
    ) => {
      state.detailsV2 = action.payload
      state.formV2 = action.payload.map((item) => ({
        chronology: item.status === 'WAITING_APPROVAL' ? item.ic_chronology : item.lp_chronology,
        evidence: item.status === 'WAITING_APPROVAL' ? item.ic_evidence : item.lp_evidence,
        icQuantity: item.ic_quantity,
        icReason: item.ic_reason,
        lpTarget: item.lp_target,
        soPartialItemId: item.so_partial_item_id,
      }))
    },
    resetTableData: (state) => {
      state.data = []
      state.queryData = { ...state.queryData, page_index: 1 }
      state.pagination = {
        page_size: 20,
        page_index: 1,
        number_of_elements: 0,
      }
    },
  },
  extraReducers: withLoadingReducer(
    (builder: ActionReducerMapBuilder<SupplyOrderApprovalSliceType>) => {
      builder
        .addCase(fetchGetSupplyOrderApproval.fulfilled, (state, action) => {
          const { data, pagination } = action.payload
          state.data = data
          state.pagination = pagination
        })
        .addCase(fetchGetUsers.fulfilled, (state, action) => {
          state.users = action.payload.content
        })
        .addCase(fetchSoList.fulfilled, (state, action) => {
          state.soList = action.payload.map((item) => ({
            supplyOrderId: item.supplyOrderId,
            supplyOrderNumber: item.supplyOrderNumber,
          }))
        })
    },
    [fetchGetSupplyOrderApproval, fetchPutSupplyOrderApprovalDetail],
  ),
})

export const {
  setReset,
  setQueryData,
  setSelectedSoNumber,
  setFormV2,
  setOpenPopup,
  setClosePopup,
  setSearchSoNumber,
  setAllowedLocationIds,
  setDataOnFetchDetailSucceed,
  resetTableData,
} = supplyOrderApprovalSlice.actions
export default supplyOrderApprovalSlice.reducer
