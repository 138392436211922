import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { dateFormat } from 'utils/helpers/date'
import {
  SLICE_NAME,
  uploadMissionImage,
  fetchRewardType,
  fetchVoucherList,
  uploadMilestoneImage,
  createReferralMission,
  fetchMissionByID,
  updateMissionByID,
} from './referralMissionAddAndEditThunk'

export type MissionStateType = {
  id: number
  missionName: string
  startDate: number
  startDay: string
  startTime: string
  endDate: number
  endDay: string
  endTime: string
  status: {
    name: string
    value: string
  }
  bannerUpcomingUrl: string
  bannerOngoingUrl: string
  bannerFinishedUrl: string
  linkTitle: string
  linkDescription: string
  linkMessage: string
  linkImageUrl: string
  rewards: {
    rewardTitle: string
    rewardPosition: number
    iconEnabledUrl: string
    iconDisabledUrl: string
    description: string
    quota: number
    rewardType: {
      name: string
      value: string
    }
    voucherCode: string
    coinAmount: number
    uniqueId: string
    isSequence: boolean
    sequence: {
      rewardTitle: string
      iconEnabledUrl: string
      iconDisabledUrl: string
      rewardType: {
        name: string
        value: string
      }
      voucherCode: string
      coinAmount: number
      description: string
      uniqueId: string
    }[]
  }[]
}

export type RewardType = {
  name: string
  value: string
}

export type VoucherRewardType = {
  list: {
    voucher_id: number
    voucher_code: string
    name: string
  }[]
}

export type QueryType = {
  voucherCode: string
}
export interface ReferralMissionAddAndEditStateType {
  isLoading: boolean
  query: QueryType
  mission: MissionStateType
  rewardType: RewardType[]
  voucher: VoucherRewardType
}

export type SetMissionPayloadType = {
  name: keyof MissionStateType
  value: number | string | MissionStateType['status'] | MissionStateType['rewards'][0]
}

const stateRewardSequence = () =>
  <MissionStateType['rewards'][0]['sequence'][0]>{
    coinAmount: 0,
    description: '',
    iconDisabledUrl: '',
    iconEnabledUrl: '',
    rewardTitle: '',
    rewardType: {
      name: 'VOUCHER',
      value: 'VOUCHER',
    },
    voucherCode: '',
    uniqueId: window.crypto.randomUUID(),
  }
const stateRewards = (rewardPosition: number) =>
  <MissionStateType['rewards'][0]>{
    rewardTitle: '',
    rewardPosition,
    iconEnabledUrl: '',
    iconDisabledUrl: '',
    rewardType: {
      name: '',
      value: '',
    },
    voucherCode: '',
    coinAmount: 0,
    uniqueId: window.crypto.randomUUID(),
    isSequence: false,
    sequence: [stateRewardSequence()],
    description: '',
    quota: 0,
  }

const initialState: ReferralMissionAddAndEditStateType = {
  isLoading: false,
  query: { voucherCode: '' },
  mission: {
    id: 0,
    missionName: '',
    startDate: Number(new Date().valueOf()),
    startDay: '',
    startTime: '',
    endDate: Number(new Date().valueOf()),
    endDay: '',
    endTime: '',
    status: {
      name: 'Active',
      value: 'ACTIVE',
    },
    bannerUpcomingUrl: '',
    bannerOngoingUrl: '',
    bannerFinishedUrl: '',
    linkTitle: '',
    linkDescription: '',
    linkMessage: '',
    linkImageUrl: '',
    rewards: [stateRewards(1)],
  },
  rewardType: [],
  voucher: { list: [] },
}

export type SetMissionRewardPayloadType = {
  name: keyof MissionStateType['rewards'][0]
  index: number
  value: string | number | MissionStateType['rewards'][0]['rewardType']
}

export type SetMissionRewardSequencePayloadType = {
  name: keyof MissionStateType['rewards'][0]['sequence'][0]
  index: number
  sequenceIndex: number
  value: string | number | MissionStateType['rewards'][0]['sequence'][0]['rewardType']
}

export type SetIsSequencePayloadType = {
  rewardIndex: number
  isSequence: boolean
}

const referralMissionAddAndEdit = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setMission: (state, action: PayloadAction<SetMissionPayloadType>) => {
      const { name, value } = action.payload
      state.mission[name] = value as never
    },
    setMissionReward: (state, action: PayloadAction<SetMissionRewardPayloadType>) => {
      const { name, index, value } = action.payload
      state.mission.rewards[index][name] = value as never
      if (name === 'rewardType') {
        state.mission.rewards[index].voucherCode = initialState.mission.rewards[0].voucherCode
      }
    },
    addMilestones: (state) => {
      const currentRewards = [...state.mission.rewards]

      state.mission.rewards.push(stateRewards(currentRewards.length + 1))
    },
    removeMilestone: (state, action: PayloadAction<{ index: number }>) => {
      const { index } = action.payload
      state.mission.rewards.splice(index, 1)
    },

    setMissionRewardSequence: (
      state,
      action: PayloadAction<SetMissionRewardSequencePayloadType>,
    ) => {
      const { name, index, value, sequenceIndex } = action.payload
      state.mission.rewards[index].sequence[sequenceIndex][name] = value as never
    },
    setIsSequence: (state, action: PayloadAction<SetIsSequencePayloadType>) => {
      const { isSequence, rewardIndex } = action.payload
      state.mission.rewards[rewardIndex].isSequence = isSequence
    },
  },
  extraReducers(builder) {
    builder
      .addCase(uploadMissionImage.pending, (state) => {
        state.isLoading = true
      })
      .addCase(uploadMissionImage.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(uploadMissionImage.fulfilled, (state, action) => {
        const { data, imageKey } = action.payload
        state.isLoading = false
        state.mission[imageKey] = data.data.url
      })
      .addCase(uploadMilestoneImage.pending, (state) => {
        state.isLoading = true
      })
      .addCase(uploadMilestoneImage.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(uploadMilestoneImage.fulfilled, (state, action) => {
        const { data, imageKey, index, milestoneType, sequenceIndex } = action.payload
        state.isLoading = false
        if (milestoneType === 'REWARD') {
          state.mission.rewards[index][imageKey] = data.data.url
        }

        if (milestoneType === 'REWARD_SEQUENCE') {
          state.mission.rewards[index].sequence[sequenceIndex][imageKey] = data.data.url
        }
      })

      .addCase(fetchRewardType.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchRewardType.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchRewardType.fulfilled, (state, action) => {
        const { data } = action.payload
        state.isLoading = false
        state.rewardType = data.content
      })

      .addCase(fetchVoucherList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchVoucherList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchVoucherList.fulfilled, (state, action) => {
        const { content } = action.payload
        state.isLoading = false
        state.voucher.list = content
          .filter((el) => el.targeting_type === 'Targeted User')
          .map((el) => ({
            name: el.voucher_code,
            voucher_code: el.voucher_code,
            voucher_id: el.voucher_id,
          }))
      })
      .addCase(createReferralMission.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createReferralMission.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(createReferralMission.fulfilled, (state) => {
        state.isLoading = false
      })

      .addCase(fetchMissionByID.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchMissionByID.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchMissionByID.fulfilled, (state, action) => {
        const { data } = action.payload
        state.isLoading = false
        state.mission = {
          ...data,
          endDay: dateFormat({ date: data.endDate, format: 'YYYY-MM-DD', utc: true }),
          endTime: dateFormat({ date: data.endDate, format: 'HH:mm', utc: true }),
          startDay: dateFormat({ date: data.startDate, format: 'YYYY-MM-DD', utc: true }),
          startTime: dateFormat({ date: data.startDate, format: 'HH:mm', utc: true }),
          status: { ...state.mission.status, value: data.status },
          rewards: data.rewards.map((el) => ({
            ...el,
            uniqueId: window.crypto.randomUUID(),
            rewardType: {
              name: el.rewardType.name ? el.rewardType.name : 'TBD',
              value: el.rewardType.value ? el.rewardType.value : 'TBD',
            },
            isSequence: !!el.sequence.length,
            sequence: el.sequence.map((elSequence) => ({
              ...elSequence,
              uniqueId: window.crypto.randomUUID(),
            })),
          })),
        }
      })

      .addCase(updateMissionByID.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateMissionByID.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateMissionByID.fulfilled, (state) => {
        state.isLoading = false
      })
  },
})

export const {
  reset,
  setMission,
  setMissionReward,
  addMilestones,
  removeMilestone,
  setMissionRewardSequence,
  setIsSequence,
} = referralMissionAddAndEdit.actions
export default referralMissionAddAndEdit.reducer
