import axios from 'axios'
const { REACT_APP_BASE_URL_API } = process.env
const baseUrl = REACT_APP_BASE_URL_API

export const getLoyaltyActivity = (params) =>
  axios.get(`${baseUrl}/api/loyalty/activity-rules`, { params })
export const getLoyaltyActivityDetail = (id) =>
  axios.get(`${baseUrl}/api/loyalty/activity-rules/${id}`)
export const postLoyaltyActivity = (payload) =>
  axios.post(`${baseUrl}/api/loyalty/activity-rules`, payload)
export const putLoyaltyActivity = (id, payload) =>
  axios.put(`${baseUrl}/api/loyalty/activity-rules/${id}`, payload)
export const putLoyaltyActivityType = (type, payload) =>
  axios.put(`${baseUrl}/api/loyalty/activity-rules/${type}`, payload)
export const deleteLoyaltyActivity = (id) =>
  axios.delete(`${baseUrl}/api/loyalty/activity-rules/${id}`)
