import { useContext, useEffect, useState } from 'react'
import { useAppDispatch } from 'store'

import { getTokenCookies, removeTokenCookies } from 'config/cookies'
import { fetchGetAtuhentication, setUserData as setUserDataRedux } from 'features/Auth/redux/Auth'
import storeContext from 'storeContext'
import { setUserData as setUserDataContext, setIsLogin } from 'storeContext/actions/auth'
import setUserDataToStore from 'utils/setUserDataToStore'

export type ValidateJWTPropsType = {
  children: JSX.Element
}

const ValidateJWT = ({ children }: ValidateJWTPropsType) => {
  const dispatchRedux = useAppDispatch()
  const { dispatch } = useContext(storeContext.AuthContext) as unknown as {
    dispatch: (payload: unknown) => void
  }
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getAuthentication = async () => {
      const token = getTokenCookies()
      try {
        if (!token) {
          throw new Error('Token Not Exsist')
        }
        const user = await dispatchRedux(fetchGetAtuhentication()).unwrap()
        const userData = setUserDataToStore(user)
        dispatchRedux(setUserDataRedux(userData))
        dispatch(setUserDataContext(userData))
        dispatch(setIsLogin(true))
      } catch (error) {
        removeTokenCookies()
      } finally {
        setLoading(false)
      }
    }
    getAuthentication()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) return null

  return children
}

export default ValidateJWT
