import {
  FormControlLabel as MuiFormControlLabel,
  FormControlLabelProps as MuiFormControlLabelProps,
} from '@mui/material'
import styled from 'styled-components'

export type FormControlLabelPropsType = MuiFormControlLabelProps

const FormControlLabel = styled(MuiFormControlLabel)<FormControlLabelPropsType>``

export default FormControlLabel
