import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const locationRBAC = {
  pageID: '32120b1acc3c64bf6dc571dd9c700026',
  elementID: {
    ViewLocationList: '4f79863818d4b459351b675347ae820c',
    ViewLocationDetail: '8a1cbd04ef808877639114b5a9388c46',
    ViewDeliveryConfig: '67d2ab66ae0a8bcf647f8a989d48ba93',
    UpdateDeliveryConfig: '6d8418be1fd3295b8144e0832d66ff63',
    UpdateLocationIDStatus: '87bcd8f11072b1ed397f433f232a62b9',
    UpdateLocationDetail: 'fb0f9f51225ea31ac23ff609ffe704b6',
    CreateNewLocationID: '006e1335a4b2bc263d1c551919399c9e',
    UpdatePooling: '92fdc8ebbeb745cb6aaf74bf0105d3d5',
  },
} as const

export const useRBACLocationPage = createUseRBAC(locationRBAC.elementID)

export type UseRBACBuyerPageType = ReturnType<typeof useRBACLocationPage>
