import { lazy } from 'react'
import { roles } from 'constant/roles'

import generateRouterDetail from 'utils/helpers/generateRouterDetail'

const ReorderListData = lazy(() => import('../pages/ReorderListData'))
const ReorderListSavePreview = lazy(() => import('../pages/ReorderListSavePreview'))

const generalRolesForReorderList = [
  roles.AUTH_HO_PROCUREMENT,
  roles.AUTH_HO_SUPPLY_CHAIN,
  roles.AUTH_HO_ASTRO_KITCHEN_SOURCING,
  roles.AUTH_HO_ASTRO_KITCHEN_SUPPLY_CHAIN,
  roles.AUTH_HO_PRODUCT_MANAGER,
  roles.AUTH_HO_QUALITY_ENGINEERING,
]
export const allowedRolesReorderListSc = [roles.AUTH_SUPPLY_CHAIN, ...generalRolesForReorderList]

export const allowedRolesReorderListSourcing = [
  roles.AUTH_SOURCING_MANAGER,
  roles.AUTH_SOURCING,
  ...generalRolesForReorderList,
]

const ReorderListDataRouter: RouterFeatureInterface<
  'ReorderListDataRouter',
  '/dashboard/reorder-list'
> = {
  name: 'ReorderListDataRouter',
  Component: ReorderListData,
  path: '/dashboard/reorder-list',
  routeType: 'PRIVATE',
  allowedRoles: [
    roles.AUTH_SUPPLY_CHAIN,
    roles.AUTH_SOURCING_MANAGER,
    roles.AUTH_SOURCING,
    ...generalRolesForReorderList,
  ],
  isDashboard: true,
}

const ReorderListSavePreviewRouter: RouterFeatureInterface<
  'ReorderListSavePreviewRouter',
  '/dashboard/reorder-list/save-preview'
> = {
  name: 'ReorderListSavePreviewRouter',
  Component: ReorderListSavePreview,
  path: '/dashboard/reorder-list/save-preview',
  routeType: 'PRIVATE',
  allowedRoles: [
    roles.AUTH_SOURCING_MANAGER,
    roles.AUTH_SOURCING,
    roles.AUTH_HO_PROCUREMENT,
    roles.AUTH_HO_ASTRO_KITCHEN_SOURCING,
    roles.AUTH_HO_PRODUCT_MANAGER,
    roles.AUTH_HO_QUALITY_ENGINEERING,
  ],
  isDashboard: true,
}

const ReorderListRouter = [ReorderListDataRouter, ReorderListSavePreviewRouter]

export const ReorderListRouterDetail = generateRouterDetail(ReorderListRouter)

export default ReorderListRouter
