import { Get, Delete, Post } from 'config/apiServiceConfig'

type PaginationType = {
  sort: null
  direction: string
  pageSize: number
  pageIndex: number
  numberOfElements: number
  totalElements: number
  totalPages: number
}

type ErrorType = {
  status: boolean
  message: string
  code: number
}

export type GetAllVIPRequestType = {
  params: {
    pageIndex: number
    pageSize: number
    sort?: string
    direction?: string
    userID?: string
    name?: string
    referralCode?: string
  }
}

export type GetAllVIPResponseType = {
  data: {
    referral_group_list: {
      user_id: number
      name: string
      group_type: string
      created_by: string
      created_at: string
      referral_code: string
    }[]
  }
  error: ErrorType
  pagination: PaginationType
}

export type DeleteReferralVIPRequestType = {
  userID: string
}

export type DeleteReferralVIPResponseType = {
  data: {
    user_id: number
    name: string
    group_type: string
  }
  error: ErrorType
  pagination: null
}

export type PostReferralVIPRequestType = {
  payload: {
    user_id: number
    group_type: string
  }[]
}

export type PostReferralVIPResponseType = {
  data: {
    user_id: number
    name: string
    group_type: string
  }[]
  error: ErrorType
  pagination: null
}

export const getAllReferralVIP = ({ params }: GetAllVIPRequestType) =>
  Get<GetAllVIPResponseType>({ url: '/api/referral-setting/group', params })

export const deleteReferralVIP = ({ userID }: DeleteReferralVIPRequestType) =>
  Delete<DeleteReferralVIPResponseType>({ url: `/api/referral-setting/group/${userID}` })

export const postReferralVIP = ({ payload }: PostReferralVIPRequestType) =>
  Post<PostReferralVIPResponseType>({ url: '/api/referral-setting/group', data: payload })

export type GetCustomerListRequestType = {
  params: {
    pageIndex: number
    pageSize: number
    name?: string
  }
}

export type GetCustomerListResponseType = {
  content: {
    customer_active: boolean
    customer_email: string
    customer_first_name: string
    customer_id: number
    customer_is_fraud: boolean
    customer_is_fraud_referral: boolean
    customer_last_name: string
    customer_phone_number: string
    customer_point: number
    customer_referral_code: string
    customer_refund_point: number
    customer_refund_point_fmt: string
  }[]
}
export const getCustomerList = ({ params }: GetCustomerListRequestType) =>
  Get<GetCustomerListResponseType>({ url: '/api/customer', params })
