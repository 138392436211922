import { memo } from 'react'
import useSWR from 'swr'

import { useAppDispatch } from 'store'
import { setRBAC, type AuthInitialStateType } from 'features/Auth/redux/Auth'

import apiServiceApiGateway from 'config/apiServiceApiGateway'
import { URLPatternExtractorSingleSegment } from 'utils/helpers/URLPatternHelper'
import { useLocation } from 'react-router-dom'

const { Get } = apiServiceApiGateway

type PropsType = {
  children: JSX.Element
  pageID?: RouterFeatureInterface<'test', 'test'>['rbacPageID']
  path?: string
  multiplePageIDMatchWithPath?: RouterFeatureInterface<'test', 'test'>['multiplePageID']
}
const FetchRBAC = ({ children, pageID, multiplePageIDMatchWithPath, path }: PropsType) => {
  const dispatch = useAppDispatch()

  const isPageIDIsSet = !!(pageID || multiplePageIDMatchWithPath)

  const location = useLocation()

  let selectedPageID = pageID

  if (multiplePageIDMatchWithPath) {
    const { keySegment, pageIDmatchWithKeySegments } = multiplePageIDMatchWithPath
    const value = URLPatternExtractorSingleSegment({
      pattern: path || '',
      segmentKey: keySegment,
      url: location.pathname,
    })

    selectedPageID = pageIDmatchWithKeySegments[value || '']
  }

  const { data, isLoading } = useSWR(isPageIDIsSet ? [`/rbac/get/${selectedPageID}`] : null, () =>
    Get<AuthInitialStateType['RBAC']>({
      url: '/auth/internal/v1/validate/page',
      params: { page_id: selectedPageID },
    }),
  )

  if (isLoading) return null

  if (data) {
    const rbac = data.data.data
    const { authorized } = rbac
    if (!authorized) return null
    dispatch(setRBAC(rbac))
  }

  return children
}

export default memo(FetchRBAC)
