import createReducer from "storeContext/reducers/createReducer";

export const RACK_DETAIL_EDIT_RESET = "rackDetailEdit/RESET";
export const RACK_DETAIL_EDIT_FETCH_SINGLE_RACK_BY_ID =
  "rackDetailEdit/FETCH_SINGLE_RACK_BY_ID";
export const RACK_DETAIL_EDIT_FETCH_SINGLE_RACK_BY_ID_SUCCESS =
  "rackDetailEdit/FETCH_SINGLE_RACK_BY_ID_SUCCESS";
export const RACK_DETAIL_EDIT_FETCH_SINGLE_RACK_BY_ID_FAILURE =
  "rackDetailEdit/FETCH_SINGLE_RACK_BY_ID_FAILURE";

export const RACK_DETAIL_EDIT_SET_IS_BUSY_SUBMIT_DATA =
  "rackDetailEdit/SET_IS_BUSY_SUBMIT_DATA";

export const RACK_DETAIL_EDIT_SET_STORAGE_NAME =
  "rackDetailEdit/SET_STORAGE_NAME";

export const initialStateRackDetailEdit = {
  isLoading: true,
  isFetchingStorageType: false,
  isFetchingStorageEnv: false,
  storageEnvironmentList: [],
  storageTypeList: [],
  isBusySubmittingData: false,
  formData: {
    storageType: 0,
    storageTypeName: "",
    storageEnvironment: 0,
    storageEnvironmentName: "",
    maxVolume: 0,
    maxVolumeUom: "",
    maxQuantity: 0,
    maxQuantityUom: "",
    maxWeight: 0,
    maxWeightUom: "",
    mixAttribute: false,
    mixProduct: false,
    active: true,
    racks: [
      {
        id: 0,
        rack_name: "",
        position: 0,
        active: false,
        productId: 0,
        productSku: "",
        minReplenishQty: 0,
        maxReplenishQty: 0,
        replenishQty: 0,
      }
    ]
  }
};

const rackDetailEditReducer = createReducer({
  [RACK_DETAIL_EDIT_RESET]: () => initialStateRackDetailEdit,
  [RACK_DETAIL_EDIT_FETCH_SINGLE_RACK_BY_ID]: (state) => {
    state.isLoading = true;
  },
  [RACK_DETAIL_EDIT_FETCH_SINGLE_RACK_BY_ID_SUCCESS]: (state, action) => {
    state.isLoading = false;
    state.formData = action.payload;
  },
  [RACK_DETAIL_EDIT_FETCH_SINGLE_RACK_BY_ID_FAILURE]: (state, action) => {
    state.isLoading = false;
  },
  [RACK_DETAIL_EDIT_SET_IS_BUSY_SUBMIT_DATA]: (state, action) => {
    state.isBusySubmittingData = action.payload;
  },
  [RACK_DETAIL_EDIT_SET_STORAGE_NAME]: (state, action) => {
    const { type, name } = action.payload;
    if (type === "type") {
      state.formData.storageTypeName = name;
    } else if (type === "environment") {
      state.formData.storageEnvironmentName = name;
    }
  }
});

export default rackDetailEditReducer;

export const rackDetailEditSetIsBusySubmitData = (isBusy) => ({
  type: RACK_DETAIL_EDIT_SET_IS_BUSY_SUBMIT_DATA,
  payload: isBusy
});

export const rackDetailEditReset = () => ({
  type: RACK_DETAIL_EDIT_RESET
});

export const rackDetailEditFetchSingleRackById = () => ({
  type: RACK_DETAIL_EDIT_FETCH_SINGLE_RACK_BY_ID
});

export const rackDetailEditFetchSingleRackByIdSuccess = (data) => ({
  type: RACK_DETAIL_EDIT_FETCH_SINGLE_RACK_BY_ID_SUCCESS,
  payload: data
});

export const rackDetailEditFetchSingleRackByIdFailure = () => ({
  type: RACK_DETAIL_EDIT_FETCH_SINGLE_RACK_BY_ID_FAILURE
});
