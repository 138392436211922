import { createAsyncThunk } from '@reduxjs/toolkit'
import { getMasterVariant } from 'features/Product/services/mastervariant'
import {
  getBrands,
  getProductVariantDetail,
  getProducts,
  postProductVariant,
  putProductVariant,
} from 'features/Product/services/productVariant'
import { callErrorMsg } from 'helpers/errorMsg'
import { MasterVariant, AddProductVariant } from 'features/Product/@types'
import {
  mappingVariantBucket,
  setBrands,
  setLoadingVariantBucket,
  setProductOptions,
  setSelectedBrand,
  setSelectedVariant,
  setSubtitle,
  setVariantBucket,
  setVariantOptions,
  SLICE_NAME,
} from './slice'

export const fetchMasterVariant = createAsyncThunk(
  `${SLICE_NAME}/fetchMasterVariant`,
  async (query: Partial<MasterVariant.QueryType>, { dispatch }) => {
    try {
      const {
        data: { data },
      } = await getMasterVariant(query)

      dispatch(setVariantOptions(data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchBrands = createAsyncThunk(
  `${SLICE_NAME}/fetchBrands`,
  async (query: { name: string }, { dispatch }) => {
    try {
      const {
        data: {
          data: { content },
        },
      } = await getBrands({ limit: 20, ...query })

      dispatch(setBrands(content))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchProducts = createAsyncThunk(
  `${SLICE_NAME}/fetchProducts`,
  async (
    { name, index, brandIDs }: { name: string; index?: number; brandIDs: number },
    { dispatch },
  ) => {
    try {
      const {
        data: { data },
      } = await getProducts({ pageSize: 20, name, brandIDs })

      const payload = data.map((product) => ({
        ...product,
        nameForDisplay: `${product.productName} - ${product.productSkuNo}`,
      }))

      if (typeof index !== 'undefined') {
        dispatch(setProductOptions({ index, value: payload }))
      }
      return payload
    } catch (error) {
      callErrorMsg(error)
      return []
    }
  },
)

export const submitProductvariant = createAsyncThunk(
  `${SLICE_NAME}/submitProductvariant`,
  async (productvariantId: Nullable<number>, { getState }) => {
    const {
      addProductVariant: { subtitle, selectedBrand, variantList, variantBucket },
    } = getState() as StoreStateType
    const payload = {
      subtitle,
      brandId: selectedBrand?.brand_id || null,
      masterVariantIds: variantList.map((variant) => variant.variant?.id),
      productVariants: variantBucket
        .filter(({ product }) => product)
        .map((bucket) => {
          const updatedBucket = {
            id: bucket.id,
            isDefault: bucket.isDefault,
            productId: bucket.product?.productID,
            variantIds: bucket.variants.map((variant) => ({
              masterVariantId: variant.masterVariant.id,
              variantId: variant.variant.id,
            })),
          }

          if (!updatedBucket.id) {
            delete updatedBucket.id
          }

          return updatedBucket
        }),
    }

    try {
      let res = null
      if (productvariantId) {
        res = await putProductVariant(
          productvariantId,
          payload as AddProductVariant.AddDataPayloadType,
        )
      } else {
        res = await postProductVariant(payload as AddProductVariant.AddDataPayloadType)
      }

      return res?.data.message
    } catch (error) {
      callErrorMsg(error)
      return ''
    }
  },
)

export const fetchProductVariantDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchProductVariantDetail`,
  async (productVariantId: number, { dispatch, getState }) => {
    const {
      addProductVariant: { variantOptions },
    } = getState() as StoreStateType

    try {
      const {
        data: { data },
      } = await getProductVariantDetail(productVariantId)

      dispatch(
        setSelectedBrand({
          brand_id: data.brandId,
          brand_name: data.brandName,
        }),
      )
      dispatch(setSubtitle(data.subtitle))

      const masterVariantDetails = await Promise.all(
        data.masterVariantIds.map((id) => matchMasterVariantById(variantOptions, id)),
      )

      masterVariantDetails.forEach((masterVariantDetail, index) => {
        if (masterVariantDetail) {
          dispatch(setSelectedVariant({ index, value: masterVariantDetail }))
        }
      })

      dispatch(mappingVariantBucket())

      const {
        addProductVariant: { variantBucket },
      } = getState() as StoreStateType

      let productOptions: AddProductVariant.VariantBucketType['productOptions'] = []

      dispatch(setLoadingVariantBucket(true))

      productOptions = await dispatch(fetchProducts({ name: '', brandIDs: data.brandId })).unwrap()

      const setVariantBucketPayload = variantBucket.map((bucket) => {
        const updatedBucket = { ...bucket }
        const matchProductVariant = data.productVariants.find((item) =>
          bucket.variants.every(
            (bucketVariant, i) =>
              item.variants[i].masterVariantId === bucketVariant.masterVariant.id &&
              item.variants[i].variantId === bucketVariant.variant.id,
          ),
        )
        updatedBucket.productOptions = productOptions

        if (matchProductVariant) {
          const selectedProduct = {
            productID: matchProductVariant.productId,
            productName: matchProductVariant.productName || '-',
            productSkuNo: '',
            nameForDisplay: matchProductVariant.productName,
          }

          updatedBucket.id = matchProductVariant.id
          updatedBucket.isDefault = matchProductVariant.default
          updatedBucket.product = selectedProduct
          updatedBucket.productOptions = updatedBucket.productOptions.filter(
            ({ productID }) => productID !== selectedProduct.productID,
          )
          updatedBucket.productOptions.unshift(selectedProduct)
        }

        return updatedBucket
      })

      dispatch(setVariantBucket(setVariantBucketPayload))
      dispatch(setLoadingVariantBucket(false))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

const matchMasterVariantById = async (
  masterVariants: MasterVariant.MasterVariantType[],
  id: number,
) => masterVariants.find((masterVariant) => masterVariant.id === id)

export const mappingVariantBucketAndFetchProductOptions = createAsyncThunk(
  `${SLICE_NAME}/mappingVariantBucketAndFetchProductOptions`,
  async (_, { dispatch, getState }) => {
    const {
      addProductVariant: { variantList, selectedBrand },
    } = getState() as StoreStateType

    if (variantList.length) {
      let productOptions: AddProductVariant.VariantBucketType['productOptions'] = []

      dispatch(setLoadingVariantBucket(true))

      if (selectedBrand) {
        productOptions = await dispatch(
          fetchProducts({ name: '', brandIDs: selectedBrand?.brand_id as number }),
        ).unwrap()
      }

      const variantBucket = variantList.flatMap((list) => {
        if (list.variant) {
          return list.variant.variants.map((variant) => ({
            isDefault: false,
            isInvalidSelectedProduct: false,
            product: null,
            productOptions,
            variants: [
              { masterVariant: list.variant as AddProductVariant.ProductVariantType, variant },
            ],
          }))
        }

        return []
      })

      dispatch(setVariantBucket(variantBucket))
      dispatch(setLoadingVariantBucket(false))
    }
  },
)
