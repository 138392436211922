import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  GetConversionListRequestType,
  GetConversionListResponseType,
} from 'features/Product/services/conversionRule'
import { GetProductListV2ResponseType } from 'utils/apiList/productV2'

export type ConversionRuleItemType = GetConversionListResponseType & {
  uniqueId: string
}

export type SkuItemType = {
  uniqueId: string
  selectedSku: Nullable<
    Pick<
      GetProductListV2ResponseType['data'][number],
      'productID' | 'productName' | 'productSkuNo' | 'baseUom'
    >
  >
  qty: string | null
}

export type SkuType = 'skuOriginList' | 'skuDestinationList'

export type ConversionRuleInitialStateType = {
  isLoading: boolean
  queryParams: GetConversionListRequestType
  searchSku: string
  modalAddEditRule: {
    isOpen: boolean
    id: number
    type: 'add' | 'edit'
    conversionType: {
      name: string
      value: string
    }
    skuOriginList: SkuItemType[]
    skuDestinationList: SkuItemType[]
    searchedValue: string
    productList: GetProductListV2ResponseType['data']
    reference: string
  }
  conversionRuleList: ConversionRuleItemType[]
}

export const initialState: ConversionRuleInitialStateType = {
  isLoading: false,
  queryParams: {
    page_index: 1,
    page_size: 20,
  },
  searchSku: '',
  modalAddEditRule: {
    isOpen: false,
    type: 'add',
    id: 0,
    conversionType: {
      name: '',
      value: '',
    },
    skuOriginList: [
      {
        uniqueId: crypto.randomUUID(),
        selectedSku: null,
        qty: null,
      },
    ],
    skuDestinationList: [
      {
        uniqueId: crypto.randomUUID(),
        selectedSku: null,
        qty: null,
      },
    ],
    productList: [],
    searchedValue: '',
    reference: '',
  },
  conversionRuleList: [],
}

const conversionRuleSlice = createSlice({
  name: 'conversionRule',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setSearchSku: (state, action) => {
      state.searchSku = action.payload
    },
    setQueryParams: (
      state,
      action: PayloadAction<Partial<ConversionRuleInitialStateType['queryParams']>>,
    ) => {
      state.queryParams = { ...state.queryParams, ...action.payload }
    },
    setModalAddEditRule: (
      state,
      action: PayloadAction<Partial<ConversionRuleInitialStateType['modalAddEditRule']>>,
    ) => {
      state.modalAddEditRule = { ...state.modalAddEditRule, ...action.payload }
    },
    resetModalAddEditRule: (state) => {
      state.modalAddEditRule = initialState.modalAddEditRule
    },
    setConversionRuleList: (state, action: PayloadAction<ConversionRuleItemType[]>) => {
      state.conversionRuleList = action.payload
    },
    setSkuInModal: (
      state,
      action: PayloadAction<{
        index: number
        fieldName: SkuType
        data: Partial<SkuItemType>
      }>,
    ) => {
      const { fieldName, data, index } = action.payload
      state.modalAddEditRule[fieldName][index] = {
        ...state.modalAddEditRule[fieldName][index],
        ...data,
      }
    },
    resetQueryParamSearch: (state) => {
      state.searchSku = ''
      state.queryParams.page_index = 1
      if (state.queryParams?.sku_origin) state.queryParams.sku_origin = ''
      if (state.queryParams?.sku_destination) state.queryParams.sku_destination = ''
    },
  },
})

export const {
  setIsLoading,
  setQueryParams,
  setSearchSku,
  setModalAddEditRule,
  resetModalAddEditRule,
  setConversionRuleList,
  setSkuInModal,
  resetQueryParamSearch,
} = conversionRuleSlice.actions
export default conversionRuleSlice.reducer
