import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import TypeTemplateList from 'helpers/typeTemplateList'
import { DataPrintQRResponseType } from 'utils/apiList/freshPurchaseOrderInbound'
import type { GetPrintSTOResponseType, PrintStoItemType } from 'utils/apiList/stockOpname'
import { fetctDataPrintQR } from './printAreaThunk'

export interface DataPrintBarcodeStateType {
  expiryDate: number | null
  barcode: string | number
  productName: string
  printQRQTY: number
}

type StoItemType = PrintStoItemType & { uniqueId: string }

type PrintStoDataType = Omit<GetPrintSTOResponseType['data'], 'stockOpnameItems'> & {
  stockOpnameItems: StoItemType[]
}

type ModifierVariantsType = {
  id: number
  name: string
  modifier_variants: {
    id: number
    change_type: string
    name: string
  }[]
}

type PrintAstroKitchenLabelType = Nullable<
  {
    customer: string
    customerNote: string
    productName: string
    itemId: number
    orderId: string
    modifiers: ModifierVariantsType[]
    addOns: ModifierVariantsType[]
  }[]
>
interface PrintAreaStateType {
  isLoading: boolean
  typeTobePrinted: string
  dataPrintQR: DataPrintQRResponseType
  dataPrintBarcode: DataPrintBarcodeStateType
  printSTO: PrintStoDataType
  astroKitchenLabel: PrintAstroKitchenLabelType
}

interface DataPrintStockOpnameType {
  doc: 'BERITA_ACARA' | 'STO'
  data: PrintStoDataType
}

export const initialState: PrintAreaStateType = {
  isLoading: false,
  typeTobePrinted: '',
  dataPrintQR: {
    expiry_date: 0,
    qr_code: '',
    manufacture_date: null,
    produced_by: null,
    product_name: '',
    storage_notes: null,
    printQRQTY: 0,
  },
  dataPrintBarcode: { barcode: '', expiryDate: 0, printQRQTY: 0, productName: '' },
  printSTO: {
    id: 0,
    stockOpnameNumber: '',
    createdAt: 0,
    locationId: 0,
    locationName: '',
    assignName: '',
    stockOpnameItems: [],
    totalSkuRack: 0,
    typeReference: '',
  },
  astroKitchenLabel: null,
}

const printArea = createSlice({
  name: 'printArea',
  initialState,
  reducers: {
    reset: () => initialState,
    setTypeTobePrinted: (state, action: PayloadAction<string>) => {
      state.typeTobePrinted = action.payload
    },
    setDataPrintBarcode: (state, action: PayloadAction<DataPrintBarcodeStateType>) => {
      state.dataPrintBarcode = action.payload
      state.typeTobePrinted = TypeTemplateList.FPOPrintBarcode
      setTimeout(() => {
        window.print()
      })
    },
    setDataPrintStockOpname: (state, action: PayloadAction<DataPrintStockOpnameType>) => {
      state.typeTobePrinted =
        action.payload.doc === 'BERITA_ACARA'
          ? TypeTemplateList.StockOpnameBeritaAcara
          : TypeTemplateList.StockOpname

      if (action.payload.doc === 'STO') {
        const { data } = action.payload
        state.printSTO = data
      }

      setTimeout(() => {
        window.print()
      })
    },
    setDataKitchenModifierReceipt: (state, action: PayloadAction<PrintAstroKitchenLabelType>) => {
      state.astroKitchenLabel = action.payload
      state.typeTobePrinted = TypeTemplateList.KitchenModifierReceipt
      setTimeout(() => {
        window.print()
      })
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetctDataPrintQR.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetctDataPrintQR.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: DataPrintQRResponseType
            printQRQTY: number
          }>,
        ) => {
          const { printQRQTY, data } = action.payload
          state.dataPrintQR = data
          state.dataPrintQR.printQRQTY = printQRQTY
          state.typeTobePrinted = TypeTemplateList.PrintQRV2
          state.isLoading = false
          setTimeout(() => {
            window.print()
          })
        },
      )
      .addCase(fetctDataPrintQR.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const {
  reset,
  setTypeTobePrinted,
  setDataPrintBarcode,
  setDataPrintStockOpname,
  setDataKitchenModifierReceipt,
} = printArea.actions
export default printArea.reducer
