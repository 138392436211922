export const AUTH_ADMIN = 'AUTH_ADMIN'

export const AUTH_QUALITY_ENGINEERING = 'AUTH_QUALITY_ENGINEERING'
export const AUTH_PRODUCT_MANAGER = 'AUTH_PRODUCT_MANAGER'
export const AUTH_ADMIN_VIEW_ONLY = 'AUTH_ADMIN_VIEW_ONLY'

// WAREHOUSE LIST
export const AUTH_CENTRAL_SUPERVISOR = 'AUTH_CENTRAL_SUPERVISOR'
export const AUTH_INBOUND_STAFF = 'AUTH_INBOUND_STAFF'
export const AUTH_OUTBOUND_STAFF = 'AUTH_OUTBOUND_STAFF'
export const AUTH_CUSTOMER_SERVICE = 'AUTH_CUSTOMER_SERVICE'
export const AUTH_CS_CAPTAIN = 'AUTH_CS_CAPTAIN'
export const AUTH_MERCHANDISING_ADMIN = 'AUTH_MERCHANDISING_ADMIN'
export const AUTH_FINANCE_ADMIN = 'AUTH_FINANCE_ADMIN'
export const AUTH_HO_FINANCE = 'AUTH_HO_FINANCE'
export const AUTH_MARKETING = 'AUTH_MARKETING'
export const AUTH_MERCHANDISING_WAREHOUSE = 'AUTH_MERCHANDISING_WAREHOUSE'
export const AUTH_HO_CS_LEADER = 'AUTH_HO_CS_LEADER'

// HUB LIST
export const AUTH_PICKER = 'AUTH_PICKER'
export const AUTH_PACKER = 'AUTH_PACKER'
export const AUTH_SUPER_PICKER = 'AUTH_SUPER_PICKER'
export const AUTH_SUPER_PACKER = 'AUTH_SUPER_PACKER'
export const AUTH_SUPER_DRIVER = 'AUTH_SUPER_DRIVER'
export const AUTH_CAPTAIN = 'AUTH_CAPTAIN'
export const AUTH_MERCHANDISING_HUB = 'AUTH_MERCHANDISING_HUB'
export const AUTH_DRIVER_COORDINATOR = 'AUTH_DRIVER_COORDINATOR'
export const AUTH_SPV_HUB = 'AUTH_SPV_HUB'

// GLOBAL LIST
export const AUTH_PRICING = 'AUTH_PRICING'
export const AUTH_SUPPLY_CHAIN = 'AUTH_SUPPLY_CHAIN'
export const AUTH_FRAUD = 'AUTH_FRAUD'
export const AUTH_SOURCING = 'AUTH_SOURCING'
export const AUTH_EXPANSION = 'AUTH_EXPANSION'
export const AUTH_INVENTORY_CONTROLLER = 'AUTH_INVENTORY_CONTROLLER'
export const AUTH_SOURCING_MANAGER = 'AUTH_SOURCING_MANAGER'
export const AUTH_PROCUREMENT = 'AUTH_PROCUREMENT'
export const AUTH_QA_FRESH_INBOUND = 'AUTH_QA_FRESH_INBOUND'
export const AUTH_QA_FRESH_OUTBOUND = 'AUTH_QA_FRESH_OUTBOUND'
export const AUTH_FRESH_PROCESSING = 'AUTH_FRESH_PROCESSING'
export const AUTH_LP = 'AUTH_LP'
export const AUTH_SHIFT_LEAD = 'AUTH_SHIFT_LEAD'
export const AUTH_STOCK_OPNAME = 'AUTH_STOCK_OPNAME'
export const AUTH_ADJUSTMENT = 'AUTH_ADJUSTMENT'
export const AUTH_AREA_MANAGER = 'AUTH_AREA_MANAGER'
export const AUTH_STO_VIEW_ONLY = 'AUTH_STO_VIEW_ONLY'
export const AUTH_CENTRAL_MANAGER = 'AUTH_CENTRAL_MANAGER'
export const AUTH_WH_MANAGER = 'AUTH_WH_MANAGER'
export const AUTH_INBOUND_CHECKER = 'AUTH_INBOUND_CHECKER'
export const AUTH_DRIVER = 'AUTH_DRIVER'
export const AUTH_STOCK_COUNT_AUDITOR = 'AUTH_STOCK_COUNT_AUDITOR'
export const AUTH_TL_FLEET = 'AUTH_TL_FLEET'
export const AUTH_ADMIN_FLEET = 'AUTH_ADMIN_FLEET'

export const AUTH_CHECKER = 'AUTH_CHECKER'
export const AUTH_OUTBOUND_CHECKER = 'AUTH_OUTBOUND_CHECKER'
export const AUTH_OUTBOUND_PACKER = 'AUTH_OUTBOUND_PACKER'
export const AUTH_OUTBOUND_PICKER = 'AUTH_OUTBOUND_PICKER'
export const AUTH_HO_CS_REFUND = 'AUTH_HO_CS_REFUND'

export const AUTH_HO_TAX = 'AUTH_HO_TAX'

export const AUTH_HO_HR = 'AUTH_HO_HR'
export const AUTH_CS_AGENT = 'AUTH_HO_CS_AGENT'

export const AUTH_HUB_AREA_MANAGER = 'AUTH_HUB_AREA_MANAGER'
export const AUTH_HUB_AREA_MANAGER_VIEWONLY = 'AUTH_HUB_AREA_MANAGER_VIEWONLY'
export const AUTH_HUB_INV_MANAGER = 'AUTH_HUB_INV_MANAGER'
export const AUTH_HUB_INV_MANAGER_VIEWONLY = 'AUTH_HUB_INV_MANAGER_VIEWONLY'
export const AUTH_HUB_INV_SPV = 'AUTH_HUB_INV_SPV'
export const AUTH_HUB_INV_STAFF = 'AUTH_HUB_INV_STAFF'
export const AUTH_QA_MANAGER = 'AUTH_QA_MANAGER'
export const AUTH_QA_SUPERVISOR = 'AUTH_QA_SUPERVISOR'
export const AUTH_QA_STAFF = 'AUTH_QA_STAFF'
export const AUTH_SUPER_AREA_MANAGER = 'AUTH_SUPER_AREA_MANAGER'
export const AUTH_WH_MANAGER_VIEWONLY = 'AUTH_WH_MANAGER_VIEWONLY'
export const AUTH_WH_INBOUND_SPV = 'AUTH_WH_INBOUND_SPV'
export const AUTH_WH_INBOUND_STAFF = 'AUTH_WH_INBOUND_STAFF'
export const AUTH_WH_OUTBOUND_SPV = 'AUTH_WH_OUTBOUND_SPV'
export const AUTH_WH_INV_MANAGER = 'AUTH_WH_INV_MANAGER'
export const AUTH_WH_INV_MANAGER_VIEWONLY = 'AUTH_WH_INV_MANAGER_VIEWONLY'
export const AUTH_WH_INV_SPV = 'AUTH_WH_INV_SPV'
export const AUTH_WH_INV_STAFF = 'AUTH_WH_INV_STAFF'
export const AUTH_HO_MERCHANDISING = 'AUTH_HO_MERCHANDISING'
export const AUTH_HO_ADJUSTMENT = 'AUTH_HO_ADJUSTMENT'
export const AUTH_HO_STOCK_COUNT_AUDITOR = 'AUTH_HO_STOCK_COUNT_AUDITOR'
export const AUTH_HO_GA = 'AUTH_HO_GA'
export const AUTH_HO_CAMPAIGN = 'AUTH_HO_CAMPAIGN'
export const AUTH_HO_PARTNERSHIP = 'AUTH_HO_PARTNERSHIP'
export const AUTH_HO_ASTRO_KITCHEN_MANAGER = 'AUTH_HO_ASTRO_KITCHEN_MANAGER'
export const AUTH_HO_ASTRO_KITCHEN_STAFF = 'AUTH_HO_ASTRO_KITCHEN_STAFF'
export const AUTH_HO_EXPANSION = 'AUTH_HO_EXPANSION'
export const AUTH_HO_LP = 'AUTH_HO_LP'
export const AUTH_HO_SUPPLY_CHAIN = 'AUTH_HO_SUPPLY_CHAIN'
export const AUTH_HO_PRODUCT_MANAGER = 'AUTH_HO_PRODUCT_MANAGER'
export const AUTH_HO_PRODUCT_HEAD = 'AUTH_HO_PRODUCT_HEAD'
export const AUTH_HO_QUALITY_ENGINEERING = 'AUTH_HO_QUALITY_ENGINEERING'
export const AUTH_HO_OPX_MID_MILE = 'AUTH_HO_OPX_MID_MILE'
export const AUTH_HO_OPS_MID_MILE_ADMIN = 'AUTH_HO_OPS_MID_MILE_ADMIN'
export const AUTH_HO_OPX_HUB = 'AUTH_HO_OPX_HUB'
export const AUTH_HO_OPS_MID_MILE_SPV = 'AUTH_HO_OPS_MID_MILE_SPV'
export const AUTH_HO_FRAUD = 'AUTH_HO_FRAUD'
export const AUTH_HUB_SPV = 'AUTH_HUB_SPV'
export const AUTH_HO_MARKETING = 'AUTH_HO_MARKETING'
export const AUTH_OFFLINE_MARKETING = 'AUTH_OFFLINE_MARKETING'
export const AUTH_HO_ASTRO_KITCHEN_SOURCING = 'AUTH_HO_ASTRO_KITCHEN_SOURCING'
export const AUTH_HO_ASTRO_KITCHEN_SUPPLY_CHAIN = 'AUTH_HO_ASTRO_KITCHEN_SUPPLY_CHAIN'
export const AUTH_HO_IA_HEAD = 'AUTH_HO_IA_HEAD'
export const AUTH_HO_IA_MANAGER = 'AUTH_HO_IA_MANAGER'
export const AUTH_HO_IA_SPV = 'AUTH_HO_IA_SPV'
export const AUTH_HO_IA_STAFF = 'AUTH_HO_IA_STAFF'

// Temporary Role sampai AUTH_HUB_AREA_MANAGER selesai di development
export const AUTH_TEMP_MASTER_APPROVAL = 'AUTH_TEMP_MASTER_APPROVAL'

export const AUTH_HR_LEAD = 'AUTH_HR_LEAD'
export const AUTH_EXT_TICKET = 'AUTH_EXT_TICKET'

export const AUTH_HO_SOURCING_MANAGERIAL_LEVEL = 'AUTH_HO_SOURCING_MANAGERIAL_LEVEL'
export const AUTH_HO_PROCUREMENT = 'AUTH_HO_PROCUREMENT'
export const AUTH_HUB_SHIFT_LEAD = 'AUTH_HUB_SHIFT_LEAD'

export const AUTH_HO_CS_SHIFT_LEAD = 'AUTH_HO_CS_SHIFT_LEAD'
export const AUTH_HO_CS_SPV = 'AUTH_HO_CS_SPV'
export const AUTH_HO_OPX_HUB_MANAGER = 'AUTH_HO_OPX_HUB_MANAGER'
export const AUTH_HO_OPX_HUB_SPV = 'AUTH_HO_OPX_HUB_SPV'
export const AUTH_HO_OPX_HUB_STAFF = 'AUTH_HO_OPX_HUB_STAFF'
export const AUTH_HO_OPX_HUB_SHIFT_LEAD = 'AUTH_HO_OPX_HUB_SHIFT_LEAD'
export const AUTH_HO_OPX_WH_MANAGER = 'AUTH_HO_OPX_WH_MANAGER'
export const AUTH_HO_OPX_WH_SPV = 'AUTH_HO_OPX_WH_SPV'
export const AUTH_HO_OPX_WH_STAFF = 'AUTH_HO_OPX_WH_STAFF'

export const AUTH_SUPER_INBOUND_STAFF = 'AUTH_SUPER_INBOUND_STAFF'
export const AUTH_HUB_INBOUND_STAFF = 'AUTH_HUB_INBOUND_STAFF'
export const AUTH_INTERNAL_SALES = 'AUTH_INTERNAL_SALES'
export const AUTH_SUPER_SPV = 'AUTH_SUPER_SPV'
export const AUTH_SUPER_SHIFT_LEAD = 'AUTH_SUPER_SHIFT_LEAD'
export const AUTH_HO_SOURCING_ADMIN = 'AUTH_HO_SOURCING_ADMIN'

export const AUTH_HO_IT = 'AUTH_HO_IT'

export const AUTH_WH_OUTBOUND_ADMIN = 'AUTH_WH_OUTBOUND_ADMIN'
export const AUTH_WH_OUTBOUND_PICKER = 'AUTH_WH_OUTBOUND_PICKER'
export const AUTH_WH_OUTBOUND_PACKER_CHECKER = 'AUTH_WH_OUTBOUND_PACKER_CHECKER'
export const AUTH_WH_OUTBOUND_LOADER_SHIFT_LEAD = 'AUTH_WH_OUTBOUND_LOADER_SHIFT_LEAD'
export const AUTH_WH_OUTBOUND_LOADER_STAFF = 'AUTH_WH_OUTBOUND_LOADER_STAFF'
export const AUTH_WH_INV_ADMIN = 'AUTH_WH_INV_ADMIN'
export const AUTH_HO_PRICING_HEAD = 'AUTH_HO_PRICING_HEAD'
export const AUTH_HO_PRICING_MANAGER = 'AUTH_HO_PRICING_MANAGER'
export const AUTH_HO_PRICING_SPV = 'AUTH_HO_PRICING_SPV'
export const AUTH_HO_PRICING_STAFF = 'AUTH_HO_PRICING_STAFF'
export const AUTH_HO_CATEGORY_HEAD_PL_FOOD = 'AUTH_HO_CATEGORY_HEAD_PL_FOOD'
export const AUTH_HO_CATEGORY_MANAGER_PL_FOOD = 'AUTH_HO_CATEGORY_MANAGER_PL_FOOD'
export const AUTH_HO_CATEGORY_SPV_PL_FOOD = 'AUTH_HO_CATEGORY_SPV_PL_FOOD'
export const AUTH_HO_CATEGORY_STAFF_PL_FOOD = 'AUTH_HO_CATEGORY_STAFF_PL_FOOD'
export const AUTH_HO_CATEGORY_HEAD_PL_NON_FOOD = 'AUTH_HO_CATEGORY_HEAD_PL_NON_FOOD'
export const AUTH_HO_CATEGORY_MANAGER_PL_NON_FOOD = 'AUTH_HO_CATEGORY_MANAGER_PL_NON_FOOD'
export const AUTH_HO_CATEGORY_SPV_PL_NON_FOOD = 'AUTH_HO_CATEGORY_SPV_PL_NON_FOOD'
export const AUTH_HO_CATEGORY_STAFF_PL_NON_FOOD = 'AUTH_HO_CATEGORY_STAFF_PL_NON_FOOD'
export const AUTH_HO_CUSTOMER_GROWTH_CONTROLLER = 'AUTH_HO_CUSTOMER_GROWTH_CONTROLLER'
export const AUTH_HO_LEGAL = 'AUTH_HO_LEGAL'
export const AUTH_HO_FINANCE_MANAGER = 'AUTH_HO_FINANCE_MANAGER'
export const AUTH_HO_FINANCE_AP_SPV = 'AUTH_HO_FINANCE_AP_SPV'
export const AUTH_HO_FINANCE_AR_SPV = 'AUTH_HO_FINANCE_AR_SPV'
export const AUTH_HO_SOURCING_MANAGER = 'AUTH_HO_SOURCING_MANAGER'

export const AUTH_ASTRO_GO_MANAGER = 'AUTH_ASTRO_GO_MANAGER'
export const AUTH_ASTRO_GO_SPV = 'AUTH_ASTRO_GO_SPV'
export const AUTH_ASTRO_GO_SHIFT_LEAD = 'AUTH_ASTRO_GO_SHIFT_LEAD'
export const AUTH_ASTRO_GO_STAFF = 'AUTH_ASTRO_GO_STAFF'
export const HO_ASTRO_KITCHEN_SUPPLY_CHAIN = 'HO_ASTRO_KITCHEN_SUPPLY_CHAIN'
export const HO_SUPPLY_CHAIN_HEAD = 'HO_SUPPLY_CHAIN_HEAD'
export const HO_SUPPLY_CHAIN_MANAGER = 'HO_SUPPLY_CHAIN_MANAGER'
export const HO_SUPPLY_CHAIN_SPV = 'HO_SUPPLY_CHAIN_SPV'
export const HO_SUPPLY_CHAIN_STAFF = 'HO_SUPPLY_CHAIN_STAFF'
export const AUTH_HO_SUPPLY_CHAIN_HEAD = 'AUTH_HO_SUPPLY_CHAIN_HEAD'
export const AUTH_HO_SUPPLY_CHAIN_MANAGER = 'AUTH_HO_SUPPLY_CHAIN_MANAGER'
export const AUTH_HO_SUPPLY_CHAIN_SPV = 'AUTH_HO_SUPPLY_CHAIN_SPV'
export const AUTH_HO_SUPPLY_CHAIN_STAFF = 'AUTH_HO_SUPPLY_CHAIN_STAFF'

export const AUTH_VENDOR_SUBMISSION_APPROVAL_1 = 'AUTH_VENDOR_SUBMISSION_APPROVAL_1'
export const AUTH_VENDOR_SUBMISSION_APPROVAL_2 = 'AUTH_VENDOR_SUBMISSION_APPROVAL_2'
export const AUTH_VENDOR_SUBMISSION_APPROVAL_3 = 'AUTH_VENDOR_SUBMISSION_APPROVAL_3'
export const AUTH_VENDOR_SUBMISSION_APPROVAL_4 = 'AUTH_VENDOR_SUBMISSION_APPROVAL_4'

export const AUTH_ADMIN_XDOCK = 'AUTH_ADMIN_XDOCK'
export const AUTH_WH_SECURITY = 'AUTH_WH_SECURITY'
export const AUTH_HO_SOURCING_HEAD = 'AUTH_HO_SOURCING_HEAD'

export const roles = {
  AUTH_ADMIN,
  AUTH_PACKER,
  AUTH_PICKER,
  AUTH_ADJUSTMENT,
  AUTH_ADMIN_FLEET,
  AUTH_ADMIN_VIEW_ONLY,
  AUTH_AREA_MANAGER,
  AUTH_CAPTAIN,
  AUTH_CENTRAL_MANAGER,
  AUTH_CENTRAL_SUPERVISOR,
  AUTH_CHECKER,
  AUTH_CS_AGENT,
  AUTH_CS_CAPTAIN,
  AUTH_CUSTOMER_SERVICE,
  AUTH_DRIVER,
  AUTH_DRIVER_COORDINATOR,
  AUTH_EXPANSION,
  AUTH_FINANCE_ADMIN,
  AUTH_FRAUD,
  AUTH_FRESH_PROCESSING,
  AUTH_HO_CS_LEADER,
  AUTH_HO_CS_REFUND,
  AUTH_HO_HR,
  AUTH_HO_TAX,
  AUTH_HR_LEAD,
  AUTH_INBOUND_CHECKER,
  AUTH_INBOUND_STAFF,
  AUTH_QUALITY_ENGINEERING,
  AUTH_PRODUCT_MANAGER,
  AUTH_OUTBOUND_STAFF,
  AUTH_MERCHANDISING_ADMIN,
  AUTH_MARKETING,
  AUTH_MERCHANDISING_WAREHOUSE,
  AUTH_SUPER_PICKER,
  AUTH_SUPER_PACKER,
  AUTH_SUPER_DRIVER,
  AUTH_MERCHANDISING_HUB,
  AUTH_SPV_HUB,
  AUTH_PRICING,
  AUTH_SUPPLY_CHAIN,
  AUTH_SOURCING,
  AUTH_INVENTORY_CONTROLLER,
  AUTH_SOURCING_MANAGER,
  AUTH_PROCUREMENT,
  AUTH_QA_FRESH_INBOUND,
  AUTH_QA_FRESH_OUTBOUND,
  AUTH_LP,
  AUTH_SHIFT_LEAD,
  AUTH_STOCK_OPNAME,
  AUTH_STO_VIEW_ONLY,
  AUTH_WH_MANAGER,
  AUTH_STOCK_COUNT_AUDITOR,
  AUTH_TL_FLEET,
  AUTH_OUTBOUND_CHECKER,
  AUTH_OUTBOUND_PACKER,
  AUTH_OUTBOUND_PICKER,
  AUTH_HO_ASTRO_KITCHEN_MANAGER,
  AUTH_HO_ASTRO_KITCHEN_STAFF,
  AUTH_EXT_TICKET,
  AUTH_HO_LP,
  AUTH_HUB_INV_SPV,
  AUTH_HO_CAMPAIGN,
  AUTH_HO_PARTNERSHIP,
  AUTH_HO_PRODUCT_MANAGER,
  AUTH_HO_PRODUCT_HEAD,
  AUTH_HO_MARKETING,
  AUTH_HO_QUALITY_ENGINEERING,
  AUTH_HO_FINANCE,
  AUTH_HUB_AREA_MANAGER,
  AUTH_HUB_AREA_MANAGER_VIEWONLY,
  AUTH_HUB_INV_MANAGER,
  AUTH_HUB_INV_MANAGER_VIEWONLY,
  AUTH_HUB_INV_STAFF,
  AUTH_QA_MANAGER,
  AUTH_QA_SUPERVISOR,
  AUTH_QA_STAFF,
  AUTH_SUPER_AREA_MANAGER,
  AUTH_WH_MANAGER_VIEWONLY,
  AUTH_WH_INBOUND_SPV,
  AUTH_WH_INBOUND_STAFF,
  AUTH_WH_OUTBOUND_SPV,
  AUTH_WH_INV_MANAGER,
  AUTH_WH_INV_MANAGER_VIEWONLY,
  AUTH_WH_INV_SPV,
  AUTH_WH_INV_STAFF,
  AUTH_HO_MERCHANDISING,
  AUTH_HO_ADJUSTMENT,
  AUTH_HO_STOCK_COUNT_AUDITOR,
  AUTH_HO_GA,
  AUTH_HO_EXPANSION,
  AUTH_HO_SUPPLY_CHAIN,
  AUTH_HO_OPX_MID_MILE,
  AUTH_HO_OPS_MID_MILE_ADMIN,
  AUTH_HO_OPX_HUB,
  AUTH_HO_OPS_MID_MILE_SPV,
  AUTH_HO_FRAUD,
  AUTH_HUB_SPV,
  AUTH_OFFLINE_MARKETING,
  AUTH_TEMP_MASTER_APPROVAL,
  AUTH_HO_SOURCING_MANAGERIAL_LEVEL,
  AUTH_HO_PROCUREMENT,
  AUTH_HO_ASTRO_KITCHEN_SOURCING,
  AUTH_HO_ASTRO_KITCHEN_SUPPLY_CHAIN,
  AUTH_HO_CS_SHIFT_LEAD,
  AUTH_HO_CS_SPV,
  AUTH_HUB_SHIFT_LEAD,
  AUTH_HO_IT,
  AUTH_HO_IA_HEAD,
  AUTH_HO_IA_MANAGER,
  AUTH_HO_IA_SPV,
  AUTH_HO_IA_STAFF,
  AUTH_WH_OUTBOUND_ADMIN,
  AUTH_WH_OUTBOUND_PICKER,
  AUTH_WH_OUTBOUND_PACKER_CHECKER,
  AUTH_WH_OUTBOUND_LOADER_SHIFT_LEAD,
  AUTH_WH_OUTBOUND_LOADER_STAFF,
  AUTH_WH_INV_ADMIN,
  AUTH_ASTRO_GO_MANAGER,
  AUTH_ASTRO_GO_SPV,
  AUTH_ASTRO_GO_SHIFT_LEAD,
  AUTH_ASTRO_GO_STAFF,
  AUTH_HO_OPX_HUB_MANAGER,
  AUTH_HO_OPX_HUB_SPV,
  AUTH_HO_OPX_HUB_STAFF,
  AUTH_HO_OPX_HUB_SHIFT_LEAD,
  AUTH_HO_OPX_WH_MANAGER,
  AUTH_HO_OPX_WH_SPV,
  AUTH_HO_OPX_WH_STAFF,
  AUTH_HO_PRICING_HEAD,
  AUTH_HO_PRICING_MANAGER,
  AUTH_HO_PRICING_SPV,
  AUTH_HO_PRICING_STAFF,
  AUTH_HO_CATEGORY_HEAD_PL_FOOD,
  AUTH_HO_CATEGORY_MANAGER_PL_FOOD,
  AUTH_HO_CATEGORY_SPV_PL_FOOD,
  AUTH_HO_CATEGORY_STAFF_PL_FOOD,
  AUTH_HO_CATEGORY_HEAD_PL_NON_FOOD,
  AUTH_HO_CATEGORY_MANAGER_PL_NON_FOOD,
  AUTH_HO_CATEGORY_SPV_PL_NON_FOOD,
  AUTH_HO_CATEGORY_STAFF_PL_NON_FOOD,
  AUTH_HO_CUSTOMER_GROWTH_CONTROLLER,
  AUTH_HO_SOURCING_ADMIN,
  AUTH_HO_LEGAL,
  AUTH_HO_FINANCE_MANAGER,
  AUTH_HO_FINANCE_AP_SPV,
  AUTH_HO_FINANCE_AR_SPV,
  AUTH_HO_SOURCING_MANAGER,
  HO_ASTRO_KITCHEN_SUPPLY_CHAIN,
  HO_SUPPLY_CHAIN_HEAD,
  HO_SUPPLY_CHAIN_MANAGER,
  HO_SUPPLY_CHAIN_SPV,
  HO_SUPPLY_CHAIN_STAFF,
  AUTH_HO_SUPPLY_CHAIN_HEAD,
  AUTH_HO_SUPPLY_CHAIN_MANAGER,
  AUTH_HO_SUPPLY_CHAIN_SPV,
  AUTH_HO_SUPPLY_CHAIN_STAFF,
  AUTH_VENDOR_SUBMISSION_APPROVAL_1,
  AUTH_VENDOR_SUBMISSION_APPROVAL_2,
  AUTH_VENDOR_SUBMISSION_APPROVAL_3,
  AUTH_VENDOR_SUBMISSION_APPROVAL_4,
  AUTH_ADMIN_XDOCK,
  AUTH_WH_SECURITY,
  AUTH_HO_SOURCING_HEAD,
} as const

export const rolesWhichCanAccessAllPage = [
  roles.AUTH_ADMIN,
  roles.AUTH_QUALITY_ENGINEERING,
  roles.AUTH_PRODUCT_MANAGER,
  roles.AUTH_ADMIN_VIEW_ONLY,
]

export type RolesType = typeof roles
export type RolesKeyType = keyof typeof roles
