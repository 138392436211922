import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import {
  getDataAndStatusAstroKitchenOrder,
  getScheduleCloudKitchenProductHub,
  postAuthKitchenStaff,
  postDataAndCompleteAstroKitchen,
  postScheduleCloudKitchenProductHub,
  putDataUpdateItemOrderKitchen,
} from 'utils/apiList/cloudKitchen'
import { callErrorMsg } from 'helpers/errorMsg'
import { days } from 'constant/daysMapping'
import { toastFailed } from 'utils/toast'
import { postValidateUserLocation } from 'utils/apiList/auth'

const initialState = {
  isLoading: false,
  isOpenCloudKitchenSetSchedule: false,
  detailOrderSchedule: {},
  refetchOrderAstroKitchen: false,
}

const cloudKitchen = createSlice({
  name: 'cloudKitchen',
  initialState,
  reducers: {
    reset: () => initialState,
    setOpenCloudKitchenSetScheduleDialog: (state, action) => {
      state.isOpenCloudKitchenSetSchedule = action.payload
    },
    setDetailOrderSchedule: (state, action) => {
      state.detailOrderSchedule = action.payload
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
    deleteScheduleByDay: (state, action) => {
      const dayID = action.payload
      const { schedules } = state.detailOrderSchedule
      state.detailOrderSchedule.schedules = schedules.filter((each) => each.day !== dayID)
    },
    setTime: (state, action) => {
      const { keyNameTime, dayID, value } = action.payload
      const { schedules } = state.detailOrderSchedule
      const index = schedules.findIndex((each) => each.day === dayID)
      if (index !== -1) {
        state.detailOrderSchedule.schedules[index][keyNameTime] = value
        return
      }
      state.detailOrderSchedule.schedules.push({
        day: dayID,
        [keyNameTime]: value,
        allDay: false,
      })
    },
    setOpen24Hours: (state, action) => {
      const { dayID, value } = action.payload
      const { schedules } = state.detailOrderSchedule
      const index = schedules.findIndex((each) => each.day === dayID)
      if (index !== -1) {
        const { openTime, closeTime } = schedules[index]
        if (!openTime && !closeTime && !value) {
          state.detailOrderSchedule.schedules = schedules.filter((each) => each.day !== dayID)
          return
        }
        state.detailOrderSchedule.schedules[index].allDay = value
        return
      }
      state.detailOrderSchedule.schedules.push({
        day: dayID,
        openTime: '',
        closeTime: '',
        allDay: value,
      })
    },
    setRefetchOrderAstroKitchen: (state, action) => {
      state.refetchOrderAstroKitchen = action.payload
    },
  },
})

export const {
  setOpenCloudKitchenSetScheduleDialog,
  setDetailOrderSchedule,
  setTime,
  setOpen24Hours,
  setLoading,
  deleteScheduleByDay,
  setRefetchOrderAstroKitchen,
} = cloudKitchen.actions

export const getDetailProductHubAndOpenCloudKitchenSchedule = createAsyncThunk(
  'cloudKitchen/getDetailProductHubAndOpenCloudKitchenSchedule',
  async (payload, { dispatch }) => {
    try {
      const { inventoryStatus, ...rest } = payload
      dispatch(setLoading(true))
      const {
        data: { data },
      } = await getScheduleCloudKitchenProductHub(rest)
      dispatch(setDetailOrderSchedule({ ...data, inventoryStatus }))
      dispatch(setOpenCloudKitchenSetScheduleDialog(true))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

const validateScheduleBeforeCreate = (schedules, inventoryStatus) => {
  if (!schedules.length && inventoryStatus) {
    toastFailed('Anda belum menambahkan jadwal cloud kitchen')
    return false
  }
  const errorSchedule = schedules.find((each) => {
    const { allDay, openTime, closeTime } = each
    if ((!openTime || !closeTime) && !allDay) {
      return each
    }
  })
  if (errorSchedule) {
    const { day } = errorSchedule
    const name = days.find((each) => each.id === day)?.name
    toastFailed(
      `Terjadi kesalahan jadwal pada hari ${name}, jam buka & jam tutup harus diisi atau aktifkan buka 24 jam`,
    )
    return false
  }
  return true
}

export const createScheduleCloudKitchen = createAsyncThunk(
  'cloudKitchen/createScheduleCloudKitchen',
  async (_, { dispatch, getState }) => {
    const { detailOrderSchedule } = getState().cloudKitchen
    const { schedules, inventoryStatus } = detailOrderSchedule
    if (validateScheduleBeforeCreate(schedules, inventoryStatus)) {
      try {
        dispatch(setLoading(true))
        await postScheduleCloudKitchenProductHub({
          ...detailOrderSchedule,
        })
        dispatch(setOpenCloudKitchenSetScheduleDialog(false))
      } catch (error) {
        callErrorMsg(error)
      } finally {
        dispatch(setLoading(false))
      }
    }
  },
)

export const fetchDataAndStatusAstroKitchenOrder = createAsyncThunk(
  'cloudKitchen/fetchDataAndStatusAstroKitchenOrder',
  async (orderId, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const { data } = await getDataAndStatusAstroKitchenOrder(orderId)
      return data.data
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const updateFulfilQuantityItemKitchen = createAsyncThunk(
  'cloudKitchen/updateFulfilQuantityItemKitchen',
  async (payload, { dispatch, fulfillWithValue }) => {
    try {
      dispatch(setLoading(true))
      const { orderId, ...restPayload } = payload
      const { data } = await putDataUpdateItemOrderKitchen(orderId, restPayload)
      return data
    } catch (err) {
      callErrorMsg(err)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const submitDataAndChangeStatusOrderAstroKitchen = createAsyncThunk(
  'cloudKitchen/submitDataAndChangeStatusOrderAstroKitchen',
  /**
   * define type to cater ts checker
   * @param {Object} payload
   * @param {string} payload.note
   * @param {orderId} payload.orderId
   */
  async (payload, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const { orderId, ...restPayload } = payload
      const { data } = await postDataAndCompleteAstroKitchen(orderId, restPayload)
      return data
    } catch (err) {
      callErrorMsg(err)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const submitCredentialKitchenStaff = createAsyncThunk(
  'cloudKitchen/submitDataAndChangeStatusOrderAstroKitchen',
  /**
   * define type to cater ts checker
   * @param {Object} payload
   * @param {string} payload.approval_email
   */
  async (payload, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const { data } = await postAuthKitchenStaff(payload)
      return data
    } catch (err) {
      callErrorMsg(err)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const submitCredentialKitchenStaffv2 = createAsyncThunk(
  'cloudKitchen/submitDataAndChangeStatusOrderAstroKitchen',
  /**
   * @param {Object} payload
   * @param {string} payload.destination_method_uri
   * @param {string} payload.destination_uri
   * @param {string} payload.location_id
   * @param {string} payload.phone_number
   * @param {string} payload.pin
   */
  async (payload, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const { data } = await postValidateUserLocation(payload)
      return data
    } catch (err) {
      toastFailed('Nomer telepon atau PIN salah. mohon dicoba kembali')
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export default cloudKitchen.reducer
