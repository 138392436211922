import { Box, Typography, Chip } from '@astro-ui/components'

export type RenderMultipleValueType = {
  value: string[]
  placeholder?: string
  multipleProps?: {
    valueType?: 'chip' | 'total'
    suffixTotal?: string
    hideSelectAll?: boolean
    selectAllText?: string
  }
  onDelete: (_: string) => void
}

const RenderMultipleValue = ({
  value,
  multipleProps,
  placeholder,
  onDelete,
}: RenderMultipleValueType) => {
  if (!value.length)
    return (
      <Typography color={!value.length ? 'grey2' : 'grey9'} variant="body2">
        {placeholder}
      </Typography>
    )

  if (multipleProps?.valueType === 'total') {
    return (
      <Typography color="grey9" variant="body2">
        {`${value.length} ${multipleProps?.suffixTotal || 'Selected'}`}
      </Typography>
    )
  }

  return (
    <Box display="flex" flexWrap="wrap" gap="4px">
      {value.map((name) => (
        <Chip
          key={name}
          label={name}
          p="0 4px"
          size="small"
          onDelete={() => onDelete(name)}
          onMouseDown={(event) => {
            event.stopPropagation()
          }}
        />
      ))}
    </Box>
  )
}

export default RenderMultipleValue
