import { toastSuccess, toastFailed } from 'utils/toast'
import {
  SET_IS_START_ALLORDER,
  SET_IS_FAILED_ALLORDER,
  SET_IS_SUCCESS_ALLORDER,
  SET_IS_NEED_TO_RELOAD_ALLORDER,
  SET_PAGINATION_DATA_ALLORDER,
  SET_DETAIL_DATA_ALLORDER,
  SET_STATUS_DETAIL_DATA_ALLORDER,
  SET_LIST_OF_HUB_ALLORDER,
  SET_IS_CHECKED_VERIFIY,
  SET_WAITING_CONFIRMATION_ORDER_ID_FOCUS,
  SET_ORDER_TYPE_LIST,
} from 'storeContext/actionsType'
import {
  geAllOrderList,
  getAllOrderDetail,
  putCancelOrder_AllOrder,
  putCompleteOrder_AllOrder,
  getOrderReferenceByInvoice_AllOrder,
  getAllProductInventory_AllOrder,
  postCreateOrder_AllOrder,
  postVerifyLocation_AllOrder,
  getAllLocation,
  geAllSuperOrderList,
  getAvailableTimeslots,
  putSudoCancelOrder_AllOrder,
  getOrderTypes,
  getActionsReasons,
  getCancelCompleteReason,
} from 'utils/apiList/allOrder'
import { callErrorMsg } from 'helpers/errorMsg'
import { setIsLoadingHCU } from 'features/HCU/redux/HCU'

export const allOrderStart = () => {
  return {
    type: SET_IS_START_ALLORDER,
  }
}

export const allOrderStop = () => {
  return {
    type: SET_IS_FAILED_ALLORDER,
  }
}

export const getAllOrder = (data) => {
  return {
    type: SET_IS_SUCCESS_ALLORDER,
    payload: data,
  }
}

export const reloadAllOrderList = () => {
  return {
    type: SET_IS_NEED_TO_RELOAD_ALLORDER,
  }
}

export const setPagination = (data) => {
  return {
    type: SET_PAGINATION_DATA_ALLORDER,
    payload: data,
  }
}
export const setDetailOrder = (data) => {
  return {
    type: SET_DETAIL_DATA_ALLORDER,
    payload: data,
  }
}

export const setStatusDetailOrder = (status) => {
  return {
    type: SET_STATUS_DETAIL_DATA_ALLORDER,
    payload: status,
  }
}

export const seListOfHub = (data) => {
  return {
    type: SET_LIST_OF_HUB_ALLORDER,
    payload: data,
  }
}

export const setIsCheckedVerify = (data) => {
  return {
    type: SET_IS_CHECKED_VERIFIY,
    payload: data,
  }
}

export const setWaitingConfirmationOrderIdFocus = (payload) => {
  return {
    type: SET_WAITING_CONFIRMATION_ORDER_ID_FOCUS,
    payload,
  }
}

export const setOrderTypeList = (payload) => {
  return {
    type: SET_ORDER_TYPE_LIST,
    payload,
  }
}

export const getOrderTypeList = () => (dispatch) => {
  return getOrderTypes()
    .then(({ data }) => {
      let orderTypeOptions = [
        {
          label: 'Semua Tipe',
          value: '',
        },
      ]

      const parseLabel = (value) => {
        let splitWords = value.toLowerCase().split('_')
        for (let i = 0; i < splitWords.length; i++) {
          splitWords[i] = splitWords[i][0].toUpperCase() + splitWords[i].substr(1)
        }
        return splitWords.join(' ')
      }

      data.data.forEach((d) => {
        orderTypeOptions.push({
          label: parseLabel(d),
          value: d,
        })
      })

      dispatch(setOrderTypeList(orderTypeOptions))
    })
    .catch((err) => {
      callErrorMsg(err)
    })
}

export const postVerifyLocationAllOrder =
  (id, params, cb = () => {}) =>
  (dispatch) => {
    const payload = {
      customer_service_email: params.customer_service_email,
      customer_service_password: params.customer_service_password,
      note: params.note,
      is_active: false,
    }
    return postVerifyLocation_AllOrder(id, payload)
      .then(({ data }) => {
        dispatch(setIsCheckedVerify(true))
        toastSuccess(data?.data?.message)
        cb && cb(id)
      })
      .catch(() => {
        dispatch(setIsCheckedVerify(false))
        toastFailed('terjadi masalah, coba beberapa saat lagi')
      })
  }

export const postVerifyHubAllOrder =
  (id, params, cb = () => {}) =>
  (dispatch) => {
    const payload = {
      ...params,
      is_active: false,
      verification_type: 'ARRIVED_HUB',
    }

    /* NOTE: yes, this use same api as postVerifyLocationAllOrder method */
    return postVerifyLocation_AllOrder(id, payload)
      .then(({ data }) => {
        toastSuccess(data?.data?.message)
        cb()
      })
      .catch(() => {
        toastFailed('terjadi masalah, coba beberapa saat lagi')
      })
  }
export const getListOfHub = () => (dispatch) => {
  return getAllLocation()
    .then(({ data }) => {
      const listHub = [{ id: false, name: 'Semua Lokasi' }]
      data.data?.map((items) => {
        return listHub.push({
          ...items,
          id: items.location_id,
          name: items.location_name,
        })
      })
      //const defaultData = [];
      dispatch(seListOfHub(listHub))
    })
    .catch((error) => {})
}

export const getListOfSuperOrder =
  (queryPagination = false, withLoading) =>
  (dispatch, getState) => {
    if (!withLoading) {
      dispatch(allOrderStart())
    }

    return geAllSuperOrderList(queryPagination)
      .then(({ data }) => {
        dispatch(getAllOrder({ content: data.super_orders }))
        const pagination = {
          pageItem: data?.cursor_pagination?.page_item,
          pageSize: data?.cursor_pagination?.page_size,
          next: data?.cursor_pagination?.next_cursor,
          prev: data?.cursor_pagination?.previous_cursor,
          first: !data?.cursor_pagination?.previous_page_exist,
          last: !data?.cursor_pagination?.next_page_exist,
        }
        dispatch(setPagination(pagination))
        dispatch(allOrderStop())
      })
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.error_message || 'terjadi masalah, coba beberapa saat lagi'
        toastFailed(errorMessage)
        dispatch(allOrderStop())
      })
  }

export const getSuperTimeslot =
  (query, callback = () => {}) =>
  (dispatch, getState) => {
    dispatch(allOrderStart())

    return getAvailableTimeslots(query)
      .then(({ data }) => {
        callback(data.data.timeslots)
      })
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.error_message || 'terjadi masalah, coba beberapa saat lagi'
        toastFailed(errorMessage)
      })
      .finally(() => dispatch(allOrderStop()))
  }

export const getListOfAllOrder =
  (queryPagination = false, withLoading) =>
  (dispatch, getState) => {
    if (!withLoading) {
      dispatch(allOrderStart())
    }
    let locationID = ''
    const query = queryPagination ? queryPagination : `?pageSize=15&pageIndex=0${locationID}`
    return geAllOrderList(query)
      .then(({ data }) => {
        dispatch(getAllOrder(data))
        const pagination = {
          first: data.first,
          last: data.last,
        }
        dispatch(setPagination(pagination))
        dispatch(allOrderStop())
      })
      .catch((error) => {
        const errorMessage =
          error?.response?.data?.error_message || 'terjadi masalah, coba beberapa saat lagi'
        toastFailed(errorMessage)
        dispatch(allOrderStop())
      })
  }

export const getDetailOrder = (id) => (dispatch) => {
  //dispatch(allOrderStart());

  return getAllOrderDetail(id)
    .then(({ data }) => {
      dispatch(setDetailOrder(data))
      dispatch(setStatusDetailOrder(true))
      //dispatch(allOrderStop());
    })
    .catch((error) => {
      toastFailed('terjadi masalah, coba beberapa saat lagi')
      //dispatch(allOrderStop());
    })
}

/******
 *
 *  USE IN ->
 *  pages\AllOrder\component\PopupCancel.js
 *
 */
export const putCancelOrder = (orderID, cancelData, localState) => (dispatch) => {
  const dataToSend = cancelData
  dispatch(allOrderStart())
  dispatch(setIsLoadingHCU(true))

  return putCancelOrder_AllOrder(orderID, dataToSend)
    .then(({ data }) => {
      toastSuccess(`${data.order_invoice} berhasil dibatalkan`)

      if (localState.getListOfAllOrder) {
        localState.getListOfAllOrder()
      }

      if (localState.instantCourier) {
        setTimeout(() => {
          localState.cancelCourier()
        }, 12000)
      }
    })
    .catch((err) => {
      callErrorMsg(err)
    })
    .finally(() => {
      dispatch(allOrderStop())
      dispatch(setIsLoadingHCU(false))
      localState.handleClose()
    })
}

export const getCancelReason =
  (successCallback = () => {}) =>
  (dispatch) => {
    dispatch(allOrderStart())
    const CANCEL_ACTION = 'CANCEL_ORDER'
    return getActionsReasons(CANCEL_ACTION)
      .then(({ data }) => {
        successCallback(data?.data?.reasons)
      })
      .catch((error) => {
        callErrorMsg(error)
      })
      .finally(() => {
        dispatch(allOrderStop())
      })
  }
export const getCancelReasonSudo =
  (successCallback = () => {}) =>
  (dispatch) => {
    dispatch(allOrderStart())
    return getCancelCompleteReason()
      .then(({ data }) => {
        successCallback(data.data)
      })
      .catch((error) => {
        callErrorMsg(error)
      })
      .finally(() => {
        dispatch(allOrderStop())
      })
  }

export const putCompleteOrder = (orderID, completeData, localState) => (dispatch) => {
  dispatch(allOrderStart())
  const dataToSend = completeData

  return putCompleteOrder_AllOrder(orderID, dataToSend)
    .then(({ data }) => {
      //console.log(data);
      dispatch(getListOfAllOrder())
      toastSuccess(`${data.order_invoice} berhasil di selesaikan`)
      localState.handleClose()
      dispatch(allOrderStop())
    })
    .catch((error) => {
      callErrorMsg(error)
      dispatch(allOrderStop())
    })
}

export const putSudoCancelOrder = (orderID, payload, localState) => (dispatch) => {
  dispatch(allOrderStart())

  return putSudoCancelOrder_AllOrder(orderID, payload)
    .then(({ data }) => {
      dispatch(getListOfAllOrder())
      toastSuccess(`${data.data.order_invoice} berhasil di cancel`)
      localState.handleClose()
    })
    .catch((error) => {
      callErrorMsg(error)
    })
    .finally(() => {
      dispatch(allOrderStop())
    })
}

export const getOrderReferenceByInvoice = (orderID, localState) => (dispatch) => {
  // invoice == orderID
  dispatch(allOrderStart())

  const query = `?invoice=${orderID}`

  return getOrderReferenceByInvoice_AllOrder(query)
    .then(({ data }) => {
      //console.log(data)

      localState.setIsOrderInvoiceTrue(true)

      localState?.setForms((prev) => {
        return {
          ...prev,
          note_instruksi: data.order_customer.customer_address_instruction,
          note_pesanan: data.order_note ? data.order_note : '',
          receiver: {
            name: data.order_customer.customer_name,
            phone: data.order_customer.customer_phone_number,
            address: data.order_customer.customer_address_detail,
          },
          order_line_items: data.order_line_items,
          order_location_id: data.order_location_id,
        }
      })
      dispatch(allOrderStop())
    })
    .catch((error) => {
      localState.setIsOrderInvoiceTrue(false)
      dispatch(allOrderStop())
      //toastFailed("terjadi masalah, coba beberapa saat lagi");
    })
}

export const getAllProductInventory =
  (order_location_id, productNamaOrSKU, localState = {}, searchType = false) =>
  (dispatch) => {
    let query = ''
    if (searchType === 'name') {
      query = `?name=${productNamaOrSKU}`
    } else if (searchType === 'sku') {
      query = `?sku=${productNamaOrSKU}`
    }
    //console.log(query)

    return getAllProductInventory_AllOrder(order_location_id, query)
      .then(({ data }) => {
        //console.log(data);
        localState.setProductList(data)
      })
      .catch((error) => {
        toastFailed('terjadi masalah, coba beberapa saat lagi')
      })
  }

/******
 *
 *  USE IN ->
 *  pages\AllOrder\component\PopupAdd.js
 *
 */

export const postCreateOrder = (formsData, localState) => (dispatch) => {
  dispatch(allOrderStart())

  return postCreateOrder_AllOrder(formsData)
    .then((res) => {
      const { data } = res

      toastSuccess(`${data.order_invoice} berhasil di tambahkan`)
      localState.handleClose()
      //console.log(localState.query);
      dispatch(getListOfAllOrder(localState.query))
      dispatch(allOrderStop())
    })
    .catch((error) => {
      callErrorMsg(error)
      dispatch(allOrderStop())
    })
}

export const getActionReasons = async (action) => {
  try {
    const { data } = await getActionsReasons(action)
    if (data?.data?.reasons) {
      return data?.data?.reasons
    }
    return []
  } catch (err) {
    callErrorMsg(err)
  }
}
