import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  getLocationHub,
  getLocationWarehouse,
  getSLOCList,
  getSLOCRackEnvironment,
  getSLOCRackLocation,
  postAddSLOC,
  getProductTypes,
  PostAddStockOpnameRequestType,
} from 'utils/apiList/stockOpname'
import { getProductCategoryL1 } from 'features/Product/services/product'

import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'

import { callErrorMsg } from 'helpers/errorMsg'
import { StockOpnameTypeEnum } from 'features/StockOpname/@types/StockOpnameAdd'
import {
  type GetInventoriesParamsType,
  getProductInventories,
} from 'features/StockOpname/services/stockOpnameAdd'

const SLICE_NAME = 'stockOpnameCheckList'

export const handleTest = () => 12

export const fetchHubLocationsList = createAsyncThunk(
  `${SLICE_NAME}/fetchStockOpnameListWarehouseLocationList`,
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userData } = (getState() as StoreStateType).auth
      const res = await getLocationHub()

      if (userData.isSuperAdmin) return res.data

      const listLocationId = getAccessibleLocationIdByType(userData.location_roles, 'HUB')
      return res.data.filter((item) => listLocationId.includes(item.location_id))
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

type LocationRoleType = {
  location_id: number
  location_name: string
  location_type: string
  role_names: string[]
}

const getAccessibleLocationIdByType = (
  locationRoles: LocationRoleType[],
  type: 'HUB' | 'WAREHOUSE',
) =>
  locationRoles
    .filter((item: LocationRoleType) => item.location_type === type)
    ?.map((item: LocationRoleType) => item.location_id)

export const fetchWarehouseLocationsList = createAsyncThunk(
  `${SLICE_NAME}/fetchStockOpnameListHubLocationList`,
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userData } = (getState() as StoreStateType).auth
      const res = await getLocationWarehouse()

      if (userData.isSuperAdmin) return res.data

      const listLocationId = getAccessibleLocationIdByType(userData.location_roles, 'WAREHOUSE')
      return res.data.filter((item) => listLocationId.includes(item.location_id))
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchSLOCRackEnvironment = createAsyncThunk(
  `${SLICE_NAME}/fetchSLOCRackEnvironment`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getSLOCRackEnvironment()

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchSLOCRackLocation = createAsyncThunk(
  `${SLICE_NAME}/fetchSLOCRackLocation`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getSLOCRackLocation()

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchSLOCRackList = createAsyncThunk(
  `${SLICE_NAME}/fetchSLOCRackList`,
  async (
    {
      pageIndex,
      storageTypes,
      storageEnvironment,
      rackName,
    }: { pageIndex: number; storageEnvironment?: number; storageTypes?: string; rackName?: string },
    { rejectWithValue, getState },
  ) => {
    const {
      stockOpnameAdd: {
        query: { locationId },
      },
    } = getState() as StoreStateType
    try {
      const pageSize = 10
      if (locationId !== 0 && locationId) {
        const res = await getSLOCList(
          pageIndex,
          pageSize,
          locationId,
          rackName,
          storageTypes,
          String(storageEnvironment || ``),
        )

        return res.data
      }

      return null
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const postAddSloc = createAsyncThunk(
  `${SLICE_NAME}/postAddSloc`,
  async (_, { rejectWithValue, getState }) => {
    const {
      stockOpnameAdd: {
        query: { locationId },
        submitParam: {
          taskGenerated,
          locationType,
          time,
          date,
          SLOCConfiguration,
          selectedType: stockOpnameConfigurationType,
        },
        selectedSlocList,
        rackLocation,
        productType,
        productCategory,
        product: { selectedProducts },
      },
    } = getState() as StoreStateType

    const payload: PostAddStockOpnameRequestType = {
      locationId,
      taskGenerated,
      locationType: locationType.toUpperCase(),
      startDateAt: date,
      startTimeAt: time,
      selectedType: SLOCConfiguration.toUpperCase(),
      selectedIds: [],
      rackStorageType: rackLocation.selectedDataList.map((item) => item.rackStorageId),
      productTypes: productType.selectedDataList.map((item) => item.product_type_id),
    }

    if (stockOpnameConfigurationType === StockOpnameTypeEnum.SLOC) {
      payload.selectedIds = selectedSlocList.map((d) => d.id)

      if (SLOCConfiguration === `rack` && taskGenerated > selectedSlocList.length) {
        return rejectWithValue(`Maaf, Jumlah Task harus lebih kecil daripada jumlah SLOC.`)
      }
    }

    if (stockOpnameConfigurationType === StockOpnameTypeEnum.SKU) {
      payload.selectedIds = selectedProducts.map((product) => product.product_id)
      payload.taskGenerated = selectedProducts.length
      payload.categoryIds = productCategory.selectedDataList.map((cat) => cat.category_id)
      payload.selectedType = StockOpnameTypeEnum.SKU

      if (!selectedProducts.length) {
        return rejectWithValue(`Maaf, Anda belum memilih SKU apapun.`)
      }
    }

    const { errorMessages } = await validatePayload(payload, stockOpnameConfigurationType)

    if (errorMessages.length) {
      return rejectWithValue(errorMessages[0])
    }

    try {
      const res = await postAddSLOC(payload)
      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

const validatePayload = (
  payload: PostAddStockOpnameRequestType,
  configurationType: StockOpnameTypeEnum,
) => {
  const errorMessages = []

  if (!payload.locationId) {
    errorMessages.push('Lokasi wajib diisi.')
  }

  if (!payload.startDateAt || !payload.startTimeAt) {
    errorMessages.push('Tanggal dan waktu wajib diisi.')
  }

  if (configurationType === StockOpnameTypeEnum.SLOC && !payload.selectedType) {
    errorMessages.push('Konfigurasi SLOC wajib dipilih.')
  }

  if (configurationType === StockOpnameTypeEnum.SLOC && !payload.rackStorageType.length) {
    errorMessages.push('Storage type wajib diisi')
  }

  return Promise.resolve({ errorMessages })
}

export const fetchProductType = createAsyncThunk(
  `${SLICE_NAME}/fetchProductType`,
  async (_, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await getProductTypes()
      return data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchProductCategoryL1 = createAsyncThunk(
  `${SLICE_NAME}/fetchProductCategoryL1`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getProductCategoryL1()
      return data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchProductInventories = createAsyncThunk(
  `${SLICE_NAME}/fetchProductInventories`,
  async (params: GetInventoriesParamsType, { rejectWithValue }) => {
    try {
      const { data } = await getProductInventories(params)
      return data
    } catch (err) {
      callErrorMsg(err, 'Terjadi kesalahan saat mendapatkan data produk')
      return rejectWithValue(err)
    }
  },
)
