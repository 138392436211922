import { memo } from 'react'
import styled from 'styled-components'
import { RowProduct, SystemIcon, NoData } from 'components'
import allowedRoleList from 'middleware/allowedRoleOnFeature/productAndStock/product'
import useAllowedAccessFor from 'customHooks/useAllowedAccessFor'
import { useAppSelector, shallowEqual } from 'store'

function TableProduct({
  data,
  onToggleProductStatus,
  handleClickDetail,
  handleClickUpdate,
  handleClickPackageStructure,
  handleClickVendor,
}) {
  const { authorities } = useAppSelector(
    ({
      auth: {
        userData: { authorities },
      },
    }) => ({ authorities }),
    shallowEqual,
  )

  /****
   *  RESTRICTION LIST FOR FEATURE
   *  USING CUSTOM HOOKS WITH MEMOIZATION
   *
   */
  const allowedForToggleStatus = useAllowedAccessFor(authorities, allowedRoleList.toggleStatus)

  const allowedForDetail = useAllowedAccessFor(authorities, allowedRoleList.detail)

  const allowedForEdit = useAllowedAccessFor(authorities, allowedRoleList.edit)

  return (
    <>
      <Container>
        <thead>
          <Tr>
            <Th ratio="3">
              NAMA PRODUK
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th ratio="2">
              NOMOR SKU
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th ratio="1">VENDOR</Th>
            <Th ratio="2">
              KATEGORI
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th ratio="1">PPN</Th>
            <Th ratio="1">STATUS</Th>
            <Th ratio="1">AKSI</Th>
          </Tr>
        </thead>
        <tbody>
          {data.map((el) => (
            <RowProduct
              data={el}
              key={el.productID}
              onToggleProductStatus={onToggleProductStatus}
              handleClickDetail={handleClickDetail}
              handleClickUpdate={handleClickUpdate}
              handleClickPackageStructure={handleClickPackageStructure}
              handleClickVendor={handleClickVendor}
              allowedForToggleStatus={allowedForToggleStatus}
              allowedForDetail={allowedForDetail}
              allowedForEdit={allowedForEdit}
            />
          ))}
        </tbody>
      </Container>
      {data.length === 0 && <NoData />}
    </>
  )
}

export default memo(TableProduct)

const Container = styled.table`
  width: 100%;
`
const Tr = styled.tr`
  display: flex;
  padding: 16px 32px;
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`
