import { createAsyncThunk } from '@reduxjs/toolkit'

import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'
import {
  getReplenishmentTaskDetailAPI,
  getReplenishmentTaskItemAPI,
} from 'features/SupplyOrder/services/replenishment'
import { callErrorMsg } from 'helpers/errorMsg'

export const SLICE_NAME = 'REPLENISHMENT_TASK_PARTIAL'

export const fetchGetReplenishmentTaskPartial = createAsyncThunk(
  `${SLICE_NAME}/fetchGetReplenishmentTaskPartial`,
  async (
    { id, params }: Parameters<typeof getReplenishmentTaskItemAPI>[0],
    { rejectWithValue },
  ) => {
    try {
      const responseDetail = await getReplenishmentTaskDetailAPI({ id })
      const responseItem = await getReplenishmentTaskItemAPI({ id, params })

      const { replenishment_task_no } = responseDetail.data.data.replenishment_task

      const response: {
        replenishNo: string
        item: typeof responseItem.data
      } = {
        replenishNo: replenishment_task_no,
        item: responseItem.data,
      }

      return response
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)
