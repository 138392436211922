import { createAsyncThunk } from '@reduxjs/toolkit'
import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'
import { callErrorMsg } from 'helpers/errorMsg'
import { dateFormat } from 'utils/helpers/date'
import { queryParamsURL } from 'utils/queryParamsURL'
import { toastSuccess } from 'utils/toast'
import { getTimeSlotListApi } from 'features/Enterprise/CapacityHandler/services/timeSlot'
import {
  deleteInboundSchedule,
  getCountScheduled,
  getInboundSchedule,
  GetInboundScheduleParamsType,
  GetInboundScheduleType,
  getLocationDetail,
} from 'features/Enterprise/CapacityHandler/services/inboundSchedule'

export const SLICE_NAME = 'inboundSchedule'

export const fetchInboundSchedule = createAsyncThunk<
  GetInboundScheduleType,
  undefined,
  RejectValueType
>(`${SLICE_NAME}/fetchInboundSchedule`, async (_, { getState, rejectWithValue }) => {
  const {
    inboundSchedule: { filters },
  } = getState() as StoreStateType
  const params = {
    startDate: dateFormat({ date: filters.date[0], format: 'YYYY-MM-DD' }),
    endDate: dateFormat({ date: filters.date[1], format: 'YYYY-MM-DD' }),
    locationId: filters.location?.location_id,
    loadingDockId: filters.loadingDock?.id,
  } as GetInboundScheduleParamsType

  try {
    const response = await getInboundSchedule(params)
    queryParamsURL.set(params)

    return response.data
  } catch (error) {
    callErrorMsg(error)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchLoadingDockTimeSlot = createAsyncThunk(
  `${SLICE_NAME}/fetchLoadingDockTimeSlot`,
  async (_, { getState, rejectWithValue }) => {
    const {
      inboundSchedule: { filters },
    } = getState() as StoreStateType

    try {
      const response = await getTimeSlotListApi(filters.loadingDock?.id as number, { active: true })

      return response.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchLocationDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchLocationDetail`,
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await getLocationDetail(id)

      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const fetchCountScheduled = createAsyncThunk(
  `${SLICE_NAME}/fetchCountScheduled`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await getCountScheduled()
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const removeInboundSchedule = createAsyncThunk(
  `${SLICE_NAME}/removeInboundSchedule`,
  async (_, { rejectWithValue, getState }) => {
    const {
      inboundSchedule: { selectedInboundScheduleToDelete },
    } = getState() as StoreStateType

    try {
      const { data } = await deleteInboundSchedule(
        selectedInboundScheduleToDelete?.inboundScheduleData?.scheduleId as number,
      )

      toastSuccess(`${selectedInboundScheduleToDelete?.text} berhasil dihapus.`)

      return data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)
