import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import { getFeatureFlagByLocationID } from 'utils/api'
import { getOrderPartialConfirmed, getTotalOrderCount } from 'utils/apiList/order'
import { withLoadingReducer } from 'utils/reducerHandler'

const initialState = {
  flagMergeOrder: false,
  selectedOrderType: 'INSTANT',
  totalCount: {
    instant: {
      total_count: 0,
    },
    super: {
      total_count: 0,
    },
  },
  partialOrderConfirmed: [],
  showPopupCompleteAstroKitchen: false,
  selectedOrderId: 0,
  flag3plAutoCall: false,
}

export const fetchTotalOrders = createAsyncThunk(
  'order/fetchTotalOrders',
  async (locationId, { dispatch }) => {
    try {
      const response = await getTotalOrderCount(locationId)
      dispatch(setTotalCount(response.data.data))
      return response.data.data
    } catch (err) {
      callErrorMsg(err)
    }
  },
)

export const checkMergeFlag = createAsyncThunk(
  'order/checkMergeFlag',
  async (locationId, { dispatch }) => {
    try {
      const query = { featureKey: 'merge_pesanan_berlangsung' }
      const flag = await getFeatureFlagByLocationID(locationId, query)
      const flagBool = flag?.data?.data?.features?.merge_pesanan_berlangsung
      return flagBool
    } catch (err) {
      callErrorMsg(err)
    }
  },
)

export const fetchPartialConfirmedOrders = createAsyncThunk(
  'order/fetchPartialConfirmedOrders',
  async (locationId, { dispatch }) => {
    try {
      const { data } = await getOrderPartialConfirmed(locationId)
      if (data?.data?.orders) return dispatch(setOrderPartialConfirmed(data?.data?.orders))
      return data
    } catch (err) {
      callErrorMsg(err)
    }
  },
)

const order = createSlice({
  name: 'order',
  initialState,
  reducers: {
    resetOrder: () => initialState,

    setSelectedOrderType: (state, action) => {
      state.selectedOrderType = action.payload
    },
    setTotalCount: (state, action) => {
      state.totalCount.instant = action.payload.instant
      state.totalCount.super = action.payload.super_delivery
    },
    setFlagMergeOrders: (state, action) => {
      state.flagMergeOrder = action.payload
    },
    setOrderPartialConfirmed: (state, action) => {
      state.partialOrderConfirmed = action.payload
    },
    setShowPopupCompleteAstroKitchen: (state, action) => {
      state.showPopupCompleteAstroKitchen = action.payload
    },
    setSelectedOrderId: (state, action) => {
      state.selectedOrderId = action.payload
    },
    set3plAutoCall: (state, action) => {
      state.flag3plAutoCall = action.payload
    },
  },
  extraReducers: withLoadingReducer(
    (builder) => {
      builder.addCase(checkMergeFlag.fulfilled, (state, action) => {
        state.flagMergeOrder = action.payload
      })
    },
    [checkMergeFlag],
  ),
})

export const {
  setSelectedOrderType,
  resetOrder,
  setTotalCount,
  setFlagMergeOrders,
  setOrderPartialConfirmed,
  setShowPopupCompleteAstroKitchen,
  setSelectedOrderId,
  set3plAutoCall,
} = order.actions

export default order.reducer
