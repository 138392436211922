import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material'
import styled from 'styled-components'
import {
  compose,
  layout,
  space,
  border,
  position,
  LayoutProps,
  SpaceProps,
  BorderProps,
  PositionProps,
} from 'styled-system'

export type ButtonPropsType = MuiButtonProps &
  LayoutProps &
  SpaceProps &
  BorderProps &
  PositionProps

const Button = styled(MuiButton)<ButtonPropsType>(compose(layout, space, border, position), {
  textTransform: 'none',
})

export default Button
