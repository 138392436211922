import { matchPath } from 'react-router-dom'

const queryUsable = (valueToCheck) => {
  if (valueToCheck === 0 || valueToCheck === false || valueToCheck) {
    return true
  }

  return false
}

const removeObjFromArray = (qs, query) => {
  return Object.keys(qs).reduce((obj, key) => {
    if (!query.includes(key)) {
      obj[key] = qs[key]
    }
    return obj
  }, {})
}

export const getCleanQueryObj = (data) => {
  return Object.keys(data).reduce((obj, key) => {
    if (queryUsable(data[key])) return { ...obj, [key]: data[key] }
    return { ...obj }
  }, {})
}

export const queryObjToString = (data) => {
  const cleanObj = getCleanQueryObj(data)
  const listParams = Object.keys(cleanObj).map((key) => {
    if (Array.isArray(cleanObj[key])) return `${key}=${cleanObj[key].toString()}`
    return `${key}=${encodeURIComponent(cleanObj[key])}`
  })
  if (listParams.length) return `?${listParams.join('&')}`
  return ''
}

export const queryStringToObj = (url) => {
  return Object.fromEntries(new URLSearchParams(url))
}

export const mergeQueryObjAndString = (qsObj, qsString) => {
  const newQs = queryStringToObj(qsString)
  return queryObjToString({
    ...qsObj,
    ...newQs,
  })
}

export const queryParamsURL = {
  get() {
    const qs = window.location.search
    return queryStringToObj(qs)
  },
  getURL() {
    return window.location.pathname
  },
  getParams(path) {
    const pathname = this.getURL()
    return matchPath(pathname, { path })?.params || {}
  },
  set(queryObjOrFunc) {
    const qs = this.get()
    let newQueryString

    if (typeof queryObjOrFunc === 'function') {
      const queryObj = queryObjOrFunc(qs)
      newQueryString = queryObjToString(queryObj)
    } else {
      newQueryString = queryObjToString({ ...qs, ...queryObjOrFunc })
    }

    window.history.replaceState(this.get(), '', newQueryString)
    return queryStringToObj(newQueryString)
  },
  remove(queryArray) {
    const qs = this.get()
    const queryObj = removeObjFromArray(qs, queryArray)

    window.history.replaceState(this.get(), '', queryObjToString(queryObj))
  },
}

export const serializeQueryToURL = (newQuery) => {
  const query = queryParamsURL.set(newQuery)

  let emptyQuery = []

  Object.entries(query).forEach(([key, value]) => {
    if (!queryUsable(value)) {
      emptyQuery.push(key)
    }
  })

  if (emptyQuery.length) {
    queryParamsURL.remove(emptyQuery)
  }

  return queryStringToObj(query)
}
