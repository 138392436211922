import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ModifierConnection, Product, Modifier } from 'features/Product/@types'

export const initialState: ModifierConnection.InitialStateType = {
  modalState: 'CLOSE',
  isLoading: false,
  query: {
    pageIndex: 1,
    pageSize: 20,
    name: '',
    bomCategory: 'ASTRO_KITCHEN',
  },
  modifierConnection: {
    pagination: null,
    data: [],
  },
  product: {
    pagination: {
      sort: 'id',
      direction: 'desc',
      pageSize: 20,
      pageIndex: 1,
      numberOfElements: 0,
    },
    data: [],
  },
  checkedModifiers: [],
  selectedProduct: null,
}

export const SLICE_NAME = 'modifierConnection'

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    setModalState: (state, { payload }: PayloadAction<ModifierConnection.ModalStateType>) => {
      state.modalState = payload
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setQuery: (state, { payload }: PayloadAction<Partial<Product.QueryType>>) => {
      state.query = {
        ...state.query,
        ...payload,
      }
    },
    setModifierConnectionData: (
      state,
      { payload }: PayloadAction<ModifierConnection.InitialStateType['modifierConnection']>,
    ) => {
      state.modifierConnection = payload
    },
    setProductData: (
      state,
      { payload }: PayloadAction<ModifierConnection.InitialStateType['product']>,
    ) => {
      state.product = payload
    },
    setCheckedModifier: (state, { payload }: PayloadAction<Modifier.ModifierType>) => {
      state.checkedModifiers = [...state.checkedModifiers, { ...payload }]
    },
    removeCheckedModifier: (state, { payload }: PayloadAction<Modifier.ModifierType>) => {
      state.checkedModifiers = state.checkedModifiers.filter(
        (modifier) => modifier.id !== payload.id,
      )
    },
    resetCheckedModifier: (state, { payload }: PayloadAction<Modifier.ModifierType[]>) => {
      state.checkedModifiers = [...payload]
    },
    setSelectedProduct: (
      state,
      { payload }: PayloadAction<ModifierConnection.InitialStateType['selectedProduct']>,
    ) => {
      state.selectedProduct = payload
    },
  },
})

export const {
  resetState,
  setModalState,
  setModifierConnectionData,
  setQuery,
  setIsLoading,
  setProductData,
  setCheckedModifier,
  removeCheckedModifier,
  resetCheckedModifier,
  setSelectedProduct,
} = slice.actions
export default slice.reducer
