import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getProductList,
  GetProductListRequestParamsType,
  downloadProducts,
  DownloadProductsResponseType,
  getProductDetail,
  GetProductDetailResponseType,
} from '../../services/products'

export const fetchProducts = createAsyncThunk(
  'pricingEngineProducts/fetchProducts',
  async (params: GetProductListRequestParamsType, { rejectWithValue }) => {
    try {
      const response = await getProductList(params)
      return response.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue((error as Error).message)
    }
  },
)

export const downloadProductsThunk = createAsyncThunk<DownloadProductsResponseType, number[]>(
  'pricingEngineProducts/downloadProducts',
  async (ids, { rejectWithValue }) => {
    try {
      const response = await downloadProducts(ids)
      return response.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue((error as Error).message)
    }
  },
)

export const fetchProductDetail = createAsyncThunk<GetProductDetailResponseType['data'], number>(
  'pricingEngineProducts/fetchProductDetail',
  async (id, { rejectWithValue }) => {
    try {
      const response = await getProductDetail(id)
      return response.data.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue((error as Error).message)
    }
  },
)
