import axios from 'axios'
import apiServiceApiGateway from 'config/apiServiceApiGateway'
import { Get, Post, Put } from 'config/apiServiceConfig'

const { REACT_APP_BASE_URL_API } = process.env

export type GetLocationResponseType = {
  location_id: number
  location_name: string
  location_type: string
}

type ErrorType = {
  status: false
  message: string
  code: number
}

type PaginationType = {
  sort: string
  direction: string
  pageSize: number
  pageIndex: number
  numberOfElements: number
}

type GetStockOpnameListDataType = {
  action: string[]
  assignee: { id: number; name: string }
  createdAt: number
  gap: number
  id: number
  status: string
  cancelledBy: string
  isRecount: boolean
  stockOpnameGroupId: number
  stockOpnameNumber: string
  totalRack: number
  latestCountVersion: number
}

type GetStockOpnameGroupListDataType = {
  action: string[]
  cancelledBy: string
  completedAt: number
  createdAt: number
  expiredAt: number
  gap: number
  id: number
  status: string
  location: { id: 2; name: string }
  startAt: number
  stockOpnameGroupNumber: string
  taskComplete: number
  taskCount: number
  totalRack: number
  latestCountVersion: number
}

export type GetStockOpnameDetailActivityType = {
  name: string
  startAt: number
  endAt: number | null
  duration: string | null
  createdBy: string
  note: string
}

export type GetStockOpnameDetailAssignActivityType = {
  assignTo: string
  createdAt: number
  createdBy: string
  name: string
}

export type GetStockOpnameDetailInfoType = {
  action: string[]
  id: number
  stockOpnameNumber: string
  createdAt: number
  stockOpnameGroupNumber: string
  totalSloc: number
  gap: number
  accuracy: number
  status: string
  location: {
    id: number
    name: string
    type: string
  }
  assignee: {
    id: number
    name: string
  }
  activities: GetStockOpnameDetailActivityType[]
  assignActivities: GetStockOpnameDetailAssignActivityType[]
}

export type GetStockOpnameDetailListType = {
  rackName: string
  skuNumber: string
  productName: string
  expiryDate: number
  packageId: string
  qty: number
  uom: string
}

export type GetStockOpnameListResponseType = {
  data: GetStockOpnameListDataType[]
  error: ErrorType
  message: string
  pagination: {
    direction: 'ASC' | 'DESC'
    numberOfElements: number
    pageIndex: number
    pageSize: number
    sort: string
  }
}

export type GetStockOpnameListResquestType = {
  params: {
    stockOpnameGroupID: number
    pageIndex?: number
    pageSize?: number
    status?: number
    locationId?: number
    name?: number
    stockOpnameNumber?: number
  }
}

export type GetStockOpnameGroupListResonseType = {
  data: GetStockOpnameGroupListDataType[]
  error: ErrorType
  message: string
  pagination: null
}

export type GetStockOpnameGroupListRequestType = {
  params: {
    pageSize: number
    pageIndex: number
    searchProduct?: string
    searchRack?: string
    locationId?: number
    status?: string
    stockOpnameNumber?: string
  }
}

export type PostCancelStockOpnameRequestType = {
  id: number
  type: 'GROUP' | 'ITEM'
}

export type PostCancelStockOpnameResponseType = {
  error: ErrorType
  message: string
}

export type PostSubmitStockOpnameResponseType = {
  data: null
  error: ErrorType
  message: string
  pagination: null
}

export type GetStockOpnameDetailResponseType = {
  data: GetStockOpnameDetailInfoType
  error: ErrorType
  message: string
}

export type GetStockOpnameDetailListResponseType = {
  data: GetStockOpnameDetailListType[]
  error: ErrorType
  message: string
}

export type GetCheckerListRequestType = {
  params?: {
    id?: number
    name?: number
    checkerId?: number
  }
  locationId: string
}

export type GetCheckerListResponseType = {
  data: {
    id: number
    name: string
  }[]
  error: ErrorType
  message: string
  pagination: null
}

export type PostAssignCheckerRequestType = {
  payload: {
    id?: number
    assigneeId?: number[]
    isReAssign: boolean
  }
}

export type PostAssignCheckerResponseType = {
  data: null
  error: ErrorType
  message: string
  pagination: null
}

export type PrintStoItemType = {
  productId: number
  productName: string
  productSku: string
  rackId: number
  rackName: string
}
export type GetPrintSTOResponseType = {
  data: {
    id: number
    stockOpnameNumber: string
    createdAt: number
    locationId: number
    locationName: string
    assignName: string
    stockOpnameItems: PrintStoItemType[]
    totalSkuRack: number
    typeReference: string
  }
  error: ErrorType
  message: string
  pagination: null
}

export type PostAdjustStockOpnameRequestType = {
  stockOpnameId: string
}

export type PostAdjustStockOpnameResponseType = {
  data: null
  error: ErrorType
  message: string
  pagination: null
}

export type GetStockOpnameTaskDataResponseType = {
  data: {
    id: number
    stockOpnameNumber: string
    details: {
      rackId: number
      rackName: string
      availableSku: string[]
    }[]
  }[]
  error: ErrorType
  message: string
  pagination: null
}

export type GetStockOpnameTaskDataRequestType = {
  params: {
    stockOpnameIds: string
  }
}

export type GetStockOpnameReviewDataRequestType = {
  params: {
    pageSize: number
    pageIndex: number
    rackName?: string
    productName?: string
  }
  stockOpnameId: string
}

export type RackItemInStoReviewType = {
  rackId: number
  rackName: string
  countSystem: {
    available: number
    bad: number
    lost: number
    qty: number
  }
  countPhysicals: {
    version: number
    available: number
    bad: number
    gap: number
    note: string
  }[]
}

export type StockOpnameReviewDataItemType = {
  productId: number
  productName: string
  productSku: string
  uom: string
  totalProductCountSystem: {
    available: number
    bad: number
    lost: number
    qty: number
  }
  totalProductCountPhysicals: TotalProductCountPhysicalsType[]
  racks: RackItemInStoReviewType[]
}

export type TotalProductCountPhysicalsType = {
  version: number
  available: number
  bad: number
  gap: number
  note: string
}

export type GetStockOpnameReviewGroupOrTaskResponseType = {
  data: {
    stoNumber: string
    latestCount: number
    list: StockOpnameReviewDataItemType[]
    totalCountSystem: {
      available: number
      bad: number
      lost: number
      qty: number
    }
    totalCountPhysicals: {
      version: number | null
      available: number | null
      bad: number | null
      gap: number | null
    }[]
  }
  error: ErrorType
  message: string
  pagination: {
    sort: string
    direction: string
    pageSize: number
    pageIndex: number
  }
}

export type GetStockOpnamePrintBeritaAcaraType = {
  data: {
    message: string
  }
  error: ErrorType
  message: string
  pagination: null
}

export type PostRecountStockOpnameRequestType = {
  payload: {
    type: 'SELECTED' | 'GAP' | 'ALL' | 'AUTO_RECOUNT'
    ids: number[]
    excludeIds: number[]
  }
  stockOpnameGroupId: string
}

export type PutSuccessResponseType = {
  data: null
  message: string
}

export type PostRecountStockOpnameResponseType = {
  data: null
  error: ErrorType
  message: string
  pagination: null
}

export type GetStockOpnameSLOCListType = {
  data: {
    id: number
    locationId: number
    rackName: string
    position: number
    active: boolean
    isDeleted: boolean
    isEmpty: boolean
    storageEnvironment: { id: number; name: string }
    storageType: { id: number; name: string }
  }[]
  error: ErrorType
  message: string
  pagination: PaginationType
}

export type GetStockOpnameSLOCRackType = {
  data: {
    content: { rackStorageId: number; rackStorageName: string; rackStorageType: string }[]
  }
  error: ErrorType
  message: string
  pagination: PaginationType
}

export type PostAddStockOpnameRequestType = {
  locationType: string
  locationId: number
  startDateAt: string
  startTimeAt: string
  selectedType: string
  selectedIds: number[]
  taskGenerated: number
  rackStorageType: number[]
  productTypes: number[]
  categoryIds?: number[]
}

export type PostAddStockOpnameResponseType = {
  data: null
  error: ErrorType
  message: string
  pagination: null
}

export type GetTop25SkuResponseType = {
  data: {
    rackId: number
    rackName: string
    productId: number
    productName: string
    productSkuNumber: string
    gap: number
  }[]
  error: ErrorType
  message: string
}

export type GetTop25SkuV2ResponseType = {
  data: {
    productId: number
    productSkuNumber: string
    productName: string
    gap: number
    cogs: number
    totalCogs: number
  }[]
  error: ErrorType
  message: string
}

export type GetStoAutoRecountListResponseType = {
  data: {
    criteria: {
      accuracy: string
      materiality: number
    }
    summary: {
      totalSloc: number
      totalSku: number
      totalGap: number
      totalCogs: number
    }
    items: {
      productId: number
      productSkuNumber: string
      productName: string
      gap: string
      cogs: number
      totalCogs: number
    }[]
  }
  error: ErrorType
  message: string
  pagination: {
    sort: string
    direction: string
    pageSize: number
    pageIndex: number
    numberOfElements: number
  }
}

export type GetPrintDocumentInExcelType = {
  file_url: string
}

export const getLocationList = () =>
  axios.get<GetLocationResponseType[]>(`${REACT_APP_BASE_URL_API}/api/locations/all`)

export const getLocationHub = () =>
  axios.get<GetLocationResponseType[]>(`${REACT_APP_BASE_URL_API}/api/locations/hub`)

export const getLocationWarehouse = () =>
  axios.get<GetLocationResponseType[]>(`${REACT_APP_BASE_URL_API}/api/locations/warehouse`)

export const getStockOpnameList = ({ params }: GetStockOpnameListResquestType) =>
  axios.get<GetStockOpnameListResponseType>(`${REACT_APP_BASE_URL_API}/api/ims/v1/stock-opname`, {
    params,
  })

export const getStockOpnameGroupList = ({ params }: GetStockOpnameGroupListRequestType) =>
  axios.get<GetStockOpnameGroupListResonseType>(
    `${REACT_APP_BASE_URL_API}/api/ims/v1/stock-opname/group`,
    { params },
  )

export const postCancelStockOpname = (payload: PostCancelStockOpnameRequestType) =>
  axios.post<PostCancelStockOpnameResponseType>(
    `${REACT_APP_BASE_URL_API}/api/ims/v1/stock-opname/cancel`,
    payload,
  )

export const postSubmitStockOpname = ({ groupId }: { groupId: number }) =>
  axios.post<PostSubmitStockOpnameResponseType>(
    `${REACT_APP_BASE_URL_API}/api/ims/v1/stock-opname/group/${groupId}/submit`,
  )

export const getStockOpnameDetail = (id: string) =>
  axios.get<GetStockOpnameDetailResponseType>(
    `${REACT_APP_BASE_URL_API}/api/ims/v1/stock-opname/${id}/detail`,
  )

export const getStockOpnameDetailList = (id: string, params: object) =>
  axios.get<GetStockOpnameDetailListResponseType>(
    `${REACT_APP_BASE_URL_API}/api/ims/v1/stock-opname/${id}/detail/items`,
    { params },
  )

export const getCheckerList = ({ params, locationId }: GetCheckerListRequestType) =>
  axios.get<GetCheckerListResponseType>(
    `${REACT_APP_BASE_URL_API}/api/ims/v1/stock-opname/${locationId}/checker`,
    { params },
  )

export const postAssignChecker = ({ payload }: PostAssignCheckerRequestType) =>
  axios.post<PostAssignCheckerResponseType>(
    `${REACT_APP_BASE_URL_API}/api/ims/v1/stock-opname/assign`,
    payload,
  )

export const getReportSTO = (id: string) =>
  axios.get<GetPrintSTOResponseType>(
    `${REACT_APP_BASE_URL_API}/api/ims/v1/stock-opname/${id}/print`,
  )

export const postAdjustStockOpname = ({ stockOpnameId }: PostAdjustStockOpnameRequestType) =>
  axios.post<PostAdjustStockOpnameResponseType>(
    `${REACT_APP_BASE_URL_API}/api/ims/v1/stock-opname/group/${stockOpnameId}/adjust-stock`,
  )

export const getStockOpnameTaskData = ({ params }: GetStockOpnameTaskDataRequestType) =>
  axios.get<GetStockOpnameTaskDataResponseType>(`http://localhost:3000/sto/getStoTaskData.json`, {
    params,
  })

export const getStockOpnamePrintBeritaAcara = (id: string) =>
  Get<GetStockOpnamePrintBeritaAcaraType>({
    url: `/api/ims/v1/stock-opname/${id}/record-of-transfer`,
  })

export type GetStoPrintBeritaAcaraResponseType = {
  data: {
    fileUrl: string
  }
  error: ErrorType
  message: string
}

export type ProductType = {
  product_type_id: number
  product_type_name: string
}

export const getStoPrintBeritaAcaraPreview = (stoGroupId: number) =>
  Get<GetStoPrintBeritaAcaraResponseType>({
    url: `/api/ims/:version/stock-opname/${stoGroupId}/record-of-transfer-preview`,
    version: 'v1',
  })

export const getStoPrintBeritaAcaraTransferAndAdjustment = (stoGroupId: number) =>
  Get<GetStoPrintBeritaAcaraResponseType>({
    url: `/api/ims/:version/stock-opname/${stoGroupId}/record-of-transfer-adjustment`,
    version: 'v1',
  })

export const getStoPrintBeritaAcaraWriteOff = (stoGroupId: number) =>
  Get<GetStoPrintBeritaAcaraResponseType>({
    url: `/api/ims/:version/stock-opname/${stoGroupId}/record-of-transfer-write-off`,
    version: 'v1',
  })

export const getStoPrintBeritaAcaraTransferAndAdjustmentExcel = (stoGroupId: number) =>
  apiServiceApiGateway.Get<GetPrintDocumentInExcelType>({
    url: `/ims/internal/v1/stock-opnames/${stoGroupId}/record-of-transfer-adjustment/excel`,
  })

export const getStoPrintBeritaAcaraWriteOffExcel = (stoGroupId: number) =>
  apiServiceApiGateway.Get<GetPrintDocumentInExcelType>({
    url: `/ims/internal/v1/stock-opnames/${stoGroupId}/record-of-transfer-write-off/excel`,
  })

export const getStoPrintBeritaAcaraPreviewExcel = (stoGroupId: number) =>
  apiServiceApiGateway.Get<GetPrintDocumentInExcelType>({
    url: `/ims/internal/v1/stock-opnames/${stoGroupId}/record-of-transfer-preview/excel`,
  })

export const getSLOCList = (
  pageIndex: number,
  pageSize: number,
  locationId: number,
  rackName?: string,
  storageTypes?: string,
  storageEnvironment?: string,
) =>
  Get<GetStockOpnameSLOCListType>({
    url: `/api/ims/v2/racks/${locationId}?pageIndex=${pageIndex}&pageSize=${pageSize}&rackName=${
      rackName || ''
    }&storageTypes=${storageTypes || ''}&storageEnvironment=${
      storageEnvironment || ''
    }&active=true`,
  })

export const getSLOCRackEnvironment = () =>
  Get<GetStockOpnameSLOCRackType>({
    url: `/api/racks/storage?type=Environment`,
  })

export const getSLOCRackLocation = () =>
  Get<GetStockOpnameSLOCRackType>({
    url: `/api/racks/storage?type=Type`,
  })

export const postAddSLOC = (payload: PostAddStockOpnameRequestType) =>
  Post<PostAddStockOpnameResponseType>({
    url: `/api/ims/v1/stock-opname/group`,
    data: payload,
  })

export const getTop25Sku = (stoId: number, params: { pageIndex: number; pageSize: number }) =>
  Get<GetTop25SkuV2ResponseType>({
    url: `/api/ims/:version/stock-opname/group/:id/top-sku`,
    version: 'v1',
    urlParams: { id: stoId },
    params,
  })

export const cancelStoAdjustment = (stoGroupId: number) =>
  Put<PutSuccessResponseType>({
    url: '/api/ims/v1/stock-opname/group/:id/cancel',
    urlParams: { id: stoGroupId },
  })

export const recountStoAdjustment = (
  stoGroupId: number,
  bodyData: PostRecountStockOpnameRequestType['payload'],
) =>
  Put<PutSuccessResponseType>({
    url: '/api/ims/v1/stock-opname/group/:id/recount',
    urlParams: { id: stoGroupId },
    data: bodyData,
  })

export const getStoAutoRecountList = (stoGroupId: number) =>
  Get<GetStoAutoRecountListResponseType>({
    url: 'api/ims/:version/stock-opname/group/:id/auto-recount',
    version: 'v1',
    urlParams: { id: stoGroupId },
  })

export const getReviewStoGroup = ({ params, stockOpnameId }: GetStockOpnameReviewDataRequestType) =>
  Get<GetStockOpnameReviewGroupOrTaskResponseType>({
    url: `/api/ims/:version/stock-opname/group/${stockOpnameId}/review`,
    version: 'v1',
    params,
  })

export const getReviewStoChild = ({ params, stockOpnameId }: GetStockOpnameReviewDataRequestType) =>
  Get<GetStockOpnameReviewGroupOrTaskResponseType>({
    url: `/api/ims/:version/stock-opname/${stockOpnameId}/review`,
    version: 'v2',
    params,
  })

export const getProductTypes = () =>
  Get<{ data: ProductType[] }>({
    url: '/api/product/type',
  })
