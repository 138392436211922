import { memo } from "react";
import styled from "styled-components";
import { TextBody, Button } from "components";
import dayjs from "dayjs";
import { formatMoneyRMG } from "helpers";

function RowBuyerPoints({ data, handleOpenPopup }) {
  const checkColorPoints = () => {
    return data?.type_transaction === "CREDIT" ? "green" : "red";
  };

  const operatorAssignment = () => {
    if (data?.point_amount) {
      return data?.type_transaction === "CREDIT" ? "+" : "-";
    }
  };
  return (
    <Tr>
      <Td ratio="4">
        <TextBody weight="light" color="textSoft">
          {data?.point_created_at
            ? dayjs(data?.point_created_at).format("DD MMM YYYY - HH:mm")
            : "13 Jun 1995 - 19:00"}
        </TextBody>
      </Td>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {data?.type_point ? data?.type_point : ""}
        </TextBody>
      </Td>
      <Td ratio="4">
        <TextBody weight="light">
          {data?.point_title ? data?.point_title : ""}
        </TextBody>
      </Td>
      <Td ratio="4">
        <TextBody weight="light">
          {data?.point_description ? data?.point_description : ""}
        </TextBody>
      </Td>
      <Td ratio="4">
        <TextBody weight="light" color={checkColorPoints()}>
          {operatorAssignment()}
          {data?.point_amount ? formatMoneyRMG(data?.point_amount, "") : "0"}
        </TextBody>
      </Td>
      <Td ratio="4">
        <Button
          variant="wrapper"
          onClick={() => {
            if (data?.order_id) {
              handleOpenPopup(data?.order_id);
            }
            //handleOpenPopup(data?.order_id);
          }}
        >
          <TextBody weight="light" className="pointer" color="main">
            {data?.order_invoice ? data?.order_invoice : ""}
          </TextBody>
        </Button>
      </Td>
    </Tr>
  );
}

export default memo(RowBuyerPoints);

const Tr = styled.tr`
  display: flex;
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
  .input-container {
    width: calc(100% - 20px);
  }
`;
const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  /* padding-right: ${({ pr }) => pr}; */
`;
