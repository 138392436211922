import { Get, Post, Put, Delete } from 'config/apiServiceConfig'
import { type ImageRulesType } from 'utils/apiList/images'

const { REACT_APP_NAME } = process.env

export type SeoStatusType = {
  id: number
  name: string
}

export type SectionType = {
  id: number
  name: string
}

export type CategoryType = {
  category_id: number
  category_name: string
}

export type CatalogueType = CategoryType

export type GetSeoStatusResponseType = {
  data: {
    data: SeoStatusType[]
  }
}

export type ComponentVariantType =
  | 'article'
  | 'banner'
  | 'masthead'
  | 'dynamic-channel'
  | 'product-tabbing-category'

export type DynamicLandingType = {
  id: number
  name: string
  urlDomain: string
  iosLink: string
  androidLink: string
  seoStatus: SeoStatusType
  ogImageId: number
  ogImageUrl: string
  ogImageWidth: number
  ogImageHeight: number
  ogImageFilePath: string
  metaDescription: string
  metaKeyword: string
  isActive: boolean
  components: {
    id: number
    type: ComponentVariantType
    name: string
  }[]
}

export type GetDynamicLandingDetailResponseType = {
  data: DynamicLandingType
}

export type ImageViewportType = 'small' | 'medium'

export type PostPutDyamicLandingPayloadType = {
  name: string
  urlDomain: string
  iosLink: string
  androidLink: string
  seoStatusId: number
  ogImageId?: number
  ogImageUrl: string
  ogImageFilePath: string
  ogImageWidth: number
  ogImageHeight: number
  metaDescription: string
  metaKeyword: string
  isActive: boolean
}

export type ComponentSectionVariantType = {
  id: number
  name: string
  type: ComponentVariantType
  iconUrl: string
}

export type GetComponentSectionTypeListResponseType = {
  data: ComponentSectionVariantType[]
}

type ImageMetaSizeType = {
  viewport: 'medium' | 'small'
  width: number
  height: number
}

export type BannerLayoutType = {
  id: number
  name: string
  iconUrl: string
  sizes: ImageMetaSizeType[]
  imageRules: (ImageRulesType & { viewport: 'medium' | 'small' })[]
  maxCount: number
}

export type GetBannerLayoutResponseType = {
  data: {
    data: BannerLayoutType[]
  }
}

export type MastheadLayoutType = BannerLayoutType

export type GetMastheadLayoutResponseType = {
  data: {
    data: MastheadLayoutType[]
  }
}

export type GetSectionListResponseType = {
  data: SectionType[]
}

export type GetCatalogueListResponseType = {
  content: CatalogueType[]
}

export type GetCategoryListResponseType = {
  content: CategoryType[]
}

export type GetTabbingCatalogueResponseType = {
  data: SectionType[]
}

export type GetTabbingCategoryResponseType = {
  data: SectionType[]
}

export type ArticleComponentSectionType = {
  id: number
  title: string
  body: string
}

export type GetArticleComponentResponseeType = {
  data: ArticleComponentSectionType
}

export type PostPutArticleComponentPayloadType = {
  title: string
  content: string
  sequence: number
}

export type GetDynamicLandingParamsType = {
  pageIndex?: number
  pageSize?: number
  search?: string
}

export type GetCatalogueParamsType = {
  name?: string
}

export type GetCategoryParamsType = GetCatalogueParamsType

export type GetDynamicLandingResponseType = {
  content: DynamicLandingType[]
  size: number
  number: number
  numberOfElements: number
  totalElements: number
  totalPages: number
}

export type PutDynamicLandingStatusRequestType = {
  dynamicLandingId: string
  payload: {
    isActive: boolean
  }
}

export type PutDynamicLandingStatusResponseType = {
  isActive: boolean
}

export type PostDynamicLandingCopyRequestType = {
  dynamicLandingId: string
}

export type PostPutDynamicLandingResponseType = {
  data: DynamicLandingType
}

export type PostDynamicLandingCopyResponseType = {
  data: DynamicLandingType
}

export type PostPutBannerComponentPayloadType = {
  layoutId: number
  items: {
    imageId?: number
    filePath: string
    height: number
    width: number
    url: string
    title: string
    targetUrl: string
    viewport: ImageMetaSizeType['viewport']
    imageSequence: number
  }[]
  sequence: number
  end_date: string
  start_date: string
}

export type BannerComponentSectionType = {
  id: number
  layoutId: number
  items: {
    imageId: number
    width: number
    height: number
    filePath: string
    url: string
    title: string
    link: string
    viewport: ImageMetaSizeType['viewport']
    seq: number
  }[]
  end_date: string
  start_date: string
}

export type MastheadComponentSectionType = BannerComponentSectionType

export type GetBannerComponentResponseType = {
  data: BannerComponentSectionType
}

export type PutComponentSortPayloadType = {
  data: {
    components: (Pick<ComponentSectionVariantType, 'id' | 'name' | 'type'> & {
      seq: number
    })[]
  }
}

export type PostPutMastheadComponentPayloadType = PostPutBannerComponentPayloadType

export type GetMastheadComponentResponseType = {
  data: MastheadComponentSectionType
}

type SectionCaruoselType = {
  id: number
  name: string
}

export type DynamicChannelComponentType = {
  id: number
  title: string
  section: SectionCaruoselType
  category: CategoryType
  catalogue: CatalogueType
  end_date: string
  start_date: string
}

export type GetDynamicChannelComponentResponseType = {
  data: DynamicChannelComponentType
}

export type GetSectionCarouselListResponseType = {
  data: SectionCaruoselType[]
}

export type PostPutDynamicChannelComponentPayloadType = {
  id?: number
  title: string
  sectionId: number
  categoryId: number | null
  catalogueId: number | null
  seq: number
  end_date: string
  start_date: string
}

export type ProductTabbingComponentType = {
  id: number
  title: string
  items: {
    section: SectionCaruoselType
    category: CategoryType
    catalogue: CatalogueType
  }[]
  end_date: string
  start_date: string
}

export type GetProductTabbingComponentResponseType = {
  data: ProductTabbingComponentType
}

export type PostPutProductTabbingComponentPayloadType = {
  id?: number
  title: string
  items: {
    sectionId: number | null
    categoryId: number | null
    catalogueId: number | null
  }[]
  end_date: string
  start_date: string
}

export type GetTabbingSectionListResponseType = GetSectionCarouselListResponseType

// TODO: implement real API

export const getSeoStatus = () =>
  Get<GetSeoStatusResponseType>({ url: '/api/dynamic-landing/seo-status' })

export const getDynamicLandingDetail = (id: number) =>
  Get<GetDynamicLandingDetailResponseType>({ url: `/api/dynamic-landing/${id}` })

export const postDynamicLanding = (payload: PostPutDyamicLandingPayloadType) =>
  Post<PostPutDynamicLandingResponseType>({ url: '/api/dynamic-landing', data: payload })

export const putDynamicLanding = (id: number, payload: PostPutDyamicLandingPayloadType) =>
  Put<PostPutDynamicLandingResponseType>({
    url: '/api/dynamic-landing/:id',
    data: payload,
    urlParams: { id },
  })

export const deleteDynamicLanding = (id: number) =>
  Delete({
    url: '/api/dynamic-landing/:id',
    urlParams: { id },
  })

export const getComponentTypeList = () =>
  Get<GetComponentSectionTypeListResponseType>({ url: '/api/dynamic-landing/component-type' })

export const getBannerLayoutList = () =>
  Get<GetBannerLayoutResponseType>({
    url: '/api/dynamic-landing/banner/layout',
    config: {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-Service-Name': REACT_APP_NAME,
      },
    },
  })

export const getMastheadLayoutList = () =>
  Get<GetMastheadLayoutResponseType>({
    url: '/api/dynamic-landing/masthead/layout',
    config: {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'X-Service-Name': REACT_APP_NAME,
      },
    },
  })

export const getSectionCarouselList = () =>
  Get<GetSectionCarouselListResponseType>({
    url: '/api/dynamic-landing/dynamic-channel/product/section',
  })

export const getCatalogueCarouselList = (params?: GetCatalogueParamsType) =>
  Get<GetCatalogueListResponseType>({ url: '/api/catalogue', params })

export const getCategoryCarouselList = (params?: GetCategoryParamsType) =>
  Get<GetCategoryListResponseType>({ url: '/api/category', params })

export const getArticleComponent = (dynamicLandingId: number, componentId: number) =>
  Get<GetArticleComponentResponseeType>({
    url: `/api/dynamic-landing/:dynamicLandingId/component/article/:componentId`,
    urlParams: {
      dynamicLandingId,
      componentId,
    },
  })

export const putArticleComponent = (
  dynamicLandingId: number,
  componentId: number,
  payload: PostPutArticleComponentPayloadType,
) =>
  Put<GetArticleComponentResponseeType>({
    url: '/api/dynamic-landing/:dynamicLandingId/component/article/:componentId',
    data: payload,
    urlParams: {
      dynamicLandingId,
      componentId,
    },
  })

export const postArticleComponent = (
  dynamicLandingId: number,
  payload: PostPutArticleComponentPayloadType,
) =>
  Post<GetArticleComponentResponseeType>({
    url: `/api/dynamic-landing/${dynamicLandingId}/component/article`,
    data: payload,
  })

export const deleteArticleComponent = (dynamicLandingId: number, componentId: number) =>
  Delete({
    url: '/api/dynamic-landing/:dynamicLandingId/component/article/:componentId',
    urlParams: {
      dynamicLandingId,
      componentId,
    },
  })

export const getDynamicLandingList = (params: GetDynamicLandingParamsType) =>
  Get<GetDynamicLandingResponseType>({ url: '/api/dynamic-landing', params })

export const putDynamicLandingStatus = ({
  dynamicLandingId,
  payload,
}: PutDynamicLandingStatusRequestType) =>
  Put({
    url: `/api/dynamic-landing/${dynamicLandingId}/active-status`,
    data: payload,
  })

export const postDynamicLandingCopy = ({ dynamicLandingId }: PostDynamicLandingCopyRequestType) =>
  Post<PostDynamicLandingCopyResponseType>({
    url: `/api/dynamic-landing/${dynamicLandingId}/copy`,
  })

export const postBannerComponent = (
  dynamicLandingId: number,
  payload: PostPutBannerComponentPayloadType,
) =>
  Post({
    url: `/api/dynamic-landing/${dynamicLandingId}/component/banner`,
    data: payload,
  })

export const putBannerComponent = (
  dynamicLandingId: number,
  componentId: number,
  payload: PostPutBannerComponentPayloadType,
) =>
  Put({
    url: `/api/dynamic-landing/:dynamicLandingId/component/banner/:componentId`,
    data: payload,
    urlParams: {
      dynamicLandingId,
      componentId,
    },
  })

export const getBannerComponent = (dynamicLandingId: number, componentId: number) =>
  Get<GetBannerComponentResponseType>({
    url: `/api/dynamic-landing/:dynamicLandingId/component/banner/:componentId`,
    urlParams: {
      dynamicLandingId,
      componentId,
    },
  })

export const deleteBannerComponent = (dynamicLandingId: number, componentId: number) =>
  Delete({
    url: '/api/dynamic-landing/:dynamicLandingId/component/banner/:componentId',
    urlParams: {
      dynamicLandingId,
      componentId,
    },
  })

export const putComponentSort = (dynamicLandingId: number, payload: PutComponentSortPayloadType) =>
  Put({
    url: `/api/dynamic-landing/:dynamicLandingId/component/sort`,
    data: payload,
    urlParams: {
      dynamicLandingId,
    },
  })

export const postMastheadComponent = (
  dynamicLandingId: number,
  payload: PostPutMastheadComponentPayloadType,
) =>
  Post({
    url: `/api/dynamic-landing/${dynamicLandingId}/component/masthead`,
    data: payload,
  })

export const deleteMastheadComponent = (dynamicLandingId: number, componentId: number) =>
  Delete({
    url: '/api/dynamic-landing/:dynamicLandingId/component/masthead/:componentId',
    urlParams: {
      dynamicLandingId,
      componentId,
    },
  })

export const getMastheadComponent = (dynamicLandingId: number, componentId: number) =>
  Get<GetMastheadComponentResponseType>({
    url: `/api/dynamic-landing/:dynamicLandingId/component/masthead/:componentId`,
    urlParams: {
      dynamicLandingId,
      componentId,
    },
  })

export const putMastheadComponent = (
  dynamicLandingId: number,
  componentId: number,
  payload: PostPutMastheadComponentPayloadType,
) =>
  Put({
    url: `/api/dynamic-landing/:dynamicLandingId/component/masthead/:componentId`,
    data: payload,
    urlParams: {
      dynamicLandingId,
      componentId,
    },
  })

export const deleteDynamicChannelComponent = (dynamicLandingId: number, componentId: number) =>
  Delete({
    url: '/api/dynamic-landing/:dynamicLandingId/component/dynamic-channel/:componentId',
    urlParams: {
      dynamicLandingId,
      componentId,
    },
  })

export const getDynamicChannelComponent = (dynamicLandingId: number, componentId: number) =>
  Get<GetDynamicChannelComponentResponseType>({
    url: `/api/dynamic-landing/:dynamicLandingId/component/dynamic-channel/:componentId`,
    urlParams: {
      dynamicLandingId,
      componentId,
    },
  })

export const postDynamicChannelComponent = (
  dynamicLandingId: number,
  payload: PostPutDynamicChannelComponentPayloadType,
) =>
  Post({
    url: `/api/dynamic-landing/${dynamicLandingId}/component/dynamic-channel`,
    data: payload,
  })

export const putDynamicChannelComponent = (
  dynamicLandingId: number,
  componentId: number,
  payload: PostPutDynamicChannelComponentPayloadType,
) =>
  Put({
    url: `/api/dynamic-landing/:dynamicLandingId/component/dynamic-channel/:componentId`,
    data: payload,
    urlParams: {
      dynamicLandingId,
      componentId,
    },
  })

export const getProductTabbingComponent = (dynamicLandingId: number, componentId: number) =>
  Get<GetProductTabbingComponentResponseType>({
    url: `/api/dynamic-landing/:dynamicLandingId/component/product-tabbing-category/:componentId`,
    urlParams: {
      dynamicLandingId,
      componentId,
    },
  })

export const postProductTabbingComponent = (
  dynamicLandingId: number,
  payload: PostPutProductTabbingComponentPayloadType,
) =>
  Post({
    url: `/api/dynamic-landing/${dynamicLandingId}/component/product-tabbing-category`,
    data: payload,
  })

export const putProductTabbingComponent = (
  dynamicLandingId: number,
  componentId: number,
  payload: PostPutProductTabbingComponentPayloadType,
) =>
  Put({
    url: `/api/dynamic-landing/:dynamicLandingId/component/product-tabbing-category/:componentId`,
    data: payload,
    urlParams: {
      dynamicLandingId,
      componentId,
    },
  })

export const deleteProductTabbingComponent = (dynamicLandingId: number, componentId: number) =>
  Delete({
    url: '/api/dynamic-landing/:dynamicLandingId/component/product-tabbing-category/:componentId',
    urlParams: {
      dynamicLandingId,
      componentId,
    },
  })

export const getTabbingSectionList = () =>
  Get<GetTabbingSectionListResponseType>({
    url: '/api/dynamic-landing/dynamic-channel/tabbing/section',
  })
