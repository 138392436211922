import { memo } from 'react'
import styled from 'styled-components'
import { TextBody, Checkbox } from 'components'

function RowProductHub({ data, handleCheck }) {
  return (
    <Tr>
      <Td ratio="1">
        <Checkbox checked={data.checked} onChange={() => handleCheck(data)} />
      </Td>
      <Td ratio="5">
        <ProductWrapper>
          <TextBody weight="light">{data.product_name}</TextBody>
        </ProductWrapper>
      </Td>
      <Td ratio="3">
        <TextBody weight="light">{data.product_sku_number}</TextBody>
      </Td>
      <Td ratio="3">
        <TagWrapper>
          <Tag>{data.product_categories[0]?.category_name}</Tag>{' '}
          {data.product_categories.length > 1 && (
            <Tag>+ {data.product_categories.length - 1} lainnya</Tag>
          )}
        </TagWrapper>
      </Td>
      <Td ratio="3">
        <StatusWrapper>
          <Dot active={data.product_active} />
          <TextBody weight="light" color={data.product_active ? 'textSoft' : 'grey'}>
            {data.product_active ? 'Aktif' : 'Non-Aktif'}
          </TextBody>
        </StatusWrapper>
      </Td>
    </Tr>
  )
}

export default memo(RowProductHub)

const TagWrapper = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
`

const Tag = styled.div`
  padding: 2px 8px;
  color: ${({ theme: { colors } }) => colors.grey};
  background-color: #f3f6fa;
  border-radius: 3px;
`

const Tr = styled.tr`
  display: flex;
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
`
const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`

const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`
const Dot = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ theme: { colors }, active }) => (active ? colors.main : colors.grey)};
`
