import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit'
import {
  GetStaffByRoleAndLocationResponseType,
  GetReplenishmentTaskListRequestType,
  GetReplenishmentTaskListResponseType,
  GetWarehouseLocationsResponseType,
} from 'features/SupplyOrder/services/replenishment'
import { withLoadingReducer } from 'utils/reducerHandler'
import {
  SLICE_NAME,
  fetchGetWarehouseLocations,
  fetchGetReplenishmentTask,
  setReplenishmentTaskFilter,
  fetchGetInventoryController,
  fetchPutReplenishmentTask,
  fetchPutUpdateStaffReplenishmentTask,
} from './thunk'

export type ReplenishmentTaskSliceType = {
  isLoading: boolean
  filter: GetReplenishmentTaskListRequestType
  pagination: GetReplenishmentTaskListResponseType['pagination']
  data: GetReplenishmentTaskListResponseType['data']
  constants: {
    warehouseList: GetWarehouseLocationsResponseType
    inventoryControllerList: GetStaffByRoleAndLocationResponseType['data']
  }
}

const initialState: ReplenishmentTaskSliceType = {
  isLoading: false,
  filter: {},
  pagination: {
    page_index: 1,
    page_size: 20,
    number_of_elements: 0,
    sort: '',
    direction: '',
  },
  data: [],
  constants: {
    warehouseList: [],
    inventoryControllerList: [],
  },
}

const replenishmentTaskSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetReplenishmentTask: () => initialState,
    resetReplenishmentTaskFilter: (state) => {
      state.filter = initialState.filter
    },
  },
  extraReducers: withLoadingReducer(
    (builder: ActionReducerMapBuilder<ReplenishmentTaskSliceType>) => {
      builder
        .addCase(setReplenishmentTaskFilter, (state, action) => {
          state.filter = action.payload
        })
        .addCase(fetchGetWarehouseLocations.fulfilled, (state, action) => {
          state.constants.warehouseList = action.payload
        })
        .addCase(fetchGetReplenishmentTask.fulfilled, (state, action) => {
          state.data = action.payload.data
          state.pagination = action.payload.pagination
        })
        .addCase(fetchGetInventoryController.fulfilled, (state, action) => {
          state.constants.inventoryControllerList = action.payload
        })
    },
    [
      fetchGetReplenishmentTask,
      fetchGetInventoryController,
      fetchPutReplenishmentTask,
      fetchPutUpdateStaffReplenishmentTask,
    ],
  ),
})

export const { resetReplenishmentTask, resetReplenishmentTaskFilter } =
  replenishmentTaskSlice.actions
export default replenishmentTaskSlice.reducer
