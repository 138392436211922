import { memo } from "react";
import styled from "styled-components";
import Header from "./Header";
import Body from "./Body";

function TemplateOrderPackerPacking() {
  return (
    <Container>
      <Header />
      <Body />
    </Container>
  );
}

export default memo(TemplateOrderPackerPacking);

const Container = styled.div``;
//const Header = styled.div``;
//const Body = styled.div``;
