import axios from 'axios'
import { Get, Put, Delete } from 'config/apiServiceConfig'

const { REACT_APP_BASE_URL_API } = process.env
const baseUrl = REACT_APP_BASE_URL_API

export interface CampaignSegmentListResponseType {
  text: string
  value: string
}

export interface DeleteCampaignProgressResponseType {
  data: string
  error: {
    status: boolean
    message: string
    code: number
  }
}

export type GetCampaignTypeListType = {
  data: {
    content: {
      label: string
      value: string
    }[]
  }
  error: {
    status: boolean
    message: string
    code: number
  }
  pagination: null
}

export type GetInventoryProductListRequestType = {
  campaignId: string
  params: {
    pageSize: number
    pageIndex: number
    name?: string
    createdDateFrom?: number
    createdDateTo?: number
    status?: string
    categoryId?: string
    locationId?: string
  }
}

export type GetInventoryProductListResponseType = {
  data: {
    content: {
      locationId: number
      locationName: string
      productId: number
      productName: string
      astroPercentage: number
      productSkuNumber: string
      productStock: number
      originalPrice: number
      campaignPriceDetails: [
        {
          campaignInventoryId: number
          discountedPrice: number
          discountPercentage: number
          discountQuota: number
          dailyQuota: number
          userSegment: string
          tier: {
            threshold: number
            discountPercentage: number
            discountedPrice: number
          }[]
        },
      ]
    }[]

    pageable: {
      sort: {
        sorted: boolean
        unsorted: boolean
        empty: boolean
      }
      offset: number
      pageSize: number
      pageNumber: number
      unpaged: boolean
      paged: boolean
    }
    totalElements: number
    totalPages: number
    last: boolean
    first: boolean
    size: number
    number: number
    sort: {
      sorted: boolean
      unsorted: boolean
      empty: boolean
    }
    numberOfElements: number
    empty: boolean
  }
  error: {
    status: boolean
    message: string
    code: number
  }
  pagination: null
}

export type PutUpdateDataInventoriesPerItemRequestType = {
  campaignInventoryId: number
  payload: {
    discountedPrice: number
    discountPercentage: number
    discountStock: number
    dailyQuota: number
    userSegment: string
    astroPercentage: number
    campaignType: string
    tier: {
      threshold: number
      discountPercentage: number
      discountedPrice: number
    }[]
  }
}

export type PutUpdateDataInventoriesPerItemResponseType = {
  data: {
    message: string
  }
  error: {
    status: boolean
    message: string
    code: number
  }
  pagination: null
}

export type DeleteDataInventoriesRequestType = {
  campaignId: number
  payload: number[]
}

export type DeleteDataInventoriesResponseType = {
  data: {
    message: string
  }
  error: {
    status: boolean
    message: string
    code: number
  }
  pagination: null
}

export type GetCampaignListV2RequestType = {
  params: {
    pageSize: number
    pageIndex: number
    createdDateFrom?: string
    createdDateTo?: string
    campaignType?: string
    name?: string
    status?: string
  }
}

export type GetCampaignCommercialInternalSkpReqType = {
  skp_no?: string
  campaign_name?: string
  promotion_type?: 'Rafaksi' | 'Rafaksi BMSM'
  status?: string
}

export type GetCampaignCommercialInternalSkpResType = {
  data: {
    id: number
    created_at: string
    skp_no: string
    promotion_type: string
    campaign_name: string
    start_date: string
    end_date: string
    vendor_name: string
    budget_skp: number
    actual_claim_skp: number
    status: string
    reject_reason: string
    is_uploaded_signed_skp: boolean
    is_verified: boolean
    is_uploaded_proof: boolean
    is_campaign_download: boolean
    is_uploaded_actual_claim: boolean
    is_approved_actual_claim: boolean
    uploaded_actual_claim_by: string
    uploaded_actual_claim_at: string
    approved_actual_claim_by: string
    approved_actual_claim_at: string
    start_date_campaign: string
    end_date_campaign: string
  }[]
}

export const getCampaignSegmentList = () =>
  axios.get<{ data: CampaignSegmentListResponseType[] }>(`${baseUrl}/api/campaign/user-segment`)

export const deleteCampaignProgress = (id: string) =>
  axios.delete<DeleteCampaignProgressResponseType>(`${baseUrl}/api/campaign/${id}/progress`)

export const getCampaignTypeList = () => Get<GetCampaignTypeListType>({ url: `/api/campaign/type` })

export const getInventoryProductList = ({
  campaignId,
  params,
}: GetInventoryProductListRequestType) =>
  Get<GetInventoryProductListResponseType>({ url: `/api/campaign/${campaignId}/inventory`, params })

export const putUpdateDataInventoriesPerItem = ({
  campaignInventoryId,
  payload,
}: PutUpdateDataInventoriesPerItemRequestType) =>
  Put<PutUpdateDataInventoriesPerItemResponseType>({
    url: `/api/campaign/inventory/${campaignInventoryId}`,
    data: payload,
  })

export const deleteDataInventories = ({ campaignId, payload }: DeleteDataInventoriesRequestType) =>
  Delete<DeleteDataInventoriesResponseType>({
    url: `/api/campaign/${campaignId}/remove-inventory`,
    config: { data: payload },
  })

export const getCampaignListV2 = ({ params }: GetCampaignListV2RequestType) =>
  Get({ url: `/api/campaigns`, params })

export type GetDownloadInvetoryDiscountRequestType = { campaign_id: string }

export type GetDownloadInvetoryDiscountResponseType = {
  data: {
    url: string
  }
  error: {
    status: boolean
    message: string
    code: number
  }
}

export const getDownloadInvetoryDiscount = ({
  campaign_id,
}: GetDownloadInvetoryDiscountRequestType) =>
  Get<GetDownloadInvetoryDiscountResponseType>({
    url: `/api/inventory-discount/campaign/${campaign_id}`,
  })

export const getCampaignCommercialInternalSkp = (params: GetCampaignCommercialInternalSkpReqType) =>
  Get<GetCampaignCommercialInternalSkpResType>({
    config: {
      baseURL: process.env.REACT_APP_BASE_URL_KONG_API,
    },
    url: '/commercial/internal/v1/skp',
    params,
  })
