import { memo, useMemo } from 'react'
import { Box, Pill } from '@astro-ui/components'
import styled from 'styled-components'
import { TextBody, Toggle, InputDropDown } from 'components'
import { Link } from 'react-router-dom'
import { AccessControl } from 'customHooks/useAccessControl'
import { AUTH_HO_TAX, AUTH_HO_CS_LEADER } from 'middleware/privateRoute'
import { useViewOnly } from 'customHooks/useAllowedAccessFor'
import { Skeleton } from '@astro-ui/components'
import { styleBusinessTagging } from 'features/Product/constant/product'

function RowProduct({
  data,
  onToggleProductStatus,
  handleClickDetail,
  handleClickUpdate,
  handleClickPackageStructure,
  handleClickVendor,
  allowedForToggleStatus,
  allowedForDetail,
  allowedForEdit,
}) {
  const isViewOnly = useViewOnly([AUTH_HO_CS_LEADER])

  const getActionList = useMemo(() => {
    let listOptions = []

    if (!isViewOnly) {
      listOptions.unshift({
        value: 'PACKAGE_STRUCTURE',
        name: 'Package Structure',
      })
    }

    if (!isViewOnly) {
      listOptions.unshift({
        value: 'VENDOR',
        name: 'Daftar Vendor',
      })
    }

    if (allowedForEdit) {
      listOptions.unshift({
        value: 'EDIT',
        name: 'Edit',
      })
    }

    if (allowedForDetail) {
      listOptions.unshift({
        value: 'DETAIL',
        name: 'Detail',
      })
    }
    return listOptions
  })

  return (
    <Tr>
      <Td ratio="3">
        <ProductWrapper>
          <ImgCategory size="32px" alt="box" src={data.images[0]?.small_url} />
          <TextBody weight="light" color="textSoft">
            {data.product_name}
          </TextBody>
        </ProductWrapper>
      </Td>
      <Td ratio="2">
        <TextBody weight="light" color="textSoft">
          {data.product_sku_no}
        </TextBody>
        <Box display="flex" flexDirection="column">
          {data?.business_taggings &&
            data?.business_taggings.map((item) => (
              <Box display="inline-block" key={item.key}>
                <Pill
                  content={item.value}
                  sx={{ marginTop: '5px', ...styleBusinessTagging[item.key] }}
                />
              </Box>
            ))}
        </Box>
      </Td>
      <Td ratio="1">
        <AccessControl
          blockedPermissions={[AUTH_HO_TAX]}
          renderElement={({ isAllowedAccess }) => {
            if (data?.vendorCount === undefined)
              return <Skeleton variant="text" sx={{ fontSize: '1rem', width: '80px' }} />

            if (isAllowedAccess && data?.vendorCount === 0) {
              return (
                <Link to={`/dashboard/product-vendor/${data.product_id}/add`} disabled>
                  <LinkText weight="light" color="blueTag">
                    Tambahkan
                  </LinkText>
                </Link>
              )
            }

            return (
              <TextBody weight="light" color="textSoft">
                {`${data.vendorCount} Vendor`}
              </TextBody>
            )
          }}
        />
      </Td>
      <Td ratio="2">
        <TagWrapper>
          <Tag>{data.categories[0]?.name}</Tag>
          {data.categories.length > 1 && <Tag>+ {data.categories.length - 1} lainnya</Tag>}
        </TagWrapper>
      </Td>
      <Td ratio="1">
        <TextBody weight="light" color="textSoft">
          {data.sell_ppn.ppn} %
        </TextBody>
      </Td>
      <Td ratio="1">
        <Toggle
          active={data.active}
          disabled={!allowedForToggleStatus}
          onClick={() => onToggleProductStatus(data)}
        />
      </Td>
      <Td ratio="1">
        <ActionWrapper>
          <InputDropDown
            listOptions={getActionList}
            handleClickOption={(actionOption) => {
              if (actionOption.value === 'DETAIL') {
                handleClickDetail(data)
              }
              if (actionOption.value === 'EDIT') {
                handleClickUpdate(data)
              }
              if (actionOption.value === 'PACKAGE_STRUCTURE') {
                handleClickPackageStructure(data)
              }
              if (actionOption.value === 'VENDOR') {
                handleClickVendor(data)
              }
            }}
            value="Atur"
            initialKey="0"
            noSetOption
          />
        </ActionWrapper>
      </Td>
    </Tr>
  )
}

export default memo(RowProduct)

const TagWrapper = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
`

const Tag = styled.div`
  padding: 2px 8px;
  color: ${({ theme: { colors } }) => colors.grey};
  background-color: #f3f6fa;
  border-radius: 3px;
`

const Tr = styled.tr`
  display: flex;
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
`
const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`
const ImgCategory = styled.img`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  border-radius: 5px;
  background-color: ${({ theme: { colors } }) => colors.mainFaded};
  border: none;
`

const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const ActionWrapper = styled.div`
  display: flex;
  color: black;
  i {
    margin-left: 20px;
  }
`

const LinkText = styled(TextBody)`
  cursor: pointer;
`
