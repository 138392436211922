import { memo } from "react";
import styled from "styled-components";
import { TextBody, InputDropDown } from "components";
import { toastFailed } from "utils/toast";
import dayjs from "dayjs";

const listOption = [
  {
    name: "Edit",
    id: "editSo"
  },
  {
    name: "Cetak Picking List",
    id: "cetakPickingList"
  },
  {
    name: "Cek Quantity",
    id: "cekQty"
  },
  {
    name: "Cetak Surat Jalan",
    id: "cekQty"
  }
  // {
  //   name: "Cetak",
  //   id: "cetakSo"
  // }
];

function RowSupplyORder({
  data,
  handlePrint,
  handleCheckQty,
  handleEdit,
  handlePrintPickingList,
  handlePrintSuratJalan
}) {
  const handleClickOption = (option) => {
    const [edit, cetakPickingList, checkQty, cetakSuratJalan /* print*/] =
      listOption;
    switch (option.name) {
      case edit.name:
        if (data.supply_order_status === "CREATED") {
          handleEdit(data);
        } else {
          toastFailed(`Hanya status CREATED yang dapat diedit`);
        }
        break;
      // case print.name:
      //   handlePrint(data);
      //   break;
      case checkQty.name:
        if (data.supply_order_status === "CREATED") {
          handleCheckQty(data);
        } else {
          toastFailed(`Hanya status CREATED yang dapat diupdate`);
        }
        break;
      case cetakPickingList.name:
        handlePrintPickingList(data);
        break;
      case cetakSuratJalan.name:
        handlePrintSuratJalan(data);
        break;
      default:
        break;
    }
  };

  return (
    <Tr>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {data.supply_order_number}
        </TextBody>
      </Td>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {dayjs(data.supply_order_created_at).format("DD MMM YYYY - HH:mm")}
        </TextBody>
      </Td>
      <Td ratio="2">
        <TextBody weight="light" color="textSoft">
          {data.location_origin_name}
        </TextBody>
      </Td>
      <Td ratio="2">
        <TextBody weight="light" color="textSoft">
          {data.location_destination_name}
        </TextBody>
      </Td>
      <Td ratio="2">
        <TextBody weight="light" color="textSoft">
          {data.supply_order_created_by}
        </TextBody>
      </Td>
      <Td ratio="2">
        <TextBody weight="light" color="textSoft" className="text-over w120">
          {data.supply_order_status}
        </TextBody>
      </Td>
      <Td ratio="2">
        <ActionWrapper>
          <InputDropDown
            disabled
            value="Atur"
            listOptions={listOption}
            initialKey={data.supply_order_id}
            noSetOption
            handleClickOption={handleClickOption}
          />
          {/* <Button variant="wrapper" onClick={() => handlePrint(data)}>
            <TextBody weight="light" color="main">
              Cetak
            </TextBody>
          </Button> */}
          {/* <SystemIcon className="pointer" colorIcon="main" iconName="edit" /> */}
        </ActionWrapper>
      </Td>
    </Tr>
  );
}

export default memo(RowSupplyORder);

const Tr = styled.tr`
  display: flex;
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
`;
const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  .w120 {
    max-width: 120px;
  }
`;

const ActionWrapper = styled.div`
  /* display: flex;
  i {
    margin-left: 20px;
  } */
  .input-drop-down {
    max-width: 110px;
    .list-container {
      right: 0;
      left: auto;
    }
  }
`;
