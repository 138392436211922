import { memo, useState, useRef } from "react";
import styled from "styled-components";
import listenClickOutside from "customHooks/outSideClick";
function Select({ children, className, options, handleClickOption }) {
  const refOutside = useRef(null);
  const [show, setShow] = useState(false);
  listenClickOutside(
    refOutside,
    () => {
      setShow(false);
    },
    show
  );
  return (
    <Container ref={refOutside} className={className}>
      <Wrapper onClick={() => setShow(true)}>{children}</Wrapper>
      {show && (
        <ListContainer>
          {options?.map((el) => (
            <Item
              onClick={() => {
                setShow(false);
                handleClickOption(el);
              }}
              key={el.name}
            >
              {el.name}
            </Item>
          ))}
        </ListContainer>
      )}
    </Container>
  );
}

Select.defaultProps = {
  handleClickOption: () => {}
};

export default memo(Select);

const Item = styled.div`
  padding: 8px 10px;
  white-space: nowrap;
  font-size: 14px;
  border-radius: 8px;
  cursor: pointer;
  color: ${({ theme: { colors } }) => colors.textSoft};
  :hover {
    background-color: ${({ theme: { colors } }) => colors.softGrey};
  }
`;
const ListContainer = styled.div`
  position: absolute;
  background: white;
  border-radius: 8px;
  margin-top: 8px;
`;

const Container = styled.div`
  height: fit-content;
  width: fit-content;
  position: relative;
  z-index: 1;
`;

const Wrapper = styled.div`
  height: fit-content;
  width: fit-content;
`;
