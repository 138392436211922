import { useMemo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'

/**
 * THIS METHOD SHOULD BE DEPRECATED AND NOT USE ANYMORE
 * @param {*} authoritiessList
 * @param {*} allowedList
 * @returns {boolean}
 */
const useAllowedAccessFor = (authoritiessList = [], allowedList = []) => {
  /*******
   *  EXAMPLE AUTHORITIES
   *  @authoritiessList = [{"name": "AUTH_PRICING" },{ "name": "AUTH_SUPPLY_CHAIN" }]]
   *
   *  EXAMPLE ALLOWEDLIST
   *  @allowedList = ['AUTH_MERCHANDISING_ADMIN','AUTH_MERCHANDISING_HUB', 'AUTH_MERCHANDISING_WAREHOUSE' ]
   *
   */

  const isAllowed = useMemo(() => {
    const findAllowedAccess = (arr1, arr2) => {
      return arr1.some((item) => arr2?.includes(item))
    }

    const currentUserAuth = authoritiessList?.map((items) => items.name)

    /***
     *  RETURN TRUE IF ROLES IS EXIST
     *
     *  MAKE AUTH_ADMIN as Default allowed accesss
     */
    return findAllowedAccess(currentUserAuth, ['AUTH_ADMIN', ...allowedList])
  }, [authoritiessList])

  return isAllowed
}

/**
 * THIS METHOD SHOULD BE DEPRECATED AND NOT USE ANYMORE
 * @param {*} authoritiessList
 * @param {*} allowedList
 * @returns {boolean}
 */
export const useBlockedAccessFor = (authoritiessList = [], blockedList = []) => {
  /*******
   *  EXAMPLE AUTHORITIES
   *  @authoritiessList = [{"name": "AUTH_PRICING" },{ "name": "AUTH_SUPPLY_CHAIN" }]]
   *
   *  EXAMPLE ALLOWEDLIST
   *  @allowedList = ['AUTH_MERCHANDISING_ADMIN','AUTH_MERCHANDISING_HUB', 'AUTH_MERCHANDISING_WAREHOUSE' ]
   *
   */

  const isBlocked = useMemo(() => {
    const findAllowedAccess = (arr1, arr2) => {
      return arr1.some((item) => arr2?.includes(item))
    }

    const currentUserAuth = authoritiessList?.map((items) => items.name)

    /***
     *  RETURN TRUE IF ROLES IS EXIST
     *
     *  MAKE AUTH_ADMIN as Default allowed accesss
     */
    return findAllowedAccess(currentUserAuth, [...blockedList])
  }, [authoritiessList])

  return isBlocked
}

export const useViewOnly = (viewOnlyList = []) => {
  const authorities = useSelector(({ auth: { userData } }) => userData.authorities, shallowEqual)

  const viewOnly = useMemo(() => {
    const findAllowedAccess = (arr1, arr2) => {
      return arr1.some((item) => arr2?.includes(item))
    }

    const currentUserAuth = authorities?.map((items) => items.name)

    return findAllowedAccess(currentUserAuth, [...viewOnlyList])
  }, [authorities])

  return viewOnly
}

/**
 * This useAllowedAccess hooks basically have same implementation with useAllowedAccessFor.
 * The difference is just user authorities loaded directly from store instead from argument.
 * @param {*} allowedList
 * @returns
 */
export const useAllowedAccess = (allowedList = []) => {
  const authorities = useSelector(({ auth: { userData } }) => userData.authorities, shallowEqual)

  const isAllowed = useMemo(() => {
    const findAllowedAccess = (arr1, arr2) => {
      return arr1.some((item) => arr2?.includes(item))
    }

    const currentUserAuth = authorities?.map((items) => items.name)

    return findAllowedAccess(currentUserAuth, ['AUTH_ADMIN', ...allowedList])
  }, [authorities])

  return isAllowed
}

export default useAllowedAccessFor
