export const SET_IS_LOADING = "SET_IS_LOADING/refferal-page";
export const SET_BENEFITS = "SET_BENEFITS/refferal-page";
export const SET_TARGETS = "SET_TARGETS/refferal-page";
export const SET_MECHANISMS = "SET_MECHANISMS/refferal-page";
export const SET_USERLIST = "SET_USERLIST/refferal-page";
export const SET_IS_LOADING_GET_USER = "SET_IS_LOADING_GET_USER/refferal-page";
export const SET_DATA_PAGINATION_USER =
  "SET_DATA_PAGINATION_USER/refferal-page";
export const SET_SELECTED_CUSTOMERS = "SET_SELECTED_CUSTOMERS/refferal-page";
export const SET_DATA_REFFERAL = "SET_DATA_REFFERAL/refferal-page";
