export const SET_IS_LOADING_PICKER = 'SET_IS_LOADING_PICKER/picker-page'
export const SET_DATA_PAGINATION = 'SET_DATA_PAGINATION/picker-page'
export const SET_DATA_PICKERS = 'SET_DATA_PICKERS/picker-page'
export const SET_DATA_LOCATIONS = 'SET_DATA_LOCATIONS/picker-page'
export const SET_IS_POPUP_DETAIL_SHOW = 'SET_IS_POPUP_DETAIL_SHOW/picker-page'
export const SET_SELECTED_PICKER = 'SET_SELECTED_PICKER/picker-page'
export const SET_IS_POPUP_CANCEL_SHOW = 'SET_IS_POPUP_CANCEL_SHOW/picker-page'
export const SET_LAST_PARAMS = 'SET_LAST_PARAMS/picker-page'
export const SET_LOCATIONS = 'SET_LOCATIONS/picker-page'
export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION/picker-page'
export const SET_VISIBLE_NOTIF_SUCCESS_ASSIGN_DRIVER =
  'SET_VISIBLE_NOTIF_SUCCESS_ASSIGN_DRIVER/picker-page'
export const SET_VISIBLE_MODAL_TO_ASSIGN_DRIVER = 'SET_VISIBLE_MODAL_TO_ASSIGN_DRIVER/picker-page'
export const SET_PACKER_SELECTION_PROCESS = 'IS_PAKCER_SELECTION_PROCESS'

export const SET_CHANGE_PICKER_REQUEST = 'SET_CHANGE_PICKER_REQUEST'
export const SET_CHANGE_PICKER_SUCCESS = 'SET_CHANGE_PICKER_SUCCESS'
export const SET_CHANGE_PICKER_FAILED = 'SET_CHANGE_PICKER_FAILED'
export const SET_HEAD_START_FORM = 'SET_HEAD_START_FORM'
export const SET_POPUP_STATUS = 'SET_POPUP_STATUS'
