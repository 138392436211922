import { memo } from "react";
import styled from "styled-components";
import { TextBody, Button, SystemIcon } from "components";
import dayjs from "dayjs";

function RowInboundWarehouse({
  //typeSO,
  rowData,
  listHandler,
  statusSO,
  handlePrintCompletSO
}) {
  const handleClickEdit = () => {
    listHandler.handleClickOpenPopupEdit(rowData.supply_order_id);
    //console.log(rowData.supply_order_id)
  };

  const handleClickDetail = () => {
    listHandler.handleClickOpenPopupDetail(rowData.supply_order_id);
  };

  // const handleClickPrint = () => {
  //   listHandler.handlePrint(rowData?.supply_order_id);
  // };

  const handleClickPrintInbound = () => {
    handlePrintCompletSO(rowData);
  };

  return (
    <Tr>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {rowData?.supply_order_number}
        </TextBody>
      </Td>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {dayjs(rowData?.supply_order_created_at).format(
            "DD MMM YYYY - HH:mm"
          )}
        </TextBody>
      </Td>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {rowData?.location_origin_name}
        </TextBody>
      </Td>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {rowData?.location_destination_name}
        </TextBody>
      </Td>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {rowData?.supply_order_status}
        </TextBody>
      </Td>
      <Td ratio="2">
        {statusSO === "incoming" ? (
          <ActionWrapper>
            <Button
              variant="wrapper"
              onClick={() => {
                handleClickDetail();
              }}
            >
              <TextBody weight="light" className="pointer" color="main">
                Detail
              </TextBody>
            </Button>
            <Button
              variant="wrapper"
              onClick={() => {
                handleClickEdit();
              }}
            >
              <SystemIcon colorIcon="main" iconName="edit" />
            </Button>
          </ActionWrapper>
        ) : (
          <>
            {/* <Button
              variant="wrapper"
              onClick={() => {
                handleClickPrint();
              }}
            >
              <TextBody weight="light" className="pointer" color="main">
                Cetak
              </TextBody>
            </Button> */}
            <Button variant="wrapper" onClick={handleClickPrintInbound}>
              <TextBody weight="light" className="pointer" color="main">
                Cetak
              </TextBody>
            </Button>
          </>
        )}
      </Td>
    </Tr>
  );
}

export default memo(RowInboundWarehouse);

const Tr = styled.tr`
  display: flex;
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
`;
const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`;

const ActionWrapper = styled.div`
  display: flex;
  i {
    margin-left: 20px;
  }
`;
