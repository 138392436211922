/* eslint-disable @typescript-eslint/naming-convention */
import AxiosInstance from 'config/apiServiceApiGateway'
import { Get } from 'config/apiServiceConfig'

export type VendorBankType = {
  id?: number
  is_primary?: boolean
  bank_name: string
  bank_code?: string
  account_name: string
  account_number: string
  deleted?: boolean
  form_id?: string
  idx?: number
}

export type VendorContactType = {
  email: string
  name: string
  phone_number: string
  preferred_channel: string
  role: string
  deleted?: boolean
  id?: number
  vendor_id?: number
  idx?: number
}

export type VendorLocationType = {
  state: string
  postal_code: string
  address: string
  country: string
  address_label: string
  city: string
  id?: number
  latitude?: string
  longitude?: string
  vendor_location_hours?: string[]
}

export type CompanyType = {
  id: number
  company_name: string
  company_code: string
  company_id?: number
}

export type DocumentType =
  | 'ktp'
  | 'npwp'
  | 'nomor_induk_usaha'
  | 'bank_guarantee'
  | 'account_book'
  | 'akta_pendirian'
  | 'akta_perubahan'
  | 'trading_term'
  | 'credit_limit'
  | 'sp_non_pkp'
  | 'pks'

export type VendorType = 'PT' | 'CV' | 'UD' | 'INDIVIDU'

export type VendorDocumentType = {
  id?: number
  file_path: string
  file_name: string
  document_type: DocumentType | string
  company_id?: number
  label?: string
  required?: number
  file_url?: string
}

export type VendorFormType = Omit<VendorDetailsInterface, 'vendor_locations'> & {
  state: string
  postal_code: string
  address: string
  country: string
  address_label: string
  city: string
}

export interface VendorDetailsInterface {
  id: number
  company_name: string
  companies: CompanyType[]
  vendor_type: VendorType
  category_vendor: string
  inventory_type: string
  npwp: string
  sppkp_number: string
  nib_number: string
  currency_code: string
  credit_limit: string
  terms: string
  vendor_bank_accounts: VendorBankType[]
  supplier_schedule: number[]
  cutoff_po_time: string
  vendor_shipping_info: {
    id?: number
    location_type: string
    frequency_delivery: number
    frequency_delivery_period: string
    delivery_days: number[]
    delivery_start_time: string
    delivery_end_time: string
    order_requirement: string
    moq_qty: number
    moq_max: boolean
    moq_multiplier: number
    moq_uom: string
    mov: string
    shipping_fee_rules: boolean
    shipping_fee_amount: string
    po_blocker: string
    po_lead_time: number
  }[]
  vendor_general_contacts: VendorContactType[]
  vendor_locations: VendorLocationType[]
  documents: VendorDocumentType[]
  astro_pic: AstroPicType[]

  active: boolean
  email: string
  first_name: string
  is_person: boolean
  last_name: string
  middle_name: string
  netsuite_id: number
  pic_phone_number: string
  tax_code: string
  user: string
  vendor_code: string
  vendor_phone_number: string
  waiting_time: number
  ktp: string
  vendor_id: number
}

export type AstroPicType = {
  id: number
  user_id: number
  email: string
  full_name?: string
  name: string
  phone_number: string
}

export type VendorCompanyType = {
  id: number
  company_name: string
  company_code: string
}

export type GetFullImageUrlParamsType = {
  file_path: string
  request_type: string
}

export type PostImageResponseType = {
  file_path: string
  file_url: string
  filename: string
  error: { code: number; status: boolean; message: string }
}

export interface VendorDetailRequestInterface {
  approval_pic: string
  id: number
  reason: string
  reject_pic: string
  revision: number
  status: string
  request_data: VendorDetailsInterface
}

export type VendorOptionsResponseType = {
  data: {
    options: VendorOptionsType[]
  }
}

export type VendorOptionsType = {
  type: string
  values: string[]
}

export type VendorUomsResponseType = {
  data: {
    unit_of_measurements: {
      content: VendorUomsType[]
    }
  }
}

export type VendorUomsType = {
  active: boolean
  description: string
  group: string
  id: string
}

export type RevisionInfoType = Omit<VendorDetailRequestInterface, 'request_data'>

export type AstroPicResponseType = {
  data: {
    users: AstroPicType[]
  }
}

export const getVendorDetails = (vendor_id: string) =>
  AxiosInstance.Get<VendorDetailsInterface>({
    url: `/erp/internal/v2/vendor/${vendor_id}?with_astro_pic=true&with_shipping_info=true`,
  })

export const createNewVendor = (data: VendorDetailsInterface) =>
  AxiosInstance.Post({
    url: `/erp/internal/v2/vendor`,
    data,
  })

export const putVendor = (data: VendorDetailsInterface) =>
  AxiosInstance.Put({
    url: `/erp/internal/v2/vendor`,
    data,
  })

export const getCompanies = () =>
  AxiosInstance.Get<VendorCompanyType[]>({
    url: `/erp/internal/:version/companies?page_size=99`,
    version: 'v1',
  })

export const getFullImageUrl = (params: GetFullImageUrlParamsType) =>
  AxiosInstance.Get({
    url: `/erp/internal/:version/vendor/document`,
    params,
    version: 'v2',
  })

export const uploadImage = (data: FormData) =>
  AxiosInstance.Post<PostImageResponseType>({
    url: `/erp/internal/:version/vendor/document`,
    version: 'v2',
    data,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  })

export const postVendorRequest = (data: VendorDetailsInterface) =>
  AxiosInstance.Post({
    url: `/erp/internal/:version/vendor/submission`,
    version: 'v2',
    data,
  })

export const putVendorRequest = (data: VendorDetailsInterface) =>
  AxiosInstance.Put({
    url: `/erp/internal/:version/vendor/submission/:id`,
    version: 'v2',
    urlParams: { id: data.id },
    data,
  })

export const getVendorDetailRequest = (vendor_id: string) =>
  AxiosInstance.Get<VendorDetailRequestInterface, true>({
    url: `/erp/internal/:version/vendor/request/${vendor_id}`,
    version: 'v2',
  })

export const postReview = (vendor_id: number | string, data: { action: string; reason: string }) =>
  AxiosInstance.Post({
    url: `/erp/internal/:version/vendor/submission/${vendor_id}/review`,
    version: 'v2',
    data,
  })

export const getOptions = () =>
  AxiosInstance.Get<VendorOptionsResponseType, true>({
    url: `/erp/internal/:version/options/vendor`,
    version: 'v1',
  })

export const getUoms = () =>
  Get<VendorUomsResponseType>({
    url: `/api/uoms?groups=ITEM&pageSize=100`,
  })

export const getPicList = () =>
  AxiosInstance.Get<AstroPicResponseType, true>({
    url: `/accounts/internal/:version/user/location/role?page_index=0&page_size=9999&roles=SOURCING&roles=SOURCING_MANAGER&roles=HO_SOURCING_HEAD&roles=HO_SOURCING_ADMIN&roles=HO_CATEGORY_MANAGER_PL_NON_FOOD&roles=HO_CATEGORY_MANAGER_PL_FOOD&roles=HO_CATEGORY_HEAD_PL_NON_FOOD&roles=HO_CATEGORY_HEAD_PL_FOOD&roles=HO_ASTRO_KITCHEN_SOURCING`,
    version: 'v1',
  })
