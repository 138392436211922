export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_FORMAT_LOCAL = 'DD-MM-YYYY'
export const DATE_WITH_MONTH_NAME_FORMAT = 'DD MMM YYYY'
export const DATE_WITH_MONTH_NAME_WITH_TIME_FORMAT = 'DD MMM YYYY - HH:mm'
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm'
export const DATE_TIME_HMS_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const DATE_TIME_BE_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]'
export const DATE_TIME_DMY_HMS_FORMAT = 'DD MMM YYYY HH:mm:ss'
export const DATE_TIME_WITH_MONTH_NAME_FORMAT = 'DD MMM YYYY HH:mm:ss'
export const DATE_DM_FORMAT = 'DD MMM'
