import { Get, Post } from 'config/apiServiceConfig'
import {
  GetSlottingListRequestType,
  GetLocationListResponseType,
  GetSlottingListResponseType,
  PostBulkUploadSlottingResponseType,
  PostBulkUploadSlottingRequestType,
  GetSlottingTemplateListResponseType,
  GetDownloadTemplateRequestType,
  PostMassUploadSlottingRequestType,
  PostMassUploadSlottingResponseType,
} from 'features/CategoryAndPosisi/@types/homepageSlotting'

export const getSlottingList = ({ params }: GetSlottingListRequestType) =>
  Get<GetSlottingListResponseType>({
    url: '/api/slotting/list/',
    params,
  })

export const getLocationList = () =>
  Get<GetLocationListResponseType>({
    url: '/api/locations/order',
  })

export const postBulkUploadSlotting = ({ payload }: PostBulkUploadSlottingRequestType) =>
  Post<PostBulkUploadSlottingResponseType>({
    url: '/api/slotting/upload',
    data: payload,
  })

export const getSlottingTemplateList = () =>
  Get<GetSlottingTemplateListResponseType>({
    url: '/api/slotting/csv/list',
  })

export const getDownloadTemplate = ({ params }: GetDownloadTemplateRequestType) =>
  Get({
    url: '/api/slotting/csv/download',
    params,
    config: { responseType: 'blob' },
  })

export const postMassUploadSlotting = ({ formData, config }: PostMassUploadSlottingRequestType) =>
  Post<PostMassUploadSlottingResponseType>({
    url: '/api/slotting/csv/bulk-upload',
    data: formData,
    contentType: 'multipart/form-data',
    config,
  })

export const getGenerateTemplate = () =>
  Get({
    url: '/api/slotting/csv/generate',
  })
