import { toastFailed } from "utils/toast";
import {
  SET_IS_START_SETPOS,
  SET_IS_FAILED_SETPOS,
  SET_IS_SUCCESS_SETPOS,
  SET_IS_PAGINATION_DATA_SETPOS,
  SET_IS_NEEN_TO_RELOAD_SETPOS
} from "storeContext/actionsType/setPosition";
import { getPPCatParent_SL } from "utils/apiList/setPosition";

export const startSetPos = () => {
  return {
    type: SET_IS_START_SETPOS
  };
};

export const stopSetPos = () => {
  return {
    type: SET_IS_FAILED_SETPOS
  };
};

export const getSetPosData = (data) => {
  return {
    type: SET_IS_SUCCESS_SETPOS,
    payload: data
  };
};

export const setPaginationSetPos = (data) => {
  return {
    type: SET_IS_PAGINATION_DATA_SETPOS,
    payload: data
  };
};

export const getReloadData = () => {
  return {
    type: SET_IS_NEEN_TO_RELOAD_SETPOS
  };
};

export const getSetPosition = (query) => (dispatch) => {
  dispatch(startSetPos());

  return getPPCatParent_SL(query)
    .then(({ data }) => {
      //console.log(data);
      dispatch(getSetPosData(data));
    })
    .catch(() => {
      dispatch(stopSetPos());

      toastFailed("terjadi masalah, coba beberapa saat lagi");
    });
};
