import { memo } from "react";
import { TextBody } from "components";
import styled from "styled-components";

function LastRow({ data }) {
  const totalQty = () => {
    let tmpTotalQty = 0;
    [...data].map((items) => {
      tmpTotalQty += items.product_request_quantity;
      return items.product_request_quantity;
    });
    return tmpTotalQty;
  };

  const totalRealQty = () => {
    let tmpTotalRealQty = 0;
    [...data].map((items) => {
      const tmpNumb = items.product_actual_quantity
        ? items.product_actual_quantity
        : 0;

      tmpTotalRealQty += parseInt(tmpNumb);
      return items.product_actual_quantity;
    });

    return tmpTotalRealQty;
  };

  const totalIncomingQty = () => {
    let tmpTotalRealQty = 0;
    [...data].map((items) => {
      const tmpNumb = items.product_incoming_quantity
        ? items.product_incoming_quantity
        : 0;

      tmpTotalRealQty += parseInt(tmpNumb);
      return items.product_incoming_quantity;
    });

    return tmpTotalRealQty;
  };
  return (
    <TrBody>
      <Td></Td>
      <Td>TOTAL</Td>
      <Td></Td>
      <Td>{totalQty()}</Td>
      <Td>{totalIncomingQty()}</Td>
      <Td></Td>
      {/* <Td>{totalOriginStock()}</Td> */}
      <Td>
        <TextBody size="smaller" weight="light" style={{ textAlign: "center" }}>
          {totalRealQty()}{" "}
        </TextBody>
      </Td>
      <Td></Td>
      <Td></Td>
      <Td></Td>
    </TrBody>
  );
}

export default memo(LastRow);

const TrBody = styled.tr`
  border-top: 1px solid #eff3f9;
  background-color: #fffae6;
`;

const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.textSoft};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  padding: 16px 32px;
  vertical-align: top;
  .input-qty {
    max-width: 70px;
  }
`;
