import createReducer from "storeContext/reducers/createReducer";
import {
  SET_IS_LOADING,
  SET_QUERY,
  SET_BOMS,
  SET_PRODUCTS
} from "storeContext/actionsType/bom";

export const initialStateBoM = {
  isLoading: false,
  query: {
    pageIndex: 0
  },
  pagination: {},
  data: [],
  products: []
};

const bomReducer = createReducer({
  [SET_IS_LOADING]: (state, action) => {
    state.isLoading = action.payload;
  },
  [SET_QUERY]: (state, action) => {
    state.isLoading = true;
    state.query = {
      ...state.query,
      ...action.payload.query
    };
  },
  [SET_BOMS]: (state, action) => {
    const { data, pagination } = action.payload;
    const { pageSize, pageIndex, totalElement } = pagination;

    state.data = data;
    state.pagination = {
      pageSize,
      currentPage: pageIndex,
      totalPage: Math.ceil(totalElement / pageSize)
    };
    state.isLoading = false;
  },
  [SET_PRODUCTS]: (state, action) => {
    state.products = action.payload.data;
  }
});

export default bomReducer;
