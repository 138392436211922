import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  GetMasterPayrollListServicesInterface,
  GetPayrollDetailByUserIdServicesInterface,
  PayrollQueryParamsInterface,
} from 'features/Enterprise/HRIS/services'

export interface MasterPayrollInitialStateInterface {
  isLoading: boolean
  hubLists: {
    location_id: number
    location_name: string
    location_type: string
  }[]
  selectedHub: MasterPayrollInitialStateInterface['hubLists'][0] | undefined
  masterPayrollList: GetMasterPayrollListServicesInterface['data']
  payrollDetailByUserId: GetPayrollDetailByUserIdServicesInterface['data'] | ObjectNullType
  query: PayrollQueryParamsInterface
  queryPayrollDetailByUserId: PayrollQueryParamsInterface
}

const initialState: MasterPayrollInitialStateInterface = {
  isLoading: false,
  hubLists: [],
  selectedHub: undefined,
  masterPayrollList: [],
  payrollDetailByUserId: {},
  query: {
    direction: 'end_date',
    pageIndex: 1,
    pageSize: 10,
    sort: 'desc',
    numberOfElements: 0,
  },
  queryPayrollDetailByUserId: {
    direction: 'end_date',
    pageIndex: 1,
    pageSize: 10,
    sort: 'desc',
    numberOfElements: 0,
  },
}

export const sliceName = 'masterPayroll'

const appSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    reset: () => initialState,
    setLoading: (state, action: PayloadAction<MasterPayrollInitialStateInterface['isLoading']>) => {
      state.isLoading = action.payload
    },
    setHubList: (state, action: PayloadAction<MasterPayrollInitialStateInterface['hubLists']>) => {
      state.hubLists = action.payload
    },
    setSelectedHub: (
      state,
      action: PayloadAction<MasterPayrollInitialStateInterface['selectedHub']>,
    ) => {
      state.selectedHub = action.payload
    },
    setQuery: (state, action: PayloadAction<MasterPayrollInitialStateInterface['query']>) => {
      state.query = action.payload
    },
    setQueryPayrollDetailByUserId: (
      state,
      action: PayloadAction<MasterPayrollInitialStateInterface['queryPayrollDetailByUserId']>,
    ) => {
      state.queryPayrollDetailByUserId = action.payload
    },
    setMasterPayrollList: (
      state,
      action: PayloadAction<MasterPayrollInitialStateInterface['masterPayrollList']>,
    ) => {
      state.masterPayrollList = action.payload
    },
    setPayrollDetailByUserId: (
      state,
      action: PayloadAction<MasterPayrollInitialStateInterface['payrollDetailByUserId']>,
    ) => {
      state.payrollDetailByUserId = action.payload
    },
  },
})

export const {
  setHubList,
  setLoading,
  setSelectedHub,
  setMasterPayrollList,
  setPayrollDetailByUserId,
  setQuery,
  setQueryPayrollDetailByUserId,
  reset,
} = appSlice.actions

export default appSlice.reducer
