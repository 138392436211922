import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SLICE_NAME, fetchTargetedUser, removeTargetedUser } from './gWPThunk'

export type TargetedUserListType = {
  data: { id: number; name: string }[]
  query: {
    pageIndex: number
    pageSize: number
    totalElements: number
  }
}

export interface GiftWithPurchaseStateType {
  isLoading: boolean
  isShowModalTargetedUser: boolean
  targetedUserList: TargetedUserListType
}

const initialState: GiftWithPurchaseStateType = {
  isLoading: false,
  isShowModalTargetedUser: false,
  targetedUserList: {
    data: [],
    query: {
      pageIndex: 0,
      pageSize: 20,
      totalElements: 0,
    },
  },
}

export type SetQueryTargetedUserPayloadType = {
  name: keyof TargetedUserListType['query']
  value: TargetedUserListType['query'][keyof TargetedUserListType['query']]
}

const giftWithPurchase = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setIsShowModalTargetedUser: (state, action: PayloadAction<{ value: boolean }>) => {
      const { value } = action.payload
      state.isShowModalTargetedUser = value
    },
    setQueryTargetedUser: (state, action: PayloadAction<SetQueryTargetedUserPayloadType>) => {
      const { name, value } = action.payload
      state.targetedUserList.query[name] = value
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTargetedUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchTargetedUser.fulfilled, (state, action) => {
        const { data, pagination } = action.payload
        state.isLoading = false
        state.targetedUserList.query.totalElements = pagination.totalElements
        state.targetedUserList.data = data.list
      })
      .addCase(fetchTargetedUser.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(removeTargetedUser.pending, (state) => {
        state.isLoading = true
      })
      .addCase(removeTargetedUser.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(removeTargetedUser.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const { reset, setIsShowModalTargetedUser, setQueryTargetedUser } = giftWithPurchase.actions
export default giftWithPurchase.reducer
