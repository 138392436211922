import { ProductVariant as Variant } from 'features/Product/@types'

export const SLICE_NAME = 'productVariant'
export const initialState: Variant.InitialStateType = {
  isLoading: false,
  query: {
    productNameOrSku: '',
    pageIndex: 1,
    pageSize: 10,
  },
  productVariants: [],
  modalConfirmationDelete: {
    selectedProductId: null,
    isOpen: false,
  },
}
