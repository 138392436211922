import {
  TableSortLabel as MuiTableSortLabel,
  TableSortLabelProps as MuiTableSortLabelProps,
} from '@mui/material'
import styled from 'styled-components'

export type TableSortLabelPropsType = MuiTableSortLabelProps

const TableSortLabel = styled(MuiTableSortLabel)<TableSortLabelPropsType>``
export default TableSortLabel
