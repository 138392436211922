import apiServices from 'config/apiServiceApiGateway'
import { CatalogueImageType, CatalogBackgroundType } from '../@types/catalogue'

export type PostCatalogueBackgroundPayloadType = PartialBy<
  Omit<CatalogBackgroundType, 'id'>,
  'bg_image'
>

const { Get, Post, Put } = apiServices

export const getCatalogueImageTypes = () =>
  Get<CatalogueImageType[]>({
    url: '/browse/internal/:version/catalogue-types',
    version: 'v1',
  })

export const getCatalogueBackground = () =>
  Get<CatalogBackgroundType, true>({
    url: '/browse/internal/:version/catalogue-background',
    version: 'v1',
  })

export const postCatalogueBackground = (data: PostCatalogueBackgroundPayloadType) =>
  Post<CatalogBackgroundType, true>({
    url: '/browse/internal/:version/catalogue-background',
    version: 'v1',
    data,
  })

export const putCatalogueBackground = (id: number, data: PostCatalogueBackgroundPayloadType) =>
  Put<CatalogBackgroundType, true>({
    url: '/browse/internal/:version/catalogue-background/:id',
    version: 'v1',
    urlParams: { id },
    data,
  })
