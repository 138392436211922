import { shallowEqual, useSelector } from 'react-redux'

const filteredSelectState = (state: StoreStateType) => {
  const { formFilter, dataSegmentList } = state.campaignAddManual

  return {
    formFilter,
    dataSegmentList,
  }
}

export const useRowCampaignAddManual = () => {
  const selectedState = useSelector(filteredSelectState, shallowEqual)

  return { state: { ...selectedState } }
}
