import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const featureFlagRBAC = {
  pageID: '9857176840839a8eddc31a0de33bee02',
  elementID: {
    UpdateFeatureFlag: 'faf7c4b79b34140b00242c38bf22614b',
    OpenFeatureFlagSetting: '827009a1bde30cf254fcac48437a795f',
  },
} as const

export const useFeatureFlagRBACPage = createUseRBAC(featureFlagRBAC.elementID)

export type UseFeatureFlagRBACPageType = ReturnType<typeof useFeatureFlagRBACPage>
