import { createAsyncThunk } from '@reduxjs/toolkit'
import { getPackingDetailByInvoice } from 'features/Order/services/packers'
import { callErrorMsg } from 'helpers/errorMsg'

type PayloadGetDetailPackingThunkType = {
  invoice: string
}

export const getDetailPacking = createAsyncThunk(
  'ongoingOrders/getPackingDetail',
  async (payload: PayloadGetDetailPackingThunkType, { fulfillWithValue }) => {
    try {
      const {
        data: { data: orderDataRes },
      } = await getPackingDetailByInvoice(payload)
      return fulfillWithValue(orderDataRes)
    } catch (err) {
      return callErrorMsg(err)
    }
  },
)
