const fontSizes = {
  bigIcon: '2rem',
  headerBiggest: '1.7rem',
  headerBigger: '1.5rem',
  header: '1.3rem',
  headerSmaller: '1.2rem',
  bigger: '1rem',
  normal: '.9rem',
  smaller: '.8rem',
  textSm: '.75rem',
  toast: '.7rem',
  smallest: '.6rem',
  tiny: '.5rem',
}

const fontWeights = {
  bold: '700',
  normal: '600',
  light: '400',
  lightest: '300',
}

const fontFamily = {
  monstreat: `'Montserrat', sans-serif`,
  nunito: `'Nunito', sans-serif`,
  nunitoSans: `'Nunito Sans', sans-serif`,
}

const fonts = {
  fontSizes,
  fontWeights,
  fontFamily,
}

export type FontsType = typeof fonts
export default fonts
