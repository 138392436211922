import { TableCell as MuiTableCell, TableCellProps as MuiTableCellProps } from '@mui/material'
import styled from 'styled-components'
import { space, SpaceProps, typography, TypographyProps, color, ColorProps } from 'styled-system'

export type TableCellPropsType = MuiTableCellProps & SpaceProps & TypographyProps & ColorProps

const TableCell = styled(MuiTableCell)<TableCellPropsType>`
  ${space}
  ${typography}
  ${color}
`

export default TableCell
