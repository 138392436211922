import { lazy } from 'react'

import generateRouterDetail from 'utils/helpers/generateRouterDetail'

const TicketScanner = lazy(() => import('../pages/TicketScanner'))

export const TicketScannerRouter: RouterFeatureInterface<
  'TicketScannerRouter',
  '/dashboard/ticket-scanner'
> = {
  name: 'TicketScannerRouter',
  Component: TicketScanner,
  path: '/dashboard/ticket-scanner',
  routeType: 'PRIVATE',
  allowedRoles: ['AUTH_EXT_TICKET', 'AUTH_ADMIN'],
  isDashboard: true,
}

const AstroEventRouter = [TicketScannerRouter]

export const AstroEventRouterDetail = generateRouterDetail(AstroEventRouter)
export default AstroEventRouter
