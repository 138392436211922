import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getPricingEngineHistory,
  type PricingEngineHistoryParamsType,
} from 'features/PricingEngine/services/history'

export const fetchPricingEngineHistory = createAsyncThunk(
  'pricingEngineHistory/fetchPricingEngineHistory',
  async (params: PricingEngineHistoryParamsType, { rejectWithValue }) => {
    try {
      const newParams = { ...params }
      if (!newParams.page_size) {
        newParams.page_size = 10
      }

      if (newParams.date_from) {
        const dateFrom = new Date(newParams.date_from)
        dateFrom.setHours(dateFrom.getHours() - 7)
        newParams.date_from = dateFrom.toISOString()
      }

      if (newParams.date_to) {
        const dateTo = new Date(newParams.date_to)
        dateTo.setHours(23, 59, 59, 999)
        newParams.date_to = dateTo.toISOString()
      }

      const response = await getPricingEngineHistory(newParams)
      return response.data
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)
