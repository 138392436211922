import { Badge as MuiBadge, BadgeProps as MuiBadgeProps } from '@mui/material'
import { space, SpaceProps, color, ColorProps } from 'styled-system'
import styled from 'styled-components'

export type BadgePropsType = MuiBadgeProps & SpaceProps & ColorProps

const Badge = styled(MuiBadge)<BadgePropsType>`
  ${space}
  ${color}
`
export default Badge
