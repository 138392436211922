import {
  SET_BENEFITS,
  SET_DETAIL_VOUCHER,
  SET_IS_FETCHING_PAYMENT_CHANNEL,
  SET_IS_LOADING,
  SET_LAST_QUERY,
  SET_MECHANISMS,
  SET_PAGINATION_DATA_VOUCHER,
  SET_PAYMENT_CHANNEL,
  SET_RESET,
  SET_TARGETS,
  SET_VOUCHER_DATA,
} from 'storeContext/actionsType/voucher'

import {
  getBenefits,
  getMechanisms,
  getPaymentChannel,
  getTargets,
  getVoucher,
  getVoucherDetail,
  postVoucher,
  putVoucherActive,
  putVoucherDetail,
} from 'utils/api'
import { toastFailed, toastSuccess } from 'utils/toast'

export function setDetailVoucher(payload) {
  return {
    type: SET_DETAIL_VOUCHER,
    payload,
  }
}

export function setIsLoading(payload) {
  return {
    type: SET_IS_LOADING,
    payload,
  }
}

export function setPaginationData(payload) {
  return {
    type: SET_PAGINATION_DATA_VOUCHER,
    payload,
  }
}
export function setVoucherData(payload) {
  return {
    type: SET_VOUCHER_DATA,
    payload,
  }
}
export function setLastQuery(payload) {
  return {
    type: SET_LAST_QUERY,
    payload,
  }
}
export function setMechanisms(payload) {
  return {
    type: SET_MECHANISMS,
    payload,
  }
}
export function setBenefits(payload) {
  return {
    type: SET_BENEFITS,
    payload,
  }
}
export function setTargets(payload) {
  return {
    type: SET_TARGETS,
    payload,
  }
}
export function setPaymentChannelList(payload) {
  return {
    type: SET_PAYMENT_CHANNEL,
    payload,
  }
}
export function setIsFetchingPaymentChannel(payload) {
  return {
    type: SET_IS_FETCHING_PAYMENT_CHANNEL,
    payload,
  }
}
export function reset() {
  return {
    type: SET_RESET,
  }
}

export const actGetVouchers =
  (query = {}) =>
  (dispatch) => {
    dispatch(setIsLoading(true))
    getVoucher(query)
      .then(({ data }) => {
        const dataVoucher = data.content
        const pagination = {
          pageSize: data.size,
          totalData: data.totalElements,
          totalPage: data.totalPages,
          currentPage: data.number,
          currentItem: data.numberOfElements,
        }
        dispatch(setVoucherData(dataVoucher))
        dispatch(setPaginationData(pagination))
        dispatch(setLastQuery(query))
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

export const actGetDataType = () => (dispatch) => {
  dispatch(setIsLoading(true))
  const pendingPromises = [getBenefits(), getTargets(), getMechanisms()]
  Promise.all(pendingPromises)
    .then(([{ data: dataBenefits }, { data: dataTargets }, { data: dataMechanisms }]) => {
      dispatch(setBenefits(dataBenefits))
      dispatch(setTargets(dataTargets))
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actGetPaymentChannel = () => async (dispatch) => {
  dispatch(setIsFetchingPaymentChannel(true))
  try {
    const res = await getPaymentChannel()
    const newList = [
      ...res.data.payment_channel_EWallet,
      ...res.data.payment_channel_Virtual_Account,
    ]
    dispatch(setPaymentChannelList(newList))
  } catch (error) {
    toastFailed('Gagal mendapatkan list payment channel')
  } finally {
    dispatch(setIsFetchingPaymentChannel(false))
  }
}

export const actHandleAddVoucher =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setIsLoading(true))
    postVoucher(payload)
      .then(() => {
        cb()
        setTimeout(() => {
          toastSuccess('Voucher berhasil ditambahkan')
        }, 300)
      })
      .catch(() => {
        toastFailed('Voucher gagal ditambahkan')
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

export const actHandleChangeActive = (data) => (dispatch, getState) => {
  dispatch(setIsLoading(true))
  putVoucherActive(data.voucher_id, { is_active: !data.voucher_active })
    .then(() => {
      const { lastQuery } = getState().stateVoucher
      dispatch(actGetVouchers(lastQuery))
      toastSuccess('Voucher berhasil diupdate')
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actHandleGetVoucherData = (id) => (dispatch) => {
  dispatch(setIsLoading(true))
  getVoucherDetail(id)
    .then(({ data }) => {
      dispatch(setDetailVoucher(data))
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actHandleEditVoucher =
  (id, payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setIsLoading(true))
    putVoucherDetail(id, payload)
      .then(() => {
        cb()
        setTimeout(() => {
          toastSuccess('Voucher berhasil ditambahkan')
        }, 300)
      })
      .catch(() => {
        toastFailed('Voucher gagal ditambahkan')
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }
