import { useLayoutEffect, useState } from 'react'
import theme from '@astro-ui/theme'

const DEVICE_TYPE = {
  desktop: 'DESKTOP',
  tablet: 'TABLET',
  mobile: 'MOBILE',
}

const useDeviceDetect = () => {
  const [device, setDevice] = useState(null)

  useLayoutEffect(() => {
    const updateSize = () => {
      const { tablet, desktop } = theme.breakpoints
      const tabletSize = +tablet.replace('px', '')
      const desktopSize = +desktop.replace('px', '')

      const isMobile = window.innerWidth < tabletSize
      const isDesktop = window.innerWidth >= desktopSize

      if (isMobile) {
        setDevice(DEVICE_TYPE.mobile)
      } else if (isDesktop) {
        setDevice(DEVICE_TYPE.desktop)
      } else {
        setDevice(DEVICE_TYPE.tablet)
      }
    }

    if (!device) {
      updateSize()
    }
    window.addEventListener('resize', updateSize)

    return () => window.removeEventListener('resize', updateSize)
  }, [])

  return {
    device,
    isMobile: device === DEVICE_TYPE.mobile,
    isTablet: device === DEVICE_TYPE.tablet,
    isDesktop: device === DEVICE_TYPE.desktop,
  }
}

export default useDeviceDetect
