import { memo, useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import Header from './Header'
import Body from './Body'
import { printAreaStop, setReadyPrint, setNotReadyPrint } from 'storeContext/actions/printArea'
import store from 'storeContext'
import TemplateGrabMart from '../TemplateGrabMart'

function TemplateOrderPackerWaitingDriver() {
  const { state: statePrintArea, dispatch: dispatchPrintArea } = useContext(store.PrintAreaContext)

  const initStateImageList = [{ name: 'logo', status: false }]
  const initImgLoaded = false

  const [imgLoaded, setImgLoaded] = useState(initImgLoaded)
  const [stateImageList, setStateImageList] = useState(initStateImageList)

  useEffect(() => {
    const imgState = stateImageList.every((el) => el.status === true)
    if (imgState || statePrintArea.dataResiOrderPackerWaitingDriver?.order_barcode_url === null) {
      dispatchPrintArea(printAreaStop())
      dispatchPrintArea(setReadyPrint())
      setImgLoaded(true)
      if (!imgLoaded && statePrintArea.dataResiOrderPackerWaitingDriver?.order_barcode_url) {
        // setTimeout(() => {
        //   window.print();
        // }, 800);
      }
    }
  }, [stateImageList])

  useEffect(() => {
    const imgState = stateImageList.every((el) => el.status === false)
    if (imgState) {
      dispatchPrintArea(setNotReadyPrint())
    }
  }, [stateImageList])

  const handleOnLoadLogo = () => {
    setStateImageList((prev) => {
      const imageName = 'logo'
      const newArr = [...prev]
      const indexBarcode = newArr.findIndex((el) => el.name === imageName)
      newArr[indexBarcode] = { ...prev[indexBarcode], status: true }

      return newArr
    })
  }

  const handleList = {
    handleOnLoadLogo: handleOnLoadLogo,
  }

  if (!statePrintArea.dataResiOrderPackerWaitingDriver) return null

  const {
    dataResiOrderPackerWaitingDriver: { packageList },
  } = statePrintArea

  const renderTemplate = () => {
    if (statePrintArea.dataResiOrderPackerWaitingDriver.order_type == 'GRAB_MART') {
      if (packageList && packageList.length) {
        return packageList.map(({ paketNumber, packageId }, i) => {
          return (
            <TemplateGrabMart
              key={i + paketNumber}
              data={statePrintArea.dataResiOrderPackerWaitingDriver}
              handleList={handleList}
              pages={packageList}
              packageOrderNumber={paketNumber ? paketNumber : 1}
              packageId={packageId}
            />
          )
        })
      }
      return (
        <TemplateGrabMart
          data={statePrintArea.dataResiOrderPackerWaitingDriver}
          handleList={handleList}
          pages={packageList}
          packageOrderNumber={1}
          packageId={statePrintArea.dataResiOrderPackerWaitingDriver.order_packages[0].packageId}
        />
      )
    }

    return (
      <>
        {packageList && packageList.length ? (
          packageList.map(({ paketNumber, packageId }) => {
            return (
              <Wrapper key={paketNumber}>
                <Header
                  data={statePrintArea.dataResiOrderPackerWaitingDriver}
                  handleList={handleList}
                  imageBarcodeBlob={statePrintArea.imageBarcodeBlob}
                />
                <Body
                  data={statePrintArea.dataResiOrderPackerWaitingDriver}
                  packageOrderNumber={paketNumber}
                  packageId={packageId}
                  orderPackage={statePrintArea.dataResiOrderPackerWaitingDriver.order_packages.find(
                    (el) => el.packageId === packageId,
                  )}
                />
              </Wrapper>
            )
          })
        ) : (
          <Wrapper>
            <Header
              data={statePrintArea.dataResiOrderPackerWaitingDriver}
              handleList={handleList}
              imageBarcodeBlob={statePrintArea.imageBarcodeBlob}
            />
            <Body data={statePrintArea.dataResiOrderPackerWaitingDriver} />
          </Wrapper>
        )}
      </>
    )
  }

  return renderTemplate()
}

export default memo(TemplateOrderPackerWaitingDriver)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4vh;
  width: 100%;
  height: 100%;
  padding-bottom: 0px;
  @media print {
    page-break-inside: avoid;
    break-inside: avoid;
    clear: both;
    @page {
      size: 101.6mm 101.6mm;
      margin: 0mm;
    }
  }
`
