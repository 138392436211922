import { createSlice, PayloadAction, ActionReducerMapBuilder } from '@reduxjs/toolkit'
// import { sortDataWithGiftNote } from 'features/Order/pages/OngoingOrders/components/PopupCheckQty/ScanItemsAndConsumables/ProductTable/helpers'
import { OrderTypeResponseInterface } from 'features/Order/services/packers'
import { withLoadingReducer } from 'utils/reducerHandler'
import { getDetailPacking } from './thunk'

export type OrderDataType = {
  order_line_items: OrderLineItemsType[]
  total_relabeled_item: number
  order_kitchen_status: string
}

export type OrderLineItemsType = {
  is_need_to_relabel: boolean
  order_product_scanned_quantity: number
  is_manual_input_sku: boolean
  order_line_item_id: number
  cloud_kitchen: boolean
  brand_name: string
  order_product_rack: string
  order_product_id: number
  order_product_name: string
  order_product_quantity: number
  product_note: { displayName: string }
  order_line_item_type: string
  order_product_sku: string
  modifiers: {
    name: string
    modifier_variants: {
      id: number
      name: string
    }[]
  }[]
  add_ons: {
    name: string
    modifier_variants: {
      id: number
      name: string
    }[]
  }[]
  order_line_item_product_image: string
  item_id: number
  special_type: string
  order_product_actual_quantity: number
  note: number
}

export type UseScanItemAndConsumeableType = {
  showCheckbox: boolean
  orderData: OrderDataType
}
export type PopUpCheckQtyStateType = {
  orderData: Nullable<OrderTypeResponseInterface>
  errNote: string
  isWithAdditionalBox: boolean
  dataSKUBarcodeQuantity: {
    qr: string
    quantity: number
  }
  canRemoveProduct: boolean
  showRemoveProductPopup: boolean
  removeableProducts: boolean
  showContactCS: boolean
  notesTemporary: { id: number; product_note: { displayName: string } }[]
  removedProducts: OrderLineItemsType[]
  relabelModalProps: {
    qty?: number
    show: boolean
  }
  showPrintRelabel: {
    show: boolean
    product?: OrderLineItemsType
  }
  showRelabelReminder: {
    previouslyReminded: []
    show: boolean
    sku?: string
  }
  flags: Record<string, boolean>
}
const initialState: PopUpCheckQtyStateType = {
  orderData: null,
  errNote: '',
  isWithAdditionalBox: false,
  dataSKUBarcodeQuantity: {
    qr: '',
    quantity: 0,
  },
  canRemoveProduct: false,
  showRemoveProductPopup: false,
  removeableProducts: false,
  showContactCS: false,
  notesTemporary: [],
  removedProducts: [],
  relabelModalProps: {
    qty: 0,
    show: false,
  },
  showPrintRelabel: {
    show: false,
    product: undefined,
  },
  showRelabelReminder: {
    previouslyReminded: [],
    show: false,
    sku: undefined,
  },
  flags: {},
}

const popUpCheckQty = createSlice({
  name: 'popUpCheckQty',
  initialState,
  reducers: {
    setDataSKUBarcodeQuantity: (
      state,
      action: PayloadAction<PopUpCheckQtyStateType['dataSKUBarcodeQuantity']>,
    ) => {
      state.dataSKUBarcodeQuantity = action.payload
    },
    setOrderData: (state, action: PayloadAction<PopUpCheckQtyStateType['orderData']>) => {
      state.orderData = action.payload
    },
    setFlags: (state, action: PayloadAction<PopUpCheckQtyStateType['flags']>) => {
      state.flags = action.payload
    },
  },
  extraReducers: withLoadingReducer(
    (builder: ActionReducerMapBuilder<PopUpCheckQtyStateType>) => {
      builder.addCase(getDetailPacking.fulfilled, (state, action) => {
        if (action?.payload) {
          // const { dataItems, giftCards } = sortDataWithGiftNote(action.payload)
          state.orderData = action.payload
        }
      })
    },
    [getDetailPacking],
  ),
})
export const { setDataSKUBarcodeQuantity, setFlags } = popUpCheckQty.actions
export default popUpCheckQty.reducer
