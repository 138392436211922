import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  type DynamicChannelType,
  type DynamicChannelSectionType,
  type DynamicChannelVariantType,
  type DynamicChannelCustomLogicType,
  type LocationType,
  type CategoryType,
} from 'utils/apiList/dynamicChannel'
import { BannerType } from 'features/CategoryAndPosisi/@types/dynamicChannel'

import {
  SLICE_NAME,
  fetchDynamicChannel,
  fetchDynamicChannelSection,
  fetchDynamicChannelVariant,
  fetchHub,
  fetchCatalogue,
  fetchCategory,
  fetchDynamicChannelCustomLogic,
  saveDynamicChannel,
  fetchDynamicChannelDetail,
  removeDynamicChannel,
  fetchBannerList,
} from './thunk'

export type {
  DynamicChannelType,
  DynamicChannelSectionType,
  DynamicChannelVariantType,
  DynamicChannelCustomLogicType,
}

export type QueryType = Record<string, unknown> &
  Partial<DynamicChannelStateInterface['pagination']> & {
    name: string
  }

export interface DynamicChannelStateInterface {
  isLoading: boolean
  dataList: DynamicChannelType[]
  pagination: {
    pageNumber: number
    pageSize: number
  }
  needToReload: number
  query: QueryType
  constants: {
    channelSections: DynamicChannelSectionType[]
    channelVariants: DynamicChannelVariantType[]
    customLogics: DynamicChannelCustomLogicType[]
    hubs: LocationType[]
    catalogues: CategoryType[]
    categories: CategoryType[]
    banner: BannerType[]
  }
  queryBanner: {
    pageIndex: number
    pageSize: number
    name: string
  }
}

export const initialState: DynamicChannelStateInterface = {
  isLoading: false,
  dataList: [],
  pagination: {
    pageNumber: 0,
    pageSize: 20,
  },
  needToReload: 0,
  query: { name: '' },
  constants: {
    channelSections: [],
    channelVariants: [],
    customLogics: [],
    hubs: [],
    catalogues: [],
    categories: [],
    banner: [],
  },
  queryBanner: { name: '', pageIndex: 0, pageSize: 30 },
}

const dynamicChannelSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setQuery: (
      state,
      action: PayloadAction<
        Partial<DynamicChannelStateInterface['query'] & { needToReload?: boolean }>
      >,
    ) => {
      const { needToReload, ...rest } = action.payload

      state.query = {
        ...state.query,
        ...rest,
      }

      if (needToReload === undefined || needToReload) {
        state.needToReload += 1
      }
    },
    setDatalist: (state, action: PayloadAction<DynamicChannelType[]>) => {
      state.dataList = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDynamicChannel.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchDynamicChannel.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchDynamicChannel.fulfilled, (state, action) => {
      state.isLoading = false
      state.dataList = action.payload?.content || []

      state.pagination = {
        ...state.pagination,
        pageNumber: action.payload?.number,
      }
    })
    builder.addCase(fetchDynamicChannelSection.fulfilled, (state, action) => {
      state.constants.channelSections = action.payload || []
    })
    builder.addCase(fetchDynamicChannelVariant.fulfilled, (state, action) => {
      state.constants.channelVariants = action.payload || []
    })
    builder.addCase(fetchHub.fulfilled, (state, action) => {
      state.constants.hubs = action.payload || []
    })
    builder.addCase(fetchCatalogue.fulfilled, (state, action) => {
      state.constants.catalogues = action.payload || []
    })
    builder.addCase(fetchCategory.fulfilled, (state, action) => {
      state.constants.categories = action.payload || []
    })
    builder.addCase(fetchDynamicChannelCustomLogic.fulfilled, (state, action) => {
      state.constants.customLogics = action.payload || []
    })
    builder.addCase(saveDynamicChannel.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(saveDynamicChannel.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(saveDynamicChannel.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchDynamicChannelDetail.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchDynamicChannelDetail.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchDynamicChannelDetail.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(removeDynamicChannel.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(removeDynamicChannel.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(removeDynamicChannel.fulfilled, (state) => {
      state.isLoading = false
    })

    builder.addCase(fetchBannerList.pending, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchBannerList.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchBannerList.fulfilled, (state, action) => {
      state.isLoading = false
      state.constants.banner = action.payload.content.map((el, i) => ({
        ...el,
        position: i + 1,
        uniqueKey: window.crypto.randomUUID(),
        name: `${el.banner_id} - ${el.banner_name}`,
        banner_image: el.banner_image,
      }))
    })
  },
})

export const { setQuery, setDatalist } = dynamicChannelSlice.actions
export default dynamicChannelSlice.reducer
