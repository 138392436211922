import {
  SET_LIST_FRAUD_TYPES,
  SET_IS_LOADING_FRAUD_CONFIG,
  SET_LIST_FRAUD_CONFIG,
  SET_DETAIL_FRAUD_CONFIG,
  SET_EDIT_FRAUD_CONFIG,
} from 'storeContext/actionsType'
import { dateFormat } from 'utils/helpers/date'

export const initialStateFraud = {
  isLoading: false,
  isEdit: false,
  paginationData: {},
  needToReload: 1,
  listFraudTypes: [],
  listFraudConfig: [],
  detailFraudConfig: null,
  qsListFraudConfig: '',
}

export default function fraud(state = initialStateFraud, { type, payload }) {
  switch (type) {
    case SET_IS_LOADING_FRAUD_CONFIG:
      return {
        ...state,
        isLoading: payload,
      }
    case SET_LIST_FRAUD_CONFIG:
      return {
        ...state,
        isLoading: false,
        qsListFraudConfig: payload.query,
        listFraudConfig: payload.data.data.fraudConfigs.map((item) => ({
          id: item.id,
          createdBy: item.createdBy,
          date: item.createdAt
            ? dateFormat({ date: new Date(item.createdAt), format: 'DD/MM/YYYY' })
            : '-',
          fraudType: item.description,
          description: item.value,
          status: item.status === 'ACTIVE',
        })),
        paginationData: {
          pageSize: payload.data.pagination.pageSize,
          totalData: payload.data.pagination.totalElement,
          totalPage: Math.ceil(
            payload.data.pagination.totalElement / payload.data.pagination.pageSize,
          ),
          currentPage: payload.data.pagination.pageIndex,
          currentItem: payload.data.pagination.pageSize,
        },
      }
    case SET_DETAIL_FRAUD_CONFIG:
      return {
        ...state,
        isLoading: false,
        isEdit: true,
        detailFraudConfig: payload.data.data,
      }
    case SET_EDIT_FRAUD_CONFIG:
      return {
        ...state,
        ...(!payload
          ? { detailFraudConfig: null }
          : { detailFraudConfig: state.detailFraudConfig }),
        isEdit: payload,
      }
    case SET_LIST_FRAUD_TYPES:
      return {
        ...state,
        listFraudTypes: payload.data.fraudTypes.map((i) => ({
          name: i.description,
          value: i.id,
        })),
      }
    default:
      return {
        ...state,
      }
  }
}
