import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  fetchRequests,
  fetchRequestDetail,
  fetchRejectReason,
  fetchRejectionDetail,
} from 'features/PricingEngine/redux/requests'
import type {
  RequestListItemType,
  GetPriceChangeDetailResponseType,
  GetRejectionDetailResponseType,
  ReasonType,
} from '../../services/request'

export interface RequestsStateType {
  requests: RequestListItemType[]
  selectedRequests: number[]
  requestDetail: Nullable<GetPriceChangeDetailResponseType['data']>
  rejectReasons: ReasonType[]
  rejectionDetail: Nullable<GetRejectionDetailResponseType['data']>
  isLoadingRejectionDetail: boolean
  reviewed: {
    requestIds: (string | number)[]
    /* would be in format: 'REQUEST_ID-LOCATION_ID-STATUS' */
    history: string[]
    approved: number
    rejected: number
  }
  pagination: {
    total_elements: number
    prev_cursor: string
    next_cursor: string
  }
  isLoading: boolean
  isLoadingDetail: boolean
  prevIds: string[]
}

const initialState: RequestsStateType = {
  requests: [],
  selectedRequests: [],
  requestDetail: null,
  rejectReasons: [],
  rejectionDetail: null,
  isLoadingRejectionDetail: false,
  reviewed: {
    requestIds: [],
    history: [],
    approved: 0,
    rejected: 0,
  },
  pagination: {
    total_elements: 0,
    prev_cursor: '',
    next_cursor: '',
  },
  isLoading: false,
  isLoadingDetail: false,
  prevIds: [],
}

const requestsSlice = createSlice({
  name: 'pricingEngineRequests',
  initialState,
  reducers: {
    selectRequest: (state, action: PayloadAction<number>) => {
      if (!state.selectedRequests.includes(action.payload)) {
        state.selectedRequests.push(action.payload)
      }
    },
    deselectRequest: (state, action: PayloadAction<number>) => {
      state.selectedRequests = state.selectedRequests.filter((id) => id !== action.payload)
    },
    selectAllRequests: (state, action: PayloadAction<number[]>) => {
      state.selectedRequests = action.payload
    },
    clearSelectedRequests: (state) => {
      state.selectedRequests = []
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    clearRequestDetail: (state) => {
      state.requestDetail = null
    },
    clearState: (state) => {
      state.requests = []
      state.selectedRequests = []
      state.requestDetail = null
      state.rejectReasons = []
      state.reviewed = initialState.reviewed
    },
    clearReviewed: (state) => {
      state.reviewed = initialState.reviewed
    },
    setPrevIds: (state, action: PayloadAction<string[]>) => {
      state.prevIds = action.payload
    },
    clearPrevIds: (state) => {
      state.prevIds = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRequests.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchRequests.fulfilled, (state, action) => {
      state.isLoading = false
      state.requests = action.payload.data
      state.pagination = action.payload.pagination
    })
    builder
      .addCase(fetchRequestDetail.pending, (state) => {
        state.isLoadingDetail = true
        state.requestDetail = null
      })
      .addCase(fetchRequestDetail.fulfilled, (state, action) => {
        state.isLoadingDetail = false
        state.requestDetail = action.payload.data
        const requestId = action.payload.data.id
        const approvedHistory = action.payload.data.details
          .filter((detail) => detail.status === 'APPROVED')
          .map((detail) => `${requestId}-${detail.location_id}-APPROVED`)

        const rejectedHistory = action.payload.data.details
          .filter((detail) => detail.status === 'REJECTED')
          .map((detail) => `${requestId}-${detail.location_id}-REJECTED`)

        state.reviewed = {
          requestIds: Array.from(
            new Map([...state.reviewed.requestIds, requestId].map((id) => [id, id])).values(),
          ),
          history: Array.from(
            new Map(
              [...state.reviewed.history, ...approvedHistory, ...rejectedHistory].map((h) => [
                h,
                h,
              ]),
            ).values(),
          ),
          approved: Array.from(
            new Map([...state.reviewed.history, ...approvedHistory].map((h) => [h, h])).values(),
          ).filter((h) => h.endsWith('-APPROVED')).length,
          rejected: Array.from(
            new Map([...state.reviewed.history, ...rejectedHistory].map((h) => [h, h])).values(),
          ).filter((h) => h.endsWith('-REJECTED')).length,
        }
      })
      .addCase(fetchRequestDetail.rejected, (state) => {
        state.isLoadingDetail = false
        state.requestDetail = null
        state.reviewed = initialState.reviewed
      })
      .addCase(fetchRejectReason.fulfilled, (state, action) => {
        state.rejectReasons = action.payload.data
      })
      .addCase(fetchRejectionDetail.pending, (state) => {
        state.isLoadingRejectionDetail = true
        state.rejectionDetail = null
      })
      .addCase(fetchRejectionDetail.fulfilled, (state, action) => {
        state.isLoadingRejectionDetail = false
        state.rejectionDetail = action.payload.data
      })
      .addCase(fetchRejectionDetail.rejected, (state) => {
        state.isLoadingRejectionDetail = false
        state.rejectionDetail = null
      })
  },
})

export const {
  selectRequest,
  deselectRequest,
  selectAllRequests,
  clearSelectedRequests,
  clearRequestDetail,
  clearState,
  clearReviewed,
  setPrevIds,
  clearPrevIds,
} = requestsSlice.actions
export default requestsSlice.reducer
