import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

export type WidgetVariantType =
  | 'WIDGET_SPECIAL'
  | 'WIDGET_TIERING'
  | 'WIDGET_BACKGROUND'
  | 'WIDGET_MEGATAB'
  | 'WIDGET_VOUCHER'

export type LoyaltyWidgetType = {
  id: number
  title: string
  is_active: boolean
  widget_type: {
    text: string
    key: WidgetVariantType
  }
}

type GetLoyaltyWidgetReponseType = {
  data: {
    widgets: LoyaltyWidgetType[]
  }
  pagination: {
    page_size: number
    page_index: number
    total_element: number
    number_of_elements: number
    total_pages: number
  }
}

type GetLoyaltyWidgetParamsType = Partial<{
  sort: string
  direction: 'asc' | 'desc'
  page_size: number
  page_index: number
}>

export type PutLoyaltyWidgetSortPayloadType = {
  widgets: {
    id: number
    position: number
  }[]
}

export type PutLoyaltyWidgetStatusByIdPayloadType = {
  is_active: boolean
}

export type GetWidgetVariantResponseType = {
  data: {
    types: LoyaltyWidgetType['widget_type'][]
  }
}

export type GetWidgetByIdResponseType = {
  data: {
    id: number
    background_url: string
    background_file_path: string
    title: string
    widget_type: LoyaltyWidgetType['widget_type']
    tabs: {
      background_url: string
      background_file_path: string
      title: string
      campaign_name: string
      campaign_id: number
    }[]
    loyalty_products: {
      id: number
      name: string
    }[]
  }
}

export type PostPutCommonLoyaltyWidgetPayloadType = {
  title: string
  widget_type: WidgetVariantType
}

export type PostPutLoyaltyWidgetSpecialPayloadType = PostPutCommonLoyaltyWidgetPayloadType & {
  widget_specials: {
    background_file_path: string
    tab_title: string
    campaign_id: number
  }[]
}

export type PostPutLoyaltyWidgetBackgroundPayloadType = PostPutCommonLoyaltyWidgetPayloadType & {
  background_file_path: string
  loyalty_product_ids: number[]
}

export type PostPutLoyaltyWidgetPayloadType =
  | PostPutCommonLoyaltyWidgetPayloadType
  | PostPutLoyaltyWidgetSpecialPayloadType
  | PostPutLoyaltyWidgetBackgroundPayloadType

export const getLoyaltyWidget = (params?: GetLoyaltyWidgetParamsType) =>
  axiosInstanceApiGateway.Get<GetLoyaltyWidgetReponseType, true>({
    url: '/loyalty/internal/v1/widget',
    params,
  })

export const putLoyaltyWidgetSort = (data: PutLoyaltyWidgetSortPayloadType) =>
  axiosInstanceApiGateway.Put({
    url: '/loyalty/internal/v1/widget/sort',
    data,
  })

export const putLoyaltyWidgetStatusById = (
  id: number,
  data: PutLoyaltyWidgetStatusByIdPayloadType,
) =>
  axiosInstanceApiGateway.Put({
    url: '/loyalty/internal/v1/widget/:id/status',
    urlParams: {
      id,
    },
    data,
  })

export const deleteLoyaltyWidgetById = (id: number) =>
  axiosInstanceApiGateway.Delete({
    url: '/loyalty/internal/v1/widget/:id',
    urlParams: {
      id,
    },
  })

export const getWidgetVariant = () =>
  axiosInstanceApiGateway.Get<GetWidgetVariantResponseType, true>({
    url: '/loyalty/internal/v1/widget/types',
  })

export const getWidgetById = (id: number) =>
  axiosInstanceApiGateway.Get<GetWidgetByIdResponseType, true>({
    url: 'loyalty/internal/v1/widget/:id',
    urlParams: {
      id,
    },
  })

export const postLoyaltyWidget = (payload: PostPutLoyaltyWidgetPayloadType) =>
  axiosInstanceApiGateway.Post({
    url: 'loyalty/internal/v1/widget',
    data: payload,
  })

export const putLoyaltyWidget = (id: number, payload: PostPutLoyaltyWidgetPayloadType) =>
  axiosInstanceApiGateway.Put({
    url: 'loyalty/internal/v1/widget/:id',
    urlParams: {
      id,
    },
    data: payload,
  })
