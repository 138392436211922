import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAllSegments } from 'features/CampaignAndDiscount/services/campaignAddManual'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getVoucherList,
  postMilestone,
  getMilestoneById,
  getVoucherById,
} from 'features/Promotion/services/milestoneAddEdit'
import { getImageRules, postImage } from 'utils/apiList/images'
import {
  MilestoneAddEditPayloadType,
  VoucherType,
} from 'features/Promotion/@types/MilestoneAddEdit'
import { toastFailed } from 'utils/toast'
import { userTypeOptions } from 'features/Promotion/pages/MilestoneAddEdit/constant'
import { unixToDateFormat } from 'features/CategoryAndPosisi/utils/date'

import {
  MILESTONE_ADD_EDIT_SLICE_NAME,
  setUserSegmentOptions,
  setVoucherOptions,
  resetGeneralForm,
  setMilestoneList,
  resetTncForm,
  setBackgroundImageRule,
  setMilestoneDetail,
} from './slice'

export const fetchUserSegments = createAsyncThunk(
  `${MILESTONE_ADD_EDIT_SLICE_NAME}/fetchUserSegments`,
  async (_, { dispatch }) => {
    try {
      const {
        data: { data },
      } = await getAllSegments()
      dispatch(setUserSegmentOptions(data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchVoucherList = createAsyncThunk(
  `${MILESTONE_ADD_EDIT_SLICE_NAME}/fetchVoucherList`,
  async (_, { dispatch }) => {
    try {
      const {
        data: { content },
      } = await getVoucherList()
      dispatch(setVoucherOptions(content))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchVoucherDetail = createAsyncThunk(
  `${MILESTONE_ADD_EDIT_SLICE_NAME}/fetchVoucherDetail`,
  async (id: number) => {
    try {
      const { data } = await getVoucherById(id)
      return data
    } catch (error) {
      callErrorMsg(error)
      return null
    }
  },
)

export const createMilestone = createAsyncThunk(
  `${MILESTONE_ADD_EDIT_SLICE_NAME}/createMilestone`,
  async (data: MilestoneAddEditPayloadType) => {
    try {
      const {
        data: { error },
      } = await postMilestone(data)

      if (error?.status) {
        toastFailed(error.message)
        return false
      }

      return true
    } catch (error) {
      callErrorMsg(error)
      return false
    }
  },
)

export const fetchMilestoneById = createAsyncThunk(
  `${MILESTONE_ADD_EDIT_SLICE_NAME}/fetchMilestoneById`,
  async (id: number, { dispatch, getState }) => {
    const {
      milestoneAddEdit: {
        general: { segmentOptions },
      },
    } = getState() as StoreStateType

    try {
      const {
        data: { data },
      } = await getMilestoneById(id)

      dispatch(setMilestoneDetail(data))

      const milestoneType =
        userTypeOptions.find(({ value }) => value === data.milestone_type) || null

      const segment = segmentOptions.find((item) => item.id === data.segment_id) || null

      dispatch(
        resetGeneralForm({
          milestone_name: data.milestone_name,
          milestone_title: data.milestone_title,
          milestoneType,
          segment,
          title_bg_color: data.title_bg_color,
          backgroundFile: null,
          background_url: data.background_url,
          startDate: unixToDateFormat(data.start_time, 'date'),
          startTime: unixToDateFormat(data.start_time, 'time'),
          endDate: unixToDateFormat(data.end_time, 'date'),
          endTime: unixToDateFormat(data.end_time, 'time'),
        }),
      )

      if (data.milestone_details.length) {
        const voucherDetails = await Promise.allSettled(
          data.milestone_details.map((item) =>
            dispatch(fetchVoucherDetail(item.reward_id)).unwrap(),
          ),
        )

        dispatch(
          setMilestoneList(
            data.milestone_details.map((item, index) => {
              const reward =
                voucherDetails[index].status === 'fulfilled'
                  ? (voucherDetails[index] as Awaited<PromiseFulfilledResult<VoucherType>>).value
                  : null

              return {
                minimum_purchase: `${item.minimum_purchase}`,
                transaction_count: item.transaction_count,
                reward_position: item.reward_position,
                reward_type: item.reward_type,
                reward,
              }
            }),
          ),
        )
      }

      dispatch(
        resetTncForm({
          tnc_title: data.tnc_title,
          tnc_summary: data.tnc_summary,
          tnc_detail: data.tnc_detail,
        }),
      )
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchBackgroundImageRule = createAsyncThunk(
  `${MILESTONE_ADD_EDIT_SLICE_NAME}/fetchBackgroundImageRule`,
  async (_, { dispatch }) => {
    try {
      const { data } = await getImageRules('milestone-home-background')

      dispatch(setBackgroundImageRule(data.data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const uploadBackgroundImage = createAsyncThunk(
  `${MILESTONE_ADD_EDIT_SLICE_NAME}/uploadBackgroundImage`,
  async (file: File, { getState }) => {
    const {
      auth: { userData },
    } = getState() as StoreStateType

    try {
      const { data } = await postImage('milestone-home-background', {
        created_by: userData.id,
        file_input: file,
      })

      return data.data?.images.length ? data.data.images : []
    } catch (error) {
      callErrorMsg(error)
      return []
    }
  },
)
