import { Input, InputWithLabel, TextBody } from 'components'
import { memo } from 'react'
import styled from 'styled-components'
import { dateFormat } from 'utils/helpers/date'

function VoucherPeriod({ forms, setForms }) {
  const handleChangeTime = (e) => {
    const {
      target: { value, name },
    } = e
    if (!value && (name === 'date_start' || name === 'date_end')) {
      setForms({ ...forms, [name]: dateFormat({ date: new Date(), format: 'YYYY-MM-DD' }) })
    } else {
      setForms({ ...forms, [name]: value })
    }
  }
  return (
    <Container>
      <Content>
        <Section ratio="5">
          <TextBody>Voucher Detail</TextBody>
        </Section>
        <Section ratio="6">
          <Row width="100%" flex>
            <Row width="48%" style={{ marginLeft: 'auto' }}>
              <TextBody>Tanggal Mulai</TextBody>
              <CostumInput
                iconName="calendar"
                bgIcon=""
                iconColor="secondaryText"
                iconPos="left"
                className="input-date mt-4"
                type="date"
                name="date_start"
                value={forms.date_start}
                onChange={handleChangeTime}
              />
            </Row>
            <Row width="48%" style={{ marginLeft: 'auto' }}>
              <CostumInputWithLabel
                label="Waktu Mulai"
                iconName="clockFull"
                iconPos="left"
                iconColor="grey"
                bgIcon="body"
                name="time_start"
                type="time"
                value={forms.time_start}
                onChange={handleChangeTime}
              />
            </Row>
          </Row>
          <Row width="100%" flex>
            <Row width="48%" style={{ marginLeft: 'auto' }}>
              <TextBody>Tanggal Berakhir</TextBody>
              <CostumInput
                iconName="calendar"
                bgIcon=""
                iconColor="secondaryText"
                iconPos="left"
                className="input-date mt-4"
                type="date"
                name="date_end"
                value={forms.date_end}
                onChange={handleChangeTime}
              />
            </Row>
            <Row width="48%" style={{ marginLeft: 'auto' }}>
              <CostumInputWithLabel
                label="Waktu Berakhir"
                iconName="clockFull"
                iconPos="left"
                iconColor="grey"
                bgIcon="body"
                name="time_end"
                type="time"
                value={forms.time_end}
                onChange={handleChangeTime}
              />
            </Row>
          </Row>
        </Section>
      </Content>
    </Container>
  )
}

export default memo(VoucherPeriod)

const Container = styled.div`
  width: 100%;
  margin-bottom: 24px;
  padding: 32px;
  background: #ffffff;
  box-shadow: 0px 1px 12px rgba(105, 110, 118, 0.12);
  border-radius: 16px;
`
const Content = styled.div`
  display: flex;
`

const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: ${({ ratio }) => ratio};
`

const Row = styled.div`
  width: ${({ width }) => (width ? width : '100%')};
  ${({ flex }) => (flex ? 'display:flex;' : '')}
`

const CostumInputWithLabel = styled(InputWithLabel)``
const CostumInput = styled(Input)``
