import { Typography, MenuItem, ListItemText } from '@astro-ui/components'

export type SelectNonePropsType = {
  onClick: React.MouseEventHandler<HTMLLIElement>
}

const SelectNone = ({ onClick }: SelectNonePropsType) => (
  <MenuItem value="" onClick={onClick}>
    <ListItemText>
      <Typography variant="body2">
        <em>None</em>
      </Typography>
    </ListItemText>
  </MenuItem>
)

export default SelectNone
