import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import { getImageRules, type ImageVariantIdType } from 'utils/apiList/images'
import {
  type GetCategoryParamsType,
  type GetTargetedUsersParamsType,
  type PutInfiniteScrollTypesType,
  getCategoryList,
  getCatalogueList,
  getLocationList,
  getTargetingTypes,
  getTargetedUsers,
  postInfiniteScrollTabbing,
  getInfiniteScrollTabbingDetail,
  putInfiniteScrollTabbing,
} from 'features/CategoryAndPosisi/services/infiniteScrollTabAddEdit'
import { getAllSegments } from 'features/BannerAndTicker/sevices/banner'
import { postImage } from 'utils/apiList/images'
import {
  FormType,
  InfiniteScrollTabCreatePayloadType,
} from 'features/CategoryAndPosisi/@types/InfiniteScrollTabAddEdit'
import {
  logicOptions,
  redirectionTypes,
} from 'features/CategoryAndPosisi/pages/InfiniteScrollTab/constant'
import { RedirectionEnum } from 'features/CategoryAndPosisi/@types/InfiniteScrollTab'
import { isValidDate } from 'utils/helpers/date'
import { dateFormat } from '@astronautsid/wpe-utils'
import {
  SLICE_NAME_INFINITE_SCROLL_TAB_ADD_EDIT,
  setImageRules,
  setConstants,
  setSelectedTabToEdit,
  setFlag,
  resetForm,
} from './slice'

export const fetchInfiniteScrollImageRule = createAsyncThunk(
  `${SLICE_NAME_INFINITE_SCROLL_TAB_ADD_EDIT}/fetchInfiniteScrollImageRule`,
  async (_, { dispatch }) => {
    try {
      const {
        data: { data },
      } = await getImageRules('infinite-scroll-tabbing-image')
      dispatch(setImageRules(data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchCategories = createAsyncThunk(
  `${SLICE_NAME_INFINITE_SCROLL_TAB_ADD_EDIT}/fetchCategories`,
  async (params: GetCategoryParamsType | undefined, { dispatch }) => {
    try {
      const {
        data: { content },
      } = await getCategoryList(params)
      dispatch(setConstants({ field: 'categories', value: content }))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchCatalogue = createAsyncThunk(
  `${SLICE_NAME_INFINITE_SCROLL_TAB_ADD_EDIT}/fetchCatalogue`,
  async (params: GetCategoryParamsType | undefined, { dispatch }) => {
    try {
      const {
        data: { content },
      } = await getCatalogueList(params)
      dispatch(setConstants({ field: 'catalogue', value: content }))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchLocations = createAsyncThunk(
  `${SLICE_NAME_INFINITE_SCROLL_TAB_ADD_EDIT}/fetchLocations`,
  async (_, { dispatch }) => {
    try {
      const {
        data: { data },
      } = await getLocationList()
      dispatch(setConstants({ field: 'locations', value: data }))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchTargetingTypes = createAsyncThunk(
  `${SLICE_NAME_INFINITE_SCROLL_TAB_ADD_EDIT}/fetchTargetingTypes`,
  async (_, { dispatch }) => {
    try {
      const { data } = await getTargetingTypes()
      dispatch(setConstants({ field: 'targetingTypes', value: data }))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchTargetedSegments = createAsyncThunk(
  `${SLICE_NAME_INFINITE_SCROLL_TAB_ADD_EDIT}/fetchTargetedSegments`,
  async (_, { dispatch }) => {
    try {
      const {
        data: { data },
      } = await getAllSegments()
      dispatch(setConstants({ field: 'targetedSegments', value: data }))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchTargetedUsers = createAsyncThunk(
  `${SLICE_NAME_INFINITE_SCROLL_TAB_ADD_EDIT}/fetchTargetedUsers`,
  async (params: Pick<GetTargetedUsersParamsType, 'number'> | undefined, { dispatch }) => {
    try {
      const {
        data: { content },
      } = await getTargetedUsers({
        number: params?.number || '',
        pageSize: 20,
      })
      dispatch(setConstants({ field: 'targetedUsers', value: content }))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const uploadInfiniteScrollTabImage = createAsyncThunk(
  `${SLICE_NAME_INFINITE_SCROLL_TAB_ADD_EDIT}/uploadInfiniteScrollTabImage`,
  async ({ file, typeId }: { file: File; typeId: ImageVariantIdType }, { getState }) => {
    const {
      auth: { userData },
    } = getState() as StoreStateType

    try {
      const { data } = await postImage(typeId, {
        created_by: userData.id,
        file_input: file,
      })

      return data.data?.images.length ? data.data.images : []
    } catch (error) {
      callErrorMsg(error)
      return []
    }
  },
)

export const createInfiniteScrollTabbing = createAsyncThunk(
  `${SLICE_NAME_INFINITE_SCROLL_TAB_ADD_EDIT}/createInfiniteScrollTabbing`,
  async (payload: InfiniteScrollTabCreatePayloadType) => {
    try {
      await postInfiniteScrollTabbing(payload)

      return true
    } catch (error) {
      callErrorMsg(error)
      return false
    }
  },
)

export const updateInfiniteScrollTabbing = createAsyncThunk(
  `${SLICE_NAME_INFINITE_SCROLL_TAB_ADD_EDIT}/updateInfiniteScrollTabbing`,
  async ({
    tabId,
    payload,
    updateType,
  }: {
    payload: InfiniteScrollTabCreatePayloadType
    tabId: number
    updateType?: PutInfiniteScrollTypesType
  }) => {
    try {
      await putInfiniteScrollTabbing(tabId, payload, updateType)

      return true
    } catch (error) {
      callErrorMsg(error)
      return false
    }
  },
)

export const fetchInfiniteScrollTabbingDetail = createAsyncThunk(
  `${SLICE_NAME_INFINITE_SCROLL_TAB_ADD_EDIT}/fetchInfiniteScrollTabbingDetail`,
  async (tabId: number, { dispatch, getState }) => {
    const {
      infiniteScrollTabAddEdit: { constants },
    } = getState() as StoreStateType

    try {
      const {
        data: { data, flag },
      } = await getInfiniteScrollTabbingDetail(tabId)

      dispatch(setSelectedTabToEdit({ ...data }))
      dispatch(setFlag(flag))

      const formPayload: FormType = {
        name: data.name,
        tabbingType: data.tabbing_type,
        redirection: redirectionTypes.find(({ value }) => value === data.redirection.type) || null,
        imageFile: null,
        imageUrls: data.infinite_scroll_tabbing_image,
        category:
          data.redirection.type === RedirectionEnum.CATEGORY
            ? {
                category_id: data.redirection.category_id,
                category_name: data.redirection.category_name,
              }
            : null,
        catalogue:
          data.redirection.type === RedirectionEnum.CATALOGUE
            ? {
                category_id: data.redirection.category_id,
                category_name: data.redirection.category_name,
              }
            : null,
        logic:
          data.redirection.type === RedirectionEnum.LOGIC
            ? logicOptions.find(({ value }) => value === data.redirection.logic) || null
            : null,
        startDate: getFormDate(data.start_date, 'date'),
        startTime: getFormDate(data.start_date, 'time'),
        endDate: getFormDate(data.end_date, 'date'),
        endTime: getFormDate(data.end_date, 'time'),
        locations: [...data.infinite_scroll_tabbing_hubs],
        isAllLocation: data.is_all_location,
        targetingType:
          constants.targetingTypes.find(({ id }) => id === data.targeting_type) || null,
        targetedSegments: data.targeted_segmentations.map((segment) => ({
          id: segment.segment_type_id,
          name: segment.segment_type_name,
          total_user: 0,
        })),
        targetedUsers: data.targeted_users.map((item) => ({
          customer_id: item.user_id,
          customer_name: item.user_name,
          customer_phone_number: item.phone,
        })),
      }

      dispatch(resetForm(formPayload))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

const getFormDate = (date: string, type: 'date' | 'time') => {
  if (!date || !isValidDate(date)) return ''

  return dateFormat({
    date: new Date(date),
    format: type === 'date' ? 'YYYY-MM-DD' : 'HH:mm',
  })
}
