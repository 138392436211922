import { X_DEVICE_DESKTOP, X_DEVICE_MOBILE } from 'constant/deviceType'
import getBrowserFingerprint from 'get-browser-fingerprint'
import UAParser from 'ua-parser-js'
import { v4 as uuidv4 } from 'uuid'

const uaParser = new UAParser.UAParser()

export const getUA = () => {
  try {
    const uastring = window.navigator.userAgent
    uaParser.setUA(uastring)
    return {
      os: uaParser.getOS(),
      browser: uaParser.getBrowser(),
      cpu: uaParser.getCPU(),
      device: uaParser.getDevice(),
      engine: uaParser.getEngine(),
    }
  } catch (err) {
    return null
  }
}

export const getDevice = () => {
  try {
    const uastring = window.navigator.userAgent
    uaParser.setUA(uastring)
    return uaParser.getDevice()
  } catch (err) {
    return null
  }
}

export const checkIsMobileDevice = () => getDevice()?.type === 'mobile'

export const getRequestXDevice = () => (checkIsMobileDevice() ? X_DEVICE_MOBILE : X_DEVICE_DESKTOP)

export const generateXDeviceId = async () => {
  try {
    return await getBrowserFingerprint()
  } catch {
    return uuidv4()
  }
}
