import { Button, SystemIcon, TextBody } from 'components'
import { memo, useContext } from 'react'
import storeContext from 'storeContext'
import { actHandleRemoveCustomer } from 'storeContext/actions/refferal'
import styled from 'styled-components'

function RowUserID({ data }) {
  const { dispatch } = useContext(storeContext.RefferalContext)
  const handleDeleteUserID = (cus) => {
    dispatch(actHandleRemoveCustomer(cus))
  }
  return (
    <Tr>
      <Td ratio="2">
        <TextBody weight="light"> {data.customer_id}</TextBody>
      </Td>
      <Td ratio="4">
        <TextBody weight="light">{data.customer_name || data.customer_phone_number}</TextBody>
      </Td>
      <Td ratio="1">
        <Button variant="wrapper" onClick={() => handleDeleteUserID(data)}>
          <ActionWrapper>
            <SystemIcon iconName="trash" colorIcon="secondaryText" />
          </ActionWrapper>
        </Button>
      </Td>
    </Tr>
  )
}
export default memo(RowUserID)

const Tr = styled.tr`
  display: flex;
  padding: 12px 24px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
`

const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`

const ActionWrapper = styled.div`
  margin-left: auto;
  text-align: right;
`

/*
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 12px;
`;


const CustomP = styled(TextBody)`
  max-width: 110px;
`;
*/
