import { memo } from "react";
import styled from "styled-components";
import { RowInbound, SystemIcon, NoData } from "components";

function TableInbound({
  data,
  type,
  handleClickEdit,
  handlePrint,
  handlePrintCompletSO
}) {
  return (
    <>
      <Container>
        <thead>
          <Tr>
            <Th ratio="3">
              NOMOR SO
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th ratio="3">
              SO CREATED TIME
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>

            <Th ratio="3">
              ASAL
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th ratio="3">
              TUJUAN
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th ratio="3">
              STATUS
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th ratio="2">AKSI</Th>
          </Tr>
        </thead>
        <tbody>
          {data.map((el) => (
            <RowInbound
              key={el.supply_order_id}
              data={el}
              type={type}
              handleClickEdit={handleClickEdit}
              handlePrint={handlePrint}
              handlePrintCompletSO={handlePrintCompletSO}
            />
          ))}
        </tbody>
      </Container>
      {data.length === 0 && <NoData />}
    </>
  );
}

export default memo(TableInbound);

const Container = styled.table`
  width: 100%;
`;
const Tr = styled.tr`
  display: flex;
  padding: 16px 32px;
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`;
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`;
