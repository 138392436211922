export const SET_IS_LOADING_CATALOGUE = 'SET_IS_LOADING_CATALOGUE/catalogue-page'
export const SET_CATALOGS = 'SET_CATALOGS/catalogue-page'
export const SET_PAGINATION_DATA = 'SET_PAGINATION_DATA/catalogue-page'

export const SET_SHOW_POPUP_ADD = 'catalogue-page/SET_SHOW_POPUP_ADD'
export const SET_SHOW_POPUP_EDIT = 'catalogue-page/SET_SHOW_POPUP_EDIT'
export const CATALOG_SEARCH = 'catalogue-page/CATALOG_SEARCH'
export const CATALOGUE_SET_CUSTOMER_LIST = 'catalogue-page/CATALOGUE_SET_CUSTOMER_LIST'
export const CATALOGUE_SET_SELECTED_CUSTOMER_LIST =
  'catalogue-page/CATALOGUE_SET_SELECTED_CUSTOMER_LIST'
export const CATALOGUE_SET_TARGETING_TYPE_LIST = 'catalogue-page/CATALOGUE_SET_TARGETING_TYPE_LIST'
