import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { productVendorAdd, productVendorEdit } from 'config/routes'
import { callErrorMsg } from 'helpers/errorMsg'
import { withLoadingReducer } from 'utils/reducerHandler'
import { queryParamsURL } from 'utils/queryParamsURL'
import {
  getPIC,
  getLocation,
  postProductCatalog,
  getPICSourcing,
  getProductCatalogDetail,
  patchProductCatalog,
} from 'utils/apiList/productVendor'
import { getProduct, getVendorList } from 'utils/api'
import { toastSuccess } from 'utils/toast'
import { getLocationTypeAPI } from 'utils/apiList/constant'

export const fetchGetProductDetail = createAsyncThunk(
  'productVendorAddSlice/fetchGetProductDetail',
  async (productId, { rejectWithValue }) => {
    try {
      const response = await getProduct(productId)
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue()
    }
  },
)

export const fetchGetVendors = createAsyncThunk(
  'productVendorAddSlice/fetchGetVendors',
  async (vendorName, { rejectWithValue }) => {
    try {
      const response = await getVendorList({
        page_size: 50,
        page_index: 1,
        company_name: vendorName || '',
      })
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue()
    }
  },
)

export const fetchGetPIC = createAsyncThunk(
  'productVendorAddSlice/fetchGetPIC',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getPIC({
        pageSize: 10000,
      })

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue()
    }
  },
)

export const fetchGetPICSourcing = createAsyncThunk(
  'productVendorAddSlice/fetchGetPICSourcing',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getPICSourcing({
        pageSize: 10000,
      })

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue()
    }
  },
)

export const fetchGetLocations = createAsyncThunk(
  'productVendorAddSlice/fetchGetLocations',
  async ({ search = '' } = {}, { rejectWithValue, getState }) => {
    const {
      productVendorAdd: { constants },
    } = getState()

    try {
      const response = await getLocation({
        pageSize: 10000,
        type: constants.selectedLocationType,
        name: search,
      })

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue()
    }
  },
)

export const fetchGetLocationType = createAsyncThunk(
  'productVendorAddSlice/fetchGetLocationType',
  async (_, { dispatch }) => {
    try {
      const {
        data: { data },
      } = await getLocationTypeAPI()
      const locationTypes = data.map((type) => type.name).filter((type) => type !== 'VIRTUAL')
      dispatch(setLocationType(locationTypes))
    } catch (err) {
      callErrorMsg(err)
    }
  },
)

export const fetchProductCatalog = createAsyncThunk(
  'productVendorAddSlice/fetchProductCatalog',
  async ({ productId, catalogId }, { dispatch, getState }) => {
    const {
      productVendorAdd: { constants },
    } = getState()

    try {
      const {
        data: { data },
      } = await getProductCatalogDetail(productId, catalogId)

      const selectedPreference = constants.preference.find(({ value }) => value === data.preference)
      dispatch(setForm({ key: 'preference', value: selectedPreference }))
      dispatch(setForm({ key: 'rebate', value: data.rebate }))
      dispatch(
        setForm({
          key: 'vendor',
          value: { id: data.vendor.vendorId, company_name: data.vendor.vendorName },
        }),
      )

      dispatch(
        setForm({
          key: 'pic',
          value: {
            id: data.pic.picId,
            name: data.pic.picName,
            email: data.pic.picEmail,
          },
        }),
      )
      dispatch(setForm({ key: 'price', value: `${parseInt(data.price)}` }))
      dispatch(
        setForm({
          key: 'picSourcing',
          value: {
            id: data.picSourcing.picId,
            full_name: data.picSourcing.picName,
            email: data.picSourcing.picEmail,
          },
        }),
      )
      dispatch(
        setLocation(
          data.locations.map((location) => ({
            location_id: location.locationId,
            location_name: location.locationName,
            location_type: location.locationType,
          })),
        ),
      )
      dispatch(setProductCatalog(data))
    } catch (err) {
      callErrorMsg(err)
    }
  },
)

export const fetchPostVendorCatalog = createAsyncThunk(
  'productVendorAddSlice/fetchPostVendorCatalog',
  async (navigateToProductVendor, { rejectWithValue, getState }) => {
    try {
      const { productId } = queryParamsURL.getParams(productVendorAdd)
      const { preference, vendor, pic, picSourcing, locations, price, rebate } =
        getState().productVendorAdd.form

      const response = await postProductCatalog(productId, {
        preference: preference.value,
        vendorId: vendor.id,
        picId: pic.id,
        picSourcingId: picSourcing.id,
        price: +price,
        rebate: +rebate,
        locations: locations.map((location) => location.location_id),
      })

      toastSuccess(response.data.message)
      navigateToProductVendor()

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue()
    }
  },
)

export const fetchPatchVendorCatalog = createAsyncThunk(
  'productVendorAddSlice/fetchPatchVendorCatalog',
  async (navigateToProductVendor, { rejectWithValue, getState }) => {
    try {
      const { productId, catalogId } = queryParamsURL.getParams(productVendorEdit)
      const { pic, picSourcing, locations, price, rebate } = getState().productVendorAdd.form

      const response = await patchProductCatalog(productId, catalogId, {
        picId: pic.id,
        picSourcingId: picSourcing.id,
        price: +price,
        rebate: +rebate,
        locations: locations.map((location) => location.location_id),
      })

      toastSuccess(response.data.message)
      navigateToProductVendor()

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue()
    }
  },
)

const initialState = {
  isLoading: false,
  constants: {
    preference: [...new Array(10)].map((_, index) => ({
      name: `Prioritas ke-${index + 1}`,
      value: index + 1,
    })),
    vendors: [],
    pic: [],
    picSourcing: [],
    locations: [],
    productCatalog: {},
    locationTypes: [],
    selectedLocationType: 'WAREHOUSE',
  },
  form: {
    preference: null,
    vendor: null,
    pic: null,
    product: '',
    picSourcing: null,
    locations: [],
    price: '',
    rebate: 0,
  },
}

const productVendorAddSlice = createSlice({
  name: 'productVendorSlice',
  initialState,
  reducers: {
    setForm: (state, action) => {
      const { key, value } = action.payload

      state.form[key] = value
    },
    setCheckedLocation: (state, { payload }) => {
      const isExist = state.form.locations.some(
        (location) => location.location_id === payload.location_id,
      )

      if (isExist) {
        state.form.locations = state.form.locations.filter(
          (location) => location.location_id !== payload.location_id,
        )
      } else {
        state.form.locations = [...state.form.locations, { ...payload }]
      }
    },
    setCheckedAllLocation: (state, action) => {
      const checked = action.payload

      if (checked) {
        state.form.locations = [
          ...state.form.locations.filter(
            (location) => location.location_type !== state.constants.selectedLocationType,
          ),
          ...state.constants.locations,
        ]
      } else {
        state.form.locations = [
          ...state.form.locations.filter(
            (location) => location.location_type !== state.constants.selectedLocationType,
          ),
        ]
      }
    },
    setLocation: (state, { payload }) => {
      state.form.locations = [...payload]
    },
    setProductCatalog: (state, { payload }) => {
      state.constants.productCatalog = { ...payload }
    },
    setLocationType: (state, { payload }) => {
      state.constants.locationTypes = [...payload]
    },
    setSelectedLocationType: (state, { payload }) => {
      state.constants.selectedLocationType = payload
    },
    setReset: () => {
      return initialState
    },
  },
  extraReducers: withLoadingReducer(
    (builder) => {
      builder
        .addCase(fetchGetProductDetail.fulfilled, (state, action) => {
          state.form.product = action.payload.product_name
        })
        // =====
        .addCase(fetchGetVendors.fulfilled, (state, action) => {
          state.constants.vendors = action.payload.data
        })
        // =====
        .addCase(fetchGetPIC.fulfilled, (state, action) => {
          const { data } = action.payload

          state.constants.pic = data
        })
        // =====
        .addCase(fetchGetPICSourcing.fulfilled, (state, action) => {
          const { content } = action.payload

          state.constants.picSourcing = content
        })
        // =====
        .addCase(fetchGetLocations.fulfilled, (state, action) => {
          state.constants.locations = action.payload.content
        })
    },
    [fetchGetProductDetail, fetchPostVendorCatalog],
  ),
})

export const {
  setForm,
  setCheckedLocation,
  setCheckedAllLocation,
  setReset,
  setProductCatalog,
  setLocation,
  setLocationType,
  setSelectedLocationType,
} = productVendorAddSlice.actions
export default productVendorAddSlice.reducer
