import {
  ListItemText as MuiListItemText,
  ListItemTextProps as MuiListItemTextProps,
} from '@mui/material'
import styled from 'styled-components'

export type ListItemTextPropsType = MuiListItemTextProps

const ListItemText = styled(MuiListItemText)<ListItemTextPropsType>``

export default ListItemText
