/* eslint-disable sonarjs/no-identical-functions */
import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  ActionReducerMapBuilder,
} from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'

import {
  getAllBrandV2,
  getBrandCompany,
  postBrand,
  putBrand,
  putBrandCloudKitchen,
} from 'utils/apiList/brandMaster'
import type {
  GetAllBrandV2ResponseType,
  GetAllBrandV2,
  GetBrandCompany,
  GetBrandCompanyResponseType,
  PutBrandCloudKitchen,
  PutBrandCloudKitchenResponseType,
  PostBrand,
  PostBrandResponseType,
  PutBrand,
  PutBrandResponseType,
} from 'utils/apiList/brandMaster'
import { withLoadingReducer } from 'utils/reducerHandler'
import { callErrorMsg } from 'helpers/errorMsg'
import { toastNeutral } from 'utils/toast'
import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'

export interface FormStateInterface {
  id?: number
  name?: string
  cloudKitchen?: boolean
  brandCompanyName?: string
}

export type BrandMasterStateType = {
  isShowDialog: boolean
  isLoadingBrand: boolean
  isLoadingCompany: boolean
  indexCloudKitchen: number
  keyword: string
  brands: GetAllBrandV2ResponseType
  formState: FormStateInterface
  isShowResultsSearch: boolean
  companies: GetBrandCompanyResponseType
  willUseNewCompany: boolean
  countReload: number
}

export const fetchGetAllBrandV2 = createAsyncThunk<
  GetAllBrandV2ResponseType,
  GetAllBrandV2,
  RejectValueType
>('brandMaster/fetchGetAllBrandV2', async (params, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<GetAllBrandV2ResponseType> = await getAllBrandV2(params)

    return response.data
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchGetBrandCompany = createAsyncThunk<
  GetBrandCompanyResponseType,
  GetBrandCompany,
  RejectValueType
>('brandMaster/fetchGetBrandCompany', async (params, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<GetBrandCompanyResponseType> = await getBrandCompany(params)

    return response.data
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchPutBrandCloudKitchen = createAsyncThunk<
  PutBrandCloudKitchenResponseType,
  PutBrandCloudKitchen,
  RejectValueType
>('brandMaster/fetchPutBrandCloudKitchen', async (params, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<PutBrandCloudKitchenResponseType> = await putBrandCloudKitchen(
      params,
    )

    return response.data
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchPostBrand = createAsyncThunk<PostBrandResponseType, PostBrand, RejectValueType>(
  'brandMaster/fetchPostBrand',
  async (params, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<PostBrandResponseType> = await postBrand(params)

      toastNeutral('Brand berhasil ditambahkan')

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchPutBrand = createAsyncThunk<PutBrandResponseType, PutBrand, RejectValueType>(
  'brandMaster/fetchPutBrand',
  async (params, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<PutBrandResponseType> = await putBrand(params)

      toastNeutral('Brand berhasil diedit')

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

const initialState: BrandMasterStateType = {
  isShowDialog: false,
  isLoadingBrand: true,
  isLoadingCompany: true,
  keyword: '',
  indexCloudKitchen: 0,
  brands: {
    data: [],
    pagination: {
      direction: 'asc',
      numberOfElements: 1,
      pageIndex: 1,
      pageSize: 20,
      sort: 'created_at',
      totalElements: 0,
      totalPages: 0,
    },
  },
  formState: {
    id: 0,
    name: '',
    cloudKitchen: false,
    brandCompanyName: '',
  },
  isShowResultsSearch: false,
  companies: {
    data: [],
    pagination: {
      direction: 'asc',
      numberOfElements: 1,
      pageIndex: 1,
      pageSize: 20,
      sort: 'name',
      totalElements: 0,
      totalPages: 0,
    },
  },
  willUseNewCompany: false,
  countReload: 1,
}

const brandMaster = createSlice({
  name: 'brandMaster',
  initialState,
  reducers: {
    setKeyword: (state, action: PayloadAction<string>) => {
      state.keyword = action.payload
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.brands.pagination.pageIndex = action.payload
    },
    setIndexCloudKitchen: (state, action: PayloadAction<number>) => {
      state.indexCloudKitchen = action.payload
    },
    setFormState: (state, action: PayloadAction<FormStateInterface>) => {
      state.formState = {
        ...state.formState,
        ...action.payload,
      }
    },
    setResetForm: (state) => {
      state.formState = initialState.formState
    },
    setIsShowResultsSearch: (state, action: PayloadAction<boolean>) => {
      state.isShowResultsSearch = action.payload
    },
    setLoadingCompany: (state, action: PayloadAction<boolean>) => {
      state.isLoadingCompany = action.payload
    },
    setWillUseNewCompany: (state, action: PayloadAction<boolean>) => {
      state.willUseNewCompany = action.payload
    },
    setShowDialog: (state, action: PayloadAction<boolean>) => {
      state.isShowDialog = action.payload
    },
    setCountReload: (state) => {
      state.countReload += 1
    },
    setLoadingBrand: (state) => {
      state.isLoadingBrand = true
    },
  },
  extraReducers: withLoadingReducer(
    (builder: ActionReducerMapBuilder<BrandMasterStateType>) => {
      builder
        .addCase(fetchGetAllBrandV2.fulfilled, (state, action) => {
          state.brands = action.payload
          state.isLoadingBrand = false
        })
        .addCase(fetchPutBrandCloudKitchen.fulfilled, (state, action) => {
          state.brands.data[state.indexCloudKitchen].cloud_kitchen =
            action.payload.data.cloud_kitchen
        })
        .addCase(fetchGetBrandCompany.fulfilled, (state, action) => {
          state.companies = action.payload
          state.isLoadingCompany = false
        })
        .addCase(fetchPostBrand.fulfilled, (state) => {
          state.formState = initialState.formState
          state.isShowDialog = false
          state.countReload += 1
          brandMaster.caseReducers.setCountReload(state)
        })
        .addCase(fetchPutBrand.fulfilled, (state) => {
          state.formState = initialState.formState
          state.isShowDialog = false
          state.countReload += 1
          brandMaster.caseReducers.setCountReload(state)
        })
    },
    [fetchGetAllBrandV2, fetchPutBrandCloudKitchen, fetchGetBrandCompany],
  ),
})

export const {
  setKeyword,
  setPage,
  setIndexCloudKitchen,
  setFormState,
  setResetForm,
  setIsShowResultsSearch,
  setLoadingCompany,
  setWillUseNewCompany,
  setShowDialog,
  setLoadingBrand,
} = brandMaster.actions
export default brandMaster.reducer
