import axios from 'axios'
import axiosInstanceApiGateway from 'config/apiServiceApiGateway/index'

const { Get, Post, Put } = axiosInstanceApiGateway
const { REACT_APP_BASE_URL_API } = process.env
const baseUrl = REACT_APP_BASE_URL_API

export const getScheduleCloudKitchenProductHub = ({ productId, locationId }) =>
  axios.get(`${baseUrl}/api/cloud-kitchen/schedule/${productId}/${locationId}`)

export const postScheduleCloudKitchenProductHub = (payload) =>
  axios.post(`${baseUrl}/api/cloud-kitchen/schedule`, payload)

export const getCloudKitchenBulkUploadTemplate = (params) =>
  axios.get(`${baseUrl}/api/cloud-kitchen/schedule/template`, { params })

export const postUploadCloudKitchenBulkUpload = (data, config) =>
  axios.post(`${baseUrl}/api/cloud-kitchen/schedule/bulk-upload`, data, config)

export const getDataAndStatusAstroKitchenOrder = (orderId) =>
  Get({ url: `/fulfillment/internal/v1/kitchen/orders/${orderId}` })

export const postDataAndCompleteAstroKitchen = (orderId, payload) =>
  Post({ url: `/fulfillment/internal/v1/kitchen/orders/${orderId}/finish`, data: payload })

export const putDataUpdateItemOrderKitchen = (orderId, payload) =>
  Put({ url: `/fulfillment/internal/v1/kitchen/orders/${orderId}/items`, data: payload })

export const postAuthKitchenStaff = (payload) => {
  axios.post(`${baseUrl}/api/admin/kitchen-auth`, payload)
}
