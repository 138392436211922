export const SET_IS_START_ADMIN = 'SET_IS_START_ADMIN'
export const SET_IS_FAILED_ADMIN = 'SET_IS_FAILED_ADMIN'
export const SET_IS_SUCCESS_ADMIN = 'SET_IS_SUCCESS_ADMIN'
export const SET_IS_NEED_TO_RELOAD_ADMIN = 'SET_IS_NEED_TO_RELOAD_ADMIN'
export const SET_PAGINATION_DATA_ADMIN = 'SET_PAGINATION_DATA_ADMIN'
export const SET_LIST_LOCATION_WAREHOUSE = 'SET_LIST_LOCATION_WAREHOUSE'
export const SET_LIST_LOCATION_HUB = 'SET_LIST_LOCATION_HUB'
export const SET_LIST_ROLES_WAREHOUSE = 'SET_LIST_ROLES_WAREHOUSE'
export const SET_LIST_ROLES_HUB = 'SET_LIST_ROLES_HUB'
export const SET_LIST_OF_POSITION = 'SET_LIST_OF_POSITION'
