import styled from 'styled-components'
import { Typography, Divider } from '@astro-ui/components'
import QRCode from 'react-qr-code'
import { shallowEqual, useAppSelector } from 'store'

const TOPPING_CHAR_LIMIT = 43

const TemplateKitchenModifierReceipt = () => {
  const { astroKitchenLabel } = useAppSelector((state) => state.printArea, shallowEqual)
  if (!astroKitchenLabel) return null
  return astroKitchenLabel.map((item, index) => {
    const topping = item.addOns
      .map((addOn) => addOn.modifier_variants.map((variant) => variant.name).join(','))
      .join(',')
    return (
      // eslint-disable-next-line react/no-array-index-key
      <Wrapper key={index}>
        <Container className="top">
          <Typography
            sx={{
              display: '-webkit-box',
              fontWeight: 800,
              fontSize: '18px',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: 'rgba(0, 0, 0, 1)',
            }}
          >
            {item.productName}
          </Typography>
          {item?.modifiers?.length > 0 && (
            <ListContainer>
              <Typography
                sx={{
                  fontWeight: 800,
                  fontSize: '14px',
                  lineHeight: '12px',
                }}
              >
                Bahan Dasar ({`${item.modifiers.length}`})
              </Typography>
              <ul>
                {item.modifiers.slice(0, 5).map((variant) => (
                  <li key={variant.id}>
                    <Typography variant="smallRegular">{variant.name}</Typography>{' '}
                    {variant.modifier_variants.map((variantType, i) => (
                      <Typography key={variantType.id} variant="smallRegular">
                        {variantType.name}
                        {i === variant.modifier_variants.length - 1 ? '' : `, `}
                      </Typography>
                    ))}
                  </li>
                ))}
              </ul>
            </ListContainer>
          )}
          {item?.addOns?.length > 0 && (
            <ToppingContainer>
              <Typography
                sx={{
                  fontWeight: 800,
                  fontSize: '14px',
                  lineHeight: '12px',
                }}
              >
                Tambahan ({`${item?.addOns?.length}`})
              </Typography>
              <Typography
                sx={{
                  display: '-webkit-box',
                  fontSize: '14px',
                  lineHeight: '12px',
                  WebkitLineClamp: topping.length >= TOPPING_CHAR_LIMIT ? 1 : 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: 'rgba(0, 0, 0, 1)',
                }}
              >
                {topping}
              </Typography>
            </ToppingContainer>
          )}
        </Container>
        {topping.length >= TOPPING_CHAR_LIMIT && (
          <>
            <Divider sx={{ borderColor: '#000000', borderStyle: 'dashed' }} />
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                justifyContent: 'center',
                fontWeight: 800,
                lineHeight: '12px',
                fontSize: '12px',
                fontStyle: 'italic',
                textTransform: 'uppercase',
                marginY: '4px',
              }}
            >
              *Catatan Lengkap Lihat Aplikasi*
            </Typography>
          </>
        )}
        <Divider sx={{ borderColor: '#000000', borderStyle: 'dashed', marginBottom: '10px' }} />
        <Container>
          <QrContainer>
            <CustomerContainer>
              <Typography
                sx={{ fontWeight: 800, lineHeight: '12px', fontSize: '14px', color: '#000000' }}
              >
                {item.customer}
              </Typography>
              <Typography sx={{ fontWeight: 400, lineHeight: '12px', fontSize: '12px' }}>
                Item ID: {item.itemId}
              </Typography>
              <Typography sx={{ fontWeight: 400, lineHeight: '12px', fontSize: '12px' }}>
                Order ID: {item.orderId}
              </Typography>
            </CustomerContainer>
            <QRCode value={`kitchen-${item.itemId}`} size={80} />
          </QrContainer>
        </Container>
      </Wrapper>
    )
  })
}

export default TemplateKitchenModifierReceipt

const Wrapper = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  padding-bottom: 0.3125in;
  padding-top: 0.3125in;
  -webkit-print-color-adjust: exact;
  @media print {
    -webkit-print-color-adjust: exact;
    page-break-inside: avoid;
    break-inside: avoid;
    clear: both;
    @page {
      margin: 0mm;
    }
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  &.top {
    display: flex;
    flex-grow: 1;
  }
`

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #212121;
  ul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
    color: #212121;
    li {
      font-size: 7px;
      font-weight: 400;
      line-height: 12px;
      color: #212121;
      span {
        font-weight: 800;
        color: #212121;
        line-height: 12px;
      }
      &:before {
        content: '-';
        margin-right: 2px;
        color: #212121;
        line-height: 12px;
      }
    }
  }
`

const ToppingContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: #212121;
`

const QrContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`

const CustomerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
