import axiosInstanceApiKong from 'config/apiServiceApiGateway'

type GetUserListRequestType =
  | Partial<{
      page_index: string | number
      page_size: string | number
      name: string
    }>
  | undefined

export type GetUserListResponseType = {
  users: {
    email: string
    full_name: string
    position: string
    id: number
    phone_number: string
    roles: {
      id: number
      name: string
    }[]
    active: boolean
  }[]
}

export type GetUserDetailResponseType = {
  email: string
  full_name: string
  position: string
  id: number
  phone_number: string
  roles: {
    id: number
    name: string
    location_ids: number[]
  }[]
  active: boolean
}

export type PostUserRequestType = {
  email: string
  is_super_admin: boolean
  locations: {
    is_deleted?: boolean
    id: number
  }[]
  name: string
  phone_number: string
  position: string
  roles: {
    id: number
    is_deleted?: boolean
  }[]
}

export type PostUserResponseType = {
  data: {
    id: number
  }
  error: {
    status: boolean
    message: string
    code: number
  }
}

export type PutUserRequestType = {
  id: number
  payload: PostUserRequestType
}

export type PutResetAccountRequestType = {
  phone_number: string
}

export type PutResetAccountResponseType = {
  data: {
    is_success: boolean
  }
  error: {
    status: boolean
    message: string
    code: number
  }
}

export type PatchActivateAccountResponseType = {
  data: {
    is_success: boolean
  }
  error: {
    status: boolean
    message: string
    code: number
  }
}

export const getUserListAPI = (params: GetUserListRequestType) =>
  axiosInstanceApiKong.Get<GetUserListResponseType>({
    url: '/accounts/internal/v2/user',
    params,
  })

export const getUserDetailAPI = (id: number) =>
  axiosInstanceApiKong.Get<GetUserDetailResponseType>({
    url: `/accounts/internal/v2/user/${id}`,
  })

export const postUserAPI = (payload: PostUserRequestType) =>
  axiosInstanceApiKong.Post<PostUserResponseType>({
    url: '/accounts/internal/v2/user',
    data: payload,
  })

export const putUserAPI = ({ id, payload }: PutUserRequestType) =>
  axiosInstanceApiKong.Put<PostUserResponseType>({
    url: `/accounts/internal/v2/user/${id}`,
    data: payload,
  })

export const putResetAccountAPI = (payload: PutResetAccountRequestType) =>
  axiosInstanceApiKong.Put<PutResetAccountResponseType>({
    url: '/accounts/internal/v1/user/totp/reset',
    data: payload,
  })

export const patchActivateAccountAPI = (userId: number) =>
  axiosInstanceApiKong.Patch<PatchActivateAccountResponseType>({
    url: `/accounts/internal/v1/user/activate/${userId}`,
  })

export const patchDeactivateAccountAPI = (userId: number) =>
  axiosInstanceApiKong.Patch<PatchActivateAccountResponseType>({
    url: `/accounts/internal/v1/user/deactivate/${userId}`,
  })

export const getRolesAPI = (params: GetUserListRequestType) =>
  axiosInstanceApiKong.Get<{
    roles: {
      id: number
      name: string
    }[]
  }>({
    url: '/auth/internal/v1/role',
    params,
  })
