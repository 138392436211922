import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { ImageRulesType } from '@astronautsid/wpe-utils'
import {
  type InitialStateType,
  type PopupStateType,
  type SetFormPayloadType,
  type SetConstantPayloadType,
  type FormType,
} from 'features/CategoryAndPosisi/@types/InfiniteScrollTabAddEdit'
import {
  InfiniteScrollTabbingType,
  InfiniteScrollTabFlagType,
  TabbingTypeEnum,
} from 'features/CategoryAndPosisi/@types/InfiniteScrollTab'

export const SLICE_NAME_INFINITE_SCROLL_TAB_ADD_EDIT = 'infiniteScrollTabAddEdit'

const initialState: InitialStateType = {
  isLoading: false,
  popupState: 'CLOSED',
  form: {
    tabbingType: TabbingTypeEnum.DEFAULT,
    name: '',
    redirection: null,
    imageFile: null,
    imageUrls: null,
    category: null,
    catalogue: null,
    logic: null,
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    locations: [],
    isAllLocation: false,
    targetingType: null,
    targetedSegments: [],
    targetedUsers: [],
  },
  imageRules: null,
  constants: {
    catalogue: [],
    categories: [],
    locations: [],
    targetingTypes: [],
    targetedSegments: [],
    targetedUsers: [],
  },
  flag: {
    default_wording: '',
    is_custom_disable: true,
  },
  selectedTabToEdit: {},
}

const slice = createSlice({
  name: SLICE_NAME_INFINITE_SCROLL_TAB_ADD_EDIT,
  initialState,
  reducers: {
    resetState: () => initialState,
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setPopupState: (state, { payload }: PayloadAction<PopupStateType>) => {
      state.popupState = payload
    },
    setForm: (state, { payload }: PayloadAction<SetFormPayloadType>) => {
      const { field, value } = payload
      state.form[field] = value as never
    },
    setImageRules: (state, { payload }: PayloadAction<Nullable<ImageRulesType>>) => {
      state.imageRules = payload
    },
    setConstants: (state, { payload }: PayloadAction<SetConstantPayloadType>) => {
      state.constants[payload.field] = payload.value as never
    },
    resetForm: (state, { payload }: PayloadAction<FormType | undefined>) => {
      state.form = typeof payload !== 'undefined' ? { ...payload } : initialState.form
    },
    setFlag: (state, { payload }: PayloadAction<InfiniteScrollTabFlagType>) => {
      state.flag = { ...payload }
    },
    setSelectedTabToEdit: (
      state,
      { payload }: PayloadAction<InfiniteScrollTabbingType | ObjectNullType>,
    ) => {
      state.selectedTabToEdit = { ...payload }
    },
  },
})

export const {
  resetState,
  setIsLoading,
  setPopupState,
  setForm,
  setImageRules,
  setConstants,
  resetForm,
  setFlag,
  setSelectedTabToEdit,
} = slice.actions
export default slice.reducer
