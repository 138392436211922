import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { coinExchange } from 'config/routes'
import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'
import { history } from 'store'
import { AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  createRedeemCoin,
  deleteRedeemCoin,
  getRedeemCoin,
  getRedeemCoinCategory,
  getRedeemCoinLocationsByType,
  getRedeemCoinLocationType,
  getRedeemCoinProductsByLocationId,
  updateRedeemCoin,
  updateRedeemCoinStatus,
  type BodyLoyaltyExchangeCoinEditCoinType,
  type BodyLoyaltyRedeemCoinCreateType,
  type LoyaltyExchangeCoinHubListType,
  type LoyaltyExchangeCoinProductParamsType,
  type LoyaltyExchangeCoinResponseType,
} from 'utils/apiList/redeemCoin'
import { toastFailed, toastSuccess } from 'utils/toast'
import type { LoyaltyExchangeCoinType } from './loyaltyExchangeCoin.types'

const dateNow = dayjs().format('YYYY-MM-DD')
const timeNow = dayjs().format('HH:mm')

export const initialState: LoyaltyExchangeCoinType = {
  params: {
    param: '',
    sort: 'updated_at',
    direction: 'desc',
    pageSize: 10,
    pageIndex: 0,
  },
  needToReload: 0,
  paramsProductList: {
    param: '',
    categoryId: 0,
    direction: 'ASC',
    sort: 'productName',
    pageSize: 20,
    pageIndex: 0,
  },
  productSelected: [],
  productList: [],
  checkList: [],
  bulkEdit: {
    startDate: dateNow,
    startTime: timeNow,
    endDate: dateNow,
    endTime: timeNow,
    coin: 0,
    quota: 0,
    isActive: false,
    isSpecial: false,
    isAllDay: false,
  },
  detail: null,
  isLoading: false,
  isLoadingProductList: false,
  isShowEdit: false,
  data: [],
  // Form state
  search: '',
  selectedLocationType: null,
  selectedLocation: null,
  selectedCategory: null,
  dropdownCategory: [],
  dropdownLocationType: [],
  dropdownLocationsByType: [],
}

const loyaltyExchangeCoin = createSlice({
  name: 'loyaltyExchangeCoin',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<LoyaltyExchangeCoinType['isLoading']>) => {
      state.isLoading = action.payload
    },
    setIsLoadingProductList: (
      state,
      action: PayloadAction<LoyaltyExchangeCoinType['isLoading']>,
    ) => {
      state.isLoadingProductList = action.payload
    },
    setBulkEdit: (state, action: PayloadAction<LoyaltyExchangeCoinType['bulkEdit']>) => {
      state.bulkEdit = action.payload
    },
    setIsShowEdit: (state, action: PayloadAction<LoyaltyExchangeCoinType['isShowEdit']>) => {
      state.isShowEdit = action.payload
    },
    setCheckList: (state, action: PayloadAction<LoyaltyExchangeCoinType['checkList']>) => {
      state.checkList = action.payload
    },
    setData: (state, action: PayloadAction<LoyaltyExchangeCoinType['data']>) => {
      state.data = action.payload
    },
    setProductSelected: (
      state,
      action: PayloadAction<LoyaltyExchangeCoinType['productSelected']>,
    ) => {
      state.productSelected = action.payload
    },
    setParams: (
      state,
      action: PayloadAction<LoyaltyExchangeCoinType['params'] & { reload?: boolean }>,
    ) => {
      const { reload, ...rest } = action.payload

      state.params = rest

      if (reload) {
        state.needToReload += 1
      }
    },
    setParamsProductList: (
      state,
      action: PayloadAction<LoyaltyExchangeCoinType['paramsProductList']>,
    ) => {
      state.paramsProductList = action.payload
    },
    setProductList: (state, action: PayloadAction<LoyaltyExchangeCoinType['productList']>) => {
      state.productList = action.payload
    },
    setDropdownCategory: (
      state,
      action: PayloadAction<LoyaltyExchangeCoinType['dropdownCategory']>,
    ) => {
      state.dropdownCategory = action.payload
    },
    setDropdownLocationType: (
      state,
      action: PayloadAction<LoyaltyExchangeCoinType['dropdownLocationType']>,
    ) => {
      state.dropdownLocationType = action.payload
    },
    setDropdownLocationsByType: (
      state,
      action: PayloadAction<LoyaltyExchangeCoinType['dropdownLocationsByType']>,
    ) => {
      state.dropdownLocationsByType = action.payload
    },
    resetBulkEdit: (state) => {
      state.bulkEdit = initialState.bulkEdit
    },
    resetDropdownLocationsByType: (state) => {
      state.dropdownLocationsByType = initialState.dropdownLocationsByType
    },
    setSearch: (state, action: PayloadAction<LoyaltyExchangeCoinType['search']>) => {
      state.search = action.payload
    },
    setSelectedLocationType: (
      state,
      action: PayloadAction<LoyaltyExchangeCoinType['selectedLocationType']>,
    ) => {
      state.selectedLocationType = action.payload
    },
    setSelectedLocation: (
      state,
      action: PayloadAction<LoyaltyExchangeCoinType['selectedLocation']>,
    ) => {
      state.selectedLocation = action.payload
    },
    setSelectedCategory: (
      state,
      action: PayloadAction<LoyaltyExchangeCoinType['selectedCategory']>,
    ) => {
      state.selectedCategory = action.payload
    },
    resetForm: (state) => {
      state.params = initialState.params
      state.productList = initialState.productList
      state.productSelected = initialState.productSelected
      state.paramsProductList = initialState.paramsProductList
      state.dropdownCategory = initialState.dropdownCategory
      state.dropdownLocationsByType = initialState.dropdownLocationsByType
      state.dropdownLocationType = initialState.dropdownLocationType
      state.search = initialState.search
      state.selectedLocationType = initialState.selectedLocationType
      state.selectedLocation = initialState.selectedLocation
      state.selectedCategory = initialState.selectedCategory
    },
    reset: (state) => {
      Object.assign(state, { ...initialState })
    },
  },
})

export const {
  reset,
  setCheckList,
  setBulkEdit,
  setIsLoading,
  setIsShowEdit,
  setData,
  setParams,
  setProductList,
  setIsLoadingProductList,
  setParamsProductList,
  setProductSelected,
  setSearch,
  setSelectedLocationType,
  setSelectedLocation,
  setSelectedCategory,
  resetForm,
  resetBulkEdit,
  setDropdownCategory,
  setDropdownLocationType,
  setDropdownLocationsByType,
  resetDropdownLocationsByType,
} = loyaltyExchangeCoin.actions

export const getData = createAsyncThunk(
  'loyaltyExchangeCoin/getData',
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      dispatch(setIsLoading(true))

      const {
        loyaltyExchangeCoin: { params },
      } = getState() as StoreStateType

      const {
        data: {
          data,
          pagination: { pageSize, pageIndex },
        },
      } = await getRedeemCoin({
        param: params.param,
        sort: params.sort,
        direction: params.direction,
        page_index: params.pageIndex,
        page_size: params.pageSize,
      })

      dispatch(setData(data?.redeemRewards || []))

      dispatch(setParams({ ...params, pageIndex, pageSize }))

      return true
    } catch (error) {
      toastFailed('Gagal Load Data')
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const putEditCoin = createAsyncThunk(
  'loyaltyExchangeCoin/putEditCoin',
  async (_, { dispatch, rejectWithValue, getState }) => {
    dispatch(setIsLoading(true))
    const {
      loyaltyExchangeCoin: { checkList, bulkEdit, params },
    } = getState() as StoreStateType

    try {
      await updateRedeemCoin({
        ids: checkList,
        coin: bulkEdit.coin,
        quota: bulkEdit.quota,
        isActive: bulkEdit.isActive,
        isSpecial: bulkEdit.isSpecial,
        // startAt: dayjs(`${bulkEdit.startDate} ${bulkEdit.startTime}`).unix(),
        // endAt: dayjs(`${bulkEdit.endDate} ${bulkEdit.endTime}`).unix(),
        startAt: 0,
        endAt: 0,
      })

      dispatch(setIsShowEdit(initialState.isShowEdit))
      dispatch(setBulkEdit(initialState.bulkEdit))
      dispatch(setCheckList(initialState.checkList))
      dispatch(setParams({ ...params, pageIndex: initialState.params.pageIndex }))
      dispatch(getData())

      toastSuccess('Berhasil Edit Koin')
      return true
    } catch (error) {
      toastFailed('Gagal Edit Koin')
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const putEditCoinSingle = createAsyncThunk(
  'loyaltyExchangeCoin/putEditCoinSingle',
  async (
    { id, data }: { id: number; data: BodyLoyaltyExchangeCoinEditCoinType },
    { dispatch, rejectWithValue, getState },
  ) => {
    dispatch(setIsLoading(true))
    const {
      loyaltyExchangeCoin: { params },
    } = getState() as StoreStateType

    try {
      await updateRedeemCoin({
        ids: [id],
        coin: data.coin,
        quota: data.quota,
        isActive: data.isActive,
        isSpecial: data.isSpecial,
        startAt: 0,
        endAt: 0,
        // startAt: data.startAt,
        // endAt: data.endAt,
      })

      dispatch(setCheckList(initialState.checkList))
      dispatch(setParams({ ...params, pageIndex: initialState.params.pageIndex }))
      dispatch(getData())

      toastSuccess('Berhasil Edit Koin')
      return true
    } catch (error) {
      toastFailed('Gagal Edit Koin')
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const deleteEditCoinSingle = createAsyncThunk<
  LoyaltyExchangeCoinResponseType,
  number,
  RejectValueType
>(
  'loyaltyExchangeCoin/deleteEditCoinSingle',
  async (id, { dispatch, rejectWithValue, getState }) => {
    dispatch(setIsLoading(true))
    const {
      loyaltyExchangeCoin: { params },
    } = getState() as StoreStateType

    try {
      const res: AxiosResponse<LoyaltyExchangeCoinResponseType> = await deleteRedeemCoin(id)

      dispatch(setCheckList(initialState.checkList))
      dispatch(setParams({ ...params, pageIndex: initialState.params.pageIndex }))
      dispatch(getData())

      toastSuccess('Berhasil Hapus Koin')
      return res.data
    } catch (error) {
      toastFailed('Gagal Hapus Koin')
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const putStatus = createAsyncThunk<
  LoyaltyExchangeCoinResponseType,
  { id: number; active: boolean },
  RejectValueType
>(
  'loyaltyExchangeCoin/putStatus',
  async (
    { id, active }: { id: number; active: boolean },
    { dispatch, rejectWithValue, getState },
  ) => {
    dispatch(setIsLoading(true))
    const {
      loyaltyExchangeCoin: { params },
    } = getState() as StoreStateType

    try {
      const res = await updateRedeemCoinStatus(id, {
        isAvailable: active,
      })

      dispatch(setParams({ ...params, pageIndex: initialState.params.pageIndex }))
      dispatch(getData())

      toastSuccess('Berhasil Edit Status')
      return res.data
    } catch (error) {
      toastFailed('Gagal Edit Status')
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const getProducts = createAsyncThunk(
  'loyaltyExchangeCoin/getProducts',
  async (
    {
      locationId,
      params: newParams,
    }: { locationId: number; params: Partial<LoyaltyExchangeCoinProductParamsType> },
    { dispatch, rejectWithValue, getState },
  ) => {
    dispatch(setIsLoadingProductList(true))

    const {
      loyaltyExchangeCoin: { paramsProductList },
    } = getState() as StoreStateType

    const nextParams = {
      ...paramsProductList,
      ...newParams,
    }

    try {
      const res = await getRedeemCoinProductsByLocationId(
        locationId,
        nextParams as LoyaltyExchangeCoinProductParamsType,
      )
      const {
        data: {
          data,
          pagination: { pageIndex },
        },
      } = res

      dispatch(setProductList(data.products))
      dispatch(
        setParamsProductList({
          ...(nextParams as LoyaltyExchangeCoinProductParamsType),
          pageIndex,
        }),
      )

      return res.data
    } catch (error) {
      callErrorMsg(error, 'Gagal load Data Produk')
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    } finally {
      dispatch(setIsLoadingProductList(false))
    }
  },
)

export const postRedeemCoin = createAsyncThunk(
  'loyaltyExchangeCoin/postRedeemCoin',
  async (_, { dispatch, rejectWithValue, getState }) => {
    dispatch(setIsLoadingProductList(true))
    const {
      loyaltyExchangeCoin: { productSelected, selectedLocation },
    } = getState() as StoreStateType

    const locationId = selectedLocation?.id ?? 0

    const payload: BodyLoyaltyRedeemCoinCreateType = {
      redeemRewardRequests: productSelected.map((productId) => ({
        productId,
        locationId,
        coin: 0,
        quota: 0,
        isActive: false,
        isSpecial: false,
        startAt: 0,
        endAt: 0,
      })),
    }

    try {
      await createRedeemCoin(payload)
      toastSuccess('Berhasil Tambah Koin ditukar')
      dispatch(resetForm())
      return history.push(coinExchange)
    } catch (error) {
      callErrorMsg(error, 'Gagal Tambah Koin ditukar')
      return rejectWithValue(error)
    } finally {
      dispatch(setIsLoadingProductList(false))
    }
  },
)

export const getDropdownCategory = createAsyncThunk(
  'loyaltyExchangeCoin/getDropdownCategory',
  async (_, { dispatch }) => {
    try {
      const {
        data: { content },
      } = await getRedeemCoinCategory()
      dispatch(
        setDropdownCategory(
          content.map((i) => ({
            id: i.category_id,
            name: i.category_name,
          })),
        ),
      )
      return true
    } catch (error) {
      dispatch(setDropdownCategory(initialState.dropdownCategory))
      toastFailed('Gagal Load Data Kategori')
      return false
    }
  },
)

export const getDropdownLocationType = createAsyncThunk(
  'loyaltyExchangeCoin/getDropdownLocationType',
  async (_, { dispatch }) => {
    try {
      const {
        data: { data },
      } = await getRedeemCoinLocationType()
      dispatch(setDropdownLocationType(data.map((i) => ({ ...i, selected: false }))))
      return true
    } catch (error) {
      dispatch(setDropdownLocationType(initialState.dropdownLocationType))
      toastFailed('Gagal Load Data Location Type')
      return false
    }
  },
)

export const getDropdownLocationsByType = createAsyncThunk(
  'loyaltyExchangeCoin/getDropdownLocationsByType',
  async (type: string, { dispatch }) => {
    try {
      const { data } = await getRedeemCoinLocationsByType(type)
      dispatch(
        setDropdownLocationsByType(
          data.map((i: LoyaltyExchangeCoinHubListType) => ({
            id: i.location_id,
            name: i.location_name,
            selected: false,
          })),
        ),
      )
      return true
    } catch (error) {
      dispatch(setDropdownLocationsByType(initialState.dropdownLocationsByType))
      toastFailed('Gagal Load Data Locations By Type')
      return false
    }
  },
)

export default loyaltyExchangeCoin.reducer
