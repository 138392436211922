import axios from 'axios'
import { Get, Delete } from 'config/apiServiceConfig'
import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

import { PaymentChannelTypeEnum } from 'utils/apiList/paymentChannel'

const { Get: GetKong, Post: PostKong, Put: PutKong } = axiosInstanceApiGateway

const baseUrl = process.env.REACT_APP_BASE_URL_API
const consumerAPI = process.env.REACT_APP_BASE_URL_API_CONSUMER

export type VoucherBenefitType = {
  name: 'Diskon' | 'Free Ongkir' | 'Cashback'
  icon: string
}

export type VoucherTargetedType = {
  name: 'Global User' | 'Targeted User' | 'New User' | 'Partnership Payment'
}

export type VoucherMechanismType = {
  name: 'Input Code' | 'Auto Apply' | 'Auto Inject' | 'Loyalty User'
}

export type VoucherRuleCategoryType = {
  id: number
  name: string
}

export type VoucherRuleProductType = {
  id: number
  name: string
  sku_number: string
}

export type VoucherRuleLocationType = {
  id: number
  name: string
}

export type VoucherRulePartnershipType = {
  id: number
  name: string
  bin: string
  icon_url: string
  channel_type: PaymentChannelTypeEnum
}

export type VoucherRuleSLAType = {
  id: number
  name: string
}

export type VoucherRuleType = {
  /**
   * 'sla' stands for 'service level agreement'
   */
  key: 'category' | 'product' | 'hub' | 'partnership' | 'sla'
  items: (
    | VoucherRuleCategoryType
    | VoucherRuleProductType
    | VoucherRuleLocationType
    | VoucherRulePartnershipType
    | VoucherRuleSLAType
  )[]
  is_exclude: boolean
}

export interface VoucherType {
  voucher_id: number
  voucher_code: string
  voucher_image_url: string
  benefit_type: VoucherBenefitType['name']
  targeting_type: VoucherTargetedType['name']
  mechanism_type: VoucherMechanismType['name']
  quota_user: number
  targeted_user: { id: number; full_name: string }
  minimum_purchase: number
  voucher_amount: number
  voucher_percentage: number
  voucher_start_at: number
  voucher_end_at: number
  payment_channel_id: number
  payment_channel_name: string
  payment_channel_code: string
  quota_all: number
  is_quota_unlimited: boolean
  coin_amount: number
  quota_redemption: number
  is_unlimited_redemption: boolean
  rules: VoucherRuleType[]
  content: {
    description: string
    tnc: string // HTML string
    banner_image: string
  }
  cost_setting: {
    astro_percentage: number
    partner_name: string[]
  }
  is_daily_repeat: boolean
  daily_quota: number
  segment: {
    segment_id: number
    segment_value: string
  }
  voucher_image_url_ineligible: string
}

export type PostPutVoucherDetailRequestType = {
  voucher_code: string
  voucher_image: string
  benefit_type: VoucherBenefitType['name']
  targeting_type: VoucherTargetedType['name']
  mechanism_type: VoucherMechanismType['name']
  quota_user: number
  targeted_user: number[]
  minimum_purchase: number
  voucher_amount: number
  voucher_percentage: number
  voucher_start_at: number
  voucher_end_at: number
  payment_channel_id: number
  quota_all: number
  is_quota_unlimited: boolean
  rules: {
    key: VoucherRuleType['key']
    value_int?: number[]
    value_str?: string[]
    is_exclude: boolean
  }[]
  content?: {
    description: string
    tnc: string
    banner: string
  }
}

export type GetShippingCostConfigResponseType = {
  data: VoucherRuleSLAType[]
}

export interface VoucherUploadTargetUsersRequestType {
  data: File
}

export interface VoucherUploadTargetUsersResponseType {
  status: string
  urlDownload: string
}

export interface GetUserTargetedListResponseType {
  content: [
    {
      id: number
      user_name: string
      phone_number: string
    },
  ]
  pageable: {
    sort: {
      unsorted: boolean
      sorted: boolean
      empty: boolean
    }
    pageSize: number
    pageNumber: number
    offset: number
    unpaged: boolean
    paged: boolean
  }
  last: boolean
  totalPages: number
  totalElements: number
  first: boolean
  sort: {
    unsorted: boolean
    sorted: boolean
    empty: boolean
  }
  numberOfElements: number
  size: number
  number: number
  empty: boolean
}

export interface GetUserTargetedListRequestType {
  id: string
  params: {
    pageIndex?: string
    phoneNumber?: string
    sort?: string
    direction?: string
  }
}

export type DeleteCustomerVoucherRequestType = {
  payload: { customer_id: number; voucher_id: number }
}

export type VoucherUserSegmentType = {
  id: number
  name: string
}

export const getVoucherBenefit = () => axios.get(`${baseUrl}/api/voucher/benefit`)
export const getVoucherTargeted = () => axios.get(`${baseUrl}/api/voucher/targeted`)
export const getMechanism = () => axios.get(`${baseUrl}/api/voucher/mechanism`)
export const getInternalPaymentChannel = () =>
  axios.get(`${consumerAPI}/api/internal/payment-channel`)

export const getVoucherById = (voucherId: number) =>
  GetKong<{ content: VoucherType }>({
    url: '/promo/internal/:version/voucher/:voucherId',
    urlParams: { voucherId },
    version: 'v1',
  })

export const postVoucher = (data: PostPutVoucherDetailRequestType) =>
  PostKong({
    url: '/promo/internal/:version/voucher',
    version: 'v1',
    data,
  })

export const putVoucher = (voucherId: number, data: PostPutVoucherDetailRequestType) =>
  PutKong({
    url: '/promo/internal/:version/voucher/:voucherId',
    version: 'v1',
    urlParams: { voucherId },
    data,
  })

export const getShippingCostConfig = () =>
  Get<GetShippingCostConfigResponseType>({ url: '/api/promo/shipping-cost-config' })

export const postVoucherUploadTargetUsers = ({ data }: VoucherUploadTargetUsersRequestType) =>
  axios.post<VoucherUploadTargetUsersResponseType>(
    `${baseUrl}/api/voucher/csv/upload-user?uploadOnly=true`,
    data,
    {
      headers: {
        /* eslint-disable @typescript-eslint/naming-convention */
        'Content-Type': 'multipart/form-data',
      },
    },
  )

export const getUserTargetedList = ({ id, params }: GetUserTargetedListRequestType) =>
  axios.get<GetUserTargetedListResponseType>(`${baseUrl}/api/voucher/${id}/user-targeted`, {
    params,
  })

export const deleteCustomerVoucher = ({ payload }: DeleteCustomerVoucherRequestType) =>
  Delete({ url: '/api/promo/voucher/customer-vouchers', config: { data: payload } })

export const getUserSegmentList = () =>
  GetKong<VoucherUserSegmentType[]>({
    url: '/segmentation/internal/:version/segment',
    version: 'v1',
  })
