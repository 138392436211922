import { Get } from 'config/apiServiceConfig'

export type GetProductListV2RequestType = Partial<{
  sort: string
  direction: string
  pageSize: number
  name: string
  categoryIDs: number[]
  isActive: boolean
}>

export type GetProductListV2ResponseType = {
  data: {
    baseUom: string
    productID: number
    productSkuNo: string
    productName: string
    categories: {
      categoryId: number
      categoryName: string
      categoryType: string
    }[]
    active: boolean
    images: {
      imageURLLarge: string
      imageURLMedium: string
      imageURLSmall: string
    }
  }[]
  error: {
    status: boolean
    message: string
    code: number
  }
  message: string | null
  pagination: {
    sort: string
    direction: string
    pageSize: number
    pageIndex: number
    numberOfElements: number
  }
}

export type GetVendorCountRequestType = {
  id: number
}

export type GetVendorCountResponseType = {
  data: {
    vendorCount: number
  }
}
export type GetBusinessOrCompanyResponseType = {
  data: {
    id: number
    type: string
    key: string
    value: string
  }[]
}

export type ListType = 'BUSINESS_UNIT' | 'BUSINESS_TAGGING' | 'COMPANY'

type GetBusinessOrCompanyQueryParamsType = {
  type: ListType
}

export const getProductV2API = (params: GetProductListV2RequestType | undefined) =>
  Get<GetProductListV2ResponseType>({
    url: '/api/ims/v2/products',
    params,
  })

export const getVendorCountAPI = ({ id }: GetVendorCountRequestType) =>
  Get<GetVendorCountResponseType>({
    url: `/api/ims/v2/product/${id}/vendor-count`,
  })

export const getProductAttributeOption = (params: GetBusinessOrCompanyQueryParamsType) =>
  Get<GetBusinessOrCompanyResponseType>({
    url: `/api/ims/v2/product/attribute-option`,
    params,
  })
