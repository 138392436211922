import { memo } from 'react'
import styled from 'styled-components'
import { SystemIcon, NoData, RowPurchaseOrder } from 'components'

function TablePurchaseOrder({ data, handleClickOption }) {
  return (
    <>
      <Container>
        <thead>
          <Tr>
            <Th>
              NOMOR PO
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th>ID REFERENSI</Th>
            <Th>NAMA VENDOR</Th>
            <Th>
              TANGGAL BUAT
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th>JML ITEM</Th>
            <Th>JML KUANTITAS</Th>
            <Th>
              STATUS
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th>AKSI</Th>
          </Tr>
        </thead>
        <tbody>
          {data.map((el) => (
            <RowPurchaseOrder key={el.id} data={el} handleClickOption={handleClickOption} />
          ))}
        </tbody>
      </Container>
      {data.length === 0 && <NoData />}
    </>
  )
}

TablePurchaseOrder.defaultProps = {
  data: [],
}

export default memo(TablePurchaseOrder)

const Container = styled.table`
  width: 100%;
  border-collapse: collapse;
  height: fit-content;
`
const Tr = styled.tr`
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  text-align: start;
  padding: 16px 32px;
`
