import AxiosInstance from 'config/apiServiceApiGateway'

export const getVendors = (params) =>
  AxiosInstance.Get({
    url: `/erp/internal/v2/vendors`,
    params,
    convertResponse: true,
    convertRequest: true,
  })

export const getVendorDetails = (vendor_id) =>
  AxiosInstance.Get({
    url: `/erp/internal/v2/vendor/${vendor_id}`,
  })

export const createNewVendor = (data) =>
  AxiosInstance.Post({
    url: `/erp/internal/v2/vendor`,
    data,
  })

export const putVendor = (data) =>
  AxiosInstance.Put({
    url: `/erp/internal/v2/vendor`,
    data,
  })
