/* eslint-disable jsx-a11y/control-has-associated-label */
import styled from 'styled-components'

import { Box, Typography } from '@astro-ui/components'
import logoAstro from 'assets/images/logo-black.png'

/* eslint-disable jsx-a11y/control-has-associated-label */
function TemplatePrintSTOBeritaAcara() {
  return (
    <StyledContainer>
      <Box padding="30px 24px 18px" borderBottom=" 1px solid #d6dfeb">
        <img src={logoAstro} alt="logo" />
      </Box>
      <Box padding="18px 24px" display="flex" justifyContent="space-between">
        <Box>
          <Typography fontSize={16} fontWeight={700} mb="8px">
            Berita Acara Stock Opname Hub
          </Typography>
          <Typography fontSize={16} fontWeight={700} mb="8px">
            Hari / Tanggal :
            <Typography display="inline-block" fontWeight={400} ml="8px">
              30 Jun 2021 / 10:39
            </Typography>
          </Typography>
          <Typography fontSize={16} fontWeight={700} mb="8px">
            Nama Manager Area :
            <Box borderBottom="1px dotted #212121" width={150} display="inline-block" ml={12} />
          </Typography>
        </Box>
        <Box pt={28}>
          <Typography textAlign="right" fontSize={16} fontWeight={700} mb="8px">
            Lokasi :
            <Typography display="inline-block" fontWeight={400} ml="4px">
              KOS - Kosambi
            </Typography>
          </Typography>
          <Typography textAlign="right" fontSize={16} fontWeight={700} mb="8px">
            Nama PIC Supervisor :
            <Typography display="inline-block" fontWeight={400} ml="4px">
              Randi Nainggolan
            </Typography>
          </Typography>
        </Box>
      </Box>
      <Box padding="0 24px 0">
        <StyledTable>
          <tr>
            <th rowSpan={2}>No.</th>
            <th rowSpan={2} className="text-left">
              SLOC
            </th>
            <th rowSpan={2}>Package ID</th>
            <th rowSpan={2}>No. SKU</th>
            <th rowSpan={2}>SKU Name</th>
            <th colSpan={3}>System</th>
            <th colSpan={3}>Physical</th>
            <th rowSpan={2}>Gap</th>
            <th rowSpan={2}>Expiry Date</th>
          </tr>
          <tr>
            <th style={{ width: 50 }}>Availabel</th>
            <th style={{ width: 50 }}>Bad / Damage</th>
            <th style={{ width: 30 }}>Lost</th>
            <th style={{ width: 50 }}>Availabel</th>
            <th style={{ width: 50 }}>Bad / Damage</th>
            <th style={{ width: 30 }}>Lost</th>
          </tr>
          <tr>
            <td>1</td>
            <td>L3-D4-C2-1</td>
            <td>23456789098765</td>
            <td>1234567890981</td>
            <td>Mie Goreng Spesial</td>
            <td>51</td>
            <td>2</td>
            <td>2</td>
            <td>51</td>
            <td>2</td>
            <td>2</td>
            <td>0</td>
            <td>22 Jan 2022</td>
          </tr>
          <tr>
            <td>2</td>
            <td>L3-D4-C2-1</td>
            <td>23456789098765</td>
            <td>1234567890981</td>
            <td>Mie Goreng Spesial</td>
            <td>51</td>
            <td>2</td>
            <td>2</td>
            <td>51</td>
            <td>2</td>
            <td>2</td>
            <td>0</td>
            <td>22 Jan 2022</td>
          </tr>
          <tr className="total-table">
            <td className="text-left" colSpan={5}>
              Total
            </td>
            <td>102</td>
            <td>4</td>
            <td>4</td>
            <td>102</td>
            <td>4</td>
            <td>4</td>
            <td>0</td>
            <td />
          </tr>
        </StyledTable>
        <Box display="flex" justifyContent="end" mt={24}>
          <Box width={400}>
            <Typography
              fontSize={16}
              fontWeight="700"
              border="1px solid #D6DFEB"
              padding="12px 24px"
              borderBottom="none"
            >
              TTD PIC
            </Typography>
            <Box display="flex" flexWrap="wrap">
              <Box
                height={120}
                width={200}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                border="1px solid #D6DFEB"
                borderBottom="none"
                borderRight="none"
                pt={12}
                pb={24}
              >
                <Typography textAlign="center" fontSize={12} color="#6D7588">
                  Propose Adjustment By
                </Typography>
                <Typography textAlign="center" fontWeight={700}>
                  Agung Septiyadi
                </Typography>
              </Box>
              <Box
                height={120}
                width={200}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                border="1px solid #D6DFEB"
                pt={12}
                pb={24}
              >
                <Typography textAlign="center" fontSize={12} color="#6D7588">
                  Adjustment By,
                </Typography>
                <Typography textAlign="center" fontWeight={700}>
                  Agung S
                </Typography>
              </Box>
              <Box
                height={120}
                width={200}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                border="1px solid #D6DFEB"
                pt={12}
                pb={24}
              >
                <Typography />
                <Typography textAlign="center" fontWeight={700}>
                  Agung Septiyadi
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  @media print {
    @page {
      margin: 0;
    }
    body {
      padding-top: 72px;
      padding-bottom: 72px;
      padding-left: 0;
      padding-right: 0;
    }
  }
`

const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;

  & th {
    background-color: #f3f4f5;
    padding: 16px 4px;
  }

  & td {
    padding: 4px;
  }

  & th.text-left,
  td.text-left {
    text-align: left;
  }

  & th,
  td {
    font-size: 11px;
    border: 1px solid #d6dfeb;
    text-align: center;
  }

  .total-table td {
    background-color: #fffae6;
    padding: 12px;

    &:first-child {
      font-weight: 700;
    }
  }
`

export default TemplatePrintSTOBeritaAcara
