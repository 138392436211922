import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getWatchTowerConfig,
  putWatchTowerConfig,
  PutWatchTowerConfigRequestType,
  getLocationHubList,
  postHubExclusion,
  getHubExclusionList,
  putHubExclusion,
  deleteHubExclusion,
} from 'utils/apiList/fraudSetting'

import type {
  PostHubExclusionRequestType,
  PutHubExclusionRequestType,
  DeleteHubExclusionRequestType,
} from 'utils/apiList/fraudSetting'

const SLICE_NAME = 'fraudSetting'

export const fetchWatchTowerConfig = createAsyncThunk(
  `${SLICE_NAME}/fetchWatchTowerConfig`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getWatchTowerConfig()
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateWatchTowerConfig = createAsyncThunk(
  `${SLICE_NAME}/updateWatchTowerConfig`,
  async (payload: PutWatchTowerConfigRequestType, { rejectWithValue }) => {
    try {
      const res = await putWatchTowerConfig(payload)
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchLocationHubList = createAsyncThunk(
  `${SLICE_NAME}/fetchLocationHubList`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getLocationHubList()
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchHubExclusionList = createAsyncThunk(
  `${SLICE_NAME}/fetchHubExclusionList`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getHubExclusionList()
      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const createHubExclusion = createAsyncThunk(
  `${SLICE_NAME}/createHubExclusion`,
  async (params: PostHubExclusionRequestType, { rejectWithValue, dispatch }) => {
    try {
      const res = await postHubExclusion(params)
      await dispatch(fetchHubExclusionList())
      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateHubExclusion = createAsyncThunk(
  `${SLICE_NAME}/updateHubExclusion`,
  async (params: PutHubExclusionRequestType, { rejectWithValue, dispatch }) => {
    try {
      const res = await putHubExclusion(params)
      await dispatch(fetchHubExclusionList())
      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const destroyHubExclusion = createAsyncThunk(
  `${SLICE_NAME}/destroyHubExclusion`,
  async (params: DeleteHubExclusionRequestType, { rejectWithValue, dispatch }) => {
    try {
      const res = await deleteHubExclusion(params)
      await dispatch(fetchHubExclusionList())
      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
