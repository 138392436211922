import { Box } from '@astro-ui/components'
import { DEVICE_TYPE } from 'constant/deviceType'
import useDeviceDetect from 'customHooks/useDeviceDetect'

const DeviceWrapper = ({ show = [DEVICE_TYPE.desktop], children, ...rest }) => {
  const { device } = useDeviceDetect()

  if (show.includes(device)) {
    return <Box {...rest}>{children}</Box>
  }

  return null
}

export default DeviceWrapper
