const getParams = (paramsKey) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(paramsKey);
};

export const objectToQueryParams = (object) => {
  return object ? `?${new URLSearchParams(object).toString()}` : "";
};

export default getParams;
