import { toastFailed, toastFailedBig } from 'utils/toast'

/**
 *
 * @param {AxiosResponse} errorResponse
 */
export const getParsedErrorResponse = (errorResponse) => {
  const message =
    errorResponse?.data?.error?.message ||
    errorResponse?.data?.error?.msg ||
    errorResponse?.data?.message ||
    errorResponse?.data?.error_message

  return message || `Failed with status code ${errorResponse.status}`
}

export const callErrorMsg = (resError, defaultError, toastOptions) => {
  const errorResponse = resError?.response

  if (errorResponse) {
    const message = getParsedErrorResponse(errorResponse)

    if (message) toastOptions?.big ? toastFailedBig(message) : toastFailed(message, toastOptions)
    else
      toastOptions?.big
        ? toastFailedBig(defaultError || `Failed with status code ${errorResponse.status}`)
        : toastFailed(
            defaultError || `Failed with status code ${errorResponse.status}`,
            toastOptions,
          )
  }
}
