import {
  SET_IS_START_CAMPAIGN,
  SET_IS_FAILED_CAMPAIGN,
  SET_IS_HUB_LIST_DATA_CAMPAIGN_ADD_MANUAL,
  SET_IS_CATEGORY_LIST_DATA_CAMPAIGN,

  //CAMPAIGN
  SET_IS_SUCCESS_CAMPAIGN,
  SET_PAGINATION_DATA_CAMPAIGN,
  SET_IS_NEED_TO_RELOAD_CAMPAIGN,

  //CAMPAIGN_ADD
  SET_IS_SUCCESS_CAMPAIGN_ADD,
  SET_PAGINATION_DATA_CAMPAIGN_ADD,
  SET_IS_NEED_TO_RELOAD_CAMPAIGN_ADD,
  SET_IS_DETAIL_DATA_CAMPAIGN_ADD,
  RESET_CAMPAIGN,

  //CAMPAIGN_ADD_MANUAL
  SET_IS_SUCCESS_CAMPAIGN_ADD_MANUAL,
  SET_PAGINATION_DATA_CAMPAIGN_ADD_MANUAL,
  SET_IS_NEED_TO_RELOAD_CAMPAIGN_ADD_MANUAL,
} from 'storeContext/actionsType/campaign'

export const initialStateCampaign = {
  isLoading: false,
  campaignAddManual_HubListData: [],
  categoryListData: [],

  //CAMPAIGN
  campaignData: [],
  campaignPaginationData: {},
  campaignNeedToReload: 1,
  //CAMPAIGN ADD
  campaignAdd_Data: [],
  campaignAdd_DetailData: {},
  campaignAdd_PaginationData: {},
  campaignAdd_NeedToReload: 1,
  //CAMPAIGN ADD MANUAL
  campaignAddManual_Data: [],
  campaignAddManual_PaginationData: {},
  campaignAddManual_NeedToReload: {},
}

export default function campaign(state = initialStateCampaign, { type, payload }) {
  switch (type) {
    case SET_IS_START_CAMPAIGN:
      return {
        ...state,
        isLoading: true,
      }
    case SET_IS_FAILED_CAMPAIGN:
      return {
        ...state,
        isLoading: false,
      }
    case SET_IS_HUB_LIST_DATA_CAMPAIGN_ADD_MANUAL:
      return {
        ...state,
        campaignAddManual_HubListData: payload,
      }
    case SET_IS_CATEGORY_LIST_DATA_CAMPAIGN:
      return {
        ...state,
        categoryListData: payload,
      }

    //CAMPAIGN
    case SET_IS_SUCCESS_CAMPAIGN:
      return {
        ...state,
        isLoading: false,
        campaignData: payload,
      }
    case SET_PAGINATION_DATA_CAMPAIGN:
      return {
        ...state,
        campaignPaginationData: payload,
      }

    case SET_IS_NEED_TO_RELOAD_CAMPAIGN:
      return {
        ...state,
        campaignNeedToReload: state.campaignNeedToReload + 1,
      }

    //CAMPAIGN ADD
    case SET_IS_SUCCESS_CAMPAIGN_ADD:
      return {
        ...state,
        isLoading: false,
        campaignAdd_Data: payload,
      }

    case SET_PAGINATION_DATA_CAMPAIGN_ADD:
      return {
        ...state,
        campaignAdd_PaginationData: payload,
      }
    case SET_IS_NEED_TO_RELOAD_CAMPAIGN_ADD:
      return {
        ...state,
        campaignAdd_NeedToReload: state.campaignAdd_NeedToReload + 1,
      }
    case SET_IS_DETAIL_DATA_CAMPAIGN_ADD:
      return {
        ...state,
        campaignAdd_DetailData: payload,
      }

    //CAMPAIGN ADD MANUAL
    case SET_IS_SUCCESS_CAMPAIGN_ADD_MANUAL:
      return {
        ...state,
        campaignAddManual_Data: payload,
      }

    case SET_PAGINATION_DATA_CAMPAIGN_ADD_MANUAL:
      return {
        ...state,
        campaignAddManual_PaginationData: payload,
      }
    case SET_IS_NEED_TO_RELOAD_CAMPAIGN_ADD_MANUAL:
      return {
        ...state,
        campaignAddManual_NeedToReload: payload,
      }
    case RESET_CAMPAIGN:
      return {
        ...initialStateCampaign,
      }

    //DEFAULT
    default:
      return {
        ...state,
      }
  }
}
