import { memo } from "react";
import styled from "styled-components";
import { TextBody, Toggle, Button, SystemIcon } from "components";

function RowFraudSetting({ data, handleChangeStatus, handleEdit }) {
  return (
    <Tr>
      <Td>
        <TextBody size="smaller" weight="light">
          {data?.createdBy}
        </TextBody>
      </Td>
      <Td>
        <TextBody size="smaller" weight="light">
          {data?.date}
        </TextBody>
      </Td>
      <Td>
        <TextBody size="smaller" weight="light">
          {data?.fraudType}
        </TextBody>
      </Td>
      <Td>
        <TextBody size="smaller" weight="light">
          {data?.description}
        </TextBody>
      </Td>
      <Td>
        <ActionWrapper>
          <Toggle
            active={data?.status}
            onClick={() => handleChangeStatus(data.id, !data.status)}
          />
        </ActionWrapper>
      </Td>
      <Td>
        <Button variant="wrapper" onClick={() => handleEdit(data.id)}>
          <SystemIcon className="pointer" colorIcon="main" iconName="edit" />
        </Button>
      </Td>
    </Tr>
  );
}

RowFraudSetting.defaultProps = {
  data: {}
};

export default memo(RowFraudSetting);

const Tr = styled.tr`
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
`;

const Td = styled.td`
  color: ${({ theme: { colors }, color = "textSoft" }) => colors[color]};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  padding: 16px;
  padding-right: 20px;
  min-width: ${({ min }) => min};
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  i {
    margin-left: 20px;
  }
`;
