import { toastFailed, toastSuccess } from 'utils/toast'
import {
  SET_IS_START_HUB_DETAIL,
  SET_IS_FAILED_HUB_DETAIL,
  SET_IS_SUCCESS_HUB_DETAIL,
  SET_IS_NEED_TO_RELOAD_HUB_DETAIL,
  SET_PAGINATION_DATA_HUB_DETAIL,
  SET_SELECT_PRODUCT_DATA_HUB_DETAIL,
  SET_IS_FAILED_HUB_DETAIL_HISTORY,
  SET_IS_SUCCESS_HUB_DETAIL_HISTORY,
  SET_PAGINATION_DATA_HUB_DETAIL_HISTORY,
  SET_IS_START_INVENTORY_LOCATION,
  SET_IS_FAILED_INVENTORY_LOCATION,
  SET_IS_SUCCESS_INVENTORY_LOCATION,
  SET_PAGINATION_INVENTORY_LOCATION,
  SET_UPDATE_INVENTORY_LOCATION_FAILED,
  SET_SAVE_INVENTORY_LOCATION_FAILED,
  SET_SAVE_INVENTORY_LOCATION_START,
  SET_IS_START_INVENTORY_STATUS,
  SET_IS_START_INVENTORY_NOTE_BY_STATUS,
  SET_IS_FAILED_INVENTORY_NOTE_BY_STATUS,
  SET_IS_FAILED_INVENTORY_STATUS,
  SET_IS_SUCCESS_INVENTORY_STATUS,
  SET_IS_SUCCESS_INVENTORY_NOTE_BY_STATUS,
  SET_PAGINATION_INVENTORY_STATUS,
  SET_PAGINATION_INVENTORY_NOTE_BY_STATUS,
  SET_IS_START_INVENTORY_SAVE,
  SET_IS_FAILED_INVENTORY_SAVE,
  SET_IS_SUCCESS_INVENTORY_SAVE,
  SET_MODAL_INFORMATION,
  SET_INVENTORY_NOTE,
} from 'storeContext/actionsType/hubDetail'

import {
  getHubDetailList_HD,
  getHubHistory,
  getInventoryLocation,
  getProductDetail_HD,
  saveInventoryLocation,
  updateInventoryLocation,
  transferProductDetailApi,
  getInventoryStatus,
  getInventoryNoteByStatus,
  getInventoryNote,
} from 'utils/apiList/hubDetail'

import { callErrorMsg } from 'helpers/errorMsg'

export const transferLocationStart = () => {
  return {
    type: SET_SAVE_INVENTORY_LOCATION_START,
  }
}

export const inventoryLocationStart = () => {
  return {
    type: SET_IS_START_INVENTORY_LOCATION,
  }
}

export const inventorySaveStart = () => {
  return {
    type: SET_IS_START_INVENTORY_SAVE,
  }
}

export const inventorySaveStop = () => {
  return {
    type: SET_IS_FAILED_INVENTORY_SAVE,
  }
}

export const inventorySaveSuccess = () => {
  return {
    type: SET_IS_SUCCESS_INVENTORY_SAVE,
  }
}

export const inventoryNoteByStatusStart = () => {
  return {
    type: SET_IS_START_INVENTORY_NOTE_BY_STATUS,
  }
}

export const inventoryNoteByStatusStop = () => {
  return {
    type: SET_IS_FAILED_INVENTORY_NOTE_BY_STATUS,
  }
}

export const inventoryStatusStart = () => {
  return {
    type: SET_IS_START_INVENTORY_STATUS,
  }
}

export const inventoryStatusStop = () => {
  return {
    type: SET_IS_FAILED_INVENTORY_STATUS,
  }
}

export const inventoryLocationStop = () => {
  return {
    type: SET_IS_FAILED_INVENTORY_LOCATION,
  }
}

export const saveProductDetailStop = () => {
  return {
    type: SET_UPDATE_INVENTORY_LOCATION_FAILED,
  }
}

export const updateProductDetailStop = () => {
  return {
    type: SET_SAVE_INVENTORY_LOCATION_FAILED,
  }
}

export const hubDetailStart = () => {
  return {
    type: SET_IS_START_HUB_DETAIL,
  }
}

export const hubDetailStop = () => {
  return {
    type: SET_IS_FAILED_HUB_DETAIL,
  }
}

export const hubHistoryStop = () => {
  return {
    type: SET_IS_FAILED_HUB_DETAIL_HISTORY,
  }
}

export const getHubDetailData = (data) => {
  return {
    type: SET_IS_SUCCESS_HUB_DETAIL,
    payload: data,
  }
}

export const getHubHistoryData = (data) => {
  return {
    type: SET_IS_SUCCESS_HUB_DETAIL_HISTORY,
    payload: data,
  }
}

export const getInventoryLocationData = (data) => {
  return {
    type: SET_IS_SUCCESS_INVENTORY_LOCATION,
    payload: data,
  }
}

export const getInventoryStatusData = (data) => {
  return {
    type: SET_IS_SUCCESS_INVENTORY_STATUS,
    payload: data,
  }
}

export const getInventoryNoteByStatusData = (data) => {
  return {
    type: SET_IS_SUCCESS_INVENTORY_NOTE_BY_STATUS,
    payload: data,
  }
}

export const setPagination = (data) => {
  return {
    type: SET_PAGINATION_DATA_HUB_DETAIL,
    payload: data,
  }
}

export const setPaginationHistory = (data) => {
  return {
    type: SET_PAGINATION_DATA_HUB_DETAIL_HISTORY,
    payload: data,
  }
}

export const setPaginationInventoryLocation = (data) => {
  return {
    type: SET_PAGINATION_INVENTORY_LOCATION,
    payload: data,
  }
}

export const setPaginationInventoryStatus = (data) => {
  return {
    type: SET_PAGINATION_INVENTORY_STATUS,
    payload: data,
  }
}

export const setPaginationInventoryNoteStatus = (data) => {
  return {
    type: SET_PAGINATION_INVENTORY_NOTE_BY_STATUS,
    payload: data,
  }
}

export const reloadHubDetail = () => {
  return {
    type: SET_IS_NEED_TO_RELOAD_HUB_DETAIL,
  }
}

export const selectProductData = (data) => {
  return {
    type: SET_SELECT_PRODUCT_DATA_HUB_DETAIL,
    payload: data,
  }
}

export const setModalInformation = (data) => ({
  type: SET_MODAL_INFORMATION,
  payload: data,
})

export const setInventoryNote = (data) => {
  return {
    type: SET_INVENTORY_NOTE,
    payload: data,
  }
}

export const getListOfHubDetail =
  (query = false, localState = {}) =>
  (dispatch, getState) => {
    dispatch(hubDetailStart())
    //const { selectedProduct } = getState().stateHubDetail;

    return getHubDetailList_HD(localState.product_id, query)
      .then(({ data }) => {
        dispatch(getHubDetailData(data))

        const pagination = {
          currentItem: data.numberOfElements,
          currentPage: data.number,
          pageSize: data.size,
          totalData: data.totalElements,
          totalPage: data.totalPages,
        }
        dispatch(setPagination(pagination))
        dispatch(hubDetailStop())
      })
      .catch(() => {
        toastFailed('terjadi masalah, coba beberapa saat lagi')

        dispatch(hubDetailStop())
      })
  }

export const getListHubHistory = (payload, query) => (dispatch) => {
  dispatch(hubDetailStart())
  return getHubHistory(payload, query)
    .then(({ data }) => {
      dispatch(getHubHistoryData(data.data, query))
      dispatch(setPaginationHistory(data.pagination))
    })
    .catch((err) => {
      toastFailed('terjadi masalah, coba beberapa saat lagi')
      dispatch(hubHistoryStop())
    })
}

export const getListInventoryLocation = (payload, query) => (dispatch) => {
  dispatch(hubDetailStart())
  return getInventoryLocation(payload, query)
    .then(({ data }) => {
      dispatch(getInventoryLocationData(data))
      /**
       * this only contain first and last for pagination
       */
      const {
        data: { first, last },
      } = data

      const pagination = {
        first: first,
        last: last,
      }
      dispatch(setPaginationInventoryLocation(pagination))
      dispatch(hubDetailStop())
    })
    .catch(() => {
      toastFailed('terjadi masalah, coba beberapa saat lagi')
      dispatch(hubDetailStop())
    })
}

export const saveProductDetail = (payload, callbackSuccess) => (dispatch) => {
  dispatch(inventorySaveStart())
  return saveInventoryLocation(payload)
    .then(() => {
      toastSuccess(`Product detail berhasil di tambahkan`)
      callbackSuccess()
      dispatch(inventorySaveSuccess())
    })
    .catch((error) => {
      callErrorMsg(error)
      dispatch(inventorySaveStop())
    })
}

export const updateProductDetail = (payload, id, callbackSuccess, callbackError) => (dispatch) => {
  dispatch(transferLocationStart())
  return updateInventoryLocation(payload, id)
    .then(({ data }) => {
      toastSuccess(`Product detail berhasil di ubah`)
      callbackSuccess()
    })
    .catch((error) => {
      callbackError()
      callErrorMsg(error)
      dispatch(updateProductDetailStop())
    })
}

export const transferProductDetail = (payload, id, callbackSuccess) => (dispatch) => {
  dispatch(transferLocationStart())
  return transferProductDetailApi(payload, id)
    .then(({ data }) => {
      toastSuccess(`Product detail berhasil di transfer`)
      callbackSuccess()
    })
    .catch((error) => {
      callErrorMsg(error)

      dispatch(updateProductDetailStop())
    })
}

export const getProductDataById = (id) => (dispatch) => {
  dispatch(hubDetailStart())

  return getProductDetail_HD(id)
    .then(({ data }) => {
      selectProductData(data)
      dispatch(hubDetailStop())
    })
    .catch(() => {
      toastFailed('terjadi masalah, coba beberapa saat lagi')
      dispatch(hubDetailStop())
    })
}

export const getInventoryNotes = (payload) => (dispatch) => {
  return getInventoryNote()
    .then(({ data }) => {
      let notes = {}

      payload.forEach((item) => {
        notes[item.id] = data.data.notes
          .filter((note) => note.statusId === item.id)
          .map((note) => ({
            id: note.id,
            name: note.displayName,
          }))
      })

      dispatch(setInventoryNote(notes))
    })
    .catch((error) => {
      callErrorMsg(error, 'terjadi masalah dalam mendapatkan inventory note')
    })
}

export const getInventoryStatusAction = () => (dispatch) => {
  dispatch(inventoryStatusStart())
  return getInventoryStatus()
    .then(({ data }) => {
      dispatch(getInventoryStatusData(data))

      dispatch(getInventoryNotes(data.data.status))

      /**
       * this only contain first and last for pagination
       */
      const pagination = {
        first: data.first,
        last: data.last,
      }
      dispatch(setPaginationInventoryStatus(pagination))
    })
    .catch(() => {
      toastFailed('terjadi masalah dalam mendapatkan inventory status')
      dispatch(inventoryStatusStop())
    })
}

export const getInventoryNoteByStatusAction = (payload) => (dispatch) => {
  dispatch(inventoryNoteByStatusStart())
  return getInventoryNoteByStatus(payload)
    .then(({ data }) => {
      dispatch(getInventoryNoteByStatusData(data))
      /**
       * this only contain first and last for pagination
       */
      const pagination = {
        first: data.first,
        last: data.last,
      }
      dispatch(setPaginationInventoryNoteStatus(pagination))
    })
    .catch(() => {
      toastFailed('terjadi masalah dalam mendapatkan inventory note by status')
      dispatch(inventoryNoteByStatusStop())
    })
}
