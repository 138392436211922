import { memo } from 'react'
import styled from 'styled-components'
import { RowLocation, NoData } from 'components'

function TableLocation({
  data,
  handleChangeActive,
  handleClickDetail,
  handleClickEdit,
  handleClickDownload,
  handleGenerateQRCode,
}) {
  return (
    <>
      <Container>
        <thead>
          <Tr>
            <Th align="center" width={64}>
              ID
            </Th>
            <Th width={300}>Nama</Th>
            <Th>Alamat Lengkap</Th>
            <Th width={220}>Supervisor</Th>
            <Th width={220}>Tipe</Th>
            <Th width={100} align="center">
              Status
            </Th>
            <Th width={100} align="center">
              Aksi
            </Th>
            <Th width={160}></Th>
          </Tr>
        </thead>
        <tbody>
          {data?.map((el) => (
            <RowLocation
              key={`${el.location_id}--idLoc`}
              data={el}
              handleChangeActive={handleChangeActive}
              handleClickDetail={handleClickDetail}
              handleClickEdit={handleClickEdit}
              handleClickDownload={handleClickDownload}
              handleGenerateQRCode={handleGenerateQRCode}
            />
          ))}
        </tbody>
      </Container>
      {data?.length === 0 && <NoData />}
    </>
  )
}

export default memo(TableLocation)

const Container = styled.table`
  width: 100%;
  border-spacing: 0;
  border: 1px solid #d6dfeb;
  border-radius: 8px;
`
const Tr = styled.tr`
  background-color: #f3f4f5;
`
const Th = styled.th`
  color: #212121;
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  padding: 16px;
  text-align: ${({ align }) => align ?? 'left'};
  border-bottom: 1px solid #d6dfeb;
  border-left: 1px solid #d6dfeb;
  background-color: #f3f4f5;
  :first-of-type {
    border-top-left-radius: 8px;
  }
  :last-of-type {
    border-top-right-radius: 8px;
  }
`
