import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  VendorBrandGroupingItemDataType,
  VendorBrandGroupingListRequestParamsType,
} from 'utils/apiList/vendorBrandGrouping'
import {
  SLICE_NAME,
  fetchVendorBrandGroupingThunk,
  fetchVendorListThunk,
  fetchBrandListThunk,
  addEditVendorBrandGroup,
  updateStatusVendorBrandGroup,
} from './vendorBrandGroupingThunk'

export interface ModalAddEditInterface {
  isOpen: boolean
  title: string
  purpose: 'add' | 'edit'
  data: {
    id: number
    groupId: number
    selectedBrand: {
      value: number
      name: string
    }
    selectedUom: {
      value: string
      name: string
    }
    selectedVendor: {
      value: number
      name: string
    }
    moq: number
    mov: number
    status: boolean
  }
  vendorList: {
    id: number
    companyName: string
  }[]
  brandList: {
    brandId: number
    brandName: string
  }[]
}

export interface VendorBrandGroupingInitialStateInterface {
  isLoading: boolean
  query: VendorBrandGroupingListRequestParamsType
  vendorBrandGroupingList: VendorBrandGroupingItemDataType[]
  modalAddEdit: ModalAddEditInterface
  triggerToFetchListData: boolean
}

const initialState: VendorBrandGroupingInitialStateInterface = {
  isLoading: true,
  query: {
    pageSize: 20,
    pageIndex: 1,
    search: '',
  },
  vendorBrandGroupingList: [],
  modalAddEdit: {
    isOpen: false,
    purpose: 'add',
    title: '',
    data: {
      id: 0,
      groupId: 0,
      selectedBrand: {
        value: 0,
        name: '',
      },
      selectedUom: {
        value: '',
        name: '',
      },
      selectedVendor: {
        value: 0,
        name: '',
      },
      moq: 0,
      mov: 0,
      status: false,
    },
    vendorList: [],
    brandList: [],
  },
  triggerToFetchListData: false,
}

const vendorBrandGroupingSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetModalAddEdit: (state) => {
      state.modalAddEdit = initialState.modalAddEdit
    },
    setModalAddEdit: (
      state,
      action: PayloadAction<Partial<VendorBrandGroupingInitialStateInterface['modalAddEdit']>>,
    ) => {
      const { isOpen, purpose, title, data } = action.payload

      if (isOpen) state.modalAddEdit.isOpen = isOpen
      if (purpose) state.modalAddEdit.purpose = purpose
      if (title) state.modalAddEdit.title = title
      if (data) state.modalAddEdit.data = data
    },
    setQuery: (
      state,
      action: PayloadAction<Partial<VendorBrandGroupingInitialStateInterface['query']>>,
    ) => {
      const { pageSize, pageIndex } = action.payload
      if (pageIndex) state.query.pageIndex = pageIndex
      if (pageSize) state.query.pageSize = pageSize
    },
    setSearch: (state, action: PayloadAction<string>) => {
      state.query.search = action.payload
    },
    setGroup: (state, action: PayloadAction<string>) => {
      state.query.group = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVendorBrandGroupingThunk.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchVendorBrandGroupingThunk.fulfilled, (state, action) => {
        state.isLoading = false
        state.vendorBrandGroupingList = action.payload.data
        state.triggerToFetchListData = false
      })
      .addCase(fetchVendorBrandGroupingThunk.rejected, (state) => {
        state.isLoading = false
        state.triggerToFetchListData = false
      })
      .addCase(fetchVendorListThunk.fulfilled, (state, action) => {
        state.modalAddEdit.vendorList = action.payload.data
      })
      .addCase(fetchBrandListThunk.fulfilled, (state, action) => {
        state.modalAddEdit.brandList = action.payload
      })
      .addCase(addEditVendorBrandGroup.fulfilled, (state) => {
        state.modalAddEdit = initialState.modalAddEdit
        state.triggerToFetchListData = true
      })
      .addCase(updateStatusVendorBrandGroup.fulfilled, (state) => {
        state.triggerToFetchListData = true
      })
  },
})

export const { resetModalAddEdit, setModalAddEdit, setQuery, setSearch, setGroup } =
  vendorBrandGroupingSlice.actions
export default vendorBrandGroupingSlice.reducer
