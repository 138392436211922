import { callErrorMsg } from 'helpers/errorMsg'
import { setSelectedOrderId, setShowPopupCompleteAstroKitchen } from 'pages/Order/redux/order'
import { useContext, useEffect, useState } from 'react'
import {
  fetchDataAndStatusAstroKitchenOrder,
  setRefetchOrderAstroKitchen,
  updateFulfilQuantityItemKitchen,
} from 'redux/cloudKitchen'
import { useAppSelector, shallowEqual, useAppDispatch } from 'store'
import { printCloudKitchenLabel, printCloudKitchenModifierLabel } from 'utils/printFromBrowser'
import { setDataKitchenModifierReceipt } from 'redux/printArea'
import { toastFailed, toastSuccess } from 'utils/toast'
import { getFeatureFlagByLocationID } from 'utils/api'
import storeContext from 'storeContext'
import { setIsPopupDetailShow } from 'storeContext/actions/packer'

const usePrintLabelCloudKitchen = (
  { order_line_items, order_id, order_total_quantity } = {},
  isShow = false,
  selectedLocationID,
) => {
  const [listResi, setListResi] = useState([])
  const [listReadyToPrint, setListReadyToPrint] = useState([])
  const [allSelected, setAllSelected] = useState(false)
  const [astroKitchenDetail, setAstroKitchenDetail] = useState(null)
  const [isOrderNotFound, setIsOrderNotFound] = useState(false)
  const [allLabelPrinted, setAllLabelPrinted] = useState(false)
  const [isActiveHubModifier, setIsActiveHubModifier] = useState(false)

  const dispatchRedux = useAppDispatch()
  const { refetchOrderAstroKitchen } = useAppSelector((state) => state.cloudKitchen, shallowEqual)
  const { dispatch } = useContext(storeContext.PackerContext)

  useEffect(() => {
    return () => {
      setAllSelected(false)
      setListReadyToPrint([])
      setAstroKitchenDetail(null)
      setIsOrderNotFound(false)
    }
  }, [])

  useEffect(() => {
    if (isShow) {
      getFeatureFlagKitchenModifier(selectedLocationID)
      getOrderKitchen()
    }
  }, [isShow])
  useEffect(() => {
    if (astroKitchenDetail) {
      setListResi(
        astroKitchenDetail?.items?.map((each) => ({
          ...each,
          selected: false,
          accordion: true,
          status: each.status.split('_').join(' '),
          customer: astroKitchenDetail.customer_name,
          invoice: astroKitchenDetail.invoice,
        })),
      )
    }
  }, [astroKitchenDetail])

  useEffect(() => {
    if (refetchOrderAstroKitchen) {
      handleRefetchData()
    }
  }, [refetchOrderAstroKitchen])

  useEffect(() => {
    setListReadyToPrint([])
    if (allSelected) {
      const newListReadyToPrint = listResi.flatMap((d) =>
        Array.from({ length: d.quantity }, (_, index) => ({
          ...d,
          page: index + 1,
          total: d.quantity,
        })),
      )

      setListReadyToPrint(newListReadyToPrint)
    }
    setListResi((prev) => prev.map((d) => ({ ...d, selected: allSelected })))
  }, [allSelected])

  const handleSetListAndAllLabeled = (data) => {
    const flagLabel = data?.items?.every((d) => d.status.toLowerCase() !== 'new_order')
    setAstroKitchenDetail(data)
    setAllLabelPrinted(flagLabel)
  }

  const getFeatureFlagKitchenModifier = async (locationID) => {
    try {
      const response = await getFeatureFlagByLocationID(locationID, {
        featureKey: 'kitchen_modifier_fulfillment',
      })

      setIsActiveHubModifier(!!response?.data?.data?.features?.kitchen_modifier_fulfillment)
      return !!response?.data?.data?.features?.kitchen_modifier_fulfillment
    } catch (err) {
      toastFailed('Mohon pilih lokasi terlebih dahulu')
      callErrorMsg(err)
      handleClosePopup()
      return false
    }
  }

  const getOrderKitchen = () => {
    if (order_id) {
      dispatchRedux(fetchDataAndStatusAstroKitchenOrder(order_id))
        .unwrap()
        .then((res) => {
          if (res?.order) {
            res.order.status = res.order.status?.split('_').join(' ')
            handleSetListAndAllLabeled(res.order)
          } else {
            setIsOrderNotFound(true)
            setAstroKitchenDetail({
              id: order_id,
              status: '',
              items: order_line_items
                ?.map((item) => ({
                  ...item,
                  customer_note: '',
                  fulfill_quantity: 0,
                  id: item.order_line_item_id,
                  image: '',
                  name: item.order_product_name,
                  note: item.product_modifier,
                  quantity: item.order_product_quantity,
                  sku: item.order_product_sku,
                  status: '',
                }))
                .filter((item) => item.cloud_kitchen),
              total_quantity: order_total_quantity,
            })
          }
        })
    }
  }

  const handleRefetchData = () => {
    getOrderKitchen()
    dispatchRedux(setRefetchOrderAstroKitchen(false))
    setListReadyToPrint([])
  }

  const onPrintCloudKitchenLabel = () => {
    const dataToPrint = listReadyToPrint.map((d) => ({
      customer: d.customer,
      customerNote: d.customer_note,
      modifiers: d.modifiers,
      productName: d.name,
      itemId: d.id,
      orderId: d.invoice.split('/').pop(),
      addOns: d.add_ons,
    }))
    if (isOrderNotFound) {
      return dispatchRedux(setDataKitchenModifierReceipt(dataToPrint))
    }
    const payload = {
      orderId: order_id,
      items: listReadyToPrint.map((d) => ({ id: d.id, fulfill_quantity: 0, note: d.note })),
    }
    dispatchRedux(updateFulfilQuantityItemKitchen(payload))
      .unwrap()
      .then((res) => {
        if (res.data) {
          getOrderKitchen()
          setAllSelected(false)
          setListReadyToPrint([])

          return isActiveHubModifier
            ? printCloudKitchenModifierLabel(dataToPrint)
            : printCloudKitchenLabel(listReadyToPrint)
        }
      })
      .catch((err) => {
        callErrorMsg(err)
      })
  }

  const onClickSelect = (el) => {
    if (!el.selected) {
      for (let index = 0; index < el.quantity; index++) {
        setListReadyToPrint((prev) => [...prev, { ...el, page: index + 1, total: el.quantity }])
      }
    } else {
      setListReadyToPrint((prev) => prev.filter((d) => d.id !== el.id))
    }
    setListResi((prev) =>
      prev.map((d) => (d.id == el.id ? { ...d, selected: !d.selected } : { ...d })),
    )
  }

  const onClickArrow = (el) => {
    setListResi((prev) => prev.map((d) => (d.id == el.id ? { ...d, accordion: !d.accordion } : d)))
  }

  const onClickSelectAll = () => {
    setAllSelected((prev) => !prev)
  }

  const handleOpenPopupCompleteOrder = () => {
    dispatchRedux(setShowPopupCompleteAstroKitchen(true))
    dispatchRedux(setSelectedOrderId(order_id))
  }

  const getDataCustomer = async (order) => {
    const [orderId, productId] = order.split(':')
    const arrayProductId = productId?.split(',').map((d) => Number(d))

    try {
      const response = await dispatchRedux(fetchDataAndStatusAstroKitchenOrder(orderId)).unwrap()
      const items = response?.order?.items?.filter((d) => arrayProductId?.includes(d.id))
      const customer = response?.order?.customer_name
      const invoice = response?.order?.invoice

      const payload = {
        orderId,
        items: arrayProductId?.map((d) => ({ id: d, fulfill_quantity: 0, note: '' })),
      }
      const products = items?.map((item) => ({ ...item, customer, invoice }))

      await dispatchRedux(updateFulfilQuantityItemKitchen(payload)).unwrap()

      return products
    } catch (err) {
      callErrorMsg('Terjadi Kesalahan saat mencari detail order')
    }
  }

  const printFromQr = async (data, locationID) => {
    const setActiveModifier = await getFeatureFlagKitchenModifier(locationID)
    if (data?.includes(':') && locationID) {
      const dataRemoveUnusedCharacters = data.replace(/"/g, '')
      const dataParsed = dataRemoveUnusedCharacters.split('|')
      const results = await Promise.all(dataParsed?.map((d) => getDataCustomer(d)))
      const dataToSend = results
        .flatMap((p) => p)
        .flatMap((p) =>
          Array.from({ length: p.quantity }, (_, index) => {
            if (setActiveModifier) {
              return {
                customer: p.customer,
                customerNote: p.customer_note,
                modifiers: p.modifiers,
                productName: p.name,
                itemId: p.id,
                orderId: p.invoice.split('/').pop(),
                addOns: p.add_ons,
              }
            }
            return {
              invoice: p.invoice,
              customer: p.customer,
              sku: p.sku,
              name: p.name,
              product_note: p.note,
              page: index + 1,
              total: p.quantity,
            }
          }),
        )
      if (dataToSend.length > 0) {
        setActiveModifier
          ? printCloudKitchenModifierLabel(dataToSend)
          : printCloudKitchenLabel(dataToSend)
      } else {
        callErrorMsg('Terjadi kesalahan mohon dicoba kembali')
      }
    }
  }

  const parseStatusToIndonesian = (status) => {
    switch (status) {
      case 'COMPLETED':
        return 'Selesai Diproses'
      case 'NEW ORDER':
        return 'Belum Diproses'
      case 'IN PROGRESS':
        return 'Sedang Diproses'
      default:
        return status
    }
  }
  const parseStatusToColor = (status) => {
    switch (status) {
      case 'COMPLETED':
        return {
          box: '#42b139',
          text: '#e7f5e6',
        }
      case 'NEW ORDER':
        return {
          box: '#E9E9E9',
          text: 'black',
        }
      case 'IN PROGRESS':
        return {
          box: '#FFF3E6',
          text: '#E67800',
        }
      default:
        return {
          box: '#E9E9E9',
          text: 'black',
        }
    }
  }

  const copyToClipboard = (orderId) => {
    toastSuccess(`order id berhasil disalin ke clipboard`)
    navigator.clipboard.writeText(orderId)
  }

  const handleClosePopup = () => {
    setAllSelected(false)
    setListReadyToPrint([])
    setAstroKitchenDetail(null)
    setIsOrderNotFound(false)
    dispatch(setIsPopupDetailShow(false))
  }

  return {
    onClickSelect,
    onClickSelectAll,
    onPrintCloudKitchenLabel,
    listResi,
    listReadyToPrint,
    allSelected,
    setListReadyToPrint,
    setAllSelected,
    onClickArrow,
    handleOpenPopupCompleteOrder,
    printFromQr,
    astroKitchenDetail,
    isOrderNotFound,
    allLabelPrinted,
    setIsOrderNotFound,
    parseStatusToIndonesian,
    parseStatusToColor,
    copyToClipboard,
  }
}

export default usePrintLabelCloudKitchen
