import { memo, useContext } from 'react'
import styled from 'styled-components'
import { TextBody, SystemIcon, Button } from 'components'
import store from 'storeContext'
import { getServiceDetail } from 'storeContext/actions/serviceLevel'
import { useServiceLevelRBACPage } from 'pages/ServiceLevel/rbac'

function RowServiceLevel({ data, handleClickEdit }) {
  //GLOBAL STATE

  const { dispatch: dispatchServiceLevel } = useContext(store.ServiceLevelContext)
  const { isAllowedForUpdateServiceLevel } = useServiceLevelRBACPage()

  const handleEdit = () => {
    const { service_level_id } = data
    const localState = {
      handleClickEdit: handleClickEdit,
    }
    dispatchServiceLevel(getServiceDetail(service_level_id, localState))
  }

  return (
    <Tr>
      <Td ratio="4">
        <TextBody weight="light">{data?.service_level_name}</TextBody>
      </Td>
      <Td ratio="4">
        <TextBody weight="light">{data?.service_level_maximum_duration} menit</TextBody>
      </Td>
      <Td ratio="4">
        <TextBody weight="light">{data?.service_level_total_duration} menit</TextBody>
      </Td>
      <Td ratio="1">
        {isAllowedForUpdateServiceLevel && (
          <Button variant="wrapper" onClick={handleEdit}>
            <SystemIcon className="pointer" colorIcon="main" iconName="edit" />
          </Button>
        )}
      </Td>
    </Tr>
  )
}

export default memo(RowServiceLevel)

const Tr = styled.tr`
  display: flex;
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
`
const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`
