import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ProductImageType,
  AddEditAssetParamInterface,
} from 'features/AssetManagement/@types/typeAddEditAsset'

type UOMsType = {
  id: string
  group: string
  description: string
  active: boolean
}

type ErrorFormType = {
  name: boolean
  sku_number: boolean
  uom: boolean
  type: boolean
  weight: boolean
  length: boolean
  width: boolean
  height: boolean
  status: boolean
  note: boolean
  asset_categories: boolean
  asset_images: boolean
}

interface ProductAddEditStateType {
  isLoading: boolean
  productImages: ProductImageType[]
  confirmationDeleteImage: {
    isOpen: boolean
    indexToDelete: number
  }
  maxImageToUpload: number
  sendData: AddEditAssetParamInterface
  errorForm: ErrorFormType
  assetListUoms: UOMsType[]
}

const initialState: ProductAddEditStateType = {
  isLoading: false,
  productImages: [],
  confirmationDeleteImage: {
    isOpen: false,
    indexToDelete: -1,
  },
  maxImageToUpload: 5,
  sendData: {
    name: '',
    sku_number: '',
    uom: '',
    type: '',
    weight: 0,
    length: 0,
    width: 0,
    height: 0,
    status: '',
    note: '',
    asset_categories: [],
    asset_images: [],
  },
  errorForm: {
    name: false,
    sku_number: false,
    uom: false,
    type: false,
    weight: false,
    length: false,
    width: false,
    height: false,
    status: false,
    note: false,
    asset_categories: false,
    asset_images: false,
  },
  assetListUoms: [],
}
const productAddEditSlice = createSlice({
  name: 'productAssetAddEdit',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    resetStateOfProductAddEdit: () => initialState,
    resetConfirmationDeleteImage: (state) => {
      state.confirmationDeleteImage = initialState.confirmationDeleteImage
    },
    setConfirmationDeleteImage: (
      state,
      action: PayloadAction<Partial<ProductAddEditStateType['confirmationDeleteImage']>>,
    ) => {
      state.confirmationDeleteImage = {
        ...state.confirmationDeleteImage,
        ...action.payload,
      }
    },
    setProductImages: (state, action: PayloadAction<ProductImageType[]>) => {
      state.productImages = action.payload
    },
    setProductData: (
      state,
      action: PayloadAction<Partial<ProductAddEditStateType['sendData']>>,
    ) => {
      const newPayload = action.payload
      state.sendData = { ...state.sendData, ...newPayload }
    },
    resetStateSendData: (state) => {
      state.sendData = initialState.sendData
    },
    setAssetListUoms: (state, action: PayloadAction<UOMsType[]>) => {
      state.assetListUoms = action.payload
    },
    setErrorForm: (state, action: PayloadAction<Partial<ProductAddEditStateType['errorForm']>>) => {
      state.errorForm = { ...state.errorForm, ...action.payload }
    },
    resetErrorForm: (state) => {
      state.errorForm = initialState.errorForm
    },
  },
})

export const {
  setIsLoading,
  resetStateOfProductAddEdit,
  resetConfirmationDeleteImage,
  setConfirmationDeleteImage,
  setProductImages,
  resetStateSendData,
  setProductData,
  setAssetListUoms,
  setErrorForm,
  resetErrorForm,
} = productAddEditSlice.actions

export default productAddEditSlice.reducer
