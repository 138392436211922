import {
  SET_IS_START_ADMIN,
  SET_IS_FAILED_ADMIN,
  SET_IS_SUCCESS_ADMIN,
  SET_IS_NEED_TO_RELOAD_ADMIN,
  SET_PAGINATION_DATA_ADMIN,
  SET_LIST_LOCATION_WAREHOUSE,
  SET_LIST_LOCATION_HUB,
  SET_LIST_ROLES_WAREHOUSE,
  SET_LIST_ROLES_HUB,
  SET_LIST_OF_POSITION,
} from 'storeContext/actionsType'

export const initialStateAdmin = {
  isLoading: false,
  adminData: [],
  paginationData: {},
  needToReload: 1,
  listLocationOfWarehouse: [],
  listLocationOfHub: [],
  listRolesOfWarehouse: [],
  listRolesOfHub: [],
  listOfPosition: [],
}

export default function admin(state = initialStateAdmin, { type, payload }) {
  switch (type) {
    case SET_IS_START_ADMIN:
      return {
        ...state,
        isLoading: true,
      }
    case SET_IS_FAILED_ADMIN:
      return {
        ...state,
        isLoading: false,
      }
    case SET_IS_SUCCESS_ADMIN:
      return {
        ...state,
        isLoading: false,
        adminData: payload.content,
      }
    case SET_IS_NEED_TO_RELOAD_ADMIN:
      return {
        ...state,
        needToReload: state.needToReload + 1,
      }
    case SET_PAGINATION_DATA_ADMIN:
      return {
        ...state,
        paginationData: payload,
      }
    case SET_LIST_LOCATION_WAREHOUSE:
      return {
        ...state,
        listLocationOfWarehouse: payload,
      }
    case SET_LIST_LOCATION_HUB:
      return {
        ...state,
        listLocationOfHub: payload,
      }
    case SET_LIST_ROLES_WAREHOUSE:
      return {
        ...state,
        listRolesOfWarehouse: payload,
      }
    case SET_LIST_ROLES_HUB:
      return {
        ...state,
        listRolesOfHub: payload,
      }
    case SET_LIST_OF_POSITION:
      return {
        ...state,
        listOfPosition: payload,
      }
    default:
      return {
        ...state,
      }
  }
}
