import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { GetUVOrderDetailType } from 'utils/apiList/uniqueVoucher'
import {
  fetchUniqueVoucherList,
  updateVoucherStatus,
  fetchUVOrderDetailList,
  fetchUVStatusList,
} from './uniqueVoucherListThunk'

export interface DataVoucherListStateType {
  voucher_id: number
  voucher_code: string
  campaign_name: string
  expired_at: string
  quota: number
  used: number
  quota_label: string
  status: string
  active: boolean
  uniqueId: string
}
export interface UVPaginationType {
  empty: boolean
  first: boolean
  last: boolean
  number: number
  numberOfElements: number
  size: number
  totalElements: number
  totalPages: number
}

export type UVStatusListStateType = {
  value: string
  name: string
}

export interface UniqueVoucherListStateType {
  isLoading: boolean
  isShowModalDetail: boolean
  needToReload: number
  query: {
    voucher: string
    campaign: string
    status: string
    pageIndex: number
  }
  dataVoucherList: DataVoucherListStateType[]
  pagination: UVPaginationType
  orderDetailList: GetUVOrderDetailType[]
  statusList: UVStatusListStateType[]
}

export const initialState: UniqueVoucherListStateType = {
  isLoading: false,
  isShowModalDetail: false,
  needToReload: 1,
  query: { campaign: '', voucher: '', status: '', pageIndex: 0 },
  dataVoucherList: [],
  pagination: {
    empty: false,
    first: true,
    last: true,
    number: 0,
    numberOfElements: 0,
    size: 0,
    totalElements: 0,
    totalPages: 0,
  },
  orderDetailList: [],
  statusList: [],
}

const uniqueVoucherList = createSlice({
  name: 'uniqueVoucherList',
  initialState,
  reducers: {
    reset: () => initialState,
    setQuery: (
      state,
      action: PayloadAction<{
        fieldName: keyof UniqueVoucherListStateType['query']
        value: string | number
      }>,
    ) => {
      const { fieldName, value } = action.payload
      state.query[fieldName] = value as never
      if (fieldName !== 'pageIndex') {
        state.query.pageIndex = 0
      }
      if (fieldName === 'status' || fieldName === 'pageIndex') {
        state.needToReload += 1
      }
    },
    setIsShowModalDetail: (
      state,
      action: PayloadAction<UniqueVoucherListStateType['isShowModalDetail']>,
    ) => {
      state.isShowModalDetail = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUniqueVoucherList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchUniqueVoucherList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchUniqueVoucherList.fulfilled, (state, action) => {
        const { content, ...restValue } = action.payload
        state.isLoading = false
        state.dataVoucherList = content.map((el) => ({
          ...el,
          uniqueId: window.crypto.randomUUID(),
        }))
        state.pagination = restValue
      })
      .addCase(updateVoucherStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateVoucherStatus.fulfilled, (state) => {
        state.isLoading = false
        state.needToReload += 1
      })
      .addCase(updateVoucherStatus.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchUVOrderDetailList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchUVOrderDetailList.fulfilled, (state, action) => {
        state.isLoading = false
        state.orderDetailList = action.payload
        state.isShowModalDetail = true
      })
      .addCase(fetchUVOrderDetailList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchUVStatusList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchUVStatusList.fulfilled, (state, action) => {
        state.isLoading = false
        state.statusList = action.payload.map((el) => ({
          value: el.value,
          name: el.value,
        }))
      })

      .addCase(fetchUVStatusList.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const { reset, setQuery, setIsShowModalDetail } = uniqueVoucherList.actions
export default uniqueVoucherList.reducer
