import axios from 'axios'
const { REACT_APP_BASE_URL_API } = process.env
const baseUrl = REACT_APP_BASE_URL_API

// Get Section

export const getRefund = (params) => axios.get(`${baseUrl}/api/refunds`, { params })

export const getRefundDetail = (data, params) =>
  axios.get(`${baseUrl}/api/refund/${data}`, { params })

export const getRefundCriteria = (params) => axios.get(`${baseUrl}/api/refunds`, { params })

export const getRefundPointBalance = (params) =>
  axios.get(`${baseUrl}/api/refund/points`, { params })

export const getListRefund = (order_id, params) =>
  axios.get(`${baseUrl}/api/refund/list/${order_id}`, { params })

export const getRejectReason = (params) =>
  axios.get(`${baseUrl}/api/refund/reject-reason`, { params })

export const getSaldoHistory = (customerId, params) =>
  axios.get(`${baseUrl}/api/refund/refund-point-history/${customerId}`, {
    params,
  })

export const getRefundableProducts = (params) =>
  axios.get(`${baseUrl}/api/refund/refundable-products`, { params })

export const getRefundComplaintReason = () => axios.get(`${baseUrl}/api/refund/complaint-reason`)

// Put Section

export const putRefund = (id, payload) => axios.put(`${baseUrl}/api/refund/${id}`, payload)

export const putUpdateRefundStatus = (refundId, payload) =>
  axios.put(`${baseUrl}/api/refund/status/${refundId}`, payload)

// Post Section

export const postCreateRefund = (payload) => axios.post(`${baseUrl}/api/refund/create`, payload)
