import { memo } from 'react'
import styled from 'styled-components'
import { HeadInformation, HeadLabel, TableMain, TermCondition } from './components'

function TemplatePurchaseOrderLiteNewWithTax() {
  return (
    <>
      <Container>
        <HeadLabel />
        <HeadInformation />
        <TableMain />
        <TermCondition />
      </Container>
    </>
  )
}

export default memo(TemplatePurchaseOrderLiteNewWithTax)

const Container = styled.div`
  -webkit-print-color-adjust: exact !important;

  .bel {
    position: absolute;
    bottom: 0;
  }

  @media print {
    padding: 20px;
    background: white;
  }
`
