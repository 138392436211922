import { memo } from "react";
import styled from "styled-components";
import { TableSuratJalan, TablePickingList } from "./components";
// import { TextBody } from "components";

function Body({ data, isSuratJalan, selectedData }) {
  return (
    <>
      <Container>
        {/* <TablePickingList
          data={data}
          isSuratJalan={isSuratJalan}
          selectedData={selectedData}
        /> */}
        {!isSuratJalan && (
          <TablePickingList
            data={data}
            isSuratJalan={isSuratJalan}
            selectedData={selectedData}
          />
        )}
        {isSuratJalan && (
          <TableSuratJalan
            data={data}
            isSuratJalan={isSuratJalan}
            selectedData={selectedData}
          />
        )}
      </Container>
    </>
  );
}

export default memo(Body);

const Container = styled.div`
  width: 100%;
  //   border-collapse: collapse;
  //   height: fit-content;
  margin-top: 22px;
`;
