import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import { getFraudReferralList, postReleaseFraudReferral } from 'utils/apiList/fraudReferral'
import type {
  GetFraudReferralListRequestType,
  PostReleaseFraudReferralRequestType,
} from 'utils/apiList/fraudReferral'
import { getAllMissions } from 'utils/apiList/referralMission'
import type { GetAllMissionRequestType } from 'utils/apiList/referralMission'

export const SLICE_NAME = 'fraudReferral'

const validateParamsValue = (
  key: keyof GetAllMissionRequestType['params'] | keyof GetFraudReferralListRequestType['params'],
  value: string | number | Date | null,
) =>
  value
    ? {
        [key]: value,
      }
    : {}
export const fetchFraudReferralList = createAsyncThunk(
  `${SLICE_NAME}/fetchFraudReferralList`,
  async (_, { rejectWithValue, getState }) => {
    const {
      fraudReferral: { query, referralMission },
    } = getState() as StoreStateType
    try {
      const res = await getFraudReferralList({
        params: {
          campaignReferralId: query.campaignReferralId
            ? query.campaignReferralId
            : referralMission.data[referralMission.data.length - 1].id,
          ...validateParamsValue('customerId', query.customerId),
        },
      })

      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchReferralMissionList = createAsyncThunk(
  `${SLICE_NAME}/fetchReferralMissionList`,
  async (_, { rejectWithValue, getState }) => {
    const {
      fraudReferral: {
        referralMission: { query },
      },
    } = getState() as StoreStateType

    try {
      const res = await getAllMissions({
        params: {
          pageIndex: query.pageIndex,
          ...validateParamsValue('missionName', query.missionName),
          sortBy: 'created_at',
          sortDirection: 'desc',
        },
      })

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const updateReleaseFraudReferral = createAsyncThunk(
  `${SLICE_NAME}/updateReleaseFraudReferral`,
  async (request: PostReleaseFraudReferralRequestType, { rejectWithValue }) => {
    try {
      const res = await postReleaseFraudReferral(request)

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)
