export type GeneralQueryObjectType<QueryType> = {
  [Key in keyof QueryType]: string | number | boolean | Array<string | number | boolean>
}

export const generateQueryObjectToStringWithSameName = <T = Record<string, unknown>>(
  queryObject: GeneralQueryObjectType<T>,
) => {
  const query = new URLSearchParams()

  Object.entries(queryObject).forEach(([queryName, queryValue]) => {
    if (Array.isArray(queryValue)) {
      queryValue.forEach((value) => {
        query.append(queryName, `${value}`)
      })
    } else {
      query.append(queryName, `${queryValue}`)
    }
  })

  return query.toString()
}
