/* eslint-disable sonarjs/cognitive-complexity */
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { SystemIcon } from 'components'
import { useLocation } from 'react-router-dom'
import { RolesKeyType } from 'constant/roles'
import useURLParams from 'customHooks/useURLParams'

import { type AuthInitialStateType } from 'features/Auth/redux/Auth'
import { type SidebarConfigType } from 'config/sidebar'

type ButtonSideBarPropsType = {
  className?: string
  data: SidebarConfigType[number]
  onClick: (link: string) => void
  middleware?: boolean
  currentUserAuth?: RolesKeyType[]
  location_type?: string[]
  allowedPageID?: AuthInitialStateType['userData']['pages']
}

const ButtonSideBar = ({
  className,
  data,
  onClick,
  middleware,
  currentUserAuth,
  location_type,
  allowedPageID,
}: ButtonSideBarPropsType) => {
  // STATE HOOKS
  const [isExpand, setIsExpand] = useState(false)
  const [menu, setMenu] = useState<SidebarConfigType[number] | null>(null)
  const location = useLocation()
  const { get } = useURLParams()

  const findAllowedAccess = (arr1?: string[], arr2?: string[], allowForAllRoles = false) =>
    arr1?.some((item: string) => arr2?.includes(item)) || allowForAllRoles

  const findByPageID = (pageID: string) =>
    allowedPageID?.find((eachAllowedPageId) => eachAllowedPageId.page_id === pageID)

  const findByMultiplePageID = (
    multipleLinkWithPageID: SidebarConfigType[number]['multipleLinkWithPageID'],
  ) =>
    multipleLinkWithPageID?.find(
      (eachLinkAndPage) =>
        !!allowedPageID?.find(
          (eachAllowedPageId) => eachAllowedPageId.page_id === eachLinkAndPage.pageID,
        ),
    )

  const mapMultipleLinkToLink = (
    multipleLinkWithPageID: SidebarConfigType[number]['multipleLinkWithPageID'],
    link?: string,
  ) => multipleLinkWithPageID?.[0].link || link

  const doFilterMenu = (eachMenu: Omit<NonNullable<SidebarConfigType[number]>, 'subMenu'>) => {
    // check pageID or multiplePageID for parent only menu
    if (eachMenu.allowAll) {
      return {
        ...eachMenu,
        link: mapMultipleLinkToLink(eachMenu.multipleLinkWithPageID, eachMenu.link),
      }
    }
    if (eachMenu.multipleLinkWithPageID) {
      const selectedLinkItems = findByMultiplePageID(eachMenu.multipleLinkWithPageID)
      if (selectedLinkItems) {
        return { ...eachMenu, link: selectedLinkItems.link }
      }
    }
    if (eachMenu.pageID && findByPageID(eachMenu.pageID)) {
      return eachMenu
    }
    // end of the check

    // check legacy ROLE
    if (findAllowedAccess(currentUserAuth, eachMenu.allow)) {
      if (!eachMenu.location_type) {
        return eachMenu
      }
      if (findAllowedAccess(location_type, eachMenu.location_type)) {
        return eachMenu
      }
    }
    return null
  }

  useEffect(() => {
    const filterMenu = () => {
      // render all for no need middleware filter or allowed all
      if (!middleware || data.allowAll) {
        setMenu({
          ...data,
          link: mapMultipleLinkToLink(data?.multipleLinkWithPageID, data.link),
          subMenu: data.subMenu?.map((each) => ({
            ...each,
            link: mapMultipleLinkToLink(each?.multipleLinkWithPageID, each.link),
          })),
        })
        return
      }
      // check if they have submenu
      if (data.subMenu?.length) {
        const listOfMenu = data.subMenu?.reduce((prev, items) => {
          // check pageID or multiplePageID
          const filteredItems = doFilterMenu(items)
          if (filteredItems) {
            return [...prev, filteredItems]
          }
          // dont map items if no checked is true
          return [...prev]
        }, [] as NonNullable<SidebarConfigType[number]['subMenu']>) as NonNullable<
          SidebarConfigType[number]['subMenu']
        >
        if (listOfMenu && listOfMenu.length) {
          setMenu({
            ...data,
            subMenu: listOfMenu,
          })
          return
        }
        setMenu(null)
        return
      }
      // check parent only menu
      const parentMenu = doFilterMenu(data)
      if (parentMenu) {
        setMenu({
          ...parentMenu,
        })
        return
      }
      // end of the check
      setMenu(null)
    }
    filterMenu()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, allowedPageID])

  const isSubMenuActive = (el: NonNullable<SidebarConfigType[number]['subMenu']>[number]) => {
    if (el.id === 'create_supply_order_SO') {
      return el.link?.includes(location.pathname) && get('created') === 'false'
    }
    if (el.id === 'create_supply_order_grand') {
      return el.link === location.pathname && !get('created')
    }
    return el.link === location.pathname
  }

  return menu ? (
    <Container className={className}>
      <ParentWrapper>
        <Button
          isActive={!menu.subMenu && menu.link === location.pathname}
          onClick={() => {
            if (menu.subMenu) {
              setIsExpand(!isExpand)
              return
            }
            onClick(menu.link || '/')
          }}
        >
          {menu.name}
        </Button>
        {menu.subMenu && (
          <IconExpand onClick={() => setIsExpand(!isExpand)} isExpand={isExpand}>
            <SystemIcon iconName="dropdown" />
          </IconExpand>
        )}
        <IconWrapper>
          <SystemIcon iconName={menu.icon} fontSize="header" />
        </IconWrapper>
      </ParentWrapper>
      {menu.subMenu && (
        <WrapperSubMenu isExpand={isExpand} count={menu.subMenu.length}>
          {menu.subMenu.map((el) => (
            <Button
              key={el.name}
              isActive={!!isSubMenuActive(el)}
              onClick={() => onClick(el.link || '/')}
            >
              {el.name}
            </Button>
          ))}
        </WrapperSubMenu>
      )}
    </Container>
  ) : null
}

ButtonSideBar.defaultProps = {}

export default ButtonSideBar

const WrapperSubMenu = styled.div<{
  isExpand: boolean
  count: number
}>`
  /*height: ${({ isExpand, count }) => (isExpand ? `${count * 35}px` : '0px')};*/
  height: ${({ isExpand, count }) => (isExpand ? `${count * 41 + 41 / 2}px` : '0px')};
  overflow: hidden;
  transition: all ease 0.2s;
`

const IconExpand = styled.div<{
  isExpand: boolean
}>`
  position: absolute;
  right: 8px;
  top: 8px;
  transition: all ease 0.2s;
  cursor: pointer;
  transform: ${({ isExpand }) => (isExpand ? 'rotate(180deg)' : 'rotate(0)')};
`

const ParentWrapper = styled.div`
  position: relative;
  :hover {
    button {
      background-color: ${({ theme: { colors } }) => colors.mainFaded};
      color: ${({ theme: { colors } }) => colors.main};
    }
    i {
      ::before {
        color: ${({ theme: { colors } }) => colors.main};
      }
    }
  }
`

const Button = styled.button<{
  isActive: boolean
}>`
  width: 100%;
  background-color: none;
  border: none;
  background: ${({ isActive }) => (isActive ? '#EDF1F9' : 'none')};
  padding: 9px 20px 9px 40px;
  text-align: start;
  border-radius: 6px;
  transition: all ease 0.3s;
  cursor: pointer;
  color: ${({ theme: { colors }, isActive }) => (isActive ? '#1553B6' : colors.secondaryText)};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  :hover {
    background-color: ${({ theme: { colors } }) => colors.mainFaded};
    color: ${({ theme: { colors } }) => colors.main};
  }
  margin-bottom: 4px;
`
const Container = styled.div``

const IconWrapper = styled.div`
  position: absolute;
  top: 7px;
  left: 8px;
`
