import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CampaignSegmentStateType } from 'features/CampaignAndDiscount/@types/campaignSegmentList'

import { type GetPromoUserSegmentResponseType } from 'utils/apiList/promoUserSegment'

import { fetchPromoUserSegment, SLICE_NAME } from './thunk'

const initialState: CampaignSegmentStateType = {
  isLoading: false,
  userSegmentList: [],
  pagination: {
    pageSize: 20,
    pageIndex: 0,
    numberOfElements: 0,
  },
  query: {},
}

const campaignSegment = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    reset: () => initialState,
    setQuery: (state, action: PayloadAction<CampaignSegmentStateType['query']>) => {
      state.query = {
        ...state.query,
        ...action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPromoUserSegment.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPromoUserSegment.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(
        fetchPromoUserSegment.fulfilled,
        (state, action: PayloadAction<GetPromoUserSegmentResponseType['data']>) => {
          const { payload } = action

          state.pagination = payload.pagination

          state.userSegmentList = payload.data.list

          state.isLoading = false
        },
      )
  },
})

export const { setIsLoading, reset, setQuery } = campaignSegment.actions
export default campaignSegment.reducer
