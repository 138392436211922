import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type {
  InitialStateType,
  SetLoginStoriesPayloadType,
  SetQueryPayloadActionType,
  SetLoginStoryPayloadType,
  SetMediaImageRulesPayloadActionType,
  SetMediaFileRulesPayloadActionType,
  SetBackgroundImagesPayloadType,
  SetBackgroundImagePayloadActionType,
} from 'features/CategoryAndPosisi/@types/onboardingMedia'
import { Options } from 'react-lottie'

export const LOTTIE_OPTION_DEFAULT: Options = {
  loop: true,
  autoplay: true,
  animationData: null,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

export const SLICE_NAME = 'onboardingMedia'
export const initialState: InitialStateType = {
  isLoading: [],
  query: {
    direction: '',
    page_index: 0,
    page_size: 16,
    sort: '',
    type: '',
  },
  loginStories: [],
  backgroundImages: [],
  mediaRules: {
    images: {
      loginStory: {
        extensions: [],
        height: { max: 0, min: 0 },
        maxFileSize: 0,
        typeId: 'product-placement-new-login-stories',
        width: { max: 0, min: 0 },
      },
    },
    files: {
      background: { extensions: [], maxFileSize: 0, typeId: 'new-login-stories-image-lottie' },
    },
  },
}

const onboardingMedia = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setQuery: (state, action: PayloadAction<SetQueryPayloadActionType>) => {
      state.query = {
        ...state.query,
        ...action.payload,
      }
    },
    setIsloading: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.isLoading.push(true)
      } else {
        state.isLoading.pop()
      }
    },
    setLoginStories: (state, action: PayloadAction<SetLoginStoriesPayloadType>) => {
      state.loginStories = action.payload
    },
    setMediaImageRule: (state, action: PayloadAction<SetMediaImageRulesPayloadActionType>) => {
      const { key, rule } = action.payload
      state.mediaRules.images[key] = rule
    },
    setMediaFileRule: (state, action: PayloadAction<SetMediaFileRulesPayloadActionType>) => {
      const { key, rule } = action.payload
      state.mediaRules.files[key] = rule
    },
    setBackgroundImages: (state, action: PayloadAction<SetBackgroundImagesPayloadType>) => {
      state.backgroundImages = action.payload
    },
    setLoginStory: (state, action: PayloadAction<SetLoginStoryPayloadType>) => {
      const { uniqueID, value } = action.payload

      state.loginStories.some((el, i) => {
        if (el.uniqueID === uniqueID) {
          state.loginStories[i] = {
            ...state.loginStories[i],
            ...value,
          }
        }
        return el.uniqueID === uniqueID
      })
    },
    setBackgroundImage: (state, action: PayloadAction<SetBackgroundImagePayloadActionType>) => {
      const { uniqueID, value } = action.payload

      state.backgroundImages.some((el, i) => {
        if (el.uniqueID === uniqueID) {
          state.backgroundImages[i] = {
            ...state.backgroundImages[i],
            ...value,
          }
        }
        return el.uniqueID === uniqueID
      })
    },
  },
})

export const {
  reset,
  setQuery,
  setIsloading,
  setLoginStories,
  setLoginStory,
  setBackgroundImages,
  setMediaFileRule,
  setMediaImageRule,
  setBackgroundImage,
} = onboardingMedia.actions
export default onboardingMedia.reducer
