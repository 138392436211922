import { memo, useMemo } from 'react'
import { dateFormat } from 'utils/helpers/date'
import { useTemplatePrintQRv2 } from './useTemplateFPOPrintBarcode'
import { S } from './TemplateFPOPrintBarcodeStyle'

const Barcode = require('react-barcode')
const { TextBody } = require('components')

const TemplateFPOPrintBarcode = () => {
  const {
    state: {
      dataPrintBarcode: { printQRQTY },
    },
  } = useTemplatePrintQRv2()

  const renderQRCard = () => {
    const tmpData = []

    for (let i = 0; i < printQRQTY; i += 1) {
      tmpData.push(<QRCard index={i + 1} key={`${i}sas`} />)
    }

    return tmpData
  }

  return <S.Container> {renderQRCard()}</S.Container>
}

function QRCard({ index }: { index: number }) {
  const {
    state: { dataPrintBarcode, nameProduct },
  } = useTemplatePrintQRv2()

  const validateDate = useMemo(
    () => (dataPrintBarcode.expiryDate ? new Date(dataPrintBarcode.expiryDate) : new Date()),
    [dataPrintBarcode.expiryDate],
  )

  return (
    <S.QRWrapper odd={index % 2}>
      <S.QRCardContainer>
        <S.ProductName>
          <TextBody className="text-center" size="smallest">
            {nameProduct()}
          </TextBody>
        </S.ProductName>
        <S.BarcodeSection>
          <div className="ml-auto" />
          <S.Day style={{ transform: 'rotate(-90deg)' }}>
            <S.StyledTBody style={{ position: 'relative', top: '14px' }}>PS</S.StyledTBody>
          </S.Day>
          <Barcode
            marginTop={0}
            value={dataPrintBarcode.barcode}
            height="18mm"
            width={1.8}
            displayValue={false}
            flat
            marginRight={0}
          />
          <S.Day className="mr-auto">
            <S.StyledTBody style={{ position: 'relative', top: '8px' }}>
              {dateFormat({
                date: validateDate,
                format: 'DD',
              })}
            </S.StyledTBody>
          </S.Day>
        </S.BarcodeSection>
        <S.BarcodeText>
          <TextBody className="text-center" size="smallest">
            {dataPrintBarcode.barcode}
          </TextBody>
        </S.BarcodeText>
      </S.QRCardContainer>
    </S.QRWrapper>
  )
}

export default memo(TemplateFPOPrintBarcode)
