import { memo, useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { SystemIcon, Checkbox, RowCampaignAdd } from 'components'

import store from 'storeContext'

function TableCampaignAdd({
  data,
  stateCollections,
  actionList,
  isInProgressUpload,
  segmentOptions,
}) {
  const { listOfSelectedData } = stateCollections.getter
  const { setListOfSelectedData } = stateCollections.setter
  const { initListOfSelectedData } = stateCollections.initial

  const { state: stateCampaign } = useContext(store.CampaignContext)

  //INITIAL STATES
  const initCheckedClearAll = false

  //STATE HOOKS
  const [checkedClearAll, setCheckedClearAll] = useState(initCheckedClearAll)
  const [tableData, setTableData] = useState([])

  //EFFECT HOOKS
  useEffect(() => {
    if (listOfSelectedData.length > 0) {
      setCheckedClearAll(true)
    } else {
      setCheckedClearAll(false)
    }
  }, [listOfSelectedData.length])

  useEffect(() => {
    setTableData([...data])
  }, [stateCampaign.campaignAdd_PaginationData])

  const handleChangeClear = () => {
    setCheckedClearAll((prev) => {
      const allData = []

      tableData.forEach((el) => {
        if (el.campaignPriceDetails.length === 1) {
          allData.push(el.campaignPriceDetails[0])
        } else {
          el.campaignPriceDetails.forEach((ell) => {
            allData.push(ell)
          })
        }
      })

      if (listOfSelectedData.length > 0) {
        setListOfSelectedData(initListOfSelectedData)
      } else {
        setListOfSelectedData(allData)
      }
      return !prev
    })
  }

  const newActionList = {
    ...actionList,
    handleClickShow: (uniqueId) => {
      setTableData((prev) => {
        const newArr = prev.map((el) => {
          return { ...el, isShowRow: el.uniqueId === uniqueId ? !el.isShowRow : el.isShowRow }
        })
        return [...newArr]
      })
    },
  }

  const renderRow = () => {
    const arrElement = []
    tableData.forEach((el, idx) => {
      const headerData = {
        ...el.campaignPriceDetails[0],
        ...el,
      }
      arrElement.push(
        <RowCampaignAdd
          key={el.uniqueId}
          data={headerData}
          stateCollections={stateCollections}
          actionList={newActionList}
          isInProgressUpload={isInProgressUpload}
          isChild={false}
          isShow={true}
        />,
      )

      if (el.campaignPriceDetails.length > 1) {
        el.campaignPriceDetails.forEach((ell) => {
          const childData = {
            ...el,
            ...ell,
          }
          arrElement.push(
            <RowCampaignAdd
              key={ell.uniqueId}
              data={childData}
              stateCollections={stateCollections}
              actionList={newActionList}
              isInProgressUpload={isInProgressUpload}
              isChild
              isShow={el.isShowRow}
              segmentOptions={segmentOptions}
            />,
          )
        })
      } else if (
        el.campaignPriceDetails.length === 1 &&
        el.campaignPriceDetails[0].userSegment === 'TARGETED_USER'
      ) {
        el.campaignPriceDetails.forEach((ell) => {
          const childData = {
            ...el,
            ...ell,
          }

          arrElement.push(
            <RowCampaignAdd
              key={ell.uniqueId}
              data={childData}
              stateCollections={stateCollections}
              actionList={newActionList}
              isInProgressUpload={isInProgressUpload}
              isChild
              isShow={el.isShowRow}
              segmentOptions={segmentOptions}
            />,
          )
        })
      }
    })

    return arrElement
  }

  return (
    <>
      <Container>
        <thead>
          <Tr>
            <Th ratio="1">
              <WrapperCheck>
                <Checkbox checked={checkedClearAll} onChange={handleChangeClear} />
              </WrapperCheck>
            </Th>
            <Th ratio="3">
              Nama Hub
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th ratio="5">
              Nama Produk
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th ratio="3">
              Segmen
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th ratio="2">
              Harga
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th ratio="1">Stock</Th>
            <Th ratio="3">Percentage Astro</Th>
            <Th ratio="4">Harga Discount</Th>
            <Th ratio="3">Diskon</Th>
            <Th ratio="3">Kuota Discount</Th>
            <Th ratio="3">Qty/Hari</Th>
          </Tr>
        </thead>
        <tbody style={{ width: '100%' }}>{renderRow()}</tbody>
      </Container>
    </>
  )
}

TableCampaignAdd.defaultProps = {
  data: [],
}

export default memo(TableCampaignAdd)

const Container = styled.table`
  width: 100%;
  border-collapse: collapse;
  height: fit-content;
`
const Tr = styled.tr`
  background-color: ${({ theme: { colors } }) => colors.tableHead};
  display: flex;
  padding: 16px 32px;
`
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  text-align: start;
  padding: 12px 4px;
  display: flex;
  flex: ${({ ratio }) => ratio};
`

const WrapperCheck = styled.div`
  display: grid;
  place-items: center;
`
