import styled from 'styled-components'

export const Col = styled.div`
  width: ${({ width }) => (width ? `${width}%` : `100%`)};
  ${({ ml }) => (ml ? `margin-left:${ml};` : ``)}
`

export const StyledHeader = styled.div`
  background-color: #e9e9e9;
  width: 100%;
  padding: 8px 12px;
  color: #212121;
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
`

export const StyledRoundedCard = styled.div`
  border-radius: 8px;
  border: 1px solid #d3d3d3;
  min-height: ${({ height }) => height || 'auto'};
  box-sizing: border-box;
`
export const StyledTermConditonSection = styled.div`
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media print {
    page-break-before: ${({ pageBreak }) => pageBreak || 'auto'};
  }
`

export const StyledNotes = styled.div`
  padding: 8px;
  font-size: 12px;
  border-radius: 8px;
  background-color: #e9e9e9;
  border-left: 3px solid #909090;
`
