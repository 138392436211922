import apiServices from 'config/apiServiceApiGateway'
import { LimitSKUType, PaginationType, QueryParamsType } from '../@types/LimitSKU'

const { Get, Post, Put } = apiServices

export type GetLimitSKUResponseType = {
  data: LimitSKUType[]
  pagination: PaginationType
}

export type PostLimitSKUPayloadType = {
  location_id: number
  limit_sku: number
}

export type PutLimitSKUPayloadType = {
  limit_sku: number
}

export const getLimitSKU = (params: Partial<QueryParamsType>) =>
  Get<GetLimitSKUResponseType, true>({
    url: '/wms/internal/:version/supply-order/config-limits',
    version: 'v1',
    params,
  })

export const postLimitSKU = (data: PostLimitSKUPayloadType) =>
  Post({
    url: '/wms/internal/:version/supply-order/config-limits',
    version: 'v1',
    data,
  })

export const putLimitSKU = (id: number, data: PutLimitSKUPayloadType) =>
  Put({
    url: '/wms/internal/:version/supply-order/config-limits/:id',
    version: 'v1',
    urlParams: { id },
    data,
  })
