import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  SLICE_NAME,
  fetchAllMission,
  updateStatusReferralMission,
  fetchReferralStatus,
} from './referralMissionListThunk'

export interface MissionStateType {
  list: {
    id: number
    missionName: string
    startDate: string
    endDate: string
    milestoneCount: number
    status: {
      name: string
      value: string
    }
    uniqueId: string
  }[]
}

export type ReferralStatusListType = {
  name: string
  value: string
}
export interface QueryStateType {
  date: [Date | null, Date | null]
  missionName: string
  status: ReferralStatusListType
  pageIndex: number
  pageSize: number
}

export interface ReferralMissionListStateType {
  isLoading: boolean
  mission: MissionStateType
  selectedReferralMission: MissionStateType['list'][0]
  isShowModalDelete: boolean
  isShowModalActivation: boolean
  query: QueryStateType
  referralStatusList: ReferralStatusListType[]
}

export const initialState: ReferralMissionListStateType = {
  isLoading: false,
  mission: { list: [] },
  isShowModalDelete: false,
  isShowModalActivation: false,
  selectedReferralMission: {
    endDate: '',
    id: 0,
    milestoneCount: 0,
    missionName: '',
    startDate: '',
    status: { name: '', value: '' },
    uniqueId: '',
  },
  query: {
    date: [null, null],
    missionName: '',
    status: { name: '', value: '' },
    pageIndex: 0,
    pageSize: 20,
  },
  referralStatusList: [],
}

export type SetQueryPayloadType = {
  name: keyof QueryStateType
  value: QueryStateType[keyof QueryStateType]
}

const referralMissionList = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setIsShowModalDelete: (state, action: PayloadAction<{ value: boolean }>) => {
      const { value } = action.payload
      state.isShowModalDelete = value
    },
    setIsShowModalActivation: (state, action: PayloadAction<{ value: boolean }>) => {
      const { value } = action.payload
      state.isShowModalActivation = value
    },
    setSelectedReferral: (
      state,
      action: PayloadAction<{ value: ReferralMissionListStateType['selectedReferralMission'] }>,
    ) => {
      const { value } = action.payload
      state.selectedReferralMission = value
    },
    setQuery: (state, action: PayloadAction<SetQueryPayloadType>) => {
      const { value, name } = action.payload

      state.query[name] = value as never
    },
  },

  extraReducers(builder) {
    builder
      .addCase(fetchAllMission.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchAllMission.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchAllMission.fulfilled, (state, action) => {
        const { data } = action.payload
        state.isLoading = false
        state.mission.list = data.list.map((el) => ({
          ...el,
          uniqueId: window.crypto.randomUUID(),
        }))
      })
      .addCase(updateStatusReferralMission.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateStatusReferralMission.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateStatusReferralMission.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchReferralStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchReferralStatus.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchReferralStatus.fulfilled, (state, payload) => {
        state.isLoading = false
        state.referralStatusList = payload.payload.data.content
      })
  },
})

export const {
  reset,
  setIsShowModalDelete,
  setSelectedReferral,
  setQuery,
  setIsShowModalActivation,
} = referralMissionList.actions
export default referralMissionList.reducer
