import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

export type SOParamsDataType = {
  hub_id: string
  wh_id: string
  product_id: string
  total_allocation: number
  reorder_point: number
  unit: string
  multiplier: number
  product_type: string
  so_group: string
  requester_email: string
  priority: number
  optimal_stock: number
  safety_stock: number
  status: number
  remarks: string
  initial_balance: number
}

export type SOSchedulesDataType = {
  id: number
  wh_id: number
  so_group: string
  schedule_days: string
  schedule_time: string
  product_type: string
  created_at: string
  updated_at: string
  hub_ids: string
  action?: string
}

export type SOReportsDataType = {
  id: number
  file_path: string
  status: string
  created_at: string
  action?: string
}

export type SOAutomationQueryType = {
  sort: string
  direction: string
  page_size: number
  page_index: number
  number_of_elements: number
  total_elements: number
  total_pages: number
  search?: string
}

export interface GetSOParamResponseInterface {
  data: SOParamsDataType[]
  pagination: SOAutomationQueryType
}

export interface GetSOSchedulesResponseInterface {
  data: SOSchedulesDataType[]
  pagination: SOAutomationQueryType
}

export interface GetSOReportsResponseInterface {
  data: SOReportsDataType[]
  pagination: SOAutomationQueryType
}

export const fetchSOParamsAPI = (params: SOAutomationQueryType) =>
  axiosInstanceApiGateway.Get<GetSOParamResponseInterface, true>({
    url: '/commercial/internal/:version/so-automation/so-param',
    version: 'v1',
    params,
  })

export const fetchSOSchedulesAPI = (params: SOAutomationQueryType) =>
  axiosInstanceApiGateway.Get<GetSOSchedulesResponseInterface, true>({
    url: '/commercial/internal/:version/so-automation/so-schedules',
    version: 'v1',
    params,
  })

export const uploadSoSchedulesAPI = (file: File) =>
  axiosInstanceApiGateway.Post({
    url: '/commercial/internal/:version/so-automation/so-schedules/upload',
    version: 'v1',
    data: {
      chunk_data: file,
    },
    config: {
      headers: {
        /* eslint-disable @typescript-eslint/naming-convention */
        'Content-Type': 'multipart/form-data',
      },
    },
  })

export const deleteSoScheduleByIdAPI = (id: number) =>
  axiosInstanceApiGateway.Delete<{ remarks: string }>({
    url: '/commercial/internal/:version/so-automation/so-schedule/:id',
    version: 'v1',
    urlParams: { id },
  })

export const deleteAllSoSchedulesAPI = () =>
  axiosInstanceApiGateway.Delete<{ remarks: string }>({
    url: '/commercial/internal/:version/so-automation/so-schedules',
    version: 'v1',
  })

export const fetchSOReportsAPI = (params: SOAutomationQueryType) =>
  axiosInstanceApiGateway.Get<GetSOReportsResponseInterface, true>({
    url: '/commercial/internal/:version/so-automation/so-upload/files',
    version: 'v1',
    params,
  })

export const clearRemarksAPI = (email: string) =>
  axiosInstanceApiGateway.Post({
    url: '/commercial/internal/:version/so-automation/update-remarks',
    version: 'v1',
    data: { remarks: `SOReport Cleared by ${email}` },
  })

export const downloadSoReportAPI = (id: number) =>
  axiosInstanceApiGateway.Get<{ file_url: string }, true>({
    url: '/commercial/internal/:version/so-automation/so-upload/files/:id/download',
    version: 'v1',
    urlParams: { id },
  })
