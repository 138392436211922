import styled, { keyframes } from 'styled-components'

interface LoaderPropsInterface {
  size?: number
}

const spin = keyframes`
    0% { 
      transform: rotate(0deg); 
    }
    100% { 
      transform: rotate(360deg); 
    }
  `

const LoaderComponent = styled.div<LoaderPropsInterface>`
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid ${({ theme }) => theme.colors.blueTag};
  min-width: ${({ size }) => size}px;
  min-height: ${({ size }) => size}px;
  -webkit-animation: ${spin} 2s linear infinite; /* Safari */
  animation: ${spin} 2s linear infinite;
  margin: auto;
`

const LoadingIndicator = ({ size = 20 }: LoaderPropsInterface) => <LoaderComponent size={size} />

export default LoadingIndicator
