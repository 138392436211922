import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import {
  InitialStateType,
  DialogStateType,
  LimitSKUType,
  PaginationType,
} from 'features/SupplyOrder/@types/LimitSKU'

export const SLICE_NAME_LIMIT_SKU = 'limitSKU'

export const initialState: InitialStateType = {
  isLoading: false,
  dialogState: 'CLOSED',
  limitSKUList: [],
  pagination: {
    page_index: 1,
    page_size: 20,
    number_of_elements: 0,
  },
  locationType: [],
  selectedLocationType: null,
  locationList: [],
  selectedLocation: null,
}

const slice = createSlice({
  name: SLICE_NAME_LIMIT_SKU,
  initialState,
  reducers: {
    resetState: () => initialState,
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setDialogState: (state, { payload }: PayloadAction<DialogStateType>) => {
      state.dialogState = payload
    },
    setLimitSKUList: (state, { payload }: PayloadAction<LimitSKUType[]>) => {
      state.limitSKUList = payload
    },
    setPagination: (state, { payload }: PayloadAction<PaginationType>) => {
      state.pagination = payload
    },
    setLocationType: (state, { payload }: PayloadAction<InitialStateType['locationType']>) => {
      state.locationType = payload
    },
    setSelectedLocationType: (
      state,
      { payload }: PayloadAction<InitialStateType['selectedLocationType']>,
    ) => {
      state.selectedLocationType = payload
    },
    setLocationList: (state, { payload }: PayloadAction<InitialStateType['locationList']>) => {
      state.locationList = payload
    },
    setSelectedLocation: (
      state,
      { payload }: PayloadAction<InitialStateType['selectedLocation']>,
    ) => {
      state.selectedLocation = payload
    },
  },
})

export const {
  resetState,
  setDialogState,
  setIsLoading,
  setLimitSKUList,
  setPagination,
  setSelectedLocationType,
  setLocationType,
  setLocationList,
  setSelectedLocation,
} = slice.actions
export default slice.reducer
