import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit'
import { withLoadingReducer } from 'utils/reducerHandler'
import {
  type RoleDetailInterface,
  type PaginationInterface,
  type GetPagePermissionParamsType,
  type GetPagePermissionContentInterface,
  type RolesPermissionInterface,
} from 'features/Account/services/adminAccess/roles'
import {
  getRolesLists,
  getPagePermissions,
  getRoleById,
  deleteRole,
  putCreateRole,
  postCreateRole,
} from './roleAdminAccessThunk'

export type FormRolesListType = {
  sort?: string
  direction?: string
  size?: number
  index?: number
  name?: string
}

export interface RoleAdminAccessStateType {
  isLoading: boolean
  isShowAddEditDrawer: boolean
  isShowDeleteDialog: boolean
  formRolesList: FormRolesListType
  formPagePermission: GetPagePermissionParamsType
  roleListData: RoleDetailInterface[]
  pagePermissionData: {
    pagination: PaginationInterface
    permissionsPage: GetPagePermissionContentInterface[]
  }
  pagination: PaginationInterface
  editFormRole: {
    id: number
    permissions: RolesPermissionInterface[]
    roleName: string
  }
  deleteRoleForm: {
    id: number
    roleName: string
  }
}

export const initialState: RoleAdminAccessStateType = {
  isLoading: false,
  isShowAddEditDrawer: false,
  isShowDeleteDialog: false,
  formRolesList: { sort: `id`, direction: `DESC`, size: 10, index: 0, name: `` },
  roleListData: [],
  pagination: {} as PaginationInterface,
  formPagePermission: {
    name: ``,
    page_index: 0,
    page_size: 30,
  },
  pagePermissionData: {
    pagination: {} as PaginationInterface,
    permissionsPage: [],
  },
  editFormRole: {
    id: 0,
    permissions: [],
    roleName: ``,
  },
  deleteRoleForm: {
    id: 0,
    roleName: ``,
  },
}

const SLICE_NAME = 'roleAdminAccess'

const roleAdminAccess = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    resetFromRolesList: (state) => {
      state.formRolesList = initialState.formRolesList
    },
    resetEditFormRole: (state) => {
      state.editFormRole = initialState.editFormRole
    },
    setShowAddEditDrawer: (state, action: PayloadAction<boolean>) => {
      state.isShowAddEditDrawer = action.payload
    },
    setShowDeleteDialog: (state, action: PayloadAction<boolean>) => {
      state.isShowDeleteDialog = action.payload
    },
    setEditFormRole: (state, action: PayloadAction<RoleAdminAccessStateType['editFormRole']>) => {
      state.editFormRole = action.payload
    },
    setDeleteRoleForm: (
      state,
      action: PayloadAction<RoleAdminAccessStateType['deleteRoleForm']>,
    ) => {
      state.deleteRoleForm = action.payload
    },
    setFormRolesList: (state, action: PayloadAction<FormRolesListType>) => {
      const newPayload = action.payload
      state.formRolesList = { ...state.formRolesList, ...newPayload }
    },
    setFormPagePermission: (state, action: PayloadAction<GetPagePermissionParamsType>) => {
      const newPayload = action.payload
      state.formPagePermission = { ...state.formPagePermission, ...newPayload }
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
  },
  extraReducers: withLoadingReducer(
    (builder: ActionReducerMapBuilder<RoleAdminAccessStateType>) => {
      builder
        .addCase(getRolesLists.fulfilled, (state, action) => {
          state.roleListData = action.payload.roles
          state.pagination = action.payload.pagination
        })
        .addCase(getPagePermissions.fulfilled, (state, action) => {
          state.pagePermissionData.permissionsPage = action.payload.permissions
          state.pagePermissionData.pagination = action.payload.pagination
        })
    },
    [getRolesLists, getPagePermissions, getRoleById, deleteRole, putCreateRole, postCreateRole],
  ),
})

export const {
  reset,
  setShowDeleteDialog,
  setShowAddEditDrawer,
  resetFromRolesList,
  setFormRolesList,
  setFormPagePermission,
  setEditFormRole,
  setDeleteRoleForm,
  resetEditFormRole,
  setLoading,
} = roleAdminAccess.actions

export default roleAdminAccess.reducer
