import {
  SET_IS_LOADING_CATEGORY,
  SET_CATEGORIES,
  SET_PAGINATION_DATA,
  CATEGORY_SET_CUSTOMER_LIST,
  CATEGORY_SET_SELECTED_CUSTOMER_LIST,
  CATEGORY_SET_TARGETING_TYPE_LIST,
} from 'storeContext/actionsType/category'

export const initialStateCategory = {
  isLoading: false,
  categories: [],
  paginationData: {},

  // popup date
  customerList: [],
  selectedCustomerList: [],
  targetingTypeList: [],
}

export default function category(state = initialStateCategory, { type, payload }) {
  switch (type) {
    case SET_IS_LOADING_CATEGORY:
      return {
        ...state,
        isLoading: payload,
      }
    case SET_CATEGORIES:
      return {
        ...state,
        categories: payload,
      }
    case SET_PAGINATION_DATA:
      return {
        ...state,
        paginationData: payload,
      }
    case CATEGORY_SET_CUSTOMER_LIST:
      return {
        ...state,
        customerList: payload,
      }
    case CATEGORY_SET_SELECTED_CUSTOMER_LIST: {
      return {
        ...state,
        selectedCustomerList: payload,
      }
    }
    case CATEGORY_SET_TARGETING_TYPE_LIST:
      return {
        ...state,
        targetingTypeList: payload,
      }
    default:
      return state
  }
}
