import { memo } from 'react'
import styled from 'styled-components'
import { TextBody, Toggle, Button } from 'components'
//import dayjs from "dayjs";
import { formatMoneyRMG } from 'helpers'
import { useHistory } from 'react-router-dom'
import { buyerPoints, buyerAstroSaldoHistory } from 'config/routes'
function RowBuyer({ data, handlerChangeActive, roles }) {
  const history = useHistory()

  const handleGoToBuyerPoint = () => {
    if (roles.viewOnly) return
    history.push(
      `${buyerPoints}?costumerId=${data?.customer_id}&fullName=${data?.customer_first_name}`,
    )
  }

  const handleGoToSaldoAstro = () => {
    if (roles.viewOnly) return
    history.push(
      `${buyerAstroSaldoHistory}?costumerId=${data?.customer_id}&fullName=${data?.customer_first_name}`,
    )
  }

  return (
    <Tr>
      <Td ratio="1">
        <TextBody weight="light" color="textSoft">
          {data?.customer_id}
        </TextBody>
      </Td>
      <Td ratio="3">
        <NameContainer>
          <TextBody weight="light" color="textSoft">
            {data?.customer_first_name} {data?.customer_last_name}
          </TextBody>
          {data?.customer_is_fraud && <IsFraud />}
        </NameContainer>
      </Td>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {data?.customer_referral_code}
        </TextBody>
      </Td>
      <Td ratio="4">
        <TextBody weight="light" color="textSoft">
          {data?.customer_phone_number}
        </TextBody>
      </Td>
      <Td ratio="4">
        <TextBody weight="light" color="textSoft">
          {data?.customer_email}
        </TextBody>
      </Td>
      <Td ratio="3">
        <Button variant="wrapper" onClick={handleGoToSaldoAstro}>
          <TextBody weight="light" className="pointer" color="main">
            {data?.customer_refund_point || data?.customer_refund_point !== 0
              ? `${formatMoneyRMG(data?.customer_refund_point)}`
              : '0 Saldo Astro'}
          </TextBody>
        </Button>
      </Td>
      <Td ratio="3">
        <Button variant="wrapper" onClick={handleGoToBuyerPoint}>
          <TextBody weight="light" className="pointer" color="main">
            {data?.customer_point
              ? `${formatMoneyRMG(data?.customer_point, '')} Points`
              : '0 Points'}
          </TextBody>
        </Button>
      </Td>
      <Td ratio="1">
        <ActionWrapper>
          <Toggle
            active={data?.customer_active}
            disabled={roles.blockForToggleStatus}
            onClick={() =>
              handlerChangeActive({
                ...data,
                customer_active: !data.customer_active,
              })
            }
          />
        </ActionWrapper>
      </Td>
    </Tr>
  )
}

function IsFraud() {
  return (
    <FraudTextContainer>
      <FraudText>Fraud</FraudText>
    </FraudTextContainer>
  )
}

export default memo(RowBuyer)

const NameContainer = styled.div`
  display: flex;
`

const Tr = styled.tr`
  display: flex;
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
`
const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`

const ActionWrapper = styled.div`
  display: flex;
  i {
    margin-left: 20px;
  }
`

const FraudTextContainer = styled.div`
  background: #f5e4e7;
  padding: 1px 5px;
  margin-left: 12px;
  align-self: center;
`

const FraudText = styled.p`
  color: #f0525e;
`
