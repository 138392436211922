import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getTemplateFreshPurchaseOrder,
  postBulkUploadFreshPurchaseOrder,
  GetTemplateFreshPurchaseOrderType,
  PostBulkUploadFpoSuccessResponseType,
  ConfigMultipartFormDataType,
} from 'utils/apiList/freshPurchaseOrder'
import { callErrorMsg } from 'helpers/errorMsg'
import type { AxiosError } from 'axios'

export const SLICE_NAME = 'freshPurchaseOrderBulkUpload'

export const getDownloadTemplateFreshPurchaseOrder = createAsyncThunk<
  GetTemplateFreshPurchaseOrderType,
  void
>(`${SLICE_NAME}/getDownloadTemplateFreshPurchaseOrder`, async (_, { rejectWithValue }) => {
  try {
    const response = await getTemplateFreshPurchaseOrder()
    return response.data
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(err)
  }
})

export const bulkUploadFreshPurchaseOrder = createAsyncThunk(
  `${SLICE_NAME}/bulkUploadFreshPurchaseOrder`,
  async (
    handler: { formData: string; configHeader: ConfigMultipartFormDataType },
    { rejectWithValue },
  ) => {
    const { formData, configHeader } = handler
    try {
      const response = await postBulkUploadFreshPurchaseOrder(formData, configHeader)
      return response.data
    } catch (err) {
      const errResponse = err as AxiosError<PostBulkUploadFpoSuccessResponseType>
      callErrorMsg(errResponse.response?.data)
      return rejectWithValue(errResponse.response?.data)
    }
  },
)
