import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import { callErrorMsg } from 'helpers/errorMsg'
import {
  getSeoStatus,
  getDynamicLandingDetail,
  postDynamicLanding,
  putDynamicLanding,
  getComponentTypeList,
  getBannerLayoutList,
  getMastheadLayoutList,
  getArticleComponent,
  putArticleComponent,
  postArticleComponent,
  deleteArticleComponent,
  getSectionCarouselList,
  getCatalogueCarouselList,
  getCategoryCarouselList,
  postBannerComponent,
  putBannerComponent,
  getBannerComponent,
  deleteBannerComponent,
  putComponentSort,
  postMastheadComponent,
  deleteMastheadComponent,
  getMastheadComponent,
  putMastheadComponent,
  deleteDynamicChannelComponent,
  getDynamicChannelComponent,
  postDynamicChannelComponent,
  putDynamicChannelComponent,
  getProductTabbingComponent,
  postProductTabbingComponent,
  putProductTabbingComponent,
  deleteProductTabbingComponent,
  getTabbingSectionList,
  type CategoryType,
  type ComponentVariantType,
  type SeoStatusType,
  type SectionType,
  type CatalogueType,
  type DynamicLandingType,
  type PostPutDyamicLandingPayloadType,
  type ComponentSectionVariantType,
  type BannerLayoutType,
  type MastheadLayoutType,
  type ArticleComponentSectionType,
  type PostPutArticleComponentPayloadType,
  type PostPutBannerComponentPayloadType,
  type BannerComponentSectionType,
  type PutComponentSortPayloadType,
  type PostPutMastheadComponentPayloadType,
  type MastheadComponentSectionType,
  type ImageViewportType,
  type DynamicChannelComponentType,
  type PostPutDynamicChannelComponentPayloadType,
  type ProductTabbingComponentType,
  type PostPutProductTabbingComponentPayloadType,
} from 'utils/apiList/dynamicLanding'
import {
  getImageRules,
  postImage,
  type ImageRulesType,
  type ImageVariantIdType,
  type PostImageRequestPayload,
} from 'utils/apiList/images'
import { toastSuccess } from 'utils/toast'

export type {
  SeoStatusType,
  SectionType,
  CatalogueType,
  CategoryType,
  PostPutDyamicLandingPayloadType,
  ComponentSectionVariantType,
  BannerLayoutType,
  ComponentVariantType,
  MastheadLayoutType,
  DynamicLandingType,
  ArticleComponentSectionType,
  PostPutArticleComponentPayloadType,
  ImageVariantIdType,
  ImageRulesType,
  PostPutBannerComponentPayloadType,
  BannerComponentSectionType,
  PutComponentSortPayloadType,
  PostPutMastheadComponentPayloadType,
  MastheadComponentSectionType,
  ImageViewportType,
  DynamicChannelComponentType,
  PostPutDynamicChannelComponentPayloadType,
  ProductTabbingComponentType,
  PostPutProductTabbingComponentPayloadType,
}

export const transformDataItemsBannerOrMasthead = (
  sectionData: BannerComponentSectionType | MastheadComponentSectionType,
) =>
  sectionData.items.map((i) => {
    const { imageId, ...rest } = i

    return {
      ...rest,
      customId: crypto.randomUUID(),
      id: i.imageId,
    }
  })

const SLICE_NAME = 'dynamicLanding'

export const COMMON_PROMPT_MESSAGE = 'Kamu memiliki perubahan yang belum disimpan, tetap pergi?'

export type CustomBannerComponentSectionType = Omit<BannerComponentSectionType, 'items'> & {
  items: (Omit<BannerComponentSectionType['items'][0], 'imageId'> & {
    customId: string
    id: number
  })[]
}

export type CustomMastheadComponentSectionType = CustomBannerComponentSectionType

export type ConfigSectionComponentType = DynamicLandingType['components'][0] & {
  customId: string
  data?:
    | ArticleComponentSectionType
    | CustomBannerComponentSectionType
    | CustomMastheadComponentSectionType
    | DynamicChannelComponentType
    | ProductTabbingComponentType
}

export type DeleteModalInfoType = {
  data: ConfigSectionComponentType
  index: number
}

export type BannerLayoutChangeModalInfoType = {
  targetBannerLayoutId: number
}

export type ModalDataType = {
  type: 'delete' | 'banner-layout-change'
  info: DeleteModalInfoType | BannerLayoutChangeModalInfoType
}
export type SchedulerType = {
  start_date: string
  end_date: string
}
export interface DynamicLandingStateType {
  isLoading: boolean
  isUploadingImage: boolean
  constants: {
    seoStatusList: SeoStatusType[]
    componentTypeList: ComponentSectionVariantType[]
    bannerLayoutList: BannerLayoutType[]
    mastheadLayoutList: MastheadLayoutType[]

    // product carousel
    sectionCarouselList: SectionType[]
    catalagoueCarouselList: CatalogueType[]
    categoryCarouselList: CategoryType[]

    // tabbing
    productTabbingSectionList: SectionType[]
    // image rules
    openGraphImageRules: ImageRulesType | null
  }
  dataDetail: DynamicLandingType | null
  generalEditForm: {
    title: string
    domainUrl: string
    iosLink: string
    androidLink: string
    seoStatus: SeoStatusType | null
    ogImageId: number
    ogImageUrl: string
    ogImageFilePath: string
    ogImageWidth: number
    ogImageHeight: number
    metaDescription: string
    metaKeyword: string
  }
  configSectionComponents: ConfigSectionComponentType[]
  currentEditingSection: ConfigSectionComponentType | null
  modalData: ModalDataType | null
  scheduler: SchedulerType
}

export type GeneralEditFormValuesType = string | SeoStatusType | File | null | number

export const initialArticleComponentData = {
  title: '',
  content: '',
}

export const initialState: DynamicLandingStateType = {
  isLoading: false,
  isUploadingImage: false,
  constants: {
    seoStatusList: [],
    componentTypeList: [],
    bannerLayoutList: [],
    mastheadLayoutList: [],

    sectionCarouselList: [],
    catalagoueCarouselList: [],
    categoryCarouselList: [],

    productTabbingSectionList: [],

    openGraphImageRules: null,
  },
  dataDetail: null,
  generalEditForm: {
    title: '',
    domainUrl: '',
    iosLink: '',
    androidLink: '',
    seoStatus: null,
    ogImageId: 0,
    ogImageFilePath: '',
    ogImageUrl: '',
    ogImageWidth: 0,
    ogImageHeight: 0,
    metaDescription: '',
    metaKeyword: '',
  },
  configSectionComponents: [],
  currentEditingSection: null,
  modalData: null,
  scheduler: { end_date: new Date().toISOString(), start_date: new Date().toISOString() },
}

const dynamicChannelSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    resetScheduler: (state) => {
      state.scheduler = initialState.scheduler
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setGeneralEditForm: (
      state,
      action: PayloadAction<{
        key: keyof DynamicLandingStateType['generalEditForm']
        value: GeneralEditFormValuesType
      }>,
    ) => {
      const { payload } = action

      state.generalEditForm = {
        ...state.generalEditForm,
        [payload.key]: payload.value,
      }
    },
    setConfigSectionComponents: (
      state,
      action: PayloadAction<DynamicLandingStateType['configSectionComponents']>,
    ) => {
      state.configSectionComponents = action.payload
    },
    setConfigSectionComponentData: (
      state,
      action: PayloadAction<{
        data: ConfigSectionComponentType['data']
        index: number
      }>,
    ) => {
      const { payload } = action
      const { data, index } = payload

      const newConfigSectionComponents = [...state.configSectionComponents]

      newConfigSectionComponents[index].data = data

      state.configSectionComponents = newConfigSectionComponents
    },
    setCurrentEditingSection: (
      state,
      action: PayloadAction<DynamicLandingStateType['currentEditingSection']>,
    ) => {
      const { payload } = action

      state.currentEditingSection = payload
    },
    setModalData: (state, action: PayloadAction<DynamicLandingStateType['modalData']>) => {
      const { payload } = action

      state.modalData = payload
    },
    setScheduler: (state, action: PayloadAction<Partial<SchedulerType>>) => {
      const { payload } = action
      state.scheduler = { ...state.scheduler, ...payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSeoStatus.fulfilled, (state, action) => {
      const { payload } = action

      state.constants = {
        ...state.constants,
        seoStatusList: payload || [],
      }
    })
    builder.addCase(fetchDynamicLandingDetail.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchDynamicLandingDetail.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchDynamicLandingDetail.fulfilled, (state, action) => {
      state.isLoading = false

      const { payload } = action

      state.dataDetail = payload

      state.generalEditForm = {
        ...state.generalEditForm,
        title: payload.name,
        domainUrl: payload.urlDomain,
        iosLink: payload.iosLink,
        androidLink: payload.androidLink,
        seoStatus: payload.seoStatus,
        ogImageUrl: payload.ogImageUrl,
        ogImageId: payload.ogImageId,
        ogImageFilePath: payload.ogImageFilePath,
        ogImageWidth: payload.ogImageWidth,
        ogImageHeight: payload.ogImageHeight,
        metaDescription: payload.metaDescription,
        metaKeyword: payload.metaKeyword,
      }

      const { components } = payload

      const transformedSectionComponents = components.map((i) => ({
        ...i,
        customId: crypto.randomUUID(),
      }))

      state.currentEditingSection = null

      state.configSectionComponents = transformedSectionComponents
    })
    builder.addCase(uploadImage.pending, (state) => {
      state.isUploadingImage = true
    })
    builder.addCase(uploadImage.rejected, (state) => {
      state.isUploadingImage = false
    })
    builder.addCase(uploadImage.fulfilled, (state) => {
      state.isUploadingImage = false
    })
    builder.addCase(createDynamicLanding.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(createDynamicLanding.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(createDynamicLanding.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(updateDynamicLanding.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updateDynamicLanding.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(updateDynamicLanding.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchComponentTypeList.fulfilled, (state, action) => {
      const { payload } = action

      state.constants = {
        ...state.constants,
        componentTypeList: payload?.data || [],
      }
    })
    builder.addCase(fetchBannerLayoutList.fulfilled, (state, action) => {
      const { payload } = action

      state.constants = {
        ...state.constants,
        bannerLayoutList: payload?.data || [],
      }
    })
    builder.addCase(fetchMastheadLayoutList.fulfilled, (state, action) => {
      const { payload } = action

      state.constants = {
        ...state.constants,
        mastheadLayoutList: payload?.data || [],
      }
    })
    builder.addCase(fetchSectionCarouselList.fulfilled, (state, action) => {
      const { payload } = action

      state.constants = {
        ...state.constants,
        sectionCarouselList: payload || [],
      }
    })
    builder.addCase(fetchCatalogueCarouselList.fulfilled, (state, action) => {
      state.constants = {
        ...state.constants,
        catalagoueCarouselList: action.payload,
      }
    })
    builder.addCase(fetchCategoryCarouselList.fulfilled, (state, action) => {
      state.constants = {
        ...state.constants,
        categoryCarouselList: action?.payload,
      }
    })
    builder.addCase(fetchOpenGraphImageRules.fulfilled, (state, action) => {
      const { payload } = action

      state.constants = {
        ...state.constants,
        openGraphImageRules: payload,
      }
    })
    builder.addCase(fetchArticleComponent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchArticleComponent.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchArticleComponent.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(updateArticleComponent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updateArticleComponent.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(updateArticleComponent.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(createArticleComponent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(createArticleComponent.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(createArticleComponent.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(removeArticleComponent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(removeArticleComponent.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(removeArticleComponent.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(createBannerComponent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(createBannerComponent.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(createBannerComponent.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(updateBannerComponent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updateBannerComponent.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(updateBannerComponent.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchBannerComponent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchBannerComponent.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchBannerComponent.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(removeBannerComponent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(removeBannerComponent.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(removeBannerComponent.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(createMastheadComponent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(createMastheadComponent.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(createMastheadComponent.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(removeMastheadComponent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(removeMastheadComponent.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(removeMastheadComponent.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchMastheadComponent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchMastheadComponent.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchMastheadComponent.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(removeDynamicChannelComponent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(removeDynamicChannelComponent.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(removeDynamicChannelComponent.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchDynamicChannelComponent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchDynamicChannelComponent.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchDynamicChannelComponent.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(createDynamicChannelComponent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(createDynamicChannelComponent.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(createDynamicChannelComponent.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(updateDynamicChannelComponent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updateDynamicChannelComponent.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(updateDynamicChannelComponent.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchProductTabbingComponent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchProductTabbingComponent.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchProductTabbingComponent.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(createProductTabbingComponent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(createProductTabbingComponent.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(createProductTabbingComponent.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(removeProductTabbingComponent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(removeProductTabbingComponent.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(removeProductTabbingComponent.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(updateProductTabbingComponent.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updateProductTabbingComponent.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(updateProductTabbingComponent.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(fetchTabbingSectionList.fulfilled, (state, action) => {
      const { payload } = action

      state.constants = {
        ...state.constants,
        productTabbingSectionList: payload || [],
      }
    })
  },
})

export const {
  reset,
  setIsLoading,
  setGeneralEditForm,
  setConfigSectionComponents,
  setConfigSectionComponentData,
  setCurrentEditingSection,
  setModalData,
  setScheduler,
  resetScheduler,
} = dynamicChannelSlice.actions

export default dynamicChannelSlice.reducer

export const fetchSeoStatus = createAsyncThunk(
  `${SLICE_NAME}/fetchSeoStatus`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getSeoStatus()

      return data?.data?.data || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar SEO status')
      return rejectWithValue(err)
    }
  },
)

export const fetchDynamicLandingDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchDynamicLandingDetail`,
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await getDynamicLandingDetail(id)

      return data?.data as DynamicLandingType
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan detail landing page')
      return rejectWithValue(err)
    }
  },
)

export const uploadImage = createAsyncThunk(
  `${SLICE_NAME}/uploadImage`,
  async (
    payload: { typeId: ImageVariantIdType; data: PostImageRequestPayload },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await postImage(payload.typeId, payload.data)

      return data?.data
    } catch (err) {
      callErrorMsg(err, 'Gagal mengunggah gambar')
      return rejectWithValue(err)
    }
  },
)

export const createDynamicLanding = createAsyncThunk(
  `${SLICE_NAME}/createDynamicLanding`,
  async (payload: PostPutDyamicLandingPayloadType, { rejectWithValue }) => {
    try {
      const { data } = await postDynamicLanding(payload)

      toastSuccess('Berhasil membuat landing page')
      return data.data
    } catch (err) {
      callErrorMsg(err, 'Gagal membuat landing page', { autoClose: false })
      return rejectWithValue(err)
    }
  },
)

export const updateDynamicLanding = createAsyncThunk(
  `${SLICE_NAME}/updateDynamicLanding`,
  async (
    payload: { id: number; payload: PostPutDyamicLandingPayloadType },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await putDynamicLanding(payload.id, payload.payload)

      toastSuccess('Berhasil update landing page')
      return data.data
    } catch (err) {
      callErrorMsg(err, 'Gagal update landing page', { autoClose: false })
      return rejectWithValue(err)
    }
  },
)

export const fetchComponentTypeList = createAsyncThunk(
  `${SLICE_NAME}/fetchComponentTypeList`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getComponentTypeList()

      return data || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan list tipe komponen')
      return rejectWithValue(err)
    }
  },
)

export const fetchBannerLayoutList = createAsyncThunk(
  `${SLICE_NAME}/fetchBannerLayoutList`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getBannerLayoutList()

      return data?.data || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan list tipe layout banner')
      return rejectWithValue(err)
    }
  },
)

export const fetchMastheadLayoutList = createAsyncThunk(
  `${SLICE_NAME}/fetchMastheadLayoutList`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getMastheadLayoutList()

      return data?.data || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan list tipe layout masthead')
      return rejectWithValue(err)
    }
  },
)

export const fetchArticleComponent = createAsyncThunk(
  `${SLICE_NAME}/fetchArticleComponent`,
  async (
    payload: { dynamicLandingId: number; id: number; index: number },
    { rejectWithValue, dispatch, getState },
  ) => {
    try {
      const { data } = await getArticleComponent(payload.dynamicLandingId, payload.id)

      const sectionData = data?.data

      dispatch(setConfigSectionComponentData({ data: sectionData, index: payload.index }))

      const {
        dynamicLanding: { configSectionComponents },
      } = getState() as StoreStateType

      dispatch(setCurrentEditingSection(configSectionComponents[payload.index]))

      return sectionData
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateArticleComponent = createAsyncThunk(
  `${SLICE_NAME}/updateArticleComponent`,
  async (
    payload: {
      dynamicLandingId: number
      id: number
      payload: PostPutArticleComponentPayloadType
    },
    { rejectWithValue },
  ) => {
    try {
      await putArticleComponent(payload.dynamicLandingId, payload.id, payload.payload)

      return null
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const createArticleComponent = createAsyncThunk(
  `${SLICE_NAME}/createArticleComponent`,
  async (
    payload: {
      dynamicLandingId: number
      id: number
      payload: PostPutArticleComponentPayloadType
    },
    { rejectWithValue },
  ) => {
    try {
      await postArticleComponent(payload.dynamicLandingId, payload.payload)

      return null
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const removeArticleComponent = createAsyncThunk(
  `${SLICE_NAME}/removeArticleComponent`,
  async (
    payload: {
      dynamicLandingId: number
      id: number
    },
    { rejectWithValue },
  ) => {
    try {
      await deleteArticleComponent(payload.dynamicLandingId, payload.id)

      return null
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchSectionCarouselList = createAsyncThunk(
  `${SLICE_NAME}/fetchSectionCarouselList`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getSectionCarouselList()

      return data.data || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan list section carousel')
      return rejectWithValue(err)
    }
  },
)

export const fetchCatalogueCarouselList = createAsyncThunk(
  `${SLICE_NAME}/fetchCatalogueCarouselList`,
  async (queryObj: { name?: string }, { rejectWithValue }) => {
    try {
      const { data } = await getCatalogueCarouselList(queryObj)

      return data?.content || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan list catalogue carousel')
      return rejectWithValue(err)
    }
  },
)

export const fetchCategoryCarouselList = createAsyncThunk(
  `${SLICE_NAME}/fetchCategoryCarouselList`,
  async (queryObj: { name?: string }, { rejectWithValue }) => {
    try {
      const { data } = await getCategoryCarouselList(queryObj)

      return data?.content || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan list kategori carousel')
      return rejectWithValue(err)
    }
  },
)

export const fetchOpenGraphImageRules = createAsyncThunk(
  `${SLICE_NAME}/fetchOpenGraphImageRules`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getImageRules('open-graph')

      return data?.data || null
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan list kategori carousel')
      return rejectWithValue(err)
    }
  },
)

export const createBannerComponent = createAsyncThunk(
  `${SLICE_NAME}/createBannerComponent`,
  async (
    payload: {
      dynamicLandingId: number
      id: number
      payload: PostPutBannerComponentPayloadType
    },
    { rejectWithValue },
  ) => {
    try {
      await postBannerComponent(payload.dynamicLandingId, payload.payload)

      return null
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateBannerComponent = createAsyncThunk(
  `${SLICE_NAME}/updateBannerComponent`,
  async (
    payload: {
      dynamicLandingId: number
      id: number
      payload: PostPutBannerComponentPayloadType
    },
    { rejectWithValue },
  ) => {
    try {
      await putBannerComponent(payload.dynamicLandingId, payload.id, payload.payload)

      return null
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchBannerComponent = createAsyncThunk(
  `${SLICE_NAME}/fetchBannerComponent`,
  async (
    payload: { dynamicLandingId: number; id: number; index: number },
    { rejectWithValue, dispatch, getState },
  ) => {
    try {
      const { data } = await getBannerComponent(payload.dynamicLandingId, payload.id)

      const sectionData = data.data

      const transformItems = transformDataItemsBannerOrMasthead(sectionData)

      const newSectionData = {
        ...sectionData,
        items: transformItems,
      } as unknown as CustomBannerComponentSectionType

      dispatch(
        setConfigSectionComponentData({
          data: newSectionData,
          index: payload.index,
        }),
      )

      dispatch(setScheduler({ end_date: sectionData.end_date, start_date: sectionData.start_date }))

      const {
        dynamicLanding: { configSectionComponents },
      } = getState() as StoreStateType

      dispatch(setCurrentEditingSection(configSectionComponents[payload.index]))

      return sectionData
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const removeBannerComponent = createAsyncThunk(
  `${SLICE_NAME}/removeBannerComponent`,
  async (
    payload: {
      dynamicLandingId: number
      id: number
    },
    { rejectWithValue },
  ) => {
    try {
      await deleteBannerComponent(payload.dynamicLandingId, payload.id)

      return null
    } catch (err) {
      callErrorMsg(err, { autoClose: false })
      return rejectWithValue(err)
    }
  },
)

export const updateComponentSort = createAsyncThunk(
  `${SLICE_NAME}/updateComponentSort`,
  async (
    payload: {
      dynamicLandingId: number
      payload: PutComponentSortPayloadType
    },
    { rejectWithValue },
  ) => {
    try {
      await putComponentSort(payload.dynamicLandingId, payload.payload)

      return null
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const createMastheadComponent = createAsyncThunk(
  `${SLICE_NAME}/createMastheadComponent`,
  async (
    payload: {
      dynamicLandingId: number
      id: number
      payload: PostPutBannerComponentPayloadType
    },
    { rejectWithValue },
  ) => {
    try {
      await postMastheadComponent(payload.dynamicLandingId, payload.payload)

      return null
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const removeMastheadComponent = createAsyncThunk(
  `${SLICE_NAME}/removeMastheadComponent`,
  async (
    payload: {
      dynamicLandingId: number
      id: number
    },
    { rejectWithValue },
  ) => {
    try {
      await deleteMastheadComponent(payload.dynamicLandingId, payload.id)

      return null
    } catch (err) {
      callErrorMsg(err, { autoClose: false })
      return rejectWithValue(err)
    }
  },
)

export const fetchMastheadComponent = createAsyncThunk(
  `${SLICE_NAME}/fetchMastheadComponent`,
  async (
    payload: { dynamicLandingId: number; id: number; index: number },
    { rejectWithValue, dispatch, getState },
  ) => {
    try {
      const { data } = await getMastheadComponent(payload.dynamicLandingId, payload.id)

      const sectionData = data.data

      const transformItems = transformDataItemsBannerOrMasthead(sectionData)

      const newSectionData = {
        ...sectionData,
        items: transformItems,
      } as unknown as CustomBannerComponentSectionType

      dispatch(
        setConfigSectionComponentData({
          data: newSectionData,
          index: payload.index,
        }),
      )
      dispatch(setScheduler({ end_date: sectionData.end_date, start_date: sectionData.start_date }))

      const {
        dynamicLanding: { configSectionComponents },
      } = getState() as StoreStateType

      dispatch(setCurrentEditingSection(configSectionComponents[payload.index]))

      return sectionData
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateMastheadComponent = createAsyncThunk(
  `${SLICE_NAME}/updateMastheadComponent`,
  async (
    payload: {
      dynamicLandingId: number
      id: number
      payload: PostPutBannerComponentPayloadType
    },
    { rejectWithValue },
  ) => {
    try {
      await putMastheadComponent(payload.dynamicLandingId, payload.id, payload.payload)

      return null
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const removeDynamicChannelComponent = createAsyncThunk(
  `${SLICE_NAME}/removeDynamicChannelComponent`,
  async (
    payload: {
      dynamicLandingId: number
      id: number
    },
    { rejectWithValue },
  ) => {
    try {
      await deleteDynamicChannelComponent(payload.dynamicLandingId, payload.id)

      return null
    } catch (err) {
      callErrorMsg(err, { autoClose: false })
      return rejectWithValue(err)
    }
  },
)

export const fetchDynamicChannelComponent = createAsyncThunk(
  `${SLICE_NAME}/fetchDynamicChannelComponent`,
  async (
    payload: { dynamicLandingId: number; id: number; index: number },
    { rejectWithValue, dispatch, getState },
  ) => {
    try {
      const { data } = await getDynamicChannelComponent(payload.dynamicLandingId, payload.id)

      const sectionData = data.data

      dispatch(
        setConfigSectionComponentData({
          data: sectionData,
          index: payload.index,
        }),
      )

      const {
        dynamicLanding: { configSectionComponents },
      } = getState() as StoreStateType

      dispatch(setScheduler({ end_date: sectionData.end_date, start_date: sectionData.start_date }))

      dispatch(setCurrentEditingSection(configSectionComponents[payload.index]))

      return sectionData
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const createDynamicChannelComponent = createAsyncThunk(
  `${SLICE_NAME}/createDynamicChannelComponent`,
  async (
    payload: {
      dynamicLandingId: number
      id: number
      payload: PostPutDynamicChannelComponentPayloadType
    },
    { rejectWithValue },
  ) => {
    try {
      await postDynamicChannelComponent(payload.dynamicLandingId, payload.payload)

      return null
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateDynamicChannelComponent = createAsyncThunk(
  `${SLICE_NAME}/updateDynamicChannelComponent`,
  async (
    payload: {
      dynamicLandingId: number
      id: number
      payload: PostPutDynamicChannelComponentPayloadType
    },
    { rejectWithValue },
  ) => {
    try {
      await putDynamicChannelComponent(payload.dynamicLandingId, payload.id, payload.payload)

      return null
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchProductTabbingComponent = createAsyncThunk(
  `${SLICE_NAME}/fetchProductTabbingComponent`,
  async (
    payload: { dynamicLandingId: number; id: number; index: number },
    { rejectWithValue, dispatch, getState },
  ) => {
    try {
      const { data } = await getProductTabbingComponent(payload.dynamicLandingId, payload.id)

      const sectionData = data.data

      const transformedData = {
        ...sectionData,
        items: sectionData.items.map((i: Record<string, Record<string, unknown>>) => ({
          section: {
            id: i.section.sectionId,
            name: i.section.sectionName,
          },
          category: i.category?.categoryId
            ? {
                category_id: i.category.categoryId,
                category_name: i.category.categoryName,
              }
            : null,
          catalogue: i.catalogue?.catalogueId
            ? {
                category_id: i.catalogue.catalogueId,
                category_name: i.catalogue.catalogueName,
              }
            : null,
        })),
      }

      dispatch(
        setConfigSectionComponentData({
          data: transformedData as ProductTabbingComponentType,
          index: payload.index,
        }),
      )

      const {
        dynamicLanding: { configSectionComponents },
      } = getState() as StoreStateType

      dispatch(setScheduler({ end_date: sectionData.end_date, start_date: sectionData.start_date }))

      dispatch(setCurrentEditingSection(configSectionComponents[payload.index]))

      return sectionData
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const createProductTabbingComponent = createAsyncThunk(
  `${SLICE_NAME}/createProductTabbingComponent`,
  async (
    payload: {
      dynamicLandingId: number
      id: number
      payload: PostPutProductTabbingComponentPayloadType
    },
    { rejectWithValue },
  ) => {
    try {
      await postProductTabbingComponent(payload.dynamicLandingId, payload.payload)

      return null
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateProductTabbingComponent = createAsyncThunk(
  `${SLICE_NAME}/updateProductTabbingComponent`,
  async (
    payload: {
      dynamicLandingId: number
      id: number
      payload: PostPutProductTabbingComponentPayloadType
    },
    { rejectWithValue },
  ) => {
    try {
      await putProductTabbingComponent(payload.dynamicLandingId, payload.id, payload.payload)

      return null
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const removeProductTabbingComponent = createAsyncThunk(
  `${SLICE_NAME}/removeProductTabbingComponent`,
  async (
    payload: {
      dynamicLandingId: number
      id: number
    },
    { rejectWithValue },
  ) => {
    try {
      await deleteProductTabbingComponent(payload.dynamicLandingId, payload.id)

      return null
    } catch (err) {
      callErrorMsg(err, { autoClose: false })
      return rejectWithValue(err)
    }
  },
)

export const fetchTabbingSectionList = createAsyncThunk(
  `${SLICE_NAME}/fetchTabbingSectionList`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getTabbingSectionList()

      return data.data || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan list section product tabbing')
      return rejectWithValue(err)
    }
  },
)
