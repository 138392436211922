import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const PurchaseOrderAssetRBAC = {
  pageID: 'd511ed0c0c9a041ead71fb441843dea6',
  elementID: {
    ButtonAction: 'e38379ad1317e0a817621e29122d942f',
    ViewOnly: '7c5e7a06dadf1449c97954351b6cd8f8',
  },
} as const

export const usePurchaseOrderAssetRBAC = createUseRBAC(PurchaseOrderAssetRBAC.elementID)

export type UsePurchaseOrderAssetRBACPageType = ReturnType<typeof usePurchaseOrderAssetRBAC>
