import { memo, useContext } from 'react'
import styled from 'styled-components'
import storeContext from 'storeContext'
import { TextBody } from 'components'
import AstroLogo from 'assets/images/logo.png'

function DeliveryOrder() {
  const {
    state: { selectedDataSO },
  } = useContext(storeContext.PrintAreaContext)
  const sum = (keyS) => {
    let result = 0
    if (selectedDataSO?.supply_order_items) {
      selectedDataSO?.supply_order_items.forEach((el) => {
        result += el[keyS]
      })
    }
    return result
  }
  return (
    <Container>
      <Body>
        <TableWrapper>
          <Img src={AstroLogo} />
          <TextBody size="bigger" className="mt-8">
            Supply Order
          </TextBody>
          <SpaceBerween>
            <Wrap>
              <TextBody className="mt-4">{selectedDataSO.supply_order_number}</TextBody>
              <TextBody className="mt-4" weight="light">
                {selectedDataSO.supply_order_date}
              </TextBody>
            </Wrap>
            <TextBody className="mt-4">Tujuan : {selectedDataSO.supply_order_destination}</TextBody>
          </SpaceBerween>
          <Table>
            <thead>
              <Tr>
                <ThCol2 rowspan="2">NO.</ThCol2>
                <ThCol2 rowspan="2">Barcode</ThCol2>
                <ThCol2 rowspan="2">Nama Produk</ThCol2>
                <ThCol2 rowspan="2">Jumlah Pesanan</ThCol2>
                <ThRow3>Pengiriman dari {selectedDataSO.supply_order_origin}</ThRow3>
                <ThRow3>Tujuan ke {selectedDataSO.supply_order_destination}</ThRow3>
              </Tr>
              <Tr>
                <Th>Jumlah</Th>
                <Th>No. Koli</Th>
                <Th>Catatan</Th>
                <Th>SLOC</Th>
                <Th>Jumlah</Th>
                <Th>Catatan</Th>
              </Tr>
            </thead>
            <TrBody>
              {selectedDataSO?.supply_order_items?.map((el, idx) => {
                const takeMultiColi =
                  el.incoming_quantity_list.length > 0
                    ? el.incoming_quantity_list.map((value) => {
                        return value.koli
                      })
                    : []

                const totalProductActualQtyOnChild = el.incoming_quantity_list.reduce(
                  (acc, valueMatch) => {
                    return acc + parseInt(valueMatch.quantity, 10)
                  },
                  0,
                )
                return (
                  <>
                    <TrR color="white" key={idx}>
                      <Td>{idx + 1}</Td>
                      <Td>{el.product_sku}</Td>
                      <Td>{el.product_name}</Td>
                      <Td>{el.product_request_quantity}</Td>
                      <Td>{el.product_incoming_quantity}</Td>
                      <Td>
                        {takeMultiColi.length > 0 ? takeMultiColi.join(',') : el.product_koli}
                      </Td>
                      <Td>{el.product_note}</Td>
                      <Td>{el.product_destination_rack}</Td>
                      <Td />
                      <Td />
                    </TrR>
                    {el.incoming_quantity_list.length > 1 &&
                      el.incoming_quantity_list.map((valueIncoming) => {
                        return (
                          <>
                            <TrR
                              style={{
                                backgroundColor:
                                  totalProductActualQtyOnChild !== el.product_request_quantity
                                    ? '#FFF9F9'
                                    : '#F4F9FF',
                              }}
                              key={idx}
                            >
                              <Td />
                              <Td>{el.product_sku}</Td>
                              <Td>{el.product_name}</Td>
                              <Td>{el.product_request_quantity}</Td>
                              <Td>{valueIncoming.quantity}</Td>
                              <Td>{valueIncoming.koli}</Td>
                              <Td>{el.product_note}</Td>
                              <Td>{el.product_destination_rack}</Td>
                              <Td />
                              <Td />
                            </TrR>
                          </>
                        )
                      })}
                  </>
                )
              })}
              <TrR color="yellowFaded">
                <Td></Td>
                <Td></Td>
                <Td>TOTAL</Td>
                <Td>{sum('product_request_quantity')}</Td>
                <Td>{sum('product_incoming_quantity')}</Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
                <Td></Td>
              </TrR>
            </TrBody>
          </Table>
          <TTD>
            <TTDTitle>
              <TextBody>TTD PIC</TextBody>
            </TTDTitle>
            <WrapBox>
              <Box>
                <TextBody size="toast" weight="light">
                  Disetujui Oleh,
                </TextBody>
              </Box>
              <Box>
                <TextBody size="toast" weight="light">
                  Disetujui Oleh,
                </TextBody>
              </Box>
              <Box>
                <TextBody size="toast" weight="light">
                  Diterima Oleh,
                </TextBody>
              </Box>
            </WrapBox>
          </TTD>
        </TableWrapper>
      </Body>
    </Container>
  )
}

export default memo(DeliveryOrder)

const TTDTitle = styled.div`
  padding: 12px 24px;
`

const WrapBox = styled.div`
  display: flex;
`

const Box = styled.div`
  flex: 1;
  height: 66px;
  padding: 12px;
  border: 1px solid #d6dfeb;
`

const TTD = styled.div`
  width: 500px;
  border: 1px solid #d6dfeb;
  border-radius: 8px;
  margin-top: 30px;
`

const Container = styled.div`
  width: 100vw;
`

const Body = styled.div`
  padding: 0;
  padding-bottom: 0;
`

const TableWrapper = styled.div`
  margin-top: 25px;
  background-color: ${({ theme: { colors } }) => colors.white};
`
const Img = styled.img`
  height: 20px;
`

const Wrap = styled.div`
  display: flex;
  gap: 5px;
`

const SpaceBerween = styled.div`
  display: flex;
  justify-content: space-between;
`

const Table = styled.table`
  width: 100%;
  border: 1px solid #f0f3f7;
  border-collapse: collapse;
  border-radius: 8px;
  margin-top: 22px;
`

const Tr = styled.tr`
  background-color: ${({ theme: { colors } }) => colors.mercuryGrey};
`

const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.toast};
  text-align: start;
  padding: 8px 16px;
`

const ThCol2 = styled.th.attrs({
  rowSpan: 2,
})`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.toast};
  text-align: start;
  padding: 12px;
  border: 1px solid #f0f3f7;
`

const ThRow3 = styled.th.attrs({
  colSpan: 3,
})`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.toast};
  text-align: start;
  padding: 12px;
  border: 1px solid #f0f3f7;
`

const TrBody = styled.tbody`
  border-top: 1px solid #eff3f9;
  background-color: ${({ theme: { colors } }) => colors.white};
`

const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.textSoft};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  text-align: start;
  padding: 16px;
  vertical-align: top;
  border: 1px solid #f0f3f7;
`

const TrR = styled.tr`
  background-color: ${({ theme: { colors }, color }) => colors[color]};
`
