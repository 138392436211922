import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import {
  type GetVendorListResponseInterface,
  type VendorListInterface,
  type VendorListPaginatioInterface,
  type VendorRequestType,
  type VendorRequestResponseInterface,
  type VendorRequestParamsType,
} from 'features/Enterprise/Vendor/services/vendorList'

interface InitialStateType {
  isLoading: boolean
  vendorList: VendorListInterface[]
  pagination: VendorListPaginatioInterface
  query: VendorRequestParamsType
  vendorRequestList: VendorRequestType[]
  approvalCount: number
  draftCount: number
}

const initialState: InitialStateType = {
  isLoading: false,
  vendorList: [],
  pagination: {} as VendorListPaginatioInterface,
  query: {
    order_by: 'id',
    order_direction: 'desc',
    page_size: '10',
    page_index: 1,
    vendor_name: '',
    status: '',
    approval_pic: '',
    company_name: '',
    category_vendor: '',
    request_pic: '',
  },
  vendorRequestList: [],
  approvalCount: 0,
  draftCount: 0,
}

export const sliceName = 'vendorList'

export const vendorListSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setVendorList: (state, action: PayloadAction<GetVendorListResponseInterface>) => {
      state.vendorList = action.payload.data
      state.pagination = action.payload.pagination
    },
    setQuery: (state, action: PayloadAction<VendorRequestParamsType>) => {
      state.query = action.payload
    },
    setResetQuery: (state) => {
      state.query = initialState.query
    },
    setVendorRequestList: (state, action: PayloadAction<VendorRequestResponseInterface>) => {
      state.vendorRequestList = action.payload.data
      state.pagination = action.payload.pagination
    },
    setVendorRequestCount: (state, action: PayloadAction<{ status: string; count: number }>) => {
      if (action?.payload?.status === 'DRAFT') {
        state.draftCount = action?.payload?.count
      } else {
        state.approvalCount = action?.payload?.count
      }
    },
  },
})

export const {
  setLoading,
  setVendorList,
  setQuery,
  setVendorRequestList,
  setVendorRequestCount,
  setResetQuery,
} = vendorListSlice.actions

export default vendorListSlice.reducer
