import {
  SET_IS_LOADING_PRODUCT,
  SET_PRODUCTS,
  SET_CATEGORIES,
  SET_DATA_PAGINATION,
  SET_SELECTED_PRODUCT,
  SET_LAST_QUERY,
  SET_FILTER_CATEGORIES,
  SET_LIST_ID_CATEGORIES,
  SET_LOADING_LOAD_MORE,
  SET_DATA_STRUCTURE,
  SET_LAST_PARAMS,
  SET_BULK_PRICE_PRODUCTS,
} from 'storeContext/actionsType'

export const initialStateProduct = {
  isLoading: false,
  categories: [],
  products: [],
  dataPagination: {},
  selectedProduct: {
    product_images: [],
    product_categories: [],
  },
  lastQuery: '',
  filterCategories: [],
  listIdCategories: [],
  isLoadingLoadMore: false,
  dataStructures: [],
  lastParams: {},
  bulkPriceProducts: [],
}

export default function product(state = initialStateProduct, { type, payload }) {
  switch (type) {
    case SET_IS_LOADING_PRODUCT:
      return {
        ...state,
        isLoading: payload,
      }
    case SET_CATEGORIES:
      return {
        ...state,
        categories: payload,
      }
    case SET_PRODUCTS:
      return {
        ...state,
        products: payload,
      }
    case SET_DATA_PAGINATION:
      return {
        ...state,
        dataPagination: payload,
      }
    case SET_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: payload,
      }
    case SET_LAST_QUERY:
      return {
        ...state,
        lastQuery: payload,
      }
    case SET_FILTER_CATEGORIES:
      return {
        ...state,
        filterCategories: payload,
      }
    case SET_LIST_ID_CATEGORIES:
      return {
        ...state,
        listIdCategories: payload,
      }
    case SET_LOADING_LOAD_MORE:
      return {
        ...state,
        isLoadingLoadMore: payload,
      }
    case SET_DATA_STRUCTURE:
      return {
        ...state,
        dataStructures: payload,
      }
    case SET_LAST_PARAMS:
      return {
        ...state,
        lastParams: payload,
      }
    case SET_BULK_PRICE_PRODUCTS:
      return {
        ...state,
        bulkPriceProducts: payload,
      }
    default:
      return state
  }
}
