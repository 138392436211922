import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'

// import { getQueryParamsObjectToString } from 'utils/helpers/router'
import { toastSuccess } from 'utils/toast'
import { getAllCategories, getAlllocation } from 'utils/api'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  updatePutAway,
  getDirectPutaway,
  getEligibleRacks,
  getStaffByPhone,
  completePutAway,
  resetPutaway,
  validatePoComplete,
  type GetDirectPutawayResponseType,
  type GetDirectPutawayRequestType,
  type GetStaffResponseType,
  type UpdatePutAwayPayloadType,
} from 'utils/apiList/direcPutaway'

export interface DirectPutawayInitialStateInterface {
  isLoading: boolean
  isLoadingUpdateTask: boolean
  isLoadingSearchRacks: boolean
  putawayList: GetDirectPutawayResponseType['data']
  locationList: {
    location_id: number
    location_name: string
    location_type: string
  }[]
  categories: {
    category_hidden: boolean
    category_id: number
    category_name: string
  }[]
  selectedCategory: DirectPutawayInitialStateInterface['categories'][0] | ObjectNullType
  selectedLocation: DirectPutawayInitialStateInterface['locationList'][0] | ObjectNullType
  popUpState: {
    type: string
    isShow: boolean
    putaway?: DirectPutawayInitialStateInterface['putawayList'][0] | ObjectNullType
  }
  rackList: {
    id: number
    rackName: string
  }[]
  staff: GetStaffResponseType['data'] | ObjectNullType
  query: GetDirectPutawayRequestType['params']
  pagination: GetDirectPutawayResponseType['pagination']
}

export enum PutawayStatusEnum {
  COMPLETED = 'COMPLETED',
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
}

export enum PutawayActionEnum {
  PROCESS = 'Proses',
  COMPLETE = 'Selesaikan',
  RESET = 'Reset Task',
}

export enum PutawayModalTypeEnum {
  SHOP_KEEPER = 'SHOP_KEEPER',
  CONFIRMATION = 'CONFIRMATION',
  RESET = 'RESET',
}

const initialState: DirectPutawayInitialStateInterface = {
  isLoading: false,
  isLoadingUpdateTask: false,
  isLoadingSearchRacks: false,
  locationList: [],
  categories: [],
  selectedCategory: {},
  selectedLocation: {},
  putawayList: [],
  popUpState: {
    type: '',
    isShow: false,
    putaway: {},
  },
  rackList: [
    {
      id: 0,
      rackName: '',
    },
  ],
  query: {
    page_index: 1,
    page_size: 10,
    sort: 'created_at',
    location_ids: '',
    sku_number: '',
    package_label: '',
    product_category_id: 0,
    status: '',
    inbound_date: '',
  },
  staff: {
    staffId: 0,
    staffFullName: '',
    staffPhoneNumber: '',
  },
  pagination: {
    direction: 'desc',
    number_of_elements: 0,
    page_index: 1,
    page_size: 10,
    sort: 'created_at',
  },
}

export const sliceName = 'masterApproval'

const appSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    reset: () => initialState,
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setLoadingUpdateTask: (state, action: PayloadAction<boolean>) => {
      state.isLoadingUpdateTask = action.payload
    },
    setLoadingSearchRacks: (state, action: PayloadAction<boolean>) => {
      state.isLoadingSearchRacks = action.payload
    },
    setLocationList: (
      state,
      action: PayloadAction<DirectPutawayInitialStateInterface['locationList']>,
    ) => {
      state.locationList = action.payload
    },
    setCategories: (
      state,
      action: PayloadAction<DirectPutawayInitialStateInterface['categories']>,
    ) => {
      state.categories = action.payload
    },
    setPopUpState: (
      state,
      action: PayloadAction<DirectPutawayInitialStateInterface['popUpState']>,
    ) => {
      state.popUpState = action.payload
    },
    setQuery: (state, action: PayloadAction<DirectPutawayInitialStateInterface['query']>) => {
      state.query = action.payload
    },
    setPutawayList: (
      state,
      action: PayloadAction<DirectPutawayInitialStateInterface['putawayList']>,
    ) => {
      state.putawayList = action.payload
    },
    setRackList: (state, action: PayloadAction<DirectPutawayInitialStateInterface['rackList']>) => {
      state.rackList = action.payload
    },
    setStaff: (state, action: PayloadAction<GetStaffResponseType['data']>) => {
      state.staff = action.payload
    },
    setPagination: (state, action: PayloadAction<GetDirectPutawayResponseType['pagination']>) => {
      state.pagination = action.payload
    },
    setClosePopup: (state) => {
      state.staff = {}
      state.popUpState = {
        isShow: false,
        type: '',
        putaway: {},
      }
    },
  },
})

export const {
  setLoading,
  setLoadingUpdateTask,
  setLocationList,
  setCategories,
  setPopUpState,
  setQuery,
  setPutawayList,
  setRackList,
  setLoadingSearchRacks,
  setStaff,
  setPagination,
  setClosePopup,
} = appSlice.actions

export default appSlice.reducer

export const updatePutAwayTask = createAsyncThunk(
  `${sliceName}/updatePutAwayTask`,
  async (
    {
      id,
      rackName,
      payload,
    }: {
      id: number
      rackName?: string
      payload: UpdatePutAwayPayloadType
    },
    { dispatch },
  ) => {
    try {
      dispatch(setLoadingUpdateTask(true))
      await updatePutAway(id, payload)
      await dispatch(getPutAwayTaskList())

      if (rackName) {
        toastSuccess('Update Sloc Berhasil')
      } else {
        toastSuccess('Assign Staff Berhasil')
      }
      dispatch(setClosePopup())
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoadingUpdateTask(false))
    }
  },
)

export const completePutAwayTask = createAsyncThunk(
  `${sliceName}/completePutAway`,
  async (
    {
      id,
      packageId,
      payload,
    }: { id: number; packageId: string; payload: UpdatePutAwayPayloadType },
    { dispatch },
  ) => {
    try {
      dispatch(setLoadingUpdateTask(true))
      await completePutAway(id, payload)
      await dispatch(getPutAwayTaskList())
      dispatch(setClosePopup())
      toastSuccess(`Berhasil Proses Putaway ${packageId}`)
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoadingUpdateTask(false))
    }
  },
)

export const resetPutawayTask = createAsyncThunk(
  `${sliceName}/resetPutawayTask`,
  async (
    {
      id,
      packageId,
    }: {
      id: number
      packageId: string
    },
    { dispatch },
  ) => {
    try {
      dispatch(setLoadingUpdateTask(true))
      await resetPutaway(id)
      await dispatch(getPutAwayTaskList())
      dispatch(setClosePopup())
      toastSuccess(`Reset putaway ${packageId} berhasil`)
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoadingUpdateTask(false))
    }
  },
)

export const getPutAwayTaskList = createAsyncThunk(
  `${sliceName}/getPutAwayTaskList`,
  async (_, { dispatch, getState }) => {
    try {
      const {
        directPutaway: { query },
      } = getState() as StoreStateType
      dispatch(setLoading(true))
      const { data } = await getDirectPutaway(query)
      dispatch(setPutawayList(data.data))
      dispatch(setPagination(data.pagination))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const getRacks = createAsyncThunk(
  `${sliceName}/getRacks`,
  async (
    { search, productId, locationId }: { search: string; productId: number; locationId: number },
    { dispatch },
  ) => {
    try {
      dispatch(setLoadingSearchRacks(true))
      const { data } = await getEligibleRacks({
        productId,
        locationId,
        search,
      })

      let rackList: {
        id: number
        rackName: string
      }[] = []

      if (data?.data?.length) {
        rackList = data?.data
          ?.filter((x) => x.active)
          .map((x) => ({
            id: x.id,
            rackName: x.rackName,
          }))
      }

      dispatch(setRackList(rackList))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoadingSearchRacks(false))
    }
  },
)

export const getStaff = createAsyncThunk(
  `${sliceName}/getStaff`,
  async (_, { dispatch, getState }) => {
    try {
      dispatch(setLoadingSearchRacks(true))
      const {
        auth: { userData },
      } = getState() as StoreStateType
      const { data } = await getStaffByPhone(userData.phone_number)
      if (data?.data) {
        dispatch(
          setStaff({
            staffId: data.data.staffId,
            staffFullName: data.data.staffFullName,
            staffPhoneNumber: data.data.staffPhoneNumber,
          }),
        )

        return data.data.staffId
      }
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoadingSearchRacks(false))
    }

    return ''
  },
)

export const getLocations = createAsyncThunk(
  `${sliceName}/getLocations`,
  async (_, { dispatch }) => {
    try {
      const { data } = await getAlllocation()
      if (data?.length) {
        dispatch(setLocationList([...data]))
      }
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const getCategories = createAsyncThunk(
  `${sliceName}/getCategories`,
  async (_, { dispatch }) => {
    try {
      const { data } = await getAllCategories()
      if (data?.length) {
        const filterAvailableCategories = data?.filter(
          (x: DirectPutawayInitialStateInterface['categories'][0]) => !x.category_hidden,
        )
        dispatch(setCategories([...filterAvailableCategories]))
      }
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const actValidatePoComplete =
  (packageId: number, callbackFunction: () => void) => async () => {
    try {
      await validatePoComplete(packageId)
      callbackFunction()
    } catch (error) {
      callErrorMsg(error, 'PO belum Complete, selesaikan PO terlebih dahulu')
    }
  }
