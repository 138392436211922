import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  WatchtowerAreaDetectionStateType,
  SetFormCreateEditPayloadActionType,
  SetQueryPayloadActionType,
} from 'features/Fraud/@types/watchtowerAreaDetection'
import {
  SLICE_NAME,
  fetchWatchtowerAreaDetectionList,
  createWatchtowerArea,
  fetchWatchtowerAreaById,
  updateWatchtowerArea,
  updateWatchtowerAreaStatus,
} from './thunk'

export const initialState: WatchtowerAreaDetectionStateType = {
  isLoading: false,
  query: { pageIndex: 1, pageSize: 20 },
  watchtowerList: [],
  formCreateEdit: { coordinate: '', name: '', radius: '', date: [null, null], id: '' },
  isModalCreateEdit: '',
}

const watchtowerAreaDetection = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    resetFormCreateEdit: (state) => {
      state.formCreateEdit = initialState.formCreateEdit
    },
    setIsModalCreateEdit: (
      state,
      action: PayloadAction<{ value: WatchtowerAreaDetectionStateType['isModalCreateEdit'] }>,
    ) => {
      state.isModalCreateEdit = action.payload.value
    },
    setFormCreateEdit: (state, action: PayloadAction<SetFormCreateEditPayloadActionType>) => {
      const { name, value } = action.payload
      state.formCreateEdit[name] = value as never
    },
    setQuery: (state, action: PayloadAction<SetQueryPayloadActionType>) => {
      const { name, value } = action.payload
      state.query[name] = value as never
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWatchtowerAreaDetectionList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchWatchtowerAreaDetectionList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchWatchtowerAreaDetectionList.fulfilled, (state, action) => {
        const { watch_tower } = action.payload
        state.watchtowerList = watch_tower.map((el) => ({
          ...el,
          uniqueKey: window.crypto.randomUUID(),
        }))
        state.isLoading = false
      })
      .addCase(createWatchtowerArea.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createWatchtowerArea.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(createWatchtowerArea.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(updateWatchtowerArea.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateWatchtowerArea.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateWatchtowerArea.fulfilled, (state) => {
        state.isLoading = false
      })

      .addCase(updateWatchtowerAreaStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateWatchtowerAreaStatus.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateWatchtowerAreaStatus.fulfilled, (state) => {
        state.isLoading = false
      })

      .addCase(fetchWatchtowerAreaById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchWatchtowerAreaById.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchWatchtowerAreaById.fulfilled, (state, action) => {
        const { watch_tower } = action.payload
        state.isLoading = false
        state.formCreateEdit = {
          ...watch_tower,
          date: [new Date(watch_tower.start), new Date(watch_tower.end)],
        }
      })
  },
})

export const { reset, setIsModalCreateEdit, setFormCreateEdit, resetFormCreateEdit, setQuery } =
  watchtowerAreaDetection.actions
export default watchtowerAreaDetection.reducer
