import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { withLoadingReducer } from 'utils/reducerHandler'
import { fetchGetLocations } from 'features/Location/redux/location'
import { GetLocationListResponseType } from 'features/Location/@types/location'
import { GetAreaDetailResponseType, GetCategoriesResponseType } from 'features/Rack/services/area'
import {
  fetchGetAreaDetail,
  fetchGetCategories,
  fetchPostArea,
  fetchPutArea,
  SLICE_NAME,
} from './thunk'

export type AreaAddEditSliceType = {
  isLoading: boolean
  isLocationLoading: boolean
  constants: {
    locations: GetLocationListResponseType['content']
    categories: GetCategoriesResponseType
  }
  data: Nullable<GetAreaDetailResponseType>
  form: {
    name: string
    location: Nullable<{
      location_id: number
      location_name: string
    }>
    category: {
      category_id: number
      category_name: string
    }[]
  }
}

const initialState: AreaAddEditSliceType = {
  isLoading: false,
  isLocationLoading: false,
  constants: {
    locations: [],
    categories: [],
  },
  data: null,
  form: {
    name: '',
    location: null,
    category: [],
  },
}

const areaSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setAreaAddEditForm: (
      state,
      action: PayloadAction<{
        key: keyof AreaAddEditSliceType['form']
        value: never
      }>,
    ) => {
      const { key, value } = action.payload
      state.form[key] = value
    },
  },
  extraReducers: withLoadingReducer(
    (builder: ActionReducerMapBuilder<AreaAddEditSliceType>) => {
      builder
        .addCase(fetchGetLocations.pending, (state) => {
          state.isLocationLoading = true
        })
        .addCase(fetchGetLocations.fulfilled, (state, action) => {
          state.isLocationLoading = false
          state.constants.locations = action.payload.content
        })
        .addCase(fetchGetLocations.rejected, (state) => {
          state.isLocationLoading = false
        })
        .addCase(fetchGetCategories.fulfilled, (state, action) => {
          state.constants.categories = action.payload
        })
        .addCase(fetchGetAreaDetail.fulfilled, (state, action) => {
          const data = action.payload

          state.data = data
          state.form = {
            name: data.name,
            location: {
              location_id: data.location.id,
              location_name: data.location.name,
            },
            category: data.product_categories,
          }
        })
    },
    [fetchGetAreaDetail, fetchPostArea, fetchPutArea],
  ),
})

export default areaSlice.reducer
export const { reset, setAreaAddEditForm } = areaSlice.actions
