export const healthyPage = '/application/health'
export const login = '/'
export const resetPassword = '/reset-password'
export const dashboard = '/dashboard'
export const category = '/dashboard/category'
export const catalogue = '/dashboard/catalogue'
export const product = '/dashboard/product'
export const brandMaster = '/dashboard/brand-master'
export const productAdd = '/dashboard/product/add'
export const productEdit = '/dashboard/product/:prefix/:id'
export const productVendor = '/dashboard/product-vendor/:productId'
export const productVendorAdd = '/dashboard/product-vendor/:productId/add'
export const productVendorEdit = '/dashboard/product-vendor/:productId/edit/:catalogId'
export const hub = '/dashboard/hub'
export const banner = '/dashboard/banner'
export const dynamicChannel = '/dashboard/dynamic-channel'
export const location = '/dashboard/location'
export const addProductHub = '/dashboard/add-product-hub'
export const admin = '/dashboard/admin'
export const buyer = '/dashboard/buyer'
export const order = '/dashboard/order/new-order'
export const orderUpcoming = '/dashboard/order/upcoming-order'
export const orderPicking = '/dashboard/order/picking'
export const orderPacking = '/dashboard/order/packing'
export const orderWaitingDriver = '/dashboard/order/ready-for-delivery'
export const orderOnDelivery = '/dashboard/order/on-delivery'
export const orderBackToHub = '/dashboard/order/back-to-hub'
export const orderWaitingConfirmation = '/dashboard/order/waiting-confirmation'
export const orderReadyForDelivery = '/dashboard/order/ready-for-delivery'
export const orderNotAvailable = '/dashboard/order/not-available'
export const orderPicker = '/dashboard/order-picker'
export const orderPacker = '/dashboard/order-packer'
export const warehouse = '/dashboard/warehouse'
export const addProductWarehouse = '/dashboard/add-product-warehouse'
export const supplyOrder = '/dashboard/supply-order'
export const addSupplyOrder = '/dashboard/add-supply-order'
export const supplyOrderAddProduct = '/dashboard/supply-order/add-product'
export const supplyOrderEditProduct = '/dashboard/supply-order/add-product/:id'
export const inboundHub = '/dashboard/inbound-hub'
export const addInboundHub = '/dashboard/add-inbound-hub'
export const printPreviewSupplyOrder = '/print-supply-order'
export const printPreviewInbound = '/print-inbound'
export const orderPickerPicking = '/dashboard/order-picker/picking'
export const orderPickerCancelled = '/dashboard/order-picker/cancelled'
export const orderPackerPicking = '/dashboard/order-packer/picking'
export const orderPackerPacking = '/dashboard/order-packer/packing'
export const orderPackerWaitingPayment = '/dashboard/order-packer/waiting-payment'
export const orderPackerWaitingDriver = '/dashboard/order-packer/waiting-driver'
export const orderPackerCancelled = '/dashboard/order-packer/cancelled'
export const orderPackeronDelivery = '/dashboard/order-packer/delivery'
export const orderPackerBacktoHub = '/dashboard/order-packer/back-to-hub'
export const allorder = '/dashboard/all-order'
export const setting = '/dashboard/setting'
export const newPassowrd = '/new-password'
export const promotion = '/dashboard/promotion'
export const ticker = '/dashboard/ticker'
export const inboundWarehouse = '/dashboard/inbound/warehouse'
export const addPromotionVoucher = '/dashboard/add/promotion/voucher'
export const checkQuantity = '/dashboard/checkQuantity/:id'
export const editInbound = '/dashboard/editInbound/:id'
export const serviceLevel = '/dashboard/service-level'
export const referralSetting = '/dashboard/referral-setting'
export const productWarehouseStockDetail = '/dashboard/warehouse/stock-detail'
export const productHubStockDetail = '/dashboard/hub/stock-detail'
export const productHubProductDetail = '/dashboard/hub/product-detail'
export const productHubTanggungRenteng = '/dashboard/hub/product-tanggung-renteng'
export const productHubStockBulkUpdate = '/dashboard/hub/bulk-price-update'
export const productWarehouseProductDetail = '/dashboard/warehouse/product-detail'
export const productWarehouseTanggungRenteng = '/dashboard/warehouse/product-tanggung-renteng'
export const rack = '/dashboard/rack'
export const referral = '/dashboard/referral'
export const rackDetail = '/dashboard/rack/detail/:id'
export const rackDetailEdit = '/dashboard/rack/:locationId/edit/:rackId'
export const rackAdd = '/dashboard/rack/add'
export const rackEdit = '/dashboard/rack/edit/:id'
export const buyerPoints = '/dashboard/buyer-points'
export const buyerAstroSaldoHistory = '/dashboard/buyer/astro-saldo-history'
export const massUpload = '/dashboard/mass-upload'
export const orderDetail = '/dashboard/order-detail'
export const setPosition = '/dashboard/set-position'
export const setPositionCategory = '/dashboard/set-position/category'
export const setPositionProduct = '/dashboard/set-position/product'
export const purchaseOrder = '/dashboard/purchase-order'
export const purchaseOrderDetail = '/dashboard/purchase-order-detail'
export const uom = '/dashboard/uom'
export const uploadPackage = '/dashboard/upload-package'
export const detailPackageStructure = '/dashboard/detail-package-structure'
export const productDiscount = '/dashboard/product-discount'
export const addProductDiscount = '/dashboard/add-product-discount'
export const activityMonitoring = '/dashboard/activity-monitoring'
export const activityMonitoringReceiving = '/dashboard/activity-monitoring-receiving'
export const putAway = '/dashboard/put-away'
export const activityMonitoringReceivingDetail = '/dashboard/activity-monitoring-receiving/:id'
export const campaign = '/dashboard/campaign'
export const campaignAdd = '/dashboard/campaign/add'
export const campaignAddManual = '/dashboard/campaign/add/manual'
export const receiving = '/dashboard/receiving'
export const grn = '/dashboard/grn'
export const damageputaway = '/dashboard/damage-put-away'
export const directputaway = '/dashboard/direct-put-away'
export const grnprint = '/dashboard/grn/print'
export const purchaseOrderLite = '/dashboard/purchase-order-lite'
export const purchaseOrderLiteDetail = '/dashboard/purchase-order-lite/:id'
export const purchaseOrderLiteEdit = '/dashboard/purchase-order-lite-edit/:id'
export const inboundPo = '/dashboard/inbound-purchase-order'
export const supplyOrderDetail = '/dashboard/supply-order-detail'
export const printPreviewPickingList = '/print-preview-picking-list/:id'
export const supplyOrderCheckQty = '/dashboard/supply-order-check-quantity/:id'
export const inboundSo = '/dashboard/inbound-so'
export const inboundSoCheckQty = '/dashboard/inbound-so/:id'
export const printPreviewGrn = '/print-preview-grn/:id'
export const gwp = '/dashboard/gift-with-purchase'
export const gwpAdd = '/dashboard/gift-with-purchase-add'
export const gwpProductList = '/dashboard/gift-with-purchase-product-list'
export const testDnd = '/dashboard/test-dnd'
export const purchaseOrderLiteApproval = '/dashboard/purchase-order-lite-approval'

export const koliIDGenerator = '/dashboard/koli-id-generator'
export const koliIDGeneratorDetail = '/dashboard/koli-id-generator/:id'
export const packageIDGenerator = '/dashboard/package-id-generator'
export const packageIDGeneratorDetail = '/dashboard/package-id-generator-detail'
export const packageIDGeneratorPrint = '/dashboard/package-id-generator-print'
export const fraudSetting = '/dashboard/fraud-setting'
export const fraudStatus = '/dashboard/fraud-status'
export const bulkUpload = '/dashboard/bulk-upload/:id'
export const coinEarned = '/dashboard/coin-earned'
export const coinEarnedForm = '/dashboard/coin-earned/form'
export const coinExchange = '/dashboard/coin-exchange'
export const coinExchangeForm = '/dashboard/coin-exchange/form'
export const loyaltyActivity = '/dashboard/loyalty-activity'
export const loyaltyVoucher = '/dashboard/loyalty-voucher'
export const loyaltyVoucherAdd = '/dashboard/loyalty-voucher/add'
export const purchaseRequest = '/dashboard/purchase-request'
export const purchaseRequestEdit = '/dashboard/purchase-request/edit/:id'
export const purchaseRequestDetail = '/dashboard/purchase-request/:id'
export const FreshpurchaseRequestDetail = '/dashboard/purchase-request/fresh/:id'
export const bom = '/dashboard/bom'
export const bomAdd = '/dashboard/bom/add'
export const bomEdit = '/dashboard/bom/edit/:id'
export const bomDetail = '/dashboard/bom/detail/:id'
export const bomAddProduct = '/dashboard/bom/add/product'
export const kitting = '/dashboard/Kitting-De-Kitting-Order'
export const kittingDetail = '/dashboard/Kitting-De-Kitting-Order/:id'
export const kittingCheckQty = '/dashboard/kitting-check-qty/:id'
export const kittingPrintPreviewPickingList = '/kitting-print-preview-picking-list/:id'
export const kittingComplete = '/dashboard/kitting-complete/:id'
export const refund = '/dashboard/refund'
export const disbursement = '/dashboard/disbursement'
export const freshPurchaseOrderList = '/dashboard/fresh-purchase-order/list'
export const freshPurchaseOrderBulkUpload = '/dashboard/fresh-purchase-order/bulk-upload'
export const freshPurchaseOrderDetail = '/dashboard/fresh-purchase-order/detail/:id'
export const freshPurchaseOrderProcessInbound =
  '/dashboard/fresh-purchase-order/process-inbound/:id'
export const freshPurchaseOrderPricing = '/dashboard/fresh-purchase-order/pricing/:id'
export const freshPurchaseOrderInbound = '/dashboard/fresh-purchase-order/inbound'
export const freshPurchaseOrderInboundDetail = '/dashboard/fresh-purchase-order/inbound/detail/:id'
export const freshPurchaseOrderInboundTrimmingAndPacking =
  '/dashboard/fresh-purchase-order/inbound/trimming-and-packing/:id'
export const freshPurchaseOrderTrimmingandPacking =
  '/dashboard/fresh-purchase-order/trimming-packing'
export const freshPurchaseOrderTrimmingPackingDetail =
  '/dashboard/fresh-purchase-order/trimming-packing/:id'
export const freshPurchaseOrderTrimmingPackingCheckQty =
  '/dashboard/fresh-purchase-order/trimming-packing/check-quantity/:id'
export const supplyOrderApproval = '/dashboard/supply-order-approval'
export const koliLost = '/dashboard/koli-lost'

export const uniqueVoucherList = '/dashboard/unique-voucher/voucher-list'
export const uniqueVoucherCampaignList = '/dashboard/unique-voucher/campaign-list'
export const uniqueVoucherAdd = '/dashboard/unique-voucher/voucher/add'
export const uniqueVoucherEdit = '/dashboard/unique-voucher/voucher/:prefix/:id'
export const timeSlot = '/dashboard/time-slot'
export const loadingDock = '/dashboard/loading-dock'
export const bulkUploadV2 = '/dashboard/bulk-upload-v2'
export const fraudBlackAndWhiteList = '/dashboard/fraud/black-and-white-list'
export const supplyOrderPicking = '/dashboard/supply-order/picking/:id'
export const supplyOrderLoading = '/dashboard/supply-order/loading/:id'
export const pwp = '/dashboard/purchase-with-purchase'
export const pwpForm = '/dashboard/purchase-with-purchase/form'
export const pwpProductList = '/dashboard/purchase-with-purchase/product'
export const stopOpnameDetail = '/dashboard/stock-opname/detail/:id'
export const stockOpnameList = '/dashboard/stock-opname-list'
export const stockOpnameCheckAdjustment = '/dashboard/stock-opname/check-adjustment/:id'
export const stockOpnameAdjustment = '/dashboard/stock-opname/adjustment'
export const stockOpnameCheckAdjustmentView = '/dashboard/stock-opname/check-adjustment/:prefix/:id'
export const stockOpnameAddSTO = '/dashboard/stock-opname/add'
export const coinHistory = '/dashboard/coin-history'
export const inboundSchedule = '/dashboard/inbound-schedule'
export const inboundScheduleAdd = '/dashboard/inbound-schedule/:type'
export const inboundScheduleEditAndDetail = '/dashboard/inbound-schedule/:type/:id'
export const referralMissionList = '/dashboard/referral-mission'
export const referralMissionAdd = '/dashboard/referral-mission/:prefix'
export const referralMissionEdit = '/dashboard/referral-mission/:prefix/:id'
export const flagManagement = '/dashboard/flag-management'
export const orderSuper = '/dashboard/super/orders'
export const orderPickingSuper = '/dashboard/super/orders/picking'
export const orderPackingSuper = '/dashboard/super/orders/packing'
export const orderWaitingDriverSuper = '/dashboard/super/orders/waiting-driver'
export const orderOnDeliverySuper = '/dashboard/super/orders/on-delivery'
export const orderBackToHubSuper = '/dashboard/super/orders/back-to-hub'
export const allOrderSuper = '/dashboard/super/all-order'
export const vendorBrandGrouping = '/dashboard/vendor-brand-grouping'
export const ordersDetail = '/dashboard/details/order/:orderId'
export const superOrdersDetail = '/dashboard/details/super/order/:orderId'
export const fraudReferral = '/dashboard/fraud/referral'
export const promoReferralWidget = '/dashboard/promo-referral-widget'
export const fraudWatchTowerRule = '/dashboard/fraud/watchtower/rule'
export const fraudWatchTowerQueryRule = '/dashboard/fraud/watchtower/query-rule'
export const fraudWatchTowerParameters = '/dashboard/fraud/watchtower/parameters'
export const campaignSegmentList = '/dashboard/campaign/segment'
export const routeAndSchedule = '/dashboard/route'
export const routeAndScheduleAdd = '/dashboard/route/add'
export const routeAndScheduleEdit = '/dashboard/route/:id/edit'
export const productAssortment = '/dashboard/product-assortment'
export const locationPreview = '/dashboard/location-preview'
export const productLabel = '/dashboard/product-label'
export const aBAdminList = '/dashboard/ab-admin'
export const aBAdminCreateEdit = '/dashboard/ab-admin/:experimentId'
export const schedule = '/dashboard/schedule'
export const createOrder = '/dashboard/create-order'
export const campaignLoyalty = '/dashboard/campaign-loyalty'
export const campaignLoyaltyAddEdit = '/dashboard/campaign-loyalty/campaign/:prefix/:id'
export const campaignLoyaltyProductAddManual = '/dashboard/campaign-loyalty/product/add-manual'
export const referralVIPList = '/dashboard/referral-vip/list'
export const dynamicLandingList = '/dashboard/dynamic-landing'
export const dynamicLandingDetail = '/dashboard/dynamic-landing/:id'
export const driverMonitoring = '/dashboard/driver-monitoring'
export const hcu = '/dashboard/hcu'
export const poolingMap = '/dashboard/pooling-map'

// Enterprise Routes
export const hubStaffSchedule = '/dashboard/hub-staff/schedule'
export const hubStaffAttendance = '/dashboard/hub-staff/attendance'
export const hubStaffMasterShift = '/dashboard/master-shift'
export const hubStaffMasterApproval = '/dashboard/hub-staff/master-approval'
export const masterPayroll = '/dashboard/master-payroll'
export const masterPayrollDetail = '/dashboard/master-payroll/:id'
export const hubStaffScheduleBulkUpload = '/dashboard/hub-staff/schedule/bulk-upload'
export const mpBudget = '/dashboard/mp-budget'
export const mpPlanning = '/dashboard/mp-planning'
export const hubStaff = '/dashboard/hub-staff'
export const centralWarehouseStaff = '/dashboard/central-warehouse-staff'

export const reorderList = '/dashboard/reorder-list'
export const reorderListSavePreview = '/dashboard/reorder-list/save-preview'
export const reorderListBulkUpload = '/dashboard/reorder-list/bulk-upload'

export const skpSourcing = '/dashboard/skp/sourcing'
export const skpSourcingDetails = '/dashboard/skp/sourcing/details/:id'
export const skpFinance = '/dashboard/skp/finance'
export const skpFinanceDetails = '/dashboard/skp/finance/details/:id'
export const skpCampaign = '/dashboard/skp/campaign'
export const skpCampaignDetails = '/dashboard/skp/campaign/details/:id'
export const addSKP = '/dashboard/skp/add'
export const editSKP = '/dashboard/skp/edit/:id'
export const duplicateSKP = '/dashboard/skp/duplicate/:id'
export const skpTermsAndConditions = '/dashboard/skp/terms-and-conditions'

export const vendor = '/dashboard/vendor'
export const vendorDetails = '/dashboard/vendor/details/:id'
export const addVendor = '/dashboard/vendor/add-vendor'
export const editVendor = '/dashboard/vendor/edit-vendor/:id'
export const editVendorRequest = '/dashboard/vendor/edit-request/:id'
export const vendorReview = '/dashboard/vendor/review/:id'
// Enterprise Routes

// TEMP Supply Order Asset Routes.
export const supplyOrderAssetDetail = '/dashboard/supply-order-asset/:id'
