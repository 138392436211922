import { useHistory, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

function useURLParams() {
  const history = useHistory()
  const location = useLocation()
  const [urlParams, setUrlParams] = useState(new URLSearchParams(window.location.search))
  let arrayOfParams = []
  let objOfParams = {}

  const initiateFunction = () => {
    for (let key of urlParams.keys()) {
      arrayOfParams.push(urlParams.get(key))
      objOfParams[key] = urlParams.get(key)
    }
  }

  useEffect(() => {
    setUrlParams(new URLSearchParams(window.location.search))
  }, [location.search])

  const transformObjToQuery = (objIn) => {
    let tmpQuery = '?'
    let i = 0
    const lengthObjc = Object.keys(objIn).length

    for (let keyObj in objIn) {
      i++
      const paramsSeparator = lengthObjc > 0 ? '&' : ''
      tmpQuery += `${paramsSeparator}${keyObj}=${objIn[keyObj]}`
    }
    i
    return tmpQuery
  }

  const replaceHistory = (str) => {
    history.replace(str)
  }

  const getParams = (paramsKey) => {
    const myParam = urlParams.get(paramsKey)
    return myParam
  }

  const replaceParams = (key, value) => {
    /***
     * IF KEY IN OBJECT NOT EXIST DO NOTHING AND RETURN ERROR OBJECT
     */

    if (key in objOfParams) {
      objOfParams[key] = value
    } else {
      return {
        error: 'key paramaters is not  found ',
      }
    }

    let queryString = transformObjToQuery(objOfParams)

    replaceHistory(queryString)
  }

  const appendParams = (key, value) => {
    if (key in objOfParams) return replaceParams(key, value)

    objOfParams = { ...objOfParams, [key]: value }

    let queryString = transformObjToQuery(objOfParams)

    replaceHistory(queryString)
  }

  const removeParams = (key) => {
    if (key in objOfParams) {
      delete objOfParams[key]

      let queryString = transformObjToQuery(objOfParams)

      replaceHistory(queryString)
    } else {
      return {
        error: 'key paramaters is not exist!',
      }
    }
  }

  initiateFunction()

  const getKeyValue = (key) => {
    const value = urlParams.get(key)
    return value ? `${key}=${value}` : ''
  }

  const getRequiredParams = (array, exception = ['']) => {
    const filteredKeyValue = array.filter((el) => {
      return getKeyValue(el) && !exception.includes(el)
    })
    return filteredKeyValue.map((el) => getKeyValue(el)).join('&')
  }

  return {
    get: getParams,
    replace: replaceParams,
    append: appendParams,
    remove: removeParams,
    getKeyValue: getKeyValue,
    getRequiredParams: getRequiredParams,
  }
}

export default useURLParams
