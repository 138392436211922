import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GetReplenishmentTaskPartialResponseType } from 'features/SupplyOrder/services/replenishment'
import { withLoadingReducer } from 'utils/reducerHandler'
import { SLICE_NAME, fetchGetReplenishmentTaskPartial } from './thunk'

export type ReplenishmentTaskPartialSliceType = {
  isLoading: boolean
  replenishNo: string
  data: GetReplenishmentTaskPartialResponseType['data']
  pagination: GetReplenishmentTaskPartialResponseType['pagination']
  checkedItems: number[]
  form: {
    [id: number]: {
      value: number
      error: boolean
      item: GetReplenishmentTaskPartialResponseType['data'][number]
    }
  }
}

const initialState: ReplenishmentTaskPartialSliceType = {
  isLoading: false,
  replenishNo: '',
  data: [],
  pagination: {
    page_size: 20,
    page_index: 1,
    number_of_elements: 0,
  },
  checkedItems: [],
  form: {},
}

const replenishmentTaskPartialSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetReplenishmentTaskPartial: () => initialState,
    setCheckedAll: (state, action) => {
      const checked = action.payload

      if (checked) {
        state.checkedItems = [...state.checkedItems, ...state.data.map(({ id }) => id)]
      } else {
        state.checkedItems = []
      }
    },
    setCheckedItems: (state, action) => {
      const id = action.payload
      const isExist = state.checkedItems.some((itemId) => itemId === id)

      if (isExist) {
        state.checkedItems = state.checkedItems.filter((itemId) => itemId !== id)
      } else {
        state.checkedItems = [...state.checkedItems, id]
      }
    },
    setForm: (
      state,
      action: PayloadAction<{
        id: number
        selected: {
          value: number
          error: boolean
          item: GetReplenishmentTaskPartialResponseType['data'][number]
        }
      }>,
    ) => {
      const { id, selected } = action.payload
      state.form[id] = selected
    },
  },
  extraReducers: withLoadingReducer(
    (builder: ActionReducerMapBuilder<ReplenishmentTaskPartialSliceType>) => {
      builder.addCase(fetchGetReplenishmentTaskPartial.fulfilled, (state, action) => {
        const {
          replenishNo,
          item: { data, pagination },
        } = action.payload

        state.replenishNo = replenishNo
        state.data = data
        state.pagination = pagination

        data.forEach((item) => {
          const formItem = {
            value: state.form[item.id]?.value ?? item.repl_qty,
            error: state.form[item.id]?.error || false,
            item: state.form[item.id]?.item || item,
          }
          state.form[item.id] = formItem
        })
      })
    },
    [fetchGetReplenishmentTaskPartial],
  ),
})

export const { resetReplenishmentTaskPartial, setCheckedAll, setCheckedItems, setForm } =
  replenishmentTaskPartialSlice.actions
export default replenishmentTaskPartialSlice.reducer
