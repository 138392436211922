import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'

import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'
import { callErrorMsg } from 'helpers/errorMsg'
import { AUTH_ADMIN, AUTH_INVENTORY_CONTROLLER, AUTH_LP } from 'middleware/privateRoute'
import { RootStateType } from 'store'
import {
  getSoList,
  getSoApproval,
  GetSoApprovalResponseType,
  GetSoApprovalRequestType,
  PutSupplyOrderApprovalDetailResponseType,
  PutSupplyOrderApprovalDetailRequestType,
  putSupplyOrderApprovalDetail,
  getUsers,
  GetUsersRequestType,
  GetUsersResponseType,
} from 'utils/apiList/supplyOrderApproval'
import { getAllLocations } from 'features/Location/services/location'
import { GetLocationListResponseType } from 'features/Location/@types/location'
import { serializeQueryToURL } from 'utils/queryParamsURL'
import { toastSuccess } from 'utils/toast'

export const fetchGetSupplyOrderApproval = createAsyncThunk<
  GetSoApprovalResponseType,
  GetSoApprovalRequestType,
  RejectValueType
>(
  'supplyOrderApproval/fetchGetSupplyOrderApproval',
  async (params, { rejectWithValue, getState }) => {
    try {
      const qs = serializeQueryToURL(params)
      const {
        auth: { userData },
      } = getState() as RootStateType

      const approverRole = [AUTH_INVENTORY_CONTROLLER, AUTH_LP]
      const approver = [] as unknown as ['IC' | undefined, 'LP' | undefined]
      let isAdmin = false

      userData.authorities.forEach((item: { name: string }) => {
        if (item.name === AUTH_ADMIN) {
          isAdmin = true
        }

        if (approverRole.includes(item.name)) {
          approver.push(item.name === AUTH_INVENTORY_CONTROLLER ? 'IC' : 'LP')
        }
      })

      const response = (await getSoApproval({
        ...qs,
        ...(!isAdmin &&
          approver.length < 2 && {
            approver: approver[0],
          }),
      })) as unknown as AxiosResponse<GetSoApprovalResponseType>

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchPutSupplyOrderApprovalDetail = createAsyncThunk<
  PutSupplyOrderApprovalDetailResponseType,
  PutSupplyOrderApprovalDetailRequestType & {
    meta: {
      callback: () => void
    }
  },
  RejectValueType
>(
  'supplyOrderApproval/fetchPutSupplyOrderApprovalDetail',
  async ({ id, payload, meta }, { rejectWithValue, dispatch }) => {
    try {
      const response: AxiosResponse<PutSupplyOrderApprovalDetailResponseType> =
        await putSupplyOrderApprovalDetail({ id, payload })

      meta.callback()
      toastSuccess(response.data.data.message)
      dispatch(fetchGetSupplyOrderApproval())

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchGetUsers = createAsyncThunk<
  GetUsersResponseType,
  GetUsersRequestType,
  RejectValueType
>('supplyOrderApproval/fetchGetUsers', async (params, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<GetUsersResponseType> = await getUsers(params)

    return response.data
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchLocationList = createAsyncThunk(
  'supplyOrderApproval/fetchLocationList',
  async (callbackFunction: (locationIds: number[]) => void, { getState, rejectWithValue }) => {
    try {
      const { data } = (await getAllLocations()) as unknown as AxiosResponse<
        GetLocationListResponseType['content']
      >
      const listAllLocationId = data.map((item) => item.location_id)
      const { userData } = (getState() as RootStateType).auth
      const listLocationIdByRoleAccess = userData.location_roles.map(
        (locationItem) => locationItem.location_id,
      )
      const locationIds = userData.isSuperAdmin ? listAllLocationId : listLocationIdByRoleAccess

      callbackFunction(locationIds)
      return data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchSoList = createAsyncThunk(
  'supplyOrderApproval/fetchSoList',
  async (params: { locationId: number[]; soNumber?: string }, { rejectWithValue }) => {
    const { soNumber, locationId } = params

    const paramData = {
      created: false,
      pageIndex: 0,
      number: soNumber || '',
      origin: locationId.toString(),
    }
    try {
      const { data } = await getSoList(paramData)
      return data.data.content
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)
