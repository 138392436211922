import { memo } from 'react'
import styled from 'styled-components'
import { RowWarehouse, SystemIcon, NoData } from 'components'
import useAllowedAccessFor from 'customHooks/useAllowedAccessFor'
import allowedRoleList from 'middleware/allowedRoleOnFeature/productAndStock/warehouse'
import { useAppSelector, shallowEqual } from 'store'

function TableWareHouse({ data, handleChangeActive, handleClickSave }) {
  const { authorities } = useAppSelector(
    ({
      auth: {
        userData: { authorities },
      },
    }) => ({ authorities }),
    shallowEqual,
  )

  /****
   *  RESTRICTION LIST FOR FEATURE
   *  USING CUSTOM HOOKS WITH MEMOIZATION
   *
   */

  const allowedForStockTrailing = useAllowedAccessFor(authorities, allowedRoleList.detailStockTrail)

  const allowedForEditPrice = useAllowedAccessFor(authorities, allowedRoleList.editPrice)

  const allowedForEditRack = useAllowedAccessFor(authorities, allowedRoleList.editRack)

  const allowedForStock = useAllowedAccessFor(authorities, allowedRoleList.editStock)

  const allowedForToggleStatus = useAllowedAccessFor(authorities, allowedRoleList.toggleStatus)

  return (
    <>
      <Container>
        <thead>
          <Tr>
            <Th ratio="3">
              NAMA WAREHOUSE
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th ratio="4">
              NAMA PRODUK
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th ratio="2">NO SKU</Th>
            <Th ratio="2">
              HARGA
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th ratio="2">
              STOK TERSEDIA
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th ratio="2">
              TOTAL STOK
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th ratio="1">
              KUOTA DISKON
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            {/* <Th ratio="2">
              NO RAK
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th> */}
            <Th ratio="2">RESERVED QTY</Th>
            <Th ratio="1">STATUS</Th>
            <Th ratio="1">AKSI</Th>
          </Tr>
        </thead>
        <tbody>
          {data.map((el) => (
            <RowWarehouse
              key={`${el.locationId} --${el.productId}`}
              data={el}
              handleChangeActive={handleChangeActive}
              handleClickSave={handleClickSave}
              allowedForStockTrailing={allowedForStockTrailing}
              allowedForEditPrice={allowedForEditPrice}
              allowedForEditRack={allowedForEditRack}
              allowedForStock={allowedForStock}
              allowedForToggleStatus={allowedForToggleStatus}
            />
          ))}
        </tbody>
      </Container>
      {data.length === 0 && <NoData />}
    </>
  )
}

export default memo(TableWareHouse)

const Container = styled.table`
  width: 100%;
`
const Tr = styled.tr`
  display: flex;
  padding: 16px 32px;
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`
