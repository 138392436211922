import { PayloadAction, createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  InitialStateType,
  SetQeuryType,
  PutMilestoneStatusRequestType,
  SetPopupModeActionPayloadType,
  SetPopupSelectedMilestoneActionPayloadType,
  SetMilestonePriorityActionPayloadType,
} from 'features/Promotion/@types/miletstoneList'
import {
  getMilestoneList,
  putMilestoneStatus,
  getMilestonePriorityList,
  postMilestonePriority,
} from 'features/Promotion/services/milestoneList'
import { callErrorMsg } from 'helpers/errorMsg'
import { toastSuccess, toastFailed } from 'utils/toast'

export const initialState: InitialStateType = {
  isLoading: false,
  query: {
    page: 0,
    page_size: 20,
    sort_by: null,
    sort_direction: 'DESC',
    search: '',
  },
  milestones: [],
  popup: {
    mode: '',
    milestoneOptions: [],
    milestonePriority: [],
    selectedMilestone: { id: 0, status: 'MANUAL_DEACTIVATE', title: '', name: '' },
  },
}

export const SLICE_NAME = 'milestoneList'

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    resetPopupSelectedMilestone: (state) => {
      state.popup.selectedMilestone = initialState.popup.selectedMilestone
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setQuery: (state, { payload }: PayloadAction<SetQeuryType>) => {
      state.query = { ...state.query, ...payload }
    },
    setPopupMode: (state, { payload }: PayloadAction<SetPopupModeActionPayloadType>) => {
      const { mode } = payload
      state.popup.mode = mode
    },
    setPopupSelectedMilestone: (
      state,
      { payload }: PayloadAction<SetPopupSelectedMilestoneActionPayloadType>,
    ) => {
      const { milestone } = payload
      state.popup.selectedMilestone = milestone
    },
    setMilestonePriority: (
      state,
      { payload }: PayloadAction<SetMilestonePriorityActionPayloadType>,
    ) => {
      const { milestonePriorityList } = payload
      state.popup.milestonePriority = milestonePriorityList
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMilestoneList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchMilestoneList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchMilestoneList.fulfilled, (state, action) => {
        const res = action.payload

        state.milestones = res.result
        state.isLoading = false
      })
      .addCase(updateMilestoneStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateMilestoneStatus.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateMilestoneStatus.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchMilestonePriorityOption.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchMilestonePriorityOption.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchMilestonePriorityOption.fulfilled, (state, { payload }) => {
        const { result } = payload
        state.isLoading = false
        state.popup.milestoneOptions = result.map((el) => ({
          id: el.id,
          status: el.status,
          title: el.title,
          name: el.title,
        }))
      })

      .addCase(fetchMilestonePriorityList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchMilestonePriorityList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchMilestonePriorityList.fulfilled, (state, { payload }) => {
        const { pinning } = payload

        state.popup.milestonePriority = pinning

        state.isLoading = false
      })
      .addCase(upsertMilestoneStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(upsertMilestoneStatus.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(upsertMilestoneStatus.fulfilled, (state) => {
        state.isLoading = false
      })
  },
})

export const {
  reset,
  setIsLoading,
  setQuery,
  setPopupMode,
  setPopupSelectedMilestone,
  setMilestonePriority,
  resetPopupSelectedMilestone,
} = slice.actions
export default slice.reducer

export const fetchMilestoneList = createAsyncThunk(
  `${SLICE_NAME}/fetchMilestoneList`,
  async (_, { getState, rejectWithValue }) => {
    const {
      milestoneList: { query },
    } = getState() as StoreStateType

    try {
      const { data } = await getMilestoneList({
        params: { page_index: query.page, page_size: query.page_size, title: query.search },
      })

      return data.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const updateMilestoneStatus = createAsyncThunk(
  `${SLICE_NAME}/fetchMilestoneStatus`,
  async ({ payload }: PutMilestoneStatusRequestType, { rejectWithValue }) => {
    try {
      const { data } = await putMilestoneStatus({
        payload,
      })

      return data.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchMilestonePriorityOption = createAsyncThunk(
  `${SLICE_NAME}/fetchMilestonePriorityOption`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getMilestoneList({
        params: { page_index: 0, page_size: 9000 },
      })

      return data.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchMilestonePriorityList = createAsyncThunk(
  `${SLICE_NAME}/fetchMilestonePriorityList`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getMilestonePriorityList()

      return data || []
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const upsertMilestoneStatus = createAsyncThunk(
  `${SLICE_NAME}/upsertMilestoneStatus`,
  async (_, { rejectWithValue, getState }) => {
    const {
      milestoneList: { popup },
    } = getState() as StoreStateType

    try {
      const { data } = await postMilestonePriority({
        payload: {
          sequence: popup.milestonePriority.map((el) => ({ milestone_id: el.milestone_id })),
        },
      })
      if (data.error?.status) {
        toastFailed(data.error.message)
      } else {
        toastSuccess('Berhasil Mengupdate Priority')
      }

      return data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)
