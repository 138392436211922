import { memo } from "react";
import styled from "styled-components";
import { TextBody, SystemIcon, Button } from "components";
import dayjs from "dayjs";

function RowInbound({
  data,
  type,
  handleClickEdit,
  handlePrint,
  handlePrintCompletSO
}) {
  const handleClickPrintInbound = () => {
    handlePrintCompletSO(data);
  };
  return (
    <Tr>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {data.supply_order_number}
        </TextBody>
      </Td>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {dayjs(data.supply_order_created_at).format("DD MMM YYYY - HH:mm")}
        </TextBody>
      </Td>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {data.location_origin_name}
        </TextBody>
      </Td>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {data.location_destination_name}
        </TextBody>
      </Td>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {data.supply_order_status}
        </TextBody>
      </Td>
      <Td ratio="2">
        {type === "incoming" ? (
          <Button variant="wrapper" onClick={() => handleClickEdit(data)}>
            <ActionWrapper>
              <TextBody weight="light" color="main">
                Detail
              </TextBody>
              <SystemIcon colorIcon="main" iconName="edit" />
            </ActionWrapper>
          </Button>
        ) : (
          <>
            {/* <Button variant="wrapper" onClick={() => handlePrint(data)}>
              <TextBody weight="light" className="pointer" color="main">
                Cetak
              </TextBody>
            </Button> */}

            <Button variant="wrapper" onClick={handleClickPrintInbound}>
              <TextBody weight="light" className="pointer" color="main">
                Cetak
              </TextBody>
            </Button>
          </>
        )}
      </Td>
    </Tr>
  );
}

export default memo(RowInbound);

const Tr = styled.tr`
  display: flex;
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
`;
const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`;

const ActionWrapper = styled.div`
  display: flex;
  i {
    margin-left: 20px;
  }
`;
