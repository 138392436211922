import { createSlice } from '@reduxjs/toolkit'
import { GetUnloadingDetailResponseType } from 'features/SupplyOrder/services/unloadingDetail'

export type LostKoliListType = {
  supplyOrderNumber: string
  koliList: string[]
}[]

export type InboundUnloadingDetailType = {
  isLoading: boolean
  unloadingDetail: GetUnloadingDetailResponseType['data'] | null
  soUnloadingId: number
  modalLostKoli: {
    isOpen: boolean
    listDataLost: LostKoliListType
  }
  modalChronology: {
    isOpen: boolean
    chronology: string
    evidenceImageUrls: string[]
    uploadedFile: File[]
  }
}

const initialState: InboundUnloadingDetailType = {
  isLoading: true,
  unloadingDetail: null,
  soUnloadingId: 0,
  modalLostKoli: {
    isOpen: false,
    listDataLost: [],
  },
  modalChronology: {
    isOpen: false,
    chronology: '',
    evidenceImageUrls: [],
    uploadedFile: [],
  },
}

export const SLICE_NAME = 'inboundUnloading'

const inboundUnloadingDetailSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetInboundUnloadingState: () => initialState,
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setUnloadingDetail: (state, action) => {
      state.unloadingDetail = action.payload
    },
    setSoUnloadingId: (state, action) => {
      state.soUnloadingId = action.payload
    },
    setModalLostKoli: (state, action) => {
      state.modalLostKoli = { ...state.modalLostKoli, ...action.payload }
    },
    resetModalLostKoli: (state) => {
      state.modalLostKoli = initialState.modalLostKoli
    },
    setModalChronology: (state, action) => {
      state.modalChronology = { ...state.modalChronology, ...action.payload }
    },
    resetModalChronology: (state) => {
      state.modalChronology = initialState.modalChronology
    },
  },
})
export const {
  resetInboundUnloadingState,
  setIsLoading,
  setSoUnloadingId,
  setUnloadingDetail,
  setModalLostKoli,
  resetModalLostKoli,
  setModalChronology,
  resetModalChronology,
} = inboundUnloadingDetailSlice.actions
export default inboundUnloadingDetailSlice.reducer
