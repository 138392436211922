import {
  SET_IS_LOADING,
  SET_LAST_PARAMS,
  SET_IS_POPUP_SHOW,
  SET_TICKERS,
  SET_SELECTED_TICKER,
  SET_HUBS,
  SET_DATA_PAGINATION,
  SET_IS_POPUP_SHOW_EDIT,
  SET_SELECTED_LOCATION,
  SET_PAGES,
  SET_APP_LINKS,
} from "storeContext/actionsType/ticker";

export const initialStateTicker = {
  isLoading: false,
  lastParams: {},
  isShowPopup: false,
  tickers: [],
  selectedTicker: {},
  hubs: [],
  dataPagination: {},
  isShowPopupEdit: false,
  selectedLocation: {},
  pages: [],
  appLinks: [],
};

export default function ticker(state = initialStateTicker, { type, payload }) {
  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_LAST_PARAMS:
      return {
        ...state,
        lastParams: payload,
      };
    case SET_IS_POPUP_SHOW:
      return {
        ...state,
        isShowPopup: payload,
      };
    case SET_TICKERS:
      return {
        ...state,
        tickers: payload,
      };
    case SET_SELECTED_TICKER:
      return {
        ...state,
        selectedTicker: payload,
      };
    case SET_HUBS:
      return {
        ...state,
        hubs: payload,
      };
    case SET_DATA_PAGINATION:
      return {
        ...state,
        dataPagination: payload,
      };
    case SET_IS_POPUP_SHOW_EDIT:
      return {
        ...state,
        isShowPopupEdit: payload,
      };
    case SET_SELECTED_LOCATION:
      return {
        ...state,
        selectedLocation: payload,
      };
    case SET_PAGES:
      return {
        ...state,
        pages: payload,
      };
    case SET_APP_LINKS:
      return {
        ...state,
        appLinks: payload,
      };
    default:
      return state;
  }
}
