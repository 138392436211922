import { memo } from 'react'
import styled from 'styled-components'
import { Typography } from '@astro-ui/components'
import icInstagram from 'assets/images/icInstagram.svg'
import { ReactComponent as PecahBelah } from 'assets/images/mdi_glass-fragile.svg'
import { ReactComponent as Telur } from 'assets/images/egg-crack-fill.svg'
import { ReactComponent as Cair } from 'assets/images/Cair.svg'
import { ReactComponent as Busuk } from 'assets/images/Busuk_2.svg'
import QRCode from 'react-qr-code'

function Body({ data, packageOrderNumber, packageId }) {
  const uniquePID = packageId.split('-')[packageId.split('-').length - 1]
  return (
    <BodyContainer>
      <RowTop>
        <CustomerInfo>
          <Typography color="pureBlack" fontSize={'2.2vw'} lineHeight={1.5}>
            <b style={{ fontWeight: 800 }}>KODE BOOKING</b>
          </Typography>
          <Divider />
          <Typography
            fontSize={'4.1vw'}
            fontFamily="Montserrat"
            fontWeight={900}
            textTransform="uppercase"
            lineHeight={1}
          >
            {data?.order_grab?.grab_booking_id ? data?.order_grab?.grab_booking_id : '-'}
          </Typography>
          <Typography color="pureBlack" fontSize={'1.8vw'} fontWeight={800}>
            {data?.order_invoice}
          </Typography>
          <DividerLong />

          <IGContainer>
            <IGIcon src={icInstagram} />
            <Typography color="pureBlack" fontSize={'2.2vw'} lineHeight={1.5} fontWeight={900}>
              @astronauts.id
            </Typography>
          </IGContainer>
        </CustomerInfo>
        <OrderInfo>
          <QRInfoContainer>
            <QRInfo>
              <PaketInfo>
                <Typography
                  color="pureBlack"
                  fontSize={'2.2vw'}
                  fontWeight={900}
                  textTransform="uppercase"
                >
                  {`Paket ${packageOrderNumber}`}
                </Typography>
                {data?.order_customer_address.customer_location_quadrant > 0 ? (
                  <QuadrantContainer>
                    <Typography
                      color="white"
                      fontFamily={'Montserrat'}
                      fontSize={'2.2vw'}
                      fontWeight={900}
                      textTransform="uppercase"
                      lineHeight={1}
                    >
                      {data?.order_customer_address.customer_location_quadrant}
                    </Typography>
                  </QuadrantContainer>
                ) : null}
              </PaketInfo>
              <QRCode
                value={packageId || data.order_invoice}
                style={{
                  height: 'auto',
                  maxWidth: 100,
                  width: 100,
                }}
                size={100}
              />
              <Typography color="pureBlack" fontSize={'2.2vw'} fontWeight={900}>
                KODE UNIK: {uniquePID}
              </Typography>
            </QRInfo>
          </QRInfoContainer>
        </OrderInfo>
      </RowTop>
      <DeliveryHandlingContainer>
        <Column>
          <Typography
            fontFamily={'Montserrat'}
            fontSize={'4.2vw'}
            fontWeight={800}
            lineHeight={1}
            fontStyle={'italic'}
          >
            AWAS! PERHATIKAN
          </Typography>
          <Typography
            fontFamily={'Montserrat'}
            fontSize={'6vw'}
            fontWeight={800}
            lineHeight={1}
            fontStyle={'italic'}
          >
            POSISI PAKET
          </Typography>

          <Typography
            fontFamily={'Montserrat'}
            fontSize={'2.5vw'}
            fontWeight={700}
            lineHeight={1}
            fontStyle={'italic'}
            style={{ paddingLeft: 4 }}
          >
            MUDAH PECAH / CAIR / BUSUK
          </Typography>
        </Column>
        <IconRow>
          <Telur className="dh-icons" />
          <PecahBelah className="dh-icons" />
          <Cair className="dh-icons" />
          <Busuk className="dh-icons" />
        </IconRow>
      </DeliveryHandlingContainer>
    </BodyContainer>
  )
}

export default memo(Body)

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 83vw;
`
const RowTop = styled.div`
  display: flex;
  flex-direction: row;
  height: 41.5vw;
  border-bottom: 1px dashed black;
`
const CustomerInfo = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 50%;
  padding-top: 3.9vw;
  padding-right: 3.9vw;
  border-right: 1px dashed black;
`
const OrderInfo = styled.div`
  display: flex;
  flex-direction: column;
  min-width: calc(50% - 1px);
`

const Divider = styled.div`
  min-height: 1vw;
`
const DividerLong = styled.div`
  min-height: 2vw;
`

const IGContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  bottom: 2vw;
  left: 0;
`

const IGIcon = styled.img`
  height: 4vh;
  width: auto;
`

const QRInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`
const QRInfo = styled.div`
  display: flex;
  width: fit-content;
  flex-direction: column;
`
const PaketInfo = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`
const QuadrantContainer = styled.div`
  padding: 4px;
  border-radius: 2px;
  background-color: black;
`

const DeliveryHandlingContainer = styled.div`
  display: flex;
  height: 100%;
  min-height: 100%;
  padding: 4.2vw;
  height: 41.5vw;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
`
const IconRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 23%;
  flex-wrap: wrap;
  gap: 2px;

  .dh-icons {
    height: 8vh;
    width: auto;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: 4px;
`
