import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  PaymentGatewayAdminStateType,
  PaymentChannelType,
  PGConfirmationDialogStateType,
} from 'features/Payment/@types/paymentGatewayAdmin'
import { SLICE_NAME, fetchInternalPaymentChannel } from './thunk'

const initialState: PaymentGatewayAdminStateType = {
  isLoading: false,
  paymentChannel: [],
  selectedPaymentChannel: null,
  isModalUpdatePGOpen: false,
  form: {
    status: false,
    pg_source: '',
    failover: '',
  },
  confirmationDialogState: 'CLOSED',
}

const paymentGatewayAdmin = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setSelectedPaymentChannel: (
      state,
      { payload }: PayloadAction<Nullable<PaymentChannelType>>,
    ) => {
      state.selectedPaymentChannel = payload
    },
    setIsModalUpdatePGOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isModalUpdatePGOpen = payload
    },
    setForm: (state, { payload }: PayloadAction<Partial<PaymentGatewayAdminStateType['form']>>) => {
      state.form = { ...state.form, ...payload }
    },
    resetForm: (state) => {
      state.form = { ...initialState.form }
    },
    setConfirmationDialogState: (
      state,
      { payload }: PayloadAction<PGConfirmationDialogStateType>,
    ) => {
      state.confirmationDialogState = payload
    },
    closeAndResetStateDialog: (state) => {
      state.form = { ...initialState.form }
      state.selectedPaymentChannel = null
      state.isModalUpdatePGOpen = false
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchInternalPaymentChannel.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchInternalPaymentChannel.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.paymentChannel = payload
      })
      .addCase(fetchInternalPaymentChannel.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default paymentGatewayAdmin.reducer
export const {
  reset,
  setSelectedPaymentChannel,
  setIsModalUpdatePGOpen,
  setForm,
  resetForm,
  setConfirmationDialogState,
  closeAndResetStateDialog,
} = paymentGatewayAdmin.actions
