import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  InitialStateType,
  PopUpFraudStateType,
  SetFraudFormType,
  FraudType,
} from 'features/Fraud/@types/BlacklistAndWhitelist'

export const SLICE_NAME_BLACKLIST_AND_WHITELIST = 'blacklistAndWhitelist'

export const initialState: InitialStateType = {
  popupFraudState: 'CLOSED',
  fraudReasonList: [],
  fraudForm: {
    reason: '',
    otherReason: '',
  },
  popupFraudLoading: false,
  fraudType: 'blacklist',
}

const slice = createSlice({
  name: SLICE_NAME_BLACKLIST_AND_WHITELIST,
  initialState,
  reducers: {
    resetState: () => initialState,
    setPopUpFraudState: (state, action: PayloadAction<PopUpFraudStateType>) => {
      state.popupFraudState = action.payload
    },
    setFraudReasonList: (state, action: PayloadAction<string[]>) => {
      state.fraudReasonList = action.payload
    },
    setFraudForm: (state, { payload }: PayloadAction<SetFraudFormType>) => {
      const { field, value } = payload
      state.fraudForm[field] = value
    },
    resetFraudForm: (state) => {
      state.fraudForm = { ...initialState.fraudForm }
    },
    setFraudLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.popupFraudLoading = payload
    },
    setFraudType: (state, { payload }: PayloadAction<FraudType>) => {
      state.fraudType = payload
    },
  },
})

export const {
  resetState,
  setPopUpFraudState,
  setFraudReasonList,
  setFraudForm,
  resetFraudForm,
  setFraudLoading,
  setFraudType,
} = slice.actions
export default slice.reducer
