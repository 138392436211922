export const SET_IS_LOADING_LOCATION = 'SET_IS_LOADING_LOCATION/location-page'
export const SET_DATA_PAGINATION = 'SET_DATA_PAGINATION/location-page'
export const SET_LOCATIONS = 'SET_LOCATIONS/location-page'
export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION/location-page'
export const SET_SELECTED_LOCATION_DOWNLOAD = 'SET_SELECTED_LOCATION_DOWNLOAD/location-page'
export const SET_IS_POP_UP_DOWNLOAD = 'SET_IS_POP_UP_DOWNLOAD/location-page'
export const SET_LAST_QUERY = 'SET_LAST_QUERY/location-page'
export const SET_SUPERVISORS = 'SET_SUPERVISORS/product-page'
export const SET_DATA_COVERAGE_AREA = 'SET_DATA_COVERAGE_AREA'
export const SET_DATA_LOCATION_TYPE = 'SET_DATA_LOCATION_TYPE'
export const SET_DATA_LOCATION_CLOSE_REASON = 'SET_DATA_LOCATION_CLOSE_REASON'
export const LOCATION_SET_LOCATION_DETAIL_LIST = 'LOCATION_SET_LOCATION_DETAIL_LIST/location-page'
export const SET_DATA_AVAILABILITY_DRIVER = 'SET_DATA_AVAILABILITY_DRIVER'
export const LOCATION_SET_LOCATION_FLAG_LIST = 'LOCATION_SET_LOCATION_FLAG_LIST/location-page'
