import apiServices from 'config/apiServiceApiGateway'
import { type GetNonLoginTypesResponseType } from 'features/ABAdmin/@types/ExperimentAddEdit'
import {
  type GetExperimentByIdResponseType,
  type PostPutExperimentPayloadType,
} from 'utils/apiList/abtesting'

const { Get, Post, Put } = apiServices

export const getNonLoginExperimentTypes = () =>
  Get<GetNonLoginTypesResponseType>({
    url: '/abtesting/internal/:version/non_login/experiment/type',
    version: 'v1',
  })

export const getNonLoginExperimentById = (experimentId: number) =>
  Get<GetExperimentByIdResponseType, true>({
    url: '/abtesting/internal/:version/non_login/experiment/:experimentId',
    version: 'v1',
    urlParams: { experimentId },
  })

export const postNonLoginExperiment = (data: PostPutExperimentPayloadType) =>
  Post({
    url: '/abtesting/internal/:version/non_login/experiment',
    version: 'v1',
    data,
  })

export const putNonLoginExperiment = (experimentId: number, data: PostPutExperimentPayloadType) =>
  Put({
    url: '/abtesting/internal/:version/non_login/experiment/:experimentId',
    version: 'v1',
    urlParams: { experimentId },
    data,
  })

export const putStartNonLoginExperiment = (experimentId: number) =>
  Put({
    url: '/abtesting/internal/:version/non_login/experiment/:experimentId/start',
    version: 'v1',
    urlParams: { experimentId },
  })

export const putStopNonLoginExperiment = (experimentId: number) =>
  Put({
    url: '/abtesting/internal/:version/non_login/experiment/:experimentId/stop',
    version: 'v1',
    urlParams: { experimentId },
  })
