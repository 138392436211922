import React from 'react'
import styled, { css } from 'styled-components'

const Button = ({ children, variant, onClick, ...rest }) => {
  if (variant === 'wrapper') {
    return (
      <ButtonWrapper
        {...rest}
        onClick={(e) => {
          e.preventDefault()
          onClick(e)
        }}
      >
        {children}
      </ButtonWrapper>
    )
  }
  if (variant === 'print') {
    return (
      <ButtonPrint {...rest} onClick={onClick}>
        {children}
      </ButtonPrint>
    )
  }
  if (variant === 'text') {
    return (
      <ButtonText {...rest} onClick={onClick}>
        {children}
      </ButtonText>
    )
  }
  if (variant === 'add-small') {
    return (
      <ButtonAddSmall {...rest} onClick={onClick}>
        {children}
      </ButtonAddSmall>
    )
  }
  return (
    <ButtonMain {...rest} onClick={onClick}>
      {children}
    </ButtonMain>
  )
}

Button.defaultProps = {
  size: 'normal',
  color: 'white',
  onClick: () => {},
  colorButton: 'main',
  variant: '',
}

export default React.memo(Button)

const ButtonText = styled.button`
  align-items: center;
  display: flex;
  height: fit-content;
  width: ${({ size }) => (size === 'full' ? '100%' : 'fit-content')};
  cursor: pointer;
  background: none;
  justify-content: center;
  border: none;
  box-shadow: none;
  padding: ${({ size }) => (size === 'small' ? '5px 12px' : '10px 16px')};
  color: ${({ theme: { colors }, color }) => colors[color]};
  font-size: ${({ size }) => (size === 'small' ? '0.7rem' : '0.9rem')};
  border-radius: 6px;
  font-weight: ${({ theme: { fontWeights }, size }) =>
    size === 'small' ? fontWeights.light : fontWeights.normal};
  :focus {
    outline: none;
  }
  :disabled {
    background-color: ${({ theme: { colors } }) => colors.body};
    border: 1px solid ${({ theme: { colors } }) => colors.body};
    color: ${({ theme: { colors } }) => colors.grey};
    cursor: not-allowed;
  }
`

const ButtonMain = styled.button`
  align-items: center;
  display: flex;
  height: fit-content;
  width: ${({ size }) => (size === 'full' ? '100%' : 'fit-content')};
  cursor: pointer;
  background: none;
  justify-content: center;
  border: none;
  box-shadow: none;
  padding: ${({ size }) => (size === 'small' ? '5px 12px' : '10px 16px')};
  background-color: ${({ theme: { colors }, colorButton }) => colors[colorButton]};
  color: ${({ theme: { colors }, color }) => colors[color] || colors.white};
  font-size: ${({ size }) => (size === 'small' ? '0.7rem' : '0.9rem')};
  border-radius: 6px;
  font-weight: ${({ theme: { fontWeights }, size }) =>
    size === 'small' ? fontWeights.light : fontWeights.normal};
  border: 1px solid;
  :focus {
    outline: none;
  }
  :disabled {
    background-color: ${({ theme: { colors } }) => colors.body};
    border: 1px solid ${({ theme: { colors } }) => colors.body};
    color: ${({ theme: { colors } }) => colors.grey};
    cursor: not-allowed;
  }
  ${({ v2 }) =>
    v2 &&
    css`
      padding: 9px 18px;
      font-size: 14px;
      font-weight: 400;
      min-width: 160px;
      background-color: ${({ theme: { colors }, colorButton = 'mainV2' }) => colors[colorButton]};
    `}
  ${({ isOutline }) =>
    isOutline &&
    css`
      background-color: ${({ theme: { colors } }) => colors.white};
      color: ${({ theme: { colors }, outlineColor }) => colors[outlineColor] || colors.main};
      border-color: ${({ theme: { colors }, outlineColor }) => colors[outlineColor] || colors.main};
    `}
`

const ButtonPrint = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px 21px;
  border: 1px solid ${({ theme: { colors } }) => colors.main};
  border-radius: 6px;
  height: fit-content;
  background-color: ${({ theme: { colors } }) => colors.main};
  color: ${({ theme: { colors } }) => colors.white};
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 600;
`

const ButtonWrapper = styled.button`
  background-color: none;
  background: none;
  border: ${(props) => props.border || 'none'};
  cursor: pointer;
  width: ${({ size }) => (size === 'full' ? '100%' : 'fit-content')};
  height: fit-content;
  :focus {
    outline: none;
  }
  :disabled {
    cursor: not-allowed;
  }
`

const ButtonAddSmall = styled.button`
  padding: 6px 8px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
`
