import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

const { Post } = axiosInstanceApiGateway

type RequestOrderAutoAssignType = {
  order_id: number
  location_id: number
}

export const postAutoAssignOrder = (payload: RequestOrderAutoAssignType) =>
  Post({ url: '/logistic/internal/v1/admin/auto-assign', data: payload })
