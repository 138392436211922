import { createAsyncThunk } from '@reduxjs/toolkit'
import { toastSuccess } from 'utils/toast'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  postOnHold,
  postRedelivery,
  postVerifyReturn,
  putSkipVerificationDropOff,
  putSkipVerificationPackage,
} from 'utils/apiList/allOrder'
import { sliceName, setDialogState, setPopUpSkipDropOff } from './allOrderSlice'

export const onSubmitSkipPackageVerification = createAsyncThunk(
  `${sliceName}/onSubmitSkipPackageVerification`,
  async (
    payload: { captain_email: string; captain_password: string; note: string; orderId: number },
    { dispatch },
  ) => {
    try {
      const { orderId, ...restPayload } = payload
      await putSkipVerificationPackage(restPayload, orderId)
      dispatch(setDialogState('CLOSED'))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const onSubmitSkipPackageDropOff = createAsyncThunk(
  `${sliceName}/onSubmitSkipPackageDropOff`,
  async (
    payload: { email: string; password: string; note: string; orderId: number; type: string },
    { dispatch },
  ) => {
    try {
      const { orderId, ...restPayload } = payload
      await putSkipVerificationDropOff(restPayload, orderId)
      dispatch(setPopUpSkipDropOff('CLOSED'))
      toastSuccess('Berhasil Verifikasi Drop Off')
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const onSubmitRedelivery = createAsyncThunk(
  `${sliceName}/onSubmitRedelivery`,
  async (
    payload: {
      orderID?: number
      astro_approval_pin_authentication: {
        phone_number: string
        pin: string
      }
    },
    { dispatch },
  ) => {
    try {
      const { orderID, ...rest } = payload
      const { data } = await postRedelivery(orderID, rest)
      if (data) {
        toastSuccess(`Berhasil mengubah status order`)
        dispatch(setDialogState('CLOSED'))
      }
    } catch (err) {
      callErrorMsg(err)
    }
  },
)

export const onSubmitVerifyReturn = createAsyncThunk(
  `${sliceName}/onSubmitVerifyReturn`,
  async (
    payload: {
      orderID?: number
      reason: string
      astro_approval_pin_authentication: {
        phone_number: string
        pin: string
      }
    },
    { dispatch },
  ) => {
    try {
      const { orderID, ...rest } = payload
      const { data } = await postVerifyReturn(orderID, rest)
      if (data) {
        toastSuccess(`Berhasil mengubah status order`)
        dispatch(setDialogState('CLOSED'))
      }
    } catch (err) {
      callErrorMsg(err)
    }
  },
)
export const onSubmitOnHold = createAsyncThunk(
  `${sliceName}/onSubmitOnHold`,
  async (
    payload: {
      orderID?: number
      reason: string
      astro_approval_pin_authentication: {
        phone_number: string
        pin: string
      }
    },
    { dispatch },
  ) => {
    try {
      const { orderID, ...rest } = payload
      const { data } = await postOnHold(orderID, rest)
      if (data) {
        toastSuccess(`Berhasil mengubah status order`)
        dispatch(setDialogState('CLOSED'))
      }
    } catch (err) {
      callErrorMsg(err)
    }
  },
)
