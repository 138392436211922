import { memo } from "react";
import styled, { css } from "styled-components";
import { Checkbox } from "components";

function RowActivityMonitoringReceiving() {
  return (
    <Tr>
      <Td>
        <WrapCenter>
          <Checkbox />
        </WrapCenter>
      </Td>
      <Td min="200px">POKNS2021000006194-03</Td>
      <Td>SIRCLO</Td>
      <Td>11-10-2021</Td>
      <Td center>6</Td>
      <Td center>898</Td>
      <Td center>5</Td>
      <Td center>898</Td>
      <Td center>5</Td>
      <Td center>898</Td>
      <Td center>0</Td>
      <Td center>0</Td>
    </Tr>
  );
}

RowActivityMonitoringReceiving.defaultProps = {};

export default memo(RowActivityMonitoringReceiving);

const Tr = styled.tr`
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
  .input-container {
    width: calc(100% - 20px);
  }
`;

const Td = styled.td`
  color: ${({ theme: { colors }, color = "textSoft" }) => colors[color]};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  padding: 20px;
  min-width: ${({ min }) => min};
  ${({ center }) =>
    center &&
    css`
      text-align: center;
    `};
  .input-drop-down {
    width: 110px;
    input {
      padding: 6px 0;
    }
  }
`;

const WrapCenter = styled.div`
  display: flex;
  align-items: center;
`;
