export const PRODUCT_ADD_EDIT_RESET_STATE = 'productAddEdit/RESET_STATE'
export const PRODUCT_ADD_EDIT_SET_INPUT = 'productAddEdit/SET_INPUT'
export const PRODUCT_ADD_EDIT_SET_CATEGORY = 'productAddEdit/SET_CATEGORY'
export const PRODUCT_ADD_EDIT_SET_CATEGORY_ACTIVE = 'productAddEdit/SET_CATEGORY_ACTIVE'
export const PRODUCT_ADD_EDIT_ADD_CATEGORY = 'productAddEdit/ADD_CATEGORY'
export const PRODUCT_ADD_EDIT_DELETE_CATEGORY = 'productAddEdit/DELETE_CATEGORY'
export const PRODUCT_ADD_EDIT_ADD_IMAGE = 'productAddEdit/ADD_IMAGE'
export const PRODUCT_ADD_EDIT_SET_FORM_ERROR = 'productAddEdit/SET_FORM_ERROR'
export const PRODUCT_ADD_EDIT_SET_LOADING = 'productAddEdit/SET_LOADING'
export const PRODUCT_ADD_EDIT_MAP_STATE_EDIT = 'productAddEdit/MAP_STATE_EDIT'
export const PRODUCT_ADD_EDIT_SET_PRODUCT_DESCRIPTION_TEMP =
  'productAddEdit/SET_PRODUCT_DESCRIPTION_TEMP'
export const PRODUCT_ADD_EDIT_SET_POPUP_CONFIRMATION_CONSIGNMENT =
  'productAddEdit/SET_POPUP_CONFIRMATION_CONSIGNMENT'
export const PRODUCT_ADD_EDIT_SET_POPUP_SHELF_LIFE_WARNING =
  'productAddEdit/PRODUCT_ADD_EDIT_SET_POPUP_SHELF_LIFE_WARNING'
export const PRODUCT_ADD_EDIT_SET_PRIMARY_CATEGORY =
  'productAddEdit/PRODUCT_ADD_EDIT_SET_PRIMARY_CATEGORY'
export const PRODUCT_ADD_EDIT_SET_POPUP_SHELF_LIFE_VALIDATION_WARNING =
  'productAddEdit/PRODUCT_ADD_EDIT_SET_POPUP_SHELF_LIFE_VALIDATION_WARNING'
export const PRODUCT_ADD_EDIT_SET_RELABELED_IN_HUB =
  'productAddEdit/PRODUCT_ADD_EDIT_SET_RELABELED_IN_HUB'
export const PRODUCT_ADD_EDIT_SET_BUSINESS_OR_COMPANY_LIST =
  'productAddEdit/PRODUCT_ADD_EDIT_SET_BUSINESS_OR_COMPANY_LIST'
export const PRODUCT_ADD_EDIT_SET_PPN_LIST = 'productAddEdit/PRODUCT_ADD_EDIT_SET_PPN_LIST'
export const PRODUCT_ADD_EDIT_SET_SELECTED_PPN = 'productAddEdit/PRODUCT_ADD_EDIT_SET_SELECTED_PPN'
export const PRODUCT_ADD_EDIT_SET_PRODUCT_LABEL_IMAGE = 'productAddEdit/SET_PRODUCT_LABEL_IMAGE'
export const PRODUCT_ADD_EDIT_SET_PRODUCT_LABEL_PROMOTION =
  'productAddEdit/SET_PRODUCT_LABEL_PROMOTION'
export const PRODUCT_ADD_EDIT_ADD_PRODUCT_LABEL = 'productAddEdit/ADD_PRODUCT_LABEL'
export const PRODUCT_ADD_EDIT_DELETE_PRODUCT_LABEL = 'productAddEdit/DELETE_PRODUCT_LABEL'
