import { createAsyncThunk } from '@reduxjs/toolkit'

import { callErrorMsg } from 'helpers/errorMsg'
import {
  getAllLocationByTypeAPI,
  getLocationTypeAPI,
  getRoleByLocationTypeAPI,
  GetLocationTypeResponseType,
  GetAllLocationByTypeRequestType,
  GetAllLocationByTypeResponseType,
  GetRoleByLocationTypeRequestType,
  GetRoleByLocationTypeResponseType,
} from 'utils/apiList/constant'

import {
  setAllLocationByType,
  setLoading,
  setLocationType,
  setRoleByLocationType,
  sliceName,
} from './constantSlice'

type ConstantPayloadType<T, R> =
  | Partial<{
      params: T
      callback: (data: R) => void
    }>
  | undefined

export const fetchGetLocationType = createAsyncThunk<
  void,
  ConstantPayloadType<void, GetLocationTypeResponseType['data']>
>(`${sliceName}/fetchGetLocationType`, async (payload, { dispatch }) => {
  const { callback } = payload || {}
  try {
    dispatch(setLoading(true))

    const {
      data: { data },
    } = await getLocationTypeAPI()
    if (callback) callback(data)

    dispatch(setLocationType(data))
  } catch (error) {
    callErrorMsg(error)
  } finally {
    dispatch(setLoading(false))
  }
})

export const fetchGetAllLocationByType = createAsyncThunk<
  void,
  ConstantPayloadType<GetAllLocationByTypeRequestType, GetAllLocationByTypeResponseType['content']>
>(`${sliceName}/fetchGetAllLocationByType`, async (payload, { dispatch }) => {
  const { callback, params } = payload || {}
  try {
    dispatch(setLoading(true))

    const type = params?.type || ''
    const { data } = await getAllLocationByTypeAPI({ type })
    if (callback) callback(data.content)

    dispatch(setAllLocationByType({ key: type, data: data.content }))
  } catch (error) {
    callErrorMsg(error)
  } finally {
    dispatch(setLoading(false))
  }
})

export const fetchGetAllRoleByLocation = createAsyncThunk<
  void,
  ConstantPayloadType<GetRoleByLocationTypeRequestType, GetRoleByLocationTypeResponseType>
>(`${sliceName}/fetchGetAllRoleByLocation`, async (payload, { dispatch }) => {
  const { callback, params } = payload || {}
  try {
    dispatch(setLoading(true))

    const type = params?.type || ''
    const { data } = await getRoleByLocationTypeAPI({ type })
    if (callback) callback(data)

    dispatch(setRoleByLocationType({ key: type, data }))
  } catch (error) {
    callErrorMsg(error)
  } finally {
    dispatch(setLoading(false))
  }
})
