import axiosInstanceApiGateway from 'config/apiServiceApiGateway'
import { CloseMessageType, QueryType } from '../@types/CloseMessageConfig'

const { Get, Post, Put, Delete } = axiosInstanceApiGateway

export type GetCloseMessageResponseType = {
  close_reason: CloseMessageType[]
}

export type GetCloseMessageByIdResponseType = CloseMessageType

export type PostCloseMessagePayloadType = Omit<CloseMessageType, 'id'>

const CLOSE_MESSAGE_URL_WITH_ID = '/location/internal/:version/close-reason/:id'

export const getCloseMessage = (params: Partial<QueryType>) =>
  Get<GetCloseMessageResponseType>({
    url: '/location/internal/:version/close-reason',
    version: 'v1',
    params,
  })

export const postCloseMessage = (data: PostCloseMessagePayloadType) =>
  Post({
    url: '/location/internal/:version/close-reason',
    version: 'v1',
    data,
  })

export const putCloseMessage = (id: number, data: PostCloseMessagePayloadType) =>
  Put({
    url: CLOSE_MESSAGE_URL_WITH_ID,
    version: 'v1',
    data,
    urlParams: { id },
  })

export const deleteCloseMessage = (id: number) =>
  Delete({
    url: CLOSE_MESSAGE_URL_WITH_ID,
    version: 'v1',
    urlParams: { id },
  })

export const getCloseMessageById = (id: number) =>
  Get<GetCloseMessageByIdResponseType>({
    url: CLOSE_MESSAGE_URL_WITH_ID,
    version: 'v1',
    urlParams: { id },
  })
