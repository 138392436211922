import { memo } from 'react'
import styled from 'styled-components'

import { useAppSelector, shallowEqual } from 'store'
import { InputDropDown, Contact, CounterUp } from 'components'
import dayjs from 'dayjs'

const options = [
  {
    name: 'Detail Order',
    id: 'detail_order',
  },
  {
    name: 'Cetak Resi',
    id: 'cetak_resi',
  },
  {
    name: 'Ganti Driver',
    id: 'ganti_driver',
  },
]

function RowOnDelivery({ data, handleClickDetail, handleClickChangeDriver, handlePrintResi }) {
  const { userData } = useAppSelector(({ auth: { userData } }) => ({ userData }), shallowEqual)

  const handleClickOption = ({ name }) => {
    switch (name) {
      case 'Detail Order':
        handleClickDetail(data)
        break
      case 'Cetak Resi':
        handlePrintResi(data)
        break
      case 'Ganti Driver':
        handleClickChangeDriver(data)
        break
      default:
        break
    }
  }

  const filterOption = () => {
    if (!userData.isSuperAdmin) {
      return [...options].filter((items) => {
        return items.id !== 'ganti_driver'
      })
    } else {
      //
      return [...options]
    }
  }

  return (
    <Tr>
      <Td>
        {data.order_driver?.driver_full_name}- {data.order_driver?.driver_plate_number}{' '}
        <Contact className="ml-5 mt-4" phoneNumber={data?.order_driver?.driver_phone_number} />
      </Td>
      <Td>{dayjs(data.order_pick_up_at).format('HH:mm')}</Td>
      <Td>
        <CounterUp startFromTime={data.order_pick_up_at} />
      </Td>
      <Td>{data.order_invoice}</Td>
      <Td>
        <Wrapper>
          <InputDropDown
            noSetOption
            disabled
            value="Atur"
            listOptions={filterOption}
            //listOptions={options}
            handleClickOption={handleClickOption}
          />
        </Wrapper>
      </Td>
    </Tr>
  )
}

RowOnDelivery.defaultProps = {
  handleClickDetail: () => {},
  handlePrintResi: () => {},
  handleClickChangeDriver: () => {},
}

export default memo(RowOnDelivery)

const Tr = styled.tr`
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
  .input-container {
    width: calc(100% - 20px);
  }
`
const Td = styled.td`
  color: ${({ theme: { colors }, color = 'textSoft' }) => colors[color]};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  padding: 16px 32px;
`

const Wrapper = styled.div`
  .input-drop-down {
    max-width: 110px;
  }
`
