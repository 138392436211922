import {
  AUTH_SUPPLY_CHAIN,
  AUTH_CENTRAL_SUPERVISOR,
  AUTH_INVENTORY_CONTROLLER,
  AUTH_CAPTAIN,
  AUTH_STOCK_COUNT_AUDITOR,
  AUTH_HUB_INV_MANAGER,
  AUTH_HUB_INV_MANAGER_VIEWONLY,
  AUTH_HUB_INV_SPV,
  AUTH_HUB_INV_STAFF,
  AUTH_HUB_AREA_MANAGER,
  AUTH_HUB_AREA_MANAGER_VIEWONLY,
  AUTH_QA_MANAGER,
  AUTH_QA_SUPERVISOR,
  AUTH_QA_STAFF,
  AUTH_HO_ADJUSTMENT,
  AUTH_HO_STOCK_COUNT_AUDITOR,
  AUTH_HO_ASTRO_KITCHEN_MANAGER,
  AUTH_HO_SUPPLY_CHAIN,
  AUTH_ADJUSTMENT,
  AUTH_QA_FRESH_INBOUND,
  AUTH_QA_FRESH_OUTBOUND,
  AUTH_INBOUND_STAFF,
} from 'middleware/privateRoute'

const hubProductDetail = {
  quantity: [
    AUTH_SUPPLY_CHAIN,
    AUTH_STOCK_COUNT_AUDITOR,

    AUTH_HO_ADJUSTMENT,
    AUTH_HO_SUPPLY_CHAIN,
    AUTH_HO_STOCK_COUNT_AUDITOR,

    // Old Role
    AUTH_ADJUSTMENT,
  ],
  addProduct: [AUTH_HO_ADJUSTMENT, AUTH_SUPPLY_CHAIN],
  updateExpiryDate: [
    AUTH_SUPPLY_CHAIN,
    AUTH_CENTRAL_SUPERVISOR,
    AUTH_INVENTORY_CONTROLLER,
    AUTH_CAPTAIN,

    AUTH_QA_MANAGER,
    AUTH_QA_SUPERVISOR,
    AUTH_QA_STAFF,
    AUTH_HUB_INV_MANAGER,
    AUTH_HUB_INV_SPV,
    AUTH_HUB_INV_STAFF,

    // Old Role
    AUTH_INBOUND_STAFF,
    AUTH_QA_FRESH_INBOUND,
    AUTH_QA_FRESH_OUTBOUND,
  ],
  updateFieldInRow: [
    AUTH_SUPPLY_CHAIN,
    AUTH_INVENTORY_CONTROLLER,
    AUTH_STOCK_COUNT_AUDITOR,

    AUTH_HUB_INV_MANAGER,
    AUTH_HUB_INV_SPV,
    AUTH_HUB_INV_STAFF,
    AUTH_QA_MANAGER,
    AUTH_QA_SUPERVISOR,
    AUTH_QA_STAFF,
    AUTH_HO_STOCK_COUNT_AUDITOR,
    AUTH_HO_ASTRO_KITCHEN_MANAGER,

    // Old Role
    AUTH_INBOUND_STAFF,
    AUTH_QA_FRESH_INBOUND,
    AUTH_QA_FRESH_OUTBOUND,
  ],
  updateLostToAvailable: [
    AUTH_HUB_INV_MANAGER,
    AUTH_HUB_INV_SPV,
    AUTH_HUB_INV_STAFF,

    // Old Role
    AUTH_INVENTORY_CONTROLLER,
    AUTH_INBOUND_STAFF,
  ],
  viewDetailInSearching: [
    AUTH_HUB_AREA_MANAGER,
    AUTH_HUB_AREA_MANAGER_VIEWONLY,
    AUTH_HUB_INV_MANAGER,
    AUTH_HUB_INV_MANAGER_VIEWONLY,
    AUTH_HUB_INV_STAFF,
    AUTH_HO_STOCK_COUNT_AUDITOR,
    AUTH_HO_SUPPLY_CHAIN,

    // Old Role
    AUTH_CAPTAIN,
    AUTH_INVENTORY_CONTROLLER,
    AUTH_INBOUND_STAFF,
    AUTH_HO_STOCK_COUNT_AUDITOR,
    AUTH_SUPPLY_CHAIN,
  ],
}

export default hubProductDetail
