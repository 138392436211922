import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import { postValidateTicket } from 'features/AstroEvent/services/ticketScanner'

import { setScreenStatus, setTicketData, SLICE_NAME } from './slice'

export const validateTicket = createAsyncThunk(
  `${SLICE_NAME}/validateTicket`,
  async (_, { getState, dispatch }) => {
    const {
      ticketScanner: { form, screenStatus },
    } = getState() as StoreStateType

    try {
      const res = await postValidateTicket({ payload: { code: form.code } })
      dispatch(setTicketData(res.data.data))
      dispatch(setScreenStatus(res.data.data.status))
    } catch (err) {
      callErrorMsg(err)
      if (screenStatus !== 'READY_TO_SCAN') {
        dispatch(setScreenStatus('FAILED'))
      }
    }
  },
)
