import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getFreshPurchaseOrderDetail,
  postUpdateStatusFpo,
  PostUpdateStatusFpoResponseType,
  PostUpdateStatusFpoRequestType,
} from 'utils/apiList/freshPurchaseOrder'
import { shouldShowMenuItem } from 'redux/freshPurchaseOrder/freshPurchaseOrderThunk'
import { callErrorMsg } from 'helpers/errorMsg'
import { getValueForActionName, getMessageByActionName } from 'utils/reducerHandler'
import type { AxiosResponse } from 'axios'
import { toastSuccess } from 'utils/toast'

export const SLICE_NAME = 'freshPurchaseOrderDetail'

export const fetchFpoDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchFpoDetail`,
  async (id: number, { getState, rejectWithValue }) => {
    try {
      const rootState = getState() as StoreStateType
      const {
        userData: { authorities },
      } = rootState.auth

      const {
        data: { data },
      } = await getFreshPurchaseOrderDetail(id)
      return {
        ...data,
        items: data.items.map((itemData) => ({
          ...itemData,
          plannedQty: itemData.plannedQty / 1000,
          remainingQty: itemData.remainingQty / 1000,
          receivedQty: itemData.receivedQty ? itemData.receivedQty / 1000 : null,
          usedQty: itemData.usedQty / 1000,
        })),
        actionList: data.action.map((actionName) => ({
          value: getValueForActionName(actionName),
          name: actionName,
          message: getMessageByActionName(actionName),
          isShow: shouldShowMenuItem(actionName, authorities),
        })),
      }
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateStatusOfFpo = createAsyncThunk<PostUpdateStatusFpoResponseType, void>(
  `${SLICE_NAME}/updateStatusOfFpo`,
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      const rootState = getState() as StoreStateType
      const {
        data,
        modal: { idToCancelOrConfirm, statusToBe },
      } = rootState.freshPurchaseOrderDetail
      const response: AxiosResponse<PostUpdateStatusFpoResponseType> = await postUpdateStatusFpo(
        idToCancelOrConfirm,
        {
          status: statusToBe.toLocaleLowerCase() as PostUpdateStatusFpoRequestType['status'],
        },
      )

      dispatch(fetchFpoDetail(data?.id || 0))
      toastSuccess(response.data.message)
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
