/* eslint-disable @typescript-eslint/naming-convention */
import { lazy } from 'react'

import generateRouterDetail from 'utils/helpers/generateRouterDetail'

const Login = lazy(() => import('../pages/Login'))
const PrivateFeatureTest = lazy(() => import('../pages/PrivateFeatureTest'))

const LoginRouter: RouterFeatureInterface<'LoginRouter', '/'> = {
  name: 'LoginRouter',
  Component: Login,
  path: '/',
  routeType: 'PUBLIC_ONLY',
  allowedRoles: [],
}

const PrivateFeatureTestRouter: RouterFeatureInterface<
  'PrivateFeatureTestRouter',
  '/dashboard/private-router-test'
> = {
  name: 'PrivateFeatureTestRouter',
  Component: PrivateFeatureTest,
  path: '/dashboard/private-router-test',
  routeType: 'PRIVATE',
  allowedRoles: ['AUTH_HO_TAX'],
  isDashboard: true,
}

const AuthRouter = [LoginRouter, PrivateFeatureTestRouter]

export const AuthRouterDetail = generateRouterDetail(AuthRouter)

export default AuthRouter
