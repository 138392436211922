import axios from 'axios'
import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

const { REACT_APP_BASE_URL_API } = process.env
const baseUrl = REACT_APP_BASE_URL_API

export const postPurchaseOrderLiteQRCode = (data) =>
  axios.post(`${baseUrl}/api/product-detail/generate`, data)

export const getPurchaseOrderLiteQRCode = (id) => axios.get(`${baseUrl}/api/product-detail/${id}`)

export const getImageFile = (remote_URL) => axios.get(remote_URL, { responseType: 'blob' })

export const getProductDetailByQRCode = (qrCode) =>
  axios.get(`${baseUrl}/api/product-detail/qrCode/?qrCode=${qrCode}`)

export const postPoSubmitInbound = (poId, itemId, data) =>
  axiosInstanceApiGateway.Post({
    url: `/wms/internal/v1/purchase-order/${poId}/item/${itemId}`,
    data,
  })

export const getPoDetail = (id) =>
  axiosInstanceApiGateway.Get({ url: `/wms/internal/v1/purchase-order/${id}` })

export const getPurchaseOrderLiteV2 = (id) =>
  axiosInstanceApiGateway.Get({ url: `/wms/internal/v1/purchase-order/${id}` })

export const getPOLiteStatusList = () => axios.get(`${baseUrl}/api/inventory-status`)

export const getPOLiteInventoryStatus = (status_id) =>
  axios.get(`${baseUrl}/api/inventory-status-notes?inventory_status_id=${status_id}`)

export const getRakList_Rak = (location_id, query = '') =>
  axios.get(`${baseUrl}/api/racks/${location_id}${query}`)

export const deleteSelectedPOLiteProductDetail = (supply_order_id, supply_order_item_detail_id) =>
  axios.delete(
    `${baseUrl}/api/purchase-order/item/${supply_order_item_detail_id}/${supply_order_id}`,
  )

export const putRak = (supply_order_item_detail_id, data) =>
  axios.put(`${baseUrl}/api/purchase-order/item/${supply_order_item_detail_id}`, data)

export const putPurchaseOrderLiteStatus = (id, status, payload) =>
  axios.put(`${baseUrl}/api/purchase-order/${id}/status?status=${status}`, payload)
