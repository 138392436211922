import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { ProductVariant as Variant } from 'features/Product/@types'
import { SLICE_NAME, initialState } from './state'
import { fetchProductVariant } from './thunk'

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    setQuery: (state, { payload }: PayloadAction<Partial<Variant.QueryType>>) => {
      state.query = { ...state.query, ...payload }
    },
    setModalConfirmationDelete: (
      state,
      { payload }: PayloadAction<Variant.InitialStateType['modalConfirmationDelete']>,
    ) => {
      state.modalConfirmationDelete = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductVariant.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchProductVariant.fulfilled,
        (state, action: PayloadAction<Variant.ResponseGetProductVariantType>) => {
          state.productVariants = action.payload.data
          state.isLoading = false
          state.query.pageSize = action.payload.pagination.pageSize
          state.query.pageIndex = action.payload.pagination.pageIndex
        },
      )
      .addCase(fetchProductVariant.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const { resetState, setQuery, setModalConfirmationDelete } = slice.actions
export default slice.reducer
