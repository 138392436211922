import { toastSuccess } from "utils/toast";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getPurchaseRequestDetail,
  confirmPurchaseRequestById
} from "utils/apiList/purchaseRequest";
import { callErrorMsg } from "helpers/errorMsg";

export const SLICE_NAME = "purchaseRequestDetail";

const initialState = {
  isLoading: true,
  data: null,
  selectedEstimatedDate: "",
  estimatedShippingDate: "",
  isModalConfirmationOpen: false,
  isSubmitting: false
};

export const fetchPurchaseRequestDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchPurchaseRequestDetail`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await getPurchaseRequestDetail(id);
      return response.data;
    } catch (err) {
      callErrorMsg(err);
      return rejectWithValue(err);
    }
  }
);

export const confirmPurchaseRequest = createAsyncThunk(
  `${SLICE_NAME}/confirmPurchaseRequest`,
  async (id, { getState, dispatch, rejectWithValue }) => {
    const { estimatedShippingDate, data } = getState().purchaseRequestDetail;

    const detailList = data?.details.map((detailData) => ({
      detailId: detailData.detailId,
      price: detailData.price,
      qty: detailData.qty
    }));

    const dataToSubmit = {
      estimatedShippingDate,
      details: detailList
    };

    try {
      const response = await confirmPurchaseRequestById(id, dataToSubmit);
      dispatch(fetchPurchaseRequestDetail(id));
      toastSuccess(response.data.message);
    } catch (err) {
      callErrorMsg(err);
      return rejectWithValue(err);
    }
  }
);

export const purchaseRequestDetail = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    toggleModalConfirmation: (state, action) => {
      state.isModalConfirmationOpen = action.payload;
    },
    setEstimatedShippingDate: (state, action) => {
      const { selectedDate, dateValue } = action.payload;
      state.selectedEstimatedDate = selectedDate;
      state.estimatedShippingDate = dateValue;
    },
    setPriceInItemRow: (state, action) => {
      const { index, value } = action.payload;
      state.data.details[index].price = value;
    },
    setQtyInItemRow: (state, action) => {
      const { index, value } = action.payload;
      state.data.details[index].qty = value;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPurchaseRequestDetail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPurchaseRequestDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.data;
      })
      .addCase(fetchPurchaseRequestDetail.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(confirmPurchaseRequest.pending, (state) => {
        state.isSubmitting = true;
      })
      .addCase(confirmPurchaseRequest.fulfilled, (state) => {
        state.isSubmitting = false;
        state.isModalConfirmationOpen = false;
      })
      .addCase(confirmPurchaseRequest.rejected, (state) => {
        state.isSubmitting = false;
      });
  }
});

export const {
  resetState,
  toggleModalConfirmation,
  setEstimatedShippingDate,
  setPriceInItemRow,
  setQtyInItemRow
} = purchaseRequestDetail.actions;
export default purchaseRequestDetail.reducer;
