import { EnumTargetType } from 'pages/LoyaltyVoucher/component/Form'
import {
  SET_BENEFITS,
  SET_DETAIL_VOUCHER,
  SET_IS_LOADING,
  SET_LAST_QUERY,
  SET_MECHANISMS,
  SET_PAGINATION_DATA_VOUCHER,
  SET_PAYMENT_CHANNEL,
  SET_RESET,
  SET_TARGETS,
  SET_VOUCHER_DATA,
} from 'storeContext/actionsType/voucher'

export const initialStateVoucher = {
  isLoading: false,
  isLoadingPaymentChannel: false,
  data: [],
  paginationData: {},
  lastQuery: {},
  benefits: [],
  targets: [],
  paymentChannelList: [],
  mechanisms: [],
  detail: {},
}

export default function voucher(state = initialStateVoucher, { type, payload }) {
  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case SET_PAGINATION_DATA_VOUCHER:
      return {
        ...state,
        paginationData: payload,
      }
    case SET_VOUCHER_DATA:
      return {
        ...state,
        data: payload,
      }
    case SET_LAST_QUERY:
      return {
        ...state,
        lastQuery: payload,
      }
    case SET_MECHANISMS:
      return {
        ...state,
        mechanisms: payload,
      }
    case SET_TARGETS:
      return {
        ...state,
        targets: payload.filter((i) => i.name === EnumTargetType.globalUser),
      }
    case SET_PAYMENT_CHANNEL: {
      const newPaymentList = payload.map((paymentData) => ({
        code: paymentData.payment_channel_code,
        value: paymentData.payment_channel_id,
        image: paymentData.payment_channel_image_url,
        name: paymentData.payment_channel_name,
      }))
      return {
        ...state,
        paymentChannelList: newPaymentList,
      }
    }
    case SET_BENEFITS:
      return {
        ...state,
        benefits: payload,
      }
    case SET_DETAIL_VOUCHER:
      return {
        ...state,
        detail: payload,
      }
    case SET_RESET:
      return { ...state, detail: {} }
    default:
      return state
  }
}
