import { Get, Put } from 'config/apiServiceConfig'
import serviceApiGateway from 'config/apiServiceApiGateway'
import axios from 'axios'
import { DisbursementType } from 'features/Payment/@types/disbursement'

const baseUrl = process.env.REACT_APP_BASE_URL_API

export type GetDisbursementResponseType = {
  data: {
    disbursements: DisbursementType[]
    pagination_response: {
      page: number
      limit: number
      has_next_page: boolean
      has_prev_page: boolean
    }
  }
}

export type GetDisbursementRequestType = {
  params: {
    page: number
    limit: number
    end_date?: string
    start_date?: string
    status?: string
    customer_id?: string
    id?: string
  }
}

export type GetBankListResponseType = {
  data: {
    name: string
    target_type: string
    target_channel: string
  }[]
}

export type PutUpdateDisbursementRequestType = {
  payload: {
    disbursement_id: number
    status: string
    target_type: string
    target_channel: string
    target_identifier: string
    target_account_name: string
  }
}

export type GetDownloadDisbursementRequestType = {
  params: { id?: number; status?: string; start_date: string | null; end_date: string | null }
}

export const getDownloadDisbursment = ({ params }: GetDownloadDisbursementRequestType) =>
  axios.get(`${baseUrl}/api/payment/disbursement/download`, {
    params,
  })

export const getDisbursmentList = ({ params }: GetDisbursementRequestType) =>
  serviceApiGateway.Get<GetDisbursementResponseType, true>({
    url: `/payment/internal/:version/disbursement/list`,
    version: 'v1',
    params,
  })

export const getBankList = () =>
  Get<GetBankListResponseType>({ url: `/api/payment/disbursement/bank/list` })

export const putUpdateDisbursement = ({ payload }: PutUpdateDisbursementRequestType) =>
  Put({ url: '/api/payment/disbursement', data: payload })
