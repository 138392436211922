import { TDocumentDefinitions, TableCell } from 'pdfmake/interfaces'
import astroLogo from 'assets/images/astro_logo.png'

export const generateGRNTemplate = (pdfData: {
  vendor: string
  poDate: string
  poNumber: string
  grnDate: string
  grnBy: string
  location: string
  grnNumber: string
  itemsData: {
    skuNumber: string
    description: string
    orderQty: number
    receivedQty: number
    uom: string
    status: string
    expirationDate: string
  }[]
}): TDocumentDefinitions => {
  const { itemsData } = pdfData
  const populateItemData: TableCell[][] = itemsData.map((data) => [
    { text: data.skuNumber, style: 'tableContentLeft' },
    { text: data.description, style: 'tableContentLeft' },
    { text: data.orderQty, style: 'tableContent' },
    { text: data.receivedQty, style: 'tableContent' },
    { text: data.orderQty - data.receivedQty, style: 'tableContent' },
    { text: data.uom, style: 'tableContent' },
    { text: data.expirationDate, style: 'tableContent' },
    { text: data.status, style: 'tableContent' },
    { text: ' ', style: 'tableContent' },
  ])

  return {
    pageSize: 'A4',
    pageMargins: [20, 60, 20, 60],
    content: [
      {
        columns: [
          { image: astroLogo, width: 80 },
          { text: 'GOOD RECEIPT NOTES', style: 'header', alignment: 'right' },
        ],
      },
      {
        text: `GRN NO: ${pdfData.grnNumber}`,
        style: 'subheader',
        alignment: 'right',
      },
      {
        // Divider line under the logo and title
        canvas: [
          { type: 'line', x1: 0, y1: 0, x2: 550, y2: 0, lineWidth: 1, lineColor: '#000000' },
        ],
      },
      {
        text: '\n\n',
      },
      {
        columns: [
          { text: `Vendor : ${pdfData.vendor}`, style: 'subHeader' },
          { text: `Tanggal GRN : ${pdfData.grnDate}`, style: 'subHeader', alignment: 'right' },
        ],
      },
      {
        columns: [
          { text: `Tgl PO : ${pdfData.poDate}`, style: 'subHeader' },
          { text: `Di GRN Oleh : ${pdfData.grnBy}`, style: 'subHeader', alignment: 'right' },
        ],
      },
      {
        columns: [
          { text: `No. PO : ${pdfData.poNumber}`, style: 'subHeader' },
          { text: `WH / Hub : ${pdfData.location}`, style: 'subHeader', alignment: 'right' },
        ],
      },
      {
        text: '\n',
      },
      {
        style: 'table',
        table: {
          headerRows: 1,
          widths: ['11%', '*', '10%', '10%', '9%', '8%', '13%', '9%', '10%'],
          body: [
            [
              { text: 'NO. SKU', style: 'tableHeader' },
              { text: 'DESKRIPSI', style: 'tableHeader' },
              { text: 'JUMLAH PESANAN', style: 'tableHeader' },
              { text: 'JUMLAH DITERIMA', style: 'tableHeader' },
              { text: 'SELISIH', style: 'tableHeader' },
              { text: 'UOM', style: 'tableHeader' },
              { text: 'TANGGAL EXPIRED', style: 'tableHeader' },
              { text: 'STATUS', style: 'tableHeader' },
              { text: 'CATATAN', style: 'tableHeader' },
            ],
            ...populateItemData,
          ],
        },
        layout: {
          hLineColor: '#E0E0E0',
          vLineColor: '#E0E0E0',
          paddingTop() {
            return 10
          },
          paddingBottom() {
            return 10
          },
          hLineWidth(i: number, node) {
            return i === 0 || i === node.table.body.length ? 2 : 1
          },
          vLineWidth(i: number, node) {
            return i === 0 || i === node?.table?.widths?.length ? 2 : 1
          },
        },
      },
      {
        text: '\n\n',
      },
      {
        style: 'table',
        table: {
          widths: ['20%', '20%', '20%'],
          body: [
            [
              {
                text: 'TTD PIC',
                colSpan: 3,
                alignment: 'left',
                style: 'signatureHeader',
                border: [true, true, true, false],
              },
              {},
              {},
            ],
            [
              { text: 'Dibuat Oleh', style: 'signature' },
              { text: 'Diterima Oleh', style: 'signature' },
              { text: 'Diperiksa Oleh', style: 'signature' },
            ],
          ],
        },
        layout: {
          hLineColor: '#E0E0E0',
          vLineColor: '#E0E0E0',
          paddingTop() {
            return 10
          },
          paddingBottom() {
            return 10
          },
        },
      },
    ],
    footer(currentPage, pageCount) {
      return {
        text: `Hal ${currentPage} dari ${pageCount}`,
        alignment: 'center',
        fontSize: 9,
        color: 'gray',
        margin: [0, 10, 0, 0],
      }
    },
    styles: {
      header: {
        fontSize: 18,
        bold: true,
      },
      subheaderTitle: {
        fontSize: 12,
        bold: true,
      },
      subHeader: {
        fontSize: 10,
        color: 'black',
        margin: [0, 2, 0, 2],
      },
      tableContent: {
        fontSize: 9,
        color: 'black',
        alignment: 'center',
      },
      tableContentLeft: {
        fontSize: 9,
        color: 'black',
        alignment: 'left',
      },
      tableHeader: {
        bold: true,
        fontSize: 10,
        color: 'black',
        fillColor: '#F5F5F5',
        alignment: 'center',
      },
      table: {
        margin: [0, 5, 0, 15],
      },
      signatureHeader: {
        fontSize: 12,
        bold: true,
        margin: [0, 5, 0, 5],
      },
      signature: {
        fontSize: 10,
        alignment: 'center',
        margin: [0, 10, 0, 50],
      },
      signatureBox: {
        margin: [0, 20, 0, 0],
      },
    },
  }
}

export default generateGRNTemplate
