import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TicketScannerStateType, SetFormPayloadActionType } from 'features/AstroEvent/@types'

export const initialState: TicketScannerStateType = {
  isLoading: false,
  form: { code: '' },
  screenStatus: 'READY_TO_SCAN',
  ticketData: {
    buyer_name: '',
    code: '',
    email: '',
    identity_number: '',
    order_id: 0,
    status: '',
    verified_time: 0,
  },
}

export const SLICE_NAME = 'TicketScanner'

const ticketScanner = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setForm: (state, action: PayloadAction<SetFormPayloadActionType>) => {
      const { key, value } = action.payload

      state.form = {
        ...state.form,
        [key]: value,
      }
    },
    setScreenStatus: (state, action: PayloadAction<TicketScannerStateType['screenStatus']>) => {
      state.screenStatus = action.payload
    },
    setTicketData: (state, action: PayloadAction<TicketScannerStateType['ticketData']>) => {
      state.ticketData = action.payload
    },
  },
})

export const { reset, setForm, setScreenStatus, setTicketData } = ticketScanner.actions
export default ticketScanner.reducer
