export const PIN_PRODUCT_TYPE = {
  CATEGORY: 2,
  KEYWORD: 1,
}

export const PIN_PRODUCT_STATUS_ACTION = {
  REJECT: 1,
  APPROVE: 2,
}

export const WARNING_MINIMAL_ITEMS = 3
