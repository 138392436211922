import { memo, useContext } from 'react'
import { Box, IconButton, Typography } from '@astro-ui/components'
import { Close } from '@astronautsid/wpe-icons'
import * as S from './PopupConfirmationStyled'
import { ConfirmationPopupContext } from './usePopupConfirmation'

function PopupConfirmation() {
  const {
    isOpen,
    title,
    message,
    type,
    submitText,
    cancelText,
    options,
    handleCloseConfirmation,
    handleSubmitConfirmation,
  } = useContext(ConfirmationPopupContext)

  return (
    <>
      <S.Popup withHeader={false} open={isOpen} maxWidth="xs" sx={{ zIndex: 1301 }}>
        <S.Contianer>
          {options?.withCloseHeader ? (
            <Box display="flex" justifyContent="space-between" alignItems="center" mb="16px">
              <Typography fontWeight={800} fontSize="20px" color="textV2">
                {title}
              </Typography>
              <IconButton onClick={handleCloseConfirmation}>
                <Close />
              </IconButton>
            </Box>
          ) : (
            <S.Title variant="h1" color="black">
              {title}
            </S.Title>
          )}
          <Typography variant="body1" color="textV2" textAlign={options?.textAlign} mb="8px">
            {message}
          </Typography>
          <S.Action>
            <S.ButtonCancel
              actionPosition={options?.actionPosition}
              variant="outlined"
              onClick={handleCloseConfirmation}
            >
              {cancelText}
            </S.ButtonCancel>
            <S.ButtonSubmit
              actionPosition={options?.actionPosition}
              variant="contained"
              $popupType={type}
              onClick={handleSubmitConfirmation}
            >
              {submitText}
            </S.ButtonSubmit>
          </S.Action>
        </S.Contianer>
      </S.Popup>
    </>
  )
}

export default memo(PopupConfirmation)
