import { Icon, Typography } from '@astro-ui/components'
import styled from 'styled-components'
import { toast } from 'react-toastify'

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 16px;
  background-color: #00aa5b;
  border-radius: 6px;
  border: 1px solid #00ba6c;
`

const Success = ({ title, withClose }) => {
  return (
    <Container>
      <Icon icon="checklist" color="white" size={12} />
      <Typography variant="body2" color="white" ml="10px">
        {title}
      </Typography>
      {withClose && (
        <Typography variant="body2" color="white" fontWeight={700} ml="24px" cursor="pointer">
          Tutup
        </Typography>
      )}
    </Container>
  )
}

export const toastSuccess = (title, opts = {}) => {
  const { autoClose } = opts
  toast(<Success title={title} withClose={autoClose === false} />, opts)
}

const ContainerFailer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: ${({ withClose }) => (withClose ? 'space-between' : 'center')};
  padding: 10px 16px;
  background-color: #cc4339;
  box-shadow: 1px 8px 16px rgba(0, 0, 0, 0.08);
`

const Failed = ({ title, withClose }) => {
  return (
    <ContainerFailer withClose={withClose}>
      <Typography variant="body2" color="white">
        {title}
      </Typography>
      {withClose && (
        <Typography
          variant="body2"
          color="white"
          fontWeight={700}
          ml="24px"
          cursor="pointer"
          minWidth="38px"
        >
          Tutup
        </Typography>
      )}
    </ContainerFailer>
  )
}

export const toastFailed = (title, opts = {}) => {
  const { autoClose } = opts
  toast(<Failed title={title} withClose={autoClose === false} />, opts)
}

const FailedBig = ({ title }) => {
  return (
    <ContainerFailer>
      <Typography variant="h2" color="white">
        {title}
      </Typography>
    </ContainerFailer>
  )
}

export const toastFailedBig = (title) => {
  toast(<FailedBig title={title} />)
}

const ContainerNeutral = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 408px;
  justify-content: space-between;
  padding: 10px 16px;
  background-color: ${({ theme: { colors } }) => colors.blackToast};
  border-radius: 6px;
`

const Neutral = ({ title }) => {
  return (
    <ContainerNeutral>
      <Typography variant="body2" color="white">
        {title}
      </Typography>
      <Typography variant="body2" color="white" fontWeight={700} cursor="pointer">
        Tutup
      </Typography>
    </ContainerNeutral>
  )
}

export const toastNeutral = (title) => {
  toast(<Neutral title={title} />, { closeOnClick: true, autoClose: 10000 })
}

const ContainerInfo = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 16px;
  background-color: #2b75ee;
  box-shadow: 1px 8px 16px rgba(0, 0, 0, 0.08);
`

const Info = ({ title }) => {
  return (
    <ContainerInfo>
      <Typography variant="body2" color="white">
        {title}
      </Typography>
    </ContainerInfo>
  )
}

export const toastInfo = (title) => {
  toast(<Info title={title} />, { closeOnClick: true, autoClose: 5000 })
}
