import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const PoolingMapRolesRBAC = {
  pageID: '451d5a00298bbc44d07d439a48c636b1',
  elementID: {
    ViewPoolingMapMap: '46b760c07ad1a3f03b3dd74e3d660261',
    ViewPoolingMapPoolingOverlay: 'e2b9a3568e301408cd1091dfa9deec67',
    ManualPoolButtonPoolingOverlayUI: '65fefc8319d5bb01212b3b93294f1831',
  },
} as const

export const useRBACPoolingMapPage = createUseRBAC(PoolingMapRolesRBAC.elementID)

export type UseRBACPoolingMapPageType = ReturnType<typeof useRBACPoolingMapPage>
