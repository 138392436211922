import { RackItemInStoReviewType } from 'utils/apiList/stockOpname'

export const getChildItemId = (productId: number, rackName: string) => `${productId}-${rackName}`

type RackListType = {
  rackName: string
  countPhysicals: RackItemInStoReviewType['countPhysicals']
}[]

const mappingProductAndChildIdIntoListGapOnly = (
  rackList: RackListType,
  productId: number,
  callbackFunction: (childId: string) => void,
) => {
  rackList.forEach((rackItem) => {
    const latestVersion = rackItem.countPhysicals.length

    for (let i = latestVersion; i > 0; i -= 1) {
      const listItem = rackItem.countPhysicals.filter(
        (physicItem) => physicItem.version === i && physicItem.note !== 'no recount',
      )

      if (listItem.length) {
        const childId = getChildItemId(productId, rackItem.rackName)
        if (listItem[0].gap !== 0) callbackFunction(childId)
        break
      }
    }
  })
}

export const getChildIdsWithConditionGapOnly = <
  T extends {
    productId: number
    racks: { rackName: string; countPhysicals: RackItemInStoReviewType['countPhysicals'] }[]
  }[],
>(
  listData: T,
  productId: number,
) => {
  const resultList: { productId: number; childId: string }[] = []

  const stoGroup = listData.find((stoItem) => stoItem.productId === productId)
  if (stoGroup?.racks)
    mappingProductAndChildIdIntoListGapOnly(stoGroup?.racks, stoGroup.productId, (childId) => {
      resultList.push({
        productId: stoGroup.productId,
        childId,
      })
    })

  return resultList.map((item) => item.childId)
}

export const getListChildIdsByProductId = <
  T extends { productId: number; racks: { rackName: string }[] }[],
>(
  listData: T,
  productId: number,
) => {
  const idx = listData.findIndex((stoItem) => stoItem.productId === productId)
  return listData[idx].racks.map((item) => getChildItemId(productId, item.rackName))
}

export const getProductIdsWithConditionIsSelectAll = <
  T extends { productId: number; racks: { rackName: string }[] }[],
>(
  listData: T,
  excludedParentProductId: number[],
  selectedParentProductId: number[],
) => {
  const listProductIdToAdd: number[] = []
  let listChildIdToAdd: string[] = []
  listData.forEach((item) => {
    if (
      !excludedParentProductId.includes(item.productId) &&
      !selectedParentProductId.includes(item.productId)
    ) {
      listProductIdToAdd.push(item.productId)

      const childIds = item.racks.map((rackItem) =>
        getChildItemId(item.productId, rackItem.rackName),
      )
      listChildIdToAdd = [...listChildIdToAdd, ...childIds]
    }
  })

  return { listProductIdToAdd, listChildIdToAdd }
}

export const getParentAndChildItemIdIfGapOnly = <
  T extends {
    productId: number
    productName: string
    racks: { rackName: string; countPhysicals: RackItemInStoReviewType['countPhysicals'] }[]
  }[],
>(
  listData: T,
  selectedChildItemId: string[],
  excludedParentProductId: number[],
  selectedParentProductId: number[],
) => {
  let listChildIdToAdd: string[] = []
  let listProductIdToAdd: number[] = []
  const recentlyAddedData: { productId: number; childId: string }[] = []
  listData.forEach((stoGroup) => {
    mappingProductAndChildIdIntoListGapOnly(stoGroup.racks, stoGroup.productId, (childId) => {
      recentlyAddedData.push({
        productId: stoGroup.productId,
        childId,
      })
    })
  })

  if (recentlyAddedData.length) {
    const listChildId = recentlyAddedData.map((item) => item.childId)
    listChildIdToAdd = listChildId.filter((id) => !selectedChildItemId.includes(id))

    const listProductIdThatHasDuplicateData = recentlyAddedData.map((item) => item.productId)
    const uniqueProductIds = listProductIdThatHasDuplicateData.reduce(
      (unique: number[], number) => {
        if (!unique.includes(number)) {
          unique.push(number)
        }
        return unique
      },
      [],
    )
    listProductIdToAdd = uniqueProductIds.filter(
      (productId) =>
        !selectedParentProductId.includes(productId) &&
        !excludedParentProductId.includes(productId),
    )
  }

  return {
    listChildIdToAdd,
    listProductIdToAdd,
  }
}

export const getListAfterBeingRemoveById = <T>(listIds: T[], matchValue: T) => {
  const newListIds = [...listIds]

  const idxToRemove = listIds.findIndex((value) => value === matchValue)
  if (idxToRemove !== -1) newListIds.splice(idxToRemove, 1)
  return newListIds
}

export const removeSelectedParentAndChildIdsByProductId = <
  T extends { productId: number; racks: { rackName: string }[] }[],
>(
  productId: number,
  listData: T,
  selectedParentProductId: number[],
  selectedChildItemId: string[],
) => {
  const newParentList = getListAfterBeingRemoveById<number>(selectedParentProductId, productId)

  const stoGroup = listData.find((item) => item.productId === productId)
  const listChildIdsOfGroupToRemove = stoGroup?.racks.map((itemData) =>
    getChildItemId(stoGroup.productId, itemData.rackName),
  )

  const newChildList = selectedChildItemId.filter(
    (childId) => !listChildIdsOfGroupToRemove?.includes(childId),
  )
  return {
    parentIdList: newParentList,
    childIdList: newChildList,
  }
}
