import { memo, useEffect, useRef, useState } from 'react'
import { TextBody } from 'components'
import { formatTimeCountDownMaxHour, formatTime } from 'helpers'

const CounterUp = ({ startFromTime, durationTimeToCountUp, isHeader }) => {
  const interval = useRef()
  const [counter, setCounter] = useState(0)

  const runCountUp = () => {
    const timeNowInSecond = Math.floor(Date.now() / 1000)
    const startFromTimeInSecond = startFromTime / 1000

    if (timeNowInSecond >= startFromTimeInSecond) {
      setCounter(timeNowInSecond - startFromTimeInSecond + 1)
    }
  }

  useEffect(() => {
    interval.current = setInterval(runCountUp, 1000)
  }, [startFromTime])

  useEffect(() => {
    if (durationTimeToCountUp && counter >= durationTimeToCountUp && startFromTime !== 0) {
      clearInterval(interval.current)
    }
  }, [counter])

  return (
    <TextBody color="red" size={isHeader ? isHeader : 'normal'}>
      {formatTimeCountDownMaxHour(counter)}
    </TextBody>
  )
}

function Counter({ keyTimer, data, timer, isUpcomingOrder }) {
  const refInterval = useRef(null)
  const [counter, setCounter] = useState(0)
  useEffect(() => {
    clearInterval(refInterval.current)
    setCounter(0)
    const now = new Date().getTime()
    const differenceTime = Math.floor(now / 1000 - data[keyTimer] / 1000)
    if (differenceTime < timer) {
      setCounter(timer - differenceTime)
      refInterval.current = setInterval(startCount, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const stopCount = () => {
    clearInterval(refInterval.current)
  }

  const startCount = () => {
    setCounter((prev) => {
      if (prev === 0 && !isUpcomingOrder) {
        stopCount()
        return 0
      }
      return prev - 1
    })
  }
  return (
    <TextBody color="red" size="normal">
      {isUpcomingOrder ? formatTimeCountDownMaxHour(counter) : formatTime(counter)}
    </TextBody>
  )
}

Counter.defaultProps = {
  timer: 120,
  keyTimer: 'order_created_at',
}

export { CounterUp }

export default memo(Counter)
