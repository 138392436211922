import { Box, BoxPropsType } from '../Box'
import { Typography } from '../Typography'

export type TabPanelPropsType = {
  children?: React.ReactNode
  index: number
  value: number
} & BoxPropsType

function TabPanel(props: TabPanelPropsType) {
  const { children, value, index, ...rest } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      className="tabpanel"
      aria-labelledby={`tab-${index}`}
    >
      {value === index && (
        <Box p="24px" {...rest}>
          <Typography as="div">{children}</Typography>
        </Box>
      )}
    </div>
  )
}

export default TabPanel
