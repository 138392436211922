import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PostBulkUploadFpoSuccessResponseType } from 'utils/apiList/freshPurchaseOrder'
import {
  SLICE_NAME,
  getDownloadTemplateFreshPurchaseOrder,
  bulkUploadFreshPurchaseOrder,
} from './freshPurchaseOrderBulkUploadThunk'

interface FpoBulkUploadInterface {
  isUploading: boolean
  successCount: number
  totalCount: number
  uploadedStatus: string
  downloadExcelUrl: string
  downloadTemplateUrl: string
}

const initialState: FpoBulkUploadInterface = {
  isUploading: false,
  successCount: 0,
  totalCount: 0,
  uploadedStatus: '',
  downloadExcelUrl: '',
  downloadTemplateUrl: '',
}

interface URLCountStatusBulkUploadInterface {
  fileURL: string
  successData: number
  totalData: number
  status: string
}

const freshPurchaseOrderBulkUploadSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    setLoading: (state, action) => {
      state.isUploading = action.payload
    },
    setCountStatusAndURL: (state, action: PayloadAction<URLCountStatusBulkUploadInterface>) => {
      state.downloadExcelUrl = action.payload.fileURL
      state.successCount = action.payload.successData
      state.totalCount = action.payload.totalData
      state.uploadedStatus = action.payload.status.toLowerCase()
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDownloadTemplateFreshPurchaseOrder.fulfilled, (state, action) => {
        state.downloadTemplateUrl = action.payload.data.fileURL
      })
      .addCase(bulkUploadFreshPurchaseOrder.pending, (state) => {
        state.isUploading = true
      })
      .addCase(bulkUploadFreshPurchaseOrder.fulfilled, (state, action) => {
        state.isUploading = false
        freshPurchaseOrderBulkUploadSlice.caseReducers.setCountStatusAndURL(state, {
          type: 'setCountStatusAndURL',
          payload: action.payload.data,
        })
      })
      .addCase(bulkUploadFreshPurchaseOrder.rejected, (state, action) => {
        state.isUploading = false
        const response = action.payload as PostBulkUploadFpoSuccessResponseType
        freshPurchaseOrderBulkUploadSlice.caseReducers.setCountStatusAndURL(state, {
          type: 'setCountStatusAndURL',
          payload: response.data,
        })
      })
  },
})
export default freshPurchaseOrderBulkUploadSlice
