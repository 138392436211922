import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

const { Get, Post, Put, Delete } = axiosInstanceApiGateway

export type SLAPayloadType = {
  total_duration: number
  display_name: string
  maximum_duration: number
  name: string
  minimum_duration: number
  active: boolean
  available: boolean
}

export type GetServiceLevelListRequestType = {
  params: { limit: number; page: number; name: string }
}

export type GetServiceLevelListResponseType = {
  data: {
    sla: {
      display_name: string
      maximum_duration: number
      name: string
      id: number
      minimum_duration: number
      available: boolean
      active: boolean
      total_duration: number
    }[]
  }
}

export const getServiceLevelList = ({ params }: GetServiceLevelListRequestType) =>
  Get<GetServiceLevelListResponseType, true>({ url: `/location/internal/v1/sla`, params })

export type GetServiceLevelDetailRequestType = {
  id: string
}

export type GetServiceLevelDetailResponseType = {
  data: {
    sla: SLAPayloadType & { id: number }
  }
}

export const getServiceLevelDetail = ({ id }: GetServiceLevelDetailRequestType) =>
  Get<GetServiceLevelDetailResponseType, true>({ url: `/location/internal/v1/sla/${id}` })

export type PostServiceLevelRequestType = {
  payload: SLAPayloadType
}

export const postServiceLevel = ({ payload }: PostServiceLevelRequestType) =>
  Post({ url: `/location/internal/v1/sla`, data: payload })

export type PutServiceLevelRequestType = {
  payload: SLAPayloadType
  id: string
}

export const putServiceLevel = ({ payload, id }: PutServiceLevelRequestType) =>
  Put({ url: `/location/internal/v1/sla/${id}`, data: payload })

export type DeleteServiceLevelRequestType = {
  id: string
}

export const deleteServiceLevel = ({ id }: DeleteServiceLevelRequestType) =>
  Delete({ url: `/location/internal/v1/sla/${id}` })
