import { FormLabel as MuiFormLabel, FormLabelProps as MuiFormLabelProps } from '@mui/material'
import styled from 'styled-components'

export type FormLabelPropsType = MuiFormLabelProps

const FormLabel = styled(MuiFormLabel)<FormLabelPropsType>`
  font-size: 14px;
`

export default FormLabel
