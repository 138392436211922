import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { withLoadingReducer } from 'utils/reducerHandler'
import { GetListDriverResponseType } from 'features/Order/services/driver'
import { SLICE_NAME, fetchGetListDriver, fetchGetListLocation } from './thunk'

export type DriverMonitoringSliceType = {
  isLoading: boolean
  driverSelected: number | null
  totalActiveDriver: GetListDriverResponseType['total_active_driver']
  totalDriver: GetListDriverResponseType['total_driver']
  listDriver: GetListDriverResponseType['data']
  listLocation: {
    location_id: number
    location_name: string
    location_type: string
  }[]
  locationSelected: Nullable<{
    location_id: number
    location_name: string
    location_type: string
    location_latitude: string
    location_longitude: string
  }>
  showDetailOperasional: boolean
  showGuide: boolean
}

const initialState: DriverMonitoringSliceType = {
  isLoading: false,
  driverSelected: null,
  totalActiveDriver: 0,
  totalDriver: 0,
  listDriver: [],
  listLocation: [],
  locationSelected: null,
  showDetailOperasional: false,
  showGuide: true,
}

const driverMonitoringSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setDriverSelected: (
      state,
      action: PayloadAction<DriverMonitoringSliceType['driverSelected']>,
    ) => {
      state.driverSelected = action.payload
    },
    setSelectedDriverLocation: (
      state,
      action: PayloadAction<DriverMonitoringSliceType['locationSelected']>,
    ) => {
      state.driverSelected = null
      state.locationSelected = action.payload
    },
    setShowDetailOperasional: (state, action: PayloadAction<boolean>) => {
      state.showDetailOperasional = action.payload
    },
    setShowGuide: (state, action: PayloadAction<boolean>) => {
      state.showGuide = action.payload
    },
  },
  extraReducers: withLoadingReducer(
    (builder: ActionReducerMapBuilder<DriverMonitoringSliceType>) => {
      builder
        .addCase(fetchGetListLocation.fulfilled, (state, action) => {
          state.listLocation = action.payload.data
        })
        .addCase(fetchGetListDriver.fulfilled, (state, action) => {
          state.listDriver = action.payload.data || []
          state.totalActiveDriver = action.payload.total_active_driver
          state.totalDriver = action.payload.total_driver
        })
        .addCase(fetchGetListDriver.rejected, (state) => {
          state.listDriver = initialState.listDriver
          state.totalActiveDriver = initialState.totalActiveDriver
        })
    },
    [fetchGetListLocation],
  ),
})

export const {
  setSelectedDriverLocation,
  setShowDetailOperasional,
  setShowGuide,
  setDriverSelected,
} = driverMonitoringSlice.actions
export default driverMonitoringSlice.reducer
