import AxiosInstance from 'config/apiServiceApiGateway'
import { TermsAndConditionsDataInterface } from 'features/Enterprise/SKP/redux/tnc'

interface GetSKPResponseInterface {
  data: TermsAndConditionsDataInterface[]
  query: {
    total: number
    limit: number
    offset: number
  }
}

interface PostTnCParamsInterface {
  promotion_type: string
  tnc_description: string
  tnc_status: string
  tnc_title: string
}

interface PutEditAddTnCResponseInterface {
  code: number
  message: string
}

export const fetchTnCList = (query: string) =>
  AxiosInstance.Get<GetSKPResponseInterface, true>({
    url: `/enterprise/skp/skp-tnc?${query}`,
  })

export const putEditTnC = (params: PostTnCParamsInterface, id: number) =>
  AxiosInstance.Put<PutEditAddTnCResponseInterface, true>({
    url: `/enterprise/skp/skp-tnc/${id}`,
    data: params,
  })

export const deleteTnC = (id: number) =>
  AxiosInstance.Delete({ url: `/enterprise/skp/skp-tnc/${id}` })

export const postAddTnC = (params: PostTnCParamsInterface) =>
  AxiosInstance.Post<PutEditAddTnCResponseInterface, true>({
    url: `/enterprise/skp/skp-tnc?state=default`,
    data: params,
  })
