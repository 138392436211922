import { createAsyncThunk } from '@reduxjs/toolkit'

import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'

import { callErrorMsg } from 'helpers/errorMsg'

import { toastSuccess } from 'utils/toast'

import {
  getUomsAPI,
  getProductAssetByIDAPI,
  getProductAssetListAPI,
  postReviewAssetAPI,
} from 'features/AssetManagement/services/productList'

import {
  ProductAssetResponseInterface,
  ReviewAssetParamsType,
} from 'features/AssetManagement/@types/typeProductAssetList'

import {
  resetParamsReviewAsset,
  setIsLoading,
} from 'features/AssetManagement/redux/assetProductList/slice'

const SLICE_NAME = 'productAssetList'

export const getProductAssetLists = createAsyncThunk(
  `${SLICE_NAME}/getProductAssetLists`,
  async (_, { rejectWithValue, getState, dispatch }) => {
    const {
      productAssetList: { requestProductAssetList },
    } = getState() as StoreStateType

    dispatch(setIsLoading(true))

    try {
      const res = await getProductAssetListAPI({
        ...requestProductAssetList,
      })

      const resData: ProductAssetResponseInterface = res.data

      dispatch(setIsLoading(false))

      return resData
    } catch (error) {
      dispatch(setIsLoading(false))
      callErrorMsg(error)

      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const getAllUoms = createAsyncThunk(
  `${SLICE_NAME}/getUoms`,
  async (_, { rejectWithValue }) => {
    try {
      return await getUomsAPI({ pageSize: 30, groups: 'ITEM' })
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const getProductAssetByID = createAsyncThunk(
  `${SLICE_NAME}/fetchProductAssetByID`,
  async (id: number, { rejectWithValue }) => {
    try {
      const response = await getProductAssetByIDAPI(id)

      return response.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const postReviewAsset = createAsyncThunk(
  `${SLICE_NAME}/postReviewAsset`,
  async (params: ReviewAssetParamsType, { rejectWithValue, dispatch }) => {
    dispatch(setIsLoading(true))
    try {
      const { asset_id, name, ...newParams } = params
      const response = await postReviewAssetAPI(Number(asset_id), newParams)

      const errorMsg = response?.data?.error?.message || ''

      if (errorMsg !== '') {
        callErrorMsg(errorMsg)
        return rejectWithValue(errorMsg)
      }
      const endString = params.status === 'active' ? 'diterima' : 'ditolak'
      toastSuccess(`${name} berhasil di ${endString}`)

      dispatch(setIsLoading(false))
      dispatch(getProductAssetLists())
      dispatch(resetParamsReviewAsset())
      return true
    } catch (error) {
      dispatch(setIsLoading(false))
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)
