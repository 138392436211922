import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  LocationType,
  InitialStateType,
  SetCatalogueImagePayloadActionType,
  SetImageRulesPayloadActionType,
  SetFormsPayloadActionType,
  SetImageBannerRulesPayloadActionType,
  SetFormBannerPayloadActionType,
  CatalogueImageType,
  CatalogueBackgroundTypeEnum,
  SetBackgroundFormPayloadActionType,
  CatalogueBackgroundFormType,
  CatalogBackgroundType,
  FormBannerStateType,
} from 'features/CategoryAndPosisi/@types/catalogue'
import { fetchSegmentList } from 'features/BannerAndTicker/redux/banner'
import { SLICE_NAME, fetchCatalogueImageTypes } from './thunk'

export { fetchSegmentList }

export const initialState: InitialStateType = {
  isLoading: false,
  selectedHub: [],
  catalogueImage: {
    big: {
      newImage: null,
      newImagePreviewUrl: '',
      newImageUrl: '',
      newImageUrlLarge: '',
      newImageUrlMedium: '',
      newImageUrlSmall: '',
    },
    icon: {
      image: null,
      imagePreviewUrl: '',
      imageUrl: '',
      imageUrlLarge: '',
      imageUrlMedium: '',
      imageUrlSmall: '',
    },
    regular: {
      newImage: null,
      newImagePreviewUrl: '',
      newImageUrl: '',
      newImageUrlLarge: '',
      newImageUrlMedium: '',
      newImageUrlSmall: '',
      oldImage: null,
      oldImagePreviewUrl: '',
      oldImageUrl: '',
      oldImageUrlLarge: '',
      oldImageUrlMedium: '',
      oldImageUrlSmall: '',
    },
    vertical: {
      newImage: null,
      newImagePreviewUrl: '',
      newImageUrl: '',
      newImageUrlLarge: '',
      newImageUrlMedium: '',
      newImageUrlSmall: '',
    },
    subCatalogue: {
      image: null,
      imagePreviewUrl: '',
      imageUrl: '',
      imageUrlLarge: '',
      imageUrlMedium: '',
      imageUrlSmall: '',
    },
  },
  imageRules: {
    extensions: [],
    height: { max: 0, min: 0 },
    maxFileSize: 0,
    typeId: 'homepage-catalog',
    width: { max: 0, min: 0 },
  },
  imageBigRules: {
    extensions: [],
    height: { max: 0, min: 0 },
    maxFileSize: 0,
    typeId: 'homepage-catalog-big',
    width: { max: 0, min: 0 },
  },
  imageVerticalRules: {
    extensions: [],
    height: { max: 0, min: 0 },
    maxFileSize: 0,
    typeId: 'homepage-catalog-vertical',
    width: { max: 0, min: 0 },
  },
  imageBannerRules: {
    newBanner: {
      extensions: [],
      height: { max: 0, min: 0 },
      maxFileSize: 0,
      typeId: 'homepage-banner',
      width: { max: 0, min: 0 },
    },
    oldBanner: {
      extensions: [],
      height: { max: 0, min: 0 },
      maxFileSize: 0,
      typeId: 'banner-images',
      width: { max: 0, min: 0 },
    },
  },
  forms: { categoryLabel: '', segment: [] },
  formBanner: {
    bannerCategory: null,
    bannerImage: '',
    bannerLink: '',
    bannerTitle: '',
    bannerFile: null,
    destinationBanner: 'url',
    endDate: '',
    endTime: '',
    startDate: '',
    startTime: '',
  },
  catalogueTypes: [],
  selectedCatalogType: null,
  imageBackgroundRules: {
    extensions: [],
    height: { max: 0, min: 0 },
    maxFileSize: 0,
    typeId: 'homepage-catalog-background',
    width: { max: 0, min: 0 },
  },
  background: {
    isBackgroundDialogOpen: false,
    form: {
      title: '',
      bg_image_file: null,
      bg_image_preview: '',
      bg_type: CatalogueBackgroundTypeEnum.PLAIN,
    },
    detail: {},
  },
  segmentOptions: [],
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    resetCatalogueImage: (state) => {
      state.catalogueImage = initialState.catalogueImage
    },
    resetForms: (state) => {
      state.forms = initialState.forms
    },
    resetFormBanner: (state, { payload }: PayloadAction<FormBannerStateType | undefined>) => {
      state.formBanner = payload ? { ...payload } : initialState.formBanner
    },
    setSelectedHub: (state, { payload }: PayloadAction<LocationType[]>) => {
      state.selectedHub = [...payload]
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setImageRules: (state, action: PayloadAction<SetImageRulesPayloadActionType>) => {
      const { value } = action.payload

      state.imageRules = value
    },
    setImageBigRules: (state, action: PayloadAction<SetImageRulesPayloadActionType>) => {
      const { value } = action.payload

      state.imageBigRules = value
    },
    setImageVerticalRules: (state, action: PayloadAction<SetImageRulesPayloadActionType>) => {
      const { value } = action.payload

      state.imageVerticalRules = value
    },
    setImageBannerRules: (state, action: PayloadAction<SetImageBannerRulesPayloadActionType>) => {
      const { value, name } = action.payload
      state.imageBannerRules[name] = value
    },
    setCatalogueImage: (
      state,
      { payload: { name, value } }: PayloadAction<SetCatalogueImagePayloadActionType>,
    ) => {
      state.catalogueImage = { ...state.catalogueImage, [name]: value }
    },
    setForms: (state, { payload: { name, value } }: PayloadAction<SetFormsPayloadActionType>) => {
      state.forms[name] = value as never
    },
    setFormBanner: (
      state,
      { payload: { name, value } }: PayloadAction<SetFormBannerPayloadActionType>,
    ) => {
      state.formBanner[name] = value as never
    },
    setSelectedCatalogueType: (state, { payload }: PayloadAction<Nullable<CatalogueImageType>>) => {
      state.selectedCatalogType = payload
    },

    setBackgroundDialog: (state, { payload }: PayloadAction<boolean>) => {
      state.background.isBackgroundDialogOpen = payload
    },
    setBackgroundForm: (state, { payload }: PayloadAction<SetBackgroundFormPayloadActionType>) => {
      const { key, value } = payload

      if (key) {
        state.background.form[key] = value as never
      } else {
        state.background.form = { ...(value as CatalogueBackgroundFormType) }
      }
    },
    setImageBackgroundRules: (state, action: PayloadAction<SetImageRulesPayloadActionType>) => {
      const { value } = action.payload

      state.imageBackgroundRules = value
    },
    setBackgroundDetail: (
      state,
      { payload }: PayloadAction<CatalogBackgroundType | ObjectNullType>,
    ) => {
      state.background.detail = { ...payload }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCatalogueImageTypes.fulfilled, (state, { payload }) => {
        state.catalogueTypes = [...payload]
      })
      .addCase(fetchSegmentList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchSegmentList.rejected, (state) => {
        state.isLoading = true
      })
      .addCase(fetchSegmentList.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.segmentOptions = payload
      })
  },
})

export const {
  resetState,
  setCatalogueImage,
  setSelectedHub,
  setImageRules,
  resetCatalogueImage,
  setImageBigRules,
  setForms,
  resetForms,
  setImageBannerRules,
  setFormBanner,
  resetFormBanner,
  setIsLoading,
  setSelectedCatalogueType,
  setImageVerticalRules,
  setBackgroundForm,
  setBackgroundDialog,
  setImageBackgroundRules,
  setBackgroundDetail,
} = slice.actions
export default slice.reducer
