import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { productVendor } from 'config/routes'
import { callErrorMsg } from 'helpers/errorMsg'
import { queryParamsURL, serializeQueryToURL } from 'utils/queryParamsURL'
import {
  deleteProductCatalog,
  getProductCatalogs,
  patchPrimaryVendor,
} from 'utils/apiList/productVendor'
import { toastSuccess } from 'utils/toast'
import { withLoadingReducer } from 'utils/reducerHandler'
import { getProduct } from 'utils/api'

export const actSetQuery = (query) => (dispatch) => {
  dispatch(setQuery(query))
  dispatch(fetchGetProductVendor(query))
}

export const fetchGetProductDetail = createAsyncThunk(
  'productVendorSlice/fetchGetProductDetail',
  async (_, { rejectWithValue }) => {
    try {
      const { productId } = queryParamsURL.getParams(productVendor)

      const response = await getProduct(productId)

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue()
    }
  },
)

export const fetchGetProductVendor = createAsyncThunk(
  'productVendorSlice/fetchGetProductVendor',
  async (params, { rejectWithValue }) => {
    try {
      const qs = serializeQueryToURL(params)
      const { productId } = queryParamsURL.getParams(productVendor)

      const response = await getProductCatalogs(productId, qs)

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue()
    }
  },
)

export const fetchPatchPrimaryVendor = createAsyncThunk(
  'productVendorSlice/fetchPatchPrimaryVendor',
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const { productId } = queryParamsURL.getParams(productVendor)
      const { selectedVendor } = getState().productVendor

      const response = await patchPrimaryVendor(productId, selectedVendor)

      dispatch(actSetQuery())
      toastSuccess(response.data.message)

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue()
    }
  },
)

export const fetchDeleteProductVendor = createAsyncThunk(
  'productVendorSlice/fetchDeleteProductVendor',
  async (catalogId, { rejectWithValue, dispatch }) => {
    try {
      const { productId } = queryParamsURL.getParams(productVendor)

      const response = await deleteProductCatalog(productId, catalogId)

      dispatch(actSetQuery())
      toastSuccess(response.data.message)

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue()
    }
  },
)

const initialState = {
  isLoading: false,
  isEditPreference: false,
  product: null,
  query: {
    pageIndex: 1,
  },
  pagination: {},
  data: [],
  selectedVendor: null,
}

const productVendorSlice = createSlice({
  name: 'productVendorSlice',
  initialState,
  reducers: {
    setQuery: (state, action) => {
      state.query = {
        ...state.query,
        ...action.payload,
      }
    },
    setIsEditPreference: (state, action) => {
      state.isEditPreference = action.payload
    },
    setSelectedVendor: (state, action) => {
      state.selectedVendor = action.payload
    },
  },
  extraReducers: withLoadingReducer(
    (builder) => {
      builder
        .addCase(fetchGetProductVendor.fulfilled, (state, action) => {
          const {
            data,
            pagination: { pageSize, pageIndex, totalPages, totalElements, numberOfElements },
          } = action.payload

          state.data = data
          state.pagination = {
            pageSize: pageSize,
            currentItem: numberOfElements,
            currentPage: pageIndex,
            totalData: totalElements,
            last: pageIndex === totalPages,
          }
          state.selectedVendor = data[0]?.catalogId || null
        })
        // =====
        .addCase(fetchGetProductDetail.fulfilled, (state, action) => {
          state.product = action.payload
        })
        // =====
        .addCase(fetchPatchPrimaryVendor.fulfilled, (state) => {
          state.isEditPreference = false
        })
    },
    [
      fetchGetProductDetail,
      fetchGetProductVendor,
      fetchPatchPrimaryVendor,
      fetchDeleteProductVendor,
    ],
  ),
})

export const { setQuery, setIsEditPreference, setSelectedVendor } = productVendorSlice.actions
export default productVendorSlice.reducer
