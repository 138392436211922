import {
  SET_IS_LOADING,
  SET_UOM,
  SET_LAST_PARAMS,
  SET_PAGINATION_DATA,
  SET_GROUPS
} from "storeContext/actionsType/uom";

export const initialStateUom = {
  isLoading: false,
  lastParams: {},
  paginationData: {},
  uoms: [],
  groups: []
};

export default function uom(state = initialStateUom, { type, payload }) {
  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_UOM:
      return {
        ...state,
        uoms: payload
      };
    case SET_LAST_PARAMS:
      return {
        ...state,
        lastParams: payload
      };
    case SET_PAGINATION_DATA:
      return {
        ...state,
        paginationData: payload
      };
    case SET_GROUPS:
      return {
        ...state,
        groups: payload
      };
    default:
      return state;
  }
}
