import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  FPOIDetailResponseType,
  FPOIConversionResponseType,
  LocationsListResponseType,
} from 'utils/apiList/freshPurchaseOrderInbound'
import {
  fetchFPOIDetails,
  fetchFPOIConversion,
  fetchLocationList,
  fetchUOMList,
  fetchFPOIProcessAll,
  fetchFPOIProcessPartial,
} from './freshPurchaseOrderInboundTrimmingPackingThunk'

const SLICE_NAME = 'freshPurchaseInboundTrimmingPacking'

export interface PrintQRFormStateType {
  printQTY: number
  expiryDate: number
  locationId: number
  indexItem: number
  product: {
    name: string
    productId: number
  }
}

export interface PrintBarcodeFormStateType {
  indexItem: number
  printQTY: number
  expiryDate: number | null
  product: {
    name: string
    sku: string | number
  }
}

export interface UOMListType {
  name: string
}
export interface FPOInboundTrimmingPackingStateType {
  isLoading: boolean
  triggerRemainingValue: boolean
  triggerSaveToLocalStorage: boolean
  needToReload: number
  isShowPopupConfirmation: boolean
  isShowPopupPrintQR: boolean
  isShowPopupPrintBarcode: boolean
  processTypePopupConfirmatuin: string
  query: {
    sku: string
  }
  fPOIDetail: FPOIDetailResponseType
  fPOIConversion: FPOIConversionResponseType[]
  locationList: LocationsListResponseType[]
  selectedBadStockLocation: LocationsListResponseType
  uOMList: UOMListType[]
  printQRForm: PrintQRFormStateType
  printBarcodeForm: PrintBarcodeFormStateType
}

export const initialState: FPOInboundTrimmingPackingStateType = {
  isLoading: true,
  triggerRemainingValue: false,
  triggerSaveToLocalStorage: false,
  needToReload: 1,
  isShowPopupConfirmation: false,
  isShowPopupPrintQR: false,
  isShowPopupPrintBarcode: false,
  processTypePopupConfirmatuin: '',
  query: {
    sku: '',
  },
  fPOIDetail: {
    id: 0,
    inboundNumber: '',
    fpoNumber: '',
    date: 0,
    vendor: {
      vendorId: 0,
      vendorName: '',
    },
    location: {
      locationId: 0,
      locationName: '',
      locationType: '',
    },
    badStockLocation: {
      locationId: 0,
      locationName: '',
      locationType: '',
    },
    totalItem: 0,
    items: [],
    status: '',
    createdAt: 0,
    createdBy: '',
    fpoCreatedAt: 0,
    updatedAt: 0,
    updatedBy: '',
  },
  fPOIConversion: [],
  locationList: [],
  selectedBadStockLocation: {
    locationId: 0,
    locationName: '',
    locationType: '',
    name: '',
  },
  uOMList: [],
  printQRForm: {
    printQTY: 0,
    expiryDate: 0,
    locationId: 0,
    indexItem: 0,
    product: {
      productId: 0,
      name: '',
    },
  },
  printBarcodeForm: {
    indexItem: 0,
    printQTY: 0,
    expiryDate: null,
    product: {
      name: '',
      sku: '',
    },
  },
}

const freshPurchaseOrderInboundTrimmingPacking = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setQuery: (
      state,
      action: PayloadAction<{
        fieldName: keyof FPOInboundTrimmingPackingStateType['query']
        value: string | number
      }>,
    ) => {
      const { fieldName, value } = action.payload
      state.query[fieldName] = value as never
    },
    setIsShowFPOITrimmingPacking: (
      state,
      action: PayloadAction<{
        processType: string
        isShow: FPOInboundTrimmingPackingStateType['isShowPopupConfirmation']
      }>,
    ) => {
      const { isShow, processType } = action.payload
      state.isShowPopupConfirmation = isShow
      state.processTypePopupConfirmatuin = processType
      const newItems = state.fPOIDetail.items.map((el: FPOIDetailResponseType['items'][0]) => {
        const { usedQty, recycleQty, disposalQty, returQty, overgramQty, beforeProcessedQty } = el

        const usedValue = +usedQty + +recycleQty + +disposalQty + +returQty + +overgramQty
        const usedValueForOvergram = +usedQty + +recycleQty + +disposalQty + +returQty

        const overgramValue =
          processType === 'all'
            ? {
                defaultOvergram:
                  +beforeProcessedQty > +usedValue || +beforeProcessedQty === +usedValue
                    ? +beforeProcessedQty - +usedValueForOvergram
                    : 0,
                sisaQty: 0,
              }
            : {}
        const sisaValue =
          processType === 'partial'
            ? {
                defaultOvergram: 0,
                sisaQty: +beforeProcessedQty > +usedValue ? +beforeProcessedQty - +usedValue : 0,
              }
            : {}
        return { ...el, ...overgramValue, ...sisaValue }
      })

      state.fPOIDetail.items = newItems
    },
    setIsShowFPOIPrintQR: (
      state,
      action: PayloadAction<{
        isShow: FPOInboundTrimmingPackingStateType['isShowPopupPrintQR']
      }>,
    ) => {
      const { isShow } = action.payload
      state.isShowPopupPrintQR = isShow
    },
    setIsShowFPOIPrintBarcode: (
      state,
      action: PayloadAction<{
        isShow: FPOInboundTrimmingPackingStateType['isShowPopupPrintBarcode']
      }>,
    ) => {
      const { isShow } = action.payload
      state.isShowPopupPrintBarcode = isShow
    },
    setSelectedBadStockLocation: (state, action: PayloadAction<LocationsListResponseType>) => {
      state.selectedBadStockLocation = action.payload
    },
    setFPOIDetailItems: (
      state,
      action: PayloadAction<{
        fieldName: keyof FPOIDetailResponseType['items'][0]
        value: string | number | boolean | FPOIDetailResponseType['items'][0]['product']
        index: number
      }>,
    ) => {
      const { fieldName, value, index } = action.payload
      state.fPOIDetail.items[index][fieldName] = value as never
    },
    setFPOIDetailItemNumber: (
      state,
      action: PayloadAction<{
        fieldName: keyof FPOIDetailResponseType['items'][number]
        value: string | number | boolean | FPOIDetailResponseType['items'][0]['product']
        index: number
      }>,
    ) => {
      const { fieldName, value, index } = action.payload
      const { weight } = state.fPOIDetail.items[index].product
      state.triggerSaveToLocalStorage = true

      if (fieldName === 'gradeAQty') {
        state.fPOIDetail.items[index][fieldName] = +value as never
        state.fPOIDetail.items[index].usedQty = ((+value * weight) / 1000) as never
      } else {
        state.fPOIDetail.items[index][fieldName] = value as never
      }
    },
    unSelectAllFPODetailItems: (state) => {
      const { items } = state.fPOIDetail
      state.fPOIDetail.items = items.map((el) => ({
        ...el,
        isSelected: false,
      }))
    },
    selectAllFPODetailItems: (state) => {
      const { items } = state.fPOIDetail

      const modifiedItems = items.filter((el) => +el.remainingQty)

      state.fPOIDetail.items = modifiedItems.map((el) => ({
        ...el,
        isSelected: !!el.actualPrice,
      }))
    },
    setPrintQRForm: (
      state,
      action: PayloadAction<{
        fieldName: keyof PrintQRFormStateType
        value: string | number
      }>,
    ) => {
      const { fieldName, value } = action.payload
      state.printQRForm[fieldName] = value as never
    },
    setPrintQRasObj: (state, action: PayloadAction<PrintQRFormStateType>) => {
      state.printQRForm = { ...state.printQRForm, ...action.payload }
    },
    setPrintBarcodeForm: (
      state,
      action: PayloadAction<{
        fieldName: keyof PrintBarcodeFormStateType
        value: string | number
      }>,
    ) => {
      const { fieldName, value } = action.payload
      state.printBarcodeForm[fieldName] = value as never
    },
    setPrintBarcodeFormAsObj: (state, action: PayloadAction<PrintBarcodeFormStateType>) => {
      state.printBarcodeForm = { ...state.printBarcodeForm, ...action.payload }
    },
    setTriggerSaveToLocalStorage: (state, action: PayloadAction<boolean>) => {
      state.triggerSaveToLocalStorage = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFPOIDetails.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFPOIDetails.fulfilled, (state, action) => {
        const { payload } = action
        state.isLoading = false

        state.fPOIDetail = payload
        state.triggerRemainingValue = true

        state.selectedBadStockLocation = {
          ...payload.badStockLocation,
          name: payload.badStockLocation.locationName,
        }
      })
      .addCase(fetchFPOIDetails.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchLocationList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchLocationList.fulfilled,
        (
          state,
          action: PayloadAction<
            {
              location_id: number
              location_name: string
              location_type: string
            }[]
          >,
        ) => {
          state.isLoading = false

          const modifiedPayload = action.payload.map((el) => ({
            locationId: el.location_id,
            locationName: el.location_name,
            locationType: el.location_type,
            name: el.location_name,
          }))
          state.locationList = modifiedPayload
        },
      )
      .addCase(fetchLocationList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchFPOIConversion.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchFPOIConversion.fulfilled,
        (state, action: PayloadAction<FPOIConversionResponseType[]>) => {
          state.isLoading = false
          state.fPOIConversion = action.payload
        },
      )
      .addCase(fetchUOMList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchUOMList.fulfilled, (state, action) => {
        state.isLoading = false

        const modifiedData = action.payload.map((el) => ({ name: el }))
        state.uOMList = modifiedData
      })
      .addCase(fetchUOMList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchFPOIProcessAll.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFPOIProcessAll.fulfilled, (state) => {
        state.isLoading = false
        state.needToReload += 2
        state.isShowPopupConfirmation = false
        state.processTypePopupConfirmatuin = ''
      })
      .addCase(fetchFPOIProcessAll.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchFPOIProcessPartial.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFPOIProcessPartial.fulfilled, (state) => {
        state.isLoading = false
        state.needToReload += 1
      })
      .addCase(fetchFPOIProcessPartial.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const {
  reset,
  setQuery,
  setIsShowFPOITrimmingPacking,
  setSelectedBadStockLocation,
  setFPOIDetailItems,
  unSelectAllFPODetailItems,
  selectAllFPODetailItems,
  setPrintQRForm,
  setPrintQRasObj,
  setIsShowFPOIPrintQR,
  setIsShowFPOIPrintBarcode,
  setPrintBarcodeFormAsObj,
  setPrintBarcodeForm,
  setFPOIDetailItemNumber,
  setTriggerSaveToLocalStorage,
} = freshPurchaseOrderInboundTrimmingPacking.actions
export default freshPurchaseOrderInboundTrimmingPacking.reducer
