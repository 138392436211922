import { TextBody, Button, SystemIcon } from 'components'
import { SModal } from 'components/atoms/Layouting'

const ModalPopup = ({
  title = 'Confirmation',
  onCloseModal,
  width = '88%',
  children,
  isShowCloseBtn = true,
}) => {
  return (
    <SModal.Container isShow>
      <SModal.Content>
        <SModal.Main width={width}>
          <SModal.Header>
            <TextBody weight="bold">{title}</TextBody>
            {isShowCloseBtn && (
              <Button
                variant="wrapper"
                fontSize="smaller"
                className="icon-close"
                onClick={onCloseModal}
              >
                <SystemIcon iconName="plus" colorIcon="text" />
              </Button>
            )}
          </SModal.Header>
          {children}
        </SModal.Main>
      </SModal.Content>
    </SModal.Container>
  )
}
export default ModalPopup
