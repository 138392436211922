import {
  DialogActions as MuiDialogActions,
  DialogActionsProps as MuiDialogActionsProps,
} from '@mui/material'
import styled from 'styled-components'

export type DialogActionsPropsType = MuiDialogActionsProps

const DialogActions = styled(MuiDialogActions)<DialogActionsPropsType>``

export default DialogActions
