import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const ServiceLevelRBAC = {
  pageID: '0d6806e71f83373aff098b7c355b93e0',
  elementID: {
    ViewServiceLevel: '0db90c184b107b3339ce128ea72ff862',
    UpdateServiceLevel: '2d23db0338b88ad19344c3fd9671fa72',
    CreateServiceLevel: '8c791d390a3a5c69dbe523a797183a85',
  },
} as const

export const useServiceLevelRBACPage = createUseRBAC(ServiceLevelRBAC.elementID)

export type UseServiceLevelRBACPageType = ReturnType<typeof useServiceLevelRBACPage>
