import { Get, Delete, Put } from 'config/apiServiceConfig'
import {
  GetCampaignListResponseType,
  GetCampaignListRequestType,
  GetCampaignTypeListResponseType,
  DeleteCampaignResponseType,
  DeleteCampaignRequestType,
  PutAdjustmentCampaignRequestType,
  PutAdjustmentCampaignResponseType,
  PutStatusCampaignRequestType,
  PutStatusCampaignResponseType,
} from 'features/CampaignAndDiscount/@types'

export const getCampaignList = ({ params }: GetCampaignListRequestType) =>
  Get<GetCampaignListResponseType>({
    url: '/api/campaigns',
    params,
  })

export const getCampaignTypeList = () =>
  Get<GetCampaignTypeListResponseType>({ url: `/api/campaign/type` })

export const deleteCampaign = ({ payload }: DeleteCampaignRequestType) =>
  Delete<DeleteCampaignResponseType>({
    url: `/api/campaign`,
    config: { data: payload },
  })

export const putAdjustmentCampaign = ({ campaignId, payload }: PutAdjustmentCampaignRequestType) =>
  Put<PutAdjustmentCampaignResponseType>({
    url: `/api/campaign/${campaignId}/edit-inventory`,
    data: payload,
  })

export const putStatusCampaign = ({ campaignId, payload }: PutStatusCampaignRequestType) =>
  Put<PutStatusCampaignResponseType>({ url: `/api/campaign/${campaignId}/active`, data: payload })
