import { Get, Put } from 'config/apiServiceConfig'

export type GetInventoryLocationAPIRequestType = {
  [key: string]: string | number
}

export type GetInventoryLocationAPIResponseType = {
  data: {
    cutoffDate: number
    items: {
      productDetailId: number
      productId: number
      productName: string
      productSkuNumber: string
      stock: number
      statusId: number
      statusName: string
      statusNotesId: number
      statusNotesName: string
      lostPeriodFrom: number
      lostPeriodTo: number
      updatedExpiryDate: boolean
      requiredExpiryDate: boolean
      maxAdjustQty: number
      active: boolean
    }[]
    lostPeriodFrom: number
    lostPeriodTo: number
  }
  pagination: {
    pageSize: number
    pageIndex: number
    numberOfElements: number
  }
}

export type GetCheckPackageIdRequestType = {
  packageLabel: Nullable<string>
  isTransfer: boolean
}

export type GetCheckPackageIdResponseType = {
  data: {
    createdDateTime: number
    inputMethod: Nullable<null>
    packageId: number
    packageLabel: string
    rack: Nullable<{
      rackId: number
      rackName: string
      maxTransferQty: number
    }>
  }
}

export type GetInventoryStatusResponseType = {
  data: {
    status: {
      id: number
      displayName: string
      isNeedNotes: boolean
      isProblemStock: boolean
    }[]
  }
}

export type GetInventoryStatusNotesResponseType = {
  data: {
    notes: {
      id: number
      displayName: string
      statusId: number
    }[]
  }
}

export type PutTransferProductDetailRequestType = {
  id: number
  payload: {
    productId: number
    locationId: number
    stock: string
    expiryDate?: number
    rackId?: number
    rackName?: string
    statusId?: number
    statusNotesId?: number
    packageId?: number
  }
}

export type PutTransferProductDetailResponseType = {
  message: string
}

export type GetHubLocationsResponseType = {
  data: {
    is_new_ui: boolean
    is_upcoming_enable: boolean
    location_id: number
    location_name: string
    location_type: string
  }[]
}

export type GetWarehouseLocationsResponseType = {
  location_id: number
  location_name: string
  location_type: string
}[]

export const getInventoryLocationAPI = (params: GetInventoryLocationAPIRequestType) =>
  Get<GetInventoryLocationAPIResponseType>({
    url: `/api/ims/v2/inventories/detail-in-searching`,
    params,
  })

export const getCheckPackageIdAPI = (params: GetCheckPackageIdRequestType) =>
  Get<GetCheckPackageIdResponseType>({
    url: `/api/package-id/scan`,
    params,
  })

export const getInventoryStatusAPI = () =>
  Get<GetInventoryStatusResponseType>({
    url: `/api/inventory-status`,
  })

export const getInventoryStatusNotesAPI = () =>
  Get<GetInventoryStatusNotesResponseType>({
    url: `/api/inventory-status-notes`,
  })

export const putTransferProductDetailAPI = ({ id, payload }: PutTransferProductDetailRequestType) =>
  Put<PutTransferProductDetailResponseType>({
    url: `/api/ims/v2/inventories/detail/${id}/transfer`,
    data: payload,
  })

export const getHubLocationsAPI = () =>
  Get<GetHubLocationsResponseType>({
    url: '/api/locations/order',
  })

export const getWarehouseLocationsAPI = () =>
  Get<GetWarehouseLocationsResponseType>({
    url: '/api/locations/warehouse',
  })
