/* eslint-disable no-param-reassign */
/* eslint-disable sonarjs/no-identical-functions */
import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  ActionReducerMapBuilder,
} from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import {
  GetSupplyOrderGrandProductV2ReqType,
  GetSupplyOrderGrandProductV2ResType,
  GetSupplyOrderGrandPackingV2ResType,
  GetSupplyOrderGrandPackingKoliResType,
  GetSupplyOrderGrandPackingKoliReqType,
  PostSupplyOrderGrandValidateReqType,
  DeleteSupplyOrderGrandItemDetailReqType,
  GetStatusResType,
  GetNotessResType,
  GetSupplyOrderGrandKoliLabelResType,
  PostSupplyOrderGrandPackingItemReqType,
  GetProgressPickingResType,
  getProgressPicking,
  getSupplyOrderGrandProductV2,
  getSupplyOrderGrandPackingV2,
  getSupplyOrderGrandPackingKoli,
  postSupplyOrderGrandValidate,
  deleteSupplyOrderGrandItemDetail,
  postSupplyOrderGrandPackingItem,
  postSupplyOrderGrandPackingPublish,
  getSupplyOrderGrandKoliLabel,
  getStatus,
  getNotes,
  PutAssignCheckerSORequestType,
  putAssignCheckerSO,
  PutAssignPackerSORequestType,
  putAssignPackerSO,
  getSupplyOrderGrandPackingProduct,
  GetSupplyOrderGrandPackingProductV2ResType,
  postSupplyOrderPackingItem,
  PostSupplyOrderPackingItemReqType,
  getSupplyOrderGrandPackingProductV3,
  PostSupplyOrderPackingFreshResponseType,
  PostSupplyOrderPackingFreshRequestType,
  postSupplyOrderPackingFresh,
  deleteSupplyOrderItemDetailV2,
  getScanManualReason,
} from 'utils/apiList/supplyOrderCheckQty'

import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'
import { callErrorMsg } from 'helpers/errorMsg'
import { withLoadingReducer } from 'utils/reducerHandler'
import { toastFailed, toastNeutral, toastSuccess } from 'utils/toast'
import { getHubFeature, getStaffManagement } from 'utils/api'
import { AUTH_OUTBOUND_CHECKER, AUTH_OUTBOUND_PACKER } from 'middleware/privateRoute'
import {
  setPackingRecordingOnCheckQty,
  setModalConfirmation,
} from 'features/SupplyOrder/redux/packingRecording/slice'
import { actStartStopRecording } from 'features/SupplyOrder/redux/packingRecording/thunk'
import { AppDispatchType, RootStateType } from 'store'
import { getFeatureFlag } from '../utils/api'

export type StatusType = 'AVAILABLE' | 'LOST' | 'BAD_STOCK'

export interface SupplyOrderItemInterface {
  productId: number
  productName: string
  productSku: string
  productUom: string
  requestQuantity: number
  supplyOrderItemDetails: SupplyOrderItemDetailsInterface[]
  totalActualQuantity: number
  expDate?: Date
  koli?: string
}

export interface SupplyOrderItemDetailsInterface {
  supplyOrderItemDetailId: number
  actualQuantity: number
  expDate: number
  koliNumber: string
  statusCode: StatusType
  statusName: string
}

interface MapDataInterface {
  [key: number | string]: string | number | boolean
}

export interface SelectInterface {
  value: string | number
  name: string
  statusId?: number
}

export interface SupplyOrderItemRestInterface {
  soItemId?: number
  index: number
  childIndex?: number
  key: 'totalAvailable' | 'status' | 'statusNote' | 'expiryDate' | 'originalExpiryDate'
  value: number | null
}

export interface MapSelectedKoliInterface {
  action: 'add' | 'remove'
  value: string
}

export type BarcodeFocusType =
  | 'barcodeKoli'
  | 'barcodeProduct'
  | 'barcodePackingLine'
  | 'barcodeAsset'
export type FormStateInputType = {
  name: BarcodeFocusType | 'totalAvailable' | 'expDate' | 'oldBarcodeKoli'
  value: string
}

export interface SupplyOrderItemFormInterface {
  supplyOrderItemId?: number
  originalExpiryDate?: number | null
  expiryDate?: number | null
  newStatus?: {
    supplyOrderItemId?: number
    originalExpiryDate?: number | null
    expiryDate: number | null
    barcodeKoli: string
    totalRestQty: number
    totalAvailable: number
    status: number | null
    statusNote: number | null
  }[]
  totalRestQty?: number
  barcodeKoli: string
  totalAvailable: number
  status: number | null
  statusNote: number | null
  productName?: string
  productType: string
}

type NewStatusType = NonNullable<SupplyOrderItemFormInterface['newStatus']>

export interface SupplyOrderPayloadRestItemInterface {
  quantity: number
  inventoryStatusId: number | string | undefined
  inventoryStatusNoteId: number | string | undefined | null
  koliId?: number
  manualExpiryDate?: number
}

export type AssetBarcodeDataType = {
  assetId: number
  assetCode: string
  assetName: string
  incomingQty: number
  actualQty: number
  requestQty: number
}

export type SupplyOrderCheckQtyStateType = {
  id: string
  isLoading: boolean
  keyword: string
  staggedRemoveDetailId: number
  isShowConfirmationRemove: boolean
  packingRecording: {
    isFetchingApiRecording: boolean
    isStartRecording: boolean
  }
  formState: {
    checker: SelectInterface | null
    packers: {
      [key: string]: SelectInterface
    }
    oldBarcodeKoli: string
    barcodeKoli: string
    barcodeProduct: string
    barcodeAsset: string
    totalAvailable: string
    expDate: Nullable<Date>
    barcodePackingLine: string
  }
  detailSO: GetSupplyOrderGrandPackingV2ResType
  totalRestQty: number
  countReload: number
  isShowPopUpRestQty: boolean
  isShowPopUpExpiryDate: boolean
  barcodeFocus: BarcodeFocusType
  qr: {
    productType: string
    barcodeKoli: GetSupplyOrderGrandPackingKoliResType
    barcodeProduct: GetSupplyOrderGrandProductV2ResType
  }
  modal: {
    errorTotalAvailable: MapDataInterface
    errorKoli: MapDataInterface
    barcodeFocusValue: string
    statues: SelectInterface[]
    notes: SelectInterface[]
    itemsForm: SupplyOrderItemFormInterface[]
    mapStatues: MapDataInterface
    mapStatusNotes: MapDataInterface
    mapKolis: MapDataInterface
  }
  // TO REMOVE
  koliLabel: GetSupplyOrderGrandKoliLabelResType
  completeOrder: boolean
  mapSelectedKolis: {
    [key: string]: boolean
  }
  isShowAlertPickingStarted: boolean
  picking: GetProgressPickingResType['data'][number] | null
  checkerPopup: {
    isOpen: boolean
    selected: SelectInterface | null
  }
  packerPopup: {
    isOpen: boolean
    selected: {
      [key: string]: SelectInterface
    }
  }
  checkerList: SelectInterface[]
  packerList: SelectInterface[]
  featureFlag: {
    supply_order_ed_dry_scan: boolean
    productivity_tracker_v6: boolean
    scan1by1: boolean
    packing_recording: boolean
    asset: boolean
  }
  modalExpiryDate: {
    isOpen: boolean
    list: GetSupplyOrderGrandPackingProductV2ResType['data']['packingProducts']
    form: {
      [key: number]: {
        supplyOrderItemId: number
        statusId: number | null
        statusNoteId: number | null
        remainingRequestQuantity: number
        expDate: number | null
        manualExpiryDate: number | null
        totalAvailable: string
        type: 'ORIGINAL_ED' | 'ANOTHER_ED' | 'NEW_ED'
        error: string
      }[]
    }
  }
  fresh: {
    isPartialSubmit: boolean
    popupError: {
      isOpen: boolean
      title?: string
      message?: string
    }
  }
  modalInputManual: {
    isOpen: boolean
    isOpenAsset: boolean
    constants: {
      reason: {
        id: number
        reason: string
      }[]
    }
    step: 1 | 2
    form: {
      sku: string
      reason: {
        id: number
        reason: string
      } | null
    }
    item: {
      sku: string
      productName: string
      expiryDate: number | null
      totalRequestQty: number
      remainingRequestQuantity: number
      supplyOrderItemId: number
      statusId: number
      statusNoteId: number | null
      substituteItem: boolean
      qrCode: string
      productId: number | null
      totalAvailable: number
    }
  }
  productTypeAstroKitchen: string[]
  assetData: {
    assetInventoryId: number
    assetCode: string
    error: string
    name: string
  }
}

const initialStateItem = {
  expiryDate: null,
  barcodeKoli: '',
  totalAvailable: 0,
  status: null,
  statusNote: null,
  productType: '',
}

const initialState: SupplyOrderCheckQtyStateType = {
  id: '',
  isLoading: true,
  keyword: '',
  staggedRemoveDetailId: 0,
  isShowConfirmationRemove: false,
  packingRecording: {
    isFetchingApiRecording: false,
    isStartRecording: false,
  },
  formState: {
    checker: null,
    packers: {},
    oldBarcodeKoli: '',
    barcodeKoli: '',
    barcodeProduct: '',
    barcodeAsset: '',
    totalAvailable: '0',
    expDate: null,
    barcodePackingLine: '',
  },
  detailSO: {
    supplyOrderId: 0,
    supplyOrderNumber: '',
    supplyOrderItems: [],
    checker: null,
    packers: [],
  },
  totalRestQty: 0,
  countReload: 0,
  isShowPopUpRestQty: false,
  isShowPopUpExpiryDate: false,
  barcodeFocus: 'barcodeKoli',
  qr: {
    productType: '',
    barcodeKoli: {
      data: {
        code: '',
        createdDate: '',
        id: 0,
      },
      error: {
        message: '',
      },
    },
    barcodeProduct: {
      data: {
        expDate: null,
        productId: 0,
        productName: '',
        productSku: '',
        productUom: '',
        qrCode: '',
        remainingRequestQuantity: 0,
        requestQuantity: 0,
        status: '',
        statusId: 0,
        statusNote: '',
        statusNoteId: 0,
        supplyOrderItemId: 0,
      },
      error: {
        message: '',
      },
    },
  },
  modal: {
    errorTotalAvailable: {},
    errorKoli: {},
    barcodeFocusValue: '',
    statues: [],
    notes: [],
    itemsForm: [{ ...initialStateItem }],
    mapStatues: {},
    mapStatusNotes: {},
    mapKolis: {},
  },
  // TO REMOVE
  koliLabel: {
    data: [],
  },
  completeOrder: false,
  mapSelectedKolis: {},
  isShowAlertPickingStarted: false,
  picking: null,
  checkerPopup: {
    isOpen: false,
    selected: null,
  },
  packerPopup: {
    isOpen: false,
    selected: {},
  },
  checkerList: [],
  packerList: [],
  featureFlag: {
    scan1by1: false,
    supply_order_ed_dry_scan: false,
    productivity_tracker_v6: false,
    packing_recording: false,
    asset: false,
  },
  modalExpiryDate: {
    isOpen: false,
    list: [],
    form: {},
  },
  fresh: {
    isPartialSubmit: false,
    popupError: {
      isOpen: false,
    },
  },
  modalInputManual: {
    isOpen: false,
    isOpenAsset: false,
    constants: {
      reason: [],
    },
    step: 1,
    form: {
      sku: '',
      reason: null,
    },
    item: {
      sku: '',
      productName: '',
      supplyOrderItemId: 0,
      expiryDate: null,
      totalRequestQty: 0,
      remainingRequestQuantity: 0,
      totalAvailable: 0,
      statusId: 0,
      statusNoteId: null,
      productId: null,
      qrCode: '',
      substituteItem: false,
    },
  },
  productTypeAstroKitchen: ['astro kitchen - raw material chilled/frozen'],
  assetData: {
    assetInventoryId: 0,
    assetCode: '',
    error: '',
    name: '',
  },
}

type FetchGetFeatureFlagAllLocationParamsType = {
  feature: string
  callbackFunction?: (data: { flag: boolean; active: boolean }) => void
}
export const fetchGetFeatureFlagAllLocation = createAsyncThunk(
  'supplyOrderCheckQty/fetchGetFeatureFlagAllLocation',
  async (param: FetchGetFeatureFlagAllLocationParamsType, { rejectWithValue }) => {
    const { feature, callbackFunction } = param
    try {
      const {
        data: { data },
      } = await getHubFeature({ feature })

      const { flag, active } = data
      const isAllowed = flag && active

      if (callbackFunction) callbackFunction(data)

      return {
        [feature]: isAllowed,
      }
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchGetFeatureFlag = createAsyncThunk(
  'supplyOrderCheckQty/fetchGetFeatureFlag',
  async (
    {
      locationId,
      feature,
    }: {
      locationId: string
      feature: keyof SupplyOrderCheckQtyStateType['featureFlag']
    },
    { rejectWithValue },
  ) => {
    try {
      const featureFlag = await getFeatureFlag(locationId, feature)

      const isAllowed = featureFlag?.data?.data?.active

      return {
        [feature]: isAllowed,
      }
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchGetScanManualReason = createAsyncThunk(
  'supplyOrderCheckQty/fetchGetScanManualReason',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getScanManualReason()

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchGetCheckerList = createAsyncThunk(
  'supplyOrderCheckQty/fetchGetCheckerList',
  async (locationId: number, { rejectWithValue }) => {
    try {
      const response = await getStaffManagement(locationId, AUTH_OUTBOUND_CHECKER)
      return response.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchGetPackerList = createAsyncThunk(
  'supplyOrderCheckQty/fetchGetPackerList',
  async (locationId: number, { rejectWithValue }) => {
    try {
      const response = await getStaffManagement(locationId, AUTH_OUTBOUND_PACKER)

      return response.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchPutAssignChecker = createAsyncThunk(
  'supplyOrderCheckQty/fetchPutAssignChecker',
  async (
    {
      soId,
      payload,
      callback,
    }: PutAssignCheckerSORequestType & {
      callback: () => void
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await putAssignCheckerSO(soId, payload)
      toastSuccess(response.data.data)
      if (callback) {
        callback()
      }

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchPutAssignPacker = createAsyncThunk(
  'supplyOrderCheckQty/fetchPutAssignPacker',
  async (
    {
      soId,
      payload,
      callback,
    }: PutAssignPackerSORequestType & {
      callback: () => void
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await putAssignPackerSO(soId, payload)
      toastSuccess(response.data.data)
      if (callback) {
        callback()
      }

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchGetSupplyOrderGrandPackingV2 = createAsyncThunk<
  { data: GetSupplyOrderGrandPackingV2ResType },
  string,
  RejectValueType
>('supplyOrderCheckQty/fetchGetSupplyOrderGrandPackingV2', async (params, { rejectWithValue }) => {
  try {
    const response = await getSupplyOrderGrandPackingV2(params)

    return response.data
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchGetSupplyOrderGrandPackingKoli = createAsyncThunk<
  GetSupplyOrderGrandPackingKoliResType | null,
  GetSupplyOrderGrandPackingKoliReqType,
  RejectValueType
>(
  'supplyOrderCheckQty/fetchGetSupplyOrderGrandPackingKoli',
  async (params, { rejectWithValue, dispatch, getState }) => {
    if (!params.params.code || params?.isKoliNotRight) return null

    try {
      const { packingRecordingOnCheckQty } = (getState() as RootStateType).packingRecording

      const response: AxiosResponse<GetSupplyOrderGrandPackingKoliResType> =
        await getSupplyOrderGrandPackingKoli({ id: params.id, params: params.params })
      dispatch(resetAssetData())

      toastSuccess('Koli ditemukan')

      if (
        packingRecordingOnCheckQty.isRecordingStarted &&
        !packingRecordingOnCheckQty.userAbleToStopRecording
      ) {
        dispatch(
          setModalConfirmation({
            isOpen: true,
            type: 'stopRecording',
            title: 'Yakin ingin stop recording',
            description: 'Apakah Anda yakin ingin stop recording ?',
          }),
        )
      } else if (packingRecordingOnCheckQty.isPackingRecordingEnabled) {
        const packingRecordingData = {
          checkQtyData: {
            koliId: response.data.data.id,
            koliNumber: response.data.data.code,
            soId: +params.id,
          },
          ipAddress: packingRecordingOnCheckQty.ipAddress,
        }
        localStorage.setItem('packingRecording', JSON.stringify(packingRecordingData))

        dispatch(
          setPackingRecordingOnCheckQty({
            koliId: response.data.data.id,
            koliNumber: response.data.data.code,
            soId: +params.id,
          }),
        )
        dispatch(actStartStopRecording() as never)
      }
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchGetSupplyOrderGrandProductV2 = createAsyncThunk<
  GetSupplyOrderGrandProductV2ResType,
  GetSupplyOrderGrandProductV2ReqType,
  RejectValueType
>('supplyOrderCheckQty/fetchGetSupplyOrderGrandProductV2', async (params, { rejectWithValue }) => {
  try {
    const response = await getSupplyOrderGrandProductV2(params)

    toastSuccess('Produk ditemukan')

    return response.data
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchPostSupplyOrderFresh = createAsyncThunk<
  PostSupplyOrderPackingFreshResponseType,
  PostSupplyOrderPackingFreshRequestType,
  RejectValueType
>(
  'supplyOrderCheckQty/fetchPostSupplyOrderFresh',
  async ({ id, payload }, { rejectWithValue, dispatch, getState }) => {
    try {
      const { auth } = getState() as RootStateType
      const { userData } = auth

      const { id: userId } = userData

      const newPayload = payload
      newPayload.submittedBy = `${userId}`

      const response = await postSupplyOrderPackingFresh({ id, payload: newPayload })

      toastSuccess(response.data?.message)
      dispatch(fetchGetSupplyOrderGrandPackingV2(`${id}`))

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchGetSupplyOrderGrandPackingProductManual = createAsyncThunk<
  GetSupplyOrderGrandPackingProductV2ResType,
  GetSupplyOrderGrandProductV2ReqType,
  RejectValueType
>(
  'supplyOrderCheckQty/fetchGetSupplyOrderGrandPackingProductManual',
  async (params, { rejectWithValue, getState }) => {
    try {
      const { supplyOrderCheckQty } = getState() as StoreStateType

      const response: AxiosResponse<GetSupplyOrderGrandPackingProductV2ResType> =
        supplyOrderCheckQty.featureFlag.scan1by1
          ? await getSupplyOrderGrandPackingProductV3(params)
          : await getSupplyOrderGrandPackingProduct(params)

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchGetSupplyOrderGrandPackingProductV2 = createAsyncThunk<
  GetSupplyOrderGrandPackingProductV2ResType,
  GetSupplyOrderGrandProductV2ReqType,
  RejectValueType
>(
  'supplyOrderCheckQty/fetchGetSupplyOrderGrandPackingProductV2',
  async (params, { rejectWithValue, getState, dispatch }) => {
    try {
      const { supplyOrderCheckQty } = getState() as StoreStateType

      const response: AxiosResponse<GetSupplyOrderGrandPackingProductV2ResType> =
        supplyOrderCheckQty.featureFlag.scan1by1
          ? await getSupplyOrderGrandPackingProductV3(params)
          : await getSupplyOrderGrandPackingProduct(params)

      const { fresh, packingProducts, substituteItem } = response.data.data

      if (supplyOrderCheckQty.qr.barcodeKoli.error.message) {
        const errorMessage = 'Koli wajib di isi dengan benar'
        toastFailed(errorMessage)
        throw new Error(errorMessage)
      }

      if (fresh) {
        const {
          qr: { barcodeKoli },
        } = supplyOrderCheckQty

        const { supplyOrderItemId, statusId, statusNoteId, qrCode, productId, expDate } =
          packingProducts[0]

        await dispatch(
          fetchPostSupplyOrderFresh({
            id: +params.id,
            payload: {
              supplyOrderItemId,
              details: [
                {
                  quantity: 1,
                  statusId,
                  statusNoteId,
                  koliId: barcodeKoli.data.id,
                  ...(substituteItem && expDate ? { manualExpiryDate: expDate } : {}),
                },
              ],
              productId,
              qrCode,
              substituteItem,
            },
          }),
        ).unwrap()

        // hit endpoint fresh
        toastSuccess('Scan Success')
      } else {
        toastSuccess('Produk ditemukan')
      }

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchPostSupplyOrderGrandMultipleValidate = createAsyncThunk<
  PostSupplyOrderGrandValidateReqType['payload'][],
  {
    id: string
    payloads: PostSupplyOrderGrandValidateReqType['payload'][]
  },
  RejectValueType
>(
  'supplyOrderCheckQty/fetchPostSupplyOrderGrandMultipleValidate',
  async ({ id, payloads }, { rejectWithValue }) => {
    try {
      const apis = payloads.map((payload) =>
        postSupplyOrderGrandValidate({ id, payload }).catch((err) => ({
          message: err.response?.data?.error?.message,
          error: true,
        })),
      )

      const response = (await Promise.allSettled(apis)) as unknown as {
        value: {
          message: string
          error: boolean
        }
      }[]

      const hasError = response.filter((res) => res?.value.error)

      if (hasError.length) {
        toastFailed(hasError[0].value.message)
        throw new Error(hasError[0].value.message)
      }

      return payloads
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchPostSupplyOrderGrandValidate = createAsyncThunk<
  null,
  PostSupplyOrderGrandValidateReqType,
  RejectValueType
>('supplyOrderCheckQty/fetchPostSupplyOrderGrandValidate', async (params, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<null> = await postSupplyOrderGrandValidate(params)

    return response.data
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchPostSupplyOrderGrandFreshValidate = createAsyncThunk<
  null,
  PostSupplyOrderGrandValidateReqType,
  RejectValueType
>(
  'supplyOrderCheckQty/fetchPostSupplyOrderGrandFreshValidate',
  async (params, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<null> = await postSupplyOrderGrandValidate(params)

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchDeleteSupplyOrderGrandItemDetail = createAsyncThunk<
  null,
  DeleteSupplyOrderGrandItemDetailReqType,
  RejectValueType
>(
  'supplyOrderCheckQty/fetchDeleteSupplyOrderGrandItemDetail',
  async (params, { rejectWithValue, getState }) => {
    try {
      const { supplyOrderCheckQty } = getState() as StoreStateType

      const response: AxiosResponse<null> = supplyOrderCheckQty.featureFlag.scan1by1
        ? await deleteSupplyOrderItemDetailV2(params)
        : await deleteSupplyOrderGrandItemDetail(params)

      toastNeutral('Berhasil menghapus sebuah detail item')

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchGetStatus = createAsyncThunk<GetStatusResType, null, RejectValueType>(
  'supplyOrderCheckQty/fetchGetStatus',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<GetStatusResType> = await getStatus()
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchGetNotes = createAsyncThunk<GetNotessResType, null, RejectValueType>(
  'supplyOrderCheckQty/fetchGetNotes',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<GetNotessResType> = await getNotes()
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchPostSupplyOrderGrandPackingPublish = createAsyncThunk<
  GetNotessResType,
  null,
  RejectValueType
>('supplyOrderCheckQty/fetchGetNotes', async (_, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<GetNotessResType> = await getNotes()

    return response.data
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchGetSupplyOrderGrandPackingKoliModal = createAsyncThunk<
  GetSupplyOrderGrandPackingKoliResType,
  GetSupplyOrderGrandPackingKoliReqType,
  RejectValueType
>(
  'supplyOrderCheckQty/fetchGetSupplyOrderGrandPackingKoliModal',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response: AxiosResponse<GetSupplyOrderGrandPackingKoliResType> =
        await getSupplyOrderGrandPackingKoli(params)
      dispatch(resetAssetData())
      toastSuccess('Koli ditemukan')

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchPostSupplyOrderGrandPackingKoliModal = createAsyncThunk<
  { message: string },
  { id: string; callbackFunction?: () => void },
  RejectValueType
>(
  'supplyOrderCheckQty/fetchPostSupplyOrderGrandPackingKoliModal',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response: AxiosResponse<{ message: string }> = await postSupplyOrderGrandPackingPublish(
        params.id,
      )
      toastSuccess(response.data.message)
      if (params.callbackFunction) params.callbackFunction()
      // TO REMOVE
      // dispatch(fetchGetSupplyOrderGrandKoliLabel(params))

      return response.data
    } catch (err) {
      dispatch(fetchGetSupplyOrderGrandPackingV2(params.id))
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

// TO REMOVE
export const fetchGetSupplyOrderGrandKoliLabel = createAsyncThunk<
  GetSupplyOrderGrandKoliLabelResType,
  string,
  RejectValueType
>('supplyOrderCheckQty/fetchGetSupplyOrderGrandKoliLabel', async (params, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<GetSupplyOrderGrandKoliLabelResType> =
      await getSupplyOrderGrandKoliLabel(params)

    return response.data
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchGetProgressPicking = createAsyncThunk<
  GetProgressPickingResType,
  string,
  RejectValueType
>('supplyOrderCheckQty/fetchGetProgressPicking', async (params, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<GetProgressPickingResType> = await getProgressPicking(params)

    return response.data
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchPostSupplyOrderGrandPackingItem = createAsyncThunk<
  { message: string },
  PostSupplyOrderGrandPackingItemReqType,
  RejectValueType
>(
  'supplyOrderCheckQty/fetchPostSupplyOrderGrandPackingItem',
  async (params, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<{ message: string }> = await postSupplyOrderGrandPackingItem(
        params,
      )

      toastSuccess(response.data.message)
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchPostSubmitFreshItem = createAsyncThunk<
  { message: string },
  PostSupplyOrderPackingItemReqType & { callbackFunction: () => void },
  RejectValueType
>(
  'supplyOrderCheckQty/fetchPostSubmitFreshItem',
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      const { callbackFunction, ...rest } = params
      const {
        // supplyOrderCheckQty,
        auth: {
          userData: { id: userId },
        },
      } = getState() as StoreStateType

      // const { assetData } = supplyOrderCheckQty
      // let assetPayload = {}

      // if (assetData.assetInventoryId !== 0) {
      //   assetPayload = {
      //     assetId: assetData.assetInventoryId,
      //     assetCode: assetData.assetCode,
      //   }
      // }

      const newPayload = {
        id: +params.id,
        payload: {
          submittedBy: String(userId),
          items: params.payload.map((item) => ({
            supplyOrderItemId: String(item.supplyOrderItemId),
            details: item.details.map((detail) => {
              const { inventoryStatusId, inventoryStatusNoteId, ...restDetail } = detail
              return {
                statusId: inventoryStatusId,
                statusNoteId: inventoryStatusNoteId,
                ...restDetail,
              }
            }),
          })),
        },
      }

      const response: AxiosResponse<{ message: string }> = await postSupplyOrderPackingItem(
        newPayload,
      )

      dispatch(fetchPostSupplyOrderGrandPackingKoliModal({ id: rest.id, callbackFunction }))
      toastSuccess(response.data.message)
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchPostSupplyOrderPackingItem = createAsyncThunk<
  { message: string },
  PostSupplyOrderPackingItemReqType,
  RejectValueType
>(
  'supplyOrderCheckQty/fetchPostSupplyOrdePackingItem',
  async (params, { rejectWithValue, getState }) => {
    try {
      const {
        // supplyOrderCheckQty,
        auth: {
          userData: { id: userId },
        },
      } = getState() as StoreStateType

      // const { assetData } = supplyOrderCheckQty
      // let assetPayload = {}

      // if (assetData.assetInventoryId !== 0) {
      //   assetPayload = {
      //     assetId: assetData.assetInventoryId,
      //     assetCode: assetData.assetCode,
      //   }
      // }
      const newPayload = {
        id: +params.id,
        payload: {
          submittedBy: String(userId),
          items: params.payload.map((item) => ({
            supplyOrderItemId: String(item.supplyOrderItemId),
            details: item.details.map((detail) => {
              const { inventoryStatusId, inventoryStatusNoteId, ...restDetail } = detail
              return {
                statusId: inventoryStatusId,
                statusNoteId: inventoryStatusNoteId,
                ...restDetail,
              }
            }),
          })),
        },
      }

      const response: AxiosResponse<{ message: string }> = await postSupplyOrderPackingItem(
        newPayload,
      )

      toastSuccess(response.data.message)
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const actScanBarcode =
  (barcode: string, callbackFunctionOnSuccess: () => void) =>
  async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { packingRecordingOnCheckQty } = (getState() as RootStateType).packingRecording
    const { formState, id } = (getState() as RootStateType).supplyOrderCheckQty
    if (formState.barcodeKoli !== barcode && packingRecordingOnCheckQty.isRecordingStarted) {
      toastFailed('Anda harus scan barcode koli yg sama untuk mengakhiri rekaman')
      return
    }
    dispatch(setBarcodeForm(barcode))
    dispatch(fetchGetSupplyOrderGrandPackingKoli({ id, params: { code: barcode.trim() } }))
    if (callbackFunctionOnSuccess) callbackFunctionOnSuccess()
  }

const supplyOrderCheckQty = createSlice({
  name: 'supplyOrderCheckQty',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setQrKoli: (state, action: PayloadAction<BarcodeFocusType>) => {
      state.barcodeFocus = action.payload
    },
    setBarcodeForm: (state, action: PayloadAction<string>) => {
      state.formState[state.barcodeFocus] = action.payload
    },
    setFormState: (state, action: PayloadAction<FormStateInputType>) => {
      state.formState[action.payload.name] = action.payload.value as never
    },
    setStaggedDeleteId: (state, action: PayloadAction<number>) => {
      state.staggedRemoveDetailId = action.payload
      state.isShowConfirmationRemove = !!action.payload
    },
    setKeyword: (state, action: PayloadAction<string>) => {
      state.keyword = action.payload
    },
    setIsShowPopUpRestQty: (state, action: PayloadAction<boolean>) => {
      state.isShowPopUpRestQty = action.payload

      if (!action.payload) {
        state.totalRestQty = initialState.totalRestQty
        state.countReload += 1
        state.isShowPopUpRestQty = false
        state.completeOrder = false
        state.modal = {
          ...state.modal,
          errorKoli: initialState.modal.errorKoli,
          barcodeFocusValue: initialState.modal.barcodeFocusValue,
          itemsForm: initialState.modal.itemsForm,
        }
        state.mapSelectedKolis = initialState.mapSelectedKolis
        state.modalExpiryDate = initialState.modalExpiryDate
        state.fresh = initialState.fresh
      }
    },
    setItemsFormModal: (
      state,
      action: PayloadAction<{ index: number; value: string; childIndex?: number }>,
    ) => {
      const { index, value, childIndex } = action.payload

      if (typeof childIndex === 'number') {
        ;(state.modal.itemsForm[index].newStatus as NewStatusType)[childIndex].barcodeKoli = value
      } else {
        state.modal.itemsForm[index].barcodeKoli = value
      }
      state.modal.barcodeFocusValue = value
    },
    setAddItemFormModal: (state) => {
      state.modal.itemsForm.push(initialStateItem)
    },
    setAddChildItemFormModal: (
      state,
      action: PayloadAction<{ index: number; soItemId?: number }>,
    ) => {
      const { index, soItemId } = action.payload

      state.modal.itemsForm[index].newStatus?.push({
        ...initialStateItem,
        supplyOrderItemId: soItemId,
        totalRestQty: state.modal.itemsForm[index].totalRestQty as number,
        originalExpiryDate: state.modal.itemsForm[index].originalExpiryDate,
      })
    },
    setRemoveItemFormModal: (
      state,
      action: PayloadAction<{ index: number; childIndex?: number; soItemId?: number }>,
    ) => {
      const { index, childIndex, soItemId } = action.payload

      if (soItemId) {
        if (state.qr.productType === 'Fresh') {
          let total = +state.modal.itemsForm[index].totalAvailable

          state.modal.itemsForm[index].newStatus?.forEach((detail, idx) => {
            if (idx !== childIndex) {
              total += +detail.totalAvailable
            }

            state.modal.errorTotalAvailable[soItemId as number] = total > detail.totalRestQty
          })
        } else {
          let total = 0

          state.modal.itemsForm
            .filter((arr) => arr.supplyOrderItemId === soItemId)
            .forEach((item, idx) => {
              total += +item.totalAvailable
              const itemId = item.supplyOrderItemId as number
              const restQty = state.modal.itemsForm[idx].totalRestQty || 0

              item.newStatus?.forEach((detail) => {
                total += +detail.totalAvailable
              })
              state.modal.errorTotalAvailable[itemId as number] = total > restQty
            })
        }
      }

      if (typeof childIndex === 'number') {
        ;(state.modal.itemsForm[index].newStatus as NewStatusType).splice(childIndex, 1)
      } else {
        state.modal.itemsForm.splice(index, 1)
      }
    },
    setValueItemFormModal: (state, action: PayloadAction<SupplyOrderItemRestInterface>) => {
      const { index, childIndex, key, value, soItemId } = action.payload

      if (typeof childIndex === 'number') {
        const newStatus = (state.modal.itemsForm[index].newStatus as NewStatusType)?.[childIndex]

        if (key === 'status') newStatus.statusNote = null
        if (key === 'status' && value === 3) newStatus.statusNote = 16
        newStatus[key] = value as number
      } else {
        const itemForm = state.modal.itemsForm[index]

        if (key === 'status') itemForm.statusNote = null
        if (key === 'status' && value === 3) itemForm.statusNote = 16
        itemForm[key] = value as number
      }

      if (key === 'totalAvailable') {
        let total = 0

        state.modal.itemsForm
          .filter((arr) => arr.supplyOrderItemId === soItemId)
          .forEach((item) => {
            total += +item.totalAvailable
            const itemId = item.supplyOrderItemId as number
            const restQty =
              state.modal.itemsForm.find(
                (data) => data.supplyOrderItemId === item.supplyOrderItemId,
              )?.totalRestQty || 0

            item.newStatus?.forEach((detail) => {
              total += +detail.totalAvailable
            })
            state.modal.errorTotalAvailable[itemId as number] = total > restQty
          })
      }
    },
    setMapSelectedKolis: (state, action: PayloadAction<MapSelectedKoliInterface>) => {
      state.mapSelectedKolis[action.payload.value] = action.payload.action === 'add'
    },
    setShowAlert: (state, action: PayloadAction<boolean>) => {
      state.isShowAlertPickingStarted = action.payload
    },
    setOpenCheckerPopup: (state, action: PayloadAction<boolean>) => {
      const isOpen = action.payload

      state.checkerPopup.isOpen = isOpen
      state.checkerPopup.selected = isOpen ? state.formState.checker : null
    },
    setOpenPackerPopup: (state, action: PayloadAction<boolean>) => {
      const isOpen = action.payload

      state.packerPopup.isOpen = isOpen
      state.packerPopup.selected = isOpen ? state.formState.packers : {}
    },
    setPopupCheckerSelected: (state, action) => {
      if (action.payload) {
        state.checkerPopup.selected =
          action.payload.value !== state.checkerPopup.selected?.value ? action.payload : null
      } else {
        state.checkerPopup.selected = null
      }
    },
    setPopupPackerSelected: (state, action) => {
      const { value } = action.payload || {}

      if (!value) {
        state.packerPopup.selected = {}
      } else if (!state.packerPopup.selected[value]) {
        state.packerPopup.selected = {
          ...state.packerPopup.selected,
          [value]: action.payload,
        }
      } else {
        delete state.packerPopup.selected[value]
      }
    },
    setFormCheckerSelected: (state, action) => {
      state.formState.checker = action.payload
    },
    setFormPackerSelected: (state, action) => {
      state.formState.packers = action.payload
    },
    setOpenModalExpiryDate: (state, action) => {
      state.modalExpiryDate.isOpen = action.payload

      if (!action.payload) {
        state.modalExpiryDate = initialState.modalExpiryDate
      }
    },
    setNewRowExpiryDate: (
      state,
      action: PayloadAction<{ id: number; type: 'ANOTHER_ED' | 'NEW_ED' }>,
    ) => {
      const { id, type } = action.payload
      state.modalExpiryDate.form[id].push({
        ...state.modalExpiryDate.form[id][0],
        manualExpiryDate: null,
        totalAvailable: '',
        type,
        error: '',
      })
    },
    setRemoveRowExpiryDate: (state, action: PayloadAction<{ id: number; index: number }>) => {
      const { id, index } = action.payload
      state.modalExpiryDate.form[id] = state.modalExpiryDate.form[id].filter(
        (_, idx) => idx !== index,
      )
    },
    setFormExpiryDate: (
      state,
      action: PayloadAction<{
        value: string
        row: {
          id: number
          index: number
          key: 'manualExpiryDate' | 'totalAvailable'
          type: 'ANOTHER_ED' | 'NEW_ED'
          error: string
        }
      }>,
    ) => {
      const {
        value,
        row: { id, index, key, type, error },
      } = action.payload

      state.modalExpiryDate.form[id][index][key] = value as never

      if (key === 'totalAvailable') {
        state.modalExpiryDate.form[id] = state.modalExpiryDate.form[id].map((item) => ({
          ...item,
          error: error || '',
        }))
        state.modalExpiryDate.form[id][index].error = error
      }

      if (key === 'manualExpiryDate') {
        state.modalExpiryDate.form[id][index].type = type
      }
    },
    setPopupNotification: (
      state,
      action: PayloadAction<{ isOpen: boolean; title?: string; message?: string }>,
    ) => {
      state.fresh.popupError = action.payload
    },
    setSubmitFreshItem: (state, action) => {
      state.modal.itemsForm = action.payload.data
      state.fresh.isPartialSubmit = action.payload.isPartialSubmit
      state.isShowPopUpRestQty = true
    },
    setOpenModalInputManual: (state, action: PayloadAction<boolean>) => {
      const isOpen = action.payload
      state.modalInputManual.isOpen = isOpen

      if (!isOpen) {
        state.modalInputManual = initialState.modalInputManual
      }
    },
    setFormInputManual: (
      state,
      action: PayloadAction<{
        key: 'sku' | 'reason' | 'totalAvailable' | 'expiryDate'
        value: never
      }>,
    ) => {
      const { key, value } = action.payload

      if (key === 'totalAvailable') {
        state.modalInputManual.item.totalAvailable = +value
      } else if (key === 'expiryDate') {
        state.modalInputManual.item.expiryDate = value as number
      } else {
        state.modalInputManual.form[key] = value
      }
    },
    setStepInputManual: (state, action) => {
      state.modalInputManual.step = action.payload
    },
    setShowPopupRestQtyFromManualInput: (state) => {
      const { item } = state.modalInputManual
      const { totalAvailable, remainingRequestQuantity } = item

      state.formState.totalAvailable = totalAvailable as unknown as string
      state.totalRestQty = remainingRequestQuantity - totalAvailable
      state.modalInputManual.isOpen = false
      state.isShowPopUpRestQty = true
    },
    setDetailSO: (state, action: PayloadAction<GetSupplyOrderGrandPackingV2ResType>) => {
      state.detailSO = action.payload
    },
    setAssetData: (state, action: PayloadAction<SupplyOrderCheckQtyStateType[`assetData`]>) => {
      state.assetData = action.payload
      state.formState.barcodeAsset = action.payload.assetCode
    },
    resetAssetData: (state) => {
      state.assetData = initialState.assetData
      state.formState.barcodeAsset = ``
    },
    setOpenModalInputManualAsset: (state, action: PayloadAction<boolean>) => {
      const isOpen = action.payload
      state.modalInputManual.isOpenAsset = isOpen
      state.modalInputManual.isOpen = isOpen

      if (!isOpen) {
        state.modalInputManual = initialState.modalInputManual
      }
    },
    setReset: () => initialState,
  },
  extraReducers: withLoadingReducer(
    (builder: ActionReducerMapBuilder<SupplyOrderCheckQtyStateType>) => {
      builder
        .addCase(fetchGetSupplyOrderGrandPackingV2.fulfilled, (state, action) => {
          const { supplyOrderId, checker, packers, supplyOrderItems } = action.payload.data

          if (state.qr.barcodeProduct.data.qrCode) {
            const resultData =
              supplyOrderItems.find(
                (item) => item.productSku === state.qr.barcodeProduct.data.qrCode,
              ) || null
            if (resultData) {
              state.qr.barcodeProduct.data.remainingRequestQuantity =
                resultData.requestQuantity - resultData.totalActualQuantity
            }
          }

          state.id = `${supplyOrderId}`
          state.detailSO = action.payload.data

          if (checker) {
            state.formState.checker = {
              name: checker.name,
              value: checker.id,
            }
          }

          if (packers?.length) {
            state.formState.packers = packers?.reduce(
              (acc, cur) => ({
                ...acc,
                [cur.id]: { name: cur.name, value: cur.id },
              }),
              {},
            )
          }
        })
        .addCase(fetchGetSupplyOrderGrandPackingKoli.fulfilled, (state, action) => {
          if (action.payload) {
            state.qr.barcodeKoli = action.payload
          }
        })
        .addCase(fetchGetSupplyOrderGrandPackingKoli.rejected, (state) => {
          state.qr.barcodeKoli.data = initialState.qr.barcodeKoli.data
          state.qr.barcodeKoli.error.message = 'Koli tidak ditemukan'
        })
        .addCase(fetchGetSupplyOrderGrandPackingProductManual.fulfilled, (state, action) => {
          const {
            data: { fresh, showPopupExpiryDate, packingProducts, substituteItem },
          } = action.payload

          const item =
            packingProducts.length > 0 && packingProducts[0].remainingRequestQuantity === 0
              ? packingProducts.find((product) => product.remainingRequestQuantity > 0) ||
                packingProducts[0]
              : packingProducts[0]

          if (fresh) {
            state.qr.productType = 'Fresh'
          } else if (showPopupExpiryDate) {
            state.qr.productType = 'Dry'
          } else {
            state.qr.productType = ''
          }

          state.qr.barcodeProduct.data =
            item as unknown as GetSupplyOrderGrandProductV2ResType['data']

          state.modalInputManual.item = {
            supplyOrderItemId: item.supplyOrderItemId,
            statusId: item.statusId,
            statusNoteId: item.statusNoteId,
            sku: item.productSku,
            expiryDate: item.expDate,
            productName: item.productName,
            totalRequestQty: item.requestQuantity,
            productId: item.productId,
            qrCode: item.qrCode,
            remainingRequestQuantity: item.remainingRequestQuantity,
            totalAvailable: 0,
            substituteItem,
          }
          state.modalInputManual.step = 2
        })
        .addCase(fetchGetSupplyOrderGrandPackingProductV2.fulfilled, (state, action) => {
          const {
            data: { showPopupExpiryDate, packingProducts, fresh },
          } = action.payload

          // === WILL USE ON PHASE 2 ===
          // state.isShowPopUpExpiryDate = false
          // if (state.isShowPopUpExpiryDate) {
          //   state.modalExpiryDate.isOpen = true

          //   state.modalExpiryDate.list = packingProducts
          //   state.modalExpiryDate.form = packingProducts.reduce(
          //     (acc, cum) => ({
          //       ...acc,
          //       [cum.supplyOrderItemId]: [
          //         {
          //           ...cum,
          //           manualExpiryDate: cum.expDate,
          //           totalAvailable: '',
          //           type: 'ORIGINAL_ED',
          //         },
          //       ],
          //     }),
          //     {},
          //   )
          // }

          state.isShowPopUpExpiryDate = showPopupExpiryDate
          if (showPopupExpiryDate) {
            const selected = packingProducts.find((item) => item.remainingRequestQuantity > 0)
            if (selected) {
              state.qr.barcodeProduct.data =
                selected as unknown as GetSupplyOrderGrandProductV2ResType['data']
            }
            state.qr.productType = 'Dry'
          } else if (fresh) {
            state.qr.productType = 'Fresh'
            state.qr.barcodeProduct.data =
              packingProducts[0] as unknown as GetSupplyOrderGrandProductV2ResType['data']
            state.formState.barcodeProduct = ''
          } else {
            state.qr.productType = ''
            state.qr.barcodeProduct.data =
              packingProducts[0] as unknown as GetSupplyOrderGrandProductV2ResType['data']
          }
        })
        .addCase(fetchGetSupplyOrderGrandProductV2.fulfilled, (state, action) => {
          state.qr.barcodeProduct = action.payload
        })
        .addCase(fetchGetSupplyOrderGrandPackingProductManual.rejected, (state) => {
          state.qr.barcodeProduct.data = initialState.qr.barcodeProduct.data
        })
        .addCase(fetchGetSupplyOrderGrandProductV2.rejected, (state) => {
          state.qr.barcodeProduct.data = initialState.qr.barcodeProduct.data
          state.qr.barcodeProduct.error.message = 'Produk tidak ditemukan'
        })
        .addCase(fetchGetSupplyOrderGrandPackingProductV2.rejected, (state) => {
          state.qr.barcodeProduct.data = initialState.qr.barcodeProduct.data
          state.formState.barcodeProduct = ''
        })
        .addCase(fetchPostSupplyOrderGrandMultipleValidate.fulfilled, (state, action) => {
          state.modalExpiryDate.isOpen = false

          const restQty = action.payload.reduce((acc: { [key: number]: number }, obj) => {
            acc[obj.supplyOrderItemId] = obj.actualQuantity
            return acc
          }, {})

          const list = Object.values(state.modalExpiryDate.form)
            .flatMap((arr) => arr)
            .filter((item) => {
              const isFullfilled =
                +item.remainingRequestQuantity - restQty[item.supplyOrderItemId] === 0
              return !isFullfilled
            })
            .map((item) => ({
              supplyOrderItemId: item?.supplyOrderItemId,
              totalAvailable: 0,
              expiryDate: item.manualExpiryDate,
              originalExpiryDate: item.expDate,
              newStatus: [],
              totalRestQty: +item.remainingRequestQuantity - restQty[item.supplyOrderItemId],
              barcodeKoli: '',
              status: null,
              statusNote: null,
              productType: '',
            }))

          if (list.length) {
            state.modal.itemsForm = list
            state.isShowPopUpRestQty = true
          } else {
            state.completeOrder = true
          }
        })
        .addCase(fetchPostSupplyOrderGrandValidate.fulfilled, (state) => {
          const restQty =
            state.qr.barcodeProduct.data.remainingRequestQuantity -
            Number(state.formState.totalAvailable)

          if (state.qr.barcodeKoli.data.code) {
            state.mapSelectedKolis[state.qr.barcodeKoli.data.code] = true
          }

          if (restQty !== 0) {
            state.totalRestQty = restQty
            state.isShowPopUpRestQty = true
          } else {
            state.completeOrder = true
          }
        })
        .addCase(fetchDeleteSupplyOrderGrandItemDetail.fulfilled, (state) => {
          state.countReload += 1
          state.isShowConfirmationRemove = false
        })
        .addCase(fetchGetStatus.fulfilled, (state, action) => {
          const mapStatuesData: MapDataInterface = {}

          const items = action.payload.data.status.map((el, index) => {
            mapStatuesData[el.id] = index
            return {
              name: el.displayName,
              value: el.id,
            }
          })

          state.modal.statues = items
          state.modal.mapStatues = mapStatuesData
        })
        .addCase(fetchGetNotes.fulfilled, (state, action) => {
          const mapNotesData: MapDataInterface = {}

          const items = action.payload.data.notes.map((el, index) => {
            mapNotesData[el.id] = index
            return {
              name: el.displayName,
              value: el.id,
              statusId: el.statusId,
            }
          })

          state.modal.notes = items
          state.modal.mapStatusNotes = mapNotesData
        })
        .addCase(fetchGetSupplyOrderGrandPackingKoliModal.fulfilled, (state, action) => {
          state.modal.mapKolis[action.payload.data.code] = action.payload.data.id
          state.mapSelectedKolis[action.payload.data.code] = true
          if (state.modal.errorKoli[state.modal.barcodeFocusValue]) {
            delete state.modal.errorKoli[state.modal.barcodeFocusValue]
          }
        })
        .addCase(fetchGetSupplyOrderGrandPackingKoliModal.rejected, (state) => {
          state.modal.errorKoli[state.modal.barcodeFocusValue] = 'Koli tidak ditemukan'
        })
        // TO REMOVE
        .addCase(fetchGetSupplyOrderGrandKoliLabel.fulfilled, (state, action) => {
          state.koliLabel = action.payload
        })
        .addCase(fetchPostSupplyOrderGrandPackingItem.fulfilled, (state) => {
          state.formState.checker = null
          state.formState.packers = {}
          state.formState.barcodeProduct = ''
          state.formState.totalAvailable = '0'
          state.formState.expDate = null

          state.qr.productType = ''
          state.qr.barcodeProduct = initialState.qr.barcodeProduct

          state.totalRestQty = initialState.totalRestQty
          state.countReload += 1
          state.isShowPopUpRestQty = false
          state.completeOrder = false
          state.modal = {
            ...state.modal,
            errorKoli: initialState.modal.errorKoli,
            barcodeFocusValue: initialState.modal.barcodeFocusValue,
            itemsForm: initialState.modal.itemsForm,
          }
          state.mapSelectedKolis = initialState.mapSelectedKolis
          state.modalExpiryDate = initialState.modalExpiryDate
        })
        .addCase(fetchPostSupplyOrderPackingItem.fulfilled, (state) => {
          state.formState.checker = null
          state.formState.packers = {}
          state.formState.barcodeProduct = ''
          state.formState.totalAvailable = '0'
          state.formState.expDate = null

          state.qr.productType = ''
          state.qr.barcodeProduct = initialState.qr.barcodeProduct

          state.totalRestQty = initialState.totalRestQty
          state.countReload += 1
          state.isShowPopUpRestQty = false
          state.completeOrder = false
          state.modal = {
            ...state.modal,
            errorKoli: initialState.modal.errorKoli,
            barcodeFocusValue: initialState.modal.barcodeFocusValue,
            itemsForm: initialState.modal.itemsForm,
          }
          state.mapSelectedKolis = initialState.mapSelectedKolis
          state.modalExpiryDate = initialState.modalExpiryDate

          const isOpenModalInputManual = state.modalInputManual.isOpen
          if (isOpenModalInputManual) {
            state.modalInputManual = initialState.modalInputManual
          }
        })
        .addCase(fetchPostSupplyOrderGrandPackingItem.rejected, (state) => {
          state.completeOrder = false
        })
        .addCase(fetchPostSupplyOrderPackingItem.rejected, (state) => {
          state.completeOrder = false
        })
        .addCase(fetchGetProgressPicking.fulfilled, (state, action) => {
          const { data } = action.payload
          if (data.length) {
            const { status, endAt, activity, name, startAt } = data[0]
            state.isShowAlertPickingStarted = status === 'IN_PROGRESS' && !endAt
            state.picking = {
              startAt,
              endAt,
              activity,
              name,
              status,
            }
          }
        })
        .addCase(fetchGetCheckerList.fulfilled, (state, action) => {
          state.checkerList = action.payload.map((item: { fullName: string; id: number }) => ({
            name: item.fullName,
            value: item.id,
          }))
        })
        .addCase(fetchGetPackerList.fulfilled, (state, action) => {
          state.packerList = action.payload.map((item: { fullName: string; id: number }) => ({
            name: item.fullName,
            value: item.id,
          }))
        })
        .addCase(fetchGetFeatureFlagAllLocation.fulfilled, (state, action) => {
          state.featureFlag = {
            ...state.featureFlag,
            ...action.payload,
          }
        })
        .addCase(fetchGetFeatureFlag.fulfilled, (state, action) => {
          state.featureFlag = {
            ...state.featureFlag,
            ...action.payload,
          }
        })
        .addCase(fetchPostSubmitFreshItem.fulfilled, (state) => {
          state.isShowPopUpRestQty = false
        })
        .addCase(fetchGetScanManualReason.fulfilled, (state, action) => {
          state.modalInputManual.constants.reason = action.payload.data
        })
        .addCase(fetchPostSupplyOrderFresh.fulfilled, (state) => {
          const isOpenModalInputManual = state.modalInputManual.isOpen

          if (isOpenModalInputManual) {
            state.modalInputManual = initialState.modalInputManual
          }
        })
    },
    [
      fetchGetSupplyOrderGrandPackingProductManual,
      fetchGetSupplyOrderGrandPackingV2,
      fetchGetSupplyOrderGrandPackingKoli,
      fetchGetSupplyOrderGrandProductV2,
      fetchPostSupplyOrderGrandMultipleValidate,
      fetchPostSupplyOrderGrandValidate,
      fetchDeleteSupplyOrderGrandItemDetail,
      fetchGetStatus,
      fetchGetNotes,
      fetchGetSupplyOrderGrandPackingKoliModal,
      fetchGetSupplyOrderGrandKoliLabel,
      fetchPostSupplyOrderGrandPackingItem,
      fetchPostSupplyOrderPackingItem,
      fetchGetProgressPicking,
      fetchPutAssignChecker,
      fetchPutAssignPacker,
      fetchPostSubmitFreshItem,
      fetchPostSupplyOrderFresh,
      fetchPostSupplyOrderGrandPackingKoliModal,
    ],
  ),
})

export const {
  setLoading,
  setQrKoli,
  setBarcodeForm,
  setFormState,
  setStaggedDeleteId,
  setKeyword,
  setIsShowPopUpRestQty,
  setItemsFormModal,
  setAddItemFormModal,
  setAddChildItemFormModal,
  setRemoveItemFormModal,
  setValueItemFormModal,
  setReset,
  setMapSelectedKolis,
  setShowAlert,
  setOpenCheckerPopup,
  setOpenPackerPopup,
  setFormCheckerSelected,
  setFormPackerSelected,
  setPopupCheckerSelected,
  setPopupPackerSelected,
  setOpenModalExpiryDate,
  setNewRowExpiryDate,
  setRemoveRowExpiryDate,
  setFormExpiryDate,
  setPopupNotification,
  setSubmitFreshItem,
  setOpenModalInputManual,
  setFormInputManual,
  setStepInputManual,
  setShowPopupRestQtyFromManualInput,
  setAssetData,
  setOpenModalInputManualAsset,
  resetAssetData,
} = supplyOrderCheckQty.actions
export default supplyOrderCheckQty.reducer
