import axios from 'axios'
import AxiosInstance from 'config/apiServiceApiGateway'

const { REACT_APP_BASE_URL_API } = process.env
const baseUrl = REACT_APP_BASE_URL_API

export const postNewDriver_Driver = (data) =>
  AxiosInstance.Post({
    url: `/staff-management/internal/v1/staff`,
    data,
  })

export const putDriver_Driver = (id, data) =>
  AxiosInstance.Put({
    url: `/staff-management/internal/v1/staff/${id}`,
    data,
  })

export const getDriverList_Driver = (query = '') => axios.get(`${baseUrl}/api/driver-admin${query}`)

export const putDriverActive_Driver = (id, dataStatus) =>
  axios.put(`${baseUrl}/api/driver-admin/${id}/active`, dataStatus)

export const postDriverActiveRole_Driver = (id, payload) =>
  axios.post(`${baseUrl}/api/staff-management/staff/${id}/role`, payload)

export const putDriverActive_Driververify = (id, dataStatus) =>
  axios.put(`${baseUrl}/api/driver-admin/${id}/available`, dataStatus)

export const getDriverByRole = (locationId, role, query = '') =>
  axios.get(`${baseUrl}/api/driver-admin/available/${locationId}/${role}${query}`)

export const getListHubStaffRole = (locationType) =>
  axios.get(`${baseUrl}/api/driver-admin/role`, {
    params: {
      locationType: locationType || 'HUB',
    },
  })

export const getListEmploymentType = () =>
  AxiosInstance.Get({
    url: '/staff-management/internal/:version/staff/employment-types',
    version: 'v1',
  })

export const exportDriver = () =>
  axios.get(`${baseUrl}/api/driver-admin/export`, {
    params: {
      locationType: 'HUB',
    },
  })

export const downloadTemplate = (locationId, role) =>
  axios.get(`${baseUrl}/api/driver-admin/csv/template`)

export const getListVendorStaff = () =>
  axios.get(`${baseUrl}/api/staff-management/staff-vendors/list`)

export const getDepartementList = () =>
  axios.get(`${baseUrl}/api/staff-management/admin/all-department-list`)

export const getLocations = (locationType, name) =>
  axios.get(`${baseUrl}/api/location`, {
    params: {
      type: locationType || 'HUB',
      name: name,
    },
  })

export const fetchStaffList = (data) =>
  AxiosInstance.Post({
    url: '/staff-management/internal/:version/staff/get-with-pagination',
    version: 'v1',
    data,
  })
