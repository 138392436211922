import { dateManipulation } from '@astronautsid/wpe-utils'
import {
  AddOrRemoveRlVendorRequestType,
  GetReorderListRequestParamType,
  GetReasonType,
  getPicList,
  getLocationList,
  getProductCategory,
  getVendorsByProductId,
  putRlVendor,
  getRlPreview,
  getReasonList,
  putUpdateRlVendor,
  putSaveCheckedReorderList,
  getReorderList,
  putConfirmRlPreview,
  deleteRlUnconfirmed,
  postCreatePurchaseRequest,
  getReorderListVendorChecked,
  GetReasonListResponseType,
  deleteRl,
  getAllVendors,
} from 'utils/apiList/reorderListV2'

import { callErrorMsg } from 'helpers/errorMsg'
import { getCleanQueryObj } from 'utils/queryParamsURL'
import { toastFailed, toastSuccess } from 'utils/toast'
import {
  setWarehouseList,
  setIsLoading,
  setConfirmedReorderList,
  resetModalAddEditVendor,
  setVendorComboboxOfModalAddEditRl,
  setPreviewReorderList,
  resetModalEditRlState,
  setModalListReorderToConfirm,
  deleteListReorderToConfirmDataAtSpecificIndex,
  setModalAddEditVendor,
  setModalSavePreviewConfirmation,
  setIsSavingCheckedRl,
  calculateCountOfProductAndVendor,
  ModalListReorderToConfirmDataType,
  setVendor,
  setPicList,
  setMModalSkuIsSaved,
} from './reorderListV2Slice'

type ActionType =
  | 'reorderListV2/setCategoryOfModalListReorderToConfirm'
  | 'reorderListV2/setCategory'

type FetchReorderListUnconfirmedType = {
  withModalOpen: boolean
  callbackFunction?: (listData: ModalListReorderToConfirmDataType[]) => void
}

export const fetchReorderListUnconfirmed =
  ({ withModalOpen, callbackFunction }: FetchReorderListUnconfirmedType) =>
  async (dispatch: StoreDispatchType, getState: () => StoreStateType) => {
    const { modalListReorderToConfirm } = getState().reorderListV2
    try {
      const {
        data: { data },
      } = await getReorderList(modalListReorderToConfirm.filter)

      const listData = data.map((item) => ({
        ...item,
        est_shipping_date: new Date(item.est_shipping_date),
      }))

      if (callbackFunction) {
        callbackFunction(listData)
        return
      }
      dispatch(
        setModalListReorderToConfirm({
          countOfData: listData.length,
          data: listData,
          isOpen: withModalOpen,
        }),
      )
    } catch (err) {
      callErrorMsg(err)
    }
  }

export const fetchPicList =
  (name: string, purpose?: 'sourcing_list' | 'sc_list') =>
  async (dispatch: StoreDispatchType, getState: () => StoreStateType) => {
    try {
      const { modalListReorderToConfirm } = getState().reorderListV2
      const { data } = await getPicList({ name, pageIndex: 0, pageSize: 40 })

      if (purpose === 'sourcing_list') {
        dispatch(
          setPicList({
            listData: data.content,
            searchName: name,
          }),
        )
        return
      }
      dispatch(
        setModalListReorderToConfirm({
          scPic: {
            ...modalListReorderToConfirm.scPic,
            listData: data.content,
            searchName: name,
          },
        }),
      )
    } catch (err) {
      callErrorMsg(err)
    }
  }

export const fetchWarehouseList = () => async (dispatch: StoreDispatchType) => {
  try {
    const { data: warehouseList } = await getLocationList('warehouse')
    const { data: hubList } = await getLocationList('hub')
    const hubAndWarehouseList = [...warehouseList, ...hubList]
    dispatch(setWarehouseList(hubAndWarehouseList))
  } catch (err) {
    callErrorMsg(err)
  }
}

export const fetchProductCategoryList =
  (actionCreatorName: ActionType, name?: string) => async (dispatch: StoreDispatchType) => {
    try {
      const { data } = await getProductCategory(name || '')
      const newList = data.content.map((parentItem) => ({
        categoryId: parentItem.category_id,
        categoryName: parentItem.category_name,
        items: parentItem.category_childs.map((childItem) => ({
          itemId: childItem.category_id,
          itemName: childItem.category_name,
        })),
      }))

      dispatch({
        type: actionCreatorName,
        payload: {
          search: name || '',
          listData: newList,
        },
      })
    } catch (err) {
      callErrorMsg(err)
    }
  }

export const fetchReorderListConfirmed =
  (status?: 'CONFIRMED') => async (dispatch: StoreDispatchType, getState: () => StoreStateType) => {
    const { filter, confirmedReorderList } = getState().reorderListV2
    dispatch(setIsLoading(true))

    const rlParams = getCleanQueryObj({
      ...filter,
      category_ids: filter.category_ids.length ? filter.category_ids.join(',') : '',
      status,
    }) as GetReorderListRequestParamType

    try {
      const {
        data: { data },
      } = await getReorderList(rlParams)

      const allUniqueIdInCurrentPage: string[] = []
      const allReorderListVendorIdInCurrentPage: number[] = []

      const listData = data.map((item, index) => {
        const listConfirmQty = item.vendor_list.map((vendorItem) => {
          allUniqueIdInCurrentPage.push(
            `${item.id}-${item.product.product_id}-${vendorItem.vendor_id}`,
          )
          allReorderListVendorIdInCurrentPage.push(vendorItem.id)
          return vendorItem.confirm_qty
        })

        const totalConfirmQty = listConfirmQty.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0,
        )

        return {
          ...item,
          isOpen: index === 0,
          isShowTooltip: totalConfirmQty < item.required_qty,
        }
      })

      const allUniqueIdInTable = {
        ...confirmedReorderList.allUniqueIdInTable,
        [filter.page_index]: allUniqueIdInCurrentPage,
      }
      const allReorderListVendorId = {
        ...confirmedReorderList.allReorderListVendorId,
        [filter.page_index]: allReorderListVendorIdInCurrentPage,
      }

      dispatch(
        setConfirmedReorderList({
          allUniqueIdInTable,
          allReorderListVendorId,
          listData,
        }),
      )

      dispatch(setIsLoading(false))
    } catch (err) {
      dispatch(setIsLoading(false))
      callErrorMsg(err)
    }
  }

export const fetchVendorList =
  (productId: number, locationId: number) =>
  async (dispatch: StoreDispatchType, getState: () => StoreStateType) => {
    const { modalAddEditVendor } = getState().reorderListV2
    try {
      const queryParams = {
        locationId,
        vendorName: modalAddEditVendor.vendorCombobox.searchVendorName,
      }
      const {
        data: { data },
      } = await getVendorsByProductId(productId, queryParams)
      const newList = data.map((item) => {
        const { picSourcing, ...restProps } = item
        return {
          ...restProps,
          name: item.vendor.vendorName,
          vendor_sourcing_pic: item.picSourcing.picId,
        }
      })
      dispatch(
        setVendorComboboxOfModalAddEditRl({
          listData: newList,
        }),
      )
    } catch (err) {
      callErrorMsg(err)
    }
  }

export const addorRemoveRlVendor =
  (reorderListId: number) =>
  async (dispatch: StoreDispatchType, getState: () => StoreStateType) => {
    const { modalAddEditVendor } = getState().reorderListV2

    const dataToSubmit = modalAddEditVendor.updatedVendorsInTable.map((item) => {
      const data: AddOrRemoveRlVendorRequestType = {
        shipping_date: item.shipping_date,
        price: item.price,
        discount: item.discount,
        confirm_qty: item.confirm_qty,
        vendor_id: item.vendor_id,
        reorder_list_reason_id: item.reorder_list_reason_id,
        vendor_sourcing_pic: item.vendor_sourcing_pic,
      }
      if (item?.id) data.id = item.id
      return data
    })

    try {
      await putRlVendor(reorderListId, dataToSubmit)
      dispatch(resetModalAddEditVendor())
      dispatch(fetchReorderListConfirmed('CONFIRMED'))
    } catch (err) {
      callErrorMsg(err)
    }
  }

const getReasonNameById = (reasonList: GetReasonListResponseType['data'], id: number) =>
  reasonList.find((reasonItem) => +reasonItem.id === id)?.reason || ''

export const fetchReasonList =
  (reasonType: GetReasonType) =>
  async (dispatch: StoreDispatchType, getState: () => StoreStateType) => {
    try {
      const { modalAddEditVendor } = getState().reorderListV2

      const {
        data: { data },
      } = await getReasonList(reasonType)
      const listReason = [...data]

      const newListWithSelectedReason = modalAddEditVendor.vendorsInTable.map((item) => {
        const { reorder_list_reason_id } = item
        return {
          ...item,
          selectedReason: {
            id: reorder_list_reason_id,
            reason: reorder_list_reason_id
              ? getReasonNameById(listReason, reorder_list_reason_id)
              : '',
          },
        }
      })
      dispatch(
        setModalAddEditVendor({
          listReason,
          vendorsInTable: newListWithSelectedReason,
          updatedVendorsInTable: newListWithSelectedReason,
        }),
      )
    } catch (err) {
      callErrorMsg(err)
    }
  }

export const updateRl =
  (reorderListId: number) =>
  async (dispatch: StoreDispatchType, getState: () => StoreStateType) => {
    const {
      modalEditRl: { data },
    } = getState().reorderListV2
    try {
      await putUpdateRlVendor(reorderListId, data)
      dispatch(resetModalEditRlState())
      dispatch(fetchReorderListConfirmed('CONFIRMED'))
    } catch (err) {
      callErrorMsg(err)
    }
  }

export const fetchRlPreview = () => async (dispatch: StoreDispatchType) => {
  try {
    const {
      data: { data },
    } = await getRlPreview()
    const listData = data.map((previewItem) => ({
      ...previewItem,
      uniqueId: crypto.randomUUID(),
      products: previewItem.products.map((productItem) => ({
        ...productItem,
        group: 1,
        selectedReason: {
          id: 0,
          reason: '',
        },
      })),
    }))

    dispatch(
      setPreviewReorderList({
        data: listData,
      }),
    )
  } catch (err) {
    callErrorMsg(err)
  }
}

export const saveChosenReorderListExcludeSameItem =
  (callbackFunction: () => void) =>
  async (dispatch: StoreDispatchType, getState: () => StoreStateType) => {
    const {
      modalSkuIsSaved,
      confirmedReorderList: { selectedReorderListVendorId },
    } = getState().reorderListV2

    dispatch(setIsSavingCheckedRl(true))

    const listIdsThatSame = modalSkuIsSaved.reorderListItem?.map(
      (reorderItem) => reorderItem.reorder_list_vendor_id,
    )
    const idToSubmit = selectedReorderListVendorId.filter(
      (rlVendorId) => !listIdsThatSame?.includes(rlVendorId),
    )
    const dataToSubmit = idToSubmit.map((id) => ({
      reorder_list_vendor_id: id,
      checked: true,
    }))

    try {
      await putSaveCheckedReorderList({ data: dataToSubmit })

      dispatch(setIsSavingCheckedRl(false))
      if (callbackFunction) callbackFunction()
    } catch (err) {
      callErrorMsg(err)
      dispatch(setIsSavingCheckedRl(false))
    }
  }

export const saveCheckedReorderList =
  (callbackFunction: () => void) =>
  async (dispatch: StoreDispatchType, getState: () => StoreStateType) => {
    const {
      confirmedReorderList: { originalSelectedReorderListVendorId, selectedReorderListVendorId },
    } = getState().reorderListV2

    dispatch(setIsSavingCheckedRl(true))

    const listDataNeedToSetAsFalse = originalSelectedReorderListVendorId
      .filter((rlVendorId) => !selectedReorderListVendorId.includes(rlVendorId))
      .map((id: number) => ({
        reorder_list_vendor_id: id,
        checked: false,
      }))

    const listDataNeedToSetAsTrue = selectedReorderListVendorId.map((id: number) => ({
      reorder_list_vendor_id: id,
      checked: true,
    }))

    const resultList = [...listDataNeedToSetAsFalse, ...listDataNeedToSetAsTrue]
    try {
      const {
        data: { data },
      } = await putSaveCheckedReorderList({ data: resultList })
      dispatch(setIsSavingCheckedRl(false))
      if (data?.reorder_list_detail?.length) {
        dispatch(setMModalSkuIsSaved({ isOpen: true, reorderListItem: data.reorder_list_detail }))
        return
      }
      if (callbackFunction) callbackFunction()
    } catch (err) {
      callErrorMsg(err)
      dispatch(setIsSavingCheckedRl(false))
    }
  }

type DeleteReorderListType = { indexToDelete: number; id: number }

export const deleteReorderList =
  (param: DeleteReorderListType, callbackFunction?: () => void) =>
  async (dispatch: StoreDispatchType) => {
    const { indexToDelete, id } = param
    try {
      await deleteRlUnconfirmed(id)
      dispatch(deleteListReorderToConfirmDataAtSpecificIndex({ index: indexToDelete }))
      if (callbackFunction) callbackFunction()
    } catch (err) {
      callErrorMsg(err)
    }
  }

export const confirmRlPreview =
  () => async (dispatch: StoreDispatchType, getState: () => StoreStateType) => {
    const {
      modalListReorderToConfirm: { data },
    } = getState().reorderListV2

    let errorMessageValidation = ''

    const isNotValid = data.some((item) => {
      if (!item.required_qty) errorMessageValidation = 'Qty dibutuhkan tidak boleh kosong'
      else if (!item.est_shipping_date)
        errorMessageValidation = 'Estimasi pengiriman tidak boleh kosong'
      return !item.required_qty || !item.est_shipping_date
    })

    if (isNotValid) {
      toastFailed(errorMessageValidation)
      return
    }

    const dataToSubmit = data.map((item) => ({
      reorder_list_id: item.id,
      location_id: item.location.location_id,
      product_id: item.product.product_id,
      required_qty: +item.required_qty,
      est_shipping_date: dateManipulation(item.est_shipping_date).add(7, 'hour').toISOString(),
    }))

    try {
      const { data: confirmRlResponse } = await putConfirmRlPreview({ data: dataToSubmit })
      dispatch(setModalListReorderToConfirm({ isOpen: false }))
      dispatch(fetchReorderListUnconfirmed({ withModalOpen: false }))
      dispatch(fetchReorderListConfirmed())
      toastSuccess(confirmRlResponse.data.message)
    } catch (err) {
      callErrorMsg(err)
    }
  }

export const createPurchaseRequest =
  (callbackFunction: () => void) =>
  async (dispatch: StoreDispatchType, getState: () => StoreStateType) => {
    const {
      previewReorderList: { data },
    } = getState().reorderListV2

    const dataForSubmit = data.map((itemData) => ({
      vendor_id: itemData.vendor_id,
      location_id: itemData.location_id,
      shipping_date: itemData.shipping_date,
      details: itemData.products.map((productItem) => ({
        price: productItem.price,
        discount: productItem.discount,
        product_id: productItem.product_id,
        reorder_list_id: productItem.reorder_list_id,
        qty: productItem.confirm_qty,
        group: productItem.group,
      })),
    }))

    try {
      await postCreatePurchaseRequest({ data: dataForSubmit })
      dispatch(setModalSavePreviewConfirmation({ isOpen: false, countPR: 0 }))
      if (callbackFunction) callbackFunction()
    } catch (err) {
      callErrorMsg(err)
    }
  }

export const fetchReorderListVendorChecked = () => async (dispatch: StoreDispatchType) => {
  try {
    const {
      data: { data },
    } = await getReorderListVendorChecked()
    const selectedIdInTable: string[] = []
    const reorderListVendorId: number[] = []
    data?.reorder_list_vendors.forEach((reorderItem) => {
      const uniqueId = `${reorderItem.reorder_list_id}-${reorderItem.product_id}-${reorderItem.vendor_id}`
      selectedIdInTable.push(uniqueId)
      reorderListVendorId.push(reorderItem.id)
    })
    dispatch(
      setConfirmedReorderList({
        countOfProduct: data.total_sku,
        countOfVendor: data.total_vendor,
        selectedIdInTable,
        selectedReorderListVendorId: reorderListVendorId,
        originalSelectedReorderListVendorId: reorderListVendorId,
      }),
    )
    dispatch(calculateCountOfProductAndVendor())
  } catch (err) {
    callErrorMsg(err)
  }
}

export const actDeleteRL = (id: number) => async (dispatch: StoreDispatchType) => {
  try {
    const {
      data: { data },
    } = await deleteRl(id)
    dispatch(resetModalAddEditVendor())
    dispatch(fetchReorderListVendorChecked())
    dispatch(fetchReorderListConfirmed('CONFIRMED'))
    toastSuccess(data.message)
  } catch (err) {
    callErrorMsg(err)
  }
}

export const actFetchAllVendorList =
  () => async (dispatch: StoreDispatchType, getState: () => StoreStateType) => {
    const { vendor } = getState().reorderListV2
    try {
      const { data } = await getAllVendors(vendor.query)
      dispatch(setVendor({ listData: data.data }))
    } catch (err) {
      callErrorMsg(err)
    }
  }
