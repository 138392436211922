import { isAsyncThunkAction } from '@reduxjs/toolkit'

export const withLoadingReducer = (extraReducers, asyncThunk) => (builder) => {
  extraReducers(builder)

  builder.addMatcher(isAsyncThunkAction(...asyncThunk), (state, action) => {
    const { requestStatus } = action.meta
    state.isLoading = requestStatus === 'pending'
  })
}

export const setPagination = (state, action) => {
  const {
    pagination: { pageSize, pageIndex, totalPages, totalElements, numberOfElements },
  } = action.payload

  state.pagination = {
    pageSize: pageSize,
    currentItem: numberOfElements,
    currentPage: pageIndex,
    totalData: totalElements,
    last: pageIndex === totalPages,
  }
}

export const setPaginationCustom = (state, action) => {
  const {
    pagination: {
      direction,
      numberOfElements,
      pageIndex,
      pageSize,
      sort,
      totalElement,
      totalPages,
    },
  } = action.payload

  state.pagination = {
    direction: direction,
    numberOfElements: numberOfElements,
    pageIndex,
    pageSize: pageSize,
    sort: sort,
    totalElements: totalElement,
    currentItem: numberOfElements,
    currentPage: pageIndex,
    last: pageIndex * pageSize >= totalPages,
  }
}

export const stopLoadingHandler = (state) => {
  state.isLoading = false
}
