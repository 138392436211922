import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { MasterVariant as Variant } from 'features/Product/@types'
import { SLICE_NAME, initialState, getVariantForm } from './state'
import { fetchMasterVariant } from './thunk'

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    setQuery: (state, { payload }: PayloadAction<Partial<Variant.QueryType>>) => {
      state.query = { ...state.query, ...payload }
    },
    setModalState: (state, { payload }: PayloadAction<Variant.ModalStateType>) => {
      state.modalState = payload
    },
    setFormTypeName: (state, { payload }: PayloadAction<Variant.VariantType['name']>) => {
      state.form.name = payload
    },
    setFormVariantName: (
      state,
      { payload }: PayloadAction<{ index: number; value: Variant.VariantType['name'] }>,
    ) => {
      const { index, value } = payload
      state.form.variants[index].name = value
    },
    addFormVariantName: (state) => {
      state.form.variants = [...state.form.variants, getVariantForm()]
    },
    removeFormVariantName: (state, { payload }: PayloadAction<number>) => {
      state.form.variants = state.form.variants.filter((_, index) => index !== payload)
    },
    resetForm: (state) => {
      state.form = initialState.form
      state.selectedVariantId = null
    },
    setForm: (state, { payload }: PayloadAction<Variant.MasterVariantType>) => {
      state.selectedVariantId = payload.id
      state.form.name = payload.name
      state.form.variants = [...payload.variants]
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMasterVariant.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchMasterVariant.fulfilled,
        (state, { payload }: PayloadAction<Variant.MasterVariantType[]>) => {
          state.masterVariantList = payload
          state.isLoading = false
        },
      )
      .addCase(fetchMasterVariant.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const {
  resetState,
  setQuery,
  setModalState,
  setFormTypeName,
  setFormVariantName,
  addFormVariantName,
  removeFormVariantName,
  resetForm,
  setForm,
} = slice.actions
export default slice.reducer
