export const themeProductTagging = {
  SCHEDULE_DELIVERY: 'info',
  QUICK_COMMERCE: 'info',
  ASTRO_GO: 'success',
}

export const styleBusinessTagging = {
  QUICK_COMMERCE: {
    backgroundColor: '#E6F7EF',
    color: '#00AA5B',
  },
  SCHEDULE_DELIVERY: {
    backgroundColor: '#E9F1FC',
    color: '#246EE5',
  },
  ASTRO_GO: {
    backgroundColor: '#FFF3E6',
    color: '#E67800',
  },
}

export const CONVERSION_RULE_TYPE = [
  {
    name: '1 to 1',
    value: 'SKU_CONVERSION',
  },
]
