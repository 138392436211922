import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ApprovalInjectionStateType,
  SetSelectedCustomerBalanceInjectionPayloadActionType,
  SetBulkSelectedCustomerBalanceInjectionPayloadActionType,
  QueryType,
  SetModalDataPayloadActionType,
} from 'features/Payment/@types/approvalInjection'
import { SLICE_NAME, fetchApprovalInjectionList } from './thunk'

const initialState: ApprovalInjectionStateType = {
  isLoading: false,
  query: { page: 0, limit: 20, order_id: '', injection_type: '', balance_type: '', status: '' },
  customerBalanceInjectionList: [],
  selectedCustomerBalanceInjection: [],
  pagination: {
    page: 1,
    limit: 20,
    has_next_page: false,
    has_prev_page: false,
  },
  modal: {
    isLoading: false,
    state: 'CLOSED',
    data: [],
    failedData: [],
  },
}

const campaignSegment = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    resetSelectedCustomerBalanceInjection: (state) => {
      state.selectedCustomerBalanceInjection = initialState.selectedCustomerBalanceInjection
    },
    setQuery: (state, action: PayloadAction<Partial<QueryType>>) => {
      state.query = { ...state.query, ...action.payload }
    },
    setSelectedCustomerBalanceInjectionList: (
      state,
      action: PayloadAction<SetSelectedCustomerBalanceInjectionPayloadActionType>,
    ) => {
      const { index, type } = action.payload

      if (type === 'ADD') {
        state.selectedCustomerBalanceInjection = [
          ...state.selectedCustomerBalanceInjection,
          state.customerBalanceInjectionList[index],
        ]
        state.customerBalanceInjectionList[index].isChecked = true
      }
      if (type === 'REMOVE') {
        state.selectedCustomerBalanceInjection = state.selectedCustomerBalanceInjection.filter(
          (el) => state.customerBalanceInjectionList[index].uniqueKey !== el.uniqueKey,
        )
        state.customerBalanceInjectionList[index].isChecked = false
      }
    },

    setBulkSelectedCustomerBalanceInjectionList: (
      state,
      action: PayloadAction<SetBulkSelectedCustomerBalanceInjectionPayloadActionType>,
    ) => {
      const { type, value } = action.payload
      if (type === 'ADD') {
        state.selectedCustomerBalanceInjection = [
          ...state.selectedCustomerBalanceInjection,
          ...value,
        ]
        state.customerBalanceInjectionList = state.customerBalanceInjectionList.map((el) => ({
          ...el,
          isChecked: !!value.find((item) => item.uniqueKey === el.uniqueKey),
        }))
      }
      if (type === 'REMOVE') {
        state.selectedCustomerBalanceInjection = state.selectedCustomerBalanceInjection.filter(
          (el) => !value.some((elSome) => el.uniqueKey === elSome.uniqueKey),
        )
        state.customerBalanceInjectionList = state.customerBalanceInjectionList.map((el) => ({
          ...el,
          isChecked: false,
        }))
      }
    },
    setModalData: (state, { payload }: PayloadAction<SetModalDataPayloadActionType>) => {
      state.modal = { ...state.modal, ...payload }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchApprovalInjectionList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchApprovalInjectionList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchApprovalInjectionList.fulfilled, (state, action) => {
        const { get_list_injection, pagination_response } = action.payload
        state.isLoading = false

        state.customerBalanceInjectionList = get_list_injection.map((el) => {
          const uniqeKey = `${el.id}-${el.order_id}-${el.amount}`

          return {
            ...el,
            isChecked: state.selectedCustomerBalanceInjection.some(
              (elSome) => uniqeKey === elSome.uniqueKey,
            ),
            uniqueKey: uniqeKey,
          }
        })

        state.pagination = pagination_response
      })
  },
})

export const {
  reset,
  setQuery,
  setSelectedCustomerBalanceInjectionList,
  setBulkSelectedCustomerBalanceInjectionList,
  setModalData,
  resetSelectedCustomerBalanceInjection,
} = campaignSegment.actions
export default campaignSegment.reducer
