import { memo } from 'react'
import styled from 'styled-components'
import { Contact, CounterUp } from 'components'
import { dateFormat } from 'utils/helpers/date'
import { OrderID } from '..'

function RowBackToHub({ data, isFromSuper }) {
  return (
    <Tr>
      <Td>
        {data.order_driver?.driver_full_name}- {data.order_driver?.driver_plate_number}{' '}
        <Contact className="ml-5 mt-4" phoneNumber={data?.order_driver?.driver_phone_number} />
      </Td>
      <Td>
        <CounterUp startFromTime={data.order_complete_at} />
      </Td>
      <Td>
        {dateFormat({
          date: data.order_complete_at,
          format: 'HH:mm',
          locale: 'id',
        })}
      </Td>
      <Td>
        {dateFormat({
          date: isFromSuper ? data.driver_back_to_hub : data.order_back_to_hub,
          format: 'HH:mm',
          locale: 'id',
        })}
      </Td>
      <Td>
        <OrderID data={data} noCheckbox />
      </Td>
    </Tr>
  )
}

RowBackToHub.defaultProps = {
  handleClickDetail: () => {},
  handleClickTrack: () => {},
  handleClickChangeDriver: () => {},
}

export default memo(RowBackToHub)

const Tr = styled.tr`
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
  .input-container {
    width: calc(100% - 20px);
  }
`
const Td = styled.td`
  color: ${({ theme: { colors }, color = 'textSoft' }) => colors[color]};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  padding: 16px 32px;
`

// const Wrapper = styled.div`
//   .input-drop-down {
//     max-width: 110px;
//   }
// `;

// const CustomButton = styled(Button)`
//   background-color: transparent;
//   color: #525a67;
//   border: 1px solid #aab4c8;
//   padding: 6px 32px;
//   font-weight: 400;
// `;
