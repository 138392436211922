import { Switch as MuiSwitch, SwitchProps as MuiSwitchProps } from '@mui/material'
import styled from 'styled-components'

export type SwitchPropsType = MuiSwitchProps

const Switch = styled(MuiSwitch)<SwitchPropsType>`
  width: 42px;
  height: 26px;
  padding: 0px;

  & .MuiSwitch-switchBase {
    padding: 0px;
    margin: 2px;
    transition-duration: 300ms;

    &.Mui-checked {
      transform: translateX(16px);
      color: white;

      & + .MuiSwitch-track {
        background-color: #246ee5;
        opacity: 1;
        border: 0;
      }

      &.Mui-disabled + .MuiSwitch-track {
        opacity: 0.5;
      }
    }

    &.Mui-focusVisible .MuiSwitch-thumb {
      color: #33cf4d;
      border: 6px solid #fff;
    }

    &.Mui-disabled .MuiSwitch-thumb {
      color: ${({ theme }) => theme.palette.grey[100]};
    }

    &.Mui-disabled + .MuiSwitch-track {
      opacity: 0.7;
    }
  }

  & .MuiSwitch-thumb {
    box-sizing: border-box;
    width: 22px;
    height: 22px;
  }

  & .MuiSwitch-track {
    border-radius: 13px;
    background-color: #e9e9ea;
    opacity: 1;
    transition: ${({ theme }) =>
      theme.transitions.create(['background-color'], {
        duration: 500,
      })};
  }
`

export default Switch
