import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootStateType } from 'store'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getParameterList,
  postParameter,
  putParameter,
  type ParameterDataType,
  type PostParameterPayloadType,
  type ParametersParamsType,
  type GetWatchTowerParameterResponseType,
  type CreateUpdateParameterResponseType,
} from 'utils/apiList/fraudWatchTowerParameters'

const SLICE_NAME = 'fraudWatchTowerParameters'

export type FraudWatchTowerParametersStateType = {
  isLoading: boolean
  list: ParameterDataType[]
  createEditForm: FraudWatchTowerParametersStateType['list'][0]
  showCreateEditModal: boolean
  pagination: ParametersParamsType
}

const initialState: FraudWatchTowerParametersStateType = {
  isLoading: false,
  list: [],
  createEditForm: {
    id: 0,
    name: '',
    field: '',
    fieldType: '',
  },
  pagination: {
    sort: 'id',
    direction: 'desc',
    pageSize: 10,
    pageIndex: 0,
    totalElement: 0,
    numberOfElements: 0,
    totalPages: 0,
  },
  showCreateEditModal: false,
}

const fraudParameters = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    reset: () => initialState,
    resetCreateEditForm: (state) => {
      state.createEditForm = initialState.createEditForm
    },
    setCreateEditForm: (
      state,
      action: PayloadAction<{
        key: keyof FraudWatchTowerParametersStateType['createEditForm']
        value: unknown
      }>,
    ) => {
      const { payload } = action

      state.createEditForm = {
        ...state.createEditForm,
        [payload.key]: payload.value,
      }
    },
    setPrefilledCreateEditForm: (
      state,
      action: PayloadAction<FraudWatchTowerParametersStateType['list'][0]>,
    ) => {
      const { payload } = action
      state.createEditForm = payload
    },
    setPagination: (state, action: PayloadAction<ParametersParamsType>) => {
      const { payload } = action
      state.pagination = {
        ...state.pagination,
        ...payload,
      }
    },
    setShowCreateEditModal: (state, action: PayloadAction<boolean>) => {
      const { payload } = action
      state.showCreateEditModal = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchParameterList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchParameterList.fulfilled,
        (state, action: PayloadAction<GetWatchTowerParameterResponseType>) => {
          state.list = action?.payload?.data?.parameters
          state.pagination = action?.payload?.pagination
          state.isLoading = false
        },
      )
      .addCase(fetchParameterList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(createParameter.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        createParameter.fulfilled,
        (state, action: PayloadAction<CreateUpdateParameterResponseType>) => {
          state.isLoading = false
          state.showCreateEditModal = false

          const newList = [...state.list]
          newList.unshift(action.payload.data)
          newList.pop()
          state.list = newList
        },
      )
      .addCase(createParameter.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateParameter.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        updateParameter.fulfilled,
        (state, action: PayloadAction<CreateUpdateParameterResponseType>) => {
          const { payload } = action
          state.isLoading = false
          state.showCreateEditModal = false

          const newList = [...state.list]
          state.list = newList.map((data) => {
            if (data.id === payload.data.id) return payload.data
            return data
          })
        },
      )
      .addCase(updateParameter.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const {
  setIsLoading,
  reset,
  resetCreateEditForm,
  setCreateEditForm,
  setPrefilledCreateEditForm,
  setPagination,
  setShowCreateEditModal,
} = fraudParameters.actions

export default fraudParameters.reducer

export const fetchParameterList = createAsyncThunk(
  `${SLICE_NAME}/fetchParameterList`,
  async (_, { rejectWithValue, getState }) => {
    try {
      const { pagination } = (getState() as RootStateType)[SLICE_NAME]
      const { data } = await getParameterList(pagination)
      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan detail watch tower rule')
      return rejectWithValue(err)
    }
  },
)

export const createParameter = createAsyncThunk(
  `${SLICE_NAME}/createParameter`,
  async (
    { payload, cb }: { payload: PostParameterPayloadType; cb: () => void },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await postParameter(payload)
      cb()
      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal menambahkan parameter baru')
      return rejectWithValue(err)
    }
  },
)

export const updateParameter = createAsyncThunk(
  `${SLICE_NAME}/updateParameter`,
  async (
    { payload, id }: { payload: PostParameterPayloadType; id: number },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await putParameter(payload, id)
      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal mengupdate parameter')
      return rejectWithValue(err)
    }
  },
)
