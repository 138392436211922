import { Suspense } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { LoadingPage } from 'components'
import useAppInit from 'customHooks/useAppInit'
import RouterApp from 'router'

function App() {
  useAppInit()

  return (
    <div>
      <ToastContainer
        className="print-none"
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        closeButton={false}
      />
      <Suspense fallback={<LoadingPage isLoading />}>
        <RouterApp />
      </Suspense>
    </div>
  )
}
export default App
