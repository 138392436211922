import { createAsyncThunk } from '@reduxjs/toolkit'
import { postPurchaseOrderLiteQRCode } from 'utils/apiList/purcaseOrderLite'
import { callErrorMsg } from 'helpers/errorMsg'
import { PrintQRRequestType } from 'utils/apiList/freshPurchaseOrderInbound'

const SLICE_NAME = 'printArea'

export const fetctDataPrintQR = createAsyncThunk(
  `${SLICE_NAME}/fetchDataPrintQR`,
  async (
    { payload, printQRQTY }: { payload: PrintQRRequestType; printQRQTY: number },
    { rejectWithValue },
  ) => {
    try {
      const res = await postPurchaseOrderLiteQRCode(payload)

      return { data: res.data.data, printQRQTY }
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
