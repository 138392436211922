import apiGateway from 'config/apiServiceApiGateway'
import { ProductInventoryType } from '../@types/StockOpnameAdd'
import { generateQueryObjectToStringWithSameName } from '../utils/queryStringFactory'

export type GetInventoriesParamsType = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'pagination.page_size': number
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'pagination.page_index': number
  location_ids: number
  product_types: number[]
  types: string
  search_product: string
  product_category_ids: number[]
  product_active?: boolean
}

export const getProductInventories = (params: GetInventoriesParamsType) => {
  const queryString = generateQueryObjectToStringWithSameName<GetInventoriesParamsType>(params)

  return apiGateway.Get<ProductInventoryType[]>({
    url: `/ims/internal/:version/inventories/paginated?${queryString}`,
    version: 'v1',
  })
}
