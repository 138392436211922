import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'

import {
  getPromoUserSegment,
  getPromoUserSegmentCSVDownload,
  type PromoUserSegmentType,
  type GetPromoUserSegmentParamsType,
  type GetPromoUserSegmentResponseType,
} from 'utils/apiList/promoUserSegment'

export type { PromoUserSegmentType, GetPromoUserSegmentParamsType }

const SLICE_NAME = 'campaignSegment'

export type CampaignSegmentStateType = {
  isLoading: boolean
  userSegmentList: PromoUserSegmentType[]
  pagination: {
    pageSize: number
    pageIndex: number
    numberOfElements: number
  }
  query: GetPromoUserSegmentParamsType
}

const initialState: CampaignSegmentStateType = {
  isLoading: false,
  userSegmentList: [],
  pagination: {
    pageSize: 20,
    pageIndex: 0,
    numberOfElements: 0,
  },
  query: {},
}

const campaignSegment = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    reset: () => initialState,
    setQuery: (state, action: PayloadAction<CampaignSegmentStateType['query']>) => {
      state.query = {
        ...state.query,
        ...action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPromoUserSegment.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchPromoUserSegment.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(
        fetchPromoUserSegment.fulfilled,
        (state, action: PayloadAction<GetPromoUserSegmentResponseType['data']>) => {
          const { payload } = action

          state.pagination = payload.pagination

          state.userSegmentList = payload.data.list

          state.isLoading = false
        },
      )
  },
})

export const { setIsLoading, reset, setQuery } = campaignSegment.actions
export default campaignSegment.reducer

export const fetchPromoUserSegment = createAsyncThunk(
  `${SLICE_NAME}/fetchPromoUserSegment`,
  async (params: GetPromoUserSegmentParamsType = {}, { rejectWithValue, getState }) => {
    try {
      const rootState = getState() as StoreStateType

      const { query, pagination } = rootState.campaignSegment

      const newParams = {
        page: pagination.pageIndex,
        limit: pagination.pageSize,
        ...query,
        ...params,
      }

      const { data } = await getPromoUserSegment(newParams)

      return data?.data
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan data segmen user')
      return rejectWithValue(err)
    }
  },
)

export const fetchromoUserSegmentCSVById = createAsyncThunk(
  `${SLICE_NAME}/fetchromoUserSegmentCSVById`,
  async (segmentId: number, { rejectWithValue }) => {
    try {
      const { data } = await getPromoUserSegmentCSVDownload(segmentId)

      return data.data.url
    } catch (err) {
      callErrorMsg(err, 'Gagal mengunduh list segment user')
      return rejectWithValue(err)
    }
  },
)
