type ExtractRouterType<T> = T extends (infer U)[] ? U : never

const generateRouterDetail = <
  Name extends string,
  Path,
  RouterList extends RouterFeatureInterface<Name, Path>[],
>(
  a: RouterList,
) =>
  a.reduce(
    (prev, curr) => ({
      ...prev,
      ...{
        [curr.name]: {
          path: curr.path as typeof curr.path,
          allowedRoles: curr.allowedRoles,
          rbacPageID: curr.rbacPageID,
        },
      },
    }),
    {},
  ) as {
    [T in ExtractRouterType<RouterList> as T['name']]: {
      path: T['path']
      allowedRoles: T['allowedRoles']
      rbacPageID: T['rbacPageID']
    }
  }

export default generateRouterDetail
