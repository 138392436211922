import React from 'react'
import styled from 'styled-components'

const CheckBox = ({ ...rest }) => {
  return (
    <Label>
      <CheckBoxContainer {...rest} disabled={rest.isDisabled} type="checkbox" />
      <CheckBoxCustom {...rest} />
    </Label>
  )
}

CheckBox.defaultProps = {
  checked: false,
  onChange: () => {},
}

export default React.memo(CheckBox)

const getBackgroundColor = (props) => {
  if (props.isDisabled) return '#cccccc'
  if (props.checked) return props.theme.colors.main
  return ''
}

const CheckBoxCustom = styled.span`
  position: absolute;
  display: block;
  height: 18px;
  width: 18px;
  transition: all ease 0.5s;
  border: 2px solid
    ${({ theme: { colors }, checked, isDisabled }) => {
      if (checked && !isDisabled) {
        return colors.main
      }

      if (checked && isDisabled) {
        return colors.grey10
      }

      return colors.grey10
    }};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  border-radius: 3px;
  background-color: ${(props) => getBackgroundColor(props)};
  &:after {
    content: '';
    top: 0;
    position: absolute;
    height: 8px;
    width: 4px;
    border: solid ${({ checked }) => (checked ? '#fff' : 'transparent')};
    border-width: 0 3px 3px 0;
    border-radius: 2px;
    transform: rotate(45deg);
    left: 3px;
  }
`

const Label = styled.label`
  position: relative;
  display: block;
  width: 18px;
  height: 18px;
  cursor: pointer;
`

const CheckBoxContainer = styled.input`
  position: absolute;
  opacity: 0;
  border: 1px solid #aab4c8;
  cursor: pointer;
`
