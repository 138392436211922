import { memo, useState, useRef, useEffect } from 'react'
import GoogleMapReact from 'google-map-react'
import styled from 'styled-components'
import { InputDropDown } from 'components'
import {
  getCustomerAddress_AllOrder,
  getAddressAutoComplete_AllOrder,
} from 'utils/apiList/googleMaps'
import { toastFailed } from 'utils/toast'
import pinpointIcon from 'assets/images/icon_map_pinpoint.svg'
import usePlacesAutocomplete, { getGeocode, getLatLng, getZipCode } from 'use-places-autocomplete'

const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env

const GMAPS_API_KEY = REACT_APP_GOOGLE_MAPS_API_KEY
const AnyReactComponent = ({ text, isShowMarker, handleClick }) => (
  <MarkerContainer
    // className={isShowMarker ? "show-marker" : ""}
    onClick={handleClick}
  >
    <BlueMarkerContainer className={isShowMarker ? 'show-marker' : ''}>
      <ButtonContainer>
        <TextContainer>Gunakan Alamat Ini</TextContainer>
        <IconTriangle />
      </ButtonContainer>
      {text}
    </BlueMarkerContainer>
    <ImagePinPoint src={pinpointIcon} />
  </MarkerContainer>
)

function GoogleMaps({
  height,
  width,
  fullScreen,
  handleClickMarker,
  handleClickLocaion,
  children,
  prefix,
  lng,
  lat,
  withHideMarker,
  valueSelected,
  withEmptyValue,
  ...rest
}) {
  const refAddressAutocomplete = useRef()
  const { setValue } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'id' },
    },
  })

  const initForm = {
    marker: { lng, lat },
    defaultProps: {
      center: { lat, lng },
      zoom: 14,
    },
    selectedOptionLocation: {
      name: '',
    },
  }

  const initOptionLocation = [{ name: '' }]

  // GLOBAL STATE

  const [form, setForm] = useState(initForm)
  const [isShowMarker, setIsShowMarker] = useState(false)
  const [optionLocation, setOptionLocation] = useState(initOptionLocation)

  const handleClick = (key) => {
    setIsShowMarker(true)
    setForm((prev) => {
      return {
        ...prev,
        marker: {
          ...prev.marker,
          lng: key.lng,
          lat: key.lat,
        },
      }
    })
  }

  const handleClickMarkers = (e) => {
    e.stopPropagation()

    if (withHideMarker) {
      setIsShowMarker(false)
    }

    getCustomerAddress_AllOrder(`?lat=${lat}&lng=${lng}`)
      .then((res) => {
        const { results } = res.data.data

        const modifiedResult = results.map((el) => {
          return {
            ...el,
            name: el.formattedAddress,
          }
        })
        const firstData = modifiedResult.length > 0 && modifiedResult[0]

        handleClickLocaion({
          ...firstData,
          structuredFormatting: {
            secondaryText: firstData.name,
            mainText: '',
          },
        })
        handleClickMarker(modifiedResult)
        setOptionLocation(modifiedResult)
        setForm((prev) => {
          return { ...prev, selectedOptionLocation: firstData }
        })
      })
      .catch((err) => {
        toastFailed('terjadi masalah, coba beberapa saat lagi')
      })
  }

  const handleClickOptionLocation = async (location) => {
    const address = location.name
    try {
      const result = await getGeocode({ address })
      const { lat, lng } = await getLatLng(result[0])
      const zipCode = await getZipCode(result[0], false)

      await setForm((prev) => {
        return {
          ...prev,
          marker: {
            ...prev.marker,
            lng: lng,
            lat: lat,
          },
          defaultProps: {
            ...prev.defaultProps,
            center: {
              lat: lat,
              lng: lng,
            },
            zoom: 15,
          },
        }
      })

      const structuredstructuredFormatting = () => {
        if (location.structuredFormatting) {
          return { structuredFormatting: { ...location.structuredFormatting } }
        } else {
          return {
            structuredFormatting: {
              secondaryText: location.name,
              mainText: '',
            },
          }
        }
      }

      handleClickLocaion({
        ...location,
        ...structuredstructuredFormatting(),
        geometry: {
          location: {
            lat: lat,
            lng: lng,
          },
        },
        zip: zipCode,
      })
    } catch (error) {
      //
    }
    setForm((prev) => {
      return { ...prev, selectedOptionLocation: location }
    })
  }

  const handleChangeSearchOption = (e) => {
    const {
      target: { value },
    } = e

    const { lat, lng } = form.marker
    setValue(value)

    if (!withEmptyValue && !value) {
      setOptionLocation([])
      return
    }

    clearTimeout(refAddressAutocomplete.current)
    refAddressAutocomplete.current = setTimeout(() => {
      getAddressAutoComplete_AllOrder(
        `input=${value}&lat=${form.marker.lat}&lng=${form.marker.lng}`,
      )
        .then((res) => {
          const {
            data: {
              data: { predictions },
            },
          } = res
          if (predictions !== null) {
            const modifiedResult = predictions.map((el) => {
              return {
                ...el,
                name: el.description,
                geometry: {
                  location: {
                    lat: lat,
                    lng: lng,
                  },
                },
              }
            })

            setOptionLocation(modifiedResult)
          } else setOptionLocation([])
        })
        .catch((err) => {
          toastFailed('terjadi masalah, coba beberapa saat lagi')
        })
    }, [500])
  }

  useEffect(() => {
    setForm((prev) => {
      return { ...prev, selectedOptionLocation: { name: valueSelected } }
    })
  }, [valueSelected])

  return (
    // Important! Always set the container height explicitly
    <Container style={{ height: height, width: width }} fullScreen={fullScreen}>
      {children}
      <InputDropDown
        isManualFilter
        listOptions={optionLocation}
        handleClickOption={handleClickOptionLocation}
        value={form.selectedOptionLocation.name}
        handleSearchOption={handleChangeSearchOption}
        prefix={prefix}
      />
      <GoogleMapReact
        bootstrapURLKeys={{ key: GMAPS_API_KEY }}
        defaultCenter={form.defaultProps.center}
        defaultZoom={form.defaultProps.zoom}
        center={form.defaultProps.center}
        {...rest}
        onClick={handleClick}
      >
        <AnyReactComponent
          //REQUIRED
          lat={form.marker.lat}
          lng={form.marker.lng}
          text=""
          //OPTIONAL
          isShowMarker={isShowMarker}
          handleClick={handleClickMarkers}
        />
      </GoogleMapReact>
    </Container>
  )
}

GoogleMaps.defaultProps = {
  height: '100%',
  width: '100%',
  fullScreen: false,
  children: null,
  prefix: '',
  lng: 106.84989629009233,
  lat: -6.427563115316062,
  valueSelected: '',
  withHideMarker: false,
  withEmptyValue: true,
  handleClickMarker: (data) => {},
  handleClickLocaion: (data) => {},
  handleGetCurrentData: (data) => {},
}

export default memo(GoogleMaps)
const Container = styled.div`
  * {
    transition: none;
  }
  .show-marker {
    display: block;
    cursor: pointer;
    border-radius: 4px;
    user-select: none;
    position: absolute;
    bottom: 44px;
    left: -100px;
  }
  .input-drop-down {
    position: absolute;
    width: ${({ fullScreen }) => (fullScreen ? `86%` : `96%`)};

    margin-left: 2%;
    margin-right: ${({ fullScreen }) => (fullScreen ? `7%` : `2%`)};
    margin-top: 1.7%;
    top: 0;
    left: 0;
    z-index: 10;
  }

  .gm-fullscreen-control {
    ${({ fullScreen }) => !fullScreen && `display:none;`}
  }

  position: relative;
`

const MarkerContainer = styled.div`
  display: flex;
`

const BlueMarkerContainer = styled.div`
  display: none;
  background: #246ee5;
  width: 180px;
  position: relative;
`

const ImagePinPoint = styled.img`
  position: absolute;
  left: -12px;
  bottom: 4px;
`

const TextContainer = styled.div`
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  user-select: none;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: auto;
`

const ButtonContainer = styled.div`
  padding: 8px 16px;
  display: flex;
`

const IconTriangle = styled.div`
  width: 15px;
  height: 15px;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  transform: rotate(317deg);
  margin: 8px;
`
