import axios from 'axios'

const { REACT_APP_BASE_URL_API } = process.env

export interface FPOTPDetailResponseType {
  id: number
  packingNumber: string
  inboundNumber: string
  fpoNumber: string
  location: {
    locationId: number
    locationName: string
    locationType: string
  }
  status: string
  createdAt: number
  createdBy: string
  updatedAt: number
  updatedBy: string
  items: {
    uniqueKey: string
    id: number
    product: {
      id: number
      name: string
      sku: string
      weight: number
      age: number
      baseUom: string
    }
    qty: number
    totalReceiveQty: number
    expiryDate: number
    manageExpiryDate: boolean
    itemDetails: {
      uniqueKey: string
      id: number
      freshPurchasePackingId: number
      freshPurchasePackingItemId: number
      product: {
        id: number
        name: string
        sku: string
        weight: number
        age: number
        baseUom: string[]
      }
      rack: {
        rackId: number
        rackName: string
      }
      expiryDate: number
      manageExpiryDate: boolean
      uom: string
      qty: number
      packageId: {
        id: number
        packageLabel: string
      }
      statusId: number
      statusName: string
      statusNoteId: number
      statusNoteName: null | string
    }[]
  }[]
}

export interface FPOTPItemDetailResponseType {
  id: number
  productId: number
  productName: string
  sku: string
  rackId: number
  rackName: string
  qty: number
  product: {
    id: number
    name: string
    sku: string
    weight: number
    age: number
    baseUom: string[]
  }
  uom: string
  expiredDate: number
}

export interface FPOTPPackageIdResponseType {
  id: number
  packageLabel: number | string
  createdAt: number
}

export interface FPOTPPostPutResponseType {
  error: {
    status: boolean
    message: string
    code: number
  }
  message: string
}

export interface FPOTPDeleteResponseType {
  error: {
    status: boolean
    message: string
    code: number
  }
  message: string
}

export interface RackListResponseType {
  id: number
  rack_name: string
}

export interface StatusListResponseType {
  id: number
  displayName: string
}

export interface StatusNoteListResponseType {
  id: number
  displayName: string
}

export interface RackListRequestType {
  location_id: string
  query: string
}

export interface GetItemRequestType {
  packing_id: string
  sku: string
}

export interface FPOTPPackageIdRequestType {
  sku: string
}

export interface FPOTPSubmitRequestType {
  freshPurchasePackingItemId: number
  packageId: number
  receivedQty: number
  rackId: number
  statusId: number
  statusNoteId: number
  expiryDate: string
}

export interface FPOTPUpdateSLOCRequestType {
  trimming_packing_id: string
  payload: {
    freshPurchasePackingItemId: number
    freshPurchasePackingItemDetailId: number
    rackId: number
  }
}

export interface FPOTPUpdateSLOCResponseType {
  error: {
    status: boolean
    message: string
    code: number
  }
  message: string
}

export const getFPOTPItem = ({ packing_id, sku }: GetItemRequestType) =>
  axios.get<{ data: FPOTPItemDetailResponseType }>(
    `${REACT_APP_BASE_URL_API}/api/wms/fresh-purchase/trimming-packing/v1/${packing_id}/items/${sku}`,
  )

export const getFPOTPDetail = (id: string) =>
  axios.get<{ data: FPOTPDetailResponseType }>(
    `${REACT_APP_BASE_URL_API}/api/wms/fresh-purchase/trimming-packing/v1/${id}`,
  )

export const getFPOTPPackageId = ({ sku }: FPOTPPackageIdRequestType) =>
  axios.get<{ data: FPOTPPackageIdResponseType }>(
    `${REACT_APP_BASE_URL_API}/api/wms/package-id/v1/${sku}`,
  )

export const postFPOTPSubmit = (packing_id: string, payload: FPOTPSubmitRequestType) =>
  axios.post<FPOTPPostPutResponseType>(
    `${REACT_APP_BASE_URL_API}/api/wms/fresh-purchase/trimming-packing/v1/submit/${packing_id}`,
    payload,
  )

export const postFPOTSave = (packing_id: string) =>
  axios.post<FPOTPPostPutResponseType>(
    `${REACT_APP_BASE_URL_API}/api/wms/fresh-purchase/trimming-packing/v1/process/${packing_id}`,
  )

export const deleteFPOTDelete = (id: string) =>
  axios.delete<FPOTPDeleteResponseType>(
    `${REACT_APP_BASE_URL_API}/api/wms/fresh-purchase/trimming-packing/v1/items/${id}`,
  )

export const putFPOTUpdateSLOC = ({ trimming_packing_id, payload }: FPOTPUpdateSLOCRequestType) =>
  axios.put<FPOTPUpdateSLOCResponseType>(
    `${REACT_APP_BASE_URL_API}/api/wms/fresh-purchase/trimming-packing/v1/item/update/${trimming_packing_id}`,
    payload,
  )

export const getRackList = ({ location_id, query }: RackListRequestType) =>
  axios.get<{ racks: { content: RackListResponseType[] } }>(
    `${REACT_APP_BASE_URL_API}/api/racks/${location_id}${query}`,
  )

export const getStatusList = () =>
  axios.get<{ data: { status: StatusListResponseType[] } }>(
    `${REACT_APP_BASE_URL_API}/api/inventory-status`,
  )

export const getStatusNotesList = (id: string) =>
  axios.get<{ data: { notes: StatusNoteListResponseType[] } }>(
    `${REACT_APP_BASE_URL_API}/api/inventory-status-notes?inventory_status_id=${id}`,
  )
