export const currentColor = {
  transparent: 'transparent',
  whiteFaded: 'rgba(255, 255, 255, 0.32)',
  white: '#FFFFFF',
  main: '#2455B0',
  green: '#00BA6C',
  text: '#40464E',
  secondaryText: '#778092',
  mainFaded: 'rgba(239, 243, 249, 1)',
  grey: '#AAB4C8',
  softGrey: '#F7F9FA',
  softGrey2: '#2455b014',
  softGrey3: '#BFC9D9',
  softGrey4: '#D6DFEB',
  softGrey5: '#E9E9E9',
  softGrey6: '#E0E0E0',
  blackFaded: 'rgba(0, 0, 0, 0.5)',
  red: '#EC465C',
  redFaded: 'rgba(236, 70, 92, .1)',
  tableHead: '#FAFCFE',
  body: '#F3F6FA',
  grey70: '#8D96AA',
  textSoft: '#525A67',
  border: '#e4eaf3',
  mainSoft: '#76cd9f',
  orange: '#FA591D',
  orange1: '#FEF2E6',
  orange2: '#F97B06',
  yellow: '#FFF0B3',
  pink: '#FF5C84',
  pinkFaded: '#FFEAEF',
  grass: '#03AC0E',
  grassFaded: '#D6FFDE',
  alertBg: '#FFE163',
  yellowFaded: '#FFFAE6',
  yellowAlert: '#FFF1BA',
  black: '#333333',
  pureBlack: 'black',
  blueFaded: 'rgba(36, 85, 176, 0.1)',
  blue: '#2455B0',
  maroon: '#CC4339',
  redTag: '#DD342F',
  blueTag: '#246EE5',
  yellowTag: '#FFBB0C',
  green100: '#098A4E',
  greySoft: '#6D7588',
  bgIcon: '#F3F4F5',
  blueSoft: '#0479B9',
  blueTosca: '#C5FCFF',
  mercuryGrey: '#F3F4F5',
  mainV2: '#246EE5',
  textV2: '#212121',
  greenLegend: '#2ABF6C',
  yellowLegend: '#FFE600',
  redLegend: '#FF0021',
  tableBlue: '#F4F9FF',
  green1: '#E7F5E6',
  green5: '#42B139',
  green6: '#139D07',
  red6: '#E25450',
  neutralGrey: '#F0F2F5',
  blackToast: '#3E3E3E',
  grey64: '#646464',
  greyA6: '#A6A6A6',
  greyE9: '#E9E9E9',
  spaceBlack: '#5C5C5C',
  greyD3: '#D3D3D3',
  warning: '#E67800',
  success: '#00AA5B',
  pending: '#F94D63',
  inprogress: '#FFBB0C',
  done: '#00AA5B',
  lightOrange: '#FFF3E6',
  greenTag: '#E6F7EF',
  purpleTag: '#F0EDF8',
  purpleText: '#6847BB',
  disabled: 'rgba(0, 0, 0, 0.26)',
  skpTableHeadBg: '#78909C',
  bgLightBlue: '#E9F1FC',
  redOutline: '#FF1E00',
  softRed: '#e65a67',
  lightBlue: '#E9F1FC',
  bgDisabled: '#FAFAFA',
  bgOrange: '#FF8500',
  yellowError: '#F08227',
}

export const red = {
  red1: '#fdeaea',
  red2: '#fabfbd',
  red3: '#f7a09d',
  red4: '#f47571',
  red5: '#f15a55',
  red6: '#ee312b',
  red7: '#d92d27',
  red8: '#a9231f',
  red9: '#831b18',
  red900: '#641512',
}

export const blue = {
  blue1: '#eaf1fd',
  blue2: '#bdd4fa',
  blue3: '#9dc0f7',
  blue4: '#71a3f4',
  blue5: '#5591f1',
  blue6: '#2b75ee',
  blue7: '#276ad9',
  blue8: '#1f53a9',
  blue9: '#184083',
  blue900: '#123164',
  blue10: '#246EE5',
}

export const grey = {
  grey0: '#ffffff',
  grey1: '#eeeeee',
  grey2: '#cccccc',
  grey3: '#b3b3b3',
  grey4: '#909090',
  grey5: '#7a7a7a',
  grey6: '#595959',
  grey7: '#515151',
  grey8: '#252525',
  grey9: '#0e0e0e',
  grey10: '#d1d1d1',
}

export const green = {
  green50: '#e7f5e6',
  green100: '#b6e1b2',
  green200: '#92d28d',
  green300: '#61bd59',
  green400: '#42b139',
  green500: '#139d07',
  green600: '#118f06',
  green700: '#0d6f05',
  green800: '#0a5604',
  green900: '#084203',
}

export const orange = {
  orange50: '#fef2e6',
  orange100: '#fdd6b2',
  orange200: '#fcc28c',
  orange300: '#fba758',
  orange400: '#fa9538',
  orange500: '#f97b06',
  orange600: '#e37005',
  orange700: '#b15704',
  orange800: '#894403',
  orange900: '#693403',
}

const colors = {
  ...currentColor,
  ...red,
  ...blue,
  ...grey,
  ...green,
  ...orange,
}

export type ColorsType = typeof colors
export default colors
