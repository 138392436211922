import { createAsyncThunk } from '@reduxjs/toolkit'
import { getProductModifiers, putProductModifier } from 'features/Product/services/modifier'
import { callErrorMsg } from 'helpers/errorMsg'
import { getCleanQueryObj } from 'utils/queryParamsURL'
import { Product } from 'features/Product/@types'
import { getProducts } from 'features/Product/services/product'
import { toastSuccess } from 'utils/toast'
import { SLICE_NAME, setIsLoading, setModifierConnectionData, setProductData } from './slice'

export const fetchProductModifiers = createAsyncThunk<void, boolean>(
  `${SLICE_NAME}/fetchProductModifiers`,
  async (refetchAll, { getState, dispatch }) => {
    const {
      modifierConnection: { query, modifierConnection },
    } = getState() as StoreStateType

    dispatch(setIsLoading(true))

    try {
      const [{ data: product }, { data: productModifier }] = await Promise.all([
        getProducts(getCleanQueryObj(query) as Partial<Product.QueryType>),
        ...(!modifierConnection.data.length || refetchAll
          ? [getProductModifiers({ page_size: 10000 })]
          : [Promise.resolve({ data: modifierConnection })]),
      ])

      dispatch(setModifierConnectionData(productModifier))
      const { data, pagination } = product

      const productPayload = data.map((item) => {
        const productModifierMatch = productModifier.data.find(
          (modifier) => modifier.product.product_id === item.productID,
        )

        return {
          ...item,
          modifiers: productModifierMatch?.modifiers.filter(({ is_active }) => is_active) || [],
        }
      })

      dispatch(setProductData({ pagination, data: productPayload }))
    } catch (err) {
      callErrorMsg(err)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const updateProductModifier = createAsyncThunk(
  `${SLICE_NAME}/updateProductModifier`,
  async (_, { getState }) => {
    const {
      modifierConnection: { selectedProduct, checkedModifiers },
    } = getState() as StoreStateType

    const payload = {
      modifiers: checkedModifiers.map(({ id }) => ({ id })),
    }

    try {
      const {
        data: { message },
      } = await putProductModifier(selectedProduct?.productID as number, payload)

      toastSuccess(message)
      return true
    } catch (err) {
      callErrorMsg(err)
      return false
    }
  },
)
