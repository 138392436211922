import { lazy } from 'react'

import { RolesKeyType, roles } from 'constant/roles'
import generateRouterDetail from 'utils/helpers/generateRouterDetail'

const Sourcing = lazy(() => import('../pages/Sourcing'))
const SourcingDetails = lazy(() => import('../pages/SourcingDetails'))
const Finance = lazy(() => import('../pages/Finance'))
const FinanceDetails = lazy(() => import('../pages/FinanceDetails'))
const Campaign = lazy(() => import('../pages/Campaign'))
const CampaignDetails = lazy(() => import('../pages/CampaignDetails'))
const AddEditDuplicateSKP = lazy(() => import('../pages/AddEditDuplicateSKP'))
const TermsAndCondition = lazy(() => import('../pages/SyaratKetentuan'))
const Internal = lazy(() => import('../pages/Internal'))
const InternalDetails = lazy(() => import('../pages/InternalDetails'))
const AddEditDuplicateInternalSKP = lazy(() => import('../pages/AddEditDuplicateInternalSKP'))

const SourcingRouter: RouterFeatureInterface<'SourcingSKP', '/dashboard/skp/sourcing'> = {
  name: 'SourcingSKP',
  Component: Sourcing,
  path: '/dashboard/skp/sourcing',
  routeType: 'PRIVATE',
  allowedRoles: [roles.AUTH_SOURCING, roles.AUTH_SOURCING_MANAGER],
  isDashboard: true,
}

const TermsAndConditionRouter: RouterFeatureInterface<
  'TermsAndConditionSKP',
  '/dashboard/skp/terms-and-conditions'
> = {
  name: 'TermsAndConditionSKP',
  Component: TermsAndCondition,
  path: '/dashboard/skp/terms-and-conditions',
  routeType: 'PRIVATE',
  allowedRoles: [roles.AUTH_SOURCING, roles.AUTH_SOURCING_MANAGER],
  isDashboard: true,
}

const SourcingDetailsRouter: RouterFeatureInterface<
  'SourcingDetailsSKP',
  '/dashboard/skp/sourcing/details/:id'
> = {
  name: 'SourcingDetailsSKP',
  Component: SourcingDetails,
  path: '/dashboard/skp/sourcing/details/:id',
  routeType: 'PRIVATE',
  allowedRoles: [roles.AUTH_SOURCING, roles.AUTH_SOURCING_MANAGER],
  isDashboard: true,
}

export const FinanceRouter: RouterFeatureInterface<'FinanceSKP', '/dashboard/skp/finance'> = {
  name: 'FinanceSKP',
  Component: Finance,
  path: '/dashboard/skp/finance',
  routeType: 'PRIVATE',
  allowedRoles: [
    roles.AUTH_FINANCE_ADMIN,
    roles.AUTH_HO_CATEGORY_HEAD_PL_FOOD,
    roles.AUTH_HO_SOURCING_HEAD,
  ],
  isDashboard: true,
}

const FinanceDetailsRouter: RouterFeatureInterface<
  'FinanceSKPDetails',
  '/dashboard/skp/finance/details/:id'
> = {
  name: 'FinanceSKPDetails',
  Component: FinanceDetails,
  path: '/dashboard/skp/finance/details/:id',
  routeType: 'PRIVATE',
  allowedRoles: [
    roles.AUTH_FINANCE_ADMIN,
    roles.AUTH_HO_CATEGORY_HEAD_PL_FOOD,
    roles.AUTH_HO_SOURCING_HEAD,
  ],
  isDashboard: true,
}

export const CampaignRouter: RouterFeatureInterface<'CampaignSKP', '/dashboard/skp/campaign'> = {
  name: 'CampaignSKP',
  Component: Campaign,
  path: '/dashboard/skp/campaign',
  routeType: 'PRIVATE',
  allowedRoles: [
    roles.AUTH_MARKETING,
    roles.AUTH_HO_MARKETING,
    roles.AUTH_MERCHANDISING_WAREHOUSE,
    roles.AUTH_MERCHANDISING_HUB,
  ],
  isDashboard: true,
}

const CampaignDetailsRouter: RouterFeatureInterface<
  'CampaignDetailsSKP',
  '/dashboard/skp/campaign/details/:id'
> = {
  name: 'CampaignDetailsSKP',
  Component: CampaignDetails,
  path: '/dashboard/skp/campaign/details/:id',
  routeType: 'PRIVATE',
  allowedRoles: [
    roles.AUTH_MARKETING,
    roles.AUTH_HO_MARKETING,
    roles.AUTH_MERCHANDISING_WAREHOUSE,
    roles.AUTH_MERCHANDISING_HUB,
  ],
  isDashboard: true,
}

const AddSKP: RouterFeatureInterface<'AddSKP', '/dashboard/skp/add'> = {
  name: 'AddSKP',
  Component: AddEditDuplicateSKP,
  path: '/dashboard/skp/add',
  routeType: 'PRIVATE',
  allowedRoles: [roles.AUTH_SOURCING, roles.AUTH_SOURCING_MANAGER],
  isDashboard: true,
}

const EditSKP: RouterFeatureInterface<'EditSKP', '/dashboard/skp/edit/:id'> = {
  name: 'EditSKP',
  Component: AddEditDuplicateSKP,
  path: '/dashboard/skp/edit/:id',
  routeType: 'PRIVATE',
  allowedRoles: [roles.AUTH_SOURCING, roles.AUTH_SOURCING_MANAGER],
  isDashboard: true,
}

const DuplicateSKP: RouterFeatureInterface<'DuplicateSKP', '/dashboard/skp/duplicate/:id'> = {
  name: 'DuplicateSKP',
  Component: AddEditDuplicateSKP,
  path: '/dashboard/skp/duplicate/:id',
  routeType: 'PRIVATE',
  allowedRoles: [roles.AUTH_SOURCING, roles.AUTH_SOURCING_MANAGER],
  isDashboard: true,
}

const internalRouteRoles: RolesKeyType[] = [
  'AUTH_HO_CATEGORY_HEAD_PL_FOOD',
  'AUTH_HO_CATEGORY_MANAGER_PL_FOOD',
  'AUTH_HO_CATEGORY_SPV_PL_FOOD',
  'AUTH_HO_CATEGORY_STAFF_PL_FOOD',
  'AUTH_HO_CATEGORY_HEAD_PL_NON_FOOD',
  'AUTH_HO_CATEGORY_MANAGER_PL_NON_FOOD',
  'AUTH_HO_CATEGORY_SPV_PL_NON_FOOD',
  'AUTH_HO_CATEGORY_STAFF_PL_NON_FOOD',
  'AUTH_MARKETING',
  'AUTH_HO_MARKETING',
]

export const InternalSKPRouter: RouterFeatureInterface<
  'InternalSKP',
  '/dashboard/skp/internal-skp'
> = {
  name: 'InternalSKP',
  Component: Internal,
  path: '/dashboard/skp/internal-skp',
  routeType: 'PRIVATE',
  allowedRoles: [...internalRouteRoles, 'AUTH_HO_CUSTOMER_GROWTH_CONTROLLER'],
  isDashboard: true,
}

export const InternalSKPDetailsRouter: RouterFeatureInterface<
  'InternalDetailsSKP',
  '/dashboard/skp/internal-skp/details/:id'
> = {
  name: 'InternalDetailsSKP',
  Component: InternalDetails,
  path: '/dashboard/skp/internal-skp/details/:id',
  routeType: 'PRIVATE',
  allowedRoles: [...internalRouteRoles, 'AUTH_HO_CUSTOMER_GROWTH_CONTROLLER'],
  isDashboard: true,
}

export const AddInternalSKPRouter: RouterFeatureInterface<
  'AddInternalSKP',
  '/dashboard/skp/add-internal-skp'
> = {
  name: 'AddInternalSKP',
  Component: AddEditDuplicateInternalSKP,
  path: '/dashboard/skp/add-internal-skp',
  routeType: 'PRIVATE',
  allowedRoles: internalRouteRoles,
  isDashboard: true,
}

export const EditInternalSKPRouter: RouterFeatureInterface<
  'EditInternalSKP',
  '/dashboard/skp/edit-internal-skp/:id'
> = {
  name: 'EditInternalSKP',
  Component: AddEditDuplicateInternalSKP,
  path: '/dashboard/skp/edit-internal-skp/:id',
  routeType: 'PRIVATE',
  allowedRoles: internalRouteRoles,
  isDashboard: true,
}

export const DuplicateInternalSKPRouter: RouterFeatureInterface<
  'DuplicateInternalSKP',
  '/dashboard/skp/duplicate-internal-skp/:id'
> = {
  name: 'DuplicateInternalSKP',
  Component: AddEditDuplicateInternalSKP,
  path: '/dashboard/skp/duplicate-internal-skp/:id',
  routeType: 'PRIVATE',
  allowedRoles: internalRouteRoles,
  isDashboard: true,
}

const SKPRouter = [
  SourcingRouter,
  SourcingDetailsRouter,
  FinanceRouter,
  FinanceDetailsRouter,
  CampaignRouter,
  CampaignDetailsRouter,
  AddSKP,
  EditSKP,
  DuplicateSKP,
  InternalSKPRouter,
  InternalSKPDetailsRouter,
  AddInternalSKPRouter,
  EditInternalSKPRouter,
  DuplicateInternalSKPRouter,
  TermsAndConditionRouter,
]

export const SKPRouterDetail = generateRouterDetail(SKPRouter)

export default SKPRouter
