import { createAsyncThunk } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import {
  postCampaignLoyaltyList,
  getCampaignLoyaltyById,
  putCampaignLoyalty,
  getCampaignLoyaltyProductCandidateList,
  getCampaignLoyaltyProductList,
  deleteCampaignLoyaltyProduct,
  putCampaignLoyaltyProduct,
  getLocationHubs,
} from 'utils/apiList/campaignLoyalty'
import type {
  GetCampaignLoyaltyByIdRequestType,
  GetCampaignLoyaltyProductCandidateListRequestType,
  GetCampaignLoyaltyProductListRequestType,
  DeleteCampaignLoyaltyProductRequestType,
  PutCampaignLoyaltyProductRequestType,
  CampaignVariantType,
} from 'utils/apiList/campaignLoyalty'
import { toastSuccess } from 'utils/toast'
import { callErrorMsg } from 'helpers/errorMsg'
import { validateParamsValue } from '../helper'

export type { CampaignVariantType }

export const SLICE_NAME = 'loyalty/CampaignLoyaltyAddAndEdit'

export const createCampaignLoyalty = createAsyncThunk(
  `${SLICE_NAME}/createCampaignLoyalty`,
  async (_, { rejectWithValue, getState }) => {
    const {
      campaignLoyaltyAddAndEdit: {
        formCampaign: { endDate, startDate, endTime, startTime, name, selectedCampaignType },
      },
    } = getState() as StoreStateType

    try {
      const res = await postCampaignLoyaltyList({
        payload: {
          end_at: dayjs(`${endDate} ${endTime}`).valueOf(),
          name,
          start_at: dayjs(`${startDate} ${startTime}`).valueOf(),
          campaign_type: selectedCampaignType?.key as CampaignVariantType['key'],
        },
      })

      toastSuccess(`Berhasil Membuat Campaign Loyalty ${name}`)

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchCampaignLoyaltyById = createAsyncThunk(
  `${SLICE_NAME}/fetchCampaignLoyaltyById`,
  async (params: GetCampaignLoyaltyByIdRequestType, { rejectWithValue }) => {
    try {
      const res = await getCampaignLoyaltyById(params)

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateCampaignLoyaltyById = createAsyncThunk(
  `${SLICE_NAME}/updateCampaignLoyaltyById`,
  async ({ id }: { id: string }, { rejectWithValue, getState }) => {
    const {
      campaignLoyaltyAddAndEdit: {
        formCampaign: { endDate, startDate, endTime, startTime, name },
      },
    } = getState() as StoreStateType
    try {
      const res = await putCampaignLoyalty({
        id,
        payload: {
          end_at: dayjs(`${endDate} ${endTime}`).valueOf(),
          name,
          start_at: dayjs(`${startDate} ${startTime}`).valueOf(),
        },
      })
      toastSuccess(`Berhasil Menyimpan Campaign Loyalty "${name}"`)

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchCampaignLoyaltyProductCandidateList = createAsyncThunk(
  `${SLICE_NAME}/fetchCampaignLoyaltyProductCandidateLis`,
  async (_, { rejectWithValue, getState }) => {
    const {
      campaignLoyaltyList: { query },
    } = getState() as StoreStateType

    const params: GetCampaignLoyaltyProductCandidateListRequestType['params'] = {
      pageSize: query.pageSize,
      pageIndex: query.pageIndex,
    }
    try {
      const res = await getCampaignLoyaltyProductCandidateList({ params })

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchCampaignLoyaltyProductList = createAsyncThunk(
  `${SLICE_NAME}/fetchCampaignLoyaltyProductList`,
  async (_, { rejectWithValue, getState }) => {
    const {
      campaignLoyaltyAddAndEdit: { query, formCampaign },
    } = getState() as StoreStateType

    const params: GetCampaignLoyaltyProductListRequestType['params'] = {
      pageSize: query.pageSize,
      pageIndex: query.pageIndex,
      campaignId: formCampaign.id,
      ...validateParamsValue('param', query.name),
      ...validateParamsValue('locationId', query.location.location_id),
    }
    try {
      const res = await getCampaignLoyaltyProductList({ params })

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const removeCampaignLoyaltyProductById = createAsyncThunk(
  `${SLICE_NAME}/RemoveCampaignLoyaltyProductById`,
  async (params: DeleteCampaignLoyaltyProductRequestType, { rejectWithValue }) => {
    try {
      const res = await deleteCampaignLoyaltyProduct(params)
      toastSuccess(`Berhasil menghapus product id "${params.id}"`)

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateCampaignLoyaltyProductById = createAsyncThunk(
  `${SLICE_NAME}/updateCampaignLoyaltyProductById`,
  async (params: PutCampaignLoyaltyProductRequestType, { rejectWithValue }) => {
    try {
      const res = await putCampaignLoyaltyProduct(params)
      toastSuccess(`Berhasil mengupdate product`)

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchLocationHubList = createAsyncThunk(
  `${SLICE_NAME}/fetchLocationHubList`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getLocationHubs()

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
