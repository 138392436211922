import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const directPutawayRolesRBAC = {
  pageID: '9200cd8dd28083d6accae1ec96476407',
  elementID: {
    ViewPutawayList: '1d25deb4c77c8c0e3c4c9c155924f3f5',
    DropdownButton: '21c876c98747ef60b5a43729f8d275bc',
    DropdownSLOC: '285d8bf8e1da0b390a1ce27b7ebecd3b',
  },
} as const

export const useRBACDirectPutawayPage = createUseRBAC(directPutawayRolesRBAC.elementID)

export type UseRBACDirectPutawayPageType = ReturnType<typeof useRBACDirectPutawayPage>
