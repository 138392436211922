import axios from 'axios'
import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

const { REACT_APP_BASE_URL_API } = process.env
const baseUrl = REACT_APP_BASE_URL_API

// ==== PRODUCT VENDOR
export const getProductCatalogs = (id, params) =>
  axios.get(`${baseUrl}/api/product/${id}/catalogs`, { params })

export const patchPrimaryVendor = (productId, catalogId) =>
  axios.patch(`${baseUrl}/api/product/${productId}/catalog/${catalogId}/primary`)

export const deleteProductCatalog = (productId, catalogId) =>
  axios.delete(`${baseUrl}/api/product/${productId}/catalog/${catalogId}`)

// ==== PRODUCT VENDOR ADD
export const getVendor = (params) => axios.get(`${baseUrl}/api/purchase-order/vendor`, { params })

export const getPIC = (params) =>
  axiosInstanceApiGateway.Get({
    url: 'erp/internal/:version/vendor/contact/list',
    version: 'v2',
    params: {
      page_size: params.pageSize,
    },
  })

export const getPICSourcing = (params) =>
  axios.get(`${baseUrl}/api/product/catalog/pic`, { params })

export const getLocation = (params) => axios.get(`${baseUrl}/api/location`, { params })

export const postProductCatalog = (id, payload) =>
  axios.post(`${baseUrl}/api/product/${id}/catalog`, payload)

// ==== PRODUCT VENDOR EDIT
export const getProductCatalogDetail = (productId, catalogId) =>
  axios.get(`${baseUrl}/api/product/${productId}/catalog/${catalogId}`)

export const patchProductCatalog = (productId, catalogId, data) =>
  axios.patch(`${baseUrl}/api/product/${productId}/catalog/${catalogId}`, data)
