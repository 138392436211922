import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { toastFailed } from 'utils/toast'
import { downloadFileUrl } from 'utils/downloadFileUrl'
import { getHubs } from 'utils/api'
import {
  getBudgetBulkUploadTemplate,
  getPlanningBulkUploadTemplate,
  getWorkingScheduleBulkUploadTemplate,
  postUploadManPowerPlanning,
  postUploadManPowerBudget,
  postUploadWorkingSchedule,
} from 'features/Enterprise/HRIS/services/account/hubStaff'

import { getDepartementList } from 'features/Enterprise/HRIS/services/account/centralWarehouseStaff'

const initialState = {
  isLoadingDownloadTemplate: false,
  isShowDownloadTemplateDialog: false,
  dateRangeTemplate: null,
  hubsList: [],
  selectedHub: null,
  statusUpload: 'idle',
  resultUpload: {},
  selectedMonth: null,
  selectedDepartmentString: ``,
  allDepartment: [],
}

const manPowerBulkUpload = createSlice({
  name: 'manPowerBulkUpload',
  initialState,
  reducers: {
    reset: () => initialState,
    openDownloadTemplateDialog: (state, action) => {
      const isShow = action.payload
      state.isShowDownloadTemplateDialog = isShow
    },
    setDateRange: (state, action) => {
      const dateRange = action.payload
      state.dateRangeTemplate = dateRange
    },
    setHubList: (state, action) => {
      state.hubsList = action.payload
    },
    setSelectedHub: (state, action) => {
      state.selectedHub = action.payload
    },
    setSelectedMonth: (state, action) => {
      state.selectedMonth = action.payload
    },
    setSelectedDepartmentString: (state, action) => {
      state.selectedDepartmentString = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(downloadTemplate.pending, (state) => {
        state.isLoadingDownloadTemplate = true
      })
      .addCase(downloadTemplate.fulfilled, (state) => {
        state.isLoadingDownloadTemplate = false
        state.isShowDownloadTemplateDialog = false
      })
      .addCase(downloadTemplate.rejected, (state) => {
        state.isLoadingDownloadTemplate = false
      })
      .addCase(onUploadFile.pending, (state) => {
        state.statusUpload = 'uploadOnProgress'
      })
      .addCase(onUploadFile.fulfilled, (state, action) => {
        const { status, ...rest } = action.payload
        state.statusUpload =
          {
            FAILED: 'uploadFailed',
            SUCCESS: 'uploadSucceded',
            PARTIAL_SUCCESS: 'partialSuccess',
          }[status] || 'idle'
        state.resultUpload = rest
      })
      .addCase(onUploadFile.rejected, (state) => {
        state.statusUpload = 'idle'
      })
      .addCase(getAllDepartment.fulfilled, (state, action) => {
        state.allDepartment = action.payload
      })
  },
})

export const {
  openDownloadTemplateDialog,
  setDateRange,
  reset,
  setHubList,
  setSelectedHub,
  setSelectedMonth,
  setSelectedDepartmentString,
} = manPowerBulkUpload.actions

export const getAllHubs = createAsyncThunk(
  'manPowerBulkUpload/getAllHubs',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await getHubs()
      dispatch(setHubList(data))
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const downloadTemplate = createAsyncThunk(
  'manPowerBulkUpload/downloadTemplate',
  async (type, { rejectWithValue, getState }) => {
    try {
      const { selectedHub, dateRangeTemplate, selectedDepartmentString } =
        getState().manPowerBulkUpload
      const { startDate, endDate } = dateRangeTemplate || {}
      const payloadParams = {
        start_date: startDate,
        end_date: endDate,
      }
      const response = await {
        budget: () => getBudgetBulkUploadTemplate(payloadParams),
        planning: () =>
          getPlanningBulkUploadTemplate({
            ...payloadParams,
            location: selectedHub.location_id,
          }),
        workingSchedule: () =>
          getWorkingScheduleBulkUploadTemplate({
            start_date: startDate.split('-').join(''),
            end_date: endDate.split('-').join(''),
            location_id: selectedHub.location_id,
            department: selectedDepartmentString,
          }),
      }[type]()
      const {
        data: { file_url },
      } = response.data
      downloadFileUrl(file_url)
      return file_url
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  },
)

export const getAllDepartment = createAsyncThunk(
  'manPowerBulkUpload/getAllDepartment',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await getDepartementList()
      const dataObject = response?.data?.data
      const keys = Object.keys(response?.data?.data)
      const dataProcessed = []

      keys.forEach((key) => {
        dataProcessed.push({ content: dataObject[key].names, name: key })
      })

      return dataProcessed
    } catch (error) {
      return rejectWithValue(error)
    }
  },
)

export const onDownloadTempalte = createAsyncThunk(
  'manPowerBulkUpload/onDownloadTempalte',
  async (type, { getState, dispatch }) => {
    const {
      manPowerBulkUpload: { selectedHub, dateRangeTemplate },
    } = getState()
    if ((type === 'planning' || type === 'workingSchedule') && !selectedHub) {
      toastFailed('Hub harus diisi')
      return
    }
    if ((type === 'budget' || type === 'workingSchedule') && !dateRangeTemplate) {
      toastFailed('Tanggal harus diisi')
      return
    }
    dispatch(downloadTemplate(type))
  },
)

export const onUploadFile = createAsyncThunk(
  'manPowerBulkUpload/onUploadFile',
  async ({ file, type }, { rejectWithValue, getState }) => {
    const {
      auth: { userData },
    } = getState()

    const formData = new FormData()
    formData.append('file', file)

    if (type === 'workingSchedule') {
      formData.append('file_name', file?.name)
      formData.append('updated_by', userData?.email)
      formData.append('roles', userData?.authorities?.map((role) => role.name)?.join(','))
    }

    const payload = formData

    try {
      const result = await {
        budget: () => postUploadManPowerBudget(payload),
        planning: () => postUploadManPowerPlanning(payload),
        workingSchedule: () => postUploadWorkingSchedule(payload),
      }[type]()
      return result.data.data
    } catch (error) {
      toastFailed(error?.response?.data?.error?.message || error.message)
      return rejectWithValue(error)
    }
  },
)

export default manPowerBulkUpload.reducer
