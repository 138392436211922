import React, { memo } from 'react'
import styled from 'styled-components'

/**
 * Disabled step checker. compare current step with step's index.
 * @param {Number} activeStepIndex user's current step.
 * @param {Number} index index of step.
 * @returns {Boolean} true if disabled.
 */
const disabledStepperTitle = (activeStepIndex, index) => activeStepIndex < index

/**
 * Stepper JSX Component
 * @param {*} props props for StepperV2 Component
 * @property {Number} activeStepIndex user's current step.
 * @property {Array} stepTitles array of strings containing the title of each steps
 * @property {Boolean} firstLastLine
 * @property {String} steperAlign Align Of Stepper, 'center' | 'start' | 'end'
 * @returns JSX Component
 */
const Stepper = ({ activeStepIndex, stepTitles, firstLastLine = true, steperAlign = 'start' }) => (
  <S.WrapperStepper alignContent={steperAlign}>
    <S.WrapperHeader>
      {stepTitles.map((each, index) => (
        <React.Fragment key={each + index}>
          <S.WrapperTitle
            disabled={disabledStepperTitle(activeStepIndex, index)}
            leftRounded={index == 0}
            rightRounded={index == stepTitles.length - 1}
          >
            <S.IconStepper disabled={disabledStepperTitle(activeStepIndex, index)}>
              {index + 1}
            </S.IconStepper>
            <S.Title disabled={disabledStepperTitle(activeStepIndex, index)}>{each.title}</S.Title>
          </S.WrapperTitle>
        </React.Fragment>
      ))}
    </S.WrapperHeader>
  </S.WrapperStepper>
)

/**
 * render content based on active step index
 * @param {*} props properties for stepper content
 * @property {Number} stepIndex index of array of steps
 * @property {Number} activeStepIndex index of current user's step
 * @property {React Component} children content of the current step
 * @returns children component of step
 */
export const StepperContent = ({ stepIndex, activeStepIndex, children }) =>
  stepIndex === activeStepIndex ? <>{children}</> : null

export default memo(Stepper)

//----------styled-component--------------------//
const S = {}

S.Title = styled.div`
  color: ${({ theme: { colors }, disabled }) => (disabled ? colors.grey : colors.white)};
  font-size: 14px;
`

S.WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: ${({ theme: { colors }, disabled }) =>
    disabled ? colors.neutralGrey : colors.blueTag};
  padding: 8px 12px;
  ${({ leftRounded, rightRounded }) =>
    leftRounded
      ? 'border-radius: 24px 0px 0px 24px'
      : rightRounded
      ? 'border-radius: 0px 24px 24px 0px'
      : null};
`

S.WrapperStepper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: ${({ alignContent }) => alignContent};
`

S.WrapperHeader = styled.div`
  display: flex;
  gap: 2px;
`

S.IconStepper = styled.div`
  border-radius: 23px;
  background-color: ${({ theme: { colors }, disabled }) =>
    disabled ? colors.grey : colors.galaxyBlue};
  color: ${({ theme: { colors } }) => colors.white};
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
`
