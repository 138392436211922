import { Delete, Get, Patch, Post, Put } from 'config/apiServiceConfig'

import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

export type LoyaltyExchangeCoinResponseType = {
  data: {
    status: string
  }
}

export type LoyaltyExchangeCoinBasicResponseType<Data, Pagination> = {
  data: Data
  pagination: Pagination
}

export type LoyaltyExchangeCoinListType = {
  coin: number
  dailyQuota: number
  endAt: number
  id: number
  isActive: boolean
  isSpecial: boolean
  locationId: number
  locationName: string
  productId: number
  productName: string
  productPrice: number
  productSku: string
  quota: number
  startAt: number
  userDailyQuota: number
  userTargetType: {
    key: string
    text: string
  }
  rewardType: {
    key: 'BAU' | 'SPECIAL' | 'EXCLUSIVE'
    text: string
  }
}

export type LoyaltyExchangeCoinPaginationResponseType = {
  direction: string
  numberOfElements: number
  pageIndex: number
  pageSize: number
  sort: string
  totalElement: number
  totalPages: number
}

export type GetLoyaltyExchangeCoinParamsType = Partial<{
  param: string
  sort: string
  direction: string
  page_size: number
  page_index: number
  source: 'widget' | string
}>

export type LoyaltyExchangeCoinCategoryListType = {
  category_id: number
  category_name: string
}

export type LoyaltyExchangeCoinHubListType = {
  location_id: number
  location_name: string
}

export type LoyaltyExchangeCoinBulkEditType = {
  startDate: string
  startTime: string
  endDate: string
  endTime: string
  coin: number
  quota: number
  isActive: boolean
  isSpecial: boolean
  isAllDay: boolean
}

export type LoyaltyExchangeCoinDropdownType = {
  id: number
  name: string
}

export type LoyaltyExchangeCoinProductParamsType = {
  param: string
  sort: string
  direction: string
  pageSize: number
  pageIndex: number
  categoryId: number
}

export type LoyaltyExchangeCoinProductType = {
  locationId: number
  locationName: string
  productId: number
  productName: string
  productPrice: number
  productSku: string
}

export type GetExchangeCoinResponseType = LoyaltyExchangeCoinBasicResponseType<
  { ExchangeCoins: Array<LoyaltyExchangeCoinListType> },
  LoyaltyExchangeCoinPaginationResponseType
>

export type GetLocationTypeResponseType = LoyaltyExchangeCoinBasicResponseType<
  Array<LoyaltyExchangeCoinDropdownType>,
  null
>

export type GetProductResponseType = LoyaltyExchangeCoinBasicResponseType<
  { products: Array<LoyaltyExchangeCoinProductType> },
  LoyaltyExchangeCoinPaginationResponseType
>

export type BodyLoyaltyExchangeCoinEditCoinType = {
  ids?: Array<number>
  coin: number
  isActive: boolean
  isSpecial: boolean
  quota: number
  startAt: number
  endAt: number
}

export type BodyLoyaltyExchangeCoinUpdateCoinStatusType = {
  isAvailable: boolean
}

export type LoyaltyExchangeCoinCategoryResponseType = {
  content: Array<LoyaltyExchangeCoinCategoryListType>
  empty: boolean
  first: boolean
  last: boolean
  number: number
  numberOfElements: number
  size: number
  totalElements: number
  totalPages: number
  sort: { sorted: boolean; unsorted: boolean; empty: boolean }
  sorted: boolean
  unsorted: boolean
  pageable: {
    offset: number
    pageNumber: number
    pageSize: number
    paged: boolean
    sort: { sorted: boolean; unsorted: boolean; empty: boolean }
    unpaged: boolean
  }
}

export type BodyLoyaltyRedeemCoinCreateType = {
  redeemRewardRequests: Array<{
    coin: number
    endAt: number
    isActive: boolean
    isSpecial: boolean
    locationId: number
    productId: number
    quota: number
    startAt: number
  }>
}

const redeemCoinEndpoint = '/api/loyalty/redeem-reward'

export const getRedeemCoin = (params: GetLoyaltyExchangeCoinParamsType) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  axiosInstanceApiGateway.Get<any, true>({
    url: '/loyalty/internal/v1/redeem-reward',
    params,
    convertResponse: true,
  })
export const deleteRedeemCoin = (id: number) =>
  Delete<LoyaltyExchangeCoinResponseType>({ url: `${redeemCoinEndpoint}/${id}` })
export const updateRedeemCoinStatus = (
  id: number,
  body: BodyLoyaltyExchangeCoinUpdateCoinStatusType,
) =>
  Patch<LoyaltyExchangeCoinResponseType>({
    url: `${redeemCoinEndpoint}/update-status/${id}`,
    data: body,
  })
export const updateRedeemCoin = (body: BodyLoyaltyExchangeCoinEditCoinType) =>
  Put<LoyaltyExchangeCoinResponseType>({ url: `${redeemCoinEndpoint}/bulk`, data: body })
export const createRedeemCoin = (body: BodyLoyaltyRedeemCoinCreateType) =>
  Post<string>({ url: redeemCoinEndpoint, data: body })
export const getRedeemCoinCategory = () =>
  Get<LoyaltyExchangeCoinCategoryResponseType>({ url: '/api/category' })
export const getRedeemCoinLocationType = () =>
  Get<GetLocationTypeResponseType>({ url: '/api/location/type' })
export const getRedeemCoinLocationsByType = (type: string) =>
  Get<Array<LoyaltyExchangeCoinHubListType>>({ url: `/api/locations/${type}` })
export const getRedeemCoinProductsByLocationId = (
  locationId: number,
  params: LoyaltyExchangeCoinProductParamsType,
) => Get<GetProductResponseType>({ url: `${redeemCoinEndpoint}/product/${locationId}`, params })
