import {
  SET_IS_START_CAMPAIGN,
  SET_IS_FAILED_CAMPAIGN,
  SET_IS_SUCCESS_CAMPAIGN,
  SET_IS_NEED_TO_RELOAD_CAMPAIGN,
  SET_PAGINATION_DATA_CAMPAIGN,
  SET_IS_CATEGORY_LIST_DATA_CAMPAIGN
} from "storeContext/actionsType/campaign";
import { getCategories_CAMPAIGN_ADD_MANUAL } from "utils/apiList/campaign";
import { toastFailed } from "utils/toast";

export const campaignStart = () => {
  return {
    type: SET_IS_START_CAMPAIGN
  };
};

export const campaignStop = () => {
  return {
    type: SET_IS_FAILED_CAMPAIGN
  };
};

export const needToReload = () => {
  return {
    type: SET_IS_NEED_TO_RELOAD_CAMPAIGN
  };
};

export const setCampaignData = (data) => {
  return {
    type: SET_IS_SUCCESS_CAMPAIGN,
    payload: data
  };
};

export const setCampaignPagination = (data) => {
  return {
    type: SET_PAGINATION_DATA_CAMPAIGN,
    payload: data
  };
};

export const setCategoryList = (data) => {
  return {
    type: SET_IS_CATEGORY_LIST_DATA_CAMPAIGN,
    payload: data
  };
};

export const getListOfCategory = (stateCollections) => (dispatch) => {
  return getCategories_CAMPAIGN_ADD_MANUAL()
    .then(({ data }) => {
      //console.log(data);
      const { setCategoryData } = stateCollections.setter;
      const modifiedData = [...data].map((items) => {
        return {
          ...items,
          checked: false,
          name: items.category_name,
          childs: [...items.category_childs].map((items2) => {
            return {
              ...items2,
              name: items2.category_name,
              checked: false
            };
          })
        };
      });
      setCategoryData(modifiedData);
      dispatch(setCategoryList(modifiedData));
    })
    .catch(() => {
      toastFailed(`terjadi kesalahan, coba beberapa saat lagi`);
    });
};
