import type {
  GetCampaignLoyaltyProductListRequestType,
  GetCampaignLoyaltyProductCandidateListRequestType,
} from 'utils/apiList/campaignLoyalty'

export const validateParamsValue = (
  key:
    | keyof GetCampaignLoyaltyProductListRequestType['params']
    | keyof GetCampaignLoyaltyProductCandidateListRequestType['params'],
  value: string | number,
) =>
  value
    ? {
        [key]: value,
      }
    : {}
