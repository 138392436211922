/* eslint-disable no-async-promise-executor */
import SourceMap from 'source-map-js'
import { datadogLogs, LogsInitConfiguration } from '@datadog/browser-logs'
import ErrorStackParser from 'error-stack-parser'

export { datadogLogs }

export const initDatadogLog = (configs: LogsInitConfiguration) => {
  datadogLogs.init(configs)
}

type MapSourceMapFileType = {
  [k: string]: SourceMap.RawSourceMap
}

const initialSourceMapObj = {
  version: '3',
  sources: [],
  names: [],
  sourcesContent: [],
  mappings: '',
}

const getMapFileName = (fileName: string) => `${fileName}.map`

const getOriginalStackFrames = (stackFrames: StackFrame[]): Promise<string> =>
  new Promise(async (resolve, reject) => {
    const mapSourceMapFile: MapSourceMapFileType = {}

    // filter to distinct mapFileName
    stackFrames.forEach((stackFrame) => {
      const { fileName } = stackFrame
      const mapFileName = getMapFileName(String(fileName))

      if (fileName) {
        if (fileName in mapSourceMapFile) {
          return
        }

        // set to default shape
        mapSourceMapFile[mapFileName] = initialSourceMapObj
      }
    })

    // get related sourceMapFile
    const sourceMapObjPromises = Object.keys(mapSourceMapFile).map(async (mapFileName) => {
      const sourceMapObj = await fetch(mapFileName, {
        mode: 'no-cors',
      })
        .then((res) => res.json())
        .then((response) => response)
        .catch(() => reject(new Error(`Failed to fetch ${mapFileName}`)))

      return {
        mapFileName,
        sourceMap: sourceMapObj,
      }
    })

    const sourceMapObjs = await Promise.all(sourceMapObjPromises)

    sourceMapObjs.forEach((item) => {
      mapSourceMapFile[item.mapFileName] = item.sourceMap
    })

    const transformedStacks = stackFrames.map((stackFrame: StackFrame, idx: number) => {
      const { fileName } = stackFrame

      const mapFileName = getMapFileName(String(fileName))

      const sourceMapFileObj = mapSourceMapFile[mapFileName]

      const consumer = new SourceMap.SourceMapConsumer(sourceMapFileObj)

      const stackFrameOriginal = consumer.originalPositionFor({
        line: stackFrame.lineNumber as number,
        column: stackFrame.columnNumber as number,
      })

      const path = stackFrameOriginal.source?.includes('..')
        ? stackFrameOriginal.source.replace('..', '/static')
        : `${stackFrameOriginal.source}`

      return `${idx > 0 ? '\n' : ''}at ${stackFrameOriginal.name} ${path}:${
        stackFrameOriginal.line
      }:${stackFrameOriginal.column + 1}`
    })

    resolve(transformedStacks.join(''))
  })

export const parsedStackFrame = async (error: Error): Promise<string> => {
  const stackFrames = ErrorStackParser.parse(error)

  try {
    return await getOriginalStackFrames(stackFrames)
  } catch (err) {
    return (err as { message: string }).message as string
  }
}

type MessageContextType = {
  customInfo?: {
    origin?: 'error-boundary' | 'network-interceptor'
    [k: string]: unknown
  }
  error?: {
    stack?: string
    [k: string]: unknown
  }
  [k: string]: unknown
}

export const log = {
  error: (message: string, messageContext?: MessageContextType) => {
    datadogLogs.logger.error(message, messageContext)
  },
  info: (message: string, messageContext?: MessageContextType) => {
    datadogLogs.logger.info(message, messageContext)
  },
  warn: (message: string, messageContext?: MessageContextType) => {
    datadogLogs.logger.warn(message, messageContext)
  },
}
