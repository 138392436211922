import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  getAllPageIDList,
  postUploadJSONPageDetailsAPI,
} from 'features/Account/services/adminAccessPageID'
import { QueryURLPageIDListType } from 'features/Account/@types/adminAccessPageIDList'
import { toastSuccess } from 'utils/toast'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  SLICE_NAME,
  setAdminAccessPageIDList,
  setLoadingList,
  setAdminURLQuery,
  setIsUploadingJSON,
  toggleDialogUploadJSON,
  initialState,
} from './adminAccessPageIDSlice'

export const onGetAllPageIDList = createAsyncThunk<void, QueryURLPageIDListType | undefined>(
  `${SLICE_NAME}/getAllPageIDList`,
  async (query, { dispatch, getState }) => {
    const {
      adminAccessPageID: { queryURL },
    } = getState() as StoreStateType
    const newQuery = { ...queryURL, ...query }
    dispatch(setAdminURLQuery(newQuery))
    try {
      dispatch(setLoadingList(true))
      const response = await getAllPageIDList(newQuery)
      dispatch(setAdminAccessPageIDList(response.data.data))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoadingList(false))
    }
  },
)

export const postCreatePageIDbyJSON = createAsyncThunk<void, string>(
  `${SLICE_NAME}/postCreatePageIDbyJSON`,
  async (jsonString, { dispatch }) => {
    try {
      dispatch(setIsUploadingJSON(true))
      const payload = { page_json: jsonString }
      await postUploadJSONPageDetailsAPI(payload)
      toastSuccess('Berhasil mengupload json page')
      dispatch(toggleDialogUploadJSON('CLOSED'))
      dispatch(onGetAllPageIDList(initialState.queryURL))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setIsUploadingJSON(false))
    }
  },
)
