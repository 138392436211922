import { memo } from 'react'
import styled from 'styled-components'
import { Checkbox, SystemIcon, RowProductHub, NoData } from 'components'

function TableAddProductHub({ data, handleCheck, isCheckAll, handleCheckAll }) {
  return (
    <>
      <Container>
        <thead>
          <Tr>
            <Th ratio="1">
              <Checkbox checked={isCheckAll} onChange={handleCheckAll} />
            </Th>
            <Th ratio="5">
              NAMA PRODUK
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th ratio="3">
              NOMOR SKU
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th ratio="3">
              KATEGORI
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th ratio="3">STATUS</Th>
          </Tr>
        </thead>
        <tbody>
          {data.map((el) => (
            <RowProductHub key={el.product_id} data={el} handleCheck={handleCheck} />
          ))}
        </tbody>
      </Container>
      {data.length === 0 && <NoData />}
    </>
  )
}

export default memo(TableAddProductHub)

const Container = styled.table`
  width: 100%;
  margin-bottom: 33px;
`
const Tr = styled.tr`
  display: flex;
  padding: 16px 32px;
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`
