import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getPaymentChannelV2,
  PaymentChannelType,
  putPaymentGateway,
  UpdatePGPayloadType,
} from 'features/Payment/services/paymentGatewayAdmin'

export const SLICE_NAME = 'paymentGatewayAdmin'

export const fetchInternalPaymentChannel = createAsyncThunk(
  `${SLICE_NAME}/getInternalPaymentChannel`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getPaymentChannelV2()
      let newPaymentChannel: PaymentChannelType[] = []
      res.data.data.payment_channel_v2.forEach((el) => {
        newPaymentChannel = [...newPaymentChannel, ...el.payment_channel]
      })

      return newPaymentChannel
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updatePaymentGateway = createAsyncThunk(
  `${SLICE_NAME}/updatePaymentGateway`,
  async ({ id, payload }: { id: number; payload: Partial<UpdatePGPayloadType> }) => {
    try {
      await putPaymentGateway(id, payload)

      return true
    } catch (err) {
      callErrorMsg(err)
      return false
    }
  },
)
