import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  fetchCampaignLoyaltyList,
  SLICE_NAME,
  removeCampaignLoyalty,
  type CampaignVariantType,
} from './campaignLoyaltyListThunk'

export interface QueryType {
  isAllDay: boolean
  startDate: number
  endDate: number
  pageSize: number
  pageIndex: number
  status: {
    name: string
    value: string
  }
  campaignType: CampaignVariantType | null
}

export interface CampaignLoyaltyListType {
  id: number
  name: string
  startDate: string
  endDate: string
  startTime: string
  endTime: string
  isActive: boolean
  campaignType: CampaignVariantType
  uniqueKey: string
}
export interface PopupDeleteStateType {
  isOpen: boolean
  selectedCampaignLoyaltyData: Omit<CampaignLoyaltyListType, 'campaignType'>
}
export interface CampaignLoyaltyListStateType {
  isLoading: boolean
  campaignLoyaltyList: CampaignLoyaltyListType[]
  query: QueryType
  popupDelete: PopupDeleteStateType
}

export const initialState: CampaignLoyaltyListStateType = {
  isLoading: false,
  campaignLoyaltyList: [],
  query: {
    endDate: 0,
    startDate: 0,
    isAllDay: true,
    pageIndex: 0,
    pageSize: 20,
    status: {
      name: '',
      value: '',
    },
    campaignType: null,
  },
  popupDelete: {
    isOpen: false,
    selectedCampaignLoyaltyData: {
      endDate: '',
      endTime: '',
      id: 0,
      isActive: false,
      name: '',
      startDate: '',
      startTime: '',
      uniqueKey: '',
    },
  },
}

const campaignLoyaltyList = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setQuery: (
      state,
      action: PayloadAction<{
        name: keyof QueryType
        value: boolean | number | QueryType['status'] | QueryType['campaignType']
      }>,
    ) => {
      const { name, value } = action.payload

      state.query[name] = value as never
    },
    setPopupDelete: (
      state,
      action: PayloadAction<{
        name: keyof PopupDeleteStateType
        value: boolean | number | PopupDeleteStateType['selectedCampaignLoyaltyData']
      }>,
    ) => {
      const { name, value } = action.payload
      state.popupDelete[name] = value as never
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCampaignLoyaltyList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchCampaignLoyaltyList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchCampaignLoyaltyList.fulfilled, (state, action) => {
        state.isLoading = false
        state.campaignLoyaltyList = action.payload.data.campaigns.map((el) => ({
          id: el.id,
          name: el.name,
          startDate: el.start_date,
          endDate: el.end_date,
          startTime: el.start_time,
          endTime: el.end_time,
          isActive: el.is_active,
          campaignType: el.campaign_type,
          uniqueKey: window.crypto.randomUUID(),
        }))
      })
      .addCase(removeCampaignLoyalty.pending, (state) => {
        state.isLoading = true
      })
      .addCase(removeCampaignLoyalty.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(removeCampaignLoyalty.fulfilled, (state) => {
        state.isLoading = false
        state.popupDelete = initialState.popupDelete
      })
  },
})

export const { reset, setQuery, setPopupDelete } = campaignLoyaltyList.actions
export default campaignLoyaltyList.reducer
