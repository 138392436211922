import { memo } from "react";
import styled from "styled-components";
import { SystemIcon } from "components";
function IconPlus({ className }) {
  return (
    <Container className={className}>
      <SystemIcon iconName="plus" colorIcon="white" />
    </Container>
  );
}

export default memo(IconPlus);

const Container = styled.div`
  background-color: ${({ theme: { colors } }) => colors.whiteFaded};
  padding: 1px;
  margin-right: 8px;
  border-radius: 4px;
`;
