import { memo, useState, useEffect } from 'react'
import styled from 'styled-components'
import { DateRangePicker } from 'react-date-range'
import dayjs from 'dayjs'

import { dateManipulation } from 'utils/helpers/date'
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import './daterangepromotion.css'

import {
  SystemIcon,
  Button,
  //TextBody
} from 'components'
/****************
 *  REMOVE DEFINED DATE WITH CSS
 */

function DateRangeAllOrder({ className, border, handleClickDateRange, data }) {
  //  STATE HOOKS
  const [isShowDateRange, setIsShowDateRange] = useState(false)
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: dateManipulation(new Date()).add(7, 'day').toDate(),
      key: 'selection',
    },
  ])
  const [previewInput, setPreviewInput] = useState({
    startDate: '',
    endDate: '',
  })
  useEffect(() => {
    if (data.startDate) {
      setDateRange([
        {
          startDate: Number(data.startDate),
          endDate: Number(data.endDate),
          key: 'selection',
        },
      ])
      setPreviewInput({
        startDate: dayjs(Number(data.startDate)).format('DD-MMM-YYYY'),
        endDate: dayjs(Number(data.endDate)).format('DD-MMM-YYYY'),
      })
    }
  }, [data])
  // FUNCTION HANDLER
  const handleClickApply = () => {
    const start = dateRange[0].startDate
    const end = dateRange[0].endDate
    setPreviewInput({
      startDate: dayjs(start).format('DD-MMM-YYYY'),
      endDate: dayjs(end).format('DD-MMM-YYYY'),
    })
    setIsShowDateRange(false)
    handleClickDateRange(true, start.getTime(), end.getTime())
    //console.log(dateRange[0].startDate.valueOf())
    //console.log(dateRange[0].endDate)
  }

  // FUNCTION RENDER
  const renderDateRangePicker = () => {
    return (
      <>
        <DatePickerContainer>
          <DatePickerHeader>
            <IconCloseWrapper onClick={() => setIsShowDateRange(false)}>
              <SystemIcon iconName="plus" fontSize="headerBigger" colorIcon="text" />
            </IconCloseWrapper>
          </DatePickerHeader>

          <DateRangePicker
            onChange={(item) => setDateRange([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={2}
            ranges={dateRange}
            direction="horizontal"
          />
          <DatePickerFooter>
            <Button style={{ marginLeft: 'auto' }} onClick={handleClickApply}>
              Terapkan
            </Button>
          </DatePickerFooter>
        </DatePickerContainer>
      </>
    )
  }

  return (
    <div>
      <InputContainer className={`${className} input-container`}>
        <Container iconPos="left" border={border}>
          <Input
            //{...rest}
            //onChange={onChange}
            //onFocus={onFocus}
            //onBlur={onBlur}
            autoComplete="off"
            //disabled={true}
            value={previewInput.startDate + ' - ' + previewInput.endDate}
            readOnly={true}
            onClick={() => setIsShowDateRange(!isShowDateRange)}
          />
          <label>
            <IconWrapper
              //onClick={handleClickIcon}
              className="icon-wrapper"
              iconPos="left"
              bgIcon="s"
            >
              <SystemIcon iconName="calendar" colorIcon="grey" />
            </IconWrapper>
          </label>
        </Container>
      </InputContainer>
      {isShowDateRange ? renderDateRangePicker() : ''}
    </div>
  )
}

DateRangeAllOrder.defaultProps = {
  onChange: () => {},
  iconPos: 'right',
  border: true,
  customIcon: '',
  bgIcon: 'grey',
  handleClickIcon: () => {},
  value: '',
  onBlur: () => {},
  onFocus: () => {},
  iconColor: 'main',
  color: 'text',
  autocomplete: 'off',
  note: '',
  data: {},
}

export default memo(DateRangeAllOrder)

const IconCloseWrapper = styled.div`
  transform: rotate(45deg);
  cursor: pointer;
  display: inline-block;
  margin-left: auto;
`
const Container = styled.div`
  width: 100%;
  border-radius: 6px;
  display: flex;
  position: relative;
  background-color: ${({ theme: { colors } }) => colors.white};
  border: ${({ border }) => (border ? 'solid 1px #E4EAF3' : 'none')};
  flex-direction: ${({ iconPos }) => (iconPos === 'left' ? 'row-reverse' : 'row')};
`
const IconWrapper = styled.div`
  display: grid;
  padding: 10px;
  place-items: center;
  background-color: ${({ theme: { colors }, bgIcon }) => colors[bgIcon]};
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.light};
  font-family: 'Noto Sans JP', sans-serif !important;
  /* padding-right: 0; */
`

const Input = styled.input`
  background-color: white;
  border: none;
  width: 100%;
  padding: 10px;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  color: ${({ theme: { colors }, color }) => colors[color]};
  border-radius: 6px;
  &:focus {
    outline: none;
  }
  ::placeholder {
    opacity: 1;
    color: #aab4c8;
  }
  ::-webkit-calendar-picker-indicator {
    width: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    /* display: none;
        -webkit-appearance: none; */
  }
`

const InputContainer = styled.div`
  width: 100%;
`

const DatePickerContainer = styled.div`
  padding: 10px;
  position: absolute;
  background: white;
  box-shadow: 0px 30px 84px rgba(19, 10, 46, 0.08), 0px 8px 32px rgba(19, 10, 46, 0.07),
    0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13);
  border-radius: 12px;
`

const DatePickerFooter = styled.div`
  display: flex;
`

const DatePickerHeader = styled.div`
  display: flex;
`
