import { memo } from "react";
import styled from "styled-components";
import { SystemIcon, Button } from "components";
function PaginationWithoutTotal({
  data,
  handleChangePage,
  className,
  showTotalOnly
}) {
  return (
    <Container className={className}>
      {Boolean(data) && (
        <>
          {!showTotalOnly && (
            <ButtonWrapper>
              <Button
                disabled={data.currentPage <= 0}
                variant="wrapper"
                onClick={() => handleChangePage("prev")}
              >
                <PagiButton type="prev">
                  <SystemIcon iconName="dropdown" />
                </PagiButton>
              </Button>

              <Button
                disabled={data.lastData === true}
                variant="wrapper"
                onClick={() => handleChangePage("next")}
              >
                <PagiButton>
                  <SystemIcon iconName="dropdown" />
                </PagiButton>
              </Button>
            </ButtonWrapper>
          )}
        </>
      )}
    </Container>
  );
}

PaginationWithoutTotal.defaultProps = {
  data: {},
  handleChangePage: () => {}
};

export default memo(PaginationWithoutTotal);

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  margin-left: 16px;
  display: flex;
  align-items: center;
  // gap: 8px;
`;

const PagiButton = styled.div`
  width: fit-content;
  height: fit-content;
  margin-left: 1rem;
  padding: 9px 10px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 6px;
  box-shadow: 2px 4px 20px 0px rgba(229, 236, 240, 1);
  transform: ${({ type }) =>
    type === "prev" ? "rotate(90deg)" : "rotate(-90deg)"};
`;
