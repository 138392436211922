import { memo } from "react";
import styled from "styled-components";
import logo_blue from "assets/images/logo.png";
import barcode_logo from "assets/images/barcode-c1.svg";
import { TextBody } from "components";

function Header() {
  return (
    <Container>
      <LogoContainer>
        <Logo src={logo_blue} />
      </LogoContainer>
      <OrderInfoContainer>
        <Invoice>
          <TextBody color="pureBlack" size="header" weight="normal">
            INV/AS/20211118/4/2/5
          </TextBody>
        </Invoice>
        <Time>
          <TextBody color="pureBlack" size="normal" weight="light">
            18 November 2021, 20:55:06
          </TextBody>
        </Time>
        <NoteContainer>
          <TextBody color="pureBlack" size="normal" weight="light">
            Catatan Buyer :
          </TextBody>
        </NoteContainer>
        <NoteContainer>
          <TextBody color="pureBlack" size="normal" weight="light">
            -
          </TextBody>
        </NoteContainer>
      </OrderInfoContainer>
      <BarcodeContainer>
        <Barcode src={barcode_logo} />
      </BarcodeContainer>
    </Container>
  );
}

export default memo(Header);

const Container = styled.div`
  display: flex;
`;
const LogoContainer = styled.div`
  padding-top: 20px;
  width: 20%;
  display: flex;
`;
const Logo = styled.img`
  height: 20px;
  margin-left: auto;
  margin-right: auto;
`;

const OrderInfoContainer = styled.div`
  margin-left: auto;
  width: 45%;
  padding-left: 20px;
`;
const Invoice = styled.div``;
const Time = styled.div``;
const NoteContainer = styled.div``;

const BarcodeContainer = styled.div`
  padding-top: 20px;
  display: flex;
  width: 35%;
`;
const Barcode = styled.img`
  height: 40px;
  margin-left: auto;
  margin-right: 20px;
`;
