import { useState, useImperativeHandle } from 'react'
import { toastFailed } from 'utils/toast'

const useUploadBox = (onResetFile, onGetFile, allowedFile, ref) => {
  const [file, setFile] = useState(null)
  const allowedFileType = allowedFile.length ? allowedFile : ['csv', 'xlsx']

  const handleDropFile = (e, doAlertWrongTypeFile) => {
    const file = e[0]
    const arrayFileName = file.name.split('.')
    if (allowedFileType.includes(arrayFileName[arrayFileName.length - 1])) {
      setFile(file)
      onGetFile(file)
      return
    }
    if (doAlertWrongTypeFile) {
      toastFailed('Format file tidak sesuai')
    }
  }

  const onReset = () => {
    setFile(null)
    onResetFile && onResetFile()
  }

  const emptyFile = () => {
    setFile(null)
    onGetFile(null)
  }

  useImperativeHandle(ref, () => ({
    resetFile: () => emptyFile(),
  }))

  return {
    file,
    isNoFile: !file,
    onReset,
    handleDropFile,
    setFile,
    emptyFile,
  }
}

export default useUploadBox
