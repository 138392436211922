import { memo, useContext } from 'react'
import styled from 'styled-components'

import storeContext from 'storeContext'
import { Typography } from '@astro-ui/components'
import { IconAstro } from 'components'

import { QR, Barcode } from './components'

function TemplateCetakKoliID() {
  const {
    state: {
      printContent: { data },
    },
  } = useContext(storeContext.PrintAreaContext)

  return (
    <Container>
      <tbody>
        {data.map((el, i) => {
          return (
            <Body key={i}>
              <td>
                <Canvas>
                  <Wrapper>
                    <StyledContainerHead>
                      <IconAstro width={80} />
                      <Typography fontWeight="900">LABEL KOLI</Typography>
                      <Typography fontWeight="900">{el?.code.slice(-5)}</Typography>
                    </StyledContainerHead>
                    <StyledContainerQr>
                      <QR data={el} />
                      <Barcode data={el} />
                    </StyledContainerQr>
                  </Wrapper>
                </Canvas>
              </td>
            </Body>
          )
        })}
      </tbody>
    </Container>
  )
}

export default memo(TemplateCetakKoliID)

const Container = styled.table`
  flex-wrap: wrap;
  background: white;
  padding: 16px;
  width: 100%;
  padding: 0px;
`

const Body = styled.tr`
  width: 100%;
  height: 180px;
`

const Canvas = styled.div`
  width: 10cm;
  height: 5cm;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0 auto 2mm;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  width: 300px;
  border: 1px solid #000000;
  border-radius: 6px;
`

const StyledContainerHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledContainerQr = styled.div`
  display: flex;
  justify-content: space-between;
`
