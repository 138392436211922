/* eslint-disable @typescript-eslint/naming-convention */
import { useState, MouseEvent } from 'react'
import { Box } from '../Box'
import { Button, ButtonPropsType } from '../Button'
import { Icon } from '../Icon'
import { Menu } from '../Menu'
import { MenuItem } from '../MenuItem'
import { Typography } from '../Typography'

export type MenuActionType = {
  label?: string
  className?: string
  options: {
    name: string
    value: unknown
    disabled?: boolean
    suffix?: React.ReactElement
  }[]
  disabled?: boolean
  onSelect?: (opts: MenuActionType['options'][number]) => void
  active?: boolean
  sxButton?: ButtonPropsType['sx']
  ActivatorComponent?: (params: {
    handleOpenMenu: (event: MouseEvent<HTMLButtonElement>) => void
  }) => React.ReactElement
}

function MenuAction({
  label,
  className,
  options,
  disabled,
  onSelect,
  active,
  sxButton,
  ActivatorComponent,
}: MenuActionType) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const isOpen = !!anchorEl

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelect: MenuActionType['onSelect'] = (opts) => {
    if (onSelect) {
      onSelect(opts)
    }
    handleClose()
  }

  return (
    <Box>
      {ActivatorComponent ? (
        <>{ActivatorComponent({ handleOpenMenu: handleOpen })}</>
      ) : (
        <Button
          variant="outlined"
          onClick={handleOpen}
          endIcon={<Icon icon="dropdown" size={18} />}
          disabled={disabled}
          sx={{
            backgroundColor: active ? '#246EE5' : 'transparent',
            color: active ? 'white' : '#778092',
            border: active ? '1px solid #246EE5' : '1px solid #778092',
            ':hover': {
              border: '1px solid black',
              backgroundColor: active ? '#246EE5' : 'transparent',
              color: active ? 'white' : '#778092',
            },
            ...sxButton,
          }}
        >
          <Typography variant="body2" fontWeight="bold" color="inherit">
            {label}
          </Typography>
        </Button>
      )}
      <Menu
        className={`${className} menu-action`}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
      >
        {options.length ? (
          options.map((item) => (
            <MenuItem
              key={item.name}
              className="menu-action-list"
              disabled={item.disabled}
              onClick={() => handleSelect(item)}
            >
              <Typography variant="body2" color="#778092" mr="8px">
                {item.name}
              </Typography>
              {item?.suffix}
            </MenuItem>
          ))
        ) : (
          <MenuItem>
            <Typography variant="body2" color="#778092">
              No Data
            </Typography>
          </MenuItem>
        )}
      </Menu>
    </Box>
  )
}

export default MenuAction
