import { Get } from 'config/apiServiceConfig'
import apiServices from 'config/apiServiceApiGateway'
import type {
  LocationType,
  QueryType,
  InfiniteScrollTabbingType,
  InfiniteScrollTabFlagType,
} from 'features/CategoryAndPosisi/@types/InfiniteScrollTab'

type GetInfiniteScrollTabbingResponseType = {
  data: InfiniteScrollTabbingType[]
  flag: InfiniteScrollTabFlagType
}

export const getLocations = () =>
  Get<{ data: LocationType[] }>({
    url: '/api/locations/order',
  })

export const getInfiniteScrollTabbing = (params: Partial<QueryType>) =>
  apiServices.Get<GetInfiniteScrollTabbingResponseType, true>({
    url: '/browse/internal/:version/infinite-scroll-tabbings',
    version: 'v1',
    params,
  })

export const deleteInfiniteScrollTabbing = (tabId: number) =>
  apiServices.Delete({
    url: '/browse/internal/:version/infinite-scroll-tabbing/:tabId',
    version: 'v1',
    urlParams: { tabId },
  })
