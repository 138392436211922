import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { SLICE_NAME, resetUserAccount } from './adminThunk'

export type FormModalResetAccountType = {
  phoneNumber: string
}

export interface AdminStateType {
  isLoading: boolean
  isShowModalResetAccount: boolean
  formResetAccount: FormModalResetAccountType
}

const initialState: AdminStateType = {
  isLoading: false,
  isShowModalResetAccount: false,
  formResetAccount: { phoneNumber: '0' },
}

export type SetFormResetAccountPayloadType = {
  key: keyof FormModalResetAccountType
  value: FormModalResetAccountType[keyof FormModalResetAccountType]
}

const admin = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    resetFormResetAccount: (state) => {
      state.formResetAccount = initialState.formResetAccount
    },
    setIsShowModalResetAccount: (state, action: PayloadAction<{ isShow: boolean }>) => {
      state.isShowModalResetAccount = action.payload.isShow
    },
    setFormResetAccount: (state, action: PayloadAction<SetFormResetAccountPayloadType>) => {
      const { key, value } = action.payload
      state.formResetAccount[key] = value
    },
  },
  extraReducers(builder) {
    builder
      .addCase(resetUserAccount.pending, (state) => {
        state.isLoading = true
      })
      .addCase(resetUserAccount.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(resetUserAccount.fulfilled, (state) => {
        state.isLoading = false
      })
  },
})

export default admin.reducer
export const { reset, setIsShowModalResetAccount, resetFormResetAccount, setFormResetAccount } =
  admin.actions
