import { memo } from 'react'
import styled from 'styled-components'
import { TextBody, Button } from 'components'
import icon_box from 'assets/images/box-item.svg'
import { useHistory } from 'react-router-dom'
import { setPositionProduct } from 'config/routes'

export const getParams = (paramsKey) => {
  const urlParams = new URLSearchParams(window.location.search)
  const myParam = urlParams.get(paramsKey)
  return myParam
  //console.log(myParam);
}

function RowSetPositionCategory({
  data,
  handleChangeActive,
  handleClickDetail,
  handleClickUpdate,
}) {
  const history = useHistory()

  //FUNCTION HANDLER
  const handleClickSubCategory = () => {
    const CurrentCategoryID = getParams('categoryID')
    history.push(
      `${setPositionProduct}?prevCategoryID=${CurrentCategoryID}&categoryID=${
        data?.category_id
      }&productCategoryName=${data?.category_name}&subCategoryName=${getParams('subCategoryName')}`,
    )
  }
  return (
    <Tr>
      <Td ratio="4">
        <ProductWrapper>
          <ImgCategory
            size="32px"
            alt="box"
            src={data?.category_image ? data?.category_image : icon_box}
          />
          <TextBody weight="light" color="textSoft">
            {data?.category_name}
          </TextBody>
        </ProductWrapper>
      </Td>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {`${data?.count_product} Produk`}
        </TextBody>
      </Td>
      <Td ratio="2">
        <ActionWrapper>
          <Button
            variant="wrapper"
            //</ActionWrapper>
            onClick={() => handleClickSubCategory()}
          >
            <TextBody weight="light" className="pointer" color="main">
              Detail Produk
            </TextBody>
          </Button>
        </ActionWrapper>
      </Td>
    </Tr>
  )
}

export default memo(RowSetPositionCategory)

const Tr = styled.tr`
  display: flex;
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
`
const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`
const ImgCategory = styled.img`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  border-radius: 5px;
  background-color: ${({ theme: { colors } }) => colors.mainFaded};
  border: none;
`

const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const ActionWrapper = styled.div`
  display: flex;
  i {
    margin-left: 20px;
  }
`
