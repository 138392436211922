import axios from 'axios'
const { REACT_APP_BASE_URL_API } = process.env
const baseUrl = REACT_APP_BASE_URL_API

const apiGetSourcingPic = `/api/product/catalog/pic`
const apiGetWarehouseList = `/api/locations/warehouse`

const apiPurchaseRequest = `/api/purchase-requests`

const apiExportPurchaseRequest = `/api/purchase-requests/export`
const apiDetailPurchaseRequest = (id) => `/api/purchase-requests/${id}`
const apiConfirmPurchaseRequest = (id) => `/api/purchase-requests/${id}/confirm`
const apiCancelPurchaseRequest = (id) => `/api/purchase-requests/${id}/cancel`
const apiGetPurchaseRequestCount = '/api/reorder-lists/created-pr-count'
const apiPostPurchaseRequestAutoConfirm = '/api/purchase-requests/auto-confirm'
const apiGetPurchaseRequestType = '/api/purchase-requests/supported-type'

export const getWarehouseList = () => axios.get(`${baseUrl}${apiGetWarehouseList}`)

export const getSourcingPicList = (params = {}) =>
  axios.get(`${baseUrl}${apiGetSourcingPic}`, { params })

export const postPurchaseRequest = (data) => axios.post(`${baseUrl}${apiPurchaseRequest}`, data)

export const getPurchaseRequestList = (params) =>
  axios.get(`${baseUrl}${apiPurchaseRequest}`, { params })

export const confirmPurchaseRequestById = (id, data) =>
  axios.patch(`${baseUrl}${apiConfirmPurchaseRequest(id)}`, data)

export const exportPurchaseRequestList = (params) =>
  axios.get(`${baseUrl}${apiExportPurchaseRequest}`, { params })

export const getPurchaseRequestDetailVendor = (params) =>
  axios.get(`http://localhost:3000/purchaseReqDetailEdit.json`)

export const getPurchaseRequestDetail = (id) =>
  axios.get(`${baseUrl}${apiDetailPurchaseRequest(id)}`)

export const cancelPurchaseRequestById = (id) =>
  axios.patch(`${baseUrl}${apiCancelPurchaseRequest(id)}`)

export const getPurchaseRequestCount = (params = {}) =>
  axios.get(`${baseUrl}${apiGetPurchaseRequestCount}`, { params })

export const postPurchaseRequestAutoConfirm = (body) =>
  axios.post(`${baseUrl}${apiPostPurchaseRequestAutoConfirm}`, body)

export const getPurchaseRequestType = () => axios.get(`${baseUrl}${apiGetPurchaseRequestType}`)
