import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import {
  getLoyaltyWidget,
  putLoyaltyWidgetSort,
  putLoyaltyWidgetStatusById,
  deleteLoyaltyWidgetById,
  getWidgetVariant,
  getWidgetById,
  postLoyaltyWidget,
  putLoyaltyWidget,
  type LoyaltyWidgetType,
  type PutLoyaltyWidgetSortPayloadType,
  type GetWidgetByIdResponseType,
  type WidgetVariantType,
  type PostPutCommonLoyaltyWidgetPayloadType,
  type PostPutLoyaltyWidgetSpecialPayloadType,
  type PostPutLoyaltyWidgetBackgroundPayloadType,
  type PostPutLoyaltyWidgetPayloadType,
} from 'features/Loyalty/service/loyalty'
import {
  getImageRules,
  postImage,
  type ImageRulesType,
  type ImageVariantIdType,
  type PostImageRequestPayload,
} from 'utils/apiList/images'
import {
  getCampaignLoyaltyList,
  type GetCampaignLoyaltyListResponseType,
} from 'utils/apiList/campaignLoyalty'
import { getRedeemCoin, type LoyaltyExchangeCoinListType } from 'utils/apiList/redeemCoin'
import { callErrorMsg } from 'helpers/errorMsg'
import { SetSelectedWidgetPayloadActionType } from 'features/Loyalty/@types/loyaltyWidget'
import { toastSuccess } from 'utils/toast'

export type {
  LoyaltyWidgetType,
  ImageRulesType,
  WidgetVariantType,
  GetCampaignLoyaltyListResponseType,
  LoyaltyExchangeCoinListType,
}

export type PopupType = 'CONFIRMATION_DELETE' | 'ADD_WIDGET' | 'EDIT_WIDGET' | ''

export const widgetSpecialImageRulesTypeId = 'product-label'

export const imageRulesTypeId = {
  widgetSpecialBg: 'loyalty-widget-special',
  widgetBg: 'loyalty-widget-bg',
} as const

export const getInitWidgetSpecialTabData = (
  tabItem?: GetWidgetByIdResponseType['data']['tabs'][0],
) => ({
  customId: crypto.randomUUID(),
  title: tabItem?.title || '',
  backgroundUrl: tabItem?.background_url || '',
  backgroundFilePath: tabItem?.background_file_path || '',
  selectedCampaign: tabItem ? { id: tabItem.campaign_id, name: tabItem.campaign_name } : null,
})

export const getComposedPayloadByWidgetType = (
  formState: LoyaltyWidgetStateType['createEditForm'],
) => {
  const { widgetType } = formState

  const commonPayload = {
    title: formState.title,
    widget_type: formState.widgetType?.key as string,
  }

  if (widgetType?.key === 'WIDGET_SPECIAL') {
    return {
      ...commonPayload,
      widget_specials: formState.tabs.map((i) => ({
        tab_title: i.title,
        campaign_id: i.selectedCampaign?.id as number,
        background_file_path: i.backgroundFilePath,
      })),
    } as PostPutLoyaltyWidgetSpecialPayloadType
  }

  if (widgetType?.key === 'WIDGET_BACKGROUND') {
    return {
      ...commonPayload,
      loyalty_product_ids: formState.loyaltyProducts.map((i) => i.productId),
      background_file_path: formState.backgroundFilePath,
    } as PostPutLoyaltyWidgetBackgroundPayloadType
  }

  // Other payload by widget type

  return {
    ...commonPayload,
  } as PostPutCommonLoyaltyWidgetPayloadType
}

export type WidgetSpecialTabConfigType = {
  customId: string
  title: string
  backgroundUrl: string
  backgroundFilePath: string
  selectedCampaign: {
    id: number
    name: string
  } | null
}

export type LoyaltyWidgetStateType = {
  isLoading: boolean
  popup: {
    type: PopupType
    selectedData?: LoyaltyWidgetType | GetWidgetByIdResponseType['data']
  } | null
  widgetList: LoyaltyWidgetType[]
  selectedWidget: LoyaltyWidgetType
  dataDetail: GetWidgetByIdResponseType['data'] | null
  createEditForm: {
    title: string
    widgetType: LoyaltyWidgetType['widget_type'] | null
    tabs: WidgetSpecialTabConfigType[]
    backgroundUrl: string
    backgroundFilePath: string
    loyaltyProducts: LoyaltyExchangeCoinListType[]
  }
  constants: {
    widgetVariantList: LoyaltyWidgetType['widget_type'][]
    imageRulesWidgetSpecialBg: ImageRulesType | null
    imageRulesWidgetBg: ImageRulesType | null
    loyaltyProductList: LoyaltyExchangeCoinListType[]
  }
}

export const initialState: LoyaltyWidgetStateType = {
  isLoading: false,
  widgetList: [],
  popup: null,
  dataDetail: null,
  selectedWidget: {
    id: 0,
    is_active: false,
    title: '',
    widget_type: { key: 'WIDGET_SPECIAL', text: '' },
  },
  createEditForm: {
    title: '',
    widgetType: null,
    tabs: [getInitWidgetSpecialTabData(), getInitWidgetSpecialTabData()],
    backgroundUrl: '',
    backgroundFilePath: '',
    loyaltyProducts: [],
  },
  constants: {
    widgetVariantList: [],
    imageRulesWidgetSpecialBg: null,
    imageRulesWidgetBg: null,
    loyaltyProductList: [],
  },
}

const SLICE_NAME = 'loyaltyWidgetSlice'

const loyatlyWidgetSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    resetSelectedWidget: (state) => {
      state.selectedWidget = initialState.selectedWidget
    },
    setWidgetList: (state, action: PayloadAction<LoyaltyWidgetType[]>) => {
      state.widgetList = action.payload
    },
    setPopup: (state, action: PayloadAction<LoyaltyWidgetStateType['popup']>) => {
      state.popup = action.payload
    },
    setSelectedWidget: (state, action: PayloadAction<SetSelectedWidgetPayloadActionType>) => {
      state.selectedWidget = action.payload.value
    },
    setCreateEditForm: (
      state,
      action: PayloadAction<{
        key: keyof LoyaltyWidgetStateType['createEditForm']
        value: LoyaltyWidgetType['widget_type'] | string | null | WidgetSpecialTabConfigType[]
      }>,
    ) => {
      const { payload } = action

      const { key, value } = payload

      state.createEditForm = {
        ...state.createEditForm,
        [key]: value,
      }
    },
    resetCreateEditForm: (state) => {
      state.createEditForm = initialState.createEditForm
      state.dataDetail = null
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLoyaltyWidget.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchLoyaltyWidget.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchLoyaltyWidget.fulfilled, (state, action) => {
        state.widgetList = action.payload

        state.isLoading = false
      })
      .addCase(removeLoyaltyWidgetById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(removeLoyaltyWidgetById.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(removeLoyaltyWidgetById.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchWidgetVariant.fulfilled, (state, action) => {
        state.constants.widgetVariantList = action.payload
      })
      .addCase(fetchImageRulesWidgetSpecial.fulfilled, (state, action) => {
        state.constants.imageRulesWidgetSpecialBg = action.payload
      })
      .addCase(uploadImage.pending, (state) => {
        state.isLoading = true
      })
      .addCase(uploadImage.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(uploadImage.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchDetailWidgetById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchDetailWidgetById.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchDetailWidgetById.fulfilled, (state, action) => {
        const { payload } = action

        state.createEditForm = {
          ...state.createEditForm,
          title: payload.title,
          widgetType: payload.widget_type,
        }

        if (action.payload.widget_type.key === 'WIDGET_SPECIAL') {
          state.createEditForm = {
            ...state.createEditForm,
            tabs: payload.tabs.map((i) => ({ ...getInitWidgetSpecialTabData(i) })),
          }
        }

        if (action.payload.widget_type.key === 'WIDGET_BACKGROUND') {
          state.createEditForm = {
            ...state.createEditForm,
            title: payload.title,
            widgetType: payload.widget_type,
            backgroundUrl: payload.background_url,
            backgroundFilePath: payload.background_file_path || '',
            loyaltyProducts: payload.loyalty_products.map(
              (i) =>
                ({
                  productName: i.name,
                  productId: i.id,
                } as LoyaltyExchangeCoinListType),
            ),
          }
        }

        state.dataDetail = payload
        state.isLoading = false
      })
      .addCase(submitForm.pending, (state) => {
        state.isLoading = true
      })
      .addCase(submitForm.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(submitForm.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchLoyaltyProduct.fulfilled, (state, action) => {
        state.constants.loyaltyProductList = action.payload
      })
      .addCase(fetchImageRulesWidgetBg.fulfilled, (state, action) => {
        state.constants.imageRulesWidgetBg = action.payload
      })
  },
})

export const {
  reset,
  setWidgetList,
  setPopup,
  setSelectedWidget,
  resetSelectedWidget,
  setCreateEditForm,
  resetCreateEditForm,
} = loyatlyWidgetSlice.actions
export default loyatlyWidgetSlice.reducer

export const fetchLoyaltyWidget = createAsyncThunk(
  `${SLICE_NAME}/fetchLocationList`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getLoyaltyWidget({ page_size: 99, sort: 'position' })

      return data.data.widgets
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const updateLoyaltyWidgetSort = createAsyncThunk(
  `${SLICE_NAME}/updateLoyaltyWidgetSort`,
  async (data: PutLoyaltyWidgetSortPayloadType, { rejectWithValue }) => {
    try {
      await putLoyaltyWidgetSort(data)

      return true
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const updateLoyaltyWidgetStatusById = createAsyncThunk(
  `${SLICE_NAME}/updateLoyaltyWidgetStatusById`,
  async ({ id, newStatus }: { id: number; newStatus: boolean }, { rejectWithValue }) => {
    try {
      await putLoyaltyWidgetStatusById(id, { is_active: newStatus })

      return true
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const removeLoyaltyWidgetById = createAsyncThunk(
  `${SLICE_NAME}/removeLoyaltyWidgetById`,
  async (id: number, { rejectWithValue }) => {
    try {
      await deleteLoyaltyWidgetById(id)

      toastSuccess('Berhasil menghapus widget')

      return true
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchWidgetVariant = createAsyncThunk(
  `${SLICE_NAME}/fetchWidgetVariant`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getWidgetVariant()

      return data.data.types
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchImageRulesWidgetSpecial = createAsyncThunk(
  `${SLICE_NAME}/fetchImageRulesWidgetSpecial`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getImageRules(imageRulesTypeId.widgetSpecialBg)

      return data?.data || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan image rules background widget special')
      return rejectWithValue(err)
    }
  },
)

export const uploadImage = createAsyncThunk(
  `${SLICE_NAME}/uploadImage`,
  async (
    payload: {
      typeId: ImageVariantIdType
      data: PostImageRequestPayload
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await postImage(payload.typeId, payload.data)

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchDetailWidgetById = createAsyncThunk(
  `${SLICE_NAME}/fetchDetailWidgetById`,
  async (id: number, { rejectWithValue }) => {
    try {
      const { data } = await getWidgetById(id)

      return data?.data || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan detail widget')
      return rejectWithValue(err)
    }
  },
)

export const submitForm = createAsyncThunk(
  `${SLICE_NAME}/submitForm`,
  async (_, { rejectWithValue, getState }) => {
    const {
      loyaltyWidget: { createEditForm, dataDetail },
    } = getState() as StoreStateType

    const composedPayload = getComposedPayloadByWidgetType(createEditForm)

    try {
      // edit
      if (dataDetail) {
        await putLoyaltyWidget(dataDetail.id, composedPayload as PostPutLoyaltyWidgetPayloadType)

        toastSuccess('Berhasil Perbarui Widget')
      } else {
        await postLoyaltyWidget(composedPayload as PostPutLoyaltyWidgetPayloadType)

        toastSuccess('Berhasil Membuat Widget')
      }

      return null
    } catch (err) {
      callErrorMsg(err, dataDetail ? 'Gagal Perbarui Widget' : 'Gagal Membuat Widget')
      return rejectWithValue(err)
    }
  },
)

export const fetchCampaignLoyaltyList = createAsyncThunk(
  `${SLICE_NAME}/fetchCampaignLoyaltyList`,
  async (name: string, { rejectWithValue }) => {
    try {
      const { data } = await getCampaignLoyaltyList({
        params: { name, campaign_type: 'EXCLUSIVE' },
      })

      return data?.data?.campaigns || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan campaign loyalty list')
      return rejectWithValue(err)
    }
  },
)

export const fetchLoyaltyProduct = createAsyncThunk(
  `${SLICE_NAME}/fetchLoyaltyProduct`,
  async (name: string, { rejectWithValue }) => {
    try {
      const { data } = await getRedeemCoin({
        param: name,
        sort: 'updated_at',
        direction: 'desc',
        page_size: 20,
        source: 'widget',
      })

      return data?.data?.redeemRewards || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan Loyalty Product')
      return rejectWithValue(err)
    }
  },
)

export const fetchImageRulesWidgetBg = createAsyncThunk(
  `${SLICE_NAME}/fetchImageRulesWidgetBg`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getImageRules(imageRulesTypeId.widgetBg)

      return data?.data || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan image rules')
      return rejectWithValue(err)
    }
  },
)
