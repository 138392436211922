import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import type {
  InitialStateType,
  PopupModeType,
  SetQueryPayloadType,
  SLAType,
  SetPopupFormSLAType,
} from 'features/Location/@types/ServiceLevel'

export const SLICE_NAME = 'serviceLevel'

export const initialState: InitialStateType = {
  isLoading: [],
  popup: {
    mode: '',
    formSLA: {
      active: false,
      available: false,
      display_name: '',
      id: 0,
      maximum_duration: 0,
      minimum_duration: 0,
      name: '',
      total_duration: 0,
    },
  },
  query: { limit: 20, page: 0, name: '' },
  sla: [],
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,

  reducers: {
    resetState: () => initialState,
    resetPopup: (state) => {
      state.popup = initialState.popup
    },
    setPopupMode: (state, { payload }: PayloadAction<PopupModeType>) => {
      state.popup.mode = payload
    },
    setPopupFormSLA: (state, { payload }: PayloadAction<SetPopupFormSLAType>) => {
      state.popup.formSLA = { ...state.popup.formSLA, ...payload }
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      if (payload) {
        state.isLoading.push(true)
      } else {
        state.isLoading.pop()
      }
    },
    setQuery: (state, { payload }: PayloadAction<SetQueryPayloadType>) => {
      state.query = { ...state.query, ...payload }
    },
    setSLA: (state, { payload }: PayloadAction<SLAType[]>) => {
      state.sla = payload
    },
  },
})

export const {
  resetState,
  resetPopup,
  setPopupMode,
  setIsLoading,
  setQuery,
  setSLA,
  setPopupFormSLA,
} = slice.actions
export default slice.reducer
