import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import { getCleanQueryObj } from 'utils/queryParamsURL'
import {
  getApprovalInjectionList,
  putCustomerBalanceInjection,
} from 'features/Payment/services/approvalInjection'
import {
  PutCustomerBalanceInjectionRequestPayloadType,
  PutCustomerBalanceInjectionResponseType,
} from 'features/Payment/@types/approvalInjection'

export const SLICE_NAME = 'approvalInjection'

export const fetchApprovalInjectionList = createAsyncThunk(
  `${SLICE_NAME}/fetchApprovalInjectionList`,
  async (_, { rejectWithValue, getState }) => {
    const {
      approvalInjection: { query },
    } = getState() as StoreStateType
    try {
      const res = await getApprovalInjectionList({
        params: getCleanQueryObj(query),
      })

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateCustomerBalanceInjection = createAsyncThunk<
  PutCustomerBalanceInjectionResponseType,
  Pick<PutCustomerBalanceInjectionRequestPayloadType, 'action' | 'reject_reason'>
>(`${SLICE_NAME}/updateCustomerBalanceInjection`, async (param, { getState, rejectWithValue }) => {
  const {
    approvalInjection: { modal },
  } = getState() as StoreStateType

  const payload = {
    ...param,
    id: modal.data.map(({ id }) => id),
  }

  try {
    const {
      data: { data },
    } = await putCustomerBalanceInjection(payload)

    return data
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue('')
  }
})
