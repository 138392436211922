import { Typography as MuiTypography, TypographyProps as MuiTypographyProps } from '@mui/material'
import styled from 'styled-components'
import {
  compose,
  typography,
  color,
  space,
  TypographyProps as StyledTypographyProps,
  ColorProps,
  SpaceProps,
} from 'styled-system'

export type TypographyPropsType = MuiTypographyProps &
  StyledTypographyProps &
  ColorProps &
  SpaceProps

const Typography = styled(MuiTypography)<TypographyPropsType>(compose(typography, color, space))

export default Typography
