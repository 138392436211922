import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { serializeQueryToURL } from 'utils/queryParamsURL'
import {
  type MPStaffType,
  type FetchStaffResponseType,
  type ResignationResonsType,
  type MPStaffVehicleType,
  type MPStaffVendorListType,
  type MPStaffDepartementListType,
  type MPStaffLocationType,
  type FetchMPStaffQueryType,
  type MPStaffPaginationType,
} from '../../services'

export interface InitialStateInterface {
  isLoading: boolean
  staffList: MPStaffType[]
  query: FetchMPStaffQueryType
  resignationReasons: ResignationResonsType | ObjectNullType
  addEditData: {
    isOpen: boolean
    type?: 'add' | 'edit'
    selectedStaff?: MPStaffType | ObjectNullType
  }
  vehicleType: MPStaffVehicleType | ObjectNullType
  employmentType: string[]
  staffVendorList: MPStaffVendorListType[]
  departemenList: MPStaffDepartementListType | ObjectNullType
  locationList: MPStaffLocationType[]
  roleList: string[]
  pagination: MPStaffPaginationType
}

const initialState: InitialStateInterface = {
  isLoading: false,
  staffList: [],
  query: {
    pageIndex: 1,
    name: '',
  },
  resignationReasons: {},
  addEditData: {
    isOpen: false,
    type: 'add',
    selectedStaff: {},
  },
  vehicleType: {},
  employmentType: [],
  staffVendorList: [],
  departemenList: {},
  locationList: [],
  roleList: [],
  pagination: {
    direction: '',
    number_of_elements: 10,
    page_index: 1,
    page_size: 10,
    sort: '',
    total_elements: 0,
    total_pages: 0,
  },
}

export const sliceName = 'mpStaff'

const mpStaff = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setQuery: (state, action: PayloadAction<FetchMPStaffQueryType>) => {
      const updatedQuery = {
        ...state.query,
        ...action.payload,
      }

      state.query = updatedQuery
      serializeQueryToURL(updatedQuery)
    },
    setStaffList: (state, action: PayloadAction<FetchStaffResponseType>) => {
      const { data, pagination } = action.payload

      state.staffList = data
      state.pagination = pagination
    },
    setResignationReasons: (state, action: PayloadAction<ResignationResonsType>) => {
      state.resignationReasons = action.payload
    },
    setUpdateStaffStatus: (state, action: PayloadAction<{ id: number; status: string }>) => {
      const { id, status } = action.payload
      state.staffList = state.staffList.map((staff) => {
        if (staff.id === id) {
          return {
            ...staff,
            status,
          }
        }

        return staff
      })
    },
    setAddEditData: (state, action: PayloadAction<InitialStateInterface['addEditData']>) => {
      state.addEditData = {
        ...state.addEditData,
        ...action.payload,
      }
    },
    setVehicleType: (state, action: PayloadAction<MPStaffVehicleType>) => {
      state.vehicleType = action.payload
    },
    setEmploymentType: (state, action: PayloadAction<string[]>) => {
      state.employmentType = action.payload
    },
    setStaffVendorList: (state, action: PayloadAction<MPStaffVendorListType[]>) => {
      state.staffVendorList = action.payload
    },
    setDepartementList: (state, action: PayloadAction<MPStaffDepartementListType>) => {
      state.departemenList = action.payload
    },
    setLocationList: (state, action: PayloadAction<MPStaffLocationType[]>) => {
      state.locationList = action.payload
    },
    setRolesList: (state, action: PayloadAction<string[]>) => {
      state.roleList = action.payload
    },
  },
})

export const {
  setLoading,
  setQuery,
  setStaffList,
  setUpdateStaffStatus,
  setResignationReasons,
  setAddEditData,
  setVehicleType,
  setEmploymentType,
  setStaffVendorList,
  setDepartementList,
  setLocationList,
  setRolesList,
} = mpStaff.actions

export default mpStaff.reducer
