import { toastFailed } from "utils/toast";

import { getRakList_Rak } from "utils/apiList/rak";

export const actGetRakOption =
  (destinationID, localState = {}) =>
  (dispatch) => {
    const options = {
      query: `active=true`,
      setRakOptions: () => {},
      ...localState
    };
    return getRakList_Rak(`/${destinationID}?${options.query}`)
      .then(({ data }) => {
        // console.log(data);

        const rakOptions = data.racks.content.map((items) => {
          if (items === null) {
            return false;
          } else {
            return {
              ...items,
              name: items.rack_name
            };
          }
        });

        options.setRakOptions(rakOptions);
      })
      .catch(() => {
        toastFailed("Gagal mendapatkan rak");
      });
  };
