import styled from 'styled-components'

export const FlexStyle = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || 'row'};
  align-items: ${(props) => props.alignItems || 'center'};
  justify-content: ${(props) => props.justifyContent || 'flex-start'};
`

export const JumbotronStyle = styled.div`
  background-color: white;
  border-radius: 5px;
  padding: 15px;
  width: ${(props) => props.width};
  margin-top: ${(props) => props.marginTop};
`

export const SModal = {
  Container: styled.div`
    transition: all ease 0.2s;
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 3;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;
    margin: auto;
    transform: ${({ isShow }) => (isShow ? 'scale(1)' : 'scale(0)')};
    opacity: ${({ isShow }) => (isShow ? '1' : '0')};
  `,
  Main: styled.div`
    margin: 0 auto;
    width: ${({ width }) => width};
    height: auto;
    background-color: ${({ theme: { colors } }) => colors.white};
    border-radius: 8px;
    padding: 15px;
  `,
  Content: styled.div`
    width: 100%;
    height: 100%;
    background-color: ${({ theme: { colors } }) => colors.blackFaded};
    padding-top: 70px;
    padding-bottom: 50px;
  `,
  Header: styled.div`
    width: 100%;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  Footer: styled.div`
    margin-top: 15px;
    border-top: 1px solid #cecece;
    width: 100%;
    padding-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
}
