import { Get } from 'config/apiServiceConfig'
import { OrderItemType } from '../redux/AllOrder'

export type OrderTypesItemType = 'INSTANT' | 'GRAB_MART' | 'SUPER' | 'KITCHEN' | 'VENDING'

export type OrderType = OrderTypesItemType[]

export type AllOrderResponseType = {
  content: OrderItemType[]
  empty: boolean
  first: boolean
  last: boolean
  number: number
  numberOfElements: number
  pageable: {
    sort: {
      sorted: boolean
      unsorted: boolean
      empty: boolean
    }
    offset: number
    pageSize: number
    pageNumber: number
    paged: boolean
    unpaged: boolean
  }
  size: number
  sort: {
    sorted: boolean
    unsorted: boolean
    empty: boolean
  }
}

export type OrderLocationType = {
  location_id: number
  location_name: string
  location_type: string
}

export type OrderLocationResponseType = {
  data: OrderLocationType[]
  error: {
    status: boolean
    message: string
    code: number
  }
  pagination: null
}

export const getOrderTypes = () => Get<{ data: OrderType }>({ url: '/api/order/types' })

export const getAllOrder = (query: string) =>
  Get<AllOrderResponseType>({ url: `/api/order${query}` })

export const getOrderLocations = () =>
  Get<OrderLocationResponseType>({ url: `/api/locations/order` })
