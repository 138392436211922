import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const sliceName = 'tnc'

export interface TermsAndConditionsDataInterface {
  promotion_type: string
  tnc_title: string
  tnc_description: string
  tnc_status: string
  created_at: string
  updated_at: string
  deleted_by: string | null
  deleted_at: string | null
  created_by: string
  updated_by: string
  id: number
}

export interface TnCEditInterface {
  promotion_type: string
  tnc_title: string
  tnc_description: string
  tnc_status: string
  id: number
}

interface TermsAndConditionsInitialStateInterface {
  isLoading: boolean
  tncList: TermsAndConditionsDataInterface[]
  pagination: {
    offset: number
    limit: number
    totalItem: number
  }
  params: {
    limit: number
    offset: number
    tnc_description: string
    tnc_title: string
    promotion_type: string
  }
  tempData: TnCEditInterface
}

const initialState: TermsAndConditionsInitialStateInterface = {
  isLoading: false,
  tncList: [],
  pagination: {
    offset: 5,
    limit: 0,
    totalItem: 0,
  },
  params: {
    limit: 5,
    offset: 0,
    tnc_description: '',
    tnc_title: '',
    promotion_type: '',
  },
  tempData: {
    promotion_type: '',
    tnc_title: '',
    tnc_description: '',
    tnc_status: 'ACTIVE',
    id: 0,
  },
}

const appSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    reset: () => initialState,
    setLoading: (
      state,
      action: PayloadAction<TermsAndConditionsInitialStateInterface['isLoading']>,
    ) => {
      state.isLoading = action.payload
    },
    setParams: (
      state,
      action: PayloadAction<TermsAndConditionsInitialStateInterface['params']>,
    ) => {
      state.params = action.payload
    },
    setTnCList: (
      state,
      action: PayloadAction<TermsAndConditionsInitialStateInterface['tncList']>,
    ) => {
      state.tncList = action.payload
    },
    setPageNumber: (
      state,
      action: PayloadAction<TermsAndConditionsInitialStateInterface['pagination']>,
    ) => {
      state.pagination = action.payload
    },
    setTempData: (
      state,
      action: PayloadAction<TermsAndConditionsInitialStateInterface['tempData']>,
    ) => {
      state.tempData = action.payload
    },
  },
})

export const { setLoading, setParams, setTnCList, reset, setPageNumber, setTempData } =
  appSlice.actions

export default appSlice.reducer
