import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  FlashSaleType,
  InitialStateType,
  PaginationType,
  QueryType,
} from 'features/Promotion/@types/FlashSale'

const initialState: InitialStateType = {
  isLoading: false,
  flashSaleList: [],
  query: {
    page: 0,
    page_size: 20,
    sort_by: null,
    sort_direction: 'DESC',
    name: '',
  },
  pagination: {
    page_index: 0,
    number_of_elements: 0,
    page_size: 0,
  },
  selectedFlashSaleToDelete: null,
}

export const SLICE_NAME = 'flashSale'

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setQuery: (state, { payload }: PayloadAction<Partial<QueryType>>) => {
      state.query = { ...state.query, ...payload }
    },
    setFlashSaleList: (state, { payload }: PayloadAction<FlashSaleType[]>) => {
      state.flashSaleList = [...payload]
    },
    setPagination: (state, { payload }: PayloadAction<PaginationType>) => {
      state.pagination = payload
    },
    setSelectedFlashSaleToDelete: (state, { payload }: PayloadAction<Nullable<FlashSaleType>>) => {
      state.selectedFlashSaleToDelete = payload
    },
  },
})

export const {
  resetState,
  setIsLoading,
  setFlashSaleList,
  setPagination,
  setQuery,
  setSelectedFlashSaleToDelete,
} = slice.actions
export default slice.reducer
