import axios from 'axios'
const { REACT_APP_BASE_URL_API } = process.env
const baseUrl = REACT_APP_BASE_URL_API

export const getPackageId = () => {
  return '/api/package-id'
}

export const createPackageId = () => {
  return 'api/package-id'
}

export const checkPackageId = () => {
  return 'api/package-id/scan'
}

export const checkPackageIdByApi = (query = '') =>
  axios.get(`${baseUrl}/api/package-id/scan${query}`)

export const getPackageIdSingle = (query = '') => axios.get(`${baseUrl}/api/package-id/${query}`)

export const fetchCheckPackageIdAPI = (params) =>
  axios.get(`${baseUrl}/api/package-id/scan`, { params })
