import { Get, Delete } from 'config/apiServiceConfig'

export type ErrorType = {
  status: boolean
  message: string
  code: number
}
export type GetTargetedUserListRequestType = {
  params: {
    pageIndex: number
    pageSize: number
  }
  id: string
}

export type GetTargetedUserListResponseType = {
  data: {
    list: {
      id: number
      name: string
    }[]
  }
  error: ErrorType
  pagination: {
    sort: string
    direction: string
    pageSize: number
    pageIndex: number
    numberOfElements: number
    totalElements: number
    totalPages: number
  }
}

export type DeleteTargetedUserRequestType = {
  customerID: string
  campaignID: string
}

export const getTargetedUserList = ({ id, params }: GetTargetedUserListRequestType) =>
  Get<GetTargetedUserListResponseType>({ url: `/api/promo/gwp/${id}/targeted-user-list`, params })

export const deleteTargetedUser = ({ customerID, campaignID }: DeleteTargetedUserRequestType) =>
  Delete({ url: `/api/promo/gwp/${campaignID}/${customerID}` })
