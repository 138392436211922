import { useContext, createContext, useReducer } from 'react'
import createReducer, { PayloadActionType } from 'storeContext/reducers/createReducer'

const OPEN_POPUP = 'popup/OPEN_POPUP_CONFIRMATION'
const CLOSE_POPUP = 'popup/CLOSE_POPUP_CONFIRMATION'

type InititalStateType = {
  isOpen: boolean
  title?: string
  message: string
  type?: string
  submitText?: string
  cancelText?: string
  options?: Partial<{
    withCloseHeader: boolean
    textAlign: 'left' | 'center'
    actionPosition: 'fullwidth' | 'fit-content'
  }>

  onSubmit: () => void
  onClose?: () => void
  handleOpenConfirmation: (
    args: Pick<
      InititalStateType,
      | 'title'
      | 'message'
      | 'type'
      | 'options'
      | 'submitText'
      | 'cancelText'
      | 'onSubmit'
      | 'onClose'
    >,
  ) => void
  handleCloseConfirmation: () => void
  handleSubmitConfirmation: () => void
}

const initialState: InititalStateType = {
  isOpen: false,
  title: 'Konfirmasi',
  message: '',
  type: 'INFO',
  submitText: 'Ya',
  cancelText: 'Kembali',
  options: {
    withCloseHeader: false,
    textAlign: 'center',
    actionPosition: 'fullwidth',
  },
  onSubmit: () => undefined,
  onClose: () => undefined,
  handleOpenConfirmation: () => undefined,
  handleCloseConfirmation: () => undefined,
  handleSubmitConfirmation: () => undefined,
}

export const ConfirmationPopupContext = createContext(initialState)

const reducer = createReducer<InititalStateType>({
  [OPEN_POPUP]: (state, action: PayloadActionType<InititalStateType>) => {
    Object.keys(state).forEach((objKey) => {
      const key = objKey as never
      state[key] = action.payload[key] || initialState[key]
    })
    state.isOpen = true
  },
  [CLOSE_POPUP]: (state) => {
    state.isOpen = false
  },
})

export function useConfirmation() {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { onClose, onSubmit, ...popupProps } = state

  const handleOpenConfirmation = (
    args: Pick<
      InititalStateType,
      | 'title'
      | 'message'
      | 'type'
      | 'options'
      | 'submitText'
      | 'cancelText'
      | 'onSubmit'
      | 'onClose'
    >,
  ) => {
    dispatch({
      type: OPEN_POPUP,
      payload: { ...args },
    })
  }

  const handleCloseConfirmation = () => {
    if (typeof onClose === 'function') onClose()
    dispatch({
      type: CLOSE_POPUP,
    })
  }

  const handleSubmitConfirmation = () => {
    if (typeof onSubmit === 'function') onSubmit()
    handleCloseConfirmation()
  }

  return {
    ...popupProps,
    handleOpenConfirmation,
    handleCloseConfirmation,
    handleSubmitConfirmation,
  }
}

export function usePopupConfirmation() {
  const { isOpen, handleOpenConfirmation, handleCloseConfirmation } =
    useContext(ConfirmationPopupContext)

  return { isOpen, handleOpenConfirmation, handleCloseConfirmation }
}
