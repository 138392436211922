import { TableRow as MuiTableRow, TableRowProps as MuiTableRowProps } from '@mui/material'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'

export type TableRowPropsType = MuiTableRowProps & SpaceProps

const TableRow = styled(MuiTableRow)<TableRowPropsType>`
  ${space}
`

export default TableRow
