import { memo } from "react";
import styled from "styled-components";
import { Table } from "./components";
// import { TextBody } from "components";

function Body({ data, isSuratJalan, selectedData }) {
  return (
    <>
      <Container>
        <Table
          data={data}
          isSuratJalan={isSuratJalan}
          selectedData={selectedData}
        />
      </Container>
    </>
  );
}

export default memo(Body);

const Container = styled.div`
  width: 100%;
  //   border-collapse: collapse;
  //   height: fit-content;
  margin-top: 22px;
`;
