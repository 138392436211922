import { type PayloadAction, createSlice } from '@reduxjs/toolkit'

import { type GenerateTOTPResponseInterface } from 'features/Auth/services/login'

export type LoginInitialStateType = {
  isLoading: boolean
  tabLogin: 'byEmail' | 'byPhoneNumber'
  tabByPhoneNumber:
    | 'phone'
    | 'otp'
    | 'registerPin'
    | 'loginPin'
    | 'authenticator'
    | 'verifyCode'
    | 'wrongPin'
    | 'forgotPin'
    | 'loginAuthenticator'
    | 'forgotOtp'
    | 'resetPin'
  previousTabByPhoneNumber: LoginInitialStateType['tabByPhoneNumber']
  flagEmailLogin: 'init' | 'disabled' | 'allow'
  validatedPhoneNumber: string
  tempToken: string
  totp: GenerateTOTPResponseInterface | null
}

const initialState: LoginInitialStateType = {
  isLoading: false,
  tabLogin: 'byEmail',
  tabByPhoneNumber: 'phone',
  previousTabByPhoneNumber: 'phone',
  flagEmailLogin: 'init',
  validatedPhoneNumber: '',
  tempToken: '',
  totp: null,
}

export const SLICE_NAME = 'loginSlice'

const login = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: (state) => ({
      ...initialState,
      flagEmailLogin: state.flagEmailLogin,
    }),
    setLoading: (state, action: PayloadAction<LoginInitialStateType['isLoading']>) => {
      state.isLoading = action.payload
    },
    setTab: (state, action: PayloadAction<LoginInitialStateType['tabLogin']>) => {
      state.tabLogin = action.payload
    },
    setTabPhoneNumber: (
      state,
      action: PayloadAction<LoginInitialStateType['tabByPhoneNumber']>,
    ) => {
      state.previousTabByPhoneNumber = state.tabByPhoneNumber
      state.tabByPhoneNumber = action.payload
    },
    setValidatedPhoneNumber: (
      state,
      action: PayloadAction<LoginInitialStateType['validatedPhoneNumber']>,
    ) => {
      state.validatedPhoneNumber = action.payload
    },
    setTempToken: (state, action: PayloadAction<string>) => {
      state.tempToken = action.payload
    },
    setTOTP: (state, action: PayloadAction<LoginInitialStateType['totp']>) => {
      state.totp = action.payload
    },
    setFlagEmailLogin: (state, action: PayloadAction<LoginInitialStateType['flagEmailLogin']>) => {
      state.flagEmailLogin = action.payload
    },
  },
})

export const {
  setLoading,
  setTab,
  reset,
  setValidatedPhoneNumber,
  setTabPhoneNumber,
  setTempToken,
  setTOTP,
  setFlagEmailLogin,
} = login.actions

export default login.reducer
