import apiServiceApiGateway from 'config/apiServiceApiGateway'

export type GetPriceTagListParamsType = {
  serial_label?: string
  search_product?: string
  page_index: number
  page_size: number
  tag_status?: string
  location_id?: number
}

export type PriceTagItemType = {
  id: number
  label_serial: string
  location_id: number
  location_name: string
  sloc: string
  sku_number: string
  product_name: string
  price: number
  price_fmt: string
  discounted_price: number
  discounted_price_fmt: string
  label_template: string
  tag_status: string
  grammation: string
}

export type GetPriceTagListResponseType = PriceTagItemType[]

export type SingleOrBulkPrintPriceTagParamsType = {
  location_id: number
  ids: string
}

export const getPriceTagList = (params: Partial<GetPriceTagListParamsType>) =>
  apiServiceApiGateway.Get<GetPriceTagListResponseType>({
    url: '/ims/internal/v1/price-tag',
    params: { ...params, tag_status: params?.tag_status?.toString() },
  })

export const singlePrintPriceTag = (params: SingleOrBulkPrintPriceTagParamsType) =>
  apiServiceApiGateway.Get<GetPriceTagListResponseType>({
    url: '/ims/internal/v1/price-tag/print',
    params,
  })

export const bulkPrintPriceTag = (qs: string) =>
  apiServiceApiGateway.Get<GetPriceTagListResponseType>({
    url: `/ims/internal/v1/price-tag/print${qs}`,
  })
