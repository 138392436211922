import { memo } from 'react'
import styled from 'styled-components'
import logo_Black from 'assets/images/logo_astro_black.svg'
// import barcode_logo from "assets/images/barcode_resi.png";
import { TextBody } from 'components'
import dayjs from 'dayjs'

function Header({ data, isSuratJalan }) {
  //console.log(data);
  return (
    <Container>
      <LogoContainer>
        {/* {<Barcode
          src={data?.order_barcode_url}
          onLoad={handleList.handleOnloadBarcode}
        />} */}
        {/* <Barcode
          //src={imageBarcodeBlob}
          src={barcode_logo}
          //onLoad={handleList.handleOnloadBarcode}
        /> */}
        <Logo
          src={logo_Black}
          //onLoad={handleList.handleOnLoadLogo}
        />
      </LogoContainer>
      <OrderInfoContainer>
        <Title>
          <TextBody
            color="pureBlack"
            style={{ fontSize: '1.25rem', textTransform: 'uppercase' }}
            weight="bold"
          >
            {isSuratJalan ? 'Surat Jalan' : 'Picking List'}
          </TextBody>
        </Title>
        <INVDateToWrapper>
          <Invoice>
            <TextBody
              color="pureBlack"
              size="bigger"
              //style={{ fontSize: "1.6rem", textTransform: "uppercase" }}
              weight="normal"
            >
              {/* {isSuratJalan ? data?.inbound_number : data?.supply_order_number} */}
              {data?.supply_order_number}
            </TextBody>
          </Invoice>
          <Time>
            <TextBody
              color="pureBlack"
              size="bigger"
              weight="light"
              style={{ marginTop: 'auto', marginBottom: '2px' }}
            >
              {dayjs(/*isSuratJalan ? "" : */ data?.supply_order_created_at).format(
                'DD MMM YYYY, HH:mm',
              )}
            </TextBody>
          </Time>
          <To>
            <TextBody
              color="pureBlack"
              size="bigger"
              weight="light"
              style={{ marginTop: 'auto', marginBottom: '2px' }}
            >
              To :{' '}
              {/* {isSuratJalan
                ? data?.inbound_destination
                : data?.location_destination_name} */}
              {data?.supply_order_destination}
            </TextBody>
          </To>
        </INVDateToWrapper>
      </OrderInfoContainer>
    </Container>
  )
}

export default memo(Header)

const Container = styled.div`
  display: flex;
  flex-direction: column;
`
const LogoContainer = styled.div`
  width: 100%;
  display: flex;
`
// const Barcode = styled.img`
//   height: 40px;
//   width: 257px;

//   margin-right: auto;
// `;
const Logo = styled.img`
  height: 28px;
  width: 135px;
  //   margin-left: auto;
  //   margin-right: auto;
  //   margin-top: auto;
  //   margin-bottom: auto;
`

const OrderInfoContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`
const Invoice = styled.div`
  margin-right: 12px;
`
const Time = styled.div`
  display: flex;
`
const Title = styled.div`
  //   font-family: ${({ theme: { fontFamily } }) => fontFamily.monstreat};
  font-size: 34px;
  margin-bottom: 8px;
`

const To = styled.div`
  margin-left: auto;
`

const INVDateToWrapper = styled.div`
  display: flex;
`
