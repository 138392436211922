import {
  SET_SETTINGS_DATA,
  SET_IS_LOADING,
  SET_ORDER_DETAIL
} from "storeContext/actionsType/settings";

export const initialStateSettings = {
  isLoading: false,
  settingData: {},
  orderDetail: {}
};

export default function setting(
  state = initialStateSettings,
  { type, payload }
) {
  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload
      };
    case SET_SETTINGS_DATA:
      return {
        ...state,
        settingData: payload
      };
    case SET_ORDER_DETAIL:
      return {
        ...state,
        orderDetail: payload
      };
    default:
      return state;
  }
}
