import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { GetFpoDetailResponseType } from 'utils/apiList/freshPurchaseOrder'
import {
  SLICE_NAME,
  fetchFpoDetail,
  processInboundFpo,
  pricingFpo,
} from './freshPurchaseOrderProcessInboundThunk'

export type FpoItemDataType = GetFpoDetailResponseType['data']['items'][number]
interface InboundItemInterface extends FpoItemDataType {
  isChecked: boolean
  isRowDisabled: boolean
  isPriceDisabled: boolean
  maxReceivedQty: number
  qty: {
    isError: boolean
    errorMessage: string
  }
  price: {
    isError: boolean
    errorMessage: string
  }
}

export interface InboundItemConfirmationInterface extends FpoItemDataType {
  restQty: number
}

interface SetPriceOrQtyInterface {
  value: number
  index: number
}

export interface FpoInboundInterface {
  isHeaderChecked: boolean
  isLoading: boolean
  searchSku: string
  data: GetFpoDetailResponseType['data'] | null
  itemList: InboundItemInterface[]
  confirmationType: 'processPartial' | 'processAll' | 'pricing' | null
  isModalConfirmationOpen: boolean
  confirmationItemList: InboundItemConfirmationInterface[]
  tempItemList: InboundItemInterface[]
  countCheckedItems: number
  allowHeaderCheckboxToToggle: boolean
  activityType: 'process-inbound' | 'pricing' | ''
}

interface ToggleConfirmationInterface {
  isModalOpen: boolean
  confirmationType: FpoInboundInterface['confirmationType']
}

const initialState: FpoInboundInterface = {
  isHeaderChecked: false,
  isLoading: true,
  searchSku: '',
  data: null,
  itemList: [],
  confirmationType: null,
  isModalConfirmationOpen: false,
  confirmationItemList: [],
  tempItemList: [],
  countCheckedItems: 0,
  allowHeaderCheckboxToToggle: true,
  activityType: '',
}

const freshPurchaseOrderInboundSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    setTempItemDataFromItemList: (
      state,
      action: PayloadAction<{ id: number; indexItem: number }>,
    ) => {
      const { id, indexItem } = action.payload
      const indexOfTempItemList = state.tempItemList.findIndex((itemData) => itemData.id === id)
      state.tempItemList[indexOfTempItemList] = state.itemList[indexItem]
    },
    setItemListToTempVariable: (state) => {
      state.tempItemList = state.itemList
    },
    searchItemsBySkuName: (state, action: PayloadAction<string>) => {
      const skuName = action.payload.toLowerCase()
      state.searchSku = skuName
      state.itemList = state.tempItemList.filter((itemData) =>
        itemData.product.product_name.toLowerCase().includes(skuName),
      )
    },
    setPriceInItemRow: (state, action: PayloadAction<SetPriceOrQtyInterface>) => {
      const { index, value: newActualPriceValue } = action.payload
      state.itemList[index].actualPrice = newActualPriceValue
    },
    setReceivedQtyInItemRow: (state, action: PayloadAction<SetPriceOrQtyInterface>) => {
      const { index, value: newReceivedQtyValue } = action.payload
      state.itemList[index].receivedQty = newReceivedQtyValue
    },
    toggleCheckItemRowData: (state, action: PayloadAction<number>) => {
      const index = action.payload
      const { isChecked } = state.itemList[index]
      state.itemList[index].isChecked = !isChecked
    },
    toggleAllItems: (state, action: PayloadAction<boolean>) => {
      const isChecked = action.payload

      state.isHeaderChecked = isChecked

      state.itemList = state.itemList.map((itemData) => ({
        ...itemData,
        isChecked: itemData.isRowDisabled ? itemData.isChecked : isChecked,
      }))

      state.itemList.forEach((_, index) => {
        if (state.activityType === 'pricing') {
          freshPurchaseOrderInboundSlice.caseReducers.validateActualPrice(state, {
            type: 'validateActualPrice',
            payload: index,
          })
        }
        if (state.activityType === 'process-inbound') {
          freshPurchaseOrderInboundSlice.caseReducers.validateReceivedQty(state, {
            type: 'validateReceivedQty',
            payload: index,
          })
        }

        freshPurchaseOrderInboundSlice.caseReducers.calculateCountOfCheckedSku(state)
      })
    },
    validateActualPrice: (state, action: PayloadAction<number>) => {
      const index = action.payload
      const { isChecked, actualPrice } = state.itemList[index]

      if (isChecked && (!actualPrice || (actualPrice && actualPrice <= 0))) {
        state.itemList[index].price.isError = true
        state.itemList[index].price.errorMessage = 'Actual price wajib lebih besar dari 0'
      }
      if ((isChecked && actualPrice && actualPrice > 0) || !isChecked) {
        state.itemList[index].price.isError = false
        state.itemList[index].price.errorMessage = ''
      }
    },
    validateReceivedQty: (state, action) => {
      const index = action.payload
      const { isChecked, receivedQty, maxReceivedQty } = state.itemList[index]

      const isReceivedQtyGreaterThanMaxReceived = receivedQty && receivedQty > maxReceivedQty

      if (isChecked && !receivedQty) {
        state.itemList[index].qty.isError = true
        state.itemList[index].qty.errorMessage = `Qty yg diterima tidak boleh kosong`
      }
      if (isChecked && isReceivedQtyGreaterThanMaxReceived && maxReceivedQty !== 0) {
        state.itemList[index].qty.isError = true
        state.itemList[
          index
        ].qty.errorMessage = `Qty yg diterima tidak boleh melebihi ${maxReceivedQty}`
      }
      if ((isChecked && receivedQty && !isReceivedQtyGreaterThanMaxReceived) || !isChecked) {
        state.itemList[index].qty.isError = false
        state.itemList[index].qty.errorMessage = ''
      }
    },
    toggleModalConfirmation: (state, action: PayloadAction<ToggleConfirmationInterface>) => {
      const { isModalOpen, confirmationType } = action.payload
      state.isModalConfirmationOpen = isModalOpen
      state.confirmationType = confirmationType
    },
    populateConfirmationData: (state) => {
      const getRestQty = (itemData: InboundItemInterface) => {
        if (itemData.receivedQty) {
          const restQty = itemData.remainingQty - itemData.receivedQty
          if (restQty >= 0) return restQty
          return 0
        }
        return 0
      }
      state.confirmationItemList = state.itemList
        .filter((itemData) => itemData.isChecked)
        .map((itemData) => ({ ...itemData, restQty: getRestQty(itemData) }))
    },
    calculateCountOfCheckedSku: (state) => {
      const listCheckedItems = state.itemList.filter((itemData) => itemData.isChecked)
      state.countCheckedItems = listCheckedItems.length
    },
    setActivityType: (state, action: PayloadAction<FpoInboundInterface['activityType']>) => {
      state.activityType = action.payload
    },
    setErrorMessageForReceivedQty: (
      state,
      action: PayloadAction<{ index: number; errorMessage: string }>,
    ) => {
      const { index, errorMessage } = action.payload
      state.itemList[index].qty.errorMessage = errorMessage
      state.itemList[index].qty.isError = !!errorMessage
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFpoDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFpoDetail.fulfilled, (state, action) => {
        const { data, itemList } = action.payload
        state.isLoading = false
        state.data = data
        state.itemList = itemList
        state.allowHeaderCheckboxToToggle = !itemList.every((itemData) => itemData.isRowDisabled)
        state.tempItemList = itemList
      })
      .addCase(fetchFpoDetail.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(processInboundFpo.fulfilled, (state) => {
        state.isModalConfirmationOpen = false
      })
      .addCase(pricingFpo.fulfilled, (state) => {
        state.isModalConfirmationOpen = false
      })
  },
})
export default freshPurchaseOrderInboundSlice
