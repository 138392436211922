//import { memo, useState } from "react";
import styled from "styled-components";
//import { DateRangePicker } from 'react-date-range';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './style.css';

import {
    SystemIcon,
    //Button,
} from "components"

function InputDatePicker({
    className,
    label,
    border,
}){
    return(
        <div style={{position:'relative'}}>
            <InputContainer 
                className={`${className} input-container`}
                iconPos="left"
                border={border}
                
                >
                    <Container 
                        iconPos="left"
                        border={border}
                    >
                        <IconWrapper>
                            <SystemIcon
                                iconName="dropdown"
                                colorIcon="grey"
                                fontSize="smaller"
                            />
                        </IconWrapper>
                        <Input
                            //{...rest}
                            //onChange={onChange}
                            //onFocus={onFocus}
                            //onBlur={onBlur}
                            autoComplete="off"
                            placeholder="pilih"
                            //disabled={true}
                            //value={previewInput.startDate + ' - ' + previewInput.endDate  }
                            readOnly={true}
                            //onClick={() => setIsShowDateRange(!isShowDateRange)}
                        />
                        <label>
                            <IconWrapper
                                //onClick={handleClickIcon}
                                className="icon-wrapper"
                                iconPos="left"
                                bgIcon="s"
                            >
                                <SystemIcon 
                                    iconName="calendar"
                                    colorIcon="grey" 
                                />
                            </IconWrapper>
                        </label>
                    </Container>
            </InputContainer>
            <DatePicker />
        </div>
    )
}

function DatePicker({
    xPos,
}){
    return(
        
        <DatePickerContainer 
            xPos={xPos}
            className="single-date-picker"
        >
            <Calendar />
        </DatePickerContainer>
        
        
    )
}

DatePicker.defaultProps = {
    xPos : 'left'
};


InputDatePicker.defaultProps = {
    className : '',
    label : '',
    border: true,
}

export default InputDatePicker;

const Container = styled.div`
    width: 100%;
    border-radius: 6px;
    display: flex;
    position: relative;
    background-color: ${({ theme: { colors } }) => colors.white};
    border: ${({ border }) => (border ? "solid 1px #E4EAF3" : "none")};
    flex-direction: ${({ iconPos }) =>
        iconPos === "left" ? "row-reverse" : "row"};
`;
const InputContainer = styled.div`
    width: 100%;
  
`;
const IconWrapper = styled.div`
  display: grid;
  padding: 10px;
  place-items: center;
  background-color: ${({ theme: { colors }, bgIcon }) => colors[bgIcon]};
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.light};
  font-family: "Noto Sans JP", sans-serif !important;
  /* padding-right: 0; */
`;

const Input = styled.input`
    background-color: white;
    border: none;
    width: 100%;
    padding: 10px;
    font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
    color: ${({ theme: { colors }, color }) => colors[color]};
    border-radius: 6px;
    &:focus {
        outline: none;
    }
    ::placeholder {
        opacity: 1;
        color: #aab4c8;
    }
    ::-webkit-calendar-picker-indicator {
        width: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        /* display: none;
        -webkit-appearance: none; */
    }
`;

const DatePickerContainer = styled.div`
    padding:10px;
    position:absolute;
    ${({xPos}) => xPos === 'left'? 'left:0;' : 'right: 0;'  }
    bottom:43px;
    background: white;
    box-shadow: 0px 30px 84px rgba(19, 10, 46, 0.08), 0px 8px 32px rgba(19, 10, 46, 0.07), 0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13);
    border-radius: 12px;
`
/*
const DatePickerFooter = styled.div`
    display: flex;
`
const DatePickerHeader = styled.div`
    display: flex;
`
*/