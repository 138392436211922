import { memo } from 'react'
import styled from 'styled-components'
import { TextBody } from 'components'
import corner_down_right from 'assets/images/corner-down-right.svg'

function OrderIDContainer({
  data,
  handleChangeOrderPool,
  setOrderPoolingData,
  orderPoolingData,
  noCheckbox,
}) {
  const parseOrderRef = (ref) => {
    let refArray = ref.split('/')
    return refArray[refArray.length - 1]
  }
  return (
    <Container>
      <IDContainer>
        <OrderIdTextCont>{data?.order_id}</OrderIdTextCont>
        {data?.order_reference ? (
          <OrderIDRefContainer>
            <OrderIDIconContainer>
              <img alt="arrow-right" src={corner_down_right} />
            </OrderIDIconContainer>
            <OrderIDRefNumbContainer>
              <TextBody
                weight="light"
                color="grey"
                size="smallest"
                style={{ marginTop: 'auto', marginBottom: 'auto' }}
              >
                {`Ref: ${parseOrderRef(data?.order_reference)}`}
              </TextBody>
            </OrderIDRefNumbContainer>
          </OrderIDRefContainer>
        ) : (
          <OrderIDRefContainer>
            <OrderIDIconContainer></OrderIDIconContainer>
            <OrderIDRefNumbContainer>
              <div style={{ marginTop: 'auto', marginBottom: 'auto' }}></div>
            </OrderIDRefNumbContainer>
          </OrderIDRefContainer>
        )}
      </IDContainer>
    </Container>
  )
}

export default memo(OrderIDContainer)

const Container = styled.div`
  /* padding-right: 10px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 60px;
`

const IDContainer = styled.div`
  /* padding-right: 10px; */
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-wrap: break-word;
  align-items: center;
`

const OrderIDRefContainer = styled.div`
  display: flex;
`

const OrderIDIconContainer = styled.div``

const OrderIDRefNumbContainer = styled.div`
  display: flex;
`
const OrderIdTextCont = styled.div`
  font-weight: bold;
  font-size: 18px;
  color: rgba(49, 53, 59, 0.96);
`
