import { createAsyncThunk } from '@reduxjs/toolkit'
import { getWarehouse } from 'utils/api'
import {
  getTrimmingPackingList,
  getGrnFpo,
  GetTrimmingPackingRequestType,
} from 'utils/apiList/freshPurchaseOrderTrimmingPacking'
import { getCleanQueryObj, queryParamsURL } from 'utils/queryParamsURL'
import { callErrorMsg } from 'helpers/errorMsg'

import {
  TDocumentDefinitions,
  Table,
  PageBreak,
  StyleReference,
  TableLayout,
} from 'pdfmake/interfaces'

import logoAstro from 'assets/images/logo-black.png'
import logoGrn from 'assets/images/grn_logo.png'
import { dateFormat } from 'utils/helpers/date'
import { toastFailed } from 'utils/toast'

export const SLICE_NAME = 'freshPurchaseOrderTrimmingPacking'

export const fetchWarehouseList = createAsyncThunk(
  `${SLICE_NAME}/fetchWarehouseList`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await getWarehouse()
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchTrimmingPackingList = createAsyncThunk(
  `${SLICE_NAME}/fetchTrimmingPackingList`,
  async (_, { getState, rejectWithValue }) => {
    try {
      const rootState = getState() as StoreStateType
      const { query } = rootState.freshPurchaseOrderTrimmingPacking
      const cleanQueryObj = getCleanQueryObj(query)

      const {
        data: { data },
      } = await getTrimmingPackingList(cleanQueryObj as GetTrimmingPackingRequestType)
      queryParamsURL.set(query)
      return data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

type ItemListType = [string, string, string, number, number, string, string, string]
type TableType = {
  table: Table
  pageBreak?: PageBreak | undefined
  style?: StyleReference | undefined
  layout?: TableLayout | undefined
}[]

type GrnDataType = {
  packingNumber: string
  fpoDate: string
  fpoNumber: string
  vendorName: string
  locationName: string
  grnNumber: string
  grnDate: string
  createdBy: string
  tableList: TableType
}

type PdfPrintFrameType = {
  frames: { printPdf: Window }
}

const printGrn = (grnData: GrnDataType) => {
  if (!window.pdfMake) {
    toastFailed('PdfMake is not ready')
    return
  }

  const { pdfMake } = window
  const docDefinition: TDocumentDefinitions = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 70, 20, 50],
    header: {
      table: {
        headerRows: 1,
        widths: ['50%', '50%'],
        body: [
          [
            {
              image: logoAstro,
              fit: [100, 100],
              alignment: 'left',
              margin: 10,
            },
            {
              stack: [
                {
                  image: logoGrn,
                  fit: [200, 200],
                  alignment: 'right',
                  margin: [0, 10, 5, 0],
                },
                {
                  text: 'Fresh Processing',
                  alignment: 'right',
                  margin: [0, 5, 10, 10],
                },
              ],
            },
          ],
        ],
      },
      layout: {
        hLineColor: '#cdcaca',
        vLineColor: 'white',
      },
    },
    footer: (currentPage, pageCount, pageSize) => {
      const pageTxt = `Hal ${currentPage.toString()} dari ${pageCount}`
      return [
        { text: pageTxt, alignment: 'center' },
        { canvas: [{ type: 'rect', x: 170, y: 32, w: pageSize.width - 170, h: 100 }] },
      ]
    },
    content: [
      {
        columns: [
          {
            width: '60%',
            columns: [
              [
                {
                  columns: [
                    {
                      width: '30%',
                      text: 'No. GRN',
                      style: 'label',
                    },
                    {
                      width: '70%',
                      text: `: ${grnData.grnNumber}`,
                      style: 'marginYSmall',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: '30%',
                      text: 'No. Packing FPO',
                      style: 'label',
                    },
                    {
                      width: '70%',
                      text: `: ${grnData.packingNumber}`,
                      style: 'marginYSmall',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: '30%',
                      text: 'Tgl. FPO',
                      style: 'label',
                    },
                    {
                      width: '70%',
                      text: `: ${grnData.fpoDate}`,
                      style: 'marginYSmall',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: '30%',
                      text: 'No. FPO',
                      style: 'label',
                    },
                    {
                      width: '70%',
                      text: `: ${grnData.fpoNumber}`,
                      style: 'marginYSmall',
                    },
                  ],
                },
              ],
            ],
          },
          {
            width: '40%',
            columns: [
              [
                {
                  columns: [
                    {
                      width: '40%',
                      text: 'Tujuan',
                      style: 'label',
                    },
                    {
                      width: '60%',
                      text: `: ${grnData.locationName}`,
                      style: 'marginYSmall',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: '40%',
                      text: 'Tanggal GRN',
                      style: 'label',
                    },
                    {
                      width: '60%',
                      text: `: ${grnData.grnDate}`,
                      style: 'marginYSmall',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: '40%',
                      text: 'Di GRN Oleh',
                      style: 'label',
                    },
                    {
                      width: '60%',
                      text: `: ${grnData.createdBy}`,
                      style: 'marginYSmall',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: '40%',
                      text: 'Vendor',
                      style: 'label',
                    },
                    {
                      width: '60%',
                      text: `: ${grnData.vendorName}`,
                      style: 'marginYSmall',
                    },
                  ],
                },
              ],
            ],
          },
        ],
      },
      ...grnData.tableList,
      {
        style: 'tableSignature',
        table: {
          widths: [100, 100, 100],
          heights: ['auto', 90],
          headerRows: 2,
          body: [
            [
              {
                text: 'TTD PIC',
                style: 'tableHeader',
                colSpan: 3,
                alignment: 'left',
                marginLeft: 10,
              },
              {},
              {},
            ],
            [
              {
                text: `Dibuat Oleh,\n\n\n\n\n ${grnData.createdBy}`,
                alignment: 'center',
              },
              { text: 'Diterima Oleh,', alignment: 'center' },
              { text: 'Diperiksa oleh,', alignment: 'center' },
            ],
          ],
        },
        layout: {
          hLineColor: '#cdcaca',
          vLineColor: '#cdcaca',
        },
      },
    ],

    styles: {
      label: {
        bold: true,
        margin: [0, 5],
      },
      marginYSmall: {
        margin: [0, 5],
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: 'black',
        fillColor: '#F3F4F5',
        margin: [0, 7, 0, 10],
      },
      tableSkuDetails: {
        margin: [0, 20, 0, 0],
      },
      tableSignature: {
        margin: [0, 20, 0, 0],
      },
    },
  }

  pdfMake
    .createPdf(docDefinition)
    .print({}, (window as unknown as PdfPrintFrameType).frames.printPdf)
}

export const fetchGrnForTrimmingPacking = createAsyncThunk(
  `${SLICE_NAME}/fetchGrnForTrimmingPacking`,
  async (id: number, { rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await getGrnFpo(id)

      const groupItemList: ItemListType[][] = []
      let itemList: ItemListType[] = []

      data.items.forEach((itemData, index) => {
        const formattedExpiredDate = dateFormat({
          date: new Date(itemData.expiredDate),
          format: 'YYYYMMDD',
          locale: 'id',
        })

        const getRackName = (rack: { rackName: string }) => {
          if (rack) return rack.rackName
          return '-'
        }

        itemList.push([
          itemData.product.sku,
          itemData.product.name,
          getRackName(itemData.rack),
          itemData.qtySent,
          itemData.qtyReceived,
          itemData.uom,
          formattedExpiredDate,
          itemData.status,
        ])

        const isMultipleOfTen = (index + 1) % 10 === 0
        const isLastIndex = index === data.items.length - 1
        if (isMultipleOfTen || isLastIndex) {
          groupItemList.push(itemList)
          itemList = []
        }
      })

      const formattedGrnDate = dateFormat({
        date: new Date(data.grnDate),
        format: 'D MMM YYYY - HH:mm',
        locale: 'id',
      })

      const formattedFpoDate = dateFormat({
        date: new Date(data.fpoDate),
        format: 'D MMM YYYY - HH:mm',
        locale: 'id',
      })

      const listOfTable: TableType = []

      groupItemList.forEach((groupItem, index) => {
        const tableWidth = [60, 100, 60, 50, 50, 50, 55, 50]

        const tableHeader = [
          { text: 'No SKU', style: 'tableHeader' },
          { text: 'Deskripsi', style: 'tableHeader' },
          { text: 'SLOC', style: 'tableHeader' },
          { text: 'Jumlah Dikirim', style: 'tableHeader' },
          { text: 'Jumlah Diterima', style: 'tableHeader' },
          { text: 'UOM', style: 'tableHeader' },
          { text: 'Tanggal Expired', style: 'tableHeader' },
          { text: 'Status', style: 'tableHeader' },
        ]

        listOfTable.push({
          table: {
            widths: tableWidth,
            heights: 40,
            body: [tableHeader, ...groupItem],
          },
          pageBreak: index !== groupItemList.length - 1 ? 'after' : undefined,
          style: 'tableSkuDetails',
          layout: {
            hLineColor: '#cdcaca',
            vLineColor: '#cdcaca',
          },
        })
      })

      const grnData = {
        packingNumber: data.packingNumber,
        fpoDate: formattedFpoDate,
        fpoNumber: data.fpoNumber,
        vendorName: data.vendor.vendorName,
        locationName: data.location.locationName,
        grnNumber: data.grnNumber,
        grnDate: formattedGrnDate,
        createdBy: data.createdBy,
        tableList: listOfTable,
      }

      printGrn(grnData)

      return null
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
