import {
  SET_IS_START_SERVICE_LEVEL,
  SET_IS_FAILED_SERVICE_LEVEL,
  SET_IS_SUCCESS_SERVICE_LEVEL,
  SET_IS_NEED_TO_RELOAD_SERVICE_LEVEL,
  SET_PAGINATION_DATA_SERVICE_LEVEL
} from "storeContext/actionsType/serviceLevel";

export const initialStateServiceLevel = {
  isLoading: false,
  serviceLevelData: [],
  paginationData: {},
  needToReload: 1
};

export default function serviceLevel(
  state = initialStateServiceLevel,
  { type, payload }
) {
  switch (type) {
    case SET_IS_START_SERVICE_LEVEL:
      return {
        ...state,
        isLoading: true
      };
    case SET_IS_FAILED_SERVICE_LEVEL:
      return {
        ...state,
        isLoading: false
      };
    case SET_IS_SUCCESS_SERVICE_LEVEL:
      return {
        ...state,
        isLoading: false,
        serviceLevelData: payload.content
      };
    case SET_PAGINATION_DATA_SERVICE_LEVEL:
      return {
        ...state,
        paginationData: payload
      };
    case SET_IS_NEED_TO_RELOAD_SERVICE_LEVEL:
      return {
        ...state,
        needToReload: state.needToReload + 1
      };
    default:
      return { ...state };
  }
}
