import styled from 'styled-components'

import { Box, Button, Icon } from '@astro-ui/components'

export interface PaginationPropsType {
  pageIndex: number
  pageSize: number
  numberOfElements: number
  onClick: (page: number) => void
  hasBorder?: boolean
  firstPage?: number
  iconSize?: number
  padding?: string
  enableCustomDisabledNextPage?: boolean
  isNextPageDisabled?: boolean
  PageNumberComponent?: React.ReactNode
}

const Pagination = ({
  pageIndex,
  pageSize,
  numberOfElements,
  onClick,
  hasBorder = false,
  firstPage = 1,
  iconSize = 12,
  padding = '14px',
  enableCustomDisabledNextPage,
  isNextPageDisabled,
  PageNumberComponent,
}: PaginationPropsType) => (
  <Box display="flex" justifyContent="flex-end" alignItems="center" mb="1">
    <ButtonPage
      disabled={pageIndex === firstPage}
      variant="white"
      mr="2"
      onClick={() => onClick(pageIndex - 1)}
      $hasBorder={hasBorder}
      padding={padding}
    >
      <Icon icon="chevron-left" color="grey7" size={iconSize} />
    </ButtonPage>
    {PageNumberComponent}
    <ButtonPage
      disabled={enableCustomDisabledNextPage ? isNextPageDisabled : numberOfElements < pageSize}
      variant="white"
      onClick={() => onClick(pageIndex + 1)}
      $hasBorder={hasBorder}
      padding={padding}
    >
      <Icon icon="chevron-right" color="grey7" size={iconSize} />
    </ButtonPage>
  </Box>
)

interface ButtonPageInterface {
  $hasBorder: boolean
}

const ButtonPage = styled(Button)<ButtonPageInterface>`
  border: ${({ $hasBorder, theme }) =>
    $hasBorder ? `1px solid ${theme.colors.softGrey3}` : 'none'};
  min-width: 0;
  border: ${({ theme }) => `1px solid ${theme.colors.grey}`};

  &:disabled {
    pointer-events: auto;
    background-color: #fcfcfc;
  }
  &.Mui-disabled {
    cursor: not-allowed !important;
  }
`

export default Pagination
