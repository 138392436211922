import axiosInstanceApiGateway from 'config/apiServiceApiGateway'
import {
  ScheduleCloseType,
  QueryType,
  ScheduleCloseLocationType,
} from '../@types/GlobalScheduledClose'

const { Get, Post, Put, Delete } = axiosInstanceApiGateway

export type GetScheduleCloseResponseType = {
  global_scheduled_close: ScheduleCloseType[]
}

export type PostScheduleClosePayloadType = {
  start_date: string
  end_date: string
  sla_id: number[]
  location: ScheduleCloseLocationType[]
  close_reason_id: number
}
export const getScheduleClose = (params: Partial<QueryType>) =>
  Get<GetScheduleCloseResponseType>({
    url: '/location/internal/:version/global-scheduled-close',
    version: 'v1',
    params,
  })

export const postScheduleClose = (data: PostScheduleClosePayloadType) =>
  Post({
    url: '/location/internal/:version/global-scheduled-close',
    version: 'v1',
    data,
  })

export const putScheduleClose = (id: number | string, data: PostScheduleClosePayloadType) =>
  Put({
    url: '/location/internal/:version/global-scheduled-close/:id',
    version: 'v1',
    data,
    urlParams: { id },
  })

export const deleteScheduleClose = (id: number | string) =>
  Delete({
    url: '/location/internal/:version/global-scheduled-close/:id',
    version: 'v1',
    urlParams: { id },
  })
