import { memo, useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { Checkbox } from "components";
import store from "storeContext";
import { setOrderPoolingData } from "storeContext/actions/packer/orderPooling";

function TopCheckBox({ data, isWaitingDriver }) {
  const { state, dispatch } = useContext(store.PackerContext);

  //INITIAL STATE
  const initCheckBoxOrderPValue = false;

  //STATE HOOKS
  const [checkBoxOrderPValue, setCheckBoxOrderPValue] = useState(
    initCheckBoxOrderPValue
  );

  //EFFECT HOOKS
  useEffect(() => {
    const orderPoolingData = [...state.orderPoolingData];

    const initOnFirstRenderCheckBoxOrder = orderPoolingData.some((val) => {
      return val.order_id === data.order_id;
    });
    if (initOnFirstRenderCheckBoxOrder) {
      setCheckBoxOrderPValue(true);
    } else {
      setCheckBoxOrderPValue(false);
    }
    //console.log(initOnFirstRenderCheckBoxOrder);
  }, [state.orderPoolingData]);

  //FUNCTION HELPER
  /*
  const checkOrderPoolingPossibility = () => {
    const orderPoolingData = [...state.orderPoolingData];
    const possibilies =
      orderPoolingData.length > 0
        ? orderPoolingData[0].order_id === data.order_id
          ? true
          : false
        : false;
    return possibilies;
    //console.log(possibilies);
  };
  */
  //FUNCTION HANDLER
  const handleClickCheckBox = (e) => {
    const { value } = e.target;
    const getCurrentRealValue = value === "true" ? false : true;

    setCheckBoxOrderPValue((prev) => {
      return !prev;
    });

    if (getCurrentRealValue) {
      ///IF getCurrentRealValue === TRUE ? ADD data to orderPooling Array
      const prevOrderPoolData = [...state.orderPoolingData];

      dispatch(setOrderPoolingData([...prevOrderPoolData, data]));
    } else {
      const prevOrderPoolData = [...state.orderPoolingData];
      const filteredOrderPoolingData = prevOrderPoolData.filter((val) => {
        return data.order_id !== val.order_id;
      });

      dispatch(setOrderPoolingData([...filteredOrderPoolingData]));
    }
  };

  return (
    <SContainer
      quadrant={data.order_location_quadrant}
      isWaitingDriver={isWaitingDriver}
    >
      {
        data.order_location_quadrant && data.order_location_quadrant > 0 ?

        <STextContainer quadrant={data.order_location_quadrant}>
          <SQuadrantLabel quadrant={data.order_location_quadrant}>
            {parseInt(data.order_location_quadrant, 10)}
          </SQuadrantLabel>
          Potensi dikirim sekaligus
        </STextContainer> : null
      }
      {
        isWaitingDriver ? 
        <SCheckBoxContainer>
          <Checkbox
            onChange={handleClickCheckBox}
            checked={checkBoxOrderPValue}
            value={checkBoxOrderPValue}
          />
        </SCheckBoxContainer> : null
      }
    </SContainer>
  );
}
/*
function OrderPoolingPotential() {
  return (
    <TextBody weight="light" color="textSoft">
      Potensi bisa dikirim bersamaan
    </TextBody>
  );
}
*/
export default memo(TopCheckBox);

const SContainer = styled.div`
  height: ${(props) => props.quadrant ? '32px' : (props.isWaitingDriver ? '32px' : '0px')};
  border-radius: 8px 8px 0px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({quadrant}) => quadrant ? 'space-between' : 'flex-end'};
  padding: ${({quadrant, isWaitingDriver}) => quadrant || isWaitingDriver ? '6px 16px;' : '0px'};
  background-color: rgb(255, 250, 230);
  ${(props) => {
    if (parseInt(props.quadrant, 10) === 1)
      return `
          background-color: #FFF4F4;            
        `;
    if (parseInt(props.quadrant, 10) === 2)
      return `
            background-color: #FFF5E0;            
        `;
    if (parseInt(props.quadrant, 10) === 3)
      return `
            background-color: rgba(255, 248, 218, 0.5);            
        `;
    if (parseInt(props.quadrant, 10) === 4)
      return `
            background-color: #F4FFDC;            
        `;
    if (parseInt(props.quadrant, 10) === 5)
      return `
            background-color: #DCFFF7;            
        `;
    if (parseInt(props.quadrant, 10) === 6)
      return `
            background-color: #DCFFEA;            
        `;
    if (parseInt(props.quadrant, 10) === 7)
      return `
            background-color: #DCF2FF;            
        `;
    if (parseInt(props.quadrant, 10) === 8)
      return `
            background-color: #E5DCFF;            
        `;
    if (parseInt(props.quadrant, 10) === 9)
      return `
            background-color: #FFDCFE;            
        `;
  }}
`;

const SCheckBoxContainer = styled.div`
  width: 18px;
  height: 18px;
`;

const STextContainer = styled.div`
  display: flex;
  flex: row;
  align-items: center;
  gap: 2px;
  font-size: 10px;
  font-weight: 700;
  ${(props) => {
    if (parseInt(props.quadrant, 10) === 1)
      return `
          color: #E8324A;            
        `;
    if (parseInt(props.quadrant, 10) === 2)
      return `
            color: #F99A00;            
        `;
    if (parseInt(props.quadrant, 10) === 3)
      return `
            color: #FFD300;            
        `;
    if (parseInt(props.quadrant, 10) === 4)
      return `
            color: #7ECC00;            
        `;
    if (parseInt(props.quadrant, 10) === 5)
      return `
            color: #00BECC;            
        `;
    if (parseInt(props.quadrant, 10) === 6)
      return `
            color: #00CC77;            
        `;
    if (parseInt(props.quadrant, 10) === 7)
      return `
            color: #147DF5;            
        `;
    if (parseInt(props.quadrant, 10) === 8)
      return `
            color: #580AFF;            
        `;
    if (parseInt(props.quadrant, 10) === 9)
      return `
            color: #A500E0;            
        `;
  }}
`;
const SQuadrantLabel = styled.div`
  font-weight: 700;
  color: #ffffff;
  font-size: 10px;
  background-color: #f94d63;
  ${(props) => {
    if (parseInt(props.quadrant, 10) === 1)
      return `
          background-color: #E8324A;            
        `;
    if (parseInt(props.quadrant, 10) === 2)
      return `
            background-color: #F99A00;            
        `;
    if (parseInt(props.quadrant, 10) === 3)
      return `
            background-color: #FFD300;            
        `;
    if (parseInt(props.quadrant, 10) === 4)
      return `
            background-color: #7ECC00;            
        `;
    if (parseInt(props.quadrant, 10) === 5)
      return `
            background-color: #00BECC;            
        `;
    if (parseInt(props.quadrant, 10) === 6)
      return `
            background-color: #00CC77;            
        `;
    if (parseInt(props.quadrant, 10) === 7)
      return `
            background-color: #147DF5;            
        `;
    if (parseInt(props.quadrant, 10) === 8)
      return `
            background-color: #580AFF;            
        `;
    if (parseInt(props.quadrant, 10) === 9)
      return `
            background-color: #A500E0;            
        `;
  }}
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  margin-right: 8px;
  height: 20px;
  padding: 4px;
`;