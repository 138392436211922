import { toastFailed, toastSuccess } from "utils/toast";
import {
  SET_IS_SUCCESS_SETPOS_PRODUCT,
  SET_IS_PAGINATION_DATA_SETPOS_PRODUCT
} from "storeContext/actionsType/setPosition";
import {
  getProductPosition,
  postAddProductPosition
} from "utils/apiList/setPosition";
import { startSetPos, stopSetPos, getReloadData } from "./index";

export const getSetPosProductData = (data) => {
  return {
    type: SET_IS_SUCCESS_SETPOS_PRODUCT,
    payload: data
  };
};

export const setPaginationSetPosProduction = (data) => {
  return {
    type: SET_IS_PAGINATION_DATA_SETPOS_PRODUCT,
    payload: data
  };
};

export const getListOfSetPositionProduct =
  (category_id, query = "") =>
  (dispatch) => {
    dispatch(startSetPos());

    return getProductPosition(category_id, query)
      .then(({ data }) => {
        dispatch(stopSetPos());
        const pagination = {
          currentItem: data.numberOfElements,
          currentPage: data.number,
          pageSize: data.size,
          totalData: data.totalElements,
          totalPage: data.totalPages
        };
        dispatch(setPaginationSetPosProduction(pagination));
        dispatch(getSetPosProductData(data));
        //console.log(data);
      })
      .catch(() => {
        dispatch(stopSetPos());
        toastFailed("terjadi masalah, coba beberapa saat lagi");
      });
  };

export const postPosition = (dataToSend) => (dispatch) => {
  dispatch(startSetPos());

  return postAddProductPosition(dataToSend)
    .then(({ data }) => {
      dispatch(stopSetPos());
      dispatch(getReloadData());

      toastSuccess("Berhasil merubah posisi produk");
      //console.log(data);
    })
    .catch(() => {
      dispatch(stopSetPos());
      toastFailed("terjadi masalah, coba beberapa saat lagi");
    });
};
