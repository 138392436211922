import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GetVoucherPriorityResponseType } from 'utils/apiList/promotion'
import {
  SLICE_NAME,
  fetchVoucherList,
  updateVoucherPriority,
  fetchVoucherPriority,
} from './promotionListThunk'

export type VoucherStateType = {
  voucher_id: number
  voucher_code: string
  name: string
}
export type SelectedVoucherStateType = GetVoucherPriorityResponseType['data']['list'][0] & {
  uniqueKey: string
}

export interface PromotionListStateType {
  isLoading: boolean
  isShowPopupVoucherPriority: boolean
  popupVocherPriority: {
    voucherList: VoucherStateType[]
    selectedVoucher: SelectedVoucherStateType
    voucherPriorityList: SelectedVoucherStateType[]
  }
}

export const initialState: PromotionListStateType = {
  isLoading: false,
  isShowPopupVoucherPriority: false,
  popupVocherPriority: {
    voucherList: [],
    selectedVoucher: {
      code: '',
      id: 0,
      priority: 0,
      uniqueKey: window.crypto.randomUUID(),
    },
    voucherPriorityList: [],
  },
}

export type MoveVoucherPriorityPayloadType = {
  from: number
  to: number
}

export type SetVoucherPriorityPayloadType = {
  value: PromotionListStateType['popupVocherPriority']['voucherPriorityList']
}

const promotionList = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    resetPopupVocherPriority: (state) => {
      state.popupVocherPriority = initialState.popupVocherPriority
    },
    setIsShowPopupVocherPriority: (state, action: PayloadAction<{ value: boolean }>) => {
      const { value } = action.payload
      state.isShowPopupVoucherPriority = value
    },
    setSelectedVoucherPriority: (
      state,
      action: PayloadAction<{ value: SelectedVoucherStateType }>,
    ) => {
      const { value } = action.payload
      state.popupVocherPriority.selectedVoucher = value
    },
    addVoucherPriority: (state) => {
      state.popupVocherPriority.voucherPriorityList.push(state.popupVocherPriority.selectedVoucher)
      state.popupVocherPriority.selectedVoucher = initialState.popupVocherPriority.selectedVoucher
    },
    removeVoucherPriority: (state, action: PayloadAction<{ value: SelectedVoucherStateType }>) => {
      const { value } = action.payload

      state.popupVocherPriority.voucherPriorityList =
        state.popupVocherPriority.voucherPriorityList.filter(
          (el) => el.uniqueKey !== value.uniqueKey,
        )
    },
    setVoucherPriority: (state, action: PayloadAction<SetVoucherPriorityPayloadType>) => {
      const { value } = action.payload
      state.popupVocherPriority.voucherPriorityList = value
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchVoucherList.fulfilled, (state, action) => {
        const { content } = action.payload
        state.isLoading = false
        state.popupVocherPriority.voucherList = content.map((el) => ({
          name: el.voucher_code,
          voucher_code: el.voucher_code,
          voucher_id: el.voucher_id,
        }))
      })
      .addCase(updateVoucherPriority.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateVoucherPriority.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateVoucherPriority.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchVoucherPriority.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchVoucherPriority.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchVoucherPriority.fulfilled, (state, action) => {
        const { data } = action.payload
        state.isLoading = false
        state.popupVocherPriority.voucherPriorityList = data.list.map((el) => ({
          ...el,
          uniqueKey: crypto.randomUUID(),
        }))
      })
  },
})

export const {
  reset,
  setIsShowPopupVocherPriority,
  resetPopupVocherPriority,
  setSelectedVoucherPriority,
  addVoucherPriority,
  removeVoucherPriority,
  setVoucherPriority,
} = promotionList.actions
export default promotionList.reducer
