import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { InitialStateType, ExperimentTypesType } from 'features/ABAdmin/@types/ExperimentAddEdit'
import { SLICE_NAME_AB_ADMIN_ADD_EDIT } from './thunk'

const initialState: InitialStateType = {
  experimentType: 'LOGIN',
}

const slice = createSlice({
  name: SLICE_NAME_AB_ADMIN_ADD_EDIT,
  initialState,
  reducers: {
    resetState: () => initialState,
    setExperimentType: (state, { payload }: PayloadAction<ExperimentTypesType>) => {
      state.experimentType = payload
    },
  },
})

export const { resetState, setExperimentType } = slice.actions
export default slice.reducer
