import { Get } from 'config/apiServiceConfig'
import apiServices from 'config/apiServiceApiGateway'
import type {
  CategoryType,
  CatalogueType,
  TargetingType,
  TargetedUserType,
  InfiniteScrollTabCreatePayloadType,
} from '../@types/InfiniteScrollTabAddEdit'
import {
  InfiniteScrollTabbingType,
  InfiniteScrollTabFlagType,
  LocationType,
} from '../@types/InfiniteScrollTab'

export type GetCategoryParamsType = {
  name: string
}

type GetCategoryResponseType = {
  content: CategoryType[]
}

type GetCatalogueResponseType = {
  content: CatalogueType[]
}

type GetLocationsResponseType = {
  data: LocationType[]
}

export type GetTargetedUsersParamsType = {
  number: string
  pageSize: number
}

type GetTargetedUsersResponseType = {
  content: TargetedUserType[]
}

type GetInfiniteScrollTabbingResponseType = {
  data: InfiniteScrollTabbingType
  flag: InfiniteScrollTabFlagType
}

export type PutInfiniteScrollTypesType = 'position' | 'status' | 'all'

export const getCategoryList = (params?: GetCategoryParamsType) =>
  Get<GetCategoryResponseType>({ url: '/api/category', params })

export const getCatalogueList = (params?: GetCategoryParamsType) =>
  Get<GetCatalogueResponseType>({ url: '/api/catalogue', params })

export const getLocationList = () => Get<GetLocationsResponseType>({ url: '/api/locations/order' })

export const getTargetingTypes = () => Get<TargetingType[]>({ url: '/api/targeting-type' })

export const getTargetedUsers = (params: GetTargetedUsersParamsType) =>
  Get<GetTargetedUsersResponseType>({ url: '/api/customer/search', params })

export const postInfiniteScrollTabbing = (data: InfiniteScrollTabCreatePayloadType) =>
  apiServices.Post({
    url: '/browse/internal/:version/infinite-scroll-tabbing',
    version: 'v1',
    data,
  })

export const putInfiniteScrollTabbing = (
  tabId: number,
  payload: InfiniteScrollTabCreatePayloadType,
  updateType?: PutInfiniteScrollTypesType,
) => {
  const data = { ...payload }
  if (updateType === 'position') {
    data.position_only = true
  } else if (updateType === 'status') {
    data.is_toggle_active = true
  }

  return apiServices.Put({
    url: '/browse/internal/:version/infinite-scroll-tabbing/:tabId',
    version: 'v1',
    urlParams: { tabId },
    data,
  })
}

export const getInfiniteScrollTabbingDetail = (tabId: number) =>
  apiServices.Get<GetInfiniteScrollTabbingResponseType, true>({
    url: '/browse/internal/:version/infinite-scroll-tabbing/:tabId',
    version: 'v1',
    urlParams: { tabId },
  })
