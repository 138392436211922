import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const AddEditProductAssetRBACPage = {
  pageID: 'b8221f8d1489d38406f6bc22d6d36849',
  elementID: {
    ButtonAddEdit: 'f97a677772196f2ed017b44d54c03d66',
    FormEdit: 'eef9a1ea6762ceae118ff0a3fe626f02',
  },
} as const

export const useAddEditProductAssetRBACPage = createUseRBAC(AddEditProductAssetRBACPage.elementID)

export type UseAddEditProductAssetRBACPageType = ReturnType<typeof useAddEditProductAssetRBACPage>
