import { memo } from 'react'
import logoQr from 'assets/images/logo_qr.svg'
import QRCode from 'react-qr-code'
import dayjs from 'dayjs'
import { useTemplatePrintQRv2 } from './useTemplatePrintQRV2'
import { S } from './TemplatePrintQRV2Style'

const { TextBody } = require('components')

type NameProductType = () => string

const TemplatePrint = () => {
  const {
    state: {
      dataPrintQR: { printQRQTY },
      nameProduct,
    },
  } = useTemplatePrintQRv2()

  const renderQRCard = () => {
    const tmpData = []

    for (let i = 0; i < printQRQTY; i += 1) {
      tmpData.push(<QRCard nameProduct={nameProduct} index={i + 1} key={`${i}sas`} />)
    }

    return tmpData
  }

  return <S.Container> {renderQRCard()}</S.Container>
}

function QRCard({ nameProduct, index }: { nameProduct: NameProductType; index: number }) {
  const {
    state: { dataPrintQR },
  } = useTemplatePrintQRv2()

  return (
    <S.QRWrapper odd={index % 2}>
      <S.QRCardContainer>
        <S.ImageSection>
          <S.ImageTitleContainer>
            <img src={logoQr} alt="logoQR" style={{ width: '46px', paddingBottom: '6px' }} />
          </S.ImageTitleContainer>
          <S.ImageBodyContainer>
            <QRCode value={dataPrintQR?.qr_code} size={46} />
          </S.ImageBodyContainer>
        </S.ImageSection>
        <S.TextSection>
          <S.ProductContainer>
            <TextBody
              size="smallest"
              style={{ fontSize: '0.5rem' }}
              color="pureBlack"
              weight="normal"
            >
              {nameProduct()}
            </TextBody>
          </S.ProductContainer>
          <S.SProducedByContainer>
            <TextBody style={{ fontSize: '0.4rem' }} color="pureBlack" weight="light">
              Prod By : {dataPrintQR?.produced_by}
            </TextBody>
          </S.SProducedByContainer>
          <S.BarcodeNumbContainer>
            <TextBody style={{ fontSize: '0.4rem' }} color="pureBlack" weight="light">
              {dataPrintQR?.qr_code}
            </TextBody>
          </S.BarcodeNumbContainer>

          <S.ExprDateAndMFGContainer>
            <S.SMFGContainer>
              <TextBody style={{ fontSize: '0.4rem' }} color="pureBlack" weight="light">
                MFG {dayjs(dataPrintQR?.manufacture_date).format('DD-MM-YYYY')}
              </TextBody>
            </S.SMFGContainer>
            <S.SExprDateContainer>
              <TextBody style={{ fontSize: '0.4rem' }} color="pureBlack" weight="light">
                EXP {dayjs(dataPrintQR?.expiry_date).format('DD-MM-YYYY')}
              </TextBody>
            </S.SExprDateContainer>
          </S.ExprDateAndMFGContainer>
          <S.SNotesContainer>
            <TextBody style={{ fontSize: '0.4rem' }} color="pureBlack" weight="light">
              {dataPrintQR?.storage_notes}
            </TextBody>
          </S.SNotesContainer>
        </S.TextSection>
      </S.QRCardContainer>
    </S.QRWrapper>
  )
}

export default memo(TemplatePrint)
