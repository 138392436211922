import { createAsyncThunk } from '@reduxjs/toolkit'

import { callErrorMsg } from 'helpers/errorMsg'
import { getQueryParamsObjectToString } from 'utils/helpers/router'
import {
  fetchTnCList,
  putEditTnC,
  deleteTnC,
  postAddTnC,
} from 'features/Enterprise/SKP/utils/apiList'

import { sliceName, setLoading, setTnCList, setPageNumber } from './tncSlice'

export const getTnCList = createAsyncThunk(
  `${sliceName}/getTnCList`,
  async (_, { getState, dispatch }) => {
    try {
      dispatch(setLoading(true))
      const {
        tnc: { params },
      } = getState() as StoreStateType

      const parseQuery = getQueryParamsObjectToString(params)
      const { data } = await fetchTnCList(parseQuery)
      dispatch(setTnCList(data.data))
      dispatch(
        setPageNumber({
          offset: data.query.offset,
          totalItem: data.query.total,
          limit: data.query.limit,
        }),
      )
      dispatch(setLoading(false))
      return data?.data
    } catch (error) {
      callErrorMsg(error)
      return false
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const editTnC = createAsyncThunk(
  `${sliceName}/editTnC`,
  async (
    payload: {
      promotion_type: string
      tnc_description: string
      tnc_status: string
      tnc_title: string
      id: number
    },
    { dispatch },
  ) => {
    try {
      dispatch(setLoading(true))

      const { data } = await putEditTnC(
        {
          promotion_type: payload.promotion_type,
          tnc_description: payload.tnc_description,
          tnc_status: payload.tnc_status,
          tnc_title: payload.tnc_title,
        },
        payload.id,
      )
      await dispatch(getTnCList())
      dispatch(setLoading(false))
      return data.message
    } catch (error) {
      callErrorMsg(error)
      return false
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const deleteTnc = createAsyncThunk(
  `${sliceName}/deleteTnc`,
  async (
    payload: {
      id: number
    },
    { dispatch },
  ) => {
    try {
      dispatch(setLoading(true))

      await deleteTnC(payload.id)
      await dispatch(getTnCList())
      return true
    } catch (error) {
      callErrorMsg(error)
      return false
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const addTnC = createAsyncThunk(
  `${sliceName}/addTnC`,
  async (
    payload: {
      promotion_type: string
      tnc_description: string
      tnc_status: string
      tnc_title: string
    },
    { dispatch },
  ) => {
    try {
      dispatch(setLoading(true))
      const { data } = await postAddTnC({
        promotion_type: payload.promotion_type,
        tnc_description: payload.tnc_description,
        tnc_status: payload.tnc_status,
        tnc_title: payload.tnc_title,
      })
      await dispatch(getTnCList())
      dispatch(setLoading(false))
      return data.message
    } catch (error) {
      callErrorMsg(error)
      return false
    } finally {
      dispatch(setLoading(false))
    }
  },
)
