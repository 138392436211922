import axios from 'axios'
const { REACT_APP_BASE_URL_API, REACT_APP_BASE_URL_KONG_API } = process.env
const baseUrl = REACT_APP_BASE_URL_API
const kongUrl = REACT_APP_BASE_URL_KONG_API

export const getCampaign_CAMPAIGN = (query) => axios.get(`${baseUrl}/api/campaigns${query}`)

export const putUpdateActivateCampaign_CAMPAIGN = (campaign_id, data) =>
  axios.put(`${baseUrl}/api/campaign/${campaign_id}/active`, data)

export const deleteCampaign_CAMPAIGN = (data) =>
  axios.delete(`${baseUrl}/api/campaign`, { data: data })

export const putAdjustCampaign_CAMPAIGN = (campaign_id, data) =>
  axios.put(`${baseUrl}/api/campaign/${campaign_id}/edit-inventory`, data)

export const getCampaign_CAMPAIGN_ADD = (campaign_id, query) =>
  axios.get(`${kongUrl}/campaign/internal/v1/${campaign_id}${query}`)

export const putUpdateDataCampaign_CAMPAIGN_ADD = (campaign_id, data, config) =>
  axios.put(`${kongUrl}/campaign/internal/v1/${campaign_id}`, data, config)

export const postBulkUploadCampaign_CAMPAIGN_ADD = (campaign_id, data, config) =>
  axios.post(`${baseUrl}/api/v2/campaign/upload/${campaign_id}`, data, config)

export const postCreateCampaign_CAMPAIGN_ADD = (data) =>
  axios.post(`${kongUrl}/campaign/internal/v1/create`, data)

export const postRemoveCampaign_CAMPAIGN_ADD = (campaign_id, data) =>
  axios.post(`${baseUrl}/api/campaign/${campaign_id}/remove-inventory`, data)

export const deleteRemoveInventoriesCampaign_CAMPAIGN_ADD = (campaign_id, data) =>
  axios.delete(`${baseUrl}/api/campaign/${campaign_id}/remove-inventory`, {
    data,
  })

export const putUpdateDataInventoriesPerItem_CAMPAIGN_ADD = (campaignInvetoryId, data) =>
  axios.put(`${baseUrl}/api/campaign/inventory/${campaignInvetoryId}`, data)

export const getBulkUploadInventoriesCampaign_CAMPAIGN_ADD = ({ params }) =>
  axios.get(`${baseUrl}/api/campaign/inventory/csv/template`, { params: params })

export const getInventoryCampaign_CAMPAIGN_ADD = (campaign_id, query) =>
  axios.get(`${baseUrl}/api/campaign/${campaign_id}/inventory${query}`)

export const putEditAllCampaign_CAMPAIGN_ADD = (campaign_id, data) =>
  axios.put(`${baseUrl}/api/campaign/${campaign_id}/edit-inventory`, data)

export const getCampaign_CAMPAIGN_ADD_MANUAL = (query = '') =>
  axios.get(`${baseUrl}/api/inventories/v4${query}`)

export const getHubLocations_CAMPAIGN_ADD_MANUAL = () => axios.get(`${baseUrl}/api/locations/hub`)

export const getCategories_CAMPAIGN_ADD_MANUAL = () => axios.get(`${baseUrl}/api/categories/child`)

export const postAddManualInventoryCampaign_CAMPAIGN_ADD_MANUAL = (campaign_id, data) =>
  axios.post(`${baseUrl}/api/campaign/${campaign_id}/add-inventory`, data)

export const getBulkUploadStatusCampaignProduct = (campaign_id) =>
  axios.get(`${baseUrl}/api/campaign/${campaign_id}/progress`)
