const { REACT_APP_TYPE } = process.env
import {
  SET_IS_LOGIN_AUTH,
  SET_IS_LOADING_AUTH,
  SET_USER_DATA,
  SET_IS_EXPAND,
} from 'storeContext/actionsType/auth'
import { getTokenCookies } from 'config/cookies'

export const initialStateAuth = {
  isLoading: false,
  isLogin: getTokenCookies() ? true : false,
  userData: {},
  isExpand: false,
  isErp: REACT_APP_TYPE === 'erp',
}

export default function auth(state = initialStateAuth, { type, payload }) {
  switch (type) {
    case SET_IS_LOADING_AUTH:
      return {
        ...state,
        isLoading: payload,
      }
    case SET_IS_LOGIN_AUTH:
      return {
        ...state,
        isLogin: payload,
      }
    case SET_USER_DATA:
      return {
        ...state,
        userData: payload,
      }
    case SET_IS_EXPAND:
      return {
        ...state,
        isExpand: payload,
      }
    default:
      return state
  }
}
