/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import type {
  DriverItemSOInterface,
  GetLoadingDetailResponseType,
  GetDetailLoadingItemsInterface,
  GetConfigurationResponseType,
} from 'utils/apiList/loadingGoods'

import {
  SLICE_NAME,
  newGroupSO,
  cancelGroupSO,
  getLoaderSO,
  getDriverSO,
  get3PLSO,
  getDetailLoadingSO,
  submitLoadingSO,
  getDetailLoadingItemsSO,
  postScanKoliSO,
  getConfigurationSO,
  getPrintSuratJalanSO,
  postCancelLoadingSO,
  postLoadingSubmitSO,
  putUpdateGroupOnDeliverySO,
} from './loadingGoodsThunk'

type LoaderStepType = 1 | 2 | 3

export type DriverType = 'ASTRO' | '3PL'

type LoadingGoodsStateType = {
  configuration: {
    cancelLoadingReasons: GetConfigurationResponseType['data']['cancelLoadingReasons']
    supportedVehicleType: GetConfigurationResponseType['data']['supportedVehicleType']
    needToSetTemperatureLocationIds: {
      [key: string]: true
    }
  }
  loader: {
    step: LoaderStepType
    focusId: number | null
    locationId: number | null
    loading: boolean
    data: {
      id: number
      fullName: string
    }[]
    pickedLoaders: { [key: number]: true }
    driverType: DriverType | null
    dataDrivers: DriverItemSOInterface[]
    pickedDriver: number | null
    groupParentId: string
    data3PL: {
      id: number
      name: string
    }[]
    picked3PL: number
    isReqChange: boolean
    formCustom: {
      tempratureBeforeLoading: number
      driverNameCustom: string
      platNomor: string
      vehicleType: GetConfigurationResponseType['data']['supportedVehicleType'][0]
    }
  }
  loading: {
    isLoading: boolean
    detail: GetLoadingDetailResponseType['data']
    so: GetDetailLoadingItemsInterface['data']
    anotherSO: {
      isShow: boolean
      totalKoliNotScanned: number
      so: string
      barcodeValue: string
      soDestIndex: number
    }
    cancel: {
      isShow: boolean
      isLoading: boolean
      supplyOrderId: number
      supplyOrderNumber: string
      totalScanned: number
      kolis: {
        code: string
        alreadyScanned: boolean
      }[]
    }
    soForceAllowed: number
    isSkipRefocusSO: boolean
  }
  onDelivery: {
    isLoading: boolean
    isShowModal: boolean
    printTravelDocumentLoadingId: number
    locationId: number
  }
  groupSO: {
    isLoading: boolean
  }
  refetchCount: number
}

interface SetFocusLoadingInterface {
  focusId: number | null
  locationId: number | null
  groupParentId: string
}

const initialState: LoadingGoodsStateType = {
  configuration: {
    cancelLoadingReasons: [],
    needToSetTemperatureLocationIds: {},
    supportedVehicleType: [],
  },
  loader: {
    step: 1,
    focusId: null,
    locationId: null,
    loading: false,
    data: [],
    pickedLoaders: {},
    driverType: null,
    dataDrivers: [],
    data3PL: [],
    pickedDriver: null,
    picked3PL: 0,
    isReqChange: false,
    formCustom: {
      tempratureBeforeLoading: 0,
      driverNameCustom: '',
      platNomor: '',
      vehicleType: {
        key: '',
        value: '',
      },
    },
    groupParentId: '',
  },
  loading: {
    isLoading: false,
    detail: {
      groupId: '',
      loaders: [],
      driver: {
        id: 0,
        name: '-',
        plateNumber: '-',
        type: '-',
        vehicle: {
          code: '',
          name: '',
        },
      },
      driverProvider: null,
      loadingId: 22,
      freshInitialTemperature: -2,
      freshFinalTemperature: null,
    },
    so: [],
    anotherSO: {
      isShow: false,
      totalKoliNotScanned: 0,
      so: '',
      barcodeValue: '',
      soDestIndex: 0,
    },
    cancel: {
      isShow: false,
      isLoading: false,
      supplyOrderId: 0,
      supplyOrderNumber: '',
      totalScanned: 0,
      kolis: [],
    },
    soForceAllowed: 0,
    isSkipRefocusSO: false,
  },
  onDelivery: {
    isLoading: false,
    isShowModal: false,
    printTravelDocumentLoadingId: 0,
    locationId: 0,
  },
  groupSO: {
    isLoading: false,
  },
  refetchCount: 1,
}

export const loadingGoods = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    setLoaderStep: (state, action: PayloadAction<LoaderStepType>) => {
      state.loader.step = action.payload
    },
    setFocusLoading: (state, action: PayloadAction<SetFocusLoadingInterface>) => {
      state.loader = { ...state.loader, ...action.payload }
    },
    setPickedLoader: (state, action: PayloadAction<number>) => {
      if (state.loader.pickedLoaders[action.payload]) {
        delete state.loader.pickedLoaders[action.payload]
      } else {
        state.loader.pickedLoaders[action.payload] = true
      }
    },
    setDriverType: (state, action: PayloadAction<DriverType>) => {
      state.loader.driverType = action.payload
    },
    setPickedDriver: (state, action: PayloadAction<number>) => {
      state.loader.pickedDriver = action.payload
    },
    setPicked3PL: (state, action: PayloadAction<number>) => {
      state.loader.picked3PL = action.payload
    },
    setTempratureBeforeLoading: (state, action: PayloadAction<number>) => {
      state.loader.formCustom.tempratureBeforeLoading = action.payload
    },
    setDriverNameCustom: (state, action: PayloadAction<string>) => {
      state.loader.formCustom.driverNameCustom = action.payload
    },
    setPlatNomor: (state, action: PayloadAction<string>) => {
      state.loader.formCustom.platNomor = action.payload
    },
    setVehicleType: (
      state,
      action: PayloadAction<GetConfigurationResponseType['data']['supportedVehicleType'][0]>,
    ) => {
      state.loader.formCustom.vehicleType = action.payload
    },
    setResetFormCustom: (state) => {
      state.loader.formCustom = initialState.loader.formCustom
    },
    setReqChanges: (state, action: PayloadAction<boolean>) => {
      state.loader.isReqChange = action.payload
      const selectedDriver = state.loader.dataDrivers.find(
        (el) => el.id === state.loader.pickedDriver,
      )
      if (selectedDriver) {
        state.loader.formCustom.driverNameCustom = selectedDriver.name
        state.loader.formCustom.platNomor = selectedDriver.plateNumber
      }
    },
    setResetLoading: (state) => {
      state.loader = initialState.loader
    },
    setAnotherSO: (state, action: PayloadAction<LoadingGoodsStateType['loading']['anotherSO']>) => {
      state.loading.anotherSO = action.payload
    },
    setResetAnotherSO: (state) => {
      state.loading.anotherSO = initialState.loading.anotherSO
    },
    setCancelSO: (state, action: PayloadAction<LoadingGoodsStateType['loading']['cancel']>) => {
      state.loading.cancel = action.payload
    },
    setResetCancelSO: (state) => {
      state.loading.cancel = initialState.loading.cancel
    },
    setScanCancelSO: (state, action: PayloadAction<number>) => {
      state.loading.cancel.kolis[action.payload].alreadyScanned = true
      state.loading.cancel.totalScanned += 1
    },
    setModalGroupOnDelivery: (state, action: PayloadAction<boolean>) => {
      state.onDelivery.isShowModal = action.payload
    },
    setForceLoading: (state, action: PayloadAction<number>) => {
      state.loading.soForceAllowed = action.payload
    },
    setPrintTravelDocument: (state, action: PayloadAction<number>) => {
      state.onDelivery.printTravelDocumentLoadingId = action.payload
    },
    setSkipRefocus: (state, action: PayloadAction<boolean>) => {
      state.loading.isSkipRefocusSO = action.payload
    },
    setDelieryLocId: (state, action: PayloadAction<number>) => {
      state.onDelivery.locationId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(newGroupSO.pending, (state) => {
        state.groupSO.isLoading = true
      })
      .addCase(newGroupSO.rejected, (state) => {
        state.groupSO.isLoading = false
      })
      .addCase(newGroupSO.fulfilled, (state) => {
        state.groupSO.isLoading = false
        state.refetchCount += 1
      })
      .addCase(cancelGroupSO.pending, (state) => {
        state.groupSO.isLoading = true
      })
      .addCase(cancelGroupSO.fulfilled, (state) => {
        state.groupSO.isLoading = false
        state.refetchCount += 1
      })
      .addCase(getLoaderSO.pending, (state) => {
        state.loader.loading = true
      })
      .addCase(getLoaderSO.fulfilled, (state, action) => {
        state.loader.loading = false
        state.loader.data = action.payload.data
      })
      .addCase(getDriverSO.pending, (state) => {
        state.loader.loading = true
      })
      .addCase(getDriverSO.fulfilled, (state, action) => {
        state.loader.loading = false
        state.loader.dataDrivers = action.payload.data
      })
      .addCase(submitLoadingSO.pending, (state) => {
        state.loader.loading = false
      })
      .addCase(submitLoadingSO.fulfilled, (state) => {
        state.loader = initialState.loader
        state.refetchCount += 1
      })
      .addCase(get3PLSO.fulfilled, (state, action) => {
        state.loader.data3PL = action.payload.data
      })
      .addCase(getDetailLoadingSO.fulfilled, (state, action) => {
        state.loading.detail = action.payload.data
      })
      .addCase(getDetailLoadingItemsSO.fulfilled, (state, action) => {
        state.loading.so = action.payload.data
      })
      .addCase(postScanKoliSO.fulfilled, (state, action) => {
        if (action.payload.data.valid) {
          const { soIndex, koliIndex } = action.payload.index
          state.loading.so[soIndex as number].kolis[koliIndex as number].alreadyScanned = true
          state.loading.so[soIndex as number].totalScanned += 1
        }
      })
      .addCase(getConfigurationSO.fulfilled, (state, action) => {
        const needToSetTemperatureLocationIds: LoadingGoodsStateType['configuration']['needToSetTemperatureLocationIds'] =
          {}

        action.payload.data.needToSetTemperatureLocationIds.forEach((el) => {
          needToSetTemperatureLocationIds[`${el}`] = true
        })

        state.configuration = {
          ...action.payload.data,
          needToSetTemperatureLocationIds,
        }
      })
      .addCase(postCancelLoadingSO.pending, (state) => {
        state.loading.cancel.isLoading = true
      })
      .addCase(postCancelLoadingSO.fulfilled, (state, action) => {
        const getIndexSO = state.loading.so.findIndex(
          (el) => el.supplyOrderNumber === (action?.payload || ''),
        )

        if (getIndexSO >= 0) {
          state.loading.so.splice(getIndexSO, 1)
        }

        state.loading.cancel = initialState.loading.cancel
      })
      .addCase(postLoadingSubmitSO.pending, (state) => {
        state.loading.isLoading = true
      })
      .addCase(postLoadingSubmitSO.fulfilled, (state) => {
        state.loading.isLoading = false
      })
      .addCase(postLoadingSubmitSO.rejected, (state) => {
        state.loading.isLoading = false
      })
      .addCase(putUpdateGroupOnDeliverySO.pending, (state) => {
        state.onDelivery.isLoading = true
      })
      .addCase(putUpdateGroupOnDeliverySO.fulfilled, (state, action) => {
        state.onDelivery.isLoading = false
        state.onDelivery.printTravelDocumentLoadingId = action.payload
      })
      .addCase(getPrintSuratJalanSO.fulfilled, (state) => {
        state.onDelivery = initialState.onDelivery
        state.loading.detail = initialState.loading.detail
      })
  },
})

export const {
  resetState,
  setLoaderStep,
  setFocusLoading,
  setPickedLoader,
  setPickedDriver,
  setPicked3PL,
  setDriverType,
  setTempratureBeforeLoading,
  setPlatNomor,
  setVehicleType,
  setResetLoading,
  setAnotherSO,
  setResetAnotherSO,
  setCancelSO,
  setResetCancelSO,
  setScanCancelSO,
  setDriverNameCustom,
  setReqChanges,
  setResetFormCustom,
  setModalGroupOnDelivery,
  setForceLoading,
  setPrintTravelDocument,
  setSkipRefocus,
  setDelieryLocId,
} = loadingGoods.actions
export default loadingGoods.reducer
