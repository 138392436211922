import createReducer from "storeContext/reducers/createReducer";

export const RAK_LIST_FETCH = "rackList/FETCH";
export const RAK_LIST_FETCH_SUCCESS = "rackList/FETCH_SUCCESS";
export const RAK_LIST_FETCH_FAILED = "rackList/FETCH_FAILED";
export const RACK_LIST_SET_QUERY = "rackList/SET_QUERY";
export const RACK_LIST_SET_LOCATION_DROPDOWN = "rackList/SET_LOCATION_DROPDOWN";

export const initialStateRack = {
  isLoading: true,
  rackList: [],
  pagination: {
    page: 0,
    limit: 20,
    totalData: 0
  },
  query: {
    pageIndex: 0
  },
  locationDropdown: []
};

const rackReducer = createReducer({
  [RAK_LIST_FETCH]: (state) => {
    state.isLoading = true;
  },
  [RAK_LIST_FETCH_SUCCESS]: (state, action) => {
    state.isLoading = false;
    state.rackList = action.payload.content;
    state.pagination.page = action.payload.number + 1;
    state.pagination.totalData = action.payload.totalElements;
  },
  [RAK_LIST_FETCH_FAILED]: (state) => {
    state.isLoading = false;
  },
  [RACK_LIST_SET_QUERY]: (state, action) => {
    state.query = action.payload;
  },
  [RACK_LIST_SET_LOCATION_DROPDOWN]: (state, action) => {
    state.locationDropdown = action.payload;
  }
});

export default rackReducer;

export const rackListFetch = () => ({
  type: RAK_LIST_FETCH
});

export const rackListFetchSuccess = (data) => ({
  type: RAK_LIST_FETCH_SUCCESS,
  payload: data
});

export const rackListFetchFailed = () => ({
  type: RAK_LIST_FETCH_FAILED
});

export const rackListSetQuery = (queryParams) => ({
  type: RACK_LIST_SET_QUERY,
  payload: queryParams
});

export const rackListSetLocationDropdown = (data) => ({
  type: RACK_LIST_SET_LOCATION_DROPDOWN,
  payload: data
});
