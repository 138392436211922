import { dateFormat } from '@astronautsid/wpe-utils'

export const dateToUnix = (date: string, time: string) =>
  date ? new Date(`${date} ${time}`).getTime() : ''

export const unixToDateFormat = (unix: number, type: 'date' | 'time') => {
  const date = new Date(unix)

  if (!unix || Number.isNaN(date.getTime())) {
    return ''
  }

  return dateFormat({ date, format: type === 'date' ? 'YYYY-MM-DD' : 'HH:mm' })
}
