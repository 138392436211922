/* eslint-disable @typescript-eslint/naming-convention */
import { createAsyncThunk } from '@reduxjs/toolkit'
import { toastSuccess } from 'utils/toast'
import { callErrorMsg } from '../../../../helpers/errorMsg'
import { SOMETHING_WHEN_WRONG } from '../../../../constant/errorMessages'
import {
  getSLAIds,
  getTicketDetail,
  getTicketList,
  getComplainReasons,
  putReviewTicket,
  putRejectTicket,
  putApproveTicket,
  type ComplaintReasonRequestParamsType,
  type ApproveTicketRequestParamsType,
  type RejectTicketRequestParamsType,
} from '../../services/tickets'

export const SLICE_NAME = 'HCU'

export const fetchSLAIDs = createAsyncThunk(
  `${SLICE_NAME}/fetchSLAIDs`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await getSLAIds()
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

const withPaginationState = (getState: () => unknown) => {
  const {
    HCU: { pagination },
  } = getState() as StoreStateType
  const params = new URLSearchParams(window.location.search)
  if (!params.has('page')) {
    Object.keys(pagination).forEach((key) => {
      const typedKey = key as keyof StoreStateType['HCU']['pagination']
      if (typedKey === 'page' || typedKey === 'page_size')
        params.set(typedKey, pagination[typedKey].toString())
    })
  }
  const customEncode = (str: string) => {
    const encodingMap: Record<string, string> = {
      '-': '%2D',
      '.': '%2E',
      '!': '%21',
      '~': '%7E',
      '*': '%2A',
      "'": '%27',
      '(': '%28',
      ')': '%29',
    }

    return str
      .split('')
      .map((char) => encodingMap[char] || char)
      .join('')
  }
  return customEncode(params.toString())
}

export const fetchTicketList = createAsyncThunk(
  `${SLICE_NAME}/fetchTicketList`,
  async (_, { getState, rejectWithValue }) => {
    const params = withPaginationState(getState)
    try {
      const response = await getTicketList(params)
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchTicketListNoLoading = createAsyncThunk(
  `${SLICE_NAME}/fetchTicketListNoLoading`,
  // eslint-disable-next-line sonarjs/no-identical-functions
  async (_, { getState, rejectWithValue }) => {
    const params = withPaginationState(getState)
    try {
      const response = await getTicketList(params)
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchTicketDetail = createAsyncThunk(
  ` ${SLICE_NAME}/fetchTicketDetail`,
  async (payload: { ticketId: number; orderType: string }, { rejectWithValue }) => {
    const { ticketId } = payload
    try {
      const response = await getTicketDetail(ticketId)

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchComplaintReasons = createAsyncThunk(
  `${SLICE_NAME}/fetchComplaintReasons`,
  async (params: ComplaintReasonRequestParamsType, { rejectWithValue }) => {
    try {
      const response = await getComplainReasons(params)
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const updateReviewTicket = createAsyncThunk(
  `${SLICE_NAME}/updateReviewTicket`,
  async (ticketId: number, { rejectWithValue, dispatch }) => {
    try {
      await putReviewTicket(ticketId)
      dispatch(fetchTicketList())
      return ticketId
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const updateApproveTicket = createAsyncThunk(
  `${SLICE_NAME}/updateApproveTicket`,
  async (
    params: { ticketId: number; payload: ApproveTicketRequestParamsType },
    { rejectWithValue, dispatch },
  ) => {
    try {
      await putApproveTicket(params.ticketId, params.payload)
      dispatch(fetchTicketList())
      toastSuccess('Tiket berhasil direview')
      return params.ticketId
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const updateRejectTicket = createAsyncThunk(
  `${SLICE_NAME}/updateRejectTicket`,
  async (
    params: { ticketId: number; payload: RejectTicketRequestParamsType },
    { rejectWithValue, dispatch },
  ) => {
    try {
      await putRejectTicket(params.ticketId, params.payload)
      dispatch(fetchTicketList())
      toastSuccess('Tiket berhasil direview')
      return params.ticketId
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)
