import AxiosInstance from 'config/apiServiceApiGateway'

const { Get, Put, Post } = AxiosInstance

export interface VendorBrandGroupingItemDataType {
  id: number
  uniqueId: string
  groupId: number
  vendorId: number
  vendorName: string
  brandId: number
  brandName: string
  moq: number
  uom: string
  mov: number
  status: boolean
}

export interface VendorBrandGroupingListRequestParamsType {
  pageSize: number
  pageIndex: number
  search?: string
  group?: string
}

export interface VendorBrandGroupingListResponseType {
  data: VendorBrandGroupingItemDataType[]
  pagination: {
    pageSize: number
    pageIndex: number
    numberOfElements: number
  }
}

export interface AddEditVendorBrandGroupResponseType {
  data: VendorBrandGroupingItemDataType
}

export interface AddEditVendorBrandGroupRequestDataType {
  id?: number
  groupId: number
  vendorId: number
  brandId: number
  uom: string
  moq: number
  mov: number
  status: boolean
}

const apiVendorBrand = '/erp/internal/v1/vendor-brands'

export const getVendorBrandGroupingList = (params: VendorBrandGroupingListRequestParamsType) =>
  Get<VendorBrandGroupingItemDataType[]>({
    url: apiVendorBrand,
    convertResponse: true,
    convertRequest: true,
    params,
  })

export const postVendorBrandGroup = (data: AddEditVendorBrandGroupRequestDataType) =>
  Post<AddEditVendorBrandGroupResponseType, true>({
    url: apiVendorBrand,
    convertResponse: true,
    convertRequest: true,
    data,
  })

export const putVendorBrandGroup = (data: AddEditVendorBrandGroupRequestDataType) =>
  Put<AddEditVendorBrandGroupResponseType, true>({
    url: apiVendorBrand,
    convertResponse: true,
    convertRequest: true,
    data,
  })
