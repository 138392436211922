import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getKitchenProducts,
  type KitchenProductsRequestType,
} from 'features/Order/services/products'
import {
  getOrderTypeListAPI,
  postSubmitCreateOrder,
  RequestCreateOrderType,
} from 'features/Order/services/createOrder'
import {
  type ProductType,
  type ProductPaginationType,
} from 'features/Order/redux/CreateOrder/slice'
import { toastSuccess } from 'utils/toast'

export const fetchOrderTypeList = createAsyncThunk(
  `createOrder/fetchOrderTypeList`,
  async (_, { fulfillWithValue }) => {
    try {
      const response = await getOrderTypeListAPI()
      return fulfillWithValue(response?.data)
    } catch (err) {
      return callErrorMsg(err)
    }
  },
)

export const submitCreateOrder = createAsyncThunk(
  'createOrder/submitCreateOrder',
  async (payload: RequestCreateOrderType & { successCallback: () => void }) => {
    try {
      const { successCallback, ...restPayload } = payload
      await postSubmitCreateOrder(restPayload)
      toastSuccess('Pesanan Berhasil dibuat')
      successCallback()
    } catch (err) {
      callErrorMsg(err)
    }
  },
)

export const fetchProductList = createAsyncThunk(
  `createOrder/fetchProductList`,
  async (payload: KitchenProductsRequestType, { fulfillWithValue }) => {
    try {
      const response = await getKitchenProducts(payload)
      const dataToSend: {
        data: ProductType[]
        pagination: ProductPaginationType
        params: KitchenProductsRequestType
      } = {
        data: response.data.data,
        pagination: response.data.pagination,
        params: payload,
      }
      return fulfillWithValue(dataToSend)
    } catch (err) {
      return callErrorMsg(err)
    }
  },
)
