import { memo } from "react";
import styled, { css } from "styled-components";
import { InputDropDown } from "components";
import { formatMoneyComa } from "helpers";

function RowPo({ data }) {
  return (
    <Tr>
      <Td isSticky left="0px" min="30px">
        {data.srNumber}
      </Td>
      <Td isSticky left="58px" min="69px">
        {data.Code || "-"}
      </Td>
      <Td isSticky left="127px">
        {data.productDescription || "-"}
      </Td>
      <Td>{formatMoneyComa(data.quantity, "")}</Td>
      <Td>{data.baseUom}</Td>
      <Td noWrap>{data.dimension}</Td>
      <Td>{data.uomType}</Td>
      <Td>{formatMoneyComa(data.volume, "")}</Td>
      <Td>{data.uomVolume}</Td>
      <Td>{formatMoneyComa(data.netWeigth, "")}</Td>
      <Td>{formatMoneyComa(data.volumeMetricWeight)}</Td>
      <Td>{formatMoneyComa(data.volume, "")}</Td>
      <Td>{formatMoneyComa(data.totalGrossWeight, "")}</Td>
      <Td>{formatMoneyComa(data.chargeableWeight, "")}</Td>
      <Td>
        <InputDropDown disabled value="atur" />
      </Td>
    </Tr>
  );
}

RowPo.defaultProps = {};

export default memo(RowPo);

const Tr = styled.tr`
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  align-items: center;
  border-bottom: 1px solid #f0f3f7;
`;
const Td = styled.td`
  /* border-right: 1px solid red; */
  ::after {
    content: "";
    background-color: ${({ theme: { colors } }) => colors.white};
    height: 20px;
    position: absolute;
    width: 2px;
    right: -1px;
  }
  background-color: ${({ theme: { colors } }) => colors.white};
  ${({ isSticky, left }) =>
    isSticky && {
      position: "sticky",
      left: left
    }}
  .input-drop-down {
    width: 112px;
  }
  input {
    padding: 8px 0;
  }
  .list-container {
    right: 0;
    left: auto;
  }
  color: ${({ theme: { colors }, color = "textSoft" }) => colors[color]};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.toast};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  padding: 16px 12px;
  min-width: ${({ min }) => min};
  ${({ noWrap }) =>
    noWrap &&
    css`
      white-space: nowrap;
    `}
`;
