import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getTargetedUserList,
  deleteTargetedUser,
  getSearchPWPProductPriority,
  getPWPPriority,
  postPWPPriority,
} from 'utils/apiList/promoPwp'
import { callErrorMsg } from 'helpers/errorMsg'

export const fetchTargetedUser = createAsyncThunk(
  'promoPwp/fetchTargetedUser',
  async ({ id }: { id: number }, { rejectWithValue, getState }) => {
    const {
      promoPwp: {
        targetedUserList: {
          query: { pageIndex, pageSize },
        },
      },
    } = getState() as StoreStateType
    try {
      const res = await getTargetedUserList({ id, params: { pageIndex, pageSize } })

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const removeTargetedUser = createAsyncThunk(
  'promoPwp/removeTargetedUser',
  async (
    { campaignID, customerID }: { campaignID: number; customerID: number },
    { rejectWithValue },
  ) => {
    try {
      const res = await deleteTargetedUser({ campaignID, customerID })
      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchSearchPWPProductTargeted = createAsyncThunk(
  'promoPwp/fetchSearchPWPProductTargeted',
  async ({ search }: { search: string }, { rejectWithValue }) => {
    try {
      const res = await getSearchPWPProductPriority({ params: { search } })
      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchPWPPriority = createAsyncThunk(
  'promoPwp/fetchPWPPriority',
  async (_, { rejectWithValue }) => {
    try {
      const res = await getPWPPriority()
      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const updatePWPPriority = createAsyncThunk(
  'promoPwp/updatePWPPriority',
  async (_, { rejectWithValue, getState }) => {
    const {
      promoPwp: { pwpPriority },
    } = getState() as StoreStateType

    try {
      const validatePayload = pwpPriority.map(
        (el: { id: number; priority: string; pwpProductId: number }, i) => ({
          id: el.pwpProductId ? el.pwpProductId : el.id,
          sortOrder: i + 1,
        }),
      )

      const res = await postPWPPriority({ payload: { data: validatePayload } })
      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)
