import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getFreshPurchaseOrderDetail,
  postProcessInboundFpo,
  postPricingFpo,
} from 'utils/apiList/freshPurchaseOrder'

import { callErrorMsg } from 'helpers/errorMsg'
import { toastSuccess } from 'utils/toast'
import type { AxiosResponse } from 'axios'
import type {
  GetFpoDetailResponseType,
  PostProcessInboundFpoRequestType,
  PostAPiResponseType,
  PostPricingFpoRequestType,
} from 'utils/apiList/freshPurchaseOrder'

export const SLICE_NAME = 'freshPurchaseOrderProcessInbound'

type FpoItemDataType = GetFpoDetailResponseType['data']['items'][number]

type FpoItemListProcessInboundLocalStorageType = { id: number; receivedQty: number }[]
type FpoItemListPricingLocalStorageType = { id: number; actualPrice: number }[]

type ActivityType = 'process-inbound' | 'pricing' | ''
interface InboundItemInterface extends FpoItemDataType {
  isChecked: boolean
  isRowDisabled: boolean
  isPriceDisabled: boolean
  maxReceivedQty: number
  qty: {
    isError: boolean
    errorMessage: string
  }
  price: {
    isError: boolean
    errorMessage: string
  }
}

const MAX_BUFFER_IN_DECIMAL = 0.2 // 20 in percent
const PRICING = 'pricing'
const PROCESS_INBOUND = 'process-inbound'

const getQtyInKg = (qtyInGram: number) => qtyInGram / 1000

const fetchFpoDetailUtil = {
  isActualPriceGreaterThanZero: (actualPrice: number | null) => !!(actualPrice && actualPrice > 0),
  getQtyInKg,
  getMaxReceivedQty: (itemData: FpoItemDataType) => {
    const maxQtyBuffer =
      MAX_BUFFER_IN_DECIMAL * getQtyInKg(itemData.plannedQty) + getQtyInKg(itemData.plannedQty)
    return maxQtyBuffer - getQtyInKg(itemData.usedQty)
  },
  getIsRowDisabled: (itemData: FpoItemDataType, activityType: ActivityType) =>
    !!(activityType === PRICING && itemData.actualPrice) ||
    !!(activityType === PROCESS_INBOUND && itemData.remainingQty === 0),
  getActualPrice: (
    itemData: FpoItemDataType,
    activityType: ActivityType,
    isPriceAlreadyExistInLocalStorage?: boolean,
    actualPriceInLocalStorage?: number,
  ) => {
    if (
      isPriceAlreadyExistInLocalStorage &&
      actualPriceInLocalStorage &&
      activityType === 'pricing'
    )
      return actualPriceInLocalStorage
    if (!itemData.actualPrice && itemData.suggestedPrice && activityType === 'pricing')
      return itemData.suggestedPrice
    return itemData.actualPrice
  },
}

const getItemData = (itemData: FpoItemDataType, activityType: ActivityType) => ({
  ...itemData,
  isChecked: false,
  isRowDisabled: fetchFpoDetailUtil.getIsRowDisabled(itemData, activityType),
  isPriceDisabled: fetchFpoDetailUtil.isActualPriceGreaterThanZero(itemData.actualPrice),
  plannedQty: getQtyInKg(itemData.plannedQty),
  remainingQty: getQtyInKg(itemData.remainingQty),
  maxReceivedQty: itemData.remainingQty > 0 ? fetchFpoDetailUtil.getMaxReceivedQty(itemData) : 0,
  usedQty: getQtyInKg(itemData.usedQty),
  qty: {
    isError: false,
    errorMessage: '',
  },
  price: {
    isError: false,
    errorMessage: '',
  },
  actualPrice: fetchFpoDetailUtil.getActualPrice(itemData, activityType),
})

const getMappedItemForProcessInboundByResponseAndLocalStorage = (
  items: FpoItemDataType[],
  parsedFpoItemListStorageData: FpoItemListProcessInboundLocalStorageType,
  activityType: ActivityType,
) => {
  const listIds = parsedFpoItemListStorageData.map((item) => item.id)
  const itemsWithSameIdWithLocalStorage = items
    .filter((item) => listIds.includes(item.id))
    .map((itemData) => {
      const newItemData = getItemData(itemData, activityType)

      const itemDataInLocalStorage = parsedFpoItemListStorageData.find(
        (itemInStorage) => itemInStorage.id === itemData.id,
      ) as FpoItemListProcessInboundLocalStorageType[number]

      return {
        ...newItemData,
        receivedQty: itemDataInLocalStorage.receivedQty,
      }
    })

  const itemsExcludeIdInLocalStorage = items
    .filter((item) => !listIds.includes(item.id))
    .map((itemData) => getItemData(itemData, activityType))

  const newList: InboundItemInterface[] = [
    ...itemsWithSameIdWithLocalStorage,
    ...itemsExcludeIdInLocalStorage,
  ]

  return newList
}

const getMappedItemForPricingByResponseAndLocalStorage = (
  items: FpoItemDataType[],
  parsedFpoItemListStorageData: FpoItemListPricingLocalStorageType,
  activityType: ActivityType,
) => {
  const listIds = parsedFpoItemListStorageData.map((item) => item.id)

  const itemsWithSameIdWithLocalStorage = items
    .filter((item) => listIds.includes(item.id))
    .map((itemData) => {
      const newItemData = getItemData(itemData, activityType)
      const itemDataInLocalStorage = parsedFpoItemListStorageData.find(
        (itemInStorage) => itemInStorage.id === itemData.id,
      ) as FpoItemListPricingLocalStorageType[number]

      return {
        ...newItemData,
        actualPrice: fetchFpoDetailUtil.getActualPrice(
          itemData,
          activityType,
          true,
          itemDataInLocalStorage.actualPrice,
        ),
      }
    })

  const itemsExcludeIdInLocalStorage = items
    .filter((item) => !listIds.includes(item.id))
    .map((itemData) => getItemData(itemData, activityType))

  const newList: InboundItemInterface[] = [
    ...itemsWithSameIdWithLocalStorage,
    ...itemsExcludeIdInLocalStorage,
  ]
  return newList
}

export const fetchFpoDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchFpoDetail`,
  async (id: number, { getState, rejectWithValue }) => {
    try {
      const rootState = getState() as StoreStateType
      const { activityType } = rootState.freshPurchaseOrderProcessInbound

      const {
        data: { data },
      } = await getFreshPurchaseOrderDetail(id)

      const fpoNumberWithActivityType = `${data.fpoNumber}_${activityType}`
      const fpoItemListStorageData = localStorage.getItem(fpoNumberWithActivityType)

      if (fpoItemListStorageData) {
        const parsedFpoItemListStorageData = JSON.parse(fpoItemListStorageData)
        const newList =
          activityType === PROCESS_INBOUND
            ? getMappedItemForProcessInboundByResponseAndLocalStorage(
                data.items,
                parsedFpoItemListStorageData,
                activityType,
              )
            : getMappedItemForPricingByResponseAndLocalStorage(
                data.items,
                parsedFpoItemListStorageData,
                activityType,
              )
        return {
          data,
          itemList: newList,
        }
      }

      const itemList = data.items.map((itemData) => getItemData(itemData, activityType))
      return {
        data,
        itemList,
      }
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

interface ProcessInboundFpoRequestType extends PostProcessInboundFpoRequestType {
  id: number
}

const removeOrSetLocalStorageInboundOrPricingData = <
  T extends { id: number },
  P extends { freshPurchaseItemId: number },
>(
  itemListLocalStorage: T[],
  fpoNumberWithActivityType: string,
  items: P[],
) => {
  const listIds = items.map((itemData) => itemData.freshPurchaseItemId)
  const newList = itemListLocalStorage.filter((itemStorage) => !listIds.includes(itemStorage.id))

  if (!newList.length) localStorage.removeItem(fpoNumberWithActivityType)
  else localStorage.setItem(fpoNumberWithActivityType, JSON.stringify(newList))
  return true
}

export const processInboundFpo = createAsyncThunk(
  `${SLICE_NAME}/processInboundFpo`,
  async (requestData: ProcessInboundFpoRequestType, { getState, rejectWithValue }) => {
    try {
      const rootState = getState() as StoreStateType
      const { data, activityType } = rootState.freshPurchaseOrderProcessInbound

      const response: AxiosResponse<PostAPiResponseType> = await postProcessInboundFpo(
        requestData.id,
        {
          fullProcess: requestData.fullProcess,
          items: requestData.items,
        },
      )

      const fpoNumberWithActivityType = `${data?.fpoNumber}_${activityType}`
      const stringifyItemList = localStorage.getItem(fpoNumberWithActivityType)

      if (stringifyItemList) {
        removeOrSetLocalStorageInboundOrPricingData(
          JSON.parse(stringifyItemList),
          fpoNumberWithActivityType,
          requestData.items,
        )
      }
      toastSuccess(response.data.message)
      window.location.reload()
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

type PricingFpoRequestType = PostPricingFpoRequestType & {
  id: number
}

export const pricingFpo = createAsyncThunk(
  `${SLICE_NAME}/pricingFpo`,
  async (requestData: PricingFpoRequestType, { getState, rejectWithValue }) => {
    try {
      const rootState = getState() as StoreStateType
      const { data, activityType } = rootState.freshPurchaseOrderProcessInbound

      const response = await postPricingFpo(requestData.id, { items: requestData.items })
      toastSuccess(response.data.message)
      const fpoNumberWithActivityType = `${data?.fpoNumber}_${activityType}`

      const stringifyItemList = localStorage.getItem(fpoNumberWithActivityType)
      if (stringifyItemList) {
        removeOrSetLocalStorageInboundOrPricingData(
          JSON.parse(stringifyItemList),
          fpoNumberWithActivityType,
          requestData.items,
        )
      }

      window.location.reload()
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
