import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getCampaignSegmentList,
  deleteCampaignProgress,
  getCampaignTypeList,
  deleteDataInventories,
  DeleteDataInventoriesRequestType,
  getCampaignCommercialInternalSkp,
} from 'utils/apiList/campaignV2'
import {
  getPromoUserSegment,
  type GetPromoUserSegmentParamsType,
} from 'utils/apiList/promoUserSegment'
import type { GetCampaignCommercialInternalSkpReqType } from 'utils/apiList/campaignV2'
import { toastFailed, toastSuccess } from 'utils/toast'
import {
  getAllSegments,
  postAddCampaignInventory,
  type PostAddCampaignInventoryRequestType,
} from 'features/CampaignAndDiscount/services/campaignAddManual'
import {
  putInventoryBMSMById,
  type PutInventoryBMSMByIdRequestType,
} from 'features/CampaignAndDiscount/services/campaignAddEdit'

const SLICE_NAME = 'campaignAddManual'

export const fetchCampaignSegmentList = createAsyncThunk(
  `${SLICE_NAME}/campaignSegmentList`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getCampaignSegmentList()
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateCampaignProgress = createAsyncThunk(
  `${SLICE_NAME}/deleteCampaignProgress`,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await deleteCampaignProgress(id)
      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchCampaignTypeList = createAsyncThunk(
  `${SLICE_NAME}/getCampaignTypeList`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getCampaignTypeList()
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchCampaignCommercialInternalSkp = createAsyncThunk(
  `${SLICE_NAME}/getCampaignCommercialInternalSkp`,
  async (params: GetCampaignCommercialInternalSkpReqType, { rejectWithValue }) => {
    try {
      const res = await getCampaignCommercialInternalSkp(params)
      return {
        data: res.data.data,
        params: { ...params },
      }
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchCampaignCommercialInternalSkpDetail = createAsyncThunk(
  `${SLICE_NAME}/getCampaignCommercialInternalSkpDetail`,
  async (
    {
      params,
      id,
    }: {
      params: GetCampaignCommercialInternalSkpReqType
      id: number
    },
    { rejectWithValue, getState },
  ) => {
    try {
      const {
        campaignAddManual: { skps },
      } = getState() as StoreStateType

      const findOne = Object.entries(skps).find((el) => el[1].id === id)

      if (!findOne) {
        const res = await getCampaignCommercialInternalSkp(params)
        const data = res.data.data.filter((el) => el.id === id)

        return {
          data,
          id,
        }
      }

      return {
        data: [],
        id,
      }
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateDataInventoriesPerItem = createAsyncThunk(
  `${SLICE_NAME}/updateDataInventoriesPerItem`,
  async (params: PutInventoryBMSMByIdRequestType, { rejectWithValue }) => {
    try {
      //
      const res = await putInventoryBMSMById(params)
      toastSuccess('Data Berhasil Di Update')

      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const removeInventoriesItem = createAsyncThunk(
  `${SLICE_NAME}/removeInventoriesItem`,
  async (params: DeleteDataInventoriesRequestType, { rejectWithValue }) => {
    try {
      const res = await deleteDataInventories(params)
      toastSuccess(res.data.data.message)

      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchSegmentCampaignLevel = createAsyncThunk(
  `${SLICE_NAME}/fetchSegmentCampaignLevel`,
  async (params: GetPromoUserSegmentParamsType, { rejectWithValue }) => {
    const currentParaams = {
      limit: 20,
      ...params,
    }
    try {
      const { data } = await getPromoUserSegment(currentParaams)

      return data?.data?.data?.list || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan data campaing level segmen user')
      return rejectWithValue(err)
    }
  },
)

export const fetchSegmentList = createAsyncThunk(
  `${SLICE_NAME}/fetchSegmentList`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getAllSegments()

      return data.data || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan user segment list')
      return rejectWithValue(err)
    }
  },
)

export const insertCampaignInventory = createAsyncThunk(
  `${SLICE_NAME}/insertCampaignInventory`,
  async (params: PostAddCampaignInventoryRequestType, { rejectWithValue }) => {
    try {
      const { data } = await postAddCampaignInventory(params)

      if (data.error?.message) {
        toastFailed(data.error?.message)
      }

      return {
        data: data.data,
        error: data.error ? data.error : null,
      }
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan user segment list')
      return rejectWithValue(err)
    }
  },
)
