import { Get, Post, Put, Delete } from 'config/apiServiceConfig'
import { ProductVariant as Variant, AddProductVariant } from 'features/Product/@types'

export const getProductVariantList = (params: Partial<Variant.QueryType>) =>
  Get<Variant.ResponseGetProductVariantType>({
    url: '/api/ims/:version/product-variant',
    version: 'v1',
    params,
  })

export const deleteProductVariant = (id: number) =>
  Delete<AddProductVariant.ResponsePostProductVariantType>({
    url: '/api/ims/:version/product-variant/:id',
    version: 'v1',
    urlParams: { id },
  })

// Add/Edit product variant
export const getBrands = (params: { limit: number; name: string }) =>
  Get<AddProductVariant.ResponseGetBrandType>({
    url: '/api/brand',
    params,
  })

export const getProducts = (params: { pageSize: number; name: string; brandIDs: number }) =>
  Get<AddProductVariant.ResponseGetProductType>({
    url: '/api/ims/:version/products',
    params,
    version: 'v2',
  })

export const postProductVariant = (data: AddProductVariant.AddDataPayloadType) =>
  Post<AddProductVariant.ResponsePostProductVariantType>({
    url: '/api/ims/:version/product-variant',
    version: 'v1',
    data,
  })

export const putProductVariant = (variantId: number, data: AddProductVariant.AddDataPayloadType) =>
  Put<AddProductVariant.ResponsePostProductVariantType>({
    url: '/api/ims/:version/product-variant/:variantId',
    version: 'v1',
    urlParams: { variantId },
    data,
  })

export const getProductVariantDetail = (id: number) =>
  Get<AddProductVariant.ResponseGetProductVariantDetailType>({
    url: '/api/ims/:version/product-variant/:id',
    urlParams: { id },
    version: 'v1',
  })
