import { memo, useContext } from 'react'
import styled from 'styled-components'
import { QR, Barcode } from './components'
import storeContext from 'storeContext'

function TemplateCetakPackageID() {
  const {
    state: {
      printContent: { data },
    },
  } = useContext(storeContext.PrintAreaContext)

  return (
    <Container>
      <tbody>
        {data.map((el, i) => {
          return (
            <Body key={`${i}asdad`}>
              <td>
                <Wrapper>
                  <QR data={el} />
                  <Barcode data={el} />
                </Wrapper>
              </td>
            </Body>
          )
        })}
      </tbody>
    </Container>
  )
}

export default memo(TemplateCetakPackageID)

const Container = styled.table`
  flex-wrap: wrap;
  background: white;
  padding: 16px;
  width: 100%;
  padding: 0px;
`

const Body = styled.tr`
  width: 100%;
  height: 7.4cm;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
