import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@mui/material'
import styled from 'styled-components'
import {
  compose,
  system,
  layout,
  space,
  border,
  position,
  color,
  LayoutProps,
  SpaceProps,
  BorderProps,
  PositionProps,
  ColorProps,
} from 'styled-system'

export type IconButtonPropsType = MuiIconButtonProps &
  LayoutProps &
  SpaceProps &
  BorderProps &
  PositionProps &
  ColorProps & {
    transform?: string
  }

const IconButton = styled(MuiIconButton)<IconButtonPropsType>(
  compose(
    layout,
    space,
    border,
    position,
    color,
    system({
      transform: true,
    }),
  ),
)

export default IconButton
