/* eslint-disable import/no-cycle */
import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  postNewGroupSO,
  putCancelGroupSO,
  getLoader,
  getDriver,
  get3PL,
  getDetailLoading,
  getDetailLoadingItems,
  postLoading,
  postScanKoli,
  getConfiguration,
  postCancelLoading,
  postSubmitLoading,
  putUpdateGroupOnDelivery,
  getPrintSuratJalan,
  PostLoadingPayloadType,
  PostNewGroupSOPayloadInterface,
  GetLoaderPayloadInterface,
  PostScanKoliPayloadType,
  PostCancelLoadingRequestType,
  PutUpdateGroupOnDeliveryType,
} from 'utils/apiList/loadingGoods'
import { callErrorMsg } from 'helpers/errorMsg'
import { history } from 'store'
import { toastNeutral, toastSuccess } from 'utils/toast'
import { supplyOrder } from 'config/routes'
import { transformObjtoQuery } from 'helpers'
import { setAnotherSO } from './loadingGoodsSlice'

export const SLICE_NAME = 'loadingGoods'

export const newGroupSO = createAsyncThunk(
  `${SLICE_NAME}/newGroupSO`,
  async (payload: PostNewGroupSOPayloadInterface) => {
    try {
      await postNewGroupSO(payload)
      toastNeutral(`SO ${payload.supplyOrderIds.join(', ')} berhasil dikelompokkan`)
      return Promise.resolve(true)
    } catch (error) {
      callErrorMsg(error)
      return Promise.reject(error)
    }
  },
)

export const cancelGroupSO = createAsyncThunk(
  `${SLICE_NAME}/cancelGroupSO`,
  async (payload: string) => {
    try {
      const response = await putCancelGroupSO(payload)

      toastNeutral(response?.data?.message || 'Success cancel group SO')
      return Promise.resolve(true)
    } catch (error) {
      callErrorMsg(error)
      return Promise.reject(error)
    }
  },
)

export const getLoaderSO = createAsyncThunk(
  `${SLICE_NAME}/getLoader`,
  async (payload: GetLoaderPayloadInterface) => {
    try {
      const response = await getLoader(payload)
      return response.data
    } catch (error) {
      callErrorMsg(error)
      return Promise.reject(error)
    }
  },
)

export const getDriverSO = createAsyncThunk(
  `${SLICE_NAME}/getDriver`,
  async (payload: GetLoaderPayloadInterface) => {
    try {
      const response = await getDriver(payload)
      return response.data
    } catch (error) {
      callErrorMsg(error)
      return Promise.reject(error)
    }
  },
)

export const get3PLSO = createAsyncThunk(`${SLICE_NAME}/get3PLSO`, async () => {
  try {
    const response = await get3PL()
    return response.data
  } catch (error) {
    callErrorMsg(error)
    return Promise.reject(error)
  }
})

export const submitLoadingSO = createAsyncThunk(
  `${SLICE_NAME}/submitLoadingSO`,
  async (payload: PostLoadingPayloadType) => {
    try {
      await postLoading(payload)
      toastNeutral(`Status SO ${payload.loaderIds.join(', ')} siap untuk loading`)
      return Promise.resolve(true)
    } catch (error) {
      callErrorMsg(error)
      return Promise.reject(error)
    }
  },
)

export const getDetailLoadingSO = createAsyncThunk(
  `${SLICE_NAME}/getDetailLoading`,
  async (payload: string) => {
    try {
      const response = await getDetailLoading(payload)
      return response.data
    } catch (error) {
      callErrorMsg(error)
      return Promise.reject(error)
    }
  },
)

export const getDetailLoadingItemsSO = createAsyncThunk(
  `${SLICE_NAME}/getDetailLoadingItemsSO`,
  async (payload: string) => {
    try {
      const response = await getDetailLoadingItems(payload)
      return response.data
    } catch (error) {
      callErrorMsg(error)
      return Promise.reject(error)
    }
  },
)

export const postScanKoliSO = createAsyncThunk(
  `${SLICE_NAME}/postScanKoli`,
  async (payload: PostScanKoliPayloadType, { dispatch }) => {
    try {
      const { soIndex, koliIndex, ...restPayload } = payload
      const response = await postScanKoli(restPayload)

      if (response.data.message && response.data.data.valid) {
        toastSuccess(response.data.message)
      } else if (response.data.data?.skippedSupplyOrders?.length) {
        dispatch(
          setAnotherSO({
            isShow: true,
            totalKoliNotScanned: response.data.data.skippedSupplyOrders[0].totalSkippedKoli,
            so: response.data.data.skippedSupplyOrders[0].invoiceNumber,
            barcodeValue: restPayload.koliNumber,
            soDestIndex: soIndex as number,
          }),
        )
      }

      return {
        data: response.data.data,
        index: {
          soIndex,
          koliIndex,
        },
      }
    } catch (error) {
      callErrorMsg(error)
      return Promise.reject(error)
    }
  },
)

export const getConfigurationSO = createAsyncThunk(`${SLICE_NAME}/getConfigurationSO`, async () => {
  try {
    const response = await getConfiguration()
    return response.data
  } catch (error) {
    callErrorMsg(error)
    return Promise.reject(error)
  }
})

export const postCancelLoadingSO = createAsyncThunk(
  `${SLICE_NAME}/postCancelLoadingSO`,
  async (payload: PostCancelLoadingRequestType) => {
    try {
      const { supplyOrderNumber, ...restPayload } = payload
      await postCancelLoading(restPayload)
      toastNeutral(`No. ${supplyOrderNumber} batal kirim, koli berhasil unloading.`)
      return supplyOrderNumber
    } catch (error) {
      callErrorMsg(error)
      return Promise.reject(error)
    }
  },
)

export const postLoadingSubmitSO = createAsyncThunk(
  `${SLICE_NAME}/postLoadingSubmitSO`,
  async (payload: { loadingId: number; so: string; origin: string }) => {
    try {
      const { so, loadingId, origin } = payload
      await postSubmitLoading(loadingId)

      const params = {
        created: false,
        pageIndex: 0,
        status: 'READY TO SHIP',
        origin,
      }

      toastNeutral(`SO ${so} berhasil diloading dan siap dikirim.`)
      history.replace(`${supplyOrder}${transformObjtoQuery(params)}#SO`)
      return Promise.resolve(true)
    } catch (error) {
      callErrorMsg(error)
      return Promise.reject(error)
    }
  },
)

export const getPrintSuratJalanSO = createAsyncThunk(
  `${SLICE_NAME}/getPrintSuratJalanSO`,
  async (payload: number) => {
    try {
      const response = await getPrintSuratJalan(payload)
      return response.data
    } catch (error) {
      callErrorMsg(error)
      return Promise.reject(error)
    }
  },
)

export const putUpdateGroupOnDeliverySO = createAsyncThunk(
  `${SLICE_NAME}/putUpdateGroupOnDeliverySO`,
  async (payload: PutUpdateGroupOnDeliveryType, { dispatch }) => {
    try {
      await putUpdateGroupOnDelivery(payload)
      dispatch(getPrintSuratJalanSO(payload.loadingID))
      return payload.loadingID
    } catch (error) {
      callErrorMsg(error)
      return Promise.reject(error)
    }
  },
)
