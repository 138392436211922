import { createAsyncThunk } from '@reduxjs/toolkit'

import { getUserData, deleteSession } from 'utils/api'
import { toastFailed } from 'utils/toast'

import { removeTokenCookies } from 'config/cookies'

import { SLICE_NAME } from './slice'

export const fetchGetAtuhentication = createAsyncThunk(
  `${SLICE_NAME}/fetchGetAtuhentication`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await getUserData()
      return response.data
    } catch (err) {
      if ((err as ApiErrorType)?.response) {
        const { response } = err as ApiErrorType
        removeTokenCookies()
        return rejectWithValue(response?.data)
      }
      return rejectWithValue(err)
    }
  },
)

export const handleLogout = createAsyncThunk(`${SLICE_NAME}/handleLogout`, async () => {
  try {
    await deleteSession()
    removeTokenCookies()
    window.location.href = '/'
  } catch (err) {
    if ((err as ApiErrorType)?.response) {
      const { response } = err as ApiErrorType
      toastFailed(response?.data)
    } else {
      toastFailed('Terjadi kesalahan mohon coba beberapa saat lagi')
    }
  }
})
