import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  AssetSupplyOrderUpdateDataParamsInterface,
  AssetSupplyOrderUpdateItemQuantityInterface,
} from 'features/AssetManagement/@types/typeSupplyOrder'

import {
  getSupplyOrderDetailAPI,
  postSupplyOrderUpdateDataAPI,
  postSupplyOrderUpdateItemQuantityAPI,
  getSupplyOrderInventoryByCodeAPI,
  getSupplyOrderAssetInventoryDetailAPI,
} from 'features/AssetManagement/services/supplyOrder'

import { setAssetData, setOpenModalInputManual } from 'redux/supplyOrderCheckQty'

import { callErrorMsg } from 'helpers/errorMsg'
import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'

import { toastFailed, toastSuccess } from 'utils/toast'

import { getSupplyOrderGrandDetail } from 'utils/api'

import {
  setIsLoading,
  setDetailSOAsset,
  setDetailSOConverted,
  setAssetManualStep,
  setAssetQrData,
  setAsset,
  resetManualInput,
} from './slice'

const SLICE_NAME = 'assetSupplyOrderDetail'

export const getSupplyOrderDetail = createAsyncThunk(
  `${SLICE_NAME}/getSupplyOrderDetail`,
  async (
    params: { id: string; cb: (param: Record<string, unknown>) => void },
    { rejectWithValue, dispatch },
  ) => {
    dispatch(setIsLoading(true))
    try {
      const { data } = await getSupplyOrderDetailAPI(params.id)
      const { items, data: so_data } = data

      const totalWeight = items.reduce((acc, item) => acc + item.weight, 0)

      const convertedData = {
        checker: null,
        locationDestinationId: so_data.destination.location_id,
        locationDestinationName: so_data.destination.location_name,
        locationOriginId: so_data.origin.location_id,
        locationOriginName: so_data.origin.location_name,
        packers: null,
        supplyOrderActivities: null,
        supplyOrderCreatedAt: so_data.created_at,
        supplyOrderCreatedBy: so_data.created_by,
        supplyOrderDate: so_data.so_date,
        supplyOrderId: so_data.id,
        supplyOrderItemInbound: null,
        supplyOrderItems: null,
        supplyOrderNumber: so_data.so_number,
        supplyOrderProductSummary: {
          totalSku: items.length,
          netWeight: totalWeight,
          volumeWeight: `-`,
          totalQty: items.reduce((acc, item) => acc + item.request_qty, 0),
          grossWeight: 0,
          volume: `-`,
        },
        supplyOrderReff: so_data.so_reff, // ini juga kayanya ga dikirim ulang.
        supplyOrderRemarks: so_data.remarks,
        supplyOrderRequestBy: so_data.request_by, // ini kayanya ga ada.
        supplyOrderShippingInstructions: so_data.shipping_instruction,
        supplyOrderStatus: so_data.status,
        supplyOrderType: so_data.type,
        items,
      }

      params.cb(convertedData)
      return data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const postSupplyOrderUpdate = createAsyncThunk(
  `${SLICE_NAME}/postSupplyOrderUpdate`,
  async (
    params: { payload: AssetSupplyOrderUpdateDataParamsInterface; callBack: () => void },
    { rejectWithValue },
  ) => {
    try {
      const response = await postSupplyOrderUpdateDataAPI(params.payload)
      const { data } = response

      if (data.code === 200) {
        params.callBack()
      }

      return true
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const getSupplyOrderDetailCheckQty = createAsyncThunk(
  `${SLICE_NAME}/getSupplyOrderDetail`,
  async (so_id: string, { rejectWithValue, dispatch }) => {
    dispatch(setIsLoading(true))
    try {
      const response = await getSupplyOrderDetailAPI(so_id)
      const { data } = response
      const { data: so_data, items } = data

      const convertedItems = items.map((item) => ({
        productId: item.asset_inventory_id,
        productSku: item.asset_code,
        productName: item.product_name,
        productUom: item.uom,
        requestQuantity: item.request_qty,
        totalActualQuantity: so_data.status === 'inprogress' ? item.incoming_qty : item.actual_qty,
        supplyOrderItemId: item.supply_order_id,
      }))

      const itemsAsset = items.map((item) => ({
        assetCode: item.asset_code,
        assetname: item.product_name,
        assetId: item.asset_inventory_id,
        incomingQty: item.incoming_qty,
        actualQty: item.actual_qty,
        requestQty: item.request_qty,
      }))

      const detailSO = {
        supplyOrderId: so_data.id,
        supplyOrderNumber: so_data.so_number,
        supplyOrderItems: convertedItems,
      }
      dispatch(setDetailSOConverted(detailSO))
      dispatch(
        setAsset({
          originId: so_data.origin.location_id,
          assetSoId: so_data.id,
          status: so_data.status,
          items: itemsAsset,
        }),
      )

      dispatch(setDetailSOAsset(response.data))
      dispatch(setIsLoading(false))

      return true
    } catch (error) {
      dispatch(setIsLoading(false))
      callErrorMsg(error)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const postSupplyOrderUpdateItemQuantity = createAsyncThunk(
  `${SLICE_NAME}/postSupplyOrderUpdateItemQuantity`,
  async (params: AssetSupplyOrderUpdateItemQuantityInterface, { rejectWithValue, dispatch }) => {
    try {
      const response = await postSupplyOrderUpdateItemQuantityAPI(params)
      const { data } = response
      if (data.code === 200) {
        toastSuccess('Berhasil update kuantiti')
      }

      dispatch(resetManualInput())

      return data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const getSupplyOrderInventoryCode = createAsyncThunk(
  `${SLICE_NAME}/getSupplyOrderInventoryCode`,
  async (params: { code: string; isManual?: boolean }, { rejectWithValue, dispatch, getState }) => {
    const {
      assetSupplyOrderDetail: { detailSOConverted },
    } = getState() as StoreStateType
    try {
      const response = await getSupplyOrderInventoryByCodeAPI(params.code)
      const { data, error } = response.data

      const findItem = detailSOConverted.supplyOrderItems.find(
        (item) => item.productId === data.asset_inventory_id,
      )

      if (data === undefined || !findItem) {
        toastFailed(`kode asset tidak ditemukan`)
        return true
      }

      const convertedData = {
        sku: data.sku_number || ``,
        productName: data.name || ``,
        totalRequestQty: 1,
        supplyOrderItemId: 0,
        expiryDate: null,
        remainingRequestQuantity: 1,
        totalAvailable: 0,
        statusId: 0,
        statusNoteId: null,
        productId: data.asset_inventory_id || 0,
        qrCode: data.code,
        substituteItem: false,
      }

      const assetQRdataPayload = {
        data: {
          expDate: null,
          productId: Number(data.asset_inventory_id) || 0,
          productName: data.name,
          productSku: data.sku_number || '',
          productUom: data.uom || '',
          qrCode: data.code,
          remainingRequestQuantity: 1,
          requestQuantity: 1,
          status: data.status,
          statusId: 0,
          statusNote: data.note,
          statusNoteId: 0,
          supplyOrderItemId: 0,
        },
        error: {
          message: error.message || '',
        },
      }

      if (params.isManual) {
        dispatch(setAssetManualStep(convertedData))
      }

      dispatch(setAssetQrData(assetQRdataPayload))

      return true
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const getSupplyOrderAssetInventoryWms = createAsyncThunk(
  `${SLICE_NAME}/getSupplyOrderInventoryWms`,
  async (
    params: { code: string; soId: string; isManual?: boolean },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const dataSO = await getSupplyOrderGrandDetail(params.soId)
      const { data: innerDataSO } = dataSO
      const originId = innerDataSO?.data?.locationOriginId || 0

      const response = await getSupplyOrderAssetInventoryDetailAPI(
        params.code,
        `moveable`,
        originId,
      )
      const { data, error } = response.data

      const assetQRdataPayload = {
        assetInventoryId: data.asset_inventory_id,
        assetCode: data.code,
        error: error.message,
        name: data.name,
      }

      dispatch(setAssetData(assetQRdataPayload))

      if (params.isManual) {
        dispatch(setOpenModalInputManual(false))
      }

      return true
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)
