import addEditVoucherSlice, {
  INITIAL_RULES,
  INITIAL_COST_SETTING,
  COST_SETTING_TYPE_OPTIONS,
  type UserTargetedType,
  type CategoryType,
  type LocationType,
  type ProductType,
  type CustomVoucherRuleType,
  type VoucherPartnershipContentFieldType,
  type CustomVoucherType,
  type AddEditVoucherStateInterface,
  type VoucherPaymentChannelType,
  type CostSettingOptionType,
  type CostSettingVariantType,
} from './addEditVoucherSlice'

export {
  type VoucherBenefitType,
  type VoucherTargetedType,
  type VoucherMechanismType,
  type VoucherRuleSLAType,
} from 'utils/apiList/voucher'

export { PaymentChannelTypeEnum, type PaymentChannelType } from 'utils/apiList/paymentChannel'

export const { reset, setIsShowModalUploadTargetUser, setUserTargetedQuery } =
  addEditVoucherSlice.actions

export {
  fetchVoucherBenefit,
  fetchVoucherTargeted,
  fetchVoucherMechanism,
  fetchVoucherPaymentChannel,
  getVoucherDetail,
  updateVoucher,
  createVoucher,
  updateTargetedUsers,
  fetchUserTargetedList,
  removeCustomerVoucher,
} from './addEditVoucherThunk'

export {
  INITIAL_RULES,
  type UserTargetedType,
  INITIAL_COST_SETTING,
  COST_SETTING_TYPE_OPTIONS,
  type CategoryType,
  type LocationType,
  type ProductType,
  type CustomVoucherRuleType,
  type VoucherPartnershipContentFieldType,
  type CustomVoucherType,
  type AddEditVoucherStateInterface,
  type VoucherPaymentChannelType,
  type CostSettingOptionType,
  type CostSettingVariantType,
}

export default addEditVoucherSlice.reducer
