import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { GetKoliLostResponseType, GetKoliLostDetailResponseType } from 'utils/apiList/koliLost'

import {
  SLICE_NAME,
  fetchGetKoliLost,
  fetchGetKoliLostDetail,
  putKoliFound,
} from './koliLostAsyncThunk'

export type KoliLostSliceType = {
  isLoading: boolean
  pagination: {
    pageSize: number
    pageIndex: number
    numberOfElements: number
  }
  data: GetKoliLostResponseType['data']
  detail: {
    isShow: boolean
    list: GetKoliLostDetailResponseType['data']
    soID: number
  }
  listRefetch: number
}

const initialState: KoliLostSliceType = {
  isLoading: false,
  pagination: {
    pageSize: 20,
    pageIndex: 1,
    numberOfElements: 0,
  },
  data: [],
  detail: {
    isShow: false,
    list: [],
    soID: 0,
  },
  listRefetch: 0,
}

const koliLostSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setData: (
      state,
      action: PayloadAction<{
        data: KoliLostSliceType['data']
        pagination: KoliLostSliceType['pagination']
      }>,
    ) => {
      state.data = action.payload.data
      state.pagination = action.payload.pagination
    },
    setDetail: (state, action: PayloadAction<KoliLostSliceType['detail']['list']>) => {
      state.detail.list = action.payload
    },
    setIsShowDetail: (state, action: PayloadAction<boolean>) => {
      state.detail.isShow = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetKoliLost.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchGetKoliLost.fulfilled, (state, action) => {
        state.isLoading = false
        state.data = action.payload.data
        state.pagination = action.payload.pagination
      })
      .addCase(fetchGetKoliLostDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchGetKoliLostDetail.fulfilled, (state, action) => {
        state.isLoading = false
        state.detail.soID = action.payload.soID
        state.detail.list = action.payload.data
      })
      .addCase(putKoliFound.pending, (state) => {
        state.isLoading = true
      })
      .addCase(putKoliFound.fulfilled, (state) => {
        state.listRefetch += 1
        state.isLoading = false
        state.detail = initialState.detail
      })
      .addCase(putKoliFound.rejected, (state) => {
        state.isLoading = false
        state.detail = initialState.detail
      })
  },
})

export const { reset, setLoading, setData, setDetail, setIsShowDetail } = koliLostSlice.actions
export default koliLostSlice.reducer
