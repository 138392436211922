import { createAsyncThunk } from '@reduxjs/toolkit'

import { callErrorMsg } from 'helpers/errorMsg'

import { postImage } from 'utils/apiList/images'
import {
  ProductImageType,
  AddEditAssetParamInterface,
} from 'features/AssetManagement/@types/typeAddEditAsset'

import { toastSuccess, toastFailed } from 'utils/toast'

import {
  getUomsAPI,
  getProductAssetByIDAPI,
  addNewProductAPI,
  postEditProductAPI,
} from 'features/AssetManagement/services/productList'

import {
  setIsLoading,
  setAssetListUoms,
  setProductData,
  resetStateSendData,
  setErrorForm,
  resetErrorForm,
} from './slice'

const SLICE_NAME = 'addEditAsset'

type ErrorAxiosType = {
  response: {
    data: {
      error: {
        msg: string
      }
    }
  }
}

const uploadProductImage = async (productImages: ProductImageType[], userId: number) => {
  const listImageIndexToUpload: number[] = []
  const productImageUrls: {
    urlImage: string
  }[] = []
  productImages.forEach((image, index) => {
    if (image.file) {
      listImageIndexToUpload.push(index)
    }
    productImageUrls.push({
      urlImage: image.previewUrl,
    })
  })

  const uploadPromises = listImageIndexToUpload.map(async (index) => {
    try {
      if (productImages[index].file !== null) {
        const {
          data: { data },
        } = await postImage('product-images', {
          file_input: productImages[index].file as File,
          created_by: userId,
        })

        data.images.forEach((imageItem) => {
          if (imageItem.type === 'medium') {
            productImageUrls[index].urlImage = imageItem.imageUrl
          }
        })
      }
    } catch (err) {
      const errorMessage =
        (err as ErrorAxiosType).response.data?.error?.msg || 'Failed to upload image'
      throw new Error(`Failed to upload image at index ${index + 1}: Reason: ${errorMessage}`)
    }
  })

  await Promise.all(uploadPromises)
  return { productImageUrls }
}

function checkErrorField(sendData: AddEditAssetParamInterface): { [key: string]: boolean } {
  const errorFields: { [key: string]: boolean } = {}

  Object.keys(sendData).forEach((key) => {
    const typedKey = key as keyof AddEditAssetParamInterface
    const value = sendData[typedKey]

    if (typedKey === 'status' || typedKey === 'note' || typedKey === 'asset_images') {
      errorFields[key] = false
      return
    }

    errorFields[key] =
      value === null ||
      value === undefined ||
      value === '' ||
      value === 0 ||
      (Array.isArray(value) && (value as unknown[]).length === 0)
  })

  return errorFields
}

export const getAllUoms = createAsyncThunk(
  `${SLICE_NAME}/getUoms`,
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await getUomsAPI({ pageSize: 30, groups: 'ITEM' })
      dispatch(setAssetListUoms(response.data.data.unit_of_measurements.content))

      return true
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const getProductAssetByID = createAsyncThunk(
  `${SLICE_NAME}/fetchProductAssetByID`,
  async (id: number, { rejectWithValue, dispatch }) => {
    dispatch(setIsLoading(true))
    try {
      const response = await getProductAssetByIDAPI(id)

      const responseProduct = response.data

      const tempProduct = {
        name: responseProduct.name,
        sku_number: responseProduct.sku_number,
        uom: responseProduct.uom,
        type: responseProduct.type,
        weight: responseProduct.weight,
        length: responseProduct.length,
        width: responseProduct.width,
        height: responseProduct.height,
        status: responseProduct.status,
        note: responseProduct.note,
        asset_categories: responseProduct.asset_categories,
        asset_images: responseProduct.asset_images,
      }

      dispatch(setProductData(tempProduct))
      dispatch(setIsLoading(false))

      return response.data
    } catch (error) {
      dispatch(setIsLoading(false))
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const assetAddEditOnSubmitAdd = createAsyncThunk(
  `${SLICE_NAME}/assetAddEditOnSubmitAdd`,
  async (
    { assetId, callBackSuccess }: { assetId: number; callBackSuccess: () => void },
    { dispatch, getState },
  ) => {
    const {
      productAssetAddEdit: { sendData, productImages },
      auth: {
        userData: { id },
      },
    } = getState() as StoreStateType
    dispatch(setIsLoading(true))

    const errorFields = checkErrorField(sendData)
    let imageError = false
    if (
      productImages.every((image) => image.previewUrl === '') &&
      sendData.asset_images.length === 0
    ) {
      imageError = true
    } else {
      imageError = false
    }

    const isThereError = Object.values(errorFields).some((value) => value)

    if (isThereError || imageError) {
      dispatch(setErrorForm(errorFields))
      dispatch(setIsLoading(false))
      if (imageError) {
        toastFailed('Gambar Produk harus diisi')
      }
      if (isThereError) {
        toastFailed('Ada field yang belum diisi')
      }

      return
    }
    dispatch(resetErrorForm())

    try {
      const { productImageUrls } = await uploadProductImage(productImages, id)
      const imagePayload = productImageUrls
        .map((image, index) => ({
          url: image.urlImage,
          is_primary: index === 0,
        }))
        .filter((image) => image.url !== '')

      const newSendData = {
        ...(sendData as AddEditAssetParamInterface),
        asset_images: imagePayload,
      }

      if (assetId) {
        await postEditProductAPI(newSendData, assetId)
        toastSuccess('Produk berhasil dirubah.')
      } else {
        await addNewProductAPI(newSendData)
        toastSuccess('Produk berhasil ditambahkan dan sedang dalam review.')
      }
      dispatch(resetStateSendData())
      callBackSuccess()
    } catch (err) {
      callErrorMsg(err)
      toastFailed((err as Error).message)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)
