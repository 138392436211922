import { MasterVariant as Variant } from 'features/Product/@types'

export const SLICE_NAME = 'masterVariant'
export const getVariantForm = () => ({ name: '' })
export const initialState: Variant.InitialStateType = {
  isLoading: false,
  query: {
    search: '',
    pageIndex: 1,
    pageSize: 10,
  },
  masterVariantList: [],
  modalState: 'CLOSE',
  selectedVariantId: null,
  form: {
    name: '',
    variants: [getVariantForm()],
  },
}
