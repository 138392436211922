import { Button } from 'components'
import styled from 'styled-components'

const S = {
  ButtonWrapper: styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    gap: 8px;
  `,

  ButtonCancel: styled(Button)`
    background: white;
    color: #246ee5;
    border: 1px solid #246ee5;
  `,

  ModalTitle: styled.span`
    color: #cc4339;
  `,
}

export default S
