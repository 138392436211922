import { memo, useMemo, useState } from 'react'
import styled from 'styled-components'
import { Checkbox, TextBody, SystemIcon, Radio } from 'components'

function Category({ data, handleCheck, radio, index, hideParentCheck }) {
  const [isExpand, setSsExpand] = useState(false)
  const isShowCheckAction = useMemo(
    () => !hideParentCheck || (hideParentCheck && !data.childs.length),
    [hideParentCheck, data.childs],
  )

  return (
    <div>
      <Option
        onClick={() => {
          setSsExpand(!isExpand)
        }}
      >
        <WrapperOptionParent>
          {isShowCheckAction && (
            <>
              {radio ? (
                <Radio
                  checked={data.checked}
                  onChange={() => {
                    handleCheck('parent', data)
                  }}
                  disabled={data.childs.length > 0 || data.checked}
                />
              ) : (
                <Checkbox
                  checked={data.checked}
                  onChange={() => {
                    handleCheck('parent', data)
                  }}
                  disabled={data.childs.length > 0}
                />
              )}
            </>
          )}
          <TextBody size="bigger" weight="lightest" color="secondaryText" className="dropdown-name">
            {data.name}
          </TextBody>
          {data.childs.length > 0 && (
            <IconWrapper isExpand={isExpand}>
              <SystemIcon iconName="arrowv2" />
            </IconWrapper>
          )}
        </WrapperOptionParent>
        {data.subName && (
          <TextBody
            size="bigger"
            weight="lightest"
            color="secondaryText"
            className="dropdown-subName"
          >
            {data.subName}
          </TextBody>
        )}
      </Option>
      <ChildsContainer isExpand={isExpand} length={data.childs.length}>
        {data.childs.map((el, idx) => (
          <OptionChild key={idx}>
            <WrapperOptionParent>
              {radio ? (
                <Radio
                  checked={el.checked}
                  onChange={() => handleCheck('child', data, el)}
                  disabled={el.checked}
                />
              ) : (
                <Checkbox checked={el.checked} onChange={() => handleCheck('child', data, el)} />
              )}
              <TextBody
                size="bigger"
                weight="lightest"
                color="secondaryText"
                className="dropdown-name"
              >
                {el.name}
              </TextBody>
            </WrapperOptionParent>
          </OptionChild>
        ))}
      </ChildsContainer>
    </div>
  )
}

Category.defaultProps = {
  handleCheck: () => {},
  radio: false,
}

export default memo(Category)

const Option = styled.div`
  cursor: pointer;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  :hover {
    background-color: ${({ theme: { colors } }) => colors.body};
  }
`

const OptionChild = styled.div`
  cursor: pointer;
  padding: 12px 16px;
  padding-left: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  :hover {
    background-color: ${({ theme: { colors } }) => colors.body};
  }
`

const WrapperOptionParent = styled.div`
  display: flex;
  align-items: center;
  p {
    margin-left: 10px;
  }
`

const IconWrapper = styled.div`
  margin-left: 5px;
  transition: all ease 0.3s;
  transform: ${({ isExpand }) => (isExpand ? 'rotate(0deg)' : 'rotate(180deg)')};
`

const ChildsContainer = styled.div`
  height: ${({ isExpand, length }) => (isExpand ? `${length * 49}px` : '0px')};
  overflow: hidden;
  transition: all ease 0.3s;
`
