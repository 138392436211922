export const SET_IS_LOADING_HUB = 'SET_IS_LOADING_HUB/hub-page'
export const SET_PRODUCTS_CAN_ADD = 'SET_PRODUCTS_CAN_ADD/hub-page'
export const SET_ID_PRODUCT_SELECTED = 'SET_ID_PRODUCT_SELECTED/hub-page'
export const SET_HUBS = 'SET_HUBS/hub-page'
export const SET_SELECTED_HUB = 'SET_SELECTED_HUB/hub-page'
export const SET_CHECK_ALL = 'SET_CHECK_ALL/hub-page'
export const SET_INVENTORIES = 'SET_INVENTORIES/hub-page'
export const SET_DATA_PAGINATION_HUB = 'SET_DATA_PAGINATION_HUB/hub-page'
export const SET_LAST_QUERY_HUB = 'SET_LAST_QUERY_HUB/hub-page'
export const SET_SELECTED_HUB_FILTER = 'SET_SELECTED_HUB_FILTER/hub-page'
export const SET_RAK_OPTIONS_HUB = 'SET_RAK_OPTIONS_HUB/hub-page'
export const SET_QUERY_PARAMS_INVENTORY = 'SET_QUERY_PARAMS_INVENTORY/hub-page'
export const SET_DATA_PAGINATION_INVENTORY = 'SET_DATA_PAGINATION_INVENTORY/hub-page'
