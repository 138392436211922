import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

type OriginDestinationSkuType = {
  product_id: number
  product_name: string
  product_sku: string
  product_uom: string
  quantity: number
}

export type GetConversionListResponseType = {
  id: number
  rule_name: string
  type: string
  product_origins: OriginDestinationSkuType[]
  product_destinations: OriginDestinationSkuType[]
}

export type GetConversionListRequestType = {
  sku_origin?: string
  sku_destination?: string
  page_index: number
  page_size: number
}

export type PostSaveConversionRuleRequestType = {
  rule_name: string
  type: string
  product_origins: {
    product_id: number
    quantity: number
  }[]
  product_destinations: {
    product_id: number
    quantity: number
  }[]
}

export const getConversionList = (params: GetConversionListRequestType) =>
  axiosInstanceApiGateway.Get<GetConversionListResponseType[]>({
    url: '/ims/internal/v1/manufacturing-rule',
    params,
  })

export const postSaveConversionRule = (data: PostSaveConversionRuleRequestType) =>
  axiosInstanceApiGateway.Post<{ message: string }>({
    url: '/ims/internal/v1/manufacturing-rule',
    data,
  })

export const putUpdateConversionRule = (
  id: number,
  data: Omit<PostSaveConversionRuleRequestType, 'type'>,
) =>
  axiosInstanceApiGateway.Put<{ message: string }>({
    url: `/ims/internal/v1/manufacturing-rule/${id}`,
    data,
  })
