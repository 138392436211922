import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const AssetMasterDetailPageRBACPage = {
  pageID: 'bf71dc5c37fe29c51959a50d53baec28',
  elementID: {
    EditDropButton: '22e2167d3bd414dfad9d14d6380fe2fc',
    PrintQR: '8bfdb9fe2ebb5a579aaf880e5212c1f7',
    UpdateAsset: '9e715a0595166869c0220d8e8ef83f67',
  },
} as const

export const useAssetMasterDetailPageRBACPage = createUseRBAC(
  AssetMasterDetailPageRBACPage.elementID,
)

export type UseAssetMasterDetailPageRBACPageType = ReturnType<
  typeof useAssetMasterDetailPageRBACPage
>
