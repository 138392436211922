import { push as pushReduxAction, replace as replaceReduxAction } from 'connected-react-router'
import UrlPattern from 'url-pattern'

const filterQuery = <T extends Record<string, string | number>>(query: T) =>
  Object.entries(query).reduce((accum, current) => {
    const [key, value] = current
    if (value) {
      return {
        ...accum,
        [key]: value,
      }
    }
    return accum
  }, {})

const urlAndParamsGenerator = (
  url: string,
  urlParams: Record<string, string | number | undefined> = {},
) => new UrlPattern(url).stringify({ ...urlParams })

export const push = <T extends Record<string, string | number>>({
  url,
  queryObject,
  paramsUrl,
}: {
  url: string
  queryObject?: T
  paramsUrl?: Record<string, string | number | undefined>
}) =>
  pushReduxAction({
    pathname: urlAndParamsGenerator(url, paramsUrl),
    search: queryObject ? new URLSearchParams(filterQuery(queryObject)).toString() : '',
  })

export const replace = <T extends Record<string, string | number>>({
  url,
  queryObject,
  paramsUrl,
}: {
  url: string
  queryObject?: T
  paramsUrl?: Record<string, string | number | undefined>
}) =>
  replaceReduxAction({
    pathname: urlAndParamsGenerator(url, paramsUrl),
    search: queryObject ? new URLSearchParams(filterQuery(queryObject)).toString() : '',
  })

export const getQueryParamsStringToObject = <T = Record<string, string>>(
  searchQueryString?: string,
) => Object.fromEntries(new URLSearchParams(searchQueryString || '')) as unknown as T

export const getQueryParamsObjectToString = (params: Record<string, string | number>) =>
  new URLSearchParams(filterQuery(params)).toString()
