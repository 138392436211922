import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getUVDetailById,
  getUVBenefitType,
  getUVTargetType,
  getUVMechanismType,
  PostUVPromoRequestType,
  postUVPromo,
  PutUVPromoRequestType,
  putUVPromo,
} from 'utils/apiList/uniqueVoucher'
import { callErrorMsg } from 'helpers/errorMsg'
import { toastSuccess } from 'utils/toast'

const SLICE_NAME = 'UniqueVoucherPromoAddAndEdit'

export const fetchUVDetailById = createAsyncThunk(
  `${SLICE_NAME}/GetDetailById`,
  async (payload: { id: string }, { rejectWithValue }) => {
    try {
      const res = await getUVDetailById(payload)

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchUVBenefitType = createAsyncThunk(
  `${SLICE_NAME}/GetBenefitType`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getUVBenefitType()

      return res.data
    } catch (err) {
      callErrorMsg(err)

      return rejectWithValue(err)
    }
  },
)

export const fetchUVTargetType = createAsyncThunk(
  `${SLICE_NAME}/GetTargetType`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getUVTargetType()
      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchUVMechanismType = createAsyncThunk(
  `${SLICE_NAME}/GetMechanismType`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getUVMechanismType()

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const createUVPromo = createAsyncThunk(
  `${SLICE_NAME}/CreateUVPromo`,
  async (
    { payload, goBack }: { payload: PostUVPromoRequestType; goBack: () => void },
    { rejectWithValue },
  ) => {
    try {
      const res = await postUVPromo(payload)
      toastSuccess('data berhasil di buat')
      goBack()
      return res.data
    } catch (err) {
      callErrorMsg(err)

      return rejectWithValue(err)
    }
  },
)

export const updateUVPromo = createAsyncThunk(
  `${SLICE_NAME}/UpdateUVPromo`,
  async (
    { payload, goBack }: { payload: PutUVPromoRequestType; goBack: () => void },
    { rejectWithValue },
  ) => {
    try {
      const res = await putUVPromo(payload)
      toastSuccess('data berhasil di update')
      goBack()
      return res.data
    } catch (err) {
      callErrorMsg(err)

      return rejectWithValue(err)
    }
  },
)
