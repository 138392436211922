import { memo, useState, useEffect } from 'react'
import styled from 'styled-components'
import { TextBody, SystemIcon } from 'components'
function PopupTemplate({
  withHeader,
  isShow,
  handleClose,
  title,
  titleColor,
  headerComponent,
  children,
  className,
  classNameMain,
  minWidth,
  maxWidthDialog,
}) {
  const [isHide, setIsHide] = useState(isShow)
  const [animation, setAnimation] = useState(false)
  useEffect(() => {
    if (isShow) {
      setIsHide(isShow)
      setTimeout(() => {
        setAnimation(isShow)
      }, 100)
    } else {
      setAnimation(isShow)
      setTimeout(() => {
        setIsHide(isShow)
      }, 300)
    }
  }, [isShow])
  if (!isHide) {
    return <span />
  }

  return (
    <Container isShow={animation} className={className}>
      <Content className="content-popup">
        <Main minWidth={minWidth} className={classNameMain} maxWidthDialog={maxWidthDialog}>
          {withHeader && (
            <Headers className="header-popup">
              <TextBody size="header" color={titleColor}>
                {title}
              </TextBody>
              {headerComponent}
              <IconWrapper className="closed-popup" onClick={() => handleClose()}>
                <SystemIcon iconName="plus" fontSize="headerBigger" colorIcon="text" />
              </IconWrapper>
            </Headers>
          )}

          <Body>{children}</Body>
        </Main>
      </Content>
    </Container>
  )
}

PopupTemplate.defaultProps = {
  isShow: false,
  title: 'Default title',
  handleClose: () => {},
  withHeader: true,
  minWidth: '200px',
  maxWidthDialog: '',
}

export default memo(PopupTemplate)

const Body = styled.div`
  padding: 16px 32px;
`

const IconWrapper = styled.div`
  transform: rotate(45deg);
  cursor: pointer;
`

const Headers = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eff3f9;
  padding: 16px 32px;
`

const Main = styled.div`
  margin: 0 auto;
  min-width: ${({ minWidth }) => minWidth};
  min-height: 200px;
  background-color: ${({ theme: { colors } }) => colors.white};
  width: fit-content;
  height: fit-content;
  border-radius: 8px;
  max-width: ${({ maxWidthDialog }) => maxWidthDialog};
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme: { colors } }) => colors.blackFaded};
  overflow: scroll;
  padding-top: 70px;
  padding-bottom: 50px;
`

const Container = styled.div`
  transition: all ease 0.2s;
  position: fixed;
  height: calc(Svar(--vh, 1vh) * 100);
  width: 100vw;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  display: grid;
  place-items: center;
  margin: auto;
  transform: ${({ isShow }) => (isShow ? 'scale(1)' : 'scale(0)')};
  opacity: ${({ isShow }) => (isShow ? '1' : '0')};
`
