import { AppDispatchType, RootStateType } from 'store'
import TypeTemplateList from 'helpers/typeTemplateList'
import { setTypeTobePrinted } from 'redux/printArea'
import { getCleanQueryObj } from 'utils/queryParamsURL'
import {
  getPriceTagList,
  singlePrintPriceTag,
  bulkPrintPriceTag,
  PriceTagItemType,
} from 'features/Product/services/priceTag'
import { GetLocationListType } from 'features/Location/@types/location'
import { getLocationList } from 'features/Location/services/location'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  setIsLoading,
  setPriceTagData,
  setLocationList,
  setQuery,
  setIsInitFirstTimeFinish,
  setSelectedLocation,
  setPriceTagListToPrint,
} from './slice'

export const actFetchPriceTagList =
  () => async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { query } = getState().priceTag
    dispatch(setIsLoading(true))

    const paramsData = getCleanQueryObj(query)

    try {
      const {
        data: { data },
      } = await getPriceTagList(paramsData)

      dispatch(
        setPriceTagData({
          data,
          pagination: {
            page_index: query?.page_index || 0,
            number_of_elements: data?.length,
            page_size: query.page_size,
          },
        }),
      )
    } catch (err) {
      callErrorMsg(err)
    } finally {
      dispatch(setIsLoading(false))
    }
  }

export const actSuccessCallbackOnPrintPriceTag =
  (data: PriceTagItemType[]) => (dispatch: AppDispatchType) => {
    dispatch(setPriceTagListToPrint(data))
    dispatch(setTypeTobePrinted(TypeTemplateList.PriceTagPrint))
    setTimeout(() => {
      window.print()
    }, 100)
  }

export const actPrintSinglePriceTag =
  (locationId: number, idToPrint: string) => async (dispatch: AppDispatchType) => {
    dispatch(setIsLoading(true))
    try {
      const {
        data: { data },
      } = await singlePrintPriceTag({
        location_id: locationId,
        ids: idToPrint,
      })
      dispatch(actSuccessCallbackOnPrintPriceTag(data))
    } catch (err) {
      callErrorMsg(err)
    } finally {
      dispatch(setIsLoading(false))
    }
  }

export const actPrintBulkPriceTag =
  (locationId: number, idToPrint: number[]) => async (dispatch: AppDispatchType) => {
    dispatch(setIsLoading(true))
    let idQuery = ''
    idToPrint.forEach((id) => {
      idQuery = idQuery.concat(`&ids=${id}`)
    })

    const params = `?location_id=${locationId}${idQuery}`
    try {
      const {
        data: { data },
      } = await bulkPrintPriceTag(params)
      dispatch(actSuccessCallbackOnPrintPriceTag(data))
    } catch (err) {
      callErrorMsg(err)
    } finally {
      dispatch(setIsLoading(false))
    }
  }

type LocationItemType = GetLocationListType['content'][number]

const setQueryAndSelectedLocation =
  (locationItem: LocationItemType) => (dispatch: AppDispatchType) => {
    dispatch(setQuery({ location_id: locationItem.location_id }))
    dispatch(setSelectedLocation(locationItem))
    dispatch(setIsInitFirstTimeFinish(true))
  }

export const actFetchLocationList =
  () => async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { location_roles, isSuperAdmin } = getState().auth.userData

    const paramsLocation = {
      active: true,
      type: 'ASTRO OFFLINE',
    }

    if (!isSuperAdmin) {
      const listLocation = location_roles
        .map((item) => ({
          location_id: item.location_id,
          location_name: item.location_name,
          location_type: item.location_type,
        }))
        ?.filter(
          (result) => (result.location_type as string) === 'ASTRO OFFLINE',
        ) as unknown as LocationItemType[]

      if (listLocation?.length) {
        dispatch(setLocationList(listLocation))
        dispatch(setQueryAndSelectedLocation(listLocation[0]))
      }
      return
    }

    try {
      const { data } = await getLocationList({
        params: paramsLocation,
      })

      dispatch(setLocationList(data.content))
      dispatch(setQueryAndSelectedLocation(data.content[0]))
    } catch (err) {
      callErrorMsg(err)
    }
  }
