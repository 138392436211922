import { memo, useContext } from "react";
import styled, { css } from "styled-components";
import storeContext from "storeContext";
import { TextBody } from "components";
import logo from "assets/images/logo-black.png";
import goodReceipt from "assets/images/receipt-note.png";
import { FooterTTD } from "./component";

import tableConfig from "./table.config";

function Row({ data, lastRow, isOld }) {
  return (
    <TrRow>
      <Td ratio={tableConfig.ratio.noSKU} ls lastRow={lastRow}>
        <Wrapper>
          <TextBody weight="light" color="textSoft">
            {data.product_sku}
          </TextBody>
        </Wrapper>
      </Td>
      <Td ratio={tableConfig.ratio.Description} lastRow={lastRow}>
        <Wrapper>
          <TextBody weight="light" color="textSoft">
            {data.product_name}
          </TextBody>
        </Wrapper>
      </Td>
      <Td ratio={tableConfig.ratio.orderQTY} lastRow={lastRow}>
        <Wrapper cntr>
          <TextBody weight="light" color="textSoft">
            {data.product_request_quantity}
          </TextBody>
        </Wrapper>
      </Td>
      <Td ratio={tableConfig.ratio.orderReceived} lastRow={lastRow}>
        <Wrapper cntr>
          <TextBody weight="light" color="textSoft">
            {data.product_actual_quantity}
          </TextBody>
        </Wrapper>
      </Td>
      {!isOld && (
        <Td ratio={tableConfig.ratio.difference} lastRow={lastRow}>
          <Wrapper cntr>
            <TextBody weight="light" color="textSoft">
              {data.product_discrepancy}
            </TextBody>
          </Wrapper>
        </Td>
      )}

      <Td ratio={tableConfig.ratio.uom} lastRow={lastRow}>
        <Wrapper cntr>
          <TextBody weight="light" color="textSoft">
            {data.product_uom}
          </TextBody>
        </Wrapper>
      </Td>
      {!isOld && (
        <Td ratio={tableConfig.ratio.dateExp} lastRow={lastRow}>
          <Wrapper cntr>
            <TextBody weight="light" color="textSoft">
              {data.expiry_date}
            </TextBody>
          </Wrapper>
        </Td>
      )}
      {!isOld && (
        <Td ratio={tableConfig.ratio.status} lastRow={lastRow}>
          <Wrapper cntr>
            <TextBody weight="light" color="textSoft">
              {data.status_name}
            </TextBody>
          </Wrapper>
        </Td>
      )}
      <Td ratio={tableConfig.ratio.note} lastr lastRow={lastRow}>
        <Wrapper cntr>
          <TextBody weight="light" color="textSoft">
            {data.status_notes_name}
          </TextBody>
        </Wrapper>
      </Td>
    </TrRow>
  );
}

function Table({ data, oldData, isOld }) {
  const totalLength = data.length;
  const totalLengthOld = oldData.length;

  const renderRow = () => {
    if (isOld)
      return oldData?.map((el, idx) => (
        <Row
          key={idx}
          data={el}
          isOld={isOld}
          lastRow={totalLengthOld - 1 === idx}
        />
      ));

    return data?.map((el, idx) => (
      <Row
        key={idx}
        data={el}
        isOld={isOld}
        lastRow={totalLength - 1 === idx}
      />
    ));
  };
  return (
    <ContainerTable cellSpacing={0}>
      <thead>
        <Tr color="tableHead">
          <Th ratio={tableConfig.ratio.noSKU}>
            <Wrapper>NO. SKU</Wrapper>
          </Th>

          <Th ratio={tableConfig.ratio.Description}>
            <Wrapper>DESKRIPSI</Wrapper>
          </Th>
          <Th ratio={tableConfig.ratio.orderQTY}>
            <Wrapper>JML PSN</Wrapper>
          </Th>
          <Th ratio={tableConfig.ratio.orderReceived}>
            <Wrapper>JML DTRM</Wrapper>
          </Th>
          {!isOld && (
            <Th ratio={tableConfig.ratio.difference}>
              <Wrapper>SELISIH</Wrapper>
            </Th>
          )}
          <Th ratio={tableConfig.ratio.uom}>
            <Wrapper>UOM</Wrapper>
          </Th>
          {!isOld && (
            <Th ratio={tableConfig.ratio.dateExp}>
              <Wrapper>TANGGAL EXPIRED</Wrapper>
            </Th>
          )}
          {!isOld && (
            <Th ratio={tableConfig.ratio.status}>
              <Wrapper>STATUS</Wrapper>
            </Th>
          )}
          <Th ratio={tableConfig.ratio.note} ls>
            <Wrapper>CATATAN</Wrapper>
          </Th>
        </Tr>
      </thead>
      <tbody>{renderRow()}</tbody>
      <tfoot></tfoot>
    </ContainerTable>
  );
}

function PrintReceipt() {
  const {
    state: { dataPrint }
  } = useContext(storeContext.InboundContext);
  return (
    <Container>
      <Content bt>
        <SpaceBetween>
          <S.ImageContainer>
            <img alt="astro" src={logo} />
          </S.ImageContainer>
          <S.ImageContainer>
            <img
              style={{
                width: "298px",
                marginLeft: "auto",
                marginBottom: "8px"
              }}
              alt="astro"
              src={goodReceipt}
            />
            <TextBody className="ml-auto" size="bigger" color="black">
              GRN No : {dataPrint?.grn_number}
            </TextBody>
          </S.ImageContainer>
        </SpaceBetween>
      </Content>
      <Content>
        <InfoWrapper>
          <Info>
            <Field>Vendor</Field>
            <Value>{dataPrint.vendor_name}</Value>
          </Info>
          <Info>
            <Field>GRN Date</Field>
            <Value>{dataPrint.grn_date}</Value>
          </Info>
        </InfoWrapper>
        <InfoWrapper>
          <Info>
            <Field>PO Date</Field>
            <Value>{dataPrint.created_at}</Value>
          </Info>
          <Info>
            <Field>Di GRN Oleh</Field>
            <Value>{dataPrint.received_by}</Value>
          </Info>
        </InfoWrapper>
        <InfoWrapper>
          <Info>
            <Field>No. PO</Field>
            <Value>{dataPrint.purchase_order_number}</Value>
          </Info>
          <Info>
            <Field>WH / Hub</Field>
            <Value>{dataPrint.destination_name}</Value>
          </Info>
        </InfoWrapper>
      </Content>
      <Table
        data={dataPrint.purchase_order_item_list}
        oldData={dataPrint.purchase_order_item_list_old}
        isOld={dataPrint.is_old_po}
      />
      <FooterTTD data={dataPrint} />
    </Container>
  );
}

export default memo(PrintReceipt);

const S = {};

const Container = styled.div`
  width: 100vw;
  background: white;
  -webkit-print-color-adjust: exact !important;
`;

const ContainerTable = styled.table`
  width: 100%;
  background-color: #ffff;
  border: 1px solid #d6dfeb;
  table-layout: fixed;
  border-radius: 8px;
  padding: 2px;
`;

const Content = styled.div`
  padding: 20px;
  ${({ bt }) =>
    bt &&
    css`
      border: 1px solid #bfc9d9;
    `}
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InfoWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

const Info = styled.div`
  display: flex;
  flex: 1;
`;

const Field = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: #212121;
  min-width: 100px;
  position: relative;
  margin-right: 5px;
  ::after {
    content: ":";
    position: absolute;
    right: 0;
  }
`;

const Value = styled.div`
  font-size: 14px;
  color: #212121;
`;

const Tr = styled.tr`
  // display: flex;
  // padding: 16px 32px;
  background-color: #f3f4f5;
  width: 100vw;
`;

const TrRow = styled.tr`
  // display: flex;
  // border: 1px solid #f3f4f5;
  // background-color: #ffffff;
  background-color: transparent;
  border-left: 1px solid #d6dfeb;
  border-right: 1px solid #d6dfeb;
  width: 100%;
  ${({ ls }) => !ls && `border-bottom: 1px solid #d6dfeb;`}
`;

const Th = styled.th`
  // color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  // flex: ${({ ratio }) => ratio};
  color: #212121;
  // display: flex;
  ${({ ls }) => !ls && `border-right: 1px solid #d6dfeb;`}
  // border-right: 1px solid #d6dfeb;
  border-bottom: 1px solid #d6dfeb;
  width: ${({ ratio }) => ratio}%;
  div {
    text-align: center;
  }
`;

const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  // flex: ${({ ratio }) => ratio} 1 0%;
  width: ${({ ratio }) => ratio}%;
  text-align: start;
  // ${({ ls }) => ls && `border-left: 1px solid #d6dfeb;`}
  ${({ lastRow }) => !lastRow && `border-bottom: 1px solid #d6dfeb;`}
  ${({ lastr }) => !lastr && `border-right: 1px solid #d6dfeb;`}
`;

const Wrapper = styled.div`
  padding: 16px 16px;
  text-align: start;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  ${({ cntr }) => cntr && " text-align:center;"}
`;

S.ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
