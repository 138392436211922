import { ThemeOptions } from '@mui/material'

import colors from '../fondations/colors'
import fonts from '../fondations/fonts'

const variant = {
  white: {
    backgroundColor: colors.white,
    color: colors.blue6,
    border: `1px solid ${colors.blue6}`,
  },
}

export const MuiButtonCustom: ThemeOptions['components'] = {
  MuiButton: {
    defaultProps: {
      variant: 'contained',
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
        fontFamily: fonts.fontFamily.nunitoSans,
        fontWeight: 700,
        borderRadius: '8px',
      },
      startIcon: {
        marginBottom: '1.78px',
      },
      endIcon: {
        marginBottom: '1.78px',
      },
    },
    variants: [
      {
        props: { variant: 'white' },
        style: variant.white,
      },
    ],
  },
}
