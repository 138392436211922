import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@mui/material'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'

export type MuiTooltipPropsType = MuiTooltipProps & SpaceProps

const Tooltip = styled(MuiTooltip)<MuiTooltipPropsType>`
  ${space}
`

export default Tooltip
