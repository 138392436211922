import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const CreateOrderRolesRBAC = {
  pageID: 'e7a9d7b4b86371110575ad2e1bdff86b',
  elementID: {
    ViewPageBuatPesanan: '1a00c6cb5c535219f32392dac42dde9f',
    CreateOrderBuatPesanan: 'a1ad35c99e2fd3b896e0f27923f76bdd',
  },
} as const

export const useRBACCreateOrderPage = createUseRBAC(CreateOrderRolesRBAC.elementID)

export type UseRBACCreateOrderPageType = ReturnType<typeof useRBACCreateOrderPage>
