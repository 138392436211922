import { Get, Post, Put, Patch } from 'config/apiServiceConfig'

export type QueryRuleParamsType = {
  sort?: string
  direction?: string
  pageSize: number
  pageIndex: number
  totalElement?: number
  numberOfElements?: number
  totalPages?: number
}

export type QueryRuleType = {
  id?: number
  name: string
  identifier: string
  status: 'ACTIVE' | 'INACTIVE'
  description: string
}

export type QueryRuleResponseType = {
  data: {
    queryIdentifiers: QueryRuleType[]
  }
  pagination: QueryRuleParamsType
}

export type CreateResponseType = {
  data: QueryRuleType
}

export const getWatchTowerQueryRuleList = (params: QueryRuleParamsType) =>
  Get<QueryRuleResponseType>({
    url: '/fraud/watchtower/query',
    params,
  })

export const postWatchTowerQueryRule = (data: QueryRuleType) =>
  Post<CreateResponseType>({
    url: '/fraud/watchtower/query',
    data,
  })

export const putWatchTowerQueryRule = (data: QueryRuleType, id: number) =>
  Put<CreateResponseType>({
    url: `/fraud/watchtower/query/${id}`,
    data,
  })

export const patchWatchTowerQueryRule = (data: QueryRuleType, id: number) =>
  Patch<CreateResponseType>({
    url: `/fraud/watchtower/query/${id}`,
    data,
  })
