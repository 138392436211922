import styled from 'styled-components'
import { memo, useRef, useState } from 'react'
import icon_more from 'assets/images/icon_more.svg'
import listenClickOutside from 'customHooks/outSideClick'

const dummyData = [
  {
    name: 'Edit Campaign',
    value: 'as',
    function: (val) => {
      //console.log(val);
    },
  },
  {
    name: 'Hapus Campaign',
    value: 'dass',
    color: '#E25450',
    function: (val) => {
      //console.log(val);
    },
  },
]

function ButtonDropDown({ listOption, width, data, ...rest }) {
  const refOutside = useRef(null)

  //STATE HOOKS
  const [isShowListOption, setIsShowListOption] = useState(false)

  //FUNCTION HANDLER
  const handleClickShowListOption = () => {
    setIsShowListOption(true)
  }

  listenClickOutside(
    refOutside,
    () => {
      //handleCloseOptions();
      setIsShowListOption(false)
    },
    isShowListOption,
  )

  return (
    <Container ref={refOutside}>
      <ButtonContainer onClick={handleClickShowListOption} {...rest}>
        <ImgIcon src={icon_more} />
        {isShowListOption && (
          <ListContainer width={width}>
            {listOption.map((items, index) => {
              return (
                <Option
                  onClick={() => {
                    items.function(items.value, { data })
                  }}
                  colors={items.color ? items.color : ''}
                  key={index}
                >
                  {items.name}
                </Option>
              )
            })}
          </ListContainer>
        )}
      </ButtonContainer>
    </Container>
  )
}

ButtonDropDown.defaultProps = {
  listOption: [...dummyData],
  width: 'fit-content',
  data: {},
}

export default memo(ButtonDropDown)

const Container = styled.div`
  position: relative;
`

const ButtonContainer = styled.div`
  display: flex;
`
const ImgIcon = styled.img``

const ListContainer = styled.div`
  max-height: 200px;
  padding-bottom: 0px;
  z-index: 2;
  overflow: scroll;

  width: ${({ width }) => (width ? width : 'fit-content')};
  border: 1px solid #e4eaf3;
  min-width: 100%;
  border-radius: 6px;
  position: absolute;
  right: 0;
  top: 40px;
  background-color: ${({ theme: { colors } }) => colors.white};
  box-shadow: 0px 2px 16px 0px rgba(202, 211, 225, 0.4);
  -webkit-box-shadow: 0px 2px 16px 0px rgba(202, 211, 225, 0.4);
  -moz-box-shadow: 0px 2px 16px 0px rgba(202, 211, 225, 0.4);
  p {
    white-space: nowrap;
  }
`

const Option = styled.div`
  padding: 16px;
  cursor: pointer;
  :hover {
    background-color: ${({ theme: { colors } }) => colors.body};
  }
  border-bottom: 1px solid #f3f4f5;
  :last-child {
    border-bottom: 0px;
  }
  ${({ colors }) => (colors ? `color : ${colors};` : '')}}
`
