import { toastFailed, toastSuccess } from "utils/toast";
import {
  allOrderStart,
  allOrderStop,
  setDetailOrder,
  reloadAllOrderList
} from "./index";
import {
  getCustomerAddress_AllOrder,
  putCustomerAddress_AllOrder,
  getAllOrderDetail
} from "utils/apiList/allOrder";
import { SET_IS_DATA_EDIT_ADDRESS } from "storeContext/actionsType";

export const putEditAddress =
  (payload, localState) => async (dispatch, getState) => {
    dispatch(allOrderStart());
    const { customer_address_id, customer_id } = await getState().stateAllorder
      .detailData.order_customer_address;

    await putCustomerAddress_AllOrder(customer_id, customer_address_id, payload)
      .then((res) => {
        const { data } = res.data;
        toastSuccess(`data address ${data.fullName} berhasil di rubah`);

        localState.handleClose();
        dispatch(reloadAllOrderList());
        dispatch(allOrderStop());
      })
      .catch(() => {
        dispatch(allOrderStop());
        toastFailed("terjadi masalah, coba beberapa saat lagi");
      });
  };

export const setDataEditAddress = (payload) => {
  return {
    type: SET_IS_DATA_EDIT_ADDRESS,
    payload
  };
};

export const getEditAddress = (localState) => async (dispatch) => {
  dispatch(allOrderStart());
  let detailData = {};

  await getAllOrderDetail(localState.orderId).then(({ data }) => {
    detailData = data;
    dispatch(setDetailOrder(data));
  });

  const { customer_address_id, customer_id } =
    await detailData.order_customer_address;

  await getCustomerAddress_AllOrder(customer_id, customer_address_id)
    .then((res) => {
      const { data } = res.data;

      const filteredData = {
        ...data,
        description: data.description ? data.description : "",
        shippingInstruction: data.shippingInstruction
          ? data.shippingInstruction
          : ""
      };

      dispatch(setDataEditAddress(filteredData));

      localState.handleClick();

      dispatch(allOrderStop());
    })
    .catch(() => {
      toastFailed("terjadi masalah, coba beberapa saat lagi");
      dispatch(allOrderStop());
    });
};
