import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getUnloadingDetail,
  postScanKoliOnUnloading,
  postSubmitUnloading,
} from 'features/SupplyOrder/services/unloadingDetail'
import { postImage } from 'utils/apiList/images'
import { callErrorMsg } from 'helpers/errorMsg'
import { toastSuccess, toastFailed } from 'utils/toast'
import {
  SLICE_NAME,
  setIsLoading,
  setUnloadingDetail,
  setModalChronology,
  resetModalChronology,
} from './slice'

export const fetchUnloadingDetail = createAsyncThunk<void, number>(
  `${SLICE_NAME}/fetchUnloadingDetail`,
  async (id, { dispatch }) => {
    dispatch(setIsLoading(true))
    try {
      const {
        data: { data },
      } = await getUnloadingDetail(id)

      dispatch(setUnloadingDetail(data))
      dispatch(setIsLoading(false))
    } catch (err) {
      callErrorMsg(err)
      dispatch(setIsLoading(false))
    }
  },
)

export const validateAndScanKoli = createAsyncThunk<void, string>(
  `${SLICE_NAME}/validateScannedKoli`,
  async (koliToScan, { dispatch, getState }) => {
    const { unloadingDetail } = (getState() as StoreStateType).inboundUnloadingDetail

    let koliId = 0

    const isKoliFound = unloadingDetail?.items.some((item) =>
      item.kolis.some((koliItem) => {
        koliId = koliItem.id
        return koliItem.code.toLowerCase() === koliToScan.toLowerCase()
      }),
    )

    if (isKoliFound) {
      dispatch(scanKoliOnUnloading(koliId))
      return
    }
    toastFailed(`Koli ${koliToScan} tidak sesuai`)
  },
)

export const scanKoliOnUnloading = createAsyncThunk<void, number>(
  `${SLICE_NAME}/scanKoliOnUnloading`,
  async (koliId, { dispatch, getState }) => {
    const { soUnloadingId } = (getState() as StoreStateType).inboundUnloadingDetail
    const dataToSubmit = {
      supply_order_unloading_id: soUnloadingId,
      koli_ids: [koliId],
      device_type: 'WEB',
    }

    try {
      const {
        data: { data },
      } = await postScanKoliOnUnloading(dataToSubmit)
      toastSuccess(data.message)
      dispatch(fetchUnloadingDetail(soUnloadingId))
    } catch (err) {
      callErrorMsg(err)
    }
  },
)

type ReturnTypeOfUploadType = ReturnType<typeof postImage>

type CallbackFunctionType = () => void

export const uploadImageChronology = createAsyncThunk<void, CallbackFunctionType>(
  `${SLICE_NAME}/uploadImageChronology`,
  async (callbackFunction, { dispatch, getState }) => {
    try {
      const { modalChronology } = (getState() as StoreStateType).inboundUnloadingDetail
      const { userData } = (getState() as StoreStateType).auth

      const requests: ReturnTypeOfUploadType[] = []

      modalChronology.uploadedFile.forEach((fileItem) => {
        requests.push(
          postImage('unloading-incomplete', {
            file_input: fileItem,
            created_by: userData.id,
          }),
        )
      })

      const responses = await Promise.all(requests)
      const listUrlImage = responses.map((responseItem) => {
        const {
          data: { data },
        } = responseItem
        return data.images.find((imageItem) => imageItem.type === 'real')?.imageUrl
      })

      dispatch(setModalChronology({ evidenceImageUrls: listUrlImage }))
      dispatch(submitUnloadingDetail(callbackFunction))
    } catch (err) {
      callErrorMsg(err)
    }
  },
)

export const submitUnloadingDetail = createAsyncThunk<void, CallbackFunctionType>(
  `${SLICE_NAME}/submitUnloadingDetail`,
  async (callbackFunction, { dispatch, getState }) => {
    const { soUnloadingId, modalChronology } = (getState() as StoreStateType).inboundUnloadingDetail
    const dataToSubmit = {
      supply_order_unloading_id: soUnloadingId,
      chronology: modalChronology.chronology,
      evidence_image_urls: modalChronology.evidenceImageUrls,
      device_type: 'WEB',
    }
    try {
      const {
        data: { data },
      } = await postSubmitUnloading(dataToSubmit)
      dispatch(resetModalChronology())
      toastSuccess(data.message)
      if (callbackFunction) callbackFunction()
    } catch (err) {
      callErrorMsg(err)
    }
  },
)
