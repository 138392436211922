import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  //   GetCustomerFraudListRequestType,
  getCustomerFraudList,
  getFraudReasonsList,
  patchOrderFraud,
} from 'features/Fraud/services/userFraudStatus'

import { PatchOrderFraudRequestType } from 'features/Fraud/@types/userFraudStatus'

export const SLICE_NAME = 'userFraudStatus'

export const fetchCampaignTypeList = createAsyncThunk(
  `${SLICE_NAME}/getCustomerFraudStatus`,
  async (_, { rejectWithValue, getState }) => {
    const {
      userFraudStatus: { query },
    } = getState() as StoreStateType
    try {
      const res = await getCustomerFraudList({
        params: {
          page_index: query.pageIndex,
          page_size: query.pageSize,
          filter: query.filter,
          customer_id: query.customer_id,
        },
      })

      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchRejectOrderReasons = createAsyncThunk(
  `${SLICE_NAME}/fetchRejectOrderReasons`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getFraudReasonsList()

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const patchFraudStatus = createAsyncThunk(
  `${SLICE_NAME}/patchFraudStatus`,
  async ({ params }: PatchOrderFraudRequestType, { rejectWithValue }) => {
    try {
      const res = await patchOrderFraud({ params })

      const { error, data } = res.data

      return !!(!error?.status && data.customer_id === params.customer_id)
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
