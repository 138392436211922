import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import { toastSuccess } from 'utils/toast'
import {
  getExternalDriverRates,
  postExternalDriver,
  cancelExternalDriver,
  getDriverHistory,
  putContactCustomer,
  putContinueOrder,
  postExternalDriverV2,
  cancelExternalDriverV2,
} from 'utils/apiList/externalDriver'

const initialState = {
  rates: [],
  selectedRate: {},
  isLoading: false,
  cancelNote: '',
  externalDriverHistory: [],
}

const externalDriver = createSlice({
  name: 'externalDriver',
  initialState,
  reducers: {
    resetRate: () => initialState,
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setRates: (state, action) => {
      state.rates = action.payload.filter(
        (rate) => rate.service_code.toLowerCase() !== 'instantcar',
      )
    },
    setSelectedRate: (state, action) => {
      state.selectedRate = action.payload
    },
    setCancelNote: (state, action) => {
      state.cancelNote = action.payload
    },
    setExternalDriverHistory: (state, action) => {
      state.externalDriverHistory = action.payload
    },
  },
})

export const {
  resetRate,
  setSelectedRate,
  setRates,
  setLoading,
  setCancelNote,
  setExternalDriverHistory,
} = externalDriver.actions

export const fetchExternalDriverRates = createAsyncThunk(
  'externalDriver/fetchExternalDriverRates',
  async (payload, { dispatch }) => {
    const params = { locationId: payload.locationID }
    try {
      dispatch(setLoading(true))
      const response = await getExternalDriverRates(payload, params)
      dispatch(setRates(response.data.data))
      return response.data.data
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const callExternalDriver = createAsyncThunk(
  'externalDriver/callExternalDriver',
  async (payload, { dispatch }) => {
    const params = { locationId: payload.locationID }
    try {
      const { isMultiDropoff, ...request } = payload
      dispatch(setLoading(true))
      const response = !isMultiDropoff
        ? await postExternalDriver(request, params)
        : await postExternalDriverV2(request, params)
      return response.data.data
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const cancelExternalDriverOrder = createAsyncThunk(
  'externalDriver/cancelExternalDriverOrder',
  async (payload, { dispatch }) => {
    try {
      const { isMultiDropoff, ...request } = payload
      dispatch(setLoading(true))
      const response = !isMultiDropoff
        ? await cancelExternalDriver(request)
        : await cancelExternalDriverV2(request)
      return response.data.data
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const getExternalDriverHistory = createAsyncThunk(
  'externalDriver/getExternalDriverHistory',
  async (payload, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const response = await getDriverHistory(payload)
      dispatch(setExternalDriverHistory(response.data.data))
      return response.data.data
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const contactCustomer = createAsyncThunk(
  'externalDriver/contactCustomer',
  async (payload, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      await putContactCustomer(payload)
      return true
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const continueOrder = createAsyncThunk(
  'externalDriver/continueOrder',
  async (payload, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      await putContinueOrder(payload)
      toastSuccess(`Pesanan GrabMart ${payload} dilanjutkan`)
      return true
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export default externalDriver.reducer
