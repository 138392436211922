import { memo } from 'react'
import styled from 'styled-components'
import { RowDriver, SystemIcon, Tooltip, TextBody } from 'components'

function TableDriver({
  driversData,
  vendorsStaff,
  allowRejoinAction,
  handleChangeActiveRole,
  handlerClickEdit,
  handleChangeVerify,
  handleChangeActive,
}) {
  const renderRowDriver = () => {
    if (driversData === null) return ''

    return driversData.map((data, index) => (
      <RowDriver
        key={data.driver_card_number + 3}
        dataDriver={data}
        vendorsStaff={vendorsStaff}
        allowRejoinAction={allowRejoinAction}
        handleChangeActiveRole={handleChangeActiveRole}
        handlerClickEdit={handlerClickEdit}
        handleChangeActive={handleChangeActive}
        handleChangeVerify={handleChangeVerify}
      />
    ))
  }

  return (
    <Container>
      <Thead>
        <Tr>
          <Th width="200px">NAMA</Th>
          <Th width="120px">TRACKER ID</Th>
          <Th width="150px">NOMOR HP</Th>
          <Th width="120px">NOMOR PLAT</Th>
          <Th width="180px">
            HUB
            <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
          </Th>
          <Th width="200px">ROLE</Th>
          <Th width="120px">Vendor</Th>
          <Th width="250px">AKTIF ROLE</Th>
          <Th width="180px">
            <ThCenter>
              STATUS KARYAWAN
              <Tooltip
                tooltipsType="hover"
                content={
                  <TextBody weight="light" color="white">
                    Status driver masih bekerja atau tidak di ASTRO
                  </TextBody>
                }
                direction="left"
              >
                <SystemIcon iconName="information" className="ml-3" />
              </Tooltip>
            </ThCenter>
          </Th>
          <Th width="150px">
            <ThCenter>
              STATUS AKTIF
              <Tooltip
                tooltipsType="hover"
                content={
                  <TextBody weight="light" color="white">
                    Status di aplikasi driver
                  </TextBody>
                }
                direction="left"
              >
                <SystemIcon iconName="information" className="ml-3" />
              </Tooltip>
            </ThCenter>
          </Th>
          <Th width="120px">Aksi</Th>
        </Tr>
      </Thead>

      <Tbody>{renderRowDriver()}</Tbody>
    </Container>
  )
}

export default memo(TableDriver)

const Container = styled.table`
  width: 100%;
`

const Thead = styled.thead`
  display: block;
`
const Tbody = styled.tbody`
  display: block;
`

const Tr = styled.tr`
  display: flex;
  padding: 16px 32px;
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`

const ThCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
