import { lazy } from 'react'

import generateRouterDetail from 'utils/helpers/generateRouterDetail'

const LoyaltyWidget = lazy(() => import('../pages/LoyaltyWidget'))

const LoyaltyWidgetRouter: RouterFeatureInterface<
  'LoyaltyWidgetRouter',
  '/dashboard/loyalty-widget'
> = {
  name: 'LoyaltyWidgetRouter',
  Component: LoyaltyWidget,
  path: '/dashboard/loyalty-widget',
  routeType: 'PRIVATE',

  allowedRoles: ['AUTH_MARKETING', 'AUTH_PRICING'],
  isDashboard: true,
}

const LoyaltyRouter = [LoyaltyWidgetRouter]

export const LoyaltyRouterDetail = generateRouterDetail(LoyaltyRouter)

export default LoyaltyRouter
