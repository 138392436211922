import { createSlice } from '@reduxjs/toolkit'
import { GetLocationListType } from 'features/Location/@types/location'
import { GetPriceTagListParamsType, PriceTagItemType } from 'features/Product/services/priceTag'

export const SLICE_NAME = 'priceTag'

export interface PriceTagInitialStateType {
  isLoading: boolean
  isInitFirstTimeFinish: boolean
  query: GetPriceTagListParamsType
  selectedLocation: {
    location_id: number
    location_name: string
  }
  priceTagList: PriceTagItemType[]
  priceTagListToPrint: PriceTagItemType[]
  pagination: {
    page_index: number
    number_of_elements: number
    page_size: number
  }
  selectedIds: number[]
  searchValue: string
  locationList: GetLocationListType['content']
}

const initialState: PriceTagInitialStateType = {
  isLoading: false,
  isInitFirstTimeFinish: false,
  query: {
    serial_label: '',
    search_product: '',
    page_index: 1,
    page_size: 20,
    tag_status: '',
    location_id: 0,
  },
  selectedLocation: {
    location_id: 0,
    location_name: '',
  },
  pagination: {
    page_index: 0,
    number_of_elements: 0,
    page_size: 20,
  },
  locationList: [],
  priceTagList: [],
  priceTagListToPrint: [],
  selectedIds: [],
  searchValue: '',
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload
    },
    setQuery: (state, action) => {
      state.query = {
        ...state.query,
        ...action.payload,
      }
    },
    setPriceTagData: (state, action) => {
      state.priceTagList = action.payload.data
      state.pagination = action.payload.pagination
    },
    setSelectedIds: (state, action) => {
      state.selectedIds = action.payload
    },
    setLocationList: (state, action) => {
      state.locationList = action.payload
    },
    setIsInitFirstTimeFinish: (state, action) => {
      state.isInitFirstTimeFinish = action.payload
    },
    setSelectedLocation: (state, action) => {
      state.selectedLocation = action.payload
    },
    setPriceTagListToPrint: (state, action) => {
      state.priceTagListToPrint = action.payload
    },
  },
})

export const {
  resetState,
  setIsLoading,
  setSearchValue,
  setQuery,
  setPriceTagData,
  setSelectedIds,
  setLocationList,
  setIsInitFirstTimeFinish,
  setSelectedLocation,
  setPriceTagListToPrint,
} = slice.actions
export default slice.reducer
