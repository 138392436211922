import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import {
  getLoyaltyActivity,
  getLoyaltyActivityDetail,
  postLoyaltyActivity,
  putLoyaltyActivity,
  putLoyaltyActivityType,
} from 'utils/apiList/loyaltyActivity'
import { toastFailed, toastSuccess } from 'utils/toast'

const initialState = {
  params: {
    name: '',
    // startDate: '2022-06-20',
    // endDate: '2022-06-25',
    sort: 'updated_at',
    direction: 'desc',
    pageSize: 20,
    pageIndex: 0,
  },
  pagination: {
    pageSize: 10,
    currentItem: 0,
    currentPage: 0,
    totalData: 0,
    last: false,
  },
  form: null,
  isLoading: false,
  isOpenForm: false,
  data: [],
}

const loyaltyActivity = createSlice({
  name: 'loyaltyActivity',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setIsOpenForm: (state, action) => {
      state.isOpenForm = action.payload
      if (!action.payload) state.form = null
    },
    setData: (state, action) => {
      state.data = action.payload
    },
    setForm: (state, action) => {
      state.form = action.payload
    },
    setPagination: (state, action) => {
      state.pagination = action.payload
    },
    setParams: (state, action) => {
      state.params = action.payload
    },
    reset: (state, _) => {
      Object.assign(state, { ...initialState })
    },
  },
})

export const { reset, setPagination, setIsOpenForm, setForm, setIsLoading, setData, setParams } =
  loyaltyActivity.actions

export const getData = createAsyncThunk(
  'loyaltyActivity/getData',
  async (_, { dispatch, rejectWithValue, getState }) => {
    try {
      dispatch(setIsLoading(true))
      const {
        loyaltyActivity: { params },
      } = getState()

      const {
        data: {
          data,
          pagination: { pageSize, pageIndex, totalPages, totalElement, numberOfElements },
        },
      } = await getLoyaltyActivity(params)
      dispatch(setData(data))
      dispatch(
        setPagination({
          pageSize,
          currentItem: numberOfElements,
          currentPage: pageIndex,
          totalData: totalElement,
          last: pageIndex === totalPages,
        }),
      )
    } catch (error) {
      toastFailed('Gagal Load Data')
      return rejectWithValue(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const getDetail = createAsyncThunk(
  'loyaltyActivity/getDetail',
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await getLoyaltyActivityDetail(id)
      dispatch(setForm(data))
    } catch (error) {
      toastFailed('Gagal Load Data')
      return rejectWithValue(error)
    }
  },
)

export const updateActivity = createAsyncThunk(
  'loyaltyActivity/updateActivity',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setIsLoading(true))
      const { id, ...payload } = params

      const isAllDay = payload?.is_all_day || false

      const startDateMiliSecond = isAllDay
        ? dayjs().startOf('day').valueOf()
        : dayjs(`${payload?.start_date} ${payload?.start_time}`).valueOf()

      const endDateMiliSecond = isAllDay
        ? dayjs().endOf('day').valueOf()
        : dayjs(`${payload?.end_date} ${payload?.end_time}`).valueOf()

      await putLoyaltyActivity(id, {
        ...payload,
        is_all_day: isAllDay,
        start_date: startDateMiliSecond,
        start_time: startDateMiliSecond,
        end_date: endDateMiliSecond,
        end_time: endDateMiliSecond,
        coin_earn: Number(params.coin_earn),
        multiplier: Number(params.multiplier),
      })

      toastSuccess('Successfully update activity')
      dispatch(getData())
      dispatch(setIsOpenForm(false))
    } catch (error) {
      toastFailed('Failed to update activity')
      return rejectWithValue(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const putActivityType = createAsyncThunk(
  'loyaltyActivity/updateActivityType',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setIsLoading(true))
      const { type, ...payload } = params
      await putLoyaltyActivityType(type, payload)
      toastSuccess('Successfully update activity')
      dispatch(getData())
      dispatch(setIsOpenForm(false))
    } catch (error) {
      toastFailed('Failed to update activity')
      return rejectWithValue(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const postActivity = createAsyncThunk(
  'loyaltyActivity/postActivity',
  async (params, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setIsLoading(true))

      const isAllDay = params?.is_all_day || false
      const startDateMiliSecond = isAllDay
        ? dayjs().startOf('day').valueOf()
        : dayjs(`${params?.start_date} ${params?.start_time}`).valueOf()

      const endDateMiliSecond = isAllDay
        ? dayjs().endOf('day').valueOf()
        : dayjs(`${params?.end_date} ${params?.end_time}`).valueOf()

      await postLoyaltyActivity({
        ...params,
        is_all_day: isAllDay,
        start_date: startDateMiliSecond,
        start_time: startDateMiliSecond,
        end_date: endDateMiliSecond,
        end_time: endDateMiliSecond,
        coin_earn: Number(params.coin_earn),
        multiplier: Number(params.multiplier),
        is_active: true,
      })

      toastSuccess('Successfully create activity')
      dispatch(getData())
      dispatch(setIsOpenForm(false))
    } catch (error) {
      toastFailed('Failed to create activity')
      return rejectWithValue(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export default loyaltyActivity.reducer
