import { memo, useContext } from 'react'
import styled from 'styled-components'

import storeContext from 'storeContext'

import Barcode from './components/Barcode'

function TemplateCetakSupplyOrderCheckQty() {
  const {
    state: {
      printContent: { data },
    },
  } = useContext(storeContext.PrintAreaContext)

  return (
    <Container>
      <tbody>
        {data.map((el, i) => {
          return (
            <Body key={i}>
              <td className="text-center">
                <Barcode data={el} />
              </td>
            </Body>
          )
        })}
      </tbody>
    </Container>
  )
}

export default memo(TemplateCetakSupplyOrderCheckQty)

const Container = styled.table`
  flex-wrap: wrap;
  background: white;
  padding: 16px;
  width: 100%;
  padding: 0px;
`

const Body = styled.tr`
  width: 10cm;
  height: 10cm;
`
