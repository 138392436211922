import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getWatchtowerAreaList,
  postWatchtowerArea,
  getWatchtowerAreaByID,
  putWatchtowerArea,
  putWatchtowerAreaStatus,
} from 'features/Fraud/services/watchtowerAreaDetection'
import { dateFormat } from 'utils/helpers/date'

export const SLICE_NAME = 'WatchtowerAreaDetection'

const DATE_FORMAT = 'YYYY-MM-DD'

export const fetchWatchtowerAreaDetectionList = createAsyncThunk(
  `${SLICE_NAME}/fetchWatchtowerAreaDetectionList`,
  async (__, { rejectWithValue, getState }) => {
    const {
      watchtowerAreaDetection: { query },
    } = getState() as StoreStateType
    try {
      const res = await getWatchtowerAreaList({
        params: { limit: query.pageSize, page: query.pageIndex },
      })

      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const createWatchtowerArea = createAsyncThunk(
  `${SLICE_NAME}/createWatchtowerArea`,
  async (__, { rejectWithValue, getState }) => {
    const {
      watchtowerAreaDetection: { formCreateEdit },
    } = getState() as StoreStateType
    try {
      const res = await postWatchtowerArea({
        payload: {
          name: formCreateEdit.name,
          coordinate: formCreateEdit.coordinate,
          radius: formCreateEdit.radius,
          start: dateFormat({
            date: formCreateEdit.date[0] || new Date(),
            format: DATE_FORMAT,
          }),
          end: dateFormat({
            date: formCreateEdit.date[1] || new Date(),
            format: DATE_FORMAT,
          }),
        },
      })

      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateWatchtowerArea = createAsyncThunk(
  `${SLICE_NAME}/updateWatchtowerArea`,
  async (__, { rejectWithValue, getState }) => {
    const {
      watchtowerAreaDetection: { formCreateEdit },
    } = getState() as StoreStateType
    try {
      const res = await putWatchtowerArea({
        id: formCreateEdit.id,
        payload: {
          name: formCreateEdit.name,
          coordinate: formCreateEdit.coordinate,
          radius: formCreateEdit.radius,
          start: dateFormat({
            date: formCreateEdit.date[0] || new Date(),
            format: DATE_FORMAT,
          }),
          end: dateFormat({
            date: formCreateEdit.date[1] || new Date(),
            format: DATE_FORMAT,
          }),
        },
      })

      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchWatchtowerAreaById = createAsyncThunk(
  `${SLICE_NAME}/fetchWatchtowerAreaById`,
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const res = await getWatchtowerAreaByID({ id })

      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateWatchtowerAreaStatus = createAsyncThunk(
  `${SLICE_NAME}/updateWatchtowerAreaStatus`,
  async ({ id, status }: { id: string; status: boolean }, { rejectWithValue }) => {
    try {
      const res = await putWatchtowerAreaStatus({
        id,
        payload: {
          status,
        },
      })

      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
