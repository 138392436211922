import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getUVVoucherList,
  putActiveVoucher,
  ActiveUVVoucherRequestType,
  getUVOrderDetailList,
  getUVoucherStatusList,
} from 'utils/apiList/uniqueVoucher'
import { callErrorMsg } from 'helpers/errorMsg'

const SLICE_NAME = 'uniqueVoucherList'

export const fetchUniqueVoucherList = createAsyncThunk(
  `${SLICE_NAME}/fecthUniqueVoucherList`,
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        uniqueVoucherList: {
          query: { campaign, voucher, status, pageIndex },
        },
      } = getState() as StoreStateType

      const res = await getUVVoucherList(
        `sort=created_at&direction=DESC&campaignName=${campaign}&code=${voucher}&status=${status}&pageIndex=${pageIndex}`,
      )
      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateVoucherStatus = createAsyncThunk(
  `${SLICE_NAME}/updateVoucherStatus`,
  async (params: ActiveUVVoucherRequestType, { rejectWithValue }) => {
    try {
      const res = await putActiveVoucher(params)
      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchUVOrderDetailList = createAsyncThunk(
  `${SLICE_NAME}/getUVOrderDetailList`,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await getUVOrderDetailList(id)

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchUVStatusList = createAsyncThunk(
  `${SLICE_NAME}/getUVStatusList`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getUVoucherStatusList()

      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
