import { NoData } from 'components'
import styled from 'styled-components'

const S = {
  NoDataWrapper: styled.div`
    display: flex;
    width: 100%;
    min-height: 60vh;
    div {
      margin-top: auto;
      margin-bottom: auto;
    }
  `,
  NoDataStyled: styled(NoData)``,
}

export default S
