import { memo, useState } from "react";
import styled from "styled-components";
import { SystemIcon, NoData, RowInboundWarehouse } from "components";

function TableInboundWarehouse({
  data,
  statusSO,
  handlePrint,
  handleClickOpenPopupEdit,
  handleClickOpenPopupDetail,
  handlePrintCompletSO
}) {
  const [listHandler] = useState({
    handleClickOpenPopupEdit: handleClickOpenPopupEdit,
    handleClickOpenPopupDetail: handleClickOpenPopupDetail,
    handlePrint: handlePrint
  });

  const renderRowInboundWarehouse = () => {
    return data.map((items, index) => {
      return (
        <RowInboundWarehouse
          rowData={items}
          key={`${items.supply_order_number}ssas${index}`}
          listHandler={listHandler}
          statusSO={statusSO}
          handlePrintCompletSO={handlePrintCompletSO}
        />
      );
    });
  };

  return (
    <>
      <Table>
        <thead>
          <Tr>
            <Th ratio="3">
              NOMOR SO
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th ratio="3">
              SO CREATED TIME
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th ratio="3">
              ASAL
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th ratio="3">
              TUJUAN
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th ratio="3">
              STATUS
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th ratio="2">AKSI</Th>
          </Tr>
        </thead>
        <tbody>{renderRowInboundWarehouse()}</tbody>
      </Table>
      {data?.length === 0 && <NoData />}
    </>
  );
}

export default memo(TableInboundWarehouse);

const Table = styled.table`
  width: 100%;
`;
const Tr = styled.tr`
  display: flex;
  padding: 16px 32px;
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`;
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`;
