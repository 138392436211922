import { memo } from "react";
import styled from "styled-components";
import { InputDropDown } from "components";
import dayjs from "dayjs";

const options = [
  {
    name: "Detail Order",
  }
];

function RowOrderPickerCancelled({
  data,
  handleDetailPicker,
  handleReturnPicker,
}) {
  const handleClickOption = (option) => {
    if (option.name === "Detail Order") {
      handleDetailPicker(data);
    } else if (option.name === "Pengembalian") {
      handleReturnPicker(data);
    }
  };
  return (
    <Tr>
      <Td>
        {data.order_invoice}
      </Td>
      <Td>{dayjs(data.order_created_at).format("DD MMM YYYY - HH:mm")}</Td>
      <Td>{data.order_customer_name}</Td>
      <Td>{data.order_total_quantity}</Td>
      <Td>
        <Wrapper>
          <InputDropDown
            disabled
            value="Atur"
            listOptions={options}
            handleClickOption={handleClickOption}
            noSetOption
          />
        </Wrapper>
      </Td>
    </Tr>
  );
}

RowOrderPickerCancelled.defaultProps = {
  handleDetailPicker: () => {},
  handleReturnPicker: () => {},
};

export default memo(RowOrderPickerCancelled);

const Tr = styled.tr`
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
  .input-container {
    width: calc(100% - 20px);
  }
`;
const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.textSoft};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  padding: 16px 32px;
`;

const Wrapper = styled.div`
  .input-drop-down {
    max-width: 110px;
    .list-container {
      right: 0;
      left: auto;
    }
  }
`;
