import { Get, Put, Post, Delete } from 'config/apiServiceConfig'

export type WatchTowerParameterType = {
  id: number
  name: string
  field: string
  fieldType: 'STRING' | 'INTEGER' | 'LIST' | 'BOOLEAN'
}

type GetWatchTowerParameterParamsType = {
  pageSize?: number
  pageIndex?: number
  sort?: string
  direction?: string
}

export type GetWatchTowerParameterResponseType = {
  data: {
    parameters: WatchTowerParameterType[]
  }
  pagination: {
    sort: string
    direction: string
    pageSize: number
    pageIndex: number
    totalElement: number
    numberOfElements: number
    totalPages: number
  }
}

export type WatchTowerRuleParameterType = {
  id: number
  operator: string
  parameterId: number
  value: string
}

export type WatchTowerRuleType = {
  id: number
  name: string
  logic: 'OR' | 'AND'
  status: 'ACTIVE' | 'INACTIVE'
  flagDeviceScore: boolean
  deviceScore: number
  flagActivityScore: boolean
  activityScore: boolean
  parameterRules: WatchTowerRuleParameterType[]
  startDate: string
  endDate: string
  statusString: string
}

export type GetWatchTowerRuleByIdResponseType = {
  data: WatchTowerRuleType
}

export type StatusType = 'ACTIVE' | 'INACTIVE'

export type GetWatchTowerRuleParamsType = {
  pageSize?: number
  pageIndex?: number
  status?: StatusType
}

export type GetWatchTowerRuleResponseType = {
  data: {
    rules: WatchTowerRuleType[]
  }
  pagination: GetWatchTowerRuleParamsType
}

type PostPutParameterRulesType = (WatchTowerRuleParameterType & {
  isDeleted: boolean
})[]

export type PostPutWatchTowerRulePayloadType = Omit<WatchTowerRuleType, 'parameterRules'> & {
  parameterRules: PostPutParameterRulesType
}

export const getParameter = (params: GetWatchTowerParameterParamsType = {}) =>
  Get<GetWatchTowerParameterResponseType>({
    url: '/fraud/watchtower/parameter',
    params,
  })

export const getWatchTowerRuleById = (id: number) =>
  Get<GetWatchTowerRuleByIdResponseType>({
    url: `/fraud/watchtower/rule/${id}`,
  })

export const getWatchTowerRule = (params: GetWatchTowerRuleParamsType) =>
  Get<GetWatchTowerRuleResponseType>({
    url: `/fraud/watchtower/rule`,
    params,
  })

export const putWatchTowerRuleStatus = (id: number, status: WatchTowerRuleType['status']) =>
  Put<GetWatchTowerRuleResponseType>({
    url: `/fraud/watchtower/rule/${id}/status`,
    params: {
      status,
    },
  })

export const postWatchTowerRule = (payload: PostPutWatchTowerRulePayloadType) =>
  Post({
    url: '/fraud/watchtower/rule',
    data: payload,
  })

export const putWatchTowerRule = (payload: PostPutWatchTowerRulePayloadType) =>
  Put({
    url: `/fraud/watchtower/rule/${payload.id}`,
    data: payload,
  })

export const deleteParameterRule = (ruleId: number) =>
  Delete({
    url: '/fraud/watchtower/parameter/rule/:ruleId',
    urlParams: {
      ruleId,
    },
  })
