import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const PINProductRBAC = {
  pageID: 'dc534692c303b1c41cf70a7ebe9be9e9',
  elementID: {
    ButtonCreatePin: '675584fdef3a715179f59efe44957736',
    ButtonApprovalAndRejectionPin: '5f5f21e7d9b74c7043259835aa6264b9',
    ToggleState: '44a49eaafc7a50d6b45108f135a6a720',
    ButtonEdit: '8d52363987b6b15edd5cd4045cd0346d',
    ViewList: 'fdc7a296a2f348f891c20025474a588e',
  },
} as const

export const useRBACPINProductPage = createUseRBAC(PINProductRBAC.elementID)

export type UseRBACPINProductPageType = ReturnType<typeof useRBACPINProductPage>
