export type QueryType = {
  name: string
  location_id: number
  active: number
  page_index: number
  page_size: number
}

export type LocationType = {
  location_id: number
  location_name: string
}

export enum RedirectionEnum {
  CATEGORY = 1,
  CATALOGUE = 2,
  LOGIC = 3,
}

export enum TabbingTypeEnum {
  DEFAULT = 1,
  CUSTOM = 2,
}

export type InfiniteScrollTabbingType = {
  id: number
  name: string
  tabbing_type: TabbingTypeEnum
  tabbing_type_name: string
  redirection: RedirectionType
  is_active: boolean
  targeting_type: number
  targeted_user_ids: number[]
  targeted_users: {
    phone: string
    user_id: number
    user_name: string
  }[]
  is_all_location: boolean
  location_ids: number[]
  position: number
  start_date: string
  end_date: string
  infinite_scroll_tabbing_image: InfiniteScrollTabbingImageType
  targeted_segmentations: TargetedSegmentationType[]
  infinite_scroll_tabbing_hubs: LocationType[]
}

export type RedirectionType = {
  type: RedirectionEnum
  type_name: string
  category_id: number
  category_name: string
  catalog_id: number
  catalog_name: string
  logic: string
}

export type InfiniteScrollTabbingImageType = {
  id: number
  url_small: string
  url_medium: string
  url_large: string
}

export type TargetedSegmentationType = {
  type_id: number
  segment_type_id: number
  segment_type_name: string
}

export type InfiniteScrollTabFlagType = {
  default_wording: string
  is_custom_disable: boolean
}

export type InitialStateType = {
  isLoading: boolean
  query: PartialBy<QueryType, 'name' | 'location_id' | 'active'>
  locationOptions: LocationType[]
  infiniteScrollTabList: InfiniteScrollTabbingType[]
  selectedDataToDelete: Nullable<InfiniteScrollTabbingType>
}
