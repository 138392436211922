import React from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'

interface DropZonePropsType {
  onDrop: (event: File[]) => void
  accept: string[]
  children: React.ReactNode
}
const DropZone = ({ onDrop, children, accept }: DropZonePropsType) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  })

  return (
    <Container {...getRootProps()} active={isDragActive}>
      <input {...getInputProps()} />
      {children}
    </Container>
  )
}

export default DropZone

interface ContainerPropsType {
  active?: boolean
}

const Container = styled.div<ContainerPropsType>`
  background-color: ${({ theme: { colors }, active }) =>
    active ? colors.mainFaded : 'transparent'};
  transition: all ease 0.5s;
  cursor: pointer;
`
