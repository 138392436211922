import axios from 'axios'

const { REACT_APP_BASE_URL_API } = process.env

export interface FPOIListResponseType {
  id: number
  inboundNumber: string
  fpoNumber: string
  location: {
    locationId: number
    locationName: string
  }
  totalItem: number
  status: string
  createdAt: number
}

export interface PaginationResponseType {
  direction: string
  numberOfElements: number
  pageIndex: number
  pageSize: number
  sort: string
}

export interface WarehouseLocationsListResponseType {
  location_id: number
  location_name: string
  name: string
}

export interface FPOIProcessAllRequestType {
  fullProcess: boolean
  badStockLocationId: null | number
  items: {
    id: number
    qty: number
    uom: string
    usedQty: number
    recycleQty: number
    disposalQty: number
    returQty: number
    overgramQty: number
  }[]
}

export interface FPOIDetailResponseType {
  id: number
  inboundNumber: string
  fpoNumber: string
  date: number
  vendor: {
    vendorId: number
    vendorName: string
  }
  location: {
    locationId: number
    locationName: string
    locationType: string
  }
  badStockLocation: {
    locationId: number
    locationName: string
    locationType: string
  }
  totalItem: number
  items: {
    isSelected: boolean
    id: number
    isShow: boolean
    product: {
      id: number
      name: string
      sku: string | number
      weight: number
      age: number
      baseUom: string[]
      selectedUOM: string
    }
    uom: string
    beforeProcessedQty: number
    startQty: number
    remainingQty: number
    usedQty: number
    estimatedPrice: number
    totalEstimatedPrice: number
    actualPrice: number
    totalActualPrice: number
    recycleQty: number
    plannedQty: number
    gradeAQty: number
    disposalQty: number
    returQty: number
    sisaQty: number
    overgramQty: number
    defaultOvergram: number
    manageExpiryDate: boolean
    expiryDate: number | null
  }[]
  status: string
  createdAt: number
  createdBy: string
  updatedAt: number
  fpoCreatedAt: number
  updatedBy: string
}

export interface FPOIProssesResponseType {
  error: {
    status: boolean
    message: string
    code: number
  }
  message: string
}

export interface FPOIConversionResponseType {
  id: number
  freshPurchaseInboundId: number
  product: {
    name: string
    sku: string
    weight: number
    age: number
    baseUom: string
  }
  convertedQty: number
  uom: string
  usedUom: string
  usedQty: number
  recycleQty: number
  returnQty: number
  disposalQty: number
  overgramQty: number
  cogs: number
  createdAt: number
}
export interface LocationsListResponseType {
  locationId: number
  locationName: string
  locationType: string
  name: string
}

export interface PrintQRResponseType {
  expiry_date: number
  manufacture_date: number | null
  produced_by: string | null
  product_name: string
  product_sku_number: string | null
  qr_code: string
  quantity: number
  storage_notes: string | null
}

export interface PrintQRRequestType {
  expiryDate: number
  isActive: boolean
  locationId: number
  productId: number
  stock: number
}

export interface DataPrintQRResponseType {
  expiry_date: number | null
  qr_code: string
  product_name: string
  produced_by: null | string
  manufacture_date: null | number
  storage_notes: null | string
  printQRQTY: number
}

export interface FPOIConversionRequestType {
  id: string
}

export const getFPOIList = (params: string) =>
  axios.get<{ data: FPOIListResponseType[]; pagination: PaginationResponseType }>(
    `${REACT_APP_BASE_URL_API}/api/wms/fresh-purchase/inbound/v1/list${params}`,
  )

export const getFPOInboundDetail = (id: string | undefined) =>
  axios.get<{ data: FPOIDetailResponseType }>(
    `${REACT_APP_BASE_URL_API}/api/wms/fresh-purchase/inbound/v1/${id}`,
  )

export const getLocationList = (query: string) =>
  axios.get(`${REACT_APP_BASE_URL_API}/api/locations/all?${query}`)

export const getWarehouseList = () =>
  axios.get<WarehouseLocationsListResponseType[]>(
    `${REACT_APP_BASE_URL_API}/api/locations/warehouse`,
  )

export const getFPOInboundConversion = ({ id }: FPOIConversionRequestType) =>
  axios.get<{ data: FPOIConversionResponseType[] }>(
    `${REACT_APP_BASE_URL_API}/api/wms/fresh-purchase/inbound/v1/conversion/${id}`,
  )

export const getUOMList = () =>
  axios.get<{ data: string[] }>(`${REACT_APP_BASE_URL_API}/api/wms/fresh-purchase/inbound/v1/uom`)

export const postFPOIProcessAll = (id: undefined | string, payload: FPOIProcessAllRequestType) =>
  axios.post<FPOIProssesResponseType>(
    `${REACT_APP_BASE_URL_API}/api/wms/fresh-purchase/inbound/v1/process/${id}`,
    payload,
  )

export const postFPOIProcessPartial = (
  id: undefined | string,
  payload: FPOIProcessAllRequestType,
) =>
  axios.post<FPOIProssesResponseType>(
    `${REACT_APP_BASE_URL_API}/api/wms/fresh-purchase/inbound/v1/partial-process/${id}`,
    payload,
  )

export const postPrintQR = (payload: PrintQRRequestType) =>
  axios.post(`${REACT_APP_BASE_URL_API}/api/product-detail/generate`, payload)
