import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { coinEarned } from 'config/routes'
import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'
import { callErrorMsg } from 'helpers/errorMsg'
import { history } from 'store'
import {
  createEarnCoin,
  deleteEarnCoin,
  getEarnCoin,
  getEarnCoinCategory,
  getEarnCoinProductCandidate,
  type GetEarnCoinResponseType,
  type GetProductCandidateResponseType,
  type LoyaltyEarnCoinProductParamsType,
  type LoyaltyEarnCoinResponseType,
  updateEarnCoin,
  updateEarnCoinStatus,
} from 'utils/apiList/earnCoin'
import { toastFailed, toastSuccess } from 'utils/toast'
import type {
  LoyaltyEarnCoinType,
  BodyLoyaltyEarnCoinPutEditCoinSingleType,
  BodyLoyaltyEarnCoinPutStatusType,
} from './loyaltyEarnCoin.types'

export const initialState: LoyaltyEarnCoinType = {
  params: {
    param: '',
    sort: 'updated_at',
    direction: 'desc',
    pageSize: 10,
    pageIndex: 0,
  },
  paramsProductList: {
    param: '',
    categoryId: 0,
    hubId: 0,
    direction: 'ASC',
    sort: 'name',
    pageSize: 20,
    pageIndex: 0,
  },
  productSelected: [],
  productList: [],
  checkList: [],
  coinEdit: 0,
  detail: null,
  isLoading: false,
  isLoadingProductList: false,
  isShowEdit: false,
  dropdownCategory: [],
  data: [],
}

const loyaltyEarnCoin = createSlice({
  name: 'loyaltyEarnCoin',
  initialState,
  reducers: {
    reset: (state) => {
      Object.assign(state, { ...initialState })
    },
    setIsLoading: (state, action: PayloadAction<LoyaltyEarnCoinType['isLoading']>) => {
      state.isLoading = action.payload
    },
    setIsLoadingProductList: (
      state,
      action: PayloadAction<LoyaltyEarnCoinType['isLoadingProductList']>,
    ) => {
      state.isLoadingProductList = action.payload
    },
    setCoinEdit: (state, action: PayloadAction<LoyaltyEarnCoinType['coinEdit']>) => {
      state.coinEdit = action.payload
    },
    setIsShowEdit: (state, action: PayloadAction<LoyaltyEarnCoinType['isShowEdit']>) => {
      state.isShowEdit = action.payload
    },
    setCheckList: (state, action: PayloadAction<LoyaltyEarnCoinType['checkList']>) => {
      state.checkList = action.payload
    },
    setData: (state, action: PayloadAction<LoyaltyEarnCoinType['data']>) => {
      state.data = action.payload
    },
    setProductSelected: (state, action: PayloadAction<LoyaltyEarnCoinType['productSelected']>) => {
      state.productSelected = action.payload
    },
    setParams: (state, action: PayloadAction<LoyaltyEarnCoinType['params']>) => {
      state.params = action.payload
    },
    setParamsProductList: (
      state,
      action: PayloadAction<LoyaltyEarnCoinType['paramsProductList']>,
    ) => {
      state.paramsProductList = action.payload
    },
    setProductList: (state, action: PayloadAction<LoyaltyEarnCoinType['productList']>) => {
      state.productList = action.payload
    },
    setDropdownCategory: (
      state,
      action: PayloadAction<LoyaltyEarnCoinType['dropdownCategory']>,
    ) => {
      state.dropdownCategory = action.payload
    },
    resetForm: (state) => {
      state.params = initialState.params
      state.productList = initialState.productList
      state.productSelected = initialState.productSelected
      state.paramsProductList = initialState.paramsProductList
      state.dropdownCategory = initialState.dropdownCategory
    },
  },
})

export const {
  reset,
  setCheckList,
  setCoinEdit,
  setIsLoading,
  setIsShowEdit,
  setData,
  setParams,
  setProductList,
  setIsLoadingProductList,
  setParamsProductList,
  setProductSelected,
  resetForm,
  setDropdownCategory,
} = loyaltyEarnCoin.actions

export const getData = createAsyncThunk(
  'loyaltyEarnCoin/getData',
  async (
    newParams: Partial<LoyaltyEarnCoinType['params']> = {},
    { dispatch, rejectWithValue, getState },
  ) => {
    try {
      dispatch(setIsLoading(true))

      const {
        loyaltyEarnCoin: { params },
      } = getState() as StoreStateType

      const nextParams = {
        ...params,
        ...newParams,
      } as LoyaltyEarnCoinType['params']

      const res: AxiosResponse<GetEarnCoinResponseType> = await getEarnCoin(nextParams)

      const {
        data: {
          data,
          pagination: { pageSize, pageIndex },
        },
      } = res

      dispatch(setData(data.earnCoins))
      dispatch(setParams({ ...nextParams, pageSize, pageIndex }))
      return true
    } catch (error) {
      toastFailed('Gagal Load Data')
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const putEditCoin = createAsyncThunk(
  'loyaltyEarnCoin/putEditCoin',
  async (_, { dispatch, rejectWithValue, getState }) => {
    dispatch(setIsLoading(true))
    const {
      loyaltyEarnCoin: { checkList, coinEdit, params },
    } = getState() as StoreStateType

    try {
      const res = await updateEarnCoin({
        productIdList: checkList,
        earnCoin: coinEdit,
      })

      dispatch(setIsShowEdit(initialState.isShowEdit))
      dispatch(setCoinEdit(initialState.coinEdit))
      dispatch(setCheckList(initialState.checkList))
      dispatch(setParams({ ...params, pageIndex: initialState.params.pageIndex }))
      dispatch(getData({}))

      toastSuccess('Berhasil Edit Koin')

      return res.data
    } catch (error) {
      toastFailed('Gagal Edit Koin')
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const putEditCoinSingle = createAsyncThunk(
  'loyaltyEarnCoin/putEditCoinSingle',
  async (
    { id, coin }: BodyLoyaltyEarnCoinPutEditCoinSingleType,
    { dispatch, rejectWithValue, getState },
  ) => {
    dispatch(setIsLoading(true))
    const {
      loyaltyEarnCoin: { params },
    } = getState() as StoreStateType

    try {
      const res = await updateEarnCoin({
        productIdList: [id],
        earnCoin: coin,
      })

      dispatch(setCheckList(initialState.checkList))
      dispatch(setParams({ ...params, pageIndex: initialState.params.pageIndex }))
      dispatch(getData({}))

      toastSuccess('Berhasil Edit Koin')
      return res.data
    } catch (error) {
      toastFailed('Gagal Edit Koin')
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const deleteEditCoinSingle = createAsyncThunk<
  LoyaltyEarnCoinResponseType,
  number,
  RejectValueType
>('loyaltyEarnCoin/deleteEditCoinSingle', async (id, { dispatch, rejectWithValue, getState }) => {
  dispatch(setIsLoading(true))
  const {
    loyaltyEarnCoin: { params },
  } = getState() as StoreStateType

  try {
    const res = await deleteEarnCoin(id)

    dispatch(setCheckList(initialState.checkList))
    dispatch(setParams({ ...params, pageIndex: initialState.params.pageIndex }))
    dispatch(getData({}))

    toastSuccess('Berhasil Hapus Koin')
    return res.data
  } catch (error) {
    toastFailed('Gagal Hapus Koin')
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  } finally {
    dispatch(setIsLoading(false))
  }
})

export const putStatus = createAsyncThunk<
  LoyaltyEarnCoinResponseType,
  BodyLoyaltyEarnCoinPutStatusType,
  RejectValueType
>(
  'loyaltyEarnCoin/putStatus',
  async (
    { id, active }: BodyLoyaltyEarnCoinPutStatusType,
    { dispatch, rejectWithValue, getState },
  ) => {
    dispatch(setIsLoading(true))
    const {
      loyaltyEarnCoin: { params },
    } = getState() as StoreStateType

    try {
      const res = await updateEarnCoinStatus(id, { isAvailable: active })

      dispatch(setParams({ ...params, pageIndex: initialState.params.pageIndex }))
      dispatch(getData({}))

      toastSuccess('Berhasil Edit Status')
      return res.data
    } catch (error) {
      toastFailed('Gagal Edit Status')
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const getProductCandidate = createAsyncThunk<
  GetProductCandidateResponseType,
  Partial<LoyaltyEarnCoinProductParamsType>,
  RejectValueType
>(
  'loyaltyEarnCoin/getProductCandidate',
  async (newParams = {}, { dispatch, getState, rejectWithValue }) => {
    dispatch(setIsLoadingProductList(true))

    const {
      loyaltyEarnCoin: { paramsProductList },
    } = getState() as StoreStateType

    const nextParams = {
      ...paramsProductList,
      ...newParams,
    }

    try {
      const res = await getEarnCoinProductCandidate(nextParams as LoyaltyEarnCoinProductParamsType)

      const {
        data: {
          data,
          pagination: { pageSize, pageIndex },
        },
      } = res

      dispatch(setProductList(data.products))
      dispatch(
        setParamsProductList({
          ...nextParams,
          pageSize,
          pageIndex,
        }),
      )

      return res.data
    } catch (error) {
      dispatch(setProductList(initialState.productList))
      callErrorMsg(error, 'Gagal Load Data Product')
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    } finally {
      dispatch(setIsLoadingProductList(false))
    }
  },
)

export const postEarnCoin = createAsyncThunk(
  'loyaltyEarnCoin/postEarnCoin',
  async (_, { dispatch, rejectWithValue, getState }) => {
    dispatch(setIsLoadingProductList(true))
    const {
      loyaltyEarnCoin: { productSelected },
    } = getState() as StoreStateType

    try {
      await createEarnCoin({
        productIdList: productSelected,
      })

      toastSuccess('Berhasil Tambah Koin didapat')
      dispatch(getData({}))
      dispatch(resetForm())
      return history.push(coinEarned)
    } catch (error) {
      callErrorMsg(error, 'Gagal Tambah Koin didapat')
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    } finally {
      dispatch(setIsLoadingProductList(false))
    }
  },
)

export const getDropdownCategory = createAsyncThunk(
  'loyaltyEarnCoin/getDropdownCategory',
  async (_, { dispatch }) => {
    try {
      const res = await getEarnCoinCategory()
      const {
        data: { content },
      } = res
      dispatch(
        setDropdownCategory(
          content.map((i) => ({
            id: i.category_id,
            name: i.category_name,
          })),
        ),
      )
      return true
    } catch (error) {
      dispatch(setDropdownCategory(initialState.dropdownCategory))
      toastFailed('Gagal Load Data Kategori')
      return false
    }
  },
)

export default loyaltyEarnCoin.reducer
