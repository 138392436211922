import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getCampaignList,
  getCampaignTypeList,
  deleteCampaign,
  putAdjustmentCampaign,
  putStatusCampaign,
} from 'features/CampaignAndDiscount/services/campaignList'
import {
  PutAdjustmentCampaignRequestType,
  PutStatusCampaignRequestType,
  GetCampaignListRequestType,
} from 'features/CampaignAndDiscount/@types'
import { toastSuccess } from 'utils/toast'

export const SLICE_NAME = 'campaignlistV2'

const validateParamsValue = (
  key: keyof GetCampaignListRequestType['params'],
  value: string | number | Date | null,
) =>
  value
    ? {
        [key]: value,
      }
    : {}

export const fetchCampaignTypeList = createAsyncThunk(
  `${SLICE_NAME}/getCampaignTypeList`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getCampaignTypeList()
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchCampaignList = createAsyncThunk(
  `${SLICE_NAME}/getCampaignList`,
  async (_, { rejectWithValue, getState }) => {
    const {
      campaignListV2: { query },
    } = getState() as StoreStateType

    try {
      const res = await getCampaignList({
        params: {
          pageIndex: query.pageIndex,
          pageSize: query.pageSize,
          ...validateParamsValue('campaignType', query.campaignType.value),
          ...validateParamsValue('status', query.status.value),
          ...validateParamsValue('name', query.name),
          ...validateParamsValue('createdDateFrom', query.date[0] && query.date[0].valueOf()),
          ...validateParamsValue('createdDateTo', query.date[1] && query.date[1].valueOf()),
        },
      })
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const removeCampaign = createAsyncThunk(
  `${SLICE_NAME}/removeCampaign`,
  async (_, { rejectWithValue, getState }) => {
    const {
      campaignListV2: { selectedCampaignList, isShowPopup, bulkSelectedCampaignList },
    } = getState() as StoreStateType

    const payload =
      isShowPopup === 'DELETE'
        ? [selectedCampaignList.id]
        : bulkSelectedCampaignList.map((el) => el.id)
    try {
      const res = await deleteCampaign({ payload })
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateCampaign = createAsyncThunk(
  `${SLICE_NAME}/updateCampaign`,
  async (
    { payload }: Pick<PutAdjustmentCampaignRequestType, 'payload'>,
    { rejectWithValue, getState },
  ) => {
    const {
      campaignListV2: { selectedCampaignList },
    } = getState() as StoreStateType
    try {
      const res = await putAdjustmentCampaign({
        campaignId: selectedCampaignList.id.toString(),
        payload,
      })
      toastSuccess(res.data.data.message)
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateStatusCampaign = createAsyncThunk(
  `${SLICE_NAME}/updateStatusCampaign`,
  async (params: PutStatusCampaignRequestType, { rejectWithValue }) => {
    try {
      const res = await putStatusCampaign(params)
      toastSuccess(`berhasil meng update ${res.data.data.name}`)
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
