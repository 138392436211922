import { createAsyncThunk } from '@reduxjs/toolkit'
import { getOrderLocations } from 'features/Order/services/allOrder'
import {
  BannerThematicGeneralPayloadType,
  getDetailBannerThematic,
  getTargetingType,
  postBannerThematicGaneral,
  getCategories,
  postBannerThematicComponent,
  putBannerThematicGaneral,
  putBannerThematicComponent,
  BannerThematicComponentPayloadType,
} from 'features/BannerAndTicker/sevices/bannerThematic'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  BannerThematicType,
  InformationFormType,
  FetchImageRulesParamType,
  BannerThematicComponentType,
  ComponentTypeFieldNameType,
  DestinationType,
} from 'features/BannerAndTicker/@types/bannerThematic'
import { dateFormat, DateFormatType } from '@astronautsid/wpe-utils'
import {
  transformDate,
  createComponentPayload,
} from 'features/BannerAndTicker/utils/bannerThematic'
import { getImageRules, postImage, PostImageResponseType } from 'utils/apiList/images'
import { getFileRules, postFile } from 'utils/apiList/files'

import {
  setLocationHubOptions,
  SLICE_NAME,
  setTargetingType,
  setBannerThematicDetail,
  setIsLoading,
  setInformationForm,
  setCategoryOptions,
  setImageRule,
  setTilesImageForm,
  setBannerImageForm,
  storeComponentForm,
  setActiveComponentForm,
  toggleExpandStepperAccordion,
  setComponentContentState,
  setAutoFillComponentTilesAndBanner,
} from './slice'

export const fetchLocations = createAsyncThunk(
  `${SLICE_NAME}/fetchLocations`,
  async (_, { dispatch }) => {
    try {
      const {
        data: { data },
      } = await getOrderLocations()

      dispatch(setLocationHubOptions(data))
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar hub')
    }
  },
)

export const fetchTargetingType = createAsyncThunk(
  `${SLICE_NAME}/fetchTargetingType`,
  async (_, { dispatch }) => {
    try {
      const { data } = await getTargetingType()

      dispatch(setTargetingType(data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const createBannerThematicGeneral = createAsyncThunk(
  `${SLICE_NAME}/createBannerThematicGeneral`,
  async (_, { rejectWithValue, getState, dispatch }) => {
    const {
      bannerThematic: { informationForm },
    } = getState() as StoreStateType

    const {
      bg_animated_url,
      bg_animated_file,
      browse_thematic_images,
      targeting_type,
      location_ids,
      startDate,
      startTime,
      endDate,
      endTime,
      ...rest
    } = informationForm

    const payload: BannerThematicGeneralPayloadType = {
      ...rest,
      bg_animated_url,
      browse_thematic_images: {
        component: 'background',
        url_large: browse_thematic_images.image?.url_large || '',
        url_medium: browse_thematic_images.image?.url_medium || '',
        url_small: browse_thematic_images.image?.url_small || '',
      },
      targeting_type: targeting_type?.id || null,
      location_ids: location_ids.map(({ location_id }) => location_id),
      start_date: transformDate(startDate, startTime),
      end_date: transformDate(endDate, endTime),
    }

    try {
      const { data } = await postBannerThematicGaneral(payload)

      await dispatch(setAutoFillComponentTilesAndBanner())
      return data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const updateBannerThematicGeneral = createAsyncThunk(
  `${SLICE_NAME}/updateBannerThematicGeneral`,
  async ({ id }: { id: number }, { rejectWithValue, getState, dispatch }) => {
    const {
      bannerThematic: { informationForm },
    } = getState() as StoreStateType

    const {
      bg_animated_url,
      bg_animated_file,
      browse_thematic_images,
      targeting_type,
      location_ids,
      startDate,
      startTime,
      endDate,
      endTime,
      ...rest
    } = informationForm

    const payload: BannerThematicGeneralPayloadType = {
      ...rest,
      bg_animated_url,
      browse_thematic_images: {
        component: 'background',
        url_large: browse_thematic_images.image?.url_large || '',
        url_medium: browse_thematic_images.image?.url_medium || '',
        url_small: browse_thematic_images.image?.url_small || '',
      },
      targeting_type: targeting_type?.id || null,
      location_ids: location_ids.map(({ location_id }) => location_id),
      start_date: transformDate(startDate, startTime),
      end_date: transformDate(endDate, endTime),
    }

    try {
      const { data } = await putBannerThematicGaneral(id, payload)

      await dispatch(setAutoFillComponentTilesAndBanner())

      return data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchBannerThematicDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchBannerThematicDetail`,
  async (id: number, { dispatch, getState }) => {
    const {
      bannerThematic: { addComponentExpenaded },
    } = getState() as StoreStateType

    dispatch(setIsLoading(true))
    try {
      const { data } = await getDetailBannerThematic(id)

      dispatch(setBannerThematicDetail(data))
      dispatch(setInformationForm({ value: createGeneralFormPayload(data) }))

      if (data.component?.length) {
        if (!addComponentExpenaded) {
          dispatch(toggleExpandStepperAccordion('addComponentExpenaded'))
        }

        if (data.component.some(({ component }) => component === 'banner')) {
          dispatch(setActiveComponentForm('banner'))
          dispatch(setComponentContentState({ key: 'bannerImage', value: 'LIST' }))
          dispatch(
            storeComponentForm({
              key: 'bannerImage',
              value: data.component.filter(({ component }) => component === 'banner'),
            }),
          )
        }

        if (data.component.some(({ component }) => component === 'tile')) {
          dispatch(setActiveComponentForm('tile'))
          dispatch(setComponentContentState({ key: 'tilesImage', value: 'LIST' }))
          dispatch(
            storeComponentForm({
              key: 'tilesImage',
              value: data.component.filter(({ component }) => component === 'tile'),
            }),
          )
        }
      }
    } catch (error) {
      callErrorMsg(error)
    }

    dispatch(setIsLoading(false))
  },
)

export const fetchImageRules = createAsyncThunk(
  `${SLICE_NAME}/fetchImageRules`,
  async ({ typeId, imageRuleType }: FetchImageRulesParamType, { dispatch }) => {
    dispatch(setIsLoading(true))
    try {
      const { data } = await getImageRules(typeId)

      dispatch(setImageRule({ name: imageRuleType, value: data.data }))
    } catch (error) {
      callErrorMsg(error)
    }

    dispatch(setIsLoading(false))
  },
)

export const fetchFileRules = createAsyncThunk(
  `${SLICE_NAME}/fetchFileRules`,
  async (_, { dispatch }) => {
    dispatch(setIsLoading(true))
    try {
      const { data } = await getFileRules('banner-animation')

      dispatch(setImageRule({ name: 'bannerLottie', value: data.data }))
      dispatch(setImageRule({ name: 'bgLottie', value: data.data }))
      dispatch(setImageRule({ name: 'tileLottie', value: data.data }))
    } catch (error) {
      callErrorMsg(error)
    }

    dispatch(setIsLoading(false))
  },
)

export const uploadImagesInformation = createAsyncThunk(
  `${SLICE_NAME}/uploadImagesInformation`,
  async (_, { dispatch, getState, rejectWithValue }) => {
    const {
      bannerThematic: {
        informationForm: { browse_thematic_images },
        imagesRules: { bgStatis },
      },
      auth: {
        userData: { id },
      },
    } = getState() as StoreStateType
    dispatch(setIsLoading(true))

    try {
      if (browse_thematic_images.file) {
        const { data } = await postImage(bgStatis.typeId, {
          created_by: id,
          file_input: browse_thematic_images.file,
        })

        const { images } = data.data

        dispatch(
          setInformationForm({
            key: 'browse_thematic_images',
            value: {
              ...browse_thematic_images,
              image: {
                component: 'background', // temporary mapping
                url_large: selectImagesImages(images, 'large'), // temporary mapping
                url_medium: selectImagesImages(images, 'medium'), // temporary mapping
                url_small: selectImagesImages(images, 'small'), // temporary mapping
              },
              imageToPreview: selectImagesImages(images, 'real'),
            },
          }),
        )
        dispatch(setIsLoading(false))

        return data.data
      }
      dispatch(setIsLoading(false))

      return true
    } catch (error) {
      callErrorMsg(error)

      dispatch(setIsLoading(false))
      return rejectWithValue(error)
    }
  },
)

const composeDate = (
  browse_thematic_hubs: BannerThematicType['browse_thematic_hubs'],
  {
    type,
    dateOrTime,
  }: {
    type: 'start_time' | 'end_time'
    dateOrTime: 0 | 1 // date = 0 & time = 1
  },
) =>
  getDateFormat(
    browse_thematic_hubs.length ? browse_thematic_hubs[0][type] : '',
    dateOrTime === 0 ? 'YYYY-MM-DD' : 'HH:mm',
  )

const createGeneralFormPayload = (data: BannerThematicType): Partial<InformationFormType> => ({
  name: data.name,
  is_active: data.is_active,
  component_sequence: data.component_sequence,
  font_color: data.font_color,
  bg_animated_url: data.animated_url,
  browse_thematic_images: {
    file: null,
    imageToPreview: data.browse_thematic_images?.url_medium || '',
    image: data.browse_thematic_images || null,
  },
  location_ids: data.browse_thematic_hubs,
  startDate: composeDate(data.browse_thematic_hubs, { dateOrTime: 0, type: 'start_time' }),
  startTime: composeDate(data.browse_thematic_hubs, { dateOrTime: 1, type: 'start_time' }),
  endDate: composeDate(data.browse_thematic_hubs, { dateOrTime: 0, type: 'end_time' }),
  endTime: composeDate(data.browse_thematic_hubs, { dateOrTime: 1, type: 'end_time' }),
})

const getDateFormat = (date: string, format: DateFormatType) =>
  date ? dateFormat({ date: new Date(date), format }) : ''

export const fetchCategories = createAsyncThunk(
  `${SLICE_NAME}/fetchCategories`,
  async (_, { dispatch }) => {
    try {
      const { data } = await getCategories()

      dispatch(setCategoryOptions(data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const createBannerThematicComponent = createAsyncThunk(
  `${SLICE_NAME}/createBannerThematicComponent`,
  async (
    {
      thematicId,
      componentType,
      destination,
    }: {
      thematicId: number
      componentType: BannerThematicComponentType
      destination?: DestinationType
    },
    { rejectWithValue, getState },
  ) => {
    const {
      bannerThematic: { bannerImage, tilesImage },
    } = getState() as StoreStateType

    const payload = createComponentPayload(
      componentType === 'banner' ? bannerImage.form : tilesImage.form,
      thematicId,
      componentType === 'banner' ? bannerImage.list.length + 1 : tilesImage.list.length + 1,
    )

    if (componentType === 'banner') {
      if (destination === 'category') {
        payload.destination_url = ''
      } else if (destination === 'url') {
        payload.category = null
      }
    }

    try {
      const {
        data: { data },
      } = await postBannerThematicComponent(payload)

      return data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const updateBannerThematicComponent = createAsyncThunk(
  `${SLICE_NAME}/updateBannerThematicComponent`,
  async (
    {
      thematicId,
      field,
      destination,
    }: { thematicId: number; field: ComponentTypeFieldNameType; destination?: DestinationType },
    { rejectWithValue, getState },
  ) => {
    const { bannerThematic } = getState() as StoreStateType

    const componentData = bannerThematic[field].form

    const payload = createComponentPayload(componentData, thematicId, componentData.sequence)

    if (field === 'bannerImage') {
      if (destination === 'category') {
        payload.destination_url = ''
      } else if (destination === 'url') {
        payload.category = null
      }
    }

    try {
      const {
        data: { data },
      } = await putBannerThematicComponent(componentData.id as number, payload)

      return data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const updateComponentSquence = createAsyncThunk(
  `${SLICE_NAME}/updateComponentSquence`,
  async (payloads: BannerThematicComponentPayloadType[]) =>
    // eslint-disable-next-line no-return-await
    await Promise.allSettled(
      payloads.map((payload) => putBannerThematicComponent(payload.id as number, payload)),
    ),
)

const selectImagesImages = (
  imagesData: PostImageResponseType['data']['images'],
  type: PostImageResponseType['data']['images'][0]['type'],
) =>
  imagesData.length === 1
    ? imagesData[0].imageUrl
    : imagesData.find((el) => el.type === type)?.imageUrl || ''

export const uploadImagesCompoenent = createAsyncThunk(
  `${SLICE_NAME}/uploadImagesCompoenent`,
  async (
    { componentType }: { componentType: 'tile' | 'banner' },
    { dispatch, getState, rejectWithValue },
  ) => {
    const {
      bannerThematic: {
        imagesRules: { tileStatis, bannerStatis },
        tilesImage: {
          form: { browse_thematic_images: tileBrowseImages },
        },
        bannerImage: {
          form: { browse_thematic_images: bannerBrowseImages },
        },
      },
      auth: {
        userData: { id },
      },
    } = getState() as StoreStateType
    dispatch(setIsLoading(true))

    try {
      if (tileBrowseImages.file && componentType === 'tile') {
        const { data } = await postImage(tileStatis.typeId, {
          created_by: id,
          file_input: tileBrowseImages.file,
        })

        const { images } = data.data

        dispatch(
          setTilesImageForm({
            key: 'browse_thematic_images',
            value: {
              ...tileBrowseImages,
              image: {
                component: 'tile',
                url_large: selectImagesImages(images, 'large'),
                url_medium: selectImagesImages(images, 'medium'),
                url_small: selectImagesImages(images, 'small'),
              },
              imageToPreview: selectImagesImages(images, 'real'),
            },
          }),
        )
        dispatch(setIsLoading(false))

        return data.data
      }

      if (bannerBrowseImages.file && componentType === 'banner') {
        const { data } = await postImage(bannerStatis.typeId, {
          created_by: id,
          file_input: bannerBrowseImages.file,
        })

        const { images } = data.data

        dispatch(
          setBannerImageForm({
            key: 'browse_thematic_images',
            value: {
              ...bannerBrowseImages,
              image: {
                component: 'tile',
                url_large: selectImagesImages(images, 'large'),
                url_medium: selectImagesImages(images, 'medium'),
                url_small: selectImagesImages(images, 'small'),
              },
              imageToPreview: selectImagesImages(images, 'real'),
            },
          }),
        )
        dispatch(setIsLoading(false))

        return data.data
      }

      dispatch(setIsLoading(false))

      return true
    } catch (error) {
      callErrorMsg(error, 'terjadi masalah saat memproses upload image')

      dispatch(setIsLoading(false))
      return rejectWithValue(error)
    }
  },
)

export const uploadLottie = createAsyncThunk(
  `${SLICE_NAME}/uploadLottie`,
  async (
    { lottieType }: { lottieType: 'tile' | 'banner' | 'bg' },
    { dispatch, getState, rejectWithValue },
  ) => {
    const TYPE_ID = 'banner-animation'
    const {
      bannerThematic: {
        informationForm: { bg_animated_file },
        tilesImage: {
          form: { animated_file: tileAnimatedFile },
        },
        bannerImage: {
          form: { animated_file: bannerAnimatedFile },
        },
      },
    } = getState() as StoreStateType
    dispatch(setIsLoading(true))

    try {
      if (bg_animated_file && lottieType === 'bg') {
        const { data } = await postFile({
          data: { file_input: bg_animated_file },
          typeId: TYPE_ID,
        })

        const { fileUrl } = data.data

        dispatch(
          setInformationForm({
            key: 'bg_animated_url',
            value: fileUrl,
          }),
        )
        dispatch(
          setInformationForm({
            key: 'bg_animated_file',
            value: null,
          }),
        )
        dispatch(setIsLoading(false))

        return data.data
      }
      if (bannerAnimatedFile && lottieType === 'banner') {
        const { data } = await postFile({
          data: { file_input: bannerAnimatedFile },
          typeId: TYPE_ID,
        })

        const { fileUrl } = data.data

        dispatch(
          setBannerImageForm({
            key: 'animated_url',
            value: fileUrl,
          }),
        )
        dispatch(
          setBannerImageForm({
            key: 'animated_file',
            value: null,
          }),
        )
        dispatch(setIsLoading(false))

        return data.data
      }
      if (tileAnimatedFile && lottieType === 'tile') {
        const { data } = await postFile({
          data: { file_input: tileAnimatedFile },
          typeId: TYPE_ID,
        })

        const { fileUrl } = data.data

        dispatch(
          setTilesImageForm({
            key: 'animated_url',
            value: fileUrl,
          }),
        )
        dispatch(
          setTilesImageForm({
            key: 'animated_file',
            value: null,
          }),
        )
        dispatch(setIsLoading(false))

        return data.data
      }
      dispatch(setIsLoading(false))

      return true
    } catch (error) {
      callErrorMsg(error)

      dispatch(setIsLoading(false))
      return rejectWithValue(error)
    }
  },
)
