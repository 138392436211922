import { createAsyncThunk } from '@reduxjs/toolkit'
import { postStagingArea } from 'features/Order/services/stagingArea'
import { callErrorMsg } from 'helpers/errorMsg'

type PayloadStagingAreaThunkType = {
  data: {
    orderId: string
    location_id: string
    staging_area: string
  }
  successCallback: Nullable<() => void>
}

export const postStagingAreaThunk = createAsyncThunk(
  'ongoingOrders/postStagingAreaThunk',
  async (payload: PayloadStagingAreaThunkType) => {
    const { orderId, ...restData } = payload.data
    try {
      const res = await postStagingArea(orderId, restData)
      if (res.data && payload.successCallback) {
        payload.successCallback()
      }
    } catch (err) {
      callErrorMsg(err, 'Terjadi Masalah Pada Server')
    }
  },
)
