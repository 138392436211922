import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

export type GetListDriverResponseType = {
  data: {
    driver_id: number
    driver_name: string
    driver_status: string
    driver_back_to_hub: number
    order_delivery_at: number
    orders: {
      order_id: number
      order_customer_name: string
      order_customer_address: string
      order_customer_address_position: {
        latitude: string
        longitude: string
      }
      order_status: string
      order_progress: string
    }[]
    activities: {
      name: string
      image_url: string
      note: string
      created_at: number
      order_id: string
      order_service_level_name: string
      order_status: string
      duration: string
      status: boolean
    }[]
  }[]
  total_active_driver: number
  total_driver: number
}

type GetListDriverRequestType = {
  location_id: number
}

export const getListDriverAPI = (params: GetListDriverRequestType) =>
  axiosInstanceApiGateway.Get<GetListDriverResponseType>({
    url: `/logistic/internal/v1/driver/tracking-driver-list`,
    params,
  })
