export const SET_IS_START_DRIVER = 'SET_IS_START_DRIVER'
export const SET_IS_FAILED_DRIVER = 'SET_IS_FAILED_DRIVER'
export const SET_IS_SUCCESS_DRIVER = 'SET_IS_SUCCESS_DRIVER'
export const SET_IS_CURRENT_PAGE_DRIVER = 'SET_IS_CURRENT_PAGE_DRIVER'
export const SET_IS_NEED_TO_RELOAD_DRIVER = 'SET_IS_NEED_TO_RELOAD_DRIVER'
export const SET_PAGINATION_DATA_DRIVER = 'SET_PAGINATION_DATA_DRIVER'
export const SET_IS_SUCCESS_DRIVER_BY_ROLE = 'SET_IS_SUCCESS_DRIVER_BY_ROLE'
export const SET_IS_FAILED_DRIVER_BY_ROLE = 'SET_IS_FAILED_DRIVER_BY_ROLE'
export const SET_IS_START_DRIVER_BY_ROLE = 'SET_IS_START_DRIVER_BY_ROLE'

export const SET_IS_REQUEST_EXPORT_STAFF = 'SET_IS_REQUEST_EXPORT_STAFF'
export const SET_IS_SUCCESS_EXPORT_STAFF = 'SET_IS_SUCCESS_EXPORT_STAFF'
export const SET_IS_FAILED_EXPORT_STAFF = 'SET_IS_FAILED_EXPORT_STAFF'
export const SET_QUERY_DATA_DRIVER = 'SET_QUERY_DATA_DRIVER'
export const SET_RESET_DRIVERS_DATA = 'SET_RESET_DRIVERS_DATA'
