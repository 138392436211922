import { Get, Post, Put, Delete } from 'config/apiServiceConfig'

export const getPwpData = (params) => Get({ url: '/api/promo/pwp', params })
export const getPwpDetail = (id) =>
  Get({
    config: {
      baseURL: process.env.REACT_APP_BASE_URL_KONG_API,
    },
    url: `/promo/internal/v1/pwp/${id}`,
  })
export const getPwpType = () => Get({ url: '/api/promo/pwp/rules-type' })
export const getPwpProduct = (params) =>
  Get({ url: '/api/gwp/product', params: { excludeVB: true, ...params } })
export const postPwpBulkUpload = (payload) =>
  Post({
    url: '/api/promo/pwp/bulk-add-product',
    data: payload,
    contentType: 'multipart/form-data',
  })

export const postPwpCreate = (payload) =>
  Post({
    config: {
      baseURL: process.env.REACT_APP_BASE_URL_KONG_API,
    },
    url: '/promo/internal/v1/pwp',
    data: payload,
  })
export const putPwpEdit = (id, payload) =>
  Put({
    config: {
      baseURL: process.env.REACT_APP_BASE_URL_KONG_API,
    },
    url: `/promo/internal/v1/pwp/${id}`,
    data: payload,
  })
export const putPwpEditStatus = (id, payload) =>
  Put({ url: `/api/promo/pwp/${id}/status`, data: payload })
export const getTargetedUserList = ({ id, params }) =>
  Get({ url: `/api/promo/pwp/${id}/targeted-user-list`, params })

export const deleteTargetedUser = ({ customerID, campaignID }) =>
  Delete({ url: `/api/promo/pwp/${campaignID}/${customerID}` })

export const getSearchPWPProductPriority = ({ params }) =>
  Get({ url: '/api/promo/pwp/products', params: { limit: 20, page: 0, ...params } })

export const getPWPPriority = () => Get({ url: '/api/promo/pwp/pinned-products' })

export const postPWPPriority = ({ payload }) =>
  Post({ url: '/api/promo/pwp/pinned-products', data: payload })

export const getPwpCommercialInternalSkp = (params) =>
  Get({
    config: {
      baseURL: process.env.REACT_APP_BASE_URL_KONG_API,
    },
    url: '/commercial/internal/v1/skp',
    params,
  })
