import { memo } from "react";
import styled from "styled-components";
import { RowFraudSetting } from "components";

function TableFraudSetting({ data, handleChangeStatus, handleEdit }) {
  return (
    <Container>
      <thead>
        <Tr>
          <Th>Created by</Th>
          <Th>Date</Th>
          <Th>Jenis Fraud</Th>
          <Th>Keterangan</Th>
          <Th>Status</Th>
          <Th>Aksi</Th>
        </Tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <RowFraudSetting
            key={`fraud-${index}`}
            data={item}
            handleChangeStatus={handleChangeStatus}
            handleEdit={handleEdit}
          />
        ))}
      </tbody>
    </Container>
  );
}

export default memo(TableFraudSetting);

const Container = styled.table`
  width: 100%;
  border-collapse: collapse;
  height: fit-content;
`;
const Tr = styled.tr`
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`;
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  text-align: start;
  padding: 16px;
  padding-right: 20px;
`;
