import { memo, useContext, useRef } from "react";
import styled from "styled-components";
import { Input, InputDropDown, TextBody } from "components";
import storeContext from "storeContext";

function RowCheckQty({
  data,
  handleChangeNote,
  handleChangeQty,
  noOriginStock,
  dIndex,
  listOfOptionNotes,
  handleSelectOptionNote,
  isEditInbound,
  handleClickSLOCOption,
  handleChangeKoli,
  actHandleGetSLOC
}) {
  const refSLOCSearch = useRef(null);

  const getParams = (paramsKey) => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get(paramsKey);
    return myParam;
    //console.log(myParam);
  };
  const {
    dispatch,
    state: { slocOptionsData }
  } = useContext(storeContext.InboundContext);

  const handleSearchOptionSLOC = (e) => {
    const { value } = e.target;
    clearTimeout(refSLOCSearch.current);

    refSLOCSearch.current = setTimeout(() => {
      dispatch(
        actHandleGetSLOC(`?active=true&pageSize=20&pageIndex=0&name=${value}`, {
          locationID: getParams("locationId")
        })
      );
    }, 500);
  };

  const handleClickOption = (rack) => {
    handleClickSLOCOption(rack, dIndex);
  };

  const handleFirstClickSLOC = () => {
    dispatch(
      actHandleGetSLOC(`?active=true`, { locationID: getParams("locationId") })
    );
  };

  return (
    <TrBody>
      <Td>{dIndex + 1}</Td>
      <Td>{data?.product_sku}</Td>
      <Td> {data?.product_name}</Td>
      <Td>
        <TextBody size="smaller" weight="light">
          {data?.product_request_quantity}
        </TextBody>
      </Td>
      <Td>
        <TextBody size="smaller" weight="light">
          {data?.product_incoming_quantity}
        </TextBody>
      </Td>
      <Td>
        {!isEditInbound && (
          <TextBody
            size="smaller"
            weight="light"
            style={{ textAlign: "center" }}
          >
            {data?.product_rack ? data?.product_rack : "#N/A"}
          </TextBody>
        )}
        {isEditInbound && (
          <SLOCDropdown>
            <InputDropDown
              isManualFilter
              handleSearchOption={handleSearchOptionSLOC}
              value={data?.product_rack ? data?.product_rack : ""}
              listOptions={slocOptionsData}
              handleClickOption={handleClickOption}
              isFirstClick
              handleFirsClick={handleFirstClickSLOC}
            />
          </SLOCDropdown>
        )}
      </Td>
      <Td>
        <StyledInput
          value={data?.product_actual_quantity || "0"}
          className="input-qty"
          inputClass="input-qty-packer"
          onChange={(e) =>
            handleChangeQty(
              dIndex,
              e.target.value,
              data?.product_request_quantity,
              isEditInbound
            )
          }
        />
      </Td>
      <Td>
        <TextBody size="smaller" weight="light" style={{ textAlign: "center" }}>
          {!isEditInbound &&
            (data?.product_request_quantity.toString() ===
            data?.product_actual_quantity.toString()
              ? "OK"
              : "Qty Kurang")}
          {isEditInbound &&
            (data?.product_incoming_quantity.toString() ===
            data?.product_actual_quantity.toString()
              ? "OK"
              : "Qty Kurang")}
        </TextBody>
      </Td>
      <Td>
        <StyledInput
          value={data?.product_koli || 0}
          className="product_koli"
          inputClass="product_koli"
          onChange={(e) =>
            handleChangeKoli(dIndex, e.target.value, data?.product_koli)
          }
        />
      </Td>
      <Td>
        <InputDropDown
          listOptions={listOfOptionNotes}
          handleClickOption={(notes) =>
            handleSelectOptionNote(notes, data?.supply_order_item_id)
          }
          value={data?.product_note ? data?.product_note : ""}
        />
      </Td>
    </TrBody>
  );
}

export default memo(RowCheckQty);

const TrBody = styled.tr`
  border-top: 1px solid #eff3f9;
  background-color: ${({ theme: { colors } }) => colors.white};
`;

const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.textSoft};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  padding: 16px 32px;
  vertical-align: top;
  .input-qty {
    max-width: 70px;
  }
`;

const StyledInput = styled(Input)`
  max-width: 100px;
  margin-left: auto;
  margin-right: auto;
  input {
    text-align: center;
  }
  div {
  }
`;

const SLOCDropdown = styled.div`
  width: 140px;
`;
