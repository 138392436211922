import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import { getCampaignTypeList } from 'utils/apiList/campaignV2'

const SLICE_NAME = 'campaign'

export const fetchCampaignTypeList = createAsyncThunk(
  `${SLICE_NAME}/getCampaignTypeList`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getCampaignTypeList()
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
