import { memo } from "react";
import styled from "styled-components";
import { RowUom, SystemIcon, NoData } from "components";

function TableUom({ data, handleChangeStatus, handleEdit, handleClickDetail }) {
  return (
    <>
      <Container>
        <thead>
          <Tr>
            <Th ratio="2">
              Code
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th ratio="2">GROUP UOM</Th>
            <Th ratio="2">DESCRIPTION</Th>
            <Th ratio="1">STATUS</Th>
            <Th ratio="1">AKSI</Th>
          </Tr>
        </thead>
        <tbody>
          {data.map((el) => (
            <RowUom
              handleChangeStatus={handleChangeStatus}
              data={el}
              key={el.id}
              handleEdit={handleEdit}
              handleClickDetail={handleClickDetail}
            />
          ))}
        </tbody>
      </Container>
      {data.length === 0 && <NoData />}
    </>
  );
}

export default memo(TableUom);

const Container = styled.table`
  width: 100%;
`;
const Tr = styled.tr`
  display: flex;
  padding: 16px 32px;
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`;
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`;
