import { memo } from "react";
import styled from "styled-components";
import {
  SystemIcon,
  NoData,
  RowAddProductDiscount,
  Checkbox
} from "components";

function TableAddProductDiscount({ data }) {
  return (
    <>
      <Container>
        <thead>
          <Tr>
            <Th>
              <WrapperCheck>
                <Checkbox />
              </WrapperCheck>
            </Th>
            <Th>
              Nama Hub
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th>
              Nama Produk
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th>
              Harga
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th>
              Stock
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
          </Tr>
        </thead>
        <tbody>
          {data.map((el, idx) => (
            <RowAddProductDiscount key={idx} />
          ))}
        </tbody>
      </Container>
      {data.length === 0 && <NoData withAstroIcon />}
    </>
  );
}

TableAddProductDiscount.defaultProps = {
  data: []
};

export default memo(TableAddProductDiscount);

const Container = styled.table`
  width: 100%;
  border-collapse: collapse;
  height: fit-content;
`;
const Tr = styled.tr`
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`;
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  text-align: start;
  padding: 16px;
  padding-right: 20px;
`;

const WrapperCheck = styled.div`
  display: grid;
  place-items: center;
`;
