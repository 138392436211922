import { memo, useState } from 'react'
import styled, { css } from 'styled-components'
import { SystemIcon, TextBody, Button } from 'components'
import four_arrow from 'assets/images/four-arrow.svg'

function Expand({ title, children, hasEdit, handleClickEdit, isDraggable, isOpen = true }) {
  const [isExpand, setIsExpand] = useState(isOpen)
  const [isPined, setIsPined] = useState(false)

  const handleClickPin = () => {
    setIsPined(!isPined)
    if (!isPined) {
      setIsExpand(true)
    }
  }
  return (
    <Container isExpand={isExpand} isDraggable={isDraggable}>
      <HeaderExpand isDraggable={isDraggable}>
        <HeadWrapperClickExpanArea
          isDraggable={isDraggable}
          {...(!isDraggable && { onClick: () => setIsExpand(!isExpand) })}
        >
          <TitleWrapper>
            {isDraggable ? (
              <IconMove size="24px" src={four_arrow} alt="moveIco" style={{ cursor: 'grab' }} />
            ) : (
              <Button disabled={isPined} variant="wrapper">
                <IconWrapperDrop isExpand={isExpand}>
                  <SystemIcon fontSize="header" iconName="dropdown" />
                </IconWrapperDrop>
              </Button>
            )}
            <TextBodyStyled size="smaller" isDraggable={isDraggable}>
              {title}
            </TextBodyStyled>
          </TitleWrapper>
        </HeadWrapperClickExpanArea>
        {isDraggable ? (
          <Button disabled={isPined} variant="wrapper" onClick={() => setIsExpand(!isExpand)}>
            <IconWrapperDrop isExpand={isExpand}>
              <SystemIcon fontSize="header" iconName="dropdown" />
            </IconWrapperDrop>
          </Button>
        ) : (
          <div>
            <TitleWrapper>
              {hasEdit && (
                <Button variant="wrapper" onClick={() => handleClickEdit(!hasEdit)}>
                  <TextBody size="smaller" color="main">
                    Edit
                  </TextBody>
                </Button>
              )}
              <Button variant="wrapper" onClick={handleClickPin}>
                <IconWrapper isPined={isPined}>
                  <SystemIcon
                    fontSize="header"
                    iconName="pin"
                    colorIcon={isPined ? 'main' : 'text'}
                  />
                </IconWrapper>
              </Button>
            </TitleWrapper>
          </div>
        )}
      </HeaderExpand>
      <Content>{children}</Content>
    </Container>
  )
}

export default memo(Expand)

const Container = styled.div`
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 8px;
  max-height: ${({ isExpand, isDraggable }) =>
    isExpand ? 'fit-content' : isDraggable ? '89px' : '55px'};
  overflow: ${({ isExpand }) => (isExpand ? '' : 'hidden')};
  transition: all ease 0.5s;
  box-shadow: 0px 1px 12px rgba(105, 110, 118, 0.12);
  ${({ isDraggable }) =>
    isDraggable &&
    css`
      box-shadow: none;
      border: 1px solid #f0f3f7;
    `}
  ${({ isDraggable, isExpand }) =>
    isDraggable &&
    isExpand &&
    css`
      max-height: fit-content;
    `}
`

const HeaderExpand = styled.div`
  border-bottom: 1px solid #f0f3f7;
  padding: 18px;
  display: flex;
  justify-content: space-between;
  ${({ isDraggable }) =>
    isDraggable &&
    css`
      align-items: center;
    `}
`

const TitleWrapper = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;
`

const Content = styled.div`
  padding: 24px;
  height: fit-content;
  background-color: white;
`

const IconWrapper = styled.div`
  transition: all ease 0.3s;
  ${({ isPined }) =>
    isPined &&
    css`
      transform: rotate(-45deg);
    `}
`

const IconWrapperDrop = styled.div`
  transition: all ease 0.3s;
  ${({ isExpand }) =>
    isExpand &&
    css`
      transform: rotate(180deg);
    `}
`

const HeadWrapperClickExpanArea = styled.div`
  cursor: pointer;
  width: 90%;
  ${({ isDraggable }) =>
    isDraggable &&
    css`
      cursor: default;
    `}
`

const TextBodyStyled = styled(TextBody)`
  ${({ isDraggable }) =>
    isDraggable &&
    css`
      flex: 1;
    `}
`

const IconMove = styled.img`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`