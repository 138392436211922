import { memo } from 'react'
import styled from 'styled-components'
import { Input, TextBody, Toggle } from 'components'
import dayjs from 'dayjs'

function RowBanner({
  dataCollection,
  data,
  handlChangeActive,
  handleClickEdit,
  isHubFilterActive,
  setDataCollection,
  onUpdateBlur,
}) {
  const bannerHubs = data.banner_hubs
  const singleBanner = dataCollection.filter((value) => {
    return value.banner_id === data.banner_id
  })
  let selectedPosition = null
  if (singleBanner.length > 0) {
    if (singleBanner[0].banner_hubs.length > 0) {
      selectedPosition = singleBanner[0].banner_hubs[0].position
    }
  }

  return (
    <Tr>
      <Td ratio="1">
        <TextBody weight="light" color="textSoft">
          {data?.banner_id}
        </TextBody>
      </Td>
      <Td ratio="3">
        <ProductWrapper>
          <ImgBanner
            alt="banner"
            src={
              data.banner_image
                ? data.banner_image
                : 'https://www.bojonegorokarir.com/article/show_banner/43746066057a969474a526'
            }
          />
        </ProductWrapper>
      </Td>
      <Td ratio="5">
        <TextBody weight="light" color="textSoft">
          {data.banner_name}
        </TextBody>
      </Td>
      <Td ratio="5">
        <TagWrapper>
          {bannerHubs.slice(0, 4).map((value, index) => {
            return <Tag key={index}>{value.name}</Tag>
          })}
          {/*Render the rest of array object which not displayed*/}
          {data.banner_hubs.length > 4 && <Tag>+{data.banner_hubs.length - 4}</Tag>}
        </TagWrapper>
      </Td>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {dayjs(data.banner_date_start).format('DD MMM YYYY - HH:mm')}
        </TextBody>
      </Td>
      <Td ratio="4">
        <TextBody weight="light" color="textSoft">
          {dayjs(data.banner_date_end).format('DD MMM YYYY - HH:mm')}
        </TextBody>
      </Td>

      <Td ratio="3">
        <ActionWrapper>
          <Toggle
            active={data.banner_active}
            onClick={() => handlChangeActive({ ...data, banner_active: !data.banner_active })}
          />
        </ActionWrapper>
      </Td>
      <Td ratio="3">
        {isHubFilterActive ? (
          <Input
            onBlur={(e) => {
              const selected = dataCollection.find((obj) => {
                return obj.banner_id === data.banner_id
              })

              const newSelected = { ...selected }

              /** Overwrite the position*/
              newSelected.banner_hubs = selected.banner_hubs.map((value) => {
                const { location_id, name, status } = value
                return {
                  location_id,
                  name,
                  position: parseInt(e.target.value, 10),
                  status,
                }
              })
              /** Only update if value exist*/
              if (e.target.value) {
                onUpdateBlur([newSelected], data.banner_id)
              }
            }}
            onChange={(e) => {
              /** Take existing edit line*/
              const selected = dataCollection.find((obj) => {
                return obj.banner_id === data.banner_id
              })

              const newSelected = { ...selected }

              /** Overwrite the position*/
              newSelected.banner_hubs = selected.banner_hubs.map((value) => {
                const { location_id, name, status } = value
                return {
                  location_id,
                  name,
                  position: e.target.value,
                  status,
                }
              })
              /** Remove the existing line*/
              const removed = dataCollection.filter((obj) => {
                return obj.banner_id !== data.banner_id
              })
              /** Merge the existing line with new one*/
              const merge = [...[newSelected], ...removed]
              if (!isNaN(e.target.value)) {
                setDataCollection(merge)
              }
            }}
            width={'130px'}
            bgIcon="mercuryGrey"
            iconColor="grey70"
            className="input-search"
            value={selectedPosition}
          />
        ) : (
          '-'
        )}
      </Td>
      <Td ratio="3">
        <ChangeText onClick={() => handleClickEdit(data)}>Ubah</ChangeText>
      </Td>
    </Tr>
  )
}

export default memo(RowBanner)

const Tr = styled.tr`
  display: flex;
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
`
const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`
const ImgBanner = styled.img`
  height: 50px;
  width: 110px;
  border-radius: 5px;
  background-color: ${({ theme: { colors } }) => colors.mainFaded};
  border: none;
`

const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const ActionWrapper = styled.div`
  display: flex;
  i {
    margin-left: 20px;
  }
`
const TagWrapper = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
`

const Tag = styled.div`
  padding: 2px 8px;
  color: #212121;
  background-color: #f3f6fa;
  border-radius: 3px;
  font-size: 12px;
`

const ChangeText = styled.span`
  color: #246ee5;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
`
