import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import { getTargetedUserList, deleteTargetedUser } from 'utils/apiList/gwpV2'

export const SLICE_NAME = 'GiftWithPurchase'

export const fetchTargetedUser = createAsyncThunk(
  `${SLICE_NAME}/fetchTargetedUser`,
  async ({ id }: { id: number }, { rejectWithValue, getState }) => {
    const {
      gwp: {
        targetedUserList: {
          query: { pageIndex, pageSize },
        },
      },
    } = getState() as StoreStateType
    try {
      const res = await getTargetedUserList({ id: id.toString(), params: { pageIndex, pageSize } })

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const removeTargetedUser = createAsyncThunk(
  `${SLICE_NAME}/removeTargetedUser`,
  async (
    { campaignID, customerID }: { campaignID: number; customerID: number },
    { rejectWithValue },
  ) => {
    try {
      const res = await deleteTargetedUser({
        campaignID: campaignID.toString(),
        customerID: customerID.toString(),
      })
      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)
