import { memo } from 'react'
import styled from 'styled-components'
import { TextBody, SystemIcon, Button } from 'components'

function RowAdmin({ handleClickEdit, adminData }) {
  //console.log(adminData)

  //FUNCTION HANDLER
  const handleClick = () => {
    //console.log(adminData?.id)
    handleClickEdit(adminData?.id)
  }

  //FUNCTION RENDER
  const renderLocation = () => {
    const location = adminData.locations.map((items) => items.location_name)
    return location.join(', ')
  }

  const renderRoles = () => {
    const roles = adminData.roles.map((items) => items.name)
    return roles.join(', ')
  }

  return (
    <Tr>
      <Td ratio="3">
        <TextBody weight="light">{adminData ? adminData?.full_name : ''}</TextBody>
      </Td>
      <Td ratio="3">
        <TextBody weight="light">{adminData ? adminData?.phone_number : ''}</TextBody>
      </Td>
      <Td ratio="6">
        <TextBody weight="light">{adminData ? adminData?.email : ''}</TextBody>
      </Td>
      <Td ratio="3">
        <TextBody weight="light">{adminData ? adminData?.position : '-'}</TextBody>
      </Td>
      <Td ratio="4">
        <TextBody weight="light" style={{ paddingRight: '10px' }}>
          {renderLocation()}
        </TextBody>
      </Td>
      <Td ratio="4">
        <TextBody weight="light" style={{ paddingRight: '10px' }}>
          {renderRoles()}
        </TextBody>
      </Td>
      <Td ratio="1">
        <Button variant="wrapper" onClick={handleClick}>
          <SystemIcon className="pointer" colorIcon="main" iconName="edit" />
        </Button>
      </Td>
    </Tr>
  )
}

export default memo(RowAdmin)

const Tr = styled.tr`
  display: flex;
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
`
const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`
