function setUserDataToStore(data) {
  const hubs = []
  const warehouses = []
  const authorities = []

  data.location_roles.forEach((el) => {
    if (el.location_type === 'HUB') {
      hubs.push({ ...el, name: el.location_name })
    } else {
      warehouses.push({ ...el, name: el.location_name })
    }
  })
  data.authorities.forEach((el) => {
    authorities.push(el.name)
  })

  const isSuperAdmin = authorities.some((el) => el === 'AUTH_ADMIN')

  const userData = {
    ...data,
    isSuperAdmin: isSuperAdmin,
    warehouses,
    hubs,
  }

  return userData
}

export default setUserDataToStore
