import { createAsyncThunk } from '@reduxjs/toolkit'

import { callErrorMsg } from 'helpers/errorMsg'
import { getQueryParamsObjectToString } from 'utils/helpers/router'
import {
  getVendors,
  getVendorRequest,
  type GetVendorListResponseInterface,
  type VendorRequestResponseInterface,
} from 'features/Enterprise/Vendor/services/vendorList'

import {
  sliceName,
  setLoading,
  setVendorList,
  setVendorRequestList,
  setVendorRequestCount,
} from './vendorListSlice'

export const fetchVendors = createAsyncThunk(
  `${sliceName}/fetchVendors`,
  async (_, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true))
      const {
        vendorList: { query },
      } = getState() as StoreStateType
      const parseQuery = getQueryParamsObjectToString(query)
      const { data } = await getVendors(parseQuery)
      dispatch(setVendorList(data as unknown as GetVendorListResponseInterface))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const fetchVendorRequests = createAsyncThunk(
  `${sliceName}/fetchVendorRequests`,
  async (_, { dispatch, getState }) => {
    try {
      dispatch(setLoading(true))
      const {
        vendorList: { query },
      } = getState() as StoreStateType
      const { data } = await getVendorRequest(query)
      const payload = data as unknown as VendorRequestResponseInterface
      await dispatch(setVendorRequestList(payload))
      dispatch(
        setVendorRequestCount({
          status: query?.status || '',
          count: payload?.pagination?.total_elements || 0,
        }),
      )
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const fetchDraftVendorRequestsCount = createAsyncThunk(
  `${sliceName}/fetchDraftVendorRequestsCount`,
  async (status: string, { dispatch }) => {
    try {
      const { data } = await getVendorRequest({ status })
      const totalElements =
        (data as unknown as VendorRequestResponseInterface).pagination?.total_elements || 0
      dispatch(setVendorRequestCount({ status, count: totalElements }))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)
