import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  AssetMasterInventoryAssetDataInterface,
  AssetMasterInventoryAssetParamsInterface,
} from 'features/AssetManagement/@types/typeAssetMaster'

interface AssetMasterDetailStateInterface {
  isLoading: boolean
  hasNext: boolean
  assetMasterInventoryList: AssetMasterInventoryAssetDataInterface[]
  assetMasterInventoryParam: Omit<AssetMasterInventoryAssetParamsInterface, 'asset_id'>
  assetMasterDetailGeneralData: {
    name: string
    category: string
    klassifikasi: string
    sku: string
    depreciation: string
    type: string
    uom: string
  }
}

const initialState: AssetMasterDetailStateInterface = {
  isLoading: false,
  hasNext: false,
  assetMasterInventoryList: [],
  assetMasterInventoryParam: {
    page_index: 0,
    page_size: 10,
    status: 'available',
  },
  assetMasterDetailGeneralData: {
    name: '',
    category: '',
    klassifikasi: '',
    sku: '',
    depreciation: '',
    type: '',
    uom: '',
  },
}

const assetMasterDetailSlice = createSlice({
  name: 'assetMasterDetail',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setHasNext: (state, action: PayloadAction<boolean>) => {
      state.hasNext = action.payload
    },
    setAssetMasterInventoryList: (
      state,
      action: PayloadAction<AssetMasterInventoryAssetDataInterface[]>,
    ) => {
      state.assetMasterInventoryList = action.payload
    },
    setAssetMasterInventoryParam: (
      state,
      action: PayloadAction<Omit<AssetMasterInventoryAssetParamsInterface, 'asset_id'>>,
    ) => {
      const newPayload = action.payload
      state.assetMasterInventoryParam = { ...state.assetMasterInventoryParam, ...newPayload }
    },
    setAssetMasterDetailGeneralData: (
      state,
      action: PayloadAction<AssetMasterDetailStateInterface['assetMasterDetailGeneralData']>,
    ) => {
      state.assetMasterDetailGeneralData = action.payload
    },
    resetAssetMasterDetailData: (state) => {
      state.assetMasterDetailGeneralData = initialState.assetMasterDetailGeneralData
      state.assetMasterInventoryList = initialState.assetMasterInventoryList
      state.assetMasterInventoryParam = initialState.assetMasterInventoryParam
    },
    resetAssetMasterDetailParam: (state) => {
      state.assetMasterInventoryParam = initialState.assetMasterInventoryParam
    },
  },
})

export const {
  setIsLoading,
  setAssetMasterInventoryList,
  setAssetMasterInventoryParam,
  setHasNext,
  setAssetMasterDetailGeneralData,
  resetAssetMasterDetailData,
  resetAssetMasterDetailParam,
} = assetMasterDetailSlice.actions

export default assetMasterDetailSlice.reducer
