import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  layout: {
    isSidebarOpen: false,
  },
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setSidebarOpen: (state, action) => {
      state.layout.isSidebarOpen = action.payload
    },
  },
})

export const { setSidebarOpen } = appSlice.actions
export default appSlice.reducer
