/* eslint-disable @typescript-eslint/naming-convention */
import axios from 'axios'

const baseUrl = process.env.REACT_APP_BASE_URL_API

type DirectionType = 'asc' | 'desc'

export type GetAllBrandV2 = Partial<{
  pageIndex: number
  pageSize: number
  sort: string
  direction: DirectionType
  name: string
}>

type BrandCompany = {
  id: number
  name: string
}

export type Pagination = {
  direction: DirectionType
  numberOfElements: number
  pageIndex: number
  pageSize: number
  sort: string
  totalElements: number
  totalPages: number
}

export type GetAllBrandV2ResponseType = {
  data: {
    brand_company: BrandCompany | null
    brand_id: number
    brand_name: string
    cloud_kitchen: boolean
  }[]
  pagination: Pagination
}

export type PutBrandCloudKitchen = Partial<{
  id: number
  cloudKitchen: boolean
}>

export type PutBrandCloudKitchenResponseType = {
  data: {
    id: number
    cloud_kitchen: boolean
  }
}

export type GetBrandCompany = Partial<{
  name: string
  sort: string
  direction: DirectionType
  pageSize: number
}>

export type GetBrandCompanyResponseType = {
  data: BrandCompany[]
  pagination: Pagination
}

export type PostBrandResponseType = {
  id?: number
  name: string
  cloudKitchen: boolean
  brandCompanyName: string
}

export type PostBrand = Partial<PostBrandResponseType>

export type PutBrandResponseType = PostBrandResponseType

export type PutBrand = PostBrand &
  Partial<{
    id: number
  }>

export const getAllBrandV2 = (params: GetAllBrandV2) =>
  axios.get(`${baseUrl}/api/v2/brand`, { params })

export const putBrandCloudKitchen = (payload: PutBrandCloudKitchen) =>
  axios.put(`${baseUrl}/api/brand/cloud-kitchen`, payload)

export const getBrandCompany = (params: GetBrandCompany) =>
  axios.get(`${baseUrl}/api/brand-companies`, { params })

export const postBrand = (payload: PostBrand) => axios.post(`${baseUrl}/api/brand`, payload)

export const putBrand = (payload: PutBrand) => axios.put(`${baseUrl}/api/brand`, payload)
