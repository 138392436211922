import { Get, Post } from 'config/apiServiceConfig'

export type PromoWidgetType = {
  id: string
  title: string
  description: string
  thumbnailURL: string
  thumbnailFilePath: string
  backgroundImageURL: string
  backgroundImageFilePath: string
  link: string
  startTime: number
  endTime: number
  updatedBy: string
  /**
   * unix ms
   */
  updatedAt: number
}

export type GetPromoWidgetResponseType = {
  data: PromoWidgetType
}

export type PostPromoWidgetPayloadType = {
  title: string
  description: string
  thumbnailFilePath: string
  backgroundImageFilePath: string
  link: string
  startTime: number
  endTime: number
}

export const getPromoWidget = () => Get<GetPromoWidgetResponseType>({ url: '/api/promo/widget' })
export const postPromooWidget = (data: PostPromoWidgetPayloadType) =>
  Post({ url: '/api/promo/widget', data })
