import { memo } from 'react'
import styled from 'styled-components'
import logo_Black from 'assets/images/logo_astro_black.svg'

function HeadLabel() {
  return (
    <Container>
      <ContainerLogo>
        <img src={logo_Black} style={{ width: '150px' }} />
      </ContainerLogo>
      <ContainerTitle>
        <StyledTitlePage>PURCHASE ORDER</StyledTitlePage>
      </ContainerTitle>
    </Container>
  )
}

export default memo(HeadLabel)

const Container = styled.div`
  display: flex;
  margin-bottom: 18px;
`

const StyledTitlePage = styled.p`
  font-family: 'Nunito Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 30px; /* 125% */
  letter-spacing: -0.072px;
`

const ContainerLogo = styled.div``
const ContainerTitle = styled.div`
  margin-left: auto;
  font-family: ${({ theme: { fontFamily } }) => fontFamily.monstreat};
  font-size: 34px;
  margin-bottom: 8px;
`
