import { ImageRulesType, ImageVariantIdType } from 'utils/apiList/images'
import { SegmentOptionType } from 'features/CategoryAndPosisi/components/Segment'
import { CategoryBannerType } from './Category'

export type ImageVersionTypeIdType = 1 /* old */ | 2 /* Homepage revamp */

export type ImagePayloadType = {
  image_url_small: string
  image_url_medium: string
  image_url_large: string
  version_type_id: ImageVersionTypeIdType
}

export type ImageStateType = {
  image: Nullable<ImagePayloadType>
  file: Nullable<File>
  imageToPreview: string
}

export type BannerFormType = CategorySchedulerType & {
  uniqueKey: string
  bannerImageFile: Nullable<File>
  bannerImagePreview: string
  banner_name: string
  banner_url: string
  banner_active: boolean
}

export type CategorySchedulerType = {
  startDate: string
  startTime: string
  endDate: string
  endTime: string
}

export enum CategoryTypeEnum {
  INSTANT = 1,
  SUPER = 2,
}

export type FormSegmentType = {
  type_id: number
  segment_type_id: number
  segment_type_name: string
  segment_type_total_user: number
  name: string
}

export type CategoryFormType = {
  category_label: string
  category_type: CategoryTypeEnum
  segment: FormSegmentType[]
}

export type CategoryBannerListType = BannerFormType & Partial<CategoryBannerType>

export type InitialStateType = {
  isLoading: boolean
  imageRules: Record<ImageVariantIdType, ImageRulesType> | ObjectNullType
  categoryImage: {
    categoryImageNew: ImageStateType
    categoryImageOld: ImageStateType
  }
  banner: {
    isAddNewBanner: boolean
    selectedBannerToEdit: Nullable<CategoryBannerListType>
    form: BannerFormType
    list: CategoryBannerListType[]
  }
  scheduler: CategorySchedulerType
  form: CategoryFormType
  segmentOptions: SegmentOptionType[]
}

export type PayloadActionUpdateBannerType = InitialStateType['banner']['list'][number]

export type PayloadSetFormType = {
  key?: keyof CategoryFormType
  value: CategoryFormType[keyof CategoryFormType] | CategoryFormType
}

export type PayloadActionBannerFormType = {
  key: keyof BannerFormType
  value: BannerFormType[keyof BannerFormType]
}

export type SetCategorySchedulerPayloadType = {
  key?: keyof CategorySchedulerType
  value: string | CategorySchedulerType
}
