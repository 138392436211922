import { useContext } from 'react'
import { Box, Typography } from '@astro-ui/components'
import styled from 'styled-components'
import { ReactComponent as AstroKitchenLogo } from 'assets/images/astroKitchenLogo.svg'
import Cair from 'assets/images/Cair.svg'
import storeContext from 'storeContext'
import textToBase64Barcode from 'utils/textToBase64'

const TemplateAstroKitchen = () => {
  const { state: statePrintArea } = useContext(storeContext.PrintAreaContext)

  if (!statePrintArea.astroKitchenData) return null

  const { data, customer, invoice } = statePrintArea.astroKitchenData

  return data.map((d, i) => {
    return (
      <StyledWrapper key={i}>
        <Container>
          <TopSection>
            <KitchenLogo />
            <StyledMudahCair>
              <IceCream src={Cair} />
              <BoxText>
                <ResiTypography>SEGERA KONSUMSI </ResiTypography>
                <ResiTypography>SAAT DITERIMA</ResiTypography>
              </BoxText>
            </StyledMudahCair>
          </TopSection>
          <MidSection>
            <CustomerInfoTypography>
              {d.page} / {d.total}
            </CustomerInfoTypography>
            <NotesText maxLine={2}>
              <CustomerInfoTypography>{d.order_product_name}</CustomerInfoTypography>
            </NotesText>
            <Typography fontSize="7px" fontWeight={600} lineHeight={1}>
              <i>Catatan:</i>
            </Typography>
            <NotesText maxLine={2}>
              <Typography fontSize="8px" fontWeight={700} lineHeight={1}>
                <i> {d.product_modifier ? d.product_modifier : ''}</i>
              </Typography>
            </NotesText>
          </MidSection>
          <BottomSection>
            <NotesText maxLine={1}>
              <CustomerInfoTypography>{customer}</CustomerInfoTypography>
            </NotesText>
            <Typography fontSize="8px" lineHeight={1} mt={'4px'}>
              {invoice}
            </Typography>
            <BarcodeContainer src={textToBase64Barcode(d.order_product_sku)} />
            <Typography fontSize="8px" lineHeight={1} mt={'4px'}>
              SKU {d.order_product_sku}
            </Typography>
          </BottomSection>
        </Container>
      </StyledWrapper>
    )
  })
}

const StyledWrapper = styled.div`
  padding: 20px;
  width: 50.8mm;
  height: 96.6mm;
  @media print {
    @page {
      margin: 0;
      size: 50.8mm 96.6mm;
    }
  }
`

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const TopSection = styled(Box)`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px dashed black;
  justify-content: space-between;
  align-items: center;
`
const KitchenLogo = styled(AstroKitchenLogo)`
  width: 45%;
  height: auto;
  margin-right: 14px;
`
const StyledMudahCair = styled(Box)`
  display: flex;
  flex-direction: row;
  height: fit-content;
  align-items: center;
  width: 150px;
`
const IceCream = styled.img`
  width: 19px;
  height: auto;
`
const ResiTypography = styled(Typography)`
  font-family: 'Montserrat';
  font-size: 7px;
  line-height: 1;
`
const BoxText = styled(Box)`
  text-align: left;
`
const MidSection = styled(Box)`
  display: flex;
  flex-direction: column;
  border-bottom: 1px dashed black;
  padding-bottom: 10px;
  max-height: 35%;
  padding-top: 10px;
  gap: 4px;
`
const BottomSection = styled(Box)`
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;
  padding-top: 10px;
`
const CustomerInfoTypography = styled(Typography)`
  font-family: 'Montserrat';
  text-transform: capitalize;
  font-size: 10px;
  line-height: 1;
`
const BarcodeContainer = styled.img`
  width: 90%;
  height: auto;
  margin-left: -4px;
`
const NotesText = styled.div`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${({ maxLine }) => (maxLine ? maxLine : 'none')};
  overflow: hidden;
  text-overflow: ellipsis;
`

export default TemplateAstroKitchen
