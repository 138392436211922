import { AxiosResponse } from 'axios'
import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  ActionReducerMapBuilder,
} from '@reduxjs/toolkit'
import { saveAs } from 'file-saver'

import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'
import { callErrorMsg } from 'helpers/errorMsg'
import { serializeQueryToURL } from 'utils/queryParamsURL'
import { dateFormat, stringToDateObject } from 'utils/helpers/date'
import { AppDispatchType } from 'store'
import { withLoadingReducer } from 'utils/reducerHandler'
import { toastSuccess, toastFailed } from 'utils/toast'
import {
  getExportDriver,
  putDriverActive,
  putDriverStatus,
  putDriver,
  getDriverRoles,
  getEmploymentTypes,
  getLocations,
  fetchStaffList,
  PutDriverActiveRequestType,
  PutDriverStatusRequestType,
  PutDriverRequestType,
  PutDriverResponseType,
  GetCentralWarehouseStaffRequestType,
  GetCentralWarehouseStaffResponseType,
  GetLocationsResponseType,
  GetLocationsRequestType,
  GetDriverRolesResponseType,
  GetDriverRolesRequestType,
  GetEmploymentTypesResponseType,
  GetExportDriverResponseType,
  PutDriverStatusResponseType,
  PutDriverActiveResponseType,
  PostDriverRequestType,
  PostDriverResponseType,
  postDriver,
  putTermination,
  putRejoin,
  GetVehicleResponseType,
  getVehicle,
  PutDriverAndInactiveRequestType,
  getListVendorStaff,
  GetVendorStaffResponseType,
  getDepartementList,
  GetAllDepartementListResponseType,
  GetAllDepartementProcessedType,
  getTerminationReasons,
  GetTerminationReasonsResponseType,
  PutResignationPayloadType,
} from 'features/Enterprise/HRIS/services/account/centralWarehouseStaff'

type CentralWarehouseStaffConstantsType = {
  locations: GetLocationsResponseType['content']
  roles: GetDriverRolesResponseType['data']
  employmentTypes: GetEmploymentTypesResponseType['data']
  vehicleType: GetVehicleResponseType['data']['vehicle_type']
  vehicleSubType: GetVehicleResponseType['data']['vehicle_sub_type']
  vendorStaff: GetVendorStaffResponseType['data']['data']['data']
  allDepartementList: GetAllDepartementProcessedType[]
}

export type CentralWarehouseStaffStateType = {
  isLoading: boolean
  query: GetCentralWarehouseStaffRequestType
  pagination: {
    first: boolean
    last: boolean
  }
  data: GetCentralWarehouseStaffResponseType['data']
  popupAddEdit: {
    isOpen: boolean
    type: 'ADD' | 'EDIT' | 'TERMINATE' | 'REJOIN'
    selected?: GetCentralWarehouseStaffResponseType['data'][number]
  }
  constants: CentralWarehouseStaffConstantsType
  form: {
    fullName: string
    cardNumber: string
    plateNumber: string
    phoneNumber: string
    trackerId: string
    vehicleType: CentralWarehouseStaffConstantsType['vehicleType'][number] | null
    vehicleSubType: CentralWarehouseStaffConstantsType['vehicleSubType'][number] | null
    location: CentralWarehouseStaffConstantsType['locations'][number] | null
    role: CentralWarehouseStaffConstantsType['roles']
    employmentType: CentralWarehouseStaffConstantsType['employmentTypes'][number]
    staffVendorId: number | null
    bankName: string
    accountName: string
    accountNumber: string
    joinDate: string
    terminationDate: string
    employeeDetail: string
    department: string
    terminationReason: string
    isBlacklist: boolean
    approvalId: number
    is_flexible_checkout: boolean
    job_id: string
  }
  defaultTerminationDate: string
  terminationReasons: GetTerminationReasonsResponseType['data'] | ObjectNullType
}

// ==== Thunk
export const fetchGetCentralWarehouse = createAsyncThunk<
  GetCentralWarehouseStaffResponseType,
  GetCentralWarehouseStaffRequestType,
  RejectValueType
>('centralWarehouseStaff/fetchGetCentralWarehouse', async (params, { rejectWithValue }) => {
  try {
    const qs = serializeQueryToURL(params)

    const { data } = await fetchStaffList({
      location_types: qs?.locationType ? [qs.locationType] : ['WAREHOUSE', 'OFFICE'],
      location_ids: qs?.locationId ? [qs.locationId] : [],
      page_index: Number(qs?.pageIndex || 0) + 1,
      page_size: 20,
      name: qs.name,
      roles: qs.role ? [`AUTH_${qs.role}`] : [],
      module: {
        bank_account: true,
        department_location: true,
        staff_attributes: true,
        staff_vendor: true,
        termination_status: true,
        vehicle: true,
      },
    })

    return {
      data: data.data.map((x) => ({
        account_name: x.bank_account_data.account_name,
        account_number: x.bank_account_data.account_number,
        approvalId: x.staff_termination_status_data.approval_id,
        bank_name: x.bank_account_data.bank_name,
        department: x.staff_location_department_data.department,
        driver_active: x.is_active,
        driver_app_version: x.staff_app_version,
        driver_balance: null,
        driver_card_number: x.card_number,
        driver_full_name: x.full_name,
        driver_id: x.id,
        driver_location: x.location_name,
        driver_location_id: x.location_id,
        driver_location_type: x.location_type,
        driver_phone_number: x.phone_number,
        driver_plate_number: x.plate_number,
        driver_status: x.status,
        driver_verified: x.is_verified,
        employee_detail: x.employee_detail,
        employment_type: x.employment_type,
        flexible_checkout: x.staff_attributes_data.flexible_checkout,
        hub_latitude: 0,
        hub_longitude: 0,
        hub_radius: 0,
        isBlacklist: x.is_blacklist,
        join_date: x.join_date,
        role: x.staff_role,
        terminationReason: x.termination_reason,
        terminationStatus: x.staff_termination_status_data.termination_status,
        terminationStatusCode: x.staff_termination_status_data.termination_status_code,
        termination_date: x.termination_date,
        tracker_id: x.tracker_id,
        user_id: x.user_id,
        vehicle: {
          plate_number: x.plate_number,
          sub_type: x.vehicle_sub_type,
          type: x.vehicle_type,
        },
        vendor: x.staff_vendor_data.vendor_id,
        job_id: x.staff_attributes_data.job_id,
      })),
      pagination: {
        pageIndex: data?.pagination?.page_index || 1,
        totalElement: data?.pagination?.total_elements || 20,
      },
    } as GetCentralWarehouseStaffResponseType
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchGetLocations = createAsyncThunk<
  GetLocationsResponseType,
  GetLocationsRequestType,
  RejectValueType
>('centralWarehouseStaff/fetchGetLocations', async (params, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<GetLocationsResponseType> = await getLocations({
      ...params,
      type: params?.type || 'warehouse',
    })

    return response.data
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchGetDriverRoles = createAsyncThunk<
  GetDriverRolesResponseType,
  GetDriverRolesRequestType,
  RejectValueType
>('centralWarehouseStaff/fetchGetDriverRoles', async (params, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<GetDriverRolesResponseType> = await getDriverRoles(
      params.locationType,
    )

    return response.data
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchGetVehicle = createAsyncThunk<
  GetVehicleResponseType,
  ApiParamsType,
  RejectValueType
>('centralWarehouseStaff/fetchGetVehicle', async (_, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<GetVehicleResponseType> = await getVehicle()

    return response.data
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchGetEmploymentTypes = createAsyncThunk<
  GetEmploymentTypesResponseType,
  ApiParamsType,
  RejectValueType
>('centralWarehouseStaff/fetchGetEmploymentTypes', async (_, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<GetEmploymentTypesResponseType> = await getEmploymentTypes()

    return response.data
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchGetExportDriver = createAsyncThunk<
  GetExportDriverResponseType,
  ApiParamsType,
  RejectValueType
>('centralWarehouseStaff/fetchGetExportDriver', async (_, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<GetExportDriverResponseType> = await getExportDriver()

    saveAs(response.data.data.file_url)
    return response.data
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchPostDriver = createAsyncThunk<
  PostDriverResponseType,
  PostDriverRequestType,
  RejectValueType
>('centralWarehouseStaff/fetchPostDriver', async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await postDriver(params)

    toastSuccess(`Data berhasil terupdate`)
    dispatch(fetchGetCentralWarehouse())
    return response.data
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchPutDriver = createAsyncThunk<
  PutDriverResponseType,
  PutDriverRequestType,
  RejectValueType
>('centralWarehouseStaff/fetchPutDriver', async (params, { rejectWithValue, dispatch }) => {
  try {
    const response = await putDriver(params)
    if (response.data.error?.status && response.data.message === '') {
      toastFailed(response.data.error.message || SOMETHING_WHEN_WRONG)
      return response.data
    }

    toastSuccess(`Data berhasil terupdate`)
    dispatch(fetchGetCentralWarehouse())
    return response.data
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchPutUpdateDriverStatus = createAsyncThunk<
  PutDriverStatusResponseType,
  PutDriverStatusRequestType,
  RejectValueType
>(
  'centralWarehouseStaff/fetchPutUpdateDriverStatus',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response: AxiosResponse<PutDriverStatusResponseType> = await putDriverStatus(params)

      toastSuccess('Berhasil Terupdate')
      dispatch(fetchGetCentralWarehouse())
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchPutUpdateDriverActive = createAsyncThunk<
  PutDriverActiveResponseType,
  PutDriverActiveRequestType,
  RejectValueType
>(
  'centralWarehouseStaff/fetchPutUpdateDriverActive',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response: AxiosResponse<PutDriverActiveResponseType> = await putDriverActive(params)

      toastSuccess('Berhasil Terupdate')
      dispatch(fetchGetCentralWarehouse())
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchPutDriverThenUpdateDriverStatus = createAsyncThunk<
  PutDriverActiveResponseType | null,
  PutDriverAndInactiveRequestType,
  RejectValueType
>(
  'centralWarehouseStaff/fetchPutDriverThenUpdateDriverStatus',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await putDriver(params.driverParam)

      if (response.data.error?.status && response.data.message === '') {
        toastFailed(response.data.error.message || SOMETHING_WHEN_WRONG)
        return null
      }

      const responseActive: AxiosResponse<PutDriverStatusResponseType> = await putDriverActive(
        params.statusChangeParam,
      )

      toastSuccess(`Data berhasil terupdate`)
      dispatch(fetchGetCentralWarehouse())
      return responseActive.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)
export const fetchStaffWarehouse = createAsyncThunk<
  GetVendorStaffResponseType,
  ApiParamsType,
  RejectValueType
>('centralWarehouseStaff/fetchStaffWarehouse', async (_, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<GetVendorStaffResponseType> = await getListVendorStaff()

    return response.data
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchResignationTerminationReasons = createAsyncThunk(
  `centralWarehouseStaff/fetchResignationTerminationReasons`,
  async (_, { dispatch }) => {
    try {
      const { data } = await getTerminationReasons()

      dispatch(setTerminationReason(data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchDepartementLists = createAsyncThunk<
  GetAllDepartementProcessedType[],
  ApiParamsType,
  RejectValueType
>('centralWarehouseStaff/fetchDepartementLists', async (_, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<GetAllDepartementListResponseType> = await getDepartementList()
    const dataObject = response?.data?.data
    const keys = Object.keys(response?.data?.data)
    const dataProcessed: GetAllDepartementProcessedType[] = []

    keys.forEach((key: string) => {
      dataProcessed.push({ content: dataObject[key].names, name: key })
    })

    return dataProcessed
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const putTerminationStaff = createAsyncThunk(
  `centralWarehouseStaff/putTerminationStaff`,
  async (
    {
      staffId,
      staffName,
      payload,
    }: { staffId: number; staffName: string; payload: PutResignationPayloadType },
    { dispatch },
  ) => {
    try {
      await putTermination(staffId, payload)
      toastSuccess(`${staffName} berhasil terupdate`)
      dispatch(fetchGetCentralWarehouse())
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const putRejoinStaff = createAsyncThunk(
  `centralWarehouseStaff/putRejoinStaff`,
  async (
    {
      staffId,
      staffName,
      payload,
    }: { staffId: number; staffName: string; payload: PutResignationPayloadType },
    { dispatch },
  ) => {
    try {
      await putRejoin(staffId, payload)
      toastSuccess(`${staffName} berhasil terupdate`)
      dispatch(fetchGetCentralWarehouse())
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const actSetQuery = (query: ApiParamsType) => (dispatch: AppDispatchType) => {
  dispatch(setQuery(query))
  dispatch(fetchGetCentralWarehouse(query))
}

// ==== Slice
const initialState: CentralWarehouseStaffStateType = {
  isLoading: false,
  query: {
    pageIndex: 0,
  },
  pagination: {
    first: true,
    last: false,
  },
  data: [],
  popupAddEdit: {
    isOpen: false,
    type: 'ADD',
  },
  constants: {
    locations: [],
    roles: [],
    employmentTypes: [],
    vehicleType: [],
    vehicleSubType: [],
    vendorStaff: [],
    allDepartementList: [],
  },
  form: {
    fullName: '',
    cardNumber: '',
    plateNumber: '',
    phoneNumber: '',
    vehicleType: null,
    vehicleSubType: null,
    trackerId: '',
    location: null,
    role: [],
    employmentType: '',
    staffVendorId: null,
    bankName: '',
    accountName: '',
    accountNumber: '',
    joinDate: '',
    terminationDate: '',
    employeeDetail: ``,
    department: ``,
    terminationReason: '',
    isBlacklist: false,
    approvalId: 0,
    is_flexible_checkout: false,
    job_id: '',
  },
  defaultTerminationDate: ``,
  terminationReasons: {},
}

const centralWarehouseStaff = createSlice({
  name: 'centralWarehouseStaff',
  initialState,
  reducers: {
    setQuery: (state, action: PayloadAction<ApiParamsType>) => {
      state.query = {
        ...state.query,
        ...action.payload,
      }
    },
    setOpenPopupAddEdit: (
      state,
      action: PayloadAction<{
        type: CentralWarehouseStaffStateType['popupAddEdit']['type']
        selected?: CentralWarehouseStaffStateType['data'][number]
      }>,
    ) => {
      const { type, selected } = action.payload

      state.popupAddEdit.isOpen = true
      state.popupAddEdit.type = type

      if (type !== 'ADD' && selected) {
        const location = {
          location_id: selected.driver_location_id,
          location_name: selected.driver_location,
          location_type: selected.driver_location_type || ``,
        }
        const vehicleType =
          state.constants.vehicleType.find((item) => item.key === selected.vehicle?.type) || null
        const vehicleSubType =
          state.constants.vehicleSubType.find((item) => item.key === selected.vehicle?.sub_type) ||
          null

        const translateJoinDate = selected?.join_date
          ? dateFormat({
              date: stringToDateObject(selected?.join_date, 'YYYYMMDD'),
              format: 'YYYY-MM-DD',
              locale: 'id',
            })
          : ``
        const translateTerminateDate = selected?.termination_date
          ? dateFormat({
              date: stringToDateObject(selected?.termination_date, 'YYYYMMDD'),
              format: 'YYYY-MM-DD',
              locale: 'id',
            })
          : ``

        state.popupAddEdit.selected = selected

        state.form.fullName = selected.driver_full_name
        state.form.cardNumber = selected.driver_card_number
        state.form.plateNumber = selected.driver_plate_number
        state.form.phoneNumber = selected.driver_phone_number.replace('+62', '')
        state.form.trackerId = selected.tracker_id
        state.form.vehicleType = vehicleType
        state.form.vehicleSubType = vehicleSubType
        state.form.location = location
        state.form.role = selected.role.map((item) => item.authority_role)
        state.form.employmentType = selected.employment_type
        state.form.staffVendorId = selected.vendor
        state.form.bankName = selected.bank_name
        state.form.accountName = selected.account_name
        state.form.accountNumber = selected.account_number
        state.form.joinDate = translateJoinDate
        state.form.terminationDate = translateTerminateDate
        state.defaultTerminationDate = translateTerminateDate
        state.form.department = selected.department
        state.form.employeeDetail = selected.employee_detail
        state.form.terminationReason = selected.terminationReason
        state.form.isBlacklist = selected.isBlacklist
        state.form.is_flexible_checkout = selected.flexible_checkout
        state.form.job_id = selected.job_id
      }
    },
    setClosePopupAddEdit: (state) => {
      state.popupAddEdit.isOpen = false
      state.form = initialState.form
    },
    setForm: (
      state,
      action: PayloadAction<{
        key: keyof CentralWarehouseStaffStateType['form']
        value: unknown
        constants?: CentralWarehouseStaffStateType['constants']['roles']
      }>,
    ) => {
      const { key, value } = action.payload

      state.form[key] = value as never
    },
    setTerminationReason: (state, action: PayloadAction<GetTerminationReasonsResponseType>) => {
      const { data } = action.payload
      state.terminationReasons = data as unknown as GetTerminationReasonsResponseType['data']
    },
  },
  extraReducers: withLoadingReducer(
    (builder: ActionReducerMapBuilder<CentralWarehouseStaffStateType>) => {
      builder
        .addCase(fetchGetCentralWarehouse.pending, (state) => {
          state.data = []
        })
        .addCase(fetchGetCentralWarehouse.fulfilled, (state, action) => {
          const { data, pagination } = action.payload

          state.data = data
          state.pagination = {
            first: pagination.pageIndex === 1,
            last: pagination.totalElement < 20,
          }
        })
        .addCase(fetchGetLocations.fulfilled, (state, action) => {
          state.constants.locations = action.payload.content
        })
        .addCase(fetchGetDriverRoles.fulfilled, (state, action) => {
          state.constants.roles = action.payload.data
        })
        .addCase(fetchGetVehicle.fulfilled, (state, action) => {
          const { vehicle_type, vehicle_sub_type } = action.payload.data

          state.constants.vehicleType = vehicle_type
          state.constants.vehicleSubType = vehicle_sub_type
        })
        .addCase(fetchGetEmploymentTypes.fulfilled, (state, action) => {
          state.constants.employmentTypes = action.payload.data
        })
        .addCase(fetchPutDriver.fulfilled, (state) => {
          centralWarehouseStaff.caseReducers.setClosePopupAddEdit(state)
        })
        .addCase(fetchPutDriverThenUpdateDriverStatus.fulfilled, (state) => {
          centralWarehouseStaff.caseReducers.setClosePopupAddEdit(state)
        })
        .addCase(fetchPostDriver.fulfilled, (state) => {
          centralWarehouseStaff.caseReducers.setClosePopupAddEdit(state)
        })
        .addCase(fetchStaffWarehouse.fulfilled, (state, action) => {
          const filterActive = action.payload.data.data.data.filter(
            (staff) => staff.status === 'active',
          )
          state.constants.vendorStaff = filterActive
        })
        .addCase(fetchDepartementLists.fulfilled, (state, action) => {
          state.constants.allDepartementList = action.payload
        })
    },
    [
      fetchGetCentralWarehouse,
      fetchGetExportDriver,
      fetchPutUpdateDriverStatus,
      fetchPostDriver,
      fetchPutDriver,
    ],
  ),
})

export const {
  setQuery,
  setOpenPopupAddEdit,
  setClosePopupAddEdit,
  setForm,
  setTerminationReason,
} = centralWarehouseStaff.actions
export default centralWarehouseStaff.reducer
