import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  GetTop25SkuV2ResponseType,
  GetStoAutoRecountListResponseType,
  GetStockOpnameReviewGroupOrTaskResponseType,
  StockOpnameReviewDataItemType,
  RackItemInStoReviewType,
  TotalProductCountPhysicalsType,
} from 'utils/apiList/stockOpname'
import {
  recountStockOpname,
  submitStockOpname,
  fetchStoReviewList,
  fetchTop25SkuList,
  submitTop25SkuList,
} from './stockOpnameCheckAdjustmentThunk'

export interface StockOpnameTaskDataStateType {
  id: number
  uniqueKey: string
  stockOpnameNumber: string
  details: {
    rackId: number
    uniqueKey: string
    rackName: string
    availableSku: string[]
  }[]
}

export interface StockOpnameReviewDataStateType {
  stoNumber: string
  latestCount: number
  list: {
    uniqueKey: string
    rackId: number
    rackName: string
    totalRackCountSystem: {
      available: number
      bad: number
      lost: number
      qty: number
    }
    totalRackCountPhysicals: {
      version: number
      available: number
      bad: number
      gap: number
      isSkipped: boolean
      uniqueKey: string
      note: string
    }[]
    skus: {
      uniqueKey: string
      skuNumber: string
      productName: string
      uom: string
      countSystem: {
        available: number
        bad: number
        lost: number
        qty: number
      }
      countPhysicals: {
        version: number
        available: number
        bad: number
        gap: number
        isSkipped: boolean
        note: string
      }[]
    }[]
  }[]
  totalCountSystem: {
    available: number
    bad: number
    lost: number
    qty: number
  }
  totalCountPhysicals: {
    version: number | null
    available: number | null
    bad: number | null
    gap: number | null
    isSkipped: boolean
    note: string
  }[]
}

export interface QueryStateType {
  rackName?: string
  productName?: string
  pageIndex: number
  pageSize: number
}

export type TotalProductCountPhysicalsUpdatedType = TotalProductCountPhysicalsType & {
  uniqueKey: string
}
export type RackItemInStoReviewUpdatedType = RackItemInStoReviewType & {
  uniqueKey: string
}

export type StockOpnameReviewDataV2ItemType = Omit<
  StockOpnameReviewDataItemType,
  'totalProductCountPhysicals' | 'racks'
> & {
  uniqueKey: string
  totalProductCountPhysicals: TotalProductCountPhysicalsUpdatedType[]
  racks: RackItemInStoReviewUpdatedType[]
}

export interface StockOpnameCheckAdjustmentStateType {
  isLoading: boolean
  shouldRedirectToList: boolean
  stockOpnameId: number
  stockOpnameTaskData: StockOpnameTaskDataStateType[]
  stockOpnameReviewData: StockOpnameReviewDataStateType
  stockOpnameReviewDataV2: Omit<GetStockOpnameReviewGroupOrTaskResponseType['data'], 'list'> & {
    list: StockOpnameReviewDataV2ItemType[]
  }
  selectedStockOpnameReviewState: {
    isSelectedAll: boolean
    isGapOnly: boolean
  }
  selectedParentRackId: number[]
  excludedParentRackId: number[]
  selectedParentProductId: number[]
  excludedParentProductId: number[]
  selectedChildItemId: string[]
  shouldDisableInputIsGapOnly: boolean
  shouldDisableInputIsSelectedAll: boolean
  modalTop25Gap: {
    isOpen: boolean
    listData: GetTop25SkuV2ResponseType['data']
    selectedIds: number[]
    queryParams: {
      pageIndex: number
      pageSize: number | null
    }
  }
  modalAutoRecount: {
    isOpen: boolean
    data: GetStoAutoRecountListResponseType['data']
  }
  paramStockOpnameReviewDataV2: {
    pageIndex: number
    pageSize: number
    rackName?: string
    productName?: string
  }
  source: '' | 'group' | 'item'
}

export const initialState: StockOpnameCheckAdjustmentStateType = {
  isLoading: false,
  shouldRedirectToList: false,
  stockOpnameId: 0,
  stockOpnameTaskData: [],
  stockOpnameReviewData: {
    latestCount: 0,
    list: [],
    stoNumber: '',
    totalCountSystem: {
      available: 0,
      bad: 0,
      lost: 0,
      qty: 0,
    },
    totalCountPhysicals: [],
  },
  stockOpnameReviewDataV2: {
    stoNumber: '',
    latestCount: 0,
    list: [],
    totalCountSystem: {
      available: 0,
      bad: 0,
      lost: 0,
      qty: 0,
    },
    totalCountPhysicals: [],
  },
  selectedStockOpnameReviewState: {
    isGapOnly: false,
    isSelectedAll: false,
  },
  selectedParentRackId: [],
  excludedParentRackId: [],
  selectedParentProductId: [],
  excludedParentProductId: [],
  selectedChildItemId: [],
  shouldDisableInputIsGapOnly: false,
  shouldDisableInputIsSelectedAll: false,
  modalTop25Gap: {
    isOpen: false,
    listData: [],
    selectedIds: [],
    queryParams: {
      pageIndex: 1,
      pageSize: null,
    },
  },
  modalAutoRecount: {
    isOpen: false,
    data: {
      criteria: {
        accuracy: '',
        materiality: 0,
      },
      summary: {
        totalSloc: 0,
        totalSku: 0,
        totalGap: 0,
        totalCogs: 0,
      },
      items: [],
    },
  },
  paramStockOpnameReviewDataV2: {
    pageIndex: 1,
    pageSize: 10,
  },
  source: '',
}

export interface SetReviewCheckerType {
  type: 'LIST' | 'SKU'
  listIndex: number
  skuIndex: number
  uniqueKey: string
  value: boolean
  rackId: number
}

const stockOpnameCheckAdjustment = createSlice({
  name: 'stockOpnameCheckAdjustment',
  initialState,
  reducers: {
    resetState: () => initialState,
    setStoAdjustmentComesFrom: (state, action) => {
      state.source = action.payload
    },
    setIsLoadingStoAdjustmentList: (state, action) => {
      state.isLoading = action.payload
    },
    setParamStockOpnameReviewDataV2: (
      state,
      action: PayloadAction<
        Partial<StockOpnameCheckAdjustmentStateType['paramStockOpnameReviewDataV2']>
      >,
    ) => {
      state.paramStockOpnameReviewDataV2 = {
        ...state.paramStockOpnameReviewDataV2,
        ...action.payload,
      }
    },
    setStockOpnameId: (state, action) => {
      state.stockOpnameId = action.payload
    },
    setSelectedStockOpnameReviewState: (
      state,
      action: PayloadAction<{
        field: keyof StockOpnameCheckAdjustmentStateType['selectedStockOpnameReviewState']
        value: boolean
      }>,
    ) => {
      const { field, value } = action.payload
      state.selectedStockOpnameReviewState.isSelectedAll = false
      state.selectedStockOpnameReviewState.isGapOnly = false
      state.selectedStockOpnameReviewState[field] = value as never
    },
    resetSelectedAndExcludedRackIds: (state) => {
      state.excludedParentRackId = []
      state.selectedParentRackId = []
      state.selectedChildItemId = []
    },
    resetSelectedAndExcludedProductIds: (state) => {
      state.excludedParentProductId = []
      state.selectedParentProductId = []
      state.selectedChildItemId = []
    },
    setIdsOfParentChildAndExcluded: (state, action) => {
      const { excludedParentRackId, selectedParentRackId, selectedChildItemId } = action.payload
      if (excludedParentRackId) state.excludedParentRackId = excludedParentRackId
      if (selectedParentRackId) state.selectedParentRackId = selectedParentRackId
      if (selectedChildItemId) state.selectedChildItemId = selectedChildItemId
    },
    setIdsOfParentChildAndExcludedV2: (state, action) => {
      const { excludedParentProductId, selectedParentProductId, selectedChildItemId } =
        action.payload
      if (excludedParentProductId) state.excludedParentProductId = excludedParentProductId
      if (selectedParentProductId) state.selectedParentProductId = selectedParentProductId
      if (selectedChildItemId) state.selectedChildItemId = selectedChildItemId
    },
    setIsOpenModalTop25Gap: (state, action) => {
      state.modalTop25Gap.isOpen = action.payload
    },
    toggleCheckboxItemOfModalTopGap: (state, action) => {
      const productId = action.payload
      if (state.modalTop25Gap.selectedIds.includes(productId)) {
        const idx = state.modalTop25Gap.selectedIds.findIndex((value) => value === productId)
        state.modalTop25Gap.selectedIds.splice(idx, 1)
      } else {
        state.modalTop25Gap.selectedIds.push(productId)
      }
    },
    toggleCheckboxHeaderByIsCheckedAll: (state, action) => {
      const isCheckedAll = action.payload

      if (isCheckedAll) {
        state.modalTop25Gap.selectedIds = []
        return
      }
      const listIdToBeAdded = state.modalTop25Gap.listData
        .filter((item) => !state.modalTop25Gap.selectedIds.includes(item.productId))
        ?.map((item) => item.productId)
      state.modalTop25Gap.selectedIds = [...state.modalTop25Gap.selectedIds, ...listIdToBeAdded]
    },
    resetModalTop25Gap: (state) => {
      state.modalTop25Gap = initialState.modalTop25Gap
    },
    setModalTop25GapQueryParams: (state, action) => {
      state.modalTop25Gap.queryParams = { ...state.modalTop25Gap.queryParams, ...action.payload }
    },
    toggleModalAutoRecount: (state, action) => {
      state.modalAutoRecount.isOpen = action.payload
    },
    resetModalAutoRecount: (state) => {
      state.modalAutoRecount = initialState.modalAutoRecount
    },
    fetchStoAutoRecountListOnSuccess: (state, action) => {
      const { data } = action.payload
      state.modalAutoRecount.data.criteria = data.criteria
      state.modalAutoRecount.data.summary = data.summary
      state.modalAutoRecount.data.items = data.items
    },
  },
  extraReducers(builder) {
    builder
      .addCase(recountStockOpname.pending, (state) => {
        state.isLoading = true
      })
      .addCase(recountStockOpname.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(recountStockOpname.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(submitStockOpname.pending, (state) => {
        state.isLoading = true
      })
      .addCase(submitStockOpname.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(submitStockOpname.fulfilled, (state) => {
        state.isLoading = false
        state.shouldRedirectToList = true
      })
      .addCase(fetchTop25SkuList.fulfilled, (state, action) => {
        state.modalTop25Gap.listData = action.payload
        state.modalTop25Gap.isOpen = true
      })
      .addCase(submitTop25SkuList.fulfilled, (state) => {
        state.modalTop25Gap.isOpen = false
        state.shouldRedirectToList = true
      })
      .addCase(fetchStoReviewList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchStoReviewList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchStoReviewList.fulfilled, (state, action) => {
        const {
          responseData: { data, pagination },
          listParentProductIdToAdd,
          listChildIdToAdd,
        } = action.payload
        state.isLoading = false

        if (
          state.selectedStockOpnameReviewState.isSelectedAll ||
          state.selectedStockOpnameReviewState.isGapOnly
        ) {
          state.selectedChildItemId = [...state.selectedChildItemId, ...listChildIdToAdd]
          state.selectedParentProductId = [
            ...state.selectedParentProductId,
            ...listParentProductIdToAdd,
          ]
        }

        state.stockOpnameReviewDataV2 = {
          ...data,
          list: data.list.map((item: StockOpnameReviewDataItemType) => ({
            ...item,
            uniqueKey: window.crypto.randomUUID(),
            totalProductCountPhysicals: item.totalProductCountPhysicals.map(
              (itemProductCountPhysicals) => ({
                ...itemProductCountPhysicals,
                uniqueKey: window.crypto.randomUUID(),
              }),
            ),
            racks: item.racks.map((rackItem) => ({
              ...rackItem,
              uniqueKey: window.crypto.randomUUID(),
            })),
          })),
        }

        state.paramStockOpnameReviewDataV2.pageIndex = pagination.pageIndex
        state.paramStockOpnameReviewDataV2.pageSize = pagination.pageSize
      })
  },
})

export const {
  resetState,
  setIsLoadingStoAdjustmentList,
  setStoAdjustmentComesFrom,
  setParamStockOpnameReviewDataV2,
  setStockOpnameId,
  setSelectedStockOpnameReviewState,
  resetSelectedAndExcludedRackIds,
  resetSelectedAndExcludedProductIds,
  setIdsOfParentChildAndExcluded,
  setIdsOfParentChildAndExcludedV2,
  setIsOpenModalTop25Gap,
  toggleCheckboxItemOfModalTopGap,
  toggleCheckboxHeaderByIsCheckedAll,
  setModalTop25GapQueryParams,
  resetModalTop25Gap,
  toggleModalAutoRecount,
  resetModalAutoRecount,
  fetchStoAutoRecountListOnSuccess,
} = stockOpnameCheckAdjustment.actions
export default stockOpnameCheckAdjustment.reducer
