import { RolesKeyType } from 'constant/roles'
import { lazy } from 'react'

import generateRouterDetail from 'utils/helpers/generateRouterDetail'

const ApprovalInjection = lazy(() => import('../pages/ApprovalInjection'))
const PaymentGatewayAdmin = lazy(() => import('../pages/PaymentGatewayAdmin'))

export const ApprovalInjectionRouter: RouterFeatureInterface<
  'ApprovalInjectionRouter',
  '/dashboard/approval-injection'
> = {
  name: 'ApprovalInjectionRouter',
  Component: ApprovalInjection,
  path: '/dashboard/approval-injection',
  routeType: 'PRIVATE',
  allowedRoles: ['AUTH_HO_CS_SPV', 'AUTH_HO_CS_LEADER'],
  isDashboard: true,
}

export const PaymentGatewayAdminRouter: RouterFeatureInterface<
  'PaymentGatewayAdmin',
  '/dashboard/payment-gateway-admin'
> = {
  name: 'PaymentGatewayAdmin',
  Component: PaymentGatewayAdmin,
  path: '/dashboard/payment-gateway-admin',
  routeType: 'PRIVATE',
  allowedRoles: [
    'AUTH_HO_CS_SPV',
    'AUTH_HO_CS_SHIFT_LEAD',
    'AUTH_PRODUCT_MANAGER',
    'AUTH_QUALITY_ENGINEERING',
    'AUTH_HO_IA_HEAD',
    'AUTH_HO_IA_MANAGER',
    'AUTH_HO_IA_SPV',
    'AUTH_HO_IA_STAFF',
  ],
  isDashboard: true,
}

export const roleAllowedForUpdatePaymentGateway: RolesKeyType[] = [
  'AUTH_HO_PRODUCT_MANAGER',
  'AUTH_HO_QUALITY_ENGINEERING',
]

const PaymentRouter = [ApprovalInjectionRouter, PaymentGatewayAdminRouter]

export const PaymentRouterDetail = generateRouterDetail(PaymentRouter)

export default PaymentRouter
