import { lazy } from 'react'

import { ProductAssetListRBACPage } from '../pages/ProductAssetList/rbac'
import { AddEditProductAssetRBACPage } from '../pages/AddEditAsset/rbac'

const ProductAssetList = lazy(() => import('../pages/ProductAssetList'))
const AddEditProductAsset = lazy(() => import('../pages/AddEditAsset'))

export const ProductAssetListRouter: RouterFeatureInterface<
  'productAssetList',
  '/dashboard/asset/product-asset-list'
> = {
  name: 'productAssetList',
  Component: ProductAssetList,
  path: '/dashboard/asset/product-asset-list',
  rbacPageID: ProductAssetListRBACPage.pageID,
  routeType: 'PRIVATE',
  allowedRoles: [],
  isDashboard: true,
}

export const AddNewProductAssetRouter: RouterFeatureInterface<
  'addNewProductAsset',
  '/dashboard/asset/product-asset-list/add'
> = {
  name: 'addNewProductAsset',
  Component: AddEditProductAsset,
  path: '/dashboard/asset/product-asset-list/add',
  rbacPageID: AddEditProductAssetRBACPage.pageID,
  routeType: 'PRIVATE',
  allowedRoles: [],
  isDashboard: true,
}

export const EditProductAssetRouter: RouterFeatureInterface<
  'editProductAsset',
  '/dashboard/asset/product-asset-list/edit/:id'
> = {
  name: 'editProductAsset',
  Component: AddEditProductAsset,
  path: '/dashboard/asset/product-asset-list/edit/:id',
  rbacPageID: AddEditProductAssetRBACPage.pageID,
  routeType: 'PRIVATE',
  allowedRoles: [],
  isDashboard: true,
}

export const ProductAssetRouter = [
  ProductAssetListRouter,
  AddNewProductAssetRouter,
  EditProductAssetRouter,
]
