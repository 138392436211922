import createReducer from "storeContext/reducers/createReducer";

export const RACK_DETAIL_SET_IS_BTN_DELETE_BUSY =
  "rackDetail/SET_IS_BTN_DELETE_BUSY";

export const RACK_DETAIL_FETCH_LIST = "rackDetail/FETCH_LIST";
export const RACK_DETAIL_FETCH_LIST_SUCCESS = "rackDetail/FETCH_LIST_SUCCESS";
export const RACK_DETAIL_FETCH_LIST_FAILURE = "rackDetail/FETCH_LIST_FAILURE";
export const RACK_DETAIL_TOGGLE_CHECK_ITEM_RACK_DATA =
  "rackDetail/TOGGLE_CHECK_ITEM_RACK_DATA";

export const RACK_DETAIL_TOGGLE_MODAL_UPLOAD = "rackDetail/TOGGLE_MODAL_UPLOAD";
export const RACK_DETAIL_TOGGLE_CHECK_ALL_ITEM =
  "rackDetail/TOGGLE_CHECK_ALL_ITEM";
export const RACK_DETAIL_TOGGLE_MODAL_DELETE_SINGLE_ITEM =
  "rackDetail/TOGGLE_MODAL_DELETE_SINGLE_ITEM";
export const RACK_DETAIL_TOGGLE_MODAL_DELETE_MULTIPLE_ITEM =
  "rackDetail/TOGGLE_MODAL_DELETE_MULTIPLE_ITEM";

export const RACK_DETAIL_CLOSE_MODAL = "rackDetail/CLOSE_MODAL";

export const RACK_DETAIL_SET_STATUS_RACK_ITEM =
  "rackDetail/SET_STATUS_RACK_ITEM";
export const RACK_DETAIL_SET_QUERY = "rackDetail/SET_QUERY";
export const RACK_DETAIL_SET_DOWNLOAD_TEMPLATE_URL =
  "rackDetail/SET_DOWNLOAD_TEMPLATE_URL";

export const initialStateRackDetail = {
  isLoading: true,
  location: null,
  rackList: [],
  data: null,
  query: {
    pageIndex: 0,
    rackName: null,
    active: ""
  },
  pagination: {
    page: 0,
    limit: 20,
    totalData: 0
  },
  rackTemplateUrl: "",
  isModalDeleteOpen: false,
  isModalUploadOpen: false,
  modalDeleteType: "",
  idRackToDelete: 0,
  listIdToDelete: [],
  isBtnDeleteBusy: false
};

const rackDetailReducer = createReducer({
  [RACK_DETAIL_FETCH_LIST]: (state) => {
    state.isLoading = true;
  },
  [RACK_DETAIL_FETCH_LIST_SUCCESS]: (state, action) => {
    state.isLoading = false;
    state.location = action.payload.location;
    state.rackList = action.payload.racks.content;
    state.pagination.page = action.payload.racks.number + 1;
    state.pagination.totalData = action.payload.racks.totalElements;
  },
  [RACK_DETAIL_FETCH_LIST_FAILURE]: (state) => {
    state.isLoading = false;
  },
  [RACK_DETAIL_TOGGLE_CHECK_ITEM_RACK_DATA]: (state, action) => {
    const rackId = action.payload;
    const index = state.rackList.findIndex(
      (itemRack) => itemRack.id === rackId
    );
    state.rackList[index].isChecked = !state.rackList[index].isChecked;
  },
  [RACK_DETAIL_TOGGLE_MODAL_UPLOAD]: (state, action) => {
    state.isModalUploadOpen = action.payload;
  },
  [RACK_DETAIL_TOGGLE_MODAL_DELETE_SINGLE_ITEM]: (state, action) => {
    state.isModalDeleteOpen = true;
    state.idRackToDelete = action.payload;
  },
  [RACK_DETAIL_TOGGLE_MODAL_DELETE_MULTIPLE_ITEM]: (state, action) => {
    state.isModalDeleteOpen = true;
    state.listIdToDelete = action.payload;
  },
  [RACK_DETAIL_CLOSE_MODAL]: (state) => {
    state.isModalDeleteOpen = false;
    state.listIdToDelete = [];
    state.idRackToDelete = 0;
  },
  [RACK_DETAIL_SET_IS_BTN_DELETE_BUSY]: (state, action) => {
    state.isBtnDeleteBusy = action.payload;
  },
  [RACK_DETAIL_SET_STATUS_RACK_ITEM]: (state, action) => {
    const rackId = action.payload;
    const index = state.rackList.findIndex(
      (itemRack) => itemRack.id === rackId
    );
    state.rackList[index].active = !state.rackList[index].active;
  },
  [RACK_DETAIL_SET_QUERY]: (state, action) => {
    state.query = action.payload;
  },
  [RACK_DETAIL_SET_DOWNLOAD_TEMPLATE_URL]: (state, action) => {
    state.rackTemplateUrl = action.payload;
  },
  [RACK_DETAIL_TOGGLE_CHECK_ALL_ITEM]: (state, action) => {
    state.rackList = state.rackList.map((itemRack) => ({
      ...itemRack,
      isChecked: action.payload
    }));
  }
});
export default rackDetailReducer;

export const rackDetailFetchList = () => ({
  type: RACK_DETAIL_FETCH_LIST
});

export const rackDetailFetchListSuccess = (data) => ({
  type: RACK_DETAIL_FETCH_LIST_SUCCESS,
  payload: data
});

export const rackDetailFetchListFailure = () => ({
  type: RACK_DETAIL_FETCH_LIST_FAILURE
});

export const rackDetailToggleCheckItem = (rackId) => ({
  type: RACK_DETAIL_TOGGLE_CHECK_ITEM_RACK_DATA,
  payload: rackId
});

export const rackDetailToggleModalUpload = (isModalOpen) => ({
  type: RACK_DETAIL_TOGGLE_MODAL_UPLOAD,
  payload: isModalOpen
});

export const rackDetailToggleModalDeleteSingleItem = (id) => ({
  type: RACK_DETAIL_TOGGLE_MODAL_DELETE_SINGLE_ITEM,
  payload: id
});

export const rackDetailToggleModalDeleteMultipleItem = (ids) => ({
  type: RACK_DETAIL_TOGGLE_MODAL_DELETE_MULTIPLE_ITEM,
  payload: ids
});

export const rackDetailCloseModal = () => ({ type: RACK_DETAIL_CLOSE_MODAL });

export const rackDetailSetIsBtnDeleteBusy = (isLoading) => ({
  type: RACK_DETAIL_SET_IS_BTN_DELETE_BUSY,
  payload: isLoading
});

export const rackDetailSetStatusRackItem = (rackId) => ({
  type: RACK_DETAIL_SET_STATUS_RACK_ITEM,
  payload: rackId
});

export const rackDetailSetQuery = (queryObj) => ({
  type: RACK_DETAIL_SET_QUERY,
  payload: queryObj
});

export const rackDetailSetDownloadTemplateUrl = (url) => ({
  type: RACK_DETAIL_SET_DOWNLOAD_TEMPLATE_URL,
  payload: url
});

export const rackDetailToggleCheckAllItem = (isChecked) => ({
  type: RACK_DETAIL_TOGGLE_CHECK_ALL_ITEM,
  payload: isChecked
});
