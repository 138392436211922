import { Get, Post } from 'config/apiServiceConfig'

export type GetVoucherPriorityResponseType = {
  data: {
    list: {
      id: number
      priority: number
      code: string
    }[]
  }
  error: {
    status: boolean
    message: string
    code: number
  }
}

export type PostVoucherPriorityRequestType = {
  payload: {
    data: {
      voucherId: number
      voucherPriority: number
    }[]
  }
}

export const getVoucherPriority = () =>
  Get<GetVoucherPriorityResponseType>({ url: '/api/promo/voucher-pinning' })

export const postVoucherPriority = ({ payload }: PostVoucherPriorityRequestType) =>
  Post({ url: '/api/promo/voucher-pinning', data: payload })
