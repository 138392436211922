import { AppDispatchType, RootStateType } from 'store'
import {
  getXdockList,
  getStaffSorterByLocation,
  assignXdockTask,
} from 'features/SupplyOrder/services/xdock'
import { callErrorMsg } from 'helpers/errorMsg'
import { getCleanQueryObj } from 'utils/queryParamsURL'
import { toastSuccess } from 'utils/toast'
import { setIsLoading, setXdockList, setModalAssignCrew, resetModalAssignCrew } from './slice'

export const fetchXdockList =
  () => async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { query } = getState().xdock

    try {
      dispatch(setIsLoading(true))

      const paramsToSubmit = getCleanQueryObj(query)
      const { data } = await getXdockList(paramsToSubmit)

      dispatch(
        setXdockList({
          listData: data.data,
          totalData: data.pagination.number_of_elements,
        }),
      )
    } catch (err) {
      callErrorMsg(err)
    } finally {
      dispatch(setIsLoading(false))
    }
  }

export const fetchAssigneeList = (locationId: number) => async (dispatch: AppDispatchType) => {
  try {
    const { data } = await getStaffSorterByLocation(locationId)

    const listStaff = data.data?.map((item) => ({ id: item.id, fullName: item.fullName }))
    dispatch(
      setModalAssignCrew({
        isOpen: true,
        originalStaffList: listStaff,
        staffList: listStaff,
      }),
    )
  } catch (err) {
    callErrorMsg(err)
  }
}

export const actSubmitAssignee =
  () => async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    try {
      const { modalAssignCrew } = getState().xdock
      const { data } = await assignXdockTask(modalAssignCrew.xdockId, {
        staff_id: modalAssignCrew.selectedStaffId,
      })

      toastSuccess(data.message)
      dispatch(resetModalAssignCrew())
      dispatch(fetchXdockList())
    } catch (err) {
      callErrorMsg(err)
    }
  }
