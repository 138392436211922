import { Patch } from 'config/apiServiceConfig'
import apiServices from 'config/apiServiceApiGateway'
import { OrderFraudQueryType, OrderFraudType } from '../@types/Fraud'

const getFraudHeaders = () => ({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'X-Username': 'Fraud',
})

// export type OrderFraudType = {
//   address: string
//   customerId: number
//   customerPotentialFraudCount: number
//   customerStatus: number
//   id: number
//   invoice: string
//   isNewBuyer: boolean
//   name: string
//   reason: string
//   status: 'In Review'
// }

export type GetFraudStatusReponseType = {
  order_frauds: OrderFraudType[]
}

export type PatchFraudStatusPayloadType = {
  isApproved: boolean
  reason?: string
  internalReason?: string
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const getFraudStatus_Fraud = (queries: PartialBy<OrderFraudQueryType, 'search'>) =>
  apiServices.Get<GetFraudStatusReponseType>({
    url: '/fraud/internal/:version/order_fraud',
    version: 'v1',
    params: queries,
  })

// eslint-disable-next-line @typescript-eslint/naming-convention
export const patchFraudStatus_Fraud = (id: number, data: PatchFraudStatusPayloadType) =>
  Patch({
    url: `/api/order/fraud/${id}`,
    data,
    config: {
      headers: getFraudHeaders(),
    },
  })
