import { createAsyncThunk } from '@reduxjs/toolkit'

import { deleteCategory, getHubList } from 'utils/apiList/category'

import { callErrorMsg } from 'helpers/errorMsg'
import { toastSuccess } from 'utils/toast'

export const SLICE_NAME = 'category'

export const removeCategory = createAsyncThunk(
  `${SLICE_NAME}/removeCategory`,
  async (params: { id?: number }, { rejectWithValue, getState }) => {
    const {
      category: { selectedCategory },
    } = getState() as StoreStateType
    try {
      const res = await deleteCategory({ id: params.id || selectedCategory.category_id })
      toastSuccess(`berhasil menghapus ${selectedCategory.category_name}`)
      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchHubList = createAsyncThunk(
  `${SLICE_NAME}/fetchHubList`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getHubList()
      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)
