import {
  SET_DATA_LOCATIONS,
  SET_IS_LOADING_PACKER,
  SET_DATA_PAGINATION,
  SET_DATA_PACKERS,
  SET_IS_POPUP_DETAIL_SHOW,
  SET_SELECTED_PACKER,
  SET_IS_POPUP_CHECK_SHOW,
  SET_LAST_PARAMS,
  SET_SELECTED_LOCATION,
  SET_LOCATIONS,
  SET_IS_POPUP_CALL_DRIVER_SHOW,
  SET_IS_POPUP_CANCEL_SHOW,
  SET_DRIVERS,
  SET_IS_POPUP_CHANGE_DRIVER_SHOW,
  SET_IS_POPUP_CHECK_RESI_SHOW,
  SET_IS_POPUP_PRINT_LIST_SHOW,
  SET_IS_FROM_QTY,
  SET_IS_POPUP_INPUT_AUTH_SHOW,
  SET_IS_SKU_MANUAL_INPUT_NOTES,
  SET_IS_SKU_MANUAL_INPUT_AUTH,
  SET_SELECTED_PRODUCT,
  SET_INTERSECT_HUB,
  SET_IS_POPUP_CANCEL_AUTO_ASSIGN_SHOW,
} from 'storeContext/actionsType/packer'

import {
  getPackers,
  getPacker,
  putPacker,
  getPackerInvoice,
  putPackerQty,
  cancelPacker,
  getDrivers,
  putCallDriver,
  putChangeDriver,
  putPickupOrder,
  returnPacker,
  listPacker,
  resiPacker,
  detailOrder,
  getSkuManualInputNotes,
  postSkuManualInputAuth,
  postPackerInvoice,
  getPackersSuper,
  postPackerInvoiceSuper,
  putPackerQtySuper,
  getPackerOrderIdSuper,
  cancelAutoAssign,
  getOrdersV2,
} from 'utils/api'
import { actHandleCloseCallDriverV2, actHandleCloseCallExternalDriverV2 } from './onWaitingDriver'
import { toastFailed, toastSuccess } from 'utils/toast'
import { printPdfLink } from 'helpers'
import { callErrorMsg } from 'helpers/errorMsg'
import { getAllLocation, getSuperIntersectHub } from 'utils/apiList/allOrder'
import {
  getDataReceiptPackerOrder,
  getDataReceiptPackerOrderSuper,
  postOrderPooling_OrderPooling,
} from 'utils/apiList/packer'
import {
  setDataPagination as setDataPaginationPicker,
  setDataPicker,
  setLastParams as setLastParamsPicker,
} from '../picker'
import { log } from 'utils/helpers/dataDogLog'

export function setIsFromQty(payload) {
  return {
    type: SET_IS_FROM_QTY,
    payload: payload,
  }
}

export function setIsPopupPrintListShow(payload) {
  return {
    type: SET_IS_POPUP_PRINT_LIST_SHOW,
    payload: payload,
  }
}

export function setIsPopupCheckResiShow(payload) {
  return {
    type: SET_IS_POPUP_CHECK_RESI_SHOW,
    payload: payload,
  }
}

export function setIsPopupChangeDriverShow(payload) {
  return {
    type: SET_IS_POPUP_CHANGE_DRIVER_SHOW,
    payload: payload,
  }
}

export function setDrivers(payload) {
  return {
    type: SET_DRIVERS,
    payload: payload,
  }
}

export function setIsPopupCancelShow(payload) {
  return {
    type: SET_IS_POPUP_CANCEL_SHOW,
    payload: payload,
  }
}
export function setIsPopupCancelAutoAssignShow(payload) {
  return {
    type: SET_IS_POPUP_CANCEL_AUTO_ASSIGN_SHOW,
    payload: payload,
  }
}

export function setIsPopupCallDriverShow(payload) {
  return {
    type: SET_IS_POPUP_CALL_DRIVER_SHOW,
    payload: payload,
  }
}

export function setLocations(payload) {
  return {
    type: SET_LOCATIONS,
    payload: payload,
  }
}
export function setSelectedLocation(payload) {
  return {
    type: SET_SELECTED_LOCATION,
    payload: payload,
  }
}
export function setLastParams(payload) {
  return {
    type: SET_LAST_PARAMS,
    payload: payload,
  }
}
export function setIsPopupCheckShow(payload) {
  return {
    type: SET_IS_POPUP_CHECK_SHOW,
    payload: payload,
  }
}
export function setSelectedPacker(payload) {
  return {
    type: SET_SELECTED_PACKER,
    payload: payload,
  }
}
export function setIsPopupDetailShow(payload) {
  return {
    type: SET_IS_POPUP_DETAIL_SHOW,
    payload: payload,
  }
}
export function setIsPopupInputAuthShow(payload) {
  return {
    type: SET_IS_POPUP_INPUT_AUTH_SHOW,
    payload: payload,
  }
}
export function setSkuManualInputNotes(payload) {
  return {
    type: SET_IS_SKU_MANUAL_INPUT_NOTES,
    payload: payload,
  }
}
export function setIsSkuManualInputAuth(payload) {
  return {
    type: SET_IS_SKU_MANUAL_INPUT_AUTH,
    payload: payload,
  }
}
export function setDataLocations(payload) {
  return {
    type: SET_DATA_LOCATIONS,
    payload: payload,
  }
}
export function setIsLoading(payload) {
  return {
    type: SET_IS_LOADING_PACKER,
    payload: payload,
  }
}
export function setDataPagination(payload) {
  return {
    type: SET_DATA_PAGINATION,
    payload: payload,
  }
}
export function setDataPacker(payload) {
  return {
    type: SET_DATA_PACKERS,
    payload: payload,
  }
}
export function setSelectedProduct(payload) {
  return {
    type: SET_SELECTED_PRODUCT,
    payload: payload,
  }
}

export function setIntersectHub(payload) {
  return {
    type: SET_INTERSECT_HUB,
    payload: payload,
  }
}

const dataSorted = (dataPackers) => {
  const arrayOrderSorted = dataPackers.filter((item) => item.pool_number === null)

  let uniquePoolNumber = dataPackers
    .filter((item) => item.pool_number !== null)
    .map((item) => item.pool_number)
  uniquePoolNumber = [...new Set(uniquePoolNumber)]

  let header = uniquePoolNumber.reduce((accumulator, item) => {
    const smallestPossible = Math.min(
      ...dataPackers.filter((d) => d.pool_number == item).map((d) => d.order_id),
    )
    const el = dataPackers.find((item) => item.order_id === smallestPossible)
    return [...accumulator, el]
  }, [])
  header = header.sort((a, b) => (a.order_id < b.order_id ? -1 : 1))

  const newOrder = header.reduceRight((accumulator, head) => {
    const nearestPossible = accumulator
      .filter((order) => order.order_id < head.order_id)
      .map((item) => item.order_id)

    const beforeIndex = accumulator.findIndex(
      (order) => order.order_id === Math.max(...nearestPossible),
    )

    const currentIndex = beforeIndex + 1
    const members = dataPackers.filter(
      (member) => member.pool_number === head.pool_number && member.order_id !== head.order_id,
    )

    const pair = [head, ...members].sort((a, b) => (a.order_id < b.order_id ? -1 : 1))

    if (beforeIndex === -1) {
      accumulator.unshift(...pair)
    } else {
      accumulator.splice(currentIndex, 0, ...pair)
    }

    return accumulator
  }, arrayOrderSorted)
  return newOrder
}

export const actGetDataOrders =
  (params, isWithoutLoading, isPickingSection, dispatchPicker) => async (dispatch) => {
    if (!isWithoutLoading) {
      dispatch(setIsLoading(true))
    }

    return getOrdersV2(params)
      .then(({ data }) => {
        const newOrder = dataSorted(data?.data?.content)
        const pagination = {
          pageItem: data?.data?.pagination?.page_item,
          pageSize: data?.data?.pagination?.page_size,
          next: data?.data?.pagination?.next_cursor,
          prev: data?.data?.pagination?.previous_cursor,
          first: !data?.data?.pagination?.previous_page_exist,
          last: !data?.data?.pagination?.next_page_exist,
        }

        if (isPickingSection) {
          dispatchPicker(setDataPaginationPicker(pagination))
          dispatchPicker(setDataPicker(newOrder))
          dispatchPicker(setLastParamsPicker(params))
          return
        }
        dispatch(setDataPagination(pagination))
        dispatch(setDataPacker(newOrder))
        dispatch(setLastParams(params))
        return
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

export const actGetDataPackers =
  (params, isWithoutLoading, isNewApi) => async (dispatch, getState) => {
    if (!isWithoutLoading) {
      dispatch(setIsLoading(true))
    }

    const pathParts = window.location.pathname.split('/')
    const orderIndex = pathParts.indexOf('order')
    let status = orderIndex !== -1 && pathParts[orderIndex + 1] ? pathParts[orderIndex + 1] : null

    const allowedStatuses = [
      'new-order',
      'picking',
      'packing',
      'ready-for-delivery',
      'on-delivery',
      'back-to-hub',
      'waiting-confirmation',
      'upcoming-order',
    ]

    // do not call the api if status is not allowed
    if (!params.status && !allowedStatuses.includes(status)) {
      const { lastParams } = getState().statePacker
      log.info('Skipping API call due to invalid params', {
        status,
        path: window.location.pathname,
        params,
        lastParams,
      })
      dispatch(setIsLoading(false))
      return
    }

    if (!params.status && status) {
      params.status = status.replace(/-/g, ' ')
    }

    return getPackers(params)
      .then(({ data }) => {
        const newOrder = dataSorted(data?.data?.content)
        const pagination = {
          pageItem: data?.data?.pagination?.page_item,
          pageSize: data?.data?.pagination?.page_size,
          next: data?.data?.pagination?.next_cursor,
          prev: data?.data?.pagination?.previous_cursor,
          first: !data?.data?.pagination?.previous_page_exist,
          last: !data?.data?.pagination?.next_page_exist,
        }
        dispatch(setDataPagination(pagination))
        dispatch(setDataPacker(newOrder))
        dispatch(setLastParams(params))
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

export const actGetDataPackersSuper = (params, isWithoutLoading) => (dispatch) => {
  if (!isWithoutLoading) {
    dispatch(setIsLoading(true))
  }
  return getPackersSuper(params)
    .then(({ data }) => {
      const dataPackers = data.data.content
      const pagination = {
        pageItem: data?.data?.pagination?.page_item,
        pageSize: data?.data?.pagination?.page_size,
        next: data?.data?.pagination?.next_cursor,
        prev: data?.data?.pagination?.previous_cursor,
        first: !data?.data?.pagination?.previous_page_exist,
        last: !data?.data?.pagination?.next_page_exist,
      }

      dispatch(setDataPagination(pagination))
      dispatch(setDataPacker(dataPackers))
      dispatch(setLastParams(params))
    })
    .catch((err) => {
      callErrorMsg(err)
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actHandlePrint = (packer) => (dispatch) => {
  dispatch(setIsLoading(true))
  return getPacker(packer.order_id)
    .then(({ data }) => {
      dispatch(setSelectedPacker(data))
      dispatch(setIsPopupDetailShow(true))
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actHandleCheck = (packer) => async (dispatch) => {
  dispatch(
    setSelectedPacker({
      ...packer,
    }),
  )
  dispatch(setIsPopupCheckShow(true))
}

export const actHandlePutPacker = (packer) => (dispatch, getState) => {
  dispatch(setIsLoading(true))
  return putPacker(packer.order_id)
    .then(() => {
      const { lastParams } = getState().statePacker
      dispatch(actGetDataPackers(lastParams))
      toastSuccess(`${packer.order_invoice} berhasil dicetak`)
      dispatch(setIsPopupDetailShow(false))
    })
    .catch(() => {
      toastFailed(`${packer.order_invoice} gagal dicetak`)
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actHandleGetLocation = (objQuery) => (dispatch, getState) => {
  const { userData } = getState().stateAuth
  return getAllLocation().then(({ data }) => {
    const formated = []
    let selectData = ''
    data.data.forEach((el) => {
      formated.push({ ...el, name: el.location_name, location_type: el.location_type })
      if (String(el.location_id) === String(objQuery?.locationId)) {
        selectData = { ...el, name: el.location_name, location_type: el.location_type }
      }
    })

    if (userData?.location_roles?.length > 0 && !objQuery.showAllLocation) {
      let dataLocation = []
      const locations = userData.location_roles.map((d) => {
        return {
          ...d,
          name: d.location_name,
          location_type: d.location_type,
        }
      })
      locations.forEach((el) => {
        formated.map((f) => {
          if (f.location_id == el.location_id && el.location_type.toLowerCase() !== 'warehouse') {
            dataLocation.push(f)
          }
        })
      })

      let selectData = dataLocation[0]
      if (objQuery?.locationId) {
        selectData =
          dataLocation.find(
            (location) => Number(location.location_id) === Number(objQuery.locationId),
          ) || dataLocation[0]
      }

      dispatch(setSelectedLocation(selectData))
      return dispatch(setLocations(dataLocation))
    }

    if (objQuery.showAllLocation) {
      dispatch(setSelectedLocation(selectData))
      return dispatch(
        setLocations(
          formated.filter((location) => location.location_type?.toLowerCase() !== 'warehouse'),
        ),
      )
    }
    dispatch(setSelectedLocation(selectData))
    return dispatch(setLocations(formated))
  })
}

export const actHandleGetLocationRBAC = (objQuery) => (dispatch, getState) => {
  const { userData } = getState().stateAuth
  return getAllLocation().then(({ data }) => {
    const formated = data.data.map((el) => ({
      ...el,
      name: el.location_name,
      location_type: el.location_type,
    }))

    const selectData =
      formated.find((el) => String(el.location_id) === String(objQuery?.locationId)) || ''

    let dataLocation = []
    if (userData?.user_locations?.length > 0 && !objQuery.showAllLocation) {
      const locations = userData.user_locations.map((d) => ({
        ...d,
        name: d.location_name,
        location_type: d.location_type,
      }))

      dataLocation = formated.filter((f) =>
        locations.some(
          (el) => f.location_id == el.location_id && el.location_type.toLowerCase() !== 'warehouse',
        ),
      )
    }

    if (objQuery.showAllLocation) {
      dispatch(setSelectedLocation(selectData))
      dispatch(
        setLocations(
          formated.filter((location) => location.location_type?.toLowerCase() !== 'warehouse'),
        ),
      )
      return
    }

    dispatch(setSelectedLocation(selectData))
    dispatch(setLocations(dataLocation.length > 0 ? dataLocation : formated))
    return
  })
}

export const actHandleSuperIntersectHub = (locationId) => (dispatch, getState) => {
  return getSuperIntersectHub(locationId)
    .then(({ data }) => {
      let hubParsed = []
      if (data.data.length > 0) {
        hubParsed = data?.data[0]?.intersect_location_id.map((d) => {
          return {
            ...d,
            name: d.location_name,
            id: d.location_id,
          }
        })
      }
      dispatch(setIntersectHub(hubParsed))
    })
    .catch((err) => {
      callErrorMsg(err)
    })
}

export const actUpdatePicking =
  (payload, showPopup = true) =>
  (dispatch, getState) => {
    dispatch(setIsLoading(true))
    return getPackerInvoice(payload)
      .then(({ data: { data } }) => {
        const { lastParams } = getState().statePacker
        dispatch(actGetDataPackers(lastParams))
        dispatch(setSelectedPacker(data))
        dispatch(setIsPopupCheckShow(showPopup))
      })
      .catch(() => {
        toastFailed('Order ID tidak ditemukan')
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }
export const actUpdatePickingSuper =
  (payload, showPopup = true, query, flagMergeOrder) =>
  (dispatch, getState) => {
    dispatch(setIsLoading(true))
    return getPackerOrderIdSuper(payload, query)
      .then(({ data: { data } }) => {
        const { lastParams } = getState().statePacker
        dispatch(actGetDataPackersSuper(lastParams))
        dispatch(setSelectedPacker(data))
        dispatch(setIsPopupCheckShow(showPopup))
      })
      .catch(() => {
        toastFailed('Order ID tidak ditemukan')
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }
export const actPostUpdatePicking =
  (payload, showPopup = true) =>
  (dispatch, getState) => {
    dispatch(setIsLoading(true))
    return postPackerInvoice(payload)
      .then(({ data: { data } }) => {
        const { lastParams } = getState().statePacker
        const { lastParams: lastParamsPicker } = getState().statePicker
        dispatch(
          actGetDataPackers(
            lastParams?.status ? lastParams : { ...lastParamsPicker, status: 'packing' },
          ),
        )
        dispatch(setSelectedPacker(data))
        dispatch(setIsPopupCheckShow(showPopup))
      })
      .catch((err) => {
        toastFailed(err.response.data.error.message)
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

export const actPostUpdatePickingSuper =
  (payload, showPopup = true) =>
  (dispatch, getState) => {
    dispatch(setIsLoading(true))
    return postPackerInvoiceSuper(payload)
      .then(({ data: { data } }) => {
        const { lastParams } = getState().statePacker
        const { lastParams: lastParamsPicker } = getState().statePicker
        dispatch(
          actGetDataPackersSuper(
            lastParams?.status ? lastParams : { ...lastParamsPicker, status: 'packing' },
          ),
        )
        dispatch(setSelectedPacker(data))
        dispatch(setIsPopupCheckShow(showPopup))
      })
      .catch((err) => {
        toastFailed(err.response.data.error.message)
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

export const actHandleSubmitQty =
  (id, payload, localState = {}, callbackWhenSuccess = () => {}) =>
  (dispatch, getState) => {
    dispatch(setIsLoading(true))
    return putPackerQty(id, payload)
      .then((res) => {
        toastSuccess(`Quantity berhasil diupdate`)
        dispatch(setIsPopupDetailShow(false))
        callbackWhenSuccess()
      })
      .catch((error) => {
        callErrorMsg(error)
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

export const actHandleSubmitQtySuper =
  (id, payload, localState = {}, callbackWhenSuccess = () => {}) =>
  (dispatch, getState) => {
    dispatch(setIsLoading(true))
    return putPackerQtySuper(id, payload)
      .then((res) => {
        toastSuccess(`Quantity berhasil diupdate`)
        dispatch(setIsPopupDetailShow(false))
        callbackWhenSuccess()
      })
      .catch((error) => {
        callErrorMsg(error)
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

export const actHandleCallDriver = (packer) => (dispatch) => {
  dispatch(setIsLoading(true))
  getDrivers(packer.order_location_id)
    .then(({ data }) => {
      const dataFormated = data.data.map((el) => {
        return { ...el, name: el.driver_full_name }
      })
      dataFormated.push({ name: 'Request Grab / Gojek', id: 'online' })
      dispatch(setDrivers(dataFormated))
      dispatch(setSelectedPacker(packer))
      dispatch(setIsPopupCallDriverShow(true))
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actHandleCancelPacker = (packer) => (dispatch) => {
  dispatch(setSelectedPacker(packer))
  dispatch(setIsPopupCancelShow(true))
}

export const actHandleCancelAutoAssign = (packer) => (dispatch) => {
  dispatch(setSelectedPacker(packer))
  dispatch(setIsPopupCancelAutoAssignShow(true))
}

export const actHandleSubmitCancelAutoAssign = (payload) => (dispatch, getState) => {
  dispatch(setIsLoading(true))
  const { lastParams, selectedLocation, selectedPacker } = getState().statePacker
  if (selectedLocation?.location_id) {
    return cancelAutoAssign(selectedPacker.order_id, payload)
      .then((res) => {
        dispatch(actGetDataPackers(lastParams))
        toastSuccess('driver berhasil dicancel.')
        dispatch(setIsPopupCancelAutoAssignShow(false))
      })
      .catch((err) => {
        callErrorMsg(err)
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  } else {
    toastFailed('Mohon pilih lokasi terlebih dahulu')
    dispatch(setIsLoading(false))
  }
}

export const actHandleSubmitCancelPacker = (payload, cancelExternal) => (dispatch, getState) => {
  dispatch(setIsLoading(true))
  const { lastParams, selectedLocation, selectedPacker } = getState().statePacker
  if (selectedLocation?.location_id) {
    return cancelPacker(selectedPacker.order_id, payload)
      .then(() => {
        dispatch(actGetDataPackers(lastParams))
        toastSuccess('Order berhasil dicancel.')
        dispatch(setIsPopupCancelShow(false))
        if (selectedPacker.order_third_party_logistic_used) {
          cancelExternal(selectedPacker, selectedLocation?.location_id, payload.note)
        }
      })
      .catch(() => {
        toastFailed('UserId atau password salah.')
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  } else {
    toastFailed('Mohon pilih lokasi terlebih dahulu')
    dispatch(setIsLoading(false))
  }
}

export const actHandlePooledCallDriver = (payload, cb) => (dispatch, getState) => {
  dispatch(setIsLoading(true))
  return postOrderPooling_OrderPooling(payload)
    .then(({ data }) => {
      dispatch(actHandleCloseCallDriverV2())
      toastSuccess(`order pooling berhasil di assign ke driver`)
    })
    .catch((err) => {
      callErrorMsg(err)
    })
}

export const actHandleSubmitCallDriver = (payload) => (dispatch, getState) => {
  dispatch(setIsLoading(true))
  const { selectedPacker } = getState().statePacker
  return putCallDriver(selectedPacker.order_id, payload)
    .then(() => {
      dispatch(setIsPopupCallDriverShow(false))
      const { lastParams } = getState().statePacker
      dispatch(actGetDataPackers(lastParams))
      dispatch(actHandleCloseCallDriverV2())
      dispatch(actHandleCloseCallExternalDriverV2())
      toastSuccess('Berhasil mendapatkan driver.')
    })
    .catch((err) => {
      callErrorMsg(err)
      //toastFailed("Gagal mendapatkan driver");
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actHandleChangeDriver = (packer) => (dispatch) => {
  dispatch(setIsLoading(true))
  getDrivers(packer.order_location_id)
    .then(({ data }) => {
      const dataFormated = data.data.map((el) => {
        return { ...el, name: el.driver_full_name }
      })
      dataFormated.push({ name: 'Request Grab / Gojek', id: 'online' })
      dispatch(setDrivers(dataFormated))
      dispatch(setSelectedPacker(packer))
      dispatch(setIsPopupChangeDriverShow(true))
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actHandleGetPackages =
  (orderId, callback = () => {}, query) =>
  (dispatch) => {
    dispatch(setIsLoading(true))
    if (query) {
      return getDataReceiptPackerOrderSuper(orderId, query)
        .then(({ data }) => {
          callback(data.data)
        })
        .finally(() => {
          dispatch(setIsLoading(false))
        })
    } else {
      return getDataReceiptPackerOrder(orderId)
        .then(({ data }) => {
          callback(data.data)
        })
        .finally(() => {
          dispatch(setIsLoading(false))
        })
    }
  }

export const actHandleSubmitChangeDriver = (payload, order_id) => (dispatch, getState) => {
  dispatch(setIsLoading(true))
  const { selectedPacker } = getState().statePacker
  return putChangeDriver(order_id ? order_id : selectedPacker.order_id, payload)
    .then(() => {
      dispatch(setIsPopupChangeDriverShow(false))
      const { lastParams } = getState().statePacker
      dispatch(actGetDataPackers(lastParams))
      dispatch(actHandleCloseCallDriverV2())
      dispatch(actHandleCloseCallExternalDriverV2())
      toastSuccess('Berhasil mengganti driver.')
    })
    .catch((err) => {
      callErrorMsg(err)
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actHandlePickupOrder = (payload) => (dispatch, getState) => {
  dispatch(setIsLoading(true))
  return putPickupOrder(payload.order_id)
    .then(() => {
      dispatch(setIsPopupChangeDriverShow(false))
      const { lastParams } = getState().statePacker
      dispatch(actGetDataPackers(lastParams))
      toastSuccess('Order berhasil dipickup')
    })
    .catch(() => {
      toastFailed('Order gagal dipickup')
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actHandleReturnPacker = (packer) => (dispatch, getState) => {
  dispatch(setIsLoading(true))
  return returnPacker(packer.order_id)
    .then(() => {
      const { lastParams } = getState().statePacker
      dispatch(actGetDataPackers(lastParams))
      toastSuccess('Pengembalian pesanan berhasil')
    })
    .catch(() => {
      toastFailed('Pengembalian pesanan gagal')
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actHandlePrintList = (packer, isDetail) => (dispatch) => {
  dispatch(setIsLoading(true))
  return listPacker(packer.order_id)
    .then(({ data }) => {
      if (isDetail) {
        dispatch(setSelectedPacker({ ...packer, ...data }))
        dispatch(setIsPopupPrintListShow(true))
      } else {
        printPdfLink(data.file_url)
      }
    })
    .catch(() => {
      toastFailed('Cetak list gagal')
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actHandlePrintResi = (packer) => (dispatch) => {
  dispatch(setIsLoading(true))
  return resiPacker(packer.order_id)
    .then(({ data }) => {
      dispatch(setSelectedPacker({ ...packer, ...data }))
      dispatch(setIsPopupCheckResiShow(true))
    })
    .catch(() => {
      toastFailed('Resi gagal didapatkan')
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actHandleDetailOrder = (packer) => (dispatch) => {
  dispatch(setIsLoading(true))
  detailOrder(packer.order_id)
    .then(({ data }) => {
      dispatch(setSelectedPacker(data))
      dispatch(setIsPopupDetailShow(true))
    })
    .catch(() => {
      toastFailed('Data order gagal ditemukan')
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actUpdatePickingToPacking =
  (invoice, cb = () => {}) =>
  (dispatch, getState) => {
    let isSuccess
    dispatch(setIsLoading(true))
    return getPackerInvoice(invoice)
      .then(() => {
        isSuccess = true
        const { lastParams } = getState().statePacker
        dispatch(actGetDataPackers(lastParams))
      })
      .catch(() => {
        isSuccess = false
        toastFailed('Cetak List gagal')
      })
      .finally(() => {
        dispatch(setIsLoading(false))
        cb(isSuccess)
      })
  }

export const actGetSkuManualInputNotes = (params) => (dispatch) => {
  getSkuManualInputNotes(params).then(({ data }) => {
    dispatch(setSkuManualInputNotes(data.data.notes))
  })
}

export const actSkuManualInputAuth = (payload) => (dispatch) => {
  dispatch(setIsLoading(true))
  postSkuManualInputAuth(payload)
    .then(() => {
      //create new payload with product_id
      dispatch(setIsSkuManualInputAuth(true))
      dispatch(setIsPopupInputAuthShow(false))
      toastSuccess('SKU Manual Input Successfully Approved')
    })
    .catch(() => {
      toastFailed('SKU Manual Input Failed to Approve')
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}
