import { Get } from 'config/apiServiceConfig'
import AxiosInstance from 'config/apiServiceApiGateway'

const getVendorsEndpoint = '/api/vendor'
const getPoNumberEndpoint = '/api/wms/:version/purchase-order/po-number/unscheduled'

export type VendorType = {
  id: number
  companyName: string
}

export type GetVendorsType = {
  data: VendorType[]
}

export type GetSlotListType = {
  data: {
    slot: number
    label?: string
  }[]
}

export type AvailableTimeSlotType = {
  id: number
  location_id: number
  loading_dock_id: number
  name: string
  start_at: string
  end_at: string
  manpower: number
  manpower_productivity: number
  timeslot_productivity: number
  is_active: true
  created_at: string
  created_by: string
  updated_at: string
  updated_by: string
  remarks: string
  label?: string
}

export type GetAvailableTimeSlotType = AvailableTimeSlotType[]

export type GetAvailableTimeSlotParamsType = {
  date: string
  loading_dock_id: number
  slot: number
}

export type PoNumberType = {
  purchaseOrderId: number
  purchaseOrderNumber: string
  totalPcs: number
  hasStored?: boolean
  reason: string
}

export type KongPoNumberType = {
  purchase_order_id: number
  purchase_order_number: string
  reason: string
  total_pcs: number
  hasStored?: boolean
}

export type GetPoNumberType = {
  data: PoNumberType[]
}

export type GetPoNumberParamsType = {
  destinationId: number
}

export type PostInboundScheduleDataType = {
  location_id: string
  loading_dock_id: string
  vendor_id: string
  date: string
  slot: number
  time_slots: {
    time_slot_id: string
  }[]
  purchase_orders: {
    purchase_order_id: string
    reason: string
  }[]
  executed_by: string
  user_id: string
}

export type DeletePoNumberDataType = {
  purchaseOrderId: number
}

export type PutInboundScheduleDataType = Pick<
  PostInboundScheduleDataType,
  'date' | 'slot' | 'time_slots' | 'purchase_orders' | 'executed_by' | 'user_id'
>

export type GetInboundScheduleDetailType = {
  schedule_id: number
  vendor: {
    vendor_id: number
    vendor_name: string
  }
  date: string
  slot: number
  created_at: string
  created_by: string
  time_slot: AvailableTimeSlotType
  purchase_order: {
    purchase_order_id: number
    purchase_order_number: string
    total_pcs: number
    created_at?: string
    created_by?: string
    hasStored?: boolean
    reason: string
  }[]
  max_pcs: number
  is_same_day: boolean
}

export type GetAuditTrailsDataType = {
  data: {
    schedule_id: string
    date: string
    created_at: string
    created_by: string
    time_slot: {
      id: string
      location_id: string
      loading_dock_id: string
      name: string
      start_at: string
      end_at: string
      manpower: string
      manpower_productivity: string
      timeslot_productivity: string
      is_active: boolean
      created_at: string
      created_by: string
      updated_at: string
      updated_by: string
      remarks: string
    }
    audit_trails: [
      {
        purchase_order_id: string
        purchase_order_number: string
        action: string
        reason: string
        executed_by: string
        executed_at: string
      },
    ]
  }
  pagination: {
    sort: string
    direction: string
    page_size: number
    page_index: number
    number_of_elements: number
    total_elements: number
    total_pages: number
  }
}

export type GetReasonType = 'INBOUND_SCHEDULE_PO_CREATE' | 'INBOUND_SCHEDULE_PO_DELETE'

export type GetReasonResponseType = {
  id: number
  reason: string
}

export const getVendors = (companyName: string) =>
  Get<GetVendorsType>({ url: getVendorsEndpoint, params: { companyName } })

export const getPoNumber = (params: GetPoNumberParamsType) =>
  Get<GetPoNumberType>({ url: getPoNumberEndpoint, version: 'v1', params })

export const getAvailableTimeSlot = (params: GetAvailableTimeSlotParamsType) =>
  AxiosInstance.Get<GetAvailableTimeSlotType>({
    url: '/staff-management/internal/:version/inbound-schedule/available-timeslot',
    version: 'v1',
    params,
  })

export const getSlotList = () =>
  AxiosInstance.Get<GetSlotListType>({
    url: '/staff-management/internal/:version/inbound-schedule/slot',
    version: 'v1',
  })

export const postInboundSchedule = (data: PostInboundScheduleDataType) =>
  AxiosInstance.Post({
    url: '/staff-management/internal/:version/inbound-schedule',
    data,
    version: 'v1',
  })

export const putInboundSchedule = (scheduleId: string, data: PutInboundScheduleDataType) =>
  AxiosInstance.Put({
    url: '/staff-management/internal/:version/inbound-schedule/:scheduleId',
    version: 'v1',
    urlParams: { scheduleId },
    data,
  })

export const getInboundScheduleDetail = (scheduleId: string) =>
  AxiosInstance.Get<GetInboundScheduleDetailType>({
    url: 'staff-management/internal/v1/inbound-schedule/:scheduleId',
    version: 'v1',
    urlParams: { scheduleId },
  })

export const getAuditTrails = (scheduleId: string, page_index: number) =>
  AxiosInstance.Get<GetAuditTrailsDataType>({
    url: '/staff-management/internal/:version/inbound-schedule/:scheduleId/audit-trail',
    version: 'v1',
    urlParams: { scheduleId },
    params: {
      page_size: 10,
      page_index,
      order_direction: 'desc',
      order_by: 'created_at',
    },
  })

export const deletePoNumber = (
  scheduleId: string,
  purchaseOrderId: number,
  data: { executed_by: string; user_id: number; reason: string },
) =>
  AxiosInstance.Put({
    url: '/staff-management/internal/:version/inbound-schedule/:scheduleId/:purchaseOrderId',
    version: 'v1',
    urlParams: { scheduleId, purchaseOrderId },
    data,
  })

export const getReasons = (type: GetReasonType) =>
  AxiosInstance.Get<GetReasonResponseType[]>({
    url: '/commercial/internal/:version/reason/:type',
    version: 'v1',
    urlParams: { type },
  })
