import {
  SET_IS_LOADING,
  SET_QUERY,
  RESET_QUERY,
  SET_IS_OPEN_POPUP_CHECKER,
  SET_INBOUNDS,
  SET_PAGINATION_DATA,
  SET_SELECTED_INBOUND,
  SET_LAST_QUERY,
  SET_HUBS,
  SET_SELECTED_HUB,
  SET_LAST_PARAMS,
  SET_DATA_PRINT,
  SET_DATA_SLOC_OPTIONS,
  SET_CHECKER,
  SET_SELECTED_CHECKER,
  SET_SELECTED_INVENTORY_CONTROLLER,
  SET_RESET,
  SET_MODAL_CONVERSION_RULE,
  RESET_MODAL_CONVERSION_RULE,
  SET_MODAL_CONFIRM_START_UNLOADING,
  RESET_MODAL_CONFIRM_START_UNLOADING,
} from 'storeContext/actionsType/inbound'
import { hubPageLocationType } from 'constant/locationType'

export const initialStateInbound = {
  isLoading: false,
  isOpenPopupChecker: false,
  inbounds: [],
  paginationData: {},
  selectedInbound: {},
  query: {
    type: hubPageLocationType.toString(),
    pageIndex: 0,
  },
  modalConfirmStartUnloading: {
    isOpen: false,
    data: {
      soUnloadingId: 0,
      status: '',
      unloadingStatus: '',
    },
  },
  lastQuery: '',
  hubs: [],
  selectedHub: {},
  selectedChecker: [],
  selectedInventoryController: [],
  lastParams: {},
  dataPrint: {},
  slocOptionsData: [],
  checker: [],
  modalConversionRule: {
    isOpen: false,
    supplyOrderId: 0,
    data: {
      supply_order_id: 0,
      supply_order_number: '',
      items: [],
    },
  },
}

export default function inbound(state = initialStateInbound, { type, payload }) {
  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case SET_RESET:
      return initialStateInbound
    case SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...payload.query,
        },
      }
    case RESET_QUERY:
      return {
        ...state,
        query: initialStateInbound.query,
      }
    case SET_IS_OPEN_POPUP_CHECKER:
      return {
        ...state,
        isOpenPopupChecker: payload,
      }
    case SET_SELECTED_CHECKER:
      return {
        ...state,
        selectedChecker: payload,
      }
    case SET_SELECTED_INVENTORY_CONTROLLER:
      return {
        ...state,
        selectedInventoryController: payload,
      }
    case SET_INBOUNDS:
      return {
        ...state,
        inbounds: payload,
      }
    case SET_PAGINATION_DATA:
      return {
        ...state,
        paginationData: payload,
      }
    case SET_SELECTED_INBOUND:
      return {
        ...state,
        selectedInbound: payload,
      }
    case SET_LAST_QUERY:
      return {
        ...state,
        lastQuery: payload,
      }
    case SET_HUBS:
      return {
        ...state,
        hubs: payload,
      }
    case SET_SELECTED_HUB:
      return {
        ...state,
        selectedHub: payload,
      }
    case SET_LAST_PARAMS:
      return {
        ...state,
        lastParams: payload,
      }
    case SET_DATA_PRINT:
      return {
        ...state,
        dataPrint: payload,
      }
    case SET_DATA_SLOC_OPTIONS:
      return {
        ...state,
        slocOptionsData: payload,
      }
    case SET_CHECKER:
      return {
        ...state,
        checker: payload,
      }
    case SET_MODAL_CONFIRM_START_UNLOADING: {
      return {
        ...state,
        modalConfirmStartUnloading: {
          ...state.modalConfirmStartUnloading,
          ...payload,
        },
      }
    }
    case RESET_MODAL_CONFIRM_START_UNLOADING: {
      return {
        ...state,
        modalConfirmStartUnloading: initialStateInbound.modalConfirmStartUnloading,
      }
    }
    case SET_MODAL_CONVERSION_RULE: {
      return {
        ...state,
        modalConversionRule: {
          ...state.modalConversionRule,
          ...payload,
        },
      }
    }
    case RESET_MODAL_CONVERSION_RULE: {
      return {
        ...state,
        modalConversionRule: {
          isOpen: false,
          supplyOrderId: 0,
          data: null,
        },
      }
    }
    default:
      return state
  }
}
