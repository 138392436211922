import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getDetailPageIDAPI,
  getPagePermissionOptionsAPI,
  postPageIDAPI,
  putPageIDAPI,
  getJSONPageIDDetailsAPI,
} from 'features/Account/services/adminAccessPageID'
import { downloadFileBlob } from 'utils/downloadFileUrl'
import { dateFormat } from 'utils/helpers/date'
import { callErrorMsg } from 'helpers/errorMsg'
import { toastSuccess } from 'utils/toast'

export const SLICE_NAME = 'adminAccessAddEditPageID'

export const fetchGetDetailPageID = createAsyncThunk(
  `${SLICE_NAME}/fetchGetDetailPageID`,
  async (pageId: string, { rejectWithValue }) => {
    try {
      const response = await getDetailPageIDAPI(pageId)

      return response.data.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchPostPageID = createAsyncThunk(
  `${SLICE_NAME}/fetchPostPageID`,
  async (
    action: {
      payload: Parameters<typeof postPageIDAPI>[0]
      callback?: () => void
    },
    { rejectWithValue },
  ) => {
    try {
      const { payload, callback } = action
      const response = await postPageIDAPI(payload)

      if (callback) {
        callback()
      }

      toastSuccess('Page ID berhasil dibuat')
      return response.data.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchPutPageID = createAsyncThunk(
  `${SLICE_NAME}/fetchPutPageID`,
  async (
    action: {
      pageId: string
      payload: Parameters<typeof putPageIDAPI>[0]['payload']
      callback?: () => void
    },
    { rejectWithValue },
  ) => {
    try {
      const { pageId, payload, callback } = action
      const response = await putPageIDAPI({ id: pageId, payload })

      if (callback) {
        callback()
      }

      toastSuccess('Page ID berhasil diubah')
      return response.data.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchGetPermissionOptionsPageID = createAsyncThunk(
  `${SLICE_NAME}/fetchGetPermissionOptionsPageID`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await getPagePermissionOptionsAPI()

      return response.data.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchGetJSONPageIDDetails = createAsyncThunk(
  `${SLICE_NAME}/fetchGetJSONPageIDDetails`,
  async (id: string, { getState, rejectWithValue }) => {
    try {
      const {
        adminAccessAddEditPageID: { detail },
      } = getState() as StoreStateType
      const response = await getJSONPageIDDetailsAPI(id)
      const jsonString = response.data.data.page_json
      const generateFileName = () => {
        const pageName = detail?.page.name
          ?.replace(/\s+/g, '_') // Replace spaces with underscores
          .replace(/[^a-zA-Z0-9-_]/g, '') // Remove special characters
          .toLowerCase()
        return `${pageName}_${dateFormat({ date: new Date(), format: 'DD-MMM-YYYY_HH:mm:ss' })}`
      }
      downloadFileBlob({
        data: jsonString,
        fileName: generateFileName(),
        mimeType: 'application/json',
      })
      return true
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)
