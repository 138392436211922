import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getUVCampaignList,
  ActiveUVVoucherRequestType,
  putActiveUVVoucher,
  getCSVDownloadUrl,
} from 'utils/apiList/uniqueVoucher'
import { callErrorMsg } from 'helpers/errorMsg'

const SLICE_NAME = 'uniqueVoucherCampaignList'

export const fetchUVCampaignList = createAsyncThunk(
  `${SLICE_NAME}/fetchCampaignList`,
  async (_, { rejectWithValue, getState }) => {
    const {
      uniqueVoucherCampaignList: { query },
    } = getState() as StoreStateType

    try {
      const res = await getUVCampaignList(`?pageIndex=${query.pageIndex}`)
      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateUVCampaign = createAsyncThunk(
  `${SLICE_NAME}/updateCampaign`,
  async (params: ActiveUVVoucherRequestType, { rejectWithValue }) => {
    try {
      const res = await putActiveUVVoucher(params)
      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchCSVDownloadUrl = createAsyncThunk(
  `${SLICE_NAME}/fetchCSVDownlaodUrl`,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await getCSVDownloadUrl(id)
      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
