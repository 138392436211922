import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const SupplyOrderCheckQtyRBACPage = {
  pageID: '1084ae85da906c4dff530e3595fa655a',
  elementID: {
    ViewOnly: 'a0539e3f325519f5690d31f7e8d6f0ce',
  },
} as const

export const useSupplyOrderCheckQtyRBACPage = createUseRBAC(SupplyOrderCheckQtyRBACPage.elementID)

export type UseSupplyOrderCheckQtyRBACPageType = ReturnType<typeof useSupplyOrderCheckQtyRBACPage>
