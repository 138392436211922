import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DisbursementType } from 'features/Payment/@types/disbursement'
import {
  SLICE_NAME,
  fetchDisbursementList,
  fetchBankList,
  fetchHubList,
  fetchDowloadDisbursement,
} from './disbursementThunk'

export interface QueryStateType {
  date: [Date | null, Date | null]
  pageIndex: number
  pageSize: number
  id: string
  status: {
    name: string
    value: string
  }
  customerId: string
  location: {
    name: string
    id: number
  }
}

export type BankListDataType = {
  name: string
  target_type: string
  target_channel: string
}

export type FormModalReprocessingType = {
  bank: BankListDataType
  accountName: string
  accountNumber: string
}

export type HubListType = {
  name: string
  id: number
}

export interface DisbursementStateType {
  isLoading: boolean
  isShowModalReprocessing: boolean
  disbursement: DisbursementType[]
  query: QueryStateType
  selectedReprocessingData: DisbursementType | ObjectNullType
  bankListData: BankListDataType[]
  formModalReprocessing: FormModalReprocessingType
  hubList: HubListType[]
}

export const initialState: DisbursementStateType = {
  isLoading: false,
  disbursement: [],
  query: {
    date: [null, null],
    pageIndex: 1,
    pageSize: 20,
    status: { name: '', value: '' },
    customerId: '',
    id: '',
    location: { id: 0, name: '' },
  },
  isShowModalReprocessing: false,
  selectedReprocessingData: {},
  bankListData: [],
  formModalReprocessing: {
    bank: { name: '', target_channel: '', target_type: '' },
    accountName: '',
    accountNumber: '',
  },
  hubList: [],
}

export type SetQueryPayloadType = {
  name: keyof QueryStateType
  value: QueryStateType[keyof QueryStateType]
}

export type SetFormModalReprocessingPayloadType = {
  name: keyof FormModalReprocessingType
  value: FormModalReprocessingType[keyof FormModalReprocessingType]
}
const disbursement = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    resetFormModalReprocessing: (state) => {
      state.formModalReprocessing = initialState.formModalReprocessing
    },
    setQuery: (state, action: PayloadAction<SetQueryPayloadType>) => {
      const { value, name } = action.payload

      state.query[name] = value as never
    },
    setIsShowModalReprocessing: (state, action: PayloadAction<{ isShow: boolean }>) => {
      const { isShow } = action.payload
      state.isShowModalReprocessing = isShow
      if (!isShow) {
        state.selectedReprocessingData = initialState.selectedReprocessingData
      }
    },
    setSelectedReprocessingData: (state, action: PayloadAction<{ index: number }>) => {
      const { index } = action.payload
      state.selectedReprocessingData = state.disbursement[index]
    },
    setFormModalReprocessing: (
      state,
      action: PayloadAction<SetFormModalReprocessingPayloadType>,
    ) => {
      const { name, value } = action.payload
      state.formModalReprocessing[name] = value as never
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDisbursementList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchDisbursementList.fulfilled, (state, action) => {
        const { data } = action.payload
        state.isLoading = false
        state.disbursement = data.disbursements.map((el) => ({
          ...el,
          uniqueId: window.crypto.randomUUID(),
        }))
        state.query = {
          ...state.query,
          pageSize: data.pagination_response.limit,
        }
      })
      .addCase(fetchDisbursementList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchBankList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchBankList.fulfilled, (state, action) => {
        const { data } = action.payload
        state.isLoading = false
        state.bankListData = data
      })
      .addCase(fetchBankList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchHubList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchHubList.fulfilled, (state, action) => {
        const { data } = action.payload
        state.isLoading = false
        state.hubList = data.map((el) => ({ id: el.location_id, name: el.location_name }))
      })
      .addCase(fetchHubList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchDowloadDisbursement.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchDowloadDisbursement.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchDowloadDisbursement.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const {
  reset,
  setQuery,
  setIsShowModalReprocessing,
  setSelectedReprocessingData,
  setFormModalReprocessing,
  resetFormModalReprocessing,
} = disbursement.actions

export default disbursement.reducer
