import { AxiosRequestConfig, AxiosProgressEvent } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import { uploadStatus } from 'features/Rack/utils/constant'
import {
  getLocationList,
  getRackList,
  getRackDetailByLocationId,
  getRacksExport,
  GetRackDetailByLocationIdParamsType,
  putActivateDeactivateRack,
  postRackImport,
  deleteRack,
  getRackCsvTemplate,
} from 'features/Rack/services/rackList'
import { toastSuccess } from 'utils/toast'
import {
  setRack,
  setRackDetail,
  setLocation,
  setIsLoading,
  setModalBulkUpload,
  resetModalBulkUpload,
  resetModalDelete,
  SLICE_NAME,
} from './slice'

export const fetchLocationList = createAsyncThunk<void>(
  `${SLICE_NAME}/fetchLocationList`,
  async (_, { dispatch, getState }) => {
    const {
      rack: {
        location: { queryParams },
      },
    } = getState() as StoreStateType
    try {
      const { data } = await getLocationList(queryParams)
      dispatch(setLocation({ listData: data.content }))
    } catch (err) {
      callErrorMsg(err)
    }
  },
)

export const fetchRackList = createAsyncThunk<void>(
  `${SLICE_NAME}/fetchRackList`,
  async (_, { dispatch, getState }) => {
    const {
      rack: { queryParamsRackList },
    } = (getState() as StoreStateType).rack

    try {
      const {
        data: { data, pagination },
      } = await getRackList(queryParamsRackList)
      dispatch(setRack({ listData: data, numberOfElements: pagination.number_of_elements }))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

type ExportRackDetailParamsType = {
  locationId: number
  params?: GetRackDetailByLocationIdParamsType
}

export const exportRackDetail = createAsyncThunk<void, ExportRackDetailParamsType>(
  `${SLICE_NAME}/exportRackDetail`,
  async (exportParam, { dispatch }) => {
    const { locationId, params } = exportParam
    dispatch(setIsLoading(true))
    try {
      let paramData
      if (params) {
        paramData = {
          pageIndex: params.page_index,
          pageSize: params.page_size,
          ...(params?.rack_name && { name: params?.rack_name }),
          ...(params?.active && { active: params?.active }),
        }
      }
      const { data } = await getRacksExport(locationId, paramData)
      const dataCsv = `data:text/csv;charset=utf-8,${data}`
      const encodedUri = encodeURI(dataCsv)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', `${new Date()}-racks.csv`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      dispatch(setIsLoading(false))
    } catch (error) {
      dispatch(setIsLoading(false))
      callErrorMsg(error)
    }
  },
)

export const fetchRackDetailByLocationId = createAsyncThunk<void, number>(
  `${SLICE_NAME}/fetchRackDetailByLocationId`,
  async (locationId, { dispatch, getState }) => {
    const {
      rackDetail: { params },
    } = (getState() as StoreStateType).rack

    try {
      const { data } = await getRackDetailByLocationId(locationId, params)
      dispatch(
        setRackDetail({
          numberOfElements: data.pagination.number_of_elements,
          location: data.data.location,
          listData: data.data.racks,
        }),
      )
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

const getTemplate = (
  statusResponse: string,
  data: { urlDownload: string; rackTotal: number; rackFailed: number; rackSuccess: number },
) => {
  let status = ''

  if (statusResponse === 'success') status = uploadStatus.ON_UPLOAD_SUCCESS
  else if (statusResponse === 'failed') status = uploadStatus.ON_UPLOAD_FAILED
  else if (statusResponse === 'partially success') status = uploadStatus.ON_UPLOAD_SUCCESS_PARTIAL

  return {
    status,
    url_download: data.urlDownload,
    data_count: {
      total_data: data.rackTotal,
      failed_data: data.rackFailed,
      success_data: data.rackSuccess,
    },
  }
}

export const formatIdsQuery = (ids: number[]): string => ids.map((id) => `ids=${id}`).join('&')

export const actDeleteRack =
  () => async (dispatch: StoreDispatchType, getState: () => StoreStateType) => {
    const {
      rackDetail: { location },
      modalDelete,
    } = getState().rack

    try {
      const queryString = formatIdsQuery(modalDelete.idToDelete)
      await deleteRack(location.location_id, queryString)
      dispatch(resetModalDelete())
      toastSuccess('Rak berhasil dihapus')
      dispatch(fetchRackDetailByLocationId(location.location_id))
    } catch (error) {
      callErrorMsg(error)
    }
  }

export const actRackImport =
  (dataToSubmit: FormData, setProgress: (value: string) => void) =>
  async (dispatch: StoreDispatchType, getState: () => StoreStateType) => {
    const {
      modalBulkUpload,
      rackDetail: { location },
    } = getState().rack
    const config: AxiosRequestConfig = {
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / (progressEvent?.total || 0),
        )
        setProgress(percentCompleted === 100 ? 'Upload is done' : `Uploading ${percentCompleted}%`)
      },
    }

    try {
      const {
        data: { data },
      } = await postRackImport(dataToSubmit, config)

      const status = data.status.toLowerCase()
      dispatch(
        setModalBulkUpload({
          fileCSV: { ...modalBulkUpload.fileCSV, ...getTemplate(status, data) },
        }),
      )

      if (status === 'success') {
        dispatch(resetModalBulkUpload())
        dispatch(fetchRackDetailByLocationId(location.location_id))
      }
    } catch (err) {
      callErrorMsg(err)
      dispatch(
        setModalBulkUpload({
          fileCSV: { ...modalBulkUpload.fileCSV, status: uploadStatus.ON_CHOOSE_FILE },
        }),
      )
    }
  }

export const activateDeactivateRack =
  ({ rackId, active }: { rackId: number; active: boolean }) =>
  async (dispatch: StoreDispatchType, getState: () => StoreStateType) => {
    const {
      rackDetail: { location },
    } = getState().rack
    try {
      await putActivateDeactivateRack(rackId, { active })
      dispatch(fetchRackDetailByLocationId(location.location_id))
    } catch (error) {
      callErrorMsg(error)
    }
  }

export const fetchRackCsvTemplate = createAsyncThunk<void>(
  `${SLICE_NAME}/fetchRackCsvTemplate`,
  async (_, { dispatch }) => {
    try {
      const {
        data: { data },
      } = await getRackCsvTemplate()
      dispatch(setModalBulkUpload({ templateUrl: data.url }))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)
