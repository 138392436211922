import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

export type PricingEngineHistoryParamsType = {
  location_ids?: string[]
  statuses?: string[]
  roles?: string[]
  sku?: string
  product_name?: string
  date_from?: string
  date_to?: string
  order_by?: string
  order_direction?: 'desc' | 'asc'
  page_size?: number
  cursor?: string
}

export type PricingEngineProductType = {
  id: number
  name: string
  sku: string
  image_url: string
}

export type PricingEngineHistoryItemType = {
  id: number
  product: PricingEngineProductType
  locations: string[]
  before_min_price: string
  before_max_price: string
  after_min_price: string
  after_max_price: string
  updated_at: string
  updated_by: string
  role: string
  activity: string
}

export type PricingEnginePaginationType = {
  total_elements: number
  prev_cursor: string
  next_cursor: string
}

export type PricingEngineErrorType = {
  message: string
  code: number
  status: boolean
}

export type GetPricingEngineHistoryResponseType = {
  data: PricingEngineHistoryItemType[]
  pagination: PricingEnginePaginationType
  error: PricingEngineErrorType
}

export const getPricingEngineHistory = (params?: PricingEngineHistoryParamsType) =>
  axiosInstanceApiGateway.Get<GetPricingEngineHistoryResponseType, true>({
    url: '/pricing-engine/internal/v1/price-histories',
    params,
  })
