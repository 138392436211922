import { memo } from "react";
import styled from "styled-components";
import { TextBody, SystemIcon, Toggle, Button } from "components";

function RowUom({ data, handleChangeStatus, handleEdit, handleClickDetail }) {
  return (
    <Tr>
      <Td ratio="2">
        <TextBody weight="light" color="textSoft">
          {data.id}
        </TextBody>
      </Td>
      <Td ratio="2">
        <TextBody weight="light" color="textSoft">
          {data.group}
        </TextBody>
      </Td>
      <Td ratio="2">
        <TextBody weight="light" color="textSoft">
          {data.description}
        </TextBody>
      </Td>

      <Td ratio="1">
        <Toggle onClick={() => handleChangeStatus(data)} active={data.active} />
      </Td>
      <Td ratio="1">
        <ActionWrapper>
          <Button variant="wrapper" onClick={() => handleClickDetail(data)}>
            <TextBody weight="light" className="pointer" color="main">
              Detail
            </TextBody>
          </Button>
          <Button variant="wrapper" onClick={() => handleEdit(data)}>
            <SystemIcon className="pointer" colorIcon="main" iconName="edit" />
          </Button>
        </ActionWrapper>
      </Td>
    </Tr>
  );
}

export default memo(RowUom);

const Tr = styled.tr`
  display: flex;
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
`;
const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`;

const ActionWrapper = styled.div`
  display: flex;
  i {
    margin-left: 20px;
  }
`;
