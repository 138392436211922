import freshPurchaseOrderSlice from './freshPurchaseOrderSlice'

export type { FpoItemType, FreshPurchaseOrderInterface } from './freshPurchaseOrderSlice'
export type { StatusToBeType } from 'utils/apiList/freshPurchaseOrder'

export const { setQuery, setSelectedDate, toggleModal, resetModal } =
  freshPurchaseOrderSlice.actions
export default freshPurchaseOrderSlice.reducer

export {
  fetchFreshPurchaseOrder,
  fetchWarehouseList,
  updateStatusOfFpo,
} from './freshPurchaseOrderThunk'
