import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'

import { getFeatureServiceBulkUploadAPI } from '../services/bulkUpload'

export const SLICE_NAME = 'BULK_UPLOAD'

export const fetchGetFeatureServiceBulkUpload = createAsyncThunk(
  `${SLICE_NAME}/fetchGetFeatureServiceBulkUpload`,
  async (params: Parameters<typeof getFeatureServiceBulkUploadAPI>[0], { rejectWithValue }) => {
    try {
      const res = await getFeatureServiceBulkUploadAPI(params)
      return res.data.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)
