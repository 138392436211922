import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const AreaListPageRBAC = {
  pageID: '9c728ee2c314cbed38a1bc013b45b3dc',
  elementID: {
    ButtonAddArea: 'aac29cf807a0d40b4ccd887ec555c632',
    ButtonEditArea: '5745603a56e4e9e792bf1c5881bae62f',
  },
} as const

export const useRBACAreaListPage = createUseRBAC(AreaListPageRBAC.elementID)

export type UseRBACAreaListPageType = ReturnType<typeof useRBACAreaListPage>
