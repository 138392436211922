import { memo, forwardRef } from 'react'
import styled from 'styled-components'
import { Title, TextBody, DropZone } from 'components'
import {
  OnChooseFile,
  OnFileSelected,
  OnUploadProgres,
  OnUploadSuccess,
  OnUploadFailed,
  OnUploadSuccessPartial,
} from './component'
import useUploadFileDropZone from './useUploadFileDropZone'

/** status: "idle" | "uploadOnProgress" | "uploadSucceded" | "uploadFailed" | "partialSuccess" */

const UploadFileDropZone = (
  {
    onResetFile,
    onClickDownloadTemplate,
    handleStartUpload,
    status = 'idle',
    heightBox,
    progress = 'Uploading ...',
    resultUpload,
    dataName = '',
    linkToGoWhenSuccess = null,
    useGetFileOnly = false,
    onGetFile = (file) => file,
    bodyStyle = {},
    allowedFileType = [],
    isMini = false,
    showFileType = false,
    customTextSelectFile = '',
    doAlertWrongTypeFile = false,
    customSuccessDescription,
    customSuccessPartialDescription,
  },
  ref,
) => {
  const { file, isNoFile, onReset, handleDropFile, emptyFile } = useUploadFileDropZone(
    onResetFile,
    onGetFile,
    allowedFileType,
    ref,
  )

  return (
    <Content>
      <Body style={{ ...bodyStyle }}>
        {!useGetFileOnly && (
          <BodyHeader>
            <Title>Upload File Excel</Title>
            <UploadDescrtion>
              <TextWrapper>
                <TextBody weight="light" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                  Pilih atau letakkan file Excel (.xlsx) kamu di sini.
                </TextBody>
              </TextWrapper>
              <ButtonDownloadTemplate onClick={onClickDownloadTemplate}>
                Download Template
              </ButtonDownloadTemplate>
            </UploadDescrtion>
          </BodyHeader>
        )}
        <BodyContent>
          {isNoFile && (
            <DropZone
              onDrop={(e) => {
                handleDropFile(e, doAlertWrongTypeFile)
              }}
            >
              <AreaUpload heightBox={heightBox}>
                <OnChooseFile
                  customTextSelectFile={customTextSelectFile}
                  isMini={isMini}
                  showFileType={showFileType}
                />
              </AreaUpload>
            </DropZone>
          )}
          {!isNoFile && (
            <AreaUpload heightBox={heightBox}>
              {
                {
                  idle: (
                    <OnFileSelected
                      file={file}
                      onResetFile={() => emptyFile()}
                      handleUploadFile={() => handleStartUpload(file)}
                      useGetFileOnly={useGetFileOnly}
                      isMini
                      showFileType
                    />
                  ),
                  uploadOnProgress: <OnUploadProgres progress={progress} />,
                  uploadSucceded: (
                    <OnUploadSuccess
                      onResetFile={onReset}
                      resultUpload={resultUpload}
                      dataName={dataName}
                      linkToGoWhenSuccess={linkToGoWhenSuccess}
                      customSuccessDescription={customSuccessDescription}
                    />
                  ),
                  uploadFailed: (
                    <OnUploadFailed resultUpload={resultUpload} onResetFile={onReset} />
                  ),
                  partialSuccess: (
                    <OnUploadSuccessPartial
                      onResetFile={onReset}
                      dataName={dataName}
                      resultUpload={resultUpload}
                      customSuccessPartialDescription={customSuccessPartialDescription}
                    />
                  ),
                }[status]
              }
            </AreaUpload>
          )}
        </BodyContent>
      </Body>
    </Content>
  )
}

export default memo(forwardRef(UploadFileDropZone))

const Body = styled.div`
  border-radius: 8px;
  background-color: ${({ theme: { colors } }) => colors.white};
  padding: 40px;
  margin-top: 24px;
  position: relative;
`

const Content = styled.div`
  position: relative;
  width: 100%;
`

const BodyHeader = styled.div`
  margin-bottom: 30px;
`

const UploadDescrtion = styled.div`
  display: flex;
  margin-top: 13px;
`

const TextWrapper = styled.div`
  display: flex;
  margin-right: 12px;
`

const ButtonDownloadTemplate = styled.a`
  background: none;
  border: none;
  cursor: pointer;
  padding: 9px 16px;
  border: 1px solid ${({ theme: { colors } }) => colors.main};
  border-radius: 6px;
  height: fit-content;
  background-color: ${({ theme: { colors } }) => colors.white};
  color: ${({ theme: { colors } }) => colors.main};
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
  div {
    transform: rotate(-90deg);
  }
`

const BodyContent = styled.div``

const AreaUpload = styled.div`
  border: 2px dashed #e4eaf3;
  border-radius: 8px;
  min-height: ${(props) => props.heightBox || '404px'};
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 4px;
  margin-bottom: 6px;
`
