interface IconAstroInterface {
  width?: number
  height?: number
}

function IconAstro({ width = 158, height = 32 }: IconAstroInterface) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 158 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.6349 8.36339L0 32L8.11588 31.4649L24.7687 14.7055C25.1935 14.2785 25.919 14.5021 26.0339 15.0921L27.2699 21.5221L27.4813 22.6216L16.3193 26.8454C16.137 26.8893 16.1789 27.1697 16.3667 27.155L28.1923 26.2975L28.9142 30.0961L35.4113 29.6691L31.0945 7.89612L23.6349 8.36339Z"
        fill="#131313"
      />
      <path
        d="M94.1712 3.83521L65.5396 5.70063L63.4031 10.8021L74.7292 10.0563L67.3006 27.6532L73.2745 27.2593L80.7177 9.59456L92.0584 8.84692L94.1712 3.83521Z"
        fill="#131313"
      />
      <path
        d="M122.875 3.9746C121.969 2.65891 120.464 2.122 118.345 2.26127L96.8379 3.66125L87.28 26.3468L93.2539 25.9529L96.7267 17.7087L106.711 17.0509L109.819 24.8607L116.768 24.4026L113.152 16.547C115.188 16.1878 117.135 15.3009 118.992 13.8881C120.865 12.4423 122.19 10.7968 122.968 8.94967C123.811 6.95048 123.78 5.29029 122.875 3.9746ZM117.035 9.3583C116.779 9.98866 116.338 10.5329 115.709 10.9928C115.117 11.4399 114.47 11.6873 113.766 11.7331L98.8377 12.7007L100.697 8.28816L115.633 7.30414C116.349 7.22534 116.823 7.39209 117.051 7.80806C117.33 8.17821 117.324 8.69313 117.035 9.3583Z"
        fill="#131313"
      />
      <path
        d="M156.9 1.63109C156.082 0.427183 154.693 -0.109721 152.735 0.0185499L135.626 1.1455C133.679 1.27377 131.745 2.03057 129.826 3.41406C127.917 4.79755 126.577 6.40826 125.802 8.24253L122.131 16.9521L129.042 14.339L131.32 8.92786C131.641 8.16373 132.175 7.52238 132.925 7.00014C133.69 6.43391 134.465 6.13156 135.249 6.08941L148.843 5.19335C149.654 5.10723 150.212 5.31979 150.518 5.82554C150.852 6.28732 150.857 6.90118 150.535 7.66348L147.461 14.9621C147.158 15.6804 146.622 16.3236 145.857 16.8898C145.118 17.412 144.344 17.7144 143.532 17.8005L131.911 18.5665L127.304 18.9403L121.327 19.3746C121.32 19.3966 121.314 19.4131 121.31 19.4259C121.146 20.5125 121.336 21.4544 121.883 22.2588C122.711 23.4628 124.1 23.9997 126.048 23.8714L143.157 22.7463C145.117 22.6162 147.049 21.8612 148.958 20.4777C150.875 19.0924 152.217 17.4835 152.981 15.6492L156.964 6.1957C157.75 4.3541 157.728 2.83501 156.9 1.63109Z"
        fill="#131313"
      />
      <path
        d="M58.3454 15.3248C56.9088 15.4182 54.6447 15.5703 51.5566 15.7646C48.4849 15.9588 46.2135 16.1219 44.7441 16.219C44.2337 16.252 43.6157 16.1714 43.4644 15.8214C43.3332 15.5172 43.6576 14.4489 43.8436 13.8588C44.0022 13.3549 44.1025 12.9078 44.5947 12.5523C45.1142 12.1528 45.6246 11.9531 46.1205 11.9512L60.8592 10.9837L62.9866 5.86572L47.2088 6.89739C45.1689 7.09529 43.1727 7.89973 41.224 9.30704C39.2734 10.6942 37.8971 12.3379 37.0949 14.24C36.3038 16.12 36.3621 18.4234 37.1988 19.6805C38.0465 20.9155 39.4666 21.4726 41.4646 21.3517L55.569 20.4226C56.1432 20.3841 56.7776 20.2907 56.9088 20.6004C56.9745 20.7561 56.8523 20.9962 56.6135 21.5606C56.3857 22.103 56.0776 22.8286 55.5763 23.4755C54.9601 24.2689 54.4205 24.2909 53.8098 24.3642L40.2377 25.3574C40.1849 25.3629 37.9189 25.5369 37.2791 25.5736L38.0903 29.5866C42.7936 29.2989 52.2985 28.6576 52.2985 28.6576C54.3749 28.5202 56.4312 28.194 58.4365 26.7299C60.4837 25.2621 61.8983 23.076 62.7132 21.1189C63.57 19.1601 63.5463 17.5438 62.6439 16.2703C61.7926 14.9674 60.4272 15.1892 58.3454 15.3248Z"
        fill="#131313"
      />
    </svg>
  )
}

export default IconAstro
