export interface BreakpointsInterface extends Array<string> {
  xs?: string
  sm?: string
  md?: string
  lg?: string
  xl?: string
  mobile?: string
  tablet?: string
  desktop?: string
}

const breakpointsContstant: BreakpointsInterface = ['0', '425px', '768px', '992px', '1200px']

const breakpoints = {
  ...breakpointsContstant,
  ...{
    xs: breakpointsContstant[0],
    sm: breakpointsContstant[1],
    md: breakpointsContstant[2],
    lg: breakpointsContstant[3],
    xl: breakpointsContstant[4],
    mobile: breakpointsContstant[0],
    tablet: breakpointsContstant[2],
    desktop: breakpointsContstant[3],
  },
}

export default breakpoints
