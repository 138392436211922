/* eslint-disable @typescript-eslint/no-explicit-any */
import { toastFailed } from 'utils/toast'
import { ERROR_INVALID_AUTH_MESSAGE } from 'constant/errorMessages'
import React from 'react'
import { useViewOnly, useAllowedAccess } from './useAllowedAccessFor'

type CheckPermissionsType = (callbackFn: (...args: any[]) => void) => (...args: any[]) => void

type AccessControlPropsType = {
  /**
   * blockedPermissions should not be used anymore for next development.
   */
  blockedPermissions: string[]
  allowedPermissions?: string[]
  renderElement?: (args: {
    isAllowedAccess: boolean
    checkPermissions: CheckPermissionsType
  }) => JSX.Element
  children?: React.ReactNode
}

export const useAccessControl = ({
  blockedPermissions = [],
  allowedPermissions = [],
}: {
  blockedPermissions?: AccessControlPropsType['blockedPermissions']
  allowedPermissions?: AccessControlPropsType['allowedPermissions']
}) => {
  let isAllowedAccess = false

  const allowAccess = useAllowedAccess(allowedPermissions)

  /**
   * useViewOnly hooks should not be used any more once useAccessControl only receive arg allowedPermissions
   */
  const isViewOnly = useViewOnly(blockedPermissions)

  // has allowedPermission, means uses allowed approach instead the previous (blocked approach)
  if (allowedPermissions.length) {
    isAllowedAccess = allowAccess
  } else {
    // fallback to deprecated implementation
    isAllowedAccess = !isViewOnly
  }

  const checkPermissions: CheckPermissionsType =
    (callbackFn) =>
    (...args) => {
      if (typeof callbackFn === 'function') {
        if (isAllowedAccess) {
          callbackFn(...args)
        } else {
          toastFailed(ERROR_INVALID_AUTH_MESSAGE)
        }
      }
    }

  return {
    isAllowedAccess,
    checkPermissions,
  }
}

export const AccessControl = ({
  blockedPermissions = [],
  allowedPermissions = [],
  renderElement,
  children,
}: AccessControlPropsType) => {
  const { isAllowedAccess, checkPermissions } = useAccessControl({
    blockedPermissions,
    allowedPermissions,
  })

  if (renderElement) return renderElement({ isAllowedAccess, checkPermissions })
  if (!isAllowedAccess) return null

  return <>{children}</>
}
