import createReducer from 'storeContext/reducers/createReducer'
import {
  SET_IS_LOADING,
  SET_QUERY,
  SET_RESET,
  SET_BOM_ITEM,
  SET_FORM,
  SET_COMPONENTS,
  SET_SELECTED_COMPONENTS,
  SET_REMOVE_COMPONENT,
  SET_PRODUCTS,
  SET_QTY,
  SET_IS_SHOW_PRODUCT_HUB,
  SET_HUBS_BY_PRODUCT,
  SET_FINISH_ADD_HUB_TO_PRODUCT,
  SET_PRODUCT_DETAIL,
} from 'storeContext/actionsType/bomAddEdit'

export const initialStateBoMAddEdit = {
  isLoading: false,
  query: {},
  pagination: {},
  data: null,
  form: {
    product: null,
    total: 1,
    uom: '',
    criteria: '',
    note: '',
    active: false,
  },
  components: [],
  mergedComponents: {},
  products: [],
  isShowModalAddProductHub: false,
  hubs: [],
}

const bomReducer = createReducer({
  [SET_IS_LOADING]: (state, action) => {
    state.isLoading = action.payload
  },
  [SET_QUERY]: (state, action) => {
    state.query = {
      ...state.query,
      ...action.payload.query,
    }
  },
  [SET_BOM_ITEM]: (state, action) => {
    const { data } = action.payload
    const { productCriteria, note, active } = data
    state.data = data
    state.form.criteria = productCriteria
    state.form.note = note
    state.form.active = active
  },
  [SET_FORM]: (state, action) => {
    const { key, value } = action.payload

    state.form[key] = value
  },
  [SET_COMPONENTS]: (state, action) => {
    const { data, pagination } = action.payload
    const { pageSize, pageIndex, totalElement } = pagination

    data.forEach((item) => {
      state.mergedComponents[item.productId] = item
    })

    state.components = data
    state.pagination = {
      pageSize,
      currentPage: pageIndex,
      totalPage: Math.ceil(totalElement / pageSize),
    }
    state.isLoading = false
  },
  [SET_SELECTED_COMPONENTS]: (state, action) => {
    Object.keys(action.payload).forEach((key) => {
      state.mergedComponents[key] = action.payload[key]
    })
  },
  [SET_REMOVE_COMPONENT]: (state, action) => {
    const id = action.payload
    delete state.mergedComponents[id]
  },
  [SET_PRODUCTS]: (state, action) => {
    state.products = action.payload

    state.form.criteria = 'BoM'
  },
  [SET_PRODUCT_DETAIL]: (state, action) => {
    const product = action.payload
    state.form.product = product
    state.form.uom = product.baseUom
    state.form.criteria = product.bomCategory
  },
  [SET_QTY]: (state, action) => {
    const { id, value } = action.payload

    state.mergedComponents[id].qty = value
  },
  [SET_RESET]: () => {
    return initialStateBoMAddEdit
  },
  [SET_IS_SHOW_PRODUCT_HUB]: (state, action) => {
    state.isShowModalAddProductHub = action.payload
  },
  [SET_HUBS_BY_PRODUCT]: (state, action) => {
    state.hubs = action.payload
  },
  [SET_FINISH_ADD_HUB_TO_PRODUCT]: (state) => {
    state.isShowModalAddProductHub = false
    state.hubs = []
  },
})

export default bomReducer
