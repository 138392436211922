import { Options } from 'react-lottie'
import { DateFormatType, dateFormat } from '@astronautsid/wpe-utils'
import { withUtc } from 'utils/helpers/date'
import {
  BannerThematicAddComponentType,
  ComponentFormType,
  TargetingType,
} from '../@types/bannerThematic'
import { BannerThematicComponentPayloadType } from '../sevices/bannerThematic'

export const validateComponentForm = (data: ComponentFormType) => {
  if (!data.name) return 'Judul harus diisi.'
  if (!data.category) return 'Category harus diisi.'
  if (!data.location_ids.length) return 'Hub harus diisi.'
  if (!data.startDate || !data.startTime || !data.endDate || !data.endTime)
    return 'Schedule harus diisi.'

  return ''
}

export const formatDateISO = (ISODate: string | undefined, format: DateFormatType) =>
  ISODate ? dateFormat({ date: new Date(ISODate), format }) : ''

export const transformDate = (date: string, time: string) => {
  if (!date || !time) return ''

  return withUtc(new Date(`${date} ${time}`), true)
    .locale('en')
    .toISOString()
}

export const createComponentPayload = (
  data: ComponentFormType,
  thematicId: number,
  sequence: number,
): BannerThematicComponentPayloadType => {
  const {
    browse_thematic_images,
    location_ids,
    startDate,
    startTime,
    endDate,
    endTime,
    targeting_type,
    category,
    ...rest
  } = data
  const targetingType: Pick<BannerThematicComponentPayloadType, 'targeting_type'> | ObjectNullType =
    {}

  if (typeof targeting_type !== undefined) {
    targetingType.targeting_type = targeting_type?.id || 1
  }

  return {
    ...rest,
    ...targetingType,
    browse_thematic_images: {
      component: rest.component,
      url_small: browse_thematic_images.image?.url_small || '',
      url_medium: browse_thematic_images.image?.url_medium || '',
      url_large: browse_thematic_images.image?.url_large || '',
    },
    location_ids: location_ids.map(({ location_id }) => location_id),
    start_date: transformDate(startDate, startTime),
    end_date: transformDate(endDate, endTime),
    thematic_id: thematicId,
    category: category
      ? {
          category_id: category?.category_id as number,
          category_name: category?.category_name as string,
        }
      : null,
    slug: window.crypto.randomUUID(),
    sequence,
  }
}

export type ImageRenderListType = {
  type: 'LOTTIE' | 'STATIS'
  url: string
  lottieOption: Nullable<Options>
}

const LOTTIE_OPTION_DEFAULT: Options = {
  loop: true,
  autoplay: true,
  animationData: null,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

export const createImageRenderList = async (
  items: Array<Partial<BannerThematicAddComponentType>>,
): Promise<ImageRenderListType[]> => {
  const data = items.map((item) => ({ ...item, key: crypto.randomUUID() }))
  const lottieData = data.filter(({ animated_url }) => animated_url)

  const result = await Promise.allSettled(
    lottieData.map(({ animated_url = '' }) => fetch(animated_url).then((res) => res.json())),
  )

  return data.map((item) => {
    if (item.animated_url) {
      const indexLottieFile = lottieData.findIndex(({ key }) => item.key === key)

      if (indexLottieFile !== -1 && result[indexLottieFile].status === 'fulfilled') {
        return {
          type: 'LOTTIE',
          url: '',
          lottieOption: {
            ...LOTTIE_OPTION_DEFAULT,
            animationData: (
              result[indexLottieFile] as PromiseFulfilledResult<Options['animationData']>
            ).value,
          },
        }
      }
    }

    return {
      type: 'STATIS',
      url: item.browse_thematic_images?.url_large || '',
      lottieOption: null,
    }
  })
}

export const composeComponentFormData = (
  data: BannerThematicAddComponentType,
  targetingTypeOptions?: TargetingType[],
): Partial<ComponentFormType> => {
  const {
    browse_thematic_component_hubs,
    browse_thematic_images,
    targeting_type,
    category,
    ...rest
  } = data

  const matchTargetingType = targetingTypeOptions?.find((item) => item.id === targeting_type)

  return {
    ...rest,
    location_ids: browse_thematic_component_hubs,
    browse_thematic_images: {
      file: null,
      imageToPreview: browse_thematic_images?.url_large || '',
      image: browse_thematic_images,
    },
    targeting_type: matchTargetingType || null,
    startDate: formatDateISO(browse_thematic_component_hubs[0]?.start_time, 'YYYY-MM-DD'),
    startTime: formatDateISO(browse_thematic_component_hubs[0]?.start_time, 'HH:mm'),
    endDate: formatDateISO(browse_thematic_component_hubs[0]?.end_time, 'YYYY-MM-DD'),
    endTime: formatDateISO(browse_thematic_component_hubs[0]?.end_time, 'HH:mm'),
    category: category?.category_id ? category : null,
  }
}
