import axiosInstanceApiGateway from 'config/apiServiceApiGateway'
import {
  GetCustomerFraudListRequestType,
  GetCustomerFraudListResponseType,
  GetFraudReasonsResponseType,
  PatchOrderFraudRequestType,
  PatchOrderFraudResponseType,
} from 'features/Fraud/@types/userFraudStatus'

const { Get, Patch } = axiosInstanceApiGateway

export const getCustomerFraudList = ({ params }: GetCustomerFraudListRequestType) =>
  Get<GetCustomerFraudListResponseType, true>({
    url: `/fraud/internal/v1/customers`,
    params,
  })

export const getFraudReasonsList = () =>
  Get<GetFraudReasonsResponseType>({
    url: `/fraud/internal/v1/customer/release/reasons`,
  })

export const patchOrderFraud = ({ params }: PatchOrderFraudRequestType) =>
  Patch<PatchOrderFraudResponseType>({
    url: `/fraud/internal/v1/customer/status`,
    data: {
      is_fraud: false,
      api: '/fraud/internal/v1/customer/status',
      ...params,
    },
  })
