import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type {
  FPOIListResponseType,
  PaginationResponseType,
} from 'utils/apiList/freshPurchaseOrderInbound'
import { fetchFPOIList, fetchWarehouseLocationsList } from './freshPurchaseOrderInboundThunk'

export interface WarehouseLocationsListType {
  locationId: number
  locationName: string
  name: string
}

export interface FPOInboundStateType {
  isLoading: boolean
  query: {
    date: string
    fpoNumber: string
    locationId: number
    status: string
    searchFrom: {
      name: string
      value: string
    }
    location: {
      name: string
      id: number
    }
    pageSize: number
    pageIndex: number
  }
  fPOIList: FPOIListResponseType[]
  warehouseLocationsList: WarehouseLocationsListType[]
  pagination: PaginationResponseType
}

export const initialState: FPOInboundStateType = {
  isLoading: true,
  query: {
    date: '',
    fpoNumber: '',
    locationId: 0,
    status: '',
    searchFrom: { name: 'No. Inbound FPO', value: 'no_inbound_fpo' },
    location: {
      name: '',
      id: 0,
    },
    pageSize: 20,
    pageIndex: 0,
  },

  fPOIList: [
    {
      id: 1,
      inboundNumber: '',
      fpoNumber: '',
      location: {
        locationId: 0,
        locationName: '',
      },
      totalItem: 0,
      status: '',
      createdAt: 0,
    },
  ],
  warehouseLocationsList: [{ locationId: 0, locationName: '', name: '' }],
  pagination: {
    direction: '',
    numberOfElements: 0,
    pageIndex: 0,
    pageSize: 0,
    sort: '',
  },
}

const freshPurchaseOrderInbound = createSlice({
  name: 'freshPurchaseInbound',
  initialState,
  reducers: {
    reset: () => initialState,
    setQuery: (
      state,
      action: PayloadAction<{
        fieldName: keyof FPOInboundStateType['query']
        value:
          | string
          | number
          | FPOInboundStateType['query']['searchFrom']
          | FPOInboundStateType['query']['location']
      }>,
    ) => {
      const { fieldName, value } = action.payload
      state.query[fieldName] = value as never
    },
    setPagination: (state, action: PayloadAction<PaginationResponseType>) => {
      state.pagination = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFPOIList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFPOIList.fulfilled, (state, action) => {
        state.isLoading = false
        state.fPOIList = action.payload
      })
      .addCase(fetchFPOIList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchWarehouseLocationsList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchWarehouseLocationsList.fulfilled, (state, action) => {
        state.isLoading = false
        state.warehouseLocationsList = action.payload.map((el) => ({
          locationId: el.location_id,
          locationName: el.location_name,
          name: el.location_name,
        }))
      })
      .addCase(fetchWarehouseLocationsList.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const { reset, setQuery } = freshPurchaseOrderInbound.actions
export default freshPurchaseOrderInbound.reducer
