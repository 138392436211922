export const SET_IS_START_BUYER = 'SET_IS_START_BUYER'
export const SET_IS_FAILED_BUYER = 'SET_IS_FAILED_BUYER'
export const SET_IS_SUCCESS_BUYER = 'SET_IS_SUCCESS_BUYER'
export const SET_IS_NEED_TO_RELOAD_BUYER = 'SET_IS_NEED_TO_RELOAD_BUYER'
export const SET_PAGINATION_DATA_BUYER = 'SET_PAGINATION_DATA_BUYER'

export const SET_IS_SUCCESS_ASTROSALDOHISTORY_BUYER = 'SET_IS_SUCCESS_ASTROSALDOHISTORY_BUYER'
export const SET_IS_SUCCESS_BUYERPOINTS_BUYER = 'SET_IS_SUCCESS_BUYERPOINTS_BUYER'
export const SET_PAGINATION_DATA_BUYERPOINTS_BUYER = 'SET_PAGINATION_DATA_BUYERPOINTS_BUYER'
export const SET_DETAILS_ORDER_DATA_BUYERPOINTS_BUYER = 'SET_DETAILS_ORDER_DATA_BUYERPOINTS_BUYER'

export const SET_IS_SHOW_MODAL_INJECT_SALDO_BUYER = 'SET_IS_SHOW_MODAL_INJECT_SALDO_BUYER'
export const SET_IS_SHOW_MODAL_CONFIRMATION_INJECT_SALDO_BUYER =
  'SET_IS_SHOW_MODAL_CONFIRMATION_INJECT_SALDO_BUYER'
export const SET_FORM_MODAL_INJECT_SALDO_BUYER = 'SET_FORM_MODAL_INJECT_SALDO_BUYER'
export const RESET_FORM_MODAL_INJECT_SALDO_BUYER = 'RESET_FORM_MODAL_INJECT_SALDO_BUYER'
