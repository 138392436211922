import { lazy } from 'react'

import { PurchaseOrderAssetRBAC } from '../pages/PurchaseOrderAsset/rbac'
import { POAssetCheckQuantityRBACPage } from '../pages/POAssetCheckQuantity/rbac'

const PurchaseOrderAssetCheckQuantity = lazy(() => import('../pages/POAssetCheckQuantity'))
const PurchaseOrderAsset = lazy(() => import('../pages/PurchaseOrderAsset'))
const PurchaseOrderAssetDetail = lazy(() => import('pages/PurchaseOrderLiteDetail'))

export const PurchaseOrderAssetRouter: RouterFeatureInterface<
  'purchaseOrderAsset',
  '/dashboard/asset/purchase-order-assets'
> = {
  name: 'purchaseOrderAsset',
  Component: PurchaseOrderAsset,
  path: '/dashboard/asset/purchase-order-assets',
  routeType: 'PRIVATE',
  rbacPageID: PurchaseOrderAssetRBAC.pageID,
  allowedRoles: [],
  isDashboard: true,
}

export const PurchaseOrderAssetCheckQuantityRouter: RouterFeatureInterface<
  'purchaseOrderAssetCheckQuantity',
  '/dashboard/asset/purchase-order-assets/check-quantity/:id'
> = {
  name: 'purchaseOrderAssetCheckQuantity',
  Component: PurchaseOrderAssetCheckQuantity,
  path: '/dashboard/asset/purchase-order-assets/check-quantity/:id',
  rbacPageID: POAssetCheckQuantityRBACPage.pageID,
  routeType: 'PRIVATE',
  allowedRoles: [],
  isDashboard: true,
}

export const PurchaseOrderDetail: RouterFeatureInterface<
  'purchaseOrderAssetDetail',
  '/dashboard/asset/purchase-order-assets/:id'
> = {
  name: 'purchaseOrderAssetDetail',
  Component: PurchaseOrderAssetDetail,
  path: '/dashboard/asset/purchase-order-assets/:id',
  routeType: 'PRIVATE',
  rbacPageID: PurchaseOrderAssetRBAC.pageID,
  allowedRoles: [],
  isDashboard: true,
}

export const PurchaseOrderRouter = [
  PurchaseOrderAssetRouter,
  PurchaseOrderAssetCheckQuantityRouter,
  PurchaseOrderDetail,
]
