import axios from 'axios'
import axiosInstanceApiGateway from 'config/apiServiceApiGateway'
const { REACT_APP_BASE_URL_API } = process.env
const baseUrl = REACT_APP_BASE_URL_API
const { Get, Post } = axiosInstanceApiGateway

export const geAllOrderList = (query = '') => axios.get(`${baseUrl}/api/order${query}`)

export const getAllOrderDetail = (id) => axios.get(`${baseUrl}/api/order/${id}`)

export const getHub = () => axios.get(`${baseUrl}/api/locations/hub`)

export const getAllLocation = () => axios.get(`${baseUrl}/api/locations/order`)

export const getSuperIntersectHub = (locationId) =>
  axios.get(`${baseUrl}/api/location/intersect-hub/${locationId}`)

export const getAllLocationType = () => axios.get(`${baseUrl}/api/location/type`)

export const putCancelOrder_AllOrder = (id, data) =>
  axios.put(`${baseUrl}/api/order/${id}/cancel`, data)

export const putCompleteOrder_AllOrder = (id, data) =>
  axios.put(`${baseUrl}/api/order/${id}/completion`, data)

export const postCreateOrder_AllOrder = (data) => axios.post(`${baseUrl}/api/order/reference`, data)

export const postVerifyLocation_AllOrder = (id, data) =>
  axios.post(`${baseUrl}/api/driver-admin/buyer-location-validation/${id}`, data)

export const getOrderReferenceByInvoice_AllOrder = (invoice) =>
  axios.get(`${baseUrl}/api/order/reference${invoice}`)

export const getAllProductInventory_AllOrder = (location_id, query) =>
  axios.get(`${baseUrl}/api/inventory/product/${location_id}/all${query}`)

export const getDriver_OrderPooling = (location_id) =>
  axios.get(`${baseUrl}/api/driver-admin/available/${location_id}`)

export const postOrderPooling_OrderPooling = (data) =>
  axios.put(`${baseUrl}/api/order/pooling/assign-driver`, data)

export const getCustomerAddress_AllOrder = (customerID, addressID) =>
  axios.get(`${baseUrl}/api/customers/${customerID}/addresses/${addressID}`)

export const putCustomerAddress_AllOrder = (customerID, addressID, data) =>
  axios.put(`${baseUrl}/api/customers/${customerID}/addresses/${addressID}`, data)

export const getCustomerAddressNotes_AllOrder = () =>
  axios.get(`${baseUrl}/api/customers/address/note`)

export const putSkipVerificationPackage = (payload, id) =>
  axios.put(`${baseUrl}/api/packer/order/${id}/package-confirmation`, payload)
export const putSkipVerificationDropOff = (payload, id) =>
  axios.post(`${baseUrl}/api/order/${id}/bypass`, payload)

export const geAllSuperOrderList = (query = '') => axios.get(`${baseUrl}/api/super/order${query}`)
export const getAvailableTimeslots = (query = '') =>
  axios.get(`${baseUrl}/api/order/timeslots${query}`)
export const getCancelCompleteReason = () =>
  axios.get(`${baseUrl}/api/order/cancel-complete-reason`)

export const putSudoCancelOrder_AllOrder = (id, payload) =>
  axios.put(`${baseUrl}/api/order/${id}/super-cancel`, payload)

export const getOrderTypes = () => axios.get(`${baseUrl}/api/order/types`)

export const getActionsReasons = (action) =>
  Get({ url: `/oms/internal/v1/action-reasons?action=${action}` })

export const postVerifyReturn = (orderId, payload) =>
  Post({ url: `/logistic/internal/v1/cs/deliveries/${orderId}/hold`, data: payload })
export const postOnHold = (orderId, payload) =>
  Post({ url: `/logistic/internal/v1/cs/deliveries/${orderId}/hold`, data: payload })
export const postRedelivery = (orderId, payload) =>
  Post({ url: `/logistic/internal/v1/cs/deliveries/${orderId}/redeliver`, data: payload })
