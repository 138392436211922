import gosendLogo from 'assets/images/gosendLogo.svg'
import grabLogo from 'assets/images/grabLogo.svg'

export const externalDriverValidator = (logistic_term, order_status, used) => {
  let allowedStatus = ['new order', 'picking', 'packing', 'waiting driver', 'cancelled']
  let allowedLogistic = ['optional', 'mandatory']

  if (used) {
    return true
  }
  if (logistic_term && allowedLogistic.indexOf(logistic_term.toLowerCase()) !== -1) {
    if (order_status && allowedStatus.indexOf(order_status.toLowerCase()) !== -1) {
      return true
    }
  }
  return false
}

export const parse3plLogo = (provider) => {
  switch (provider.toLowerCase()) {
    case 'grab':
      return <img style={{ width: 51, height: 'auto', marginTop: 6 }} src={grabLogo}></img>
    case 'gosend':
      return <img style={{ width: 51, height: 'auto', marginTop: 6 }} src={gosendLogo}></img>
    case 'gojek':
      return <img style={{ width: 51, height: 'auto', marginTop: 6 }} src={gosendLogo}></img>
    default:
      return <p>No logo available</p>
  }
}
