import { memo } from "react";
import styled from "styled-components";
import { RowBuyerPoints } from "components";

function TableBuyerPoints({ data, handleOpenDetails }) {
  const handleOpenPopup = (orderId) => {
    //
    handleOpenDetails(orderId);
  };
  return (
    <>
      <Container>
        <thead>
          <Tr>
            <Th ratio="4">TANGGAL DAN WAKTU</Th>
            <Th ratio="3">TIPE POINTS</Th>
            <Th ratio="4">Judul</Th>
            <Th ratio="4">Deskripsi</Th>
            <Th ratio="4">JUMLAH POINTS</Th>
            <Th ratio="4">KODE/NOMER</Th>
          </Tr>
        </thead>
        <tbody>
          {data?.map((items, index) => {
            return (
              <RowBuyerPoints
                handleOpenPopup={handleOpenPopup}
                key={"sdad" + index}
                data={items}
              />
            );
          })}
        </tbody>
      </Container>
    </>
  );
}

export default memo(TableBuyerPoints);

const Container = styled.table`
  width: 100%;
`;
const Tr = styled.tr`
  display: flex;
  padding: 16px 32px;
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`;
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  text-transform: uppercase;
`;
