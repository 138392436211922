import apiServices from 'config/apiServiceApiGateway'

const { Get, Post, Put } = apiServices

export type PostFlashSaleRequestType = {
  payload: {
    name: string
    start_period: number | string
    end_period: number | string
    background_url: string
    details: {
      date: number | string
      start_time: number | string
      end_time: number | string
      campaign_ids: number[]
    }[]
  }
}

export type PostFlashSaleResponseType = {
  data: {
    success: boolean
  }
}

export type PutFlashSaleRequestType = {
  payload: {
    name: string
    start_period: number | string
    end_period: number | string
    background_url: string
    details: {
      date: number | string
      start_time: number | string
      end_time: number | string
      campaign_ids: number[]
    }[]
  }
  id: string
}

export type PutFlashSaleResponseType = {
  data: {
    success: boolean
  }
}

export type GetFlashSaleDetailRequestType = {
  id: string
}

export type GetFlashSaleDetailResponseType = {
  id: number
  name: string
  start_period: number
  end_period: number
  background_url: string
  campaign_list: {
    fs_date: number
    start_time: number
    end_time: number
    campaign_data: {
      campaign_id: number
      campaign_name: string
    }[]
  }[]
}

export const postFlashSale = ({ payload }: PostFlashSaleRequestType) =>
  Post<PostFlashSaleResponseType>({ url: `/campaign/internal/v1/flash-sale`, data: payload })

export const putFlashSale = ({ payload, id }: PutFlashSaleRequestType) =>
  Put<PutFlashSaleResponseType>({ url: `/campaign/internal/v1/flash-sale/${id}`, data: payload })

export const getFlashSaleDetail = ({ id }: GetFlashSaleDetailRequestType) =>
  Get<GetFlashSaleDetailResponseType>({ url: `/campaign/internal/v1/flash-sale/${id}` })
