import {
  CircularProgress as MuiCircularProgress,
  CircularProgressProps as MuiCircularProgressProps,
} from '@mui/material'
import styled from 'styled-components'
import { space, SpaceProps } from 'styled-system'

export type CircularProgressPropsType = MuiCircularProgressProps & SpaceProps

const CircularProgress = styled(MuiCircularProgress)<CircularProgressPropsType>`
  ${space}
`

export default CircularProgress
