import AxiosInstance from 'config/apiServiceApiGateway'
import { Get } from 'config/apiServiceConfig'

import {
  ProductAssetResponseInterface,
  GetUomsParamsType,
  GetUomsResponseType,
  ProductAssetByIDResponseInterface,
  ReviewAssetParamsType,
} from 'features/AssetManagement/@types/typeProductAssetList'

import {
  AddEditAssetResponseInterface,
  AddEditAssetParamInterface,
} from 'features/AssetManagement/@types/typeAddEditAsset'

interface GetProductListParamInterface {
  source?: string
  page_index?: number
  page_size?: number
  name?: string
  status?: string
}

export const getProductAssetListAPI = (query: GetProductListParamInterface) =>
  AxiosInstance.Get<ProductAssetResponseInterface, true>({
    url: '/ams/internal/v1/assets',
    params: query,
  })

export const getProductAssetByIDAPI = (id: number) =>
  AxiosInstance.Get<ProductAssetByIDResponseInterface, true>({
    url: `ams/internal/v1/${id}/assets`,
  })

export const getUomsAPI = (params: GetUomsParamsType) =>
  Get<GetUomsResponseType>({ url: '/api/uoms', params })

export const addNewProductAPI = (param: AddEditAssetParamInterface) =>
  AxiosInstance.Post<AddEditAssetResponseInterface, true>({
    url: '/ams/internal/v1/assets/create',
    data: param,
  })

export const postEditProductAPI = (param: AddEditAssetParamInterface, id: number) =>
  AxiosInstance.Post<AddEditAssetResponseInterface, true>({
    url: `/ams/internal/v1/assets/${id}/update`,
    data: param,
  })

export const postReviewAssetAPI = (
  id: number,
  payload: Omit<ReviewAssetParamsType, 'asset_id' | 'name'>,
) =>
  AxiosInstance.Post<AddEditAssetResponseInterface, true>({
    url: `/ams/internal/v1/assets/${id}/review`,
    data: payload,
  })
