import {
  FormHelperText as MuiFormHelperText,
  FormHelperTextProps as MuiFormHelperTextProps,
} from '@mui/material'
import styled from 'styled-components'

export type FormHelperTextPropsType = MuiFormHelperTextProps

const FormHelperText = styled(MuiFormHelperText)<FormHelperTextPropsType>`
  margin-left: 4px;
`

export default FormHelperText
