import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  AssetSupplyOrderListDataInterface,
  AssetSupplyOrderListResponseInterface,
} from 'features/AssetManagement/@types/typeSupplyOrder'

import { LocationInterface } from 'features/AssetManagement/@types/global'

interface SupplyOrderListAssetStateType {
  isLoading: boolean
  supplyOrderList: (AssetSupplyOrderListDataInterface & { checked: boolean })[]
  locationLists: LocationInterface[]
  hasNext: boolean
}

const initialState: SupplyOrderListAssetStateType = {
  isLoading: false,
  supplyOrderList: [],
  locationLists: [],
  hasNext: false,
}

const supplyOrderAssetListSlice = createSlice({
  name: 'supplyOrderAssetList',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setSupplyOrderResponse: (
      state,
      action: PayloadAction<AssetSupplyOrderListResponseInterface>,
    ) => {
      const newObject = action.payload.data.map((item) => ({
        ...item,
        checked: false,
      }))
      state.supplyOrderList = newObject
      state.hasNext = action.payload.has_next
    },
    setSupplyOrderList: (
      state,
      action: PayloadAction<SupplyOrderListAssetStateType['supplyOrderList']>,
    ) => {
      state.supplyOrderList = action.payload
    },
    setLocationLists: (
      state,
      action: PayloadAction<SupplyOrderListAssetStateType['locationLists']>,
    ) => {
      state.locationLists = action.payload
    },
  },
})

export const { setIsLoading, setSupplyOrderList, setSupplyOrderResponse, setLocationLists } =
  supplyOrderAssetListSlice.actions

export default supplyOrderAssetListSlice.reducer
