import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'
import { callErrorMsg } from 'helpers/errorMsg'

import {
  getSupplyOrderGrandData,
  GetSupplyOrderPickingResponseType,
  GetSupplyOrderPickingRequestType,
  putUpdateQty,
  PutUpdateQtyRequestType,
  PutUpdateQtyResponseType,
  PutCompletePickingResponseType,
  PutCompletePickingRequestType,
  putCompletePicking,
} from 'utils/apiList/supplyOrderPicking'
import { toastSuccess } from 'utils/toast'

export const fetchGetDeliveryOrder = createAsyncThunk<
  GetSupplyOrderPickingResponseType,
  GetSupplyOrderPickingRequestType,
  RejectValueType
>('supplyOrderPicking/fetchGetDeliveryOrder', async ({ id }, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<GetSupplyOrderPickingResponseType> =
      await getSupplyOrderGrandData(id)

    return response.data
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchPutUpdateQty = createAsyncThunk<
  PutUpdateQtyResponseType,
  PutUpdateQtyRequestType,
  RejectValueType
>('supplyOrderPicking/fetchPutUpdateQty', async (params, { rejectWithValue, dispatch }) => {
  try {
    const response: AxiosResponse<PutUpdateQtyResponseType> = await putUpdateQty(params)

    toastSuccess('Berhasil Terupdate')
    dispatch(fetchGetDeliveryOrder({ id: response.data.data.supplyOrderId }))

    return response.data
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchPutCompletePicking = createAsyncThunk<
  PutCompletePickingResponseType,
  PutCompletePickingRequestType & {
    meta: {
      callback: () => void
    }
  },
  RejectValueType
>('supplyOrderPicking/fetchPutCompletePicking', async ({ id, meta }, { rejectWithValue }) => {
  try {
    const response: AxiosResponse<PutCompletePickingResponseType> = await putCompletePicking({ id })

    toastSuccess('Berhasil Terupdate')
    meta.callback()

    return response.data
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})
