import { Get } from 'config/apiServiceConfig'
import axiosInstanceApiGateway from 'config/apiServiceApiGateway'
import {
  GetSLAConfigResponseType,
  GetLocationListResponseType,
  GetLocationListRequestType,
  PutSLAConfigRequestType,
  PutSLAConfigResponseType,
  GetLocationDetailResponseType,
  GetLocationTypeResponseType,
  GetLocationListByTypeResponseType,
  LocationListType,
} from 'features/Location/@types/location'

const { Get: GetKong, Put: PutKong, Post: PostKong } = axiosInstanceApiGateway

export const getLocations = (params?: GetLocationListRequestType) =>
  Get<GetLocationListResponseType>({
    url: `/api/location`,
    params,
  })

export const getLocationList = ({ params }: { params: GetLocationListRequestType }) =>
  Get<GetLocationListResponseType>({
    url: `/api/location`,
    params: { pageSize: 99999, ...params },
  })

export const getLocationDetail = (id: number) =>
  Get<GetLocationDetailResponseType>({
    url: `/api/location/${id}`,
  })

export const getSLAConfig = () =>
  GetKong<GetSLAConfigResponseType>({ url: `/location/internal/v1/sla-config` })

export const putSLAConfig = ({ payload }: PutSLAConfigRequestType) =>
  PutKong<PutSLAConfigResponseType>({ url: `/location/internal/v1/sla-config`, data: payload })

export const getLocationTypeList = () =>
  Get<GetLocationTypeResponseType>({ url: '/api/location/type' })

export const getLocationListByType = (locationType: string) =>
  Get<GetLocationListByTypeResponseType[]>({
    url: `/api/locations/${encodeURI(locationType)}`,
  })

export const getAllLocations = () => Get({ url: '/api/locations/all' })

export type GetKongLocationDetailRequestType = {
  id: string
}

export type GetKongLocationDetailResponseType = {
  detail: GetLocationDetailResponseType
}
export const getKongLocationDetail = ({ id }: GetKongLocationDetailRequestType) =>
  GetKong<GetKongLocationDetailResponseType>({ url: `location/internal/v1/locations/${id}/detail` })

export type GetKongLocationAllRequestType = {
  params: {
    name?: string
    limit: number
    page: number
  }
}

export type GetKongLocationAllResponseType = {
  location: Omit<LocationListType, 'uniqueId'>[]
}

export const getKongLocationAll = ({ params }: GetKongLocationAllRequestType) =>
  GetKong<GetKongLocationAllResponseType, false>({
    url: `location/internal/v1/locations`,
    params,
  })

type WhiteListType = {
  address: string
  type: number
}

export type LocationPayloadType = {
  location: {
    name: string
    address: string
    latitude: string
    longitude: string
    open_time: string
    close_time: string
    type: string
    active: boolean
    pic_3pl_id: number
    location_detail_id: number
    location_type_id: number
    location_flag_id: number
    area: [] // exclude, send empty array
    address_detail: 'dolor sed aliquip proident'
    spv: number[] // exclude, send empty array
    id: number
  }
  sla: number[]
  user_id: number
  schedule: {
    day: number
    open_time: string
    close_time: string
    is_24hr: boolean
    reroute: boolean
    close: boolean
    reason_id: number
    close_reason_title: string
    close_reason_content: string
    id: number
  }[]
  supervisor_id: number[]
  whitelist: WhiteListType[]
  blacklist: WhiteListType[]
  kitchen_schedule: {
    day: number
    open_time: string
    close_time: string
    is_24hr: boolean
    reroute: boolean
    close: boolean
    reason_id: number
    close_reason_title: string
    close_reason_content: string
    id: string
  }[]
  kitchen_sla: number[]
}

export type PutKongLocationRequestType = {
  id: string
  payload: LocationPayloadType
}

export const putKongLocation = ({ id, payload }: PutKongLocationRequestType) =>
  PutKong({ url: `location/internal/v1/location/${id}`, data: payload })

export type PostKongLocationRequestType = {
  payload: Pick<
    LocationPayloadType,
    | 'blacklist'
    | 'kitchen_schedule'
    | 'kitchen_sla'
    | 'schedule'
    | 'sla'
    | 'user_id'
    | 'supervisor_id'
    | 'whitelist'
  > & {
    location: {
      name: string
      address: string
      latitude: string
      longitude: string
      type: string
      active: boolean
      area: []
      spv: number[]
      open_time: string
      close_time: string
      pic_3pl_d: number
      location_detail_id: number
      location_type_id: number
      location_flag_id: number
      address_detail: string
    }
  }
}
export const postKongLocation = ({ payload }: PostKongLocationRequestType) =>
  PostKong({ url: `location/internal/v1/location`, data: payload })
