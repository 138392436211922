/* eslint-disable @typescript-eslint/naming-convention */
import { lazy } from 'react'
import { useParams } from 'react-router-dom'

const NewOrder = lazy(() => import('../pages/OngoingOrders/NewOrders'))
const BackToHub = lazy(() => import('../pages/OngoingOrders/BackToHubOrders'))
const UpcomingOrders = lazy(() => import('../pages/OngoingOrders/UpcomingOrders'))
const Picking = lazy(() => import('../pages/OngoingOrders/PickingOrders'))
const Packing = lazy(() => import('../pages/OngoingOrders/PackingOrders'))
const OnDelivery = lazy(() => import('../pages/OngoingOrders/OnDeliveryOrders'))
const ReadyForDelivery = lazy(() => import('../pages/OngoingOrders/ReadyForDelivery'))
const WaitingConfirmation = lazy(() => import('../pages/OngoingOrders/WaitingConfirmation'))

const DynamicRouteHandler = () => {
  const { status } = useParams<{ status: string }>()
  const componentMapping: {
    [key: string]:
      | React.LazyExoticComponent<() => JSX.Element>
      | React.LazyExoticComponent<React.MemoExoticComponent<() => JSX.Element>>
  } = {
    'back-to-hub': BackToHub,
    'new-order': NewOrder,
    'upcoming-order': UpcomingOrders,
    picking: Picking,
    packing: Packing,
    'on-delivery': OnDelivery,
    'ready-for-delivery': ReadyForDelivery,
    'waiting-confirmation': WaitingConfirmation,
  }
  const Component = componentMapping[status]
  if (!Component) return <NewOrder />
  return <Component />
}
export default DynamicRouteHandler
