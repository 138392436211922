import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  filterMain: "",
  filterCategory: ""
};

const product = createSlice({
  name: "product",
  initialState,
  reducers: {
    reset: () => initialState,

    setMainFilter: (state, action) => {
      state.filterMain = action.payload;
    },
    setSecondFilter: (state, action) => {
      state.filterCategory = action.payload;
    }
  }
});

export const { setMainFilter, setSecondFilter, getDataFilter } =
  product.actions;

export default product.reducer;
