import { lazy } from 'react'
import generateRouterDetail from 'utils/helpers/generateRouterDetail'

type NamedRoutesType = 'ABAdminLoginRouter' | 'ABAdminNonLoginRouter'

type PathListsType = '/dashboard/ab-admin' | '/dashboard/ab-admin-non-login'

type RouterInstanceType = RouterFeatureInterface<NamedRoutesType, PathListsType>

const ABAdminPage = lazy(() => import('pages/ABAdmin/GetList'))

export const ABAdminLoginRouter: RouterInstanceType = {
  name: 'ABAdminLoginRouter',
  Component: ABAdminPage,
  path: '/dashboard/ab-admin',
  routeType: 'PRIVATE',
  allowedRoles: [
    'AUTH_MERCHANDISING_ADMIN',
    'AUTH_HO_MERCHANDISING',
    'AUTH_PRODUCT_MANAGER',
    'AUTH_HO_PRODUCT_MANAGER',
    'AUTH_HO_CAMPAIGN',
    'AUTH_HO_PRICING_HEAD',
    'AUTH_HO_PRICING_MANAGER',
    'AUTH_HO_PRICING_SPV',
    'AUTH_HO_PRICING_STAFF',
    'AUTH_PRICING',
  ],
  isDashboard: true,
}

const ABAdminNonLoginRouter: RouterInstanceType = {
  name: 'ABAdminNonLoginRouter',
  Component: ABAdminPage,
  path: '/dashboard/ab-admin-non-login',
  routeType: 'PRIVATE',
  allowedRoles: [
    'AUTH_MERCHANDISING_ADMIN',
    'AUTH_HO_MERCHANDISING',
    'AUTH_PRODUCT_MANAGER',
    'AUTH_HO_PRODUCT_MANAGER',
    'AUTH_HO_CAMPAIGN',
    'AUTH_HO_PRICING_HEAD',
    'AUTH_HO_PRICING_MANAGER',
    'AUTH_HO_PRICING_SPV',
    'AUTH_HO_PRICING_STAFF',
    'AUTH_PRICING',
  ],
  isDashboard: true,
}

const ABAdminRouter: RouterInstanceType[] = [ABAdminLoginRouter, ABAdminNonLoginRouter]

export const ABAdminRouterDetail = generateRouterDetail(ABAdminRouter)

export default ABAdminRouter
