import {
  SET_IS_START_ALLORDER,
  SET_IS_FAILED_ALLORDER,
  SET_IS_SUCCESS_ALLORDER,
  SET_IS_NEED_TO_RELOAD_ALLORDER,
  SET_PAGINATION_DATA_ALLORDER,
  SET_DETAIL_DATA_ALLORDER,
  SET_STATUS_DETAIL_DATA_ALLORDER,
  SET_LIST_OF_HUB_ALLORDER,
  SET_LIST_OF_DRIVER_ORDER_POOLING,
  SET_IS_CHECKED_VERIFIY,
  SET_IS_DATA_EDIT_ADDRESS,
  SET_WAITING_CONFIRMATION_ORDER_ID_FOCUS,
  SET_ORDER_TYPE_LIST,
} from 'storeContext/actionsType'

export const initialStateAllorder = {
  isLoading: false,
  allOrderData: [],
  paginationData: {},
  needToReload: 1,
  detailData: {},
  statusDetailData: false,
  listOfHub: [],
  orderPoolingDriverData: [],
  isCheckedVerifiy: false,
  dataEditAddres: {},
  waitingConfirmationFocusOrderId: 0,
  orderTypeList: [],
}

export default function allorder(state = initialStateAllorder, { type, payload }) {
  switch (type) {
    case SET_IS_START_ALLORDER:
      return {
        ...state,
        isLoading: true,
      }
    case SET_IS_FAILED_ALLORDER:
      return {
        ...state,
        isLoading: false,
      }
    case SET_IS_SUCCESS_ALLORDER:
      return {
        ...state,
        isLoading: false,
        allOrderData: payload.content,
      }
    case SET_IS_NEED_TO_RELOAD_ALLORDER:
      return {
        ...state,
        needToReload: state.needToReload + 1,
      }
    case SET_PAGINATION_DATA_ALLORDER:
      return {
        ...state,
        paginationData: payload,
      }
    case SET_DETAIL_DATA_ALLORDER:
      return {
        ...state,
        detailData: payload,
      }
    case SET_STATUS_DETAIL_DATA_ALLORDER:
      return {
        ...state,
        statusDetailData: payload,
      }
    case SET_LIST_OF_HUB_ALLORDER:
      return {
        ...state,
        listOfHub: payload,
      }
    case SET_LIST_OF_DRIVER_ORDER_POOLING:
      return {
        ...state,
        orderPoolingDriverData: payload,
      }
    case SET_IS_CHECKED_VERIFIY:
      return {
        ...state,
        isCheckedVerifiy: true,
      }
    case SET_IS_DATA_EDIT_ADDRESS:
      return {
        ...state,
        dataEditAddres: payload,
      }
    case SET_WAITING_CONFIRMATION_ORDER_ID_FOCUS:
      return {
        ...state,
        waitingConfirmationFocusOrderId: payload,
      }
    case SET_ORDER_TYPE_LIST:
      return {
        ...state,
        orderTypeList: payload,
      }
    default:
      return {
        ...state,
      }
  }
}
