import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  FlexiComboQueryType,
  FlexiComboType,
  InitialStateType,
} from 'features/Promotion/@types/FlexiCombo'

export const SLICE_NAME = 'flexiCombo'

export const FLEXI_COMBO_QUERY_DEFAULT = {
  page_index: 0,
  page_size: 20,
  is_exclude_product: true,
}

const initialState: InitialStateType = {
  isLoading: false,
  flexiComboList: [],
  query: FLEXI_COMBO_QUERY_DEFAULT,
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setFlexiComboList: (state, { payload }: PayloadAction<FlexiComboType[]>) => {
      state.flexiComboList = payload
    },
    setFlexiComboQuery: (state, { payload }: PayloadAction<FlexiComboQueryType>) => {
      state.query = { ...payload }
    },
  },
})

export const { resetState, setIsLoading, setFlexiComboList, setFlexiComboQuery } = slice.actions
export default slice.reducer
