import { lazy } from 'react'

import { roles } from 'constant/roles'
import generateRouterDetail from 'utils/helpers/generateRouterDetail'

const MPStaff = lazy(() => import('../pages/MPStaff'))

export const whManager = [
  roles.AUTH_WH_MANAGER,
  roles.AUTH_WH_INBOUND_SPV,
  roles.AUTH_WH_OUTBOUND_SPV,
  roles.AUTH_WH_INV_SPV,
  roles.AUTH_HO_IT,
]
export const hubManager = [roles.AUTH_HUB_SPV, roles.AUTH_SPV_HUB, roles.AUTH_HO_IT]
export const hrLead = [
  roles.AUTH_PRODUCT_MANAGER,
  roles.AUTH_HO_HR,
  roles.AUTH_HR_LEAD,
  roles.AUTH_HO_IT,
]
export const rejoinAction = [roles.AUTH_HO_HR, roles.AUTH_HR_LEAD, roles.AUTH_HO_IT]
export const captainAndDriver = [
  roles.AUTH_CAPTAIN,
  roles.AUTH_CS_CAPTAIN,
  roles.AUTH_DRIVER_COORDINATOR,
  roles.AUTH_PRODUCT_MANAGER,
  roles.AUTH_QUALITY_ENGINEERING,
  roles.AUTH_HO_IT,
]

export const MPStaffRouter: RouterFeatureInterface<'MPStaffRouter', '/dashboard/mp-staff'> = {
  name: 'MPStaffRouter',
  Component: MPStaff,
  path: '/dashboard/mp-staff',
  routeType: 'PRIVATE',
  allowedRoles: [
    roles.AUTH_PACKER,
    roles.AUTH_CUSTOMER_SERVICE,
    roles.AUTH_HO_HR,
    roles.AUTH_HR_LEAD,
    ...captainAndDriver,
    ...whManager,
    ...hubManager,
  ],
  isDashboard: true,
}

const HRISAccountRouter = [MPStaffRouter]

export const HRISAccountRouterDetail = generateRouterDetail(HRISAccountRouter)

export default HRISAccountRouter
