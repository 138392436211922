import {
  AccordionSummary as MuiAccordionSummary,
  AccordionSummaryProps as MuiAccordionSummaryProps,
} from '@mui/material'
import styled from 'styled-components'

export type AccordionSummaryPropsType = MuiAccordionSummaryProps

const AccordionSummary = styled(MuiAccordionSummary)<AccordionSummaryPropsType>``

export default AccordionSummary
