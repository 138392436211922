import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  AddEditFlashSaleStateType,
  SetFormsPayloadActionType,
  SetFormDetailPayloadActionType,
  SetImageRulesPayloadActionType,
} from 'features/Promotion/@types/addEditFlashSale'
import { dateFormat } from 'utils/helpers/date'
import {
  SLICE_NAME,
  fetchCampaignList,
  createFlashSale,
  fetchFlashSaleDetail,
  updateFlashSale,
} from './thunk'

const DATE_FORMAT = 'YYYY-MM-DD'
const HOUR_FORMAT = 'HH:mm:ss'

const initalDetails: AddEditFlashSaleStateType['forms']['details'][0] = {
  campaign_ids: [],
  date: '',
  end_time: '',
  start_time: '',
  uniqueKey: window.crypto.randomUUID(),
}

const initialState: AddEditFlashSaleStateType = {
  isLoading: false,
  forms: {
    details: [],
    name: '',
    end_date: '',
    end_time: '',
    start_date: '',
    start_time: '',
    image: { file: null, imageUrl: '', imagePreview: '' },
  },
  imageRules: {
    extensions: [],
    height: { max: 0, min: 0 },
    maxFileSize: 0,
    typeId: 'banner-images',
    width: { max: 0, min: 0 },
  },
  campaign: { list: [] },
}

const addEditFlashSale = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    addDeleteFormDetail: (
      state,
      action: PayloadAction<{ type: 'ADD' | 'DELETE'; uniqueKey: string }>,
    ) => {
      const { type, uniqueKey } = action.payload

      if (type === 'ADD') {
        state.forms.details.push({
          ...initalDetails,
          uniqueKey: window.crypto.randomUUID(),
        })
      }
      if (type === 'DELETE') {
        state.forms.details = state.forms.details.filter((el) => el.uniqueKey !== uniqueKey)
      }
    },
    setFormsDetail: (state, action: PayloadAction<SetFormDetailPayloadActionType>) => {
      const { name, value, index } = action.payload
      state.forms.details[index][name] = value as never
    },
    setForms: (state, action: PayloadAction<SetFormsPayloadActionType>) => {
      const { name, value } = action.payload
      state.forms[name] = value as never
    },
    setImageRules: (state, action: PayloadAction<SetImageRulesPayloadActionType>) => {
      state.imageRules = action.payload.value
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCampaignList.fulfilled, (state, action) => {
        state.campaign.list = action.payload.content.filter(
          (el) => el.maxPercentage > 0 && el.minPercentage > 0,
        )
      })
      .addCase(createFlashSale.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createFlashSale.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(createFlashSale.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(updateFlashSale.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateFlashSale.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateFlashSale.fulfilled, (state) => {
        state.isLoading = false
      })

      .addCase(fetchFlashSaleDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFlashSaleDetail.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchFlashSaleDetail.fulfilled, (state, action) => {
        const { data } = action.payload
        state.isLoading = false
        state.forms = {
          name: data.name,
          image: { file: null, imageUrl: data.background_url, imagePreview: data.background_url },
          end_date: dateFormat({ date: new Date(data.end_period), format: DATE_FORMAT, utc: true }),
          end_time: dateFormat({ date: new Date(data.end_period), format: HOUR_FORMAT, utc: true }),
          start_time: dateFormat({
            date: new Date(data.start_period),
            format: HOUR_FORMAT,
            utc: true,
          }),
          start_date: dateFormat({
            date: new Date(data.start_period),
            format: DATE_FORMAT,
            utc: true,
          }),
          details: data.campaign_list.map((el) => ({
            date: dateFormat({ date: new Date(el.fs_date), format: DATE_FORMAT }),
            end_time: dateFormat({ date: new Date(el.end_time), format: HOUR_FORMAT }),
            start_time: dateFormat({
              date: new Date(el.start_time),
              format: HOUR_FORMAT,
            }),
            uniqueKey: window.crypto.randomUUID(),
            campaign_ids: el.campaign_data.map((ell) => ({
              name: ell.campaign_name,
              id: ell.campaign_id,
            })),
          })),
        }
      })
  },
})

export const { reset, addDeleteFormDetail, setForms, setFormsDetail, setImageRules, setIsLoading } =
  addEditFlashSale.actions
export default addEditFlashSale.reducer
