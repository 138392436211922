import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  postLogin,
  type PostLoginRequestType,
  getVerifiedPhoneNumber,
  postGenerateUserOTP,
  postValidateOtpService,
  postValidatePinService,
  putSubmitPinService,
  postGenerateTotpService,
  postValidateTotpService,
  getFlagHideEmail,
} from 'features/Auth/services/login'
import { setTokenCookies } from 'config/cookies'
import { callErrorMsg } from 'helpers/errorMsg'
import { toastSuccess, toastFailed } from 'utils/toast'

import {
  SLICE_NAME,
  setLoading,
  setValidatedPhoneNumber,
  setTabPhoneNumber,
  setTab,
  setTempToken,
  setTOTP,
  setFlagEmailLogin,
} from './slice'

export const onPostLoginByEmail = createAsyncThunk<void, PostLoginRequestType>(
  `${SLICE_NAME}/onPostLogin`,
  async (payload, { getState, dispatch }) => {
    const {
      login: { isLoading },
    } = getState() as StoreStateType
    if (isLoading) return
    try {
      dispatch(setLoading(true))
      const { data } = await postLogin(payload)
      setTokenCookies(data.token)
      window.location.href = '/dashboard'
    } catch (error) {
      callErrorMsg(error)
      dispatch(setLoading(false))
    }
  },
)

export const onSubmitPhoneNumber = createAsyncThunk(
  `${SLICE_NAME}/loginByPhone`,
  async (phone: string, { getState, dispatch }) => {
    const {
      login: { isLoading },
    } = getState() as StoreStateType
    if (isLoading) return
    try {
      dispatch(setLoading(true))
      const {
        data: { data: response },
      } = await getVerifiedPhoneNumber(phone)

      dispatch(setValidatedPhoneNumber(phone))

      const isLogin = [response.is_verified, response.is_pin_exist, response.is_totp_exist].every(
        (item) => item === true,
      )
      if (!isLogin) {
        await postGenerateUserOTP({ phone_number: phone })
        dispatch(setTabPhoneNumber('otp'))
        toastSuccess('OTP telah dikirim ke nomor HP anda')
        return
      }

      const needReset =
        !response.is_pin_exist &&
        [response.is_verified, response.is_totp_exist].every((item) => item === true)

      if (needReset) {
        dispatch(setTabPhoneNumber('wrongPin'))
        return
      }

      dispatch(setTabPhoneNumber('loginPin'))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const onValidateOTP = createAsyncThunk(
  `${SLICE_NAME}/onValidateOTP`,
  async (payload: { otp: string }, { dispatch, getState }) => {
    const {
      login: { tabByPhoneNumber, validatedPhoneNumber },
    } = getState() as StoreStateType

    if (!validatedPhoneNumber) {
      toastFailed('Nomor HP tidak ditemukan')
      return
    }
    try {
      dispatch(setLoading(true))
      const {
        data: {
          data: { token },
        },
      } = await postValidateOtpService({
        otp: payload.otp,
        phone_number: validatedPhoneNumber,
      })
      dispatch(setTempToken(token))
      if (tabByPhoneNumber === 'forgotOtp') {
        dispatch(setTabPhoneNumber('resetPin'))
      } else {
        dispatch(setTabPhoneNumber('registerPin'))
      }
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const resendOtp = createAsyncThunk(
  `${SLICE_NAME}/resendOtp`,
  async ({ onSuccess }: { onSuccess: () => void }, { getState, dispatch }) => {
    const {
      login: { validatedPhoneNumber },
    } = getState() as StoreStateType
    try {
      dispatch(setLoading(true))
      await postGenerateUserOTP({ phone_number: validatedPhoneNumber })
      onSuccess()
      toastSuccess('OTP telah dikirim ke nomor HP anda')
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const validatePin = createAsyncThunk(
  `${SLICE_NAME}/validatePin`,
  async ({ pin }: { pin: string }, { dispatch, getState }) => {
    const {
      login: { validatedPhoneNumber },
    } = getState() as StoreStateType

    try {
      dispatch(setLoading(true))

      const { data: res } = await postValidatePinService({
        phone_number: validatedPhoneNumber,
        pin,
      })

      if (!res.data.is_valid) {
        if (res.data.need_reset) {
          dispatch(setTabPhoneNumber('wrongPin'))
          return
        }

        toastFailed('Kode pin tidak sesuai. Coba cek lagi, ya')
        return
      }

      dispatch(setTabPhoneNumber('loginAuthenticator'))
      dispatch(setTempToken(res.data.token))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const registerPin = createAsyncThunk(
  `${SLICE_NAME}/registerPin`,
  async (payload: { pin: string; confirmPin: string }, { dispatch, getState }) => {
    const { pin, confirmPin } = payload
    const {
      login: { tempToken },
    } = getState() as StoreStateType
    try {
      dispatch(setLoading(true))
      const {
        data: {
          data: { message },
        },
      } = await putSubmitPinService({ pin, confirm_pin: confirmPin }, String(tempToken))
      // generate totp for authenticator
      const {
        data: { data: totp },
      } = await postGenerateTotpService(String(tempToken))
      dispatch(setTOTP(totp))
      dispatch(setTabPhoneNumber('authenticator'))
      toastSuccess(message)
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const resetPin = createAsyncThunk(
  `${SLICE_NAME}/resetPin`,
  async (payload: { pin: string; confirmPin: string }, { dispatch, getState }) => {
    const { pin, confirmPin } = payload
    const {
      login: { tempToken },
    } = getState() as StoreStateType
    try {
      const {
        data: {
          data: { message },
        },
      } = await putSubmitPinService({ pin, confirm_pin: confirmPin }, String(tempToken))
      dispatch(setTabPhoneNumber('phone'))
      toastSuccess(message)
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const handleValidateTOTP = createAsyncThunk(
  `${SLICE_NAME}/handleValidateTOTP`,
  async (totp: string, { dispatch, getState }) => {
    const {
      login: { tempToken, tabByPhoneNumber },
    } = getState() as StoreStateType
    const isSignUp = tabByPhoneNumber === 'verifyCode'
    try {
      dispatch(setLoading(true))
      const { data: res } = await postValidateTotpService(
        { totp, is_sign_up: isSignUp },
        String(tempToken),
      )
      if (!res.data.is_valid) {
        toastFailed('Kode OTP tidak sesuai. Coba cek lagi, ya')
        return
      }

      if (!isSignUp && res.data.token) {
        setTokenCookies(res.data.token as string)
        window.location.href = '/dashboard'
        return
      }

      dispatch(setTabPhoneNumber('phone'))
      dispatch(setTOTP(null))
      toastSuccess('Verifikasi kode authenticator berhasil, silahkan login kembali')
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const fetchFlagHideEmailLogin = createAsyncThunk(
  `${SLICE_NAME}/fetchFlagHideEmailLogin`,
  async (_, { dispatch }) => {
    try {
      const {
        data: {
          data: { active: flag },
        },
      } = await getFlagHideEmail()
      if (flag) {
        dispatch(setTab('byPhoneNumber'))
        dispatch(setFlagEmailLogin('disabled'))
      } else {
        dispatch(setFlagEmailLogin('allow'))
      }
    } catch (error) {
      callErrorMsg(error)
    }
  },
)
