import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  InitialStateType,
  SetGeneralFormPayloadType,
  MilestoneGeneralFormType,
  MilestoneUserSegmentType,
  MilestoneSectionFormType,
  SetMilestoneFormPayloadType,
  VoucherType,
  SetTncFormPayloadType,
  TncSectionFormType,
  MilestoneType,
} from 'features/Promotion/@types/MilestoneAddEdit'

export const MILESTONE_ADD_EDIT_SLICE_NAME = 'milestoneAddEdit'

export const initialMilestoneForm: MilestoneSectionFormType = {
  minimum_purchase: '',
  transaction_count: 0,
  reward_position: 0,
  reward_type: 'VOUCHER',
  reward: null,
}

export const initialState: InitialStateType = {
  isLoading: false,
  general: {
    form: {
      milestone_name: '',
      milestone_title: '',
      milestoneType: null,
      segment: null,
      title_bg_color: '',
      backgroundFile: null,
      background_url: '',
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
    },
    segmentOptions: [],
    backgroundImageRule: null,
  },
  milestone: {
    list: [],
    voucherOptions: [],
    milestoneIndexToDelete: null,
  },
  tnc: {
    form: {
      tnc_title: '',
      tnc_summary: '',
      tnc_detail: '',
    },
  },
  milestoneDetail: null,
}

const slice = createSlice({
  name: MILESTONE_ADD_EDIT_SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setGeneralForm: (state, { payload }: PayloadAction<SetGeneralFormPayloadType>) => {
      const { field, value } = payload
      state.general.form[field] = value as never
    },
    resetGeneralForm: (state, { payload }: PayloadAction<MilestoneGeneralFormType | undefined>) => {
      state.general.form = { ...(payload || initialState.general.form) }
    },
    setUserSegmentOptions: (state, { payload }: PayloadAction<MilestoneUserSegmentType[]>) => {
      state.general.segmentOptions = payload
    },
    setMilestoneList: (state, { payload }: PayloadAction<MilestoneSectionFormType[]>) => {
      state.milestone.list = [...payload]
    },
    setMilestoneForm: (state, { payload }: PayloadAction<SetMilestoneFormPayloadType>) => {
      const { field, value, index } = payload
      state.milestone.list[index][field] = value as never
    },
    setVoucherOptions: (state, { payload }: PayloadAction<VoucherType[]>) => {
      state.milestone.voucherOptions = payload
    },
    setTncForm: (state, { payload }: PayloadAction<SetTncFormPayloadType>) => {
      const { field, value } = payload
      state.tnc.form[field] = value as never
    },
    resetTncForm: (state, { payload }: PayloadAction<TncSectionFormType | undefined>) => {
      state.tnc.form = { ...(payload || initialState.tnc.form) }
    },
    setBackgroundImageRule: (
      state,
      { payload }: PayloadAction<InitialStateType['general']['backgroundImageRule']>,
    ) => {
      state.general.backgroundImageRule = payload
    },
    setMilestoneIndexToDelete: (state, { payload }: PayloadAction<Nullable<number>>) => {
      state.milestone.milestoneIndexToDelete = payload
    },
    deleteMilestoneByIndex: (state, { payload }: PayloadAction<number>) => {
      state.milestone.list = state.milestone.list.filter((_, i) => i !== payload)
    },
    addMilestone: (state) => {
      const lastMilestone = state.milestone.list[state.milestone.list.length - 1]

      state.milestone.list = [
        ...state.milestone.list,
        {
          ...initialMilestoneForm,
          transaction_count: +lastMilestone.transaction_count + 1,
        },
      ]
    },
    setMilestoneDetail: (state, { payload }: PayloadAction<MilestoneType>) => {
      state.milestoneDetail = { ...payload }
    },
  },
})

export const {
  reset,
  setIsLoading,
  setGeneralForm,
  resetGeneralForm,
  setUserSegmentOptions,
  setMilestoneList,
  setMilestoneForm,
  setVoucherOptions,
  setTncForm,
  resetTncForm,
  setBackgroundImageRule,
  setMilestoneIndexToDelete,
  deleteMilestoneByIndex,
  addMilestone,
  setMilestoneDetail,
} = slice.actions
export default slice.reducer
