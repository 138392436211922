import axiosInstanceApiGateway from 'config/apiServiceApiGateway'
import type {
  PostFlexiComboStatusRequestType,
  PostFlexiComboStatusResponseType,
} from 'features/Promotion/@types/FlexiCombo'
import { FlexiComboQueryType, FlexiComboType } from '../@types/FlexiCombo'

type GetFlexiComboResponseType = {
  data: {
    get_flexi_campaigns: FlexiComboType[]
    pagination: FlexiComboQueryType
  }
}

export const getFlexiComboList = (params: Partial<FlexiComboQueryType>) =>
  axiosInstanceApiGateway.Get<GetFlexiComboResponseType, true>({
    url: '/campaign/internal/v1/flexi-campaign',
    params,
  })

export const postFlexiComboStatus = ({ payload }: PostFlexiComboStatusRequestType) =>
  axiosInstanceApiGateway.Post<PostFlexiComboStatusResponseType, true>({
    url: '/campaign/internal/v1/flexi-campaign',
    data: payload,
  })
