import { memo } from "react";
import styled from "styled-components";
import { TextBody } from "components";

function Row({ dIndex, data, isSuratJalan, selectedData }) {
  return (
    <Tr
      borderLineLeft
      borderLineRight
      borderLineBottom
    >
      <Td width="4%">
        <TDWrapper>
          <TextBody size="toast">{dIndex + 1}</TextBody>
        </TDWrapper>
      </Td>
      <Td width="16%">
        <TDWrapper>
          <TextBody size="toast">{data?.product_sku}</TextBody>
        </TDWrapper>
      </Td>
      <Td width="23%">
        <TDWrapper>{data?.product_name}</TDWrapper>
      </Td>
      <Td width="12%">
        <TDWrapper>
          <CenterText>{data?.product_request_quantity}</CenterText>
        </TDWrapper>
      </Td>
      <Td width="10%">
        <TDWrapper>
          <CenterText>{data?.product_rack}</CenterText>
        </TDWrapper>
      </Td>
      <Td width="10%">
        <TDWrapper>
          <CenterText>{data?.product_incoming_quantity}</CenterText>
        </TDWrapper>
      </Td>
      <Td width="10%">
        <TDWrapper>
          <CenterText>
            {data?.product_request_quantity === data?.product_incoming_quantity
              ? "OK"
              : "Qty Kurang"}
          </CenterText>
        </TDWrapper>
      </Td>
      <Td width="15%" lastBR={true}>
        <TDWrapper>{isSuratJalan ? data?.product_note : ""}</TDWrapper>
      </Td>
    </Tr>
  );
}

export default memo(Row);

const Tr = styled.tr`
  display: flex;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: stretch;
  ${({ borderLineTop }) => borderLineTop && `border-top: 1px solid #bfc9d6;`}
  ${({ borderLineLeft }) => borderLineLeft && `border-left: 1px solid #bfc9d6;`}
  ${({ borderLineRight }) =>
    borderLineRight && `border-right: 1px solid #bfc9d6;`}

    ${({ borderLineBottom }) =>
    borderLineBottom && `border-bottom: 1px solid #bfc9d6;`}
`;
const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  //   flex: ${({ ratio }) => ratio};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  //flex: ${({ ratio }) => ratio};
  width: ${({ width }) => (width ? width : "10%")};
  text-align: start;
  ${({ lastBR }) => !lastBR && `border-right: 1px solid #bfc9d6;`}
`;

const TDWrapper = styled.div`
  padding: 12px 8px;
`;
const CenterText = styled.p`
  text-align: center;
`;
