import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getPurchaseRequestDetailVendor,
  getPurchaseRequestDetail
} from "utils/apiList/purchaseRequest";
import {
  populateChosenProductIdList,
  populateChosenVendorIdList,
  isHeaderChecked,
  getVendorList,
  getCountOfPurchaseRequestAndConfirmationList
} from "utils/reorderItemUtil";
import { callErrorMsg } from "helpers/errorMsg";

const SLICE_NAME = "purchaseRequestEdit";

export const fetchPurchaseRequestDetailVendor = createAsyncThunk(
  `${SLICE_NAME}/fetchPurchaseRequestDetailVendor`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await getPurchaseRequestDetailVendor();
      return response.data;
    } catch (err) {
      callErrorMsg(err);
      return rejectWithValue(err);
    }
  }
);

export const fetchPurchaseRequestDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchPurchaseRequestDetail`,
  async (id, { rejectWithValue }) => {
    try {
      const response = await getPurchaseRequestDetail(id);
      return response.data.data;
    } catch (err) {
      callErrorMsg(err);
      return rejectWithValue(err);
    }
  }
);

const initialState = {
  isLoading: true,
  isSubmitting: false,
  purchaseRequestDetail: {},
  tickedProductIdList: [],
  tickedVendorIdList: [],
  isOpenDeleteConfirmationModal: false,
  isOpenModalConfirmation: false,
  headerCheckbox: [
    {
      isChecked: false
    },
    {
      isChecked: false
    },
    {
      isChecked: false
    }
  ],
  originalPurchaseRequest: [],
  updatedPurchaseRequest: [],
  countPurchaseRequest: 0,
  confirmationListData: [],
  triggerApiCallCancelPurchaseRequest: false,
  triggerApiCallCreatePurchaseRequest: false,
  triggerApiCallUpdatePurchaseRequest: false
};

export const purchaseRequestEdit = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetData: () => initialState,
    setQtyInVendorItem: (state, action) => {
      const { indexDetail, indexVendor, value } = action.payload;
      state.purchaseRequestDetail.details[indexDetail].vendorList[
        indexVendor
      ].qty = value;
    },
    toggleConfirmationModal: (state, action) => {
      state.isOpenModalConfirmation = action.payload;
    },
    toggleDeleteConfirmationModal: (state, action) => {
      state.isOpenDeleteConfirmationModal = action.payload;
    },
    toggleHeaderCheckboxVendor: (state, action) => {
      const indexToToggle = action.payload;
      const currentCheckState = state.headerCheckbox[indexToToggle].isChecked;
      state.headerCheckbox[indexToToggle].isChecked = !currentCheckState;

      state.purchaseRequestDetail.details.forEach((detailData) => {
        detailData.vendorList.forEach((vendorItem, index) => {
          if (index === indexToToggle) {
            vendorItem.isChecked = !currentCheckState;
          }
        });
      });
    },
    toggleCheckboxItemPurchaseRequest: (state, action) => {
      const { indexDetail, indexVendor } = action.payload;
      state.purchaseRequestDetail.details[indexDetail].vendorList[
        indexVendor
      ].isChecked =
        !state.purchaseRequestDetail.details[indexDetail].vendorList[
          indexVendor
        ].isChecked;

      state.headerCheckbox[indexVendor].isChecked = isHeaderChecked(
        state.purchaseRequestDetail.details,
        indexVendor
      );
    },
    calculateSkuCount: (state) => {
      const listProductId = [];
      state.purchaseRequestDetail.details.forEach((detaillData) => {
        populateChosenProductIdList(
          detaillData.vendorList,
          detaillData.product.productId,
          listProductId
        );
      });
      state.tickedProductIdList = listProductId;
    },
    calculateVendorCount: (state) => {
      const listVendorId = [];
      state.purchaseRequestDetail.details.forEach((detaillData) => {
        populateChosenVendorIdList(detaillData.vendorList, listVendorId);
      });
      state.tickedVendorIdList = listVendorId;
    },
    populateConfirmationListData: (state) => {
      const locationId = state.purchaseRequestDetail.location.locationId;
      const { confirmationListData, count } =
        getCountOfPurchaseRequestAndConfirmationList(
          state.purchaseRequestDetail.details,
          locationId,
          (itemData, itemVendor) => ({
            location: state.purchaseRequestDetail.location,
            product: itemData.product,
            vendor: itemVendor,
            pendingPrQty: itemData.pendingPoQty,
            pendingPoQty: itemData.pendingPrQty,
            stock: itemData.stock,
            detailId: itemData.detailId,
            reorderListId: itemData.reorderListId,
            locationId,
            productId: itemData.product.productId,
            vendorId: itemVendor.vendorId,
            qty: itemVendor.qty
          })
        );
      state.countPurchaseRequest = count;
      state.confirmationListData = confirmationListData;
    },
    populateListToUpdateAndListToCreate: (state) => {
      const listToUpdate = [];
      const listToCreate = [];

      state.originalPurchaseRequest.forEach((originalData) => {
        state.confirmationListData.forEach((confirmedData) => {
          const isSameProduct =
            originalData.productId === confirmedData.productId;
          const isSameVendor = originalData.vendorId === confirmedData.vendorId;
          const { reorderListId, locationId, productId, vendorId, qty } =
            confirmedData;

          if (isSameProduct && isSameVendor) {
            listToUpdate.push({
              reorderListId,
              locationId,
              productId,
              vendorId,
              qty
            });
          } else if (isSameProduct && !isSameVendor) {
            listToCreate.push({
              reorderListId,
              locationId,
              productId,
              vendorId,
              qty
            });
          }
        });
      });

      if (!listToUpdate.length && listToCreate.length) {
        state.triggerApiCallCancelPurchaseRequest = true;
        state.triggerApiCallCreatePurchaseRequest = true;
      }
      if (listToUpdate.length && !listToCreate.length) {
        state.triggerApiCallUpdatePurchaseRequest = true;
      }
      if (listToUpdate.length && listToCreate.length) {
        state.triggerApiCallUpdatePurchaseRequest = true;
        state.triggerApiCallCreatePurchaseRequest = true;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPurchaseRequestDetail.fulfilled, (state, action) => {
        const responsePrDetail = action.payload;
        responsePrDetail.details.forEach((itemDetail) => {
          state.originalPurchaseRequest.push({
            detailId: itemDetail.detailId,
            locationId: responsePrDetail.location.locationId,
            productId: itemDetail.product.productId,
            vendorId: responsePrDetail.vendor.vendorId,
            qty: itemDetail.qty
          });
        });
      })
      .addCase(fetchPurchaseRequestDetailVendor.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPurchaseRequestDetailVendor.fulfilled, (state, action) => {
        const listVendorId = [];
        const listProductId = [];

        state.isLoading = false;

        state.purchaseRequestDetail = action.payload;
        state.purchaseRequestDetail.details =
          state.purchaseRequestDetail.details.map((itemDetail) => ({
            ...itemDetail,
            vendorList: getVendorList(itemDetail, listVendorId, listProductId)
          }));

        state.tickedProductIdList = listProductId;
        state.tickedVendorIdList = listVendorId;

        state.headerCheckbox[0].isChecked = isHeaderChecked(
          state.purchaseRequestDetail.details,
          0
        );
        state.headerCheckbox[1].isChecked = isHeaderChecked(
          state.purchaseRequestDetail.details,
          1
        );
        state.headerCheckbox[2].isChecked = isHeaderChecked(
          state.purchaseRequestDetail.details,
          2
        );
      })
      .addCase(fetchPurchaseRequestDetailVendor.rejected, (state) => {
        state.isLoading = false;
      });
  }
});

export const {
  resetData,
  setQtyInVendorItem,
  toggleConfirmationModal,
  toggleDeleteConfirmationModal,
  toggleHeaderCheckboxVendor,
  toggleCheckboxItemPurchaseRequest,
  calculateSkuCount,
  calculateVendorCount,
  populateConfirmationListData,
  populateListToUpdateAndListToCreate
} = purchaseRequestEdit.actions;
export default purchaseRequestEdit.reducer;
