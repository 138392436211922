import { Get } from 'config/apiServiceConfig'

type GetTanggungRentengFlagParamsType = {
  featureKey: string
}

export type GetTanggungRentengFlagResponseType = {
  data: {
    features: {
      [key: string]: boolean
    }
  }
}

export const getTanggungRentengFlag = (
  locationId: number,
  deviceId: number,
  params: GetTanggungRentengFlagParamsType,
) =>
  Get<GetTanggungRentengFlagResponseType>({
    url: `/api/feature-flag/${locationId}/${deviceId}`,
    params,
  })
