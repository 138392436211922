import { numberOnly, removeZeroOnFirst, toBase64 } from 'helpers'
import { memo, useState } from 'react'
import { VoucherDetailRender } from './index'

//INITAL STATE

function VoucherDetail({ forms, setForms, handleChangeIcon, handleUploadCsv }) {
  //STATE HOOKS
  const [formUserID, setFormUserID] = useState([])

  //FUNCTION HANDLER
  const handleChangeForm = (e) => {
    const { value, name } = e.target
    let cusValue = value
    if (
      [
        'max_free_shipping',
        'minimum_purchase',
        'quota_user',
        'voucher_discount_total',
        'voucher_discount_max',
        'coin_amount',
        'quota_all',
        'quota_redemption',
      ].includes(name)
    ) {
      cusValue = removeZeroOnFirst(numberOnly(value))
    }
    setForms((prev) => ({
      ...prev,
      [name]: cusValue,
    }))
  }
  const handleSelectOption = (option, type = false) => {
    if (type === 'benefit_type') {
      //console.log("iki benefit");
      setForms((prev) => ({
        ...prev,
        benefit_type: option,
        max_free_shipping: '',
        voucher_discount_total: '',
        voucher_discount_max: '',
      }))
    } else if (type === 'targeted_user') {
      const newFormData = { ...forms }
      if (option.name !== 'Partnership Payment') {
        newFormData.is_quota_unlimited = true
      }
      newFormData.targeting_type = option
      setForms(newFormData)
    } else if (type === 'mechanism_type') {
      setForms((prev) => ({
        ...prev,
        mechanism_type: option,
      }))
    } else if (type === 'discount_type') {
      setForms((prev) => ({
        ...prev,
        discount_type: option,
      }))
    } else if (type === 'payment_channel') {
      setForms((prev) => ({
        ...prev,
        payment_channel_id: option.value,
      }))
    }
  }

  const handleToggleQuotaUnlimited = () => {
    setForms((prevFormState) => ({
      ...prevFormState,
      is_quota_unlimited: !prevFormState.is_quota_unlimited,
      quota_all: 0,
    }))
  }

  const handleToggleQuotaRedemption = () => {
    setForms((prevFormState) => ({
      ...prevFormState,
      is_unlimited_redemption: !prevFormState.is_unlimited_redemption,
      quota_redemption: 0,
    }))
  }
  const handleChangeFile = (e) => {
    const file = e.target.files[0]
    toBase64(file).then((base64) => {
      setForms((prev) => ({
        ...prev,
        voucher_icon_file: {
          ...prev.voucher_icon_file,
          status: true,
          value: {
            ...prev.voucher_icon_file.value,
            file: base64,
            fileName: file.name,
            fileSize: file.size,
            fileType: file.type,
          },
        },
      }))
    })
  }

  //LIST OF FUNCTION HANDLER
  const handlerList = {
    handleChangeForm: handleChangeForm,
    handleSelectOption: handleSelectOption,
    handleChangeFile: handleChangeFile,
  }

  const viewHandlers = {
    forms,
    formUserID,
    setFormUserID,
    handlerList,
    handleChangeIcon,
    handleUploadCsv,
    handleToggleQuotaUnlimited,
    handleToggleQuotaRedemption,
  }
  return <VoucherDetailRender {...viewHandlers} />
}

export default memo(VoucherDetail)
