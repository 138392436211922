import { createAsyncThunk } from '@reduxjs/toolkit'

import { history } from 'store'
import { callErrorMsg } from 'helpers/errorMsg'
import { toastSuccess } from 'utils/toast'
import { push } from 'utils/helpers/router'
import { vendor } from 'config/routes'
import {
  getVendorDetails,
  createNewVendor,
  putVendor,
  getCompanies,
  getFullImageUrl,
  uploadImage,
  postVendorRequest,
  putVendorRequest,
  getVendorDetailRequest,
  postReview,
  getOptions,
  getUoms,
  getPicList,
  type VendorDetailsInterface,
  type GetFullImageUrlParamsType,
  type VendorDetailRequestInterface,
} from 'features/Enterprise/Vendor/services/addEditVendor'

import {
  SLICE_NAME,
  setVendorCompany,
  setLoading,
  setVendorDetailRequest,
  setVendorOptions,
  setVendorUoms,
  setAstroPicList,
  // setVendorDetails
} from './addEditVendorSlice'

export const fetchVendorDetails = createAsyncThunk(
  `${SLICE_NAME}/fetchVendorDetails`,
  async (
    {
      vendor_id,
      handlePrefilledForm,
    }: { vendor_id: string; handlePrefilledForm?: (data: VendorDetailsInterface) => void },
    { dispatch },
  ) => {
    try {
      dispatch(setLoading(true))
      const { data } = await getVendorDetails(vendor_id)
      dispatch(
        setVendorDetailRequest({
          request_data: {
            ...data.data,
            vendor_id: Number(vendor_id),
          },
        } as unknown as VendorDetailRequestInterface),
      )
      if (handlePrefilledForm)
        handlePrefilledForm({
          ...data.data,
          vendor_id: Number(vendor_id),
        })
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const postNewVendor = createAsyncThunk(
  `${SLICE_NAME}/postNewVendor`,
  async (payload: VendorDetailsInterface, { rejectWithValue }) => {
    try {
      const { data } = await createNewVendor(payload)
      history.push(vendor)
      toastSuccess('Vendor baru berhasil ditambahkan')
      return data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const updateVendor = createAsyncThunk(
  `${SLICE_NAME}/updateVendor`,
  async (payload: VendorDetailsInterface) => {
    try {
      await putVendor(payload)
      history.goBack()
      toastSuccess(`Informasi ${payload.company_name} berhasil diperbaharui`)
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchCompanies = createAsyncThunk(
  `${SLICE_NAME}/fetchCompanies`,
  async (_, { dispatch }) => {
    try {
      const { data } = await getCompanies()
      dispatch(setVendorCompany(data.data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchFullImageUrl = createAsyncThunk(
  `${SLICE_NAME}/fetchFullImageUrl`,
  async (urlParams: GetFullImageUrlParamsType, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const { data } = await getFullImageUrl(urlParams)
      return data
    } catch (error) {
      callErrorMsg(error)
      return error
    } finally {
      dispatch(setLoading(false))
    }
  },
)
export const postImageDocument = createAsyncThunk(
  `${SLICE_NAME}/postImageDocument`,
  async (file: FormData, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const { data } = await uploadImage(file)
      return data
    } catch (error) {
      callErrorMsg(error)
      return error
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const submitDraftVendorRequest = createAsyncThunk(
  `${SLICE_NAME}/submitRequest`,
  async (payload: VendorDetailsInterface, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      await postVendorRequest(payload)
      toastSuccess('Request vendor berhasil dibuat')
      dispatch(
        push({
          url: vendor,
          queryObject: { section: 'approvals' },
        }),
      )
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const submitUpdateVendorRequest = createAsyncThunk(
  `${SLICE_NAME}/submitRequest`,
  async (payload: VendorDetailsInterface, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      await putVendorRequest(payload)
      toastSuccess('Request vendor berhasil di update')
      dispatch(
        push({
          url: vendor,
          queryObject: { section: 'approvals' },
        }),
      )
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const fetchVendorDetailRequest = createAsyncThunk(
  `${SLICE_NAME}/fetchVendorDetailRequest`,
  async (
    {
      vendorId,
      handlePrefilledForm,
    }: { vendorId: string; handlePrefilledForm?: (data: VendorDetailsInterface) => void },
    { dispatch },
  ) => {
    try {
      dispatch(setLoading(true))
      const { data } = await getVendorDetailRequest(vendorId)
      dispatch(setVendorDetailRequest(data as unknown as VendorDetailRequestInterface))
      if (handlePrefilledForm)
        handlePrefilledForm(data.request_data as never as VendorDetailsInterface)
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const submitReview = createAsyncThunk(
  `${SLICE_NAME}/submitReview`,
  async (
    {
      data,
      id,
      url,
      vendorName,
    }: {
      data: { action: string; reason: string }
      url: string
      id: number | string
      vendorName?: string
    },
    { dispatch },
  ) => {
    try {
      dispatch(setLoading(true))
      await postReview(id, data)
      dispatch(
        push({
          url,
          paramsUrl: { id },
          queryObject: url === vendor ? { section: 'approvals' } : {},
        }),
      )

      let message = `Dokumen ${vendorName} berhasil disetujui`
      if (data.action === 'REJECTED') message = `Dokumen ${vendorName} Ditolak-Menunggu Revisi`
      else if (data.action === 'CANCELLED') message = `Dokumen ${vendorName} dibatalkan`

      if (data.action !== 'REVIEWED') toastSuccess(message)
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const fetchOptions = createAsyncThunk(
  `${SLICE_NAME}/fetchOptions`,
  async (_, { dispatch }) => {
    try {
      const { data } = await getOptions()
      if (data?.data?.options?.length) {
        dispatch(setVendorOptions(data.data.options))
      }
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchUoms = createAsyncThunk(`${SLICE_NAME}/fetchUom`, async (_, { dispatch }) => {
  try {
    const { data } = await getUoms()
    if (data.data.unit_of_measurements.content?.length) {
      dispatch(setVendorUoms(data.data.unit_of_measurements.content))
    }
  } catch (error) {
    callErrorMsg(error)
  }
})

export const getchPicList = createAsyncThunk(`${SLICE_NAME}/fetchUom`, async (_, { dispatch }) => {
  try {
    const { data } = await getPicList()
    dispatch(
      setAstroPicList(
        data?.data?.users?.map((user) => ({
          id: 0,
          user_id: user.id,
          email: user.email,
          name: user.full_name || '',
          phone_number: user.phone_number,
        })) || [],
      ),
    )
  } catch (error) {
    callErrorMsg(error)
  }
})
