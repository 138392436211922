import { lazy } from 'react'

import { AssetMasterListRBAC } from '../pages/AssetMaster/rbac'
import { AssetMasterDetailPageRBACPage } from '../pages/AssetMasterDetail/rbac'

const AssetMaster = lazy(() => import('../pages/AssetMaster'))
const AssetMasterDetail = lazy(() => import('../pages/AssetMasterDetail'))

export const AssetMasterListRouter: RouterFeatureInterface<
  'assetMaster',
  '/dashboard/asset/asset-master'
> = {
  name: 'assetMaster',
  Component: AssetMaster,
  path: '/dashboard/asset/asset-master',
  routeType: 'PRIVATE',
  rbacPageID: AssetMasterListRBAC.pageID,
  allowedRoles: [],
  isDashboard: true,
}

export const AssetMasterDetailRouter: RouterFeatureInterface<
  'assetMasterDetail',
  '/dashboard/asset/asset-master/:id'
> = {
  name: 'assetMasterDetail',
  Component: AssetMasterDetail,
  path: '/dashboard/asset/asset-master/:id',
  rbacPageID: AssetMasterDetailPageRBACPage.pageID,
  routeType: 'PRIVATE',
  allowedRoles: [],
  isDashboard: true,
}

export const AssetMasterRouter = [AssetMasterListRouter, AssetMasterDetailRouter]
