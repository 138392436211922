import { memo, useContext } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { TextBody, InputDropDown } from 'components'
import { Link } from 'react-router-dom'
import storeContext from 'storeContext'
import { purchaseOrderLiteDetail } from 'config/routes'
import {
  updateStatusPOLite,
  setPoReferenceDialogState,
  setSelectedPoReferenceData,
} from 'storeContext/actions/supplyOrder/purchaseOrderLite'
import { toggleModalConfirmationSupplyOrder } from 'storeContext/actions/supplyOrder'
import dayjs from 'dayjs'
import { actHandleGetInboundPrintData } from 'storeContext/actions/inbound/inboundPO'
import { setTypePrintArea } from 'storeContext/actions/printArea'
import templatePrint from 'helpers/typeTemplateList'
import { purchaseOrderLiteEdit } from 'config/routes'
import { filterListOptions } from 'helpers'
import { Tooltip, IconButton, Icon, Box } from '@astro-ui/components'
import { toastFailed } from 'utils/toast'

function RowPurchaseOrderLite({
  data,
  handleClickPrint,
  handleClickOpenPopupDelivery,
  handleClickOpenPopupConfirmUpdateExpiredDate,
  listOption,
  isAbleToAccessPageCheckQty,
}) {
  const history = useHistory()
  const { dispatch } = useContext(storeContext.SupplyOrderContext)
  const { dispatch: dispatchPrintArea } = useContext(storeContext.PrintAreaContext)
  const { dispatch: dispatchInbound } = useContext(storeContext.InboundContext)

  const handlePrintGRN = () => {
    dispatchInbound(
      actHandleGetInboundPrintData(data.id, () => {
        dispatchPrintArea(setTypePrintArea(templatePrint.InboundPo))
        setTimeout(() => {
          window.print()
        }, 300)
      }),
    )
  }

  const handleUpdateStatus = (status) => {
    const { id } = data

    dispatch(updateStatusPOLite({ id: id, status: status }))
  }

  const handleGoToEditPOPage = () => {
    history.push(`${purchaseOrderLiteDetail.replace(':id', data.id)}#edit`)
  }

  const handleClickConfirm = () => {
    const { expiryDate, reqShippingDate } = data

    const INCOMING = 'INCOMING'

    if (expiryDate === null) return handleUpdateStatus(INCOMING)

    if (reqShippingDate > expiryDate) return handleClickOpenPopupConfirmUpdateExpiredDate(data)

    return handleUpdateStatus(INCOMING)
  }

  const handleClickCheckQTY = () => {
    const { id } = data

    const locationId = data.destination.locationId
    if (data.status === 'ARRIVED' && isAbleToAccessPageCheckQty(locationId)) {
      dispatch(
        updateStatusPOLite({ id, status: 'RECEIVING' }, {}, () =>
          history.push(purchaseOrderLiteEdit.replace(':id', id)),
        ),
      )
    } else if (data.status === 'ARRIVED' && !isAbleToAccessPageCheckQty(locationId)) {
      toastFailed('Anda tidak memiliki akses role')
    } else {
      history.push(purchaseOrderLiteEdit.replace(':id', id))
    }
  }

  const handleOpenPoReferenceDialog = () => {
    dispatch(setSelectedPoReferenceData(data))
    dispatch(setPoReferenceDialogState('OPEN_PO_REFERENCE_CONFIRM_DIALOG'))
  }

  const handleClickOption = (option) => {
    const obj = {
      editSo: () => {
        handleGoToEditPOPage()
      },
      confirm: () => {
        handleClickConfirm()
      },
      print: () => {
        handleClickPrint(data.id)
      },
      delivery: () => {
        handleClickOpenPopupDelivery(data)
      },
      arrived: () => {
        handleUpdateStatus('ARRIVED')
      },
      checkQTY: () => {
        handleClickCheckQTY()
      },
      printGRN: () => {
        handlePrintGRN()
      },
      approve: () => {
        handleUpdateStatus('APPROVE')
      },
      reject: () => {
        handleUpdateStatus('REJECTED')
      },
      cancel: () => {
        dispatch(
          toggleModalConfirmationSupplyOrder({
            isModalConfirmCancelOpen: true,
            supplyOrderId: data.id,
            poNumberToCancel: data.poNumber,
            locationId: data.destination.locationId,
          }),
        )
      },
      poReference: () => {
        handleOpenPoReferenceDialog()
      },
    }
    option.id in obj && obj[option.id]()
  }

  return (
    <Tr>
      <Td ratio="3">
        <Link to={() => purchaseOrderLiteDetail.replace(':id', data.id)}>
          <TextBody weight="light" color="main">
            {data.poNumber}
          </TextBody>
        </Link>
      </Td>
      <Td ratio="2">
        <TextBody weight="light" color="textSoft">
          {data.vendor.vendorName}
        </TextBody>
      </Td>

      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {dayjs(data.createdAt).format('DD MMM YYYY - HH:mm')}
        </TextBody>
      </Td>
      <Td ratio="2">
        <TextBody weight="light" color="textSoft">
          {data.totalItem}
        </TextBody>
      </Td>
      <Td ratio="2">
        <TextBody weight="light" color="textSoft">
          {data.destination.locationName}
        </TextBody>
      </Td>
      <Td ratio="2">
        <TextBody weight="light" color="textSoft">
          {data.createdBy}
        </TextBody>
      </Td>
      <Td ratio="3" dflex>
        <Box display="flex" alignItems="center">
          <TextBody weight="light" color="textSoft" className={`wrapperPO  ${data.status}`}>
            {data.status.toLowerCase().replaceAll('_', ' ')}
          </TextBody>
          {data.canBeReference && (
            <Tooltip title="Perlu PO Referencing" arrow placement="top">
              <IconButton>
                <Icon icon="information" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Td>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {dayjs(data.reqShippingDate).format('DD MMM YYYY - HH:mm')}
        </TextBody>
      </Td>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {data.expiryDate ? dayjs(data.expiryDate).format('DD MMM YYYY - HH:mm') : '-'}
        </TextBody>
      </Td>
      <Td ratio="2">
        <ActionWrapper>
          <InputDropDown
            disabled
            value="Atur"
            initialKey={data.id}
            noSetOption
            handleClickOption={handleClickOption}
            listOptions={filterListOptions(listOption, data.action)}
          />
        </ActionWrapper>
      </Td>
    </Tr>
  )
}

export default memo(RowPurchaseOrderLite)

const Tr = styled.tr`
  display: flex;
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
`
const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  ${({ dflex }) => dflex && `display:flex;`}
  text-align: start;
  .w120 {
    max-width: 120px;
  }

  .wrapperPO {
    padding: 6px;
    text-transform: capitalize;
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    background: #e4ebf5;
  }

  .wrapperPO.CREATED {
    background: #c5fcff;
    color: #0479b9;
  }
  .wrapperPO.RECEIVING {
    background: #fff1ba;
    color: #f36819;
  }
  .wrapperPO.COMPLETED {
    background: #c9fde0;
    color: #098a4e;
  }
  .wrapperPO.EXPIRED {
    background: #ffdbe2;
    color: #098a4e;
  }
  .wrapperPO.PENDING_APPROVAL_DELIVERY {
    background: #fff1ba;
    color: #f36819;
  }
  .wrapperPO.PENDING_APPROVAL_CREATED {
    background: #c5fcff;
    color: #0479b9;
  }
`

const ActionWrapper = styled.div`
  .input-drop-down {
    max-width: 110px;
    .list-container {
      right: 0;
      left: auto;
    }
  }
`
