import { Get } from 'config/apiServiceConfig'
import axiosInstanceApiGateway from 'config/apiServiceApiGateway'
import { Modifier, ModifierConnection } from '../@types'
import {
  DetailModifierFormType,
  VariantFormType,
  ProductType,
} from '../constants/modifierAddValidationSchema'

const { Get: GetKong, Post, Put } = axiosInstanceApiGateway

type GetModifierResponseType = {
  pagination: Modifier.PaginationType
  data: Modifier.ModifierType[]
}

type GetModifierConnectionResponseType = {
  pagination: ModifierConnection.PaginationType
  data: ModifierConnection.ModifierConnType[]
}

type GetProductsResponseType = {
  data: {
    productID: number
    productSkuNo: string
    productName: string
  }[]
}

type PostModifierResponseType = {
  message: string
}

type GetModifierDetailResponseType = {
  data: Modifier.ModifierType
}

type GetProductDetailResponseType = ProductType

export type CreateModifierPayloadType = Omit<
  DetailModifierFormType,
  'isMaxSelected' | 'is_required'
> & {
  is_required: boolean
  modifier_variants: Array<
    Omit<VariantFormType, 'product' | 'additional_product' | 'key'> & {
      product_id: ProductType['product_id']
      additional_product_id?: Nullable<ProductType['product_id']>
    }
  >
}

export const getModifiers = (params: Partial<Modifier.QueryType>) =>
  GetKong<GetModifierResponseType, true>({
    url: '/ims/internal/:version/modifier',
    version: 'v1',
    params,
  })

export const getProductModifiers = (params: Partial<Modifier.QueryType>) =>
  GetKong<GetModifierConnectionResponseType, true>({
    url: '/ims/internal/:version/product-modifier',
    version: 'v1',
    params,
  })

export const getProducts = (params: { name?: string; pageIndex: number; pageSize: number }) =>
  Get<GetProductsResponseType>({
    url: '/api/ims/:version/products',
    version: 'v2',
    params,
  })

export const postModifier = (data: CreateModifierPayloadType) =>
  Post<PostModifierResponseType, true>({
    url: '/ims/internal/:version/modifier',
    version: 'v1',
    data,
  })

export const puttModifier = (modifierId: number | string, data: CreateModifierPayloadType) =>
  Put<PostModifierResponseType, true>({
    url: '/ims/internal/:version/modifier/:modifierId',
    version: 'v1',
    urlParams: { modifierId },
    data,
  })

export const getModifierDetail = (modifierId: number | string) =>
  GetKong<GetModifierDetailResponseType, true>({
    url: '/ims/internal/:version/modifier/:modifierId',
    version: 'v1',
    urlParams: { modifierId },
  })

export const getProductDetail = (productId: number) =>
  Get<GetProductDetailResponseType>({
    url: '/api/product/:productId',
    urlParams: { productId },
  })

export const putProductModifier = (productId: number, data: { modifiers: { id: number }[] }) =>
  Put<PostModifierResponseType, true>({
    url: '/ims/internal/:version/product-modifier/:productId',
    version: 'v1',
    urlParams: { productId },
    data,
  })
