import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  type BlacklistAndWhitelistCustomerPayloadType,
  type BlacklistAndWhitelistDevicePayloadType,
  putWhitelistCustomer,
  putWhitelistDevice,
  putBlacklistCustomer,
  putBlacklistDevice,
  postBlacklistCustomer,
  postBlacklistDevice,
  postWhitelistCustomer,
  postWhitelistDevice,
} from 'features/Fraud/services/blacklistAndWhitelist'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getBulkTemplateDevice,
  getBulkTemplateCustomer,
  postBulkBlacklistCustomer,
  postBulkBlacklistDevice,
  postBulkWhitelistCustomer,
  postBulkWhitelistDevice,
  getBlacklistCustomer,
  getBlacklistDevice,
  getWhitelistCustomer,
  getWhitelistDevice,
  type PostPutBlackAndWhiteListCustomerPayloadType,
  type PostPutBlackAndWhiteListDevicePayloadType,
  type GetBlackAndWhiteListCustomerParamsType,
} from 'utils/apiList/blackAndWhiteList'

export type {
  PostPutBlackAndWhiteListCustomerPayloadType,
  PostPutBlackAndWhiteListDevicePayloadType,
}

// eslint-disable-next-line no-shadow
export enum RestrictionByEnum {
  user = 'user',
  device = 'device',
}

export type RestrictionByOptionType = {
  name: string
  value: string
}

export const SLICE_NAME = 'blackAndWhiteList'

// == blacklist == //
export const fetchBlacklistCustomer = createAsyncThunk(
  `${SLICE_NAME}/fetchBlackListCustomer`,
  async (initialQuery: GetBlackAndWhiteListCustomerParamsType, { rejectWithValue, getState }) => {
    try {
      const rootState = getState() as StoreStateType

      const { pagination, query } = rootState.blackAndWhiteList

      const finalQuery = {
        ...query,
        ...initialQuery,
      }

      const { pageIndex, fromDate, toDate, search } = finalQuery

      const params = {
        ...pagination,
        pageIndex,
        fromDate,
        toDate,
        customerId: search,
      }

      const res = await getBlacklistCustomer({
        ...params,
      })

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchBlacklistDevice = createAsyncThunk(
  `${SLICE_NAME}/fetchBlackListDevice`,
  async (initialQuery: GetBlackAndWhiteListCustomerParamsType, { rejectWithValue, getState }) => {
    try {
      const rootState = getState() as StoreStateType

      const { pagination, query } = rootState.blackAndWhiteList

      const finalQuery = {
        ...query,
        ...initialQuery,
      }

      const { pageIndex, fromDate, toDate, search } = finalQuery

      const params = {
        ...pagination,
        pageIndex,
        fromDate,
        toDate,
        deviceId: search,
      }

      const res = await getBlacklistDevice({
        ...params,
      })

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateBlacklistCustomer = createAsyncThunk(
  `${SLICE_NAME}/updateBlacklistCustomer`,
  async (
    { id, payload }: { id: number; payload: BlacklistAndWhitelistCustomerPayloadType },
    { rejectWithValue },
  ) => {
    try {
      const res = await putBlacklistCustomer(id, payload)
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateBlacklistDevice = createAsyncThunk(
  `${SLICE_NAME}/updateBlacklistDevice`,
  async (
    { id, payload }: { id: number; payload: BlacklistAndWhitelistDevicePayloadType },
    { rejectWithValue },
  ) => {
    try {
      const res = await putBlacklistDevice(id, payload)
      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const addSingleBlacklistCustomer = createAsyncThunk(
  `${SLICE_NAME}/addSingleBlacklistCustomer`,
  async (payload: BlacklistAndWhitelistCustomerPayloadType, { rejectWithValue }) => {
    try {
      const res = await postBlacklistCustomer(payload)
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const addSingleBlacklistDevice = createAsyncThunk(
  `${SLICE_NAME}/addSingleBlacklistDevice`,
  async (payload: BlacklistAndWhitelistDevicePayloadType, { rejectWithValue }) => {
    try {
      const res = await postBlacklistDevice(payload)
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const addBulkBlacklistCustomer = createAsyncThunk(
  `${SLICE_NAME}/addBulkBlacklistCustomer`,
  async ({ file, reason }: { file: File; reason: string }, { rejectWithValue }) => {
    try {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('reason', reason)
      const res = await postBulkBlacklistCustomer(formData)
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const addBulkBlacklistDevice = createAsyncThunk(
  `${SLICE_NAME}/addBulkBlacklistDevice`,
  async ({ file, reason }: { file: File; reason: string }, { rejectWithValue }) => {
    try {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('reason', reason)
      const res = await postBulkBlacklistDevice(formData)
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

// == whitelist == //
export const fetchWhitelistCustomer = createAsyncThunk(
  `${SLICE_NAME}/fetchWhiteListCustomer`,
  async (initialQuery: GetBlackAndWhiteListCustomerParamsType, { rejectWithValue, getState }) => {
    try {
      const rootState = getState() as StoreStateType

      const { pagination, query } = rootState.blackAndWhiteList

      const finalQuery = {
        ...query,
        ...initialQuery,
      }

      const { pageIndex, fromDate, toDate, search } = finalQuery

      const params = {
        ...pagination,
        pageIndex,
        fromDate,
        toDate,
        customerId: search,
      }

      const res = await getWhitelistCustomer({
        ...params,
      })

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchWhitelistDevice = createAsyncThunk(
  `${SLICE_NAME}/fetchWhiteListDevice`,
  async (initialQuery: GetBlackAndWhiteListCustomerParamsType, { rejectWithValue, getState }) => {
    try {
      const rootState = getState() as StoreStateType

      const { pagination, query } = rootState.blackAndWhiteList

      const finalQuery = {
        ...query,
        ...initialQuery,
      }

      const { pageIndex, fromDate, toDate, search } = finalQuery

      const params = {
        ...pagination,
        pageIndex,
        fromDate,
        toDate,
        deviceId: search,
      }

      const res = await getWhitelistDevice({
        ...params,
      })

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateWhitelistCustomer = createAsyncThunk(
  `${SLICE_NAME}/updateWhitelistCustomer`,
  async (
    { id, payload }: { id: number; payload: BlacklistAndWhitelistCustomerPayloadType },
    { rejectWithValue },
  ) => {
    try {
      const res = await putWhitelistCustomer(id, payload)
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateWhitelistDevice = createAsyncThunk(
  `${SLICE_NAME}/updateWhitelistDevice`,
  async (
    { id, payload }: { id: number; payload: BlacklistAndWhitelistDevicePayloadType },
    { rejectWithValue },
  ) => {
    try {
      const res = await putWhitelistDevice(id, payload)
      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const addSingleWhitelistCustomer = createAsyncThunk(
  `${SLICE_NAME}/addSingleWhitelistCustomer`,
  async (payload: BlacklistAndWhitelistCustomerPayloadType, { rejectWithValue }) => {
    try {
      const res = await postWhitelistCustomer(payload)
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const addSingleWhitelistDevice = createAsyncThunk(
  `${SLICE_NAME}/addSingleWhitelistDevice`,
  async (payload: BlacklistAndWhitelistDevicePayloadType, { rejectWithValue }) => {
    try {
      const res = await postWhitelistDevice(payload)
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const addBulkWhitelistCustomer = createAsyncThunk(
  `${SLICE_NAME}/addBulkWhitelistCustomer`,
  async ({ file, reason }: { file: File; reason: string }, { rejectWithValue }) => {
    try {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('reason', reason)
      const res = await postBulkWhitelistCustomer(formData)
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const addBulkWhitelistDevice = createAsyncThunk(
  `${SLICE_NAME}/addBulkWhitelistDevice`,
  async ({ file, reason }: { file: File; reason: string }, { rejectWithValue }) => {
    try {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('reason', reason)
      const res = await postBulkWhitelistDevice(formData)
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

// == others == //
export const downloadBulkTemplate = createAsyncThunk(
  `${SLICE_NAME}/downloadBulkTemplate`,
  async (selectedRestrictionBy: RestrictionByOptionType, { rejectWithValue }) => {
    try {
      if (selectedRestrictionBy.value === RestrictionByEnum.device) {
        const res = await getBulkTemplateDevice()
        return res.data.data
      }

      const res = await getBulkTemplateCustomer()
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
