/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const getPackingRecordingLocalStorage = () => localStorage.getItem('packingRecording')

export const getIsPackingRecordingHasUnresolvedKoli = () => {
  const packingRecordingStringify = getPackingRecordingLocalStorage()
  const hasCheckQtyData = JSON.parse(packingRecordingStringify!)?.checkQtyData
  return !!hasCheckQtyData
}

export const getLocalIP = (): Promise<string> =>
  new Promise<string>((resolve, reject) => {
    // Check for the environment variable to use a hardcoded IP for testing

    if (process.env.REACT_APP_ENABLE_HARDCODE_IP_FOR_TESTING === 'true') {
      resolve('192.168.2.213')
    } else {
      const rtc = new RTCPeerConnection({ iceServers: [] })
      rtc.createDataChannel('')
      rtc.createOffer().then((offer) => rtc.setLocalDescription(offer))

      rtc.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
        if (event.candidate) {
          const ipMatch = /([0-9]{1,3}\.){3}[0-9]{1,3}/.exec(event.candidate.candidate)
          if (ipMatch) {
            resolve(ipMatch[0])
            rtc.close() // Clean up the RTC connection
          }
        }
      }

      setTimeout(() => {
        reject(new Error('Timeout: Unable to get local IP'))
        rtc.close() // Clean up the RTC connection in case of timeout
      }, 5000)
    }
  })

export const isPackingRecordingActiveOnLocation = (
  isPackingRecordingByLocationIdActive: boolean,
  isChosenPackingLineActive: boolean,
) => isPackingRecordingByLocationIdActive && isChosenPackingLineActive

export const resetPackingRecordingToOriginalState = () => {
  localStorage.removeItem('packingRecording')
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export class ErrorGetLocalIp extends Error {}

const PACKING_RECORDING_LOCAL_IP_KEY = 'packingRecordingLocalIP'
export const getLocalIPPromised = () =>
  new Promise<string>((resolve, reject) => {
    const ip = localStorage.getItem(PACKING_RECORDING_LOCAL_IP_KEY)

    return ip ? resolve(ip) : reject(new ErrorGetLocalIp('Unable to get packing line'))
  })

export const setLocalIpToStorage = (ip: string) => {
  localStorage.setItem(PACKING_RECORDING_LOCAL_IP_KEY, ip)
}
