import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit'
import { withLoadingReducer } from 'utils/reducerHandler'
import safeJsonParse from 'utils/safeJsonParse'
import { fetchOrderTypeList, fetchProductList, submitCreateOrder } from './thunk'

export const CART_ITEMS_STORAGE = 'kitchen/cartItems'
export const ORDER_TOTAL_PRICE_STORAGE = 'kitchen/orderTotalPrice'
export const SELECTED_ORDER_TYPE = 'kitchen/selectedOrderType'

export type ProductType = {
  cloudKitchen: boolean
  inventoryActive: boolean
  inventoryPrice: string
  inventoryStock: number
  inventoryDiscountStock: number
  inventoryReversedStock: number
  inventoryTotalStock: number
  locationId: number
  locationName: string
  locationType: string
  productId: number
  productImage: string
  productName: string
  productSkuNumber: string
  rackName: string
  rackPosition: number
  virtualBomComponent: boolean
  bomCategory: string
}

export type OrderListType = {
  name: string
  image: string

  dummy_customer: {
    id: number
    phone_number: string
    name: string
    address: {
      address: string
      address_detail: string
      id: number
    }
  }
}

export type CreateOrderStateType = {
  isConfirmReset: boolean
  isLoading: boolean
  orderList: OrderListType[]
  activeStep: number
  selectedOrderType: OrderListType & {
    location_name: string
    location_id: number
    order_created_by?: string
    booking_code?: string
  }
  productList: ProductType[]
  pagination: ProductPaginationType
  isLoadingProduct: boolean
  cartItems: CartItemType[]
  orderTotalPrice: number
  isLoadingSubmit: boolean
}
export type ProductPaginationType = {
  direction: 'ASC' | 'DESC'
  numberOfElements: number
  pageIndex: number
  pageSize: number
  sort: string
}

export type CartItemType = {
  product_id: number
  quantity: number
  note?: Nullable<string>
  price: number
  name: string
  image: string
}

export const initialSelectedOrderType = {
  name: '',
  image: '',
  location_name: '',
  location_id: 0,
  dummy_customer: {
    id: 0,
    phone_number: '',
    name: '',
    address: {
      address: '',
      address_detail: '',
      id: 0,
    },
  },
  order_created_by: '',
  booking_code: '',
}

const initialState: CreateOrderStateType = {
  isConfirmReset: false,
  isLoading: false,
  orderList: [],
  activeStep: safeJsonParse<CartItemType[]>(String(localStorage.getItem(SELECTED_ORDER_TYPE)))
    ?.length
    ? 2
    : 1,
  selectedOrderType:
    safeJsonParse<CreateOrderStateType['selectedOrderType']>(
      String(localStorage.getItem(SELECTED_ORDER_TYPE)),
    ) || initialSelectedOrderType,
  productList: [],
  pagination: {
    direction: 'ASC',
    numberOfElements: 0,
    pageIndex: 1,
    pageSize: 20,
    sort: 'created_at',
  },
  isLoadingProduct: false,
  cartItems: safeJsonParse<CartItemType[]>(String(localStorage.getItem(CART_ITEMS_STORAGE))) || [],
  orderTotalPrice:
    safeJsonParse<number>(String(localStorage.getItem(ORDER_TOTAL_PRICE_STORAGE))) || 0,
  isLoadingSubmit: false,
}

const createOrderSlice = createSlice({
  name: 'createOrder',
  initialState,
  reducers: {
    setReset: () => ({
      ...initialState,
      selectedOrderType: initialSelectedOrderType,
      cartItems: [],
      activeStep: 1,
      orderTotalPrice: 0,
    }),
    setActiveStep: (state, action: PayloadAction<CreateOrderStateType['activeStep']>) => {
      state.activeStep = action.payload
    },
    setSelectedTypeOrder: (
      state,
      action: PayloadAction<CreateOrderStateType['selectedOrderType']>,
    ) => {
      state.selectedOrderType = action.payload
    },
    update: (state, action: PayloadAction<{ cartItems: CartItemType[]; totalPrice: number }>) => {
      const { cartItems, totalPrice } = action.payload
      state.cartItems = cartItems
      state.orderTotalPrice = totalPrice
    },
    setProductList: (state, action: PayloadAction<ProductType[]>) => {
      state.productList = action.payload
    },
    setPopupConfirmReset: (state, action: PayloadAction<boolean>) => {
      state.isConfirmReset = action.payload
    },
  },
  extraReducers: withLoadingReducer(
    (builder: ActionReducerMapBuilder<CreateOrderStateType>) => {
      builder
        .addCase(fetchOrderTypeList.fulfilled, (state, action) => {
          if (action.payload) {
            state.orderList = action.payload.data
          }
        })
        .addCase(fetchProductList.pending, (state) => {
          state.isLoadingProduct = true
        })
        .addCase(fetchProductList.fulfilled, (state, action) => {
          if (action.payload) {
            const productFiltered = action.payload.params.cloudKitchen
              ? action.payload.data.filter((product) => product.bomCategory === 'ASTRO_KITCHEN')
              : action.payload.data

            if (action.payload.pagination.pageIndex === 1) {
              state.productList = productFiltered
            } else {
              state.productList = [...state.productList, ...productFiltered]
            }

            state.pagination = action.payload.pagination
            state.isLoadingProduct = false
          }
        })
        .addCase(fetchProductList.rejected, (state) => {
          state.isLoadingProduct = false
        })
        .addCase(submitCreateOrder.pending, (state) => {
          state.isLoadingSubmit = true
        })
        .addCase(submitCreateOrder.fulfilled, (state) => {
          state.isLoadingSubmit = false
        })
        .addCase(submitCreateOrder.rejected, (state) => {
          state.isLoadingSubmit = false
        })
    },
    [fetchOrderTypeList, fetchProductList, submitCreateOrder],
  ),
})
export const {
  setReset,
  setActiveStep,
  setProductList,
  setSelectedTypeOrder,
  setPopupConfirmReset,
  update,
} = createOrderSlice.actions
export default createOrderSlice.reducer
