import { MenuItem as MuiMenuItem, MenuItemProps as MuiMenuItemProps } from '@mui/material'
import styled from 'styled-components'
import { layout, LayoutProps } from 'styled-system'

export type MenuItemPropsType = MuiMenuItemProps &
  LayoutProps & {
    /**
     * Extended isSelected props. We need this because default `value` or `selected` prop clashes with `autoFocus`.
     */
    isSelected?: boolean
  }

const MenuItem = styled(MuiMenuItem)<MenuItemPropsType>`
  ${layout}

  height: 42px;
  padding: 16px;
  color: ${({ theme }) => theme.colors.grey9};
  background-color: ${({ isSelected }) => (isSelected ? 'rgba(43,117,238,0.08)' : 'unset')};
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey1};
  :last-child {
    border-bottom: unset;
  }
`

export default MenuItem
export const MenuItemBase = MuiMenuItem
export type MenuItemBaseType = MuiMenuItemProps
