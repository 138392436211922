import { createTheme, CustomTheme, CustomThemeOptions } from '@mui/material'
import { useTheme as defaultUseTheme } from 'styled-components'

import fondations from '@astro-ui/fondations'
import { MuiButtonCustom, MuiTypographyCustom } from '@astro-ui/@muiOverrides'

export const themeOptions: CustomThemeOptions = {
  ...fondations,
  components: {
    ...MuiButtonCustom,
    ...MuiTypographyCustom,
  },
  palette: {
    primary: {
      main: fondations.colors.blue6,
    },
    neutral: {
      main: fondations.colors.spaceBlack,
    },
    error: {
      main: fondations.colors.red6,
    },
  },
  typography: {
    fontFamily: fondations.fontFamily.nunitoSans,
  },
}

export const customTheme = (options?: CustomThemeOptions) =>
  createTheme({
    ...themeOptions,
    ...options,
  })

const theme = customTheme(themeOptions)

export type AstroUIThemeType = CustomTheme

export const useTheme = defaultUseTheme
export default theme
