/* eslint-disable class-methods-use-this */
import { PureComponent, ReactNode } from 'react'

import { Box, Button, Image, Typography } from '@astro-ui/components'
import { parsedStackFrame, log } from 'utils/helpers/dataDogLog'
import { NoDataImage } from '@astro-ui/assets'
import { Refresh } from '@astronautsid/wpe-icons'

type PropsType = {
  children?: ReactNode
}

type StateType = {
  hasError: boolean
}

// eslint-disable-next-line @typescript-eslint/naming-convention
class ErrorBoundary extends PureComponent<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props)

    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError(_: Error): StateType {
    return { hasError: true }
  }

  componentDidCatch(error: Error) {
    if (!this.isChunkLoadError(error)) {
      this.handleLogs(error)
    }
  }

  isChunkLoadError = (error?: Error) => /loading chunk .+ failed/gi.test(error?.message || '')

  handleLogs = async (error: Error) => {
    const parsedErrorStack = await parsedStackFrame(error)

    log.error(`${error.name}: ${error.message}`, {
      customInfo: {
        origin: 'error-boundary',
      },
      error: {
        stack: parsedErrorStack,
      },
    })
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props

    if (hasError) {
      return (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          height="100vh"
        >
          <Image src={NoDataImage} />
          <Typography variant="h2" mb="32px">
            Sorry.. Something went wrong.
          </Typography>
          <Button
            startIcon={<Refresh color="white" size={19} />}
            onClick={() => {
              window.location.reload()
            }}
          >
            Refresh Page
          </Button>
        </Box>
      )
    }

    return children
  }
}

export default ErrorBoundary
