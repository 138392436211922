import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { AssetSupplyOrderDetailResponseInterface } from 'features/AssetManagement/@types/typeSupplyOrder'

interface SOOrderConvertDataInterface {
  supplyOrderId: number
  supplyOrderNumber: string
  supplyOrderItems: {
    productId: number
    productSku: string
    productName: string
    productUom: string
    requestQuantity: number
    totalActualQuantity: number
    supplyOrderItemId: number
  }[]
}

interface BarcodeAssetDataInterface {
  data: {
    expDate: number | null
    productId: number
    productName: string
    productSku: string
    productUom: string
    qrCode: string
    remainingRequestQuantity: number
    requestQuantity: number
    status: string
    statusId: number
    statusNote: string
    statusNoteId: number
    supplyOrderItemId: number
  }
  error: {
    message: string
  }
}

interface SupplyOrderListAssetStateType {
  isLoading: boolean
  detailSOAsset: AssetSupplyOrderDetailResponseInterface
  detailSOConverted: SOOrderConvertDataInterface
  assetData: {
    assetSoId: number
    originId: number
    status: string
    items: {
      assetCode: string
      assetname: string
      assetId: number
      incomingQty: number
      actualQty: number
      requestQty: number
    }[]
  } | null
  modalInputManual: {
    isOpen: boolean
    constants: {
      reason: {
        id: number
        reason: string
      }[]
    }
    step: 1 | 2
    form: {
      sku: string
      reason: {
        id: number
        reason: string
      } | null
    }
    item: {
      sku: string
      productName: string
      expiryDate: number | null
      totalRequestQty: number
      remainingRequestQuantity: number
      supplyOrderItemId: number
      statusId: number
      statusNoteId: number | null
      substituteItem: boolean
      qrCode: string
      productId: number | null
      totalAvailable: number
    }
  }
  qr: {
    barcodeAssetData: BarcodeAssetDataInterface
  }
}

const initialState: SupplyOrderListAssetStateType = {
  isLoading: false,
  detailSOAsset: {
    data: {
      id: 0,
      origin: {
        location_id: 0,
        location_name: '',
        location_type: '',
      },
      destination: {
        location_id: 0,
        location_name: '',
        location_type: '',
      },
      so_number: '',
      status: '',
      type: '',
      remarks: '',
      shipping_instruction: '',
      so_date: '',
      grn_number: '',
      created_at: '',
      updated_at: '',
      created_by: '',
      updated_by: '',
      so_reff: '',
      request_by: '',
    },
    items: [],
    error: {
      status: false,
      message: '',
      code: 0,
    },
  },
  detailSOConverted: {
    supplyOrderId: 0,
    supplyOrderNumber: '',
    supplyOrderItems: [],
  },
  assetData: null,
  modalInputManual: {
    isOpen: false,
    constants: {
      reason: [],
    },
    step: 1,
    form: {
      sku: '',
      reason: null,
    },
    item: {
      sku: '',
      productName: '',
      supplyOrderItemId: 0,
      expiryDate: null,
      totalRequestQty: 0,
      remainingRequestQuantity: 0,
      totalAvailable: 0,
      statusId: 0,
      statusNoteId: null,
      productId: null,
      qrCode: '',
      substituteItem: false,
    },
  },
  qr: {
    barcodeAssetData: {
      data: {
        expDate: null,
        productId: 0,
        productName: '',
        productSku: '',
        productUom: '',
        qrCode: '',
        remainingRequestQuantity: 0,
        requestQuantity: 0,
        status: '',
        statusId: 0,
        statusNote: '',
        statusNoteId: 0,
        supplyOrderItemId: 0,
      },
      error: {
        message: '',
      },
    },
  },
}

const assetSupplyOrderDetail = createSlice({
  name: 'assetSupplyOrderDetail',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setDetailSOAsset: (state, action: PayloadAction<AssetSupplyOrderDetailResponseInterface>) => {
      state.detailSOAsset = action.payload
    },
    setDetailSOConverted: (state, action: PayloadAction<SOOrderConvertDataInterface>) => {
      state.detailSOConverted = action.payload
    },
    setInputManual: (state, action: PayloadAction<boolean>) => {
      state.modalInputManual.isOpen = action.payload
    },
    setAsset: (state, action: PayloadAction<SupplyOrderListAssetStateType[`assetData`]>) => {
      state.assetData = action.payload
    },
    setAssetManualStep: (
      state,
      action: PayloadAction<SupplyOrderListAssetStateType[`modalInputManual`][`item`]>,
    ) => {
      state.modalInputManual.step = 2
      state.modalInputManual.item = action.payload
    },
    setAssetQrData: (
      state,
      action: PayloadAction<SupplyOrderListAssetStateType['qr']['barcodeAssetData']>,
    ) => {
      state.qr.barcodeAssetData = action.payload
    },
    setFormInputManual: (
      state,
      action: PayloadAction<{
        key: 'sku' | 'reason' | 'totalAvailable' | 'expiryDate'
        value: never
      }>,
    ) => {
      const { key, value } = action.payload

      if (key === 'totalAvailable') {
        state.modalInputManual.item.totalAvailable = +value
      } else if (key === 'expiryDate') {
        state.modalInputManual.item.expiryDate = value as number
      } else {
        state.modalInputManual.form[key] = value
      }
    },
    setStepInputManual: (state, action) => {
      state.modalInputManual.step = action.payload
    },
    resetManualInput: (state) => {
      state.modalInputManual = initialState.modalInputManual
    },
  },
})

export const {
  setIsLoading,
  setDetailSOAsset,
  setDetailSOConverted,
  setInputManual,
  setAssetManualStep,
  setAsset,
  setAssetQrData,
  setFormInputManual,
  setStepInputManual,
  resetManualInput,
} = assetSupplyOrderDetail.actions

export default assetSupplyOrderDetail.reducer
