import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  postUploadMissionImage,
  getRewardType,
  getVoucherList,
  postReferralMission,
  getMissionByID,
  putReferralMission,
} from 'utils/apiList/referralMission'
import type {
  PostUploadMissionImageRequestType,
  GetMissionByIDRequestType,
  PostReferralMissionRequestType,
} from 'utils/apiList/referralMission'
import { callErrorMsg } from 'helpers/errorMsg'
import { toastSuccess } from 'utils/toast'
import type { ReferralMissionAddAndEditStateType } from './referralMissionAddAndEditSlice'

export const SLICE_NAME = 'ReferralMissionAddAndEdit'

export type ImageKeyBannerType = keyof Pick<
  ReferralMissionAddAndEditStateType['mission'],
  'bannerUpcomingUrl' | 'bannerOngoingUrl' | 'bannerFinishedUrl' | 'linkImageUrl'
>

export type ImageKeyRewardIconType = keyof Pick<
  ReferralMissionAddAndEditStateType['mission']['rewards'][0],
  'iconEnabledUrl' | 'iconDisabledUrl'
>

export type UploadMissionImageParamsType = {
  payload: PostUploadMissionImageRequestType['payload']
  imageKey: ImageKeyBannerType
}

export type UploadMilestoneImageParamsType = {
  payload: PostUploadMissionImageRequestType['payload']
  imageKey: ImageKeyRewardIconType
  milestoneType: 'REWARD' | 'REWARD_SEQUENCE'
  sequenceIndex: number
  index: number
}

export const uploadMissionImage = createAsyncThunk(
  `${SLICE_NAME}/uploadMissionImage`,
  async ({ payload, imageKey }: UploadMissionImageParamsType, { rejectWithValue }) => {
    try {
      const res = await postUploadMissionImage({ payload })

      return { data: res.data, imageKey }
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const uploadMilestoneImage = createAsyncThunk(
  `${SLICE_NAME}/uploadMilestoneImage`,
  async (
    { payload, imageKey, index, milestoneType, sequenceIndex }: UploadMilestoneImageParamsType,
    { rejectWithValue },
  ) => {
    try {
      const res = await postUploadMissionImage({ payload })

      return { data: res.data, imageKey, index, milestoneType, sequenceIndex }
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchRewardType = createAsyncThunk(
  `${SLICE_NAME}/fetchRewardType`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getRewardType()

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchVoucherList = createAsyncThunk(
  `${SLICE_NAME}/fetchVoucherList`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getVoucherList({ params: { code: '' } })

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

const normalizedMission = (mission: StoreStateType['referralMissionAddAndEdit']['mission']) => {
  const normalizedRewards: PostReferralMissionRequestType['payload']['rewards'] =
    mission.rewards.map((el, idx) => {
      const { isSequence } = el

      return {
        rewardType: el.rewardType.value,
        rewardTitle: el.rewardTitle,
        rewardPosition: idx + 1,
        iconEnabledUrl: el.iconEnabledUrl,
        iconDisabledUrl: el.iconDisabledUrl,
        voucherCode: el.voucherCode,
        coinAmount: Number(el.coinAmount),
        description: el.description,
        quota: Number(el.quota),
        sequence: isSequence
          ? el.sequence.map((elSequence) => ({
              rewardTitle: elSequence.rewardTitle,
              iconEnabledUrl: elSequence.iconEnabledUrl,
              iconDisabledUrl: elSequence.iconDisabledUrl,
              rewardType: elSequence.rewardType.value,
              voucherCode: elSequence.voucherCode,
              coinAmount: elSequence.coinAmount,
              description: elSequence.description,
            }))
          : [],
      }
    })

  const { endDay, endTime, startDay, id, startTime, status, ...normalizeMission } = {
    ...mission,
    startDate: new Date(`${mission.startDay} ${mission.startTime}`).valueOf(),
    endDate: new Date(`${mission.endDay} ${mission.endTime}`).valueOf(),
    rewards: normalizedRewards,
  }

  return normalizeMission
}

export const createReferralMission = createAsyncThunk(
  `${SLICE_NAME}/createReferralMission`,
  async (_, { rejectWithValue, getState }) => {
    const {
      referralMissionAddAndEdit: { mission },
    } = getState() as StoreStateType

    try {
      const res = await postReferralMission({
        payload: { ...normalizedMission(mission), status: mission.status.value },
      })
      toastSuccess('Mission berhasil di buat')
      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchMissionByID = createAsyncThunk(
  `${SLICE_NAME}/fetchMissionByID`,
  async (request: GetMissionByIDRequestType, { rejectWithValue }) => {
    try {
      const res = await getMissionByID(request)

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const updateMissionByID = createAsyncThunk(
  `${SLICE_NAME}/updateMissionByID`,
  async (_, { rejectWithValue, getState }) => {
    const {
      referralMissionAddAndEdit: { mission },
    } = getState() as StoreStateType

    try {
      const res = await putReferralMission({
        id: mission.id.toString(),
        payload: normalizedMission(mission),
      })

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)
