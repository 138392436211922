import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

const { Get } = axiosInstanceApiGateway

export enum PaymentChannelTypeEnum {
  creditCard = 'CC',
  virtualAccount = 'VA',
  eWallet = 'E-Wallet',
}

export type PaymentChannelType = {
  id: number
  name: string
  image_url: string
}

export type PaymentChanneiByProviderType = {
  title: string
  type: string
  payment_channel: PaymentChannelType[]
}

export type GetPaymentChannelV2ResponseType = {
  data: {
    payment_channel_v2: PaymentChanneiByProviderType[]
  }
}

export type GetPaymentChannelV2ParamsType = {
  payment_channel_order_type: 'marketplace' | 'tipping' | 'topup'
}

export const getPaymentChannelV2 = (
  params: GetPaymentChannelV2ParamsType | undefined = { payment_channel_order_type: 'marketplace' },
) =>
  Get<GetPaymentChannelV2ResponseType, true>({
    url: '/payment/internal/v1/channel/list',
    params,
  })
