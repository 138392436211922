/* eslint-disable sonarjs/cognitive-complexity */
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { ImageRulesType } from '@astronautsid/wpe-utils'

import { downloadFileUrl } from 'utils/downloadFileUrl'
import { push, getQueryParamsStringToObject } from 'utils/helpers/router'
import { dateFormat, dateManipulation, stringToDateObject } from 'utils/helpers/date'
import { callErrorMsg } from 'helpers/errorMsg'
import { hubStaffSchedule, hubStaffAttendance } from 'config/routes'
import { AUTH_SPV_HUB } from 'middleware/privateRoute'
import {
  getScheduleListAPI,
  getStaffDetailAPI,
  getExportedSchedule,
  getExportedAttendance,
  getScheduleSummaryServices,
  postCreateScheduleAPI,
  // postCreateAttendanceAPI,
  putUpdateAttendanceAPI,
  putUpdateScheduleAPI,
  postLeavePermissionCurrentDay,
  getShiftListAPI,
  getStaffDetailAttendanceService,
  getAttendanceListService,
  getAttendanceHistoryService,
  getAttendanceNotesService,
  GetAttendanceHistoryServiceInterface,
  GetStaffDetailAttendanceServiceInterface,
  GetStaffDetailAPIInterface,
  GetSummaryScheduleServicesInterface,
  GetScheduleListAPIInterface,
  GetShiftListAPIInterface,
  GetAttendanceListServiceInterface,
  GetAttendanceNotesInterface,
  deleteLeavePermission,
  deleteSchedule,
  getHRISimageRules,
  ApprovalInterface,
  GetAllDepartementProcessedType,
  getDepartementList,
  type PostLeaverPermissionCurrentDayType,
  type UpdateAttendancePayloadType,
} from 'features/Enterprise/Schedules/services'

export type QueryParamsWorkingScheduleType = {
  startDate?: string
  endDate?: string
  hubId?: string
  locationType?: string
  staffNameOrId?: string
  department?: string
  pageIndex: number
  pageSize: number
}

export type WorkShiftType = GetShiftListAPIInterface['data'][0]

export type WorkingScheduleInitialStateType = {
  type: 'schedule' | 'attendance'
  isLoading: boolean
  dialogStateOpen:
    | 'CLOSED'
    | 'STAFF_DETAIL_DIALOG'
    | 'WORKING_SCHEDULE_DIALOG'
    | 'STAFF_ROTATION_DIALOG'
    | 'CUSTOM_SHIFT_DIALOG'
    | 'TAKE_ON_LEAVE_DIALOG'
    | 'OFF_DAY'
    | 'EXPORT_SCHEDULE_DIALOG'
    | 'DETAIL_ATTENDANCE_DIALOG'
    | 'CHANGE_ATTENDANCE_DIALOG'
    | 'ATTENDANCE_HISTORY_DIALOG'
    | 'SHOW_IMAGE_DIALOG'
    | 'SHOW_LEAVE_PERMISSION_DIALOG'
    | 'ADD_EMERGENCY_STAFF_ROTATION'
    | 'EDIT_EMERGENCY_STAFF_ROTATION'
    | 'UPDATE_OVERTIME'
  hubLists: {
    location_id: number
    location_name: string
    location_type: string
  }[]
  selectedHub?: WorkingScheduleInitialStateType['hubLists'][0]
  selectedDateRange?: {
    startDate?: Date
    endDate?: Date
  }
  staffNameOrId: string
  scheduleList: GetScheduleListAPIInterface['data']
  staffDetail: GetStaffDetailAPIInterface['data'] | null
  selectedStaff: {
    shiftNameOnSelectedDate: WorkingScheduleInitialStateType['shiftList'][0]['shift_name']
    staffId: WorkingScheduleInitialStateType['scheduleList'][0]['staff_id']
    locationId: WorkingScheduleInitialStateType['scheduleList'][0]['location_id']
    shiftDate: WorkingScheduleInitialStateType['scheduleList'][0]['schedule'][0]['start_date']
    selectedScheduleId?: WorkingScheduleInitialStateType['scheduleList'][0]['schedule'][0]['schedule_id']
    staffRole: string
    selectedScheduleStaffRole: string
    selectedEmergencySchedule?: {
      id: number
      startTime: string
      locationId: number
      locationName: string
      locationType: string
    }
    approvals?: ApprovalInterface[]
    approvalsId?: number[]
    overtime?: number
  } | null
  shiftList: GetShiftListAPIInterface['data']
  selectedShift: GetShiftListAPIInterface['data'][0] | null
  summary: GetSummaryScheduleServicesInterface['data']
  staffAttendanceDetail: GetStaffDetailAttendanceServiceInterface['data'] | null
  historyAttendance: GetAttendanceHistoryServiceInterface['data']
  attendanceList: GetAttendanceListServiceInterface['data']
  attendanceNotes: GetAttendanceNotesInterface['data']
  departmentLists: GetAllDepartementProcessedType[]
  selectedDepartment: string
  imageRules: ImageRulesType
  pagination: GetScheduleListAPIInterface['pagination']
}

type GetScheduleType = WorkingScheduleInitialStateType['selectedDateRange'] & {
  hub?: WorkingScheduleInitialStateType['hubLists'][0]
  nameOrId?: WorkingScheduleInitialStateType['staffNameOrId']
  department?: string
  pageIndex?: number
  pageSize?: number
}

const initialState: WorkingScheduleInitialStateType = {
  type: 'schedule',
  isLoading: false,
  dialogStateOpen: 'CLOSED',
  hubLists: [],
  selectedHub: undefined,
  selectedDateRange: undefined,
  staffNameOrId: '',
  scheduleList: [],
  summary: [],
  staffDetail: null,
  selectedStaff: null,
  shiftList: [],
  selectedShift: null,
  staffAttendanceDetail: null,
  historyAttendance: [],
  attendanceList: [],
  attendanceNotes: [],
  departmentLists: [],
  selectedDepartment: ``,
  imageRules: {} as ImageRulesType,
  pagination: {
    number_of_elements: 0,
    page_index: 1,
    page_size: 20,
    total_pages: 1,
    total_elements: 20,
  },
}

const reducerName = 'workingSchedule'

const appSlice = createSlice({
  name: reducerName,
  initialState,
  reducers: {
    reset: () => initialState,
    setType: (state, action: PayloadAction<WorkingScheduleInitialStateType['type']>) => {
      state.type = action.payload
    },
    setLoading: (state, action: PayloadAction<WorkingScheduleInitialStateType['isLoading']>) => {
      state.isLoading = action.payload
    },
    setDialogStateOpen: (
      state,
      action: PayloadAction<WorkingScheduleInitialStateType['dialogStateOpen']>,
    ) => {
      state.dialogStateOpen = action.payload
    },
    setHubList: (state, action: PayloadAction<WorkingScheduleInitialStateType['hubLists']>) => {
      state.hubLists = action.payload
    },
    setSelectedHub: (
      state,
      action: PayloadAction<WorkingScheduleInitialStateType['selectedHub']>,
    ) => {
      state.selectedHub = action.payload
    },
    setSelectedDateRange: (
      state,
      action: PayloadAction<WorkingScheduleInitialStateType['selectedDateRange']>,
    ) => {
      state.selectedDateRange = action.payload
    },
    setStaffNameOrId: (
      state,
      action: PayloadAction<WorkingScheduleInitialStateType['staffNameOrId']>,
    ) => {
      state.staffNameOrId = action.payload
    },
    setScheduleList: (state, action: PayloadAction<GetScheduleListAPIInterface>) => {
      state.scheduleList = action.payload?.data || []
      state.pagination = action.payload?.pagination || {}
    },
    setSummary: (state, action: PayloadAction<GetSummaryScheduleServicesInterface['data']>) => {
      state.summary = action.payload
    },
    setStaffDetail: (state, action: PayloadAction<GetStaffDetailAPIInterface['data']>) => {
      state.staffDetail = action.payload
    },
    setSelectedStaff: (
      state,
      action: PayloadAction<WorkingScheduleInitialStateType['selectedStaff']>,
    ) => {
      state.selectedStaff = action.payload
    },
    setShiftList: (state, action: PayloadAction<GetShiftListAPIInterface['data']>) => {
      state.shiftList = action.payload
    },
    setSelectedShift: (
      state,
      action: PayloadAction<WorkingScheduleInitialStateType['selectedShift']>,
    ) => {
      state.selectedShift = action.payload
    },
    setStaffAttendanceDetail: (
      state,
      action: PayloadAction<GetStaffDetailAttendanceServiceInterface['data']>,
    ) => {
      state.staffAttendanceDetail = action.payload
    },
    setHistoryAttendance: (
      state,
      action: PayloadAction<WorkingScheduleInitialStateType['historyAttendance']>,
    ) => {
      state.historyAttendance = action.payload
    },
    setAttendanceList: (state, action: PayloadAction<GetAttendanceListServiceInterface>) => {
      state.attendanceList = action.payload.data
      state.pagination = action.payload.pagination
    },
    setAttendanceNotes: (
      state,
      action: PayloadAction<WorkingScheduleInitialStateType['attendanceNotes']>,
    ) => {
      state.attendanceNotes = action.payload
    },
    setDepartmentList: (
      state,
      action: PayloadAction<WorkingScheduleInitialStateType['departmentLists']>,
    ) => {
      state.departmentLists = action.payload
    },
    setSelectedDepartment: (
      state,
      action: PayloadAction<WorkingScheduleInitialStateType['selectedDepartment']>,
    ) => {
      state.selectedDepartment = action.payload
    },
    setImageRules: (state, action: PayloadAction<ImageRulesType>) => {
      state.imageRules = action.payload
    },
  },
})

const { actions, reducer } = appSlice

const {
  setStaffDetail,
  setSelectedDateRange,
  setSelectedHub,
  setShiftList,
  setSummary,
  setStaffAttendanceDetail,
  setHistoryAttendance,
  setAttendanceList,
  setAttendanceNotes,
  setDepartmentList,
  setSelectedDepartment,
  setImageRules,
} = actions

export const {
  setHubList,
  setLoading,
  setScheduleList,
  setDialogStateOpen,
  setSelectedStaff,
  setSelectedShift,
  setStaffNameOrId,
  setType,
  reset,
} = actions

export const getSchedules = createAsyncThunk(
  `${reducerName}/getSchedules`,
  async (
    { hub, nameOrId, department, pageIndex, ...date }: GetScheduleType,
    { dispatch, getState },
  ) => {
    const {
      workingSchedule: { selectedDateRange, selectedHub, staffNameOrId, type, selectedDepartment },
    } = getState() as StoreStateType

    const paramsDate = Object.keys(date).length ? date : selectedDateRange
    const substractStartDate = dateManipulation(paramsDate?.startDate).subtract(1, 'day')

    const parsedDate = {
      startDate: dateFormat({
        date: type === 'schedule' ? substractStartDate : paramsDate?.startDate,
        format: 'YYYYMMDD',
      }),
      endDate: dateFormat({
        date: paramsDate?.endDate,
        format: 'YYYYMMDD',
      }),
    }
    const locationId = (hub || selectedHub)?.location_id
    const search = nameOrId || staffNameOrId
    const departmentParam = department || selectedDepartment
    try {
      dispatch(setLoading(true))
      const getSchedulesList = async () => {
        try {
          const { data } = await getScheduleListAPI({
            ...parsedDate,
            locationId,
            search,
            department: departmentParam,
            pageIndex: pageIndex || 1,
          })
          return Promise.resolve(data)
        } catch (error) {
          return Promise.reject(error)
        }
      }
      const getSummary = async () => {
        try {
          const {
            data: { data: summarydata },
          } = await getScheduleSummaryServices({
            ...parsedDate,
            locationId,
            search,
          })
          return Promise.resolve(summarydata)
        } catch (error) {
          return Promise.reject(error)
        }
      }
      const getAttendanceList = async () => {
        try {
          const { data } = await getAttendanceListService({
            ...parsedDate,
            locationId,
            search,
            department: departmentParam,
            pageIndex: pageIndex || 1,
          })
          const res = {
            data: filterData(data),
            pagination: data.pagination,
          }
          return Promise.resolve(res as GetAttendanceListServiceInterface)
        } catch (error) {
          return Promise.reject(error)
        }
      }
      const [schedule, summary, attendance] = await Promise.all([
        type === 'schedule' ? getSchedulesList() : [],
        type === 'schedule' ? getSummary() : [],
        type === 'attendance' ? getAttendanceList() : [],
      ])
      dispatch(setSummary(summary))
      if (type === 'schedule') {
        dispatch(setScheduleList(schedule as GetScheduleListAPIInterface))
      } else {
        dispatch(setAttendanceList(attendance as GetAttendanceListServiceInterface))
      }
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

const filterData = ({ data }: GetAttendanceListServiceInterface) =>
  data?.map((x) => {
    const results = x?.attendance?.map((attendance) => ({
      ...attendance,
      approvals: filterDuplicateApprovals(attendance?.approvals || []),
      emergency_rotation: attendance?.emergency_rotation?.map((y) => ({
        ...y,
        approvals: filterDuplicateApprovals(y?.approvals || []),
      })),
    }))

    return {
      ...x,
      attendance: results,
    }
  })

const filterDuplicateApprovals = (approvals: ApprovalInterface[]) => {
  const results: ApprovalInterface[] = []

  approvals?.forEach((x, _, s) => {
    const tes = s.filter((m) => m.issue_notes === x.issue_notes)?.sort((a, b) => b.id - a.id)
    results.push([...tes][0])
  })

  return results.filter(
    (item, index, self) => self.findIndex((t) => t?.issue_notes === item?.issue_notes) === index,
  )
}

export const initHubStaffSchedule = createAsyncThunk(
  `${reducerName}/initHubStaffSchedule`,
  async (
    {
      date,
      query,
    }: {
      date: WorkingScheduleInitialStateType['selectedDateRange']
      query: QueryParamsWorkingScheduleType
    },
    { dispatch, getState },
  ) => {
    const { startDate, endDate, hubId, staffNameOrId, department, pageIndex, pageSize } = query
    const newDate =
      startDate && endDate
        ? {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            startDate: stringToDateObject(startDate, 'DD-MM-YYYY'),
            endDate: stringToDateObject(endDate, 'DD-MM-YYYY'),
          }
        : date
    const {
      auth: {
        userData: { authorities, location_roles },
      },
      filterLocationLists: { locationLists },
    } = getState() as StoreStateType

    const isSPV = Boolean(authorities.find((i: { name: string }) => i.name === AUTH_SPV_HUB))
    const selectedFirstLocationSPVOnly = location_roles.find((location: { role_names: string[] }) =>
      location.role_names.some((role) => `AUTH_${role}` === AUTH_SPV_HUB),
    ) as StoreStateType['auth']['userData']['location_roles'][0]
    let selectedHubFromRoles

    if (isSPV) {
      selectedHubFromRoles = {
        location_id: selectedFirstLocationSPVOnly.location_id || 0,
        location_name: selectedFirstLocationSPVOnly.location_name || ``,
        location_type: selectedFirstLocationSPVOnly.location_type || ``,
      }
    }

    const getSelectedHub = (hubs: WorkingScheduleInitialStateType['hubLists']) => {
      if (hubId) {
        return hubs.find((each) => each.location_id === +hubId)
      }
      return hubs[0]
    }

    try {
      dispatch(setSelectedDateRange(newDate))
      dispatch(setStaffNameOrId(staffNameOrId || ''))
      dispatch(setLoading(true))
      const selectedHubs = isSPV && !hubId ? selectedHubFromRoles : getSelectedHub(locationLists)
      dispatch(setHubList(locationLists))
      dispatch(setSelectedHub(selectedHubs))
      dispatch(setSelectedDepartment(department || ``))
      dispatch(getShiftList({ locationId: selectedHubs?.location_id as unknown as string }))
      dispatch(
        getSchedules({
          ...newDate,
          hub: selectedHubs,
          nameOrId: staffNameOrId,
          department: department || ``,
          pageIndex,
          pageSize,
        }),
      )
    } catch (error) {
      callErrorMsg(error)
      dispatch(setLoading(false))
    }
  },
)

export const setHeaderData = createAsyncThunk(
  `${reducerName}/setHeaderData`,
  (data: GetScheduleType, { dispatch, getState }) => {
    const { hub, nameOrId, department, pageIndex, ...date } = data
    const {
      router: {
        location: { search },
      },
      workingSchedule: { type },
    } = getState() as StoreStateType
    const query = getQueryParamsStringToObject<QueryParamsWorkingScheduleType>(search)
    const newQuery = () => {
      if (hub) {
        return {
          hubId: hub.location_id as unknown as string,
          locationType: hub.location_type as unknown as string,
          pageIndex: 1,
          pageSize: 20,
        }
      }
      if (pageIndex) {
        return {
          pageIndex,
          pageSize: 20,
        }
      }

      if (Object.keys(date).length) {
        return {
          startDate: dateFormat({
            date: date.startDate,
            format: 'DD-MM-YYYY',
          }),
          endDate: dateFormat({
            date: date.endDate,
            format: 'DD-MM-YYYY',
          }),
          pageIndex: 1,
          pageSize: 20,
        }
      }
      if (typeof department === 'string') {
        return { department: department || undefined, pageIndex: 1, pageSize: 20 }
      }

      return {
        staffNameOrId: nameOrId,
        pageIndex: 1,
        pageSize: 20,
      }
    }
    dispatch(
      push<QueryParamsWorkingScheduleType>({
        url: type === 'schedule' ? hubStaffSchedule : hubStaffAttendance,
        queryObject: {
          ...query,
          ...newQuery(),
        },
      }),
    )
  },
)

export const getStaffDetail = createAsyncThunk(
  `${reducerName}/getStaffDetail`,
  async (id: number, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const {
        data: { data },
      } = await getStaffDetailAPI({ id })
      dispatch(setStaffDetail(data))
      dispatch(setDialogStateOpen('STAFF_DETAIL_DIALOG'))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

const isNextDay = (startTime: number, endTime: number) => startTime > endTime

export const createSchedule = createAsyncThunk(
  `${reducerName}/createSchedule`,
  async (
    {
      hours = 0,
      startTime,
      endTime,
      // file,
      reason,
      rotationLocationId,
      emergencyRotationLocationId,
      rotationScheduleId,
      isCreateEmergencyRotation = false,
      role,
      emergencyScheduleId,
      approvalIds,
      fileUrl,
    }: {
      hours?: number
      startTime?: string
      endTime?: string
      rotationLocationId?: number
      file?: File
      reason?: Record<string, unknown>
      emergencyRotationLocationId?: number
      role?: string
      rotationScheduleId?: number
      isCreateEmergencyRotation?: boolean
      emergencyScheduleId?: number
      approvalIds?: number[]
      fileUrl?: string
    },
    { dispatch, getState },
  ) => {
    try {
      const {
        workingSchedule: { selectedStaff, selectedShift },
        auth: { userData },
      } = getState() as StoreStateType
      const { shift_name: shiftName } = selectedShift as Exclude<
        WorkingScheduleInitialStateType['selectedShift'],
        null
      >
      dispatch(setLoading(true))
      const authority = userData.authorities.map((x: { name: string }) => x.name)
      const is_admin = authority.includes('AUTH_ADMIN')
      const end_date = isNextDay(
        Number(startTime || selectedShift?.start_time || ''),
        Number(endTime || selectedShift?.end_time || ''),
      )
        ? dateFormat({
            date: dateManipulation(
              stringToDateObject(selectedStaff?.shiftDate || '', 'YYYYMMDD'),
            ).add(1, 'day'),
            format: 'YYYYMMDD',
          })
        : selectedStaff?.shiftDate

      if (isCreateEmergencyRotation || !selectedStaff?.shiftNameOnSelectedDate) {
        await postCreateScheduleAPI({
          schedule_file_url: fileUrl || '',
          approval_ids: approvalIds,
          authority,
          created_by: userData.email,
          description: 'dokumen wajib diisi',
          end_date: end_date as string,
          end_time: endTime || selectedShift?.end_time || '',
          file_name: '',
          is_admin,
          location_id: (emergencyRotationLocationId || selectedStaff?.locationId) as number,
          note: (reason?.note as string) || ``,
          over_time: String(hours),
          role: role as string,
          rotation_location_id: rotationLocationId || 0,
          rotation_schedule_id: rotationScheduleId || 0,
          shift_name: shiftName,
          staff_id: selectedStaff?.staffId as number,
          start_date: selectedStaff?.shiftDate as string,
          start_time: startTime || selectedShift?.start_time || '',
        })
      } else {
        await putUpdateScheduleAPI(
          {
            location_id: (emergencyRotationLocationId || selectedStaff?.locationId) as number,
            start_date: selectedStaff?.shiftDate as string,
            start_time: startTime || selectedShift?.start_time || '',
            end_date: end_date as string,
            end_time: endTime || selectedShift?.end_time || '',
            shift_name: shiftName,
            over_time: hours ? String(hours) : '0',
            updated_by: userData.email,
            note: (reason?.note as string) || ``,
            description: 'dokumen wajib diisi',
            rotation_location_id: rotationLocationId || 0,
            role: role as string,
            is_admin,
            authority,
            rotation_schedule_id: rotationScheduleId || 0,
            schedule_file_url: fileUrl || '',
            approval_ids: approvalIds,
            staff_id: selectedStaff?.staffId as number,
          },
          (emergencyScheduleId || selectedStaff?.selectedScheduleId) as number,
        )
      }
      dispatch(getSchedules({}))
      dispatch(setDialogStateOpen('CLOSED'))
    } catch (error) {
      callErrorMsg(error)
      dispatch(setLoading(false))
    }
  },
)

export const getShiftList = createAsyncThunk(
  `${reducerName}/getShiftList`,
  async (args: { locationId: string }, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const {
        data: { data },
      } = await getShiftListAPI(args)
      dispatch(setShiftList(data))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const getShiftListRotationDialog = createAsyncThunk(
  `${reducerName}/getShiftListRotationDialog`,
  async (args: { locationId: string }, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const {
        data: { data },
      } = await getShiftListAPI(args)
      return data
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
    return []
  },
)

export const exportSchedule = createAsyncThunk(
  `${reducerName}/exportSchedule`,
  async (params: Parameters<typeof getExportedSchedule>[0], { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const {
        data: {
          data: { file_url },
        },
      } = await getExportedSchedule(params)
      downloadFileUrl(file_url)
      dispatch(setDialogStateOpen('CLOSED'))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const exportAttendance = createAsyncThunk(
  `${reducerName}/exportAttendance`,
  async (params: Parameters<typeof getExportedAttendance>[0], { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const {
        data: {
          data: { file_url },
        },
      } = await getExportedAttendance(params)
      downloadFileUrl(file_url)
      dispatch(setDialogStateOpen('CLOSED'))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const getDetailStaffAttendance = createAsyncThunk(
  `${reducerName}/getDetailStaffAttendance`,
  async (payload: { id: number; date: string; locationId: number }, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const {
        data: { data },
      } = await getStaffDetailAttendanceService(payload)
      dispatch(setStaffAttendanceDetail(data))
      dispatch(setDialogStateOpen('DETAIL_ATTENDANCE_DIALOG'))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const getHistoryAttendance = createAsyncThunk(
  `${reducerName}/getHistoryAttendance`,
  async (payload: Parameters<typeof getAttendanceHistoryService>['0'], { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const {
        data: { data },
      } = await getAttendanceHistoryService(payload)
      dispatch(setHistoryAttendance(data))
      dispatch(setDialogStateOpen('ATTENDANCE_HISTORY_DIALOG'))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const createOrUpdateAttendance = createAsyncThunk(
  `${reducerName}/createAttendance`,
  async (
    {
      payload,
      attendanceId,
      scheduleId,
    }: { payload: UpdateAttendancePayloadType; attendanceId: number; scheduleId: string },
    { dispatch },
  ) => {
    try {
      dispatch(setLoading(true))
      await putUpdateAttendanceAPI(payload, attendanceId)
      if (scheduleId) await deleteLeavePermission({ scheduleId })
      dispatch(getSchedules({}))
      dispatch(setDialogStateOpen('CLOSED'))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const getAttendanceNotes = createAsyncThunk(
  `${reducerName}/getAttendanceNotes`,
  async (_, { dispatch }) => {
    try {
      const {
        data: { data },
      } = await getAttendanceNotesService()
      dispatch(setAttendanceNotes(data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const createLeavePermissionCurrentDay = createAsyncThunk(
  `${reducerName}/createLeavePermissionCurrentDay`,
  async (payload: PostLeaverPermissionCurrentDayType, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      await postLeavePermissionCurrentDay(payload)
      dispatch(getSchedules({}))
      dispatch(setDialogStateOpen('CLOSED'))
    } catch (error) {
      callErrorMsg(error)
      dispatch(setLoading(false))
    }
  },
)

export const deleteStaffSchedule = createAsyncThunk(
  `${reducerName}/deleteStaffSchedule`,
  async (id: number, { dispatch }) => {
    try {
      await deleteSchedule(id)
      dispatch(getSchedules({}))
      dispatch(setDialogStateOpen('CLOSED'))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchDepartementLists = createAsyncThunk(
  `${reducerName}/fetchDepartementLists`,
  async (_, { dispatch }) => {
    try {
      const response = await getDepartementList()
      const dataObject = response?.data?.data
      const keys = Object.keys(response?.data?.data)
      const dataProcessed: GetAllDepartementProcessedType[] = []

      keys.forEach((key: string) => {
        dataProcessed.push({ content: dataObject[key].names, name: key })
      })

      dispatch(setDepartmentList(dataProcessed))
    } catch (err) {
      callErrorMsg(err)
    }
  },
)

export const fetchImageRules = createAsyncThunk(
  `${reducerName}/fetchImageRules`,
  async (_, { dispatch }) => {
    try {
      const { data } = await getHRISimageRules('hris-schedule-attendance')
      dispatch(setImageRules(data.data))
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan image rules HRIS')
    }
  },
)

export default reducer
