import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  InitialStateType,
  CloseMessageDialogStateType,
  QueryType,
  CloseMessageType,
  SetFormPayloadType,
  CloseMessageFormType,
} from 'features/Location/@types/CloseMessageConfig'

export const CLOSE_MESSAGE_SLICE_NAME = 'closeMessageConfig'

export const initialState: InitialStateType = {
  isLoading: false,
  dialogState: 'CLOSED',
  query: {
    page: 0,
    limit: 20,
    search: '',
  },
  closeMessages: [],
  form: {
    reason: '',
    title: '',
    body: '',
  },
  selectedCloseMessage: null,
}

const location = createSlice({
  name: CLOSE_MESSAGE_SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setDialogState: (state, { payload }: PayloadAction<CloseMessageDialogStateType>) => {
      state.dialogState = payload
    },
    setQuery: (state, { payload }: PayloadAction<QueryType>) => {
      state.query = { ...payload }
    },
    setCloseMessages: (state, { payload }: PayloadAction<CloseMessageType[]>) => {
      state.closeMessages = payload
    },
    setForm: (state, { payload }: PayloadAction<SetFormPayloadType>) => {
      const { field, value } = payload
      state.form[field] = value
    },
    resetForm: (state, { payload }: PayloadAction<CloseMessageFormType | undefined>) => {
      state.form = { ...(payload || initialState.form) }
    },
    setSelectedCloseMessage: (state, { payload }: PayloadAction<Nullable<CloseMessageType>>) => {
      state.selectedCloseMessage = payload
    },
  },
})

export default location.reducer
export const {
  resetState,
  setDialogState,
  setQuery,
  setIsLoading,
  setCloseMessages,
  setForm,
  resetForm,
  setSelectedCloseMessage,
} = location.actions
