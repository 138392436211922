import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type {
  GetAllLocationByTypeResponseType,
  GetLocationTypeResponseType,
  GetRoleByLocationTypeResponseType,
} from 'utils/apiList/constant'

export type ConstantStateType = {
  isLoading: boolean
  locationType: GetLocationTypeResponseType['data']
  allLocationByType: {
    [key: string]: GetAllLocationByTypeResponseType['content']
  }
  allRoleByLocationType: {
    [key: string]: GetRoleByLocationTypeResponseType
  }
}

const initialState: ConstantStateType = {
  isLoading: false,
  locationType: [],
  allLocationByType: {},
  allRoleByLocationType: {},
}

export const sliceName = 'constant'

const constantSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<ConstantStateType['isLoading']>) => {
      state.isLoading = action.payload
    },
    setLocationType: (state, action: PayloadAction<ConstantStateType['locationType']>) => {
      state.locationType = action.payload
    },
    setAllLocationByType: (
      state,
      action: PayloadAction<{
        key: string
        data: GetAllLocationByTypeResponseType['content']
      }>,
    ) => {
      const { key, data } = action.payload
      state.allLocationByType[key] = data
    },
    setRoleByLocationType: (
      state,
      action: PayloadAction<{
        key: string
        data: GetRoleByLocationTypeResponseType
      }>,
    ) => {
      const { key, data } = action.payload
      state.allRoleByLocationType[key] = data
    },
  },
})

export const { setLoading, setLocationType, setAllLocationByType, setRoleByLocationType } =
  constantSlice.actions

export default constantSlice.reducer
