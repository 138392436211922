import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const HCURolesRBAC = {
  pageID: '4d5c3cc8ba3213e9206630700cc132e7',
  elementID: {
    ViewHCUPage: '1398ec6290e6cecfe6a18b7ae996ef2d',
    ApproveOrRejectTickets: 'aad2f7753b7fc3eddaeab55e26850d10',
  },
} as const

export const useRBACHCUPage = createUseRBAC(HCURolesRBAC.elementID)

export type UseRBACHCUPageType = ReturnType<typeof useRBACHCUPage>
