import axiosInstanceApiGateway from 'config/apiServiceApiGateway'
import { Get } from 'config/apiServiceConfig'

export type GetXdockListResponseType = {
  data: {
    id: number
    xdock_task_number: string
    purchase_order_id: number
    purchase_order_number: string
    location_id: number
    location_name: string
    product_id: number
    product_name: string
    product_sku_number: string
    product_uom: string
    category_id: number
    category_name: string
    staff_id: number
    staff_name: string
    total_qty: number
    inbound_date: number
    destination_count: number
    status: string
  }[]
  pagination: {
    sort: string
    direction: string
    page_size: number
    page_index: number
    number_of_elements: number
  }
}

export type GetXdockListParamsType = {
  page_size: number
  page_index: number
  search_product?: string
  staff_id?: number
  status: string
}

export type GetStaffSorterByLocationResponseType = {
  id: number
  fullName: string
}

export const getXdockList = (params: Partial<GetXdockListParamsType>) =>
  axiosInstanceApiGateway.Get<GetXdockListResponseType, true>({
    url: '/wms/internal/v1/xdock-tasks',
    params,
  })

export const assignXdockTask = (id: number, data: { staff_id: number }) =>
  axiosInstanceApiGateway.Post<{ message: string }, true>({
    url: `/wms/internal/v1/xdock-tasks/${id}/assign`,
    data,
  })

export const getStaffSorterByLocation = (locationId: number) =>
  Get<{ data: GetStaffSorterByLocationResponseType[] }>({
    url: `/api/staff-management/staff/${locationId}/AUTH_SORTER`,
  })
