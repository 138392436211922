import { memo, useContext } from 'react'
import styled from 'styled-components'
import Header from './Header'
import Body from './Body'

import store from 'storeContext'
import TemplateGrabMart from '../TemplateGrabMart'

function TemplateOrderPickerPicking() {
  const { state: statePrintArea } = useContext(store.PrintAreaContext)

  if (!statePrintArea.dataResiOrderPickerPicking) return null

  const renderTemplate = () => {
    if (statePrintArea.dataResiOrderPickerPicking.order_type == 'GRAB_MART') {
      return (
        <TemplateGrabMart
          data={statePrintArea.dataResiOrderPickerPicking}
          packageOrderNumber={1}
          packageId={statePrintArea.dataResiOrderPickerPicking.order_packages[0].packageId}
        />
      )
    }

    return (
      <>
        <Container className="">
          <Header
            data={statePrintArea.dataResiOrderPickerPicking}
            imageBarcodeBlob={statePrintArea.imageBarcodeBlob}
          />
          <Body
            data={statePrintArea.dataResiOrderPickerPicking}
            packageOrderNumber={1}
            packageId={statePrintArea.dataResiOrderPickerPicking.order_packages[0].packageId}
          />
        </Container>
      </>
    )
  }

  return renderTemplate()
}

export default memo(TemplateOrderPickerPicking)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4vh;
  width: 100%;
  height: 100%;
  padding-bottom: 0px;
  @media print {
    page-break-inside: avoid;
    break-inside: avoid;
    clear: both;
    @page {
      size: 101.6mm 101.6mm;
      margin: 0mm;
    }
  }
`
