import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getLimitSKU,
  postLimitSKU,
  putLimitSKU,
  type PostLimitSKUPayloadType,
  type PutLimitSKUPayloadType,
} from 'features/SupplyOrder/services/limitSKU'
import { callErrorMsg } from 'helpers/errorMsg'
import { QueryParamsType } from 'features/SupplyOrder/@types/LimitSKU'
import {
  getLocationTypeList,
  getLocationListByType,
  getLocationDetail,
} from 'features/Location/services/location'
import {
  SLICE_NAME_LIMIT_SKU,
  setIsLoading,
  setLimitSKUList,
  setPagination,
  setLocationType,
  setLocationList,
} from './slice'

export const fetchLimitSKU = createAsyncThunk(
  `${SLICE_NAME_LIMIT_SKU}/fetchLimitSKU`,
  async (params: Partial<QueryParamsType> | undefined, { dispatch, getState }) => {
    const {
      limitSKU: { pagination },
    } = getState() as StoreStateType

    let queryParams: Partial<QueryParamsType> = typeof params !== 'undefined' ? { ...params } : {}

    if (typeof params === 'undefined') {
      queryParams = {
        page_index: pagination.page_index,
        page_size: pagination.page_size,
      }
    }

    dispatch(setIsLoading(true))
    try {
      const { data } = await getLimitSKU(queryParams)
      dispatch(setLimitSKUList(data.data))
      dispatch(setPagination(data.pagination))
    } catch (error) {
      callErrorMsg(error)
    }
    dispatch(setIsLoading(false))
  },
)

export const fetchLocationType = createAsyncThunk(
  `${SLICE_NAME_LIMIT_SKU}/fetchLocationType`,
  async (_, { dispatch }) => {
    try {
      const { data } = await getLocationTypeList()
      dispatch(setLocationType(data.data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchLocation = createAsyncThunk(
  `${SLICE_NAME_LIMIT_SKU}/fetchLocation`,
  async (locationType: string, { dispatch }) => {
    try {
      const { data } = await getLocationListByType(locationType)
      dispatch(setLocationList(data))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const fetchLocationDetail = createAsyncThunk(
  `${SLICE_NAME_LIMIT_SKU}/fetchLocationDetail`,
  async (locationId: number) => {
    try {
      const { data } = await getLocationDetail(locationId)
      return data
    } catch (error) {
      return null
    }
  },
)

export const createLimitSKU = createAsyncThunk(
  `${SLICE_NAME_LIMIT_SKU}/createLimitSKU`,
  async (payload: PostLimitSKUPayloadType) => {
    try {
      const { data } = await postLimitSKU(payload)
      return data
    } catch (error) {
      return error
    }
  },
)

export const updateLimitSKU = createAsyncThunk(
  `${SLICE_NAME_LIMIT_SKU}/updateLimitSKU`,
  async ({ id, payload }: { id: number; payload: PutLimitSKUPayloadType }) => {
    try {
      const { data } = await putLimitSKU(id, payload)
      return data
    } catch (error) {
      return error
    }
  },
)
