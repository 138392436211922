import { Button, LoadingPage, SystemIcon, Title } from 'components'
import { loyaltyVoucher } from 'config/routes'
import dayjs from 'dayjs'
import { isValidHttpUrl, toBase64 } from 'helpers'
import { memo, useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import storeContext from 'storeContext'
import { setFindUser, setSelectedCustomers } from 'storeContext/actions/refferal'
import {
  actGetDataType,
  actGetPaymentChannel,
  actHandleAddVoucher,
  actHandleEditVoucher,
  actHandleGetVoucherData,
  reset,
} from 'storeContext/actions/voucher'
import styled from 'styled-components'
import { VoucherDetail, VoucherPeriod } from './component'

export const EnumTargetType = {
  globalUser: 'Global User',
  targetedUser: 'Targeted User',
  newUser: 'New User',
  partnershipPayment: 'Partnership Payment',
}

export const EnumMechanism = {
  loyaltyUser: 'Loyalty User',
}

const initForms = {
  voucher_code: '',
  voucher_image: null,
  benefit_type: {},
  targeting_type: { name: EnumTargetType.globalUser },
  mechanism_type: { name: EnumMechanism.loyaltyUser },
  quota_user: 0,
  targeted_user: [],
  minimum_purchase: 0,
  voucher_amount: 0,
  voucher_percentage: 0,
  discount_type: { name: 'Rp', value: 'Rp' },
  payment_channel_id: '0',
  quota_all: 0,
  is_quota_unlimited: false,
  coin_amount: 0,
  quota_redemption: 0,
  is_unlimited_redemption: false,
}

const LoyaltyVoucherAdd = () => {
  const history = useHistory()
  const location = useLocation()
  const {
    state: { isLoading, detail },
    dispatch,
  } = useContext(storeContext.VoucherContext)
  const {
    dispatch: dispatchRef,
    state: { listSelectedCustomers },
  } = useContext(storeContext.RefferalContext)
  const [forms, setForms] = useState(initForms)
  const [formsPeriode, setFormsPeriode] = useState({})

  useEffect(() => {
    if (detail?.voucher_id) {
      setFormsPeriode({
        date_end: dayjs(detail.voucher_end_at).format('YYYY-MM-DD'),
        date_start: dayjs(detail.voucher_start_at).format('YYYY-MM-DD'),
        time_end: dayjs(detail.voucher_end_at).format('HH:mm'),
        time_start: dayjs(detail.voucher_start_at).format('HH:mm'),
      })
      setForms({
        ...forms,
        voucher_code: detail.voucher_code,
        benefit_type: {
          name: detail.benefit_type,
          icon: detail.voucher_image_url,
        },
        voucher_discount_total: detail.voucher_percentage || detail.voucher_amount,
        discount_type: {
          name: detail.voucher_percentage ? '%' : 'Rp',
          value: detail.voucher_percentage ? '%' : 'Rp',
        },
        targeting_type: { name: EnumTargetType.globalUser },
        mechanism_type: { name: EnumMechanism.loyaltyUser },
        quota_user: detail.quota_user,
        minimum_purchase: detail.minimum_purchase,
        voucher_discount_max: detail.voucher_percentage ? detail.voucher_amount : '',
        payment_channel_id: detail.payment_channel_id || '0',
        is_quota_unlimited: detail.is_quota_unlimited || false,
        quota_all: detail.quota_all,
        coin_amount: detail.coin_amount,
        quota_redemption: detail.quota_redemption,
        is_unlimited_redemption: detail.is_unlimited_redemption,
      })
      const formatedTargeted = detail.targeted_user.map((el) => {
        return { customer_name: el.full_name, customer_id: el.id }
      })
      dispatchRef(setSelectedCustomers(formatedTargeted))
    } else setForms(initForms)
  }, [detail])
  useEffect(() => {
    dispatch(actGetDataType())
    dispatchRef(setFindUser({ number: '' }))
    dispatch(reset())
    if (location.hash) {
      const id = location.hash.split('#')
      dispatch(actHandleGetVoucherData(id[1]))
    }
  }, [])

  useEffect(() => {
    if (forms.targeting_type?.name === EnumTargetType.partnershipPayment) {
      dispatch(actGetPaymentChannel())
    }
  }, [forms])

  //FUNCTION HANDLER
  const handleGoBack = () => {
    history.push(loyaltyVoucher)
  }

  const handleClickCancel = () => {
    setForms(initForms)
    history.push(loyaltyVoucher)
  }
  const handleChangeIcon = (e) => {
    const file = e.target.files[0]
    toBase64(file).then((base64) => {
      setForms({
        ...forms,
        benefit_type: {
          name: forms.benefit_type.name,
          icon: base64,
        },
      })
    })
  }

  const handleSubmit = () => {
    const startTime = new Date(`${formsPeriode.date_start}, ${formsPeriode.time_start}`)
    const endTime = new Date(`${formsPeriode.date_end}, ${formsPeriode.time_end}`)
    const listUser = listSelectedCustomers.map((el) => el.customer_id)
    const quotaAll = Number(forms.quota_all || 0)
    const payload = {
      voucher_code: forms.voucher_code,
      voucher_image: isValidHttpUrl(forms.benefit_type.icon) ? null : forms.benefit_type.icon,
      benefit_type: forms.benefit_type.name,
      targeting_type: EnumTargetType.globalUser,
      mechanism_type: EnumMechanism.loyaltyUser,
      quota_user: Number(forms.quota_user || 0),
      targeted_user: forms.targeting_type.name === 'Targeted User' ? listUser : [],
      minimum_purchase: Number(forms.minimum_purchase || 0),
      voucher_amount: Number(
        (forms.discount_type.value === 'Rp'
          ? forms.voucher_discount_total
          : forms.voucher_discount_max) ||
          forms.max_free_shipping ||
          0,
      ),
      voucher_percentage: Number(
        (forms.discount_type.value === '%' ? forms.voucher_discount_total : 0) || 0,
      ),
      voucher_start_at: startTime.getTime(),
      voucher_end_at: endTime.getTime(),
      payment_channel_id: forms.payment_channel_id,
      quota_all: quotaAll,
      is_quota_unlimited: quotaAll === 0 ? true : forms.is_quota_unlimited,
      coin_amount: Number(forms.coin_amount) ?? 0,
      quota_redemption: forms.quota_redemption ?? 0,
      is_unlimited_redemption: forms.is_unlimited_redemption,
    }

    if (location.hash) {
      const id = location.hash.split('#')
      dispatch(
        actHandleEditVoucher(id[1], payload, () => {
          history.goBack()
        }),
      )
    } else {
      dispatch(
        actHandleAddVoucher(payload, () => {
          history.goBack()
        }),
      )
    }
  }
  const handleUploadCsv = (data) => {
    dispatchRef(setSelectedCustomers(data))
  }

  return (
    <Container>
      <LoadingPage isLoading={isLoading} />
      <Header>
        <span onClick={handleGoBack}>
          <SystemIcon
            iconName="arrow-left"
            colorIcon="textSoft"
            fontSize="headerBigger"
            className="mr-20 pointer"
          />
        </span>
        <Title>{location.hash ? 'Update Promo Voucher' : 'Buat Promo Voucher Baru'}</Title>
      </Header>
      <Content>
        <VoucherDetail
          handleChangeIcon={handleChangeIcon}
          forms={forms}
          setForms={setForms}
          handleUploadCsv={handleUploadCsv}
        />
        <VoucherPeriod forms={formsPeriode} setForms={setFormsPeriode} />
      </Content>
      <ButtonWrapper>
        <Button variant="text" onClick={handleClickCancel}>
          Batal
        </Button>
        <Button
          disabled={
            (forms.voucher_discount_max || forms.max_free_shipping || forms.voucher_discount_total
              ? false
              : true) ||
            !forms.benefit_type.name ||
            !forms.targeting_type.name ||
            !forms.mechanism_type.name ||
            !forms.voucher_code
          }
          onClick={handleSubmit}
        >
          Simpan
        </Button>
      </ButtonWrapper>
    </Container>
  )
}

export default memo(LoyaltyVoucherAdd)

const Container = styled.section`
  padding-bottom: 42px;
`
const Header = styled.div`
  display: flex;
  align-items: center;
`
const Content = styled.div`
  margin-top: 24px;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 12px;
`
