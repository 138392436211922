import { createAsyncThunk } from '@reduxjs/toolkit'

import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'
import { callErrorMsg } from 'helpers/errorMsg'
import { getCleanQueryObj, queryParamsURL } from 'utils/queryParamsURL'
import { toastSuccess } from 'utils/toast'
import {
  GetLoadingDockInterface,
  getLoadingDockListApi,
  LoadingDockRequestParamType,
  GetLocationsType,
  getLocations,
  PostLoadingDockType,
  postLoadingDock,
  deleteLoadingDock,
} from 'features/Enterprise/CapacityHandler/services/loadingDock'

export const SLICE_NAME = 'loadingDock'

type OptionalLoadingDockParamsType =
  | {
      fetchOnly: boolean
    }
  | undefined

export const fetchLoadingDock = createAsyncThunk<
  GetLoadingDockInterface,
  OptionalLoadingDockParamsType,
  RejectValueType
>(`${SLICE_NAME}/fetchLoadingDock`, async (params, { rejectWithValue, getState }) => {
  const { fetchOnly = false } = params || {}
  const { query } = (getState() as StoreStateType).loadingDock
  const apiQuery = getCleanQueryObj(query) as LoadingDockRequestParamType

  try {
    const response = await getLoadingDockListApi(getCleanQueryObj(apiQuery))

    if (!fetchOnly) queryParamsURL.set(apiQuery)
    return response.data
  } catch (error) {
    callErrorMsg(error)
    return rejectWithValue(SOMETHING_WHEN_WRONG)
  }
})

export const fetchGetLocations = createAsyncThunk<GetLocationsType, string, RejectValueType>(
  `${SLICE_NAME}/fetchGetLocations`,
  async (name, { rejectWithValue }) => {
    try {
      const response = await getLocations(name)

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const createLoadingDock = createAsyncThunk(
  `${SLICE_NAME}/createLoadingDock`,
  async (payload: PostLoadingDockType, { dispatch, getState }) => {
    try {
      const {
        loadingDock: { query },
        auth: { userData },
      } = getState() as StoreStateType

      await postLoadingDock({
        ...payload,
        executedBy: userData.email,
      })
      if (query.locationId) dispatch(fetchLoadingDock())
      toastSuccess(`Loading Dock ${payload.name} berhasil ditambahkan.`)
      return Promise.resolve(true)
    } catch (error) {
      callErrorMsg(error)
      return Promise.reject(error)
    }
  },
)

export const removeLoadingDock = createAsyncThunk(
  `${SLICE_NAME}/removeLoadingDock`,
  async (payload: { loadingDockName: string; loadingDockId: number }, { dispatch, getState }) => {
    try {
      const {
        auth: { userData },
      } = getState() as StoreStateType

      await deleteLoadingDock(payload.loadingDockId, userData.email)
      dispatch(fetchLoadingDock())
      toastSuccess(`Loading Dock ${payload.loadingDockName} berhasil dihapus.`)
    } catch (error) {
      callErrorMsg(error)
    }
  },
)
