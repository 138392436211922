import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getFPOTPDetail,
  getFPOTPItem,
  RackListRequestType,
  GetItemRequestType,
  FPOTPPackageIdRequestType,
  FPOTPSubmitRequestType,
  getRackList,
  getStatusList,
  getStatusNotesList,
  getFPOTPPackageId,
  postFPOTPSubmit,
  postFPOTSave,
  deleteFPOTDelete,
  putFPOTUpdateSLOC,
  FPOTPUpdateSLOCRequestType,
} from 'utils/apiList/freshPurchaseOrderTrimmingPackingCheckQty'
import { callErrorMsg } from 'helpers/errorMsg'
import { toastSuccess } from 'utils/toast'

const SLICE_NAME = 'freshPurchaseOrderTrimmingPackingCheckQuantity'

export const fetchFPOTPDetail = createAsyncThunk(
  `${SLICE_NAME}/freshPurchaseOrderTrimmingPackingDetail`,
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const res = await getFPOTPDetail(id)

      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchFPOTPItem = createAsyncThunk(
  `${SLICE_NAME}/freshPurchaseOrderTrimmingPackingItem`,
  async (req: GetItemRequestType, { rejectWithValue }) => {
    try {
      const res = await getFPOTPItem(req)
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchFPOTPPackageId = createAsyncThunk(
  `${SLICE_NAME}/freshPurchaseOrderTrimmingPackingPackageId`,
  async (req: FPOTPPackageIdRequestType, { rejectWithValue }) => {
    try {
      const res = await getFPOTPPackageId(req)
      return res.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateFPOTPSubmit = createAsyncThunk(
  `${SLICE_NAME}/freshPurchaseOrderTrimmingPackingSubmit`,
  async (
    { packing_id, payload }: { payload: FPOTPSubmitRequestType; packing_id: string },
    { rejectWithValue },
  ) => {
    try {
      const res = await postFPOTPSubmit(packing_id, payload)
      toastSuccess(res.data.message)
      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateFPOTPSave = createAsyncThunk(
  `${SLICE_NAME}/freshPurchaseOrderTrimmingPackingSave`,
  async (
    { packing_id, gotoFPOTPList }: { packing_id: string; gotoFPOTPList: () => void },
    { rejectWithValue },
  ) => {
    try {
      const res = await postFPOTSave(packing_id)
      toastSuccess(res.data.message)
      gotoFPOTPList()
      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateFPOTPDelete = createAsyncThunk(
  `${SLICE_NAME}/freshPurchaseOrderTrimmingPackingDelete`,
  async ({ id }: { id: string }, { rejectWithValue }) => {
    try {
      const res = await deleteFPOTDelete(id)
      toastSuccess(res.data.message)
      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateFPOTPSLOC = createAsyncThunk(
  `${SLICE_NAME}/freshPurchaseOrderTrimmingPackingUpdateSLOC`,
  async (req: FPOTPUpdateSLOCRequestType, { rejectWithValue }) => {
    try {
      const res = await putFPOTUpdateSLOC(req)
      toastSuccess(res.data.message)
      return res
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchRackList = createAsyncThunk(
  `${SLICE_NAME}/freshPurchaseOrderTrimmingPackingRackList`,
  async ({ location_id, query }: RackListRequestType, { rejectWithValue }) => {
    try {
      const res = await getRackList({ location_id, query })
      return res.data.racks.content
    } catch (err) {
      callErrorMsg(err)

      return rejectWithValue(err)
    }
  },
)

export const fetchStatusList = createAsyncThunk(
  `${SLICE_NAME}/freshPurchaseOrderTrimmingPackingStatusList`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getStatusList()
      return res.data.data.status
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchStatusNoteList = createAsyncThunk(
  `${SLICE_NAME}/freshPurchaseOrderTrimmingPackingStatusNoteList`,
  async (id: string, { rejectWithValue }) => {
    try {
      const res = await getStatusNotesList(id)
      return res.data.data.notes
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
