import apiServices from 'config/apiServiceApiGateway'

const { Get, Put } = apiServices

export type GetInventoryListByIdRequestType = {
  id: string
  params: {
    page_size: number
    page_index: number
    location_id: number
    name: string
  }
}

export type GetInventoryListByIdResponseType = {
  data: {
    content: {
      campaign_price_details: {
        user_segment: string
        campaign_inventory_id: number
        discounted_price: number
        daily_quota: number
        segment_data: {
          segment_name: string
          segment_id: number
        }
        discount_percentage: number
        discount_quota: number
        astro_percentage: number
        tier: {
          discounted_price: number
          discount_percentage: number
          threshold: number
        }[]
      }[]
      original_price: number
      product_name: string
      location_id: number
      product_stock: number
      product_id: number
      location_name: string
      product_sku_number: string
      astro_percentage: number
    }[]
    pagination: {
      direction: string
      number_of_elements: number
      sort: string
      page_size: number
      page_index: number
      total_pages: number
      total_elements: number
    }
  }
}

export const getInventoryListById = ({ id, params }: GetInventoryListByIdRequestType) =>
  Get<GetInventoryListByIdResponseType, true>({
    url: `/campaign/internal/v1/${id}/inventory`,
    params,
  })

export type PutInventoryDiscountByIdRequestType = {
  id: string
  payload: {
    discounted_price: number
    discount_percentage: number
    discount_stock: number
    daily_quota: number
    user_segment: string
    astro_percentage: number
    segment_id: number
    segment_name: string
  }
}

export const putInventoryDiscountById = ({ id, payload }: PutInventoryDiscountByIdRequestType) =>
  Put({
    url: `/campaign/internal/v1/${id}/inventory`,
    data: payload,
  })

export type PutInventoryBMSMByIdRequestType = {
  id: string
  data: {
    discounted_price: number
    discount_percentage: number
    discount_stock: number
    daily_quota: number
    user_segment: 'NORMAL_USER' // BMSM doesn't support segmentation, only NORMAL_USER
    astro_percentage: number
    tier: {
      threshold: number
      discount_percentage: number
      discounted_price: number
    }[]
    segment_id: 0 // BMSM doesn't support segmentation
    segment_name: '' // BMSM doesn't support segmentation
    campaign_type: string
  }
}
export const putInventoryBMSMById = ({ id, data }: PutInventoryBMSMByIdRequestType) =>
  Put({
    url: `/campaign/internal/v1/${id}/inventory`,
    data,
  })
