import AxiosInstance from 'config/apiServiceApiGateway'

import {
  AssetPurchaseOrderParamsInterface,
  AssetPurchaseOrderResponseInterface,
  AssetPurchaseOrderDetailResponseInterface,
  UpdatePurchaseOrderParamsInterface,
  UpdatePurchaseOrderResponseInterface,
} from 'features/AssetManagement/@types/typePurchaseOrder'

export const getPurchaseOrderListAPI = (query: AssetPurchaseOrderParamsInterface) =>
  AxiosInstance.Get<AssetPurchaseOrderResponseInterface, true>({
    url: '/ams/internal/v1/po/list',
    params: query,
  })

export const getPurchaseOrderDetailAPI = (id: number) =>
  AxiosInstance.Get<AssetPurchaseOrderDetailResponseInterface, true>({
    url: `/ams/internal/v1/po/detail/${id}`,
  })

export const postUpdatePurchaseOrderAPI = (param: UpdatePurchaseOrderParamsInterface) =>
  AxiosInstance.Post<UpdatePurchaseOrderResponseInterface, true>({
    url: `/ams/internal/v1/po/update`,
    data: param,
  })
