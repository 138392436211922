import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { FPOTPDetailResponseType } from 'utils/apiList/freshPurchaseOrderTrimmingPackingCheckQty'
import {
  fetchFPOTPDetail,
  fetchRackList,
  fetchStatusList,
  fetchStatusNoteList,
  fetchFPOTPItem,
  fetchFPOTPPackageId,
  updateFPOTPSubmit,
  updateFPOTPSave,
  updateFPOTPDelete,
  updateFPOTPSLOC,
} from './freshPurchaseOrderTrimmingPackingCheckQuantityThunk'

const SLICE_NAME = 'freshPurchaseOrderTrimmingPackingCheckQuantity'

export interface RackListStateType {
  id: number
  name: string
}

export interface FormInputQuantityStateType {
  sku: string
  searchSku: string
  searchPackageId: string
  packageId: {
    id: number
    packageLabel: string | number
    createdAt: number
  }
  receivedQty: number
  packedQty: number
  uom: string
  expiredDate: number
  product: {
    id: number
    name: string
    sku: string
    weight: number
    age: number
    baseUom: string[]
  }
  rack: {
    id: number
    name: string
  }
  status: {
    id: number
    name: string
  }
  statusNote: {
    id: number
    name: string
  }
  warning: {
    packageId: boolean
    items: boolean
  }
}

export interface FormPrintQRStateType {
  printQty: number
  product: {
    name: string
    id: number
  }
  expiryDate: number
}

export interface FormPrintBarcodeStateType {
  printQTY: number
  expiryDate: number | null
  product: {
    name: string
    sku: string | number
  }
}
export interface StatusListStateType {
  id: number
  name: string
}

export interface StatusNotesListStateType {
  id: number
  name: string
}

export interface FPOTPQueryStateType {
  sku: string
}
export interface FreshPurchaseOrderTrimmingPackingCheckQuantityType {
  isLoading: boolean
  query: FPOTPQueryStateType
  isShowPopupConfirmation: boolean
  isShowPopupPrintQR: boolean
  isShowPopupPrintBarcode: boolean
  needToReload: number
  rackList: RackListStateType[]
  statusList: StatusListStateType[]
  statusNotesList: StatusNotesListStateType[]
  fPOTPDetail: FPOTPDetailResponseType
  formInputQuantity: FormInputQuantityStateType
  formPrintQR: FormPrintQRStateType
  formPrintBarcode: FormPrintBarcodeStateType
}

const initialState: FreshPurchaseOrderTrimmingPackingCheckQuantityType = {
  isLoading: false,
  query: { sku: '' },
  isShowPopupConfirmation: false,
  isShowPopupPrintQR: false,
  isShowPopupPrintBarcode: false,
  needToReload: 1,
  rackList: [{ id: 0, name: '' }],
  statusList: [{ id: 0, name: '' }],
  statusNotesList: [{ id: 0, name: '' }],
  formInputQuantity: {
    packageId: {
      id: 0,
      packageLabel: '',
      createdAt: 0,
    },
    receivedQty: 0,
    packedQty: 0,
    sku: '',
    searchPackageId: '',
    searchSku: '',
    uom: '',
    expiredDate: 0,
    rack: { id: 0, name: '' },
    product: { age: 0, baseUom: [''], id: 0, name: '', sku: '', weight: 0 },
    status: {
      id: 0,
      name: '',
    },
    statusNote: {
      id: 0,
      name: '',
    },
    warning: {
      items: false,
      packageId: false,
    },
  },
  fPOTPDetail: {
    id: 0,
    packingNumber: '',
    inboundNumber: '',
    fpoNumber: '',
    location: {
      locationId: 0,
      locationName: '',
      locationType: '',
    },
    status: '',
    createdAt: 0,
    createdBy: '',
    updatedAt: 0,
    updatedBy: '',
    items: [],
  },
  formPrintQR: {
    printQty: 0,
    product: {
      name: '',
      id: 0,
    },
    expiryDate: 0,
  },
  formPrintBarcode: {
    printQTY: 0,
    expiryDate: null,
    product: {
      name: '',
      sku: '',
    },
  },
}

const freshPurchaseOrderTrimmingPackingCheckQuantity = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setQuery: (
      state,
      action: PayloadAction<{
        fieldName: keyof FPOTPQueryStateType
        value: string
      }>,
    ) => {
      const { fieldName, value } = action.payload
      state.query[fieldName] = value as never
    },
    setIsShowPopupConfirmation: (state, action: PayloadAction<{ isShow: boolean }>) => {
      state.isShowPopupConfirmation = action.payload.isShow
    },
    setIsShowPopupPrintQR: (state, action: PayloadAction<{ isShow: boolean }>) => {
      state.isShowPopupPrintQR = action.payload.isShow
    },
    setIsShowPopupPrintBarcode: (state, action: PayloadAction<{ isShow: boolean }>) => {
      state.isShowPopupPrintBarcode = action.payload.isShow
    },
    setFormPrintQRasObj: (state, action: PayloadAction<FormPrintQRStateType>) => {
      state.formPrintQR = action.payload
    },
    setFormPrintQR: (
      state,
      action: PayloadAction<{
        fieldName: keyof FormPrintQRStateType
        value: string | number | FormPrintQRStateType['product']
      }>,
    ) => {
      const { fieldName, value } = action.payload
      state.formPrintQR[fieldName] = value as never
    },
    setFormPrintBarcode: (
      state,
      action: PayloadAction<{
        fieldName: keyof FormPrintBarcodeStateType
        value: string | number
      }>,
    ) => {
      const { fieldName, value } = action.payload
      state.formPrintBarcode[fieldName] = value as never
    },
    setFormPrintBarcodeAsObj: (state, action: PayloadAction<FormPrintBarcodeStateType>) => {
      state.formPrintBarcode = { ...state.formPrintBarcode, ...action.payload }
    },
    setFomInputQuantity: (
      state,
      action: PayloadAction<{
        fieldName: keyof FormInputQuantityStateType
        value:
          | string
          | number
          | FormInputQuantityStateType['status']
          | FormInputQuantityStateType['statusNote']
          | FormInputQuantityStateType['rack']
      }>,
    ) => {
      const { fieldName, value } = action.payload
      state.formInputQuantity[fieldName] = value as never
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFPOTPDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFPOTPDetail.fulfilled, (state, action) => {
        state.isLoading = false
        const { payload } = action

        const modifiedPayload = {
          ...payload,
          items: payload.items.map((el) => ({
            ...el,
            uniqueKey: window.crypto.randomUUID(),
            itemDetails: el.itemDetails.map((ell) => ({
              ...ell,
              uniqueKey: window.crypto.randomUUID(),
            })),
          })),
        }

        state.fPOTPDetail = modifiedPayload
      })
      .addCase(fetchFPOTPDetail.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchFPOTPItem.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFPOTPItem.fulfilled, (state, action) => {
        const { expiredDate, product, qty, rackId, rackName, sku, uom } = action.payload

        state.isLoading = false
        state.formInputQuantity.warning.items = false

        state.formInputQuantity = {
          ...state.formInputQuantity,
          expiredDate,
          product,
          packedQty: qty,
          rack: {
            id: rackId,
            name: rackName,
          },
          sku,
          uom,
        }
      })
      .addCase(fetchFPOTPItem.rejected, (state) => {
        state.isLoading = false
        state.formInputQuantity.product = initialState.formInputQuantity.product
        state.formInputQuantity.warning.items = true
      })
      .addCase(fetchFPOTPPackageId.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchFPOTPPackageId.fulfilled, (state, action) => {
        state.isLoading = false
        state.formInputQuantity.warning.packageId = false
        state.formInputQuantity.packageId = action.payload
      })
      .addCase(fetchFPOTPPackageId.rejected, (state) => {
        state.isLoading = false
        state.formInputQuantity.warning.packageId = true
      })
      .addCase(updateFPOTPSubmit.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateFPOTPSubmit.fulfilled, (state) => {
        state.isLoading = false
        state.formInputQuantity = initialState.formInputQuantity
        state.needToReload += 1
      })
      .addCase(updateFPOTPSubmit.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateFPOTPSave.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateFPOTPSave.fulfilled, (state) => {
        state.isShowPopupConfirmation = false
        state.isLoading = false
      })
      .addCase(updateFPOTPSave.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateFPOTPDelete.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateFPOTPDelete.fulfilled, (state) => {
        state.isLoading = false
        state.needToReload += 1
      })
      .addCase(updateFPOTPDelete.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateFPOTPSLOC.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateFPOTPSLOC.fulfilled, (state) => {
        state.isLoading = false
        state.needToReload += 2
      })
      .addCase(updateFPOTPSLOC.rejected, (state) => {
        state.isLoading = false
      })

      .addCase(fetchRackList.fulfilled, (state, action) => {
        state.rackList = action.payload.map((el) => ({ id: el.id, name: el.rack_name }))
      })
      .addCase(fetchStatusList.fulfilled, (state, action) => {
        const { payload } = action

        state.statusList = payload.map((el) => ({ id: el.id, name: el.displayName }))
        state.formInputQuantity.status = { id: payload[0].id, name: payload[0].displayName }
      })
      .addCase(fetchStatusNoteList.fulfilled, (state, action) => {
        state.statusNotesList = action.payload.map((el) => ({ id: el.id, name: el.displayName }))
      })
  },
})

export default freshPurchaseOrderTrimmingPackingCheckQuantity.reducer
export const {
  reset,
  setQuery,
  setFomInputQuantity,
  setIsShowPopupConfirmation,
  setFormPrintQR,
  setFormPrintQRasObj,
  setFormPrintBarcode,
  setFormPrintBarcodeAsObj,
  setIsShowPopupPrintQR,
  setIsShowPopupPrintBarcode,
} = freshPurchaseOrderTrimmingPackingCheckQuantity.actions
