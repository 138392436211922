import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  type SLOCListType,
  type StockOpnameAddStateType,
  type ProductInventoryType,
  StockOpnameTypeEnum,
} from 'features/StockOpname/@types/StockOpnameAdd'

import {
  fetchHubLocationsList,
  fetchWarehouseLocationsList,
  fetchSLOCRackEnvironment,
  fetchSLOCRackLocation,
  fetchSLOCRackList,
  postAddSloc,
  fetchProductType,
  fetchProductCategoryL1,
  fetchProductInventories,
} from './stockOpnameAddThunk'

export const initialState: StockOpnameAddStateType = {
  query: { locationId: 0, locationName: ``, showSelectSLOC: false, showSelectSKU: false },
  previousLocationId: 0,
  submitParam: {
    date: ``,
    taskGenerated: 0,
    SLOCConfiguration: ``,
    locationType: ``,
    time: ``,
    rackStorageType: [],
    productTypes: [],
    selectedType: StockOpnameTypeEnum.SLOC,
  },
  selectedSlocList: [],
  locationList: [],
  rackEnvironment: [],
  rackLocation: {
    listData: [],
    previousDataList: [],
    selectedDataList: [],
  },
  productType: {
    listData: [],
    selectedDataList: [],
  },
  productCategory: {
    listData: [],
    selectedDataList: [],
  },
  slocList: [],
  slocListPaginationData: { totalElement: 0, pageSize: 0 },
  isLoading: false,
  product: {
    productList: [],
    selectedProducts: [],
    isLoading: false,
    query: {
      page_index: 1,
      page_size: 20,
    },
  },
}

const stockOpnameAdd = createSlice({
  name: 'stockOpnameAdd',
  initialState,
  reducers: {
    resetState: () => initialState,
    setQuery: (
      state,
      action: PayloadAction<{
        fieldName: keyof StockOpnameAddStateType['query']
        value: string | number | boolean
      }>,
    ) => {
      const { fieldName, value } = action.payload
      state.query[fieldName] = value as never
    },
    setPreviousLocationId: (state, action) => {
      state.previousLocationId = action.payload
    },
    setSelectedSLOCList: (state, action: PayloadAction<SLOCListType[]>) => {
      const selectedList = action.payload
      state.selectedSlocList = selectedList
    },
    setSubmitParam: (
      state,
      action: PayloadAction<{
        fieldName: keyof StockOpnameAddStateType['submitParam']
        value: StockOpnameAddStateType['submitParam'][keyof StockOpnameAddStateType['submitParam']]
      }>,
    ) => {
      const { fieldName, value } = action.payload
      state.submitParam[fieldName] = value as never
    },
    setRackLocation: (state, action) => {
      state.rackLocation = { ...state.rackLocation, ...action.payload }
    },
    setProductType: (state, action) => {
      state.productType = { ...state.productType, ...action.payload }
    },
    setProductCategory: (state, action) => {
      state.productCategory = { ...state.productCategory, ...action.payload }
    },
    setSelectedProductList: (state, { payload }: PayloadAction<ProductInventoryType[]>) => {
      state.product.selectedProducts = [...payload]
    },
    setProductQuery: (
      state,
      { payload }: PayloadAction<StockOpnameAddStateType['product']['query']>,
    ) => {
      state.product.query = { ...state.product.query, ...payload }
    },
    resetProductList: (state) => {
      state.product.productList = []
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchWarehouseLocationsList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchWarehouseLocationsList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchWarehouseLocationsList.fulfilled, (state, action) => {
        state.locationList = action.payload.map((el) => ({ ...el, name: el.location_name }))
      })
      .addCase(fetchHubLocationsList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchHubLocationsList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchHubLocationsList.fulfilled, (state, action) => {
        state.locationList = action.payload.map((el) => ({ ...el, name: el.location_name }))
      })
      .addCase(fetchSLOCRackEnvironment.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchSLOCRackEnvironment.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchSLOCRackEnvironment.fulfilled, (state, action) => {
        state.rackEnvironment = action.payload.data.content
      })
      .addCase(fetchSLOCRackLocation.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchSLOCRackLocation.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchSLOCRackLocation.fulfilled, (state, action) => {
        state.rackLocation.listData = action.payload.data.content
      })
      .addCase(fetchSLOCRackList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchSLOCRackList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchSLOCRackList.fulfilled, (state, action) => {
        if (action.payload) {
          state.slocList = action.payload.data
          state.slocListPaginationData = {
            totalElement: action.payload.pagination.numberOfElements,
            pageSize: action.payload.pagination.pageSize,
          }
        }
      })
      .addCase(postAddSloc.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(postAddSloc.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchProductType.fulfilled, (state, action) => {
        state.productType.listData = action.payload
      })
      .addCase(fetchProductCategoryL1.fulfilled, (state, action) => {
        state.productCategory.listData = action.payload
      })
      .addCase(fetchProductInventories.pending, (state) => {
        state.product.isLoading = true
      })
      .addCase(fetchProductInventories.fulfilled, (state, action) => {
        const { data, pagination } = action.payload

        state.product.productList = [...data]
        state.product.query = {
          page_index: pagination?.page_index as number,
          page_size: pagination?.page_size as number,
        }
        state.product.isLoading = false
      })
      .addCase(fetchProductInventories.rejected, (state) => {
        state.product.isLoading = false
      })
  },
})

export const {
  setQuery,
  setSelectedSLOCList,
  setPreviousLocationId,
  setSubmitParam,
  resetState,
  setRackLocation,
  setProductType,
  setProductCategory,
  setSelectedProductList,
  setProductQuery,
  resetProductList,
} = stockOpnameAdd.actions
export default stockOpnameAdd.reducer
