export const hubPageLocationType = [
  'HUB',
  'VENDING MACHINE',
  'MEGAHUB',
  'MEGAHUB 2.0',
  'DIGITAL GOODS',
  'VIRTUAL',
  'ASTRO OFFLINE',
]
export const warehousePageLocationType = ['WAREHOUSE']

export const locationTypeOptions = [
  { location_type: `ASTRO OFFLINE`, value: `astro offline` },
  { location_type: `HUB`, value: `hub` },
  { location_type: `MEGA HUB`, value: `megahub` },
  { location_type: `MEGA HUB 2.0`, value: `megahub 2.0` },
  { location_type: `OFFICE`, value: `office` },
  { location_type: `WAREHOUSE`, value: `warehouse` },
  { location_type: `VENDING MACHINE`, value: `vending machine` },
  { location_type: `VIRTUAL`, value: `virtual` },
]
