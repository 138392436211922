function isString(str) {
    return typeof str === 'string' || str instanceof String;

}

function strLimit(str, limit, dots) {
    if (!isString(str)) {
        return ''
    }

    if (!str) return str;

    if (!limit) {
        limit = 100;
    }

    if (!dots && dots !== '') {
        dots = '..'
    }

    if(str.length > limit) str = str.substring(0,limit) + dots;

    return str
}


 function getInitialName(string) {
    let names = string.split(' '),
        initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
}




export {
    strLimit,
    getInitialName
}
