import { memo, useState, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { purchaseOrderDetail } from "config/routes";
import { mapStatus } from "helpers";
import { SystemIcon, Tag, Button } from "components";
import listenClickOutside from "customHooks/outSideClick";
import dayjs from "dayjs";

const lisOption = [
  {
    name: "Closed",
    id: "sd"
  },
  {
    name: "Req Cancel",
    id: "sds"
  },
  {
    name: "Cetak",
    id: "sdss"
  },
  {
    name: "GRN",
    id: "sdaass"
  }
];

function RowPurchaseOrder({ handleClickOption, data }) {
  const refOutside = useRef(null);
  const [isShow, setIsShow] = useState(false);
  listenClickOutside(
    refOutside,
    () => {
      setIsShow(false);
    },
    isShow
  );
  const handleClickOpt = (opt) => {
    handleClickOption(opt, data);
    setIsShow(false);
  };
  return (
    <Tr>
      <Td>
        <Link to={`${purchaseOrderDetail}?id=${data.id}`}>{data.numberPo}</Link>
      </Td>
      <Td>{data.netsuiteId}</Td>
      <Td>{data.vendorName}</Td>
      <Td>{dayjs(data.createdAt).format("DD MMM YYYY")}</Td>
      <Td>{data.quantity}</Td>
      <Td>{data.totalQuantity}</Td>
      <Td>
        <Tag color={mapStatus(data.status)}> {data.status}</Tag>
      </Td>
      <Td>
        <IconWrapper>
          <Button variant="wrapper" onClick={() => setIsShow(!isShow)}>
            <SystemIcon
              iconName="threeDots"
              colorIcon="textSoft"
              fontSize="header"
            />
          </Button>
        </IconWrapper>
        {isShow && (
          <Options ref={refOutside}>
            {lisOption.map((el) => (
              <Button
                variant="wrapper"
                key={el.id}
                onClick={() => handleClickOpt(el)}
              >
                <Option>{el.name}</Option>
              </Button>
            ))}
          </Options>
        )}
      </Td>
    </Tr>
  );
}

RowPurchaseOrder.defaultProps = {
  handleClickOption: () => {}
};

export default memo(RowPurchaseOrder);

const Tr = styled.tr`
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
  position: relative;
`;
const Td = styled.td`
  color: ${({ theme: { colors }, color = "textSoft" }) => colors[color]};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  padding: 16px 32px;
  min-width: ${({ min }) => min};
`;

const IconWrapper = styled.div`
  transform: rotate(90deg);
`;

const Options = styled.div`
  min-width: 135px;
  box-shadow: 2px 4px 20px 0px rgba(229, 236, 240, 1);
  position: absolute;
  right: 0;
  top: 50px;
  z-index: 1;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme: { colors } }) => colors.white};
  ::before {
    content: "";
    height: 20px;
    width: 20px;
    border-radius: 3px;
    background-color: ${({ theme: { colors } }) => colors.white};
    position: absolute;
    transform: rotate(45deg);
    right: 40px;
    top: -7px;
  }
`;

const Option = styled.div`
  padding: 12px;
  min-width: 135px;
  text-align: left;
  color: ${({ theme: { colors } }) => colors.textSoft};
  transition: all ease 0.3s;
  position: relative;
  z-index: 1;
  :hover {
    background-color: ${({ theme: { colors } }) => colors.bgIcon};
  }
`;
