import Cookies from 'js-cookie'

const cookiesTokenName = 'zvdgxvzb'

export const getCookies = (key: string) => Cookies.get(key)

export const setCookies = (key: string, payload: string) => {
  // validate payload when undefined & null set to empty string
  // if key falsy cookies will not be setted
  if (key) {
    if (payload) {
      Cookies.set(key, payload)
    } else {
      Cookies.set(key, '')
    }
  }
}

export const removeCookies = (key: string) => {
  Cookies.remove(key)
}

export const setTokenCookies = (payload: string) => {
  // validate payload when server send undefined & null cookies
  if (payload) {
    setCookies(cookiesTokenName, payload)
  }
}

export const getTokenCookies = () => getCookies(cookiesTokenName)

export const removeTokenCookies = () => removeCookies(cookiesTokenName)
