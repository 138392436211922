import { memo } from "react";
import styled from "styled-components";
import { SystemIcon, RowOrderPickerCancelled, NoData } from "components";

function TableOrderPickerCancelled({
  data,
  handleDetailPicker,
  handleReturnPicker,
}) {
  return (
    <>
      <Container>
        <thead>
          <Tr>
            <Th>
              ORDER ID
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th>
              ORDER CREATED TIME
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th>
              BUYER
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th>
              QTY
              <SystemIcon
                iconName="up-and-down"
                fontSize="smallest"
                className="ml-3"
              />
            </Th>
            <Th>AKSI</Th>
          </Tr>
        </thead>
        <tbody>
          {data.map((el) => (
            <RowOrderPickerCancelled
              key={el.order_id}
              data={el}
              handleDetailPicker={handleDetailPicker}
              handleReturnPicker={handleReturnPicker}
            />
          ))}
        </tbody>
      </Container>
      {data.length === 0 && <NoData />}
    </>
  );
}

TableOrderPickerCancelled.defaultProps = {
  data: [],
};

export default memo(TableOrderPickerCancelled);

const Container = styled.table`
  width: 100%;
  border-collapse: collapse;
  height: fit-content;
`;
const Tr = styled.tr`
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`;
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  text-align: start;
  padding: 16px 32px;
`;
