import { Box, Icon, IconButton } from '@astro-ui/components'

export type ResetIconType = {
  show: boolean
  onClick?: () => void
}

const ResetIcon = ({ show, onClick }: ResetIconType) => (
  <Box display={show ? 'block' : 'none'} position="absolute" right="36px">
    <IconButton onClick={onClick} bg="grey4" p="1px" transform="rotate(45deg)">
      <Icon icon="plus" color="white" />
    </IconButton>
  </Box>
)

export default ResetIcon
