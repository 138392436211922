import styled, { css } from 'styled-components'
import { layout, space, LayoutProps, SpaceProps } from 'styled-system'

export type RowPropsType = SpaceProps &
  LayoutProps & {
    gap?: number
  }

const Row = styled.div<RowPropsType>`
  ${layout}
  ${space}
  
  ${({ gap }) => css`
    margin-left: ${gap ? `-${gap}px` : '-2px'};
    margin-right: ${gap ? `-${gap}px` : '-2px'};

    > div {
      padding: ${`${gap}px`};
    }
  `}

  &::after {
    content: '';
    clear: both;
    display: table;
  }
`

export default Row
