import { memo, useContext } from 'react'
import styled from 'styled-components'
import store from 'storeContext'
import PrintLogo from 'assets/images/astro-goods.svg'
import PrintCaption from 'assets/images/relabel-caption.svg'
import { Typography } from '@astro-ui/components'

function TemplateRelabelAstroGoods() {
  const { state: statePrintArea } = useContext(store.PrintAreaContext)
  const relabel = statePrintArea.dataRelabel
  const pages = [...Array(relabel.qty).keys()]

  if (Object.keys(relabel).length < 1) {
    return null
  }

  return (
    <>
      {pages.map((page, index) => (
        <Container key={`${page}-${index}`}>
          <Header>
            <img src={PrintLogo} alt="Astro Goods" />
            <Tagline>
              <img src={PrintCaption} alt="Print Caption" />
            </Tagline>
          </Header>
          <DateInformation>
            <Label>
              <Typography variant="smallRegular" fontWeight="500">
                PRODUCTION DATE
              </Typography>
            </Label>
            <Divider>:</Divider>
            <Typography variant="smallRegular" fontWeight="600">
              {relabel.productionDate}
            </Typography>
          </DateInformation>
          <DateInformation>
            <Label>
              <Typography variant="smallRegular" fontWeight="500">
                BEST BEFORE
              </Typography>
            </Label>
            <Divider>:</Divider>
            <Typography variant="smallRegular" fontWeight="600">
              {relabel.bestBeforeDate}
            </Typography>
          </DateInformation>
        </Container>
      ))}
    </>
  )
}

export default memo(TemplateRelabelAstroGoods)

const Container = styled.div`
  @media print {
    display: flex;
    flex-direction: column;
    gap: 8px;
    @page {
      size: 101.6mm 101.6mm;
      margin: 2rem;
    }
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 32px;
`

const Tagline = styled.div`
  margin-top: 1rem;
`

const DateInformation = styled.div`
  border: 1px solid #212121;
  border-radius: 6px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
`

const Label = styled.div`
  width: 120px;
`
const Divider = styled.div`
  margin-left: 8px;
  margin-right: 24px;
`
