import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'

import { callErrorMsg } from 'helpers/errorMsg'
import { getAlllocation } from 'utils/api'
import { dateFormat, dateManipulation } from 'utils/helpers/date'
import { toastSuccess } from 'utils/toast'
import {
  getMasterApproval,
  putUpdateMasterApproval,
  postBulkUpdateMasterApproval,
  fetchAdminDriver,
  type GetMasterApprovalListServicesInterface,
  type QueryParamsInterface,
  getDepartementList,
  type GetAllDepartementProcessedType,
} from 'features/Enterprise/Schedules/services'

export interface MasterApprovalInitialStateInterface {
  isLoading: boolean
  locationList: {
    location_id: number
    location_name: string
    location_type: string
  }[]
  selectedHub: MasterApprovalInitialStateInterface['locationList'][0] | ObjectNullType
  masterApprovalList: GetMasterApprovalListServicesInterface['data']
  approvalDetail: GetMasterApprovalListServicesInterface['data'][0] | ObjectNullType
  query: QueryParamsInterface
  popUpState: {
    isShow: boolean
    isBulkApproval?: boolean
    type: 'APPROVE' | 'REJECT' | 'CANCEL' | 'UBAH'
    data?: {
      approvalId: number
      requestBy: string
      staffId: number
      staffName: string
      reasonNotes: string
    }
    firstCheckIssueType: number
  }
  bulkApprovalIds: number[]
  redirect: {
    moduleId: number
    module: string
    type: string
    shiftDescription?: string
    startDate?: string
  }
  pagination: GetMasterApprovalListServicesInterface['pagination']
  showModalDetail: boolean
  departmentLists: GetAllDepartementProcessedType[]
  selectedDepartment: string[]
}

const initialState: MasterApprovalInitialStateInterface = {
  isLoading: false,
  locationList: [],
  selectedHub: {
    location_id: 0,
    location_name: 'All Location',
    location_type: 'Hub',
  },
  masterApprovalList: [],
  approvalDetail: {},
  query: {
    location_id: '0',
    status: '0',
    search: '',
    sort: 'created_at',
    start_date: dateFormat({
      date: dateManipulation(new Date()).subtract(1, 'month'),
      format: 'YYYYMMDD',
    }),
    end_date: dateFormat({
      date: new Date() as Date,
      format: 'YYYYMMDD',
    }),
    direction: 'desc',
    page_size: 10,
    page_index: 1,
    issue: '-1',
    requested_by: '',
    department: '',
  },
  popUpState: {
    isShow: false,
    type: 'APPROVE',
    data: {
      approvalId: 0,
      requestBy: '',
      staffId: 0,
      staffName: '',
      reasonNotes: '',
    },
    firstCheckIssueType: 0,
  },
  bulkApprovalIds: [],
  redirect: {
    moduleId: 0,
    module: '',
    type: '',
    shiftDescription: '',
    startDate: '',
  },
  pagination: {
    number_of_elements: 0,
    page_index: 0,
    page_size: 0,
    total_pages: 0,
    total_element: 0,
  },
  showModalDetail: false,
  departmentLists: [],
  selectedDepartment: [],
}

export const sliceName = 'masterApproval'

const appSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    reset: () => initialState,
    setLoading: (
      state,
      action: PayloadAction<MasterApprovalInitialStateInterface['isLoading']>,
    ) => {
      state.isLoading = action.payload
    },
    setLocationList: (
      state,
      action: PayloadAction<MasterApprovalInitialStateInterface['locationList']>,
    ) => {
      state.locationList = action.payload
    },
    setSelectedHub: (
      state,
      action: PayloadAction<MasterApprovalInitialStateInterface['selectedHub']>,
    ) => {
      state.selectedHub = action.payload
    },
    setQuery: (state, action: PayloadAction<MasterApprovalInitialStateInterface['query']>) => {
      state.query = action.payload
    },
    setMasterApprovalList: (
      state,
      action: PayloadAction<GetMasterApprovalListServicesInterface>,
    ) => {
      state.masterApprovalList = action.payload.data
      state.pagination = action.payload.pagination
    },
    setShowPopUp: (
      state,
      action: PayloadAction<MasterApprovalInitialStateInterface['popUpState']>,
    ) => {
      state.popUpState = action.payload
    },
    setRedirect: (
      state,
      action: PayloadAction<MasterApprovalInitialStateInterface['redirect']>,
    ) => {
      state.redirect = action.payload
    },
    setBulkApprovalIds: (state, action: PayloadAction<number[]>) => {
      state.bulkApprovalIds = action.payload
    },
    setApprovalDetail: (
      state,
      action: PayloadAction<{
        detail: MasterApprovalInitialStateInterface['approvalDetail']
        showModal: boolean
      }>,
    ) => {
      state.approvalDetail = action.payload.detail
      state.showModalDetail = action.payload.showModal
    },
    setDepartmentList: (
      state,
      action: PayloadAction<MasterApprovalInitialStateInterface['departmentLists']>,
    ) => {
      state.departmentLists = action.payload
    },
    setSelectedDepartment: (
      state,
      action: PayloadAction<MasterApprovalInitialStateInterface['selectedDepartment']>,
    ) => {
      state.selectedDepartment = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMasterApprovalList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getMasterApprovalList.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(getMasterApprovalList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateApproval.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateApproval.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(updateApproval.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateBulkApproval.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateBulkApproval.fulfilled, (state) => {
        state.isLoading = false
        state.bulkApprovalIds = []
      })
      .addCase(updateBulkApproval.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const {
  setLocationList,
  setLoading,
  setSelectedHub,
  setMasterApprovalList,
  setQuery,
  reset,
  setShowPopUp,
  setRedirect,
  setBulkApprovalIds,
  setApprovalDetail,
  setDepartmentList,
  setSelectedDepartment,
} = appSlice.actions

export default appSlice.reducer

export const getAllLocations = createAsyncThunk(
  `${sliceName}/getLocations`,
  async (_, { dispatch }) => {
    try {
      const { data } = await getAlllocation()
      if (data?.length) {
        dispatch(
          setLocationList([
            { location_id: 0, location_name: 'All Location', location_type: 'hub' },
            ...data,
          ]),
        )
      }
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const getMasterApprovalList = createAsyncThunk(
  `${sliceName}/getMasterApprovalList`,
  async (_, { getState, dispatch }) => {
    try {
      const {
        masterApproval: { query },
      } = getState() as StoreStateType

      const { data } = await getMasterApproval(query)

      dispatch(
        setMasterApprovalList({
          data: data.data,
          pagination: data.pagination,
        } as unknown as GetMasterApprovalListServicesInterface),
      )
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const updateApproval = createAsyncThunk(
  `${sliceName}/updateApproval`,
  async ({
    approvalId,
    status,
    statusNote,
    updatedBy,
  }: {
    approvalId: number
    status: number
    statusNote?: string
    updatedBy: string
  }) => {
    try {
      await putUpdateMasterApproval({ status, statusNote, updatedBy }, approvalId)
      let statusMsg = 'Approval berhasil diterima'
      if (status === 3) statusMsg = 'Approval ditolak'
      else if (status === 4) statusMsg = 'Approval berhasil dibatalkan'
      toastSuccess(statusMsg)
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const updateBulkApproval = createAsyncThunk(
  `${sliceName}/updateBulkApproval`,
  async ({
    ids,
    status,
    statusNote,
    updatedBy,
  }: {
    ids: number[]
    status: number
    statusNote?: string
    updatedBy: string
  }) => {
    try {
      await postBulkUpdateMasterApproval({ status, statusNote, ids, updatedBy })
      let statusMsg = 'Approval berhasil diterima'
      if (status === 3) statusMsg = 'Approval ditolak'
      else if (status === 4) statusMsg = 'Approval berhasil dibatalkan'
      toastSuccess(statusMsg)
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const getDriverAdmin = createAsyncThunk(
  `${sliceName}/getDriverAdmin`,
  async (id: number) => {
    try {
      const { data } = await fetchAdminDriver(id)
      const driverData = data.data
      return {
        driver_location_id: driverData.driver_location_id,
        driver_card_number: driverData.driver_card_number,
        driver_plate_number: driverData.driver_plate_number,
        driver_phone_number: driverData.driver_phone_number,
        role: driverData.role.map((x) => x.authority_role),
        driver_full_name: driverData.driver_full_name,
        employment_type: driverData.employment_type,
        location_type: driverData.driver_location_type,
        vehicle_type: driverData.vehicle?.type,
        vehicle_sub_type: driverData.vehicle?.sub_type,
        tracker_id: driverData.tracker_id,
        staff_vendor_id: driverData.vendor,
        bank_name: driverData.bank_name,
        account_name: driverData.account_name,
        account_number: driverData.account_number,
        join_date: driverData.join_date,
        employee_detail: driverData.employee_detail,
        department: driverData.department,
        isBlacklist: driverData.isBlacklist,
        termination_date: '',
        terminationReason: '',
        approvalIds: [],
        driver_id: driverData.driver_id,
        flexible_checkout: driverData.flexible_checkout,
      }
    } catch (error) {
      callErrorMsg(error)
      return error
    }
  },
)

export const getDepartment = createAsyncThunk(
  `${sliceName}/publishPayroll`,
  async (_, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const response = await getDepartementList()
      const dataObject = response?.data?.data
      const keys = Object.keys(response?.data?.data)
      const dataProcessed: GetAllDepartementProcessedType[] = []

      keys.forEach((key: string) => {
        dataProcessed.push({ content: dataObject[key].names, name: key })
      })

      dispatch(setDepartmentList(dataProcessed))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)
