import { Delete, Get, Post, Put } from 'config/apiServiceConfig'

export type ErrorResponseType = {
  status: boolean
  message: string
  code: number
}

export type PaginationResponseType = {
  sort: string
  direction: string
  pageSize: number
  pageIndex: number
  totalElement: number
  numberOfElements: number
  totalPages: number
}

export interface GetWatchTowerConfigResponseType {
  filterWatchTower: string
  filterNewBuyer: string
  filterSembako: string
  maxOrderAddress: string
  discountLimit: string
  orderPercentage: string
  minimumTransaction: string
  category: string
}

export interface PutWatchTowerConfigRequestType {
  filterWatchTower?: string
  filterNewBuyer?: string
  filterSembako?: string
  maxOrderAddress?: string
  discountLimit?: string
  orderPercentage?: string
  minimumTransaction?: string
  category?: string
}

export interface GetLocationHubListResponseType {
  location_id: number
  location_name: string
  location_type: string
}

export interface PostHubExclusionRequestType {
  payload: { locationId: string; status: string }
}

export type GetHubExclusionListResponseType = {
  data: {
    whitelistLocation: {
      id: number
      locationId: number
      locationName: string
      status: string
      createdAt: string
      createdBy: string
      updatedAt: string
      updatedBy: string
    }[]
  }
  error: ErrorResponseType
  pagination: PaginationResponseType
}

export type PutHubExclusionRequestType = {
  hubExclusionId: string
  payload: {
    locationId: string
    status: string
  }
}

export type PutHubExclusionResponseType = {
  data: {
    id: number
    locationId: number
    status: string
  }
  error: ErrorResponseType
  pagination: null
}

export type DeleteHubExclusionRequestType = {
  hubExclusionId: string
}

export type DeleteHubExclusionResponseType = {
  data: {
    success: boolean
  }
  error: ErrorResponseType
  pagination: null
}

export const getWatchTowerConfig = () =>
  Get<{ data: GetWatchTowerConfigResponseType }>({
    url: `/fraud/watchtower/config`,
  })

export const putWatchTowerConfig = (payload: PutWatchTowerConfigRequestType) =>
  Put<{ data: GetWatchTowerConfigResponseType }>({
    url: `/fraud/watchtower/config`,
    data: payload,
  })

export const getLocationHubList = () =>
  Get<{ data: GetLocationHubListResponseType[] }>({ url: `/api/locations/order` })

export const postHubExclusion = ({ payload }: PostHubExclusionRequestType) =>
  Post({ url: `/fraud/whitelist/location`, data: payload })

export const getHubExclusionList = () =>
  Get<GetHubExclusionListResponseType>({
    url: `/fraud/whitelist/location`,
    params: { status: 'ACTIVE', sort: 'updatedAt' },
  })

export const putHubExclusion = ({ hubExclusionId, payload }: PutHubExclusionRequestType) =>
  Put<PutHubExclusionResponseType>({
    url: `/fraud/whitelist/location/${hubExclusionId}`,
    data: payload,
  })

export const deleteHubExclusion = ({ hubExclusionId }: DeleteHubExclusionRequestType) =>
  Delete<DeleteHubExclusionResponseType>({
    url: `/fraud/whitelist/location/${hubExclusionId}`,
  })
