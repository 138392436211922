import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UVCampaignListPaginationResponseType } from 'utils/apiList/uniqueVoucher'
import {
  fetchUVCampaignList,
  updateUVCampaign,
  fetchCSVDownloadUrl,
} from './uniqueVoucherListThunk'

export interface DataUVCampaignListStateType {
  uniqueKey: string
  id: number
  campaignName: string
  prefix: string
  benefitType: string
  dateStart: string
  dateEnd: string
  quota: number
  quantity: number
  minimumPurchase: number
  amount: number
  discountPercentage: number
  active: true
  status: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
}

export interface UniqueVoucherCampaignListStateType {
  isLoading: boolean
  needToReload: number
  query: {
    pageIndex: string
  }
  dataUVCampaignList: DataUVCampaignListStateType[]
  pagination: UVCampaignListPaginationResponseType
  csv: {
    url: string
  }
  modalCSVDownload: boolean
}

export const initialState: UniqueVoucherCampaignListStateType = {
  isLoading: false,
  needToReload: 1,
  query: { pageIndex: '0' },
  dataUVCampaignList: [],
  pagination: {
    direction: '',
    numberOfElements: 0,
    pageIndex: 0,
    pageSize: 20,
    sort: 'id',
    totalElements: 0,
    totalPages: 0,
  },
  csv: {
    url: '',
  },
  modalCSVDownload: false,
}

const uniqueVoucherCampaignList = createSlice({
  initialState,
  name: 'uniqueVoucherCampaignList',
  reducers: {
    reset: () => initialState,
    setQuery: (
      state,
      action: PayloadAction<{
        fieldType: keyof UniqueVoucherCampaignListStateType['query']
        value: string
      }>,
    ) => {
      const { fieldType, value } = action.payload
      state.needToReload += 1
      state.query[fieldType] = value
    },
    setIsShowModalCSVDownload: (state, action: PayloadAction<{ value: boolean }>) => {
      state.modalCSVDownload = action.payload.value
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUVCampaignList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchUVCampaignList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchUVCampaignList.fulfilled, (state, action) => {
        state.isLoading = false
        state.pagination = action.payload.pagination

        state.dataUVCampaignList = action.payload.data.uniqueVoucherList.map((el) => ({
          ...el,
          uniqueKey: window.crypto.randomUUID(),
        }))
      })
      .addCase(updateUVCampaign.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateUVCampaign.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateUVCampaign.fulfilled, (state) => {
        state.isLoading = false
        state.needToReload += 1
      })
      .addCase(fetchCSVDownloadUrl.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchCSVDownloadUrl.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchCSVDownloadUrl.fulfilled, (state, action) => {
        state.isLoading = false
        state.modalCSVDownload = true
        state.csv.url = action.payload.data.url
      })
  },
})

export const { reset, setQuery, setIsShowModalCSVDownload } = uniqueVoucherCampaignList.actions
export default uniqueVoucherCampaignList.reducer
