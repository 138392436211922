import { dateFormat } from '@astronautsid/wpe-utils'
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getWatchTowerRuleById,
  getWatchTowerRule,
  putWatchTowerRuleStatus,
  postWatchTowerRule,
  putWatchTowerRule,
  deleteParameterRule,
  type WatchTowerParameterType,
  type WatchTowerRuleType,
  type PostPutWatchTowerRulePayloadType,
  type GetWatchTowerRuleParamsType,
  type GetWatchTowerRuleResponseType,
} from 'utils/apiList/fraudWatchTower'
import { toastSuccess } from 'utils/toast'

export type { WatchTowerParameterType, WatchTowerRuleType, PostPutWatchTowerRulePayloadType }

const SLICE_NAME = 'fraudWatchTowerRule'

export type CreateEditFormParameterType = {
  id?: number
  customId: string
  selectedParameter: WatchTowerParameterType | null
  operator: string | null
  value: string
  isDeleted?: boolean
}

export const getInitialParameteRuleData = (): CreateEditFormParameterType => ({
  id: undefined,
  customId: crypto.randomUUID(),
  selectedParameter: null,
  operator: null,
  value: '',
  isDeleted: false,
})

export const PARAMETER_RELATION_OPTIONS = ['AND', 'OR']
export const PARAMETER_RULE_OPERATOR_OPTIONS = [
  'IN',
  'NOT_IN',
  'EXIST',
  'EQUAL',
  'NOT_EQUAL',
  'GTE',
  'LTE',
  'GT',
  'LT',
]

export type FraudWatchTowerRuleStateType = {
  isLoading: boolean
  showCreateEditModal: boolean
  dataWatchTowerRules: WatchTowerRuleType[]
  dataWatchTowerRulesPagination: GetWatchTowerRuleParamsType & {
    numberOfElements: number
  }
  // form data
  dataDetail: WatchTowerRuleType | null
  createEditForm: {
    ruleName: string
    parameterRelation: string | null
    parameterRules: CreateEditFormParameterType[]
    flagDeviceScore: boolean
    deviceScore: number
    flagActivityScore: boolean
    activityScore: number
    startDate: string
    startTime: string
    endDate: string
    endTime: string
  }
}

const initialState: FraudWatchTowerRuleStateType = {
  isLoading: false,
  showCreateEditModal: false,
  dataWatchTowerRules: [],
  dataWatchTowerRulesPagination: {
    pageIndex: 0,
    pageSize: 20,
    numberOfElements: 20,
  },
  // form data
  dataDetail: null,
  createEditForm: {
    ruleName: '',
    parameterRelation: null,
    parameterRules: [getInitialParameteRuleData()],
    flagDeviceScore: false,
    deviceScore: 0,
    flagActivityScore: false,
    activityScore: 0,
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
  },
}

const fraudWatchTowerRule = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    reset: () => initialState,
    resetCreateEditForm: (state) => {
      state.createEditForm = initialState.createEditForm
      state.dataDetail = null
    },
    setDataDetail: (state, action: PayloadAction<WatchTowerRuleType | null>) => {
      state.dataDetail = action.payload
    },
    setDataWatchTowerRule: (state, action: PayloadAction<WatchTowerRuleType[]>) => {
      state.dataWatchTowerRules = action.payload
    },
    setShowCreateEditModal: (state, action: PayloadAction<boolean>) => {
      state.showCreateEditModal = action.payload
    },
    setCreateEditForm: (
      state,
      action: PayloadAction<{
        key: keyof FraudWatchTowerRuleStateType['createEditForm']
        value: unknown
      }>,
    ) => {
      const { payload } = action

      state.createEditForm = {
        ...state.createEditForm,
        [payload.key]: payload.value,
      }
    },
    setDataWatchTowerRulePagination: (
      state,
      { payload }: PayloadAction<FraudWatchTowerRuleStateType['dataWatchTowerRulesPagination']>,
    ) => {
      state.dataWatchTowerRulesPagination = { ...payload }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDetailWatchTowerRule.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchDetailWatchTowerRule.fulfilled,
        (state, action: PayloadAction<WatchTowerRuleType>) => {
          const { payload } = action
          state.dataDetail = payload

          state.isLoading = false

          const currentParameterRules = payload?.parameterRules?.length
            ? payload?.parameterRules
            : []

          state.createEditForm = {
            ...state.createEditForm,
            ruleName: payload.name,
            parameterRelation: payload.logic,
            parameterRules: currentParameterRules.map((i) => ({
              id: i?.id || undefined,
              customId: crypto.randomUUID(),
              selectedParameter: {
                id: i.parameterId,
                name: 'Loading...',
              },
              operator: i.operator,
              value: i.value,
              isDeleted: false,
            })) as CreateEditFormParameterType[],
            flagDeviceScore: payload.flagDeviceScore,
            deviceScore: payload.deviceScore,
            startDate: payload.startDate
              ? dateFormat({ date: new Date(payload.startDate), format: 'YYYY-MM-DD' })
              : '',
            startTime: payload.startDate
              ? dateFormat({ date: new Date(payload.startDate), format: 'HH:mm' })
              : '',
            endDate: payload.startDate
              ? dateFormat({ date: new Date(payload.endDate), format: 'YYYY-MM-DD' })
              : '',
            endTime: payload.startDate
              ? dateFormat({ date: new Date(payload.endDate), format: 'HH:mm' })
              : '',
          }
        },
      )
      .addCase(fetchDetailWatchTowerRule.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchWatchTowerRule.pending, (state) => {
        state.isLoading = true
      })
      .addCase(
        fetchWatchTowerRule.fulfilled,
        (state, action: PayloadAction<GetWatchTowerRuleResponseType>) => {
          const { payload } = action

          state.dataWatchTowerRulesPagination = {
            ...state.dataWatchTowerRulesPagination,
            ...payload.pagination,
          }

          state.dataWatchTowerRules = payload.data.rules
          state.isLoading = false
        },
      )
      .addCase(fetchWatchTowerRule.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(createWatchTowerRule.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createWatchTowerRule.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(createWatchTowerRule.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const {
  setIsLoading,
  reset,
  resetCreateEditForm,
  setDataDetail,
  setCreateEditForm,
  setDataWatchTowerRule,
  setShowCreateEditModal,
  setDataWatchTowerRulePagination,
} = fraudWatchTowerRule.actions
export default fraudWatchTowerRule.reducer

export const fetchDetailWatchTowerRule = createAsyncThunk(
  `${SLICE_NAME}/fetchDetailWatchTowerRule`,
  async (payload: number, { rejectWithValue }) => {
    try {
      const { data } = await getWatchTowerRuleById(payload)

      return data?.data || null
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan detail watchtower rule')
      return rejectWithValue(err)
    }
  },
)

export const fetchWatchTowerRule = createAsyncThunk(
  `${SLICE_NAME}/fetchWatchTowerRule`,
  async (payload: GetWatchTowerRuleParamsType, { getState, rejectWithValue }) => {
    const {
      fraudWatchTowerRule: { dataWatchTowerRulesPagination },
    } = getState() as StoreStateType

    const newParams = {
      ...dataWatchTowerRulesPagination,
      ...payload,
    }

    try {
      const { data } = await getWatchTowerRule(newParams)

      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar watchtower rule')
      return rejectWithValue(err)
    }
  },
)

export const updateWatchTowerRuleStatus = createAsyncThunk(
  `${SLICE_NAME}/updateWatchTowerRuleStatus`,
  async (
    { id, status }: { id: number; status: WatchTowerRuleType['status'] },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await putWatchTowerRuleStatus(id, status)

      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal update status watchtower rule')
      return rejectWithValue(err)
    }
  },
)

export const createWatchTowerRule = createAsyncThunk(
  `${SLICE_NAME}/createWatchTowerRule`,
  async (payload: PostPutWatchTowerRulePayloadType, { rejectWithValue }) => {
    try {
      const { data } = await postWatchTowerRule(payload)

      toastSuccess('Berhasil membuat watchtower rule')
      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal membuat watchtower rule')
      return rejectWithValue(err)
    }
  },
)

export const updateWatchTowerRule = createAsyncThunk(
  `${SLICE_NAME}/updateWatchTowerRule`,
  async (payload: PostPutWatchTowerRulePayloadType, { rejectWithValue }) => {
    try {
      const { data } = await putWatchTowerRule(payload)

      toastSuccess('Berhasil update watchtower rule')
      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal update watchtower rule')
      return rejectWithValue(err)
    }
  },
)

export const removeParameterRule = createAsyncThunk(
  `${SLICE_NAME}/removeParameterRule`,
  async (payload: number, { rejectWithValue }) => {
    try {
      await deleteParameterRule(payload)

      return true
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)
