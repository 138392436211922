import {
  DialogContent as MuiDialogContent,
  DialogContentProps as MuiDialogContentProps,
} from '@mui/material'
import styled from 'styled-components'

export type DialogContentPropsType = MuiDialogContentProps

const DialogContent = styled(MuiDialogContent)<DialogContentPropsType>``

export default DialogContent
