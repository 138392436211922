import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { withLoadingReducer } from 'utils/reducerHandler'

import {
  ProductAssetResponseListInterface,
  ReviewAssetParamsType,
} from 'features/AssetManagement/@types/typeProductAssetList'

import { getProductAssetLists, getAllUoms } from './thunk'

type UOMsType = {
  id: string
  group: string
  description: string
  active: boolean
}

interface ProductAssetListListStateType {
  isLoading: boolean
  assetListData: ProductAssetResponseListInterface[]
  assetListUoms: UOMsType[]
  hasNext: boolean
  requestProductAssetList: {
    source?: string
    page_index?: number
    page_size?: number
    name?: string
    status?: string
    type?: string
  }
  paramsReviewAsset: ReviewAssetParamsType
}

const initialState: ProductAssetListListStateType = {
  isLoading: false,
  assetListData: [],
  assetListUoms: [],
  hasNext: false,
  requestProductAssetList: {
    page_index: 0,
    page_size: 10,
  },
  paramsReviewAsset: {
    asset_id: '',
    status: '',
    uom: '',
    type: '',
    classification_id: 0,
    asset_categories: [],
    reject_reason: '',
    name: ``,
  },
}
const productAssetListListSlice = createSlice({
  name: 'productAssetList',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setRequestProductAssetList: (
      state,
      action: PayloadAction<ProductAssetListListStateType['requestProductAssetList']>,
    ) => {
      const newPayload = action.payload
      state.requestProductAssetList = { ...state.requestProductAssetList, ...newPayload }
    },
    setParamsReviewAsset: (
      state,
      action: PayloadAction<ProductAssetListListStateType['paramsReviewAsset']>,
    ) => {
      const newPayload = action.payload
      state.paramsReviewAsset = { ...state.paramsReviewAsset, ...newPayload }
    },
    resetParamsReviewAsset: (state) => {
      state.paramsReviewAsset = initialState.paramsReviewAsset
    },
    resetRequestProductAssetList: (state) => {
      state.requestProductAssetList = initialState.requestProductAssetList
    },
  },
  extraReducers: withLoadingReducer(
    (builder: ActionReducerMapBuilder<ProductAssetListListStateType>) => {
      builder
        .addCase(getProductAssetLists.fulfilled, (state, action) => {
          state.assetListData = action.payload.asset_list
          state.hasNext = action.payload.has_next
        })
        .addCase(getAllUoms.fulfilled, (state, action) => {
          state.assetListUoms = action.payload.data.data.unit_of_measurements.content
        })
    },
    [getProductAssetLists, getAllUoms],
  ),
})

export const {
  setIsLoading,
  setRequestProductAssetList,
  setParamsReviewAsset,
  resetParamsReviewAsset,
  resetRequestProductAssetList,
} = productAssetListListSlice.actions

export default productAssetListListSlice.reducer
