import {
  SET_PURCHASE_ORDERS,
  SET_LAST_PARAMS,
  SET_DATA_PAGINATION,
  SET_IS_LOADING,
  SET_SELECTED_PURCHASE_ORDER,
  SET_PARTIES,
  SET_PRODUCTS,
  SET_VENDORS,
} from 'storeContext/actionsType/purchaseOrder'

export const initialStatePurchaseOrder = {
  isLoading: false,
  dataPagination: {},
  lastParams: {},
  purchaseOrders: [],
  selectedPurchaseOrder: {},
  parties: [],
  products: [],
  vendors: [],
}

export default function purchaseOrder(state = initialStatePurchaseOrder, { type, payload }) {
  switch (type) {
    case SET_IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      }
    case SET_DATA_PAGINATION:
      return {
        ...state,
        dataPagination: payload,
      }

    case SET_LAST_PARAMS:
      return {
        ...state,
        lastParams: payload,
      }

    case SET_PURCHASE_ORDERS:
      return {
        ...state,
        purchaseOrders: payload,
      }
    case SET_SELECTED_PURCHASE_ORDER:
      return {
        ...state,
        selectedPurchaseOrder: payload,
      }
    case SET_PARTIES:
      return {
        ...state,
        parties: payload,
      }
    case SET_PRODUCTS:
      return {
        ...state,
        products: payload,
      }
    case SET_VENDORS:
      return {
        ...state,
        vendors: payload,
      }
    default:
      return state
  }
}
