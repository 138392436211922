import {
  SET_IS_LOADING_WAREHOUSE,
  SET_WAREHOUSES,
  SET_SELECTED_WAREHOUSE,
  SET_CHECKALL,
  SET_ID_SELECTED_PRODUCT,
  SET_DATA_PAGINATION_PRODUCT,
  SET_PRODUCTS_CAN_ADD,
  SET_PAGINATION_DATA_INVENTORIES,
  SET_INVENTORIES,
  SET_SELECTED_WAREHOUSE_FILTER,
  SET_LAST_QUERY_WAREHOUSE,
  SET_RAK_OPTIONS_WAREHOUSE,
  SET_QUERY_PARAMS_INVENTORY_WAREHOUSE,
} from 'storeContext/actionsType/warehouse'

export const initialStateWarehouse = {
  queryParamsInventory: {
    location_id: 0,
    search_product: '',
    pagination: {
      page_index: 1,
      page_size: 20,
      direction: 'desc',
      sort: 'created_at',
    },
  },
  isLoading: false,
  warehouses: [],
  selectedWarehouse: {},
  isCheckAll: false,
  idProductsSelected: [],
  dataPaginationProduct: {},
  productsCanAdd: [],
  inventories: [],
  paginationDataInventories: {},
  lastQueryWarehouse: '',
  selectedWarehouseFilter: {},
  rakOptionsData: [],
}

export default function warehouse(state = initialStateWarehouse, { type, payload }) {
  switch (type) {
    case SET_QUERY_PARAMS_INVENTORY_WAREHOUSE:
      return {
        ...state,
        queryParamsInventory: {
          ...state.queryParamsInventory,
          ...payload,
        },
      }
    case SET_IS_LOADING_WAREHOUSE:
      return {
        ...state,
        isLoading: payload,
      }
    case SET_WAREHOUSES:
      return {
        ...state,
        warehouses: payload,
      }
    case SET_SELECTED_WAREHOUSE:
      return {
        ...state,
        selectedWarehouse: payload,
      }
    case SET_CHECKALL:
      return {
        ...state,
        isCheckAll: payload,
      }
    case SET_ID_SELECTED_PRODUCT:
      return {
        ...state,
        idProductsSelected: payload,
      }
    case SET_DATA_PAGINATION_PRODUCT:
      return {
        ...state,
        dataPaginationProduct: payload,
      }
    case SET_PRODUCTS_CAN_ADD:
      return {
        ...state,
        productsCanAdd: payload,
      }
    case SET_INVENTORIES:
      return {
        ...state,
        inventories: payload,
      }
    case SET_PAGINATION_DATA_INVENTORIES:
      return {
        ...state,
        paginationDataInventories: payload,
      }

    case SET_SELECTED_WAREHOUSE_FILTER:
      return {
        ...state,
        selectedWarehouseFilter: payload,
      }
    case SET_LAST_QUERY_WAREHOUSE:
      return {
        ...state,
        lastQueryWarehouse: payload,
      }
    case SET_RAK_OPTIONS_WAREHOUSE:
      return {
        ...state,
        rakOptionsData: payload,
      }
    default:
      return state
  }
}
