import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getAllMissions,
  putStatusReferralMission,
  getReferralStatus,
} from 'utils/apiList/referralMission'
import type {
  PutStatusReferralMissionRequestType,
  GetAllMissionRequestType,
} from 'utils/apiList/referralMission'
import { callErrorMsg } from 'helpers/errorMsg'
import { toastSuccess } from 'utils/toast'

export const SLICE_NAME = 'ReferralMissionList'

const validateParamsValue = (
  key: keyof GetAllMissionRequestType['params'],
  value: string | number | Date | null,
) =>
  value
    ? {
        [key]: value,
      }
    : {}

export const fetchAllMission = createAsyncThunk(
  `${SLICE_NAME}/fetchAllMission`,
  async (_, { rejectWithValue, getState }) => {
    const {
      referralMissionList: { query },
    } = getState() as StoreStateType

    try {
      const res = await getAllMissions({
        params: {
          pageIndex: query.pageIndex,
          ...validateParamsValue('missionName', query.missionName),
          ...validateParamsValue('startDate', query.date[0] && query.date[0].valueOf()),
          ...validateParamsValue('endDate', query.date[1] && query.date[1].valueOf()),
          ...validateParamsValue('status', query.status.value),
        },
      })

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const updateStatusReferralMission = createAsyncThunk(
  `${SLICE_NAME}/updateStatusReferralMission`,
  async (params: PutStatusReferralMissionRequestType, { rejectWithValue }) => {
    try {
      const res = await putStatusReferralMission(params)
      if (res.data.data.status.value === 'DELETED') {
        toastSuccess(`Berhasil menghapus mision`)
      }
      if (res.data.data.status.value === 'ACTIVATED') {
        toastSuccess(`Berhasil mengaktivkan mission`)
      }

      if (res.data.data.status.value === 'ACTIVATED') {
        toastSuccess(`Berhasil menonaktivkan mission`)
      }

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchReferralStatus = createAsyncThunk(
  `${SLICE_NAME}/fetchReferralStatus`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getReferralStatus()

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)
