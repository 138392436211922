import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { callErrorMsg } from "helpers/errorMsg";
import { withLoadingReducer } from "utils/reducerHandler";
import { toastFailed, toastSuccess } from "utils/toast";

export const fetchGetTemplateURL = createAsyncThunk(
  "bulkUpload/fetchGetTemplateURL",
  async (downloadAPI, { rejectWithValue }) => {
    try {
      const response = await downloadAPI();

      return response.data;
    } catch (err) {
      callErrorMsg(err);
      return rejectWithValue();
    }
  }
);

export const fetchPostBulkUpload = createAsyncThunk(
  "bulkUpload/fetchPostBulkUpload",
  async ({ uploadAPI, payload, handleGoBack }, { rejectWithValue }) => {
    try {
      const response = await uploadAPI(payload);
      const { data, error } = response.data;

      if (data.status === "success") {
        handleGoBack();
        toastSuccess("Data berhasil diupload");
      } else {
        toastFailed(error?.message);
      }

      if (data?.urlDownload) {
        window.open(data.urlDownload, "_blank");
      }

      return response.data;
    } catch (err) {
      callErrorMsg(err);
      return rejectWithValue();
    }
  }
);

const initialState = {
  isLoading: false,
  downloadTemplateURL: "",
  downloadErrorURL: ""
};

const bulkUpload = createSlice({
  name: "bulkUpload",
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: withLoadingReducer(
    (builder) => {
      builder
        .addCase(fetchGetTemplateURL.fulfilled, (state, action) => {
          state.downloadTemplateURL = action.payload.data.url;
        })
        .addCase(fetchPostBulkUpload.fulfilled, (state, action) => {
          state.downloadErrorURL = action.payload.data?.urlDownload;
        });
    },
    [fetchPostBulkUpload]
  )
});

export const { reset } = bulkUpload.actions;
export default bulkUpload.reducer;
