import axiosInstanceApiKong from 'config/apiServiceApiGateway'
import { Get } from 'config/apiServiceConfig'

export type GetAreaListParamsType = Partial<{
  page_index: number
  page_size: number
  search_name: string
  location_id: number
}>

export type GetAreaDetailResponseType = {
  id: number
  name: string
  location: {
    id: number
    name: string
  }
  product_categories: {
    category_id: number
    category_name: string
  }[]
}

export type GetAreaListResponseType = GetAreaDetailResponseType[]

export type PostAreaRequestType = {
  location_id: number
  name: string
  product_category_ids: number[]
}

export type PutAreaRequestType = {
  id: number
  data: {
    name: string
    product_category_ids: number[]
  }
}

export type PostPutAreaResponseType = {
  message: string
  error: {
    status: boolean
    message: string
    code: number
  }
}

export type GetCategoriesResponseType = {
  category_id: number
  category_name: string
}[]

export const getAreaListAPI = (params?: GetAreaListParamsType) =>
  axiosInstanceApiKong.Get<GetAreaListResponseType>({
    url: '/wms/internal/v1/areas',
    params,
  })

export const getAreaDetailAPI = (id: number) =>
  axiosInstanceApiKong.Get<GetAreaDetailResponseType>({
    url: `/wms/internal/v1/areas/${id}`,
  })

export const postAreaAPI = (payload: PostAreaRequestType) =>
  axiosInstanceApiKong.Post<PostPutAreaResponseType>({
    url: '/wms/internal/v1/areas',
    data: payload,
  })

export const putAreaAPI = ({ id, data }: PutAreaRequestType) =>
  axiosInstanceApiKong.Put<PostPutAreaResponseType>({
    url: `/wms/internal/v1/areas/${id}`,
    data,
  })

export const getCategoriesAPI = () =>
  Get<GetCategoriesResponseType>({
    url: `/api/categories/child`,
  })
