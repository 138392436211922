import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { AddProductVariant as Variant } from 'features/Product/@types'

export const SLICE_NAME = 'addProductVariant'
export const TOTAL_VARIANT = 1

export const initialState: Variant.InitialStateType = {
  loadingVariantBucket: false,
  pageType: 'ADD',
  variantOptions: [],
  variantList: [],
  variantBucket: [],
  brands: [],
  selectedBrand: null,
  subtitle: '',
  modal: {
    isOpen: false,
    title: '',
    description: '',
  },
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    setPageType: (state, { payload }: PayloadAction<Variant.PageType>) => {
      state.pageType = payload
    },
    setVariantOptions: (state, { payload }: PayloadAction<Variant.ProductVariantType[]>) => {
      state.variantOptions = [...payload]
    },
    addVariant: (state) => {
      const currentVariantLength = state.variantList.length
      state.variantList.push({
        label: `Varian ${currentVariantLength + 1}`,
        variant: null,
      })
    },
    setSelectedVariant: (
      state,
      {
        payload,
      }: PayloadAction<{
        index: number
        value: Nullable<Variant.ProductVariantType>
      }>,
    ) => {
      const { index, value } = payload
      state.variantList[index].variant = value
    },
    mappingVariantBucket: (state) => {
      state.variantBucket = state.variantList.flatMap((list) => {
        if (list.variant) {
          return list.variant.variants.map((variant) => ({
            isDefault: false,
            isInvalidSelectedProduct: false,
            product: null,
            productOptions: [],
            variants: [{ masterVariant: list.variant as Variant.ProductVariantType, variant }],
          }))
        }

        return []
      })
    },
    setVariantBucket: (state, { payload }: PayloadAction<Variant.VariantBucketType[]>) => {
      state.variantBucket = payload
    },
    setBucketForm: (
      state,
      {
        payload,
      }: PayloadAction<{ index: number; key: keyof Variant.VariantBucketType; value: never }>,
    ) => {
      const { index, key, value } = payload
      state.variantBucket[index][key] = value
    },
    setDefaultProduct: (state, { payload }: PayloadAction<{ index: number; value: boolean }>) => {
      state.variantBucket = state.variantBucket.map((bucket, index) => {
        const updatedBucket = { ...bucket }
        updatedBucket.isDefault = false
        if (index === payload.index) {
          updatedBucket.isDefault = payload.value
        }

        return updatedBucket
      })
    },
    validateSelectedProduct: (
      state,
      { payload }: PayloadAction<{ index: number; productId: Nullable<number> }>,
    ) => {
      const { index, productId } = payload

      state.variantBucket[index].isInvalidSelectedProduct = state.variantBucket.some(
        (bucket, i) => i !== index && bucket.product?.productID === productId,
      )
    },
    setBrands: (
      state,
      { payload }: PayloadAction<Variant.ResponseGetBrandType['data']['content']>,
    ) => {
      state.brands = payload
    },
    setSelectedBrand: (
      state,
      { payload }: PayloadAction<Variant.ResponseGetBrandType['data']['content'][0]>,
    ) => {
      state.selectedBrand = payload
    },
    setSubtitle: (state, { payload }: PayloadAction<string>) => {
      state.subtitle = payload
    },
    setProductOptions: (
      state,
      {
        payload,
      }: PayloadAction<{
        index: number
        value: Variant.InitialStateType['variantBucket'][0]['productOptions']
      }>,
    ) => {
      state.variantBucket[payload.index].productOptions = payload.value
    },
    setModalConfirmation: (
      state,
      { payload }: PayloadAction<Variant.InitialStateType['modal']>,
    ) => {
      state.modal = { ...payload }
    },
    setLoadingVariantBucket: (state, { payload }: PayloadAction<boolean>) => {
      state.loadingVariantBucket = payload
    },
  },
})

export const {
  resetState,
  addVariant,
  setPageType,
  setVariantOptions,
  setSelectedVariant,
  mappingVariantBucket,
  setBucketForm,
  setDefaultProduct,
  setBrands,
  setSelectedBrand,
  setSubtitle,
  setProductOptions,
  validateSelectedProduct,
  setModalConfirmation,
  setVariantBucket,
  setLoadingVariantBucket,
} = slice.actions
export default slice.reducer
