import React from "react";
import styled, { css } from "styled-components";
import { SystemIcon, TextBody } from "components";

const InputForm = ({
  onChange,
  iconPos,
  customIcon,
  border,
  iconName,
  bgIcon,
  handleClickIcon,
  className,
  onFocus,
  onBlur,
  iconColor,
  note,
  width,
  error,
  errorText,
  sulfix,
  size,
  value,
  autocomplete,
  useFakeInput,
  fakeInputValue,
  ...rest
}) => {
  let reassignValue = value;
  if (value === 0) {
    reassignValue = 0;
  }
  return (
    <SInputContainer width={width} className={`${className} input-container`}>
      <SContainer iconPos={iconPos} border={border}>
        {
            useFakeInput ? 
            <SFakeInput
                useFakeInput = {useFakeInput}
            >
                {
                    value.length == 0 ? 
                    <SFakePlaceholder
                    >
                        Kata Sandi
                    </SFakePlaceholder> : 
                    fakeInputValue
                }
            </SFakeInput> : null
        }
        <SInput
          value={reassignValue}
          size={size}
          {...rest}
          error={error}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          autoComplete = {autocomplete}
          useFakeInput = {useFakeInput}
        />
        {sulfix && sulfix}
        {(customIcon || iconName) && (
          <label>
            <SIconWrapper
              onClick={handleClickIcon}
              className="icon-wrapper"
              iconPos={iconPos}
              bgIcon={bgIcon}
            >
              {customIcon ? (
                customIcon
              ) : (
                <SystemIcon iconName={iconName} colorIcon={iconColor} />
              )}
            </SIconWrapper>
          </label>
        )}
      </SContainer>
      {error && (
        <TextBody
          weight="light"
          style={{
            marginTop: "4px",
            color: "#E25450"
          }}
          size="smaller"
        >
          {errorText}
        </TextBody>
      )}
      {note && (
        <TextBody
          className="mt-2 ml-3"
          size="smaller"
          weight="light"
          color="secondaryText"
        >
          {note}
        </TextBody>
      )}
    </SInputContainer>
  );
};

InputForm.defaultProps = {
  onChange: () => {},
  iconPos: "right",
  border: true,
  customIcon: "",
  bgIcon: "grey",
  handleClickIcon: () => {},
  value: "",
  onBlur: () => {},
  onFocus: () => {},
  iconColor: "main",
  color: "text",
  autocomplete: "off",
  note: "",
  width: "100%",
  size: "md",
  inputSmall: false
};

export default React.memo(InputForm);

const SContainer = styled.div`
  width: 100%;
  border-radius: 6px;
  display: flex;
  position: relative;
  background-color: ${({ theme: { colors } }) => colors.white};
  border: ${({ border }) => (border ? "solid 1px #E4EAF3" : "none")};
  flex-direction: ${({ iconPos }) =>
    iconPos === "left" ? "row-reverse" : "row"};
`;
const SIconWrapper = styled.div`
  display: grid;
  padding: 13px;
  place-items: center;
  background-color: ${({ theme: { colors }, bgIcon }) => colors[bgIcon]};
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.light};
  font-family: "Noto Sans JP", sans-serif !important;
  white-space: nowrap;
  position: absolute;
  right: 0px;
  /* padding-right: 0; */
`;

const SFakeInput = styled.div`
    ${({ error }) => (error ? "border: 1px solid #E25450;" : "border: none;")}
  width: 100%;
  padding: 10px;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  color: ${({ theme: { colors }, color }) => colors[color]};
  border-radius: 6px;
  position: absolute;
  opacity: ${({ useFakeInput }) => (useFakeInput ? 1 : 0)};
  &:disabled {
    color: ${({ theme: { colors } }) => colors.grey};
  }
  &:focus {
    outline: none;
  }
  ::placeholder {
    opacity: 0.5;
    color: ${({ theme: { colors }, color }) => colors[color]};
  }
  ::-webkit-calendar-picker-indicator {
    width: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    /* display: none;
    -webkit-appearance: none; */
  }
  ${({ size }) =>
    size === "sm" &&
    css`
      padding: 7px;
      font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
    `}
`

const SFakePlaceholder = styled.div`
   font-size: ${({ theme: { fontSizes } }) => fontSizes.normal}; 
    color: #888;
    opacity: 0.7;
`

const SInput = styled.input`
  ${({ error }) => (error ? "border: 1px solid #E25450;" : "border: none;")}
  width: 100%;
  padding: 10px;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  color: ${({ theme: { colors }, color }) => colors[color]};
  border-radius: 6px;
  opacity: ${({ useFakeInput }) => (useFakeInput ? 0 : 1)};
  &:disabled {
    color: ${({ theme: { colors } }) => colors.grey};
  }
  &:focus {
    outline: none;
  }
  ::placeholder {
    opacity: 0.5;
    color: ${({ theme: { colors }, color }) => colors[color]};
  }
  ::-webkit-calendar-picker-indicator {
    width: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    /* display: none;
    -webkit-appearance: none; */
  }
  ${({ size }) =>
    size === "sm" &&
    css`
      padding: 7px;
      font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
    `}
`;

const SInputContainer = styled.div`
  width: ${({ width }) => width};
`;
