import { memo } from "react";
import styled from "styled-components";
import Table from "./Table";

function Body() {
  return (
    <Container>
      <Table />
    </Container>
  );
}

export default memo(Body);

const Container = styled.div`
  display: flex;
`;
