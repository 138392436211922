import { memo } from 'react'
import styled from 'styled-components'
import { TextBody } from 'components'
import dayjs from 'dayjs'

function RowHubDetail({ data }) {
  return (
    <Tr>
      <Td ratio="4">
        <TextBody weight="light" color="textSoft">
          {data ? dayjs(data.createdAt).utc().format('DD MMM YYYY - HH:mm') : '-'}
        </TextBody>
      </Td>
      <Td ratio="2">
        <TextBody weight="light" color="textSoft">
          {data?.operator}
          {data?.quantity}
        </TextBody>
      </Td>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {data?.stock}
        </TextBody>
      </Td>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {data?.type}
        </TextBody>
      </Td>
      <Td ratio="4">
        <TextBody weight="light" color="textSoft">
          {data?.createdBy}
        </TextBody>
      </Td>
      <Td ratio="4">
        <TextBody weight="light" color="textSoft">
          {data?.fromStatusName}
        </TextBody>
      </Td>
      <Td ratio="4">
        <TextBody weight="light" color="textSoft">
          {data?.toStatusName}
        </TextBody>
      </Td>
      <Td ratio="4">
        <TextBody weight="light" color="textSoft">
          {data?.originName}
        </TextBody>
      </Td>
      <Td ratio="4">
        <TextBody weight="light" color="textSoft">
          {data?.destinationName === null ? '-' : data?.destinationName}
        </TextBody>
      </Td>
      <Td ratio="4">
        {data?.transferAdjustmentReportUrl ? (
          <a
            href={data.transferAdjustmentReportUrl}
            download={`${data.invoiceNumber}.pdf`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontSize: '14px' }}
          >
            {data?.invoiceNumber}
          </a>
        ) : (
          <TextBody weight="light" color="textSoft">
            {data?.invoiceNumber}
          </TextBody>
        )}
      </Td>
    </Tr>
  )
}

export default memo(RowHubDetail)

const Tr = styled.tr`
  display: flex;
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
  .input-container {
    width: calc(100% - 20px);
  }
`
const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  /* padding-right: ${({ pr }) => pr}; */
`
