import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ProductImageType = {
  id: number | string
  file: Nullable<File>
  previewUrl: string | ArrayBuffer
  pathLarge: string
  pathMedium: string
  pathSmall: string
  urlLarge: string
  urlMedium: string
  urlSmall: string
}

interface ProductAddEditStateType {
  isUploadedRealImageOn: boolean
  productImages: ProductImageType[]
  confirmationDeleteImage: {
    isOpen: boolean
    indexToDelete: number
  }
  maxImageToUpload: number
}

const initialState: ProductAddEditStateType = {
  isUploadedRealImageOn: false,
  productImages: [],
  confirmationDeleteImage: {
    isOpen: false,
    indexToDelete: -1,
  },
  maxImageToUpload: 5,
}
const productAddEditSlice = createSlice({
  name: 'productAddEdit',
  initialState,
  reducers: {
    setIsUploadedRealImageOn: (state, action) => {
      state.isUploadedRealImageOn = action.payload
    },
    toggleIsUploadedRealImageOn: (state) => {
      state.isUploadedRealImageOn = !state.isUploadedRealImageOn
    },
    resetStateOfProductAddEdit: () => initialState,
    resetConfirmationDeleteImage: (state) => {
      state.confirmationDeleteImage = initialState.confirmationDeleteImage
    },
    setConfirmationDeleteImage: (
      state,
      action: PayloadAction<Partial<ProductAddEditStateType['confirmationDeleteImage']>>,
    ) => {
      state.confirmationDeleteImage = {
        ...state.confirmationDeleteImage,
        ...action.payload,
      }
    },
    setProductImages: (state, action: PayloadAction<ProductImageType[]>) => {
      state.productImages = action.payload
    },
    resetStateProductAddEdit: () => initialState,
  },
})

export const {
  setIsUploadedRealImageOn,
  resetStateOfProductAddEdit,
  resetConfirmationDeleteImage,
  setConfirmationDeleteImage,
  setProductImages,
  toggleIsUploadedRealImageOn,
  resetStateProductAddEdit,
} = productAddEditSlice.actions

export default productAddEditSlice.reducer
