import styled from 'styled-components'
import { Dialog, Button, Typography } from '@astro-ui/components'

export const Popup = styled(Dialog)`
  .MuiDialog-paperWidthXs {
    border-radius: 16px;
  }
`

export const Contianer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 0;
`

export const Action = styled.div`
  display: flex;
  margin-top: 24px;
`

export const ButtonSubmit = styled(Button)<{
  $popupType?: string
  actionPosition?: 'fullwidth' | 'fit-content'
}>`
  min-width: ${({ actionPosition }) => actionPosition === 'fullwidth' && '50%'};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  font-weight: 400;
  background-color: ${({ theme: { colors }, $popupType }) =>
    $popupType === 'INFO' ? colors.blueTag : colors.redTag};
`

export const ButtonCancel = styled(Button)<{
  actionPosition?: 'fullwidth' | 'fit-content'
}>`
  min-width: ${({ actionPosition }) => actionPosition === 'fullwidth' && '50%'};
  background: white;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  color: #5c5c5c;
  border: 1px solid #5c5c5c;
  margin-left: auto;
  margin-right: 8px;
`

export const Title = styled(Typography)`
  text-align: center;
  margin-bottom: 16px;
`

export const Message = styled(Typography)`
  text-align: center;
  margin-bottom: 8px;
`
