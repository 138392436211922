import styled from 'styled-components'

export const S = {
  Container: styled.div`
    display: table;
    flex-wrap: wrap;
    width: 100%;
  `,
  QRWrapper: styled.div`
    display: inline-block;
    width: ${({ odd }: { odd: number }) => (odd === 1 ? `48%` : `49%`)};
    margin-left: ${({ odd }) => (odd === 1 ? `2%` : `1%`)};
  `,
  QRCardContainer: styled.div`
    display: flex;
  `,
  ImageSection: styled.div`
    display: flex;
    flex-direction: column;
  `,
  ImageTitleContainer: styled.div`
    display: flex;
  `,
  ImageBodyContainer: styled.div``,
  TextSection: styled.div`
    display: flex;
    flex-direction: column;
    min-height: 60px;
    padding-left: 8px;
    padding-right: 12px;
  `,
  ProductContainer: styled.div`
    height: 40%;
  `,
  BarcodeNumbContainer: styled.div`
    padding-top: 2px;
  `,
  ExprDateAndMFGContainer: styled.div`
    padding-top: 2px;
    display: flex;
  `,
  SExprDateContainer: styled.div`
    width: 50%;
  `,
  SMFGContainer: styled.div`
    width: 50%;
  `,
  SProducedByContainer: styled.div`
    padding-top: 2px;
  `,
  SNotesContainer: styled.div`
    padding-top: 2px;
  `,
}
