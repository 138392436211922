import { memo, useState } from 'react'
import styled from 'styled-components'
import { TextBody, Checkbox, Input, Tag } from 'components'
import { numberOnly } from 'helpers'
import { toastFailed } from 'utils/toast'
import dayjs from 'dayjs'
import { mapProductInventoryStatus } from 'constant/supplyOrder'

function RowProductHub({ data, handleChangeStock, handleCheck, soType }) {
  const [stock, setStock] = useState(data.stockInput)

  const handleChangeInput = (e) => {
    const value = numberOnly(e.target.value)
    if (Number(value) <= data.productStock) {
      setStock(value)
      handleChangeStock({ ...data, stockInput: value })
    } else {
      toastFailed('Stock tidak cukup')
    }
  }

  return (
    <Tr>
      <Td ratio="1">
        <Checkbox checked={data.checked} onChange={() => handleCheck(data)} />
      </Td>
      <Td ratio="5">
        <ProductWrapper>
          <ImgCategory size="32px" alt="box" src={data.productImage} />
          <TextBody weight="light">{data.productName}</TextBody>
        </ProductWrapper>
      </Td>
      <Td ratio="3">
        <TextBody weight="light">{data.productSku}</TextBody>
      </Td>
      {soType === 'asset' ? (
        <>
          <Td ratio="3">
            <TextBody weight="light">{data.productAssetCode}</TextBody>
          </Td>
          <Td ratio="1" display="flex" justifyContent="center">
            <TextBody weight="light">{data.productStock}</TextBody>
          </Td>
          <Td ratio="1" display="flex" justifyContent="center">
            <TextBody weight="light">{data.productUom}</TextBody>
          </Td>
        </>
      ) : (
        <>
          <Td ratio="3">
            <TextBody weight="light">
              {data.productExpiryDate
                ? `${dayjs(data.productExpiryDate).format('DD MM YYYY')}`
                : '-'}
            </TextBody>
          </Td>
          <Td ratio="2">
            <TextBody weight="light">{data.productStock}</TextBody>
          </Td>
          <Td ratio="2">
            <TextBody weight="light">{data.productRackName || '-'}</TextBody>
          </Td>
        </>
      )}

      <Td ratio="1" display="flex" justifyContent="center">
        <InputWrapper>
          <Input value={stock} onChange={handleChangeInput} />
        </InputWrapper>
      </Td>
      <Td ratio="2" display="flex" justifyContent="center">
        <Tag color={mapProductInventoryStatus[data.productInventoryStatus]}>
          {data.productInventoryStatus}
        </Tag>
      </Td>
      <Td ratio="2">
        <TextBody weight="light">{data.productInventoryNote || '-'}</TextBody>
      </Td>
    </Tr>
  )
}

export default memo(RowProductHub)

const Tr = styled.tr`
  display: flex;
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
`
const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  display: ${({ display }) => display || 'block'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
`
const ImgCategory = styled.img`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  border-radius: 5px;
  background-color: ${({ theme: { colors } }) => colors.mainFaded};
  border: none;
`

const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const InputWrapper = styled.div`
  .input-container {
    max-width: 60px;
  }
`
