import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RouteAndScheduleQueryType, RouteAndScheduleType } from 'utils/apiList/routeAndSchedule'
import { SLICE_NAME, fetchRouteAndSchedule } from './routeAndScheduleThunk'

export interface RouteAndScheduleStateInterface {
  query: Partial<RouteAndScheduleQueryType>
  isLoading: boolean
  data: RouteAndScheduleType[]
  access: {
    bulkUpload: boolean
    addRoute: boolean
    editRoute: boolean
    updateStatus: boolean
  }
}

const initialState: RouteAndScheduleStateInterface = {
  query: {},
  isLoading: false,
  data: [],
  access: {
    bulkUpload: true,
    addRoute: true,
    editRoute: true,
    updateStatus: true,
  },
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    setQuery: (state, { payload }: PayloadAction<RouteAndScheduleStateInterface['query']>) => {
      state.query = { ...payload }
    },
    setAccessActions: (
      state,
      { payload }: PayloadAction<RouteAndScheduleStateInterface['access']>,
    ) => {
      state.access = { ...payload }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRouteAndSchedule.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchRouteAndSchedule.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.data = payload.data
      })
      .addCase(fetchRouteAndSchedule.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const { resetState, setQuery, setAccessActions } = slice.actions
export default slice.reducer
