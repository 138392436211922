import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import {
  type InitialStateType,
  type LocationType,
  type InfiniteScrollTabbingType,
  QueryType,
} from 'features/CategoryAndPosisi/@types/InfiniteScrollTab'

export const SLICE_NAME_INFINITE_SCROLL_TAB = 'infiniteScrollTab'

export const initialState: InitialStateType = {
  isLoading: false,
  query: {
    page_index: 1,
    page_size: 20,
  },
  locationOptions: [],
  infiniteScrollTabList: [],
  selectedDataToDelete: null,
}

const slice = createSlice({
  name: SLICE_NAME_INFINITE_SCROLL_TAB,
  initialState,
  reducers: {
    resetState: () => initialState,
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload
    },
    setQuery: (state, { payload }: PayloadAction<Partial<QueryType>>) => {
      state.query = { ...payload } as InitialStateType['query']
    },
    setLocationOptions: (state, { payload }: PayloadAction<LocationType[]>) => {
      state.locationOptions = [...payload]
    },
    setInfiniteScrollTabList: (state, { payload }: PayloadAction<InfiniteScrollTabbingType[]>) => {
      state.infiniteScrollTabList = [...payload]
    },
    setSelectedDataToDelete: (
      state,
      { payload }: PayloadAction<Nullable<InfiniteScrollTabbingType>>,
    ) => {
      state.selectedDataToDelete = payload ? { ...payload } : null
    },
  },
})

export const {
  resetState,
  setIsLoading,
  setQuery,
  setLocationOptions,
  setInfiniteScrollTabList,
  setSelectedDataToDelete,
} = slice.actions
export default slice.reducer
