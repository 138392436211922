import { lazy } from 'react'
import generateRouterDetail from 'utils/helpers/generateRouterDetail'
import { ServiceLevelRBAC } from '../pages/ServiceLevel/rbac'

const CloseMessageConfig = lazy(() => import('../pages/CloseMessageConfig'))
const GlobalScheduledClose = lazy(() => import('../pages/GlobalScheduledClose'))
const ServiceLevel = lazy(() => import('../pages/ServiceLevel'))

type NamedRoutesType =
  | 'CloseMessageConfigRouter'
  | 'GlobalScheduledCloseRouter'
  | 'ServiceLevelRouter'
type PathListsType =
  | '/dashboard/location/close-message-config'
  | '/dashboard/location/global-scheduled-close'
  | '/dashboard/service-level'

type RouterInstanceType = RouterFeatureInterface<NamedRoutesType, PathListsType>

const CloseMessageConfigRouter: RouterInstanceType = {
  name: 'CloseMessageConfigRouter',
  Component: CloseMessageConfig,
  path: '/dashboard/location/close-message-config',
  routeType: 'PRIVATE',
  allowedRoles: [
    'AUTH_HO_OPX_HUB_MANAGER',
    'AUTH_HUB_AREA_MANAGER',
    'AUTH_HO_PRODUCT_MANAGER',
    'AUTH_HO_PRODUCT_HEAD',
    'AUTH_HO_QUALITY_ENGINEERING',
  ],
  isDashboard: true,
}

const GlobalScheduledCloseRouter: RouterInstanceType = {
  name: 'GlobalScheduledCloseRouter',
  Component: GlobalScheduledClose,
  path: '/dashboard/location/global-scheduled-close',
  routeType: 'PRIVATE',
  allowedRoles: [
    'AUTH_HO_OPX_HUB_MANAGER',
    'AUTH_HUB_AREA_MANAGER',
    'AUTH_HO_PRODUCT_MANAGER',
    'AUTH_HO_PRODUCT_HEAD',
    'AUTH_HO_QUALITY_ENGINEERING',
  ],
  isDashboard: true,
}

const ServiceLevelRouter: RouterInstanceType = {
  name: 'ServiceLevelRouter',
  Component: ServiceLevel,
  path: '/dashboard/service-level',
  routeType: 'PRIVATE',
  rbacPageID: ServiceLevelRBAC.pageID,
  isDashboard: true,
  allowedRoles: [],
}

const LocationRouter = [CloseMessageConfigRouter, GlobalScheduledCloseRouter, ServiceLevelRouter]

export const LocationRouterDetail = generateRouterDetail(LocationRouter)

export default LocationRouter
