import { memo, useContext } from "react";
import styled, { css } from "styled-components";
import storeContext from "storeContext";
import { TextBody } from "components";
import logo from "assets/images/logo-black.png";
import goodReceipt from "assets/images/receipt-note.png";

function Row({ data, dIndex }) {
  return (
    <TrRow>
      <Td ratio="0.5">
        <TextBody size="toast" weight="light" color="textSoft">
          {dIndex + 1}
        </TextBody>
      </Td>
      <Td ratio="1.5">
        <TextBody size="toast" weight="light" color="textSoft">
          {data?.product_sku}
        </TextBody>
      </Td>
      <Td ratio="3">
        <TextBody size="toast" weight="light" color="textSoft">
          {data?.product_name}
        </TextBody>
      </Td>
      <Td ratio="2">
        <TextBody size="toast" weight="light" color="textSoft">
          {data?.product_rack}
        </TextBody>
      </Td>
      <Td ratio="0.7">
        <TextBody size="toast" weight="light" color="textSoft">
          {data?.product_actual_quantity}
        </TextBody>
      </Td>
      <Td ratio="0.5">
        <TextBody size="toast" weight="light" color="textSoft">
          {data?.product_note}
        </TextBody>
      </Td>
    </TrRow>
  );
}

function Table({ data }) {
  return (
    <ContainerTable>
      <thead>
        <Tr color="tableHead">
          <Th ratio="0.5">Product ID</Th>
          <Th ratio="1.5">Barcode</Th>
          <Th ratio="3">Description</Th>
          <Th ratio="2">To Bin</Th>
          <Th ratio="0.7">Qty</Th>
          <Th ratio="0.5">Note</Th>
        </Tr>
      </thead>
      <tbody>
        {data?.map((el, idx) => (
          <Row key={idx} data={el} dIndex={idx} />
        ))}
      </tbody>
    </ContainerTable>
  );
}

function TemplateInboundCompleteSO_v2() {
  // const {
  //   state: { dataPrint }
  // } = useContext(storeContext.InboundContext);

  const { state: statePrintArea } = useContext(storeContext.PrintAreaContext);
  const { selectedDataInboundSO } = statePrintArea;
  const { dataCetakInboundSO } = statePrintArea;

  return (
    <Container>
      <Content bt>
        <SpaceBetween>
          <img alt="astro" src={logo} />
          <img alt="astro" src={goodReceipt} />
        </SpaceBetween>
      </Content>
      <Content>
        <InfoWrapper>
          <Info>
            <Field>From</Field>
            <Value>{selectedDataInboundSO?.location_origin_name}</Value>
          </Info>
          <Info>
            <Field>To</Field>
            <Value>{selectedDataInboundSO?.location_destination_name}</Value>
          </Info>
        </InfoWrapper>
        <InfoWrapper>
          <Info>
            <Field>SO Date</Field>
            <Value>{dataCetakInboundSO?.inbound_date}</Value>
          </Info>
          <Info>
            <Field>Di GRN Oleh</Field>
            <Value>{dataCetakInboundSO?.inbound_receive_by}</Value>
          </Info>
        </InfoWrapper>
        <InfoWrapper>
          <Info>
            <Field>No. SO</Field>
            <Value>{dataCetakInboundSO?.inbound_number}</Value>
          </Info>
          {/* <Info>
            <Field>To</Field>
            <Value>{selectedDataInboundSO?.location_destination_name}</Value>
          </Info> */}
        </InfoWrapper>
      </Content>
      <Table data={dataCetakInboundSO?.inbound_item_list} />
    </Container>
  );
}

export default memo(TemplateInboundCompleteSO_v2);

const Container = styled.div`
  width: 100vw;
  -webkit-print-color-adjust: exact !important;
`;

const ContainerTable = styled.div`
  width: 100%;
  background-color: #f3f4f5;
`;

const Content = styled.div`
  padding: 20px;
  ${({ bt }) =>
    bt &&
    css`
      border: 1px solid #bfc9d9;
    `}
`;

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InfoWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

const Info = styled.div`
  display: flex;
  flex: 1;
`;

const Field = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: #212121;
  min-width: 100px;
  position: relative;
  margin-right: 5px;
  ::after {
    content: ":";
    position: absolute;
    right: 0;
  }
`;

const Value = styled.div`
  font-size: 14px;
  color: #212121;
`;

const Tr = styled.tr`
  display: flex;
  padding: 16px 32px;
  background-color: #f3f4f5;
  width: 100vw;
`;

const TrRow = styled.tr`
  display: flex;
  border: 1px solid #f3f4f5;
  padding: 16px 32px;
  background-color: #ffffff;
  width: 100vw;
`;

const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  // background-color: #f3f4f5;
`;

const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`;
