import { lazy } from 'react'

import generateRouterDetail from 'utils/helpers/generateRouterDetail'

import { ProductAssetRouter } from './productAssetRouter'
import { AssetMasterRouter } from './assetMasterRouter'
import { PurchaseOrderRouter } from './purchaseOrderRouter'

import { SupplyOrderListRBACPage } from '../pages/SupplyOrderAssetList/rbac'
import { SupplyOrderCheckQtyRBACPage } from '../pages/SupplyOrderCheckQty/rbac'
import { SupplyOrderDetailRBACPage } from '../pages/SupplyOrderDetail/rbac'

const SupplyOrderList = lazy(() => import('../pages/SupplyOrderAssetList'))
const SupplyOrderDetail = lazy(() => import('../pages/SupplyOrderDetail'))
const SupplyOrderDetailEdit = lazy(() => import('../pages/SupplyOrderDetail'))
const SupplyOrderCreate = lazy(() => import('../pages/SupplyOrderDetail'))
const SupplyOrderAddAssetSO = lazy(() => import('pages/AddSupplyOrder'))
const SupplyOrderCheckQty = lazy(() => import('../pages/SupplyOrderCheckQty'))

// supply order routes
export const SupplyOrderListRoute: RouterFeatureInterface<
  'supplyOrderListRoute',
  '/dashboard/asset/supply-order-list'
> = {
  name: 'supplyOrderListRoute',
  Component: SupplyOrderList,
  path: '/dashboard/asset/supply-order-list',
  routeType: 'PRIVATE',
  rbacPageID: SupplyOrderListRBACPage.pageID,
  allowedRoles: [],
  isDashboard: true,
}

export const SupplyOrderDetailRoute: RouterFeatureInterface<
  'supplyOrderDetailRoute',
  '/dashboard/asset/supply-order-detail/:id'
> = {
  name: 'supplyOrderDetailRoute',
  Component: SupplyOrderDetail,
  path: '/dashboard/asset/supply-order-detail/:id',
  routeType: 'PRIVATE',
  rbacPageID: SupplyOrderDetailRBACPage.pageID,
  allowedRoles: [],
  isDashboard: true,
}

export const SupplyOrderEditRoute: RouterFeatureInterface<
  'supplyOrderDetailEditRoute',
  '/dashboard/asset/supply-order-edit/:id'
> = {
  name: 'supplyOrderDetailEditRoute',
  Component: SupplyOrderDetailEdit,
  path: '/dashboard/asset/supply-order-edit/:id',
  rbacPageID: SupplyOrderDetailRBACPage.pageID,
  routeType: 'PRIVATE',
  allowedRoles: [],
  isDashboard: true,
}

export const SupplyOrderCreateRoute: RouterFeatureInterface<
  'supplyOrderCreateRoute',
  '/dashboard/asset/supply-order-create'
> = {
  name: 'supplyOrderCreateRoute',
  Component: SupplyOrderCreate,
  path: '/dashboard/asset/supply-order-create',
  rbacPageID: SupplyOrderDetailRBACPage.pageID,
  routeType: 'PRIVATE',
  allowedRoles: [],
  isDashboard: true,
}

export const SupplyOrderAddProductSORoute: RouterFeatureInterface<
  'supplyOrderAddProductSORoute',
  '/dashboard/asset/add-asset-supply-order'
> = {
  name: 'supplyOrderAddProductSORoute',
  Component: SupplyOrderAddAssetSO,
  path: '/dashboard/asset/add-asset-supply-order',
  rbacPageID: SupplyOrderDetailRBACPage.pageID,
  routeType: 'PRIVATE',
  allowedRoles: [],
  isDashboard: true,
}

export const SupplyOrderCheckQtyRoute: RouterFeatureInterface<
  'supplyOrderCheckQtyRoute',
  '/dashboard/asset/supply-check-quantity/:id'
> = {
  name: 'supplyOrderCheckQtyRoute',
  Component: SupplyOrderCheckQty,
  path: '/dashboard/asset/supply-check-quantity/:id',
  routeType: 'PRIVATE',
  rbacPageID: SupplyOrderCheckQtyRBACPage.pageID,
  allowedRoles: [],
  isDashboard: true,
}

const AssetManagementRouter = [
  ...ProductAssetRouter,
  ...AssetMasterRouter,
  ...PurchaseOrderRouter,
  SupplyOrderListRoute,
  SupplyOrderDetailRoute,
  SupplyOrderCreateRoute,
  SupplyOrderAddProductSORoute,
  SupplyOrderCheckQtyRoute,
  SupplyOrderEditRoute,
]

export const AssetManagementRouterDetail = generateRouterDetail(AssetManagementRouter)
export default AssetManagementRouter
