import {
  InputAdornment as MuiInputAdornment,
  InputAdornmentProps as MuiInputAdornmentProps,
} from '@mui/material'
import styled from 'styled-components'

export type InputAdornmentPropsType = MuiInputAdornmentProps

const InputAdornment = styled(MuiInputAdornment)<InputAdornmentPropsType>``

export default InputAdornment
