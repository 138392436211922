import { Box, Image, Typography } from '@astro-ui/components'
import { NoDataImage } from '@astro-ui/assets'

export type NoDataPropsType = {
  dataNotFoundText?: string
}
const NoData = ({ dataNotFoundText }: NoDataPropsType) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    width="100%"
    height="200px"
  >
    <Image src={NoDataImage} width="auto" height="70%" objectFit="contain" />
    <Box display="flex" width="100%" p={3} textAlign="center" justifyContent="center">
      <Typography variant="h5">{dataNotFoundText || 'No Data'}</Typography>
    </Box>
  </Box>
)
export default NoData
