import {
  SET_IS_POPUP_CALL_DRIVER_V2_SHOW,
  SET_IS_POPUP_CALL_EXTERNAL_DRIVER_V2_SHOW,
  SET_IS_POPUP_TYPE_DRIVER_POPUP_V2,
} from 'storeContext/actionsType/packer'
import {
  setSelectedPacker,
  setIsLoading,
  setDrivers,
  actHandleSubmitChangeDriver,
  setIsPopupCheckResiShow,
} from './index'
import { getDrivers, getDriversSuper } from 'utils/api'
import { toastFailed, toastSuccess } from 'utils/toast'
import { getDriverByRole, putDriverActive_Driververify } from 'utils/apiList/driver'
import { orderWaitingDriver } from 'config/routes'
import { orderPackerWaitingDriver } from 'config/routes'
import { transformObjtoQuery } from 'helpers'
import { postAutoAssignOrder } from 'features/Order/services/autoAssign'
import { callErrorMsg } from 'helpers/errorMsg'

export const setPopupCallDriverV2 = (boolean) => {
  return {
    type: SET_IS_POPUP_CALL_DRIVER_V2_SHOW,
    payload: boolean,
  }
}

export const setPopupCallDriverExternalV2 = (boolean) => {
  return {
    type: SET_IS_POPUP_CALL_EXTERNAL_DRIVER_V2_SHOW,
    payload: boolean,
  }
}

export const setTypePopupDriverV2 = (payload) => {
  return {
    type: SET_IS_POPUP_TYPE_DRIVER_POPUP_V2,
    payload,
  }
}

export const getDriverList = (packer) => async (dispatch, getState) => {
  dispatch(setIsLoading(true))
  const { selectedLocation } = getState().statePacker
  const driverMegahubRole = 'MEGAHUB_DRIVER'
  const queryMegahubDriver =
    '?' +
    new URLSearchParams({
      locationType: selectedLocation?.location_type?.replace(/[^a-zA-Z0-9]/g, '_'),
    }).toString()
  try {
    const {
      data: { data },
    } =
      selectedLocation?.location_type?.toUpperCase() == 'MEGAHUB 2.0'
        ? await getDriverByRole(packer?.order_location_id, driverMegahubRole, queryMegahubDriver)
        : await getDrivers(packer?.order_location_id)
    const dataFormated = [...data].map((el) => {
      return { ...el, name: el?.driver_full_name }
    })
    dispatch(setDrivers(dataFormated))
    dispatch(setIsLoading(false))
  } catch (err) {
    toastFailed(`terjadi masalah saat mengambil data driver, coba beberapa saat lagi ${err}`)
    dispatch(setIsLoading(false))
  }
}
export const getDriverListSuper = (packer, query) => async (dispatch) => {
  dispatch(setIsLoading(true))
  getDriversSuper(packer.order_location_id, query)
    .then(({ data: { data } }) => {
      const dataFormated = [...data].map((el) => {
        return { ...el, name: el.driver_full_name }
      })
      dispatch(setDrivers(dataFormated))
      dispatch(setIsLoading(false))
    })
    .catch(() => {
      toastFailed(`terjadi masalah saat mengambil data driver, coba beberapa saat lagi`)
      dispatch(setIsLoading(false))
    })
}

export const actHandleCallDriverSuper =
  (packer, TYPE_DRIVER_POPUP = 'PANGGIL_DRIVER', localState, fromOrderPage, driver) =>
  async (dispatch, getState) => {
    dispatch(setSelectedPacker(packer))
    const locationType = getState().statePacker.selectedLocation.location_type
    const query = transformObjtoQuery({
      locationType: locationType,
    })
    if (!driver) {
      dispatch(setTypePopupDriverV2(TYPE_DRIVER_POPUP))
      dispatch(setPopupCallDriverV2(true))
      dispatch(getDriverListSuper(packer, query))
    }
  }

export const actHandleCallDriverV3 =
  (packer, TYPE_DRIVER_POPUP = 'PANGGIL_DRIVER', localState, fromOrderPage, driver) =>
  async (dispatch) => {
    if (fromOrderPage) {
      dispatch(setSelectedPacker(packer))
      if (!driver) {
        dispatch(setTypePopupDriverV2(TYPE_DRIVER_POPUP))
        dispatch(setPopupCallDriverV2(true))
        dispatch(getDriverList(packer))
        if (localState?.handleChangeSection) {
          localState.handleChangeSection(`${orderWaitingDriver}?status=ready for delivery`)
        }
      } else {
        if (localState?.handleChangeSection) {
          localState.handleChangeSection(`${orderWaitingDriver}?status=ready for delivery`)
        }
      }
    } else {
      const newPackerData = {
        ...packer,
        ...driver,
      }
      dispatch(setSelectedPacker(newPackerData))

      if (!driver) {
        dispatch(setTypePopupDriverV2(TYPE_DRIVER_POPUP))
        dispatch(setPopupCallDriverV2(true))
        dispatch(getDriverList(newPackerData))
        localState.handleChangeSection(`${orderPackerWaitingDriver}?status=waiting driver`)
      } else {
        dispatch(setIsPopupCheckResiShow(true))
      }
    }
  }

export const actHandleCallDriverV2 =
  (packer, TYPE_DRIVER_POPUP = 'PANGGIL_DRIVER', localState) =>
  async (dispatch, getState) => {
    if (TYPE_DRIVER_POPUP == 'UBAH_DRIVER') {
      const { driver_full_name, driver_id, driver_phone_number, driver_plate_number } =
        packer.order_driver
      const { order_id } = packer

      const payload = {
        driver_full_name: driver_full_name,
        driver_id: driver_id,
        driver_phone_number: driver_phone_number,
        driver_plate_number: driver_plate_number,
      }

      dispatch(actHandleSubmitChangeDriver(payload, order_id))
    }
    if (!packer.order_driver) {
      dispatch(setTypePopupDriverV2(TYPE_DRIVER_POPUP))
      dispatch(setPopupCallDriverV2(true))
      dispatch(getDriverList(packer))
      localState.handleChangeSection(`${orderWaitingDriver}?status=ready for delivery`)
    }
  }

export const actHandleCloseCallDriverV2 = () => async (dispatch) => {
  dispatch(setPopupCallDriverV2(false))
}

export const actHandleOpenCallExternalDriverV2 = () => async (dispatch) => {
  dispatch(setPopupCallDriverExternalV2(true))
}

export const actHandleCloseCallExternalDriverV2 = () => async (dispatch) => {
  dispatch(setPopupCallDriverExternalV2(false))
}

export const actHandleActivateStatusDriver =
  (driverID, dataToSend) => async (dispatch, getState) => {
    const {
      statePacker: { selectedPacker },
    } = getState()
    // console.log(selectedPacker);
    putDriverActive_Driververify(driverID, dataToSend)
      .then(() => {
        toastSuccess(`berhasil di non activekan`)
        dispatch(getDriverList(selectedPacker))
      })
      .catch((error) => {
        toastFailed(`ada yang salah, coba beberapa saat lagi`)
      })
  }
export const callAutoAssignDriver = (payload) => async (dispatch, getState) => {
  try {
    dispatch(setIsLoading(true))
    const responseAutoAssign = await postAutoAssignOrder(payload)
    if (responseAutoAssign) {
      toastSuccess(`Order ${payload?.order_id} berhasil auto assign`)
      if (payload?.callback) payload.callback()
    }
  } catch (err) {
    callErrorMsg(err)
  } finally {
    dispatch(setIsLoading(false))
  }
}
