import {
  SET_PURCHASE_ORDER,
  SET_LAST_PARAMS,
  SET_VENDORS,
  SET_SELECTED_PURCHASE_ORDER,
  SET_LINK_TEMPLATE_CSV_PO,
  SET_CHECKER,
  SET_FEATURE_FLAG,
  SET_PO_REFERENCE_DIALOG_STATE,
  SET_SELECTED_PO_REFERENCE_DATA,
  SET_CREATE_PO_REFERENCE_LOADING,
} from 'storeContext/actionsType/supplyOrder'
import { toastFailed, toastSuccess } from 'utils/toast'
import { setIsLoading, setPaginationDataSo } from './index'
import {
  getVendorList,
  putPurchaseOrderLite,
  getPurchaseOrderCsvTemplate,
  postPurchaseOrderCsv,
  postPurchaseOrderStatus,
  getPurchaseOrderDetailLiteInbound,
  putPurchaseOrderDetailLiteInbound,
  getFeatureFlag,
  getPurchaseOrdersLiteV2,
  createPoReference,
  getStaffManagement,
  getVendorPurchaseOrder,
  postPurchaseOrderItemScan,
} from 'utils/api'
import { getPoDetail, putPurchaseOrderLiteStatus } from 'utils/apiList/purcaseOrderLite'
import { getPurchaseOrderDetailAPI } from 'features/AssetManagement/services/purchaseOrder'
import { callErrorMsg } from 'helpers/errorMsg'
import { AUTH_INBOUND_CHECKER } from 'middleware/privateRoute'

export function setChecker(payload) {
  return {
    type: SET_CHECKER,
    payload,
  }
}

export function setFeatureFlag(payload) {
  return {
    type: SET_FEATURE_FLAG,
    payload,
  }
}

export function setLinkTemplateCsvPo(payload) {
  return {
    type: SET_LINK_TEMPLATE_CSV_PO,
    payload: payload,
  }
}

export function setSelectedPurchaseOrder(payload) {
  return {
    type: SET_SELECTED_PURCHASE_ORDER,
    payload: payload,
  }
}
export function setPurchaseOrders(payload) {
  return {
    type: SET_PURCHASE_ORDER,
    payload: payload,
  }
}

export function setLastParams(payload) {
  return {
    type: SET_LAST_PARAMS,
    payload: payload,
  }
}

export function setVendors(payload) {
  return {
    type: SET_VENDORS,
    payload: payload,
  }
}

export const setPoReferenceDialogState = (payload) => ({
  type: SET_PO_REFERENCE_DIALOG_STATE,
  payload,
})

export const setSelectedPoReferenceData = (payload) => ({
  type: SET_SELECTED_PO_REFERENCE_DATA,
  payload,
})

export const setCreatePoReferenceLoading = (payload) => ({
  type: SET_CREATE_PO_REFERENCE_LOADING,
  payload,
})

export const actGetChecker = (locationId, callback) => (dispatch) => {
  dispatch(setIsLoading(true))
  return getStaffManagement(locationId, AUTH_INBOUND_CHECKER)
    .then(({ data }) => {
      const list = data.data.map((item) => ({
        name: item.fullName,
        value: item.id,
      }))

      if (callback) {
        callback(list)
      }

      dispatch(setChecker(list))
    })
    .catch((e) => {
      callErrorMsg(e)
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actGetFeatureFlag = (locationId, feature, callback) => async (dispatch) => {
  dispatch(setIsLoading(true))

  try {
    const featureFlag = await getFeatureFlag(locationId, feature)
    const isAllowed = featureFlag.data?.data?.active

    if (callback) {
      callback(isAllowed)
    }

    dispatch(setFeatureFlag({ [feature]: isAllowed }))
  } catch (err) {
    callErrorMsg('')
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const actHandleGetPurchaseOrdersV2 = (params) => (dispatch) => {
  dispatch(setIsLoading(true))
  return getPurchaseOrdersLiteV2(params)
    .then(({ data }) => {
      const pagination = {
        pageSize: data.pagination.pageSize,
        currentPage: data.pagination.pageIndex,
        currentItem: data.pagination.numberOfElements,
      }

      dispatch(setLastParams(params))
      dispatch(setPaginationDataSo(pagination))
      dispatch(setPurchaseOrders(data.data))
    })
    .catch((err) => {
      callErrorMsg(err)
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actGetVendorList =
  (vendorName = '', cb = () => {}) =>
  async (dispatch) => {
    try {
      const {
        data: { data },
      } = await getVendorList({
        page_size: 50,
        page_index: 1,
        company_name: vendorName,
      })

      const formated = data.map((el) => {
        return { ...el, vendor_id: el.id, name: el.company_name }
      })
      dispatch(setVendors(formated))
      if (cb) cb(formated)
    } catch (err) {
      callErrorMsg(err)
    }
  }

export const actHandleGetVendors =
  (cb = () => {}) =>
  (dispatch) => {
    dispatch(setIsLoading(true))
    return getVendorPurchaseOrder({})
      .then(({ data }) => {
        const formated = data.map((el) => {
          return { ...el, name: el.vendor_name }
        })
        dispatch(setVendors(formated))
        cb(formated)
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

export const actHandleGetDetailPurchaseOrderInbound =
  (id, cb = () => {}, options = {}, checkerCb = () => {}) =>
  (dispatch) => {
    dispatch(setIsLoading(true))

    const query = options.query ? options.query : ''
    return getPurchaseOrderDetailLiteInbound(id, query)
      .then(({ data }) => {
        const filteredData = {
          ...data,
          supply_order_items: [...data.supply_order_items].map((el) => {
            return {
              ...el,
              product_detail_expired_response: {
                expiryDate: null,
                isActive: true,
                locationId: 0,
                locationName: '',
                productDetailId: 0,
                productDetailImage: {
                  id: 0,
                  url: '',
                },
                productId: 0,
                productName: '',
                qrCode: '',
                rackId: null,
                rackName: null,
                stock: 0,
                ...el.product_detail_expired_response,
              },
            }
          }),
        }

        dispatch(actGetChecker(data.location_destination_id, checkerCb))
        dispatch(actGetFeatureFlag(data.location_destination_id, 'productivity_tracker_v5'))
        dispatch(setSelectedPurchaseOrder(filteredData))
        cb(filteredData)
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

export const actHandleGetDetailPurchaseOrder = (id, cb) => async (dispatch) => {
  dispatch(setIsLoading(true))
  try {
    const {
      data: { data },
    } = await getPoDetail(id)
    dispatch(setSelectedPurchaseOrder(data))
    if (cb) cb(data)
  } catch (err) {
    callErrorMsg(err)
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const actHandleGetDetailPurchaseOrderAsset = (id) => async (dispatch) => {
  dispatch(setIsLoading(true))

  try {
    const { data } = await getPurchaseOrderDetailAPI(id)
    const { detail, items, asset_inventories } = data

    const convertedItems = items.map((item) => ({
      product_id: item.asset_id,
      product_sku: item.asset_sku,
      product_name: item.asset_name,
      business_taggings: [],
      request_quantity: item.request_qty,
      uom: item.uom,
      buy_price: item.price,
      discounted_price: item.discount,
      total: item.amount,
      subtotal_tax_price: item.tax_amount,
      tax_rate: `${item.tax_rate}%`,
      product_stock: 0,
    }))

    const convertedData = {
      vendor_name: detail?.vendor?.vendor_name || `-`,
      ref_number: detail?.reference_number || `-`,
      request_shipping_date: detail?.es_shipping_date || `-`,
      destination_name: detail?.destination?.location_name || `-`,
      destination_id: detail?.destination?.location_id || `-`,
      payment_term: detail?.payment_term || `-`,
      expiry_date: detail?.expiry_date || ``,
      po_ref_id: undefined,
      po_ref_number: `-`,
      request_by: detail?.pic || `-`,
      status: detail?.status || `-`,
      sub_total: detail.subtotal,
      discount: detail?.subtotal_discount,
      tax_rate: `-`,
      tax: detail.subtotal_tax,
      shipping_cost: detail.subtotal_delivery_fee,
      total: detail.total,
      items: convertedItems,
      purchase_order_number: detail?.po_number || `-`,
      po_asset_inventory_list: asset_inventories || [],
    }
    dispatch(setSelectedPurchaseOrder(convertedData))
    dispatch(setIsLoading(false))
  } catch (err) {
    callErrorMsg(err)
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const actScanItemProduct = (data, callback) => async (dispatch, getState) => {
  const { selectedPurchaseOrder } = getState().stateSupplyOrder
  try {
    dispatch(setIsLoading(true))

    const responseData = await postPurchaseOrderItemScan(
      selectedPurchaseOrder.supply_order_id,
      data,
    )
    if (callback) callback(true, responseData.data.data)
  } catch (err) {
    if (callback) callback(false, err)
  } finally {
    dispatch(setIsLoading(false))
  }
}

export const actHandleUpdatePo =
  (id, payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setIsLoading(true))
    return putPurchaseOrderLite(id, payload)
      .then(({ data }) => {
        cb(data)
        setTimeout(() => {
          toastSuccess('Data berhasil diupdate')
        }, 300)
      })
      .catch(() => {
        toastFailed('Data gagal diupdate')
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

export const actHandleGetLinkTemplate = () => (dispatch) => {
  return getPurchaseOrderCsvTemplate().then(({ data }) => {
    dispatch(setLinkTemplateCsvPo(data.data.url))
  })
}

export const actHandleUploadCsv =
  (payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setIsLoading(true))
    return postPurchaseOrderCsv(payload)
      .then(({ data }) => {
        cb(data)
        if (data.status === 'success') {
          toastSuccess('Data berhasil diupload')
        } else {
          window.open(data.url_download, '_blank')
          toastFailed('Terdapat data tidak sesuai')
        }
      })
      .catch(() => {
        toastFailed('Data gagal diupload')
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

export const actHandleUpdatePoStatus = (id, status) => (dispatch, getState) => {
  const { lastParams } = getState().stateSupplyOrder
  dispatch(setIsLoading(true))
  return postPurchaseOrderStatus(id, status)
    .then(() => {
      toastSuccess('Data berhasil diupdate')
      dispatch(actHandleGetPurchaseOrdersV2(lastParams))
    })
    .catch(() => {
      toastFailed('Data gagal diupdate')
    })
    .finally(() => {
      dispatch(setIsLoading(false))
    })
}

export const actHandleUpdateInboundPo =
  (id, payload, cb = () => {}) =>
  (dispatch) => {
    dispatch(setIsLoading(true))
    return putPurchaseOrderDetailLiteInbound(id, payload)
      .then(({ data }) => {
        cb(data)
        toastSuccess('Data berhasil diupdate')
      })
      .catch((error) => {
        callErrorMsg(error)
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

export const updateStatusPOLite =
  (option = {}, payload = {}, callback = () => {}) =>
  async (dispatch, getState) => {
    const { id, status } = option

    const { lastParams } = getState().stateSupplyOrder
    dispatch(setIsLoading(true))

    putPurchaseOrderLiteStatus(id, status, payload)
      .then(({ data }) => {
        toastSuccess(`PO berhasil di update`)
        dispatch(actHandleGetPurchaseOrdersV2(lastParams))
        callback()
      })
      .catch((err) => {
        callErrorMsg(err)
      })
      .finally(() => {
        dispatch(setIsLoading(false))
      })
  }

export const actCreatePoReference = (callback) => async (dispatch, getState) => {
  const { poReference } = getState().stateSupplyOrder
  dispatch(setCreatePoReferenceLoading(true))
  try {
    await createPoReference(poReference.selectedData?.id)
    callback({ error: null })
  } catch (error) {
    callback({ error })
  } finally {
    dispatch(setCreatePoReferenceLoading(false))
  }
}
