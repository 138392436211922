import { memo, useState, useMemo, useEffect } from 'react'
import { InputDropDown } from 'components'
import { callErrorMsg } from 'helpers/errorMsg'
import { getApi } from 'utils/api'
import debounce from 'utils/debounce'
function DropdownAPI({ url, keyName, idValue, value, defaultOpt, handleClickOption, ...rest }) {
  const [isLoading, setIsLoading] = useState(false)
  const [selected, setSelected] = useState({})
  const [listOptions, setListOptions] = useState([])
  const optSelected = useMemo(() => {
    const finded = [...listOptions, selected].find((el) => el[keyName?.value] === idValue)
    if (finded) {
      return finded[keyName?.label]
    }
    return ''
  }, [idValue, listOptions])
  useEffect(() => {
    if (Array.isArray(defaultOpt) && defaultOpt.length) {
      const ids = [...defaultOpt].filter((el) => el[keyName.value]).map((el) => el[keyName.value])
      const removeEmpty = [...defaultOpt].filter((el) => el[keyName.value])
      const filtered = listOptions.filter((el) => !ids.includes(el[keyName.value]))

      setListOptions([...removeEmpty, ...filtered])
    }
  }, [defaultOpt])
  const handleFetchData = async () => {
    setIsLoading(true)
    try {
      const { data } = await getApi(url, {})
      if (!keyName) return false
      const res = data.data || data.content || []
      const formated = res.map((el) => ({
        ...el,
        name: el[keyName?.label],
      }))
      setListOptions(formated)
    } catch (error) {
      callErrorMsg(error)
    } finally {
      setIsLoading(false)
    }
  }
  const handleFirsClick = () => {
    handleFetchData()
  }
  const fetchData = async (prop) => {
    try {
      const { data } = await getApi(url, { name: prop })
      const res = data.data || data.content || []
      const formated = res.map((el) => ({
        ...el,
        name: el[keyName?.label],
      }))

      const arr = [...new Set(formated)]
      setListOptions(arr)
    } catch (error) {
      callErrorMsg(error)
    }
  }
  const deb = debounce(fetchData, 400)
  const handleSearchOption = (e) => {
    const {
      target: { value },
    } = e
    deb(value)
  }
  const handleClickOptionSet = (opt) => {
    setSelected(opt)
    handleClickOption(opt)
  }
  return (
    <InputDropDown
      handleClickOption={handleClickOptionSet}
      value={optSelected}
      listOptions={listOptions}
      isFirstClick
      onFocus={handleFirsClick}
      isLoading={isLoading}
      isManualFilter
      handleSearchOption={handleSearchOption}
      {...rest}
    />
  )
}

export default memo(DropdownAPI)
