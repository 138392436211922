import {
  DialogContentText as MuiDialogContentText,
  DialogContentTextProps as MuiDialogContentTextProps,
} from '@mui/material'
import styled from 'styled-components'

export type DialogContentTextPropsType = MuiDialogContentTextProps

const DialogContentText = styled(MuiDialogContentText)<DialogContentTextPropsType>``

export default DialogContentText
