import { Box, Typography } from '@astro-ui/components'
import { useAppSelector, shallowEqual } from 'store'
import QRCode from 'react-qr-code'
import AstroLogo from 'assets/images/astro-horizontal.svg'

const getPixelBasedOnCm = (centimeter: number) => centimeter * 37.7952755906

const TemplatePriceTagPrint = () => {
  const priceTagList = useAppSelector((state) => state.priceTag.priceTagListToPrint, shallowEqual)

  return (
    <Box display="flex" flexWrap="wrap">
      {priceTagList.map((item) => (
        <div
          key={item.id}
          style={{
            width: `${getPixelBasedOnCm(6)}px`,
            height: `${getPixelBasedOnCm(3.8)}px`,
            border: '1px solid #404040',
            boxSizing: 'border-box',
            padding: '8px',
            marginRight: '10px',
            marginBottom: '10px',
            position: 'relative',
            pageBreakInside: 'avoid',
          }}
        >
          <Box display="flex" justifyContent="flex-end">
            <img src={AstroLogo} alt="astro" height="20px" />
          </Box>
          <Box display="flex" flexDirection="column" marginTop="3px">
            <Box height="52px" style={{ boxSizing: 'border-box' }}>
              <Typography fontSize="13px" color="#212121" fontWeight="bold" lineHeight="18px">
                {item.product_name}
              </Typography>
            </Box>
            <Typography fontSize="13px" color="#646464" fontWeight="bold">
              {item.grammation}
            </Typography>
          </Box>
          <Typography
            fontSize="26px"
            color="#E61B00"
            fontWeight="bold"
            style={{ position: 'absolute', bottom: '5px', left: '5px' }}
          >
            {item.price_fmt}
          </Typography>
          <QRCode
            value={item.label_serial}
            size={45}
            style={{ position: 'absolute', bottom: '5px', right: '5px' }}
            viewBox="0 0 45 45"
          />
        </div>
      ))}
    </Box>
  )
}
export default TemplatePriceTagPrint
