import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SLICE_NAME, removeCategory, fetchHubList } from './categoryThunk'

export type SelectedCategoryStateType = {
  category_id: number
  category_name: string
}

export type HubListStateType = {
  locationId: number
  name: string
}

export interface CategoryStateType {
  isLoading: boolean
  isShowModalDelete: boolean
  selectedCategory: SelectedCategoryStateType
  hubList: HubListStateType[]
  query: {
    selectedHub: HubListStateType
  }
}

export const initialState: CategoryStateType = {
  isLoading: false,
  isShowModalDelete: false,
  selectedCategory: { category_id: 0, category_name: '' },
  hubList: [],
  query: {
    selectedHub: { locationId: 0, name: '' },
  },
}

const category = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setIsShowModalDelete: (state, action: PayloadAction<{ value: boolean }>) => {
      const { value } = action.payload
      state.isShowModalDelete = value
    },
    setSelectedCategory: (state, action: PayloadAction<{ value: SelectedCategoryStateType }>) => {
      const { value } = action.payload
      state.selectedCategory = value
    },
    setQuery: (
      state,
      action: PayloadAction<{
        value: CategoryStateType['query']['selectedHub']
        name: keyof CategoryStateType['query']
      }>,
    ) => {
      const { name, value } = action.payload
      state.query[name] = value as never
    },
  },
  extraReducers(builder) {
    builder
      .addCase(removeCategory.pending, (state) => {
        state.isLoading = true
      })
      .addCase(removeCategory.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(removeCategory.fulfilled, (state) => {
        state.isLoading = false
        state.isShowModalDelete = false
        state.selectedCategory = initialState.selectedCategory
      })
      .addCase(fetchHubList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchHubList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchHubList.fulfilled, (state, action) => {
        const data = action.payload
        state.isLoading = false
        state.hubList = data.data.map((el) => ({
          name: el.location_name,
          locationId: el.location_id,
        }))
      })
  },
})

export const { reset, setIsShowModalDelete, setSelectedCategory, setQuery } = category.actions
export default category.reducer
