import { memo } from "react";
import styled from "styled-components";
import { Checkbox, TextBody } from "components";

function RowProductDiscount({ data }) {
  return (
    <Tr>
      <Td>
        <WrapperCheck>
          <Checkbox />
        </WrapperCheck>
      </Td>
      <Td>
        <TextBody size="smaller" weight="light">
          Senayan
        </TextBody>
      </Td>
      <Td min="350px">
        <OverFlowSingle line="1">
          Pisang Sunpride Kuning Man 50...
        </OverFlowSingle>
      </Td>
      <Td>
        <TextBody size="smaller" weight="light">
          Rp 10.000
        </TextBody>
      </Td>
      <Td>
        <TextBody size="smaller" weight="light">
          50
        </TextBody>
      </Td>
    </Tr>
  );
}

RowProductDiscount.defaultProps = {
  data: {}
};

export default memo(RowProductDiscount);

const Tr = styled.tr`
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
`;
const Td = styled.td`
  color: ${({ theme: { colors }, color = "textSoft" }) => colors[color]};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  padding: 16px;
  padding-right: 20px;
  min-width: ${({ min }) => min};
`;

const OverFlowSingle = styled.div`
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
`;

const WrapperCheck = styled.div`
  display: grid;
  place-items: center;
`;
