import { Get } from 'config/apiServiceConfig'
import { GetCentralWarehouseStaffResponseType } from 'features/Enterprise/HRIS/services/account/centralWarehouseStaff'

import AxiosInstance from 'config/apiServiceApiGateway'

export interface GetMasterApprovalListServicesInterface {
  data: {
    after_changes: string
    attachment_url: string
    before_changes: string
    created_by: string
    created_at: string
    issue: number
    issue_value: string
    issue_notes: string
    reason_notes: string
    id: number
    location_id: number
    location_name: string
    location_type: string
    module: string
    module_id: number
    shift_description: string
    staff_id: number
    staff_name: string
    staff_role: string
    status: number
    status_notes: string
    updated_by: string
    updated_at: string
    start_date: string
    responded_by: string
    responded_at: string
  }[]
  pagination: {
    number_of_elements: number
    page_index: number
    page_size: number
    total_pages: number
    total_element: number
  }
}

export interface QueryParamsInterface {
  location_id: string
  status: string
  search: string
  sort: string
  direction: string
  page_size: number
  page_index: number
  start_date: string
  end_date: string
  requested_by: string
  department: string
  issue: string
}

export type DepartementNameListType = {
  names: string[]
}

export type GetAllDepartementListResponseType = {
  data: { [key: string]: DepartementNameListType }
}

export type GetAllDepartementProcessedType = { content: string[]; name: string }

export const getMasterApproval = (params: QueryParamsInterface) =>
  AxiosInstance.Get<GetMasterApprovalListServicesInterface>({
    url: '/staff-management/internal/:version/approval/list',
    params,
    version: 'v1',
  })

export const putUpdateMasterApproval = (
  data: { status: number; statusNote?: string; updatedBy: string },
  approvalId: number,
) =>
  AxiosInstance.Put<GetMasterApprovalListServicesInterface>({
    url: `/staff-management/internal/:version/approval/${approvalId}`,
    data,
    version: 'v1',
    convertRequest: true,
  })

export const postBulkUpdateMasterApproval = (data: {
  ids: number[]
  status: number
  statusNote?: string
  updatedBy: string
}) =>
  AxiosInstance.Post<GetMasterApprovalListServicesInterface>({
    url: '/staff-management/internal/:version/approval/bulk-update-status',
    data,
    version: 'v1',
    convertRequest: true,
  })

export const fetchAdminDriver = (id: number) =>
  Get<{ data: GetCentralWarehouseStaffResponseType['data'][0] }>({
    url: `/api/driver-admin/${id}`,
  })

export const getDepartementList = () =>
  Get<GetAllDepartementListResponseType>({
    url: `/api/staff-management/admin/all-department-list`,
  })
