/* eslint-disable jsx-a11y/control-has-associated-label */
import styled from 'styled-components'
import { shallowEqual, useSelector } from 'react-redux'

import { Box, Typography } from '@astro-ui/components'
import logoAstro from 'assets/images/logo-black.png'

import { formatDate } from 'helpers'

const Barcode = require('react-barcode')

const filterSelectedState = (state: StoreStateType) => {
  const { printSTO } = state.printArea
  return { printSTO }
}

function TemplatePrintSTO() {
  const { printSTO } = useSelector(filterSelectedState, shallowEqual)

  return (
    <StyledContainer>
      <Box padding="30px 24px 18px" borderBottom=" 1px solid #d6dfeb">
        <img src={logoAstro} alt="logo" />
      </Box>
      <Box padding="18px 24px" display="flex" justifyContent="space-between">
        <Box>
          <Typography fontSize={16} fontWeight={700} mb="8px">
            Cycle Count Manual Document
          </Typography>
          <Typography fontSize={16} fontWeight={700} mb="8px">
            Stock Opname Task
            <Typography display="inline-block" fontWeight={400} ml="8px">
              {printSTO.stockOpnameNumber || '-'}
            </Typography>
          </Typography>
          <Typography fontSize={16} fontWeight={700} mb="8px">
            Tanggal dibuat :
            <Typography display="inline-block" fontWeight={400} ml="4px">
              {printSTO.createdAt ? formatDate(printSTO.createdAt, 'DD MMMM YYYY') : '-'}
            </Typography>
          </Typography>
          <Typography fontSize={16} fontWeight={700} mb="8px">
            Total SKU / SLOC :
            <Typography display="inline-block" fontWeight={400} ml="4px">
              {printSTO.totalSkuRack || 0}
            </Typography>
          </Typography>
        </Box>
        <Box pt={28}>
          <Typography textAlign="right" fontSize={16} fontWeight={700} mb="8px">
            Lokasi :
            <Typography display="inline-block" fontWeight={400} ml="4px">
              {printSTO.locationName || '-'}
            </Typography>
          </Typography>
          <Typography textAlign="right" fontSize={16} fontWeight={700} mb="8px">
            Type / Reference :
            <Typography display="inline-block" fontWeight={400} ml="4px">
              {printSTO.typeReference || '-'}
            </Typography>
          </Typography>
          <Typography textAlign="right" fontSize={16} fontWeight={700} mb="8px">
            Assign :
            <Typography display="inline-block" fontWeight={400} ml="4px">
              {printSTO.assignName || '-'}
            </Typography>
          </Typography>
        </Box>
      </Box>
      <Box padding="0 24px 0">
        <StyledTable>
          <tr>
            <th style={{ width: '30px' }}>No.</th>
            <th className="text-left">SLOC</th>
            <th>No. SKU</th>
            <th style={{ width: '110px' }} className="text-left">
              Nama Produk
            </th>
            <th style={{ width: '90px' }}>Jumlah Good Stock</th>
            <th style={{ width: '80px' }}>Jumlah Bad Stock</th>
            <th style={{ width: '80px' }}>Expiry Date</th>
            <th style={{ width: '70px' }}>Catatan / Remark</th>
          </tr>
          {printSTO.stockOpnameItems.map((el, index) => (
            <tr key={el.uniqueId}>
              <td>{index + 1}</td>
              <td>
                {el.rackName ? (
                  <Barcode
                    value={el.rackName}
                    height={32}
                    width={0.7}
                    flat
                    fontSize={12}
                    marginTop={25}
                    marginLeft={12}
                  />
                ) : (
                  '-'
                )}
              </td>
              <td>
                {el.productSku ? (
                  <Barcode
                    value={el.productSku}
                    height={32}
                    width={0.7}
                    flat
                    fontSize={12}
                    marginTop={25}
                    marginLeft={12}
                  />
                ) : (
                  '-'
                )}
              </td>
              <td className="text-left">{el.productName || '-'}</td>
              <td />
              <td />
              <td />
              <td />
            </tr>
          ))}
        </StyledTable>
      </Box>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  @media print {
    @page {
      margin: 0;
    }
    body {
      padding-top: 72px;
      padding-bottom: 72px;
      padding-left: 0;
      padding-right: 0;
    }
  }
`

const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;

  & th {
    background-color: #f3f4f5;
    padding: 24px 4px;
  }

  & td {
    padding: 0 4px;
  }

  & th.text-left,
  td.text-left {
    text-align: left;
  }

  & th,
  td {
    font-size: 11px;
    border: 1px solid #d6dfeb;
    text-align: center;
  }
`

export default TemplatePrintSTO
