import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getImageRules,
  type ImageVariantIdType as CustomImageVariantIdType,
} from 'utils/apiList/images'
import { callErrorMsg } from 'helpers/errorMsg'
import axiosInstanceImageService from 'config/apiServiceImage'
import { withImageService, ImageVariantIdType } from '@astronautsid/wpe-utils'
import { ImagePayloadType, ImageStateType } from 'features/CategoryAndPosisi/@types/CategoryAddEdit'
import { SLICE_NAME, setImageRules } from './slice'

const { ServiceInternalImageUpload } = withImageService(axiosInstanceImageService)

type ReturnImageUploadServiceType = Awaited<ReturnType<typeof ServiceInternalImageUpload>>

export const getImageCategoryRules = createAsyncThunk(
  `${SLICE_NAME}/getImageCategoryRules`,
  async (typeId: CustomImageVariantIdType, { dispatch }) => {
    try {
      const {
        data: { data },
      } = await getImageRules(typeId)

      dispatch(setImageRules({ key: typeId, value: data }))
    } catch (error) {
      callErrorMsg(error)
    }
  },
)

export const uploadCategoryImages = createAsyncThunk(
  `${SLICE_NAME}/uploadCategoryImages`,
  async (
    { typeId, payload }: { typeId: CustomImageVariantIdType; payload: ImageStateType[] },
    { getState },
  ) => {
    const {
      auth: { userData },
    } = getState() as StoreStateType

    try {
      const data = await ServiceInternalImageUpload(
        payload
          .filter(({ file }) => file)
          .map(({ file }) => ({
            data: { file_input: file as File, created_by: userData.id },
            typeId: typeId as ImageVariantIdType,
          })),
      )

      return makePayloadImage(data, payload)
    } catch (error) {
      callErrorMsg(error)
      return []
    }
  },
)

const getImageURLByType = (
  images: ReturnImageUploadServiceType[0]['images'],
  type: ReturnImageUploadServiceType[0]['images'][0]['type'],
) => {
  const matchImageByType = images.find((image) => image.type === type) || images[0]

  return matchImageByType.imageUrl
}

const makePayloadImage = (
  response: ReturnImageUploadServiceType,
  imageStatePayload: ImageStateType[],
): Array<Nullable<Omit<ImagePayloadType, 'version_type_id'>>> => {
  let currentIndexImageAlreadyUpload = 0

  return imageStatePayload.map(({ file, imageToPreview, image }) => {
    if (!imageToPreview) return null

    if (file) {
      const responseUpload = response[currentIndexImageAlreadyUpload]
      let resultImageUpload = null
      if (!responseUpload?.error) {
        resultImageUpload = {
          image_url_small: getImageURLByType(responseUpload.images, 'small'),
          image_url_medium: getImageURLByType(responseUpload.images, 'medium'),
          image_url_large: getImageURLByType(responseUpload.images, 'large'),
        }
      }
      currentIndexImageAlreadyUpload += 1
      return resultImageUpload
    }

    return image
  })
}
