import { useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'

type StatusType = {
  statusName: string
  statusNotes: string
}

type LocationItemType = {
  location_id: number
  location_name: string
  location_type: string
  role_names: string[]
}

const selectAuthByLocation = (state: StoreStateType) => {
  const { userData } = state.auth

  return {
    isSuperAdmin: userData.authorities.some((item: { name: string }) => item.name === 'AUTH_ADMIN'),
    locationRoles: userData.location_roles,
  }
}

export const VALIDATION_MESSAGE = {
  lostBarangHilang: 'Status lost barang hilang tidak dapat berpindah status lagi',
  noAccessRole: 'Anda tidak memiliki akses role untuk mengubah status',
}

export const useAccessRoleTanggungRenteng = () => {
  const authState = useSelector(selectAuthByLocation, shallowEqual)

  const [userRolesOnSpecificLocation, setUserRolesOnSpecificLocation] = useState<string[]>()
  const [currentStatus, setCurrentStatus] = useState<StatusType>({
    statusName: '',
    statusNotes: '',
  })
  const [nextStatus, setNextStatus] = useState<StatusType>({ statusName: '', statusNotes: '' })

  const isStatusChanged = () =>
    currentStatus.statusName &&
    nextStatus.statusName &&
    (currentStatus.statusName !== nextStatus.statusName ||
      currentStatus.statusNotes !== nextStatus.statusNotes)

  const saveTemporaryCurrentStatus = (status: StatusType) => {
    setCurrentStatus(status)
  }

  const determineAuthUserRolesByLocation = (locationId: number) => {
    if (!authState.isSuperAdmin) {
      const currentLocation = authState.locationRoles.find(
        (locationItem: LocationItemType) => locationItem.location_id === +locationId,
      )
      if (currentLocation) setUserRolesOnSpecificLocation(currentLocation.role_names)
    }
  }

  const isStatusLost = (statusName: string) => statusName === 'lost'
  const isStatusNoteDalamPencarian = (statusNotes: string) => statusNotes === 'dalam pencarian'
  const isStatusNoteBarangHilang = (statusNotes: string) => statusNotes === 'barang hilang'
  const isStatusAvailable = (statusName: string) => statusName === 'available'
  const isStatusBad = (statusName: string) => statusName.includes('bad')

  const isNotAllowedToChangeStatusFromLostHilang = () =>
    isStatusLost(currentStatus.statusName) &&
    isStatusNoteBarangHilang(currentStatus.statusNotes) &&
    !authState.isSuperAdmin

  const isNotAllowedToChangeStatusByRoles = () => {
    const conditionOne =
      isStatusAvailable(currentStatus.statusName) &&
      isStatusBad(nextStatus.statusName) &&
      !userRolesOnSpecificLocation?.includes('INVENTORY_CONTROLLER') &&
      !userRolesOnSpecificLocation?.includes('SUPPLY_CHAIN') &&
      !authState.isSuperAdmin &&
      !userRolesOnSpecificLocation?.includes('HO_ADJUSTMENT')

    const conditionTwo =
      (isStatusAvailable(currentStatus.statusName) || isStatusBad(currentStatus.statusName)) &&
      isStatusLost(nextStatus.statusName) &&
      isStatusNoteDalamPencarian(nextStatus.statusNotes) &&
      !userRolesOnSpecificLocation?.includes('INVENTORY_CONTROLLER') &&
      !authState.isSuperAdmin &&
      !userRolesOnSpecificLocation?.includes('HO_ADJUSTMENT')

    const conditionThree =
      isStatusLost(currentStatus.statusName) &&
      isStatusNoteDalamPencarian(currentStatus.statusNotes) &&
      isStatusAvailable(nextStatus.statusName) &&
      !userRolesOnSpecificLocation?.includes('INVENTORY_CONTROLLER') &&
      !authState.isSuperAdmin &&
      !userRolesOnSpecificLocation?.includes('HO_ADJUSTMENT')

    const conditionFour =
      isStatusBad(currentStatus.statusName) &&
      isStatusAvailable(nextStatus.statusName) &&
      !userRolesOnSpecificLocation?.includes('INVENTORY_CONTROLLER') &&
      !userRolesOnSpecificLocation?.includes('SUPPLY_CHAIN') &&
      !authState.isSuperAdmin &&
      !userRolesOnSpecificLocation?.includes('HO_ADJUSTMENT')

    const conditionFive =
      (isStatusAvailable(currentStatus.statusName) ||
        isStatusBad(currentStatus.statusName) ||
        (isStatusLost(currentStatus.statusName) &&
          isStatusNoteDalamPencarian(currentStatus.statusNotes))) &&
      isStatusLost(nextStatus.statusName) &&
      isStatusNoteBarangHilang(nextStatus.statusNotes) &&
      !userRolesOnSpecificLocation?.includes('SUPPLY_CHAIN') &&
      !userRolesOnSpecificLocation?.includes('STOCK_COUNT_AUDITOR') &&
      !authState.isSuperAdmin &&
      !userRolesOnSpecificLocation?.includes('HO_ADJUSTMENT')

    const conditionSix =
      isStatusLost(currentStatus.statusName) &&
      isStatusNoteDalamPencarian(currentStatus.statusNotes) &&
      isStatusBad(nextStatus.statusName) &&
      !userRolesOnSpecificLocation?.includes('HO_ADJUSTMENT')

    const conditionSeven =
      isStatusLost(nextStatus.statusName) &&
      isStatusNoteDalamPencarian(nextStatus.statusNotes) &&
      userRolesOnSpecificLocation?.includes('HO_ADJUSTMENT')

    return (
      conditionOne ||
      conditionTwo ||
      conditionThree ||
      conditionFour ||
      conditionFive ||
      conditionSix ||
      conditionSeven
    )
  }

  const handleChangeNextStatus = (name: string) => {
    setNextStatus((prev) => ({ ...prev, statusName: name, statusNotes: '' }))
  }

  const handleChangeNextStatusNotes = (notes: string) => {
    setNextStatus((prev) => ({
      ...prev,
      statusNotes: notes,
      statusName: prev.statusName === '' ? currentStatus.statusName : prev.statusName,
    }))
  }

  const shouldTriggerModalStatusChange = () => {
    const toAvailable = isStatusAvailable(nextStatus.statusName)
    const toLostBarangHilang =
      isStatusLost(nextStatus.statusName) && isStatusNoteBarangHilang(nextStatus.statusNotes)

    return toAvailable || toLostBarangHilang
  }

  return {
    nextStatus,
    isStatusChanged,
    userRolesOnSpecificLocation,
    determineAuthUserRolesByLocation,
    isNotAllowedToChangeStatusByRoles,
    isNotAllowedToChangeStatusFromLostHilang,
    saveTemporaryCurrentStatus,
    handleChangeNextStatus,
    handleChangeNextStatusNotes,
    shouldTriggerModalStatusChange,
  }
}
