import { toastFailed, toastSuccess } from 'utils/toast'
import {
  campaignStart,
  campaignStop,
  setCampaignData,
  needToReload,
  setCampaignPagination,
} from './index'

import {
  putUpdateActivateCampaign_CAMPAIGN,
  deleteCampaign_CAMPAIGN,
  putAdjustCampaign_CAMPAIGN,
} from 'utils/apiList/campaign'

import { getCampaignListV2 } from 'utils/apiList/campaignV2'

const validateParamsValue = (key, value) =>
  value
    ? {
        [key]: value,
      }
    : {}

export const getCampaignList =
  (search, localState = {}) =>
  async (dispatch, getState) => {
    dispatch(campaignStart())

    return getCampaignListV2({
      params: {
        pageIndex: search.pageIndex,
        pageSize: search.pageSize,
        ...validateParamsValue('createdDateFrom', search.date_range.createdDateFrom),
        ...validateParamsValue('createdDateTo', search.date_range.createdDateTo),
        ...validateParamsValue('name', search.name),
        ...validateParamsValue('status', search.status),
        ...validateParamsValue('campaignType', search.campaignType),
      },
    })
      .then(({ data }) => {
        if (data.error.status) {
          toastFailed(data.error.message)
        } else {
          const pagination = {
            currentItem: data.data.numberOfElements,
            currentPage: data.data.number,
            pageSize: data.data.size,
            totalData: data.data.totalElements,
            totalPage: data.data.totalPages,
          }

          const modifiedData = [...data.data.content].map((items, index) => {
            return { ...items, isSelected: false }
          })

          dispatch(setCampaignPagination(pagination))
          dispatch(setCampaignData(modifiedData))
        }

        dispatch(campaignStop())
      })
      .catch(() => {
        toastFailed('terjadi masalah, coba beberapa saat lagi')
        dispatch(campaignStop())
      })
  }

export const updateStatusActive_CAMPAIGN =
  (campaignID, localState = {}) =>
  (dispatch, getState) => {
    dispatch(campaignStart())

    return putUpdateActivateCampaign_CAMPAIGN(campaignID, localState.dataToSend)
      .then(({ data }) => {
        dispatch(needToReload())
        dispatch(campaignStop())
      })
      .catch(() => {
        toastFailed('terjadi masalah, coba beberapa saat lagi')
        dispatch(campaignStop())
      })
  }

export const deleteBulkCampaign_CAMPAIGN =
  (dataToSend, localState = {}) =>
  (dispatch, getState) => {
    dispatch(campaignStart())
    return deleteCampaign_CAMPAIGN(dataToSend)
      .then(({ data }) => {
        localState.setListOfSelectedCampaign([])
        localState.setIsShowPopupConfirmBulkDelete(false)
        localState.setIsShowPopupConfirmDelete(false)
        dispatch(needToReload())
        dispatch(campaignStop())
      })
      .catch(() => {
        toastFailed('terjadi masalah, coba beberapa saat lagi')
        dispatch(campaignStop())
      })
  }

export const updateCampaign_CAMPAIGN =
  (campaign_id, localState = {}) =>
  (dispatch) => {
    dispatch(campaignStart())

    return putAdjustCampaign_CAMPAIGN(campaign_id, localState.dataToSend)
      .then(({ data }) => {
        dispatch(needToReload())
        dispatch(campaignStop())
        toastSuccess('Campaign Berhasil dirubah')
      })
      .catch((err) => {
        const { error } = err.response.data
        toastFailed(error.message)
        dispatch(campaignStop())
      })
  }
