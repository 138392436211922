import { memo } from "react";
import styled from "styled-components";
import { mappingColorTag } from "helpers";
function Tag({ children, style,color, className }) {
  const { bg, color: colorText } = mappingColorTag(color);
  return (
    <Container style={style} bg={bg} color={colorText} className={className}>
      {children}
    </Container>
  );
}

export default memo(Tag);
const Container = styled.span`
  padding: 2px 4px;
  line-height: 13.5px;
  border-radius: 3px;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.toast};
  color: ${({ theme: { colors }, color }) => colors[color]};
  background-color: ${({ theme: { colors }, bg }) => colors[bg]};
  height: fit-content;
  text-align: center;
`;
