import { createAsyncThunk } from '@reduxjs/toolkit'

import { callErrorMsg } from 'helpers/errorMsg'

import {
  getLocationsAPI,
  getProductAssetComponentAPI,
  getAssetCategoriesAPI,
} from 'features/AssetManagement/services/otherServices'

const SLICE_NAME = 'assetGlobalRedux'

export const getAssetListsComponent = createAsyncThunk(
  `${SLICE_NAME}/getProductAssetListsComponent`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await getProductAssetComponentAPI()

      const { error } = response.data
      if (error.status) {
        callErrorMsg(error)
        return rejectWithValue(error)
      }
      return response.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const getAllLocation = createAsyncThunk(
  `${SLICE_NAME}/getAllLocation`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getLocationsAPI()

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const getAssetCategories = createAsyncThunk(
  `${SLICE_NAME}/getAssetCategories`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await getAssetCategoriesAPI()

      return response.data.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)
