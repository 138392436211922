import { lazy } from 'react'
import { RolesKeyType } from 'constant/roles'

import generateRouterDetail from 'utils/helpers/generateRouterDetail'

const SOParameters = lazy(() => import('../pages/SOParameters'))
const SOReports = lazy(() => import('../pages/SOReports'))
const SOSchedules = lazy(() => import('../pages/SOSchedules'))

const globalRoles = [
  'AUTH_HO_ASTRO_KITCHEN_SUPPLY_CHAIN',
  'AUTH_HO_SUPPLY_CHAIN_HEAD',
  'AUTH_HO_SUPPLY_CHAIN_MANAGER',
  'AUTH_HO_SUPPLY_CHAIN_SPV',
  'AUTH_HO_SUPPLY_CHAIN_STAFF',
] as RolesKeyType[]

export const SOParametersRouter: RouterFeatureInterface<
  'SOParametersRouter',
  '/dashboard/so-automation/so-parameters'
> = {
  name: 'SOParametersRouter',
  Component: SOParameters,
  path: '/dashboard/so-automation/so-parameters',
  routeType: 'PRIVATE',
  allowedRoles: [...globalRoles],
  isDashboard: true,
}

export const SOSchedulesRouter: RouterFeatureInterface<
  'SOSchedulesRouter',
  '/dashboard/so-automation/so-schedules'
> = {
  name: 'SOSchedulesRouter',
  Component: SOSchedules,
  path: '/dashboard/so-automation/so-schedules',
  routeType: 'PRIVATE',
  allowedRoles: [...globalRoles],
  isDashboard: true,
}

export const SOReportsRouter: RouterFeatureInterface<
  'SOReportsRouter',
  '/dashboard/so-automation/so-reports'
> = {
  name: 'SOReportsRouter',
  Component: SOReports,
  path: '/dashboard/so-automation/so-reports',
  routeType: 'PRIVATE',
  allowedRoles: [...globalRoles],
  isDashboard: true,
}

const SOAutomationRouter = [SOParametersRouter, SOReportsRouter, SOSchedulesRouter]

export const SOAutomationRouterDetail = generateRouterDetail(SOAutomationRouter)

export default SOAutomationRouter
