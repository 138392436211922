import { memo, useMemo } from "react";
import styled from "styled-components";
import { Toggle, SystemIcon, Button } from "components";

function RowTicker({
  data,
  handleChangeStatus,
  handleEdit,
  handlePopupDelete
}) {
  const listPagesRender = useMemo(() => {
    let result = "";
    data.ticker_pages.forEach((el, index) => {
      if (index === 0) {
        result = el.page_name;
      } else {
        result += `, ${el.page_name}`;
      }
    });
    return result;
  }, [data.ticker_pages]);
  return (
    <Tr>
      <Td min="170px">{data.title}</Td>
      <Td>
        <OverFlowText line="2">{data.content}</OverFlowText>
      </Td>
      <Td>
        <OverFlowSingle line="1">{data.url_link}</OverFlowSingle>
      </Td>
      <Td>{listPagesRender}</Td>
      <Td>
        {data.start_date} - {data.end_date}
      </Td>
      <Td min="150px">
        {data.start_time} - {data.end_time}
      </Td>

      <Td>
        <ActionWrapper>
          <Toggle
            onClick={() => handleChangeStatus(data)}
            active={data.status}
          />
          <Button variant="wrapper" onClick={() => handleEdit(data)}>
            <SystemIcon className="pointer" colorIcon="main" iconName="edit" />
          </Button>
          <Button
            variant="wrapper"
            onClick={() => {
              //console.log(data);
              handlePopupDelete(data);
            }}
          >
            <SystemIcon iconName="trash" colorIcon="red" />
          </Button>
        </ActionWrapper>
      </Td>
    </Tr>
  );
}

RowTicker.defaultProps = {
  handleClickDetail: () => {},
  handleClickTrack: () => {},
  handleClickChangeDriver: () => {}
};

export default memo(RowTicker);

const Tr = styled.tr`
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
  .input-container {
    width: calc(100% - 20px);
  }
`;
const Td = styled.td`
  color: ${({ theme: { colors }, color = "textSoft" }) => colors[color]};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  padding: 16px 32px;
  min-width: ${({ min }) => min};
`;

const OverFlowText = styled.div`
  -webkit-line-clamp: ${({ line }) => line};
  -webkit-box-orient: vertical;
  white-space: pre-wrap;
  display: -webkit-box;
  overflow: hidden;
  max-width: 200px;
`;

const OverFlowSingle = styled.div`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme: { colors } }) => colors.main};
`;

const ActionWrapper = styled.div`
  display: flex;
  i {
    margin-left: 20px;
  }
`;
