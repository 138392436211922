import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getDisbursmentList,
  getBankList,
  getDownloadDisbursment,
  putUpdateDisbursement,
} from 'utils/apiList/disbursement'
import type {
  GetDisbursementRequestType,
  GetDownloadDisbursementRequestType,
} from 'utils/apiList/disbursement'
import { getHubList } from 'utils/apiList/category'
import { callErrorMsg } from 'helpers/errorMsg'
import { dateFormat } from 'utils/helpers/date'

export const SLICE_NAME = 'ReferralMissionList'

const DATE_FORMAT = 'D MMM YYYY'

const validateParamsValue = (
  key:
    | keyof GetDisbursementRequestType['params']
    | keyof GetDownloadDisbursementRequestType['params'],
  value: string | number | Date | null,
) =>
  value
    ? {
        [key]: value,
      }
    : {}

export const fetchDisbursementList = createAsyncThunk(
  `${SLICE_NAME}/fetchDisbursementList`,
  async (_, { rejectWithValue, getState }) => {
    const {
      disbursement: { query },
    } = getState() as StoreStateType
    try {
      const res = await getDisbursmentList({
        params: {
          limit: 20,
          page: query.pageIndex,
          ...validateParamsValue('status', query.status.value),
          ...validateParamsValue('id', query.id),
          ...validateParamsValue(
            'start_date',
            query.date[0]
              ? dateFormat({
                  date: query.date[0] ? new Date(query.date[0]) : 0,
                  format: DATE_FORMAT,
                })
              : 0,
          ),
          ...validateParamsValue(
            'end_date',
            query.date[1]
              ? dateFormat({
                  date: query.date[1] ? new Date(query.date[1]) : 0,
                  format: DATE_FORMAT,
                })
              : 0,
          ),
        },
      })

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchBankList = createAsyncThunk(
  `${SLICE_NAME}/fetchBankList`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getBankList()

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchHubList = createAsyncThunk(
  `${SLICE_NAME}/fetchHubList`,
  async (_, { rejectWithValue }) => {
    try {
      const res = await getHubList()

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchDowloadDisbursement = createAsyncThunk(
  `${SLICE_NAME}/fetchDowloadDisbursement`,
  async (_, { rejectWithValue, getState }) => {
    const {
      disbursement: { query },
    } = getState() as StoreStateType
    try {
      const res = await getDownloadDisbursment({
        params: {
          end_date: dateFormat({
            date: query.date[1] ? new Date(query.date[1]) : 0,
            format: DATE_FORMAT,
          }),
          start_date: dateFormat({
            date: query.date[0] ? new Date(query.date[0]) : 0,
            format: DATE_FORMAT,
          }),
          ...validateParamsValue('id', query.id),
          ...validateParamsValue('status', query.status.value),
        },
      })

      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')

      link.href = url
      link.setAttribute('download', 'csv.csv')
      document.body.appendChild(link)
      link.click()
      link.remove()

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const updateDisbursement = createAsyncThunk(
  `${SLICE_NAME}/updateDisbursement`,
  async (_, { rejectWithValue, getState }) => {
    const {
      disbursement: { selectedReprocessingData, formModalReprocessing },
    } = getState() as StoreStateType
    try {
      const res = await putUpdateDisbursement({
        payload: {
          disbursement_id: selectedReprocessingData.id as number,
          status: 'REVISED',
          target_account_name: formModalReprocessing.accountName,
          target_channel: formModalReprocessing.bank.target_channel,
          target_type: formModalReprocessing.bank.target_type,
          target_identifier: formModalReprocessing.accountNumber,
        },
      })

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)
