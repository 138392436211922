import React from 'react'
import styled, { css } from 'styled-components'
import { SystemIcon, TextBody } from 'components'

const InputForm = ({
  onChange,
  iconPos,
  customIcon,
  border,
  iconName,
  bgIcon,
  handleClickIcon,
  className,
  onFocus,
  onBlur,
  iconColor,
  note,
  width,
  error,
  errorText,
  sulfix,
  size,
  value,
  autocomplete,
  placeholder,
  reference,
  ...rest
}) => {
  let reassignValue = value
  if (value === 0) {
    reassignValue = 0
  }
  return (
    <InputContainer width={width} className={`${className} input-container`}>
      <Container iconPos={iconPos} border={border} error={error}>
        <Input
          ref={reference || rest?.ref}
          placeholder={placeholder}
          value={reassignValue}
          size={size}
          {...rest}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          autoComplete={autocomplete}
        />
        {sulfix && sulfix}
        {(customIcon || iconName) && (
          <label>
            <IconWrapper
              onClick={handleClickIcon}
              className="icon-wrapper"
              iconPos={iconPos}
              bgIcon={bgIcon}
            >
              {customIcon ? customIcon : <SystemIcon iconName={iconName} colorIcon={iconColor} />}
            </IconWrapper>
          </label>
        )}
      </Container>
      {error && (
        <TextBody
          weight="light"
          style={{
            marginTop: '4px',
            color: '#E25450',
          }}
          size="smaller"
        >
          {errorText}
        </TextBody>
      )}
      {note && (
        <TextBody className="mt-2 ml-3" size="smaller" weight="light" color="secondaryText">
          {note}
        </TextBody>
      )}
    </InputContainer>
  )
}

InputForm.defaultProps = {
  onChange: () => {},
  iconPos: 'right',
  border: true,
  customIcon: '',
  bgIcon: 'grey',
  handleClickIcon: () => {},
  value: '',
  onBlur: () => {},
  onFocus: () => {},
  iconColor: 'main',
  color: 'text',
  autocomplete: 'off',
  note: '',
  width: '100%',
  size: 'md',
  inputSmall: false,
}

export default React.memo(InputForm)

const getBorderForContainer = (border) => {
  if (typeof border === 'string') return border
  if (typeof border === 'boolean' && border) return 'solid 1px #E4EAF3'
  if (typeof border === 'boolean' && !border) return 'none'
}

const Container = styled.div`
  width: 100%;
  border-radius: 6px;
  display: flex;
  position: relative;
  background-color: ${({ theme: { colors } }) => colors.white};
  border: ${({ border }) => getBorderForContainer(border)};
  flex-direction: ${({ iconPos }) => (iconPos === 'left' ? 'row-reverse' : 'row')};
  ${({ error, border }) => (error ? 'border: 1px solid #E25450;' : getBorderForContainer(border))}
`
const IconWrapper = styled.div`
  display: grid;
  padding: 13px;
  place-items: center;
  background-color: ${({ theme: { colors }, bgIcon }) => colors[bgIcon]};
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.light};
  font-family: 'Noto Sans JP', sans-serif !important;
  white-space: nowrap;
`

const Input = styled.input`
  width: 100%;
  padding: 10px;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  color: ${({ theme: { colors }, color }) => colors[color]};
  border-radius: 6px;
  border: none;
  &:disabled {
    color: ${({ theme: { colors } }) => colors.grey};
  }
  &:focus {
    outline: none;
  }
  ::placeholder {
    opacity: 0.5;
    color: ${({ theme: { colors }, color }) => colors[color]};
  }
  ::-webkit-calendar-picker-indicator {
    width: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    /* display: none;
    -webkit-appearance: none; */
  }
  ${({ size }) =>
    size === 'sm' &&
    css`
      padding: 7px;
      font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
    `}
`

const InputContainer = styled.div`
  width: ${({ width }) => width};
`
