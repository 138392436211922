import axiosInstanceApiGateway from 'config/apiServiceApiGateway'
import type { TicketComplaintsType, TicketDetailType, TicketReasonsType } from '../types'

type SLAIDsResponseType = {
  sla: {
    active: boolean
    available: boolean
    id: number
    maximum_duration: number
    minimum_duration: number
    name: string
    total_duration: number
  }[]
}

export type GetTicketLictParamsType = {
  statuses?: string[]
  own_ticket?: boolean
  order_id?: number
  location_id?: number
  reporter_name?: string
  timing_ids?: number[]
  page_size?: number
  page?: number
}

export type TicketListResponseType = {
  tickets: {
    reporter_name: string
    responder_name: string
    max_arrive_at: number
    status: string
    id: number
    complaint: string
    location_id: number
    order: {
      id: number
      type: string
    }
    timing: {
      name: string
      id: number
      type: string
    }
    order_type: string
  }[]
  pagination: {
    page: number
    page_size: number
    has_next: boolean
    has_prev: boolean
  }
}

type TicketDetailResponseType = TicketDetailType

type TicketComplaintReasonsResponseType = {
  reasons: TicketReasonsType[]
}

export type ComplaintReasonRequestParamsType = {
  complaint_id?: number
  action_type?: 'APPROVE_TICKET' | 'REJECT_TICKET'
}

export type ComplaintRequestParamsType = {
  order_status?: string
  page_name?: string
}

export type ComplaintRequestResponseType = {
  complaints: TicketComplaintsType[]
}

export type ApproveTicketRequestParamsType = {
  reason: TicketReasonsType
}

export type RejectTicketRequestParamsType = {
  reasons: TicketReasonsType[]
  additional_reason?: string
}

const { Get, Put } = axiosInstanceApiGateway

export const getTicketList = (params: string) =>
  Get<TicketListResponseType>({
    url: `/helpdesk/internal/v1/ticket-management/cs/tickets?${params}`,
  })

export const getTicketDetail = (id: number) =>
  Get<TicketDetailResponseType>({
    url: `/helpdesk/internal/v1/ticket-management/cs/tickets/${id}`,
  })

export const getSLAIds = () =>
  Get<SLAIDsResponseType>({ url: `/location/internal/v1/sla?limit=100&&page=0` })

export const getComplainReasons = ({
  complaint_id,
  action_type,
}: ComplaintReasonRequestParamsType) =>
  Get<TicketComplaintReasonsResponseType>({
    url: '/helpdesk/internal/v1/ticket-management/cs/reasons',
    params: {
      complaint_id,
      action_type,
    },
  })

export const putReviewTicket = (ticketId: number) =>
  Put({
    url: `/helpdesk/internal/v1/ticket-management/cs/tickets/${String(ticketId)}/review`,
  })

export const putApproveTicket = (ticketId: number, payload: ApproveTicketRequestParamsType) =>
  Put({
    url: `/helpdesk/internal/v1/ticket-management/cs/tickets/${String(ticketId)}/approve`,
    data: payload,
  })

export const putRejectTicket = (ticketId: number, payload: RejectTicketRequestParamsType) =>
  Put({
    url: `/helpdesk/internal/v1/ticket-management/cs/tickets/${String(ticketId)}/reject`,
    data: payload,
  })
