import apiServices from 'config/apiServiceApiGateway'

const { Get } = apiServices

export type GetAllSegmentsResponseType = {
  name: string
  id: number
  total_user: number
}[]

export const getAllSegments = () =>
  Get<GetAllSegmentsResponseType>({ url: `/segmentation/internal/v1/segment` })
