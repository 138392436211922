import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

const baseUrl = '/accounts/internal/v1/hub-feature'

export interface GetFeatureFlagsParamsInterface {
  feature_key?: string
  page_size?: number | string
  page_index?: number | string
}

export enum DeviceIdEnum {
  WEB = 1,
  ANDROID = 2,
  IOS = 3,
}
export interface GetFlagLocationParamsInterface {
  device_id: DeviceIdEnum
  location_name: string
  location_type: string
  active?: boolean
  page_size: number
  page_index: number
}

export type FeatureFlagPayloadType = {
  location_id: number
  active: boolean
}
export interface UpdateFeatureFlagInterface {
  device_id: DeviceIdEnum
  feature_flags: Array<FeatureFlagPayloadType>
}

export const fetchFeatureFlags = ({
  feature_key,
  page_size,
  page_index,
}: GetFeatureFlagsParamsInterface) =>
  axiosInstanceApiGateway.Get({
    url: `${baseUrl}?page_size=100`,
    params: {
      feature_key,
      page_size,
      page_index,
    },
  })

export const fetchLocations = (
  featureId: number,
  params: Partial<GetFlagLocationParamsInterface>,
) =>
  axiosInstanceApiGateway.Get({
    url: `${baseUrl}/${featureId}/location`,
    params,
  })

export const updateFeatureFlag = (featureId: number, data: UpdateFeatureFlagInterface) =>
  axiosInstanceApiGateway.Put({
    url: `${baseUrl}/${featureId}/location/bulk-update`,
    data,
  })
