import { TablePropsType } from '@astro-ui/components'
import { RedirectionEnum } from 'features/CategoryAndPosisi/@types/InfiniteScrollTab'
import {
  type RedirectionType,
  type LogicType,
} from 'features/CategoryAndPosisi/@types/InfiniteScrollTabAddEdit'

export const tableHeaderConfig: TablePropsType['tableHeaders'] = [
  {
    name: 'Position',
    value: 'position',
    width: '100px',
  },
  {
    name: 'Tab Name',
    value: 'tab_name',
    width: '250px',
  },
  {
    name: 'Type',
    value: 'type',
  },
  {
    name: 'Redirection',
    value: 'redirection',
  },
  {
    name: 'Waktu Mulai',
    value: 'start_time',
  },
  {
    name: 'Waktu Berakhir',
    value: 'end_time',
  },
  {
    name: 'Lokasi',
    value: 'location',
    width: '200px',
  },
  {
    name: 'Status',
    value: 'status',
  },
  {
    name: 'Aksi',
    value: 'action',
    width: '80px',
    align: 'center',
  },
]

export const redirectionTypes: RedirectionType[] = [
  {
    name: 'Category',
    value: RedirectionEnum.CATEGORY,
  },
  {
    name: 'Catalogue',
    value: RedirectionEnum.CATALOGUE,
  },
  {
    name: 'Logic',
    value: RedirectionEnum.LOGIC,
  },
]

export const logicOptions: LogicType[] = [
  {
    name: 'Reorder',
    value: 'reorder',
  },
]

export const MAX_TABBING_NAME_LENGTH = 20

export const filterStatusOptions = [
  {
    name: 'Active',
    value: 1,
  },
  {
    name: 'Inactive',
    value: 2,
  },
]

export const allLocation = {
  location_name: 'Semua Lokasi',
  location_id: 0,
  location_type: 'All',
}
