import { createAsyncThunk } from '@reduxjs/toolkit'

import {
  getCampaignLoyaltyList,
  deleteCampaignLoyalty,
  putCampaignLoyaltyStatus,
} from 'utils/apiList/campaignLoyalty'
import type {
  GetCampaignLoyaltyListRequestType,
  PutCampaignLoyaltyStatusByIdRequestType,
  CampaignVariantType,
} from 'utils/apiList/campaignLoyalty'
import { callErrorMsg } from 'helpers/errorMsg'

export const SLICE_NAME = 'loyalty/CampaignLoyaltyAddAndEdit'

export type { CampaignVariantType }

const validateParamsValue = (
  key: keyof GetCampaignLoyaltyListRequestType['params'],
  value: string | number,
) =>
  value
    ? {
        [key]: value,
      }
    : {}

export const fetchCampaignLoyaltyList = createAsyncThunk(
  `${SLICE_NAME}/fetchCampaignLoyaltyList`,
  async (_, { rejectWithValue, getState }) => {
    const {
      campaignLoyaltyList: { query },
    } = getState() as StoreStateType

    const params: GetCampaignLoyaltyListRequestType['params'] = {
      page_size: query.pageSize,
      page_index: query.pageIndex,
      sort: 'id',
      direction: 'desc',
      ...validateParamsValue('from_date', query.startDate),
      ...validateParamsValue('to_date', query.endDate),
      ...validateParamsValue('status', query.status.value),
      ...validateParamsValue('campaign_type', query.campaignType?.key || ''),
    }
    try {
      const res = await getCampaignLoyaltyList({ params })

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const removeCampaignLoyalty = createAsyncThunk(
  `${SLICE_NAME}/removeCampaignLoyalty`,
  async (_, { rejectWithValue, getState }) => {
    const {
      campaignLoyaltyList: {
        popupDelete: { selectedCampaignLoyaltyData },
      },
    } = getState() as StoreStateType

    try {
      const res = await deleteCampaignLoyalty({ id: selectedCampaignLoyaltyData.id.toString() })

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateCampaignLoyaltyStatusById = createAsyncThunk(
  `${SLICE_NAME}/updateCampaignLoyaltyStatusById`,
  async (requestParams: PutCampaignLoyaltyStatusByIdRequestType, { rejectWithValue }) => {
    try {
      const res = await putCampaignLoyaltyStatus(requestParams)

      return res.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
