import { toastFailed } from 'utils/toast'
import {
  SET_IS_START_WAREHOUSE_DETAIL,
  SET_IS_FAILED_WAREHOUSE_DETAIL,
  SET_IS_SUCCESS_WAREHOUSE_DETAIL,
  SET_IS_NEED_TO_RELOAD_WAREHOUSE_DETAIL,
  SET_PAGINATION_DATA_WAREHOUSE_DETAIL,
  SET_SELECT_PRODUCT_DATA_WAREHOUSE_DETAIL,
  SET_IS_START_WAREHOUSE_DETAIL_HISTORY,
  SET_IS_SUCCESS_WAREHOUSE_DETAIL_HISTORY,
  SET_IS_FAILED_WAREHOUSE_DETAIL_HISTORY,
  SET_PAGINATION_DATA_WAREHOUSE_DETAIL_HISTORY,
} from 'storeContext/actionsType/warehouseDetail'
import { getWarehouseDetailList_WD, getProductDetail_WD } from 'utils/apiList/warehouseDetail'
import { getHubHistory } from '../../../utils/apiList/hubDetail'
//import { setIsLoading } from "storeContext/actions/warehouse";

export const WRHSDetailStart = () => {
  return {
    type: SET_IS_START_WAREHOUSE_DETAIL,
  }
}

export const WRHSDetailHistoryStart = () => {
  return {
    type: SET_IS_START_WAREHOUSE_DETAIL_HISTORY,
  }
}

export const WRHSDetailStop = () => {
  return {
    type: SET_IS_FAILED_WAREHOUSE_DETAIL,
  }
}

export const WRHSDetailHistoryStop = () => {
  return {
    type: SET_IS_FAILED_WAREHOUSE_DETAIL_HISTORY,
  }
}

export const getWRHSDetailData = (data) => {
  return {
    type: SET_IS_SUCCESS_WAREHOUSE_DETAIL,
    payload: data,
  }
}

export const getWRHSDetailHistoryData = (data) => {
  return {
    type: SET_IS_SUCCESS_WAREHOUSE_DETAIL_HISTORY,
    payload: data,
  }
}

export const setPaginationHistory = (data) => {
  return {
    type: SET_PAGINATION_DATA_WAREHOUSE_DETAIL_HISTORY,
    payload: data,
  }
}

export const setPagination = (data) => {
  return {
    type: SET_PAGINATION_DATA_WAREHOUSE_DETAIL,
    payload: data,
  }
}

export const reloadWRHSDetail = () => {
  return {
    type: SET_IS_NEED_TO_RELOAD_WAREHOUSE_DETAIL,
  }
}

export const selectProductData = (data) => {
  return {
    type: SET_SELECT_PRODUCT_DATA_WAREHOUSE_DETAIL,
    payload: data,
  }
}

export const getListWhHistory = (payload, query) => (dispatch) => {
  dispatch(WRHSDetailHistoryStart())
  return getHubHistory(payload, query)
    .then(({ data }) => {
      dispatch(getWRHSDetailHistoryData(data.data))
      dispatch(setPaginationHistory(data.pagination))
    })
    .catch((err) => {
      toastFailed('terjadi masalah, coba beberapa saat lagi')
      dispatch(WRHSDetailHistoryStop())
    })
}

export const getListOfWRHSDetail =
  (query = false, localState = {}) =>
  (dispatch, getState) => {
    dispatch(WRHSDetailStart())
    //localState.warehouseLoadingStart;

    //const { selectedProduct } = getState().stateWarehouseDetail;
    //console.log(selectedProduct);

    return getWarehouseDetailList_WD(localState.product_id, query)
      .then(({ data }) => {
        //console.log(data);

        dispatch(getWRHSDetailData(data))

        const pagination = {
          currentItem: data.numberOfElements,
          currentPage: data.number,
          pageSize: data.size,
          totalData: data.totalElements,
          totalPage: data.totalPages,
        }

        dispatch(setPagination(pagination))

        //console.log(pagination);
        dispatch(WRHSDetailStop())
        //dispatch(setIsLoading(false));
      })
      .catch(() => {
        toastFailed('terjadi masalah, coba beberapa saat lagi')
        dispatch(WRHSDetailStop())
      })
  }

export const getProductDataByID = (id) => (dispatch) => {
  dispatch(WRHSDetailStart())

  return getProductDetail_WD(id)
    .then(({ data }) => {
      dispatch(selectProductData(data))
      dispatch(WRHSDetailStop())
    })
    .catch(() => {
      toastFailed('terjadi masalah, coba beberapa saat lagi')
      dispatch(WRHSDetailStop())
    })
}
