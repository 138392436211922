import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const AssetMasterListRBAC = {
  pageID: '47267274ff14f7d5e9126352161aa03b',
  elementID: {
    UpdateAsset: '6e3ed10ebe12f784d156a1fa8caa60c4',
    CetakQR: 'e5cf369e1bf219b9fa8a2e477a5bca88',
    ViewOnly: '7774e293ffd0e7254b137d4456d38c26',
  },
} as const

export const useAssetMasterListRBAC = createUseRBAC(AssetMasterListRBAC.elementID)

export type UseAssetMasterListRBACPageType = ReturnType<typeof useAssetMasterListRBAC>
