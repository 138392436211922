import { memo } from 'react'
import styled from 'styled-components'
import { RowBuyer } from 'components'
import { useSelector, shallowEqual } from 'react-redux'
import { useBlockedAccessFor } from 'customHooks/useAllowedAccessFor'
import { AUTH_MARKETING } from 'middleware/privateRoute'

function TableBuyer({ buyerData, handlerChangeActive }) {
  const authorities = useSelector(({ auth: { userData } }) => userData.authorities, shallowEqual)
  const blockForToggleStatus = useBlockedAccessFor(authorities, [AUTH_MARKETING])
  const viewOnly = useBlockedAccessFor(authorities, [AUTH_MARKETING])

  // FUNTION RENDER
  const renderRowBuyer = () => {
    return buyerData.map((items, index) => (
      <RowBuyer
        key={items.customer_phone_number + index}
        data={items}
        handlerChangeActive={handlerChangeActive}
        roles={{ blockForToggleStatus, viewOnly }}
      />
    ))
  }

  return (
    <Container>
      <thead>
        <Tr>
          <Th ratio="1">ID</Th>
          <Th ratio="3">NAMA LENGKAP</Th>
          <Th ratio="3">REFERRAL CODE</Th>
          <Th ratio="4">NOMOR HP</Th>
          <Th ratio="4">EMAIL</Th>
          <Th ratio="3">SALDO ASTRO</Th>
          <Th ratio="3">ASTRO POINT</Th>
          <Th ratio="2">STATUS</Th>
        </Tr>
      </thead>
      <tbody>{renderRowBuyer()}</tbody>
    </Container>
  )
}
export default memo(TableBuyer)

const Container = styled.table`
  width: 100%;
`

const Tr = styled.tr`
  display: flex;
  padding: 16px 32px;
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`

const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`
