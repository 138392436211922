import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import {
  AuthenticationControllStateType,
  SetQueryPayloadType,
  SetModalPayloadType,
  SetSelectedUserPayloadType,
  SetIsCheckedUserPayloadType,
  SetIsShowSelectedDataPayloadType,
} from 'features/Account/@types/authenticationControll'
import { callErrorMsg } from 'helpers/errorMsg'
import { getUserList, deleteRemoveToken } from '../../services/authenticationControll'

const initialState: AuthenticationControllStateType = {
  isLoading: false,
  isShowSelectedData: false,
  userList: [],
  query: { name: '', page_index: 0, page_size: 20, type: '', id: '' },
  modal: '',
  selectedUserList: [],
}
export const SLICE_NAME = 'authenticationControll'

const authenticationControll = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    resetSelectedUser: (state) => {
      state.selectedUserList = initialState.selectedUserList
    },
    setQuery: (state, action: PayloadAction<SetQueryPayloadType>) => {
      const { value, name } = action.payload
      state.query[name] = value as never
    },
    setModal: (state, action: PayloadAction<SetModalPayloadType>) => {
      const { value } = action.payload
      state.modal = value
    },
    setIsShowSelectedData: (state, action: PayloadAction<SetIsShowSelectedDataPayloadType>) => {
      const { value } = action.payload
      state.isShowSelectedData = value
      if (!value) {
        state.selectedUserList = state.selectedUserList.filter((el) => el.isChecked)
      }
    },
    setSelectedUser: (state, action: PayloadAction<SetSelectedUserPayloadType>) => {
      const { value, type } = action.payload

      const validateValue = value.filter((el) => el.key).map((el) => ({ ...el, isChecked: true }))

      if (type === 'ADD') {
        state.selectedUserList = [...state.selectedUserList, ...validateValue]
      }
      if (type === 'DELETE') {
        state.selectedUserList = state.selectedUserList.filter(
          (el) => !validateValue.some((elSome) => elSome.id === el.id),
        )
      }
    },
    setIsCheckedUser: (state, action: PayloadAction<SetIsCheckedUserPayloadType>) => {
      const { index, type } = action.payload

      if (type === 'CHECK_ALL' || type === 'UNCHECK_ALL') {
        state.userList = state.userList.map((el) => ({
          ...el,
          isChecked: el.key ? type === 'CHECK_ALL' : false,
        }))
      }
      if (type === 'CHECK_SINGLE' || type === 'UNCHECK_SINGLE') {
        state.userList[index].isChecked = type === 'CHECK_SINGLE'
      }
    },
    setIsCheckedSelectedUser: (state, action: PayloadAction<SetIsCheckedUserPayloadType>) => {
      const { index, type } = action.payload

      if (type === 'CHECK_ALL' || type === 'UNCHECK_ALL') {
        state.selectedUserList = state.selectedUserList.map((el) => ({
          ...el,
          isChecked: el.key ? type === 'CHECK_ALL' : false,
        }))
      }
      if (type === 'CHECK_SINGLE' || type === 'UNCHECK_SINGLE') {
        state.selectedUserList[index].isChecked = type === 'CHECK_SINGLE'
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchUserList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchUserList.fulfilled, (state, action) => {
        state.isLoading = false
        state.userList = action.payload.data.user_auths
        state.userList = action.payload.data.user_auths.map((el) => ({
          ...el,
          isChecked: state.selectedUserList.some((elSome) => el.id === elSome.id),
        }))
      })
      .addCase(removeTokenUsers.pending, (state) => {
        state.isLoading = true
      })
      .addCase(removeTokenUsers.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(removeTokenUsers.fulfilled, (state) => {
        state.isLoading = false
        state.selectedUserList = initialState.selectedUserList
      })
  },
})

export default authenticationControll.reducer
export const {
  reset,
  setQuery,
  setModal,
  setIsCheckedUser,
  setSelectedUser,
  resetSelectedUser,
  setIsShowSelectedData,
  setIsCheckedSelectedUser,
} = authenticationControll.actions

export const fetchUserList = createAsyncThunk(
  `${SLICE_NAME}/fetchUserList`,
  async (_, { rejectWithValue, getState }) => {
    const {
      authenticationControll: { query },
    } = getState() as StoreStateType

    try {
      const res = await getUserList({
        params: {
          page_index: query.page_index,
          page_size: query.page_size,
          name: query.name,
          user_type: query.type,
          id: query.id,
        },
      })
      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const removeTokenUsers = createAsyncThunk(
  `${SLICE_NAME}/removeTokenUsers`,
  async (_, { rejectWithValue, getState, dispatch }) => {
    const {
      authenticationControll: { selectedUserList },
    } = getState() as StoreStateType

    const keys = selectedUserList
      .filter((el) => el.isChecked)
      .map((el) => el.key)
      .join(',')

    try {
      const res = await deleteRemoveToken({ params: { keys } })
      dispatch(resetSelectedUser())
      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)
