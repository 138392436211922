import { Get, Post, Put } from 'config/apiServiceConfig'

export type ErrorType = {
  status: boolean
  message: string
  code: number
}

export type PostUploadMissionImageRequestType = {
  payload: { image: string; imageType: 'BANNER' | 'ICON' }
}

export type PostUploadMissionImageResponseType = {
  data: {
    url: string
  }
  error: ErrorType
}

export type GetRewardTypeResponseType = {
  data: {
    content: {
      name: string
      value: string
    }[]
  }
}

export type GetAllMissionRequestType = {
  params: {
    pageIndex: number
    status?: string
    missionName?: string
    sortBy?: string
    sortDirection?: string
    startDate?: number
    endDate?: number
  }
}

export type GetAllMissionResponseType = {
  data: {
    list: [
      {
        id: number
        missionName: string
        startDate: string
        endDate: string
        milestoneCount: number
        status: {
          name: string
          value: string
        }
      },
    ]
  }
  error: ErrorType
  pagination: {
    sort: string
    direction: string
    pageSize: number
    pageIndex: number
    numberOfElements: number
  }
}

export type GetVoucherListResponseType = {
  content: {
    voucher_id: number
    voucher_code: string
    benefit_type: string
    targeting_type: string
    voucher_status: string
    voucher_created_by: string
    voucher_created_at: number
    voucher_amount: number
    voucher_active: boolean
    payment_channel_id: number
    payment_channel_name: string
    payment_channel_code: string
    quota_all: number
    is_quota_unlimited: boolean
    coin_amount: number
    quota_redemption: number
    is_unlimited_redemption: boolean
  }[]
}

export type GetVoucherListRequestType = {
  params: {
    code?: string
  }
}
export type SequenceType = {
  rewardTitle: string
  iconEnabledUrl: string
  iconDisabledUrl: string
  rewardType: string
  voucherCode: string
  coinAmount: number
  description: string
}

export type PostReferralMissionRequestType = {
  payload: {
    missionName: string
    startDate: number
    endDate: number
    bannerUpcomingUrl: string
    bannerOngoingUrl: string
    bannerFinishedUrl: string
    linkTitle: string
    linkDescription: string
    linkMessage: string
    linkImageUrl: string
    status: string
    rewards: {
      rewardTitle: string
      rewardPosition: number
      iconEnabledUrl: string
      iconDisabledUrl: string
      rewardType: string
      voucherCode: string
      coinAmount: number
      description: string
      quota: number
      sequence: SequenceType[]
    }[]
  }
}

export type PostReferralMissionResponseType = {
  data: {
    id: number
  }
  error: {
    status: boolean
    message: string
    code: number
  }
}

export type GetMissionByIDRequestType = {
  id: string
}

export type GetMissionByIDResponseType = {
  data: {
    id: number
    missionName: string
    startDate: number
    endDate: number
    bannerUpcomingUrl: string
    bannerOngoingUrl: string
    bannerFinishedUrl: string
    linkTitle: string
    linkDescription: string
    linkMessage: string
    linkImageUrl: string
    status: string
    rewards: {
      rewardTitle: string
      rewardPosition: number
      iconEnabledUrl: string
      iconDisabledUrl: string
      description: string
      quota: number
      rewardType: {
        name: string
        value: string
      }
      voucherCode: string
      coinAmount: number
      sequence: {
        rewardTitle: string
        iconEnabledUrl: string
        iconDisabledUrl: string
        rewardType: {
          name: string
          value: string
        }
        voucherCode: string
        coinAmount: number
        description: string
      }[]
    }[]
  }
  error: ErrorType
}

export type PutReferralMissionResponseType = {
  data: {
    success: boolean
  }
  error: ErrorType
}

export type PutReferralMissionRequestType = {
  id: string
  payload: {
    missionName: string
    startDate: number
    endDate: number
    bannerOngoingUrl: string
    bannerFinishedUrl: string
    bannerUpcomingUrl: string
    linkDescription: string
    linkImageUrl: string
    linkMessage: string
    linkTitle: string
    rewards: {
      rewardTitle: string
      rewardType: string
      iconEnabledUrl: string
      iconDisabledUrl: string
      voucherCode: string
      coinAmount: number
      rewardPosition: number
      quota: number
      sequence: SequenceType[]
    }[]
  }
}

export type PutStatusReferralMissionRequestType = {
  id: string
  payload: {
    status: 'DELETED' | 'MANUAL_DEACTIVATE' | 'ACTIVE'
  }
}

export type PutStatusReferralMissionResponseType = {
  data: {
    id: number
    status: {
      name: string
      value: string
    }
  }
  error: ErrorType
}

export type GetReferralStatusResponseType = {
  data: {
    content: {
      name: string
      value: string
    }[]
  }
}

export const postUploadMissionImage = ({ payload }: PostUploadMissionImageRequestType) =>
  Post<PostUploadMissionImageResponseType>({ url: '/api/promo/upload-image', data: payload })

export const getRewardType = () =>
  Get<GetRewardTypeResponseType>({ url: '/api/promo/referral-mission/reward-type' })

export const getAllMissions = ({ params }: GetAllMissionRequestType) =>
  Get<GetAllMissionResponseType>({ url: '/api/promo/referral-mission', params })

export const getVoucherList = ({ params }: GetVoucherListRequestType) =>
  Get<GetVoucherListResponseType>({
    url: '/api/voucher',
    params: { ...params, status: 'inactive||active' },
  })

export const postReferralMission = ({ payload }: PostReferralMissionRequestType) =>
  Post<PostReferralMissionResponseType>({ url: `/api/promo/referral-mission`, data: payload })

export const getMissionByID = ({ id }: GetMissionByIDRequestType) =>
  Get<GetMissionByIDResponseType>({ url: `/api/promo/referral-mission/${id}` })

export const putReferralMission = ({ id, payload }: PutReferralMissionRequestType) =>
  Put<PutReferralMissionResponseType>({ url: `/api/promo/referral-mission/${id}`, data: payload })

export const putStatusReferralMission = ({ id, payload }: PutStatusReferralMissionRequestType) =>
  Put<PutStatusReferralMissionResponseType>({
    url: `/api/promo/referral-mission/status/${id}`,
    data: payload,
  })

export const getReferralStatus = () =>
  Get<GetReferralStatusResponseType>({ url: '/api/promo/referral-mission/status' })
