import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { RootStateType } from 'store'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getWatchTowerQueryRuleList,
  postWatchTowerQueryRule,
  putWatchTowerQueryRule,
  patchWatchTowerQueryRule,
  type QueryRuleType,
  type QueryRuleParamsType,
  type QueryRuleResponseType,
  type CreateResponseType,
} from 'utils/apiList/fraudWatchTowerQueryRule'

const SLICE_NAME = 'fraudWatchTowerQueryRule'

export type FraudWatchTowerQueryRuleStateType = {
  isLoading: boolean
  createEditForm: QueryRuleType
  list: QueryRuleType[]
  pagination: QueryRuleParamsType
  showCreateEditModal: boolean
}

const initialState: FraudWatchTowerQueryRuleStateType = {
  isLoading: false,
  list: [],
  createEditForm: {
    name: '',
    identifier: '',
    status: 'INACTIVE',
    description: '',
  },
  pagination: {
    sort: 'id',
    direction: 'desc',
    pageSize: 10,
    pageIndex: 0,
    totalElement: 0,
    numberOfElements: 0,
    totalPages: 0,
  },
  showCreateEditModal: false,
}

const fraudQueryRule = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    reset: () => initialState,
    resetCreateEditForm: (state) => {
      state.createEditForm = initialState.createEditForm
    },
    setCreateEditForm: (
      state,
      action: PayloadAction<{
        key: keyof FraudWatchTowerQueryRuleStateType['createEditForm']
        value: unknown
      }>,
    ) => {
      const { payload } = action

      state.createEditForm = {
        ...state.createEditForm,
        [payload.key]: payload.value,
      }
    },
    setPrefilledCreateEditForm: (state, action: PayloadAction<QueryRuleType>) => {
      const { payload } = action
      state.createEditForm = payload
    },
    setPagination: (state, action: PayloadAction<QueryRuleParamsType>) => {
      const { payload } = action
      state.pagination = {
        ...state.pagination,
        ...payload,
      }
    },
    setShowCreateEditModal: (state, action: PayloadAction<boolean>) => {
      const { payload } = action
      state.showCreateEditModal = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQueryList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchQueryList.fulfilled, (state, action: PayloadAction<QueryRuleResponseType>) => {
        state.list = action?.payload?.data?.queryIdentifiers
        state.pagination = action?.payload?.pagination
        state.isLoading = false
      })
      .addCase(fetchQueryList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(createQueryRule.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createQueryRule.fulfilled, (state, action: PayloadAction<CreateResponseType>) => {
        state.isLoading = false
        state.showCreateEditModal = false

        const newList = [...state.list]
        newList.unshift(action.payload.data)
        newList.pop()
        state.list = newList
      })
      .addCase(createQueryRule.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateQueryRule.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateQueryRule.fulfilled, (state, action: PayloadAction<CreateResponseType>) => {
        const { payload } = action
        state.isLoading = false
        state.showCreateEditModal = false

        const newList = [...state.list]
        state.list = newList.map((data) => {
          if (data.id === payload.data.id) return payload.data
          return data
        })
      })
      .addCase(updateQueryRule.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const {
  setIsLoading,
  reset,
  resetCreateEditForm,
  setCreateEditForm,
  setPrefilledCreateEditForm,
  setPagination,
  setShowCreateEditModal,
} = fraudQueryRule.actions

export default fraudQueryRule.reducer

export const fetchQueryList = createAsyncThunk(
  `${SLICE_NAME}/fetchQueryList`,
  async (_, { getState, rejectWithValue }) => {
    try {
      const { pagination } = (getState() as RootStateType)[SLICE_NAME]
      const { data } = await getWatchTowerQueryRuleList(pagination)
      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan detail watch tower rule')
      return rejectWithValue(err)
    }
  },
)

export const createQueryRule = createAsyncThunk(
  `${SLICE_NAME}/createQueryRule`,
  async ({ payload }: { payload: QueryRuleType }, { rejectWithValue }) => {
    try {
      const { data } = await postWatchTowerQueryRule(payload)
      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal menambahkan Query Rule')
      return rejectWithValue(err)
    }
  },
)

export const updateQueryRule = createAsyncThunk(
  `${SLICE_NAME}/updateQueryRule`,
  async ({ payload, id }: { payload: QueryRuleType; id: number }, { rejectWithValue }) => {
    try {
      await putWatchTowerQueryRule(payload, id)
      return {
        data: {
          id,
          ...payload,
        },
      }
    } catch (err) {
      callErrorMsg(err, 'Gagal mengupdate Query Rule')
      return rejectWithValue(err)
    }
  },
)

export const changeQueryRuleStatus = createAsyncThunk(
  `${SLICE_NAME}/updateQueryRule`,
  async ({ payload, id }: { payload: QueryRuleType; id: number }, { rejectWithValue }) => {
    try {
      await patchWatchTowerQueryRule(payload, id)
      return {
        data: {
          id,
          ...payload,
        },
      }
    } catch (err) {
      callErrorMsg(err, 'Gagal mengubah status Query Rule')
      return rejectWithValue(err)
    }
  },
)
