import { memo } from 'react'
import styled from 'styled-components'
import QRCode from 'react-qr-code'

function QRSection({ data }) {
  return (
    <ImageQR>
      <QRCode value={data?.code} size={60} />
    </ImageQR>
  )
}

export default memo(QRSection)

const ImageQR = styled.div`
  width: 60px;
`
