import { memo, useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { TextBody, Input, SystemIcon, Button } from 'components'
import { toBase64 } from 'helpers'
import { hasAccess } from 'customHooks/useCustom'
import allowed from 'middleware/allowedRoleOnFeature/categoryAndPosition/catalogue'
import noImage from 'assets/images/no-image.jpg'
import { Icon } from '@astro-ui/components'

function CatalogueCard({ data, handleAddSubCatalogue, handleClickEdit, handleOpenModalDelete }) {
  const [subCatalogueName, setSubCatalogueName] = useState('')
  const [image, setImage] = useState('')

  const handleChangeSubCategory = ({ target: { value } }) => setSubCatalogueName(value)

  const handleSubmit = () => {
    const payload = { ...data }
    payload.category_childs.push({
      category_active: true,
      category_image: image,
      category_name: subCatalogueName,
    })
    setImage('')
    setSubCatalogueName('')
    handleAddSubCatalogue(payload)
  }
  const handleChangeImage = ({ target: { files } }) => {
    toBase64(files[0]).then((base64) => {
      setImage(base64)
    })
  }
  const dateFormat = (v) => dayjs(v).format('DD MMM YYYY')

  const validateImage = () => {
    return data?.catalogueType === 2 ? data?.iconUrl || noImage : data.category_image || noImage
  }

  return (
    <Container>
      <Header>
        <ImgCatalogue size="40px" alt="box" src={validateImage()} />
        <HeaderContent>
          <HeaderTitle>
            <TextBody className="ml-10 text-over">{data.category_name}</TextBody>
            <CustomWrapper
              className="ml-auto"
              variant="wrapper"
              onClick={() =>
                handleOpenModalDelete({
                  category_id: data.category_id,
                  category_name: data.category_name,
                })
              }
              style={{ marginRight: '9px' }}
            >
              <Icon
                size="15px"
                style={{ marginBottom: '4px' }}
                className="pointer"
                icon="trash"
                color={'red'}
              />
            </CustomWrapper>

            {hasAccess(allowed.edit) && (
              <CustomWrapper variant="wrapper" onClick={() => handleClickEdit(data)}>
                <SystemIcon className="pointer" colorIcon="main" iconName="edit" />
                <TextBody color="main">Edit</TextBody>
              </CustomWrapper>
            )}
          </HeaderTitle>
          {data.start_date && data.end_date && (
            <Date>
              <DateItem>
                <DateLabel>Waktu Mulai</DateLabel>
                <DateText>
                  {dateFormat(data.start_date)}, {data.is_all_day ? '00:00' : data.start_time}
                </DateText>
              </DateItem>
              <DateItem>
                <DateLabel>Waktu Berakhir</DateLabel>
                <DateText>
                  {dateFormat(data.end_date)}, {data.is_all_day ? '23:59' : data.end_time}
                </DateText>
              </DateItem>
            </Date>
          )}
        </HeaderContent>
      </Header>
      <Content>
        {data.category_childs.map((el) => (
          <SubCatalogue key={el.category_id}>
            <ImgCatalogue
              size="32px"
              alt="box"
              src={el.category_image ? el.category_image : noImage}
            />
            <TextBody className="ml-10 text-over" size="smaller">
              {el.category_name}
            </TextBody>
          </SubCatalogue>
        ))}
      </Content>
      <Footer>
        <label htmlFor={`subcategory-image-card-${data.category_id}`} className="pointer">
          <IconWrapper>
            {image ? (
              <Images size="40px" src={image} alt="subCategory" />
            ) : (
              <SystemIcon iconName="image" />
            )}
          </IconWrapper>
        </label>
        <input
          id={`subcategory-image-card-${data.category_id}`}
          type="file"
          hidden
          accept="image/*"
          name="category"
          onChange={handleChangeImage}
        />
        <InputWrapper>
          <Input
            placeholder="Tambah sub-kategori baru"
            value={subCatalogueName}
            onChange={handleChangeSubCategory}
          />
          {subCatalogueName && <CustomButton onClick={handleSubmit}>Tambah</CustomButton>}
        </InputWrapper>
      </Footer>
    </Container>
  )
}

export default memo(CatalogueCard)

const SubCatalogue = styled.div`
  border-radius: 6px;
  background-color: ${({ theme: { colors } }) => colors.softGrey};
  padding: 8px 12px;
  display: flex;
  align-items: center;
  border: 1px solid #e5ecf0;
`

const Content = styled.div`
  padding: 0 16px;
  overflow: scroll;
  height: 200px;
  max-height: 200px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const InputWrapper = styled.div`
  position: relative;
  flex: 1;
  margin-left: 8px;
  input {
    background-color: ${({ theme: { colors } }) => colors.softGrey};
    padding: 11.5px 16px;
  }
`

const ImgCatalogue = styled.img`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  border-radius: 5px;
  background-color: ${({ theme: { colors } }) => colors.mainFaded};
  border: none;
`

const IconWrapper = styled.div`
  border: 1px dashed #e5ecf0;
  height: 42px;
  width: 42px;
  background-color: ${({ theme: { colors } }) => colors.softGrey};
  border-radius: 6px;
  display: grid;
  place-items: center;
`

const Footer = styled.div`
  padding: 8px 16px;
  border-top: 1px solid #eff3f9;
  display: flex;
  align-items: center;
`

const Header = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
`

const Container = styled.div`
  border-radius: 8px;
  background-color: ${({ theme: { colors } }) => colors.white};
  width: 370px;
  height: fit-content;
`

const CustomButton = styled(Button)`
  position: absolute;
  right: 10px;
  padding: 5px 12px;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  top: 8px;
`

const CustomWrapper = styled(Button)`
  display: flex;
  gap: 5px;
  align-items: center;
`

const Images = styled.img`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 5px;
`

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const HeaderContent = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
  flex: 1;
`

const Date = styled.div`
  display: flex;
  align-items: center;
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
`

const DateItem = styled.div``

const DateLabel = styled.label`
  font-size: 10px;
  color: #6d7588;
`

const DateText = styled.p`
  font-size: 10px;
  color: #212121;
  margin-bottom: 0;
`
