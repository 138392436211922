/* eslint-disabled */
import axios from 'axios'
import AxiosInstance from 'config/apiServiceApiGateway'

const { REACT_APP_BASE_URL_API } = process.env
const baseUrl = REACT_APP_BASE_URL_API

export type LocationType = {
  location_id: number
  location_name: string
}

export type GetLocationsType = {
  content: LocationType[]
}

export type TipeType = 'INBOUND' | 'OUTBOUND'

export type LoadingDockType = {
  id: number
  type: TipeType
  name: string
  location: {
    id: number
    name: string
    type: 'WAREHOUSE'
  }
  createdAt: number
  createdBy: string
  updatedAt: number | null
  updatedBy: string | null
}

export type LoadingDockParamsType = {
  type: TipeType | ''
  locationId: number | null
}

export type LoadingDockRequestParamType = Partial<LoadingDockParamsType>

export interface GetLoadingDockInterface {
  data: LoadingDockType[]
}

export type PostLoadingDockType = {
  locationId: number
  name: string
  type: string
  executedBy: string
}

export const getLoadingDockListApi = (params: LoadingDockRequestParamType) =>
  AxiosInstance.Get<GetLoadingDockInterface, true>({
    url: '/staff-management/internal/:version/loading-dock',
    version: 'v1',
    params,
    convertRequest: true,
    convertResponse: true,
  })

export const postLoadingDock = (data: PostLoadingDockType) =>
  AxiosInstance.Post({
    url: '/staff-management/internal/:version/loading-dock',
    version: 'v1',
    data,
    convertRequest: true,
  })

export const deleteLoadingDock = (id: number, executed_by: string) =>
  AxiosInstance.Delete({
    url: '/staff-management/internal/:version/loading-dock/:id',
    version: 'v1',
    params: { executed_by },
    urlParams: { id },
  })

export const getLocations = (name: string) =>
  axios.get<GetLocationsType>(`${baseUrl}/api/location`, { params: { name } })
