/* eslint-disable react/display-name */
import { compose } from 'helpers/general'
import { createContext, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import thunk from './middleware'
import reducers, { initialState } from './reducers'

function store() {
  // gwp
  const initialStateGwp = initialState.gwp
  const GwpContext = createContext(initialStateGwp)

  // putaway
  const initialStatePutAway = initialState.putAway
  const PutAwayContext = createContext(initialStatePutAway)

  // receiving
  const initialStateReceiving = initialState.receiving
  const ReceivingContext = createContext(initialStateReceiving)

  // grn
  const initialStateGrn = initialState.grn
  const GrnContext = createContext(initialStateGrn)

  // damage putaway
  const initialStateDamagePutAway = initialState.damagePutAway
  const DamagePutAwayContext = createContext(initialStateDamagePutAway)

  // auth
  const initialStateAuth = initialState.auth
  const AuthContext = createContext(initialStateAuth)
  // product
  const initialStateProduct = initialState.product
  const ProductContext = createContext(initialStateProduct)
  // product Add edit
  const initialStateProductAddEdit = initialState.productAddEdit
  const ProductAddEditContext = createContext(initialStateProductAddEdit)
  // category
  const initialStateCategory = initialState.category
  const CategoryContext = createContext(initialStateCategory)
  // catalogue
  const initialStateCatalogue = initialState.catalogue
  const CatalogueContext = createContext(initialStateCatalogue)

  // location
  const initialStateLocation = initialState.location
  const LocationContext = createContext(initialStateLocation)
  //hub
  const initialStateHub = initialState.hub
  const HubContext = createContext(initialStateHub)
  //warehouse
  const initialStateWarehouse = initialState.warehouse
  const WarehouseContext = createContext(initialStateWarehouse)
  //supplyOrder
  const initialStateSupplyOrder = initialState.supplyOrder
  const SupplyOrderContext = createContext(initialStateSupplyOrder)
  //inbound
  const initialStateInbound = initialState.inbound
  const InboundContext = createContext(initialStateInbound)
  //driver
  const initialStateDriver = initialState.driver
  const DriverContext = createContext(initialStateDriver)
  //buyer
  const initialStateBuyer = initialState.buyer
  const BuyerContext = createContext(initialStateBuyer)
  // Picker
  const initialStatePicker = initialState.picker
  const PickerContext = createContext(initialStatePicker)
  // Packer
  const initialStatePacker = initialState.packer
  const PackerContext = createContext(initialStatePacker)
  //admin
  const initialStateAdmin = initialState.admin
  const AdminContext = createContext(initialStateAdmin)
  //allorder
  const initialStateAllorder = initialState.allorder
  const AllorderContext = createContext(initialStateAllorder)
  //settings
  const initialStateSettings = initialState.settings
  const SettingsContext = createContext(initialStateSettings)
  //inboundWarehouse
  const initialStateInboundWarehouse = initialState.inboundWarehouse
  const InboundWarehouseContext = createContext(initialStateInboundWarehouse)
  //promotion
  const initialStatePromotion = initialState.promotion
  const PromotionContext = createContext(initialStatePromotion)
  //ticker
  const initialStateTicker = initialState.ticker
  const TickerContext = createContext(initialStateTicker)
  //serviceLevel
  const initialStateServiceLevel = initialState.serviceLevel
  const ServiceLevelContext = createContext(initialStateServiceLevel)
  //referralSetting
  const initialStateReferralSetting = initialState.referralSetting
  const ReferralSettingContext = createContext(initialStateReferralSetting)
  //warehouseDetail
  const initialStateWarehouseDetail = initialState.warehouseDetail
  const WarehouseDetailContext = createContext(initialStateWarehouseDetail)
  //hubDetail
  const initialStateHubDetail = initialState.hubDetail
  const HubDetailContext = createContext(initialStateHubDetail)
  //refferal
  const initialStateRefferal = initialState.refferal
  const RefferalContext = createContext(initialStateRefferal)
  //rack
  const initialStateRackDetail = initialState.rackDetail
  const RackDetailContext = createContext(initialStateRackDetail)
  //rak
  const initialStateRak = initialState.rak
  const RakContext = createContext(initialStateRak)

  //rack detail edit
  const initialStateRackDetailEdit = initialState.rackDetailEdit
  const RackDetailEditContext = createContext(initialStateRackDetailEdit)

  //rack add edit
  const initialStateRackAddEdit = initialState.rackAddEdit
  const RackAddEditContext = createContext(initialStateRackAddEdit)

  //massUpload
  const initialStateMassUpload = initialState.massUpload
  const MassUploadContext = createContext(initialStateMassUpload)
  //setPosition
  const initialStateSetPosition = initialState.setPosition
  const SetPositionContext = createContext(initialStateSetPosition)
  //uom
  const initialStateUom = initialState.uom
  const UomContext = createContext(initialStateUom)
  //printArea
  const initialStatePrintArea = initialState.printArea
  const PrintAreaContext = createContext(initialStatePrintArea)
  //purchaseOrder
  const initialStatePurchaseOrder = initialState.purchaseOrder
  const PurchaseOrderContext = createContext(initialStatePurchaseOrder)
  //campaign
  const initialStateCampaign = initialState.campaign
  const CampaignContext = createContext(initialStateCampaign)
  //fraud
  const initialStateFraud = initialState.fraud
  const FraudContext = createContext(initialStateFraud)

  //bom
  const initialStateBoM = initialState.bom
  const BoMContext = createContext(initialStateBoM)
  //bomAddEdit
  const initialStateBoMAddEdit = initialState.bomAddEdit
  const BoMAddEditContext = createContext(initialStateBoMAddEdit)
  //bomAddProduct
  const initialStateBoMAddProduct = initialState.bomAddProduct
  const BoMAddProductContext = createContext(initialStateBoMAddProduct)

  //loyalty-voucher
  const initialStateVoucher = initialState.voucher
  const VoucherContext = createContext(initialStateVoucher)

  const StoreContextProvider = (props) => {
    const history = useHistory()

    //auth
    const [stateAuth, dispatchAuth] = useReducer(reducers.authReducer, initialStateAuth)
    //product
    const [stateProduct, dispatchProduct] = useReducer(reducers.productReducer, initialStateProduct)
    const [stateProductAddEdit, dispatchProductAddEdit] = useReducer(
      reducers.productAddEditReducer,
      initialStateProductAddEdit,
    )

    //gwp
    const [stateGwp, dispatchGwp] = useReducer(reducers.gwpReducer, initialStateGwp)

    //put away
    const [statePutAway, dispatchPutAway] = useReducer(reducers.putAwayReducer, initialStatePutAway)
    //receiving
    const [stateReceiving, dispatchReceiving] = useReducer(
      reducers.receivingReducer,
      initialStateReceiving,
    )

    //grn
    const [stateGRN, dispatchGRN] = useReducer(reducers.receivingReducer, initialStateReceiving)

    //damage putaway
    const [stateDamagePutAway, dispatchDamagePutAway] = useReducer(
      reducers.receivingReducer,
      initialStateReceiving,
    )

    // cateogry
    const [stateCategory, dispatchCategory] = useReducer(
      reducers.categoryReducer,
      initialStateCategory,
    )
    // catalogue
    const [stateCatalogue, dispatchCatalogue] = useReducer(
      reducers.catalogueReducer,
      initialStateCatalogue,
    )

    //location
    const [stateLocation, dispatchLocation] = useReducer(
      reducers.locationReducer,
      initialStateLocation,
    )
    //hub
    const [stateHub, dispatchHub] = useReducer(reducers.hubReducer, initialStateHub)
    //warehouse
    const [stateWarehouse, dispatchWarehouse] = useReducer(
      reducers.warehouseReducer,
      initialStateWarehouse,
    )
    //supplyOrder
    const [stateSupplyOrder, dispatchSupplyOrder] = useReducer(
      reducers.supplyOrderReducer,
      initialStateSupplyOrder,
    )
    //inbound
    const [stateInbound, dispatchInbound] = useReducer(reducers.inboundReducer, initialStateInbound)
    //driver
    const [stateDriver, dispatchDriver] = useReducer(reducers.driverReducer, initialStateDriver)
    //buyer
    const [stateBuyer, dispatchBuyer] = useReducer(reducers.buyerReducer, initialStateBuyer)
    //picker
    const [statePicker, dispatchPicker] = useReducer(reducers.pickerReducer, initialStatePicker)
    //picker
    const [statePacker, dispatchPacker] = useReducer(reducers.packerReducer, initialStatePacker)

    //admin
    const [stateAdmin, dispatchAdmin] = useReducer(reducers.adminReducer, initialStateAdmin)
    //all order
    const [stateAllorder, dispatchAllorder] = useReducer(
      reducers.allorderReducer,
      initialStateAllorder,
    )
    //settings
    const [stateSettings, dispatchSettings] = useReducer(
      reducers.settingsReducer,
      initialStateSettings,
    )
    //inboundWarehouse
    const [stateInboundWarehouse, dispatchInboundWarehouse] = useReducer(
      reducers.inboundWarehouseReducer,
      initialStateInboundWarehouse,
    )
    //promotion
    const [statePromotion, dispatchPromotion] = useReducer(
      reducers.promotionReducer,
      initialStatePromotion,
    )
    //ticker
    const [stateTicker, dispatchTicker] = useReducer(reducers.tickerReducer, initialStateTicker)
    //serviceLevel
    const [stateServiceLevel, dispatchServiceLevel] = useReducer(
      reducers.serviceLevelReducer,
      initialStateServiceLevel,
    )
    //referraSetting
    const [stateReferralSetting, dispatchReferralSetting] = useReducer(
      reducers.referralSettingReducer,
      initialStateReferralSetting,
    )
    //warehouseDetail
    const [stateWarehouseDetail, dispatchWarehouseDetail] = useReducer(
      reducers.warehouseDetailReducer,
      initialStateWarehouseDetail,
    )
    //hubDetail
    const [stateHubDetail, dispatchHubDetail] = useReducer(
      reducers.hubDetailReducer,
      initialStateHubDetail,
    )

    //refferal
    const [stateRefferal, dispatchRefferal] = useReducer(
      reducers.refferalReducer,
      initialStateRefferal,
    )
    //massUpload
    const [stateMassUpload, dispatchMassUpload] = useReducer(
      reducers.massUploadReducer,
      initialStateMassUpload,
    )
    //setPosition
    const [stateSetPosition, distpatchSetPosition] = useReducer(
      reducers.setPositionReducer,
      initialStateSetPosition,
    )
    //Uom
    const [stateUom, dispatchUom] = useReducer(reducers.uomReducer, initialStateUom)

    //rack
    const [stateRackDetail, dispatchRackDetail] = useReducer(
      reducers.rackDetailReducer,
      initialStateRackDetail,
    )
    //rak
    const [stateRak, dispatchRak] = useReducer(reducers.rakReducer, initialStateRak)

    //rack detail edit
    const [stateRackDetailEdit, dispatchRackDetailEdit] = useReducer(
      reducers.rackDetailEditReducer,
      initialStateRackDetailEdit,
    )

    const [stateRackAddEdit, dispatchRackAddEdit] = useReducer(
      reducers.rackAddEditReducer,
      initialStateRackAddEdit,
    )

    //print Area
    const [statePrintArea, dispatchPrintArea] = useReducer(
      reducers.printAreaReducer,
      initialStatePrintArea,
    )

    //purchase Order
    const [statePurchaseOrder, dispatchPurchaseOrder] = useReducer(
      reducers.purchaseOrderReducer,
      initialStatePurchaseOrder,
    )

    //campaign
    const [stateCampaign, dispatchCampaign] = useReducer(
      reducers.campaignReducer,
      initialStateCampaign,
    )

    //fraud
    const [stateFraud, dispatchFraud] = useReducer(reducers.fraudReducer, initialStateFraud)

    //voucher
    const [stateVoucher, dispatchVoucher] = useReducer(reducers.voucherReducer, initialStateVoucher)

    //bom
    const [stateBoM, dispatchBoM] = useReducer(reducers.bomReducer, initialStateBoM)

    //bom
    const [stateBoMAddEdit, dispatchBoMAddEdit] = useReducer(
      reducers.bomAddEditReducer,
      initialStateBoMAddEdit,
    )

    //bomAddProduct
    const [stateBoMAddProduct, dispatchBoMAddProduct] = useReducer(
      reducers.bomAddProductReducer,
      initialStateBoMAddProduct,
    )

    const addMiddleWare = (dispatch) => (action) => {
      thunk(action, dispatch, {
        routing: history,
        stateAuth,
        stateProduct,
        stateProductAddEdit,
        stateGwp,
        stateCatalogue,
        stateCategory,
        stateLocation,
        statePutAway,
        stateReceiving,
        stateHub,
        stateWarehouse,
        stateSupplyOrder,
        stateInbound,
        stateDriver,
        stateBuyer,
        statePicker,
        statePacker,
        stateAdmin,
        stateAllorder,
        stateSettings,
        stateInboundWarehouse,
        statePromotion,
        stateTicker,
        stateServiceLevel,
        stateReferralSetting,
        stateWarehouseDetail,
        stateHubDetail,
        stateRefferal,
        stateRak,
        stateMassUpload,
        stateSetPosition,
        stateUom,
        statePrintArea,
        statePurchaseOrder,
        stateCampaign,
        stateFraud,
        stateVoucher,
        stateBoM,
        stateBoMAddEdit,
        stateBoMAddProduct,
      })
    }

    const generateProvider =
      (ContextProvider, { state, dispatch }) =>
      (Component) => {
        return (
          <ContextProvider value={{ state, dispatch: addMiddleWare(dispatch) }}>
            {Component}
          </ContextProvider>
        )
      }

    return compose(
      generateProvider(AuthContext.Provider, {
        state: stateAuth,
        dispatch: dispatchAuth,
      }),
      generateProvider(ProductContext.Provider, {
        state: stateProduct,
        dispatch: dispatchProduct,
      }),
      generateProvider(ProductAddEditContext.Provider, {
        state: stateProductAddEdit,
        dispatch: dispatchProductAddEdit,
      }),
      generateProvider(CategoryContext.Provider, {
        state: stateCategory,
        dispatch: dispatchCategory,
      }),
      generateProvider(CatalogueContext.Provider, {
        state: stateCatalogue,
        dispatch: dispatchCatalogue,
      }),
      generateProvider(LocationContext.Provider, {
        state: stateLocation,
        dispatch: dispatchLocation,
      }),
      generateProvider(HubContext.Provider, {
        state: stateHub,
        dispatch: dispatchHub,
      }),
      generateProvider(WarehouseContext.Provider, {
        state: stateWarehouse,
        dispatch: dispatchWarehouse,
      }),
      generateProvider(SupplyOrderContext.Provider, {
        state: stateSupplyOrder,
        dispatch: dispatchSupplyOrder,
      }),
      generateProvider(InboundContext.Provider, {
        state: stateInbound,
        dispatch: dispatchInbound,
      }),
      generateProvider(DriverContext.Provider, {
        state: stateDriver,
        dispatch: dispatchDriver,
      }),
      generateProvider(BuyerContext.Provider, {
        state: stateBuyer,
        dispatch: dispatchBuyer,
      }),
      generateProvider(PickerContext.Provider, {
        state: statePicker,
        dispatch: dispatchPicker,
      }),
      generateProvider(PackerContext.Provider, {
        state: statePacker,
        dispatch: dispatchPacker,
      }),
      generateProvider(AdminContext.Provider, {
        state: stateAdmin,
        dispatch: dispatchAdmin,
      }),
      generateProvider(AllorderContext.Provider, {
        state: stateAllorder,
        dispatch: dispatchAllorder,
      }),
      generateProvider(SettingsContext.Provider, {
        state: stateSettings,
        dispatch: dispatchSettings,
      }),
      generateProvider(InboundWarehouseContext.Provider, {
        state: stateInboundWarehouse,
        dispatch: dispatchInboundWarehouse,
      }),
      generateProvider(PromotionContext.Provider, {
        state: statePromotion,
        dispatch: dispatchPromotion,
      }),
      generateProvider(TickerContext.Provider, {
        state: stateTicker,
        dispatch: dispatchTicker,
      }),
      generateProvider(ServiceLevelContext.Provider, {
        state: stateServiceLevel,
        dispatch: dispatchServiceLevel,
      }),
      generateProvider(WarehouseDetailContext.Provider, {
        state: stateWarehouseDetail,
        dispatch: dispatchWarehouseDetail,
      }),
      generateProvider(HubDetailContext.Provider, {
        state: stateHubDetail,
        dispatch: dispatchHubDetail,
      }),
      generateProvider(ReferralSettingContext.Provider, {
        state: stateReferralSetting,
        dispatch: dispatchReferralSetting,
      }),
      generateProvider(RefferalContext.Provider, {
        state: stateRefferal,
        dispatch: dispatchRefferal,
      }),
      generateProvider(RakContext.Provider, {
        state: stateRak,
        dispatch: dispatchRak,
      }),
      generateProvider(MassUploadContext.Provider, {
        state: stateMassUpload,
        dispatch: dispatchMassUpload,
      }),
      generateProvider(SetPositionContext.Provider, {
        state: stateSetPosition,
        dispatch: distpatchSetPosition,
      }),
      generateProvider(UomContext.Provider, {
        state: stateUom,
        dispatch: dispatchUom,
      }),
      generateProvider(PrintAreaContext.Provider, {
        state: statePrintArea,
        dispatch: dispatchPrintArea,
      }),
      generateProvider(PurchaseOrderContext.Provider, {
        state: statePurchaseOrder,
        dispatch: dispatchPurchaseOrder,
      }),
      generateProvider(CampaignContext.Provider, {
        state: stateCampaign,
        dispatch: dispatchCampaign,
      }),
      generateProvider(PutAwayContext.Provider, {
        state: statePutAway,
        dispatch: dispatchPutAway,
      }),
      generateProvider(ReceivingContext.Provider, {
        state: stateReceiving,
        dispatch: dispatchReceiving,
      }),
      generateProvider(GrnContext.Provider, {
        state: stateGRN,
        dispatch: dispatchGRN,
      }),
      generateProvider(DamagePutAwayContext.Provider, {
        state: stateDamagePutAway,
        dispatch: dispatchDamagePutAway,
      }),
      generateProvider(GwpContext.Provider, {
        state: stateGwp,
        dispatch: dispatchGwp,
      }),
      generateProvider(RackDetailContext.Provider, {
        state: stateRackDetail,
        dispatch: dispatchRackDetail,
      }),
      generateProvider(RackDetailEditContext.Provider, {
        state: stateRackDetailEdit,
        dispatch: dispatchRackDetailEdit,
      }),
      generateProvider(RackAddEditContext.Provider, {
        state: stateRackAddEdit,
        dispatch: dispatchRackAddEdit,
      }),
      generateProvider(FraudContext.Provider, {
        state: stateFraud,
        dispatch: dispatchFraud,
      }),
      generateProvider(VoucherContext.Provider, {
        state: stateVoucher,
        dispatch: dispatchVoucher,
      }),
      generateProvider(BoMContext.Provider, {
        state: stateBoM,
        dispatch: dispatchBoM,
      }),
      generateProvider(BoMAddEditContext.Provider, {
        state: stateBoMAddEdit,
        dispatch: dispatchBoMAddEdit,
      }),
      generateProvider(BoMAddProductContext.Provider, {
        state: stateBoMAddProduct,
        dispatch: dispatchBoMAddProduct,
      }),
    )(props.children)
  }

  return {
    StoreContextProvider,
    AuthContext,
    ProductContext,
    ProductAddEditContext,
    CategoryContext,
    CatalogueContext,
    LocationContext,
    HubContext,
    WarehouseContext,
    SupplyOrderContext,
    InboundContext,
    DriverContext,
    BuyerContext,
    PickerContext,
    PackerContext,
    AdminContext,
    AllorderContext,
    SettingsContext,
    InboundWarehouseContext,
    PromotionContext,
    TickerContext,
    ReferralSettingContext,
    WarehouseDetailContext,
    HubDetailContext,
    ServiceLevelContext,
    RefferalContext,
    RakContext,
    MassUploadContext,
    SetPositionContext,
    UomContext,
    PrintAreaContext,
    PurchaseOrderContext,
    CampaignContext,
    PutAwayContext,
    ReceivingContext,
    GrnContext,
    DamagePutAwayContext,
    GwpContext,
    RackDetailContext,
    RackDetailEditContext,
    RackAddEditContext,
    FraudContext,
    VoucherContext,
    BoMContext,
    BoMAddEditContext,
    BoMAddProductContext,
  }
}

export default store()
