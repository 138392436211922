import { memo } from 'react'
import styled from 'styled-components'
import Barcode from 'react-barcode'
import QRCode from 'react-qr-code'

import { Box, Icon, Typography } from '@astro-ui/components'

function BarcodeSection({ data }) {
  return (
    <Box width="100mm" height="100mm" py="3px" px="3px" display="flex" alignItems="center">
      <Container>
        <StyledContainerHeader>
          <Icon icon="astro" size={70} />
          <div style={{ border: '1px solid #BFC9D9', borderRadius: 6, overflow: 'hidden' }}>
            <Typography variant="h4" p="4px 8px">
              {data.supplyOrderNumber}
            </Typography>
            <Box bg="black">
              <Typography variant="h5" p="2px 8px" color="white">
                No Koli {data.koliNumber}
              </Typography>
            </Box>
          </div>
        </StyledContainerHeader>

        <StyledContainerTable>
          <StyledTable>
            <tr>
              <td>
                <Typography variant="h6" mt="1px">
                  Asal
                </Typography>
              </td>
              <td>
                <Typography variant="h6" mt="2px">
                  {data.origin}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant="h6" mt="10px">
                  Tujuan
                </Typography>
              </td>
              <td>
                <Typography variant="h2">{data.destination}</Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography variant="h6" mt="4px">
                  No. SKU (Qty)
                </Typography>
              </td>
              <td style={{ padding: '4px 8px' }}>
                {data?.skuDetails?.map((el, index) => {
                  if (index < 4) {
                    return (
                      <>
                        <Typography variant="h6" key={index}>
                          {el.skuNumber} ({el.quantity})
                        </Typography>
                      </>
                    )
                  }
                  return null
                })}
                {data?.skuDetails?.length > 4 && <Typography variant="h6">Etc</Typography>}
                {data?.skuDetails?.length === 0 && <Typography variant="h6">-</Typography>}
              </td>
            </tr>
          </StyledTable>
        </StyledContainerTable>

        <div style={{ display: 'flex', gap: 32 }}>
          <ImageQR>
            <QRCode value={data?.koliCode} size={70} />
          </ImageQR>
          <BarcodeContainer>
            <Barcode value={data?.koliCode} displayValue={false} height={70} width={2} margin={0} />
            <Typography variant="h6" lineHeight="1.2">
              {data?.koliCode}
            </Typography>
          </BarcodeContainer>
        </div>
      </Container>
    </Box>
  )
}

export default memo(BarcodeSection)
const Container = styled.div`
  border: 1px solid black;
  border-radius: 4px;
  padding: 8px;
  display: inline-block;
  width: 100%;
  max-height: 100mm;
`

const StyledContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  & svg {
    height: 50px !important;
  }
`

const ImageQR = styled.div`
  width: 100px;
  position: relative;
  left: -12px;
`

const BarcodeContainer = styled.div`
  width: 200px;
  position: relative;
  left: -45px;
  svg {
    margin-left: auto;
    margin-right: auto;
  }
`

const StyledTable = styled.table`
  width: 100%;
  border-radius: 4;
  border-collapse: collapse;

  & tr {
    & td {
      vertical-align: top;
      padding: 0px 8px;
      border-bottom: 1px solid #bfc9d9;
      text-align: left;

      &:first-child {
        background-color: #f3f4f5;
        width: fit-content;
        white-space: nowrap;
      }

      &:last-child {
        width: 100%;
      }
    }

    &:last-child {
      & td {
        border-bottom: none;
      }
    }
  }
`

const StyledContainerTable = styled.div`
  margin: 0 0 12px;
  border-radius: 6px;
  border: 1px solid #bfc9d9;
  overflow: hidden;
`
