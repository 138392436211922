import {
  getMasterVariant,
  postMasterVariant,
  putMasterVariant,
} from 'features/Product/services/mastervariant'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'
import { SLICE_NAME } from './state'

export const fetchMasterVariant = createAsyncThunk(
  `${SLICE_NAME}/fetchMasterVariant`,
  async (_, { rejectWithValue, getState }) => {
    const {
      masterVariant: { query },
    } = getState() as StoreStateType

    try {
      const {
        data: { data },
      } = await getMasterVariant(query)

      return data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const submitAddMasterVariant = createAsyncThunk(
  `${SLICE_NAME}/submitAddMasterVariant`,
  async (_, { getState }) => {
    const {
      masterVariant: { form },
    } = getState() as StoreStateType

    try {
      const { data } = await postMasterVariant(form)

      return data.message
    } catch (error) {
      callErrorMsg(error)
      return ''
    }
  },
)

export const submitEditMasterVariant = createAsyncThunk(
  `${SLICE_NAME}/submitEditMasterVariant`,
  async (_, { getState }) => {
    const {
      masterVariant: { form, selectedVariantId },
    } = getState() as StoreStateType

    const payload = { ...form }
    payload.variants = form.variants.map((variant) => ({
      ...variant,
      id: variant.id ? variant.id : null,
    }))

    if (!selectedVariantId) return ''

    try {
      const { data } = await putMasterVariant(selectedVariantId, payload)

      return data.message
    } catch (error) {
      callErrorMsg(error)
      return ''
    }
  },
)
