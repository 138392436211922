import { memo, useRef, useState } from 'react'
import styled from 'styled-components'
import { Button, Icon } from '@astro-ui/components'
import listenClickOutside from 'customHooks/outSideClick'

function ButtonMenu({
  options = [],
  onClick = (item) => {},
  disabled = false,
  label = '',
  iconColor = 'blueTag',
  menuItemSx = {},
  ...buttonProps
}) {
  const refOutside = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleClick = (item) => {
    if (onClick) {
      onClick(item)
    }
    handleClose()
  }

  listenClickOutside(
    refOutside,
    () => {
      handleClose()
    },
    isOpen,
  )

  return (
    <MenuWrapper ref={refOutside}>
      <Button
        variant="outlined"
        sx={{ backgroundColor: 'white' }}
        onClick={handleOpen}
        disabled={disabled}
        endIcon={<Icon icon="dropdown" color={disabled ? '#AAB4C8' : iconColor} />}
        size="large"
        {...buttonProps}
      >
        {label}
      </Button>
      {isOpen && (
        <ListWrapper>
          {options.length ? (
            options.map((item) => (
              <MenuItem
                key={item.name}
                variant="wrapper"
                onClick={() => handleClick(item)}
                disabled={!!item?.disabled}
                sx={menuItemSx}
              >
                {item.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem>Data tidak ditemukan</MenuItem>
          )}
        </ListWrapper>
      )}
    </MenuWrapper>
  )
}

export default memo(ButtonMenu)

const MenuWrapper = styled.div`
  position: relative;
`

const ListWrapper = styled.div`
  position: absolute;
  background-color: white;
  width: 100%;
  margin: 4px 0;
  box-shadow: 0px 2px 16px 0px rgb(202 211 225 / 40%);
  border-radius: 4px;
  overflow: hidden;
  z-index: 1;
`

const MenuItem = styled(Button)`
  cursor: pointer;
  width: 100%;
  padding: 12px 12px;
  font-size: 0.8rem;
  border-bottom: 1px solid #f3f6fa;
  color: #212121;
  :last-child {
    border-bottom: none;
  }
  :hover {
    background-color: ${({ theme: { colors } }) => colors.mainFaded};
  }
  :disabled {
    background-color: ${({ theme: { colors } }) => colors.body};
  }
`
