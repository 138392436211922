import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  GetLoadingDockInterface,
  LoadingDockRequestParamType,
  LoadingDockParamsType,
  GetLocationsType,
  LoadingDockType,
  LocationType,
} from 'features/Enterprise/CapacityHandler/services/loadingDock'

import {
  fetchLoadingDock,
  fetchGetLocations,
  SLICE_NAME,
  removeLoadingDock,
} from './loadingDockThunk'

// eslint-disable-next-line no-shadow
export enum DialogStateEnum {
  CLOSED,
  OPENED,
}

interface LoadingDockStateInterface {
  isLoading: boolean
  loadingDockList: GetLoadingDockInterface['data']
  query: LoadingDockParamsType
  locations: GetLocationsType['content']
  createLoadingDockDialogState: DialogStateEnum
  deleteLoadingDockDialogState: DialogStateEnum
  selectedLoadingDock: LoadingDockType | null
  selectedLocation: LocationType | null
}

const initialState: LoadingDockStateInterface = {
  isLoading: false,
  loadingDockList: [],
  query: {
    locationId: null,
    type: '',
  },
  locations: [],
  createLoadingDockDialogState: DialogStateEnum.CLOSED,
  deleteLoadingDockDialogState: DialogStateEnum.CLOSED,
  selectedLoadingDock: null,
  selectedLocation: null,
}

const loadingDockSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    updateQuery: (state, { payload }: PayloadAction<LoadingDockRequestParamType>) => {
      state.query = {
        ...state.query,
        ...payload,
      }
    },
    setCreateLoadingDockDialogState: (state, { payload }: PayloadAction<DialogStateEnum>) => {
      state.createLoadingDockDialogState = payload
    },
    setDeleteLoadingDockDialogState: (state, { payload }: PayloadAction<DialogStateEnum>) => {
      state.deleteLoadingDockDialogState = payload
    },
    setSelectedLoadingDock: (state, { payload }: PayloadAction<LoadingDockType | null>) => {
      state.selectedLoadingDock = payload
    },
    setSelectedLocation: (state, { payload }: PayloadAction<LocationType | null>) => {
      state.selectedLocation = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLoadingDock.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchLoadingDock.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.loadingDockList = payload.data
      })
      .addCase(fetchLoadingDock.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchGetLocations.fulfilled, (state, { payload }) => {
        state.locations = payload.content
      })
      .addCase(removeLoadingDock.fulfilled, (state) => {
        state.deleteLoadingDockDialogState = DialogStateEnum.CLOSED
      })
  },
})

export const {
  resetState,
  updateQuery,
  setCreateLoadingDockDialogState,
  setDeleteLoadingDockDialogState,
  setSelectedLoadingDock,
  setSelectedLocation,
} = loadingDockSlice.actions
export default loadingDockSlice.reducer
