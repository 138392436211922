import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit'
import { withLoadingReducer } from 'utils/reducerHandler'
import { fetchGetLocations } from 'features/Location/redux/location'
import { GetLocationListResponseType } from 'features/Location/@types/location'
import { GetAreaListResponseType } from 'features/Rack/services/area'
import { fetchGetAreaList, SLICE_NAME } from './thunk'

export type AreaListSliceType = {
  isLoading: boolean
  isLocationLoading: boolean
  constants: {
    locations: GetLocationListResponseType['content']
  }
  data: GetAreaListResponseType
  pagination: {
    pageSize: number
    pageIndex: number
    numberOfElements: number
  }
}

const initialState: AreaListSliceType = {
  isLoading: false,
  isLocationLoading: false,
  constants: {
    locations: [],
  },
  data: [],
  pagination: {
    pageSize: 20,
    pageIndex: 0,
    numberOfElements: 0,
  },
}

const areaSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: withLoadingReducer(
    (builder: ActionReducerMapBuilder<AreaListSliceType>) => {
      builder
        .addCase(fetchGetLocations.pending, (state) => {
          state.isLocationLoading = true
        })
        .addCase(fetchGetLocations.fulfilled, (state, action) => {
          state.isLocationLoading = false
          state.constants.locations = action.payload.content
        })
        .addCase(fetchGetLocations.rejected, (state) => {
          state.isLocationLoading = false
        })
        .addCase(fetchGetAreaList.fulfilled, (state, action) => {
          const { data, pagination } = action.payload

          state.data = data

          if (pagination) {
            state.pagination = {
              pageSize: pagination.page_size as number,
              pageIndex: pagination.page_index as number,
              numberOfElements: pagination.number_of_elements as number,
            }
          }
        })
    },
    [fetchGetAreaList],
  ),
})

export default areaSlice.reducer
export const { reset } = areaSlice.actions
