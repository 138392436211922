import { memo, useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { Checkbox, TextBody, Tooltip } from 'components/atoms'
import { Box, Typography } from '@astro-ui/components'
import { OrderIDContainer } from 'pages/Order/components/RowOrder/component'
import copy from 'assets/images/icCopyBiru.svg'
import { toastFailed, toastSuccess } from 'utils/toast'
import { setPools } from 'redux/orderPooling'
import information from 'assets/images/information.svg'
import { externalDriverValidator } from 'utils/3plValidator'
import getParams from 'utils/getParams'
import storeContext from 'storeContext'
import { getDetailOrder } from 'storeContext/actions/allOrder'
import { setIsPopupDetailShow, setSelectedPacker } from 'storeContext/actions/packer'
import { detailOrder } from 'utils/api'
import { useAppDispatch, useAppSelector, shallowEqual } from 'store'
import {
  AUTH_ADMIN,
  AUTH_HO_ASTRO_KITCHEN_MANAGER,
  AUTH_HO_ASTRO_KITCHEN_STAFF,
} from 'middleware/privateRoute'

function OrderId({
  data,
  setOrderPoolingData,
  orderPoolingData,
  noCheckbox,
  dashUp,
  dashDown,
  isAllOrder,
  location,
  isAllOrderSuper,
}) {
  const { dispatch: dispatchPacker } = useContext(storeContext.PackerContext)
  const { dispatch: dispatchAllorder } = useContext(storeContext.AllorderContext)
  const dispatch = useAppDispatch()
  const {
    orderPooling: { pools },
    auth: { userData },
  } = useAppSelector((state) => state, shallowEqual)
  //INITIAL STATE
  const initCheckoutOrderPValue = false

  //STATE HOOKS
  const [checkoutOrderPValue, setCheckoutOrderPValue] = useState(initCheckoutOrderPValue)

  //EFFECT HOOKS
  useEffect(() => {
    if (pools) {
      const initCheckoutOrderPValueOnFirstRender = pools.some((val) => {
        return val.order_id === data.order_id
      })
      if (initCheckoutOrderPValueOnFirstRender) {
        setCheckoutOrderPValue(true)
      } else {
        setCheckoutOrderPValue(false)
      }
    }
  }, [pools])

  useEffect(() => {
    if (orderPoolingData) {
      const initCheckoutOrderPValueOnFirstRender = orderPoolingData.some((val) => {
        return val.order_id === data.order_id
      })
      if (initCheckoutOrderPValueOnFirstRender) {
        setCheckoutOrderPValue(true)
      } else {
        setCheckoutOrderPValue(false)
      }
    }
  }, [orderPoolingData])

  //FUNCTION HANDLER

  const parseIndonesian = (data) => {
    const classNameStatus = data.toLowerCase().replaceAll(' ', '_')
    switch (classNameStatus) {
      case 'new_order':
        return 'Pesanan Baru'
      case 'picking':
        return 'Mengambil'
      case 'packing':
        return 'Mengemas'
      case 'waiting_driver':
        return 'Menunggu Driver'
      case 'waiting_confirmation':
        return isAllOrder ? 'Menunggu Respons Customer' : 'Menunggu Konfirmasi'
      default:
        return data
    }
  }

  const handleClickDetail = (data) => {
    dispatchAllorder(getDetailOrder(data?.order_id))
  }

  const renderHoverOrderPool = () => {
    if (isAllOrder) {
      if (!dashDown && !dashDown) {
        if (data.order_pool?.length > 0) {
          const renderTooltip = () => {
            return data.order_pool.map((d) => {
              return (
                <PoolContainer key={d.order_id}>
                  <Typography
                    variant="smallerBold"
                    style={{ fontWeight: 700, cursor: 'pointer' }}
                    onClick={() => handleClickDetail(d)}
                  >
                    {d.order_id}
                  </Typography>

                  <Devider />
                  <Typography variant="smallerReguler">
                    {parseIndonesian(d.order_status)}
                  </Typography>
                </PoolContainer>
              )
            })
          }

          return (
            <ExtraOrderContainer>
              <Typography variant="smallRegular">{`+${data.order_pool.length} dikirim bersamaan`}</Typography>
              <Tooltip tooltipsType="hover" content={renderTooltip()} white>
                <IcInfo src={information} />
              </Tooltip>
            </ExtraOrderContainer>
          )
        }
      }
    } else {
      if (data.order_pool && getParams('status') !== 'on delivery') {
        if (!dashDown) {
          const totalOrder = data.order_pool
            .map((d) => (d.order_status !== data.order_status ? d : null))
            .filter((d) => d)
          if (totalOrder.length > 0) {
            const renderTooltip = () => {
              return totalOrder.map((d) => {
                return (
                  <PoolContainer key={d.order_id}>
                    <Typography variant="smallerBold" style={{ fontWeight: 700 }}>
                      {d.order_id}
                    </Typography>
                    <SQuadrantLabel quadrant={d.order_location_quadrant}>
                      {renderSVGLoc(d.order_location_quadrant)}
                    </SQuadrantLabel>
                    <Devider />
                    <Typography variant="smallerReguler">
                      {parseIndonesian(d.order_status)}
                    </Typography>
                  </PoolContainer>
                )
              })
            }
            return (
              <ExtraOrderContainer>
                <Typography variant="smallRegular">{`+${totalOrder.length} dikirim bersamaan`}</Typography>
                <Tooltip tooltipsType="hover" content={renderTooltip()} white>
                  <IcInfo src={information} />
                </Tooltip>
              </ExtraOrderContainer>
            )
          }
        }
      }
    }
  }

  const copyToClipboard = (orderId) => {
    toastSuccess(`order id berhasil disalin ke clipboard`)
    navigator.clipboard.writeText(orderId)
  }

  const handleClickCheckBox = (e) => {
    setCheckoutOrderPValue((prev) => {
      return !prev
    })
    dispatch(setPools(data))
  }

  const renderCheckbox = () => {
    if (!isAllOrderSuper) {
      return (
        <CheckBoxContainer>
          {noCheckbox ? (
            <CheckboxNull />
          ) : (
            <Checkbox
              onChange={handleClickCheckBox}
              checked={checkoutOrderPValue}
              value={checkoutOrderPValue}
            />
          )}
        </CheckBoxContainer>
      )
    }

    return null
  }

  const validateRoleKitchen = () => {
    const roles = userData.authorities.map((d) => d.name)
    const authRoles = [AUTH_HO_ASTRO_KITCHEN_STAFF, AUTH_HO_ASTRO_KITCHEN_MANAGER, AUTH_ADMIN]

    return roles.some((role) => authRoles.includes(role))
  }

  const handleClickCloudKitchen = async () => {
    const { order_id, order_line_items, order_type } = data
    const dataCopy = { ...data }

    if (!validateRoleKitchen()) return toastFailed('Hanya untuk Astro Kitchen Staff dan Manager')

    if (!order_line_items && order_type !== 'KITCHEN') {
      try {
        const {
          data: { order_line_items: orderLineItems },
        } = await detailOrder(order_id)

        dataCopy.order_line_items = orderLineItems
        dispatchPacker(setIsPopupDetailShow(true))
        dispatchPacker(setSelectedPacker(dataCopy))
      } catch (err) {
        toastFailed('Data tidak ditemukan')
      }
    } else {
      dispatchPacker(setIsPopupDetailShow(true))
      dispatchPacker(setSelectedPacker(data))
    }
  }

  const renderSVGLoc = (quadrant) => {
    let quandrantCode = parseInt(quadrant, 10)
    return <TextBody color="white">{quandrantCode}</TextBody>
  }

  return (
    <Container>
      {renderCheckbox()}
      <Column>
        {dashUp ? <DashedLine /> : <Spacer />}

        <Row>
          <OrderIDContainer data={data} />
          <SCopyBox
            onClick={() => copyToClipboard(isAllOrder ? data?.order_invoice : data?.order_id)}
          >
            <CopySVG src={copy} />
          </SCopyBox>
          {data?.order_location_quadrant &&
          !['GRAB_MART', 'SUPER', 'ASTRO_BALANCE']?.includes(data?.order_type) ? (
            <SQuadrantLabel quadrant={data.order_location_quadrant}>
              {renderSVGLoc(data.order_location_quadrant)}
            </SQuadrantLabel>
          ) : (
            <SQuadrantLabel quadrant={0}>{renderSVGLoc(0)}</SQuadrantLabel>
          )}
        </Row>
        {externalDriverValidator(data?.order_third_party_logistic_term, data?.order_status) &&
          !data?.order_driver &&
          data?.order_type !== 'GRAB_MART' && (
            <NotifWarningSimiliarOrder>Layak untuk Kurir Instan</NotifWarningSimiliarOrder>
          )}
        {data?.order_third_party_logistic_used && data?.order_type !== 'GRAB_MART' && (
          <SSiapDikemasDiv>KURIR INSTAN</SSiapDikemasDiv>
        )}
        {data?.cloud_kitchen && (
          <SCloudKitchenInfoDiv onClick={handleClickCloudKitchen}>
            ASTRO KITCHEN
          </SCloudKitchenInfoDiv>
        )}
        {!data?.order_packer && data.order_status == 'Packing' && (
          <SSiapDikemasDiv>SIAP DIKEMAS</SSiapDikemasDiv>
        )}
        {renderHoverOrderPool()}
        {dashDown ? <DashedLine /> : <Spacer />}
      </Column>
    </Container>
  )
}

export default memo(OrderId)

const PoolContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-bottom: 4px;
`

const ExtraOrderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  position: absolute;
  bottom: 0px;
  width: 150px;
  left: -25px;
`
const IcInfo = styled.img`
  width: 18px;
  height: 18px;
  cursor: pointer;
`
const NotifWarningSimiliarOrder = styled.div`
  flex-direction: column;
  align-items: flex-start;
  background-color: #fff7ea;
  border-radius: 8px;
  display: flex;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 10px;
  border-left-style: solid;
  border-left-color: #ffc400;
  border-left-width: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
  color: 'black';
`

const DashedLine = styled.div`
  height: 20px;
  border-left: 2px dashed #bfc9d9;
  padding-right: 30px;
  align-self: center;
`

const Spacer = styled.div`
  height: 20px;
  min-height: 20px;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 170px;
  padding-bottom: 20px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`
const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
`

const SCopyBox = styled(Box)`
  width: 21px;
  height: 21px;
  cursor: pointer;
`

const CopySVG = styled.img`
  width: 21px;
  height: 21px;
  cursor: pointer;
`
const CheckBoxContainer = styled.div`
  margin-right: 12px;
  width: 21px;
  height: 21px;
`

const CheckboxNull = styled.div`
  width: 18px;
  height: 21px;
`

const SCloudKitchenInfoDiv = styled.div`
  background-color: #f97b06;
  padding: 4px;
  color: white;
  text-align: center;
  border-radius: 4px;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  width: fit-content;
  margin-top: 4px;
  cursor: pointer;
`
const SSiapDikemasDiv = styled.div`
  background-color: #e7f5e6;
  color: #42b139;
  padding: 4px;
  text-align: center;
  border-radius: 4px;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  width: fit-content;
  margin-right: auto;
  margin-top: 4px;
  margin-left: 8px;
`
const Devider = styled.div`
  border: 1px solid #d6dfeb;
  width: 1px;
  height: 25px;
`

const SQuadrantLabel = styled.div`
  font-weight: 700;
  color: #ffffff;
  font-size: 10px;
  background-color: white;
  ${(props) => {
    if (parseInt(props.quadrant, 10) === 1)
      return `
          background-color: #E8324A;
        `
    if (parseInt(props.quadrant, 10) === 2)
      return `
            background-color: #F99A00;
        `
    if (parseInt(props.quadrant, 10) === 3)
      return `
            background-color: #FFD300;
        `
    if (parseInt(props.quadrant, 10) === 4)
      return `
            background-color: #7ECC00;
        `
    if (parseInt(props.quadrant, 10) === 5)
      return `
            background-color: #00BECC;
        `
    if (parseInt(props.quadrant, 10) === 6)
      return `
            background-color: #00CC77;
        `
    if (parseInt(props.quadrant, 10) === 7)
      return `
            background-color: #147DF5;
        `
    if (parseInt(props.quadrant, 10) === 8)
      return `
            background-color: #580AFF;
        `
    if (parseInt(props.quadrant, 10) === 9)
      return `
            background-color: #A500E0;
        `
  }}
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  margin-right: 8px;
  height: 20px;
  padding: 4px;
`
