import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GetMasterShiftServicesInterface } from '../../services/masterShift'

export interface MasterShiftInitialStateInterface {
  isLoading: boolean
  dialogState: 'ADD' | 'EDIT' | 'CLOSE'
  hubLists: {
    location_id: number
    location_name: string
    location_type: string
  }[]
  selectedHub: MasterShiftInitialStateInterface['hubLists'][0] | ObjectNullType
  masterShiftList: GetMasterShiftServicesInterface['data']
  selectedShift: GetMasterShiftServicesInterface['data'][0] | ObjectNullType
}

const initialState: MasterShiftInitialStateInterface = {
  isLoading: false,
  dialogState: 'CLOSE',
  hubLists: [],
  selectedHub: {},
  masterShiftList: [],
  selectedShift: {},
}

export const sliceName = 'masterShift'

const appSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<MasterShiftInitialStateInterface['isLoading']>) => {
      state.isLoading = action.payload
    },
    setHubList: (state, action: PayloadAction<MasterShiftInitialStateInterface['hubLists']>) => {
      state.hubLists = action.payload
    },
    setSelectedHub: (
      state,
      action: PayloadAction<MasterShiftInitialStateInterface['selectedHub']>,
    ) => {
      state.selectedHub = action.payload
    },
    setMasterShiftList: (
      state,
      action: PayloadAction<MasterShiftInitialStateInterface['masterShiftList']>,
    ) => {
      state.masterShiftList = action.payload
    },
    setSelectedShift: (
      state,
      action: PayloadAction<MasterShiftInitialStateInterface['selectedShift']>,
    ) => {
      state.selectedShift = action.payload
    },
    setDialogState: (
      state,
      action: PayloadAction<MasterShiftInitialStateInterface['dialogState']>,
    ) => {
      state.dialogState = action.payload
    },
  },
})

export const {
  setHubList,
  setLoading,
  setSelectedHub,
  setMasterShiftList,
  setDialogState,
  setSelectedShift,
} = appSlice.actions

export default appSlice.reducer
