import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getFreshPurchaseOrderList,
  getWarehouseList,
  postUpdateStatusFpo,
  GetFpoListRequestType,
} from 'utils/apiList/freshPurchaseOrder'
import { callErrorMsg } from 'helpers/errorMsg'

import type {
  GetWarehouseListResponseType,
  PostUpdateStatusFpoResponseType,
  PostUpdateStatusFpoRequestType,
  ActionNameType,
} from 'utils/apiList/freshPurchaseOrder'
import { getCleanQueryObj, queryParamsURL } from 'utils/queryParamsURL'
import type { AxiosResponse } from 'axios'
import { toastSuccess } from 'utils/toast'
import { getValueForActionName, getMessageByActionName } from 'utils/reducerHandler'
import {
  AUTH_ADMIN,
  AUTH_QA_FRESH_INBOUND,
  AUTH_SOURCING,
  AUTH_SOURCING_MANAGER,
} from 'middleware/privateRoute'

export const SLICE_NAME = 'freshPurchaseOrder'

const allowedRolesForConfirm = [AUTH_ADMIN, AUTH_SOURCING, AUTH_SOURCING_MANAGER]
const allowedRolesForCancel = [AUTH_ADMIN, AUTH_SOURCING, AUTH_SOURCING_MANAGER]
const allowedRolesForInbound = [AUTH_ADMIN, AUTH_QA_FRESH_INBOUND]
const allowedRolesForPricing = [AUTH_ADMIN, AUTH_SOURCING, AUTH_SOURCING_MANAGER]
const allowedRolesForReturnToDraft = [AUTH_ADMIN, AUTH_SOURCING, AUTH_SOURCING_MANAGER]

type AuthoritiesType = {
  name: string
}

export const shouldShowMenuItem = (actionName: ActionNameType, authorities: AuthoritiesType[]) =>
  shouldShowBtnConfirm(actionName, authorities) ||
  shouldShowBtnCancel(actionName, authorities) ||
  shouldShowBtnInbound(actionName, authorities) ||
  shouldShowBtnReturnToDraft(actionName, authorities) ||
  shouldShowBtnPricing(actionName, authorities)

const shouldShowBtnConfirm = (actionName: ActionNameType, authorities: AuthoritiesType[]) =>
  actionName === 'CONFIRM' &&
  authorities.some((roleData) => allowedRolesForConfirm.includes(roleData.name))
const shouldShowBtnCancel = (actionName: ActionNameType, authorities: AuthoritiesType[]) =>
  actionName === 'CANCEL' &&
  authorities.some((roleData) => allowedRolesForCancel.includes(roleData.name))
const shouldShowBtnInbound = (actionName: ActionNameType, authorities: AuthoritiesType[]) =>
  actionName === 'INBOUND' &&
  authorities.some((roleData) => allowedRolesForInbound.includes(roleData.name))
const shouldShowBtnReturnToDraft = (actionName: ActionNameType, authorities: AuthoritiesType[]) =>
  actionName === 'RETURN TO DRAFT' &&
  authorities.some((roleData) => allowedRolesForReturnToDraft.includes(roleData.name))
const shouldShowBtnPricing = (actionName: ActionNameType, authorities: AuthoritiesType[]) =>
  actionName === 'PRICING' &&
  authorities.some((roleData) => allowedRolesForPricing.includes(roleData.name))

export const fetchFreshPurchaseOrder = createAsyncThunk(
  `${SLICE_NAME}/fetchFreshPurchaseOrder`,
  async (_, { getState, rejectWithValue }) => {
    try {
      const rootState = getState() as StoreStateType
      const { query } = rootState.freshPurchaseOrder
      const {
        userData: { authorities },
      } = rootState.auth

      const cleanQueryObj = getCleanQueryObj(query)
      const {
        data: { data },
      } = await getFreshPurchaseOrderList(cleanQueryObj as GetFpoListRequestType)

      queryParamsURL.set(query)

      return data.map((itemData) => ({
        ...itemData,
        actionList: itemData.action.map((actionName) => ({
          name: actionName,
          value: getValueForActionName(actionName),
          message: getMessageByActionName(actionName),
          isShow: shouldShowMenuItem(actionName, authorities),
        })),
      }))
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchWarehouseList = createAsyncThunk<GetWarehouseListResponseType[], void>(
  `${SLICE_NAME}/fetchWarehouseList`,
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<GetWarehouseListResponseType[]> = await getWarehouseList()
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateStatusOfFpo = createAsyncThunk<PostUpdateStatusFpoResponseType, void>(
  `${SLICE_NAME}/updateStatusOfFpo`,
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      const rootState = getState() as StoreStateType
      const {
        modal: { idToCancelOrConfirm, statusToBe },
      } = rootState.freshPurchaseOrder
      const response: AxiosResponse<PostUpdateStatusFpoResponseType> = await postUpdateStatusFpo(
        idToCancelOrConfirm,
        {
          status: statusToBe as PostUpdateStatusFpoRequestType['status'],
        },
      )

      dispatch(fetchFreshPurchaseOrder())
      toastSuccess(response.data.message)
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
