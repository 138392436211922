import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import {
  getExperiment,
  getNonLoginExperiment,
  ExperimentType,
  type GetExperimentResponseType,
  type GetExperimentParamReqType,
} from 'utils/apiList/abtesting'
import { callErrorMsg } from 'helpers/errorMsg'
import { dateFormat, stringToDateObject } from 'utils/helpers/date'
import { ExperimentTypesType } from 'features/ABAdmin/@types/ExperimentAddEdit'

const SLICE_NAME = 'abAdminList'

export type ExperimentsListType = GetExperimentResponseType['data']['experiments']

export type ABAdminListStateType = {
  isLoading: boolean
  query: {
    name: string
    pageIndex: number
    pageSize: number
  }
  experimentsList: {
    target_channel: string
    status: ExperimentType['status']
    end_date: string
    updated_by: string
    type: string
    id: number
    updated_at: string
    target_user: number
    name: string
    created_at: string
    is_deleted: boolean
    total_user: number
    start_date: string
    created_by: string
    uniqueId: string
  }[]
  experimentType: Nullable<ExperimentTypesType>
}

export const initialState: ABAdminListStateType = {
  isLoading: false,
  experimentsList: [],
  query: {
    name: '',
    pageIndex: 0,
    pageSize: 20,
  },
  experimentType: null,
}

const abAdminList = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setQuery: (
      state,
      action: PayloadAction<{
        key: keyof ABAdminListStateType['query']
        value: ABAdminListStateType['query'][keyof ABAdminListStateType['query']]
      }>,
    ) => {
      const { key, value } = action.payload

      state.query = {
        ...state.query,
        [key]: value,
      }
    },
    setExperimentType: (state, { payload }: PayloadAction<ExperimentTypesType>) => {
      state.experimentType = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExperimentList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchExperimentList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchExperimentList.fulfilled, (state, { payload }) => {
        state.isLoading = false
        state.experimentsList = payload.map((el) => ({
          ...el,
          end_date: dateFormat({
            date: stringToDateObject(el.end_date, 'YYYY-MM-DD hh:mm::ss Z'),
            format: 'D MMM YYYY',
          }),
          start_date: dateFormat({
            date: stringToDateObject(el.start_date, 'YYYY-MM-DD hh:mm::ss Z'),
            format: 'D MMM YYYY',
          }),
          uniqueId: window.crypto.randomUUID(),
        }))
      })
  },
})

export const { reset, setQuery, setExperimentType } = abAdminList.actions
export default abAdminList.reducer

export const fetchExperimentList = createAsyncThunk(
  `${SLICE_NAME}/fetchExperimentsList`,
  async (experimentType: ExperimentTypesType, { rejectWithValue, getState }) => {
    const {
      abAdminList: { query },
    } = getState() as StoreStateType
    try {
      const params: GetExperimentParamReqType = {
        page_index: query.pageIndex,
        page_size: query.pageSize,
        direction: 'desc',
      }

      if (experimentType === 'NON_LOGIN') {
        const { data } = await getNonLoginExperiment(params)
        return data?.data?.experiments || []
      }

      const { data } = await getExperiment(params)
      return data?.data?.experiments || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan list eksperimen')
      return rejectWithValue(err)
    }
  },
)
