import { memo, useState, useEffect, useContext, useMemo } from 'react'
import { Pill } from '@astro-ui/components'
import { useDispatch, shallowEqual, useSelector } from 'react-redux'
import { transformObjtoQuery } from 'helpers/index'
import styled from 'styled-components'
import { TextBody, Toggle, Input, InputDropDown, Button } from 'components'
import { numberOnly, formatMoneyRMG } from 'helpers'
import { useHistory } from 'react-router-dom'
import { productHubStockDetail, productHubProductDetail } from 'config/routes'
import store from 'storeContext'
import { selectProductData } from 'storeContext/actions/hubDetail'
import { getDetailProductHubAndOpenCloudKitchenSchedule } from 'redux/cloudKitchen'
import useAllowedAccessFor from 'customHooks/useAllowedAccessFor'
import allowedRoleList from 'middleware/allowedRoleOnFeature/productAndStock/hub'
import { styleBusinessTagging } from 'features/Product/constant/product'

function RowHub({
  data,
  handleChangeActive,
  handleClickSave,
  allowedForEditPrice,
  allowedForEditStock,
  allowedForToggleStatus,
}) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { dispatch: dispatchHubDetail } = useContext(store.HubDetailContext)
  const [formValue, setFormvalue] = useState({})
  const isDisableEditStock = useMemo(
    () => ['VIRTUAL', 'ASTRO_KITCHEN'].includes(data.bomCategory?.toUpperCase()),
    [data],
  )

  const authorities = useSelector(({ auth: { userData } }) => userData.authorities, shallowEqual)

  const allowedSetCloudKitchenSchedule = useAllowedAccessFor(
    authorities,
    allowedRoleList.setCloudKitchenSchedule,
  )

  const listOption = [
    {
      name: 'Lihat Detail',
      id: 'editSo',
      action: () => {
        dispatchHubDetail(selectProductData(data))
        const object = {
          product_id: data.productId,
          product_name: data.productName,
          location_id: data.locationId,
          location_name: data.locationName,
        }
        const query = transformObjtoQuery(object)
        history.push(`${productHubStockDetail}${query}`)
      },
    },
    ...(allowedSetCloudKitchenSchedule
      ? [
          {
            name: 'Jadwal CloudKitchen',
            id: 'cloudKitchen',
            action: () => {
              dispatch(
                getDetailProductHubAndOpenCloudKitchenSchedule({
                  productId: data.productId,
                  locationId: data.locationId,
                  inventoryStatus: data.inventoryActive,
                }),
              )
            },
          },
        ]
      : []),
  ]

  useEffect(() => {
    setFormvalue(data)
  }, [data])

  const handleChangeForm = (e) => {
    const {
      target: { value, name },
    } = e
    if (name === 'rackName') {
      setFormvalue({ ...formValue, [name]: value })
    } else {
      setFormvalue({ ...formValue, [name]: numberOnly(value) })
    }
  }

  const handleClickProductDetail = () => {
    dispatchHubDetail(selectProductData(data))
    const object = {
      product_id: data.productId,
      product_name: data.productName,
      location_id: data.locationId,
      location_name: data.locationName,
      sku: data.productSkuNumber,
      location_type: data.locationType,
    }

    const query = transformObjtoQuery(object)
    history.push(`${productHubProductDetail}${query}`)
  }

  return (
    <Tr>
      <Td ratio="2">
        <TextBody weight="light" color="textSoft">
          {data.locationName}
        </TextBody>
      </Td>
      <Td ratio="4">
        <ProductWrapper>
          <ImgCategory size="32px" alt="box" src={data.productImage} />
          <Wrapper>
            <TextBody weight="light" color="textSoft">
              {data.productName}
            </TextBody>
            {data.cloudKitchen && (
              <Wrapper style={{ flexDirection: 'row' }}>
                <CloudKitchenTag>
                  <TextBody weight="light" color="white">
                    CLOUD KITCHEN
                  </TextBody>
                </CloudKitchenTag>
              </Wrapper>
            )}
          </Wrapper>
        </ProductWrapper>
      </Td>
      <Td ratio="2">
        <TextBody weight="light" color="textSoft">
          {data.productSkuNumber}
        </TextBody>
        {data.businessTaggings.length > 0 && (
          <>
            {data.businessTaggings.map((taggingItem) => (
              <Pill
                key={taggingItem.key}
                content={taggingItem.value}
                sx={{ marginTop: '5px', ...styleBusinessTagging[taggingItem.key] }}
              />
            ))}
          </>
        )}
      </Td>
      <Td ratio="2" pr="20px">
        <Input
          disabled={!allowedForEditPrice}
          customIcon="Rp"
          bgIcon="body"
          iconPos="left"
          value={formatMoneyRMG(formValue.inventoryPrice, '')}
          name="inventoryPrice"
          onChange={handleChangeForm}
        />
      </Td>
      <Td ratio="2" pr="20px">
        {isDisableEditStock ? (
          <TextBody weight="light">{formValue.inventoryStock}</TextBody>
        ) : (
          <TextBody
            onClick={() => {
              allowedForEditStock && handleClickProductDetail()
            }}
            weight="light"
            className={allowedForEditStock && 'pointer'}
            color={allowedForEditStock && 'main'}
          >
            {formValue.inventoryStock}
          </TextBody>
        )}
      </Td>
      <Td ratio="2" pr="20px">
        <TextBody weight="light">{formValue.inventoryTotalStock}</TextBody>
      </Td>
      <Td ratio="1" pr="20px">
        <TextBody weight="light" color="textSoft">
          {data?.inventoryDiscountStock}
        </TextBody>
      </Td>
      <Td ratio="2" pr="20px">
        <TextBody weight="light" color="textSoft">
          {data.inventoryReversedStock}
        </TextBody>
      </Td>
      <Td ratio="1">
        <WrapperActions>
          <Toggle
            active={data.inventoryActive}
            onClick={() => handleChangeActive(data)}
            disabled={!allowedForToggleStatus}
          />
        </WrapperActions>
      </Td>
      <Td ratio="1">
        <WrapperActions>
          <InputDropDown
            disabled
            value="Atur"
            listOptions={listOption}
            noSetOption
            handleClickOption={(e) => e.action()}
          />
          {(data.rackName !== formValue.rackName ||
            data.inventoryStock.toString() !== formValue.inventoryStock.toString() ||
            data.inventoryPrice !== formValue.inventoryPrice) && (
            <Button size="small" onClick={() => handleClickSave(formValue)}>
              Simpan
            </Button>
          )}
        </WrapperActions>
      </Td>
    </Tr>
  )
}

export default memo(RowHub)

const Tr = styled.tr`
  display: flex;
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
  .input-container {
    width: calc(100% - 20px);
  }
`
const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`
const ImgCategory = styled.img`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  border-radius: 5px;
  background-color: ${({ theme: { colors } }) => colors.mainFaded};
  border: none;
`

const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding-right: 20px;
`

const WrapperActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const CloudKitchenTag = styled.div`
  background-color: ${({ theme: { colors } }) => colors.orange2};
  padding: 4px;
  border-radius: 4px;
`
