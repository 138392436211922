import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { DATE_FORMAT } from 'constant/date'
import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'
import dayjs from 'dayjs'
import {
  GetReplenishmentScheduleListRequestType,
  PostReplenishmentScheduleRequestType,
  PostReplenishmentScheduleResponseType,
  PutReplenishmentScheduleResponseType,
  deleteReplenishmentScheduleAPI,
  getCategoriesAPI,
  getReplenishmentScheduleDetailAPI,
  getReplenishmentScheduleListAPI,
  getWarehouseLocationsAPI,
  postReplenishmentScheduleAPI,
  putReplenishmentScheduleAPI,
} from 'features/SupplyOrder/services/replenishment'
import { callErrorMsg } from 'helpers/errorMsg'
import { serializeQueryToURL } from 'utils/queryParamsURL'
import { toastSuccess } from 'utils/toast'

export const SLICE_NAME = 'REPLENISHMENT_SCHEDULE'

export const fetchGetReplenishmentSchedule = createAsyncThunk(
  `${SLICE_NAME}/fetchGetReplenishmentSchedule`,
  async (params: Parameters<typeof getReplenishmentScheduleListAPI>[0], { rejectWithValue }) => {
    try {
      const response = await getReplenishmentScheduleListAPI(params)

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchGetReplenishmentScheduleConstants = createAsyncThunk(
  `${SLICE_NAME}/fetchGetReplenishmentScheduleConstants`,
  async (_, { rejectWithValue }) => {
    try {
      const locations = await getWarehouseLocationsAPI()
      const categories = await getCategoriesAPI()

      const scheduleTypeOptions = [
        {
          name: 'Satu Kali',
          value: 'once',
        },
        {
          name: 'Berulang',
          value: 'recurring',
        },
      ]

      const scheduleOptions = [
        {
          name: 'Saat Ini',
          value: 'now',
        },
        {
          name: 'Pilih Jadwal',
          value: 'selectSchedule',
        },
      ]

      const dayOptions = [
        {
          name: 'Senin',
          value: 'Monday',
        },
        {
          name: 'Selasa',
          value: 'Tuesday',
        },
        {
          name: 'Rabu',
          value: 'Wednesday',
        },
        {
          name: 'Kamis',
          value: 'Thursday',
        },
        {
          name: 'Jumat',
          value: 'Friday',
        },
        {
          name: 'Sabtu',
          value: 'Saturday',
        },
        {
          name: 'Minggu',
          value: 'Sunday',
        },
      ]

      const skuMaxOptions = ['5', '10', '20', '50', '100']

      const timeOptions = Array.from({ length: 24 }, (__, i) => i).reduce(
        (time, hour, index) => {
          time.push({
            no: index + 1,
            name: dayjs().set('hour', hour).set('minute', 0).format('HH:mm'),
            disabled: false,
          })
          return time
        },
        [] as {
          no: number
          name: string
          disabled: boolean
        }[],
      )

      return {
        warehouseList: locations.data,
        categories: categories.data,
        dayOptions,
        scheduleOptions,
        scheduleTypeOptions,
        timeOptions,
        skuMaxOptions,
      }
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const setReplenishmentScheduleFilter = createAction<GetReplenishmentScheduleListRequestType>(
  `${SLICE_NAME}/setFilter`,
)

export const actGetReplenishmentScheduleFilter =
  (params?: GetReplenishmentScheduleListRequestType) => (dispatch: StoreDispatchType) => {
    const qs = serializeQueryToURL({ pageIndex: 1, ...params })
    dispatch(setReplenishmentScheduleFilter(qs))
    dispatch(fetchGetReplenishmentSchedule(qs))
  }

export type FormScheduleDetailType = {
  id?: number
  daylist: {
    name: string
    value: string
  }[]
  timelist: {
    name: string
    disabled: boolean
  }[]
  categoryIdList: {
    category_id: number
    category_name: string
  }[]
  scheduleType: {
    name: string
    value: string
  } | null
  location: {
    location_id: number
    location_name: string
  } | null
  maxSku: number | ''
  startDate: string
  endDate: string
  unlimited: boolean | null
  scheduleOption: 'selectSchedule' | 'now' | ''
}

export const fetchGetReplenishmentScheduleDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchGetReplenishmentScheduleDetail`,
  async (id: number, { rejectWithValue, getState }) => {
    try {
      const response = await getReplenishmentScheduleDetailAPI({ id })

      const {
        replenishmentSchedule: {
          constants: { categories, dayOptions, timeOptions, scheduleTypeOptions, warehouseList },
        },
      } = getState() as StoreStateType
      const { data } = response.data

      const scheduleType =
        scheduleTypeOptions.find((item) => item.value === data.scheduleType.toLocaleLowerCase()) ||
        null
      const categoryIdList = categories.filter((item) => data.categoryId.includes(item.category_id))
      const daylist = dayOptions.filter((item) => data.day.includes(item.value))
      const timelist = timeOptions.filter((item) => data.time.includes(item.name))
      const location = warehouseList.find((item) => item.location_id === data.locationId) || null

      const result: FormScheduleDetailType = {
        id: data.id,
        scheduleType,
        categoryIdList,
        daylist,
        timelist,
        location,
        unlimited: data.infinite,
        maxSku: data.maxSku,
        scheduleOption: data.isNow ? 'now' : 'selectSchedule',
        startDate: data.startDate
          ? dayjs(data.startDate, 'DD-MMMM-YYYY').format(DATE_FORMAT).toString()
          : '',
        endDate: data.endDate
          ? dayjs(data.endDate, 'DD-MMMM-YYYY').format(DATE_FORMAT).toString()
          : '',
      }

      return result
    } catch (err) {
      callErrorMsg(err)

      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchPostReplenishmentSchedule = createAsyncThunk(
  `${SLICE_NAME}/fetchPostReplenishmentSchedule`,
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      const {
        replenishmentSchedule: {
          popupAddEditSchedule: {
            form: {
              id,
              daylist,
              timelist,
              categoryIdList,
              scheduleType,
              startDate,
              endDate,
              location,
              maxSku,
              unlimited,
              scheduleOption,
            },
          },
        },
        auth: {
          userData: { email },
        },
      } = getState() as StoreStateType

      const payload: PostReplenishmentScheduleRequestType = {
        dayList: daylist.map((item) => item.value),
        timeList: timelist.map((item) => item.name),
        categoryIdList: categoryIdList.map((item) => item.category_id),
        scheduleType: scheduleType?.value,
        startDate: scheduleOption === 'now' ? '' : startDate,
        endDate,
        createdBy: email,
        locationId: location?.location_id,
        maxSku: +maxSku,
        unlimited: !!unlimited,
        isNow: scheduleOption === 'now',
      }

      const response: AxiosResponse<
        PutReplenishmentScheduleResponseType | PostReplenishmentScheduleResponseType
      > = id
        ? await putReplenishmentScheduleAPI({ id, payload })
        : await postReplenishmentScheduleAPI(payload)

      dispatch(fetchGetReplenishmentSchedule())
      toastSuccess(response.data.message)

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchDeleteReplenishmentSchedule = createAsyncThunk(
  `${SLICE_NAME}/fetchDeleteReplenishmentSchedule`,
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await deleteReplenishmentScheduleAPI({ id })

      toastSuccess(response.data.message)
      dispatch(fetchGetReplenishmentSchedule())

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
