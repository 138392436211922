import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getDiscoveryLabel,
  getMasterLabelType,
  getMasterLabelColor,
  getDiscoveryLabelById,
  postDiscoveryLabel,
  putDiscoveryLabel,
  deleteDiscoveryLabel,
  type MasterLabelVariantType,
  type DiscoveryLabelColorType,
  type GetDiscoveryLabelReqParamsType,
  type DiscoveryLabelType,
  type PostPutDiscoveryLabelPayloadType,
} from 'utils/apiList/dicoveryLabel'

import {
  getImageRules,
  postImage,
  type ImageRulesType,
  type ImageVariantIdType,
  type PostImageRequestPayload,
} from 'utils/apiList/images'
import { toastSuccess } from 'utils/toast'

export type {
  DiscoveryLabelType,
  GetDiscoveryLabelReqParamsType,
  DiscoveryLabelColorType,
  ImageRulesType,
  PostPutDiscoveryLabelPayloadType,
}

const SLICE_NAME = 'productLabel'

export const uploadProductLabelServiceCode = 'DISCOVERY_UPLOAD_PRODUCT_LABEL'

export interface ProductLabelStateType {
  isLoading: boolean
  filter: {
    name: string
    pageIndex: number
    pageSize: number
    numberOfElements: number
  }
  needToReload: number
  dataList: DiscoveryLabelType[]
  currentEditingData: DiscoveryLabelType | null
  modalData: {
    type: 'delete-confirm' | 'add-edit'
    data?: DiscoveryLabelType
  } | null
  addEditForm: {
    name: string
    labelType: MasterLabelVariantType | null
    labelColor: DiscoveryLabelColorType | null
    logoUrl: string
    filePath: string
  }
  constant: {
    typeList: MasterLabelVariantType[]
    colorList: DiscoveryLabelColorType[]
    imageRules: ImageRulesType | null
  }
}

export const initialState: ProductLabelStateType = {
  isLoading: false,
  filter: {
    name: '',
    pageIndex: 0,
    pageSize: 10,
    numberOfElements: 0,
  },
  needToReload: 0,
  dataList: [],
  currentEditingData: null,
  modalData: null,
  addEditForm: {
    name: '',
    labelType: null,
    labelColor: null,
    logoUrl: '',
    filePath: '',
  },
  constant: {
    typeList: [],
    colorList: [],
    imageRules: null,
  },
}

export type MoveVoucherPriorityPayloadType = {
  from: number
  to: number
}

export type AddEditFormActionPayloadType = {
  key: keyof ProductLabelStateType['addEditForm']
  value: unknown
}

const productLabelSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setFilter: (
      state,
      action: PayloadAction<GetDiscoveryLabelReqParamsType & { needToReload?: boolean }>,
    ) => {
      const { needToReload, ...rest } = action.payload

      state.filter = {
        ...state.filter,
        ...rest,
      }

      if (needToReload === undefined || needToReload) {
        state.needToReload += 1
      }
    },
    resetFilter: (state) => {
      state.filter = initialState.filter
    },
    setAddEditForm: (state, action: PayloadAction<AddEditFormActionPayloadType>) => {
      const { key, value } = action.payload

      state.addEditForm = {
        ...state.addEditForm,
        [key]: value,
      }
    },
    resetAddEditForm: (state) => {
      state.addEditForm = initialState.addEditForm
    },
    setModalData: (state, action: PayloadAction<ProductLabelStateType['modalData']>) => {
      state.modalData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDiscoveryLabel.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchDiscoveryLabel.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchDiscoveryLabel.fulfilled, (state, action) => {
        state.isLoading = false
        state.dataList = action.payload?.content || []

        state.filter = {
          ...state.filter,
          pageSize: action.payload?.size,
          pageIndex: action.payload?.number,
          numberOfElements: action.payload?.numberOfElements,
        }
      })

      .addCase(fetchDiscoveryLabelType.fulfilled, (state, action) => {
        state.constant = {
          ...state.constant,
          typeList: action.payload,
        }
      })
      .addCase(fetchDiscoveryLabelColor.fulfilled, (state, action) => {
        state.constant = {
          ...state.constant,
          colorList: action.payload,
        }
      })
      .addCase(fetchDiscoveryLabelImageRules.fulfilled, (state, action) => {
        state.constant = {
          ...state.constant,
          imageRules: action.payload,
        }
      })
      .addCase(uploadImage.pending, (state) => {
        state.isLoading = true
      })
      .addCase(uploadImage.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(uploadImage.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchDiscoveryLabelById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchDiscoveryLabelById.fulfilled, (state, { payload }) => {
        state.isLoading = false

        state.currentEditingData = payload

        state.addEditForm = {
          ...state.addEditForm,
          name: payload.name,
          labelType: payload.masterLabelTypeId
            ? {
                id: payload.masterLabelTypeId,
                name: payload.masterLabelTypeName,
              }
            : null,
          labelColor: payload.masterLabelColorId
            ? {
                id: payload.masterLabelColorId,
                name: payload.masterLabelColorName,
                description: payload.masterLabelColorDescription,
                hexacode: payload.masterLabelColorHexabase,
                hexafont: payload.masterLabelColorHexabase,
              }
            : null,
          logoUrl: payload.logoUrl,
          filePath: payload.filePath,
        }
      })
      .addCase(fetchDiscoveryLabelById.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(createDiscoveryLabel.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createDiscoveryLabel.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(createDiscoveryLabel.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(removeDiscoveryLabel.pending, (state) => {
        state.isLoading = true
      })
      .addCase(removeDiscoveryLabel.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(removeDiscoveryLabel.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const { reset, setFilter, setAddEditForm, resetFilter, resetAddEditForm, setModalData } =
  productLabelSlice.actions
export default productLabelSlice.reducer

export const fetchDiscoveryLabel = createAsyncThunk(
  `${SLICE_NAME}/fetchDiscoveryLabel`,
  async (params: GetDiscoveryLabelReqParamsType, { rejectWithValue, getState }) => {
    const {
      productLabel: { filter },
    } = getState() as StoreStateType

    const newParams = {
      ...filter,
      ...params,
    }

    try {
      const { data } = await getDiscoveryLabel(newParams)

      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar label produk')
      return rejectWithValue(err)
    }
  },
)

export const fetchDiscoveryLabelType = createAsyncThunk(
  `${SLICE_NAME}/fetchDiscoveryLabelType`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getMasterLabelType()

      return data || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar label type produk')
      return rejectWithValue(err)
    }
  },
)

export const fetchDiscoveryLabelColor = createAsyncThunk(
  `${SLICE_NAME}/fetchDiscoveryLabelColor`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getMasterLabelColor()

      return data || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar label color produk')
      return rejectWithValue(err)
    }
  },
)

export const fetchDiscoveryLabelImageRules = createAsyncThunk(
  `${SLICE_NAME}/fetchDiscoveryLabelImageRules`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getImageRules('product-label')

      return data?.data || []
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan label image rule')
      return rejectWithValue(err)
    }
  },
)

export const uploadImage = createAsyncThunk(
  `${SLICE_NAME}/uploadImage`,
  async (
    payload: {
      typeId: ImageVariantIdType
      data: PostImageRequestPayload
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await postImage(payload.typeId, payload.data)

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchDiscoveryLabelById = createAsyncThunk(
  `${SLICE_NAME}/fetchDiscoveryLabelById`,
  async (payload: number, { rejectWithValue }) => {
    try {
      const response = await getDiscoveryLabelById(payload)

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const createDiscoveryLabel = createAsyncThunk(
  `${SLICE_NAME}/createDiscoveryLabel`,
  async (payload: PostPutDiscoveryLabelPayloadType, { rejectWithValue }) => {
    try {
      await postDiscoveryLabel(payload)

      toastSuccess('Membuat label berhasil')
      return null
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateDiscoveryLabel = createAsyncThunk(
  `${SLICE_NAME}/updateDiscoveryLabel`,
  async (
    payload: { id: number; payload: PostPutDiscoveryLabelPayloadType },
    { rejectWithValue },
  ) => {
    try {
      await putDiscoveryLabel(payload.id, payload.payload)

      toastSuccess('Update label berhasil')
      return null
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const removeDiscoveryLabel = createAsyncThunk(
  `${SLICE_NAME}/removeDiscoveryLabel`,
  async (payload: number, { rejectWithValue }) => {
    try {
      await deleteDiscoveryLabel(payload)

      toastSuccess('Menghapus label berhasil')
      return null
    } catch (err) {
      callErrorMsg(err, '', { autoClose: false })
      return rejectWithValue(err)
    }
  },
)
