import { memo } from "react";
import styled from "styled-components";

import { TextBody } from "components";

function FooterTTD({ data }) {
  return (
    <Container>
      <tbody>
        <TR>
          <TD>
            <TableWrapper>
              <Header>
                <TextWrapper>
                  <TextBody size="header">TTD PIC</TextBody>
                </TextWrapper>
              </Header>
              <BodyWrapper>
                <Body width="33%" first>
                  <Description>Dibuat Oleh,</Description>
                  <Signature></Signature>
                </Body>
                <Body>
                  <Description>Diterima Oleh,</Description>
                  <Signature></Signature>
                </Body>
                <Body>
                  <Description>Diperiksa Oleh,</Description>
                  <Signature></Signature>
                </Body>
              </BodyWrapper>
            </TableWrapper>
          </TD>
        </TR>
      </tbody>
    </Container>
  );
}

export default memo(FooterTTD);

const Container = styled.table`
  margin-top: 42px;
`;

const TR = styled.tr``;
const TD = styled.td`
  width: 100vw;
  display: flex;
`;

const TableWrapper = styled.div`
  width: 70%;
  margin-left: 30px;
  border: 1px solid #d6dfeb;
  box-sizing: border-box;
  border-radius: 8px;
`;
const Header = styled.div`
  padding: 12px 24px;
  border-bottom: 1px solid #d6dfeb;
`;
const TextWrapper = styled.div``;

const BodyWrapper = styled.div`
  display: flex;
`;
const Body = styled.div`
  width: ${({ width }) => (width ? width : `50%`)};
  height: 140px;
  display: flex;
  flex-direction: column;
  ${({ first }) => !first && `border-left: 1px solid #d6dfeb;`}
`;

const Description = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 14px;
`;
const Signature = styled.div`
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 14px;
`;
