export const SET_IS_LOADING_PACKER = 'SET_IS_LOADING_PACKER/packer-page'
export const SET_DATA_PAGINATION = 'SET_DATA_PAGINATION/packer-page'
export const SET_DATA_PACKERS = 'SET_DATA_PACKERS/packer-page'
export const SET_DATA_LOCATIONS = 'SET_DATA_LOCATIONS/packer-page'
export const SET_IS_POPUP_DETAIL_SHOW = 'SET_IS_POPUP_DETAIL_SHOW/packer-page'
export const SET_SELECTED_PACKER = 'SET_SELECTED_PACKER/packer-page'
export const SET_IS_POPUP_CHECK_SHOW = 'SET_IS_POPUP_CHECK_SHOW/packer-page'
export const SET_LAST_PARAMS = 'SET_LAST_PARAMS/packer-page'
export const SET_LOCATIONS = 'SET_LOCATIONS/packer-page'
export const SET_SELECTED_LOCATION = 'SET_SELECTED_LOCATION/packer-page'
export const SET_IS_POPUP_CALL_DRIVER_SHOW = 'SET_IS_POPUP_CALL_DRIVER_SHOW/packer-page'
export const SET_IS_POPUP_CANCEL_SHOW = 'SET_IS_POPUP_CANCEL_SHOW/packer-page'
export const SET_DRIVERS = 'SET_DRIVERS/packer-page'
export const SET_IS_POPUP_CHANGE_DRIVER_SHOW = 'SET_IS_POPUP_CHANGE_DRIVER_SHOW/packer-page'
export const SET_IS_POPUP_CHECK_RESI_SHOW = 'SET_IS_POPUP_CHECK_RESI_SHOW/packer-page'
export const SET_IS_POPUP_PRINT_LIST_SHOW = 'SET_IS_POPUP_PRINT_LIST_SHOW/packer-page'
export const SET_IS_FROM_QTY = 'SET_IS_FROM_QTY/packer-page'
export const SET_IS_ORDER_POOLING_DATA = 'SET_IS_ORDER_POOLING_DATA'
export const SET_IS_POPUP_CALL_DRIVER_V2_SHOW = 'SET_IS_POPUP_CALL_DRIVER_V2_SHOW/packer-page'
export const SET_IS_POPUP_CALL_EXTERNAL_DRIVER_V2_SHOW =
  'SET_IS_POPUP_CALL_EXTERNAL_DRIVER_V2_SHOW/packer-page'
export const SET_IS_POPUP_TYPE_DRIVER_POPUP_V2 = 'SET_IS_POPUP_TYPE_DRIVER_POPUP_V2/packer-page'
export const SET_IS_POPUP_INPUT_AUTH_SHOW = 'SET_IS_POPUP_INPUT_AUTH_SHOW/packer-page'
export const SET_IS_SKU_MANUAL_INPUT_NOTES = 'SET_IS_SKU_MANUAL_INPUT_NOTES/packer-page'
export const SET_IS_SKU_MANUAL_INPUT_AUTH = 'SET_IS_SKU_MANUAL_INPUT_AUTH/packer-page'
export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT/packer-page'
export const SET_INTERSECT_HUB = 'SET_INTERSECT_HUB'
export const SET_IS_POPUP_CANCEL_AUTO_ASSIGN_SHOW =
  'SET_IS_POPUP_CANCEL_AUTO_ASSIGN_SHOW/packer-page'
