import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import { getFraudReasonsList } from 'features/Fraud/services/userFraudStatus'
import { SLICE_NAME_BLACKLIST_AND_WHITELIST, setFraudReasonList } from './slice'

export const fetchFraudReasons = createAsyncThunk(
  `${SLICE_NAME_BLACKLIST_AND_WHITELIST}/fetchFraudReasons`,
  async (_, { dispatch }) => {
    try {
      const { data } = await getFraudReasonsList()

      dispatch(setFraudReasonList(data.data.reasons))
    } catch (err) {
      callErrorMsg(err)
    }
  },
)
