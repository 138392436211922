import { memo, useContext } from 'react'
import styled from 'styled-components'
import store from 'storeContext'
import AstroLogo from 'assets/images/astro-horizontal.svg'
import { TextBody } from '../../../../atoms'
import { Box, Checkbox, Typography } from '@astro-ui/components'

const TUKAR_GULING_MAX_ROW = 5
const BAD_STOCK_ROW = 4

function TemplateSupplyOrderDeliveryNote() {
  const { state: statePrintArea } = useContext(store.PrintAreaContext)
  const { driverName, plateNumber, temperatureBefore, temperatureAfter, documentId, data } =
    statePrintArea.dataDeliveryNoteBulk

  const header = [
    {
      label: 'Nama Driver',
    },
    {
      label: 'KM Awal',
    },
    {
      label: 'Jam Tiba',
    },
    {
      label: 'Seal',
    },
    {
      label: 'Plat Mobil',
    },
    {
      label: 'Suhu Sebelum Loading',
    },
    {
      label: 'Jam Berangkat',
    },
    {
      label: 'Rit',
    },
    {
      label: 'Tanggal',
    },
    {
      label: 'Suhu Setelah Loading',
    },
    {
      label: 'Suhu Wayback',
    },
    {
      label: 'Rute',
    },
  ]
  const RenderHeaderColiInformation = () => {
    return (
      <TableContainer fullWidth>
        <Tr borderTop>
          <Td borderRight>Jmlh</Td>
          <Td borderRight>TTD</Td>
          <Td>Nama</Td>
        </Tr>
      </TableContainer>
    )
  }
  const RenderEmptyColi = () => {
    return (
      <TableContainer fullWidth>
        <Tr>
          <Td borderRight width={'48px'}>
            &nbsp;
          </Td>
          <Td borderRight>&nbsp;</Td>
          <Td>&nbsp;</Td>
        </Tr>
      </TableContainer>
    )
  }
  const renderUnderscore = () => (
    <>
      <TextBody
        color="pureBlack"
        size="normal"
        weight="normal"
        style={{
          marginLeft: '5px',
          marginRight: '10px',
          fontSize: '7px',
        }}
      >
        :
      </TextBody>
      <TextBody color="pureBlack" size="normal" weight="normal">
        _________
      </TextBody>
    </>
  )

  const renderValue = (value) => {
    if (value || value === 0) {
      return (
        <>
          <TextBody
            color="pureBlack"
            size="normal"
            weight="normal"
            style={{
              marginLeft: '5px',
              marginRight: '10px',
              fontSize: '7px',
            }}
          >
            :
          </TextBody>
          <TextBody style={{ fontSize: 8, width: 89 }} color="pureBlack" weight="normal">
            {value}
          </TextBody>
        </>
      )
    }

    return renderUnderscore()
  }

  const renderUnderscoreForKmField = () => (
    <>
      <TextBody
        color="pureBlack"
        size="normal"
        weight="normal"
        style={{
          marginLeft: '5px',
          marginRight: '10px',
          fontSize: '7px',
        }}
      >
        :
      </TextBody>
      <TextBody color="pureBlack" size="normal" weight="normal">
        ____
      </TextBody>
    </>
  )

  const SectionCheckboxYesOrNo = (
    <>
      <Box display="flex" alignItems="center">
        <Typography variant="body2">Ya</Typography>
        <Checkbox checked={false} />
      </Box>
      <Box display="flex" alignItems="center">
        <Typography variant="body2">Tidak</Typography>
        <Checkbox checked={false} />
      </Box>
    </>
  )

  return (
    <Container>
      <LogoWrapper>
        <div>
          <Logo src={AstroLogo} />
        </div>
        <DeliveryNoteText>Surat Jalan</DeliveryNoteText>
        <NoForm>
          No form
          {documentId && <div>{documentId}</div>}
        </NoForm>
      </LogoWrapper>
      <HeaderWrapper>
        {header.map((value, key) => {
          return (
            <HeaderLabelWrapper key={key}>
              <Label>
                <TextBody
                  color="pureBlack"
                  style={{
                    textTransform: 'uppercase',
                    flexBasis: '50%',
                    marginLeft: '10px',
                    fontSize: '7px',
                  }}
                  size="normal"
                  weight="normal"
                >
                  {value.label}
                </TextBody>

                {value.label === 'KM Awal' && renderUnderscoreForKmField()}

                {value.label !== 'KM Awal' &&
                  value.label !== 'Nama Driver' &&
                  value.label !== 'Plat Mobil' &&
                  value.label !== 'Suhu Sebelum Loading' &&
                  value.label !== 'Suhu Setelah Loading' &&
                  renderUnderscore()}

                {value.label === 'Nama Driver' && renderValue(driverName)}
                {value.label === 'Plat Mobil' && renderValue(plateNumber)}
                {value.label === 'Suhu Sebelum Loading' && renderValue(temperatureBefore)}
                {value.label === 'Suhu Setelah Loading' && renderValue(temperatureAfter)}

                {value.label === 'KM Awal' && (
                  <>
                    <TextBody
                      color="pureBlack"
                      style={{
                        marginLeft: '10px',
                        textTransform: 'uppercase',
                        flexBasis: '50%',
                        fontSize: '7px',
                      }}
                      size="normal"
                      weight="normal"
                    >
                      KM Akhir
                    </TextBody>
                    {renderUnderscoreForKmField()}
                  </>
                )}
              </Label>
            </HeaderLabelWrapper>
          )
        })}
      </HeaderWrapper>
      {data?.map((el, key) => {
        return (
          <TableContainer fullWidth key={key}>
            <Tr bordered>
              <Td colored borderRight paddingLeft="5px">
                Hub: {el.destinationLocationName || '-'}
              </Td>
              <Td colored borderRight paddingLeft="5px">
                Jam Tiba:
              </Td>
              <Td colored borderRight paddingLeft="5px">
                Suhu Box Fleet:
              </Td>
              <Td colored bordered spacedLeft paddingLeft="5px">
                Nomor seal hub:
              </Td>
            </Tr>
            <Tr height={'40px'}>
              <Td colored bordered centerText width="40%">
                <p>Nomor SO</p>
                <p>(Regular)</p>
              </Td>
              <Td colored centerText bordered width="20%">
                <p>Koli Dispatch</p>
                <p>(Diisi WH)</p>
                <RenderHeaderColiInformation />
              </Td>
              <Td colored centerText bordered width="20%">
                <p>Koli Dikirim</p>
                <p>(Diisi Driver)</p>
                <RenderHeaderColiInformation />
              </Td>
              <Td colored centerText bordered width="20%">
                <p>Koli Diterima</p>
                <p>(Diisi HUB)</p>
                <RenderHeaderColiInformation />
              </Td>
            </Tr>

            {el.supplyOrderData.map((value, keyChild) => {
              return (
                <Tr key={keyChild}>
                  <Td bordered paddingLeft="12px">
                    {value.supplyOrderNumber}
                  </Td>
                  <Td bordered>
                    <TableContainer fullWidth>
                      <Tr>
                        <Td centerText width={'49px'} borderRight>
                          {value.koliDispatched}
                        </Td>
                        <Td width={'40px'} borderRight>
                          &nbsp;
                        </Td>
                        <Td>&nbsp;</Td>
                      </Tr>
                    </TableContainer>
                  </Td>
                  <Td bordered>
                    <TableContainer fullWidth>
                      <Tr>
                        <Td centerText borderRight width={'49px'}>
                          {value.koliSent}
                        </Td>
                        <Td borderRight>&nbsp;</Td>
                        <Td>&nbsp;</Td>
                      </Tr>
                    </TableContainer>
                  </Td>
                  <Td bordered>
                    <RenderEmptyColi />
                  </Td>
                </Tr>
              )
            })}
          </TableContainer>
        )
      })}
      <TableContainer style={{ marginBottom: '12px' }} fullWidth>
        <Tr height={'40px'}>
          <Td colored bordered centerText width="40%">
            <p>Nomor SO</p>
            <p>(Bad Stock)</p>
          </Td>
          <Td colored centerText bordered width="20%">
            <p>Koli Dispatch</p>
            <p>(Diisi HUB)</p>
            <RenderHeaderColiInformation />
          </Td>
          <Td colored centerText bordered width="20%">
            <p>Koli Dikirim</p>
            <p>(Diisi Driver)</p>
            <RenderHeaderColiInformation />
          </Td>
          <Td colored centerText bordered width="20%">
            <p>Koli Diterima</p>
            <p>(Diisi WH)</p>
            <RenderHeaderColiInformation />
          </Td>
        </Tr>
        {Array.from({ length: BAD_STOCK_ROW }).map((_, i) => (
          <Tr key={`bad_stock_${i + 1}`}>
            <Td bordered height="20px" />
            <Td bordered>
              <RenderEmptyColi />
            </Td>

            <Td bordered>
              <RenderEmptyColi />
            </Td>
            <Td bordered>
              <RenderEmptyColi />
            </Td>
          </Tr>
        ))}
      </TableContainer>
      <Typography variant="body2" fontWeight="bold" mt="3">
        Tukar Guling Asset (Checking){' '}
      </Typography>

      <TableContainer fullWidth style={{ marginTop: '10px' }}>
        <thead>
          <Tr>
            <Th rowSpan="2">Nama Hub</Th>
            <Th rowSpan="2">Jenis Asset</Th>
            <Th colSpan="2">WH</Th>
            <Th colSpan="2">HUB</Th>
            <Th rowSpan="2">Catatan</Th>
          </Tr>
          <Tr>
            <Th>Jumlah Ditagihkan</Th>
            <Th>TTD</Th>
            <Th>Jumlah Diberikan</Th>
            <Th>TTD</Th>
          </Tr>
        </thead>

        {Array.from({ length: TUKAR_GULING_MAX_ROW }).map((_, i) => {
          if (i === 0)
            return (
              <Tr key={`tukar_guling_${i + 1}`}>
                <Td bordered height="20px" paddingLeft="5px">
                  Contoh: BS9
                </Td>
                <Td bordered paddingLeft="5px">
                  2008
                </Td>
                <Td bordered paddingLeft="5px">
                  5
                </Td>
                <Td bordered />
                <Td bordered paddingLeft="5px">
                  3
                </Td>
                <Td bordered paddingLeft="5px" />
                <Td bordered paddingLeft="5px">
                  Fleet Overload
                </Td>
              </Tr>
            )
          return (
            <Tr key={`tukar_guling_${i + 1}`}>
              <Td bordered height="20px" />
              <Td bordered />
              <Td bordered />
              <Td bordered />
              <Td bordered />
              <Td bordered />
              <Td bordered />
            </Tr>
          )
        })}
      </TableContainer>
      <Typography variant="body1" fontWeight="bold" mt="3">
        Disclaimer
      </Typography>
      <Typography variant="body2" mt="3" fontStyle="italic">
        Bagian ini akan digunakan sebagai konfirmasi dari pihak-pihak yang terlibat dalam proses
        pengiriman dan dinyatakan sah atas ketidaksesuaian yang terjadi.
      </Typography>
      <Box display="flex" alignItems="flex-start" justifyContent="space-between">
        <Box display="flex">
          <Typography variant="body2" marginRight="10px" marginTop="10px">
            Proses Loading sudah sesuai ?
          </Typography>
          {SectionCheckboxYesOrNo}
        </Box>
        <WrapperReason>Alasan:</WrapperReason>
      </Box>
      <Box display="flex" alignItems="flex-start" justifyContent="space-between">
        <Box display="flex">
          <Typography variant="body2" marginRight="10px" marginTop="10px">
            Koli barang dalam kondisi baik ?
          </Typography>
          {SectionCheckboxYesOrNo}
        </Box>

        <WrapperReason>
          <Box marginBottom="5px">Alasan:</Box>

          <Box display="flex" alignItems="center" justifyContent="space-between" width="100px">
            <Box display="inline-block">No. SO</Box>
            <Box display="inline-block">:</Box>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100px">
            <Box display="inline-block">No. Koli</Box>
            <Box display="inline-block">:</Box>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100px">
            <Box display="inline-block">Jumlah koli</Box>
            <Box display="inline-block">:</Box>
          </Box>
        </WrapperReason>
      </Box>
    </Container>
  )
}

export default memo(TemplateSupplyOrderDeliveryNote)

const Container = styled.div``

const TableContainer = styled.table`
  ${({ fullWidth }) => fullWidth && ` width: 100%;`}
  width: ${(props) => (props.width ? `${props.width}` : '')};
  height: ${(props) => (props.height ? `${props.height}` : '')};
  border-collapse: collapse;
  font-size: 12px;
  font-weight: 700;
  font-family: Nunito Sans;
`

const Tr = styled.tr`
  font-size: 12px;
  height: ${(props) => (props.height ? `${props.height}` : '')};
  ${({ bordered }) => bordered && `border: 1px solid #BFC9D9`}
  ${({ borderTop }) => borderTop && `border-top: 1px solid #BFC9D9`}
`
const Td = styled.td`
  font-size: 12px;
  width: ${(props) => (props.width ? `${props.width}` : '')};
  padding-left: ${(props) => (props.paddingLeft ? `${props.paddingLeft}` : '0')};
  ${({ colored }) => colored && `background:#F3F4F5;`}
  ${({ centerText }) => centerText && `text-align: center;`}
  ${({ bordered }) => bordered && `border: 1px solid #BFC9D9`}
  ${({ borderLeft }) => borderLeft && `border-left: 1px solid #BFC9D9`}
  ${({ borderRight }) => borderRight && `border-right: 1px solid #BFC9D9`}
  ${({ borderBottom }) => borderBottom && `border-bottom: 1px solid #BFC9D9`}
  ${({ borderTop }) => borderTop && `border-top: 1px solid #BFC9D9`}
  ${({ spacedLeft }) => spacedLeft && `padding-left: 12px`}
`

const Th = styled.th`
  background: #f3f4f5;
  border: 1px solid #bfc9d9;
  padding: 5px;
  text-align: ${(props) => props.align || 'center'};
`

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 10px;
  grid-gap: 8px;
  align-items: end;
`

const Logo = styled.img`
  width: 157.54px;
  height: 32px;
`
const DeliveryNoteText = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.5px;
`
const NoForm = styled.div`
  border: 1px solid #8d96aa;
  border-radius: 8px;
  width: 192px;
  height: 48px;
  font-weight: 700;
  font-size: 12px;
  padding: 8px;
`
const LogoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid #8d96aa;
  box-sizing: border-box;
`
const Label = styled.div`
  margin-bottom: 4px;
  display: flex;
  align-items: flex-end;
`
const HeaderLabelWrapper = styled.div`
  position: relative;
`
const WrapperReason = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  width: 50%;
  box-sizing: border-box;
  min-height: 100px;
  padding: 5px;
  margin-top: 10px;
`
