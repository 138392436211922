import { memo } from "react";
import styled, { css } from "styled-components";

function Toggle({
  className,
  active,
  onClick,
  disabled,
  radiusLength,
  ...rest
}) {
  return (
    <Container
      toggle={active}
      {...rest}
      className={className}
      disabled={disabled}
    >
      <Dot
        radiusLength={radiusLength}
        toggle={active}
        disabled={disabled}
        onClick={disabled ? () => {} : onClick}
      />
    </Container>
  );
}

export default memo(Toggle);

const Container = styled.div`
  width: ${({ width }) => width || "35px"};
  height: ${({ height }) => height || "21px"};
  background-color: ${({ theme: { colors }, toggle, activeColor }) =>
    toggle ? colors[activeColor] || colors.green : colors.secondaryText};
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 4px;
  transition: all ease 0.3s;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
`;

const Dot = styled.div`
  background-color: ${({ theme: { colors } }) => colors.white};
  border-radius: 50%;
  cursor: pointer;
  margin-left: ${({ toggle }) => (toggle ? "14px" : "0px")};
  transition: all ease 0.3s;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
  ${({ radiusLength }) =>
    css`
      width: ${radiusLength || "13px"};
      height: ${radiusLength || "13px"};
    `}
`;
