import {
  ListItemIcon as MuiListItemIcon,
  ListItemIconProps as MuiListItemIconProps,
} from '@mui/material'
import styled from 'styled-components'

export type ListItemIconPropsType = MuiListItemIconProps

const ListItemIcon = styled(MuiListItemIcon)<ListItemIconPropsType>``

export default ListItemIcon
