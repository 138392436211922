import { Get } from 'config/apiServiceConfig'
import AxiosInstance from 'config/apiServiceApiGateway'

export type LocationType = {
  location_id: number
  location_name: string
  location_type: string
}

export type MPBudgetType = {
  location_id: number
  role: string
  status: boolean
  planning: {
    date: string
    budget: number
  }[]
  is_show?: boolean
}

export type FetchMPBudgetParamsType = {
  location_id?: number
  role?: string
  start_date?: string
  end_date?: string
  location_type?: string
}

export type PostMPBudgetPayloadType = {
  location_id: number
  role: string
  planning: {
    date: string
    budget: number
  }[]
}

export type PutMPBudgetStatusPayloadType = {
  location_id: number
  role: string
  status: boolean
}

export const fetchLocations = (type: string) =>
  Get<LocationType[]>({
    url: '/api/locations/:type',
    urlParams: { type },
  })

export const fetchMPBudgetList = (params: FetchMPBudgetParamsType) =>
  AxiosInstance.Get<MPBudgetType[]>({
    url: '/staff-management/internal/:version/man-power-budget',
    params,
    version: 'v1',
  })

export const postMPBudget = (data: PostMPBudgetPayloadType) =>
  AxiosInstance.Post({
    url: '/staff-management/internal/:version/man-power-budget/upsert',
    version: 'v1',
    data,
  })

export const putMPBudgetStatus = (data: PutMPBudgetStatusPayloadType) =>
  AxiosInstance.Put({
    url: '/staff-management/internal/:version/man-power-budget/update-status',
    version: 'v1',
    data,
  })

export const fetchExportMPBudget = (params: FetchMPBudgetParamsType) =>
  AxiosInstance.Get<{ file_url: string }>({
    url: '/staff-management/internal/:version/man-power-budget/export',
    params,
    version: 'v1',
  })

export const fetchTemplateMPBudget = (params: FetchMPBudgetParamsType) =>
  AxiosInstance.Get<{ file_url: string }>({
    url: '/staff-management/internal/:version/man-power-budget/generate-template',
    params,
    version: 'v1',
  })
