import { memo } from "react";
import styled from "styled-components";
import { TextBody } from "components";

function Row({ dIndex, data, isSuratJalan, selectedData }) {
  // const checkStatus = () => {
  //   //console.log(selectedData.supply_order_status);

  //   switch (selectedData.supply_order_status) {
  //     case "CREATED":
  //       return data.product_request_quantity;
  //     case "INCOMING":
  //       return data.product_incoming_quantity;
  //     case "COMPLETED":
  //       return data.product_actual_quantity;
  //     case "PARTIALLY_FULFILLED":
  //       return data.product_actual_quantity;
  //   }
  // };
  return (
    <Tr
      //   className="pagebreak"

      borderLineLeft
      borderLineRight
      borderLineBottom
    >
      <Td width="4%">
        <TDWrapper>
          <TextBody size="toast">{dIndex + 1}</TextBody>
        </TDWrapper>
      </Td>
      <Td width="16%">
        <TDWrapper>
          <TextBody size="toast">{data?.product_sku}</TextBody>
        </TDWrapper>
      </Td>
      <Td width="23%">
        <TDWrapper>{data?.product_name}</TDWrapper>
      </Td>
      <Td width="12%">
        <TDWrapper>
          <CenterText>{data?.product_request_quantity}</CenterText>
        </TDWrapper>
      </Td>
      <Td width="7.5%">
        <TDWrapper>
          <CenterText>
            {isSuratJalan ? data?.product_incoming_quantity : ""}
          </CenterText>
        </TDWrapper>
      </Td>
      <Td width="7.5%">
        <TDWrapper>
          <CenterText>{data?.product_koli && data?.product_koli}</CenterText>
        </TDWrapper>
      </Td>
      <Td width="7.5%">
        <TDWrapper>
          <CenterText>
            {isSuratJalan
              ? data?.product_request_quantity ===
                data?.product_incoming_quantity
                ? "OK"
                : "Qty Kurang"
              : ""}
          </CenterText>
        </TDWrapper>
      </Td>

      <Td width="7.5%">
        <TDWrapper>
          <CenterText>
            {data?.product_destination_rack
              ? data?.product_destination_rack
              : ""}
          </CenterText>
        </TDWrapper>
      </Td>
      <Td width="7.5%">
        <TDWrapper>
          <CenterText></CenterText>
        </TDWrapper>
      </Td>
      <Td width="7.5%" lastBR={true}>
        <TDWrapper>
          <CenterText></CenterText>
        </TDWrapper>
      </Td>
      {/* <Td width="15%" lastBR={true}>
        <TDWrapper>{isSuratJalan ? data?.product_note : ""}</TDWrapper>
      </Td> */}
    </Tr>
  );
}

export default memo(Row);

const Tr = styled.tr`
  display: flex;
  //padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: stretch;
  ${({ borderLineTop }) => borderLineTop && `border-top: 1px solid #bfc9d6;`}
  ${({ borderLineLeft }) => borderLineLeft && `border-left: 1px solid #bfc9d6;`}
  ${({ borderLineRight }) =>
    borderLineRight && `border-right: 1px solid #bfc9d6;`}

    ${({ borderLineBottom }) =>
    borderLineBottom && `border-bottom: 1px solid #bfc9d6;`}
`;
const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.black};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  //   flex: ${({ ratio }) => ratio};
  //   text-align: start;
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  //flex: ${({ ratio }) => ratio};
  width: ${({ width }) => (width ? width : "10%")};
  text-align: start;
  ${({ lastBR }) => !lastBR && `border-right: 1px solid #bfc9d6;`}
`;

const TDWrapper = styled.div`
  padding: 12px 8px;
  //   height: 64px;
`;
const CenterText = styled.p`
  text-align: center;
`;
