import { memo, useState } from 'react'
import styled from 'styled-components'
import themes from 'theme/theme'
import { InputDropDown, TextBody, Toggle, Button, Tag } from 'components'
import { strLimit } from 'utils/string'
import { Icon, Tooltip } from '@astro-ui/components'

import { LOCATION_VENDING_MACHINE_ID } from 'constant/misc'
import { useDispatch } from 'react-redux'
import { setIsOpenedEditPooling, setLocationEditConfig } from 'redux/editPooling'
import { useRBACLocationPage } from 'pages/Location/rbac'

function RowLocation({
  data,
  handleChangeActive,
  handleClickDetail,
  handleClickEdit,
  handleClickDownload,
  handleGenerateQRCode,
}) {
  const dispatchRedux = useDispatch()
  const listAction = []
  const [tootlip, setTooltip] = useState(false)

  const {
    isAllowedForViewLocationDetail,
    isAllowedForUpdateLocationIDStatus,
    isAllowedForUpdatePooling,
  } = useRBACLocationPage()

  if (isAllowedForViewLocationDetail)
    listAction.push({ id: 'edit', name: 'Edit', action: handleClickEdit })
  if (isAllowedForUpdatePooling && String(data?.location_type)?.toLowerCase() === 'hub')
    listAction.push({
      id: 'edit_pooling',
      name: 'Edit Pooling',
      action: (locationId, locationName, locationType) => {
        dispatchRedux(setLocationEditConfig({ locationId, locationName, locationType }))
        dispatchRedux(setIsOpenedEditPooling(true))
      },
    })
  if (String(data?.location_type)?.toLowerCase() === LOCATION_VENDING_MACHINE_ID) {
    listAction.push({
      id: 'download-qr-code',
      name: 'Generate QR Code',
      action: handleGenerateQRCode,
    })
  }

  listAction.push({
    id: 'download_reroute',
    name: 'Download Reroute',
    action: handleClickDownload,
  })

  const handleClickOptionLocation = ({ action }) => {
    action(data.location_id, data.location_name, data.location_type)
  }

  const handleClickOpenTooltip = () => {
    setTooltip((prev) => !prev)
  }

  return (
    <Tr active={data?.location_flag}>
      <Td align="center">
        <TextBody weight="light" color="textSoft">
          {data?.location_id}
        </TextBody>
      </Td>
      <Td>
        <TextBody weight="light" color="textSoft">
          {data.location_name}
          {!!data?.location_flag && (
            <Tooltip title={data?.location_flag} arrow placement="top" openopen={tootlip}>
              <span style={{ paddingRight: '4px' }} onClick={handleClickOpenTooltip}>
                <Icon icon="flag-v2" style={{ transform: 'translate(30%, 25%)' }} />
              </span>
            </Tooltip>
          )}
        </TextBody>
      </Td>
      <Td>
        <TextBody weight="light" color="textSoft">
          {strLimit(data.location_address, 70)}
        </TextBody>
      </Td>
      <Td>
        <TagWrapper>
          {data?.supervisor_names?.slice(0, 2).map((el, idx) => (
            <CustomTag key={idx} color="grey">
              {el}
            </CustomTag>
          ))}
          {data?.supervisor_names?.length > 2 && (
            <Tag color="grey">{data.supervisor_names.length - 2}</Tag>
          )}
        </TagWrapper>
      </Td>
      <Td>
        <TextBody weight="light" color="textSoft">
          {data.location_type}
        </TextBody>
      </Td>
      <Td align="center">
        <ActionWrapper>
          <Toggle
            active={data.location_active}
            disabled={!isAllowedForUpdateLocationIDStatus}
            onClick={() => handleChangeActive(data)}
          />
        </ActionWrapper>
      </Td>
      <Td align="center">
        <Button variant="wrapper" onClick={() => handleClickDetail(data.location_id)}>
          <TextBody weight="light" color="main" className="pointer">
            Detail
          </TextBody>
        </Button>
      </Td>
      <Td align="center">
        <InputDropDown
          listOptions={listAction}
          placeholder="Atur"
          handleClickOption={handleClickOptionLocation}
          styleListContainer={{
            left: -40,
          }}
        />
      </Td>
    </Tr>
  )
}

export default memo(RowLocation)

const Tr = styled.tr`
  background-color: ${({ active }) => (active ? themes.colors.orangeOr2 : themes.colors.white)};
  :last-of-type {
    td:first-of-type {
      border-bottom-left-radius: 8px;
    }
    td:last-of-type {
      border-bottom-right-radius: 8px;
    }
  }
  :last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`
const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  padding: 16px;
  border-bottom: 1px solid #d6dfeb;
  border-left: 1px solid #d6dfeb;
  text-align: ${({ align }) => align ?? 'left'};
`

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  & > * + * {
    margin-left: 24px;
  }
`

const TagWrapper = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
`

const CustomTag = styled(Tag)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 120px;
`
