import {
  TableFooter,
  TableRow,
  TableCell,
  Typography,
  Select,
  Pagination,
  PaginationPropsType,
} from '@astro-ui/components'
import styled from 'styled-components'

const pageLimitOptions = [{ value: 10 }, { value: 20 }, { value: 30 }]

export type PageSizeOptionsType = {
  value: number
}
export type TableFooterPaginationPropsType = Omit<PaginationPropsType, 'onClick'> & {
  customPageLimitOptions?: PageSizeOptionsType[]
  onChangePageSize: (selected: PageSizeOptionsType) => void
  onClickBtnPage: (page: number) => void
  customColSpan?: number
}

const TableFooterPagination = ({
  pageIndex,
  pageSize,
  numberOfElements,
  customPageLimitOptions,
  onChangePageSize,
  onClickBtnPage,
  customColSpan = 11,
  firstPage,
}: TableFooterPaginationPropsType) => (
  <S.TableFooterSticky>
    <TableRow>
      <TableCell colSpan={customColSpan}>
        <S.WrapperTablePagination>
          <S.WrapperLimitCombobox>
            <Select
              required
              displayKey="value"
              value={pageSize.toString()}
              options={customPageLimitOptions || pageLimitOptions}
              onChange={onChangePageSize}
            />
            <Typography variant="body2" marginLeft="10px" fontWeight="bold">
              Tiap halaman
            </Typography>
          </S.WrapperLimitCombobox>
          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            numberOfElements={numberOfElements}
            onClick={onClickBtnPage}
            firstPage={firstPage}
          />
        </S.WrapperTablePagination>
      </TableCell>
    </TableRow>
  </S.TableFooterSticky>
)

export default TableFooterPagination

const S = {
  TableFooterSticky: styled(TableFooter)`
    width: 100%;
    position: sticky;
    bottom: 0;
    z-index: 1;
    background-color: white;
  `,
  WrapperTablePagination: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  `,
  WrapperLimitCombobox: styled.div`
    display: flex;
    align-items: center;
  `,
}
