import { createAsyncThunk } from '@reduxjs/toolkit'
import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  GetTanggungRentengFlagResponseType,
  getTanggungRentengFlag,
} from 'utils/apiList/tanggungRenteng'

export const SLICE_NAME = 'tanggungRenteng'

type GetTanggungRentengRequestType = {
  locationId: number
  callbackFunction?: (isTanggungRentengActive: boolean) => void
}
export const fetchTanggungRentengFlag = createAsyncThunk<
  GetTanggungRentengFlagResponseType,
  GetTanggungRentengRequestType,
  RejectValueType
>(
  `${SLICE_NAME}/fetchGetTanggungRentengToogle`,
  async ({ locationId, callbackFunction }, { rejectWithValue }) => {
    try {
      const response = await getTanggungRentengFlag(locationId, 1, {
        featureKey: 'tanggung_renteng',
      })

      if (callbackFunction) callbackFunction(response.data.data.features?.tanggung_renteng || false)
      return response.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)
