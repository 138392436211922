import { createAsyncThunk } from '@reduxjs/toolkit'
import { getOrderLocations } from 'features/Order/services/allOrder'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getBannerThematicList,
  GetBannerThematicListRequestType,
  putBannerThematicStatus,
} from 'features/BannerAndTicker/sevices/bannerThematicList'

import { SLICE_NAME, setIsLoading, setLocationHubOptions, setThematicList } from './slice'

const validateParamsValue = (
  key: keyof GetBannerThematicListRequestType['params'],
  value: string | number | null,
) =>
  value
    ? {
        [key]: value,
      }
    : {}
export const fetchLocations = createAsyncThunk(
  `${SLICE_NAME}/fetchLocations`,
  async (_, { dispatch }) => {
    try {
      const {
        data: { data },
      } = await getOrderLocations()

      dispatch(
        setLocationHubOptions(data.map((el) => ({ id: el.location_id, name: el.location_name }))),
      )
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar hub')
    }
  },
)

export const fetchThematicList = createAsyncThunk(
  `${SLICE_NAME}/fetchThematicList`,
  async (_, { dispatch, getState }) => {
    dispatch(setIsLoading(true))

    const {
      bannerThematicList: { query },
    } = getState() as StoreStateType
    try {
      const {
        data: { data },
      } = await getBannerThematicList({
        params: {
          page_index: query.pageIndex,
          page_size: query.pageSize,
          ...validateParamsValue('name', query.name),
          ...validateParamsValue('location_id', query.location.id),
        },
      })
      dispatch(setThematicList(data))
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan daftar banner thematic')
    }
    dispatch(setIsLoading(false))
  },
)

export const updateThematicStatus = createAsyncThunk(
  `${SLICE_NAME}/updateThematicStatus`,
  async ({ id }: { id: string }, { dispatch, rejectWithValue }) => {
    dispatch(setIsLoading(true))

    try {
      const {
        data: { data },
      } = await putBannerThematicStatus({ id })

      dispatch(setIsLoading(false))

      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal Mengubah Status Thematic')
      dispatch(setIsLoading(false))

      return rejectWithValue(err)
    }
  },
)
