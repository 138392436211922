/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, TextBody } from 'components'
import Modal from 'components/molecules/Modal'
import { memo, ReactNode } from 'react'
import S from './style'

interface PopUpConfirmationInterface {
  handleClose: () => void
  title: string
  description: ReactNode
  textPrimaryButton?: string
  textSecondaryButton?: string
  handlePrimaryButton?: () => void
  handleSecondaryButton?: () => void
}

const opt: any = {}

const PopUpCancel = ({
  handleClose,
  title,
  description,
  textPrimaryButton,
  textSecondaryButton,
  handlePrimaryButton,
  handleSecondaryButton,
}: PopUpConfirmationInterface) => (
  <Modal title="" width="400px" onCloseModal={handleClose} isShowCloseBtn={false}>
    <TextBody weight="bold" color="textV2" size="headerBigger" className="text-center mb-8">
      {title}
    </TextBody>
    <TextBody weight="light" className="text-center">
      {description}
    </TextBody>
    <S.ButtonWrapper>
      {textSecondaryButton && handleSecondaryButton && (
        <S.ButtonCancel className="w-100" onClick={handleSecondaryButton}>
          {textSecondaryButton}
        </S.ButtonCancel>
      )}

      {textPrimaryButton && handlePrimaryButton && (
        <Button className="w-100" onClick={handlePrimaryButton} {...opt}>
          {textPrimaryButton}
        </Button>
      )}
    </S.ButtonWrapper>
  </Modal>
)

export default memo(PopUpCancel)
