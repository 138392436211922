import { memo } from "react";
import styled from "styled-components";
import LogoImage from "assets/images/astro.svg";
import QRCode from "react-qr-code";
function QRSection({ data }) {
  return (
    <Container>
      <ImageLogo src={LogoImage} />
      <ImageQR>
        <QRCode value={data?.packageLabel} size="48" />
      </ImageQR>
    </Container>
  );
}

export default memo(QRSection);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ImageQR = styled.div`
  width: 48px;
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
`;

const ImageLogo = styled.img`
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
  width: 48px;
`;
