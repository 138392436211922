import { toastFailed } from 'utils/toast'
import { TDocumentDefinitions } from 'pdfmake/interfaces'

const generatePDF = (template: TDocumentDefinitions) => {
  if (!window.pdfMake) {
    toastFailed('PdfMake is not ready')
    return
  }
  const { pdfMake } = window
  pdfMake.createPdf(template).getBuffer((buffer: ArrayBuffer) => {
    const blob = new Blob([buffer], { type: 'application/pdf' })
    const url = URL.createObjectURL(blob)
    const iframe = document.createElement('iframe')
    iframe.style.display = 'none'
    iframe.src = url
    document.body.appendChild(iframe)
    iframe.contentWindow?.print()
  })
}

export default generatePDF
