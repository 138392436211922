import { Get } from 'config/apiServiceConfig'
import apiGateway from 'config/apiServiceApiGateway'
import type {
  GetMilestoneDetailResponseType,
  GetVoucherResponseType,
  MilestoneAddEditPayloadType,
  VoucherType,
} from 'features/Promotion/@types/MilestoneAddEdit'

export const getVoucherList = () =>
  Get<GetVoucherResponseType>({
    url: '/api/voucher',
    params: {
      status: 'active',
      pageSize: 1000,
    },
  })

export const getVoucherById = (id: number) =>
  Get<VoucherType>({
    url: '/api/voucher/:id',
    urlParams: { id },
  })

export const postMilestone = (data: MilestoneAddEditPayloadType) =>
  apiGateway.Post({
    url: `/promo/internal/:version/milestone`,
    version: 'v1',
    data,
  })

export const getMilestoneById = (id: number) =>
  apiGateway.Get<GetMilestoneDetailResponseType>({
    url: `/promo/internal/:version/milestone/:id`,
    version: 'v1',
    urlParams: { id },
  })
