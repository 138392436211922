import axiosInstanceApiGateway from 'config/apiServiceApiGateway'
import { CartItemType, OrderListType } from 'features/Order/redux/CreateOrder'

const { Get, Post } = axiosInstanceApiGateway

export type RequestCreateOrderType = {
  order_location_id: number
  provider: string
  order_external_data: {
    external_order_id?: string
    external_second_id?: string
  }
  order_items: CartItemType[]
  order_total_price_item: number
}
export const getOrderTypeListAPI = () =>
  Get<{ data: OrderListType[] }, true>({ url: '/oms/internal/v1/kitchen/providers' })
export const postSubmitCreateOrder = (payload: RequestCreateOrderType) =>
  Post<{ orderId: string }>({ url: '/oms/internal/v1/kitchen/orders', data: payload })
