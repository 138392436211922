import { Get, Post, Put } from 'config/apiServiceConfig'
import { queryObjToString } from 'utils/queryParamsURL'

export type RouteAndScheduleQueryType = {
  ids: number[]
  name: string
  originETD: string
  originLocationIDs: number[]
  originVendorIDs: number[]
  distanceType: string
  sort: 'name' | 'distance_type'
  direction: 'asc' | 'desc'
  pageIndex: string
  pageSize: string
}

export type RouteOriginType = {
  id: number
  locationType: string
  locationID: number
  locationName: string
  estimatedTimeOfArrival: string
  estimatedTimeOfDeparture: string
}

export type RouteDestinationType = {
  id: number
  locationType: string
  locationID: number
  locationName: string
  estimatedTimeOfArrival: string
  estimatedTimeOfDeparture: string
}

export type RouteAndScheduleType = {
  id: number
  name: string
  distanceType: DistanceType[number]
  tripType: TripType[number]
  active: boolean
  routeOrigins: RouteOriginType[]
  routeDestinations: RouteDestinationType[]
}

export type GetRouteAndScheduleResponseType = {
  data: RouteAndScheduleType[]
  message: string
  pagination: null
}

export type DistanceType = ['NEAREST', 'MID', 'FARTHEST']

export type TripType = ['REGULAR', 'IRREGULAR']

export type LocationType = [
  'HUB',
  'MEGAHUB',
  'OFFICE',
  'VENDING MACHINE',
  'VENDOR',
  'VIRTUAL',
  'WAREHOUSE',
]

export type GetRouteConfigResponseType = {
  data: {
    distanceType: DistanceType
    tripType: TripType
    locationType: LocationType
  }
}

export type LocationDetailType = {
  location_id: number
  location_name: string
  location_address: string
  supervisor_names: Nullable<string>
  location_detail: string
  location_type: LocationType[number]
  location_active: boolean
  location_flag: string
}

export type GetLocationResponseType = {
  content: LocationDetailType[]
}

export type LocationQueryType = {
  type: LocationType[number]
  name: string
}

type OriginDestinationStatusType = 'CREATE' | 'UPDATE'

export type PostOriginDestinationType = Pick<
  RouteOriginType,
  'estimatedTimeOfArrival' | 'estimatedTimeOfDeparture'
> & {
  id?: string
  type: LocationType[number] | ''
  locationID: Nullable<number>
  status?: OriginDestinationStatusType
}

export type PostRouteMasterDataType = {
  name: string
  active: boolean
  tripType: TripType[number] | ''
  distanceType: DistanceType[number] | ''
  routeOrigins: PostOriginDestinationType[]
  routeDestinations: PostOriginDestinationType[]
}

export type RouteMasterLogType = {
  notes: string
  createdAt: number
  createdByName: string
  createdByEmail: string
}

export type GetRouteMasterDetailResponseType = {
  data: RouteAndScheduleType & {
    logs: RouteMasterLogType[]
  }
}

export type VendorQueryType = {
  companyName: string
}
export type GetVendorResponseType = {
  data: VendorType[]
}

export type VendorType = {
  id: number
  companyName: string
  categoryVendor: string
  creditLimit: string
  waitingTime: number
  status: boolean
}

export const getRouteAndQueryList = (query: Partial<RouteAndScheduleQueryType>) =>
  Get<GetRouteAndScheduleResponseType>({
    url: `/api/wms/:version/route-master${queryObjToString(query)}`,
    version: 'v1',
  })

export const getRouteConfig = () =>
  Get<GetRouteConfigResponseType>({
    url: '/api/wms/:version/route-master/config',
    version: 'v1',
  })

export const getLocation = (query: LocationQueryType) =>
  Get<GetLocationResponseType>({
    url: '/api/location',
    params: { ...query, pageSize: 1000 },
  })

export const getVendor = (query: VendorQueryType) =>
  Get<GetVendorResponseType>({
    url: '/api/vendor',
    params: { ...query, pageSize: 1000 },
  })

export const postRouteMaster = (data: PostRouteMasterDataType) =>
  Post<Pick<GetRouteAndScheduleResponseType, 'message'>>({
    url: '/api/wms/:version/route-master',
    version: 'v1',
    data,
  })

export const getRouteMasterDetail = (id: number | string) =>
  Get<GetRouteMasterDetailResponseType>({
    url: `/api/wms/:version/route-master/:id`,
    version: 'v1',
    urlParams: { id },
  })

export const putRouteMaster = (id: string | number, data: PostRouteMasterDataType) =>
  Put<Pick<GetRouteAndScheduleResponseType, 'message'>>({
    url: '/api/wms/:version/route-master/:id',
    version: 'v1',
    data,
    urlParams: { id },
  })

export const putRouteMasterStatus = (
  id: string | number,
  data: Pick<RouteAndScheduleType, 'active'>,
) =>
  Put<Pick<GetRouteAndScheduleResponseType, 'message'>>({
    url: '/api/wms/:version/route-master/:id/active',
    version: 'v1',
    data,
    urlParams: { id },
  })
