import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  AssetMasterParamsInterface,
  AssetMasterDataInterface,
} from 'features/AssetManagement/@types/typeAssetMaster'

interface AssetMasterListStateType {
  isLoading: boolean
  assetMasterParam: AssetMasterParamsInterface
  assetMasterList: AssetMasterDataInterface[]
  hasNext: boolean
}

const initialState: AssetMasterListStateType = {
  isLoading: false,
  assetMasterParam: {
    page_index: 0,
    page_size: 10,
    name: '',
  },
  assetMasterList: [],
  hasNext: false,
}
const assetMasterSlice = createSlice({
  name: 'assetMasterList',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    resetStateAssetMaster: () => initialState,
    resetAssetMasterParam: (state) => {
      state.assetMasterParam = initialState.assetMasterParam
    },
    setAssetMasterParam: (state, action: PayloadAction<AssetMasterParamsInterface>) => {
      const newPayload = action.payload
      state.assetMasterParam = { ...state.assetMasterParam, ...newPayload }
    },
    setAssetMasterList: (state, action: PayloadAction<AssetMasterDataInterface[]>) => {
      state.assetMasterList = action.payload
    },
    setHasNext: (state, action: PayloadAction<boolean>) => {
      state.hasNext = action.payload
    },
  },
})

export const {
  setIsLoading,
  resetStateAssetMaster,
  resetAssetMasterParam,
  setAssetMasterParam,
  setAssetMasterList,
  setHasNext,
} = assetMasterSlice.actions

export default assetMasterSlice.reducer
