import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ProductItemResponseType,
  GetProductListForSoDirectRequestType,
  GetProductItemsForSoDirectRequestType,
} from 'utils/apiList/supplyOrderAddProductDirect'
import { GetProductTypeResponseType } from 'features/Product/services/product'
import {
  SLICE_NAME,
  fetchProductList,
  fetchProductItemList,
  addProductSo,
  updateProductSo,
  actGetConfigOfSupplyOrder,
  ExtendedProductAttributeType,
  ProductType,
  SelectedItemType,
  fetchProductType,
} from './supplyOrderAddProductDirectAsyncThunk'

export type ProductItemType = ProductItemResponseType & ExtendedProductAttributeType

type ProductListOrItemType = 'productList' | 'productItemList'

export interface SoAddProductDirectInterface {
  isLoading: boolean
  isLoadingProductItems: boolean
  supplyOrderId: number
  needToRedirect: boolean
  soType: string
  modalProduct: {
    isOpen: boolean
    indexProductList: number
  }
  queryParamsProductList: GetProductListForSoDirectRequestType
  queryParamsProductItems: GetProductItemsForSoDirectRequestType
  numberOfElementsProductList: number
  numberOfElementsProductItems: number
  headerCheckboxProductListState: boolean
  isHeaderProductItemChecked: boolean
  isHeaderCheckboxIndeterminate: {
    productList: boolean
    productItemList: boolean
  }
  isHeaderChecked: {
    productItemList: boolean
  }
  productList: ProductType[]
  productItemList: ProductItemType[]
  destinationId: number
  shouldFetchProductItemListForNextPage: boolean
  selectedProductGroupIds: string[]
  selectedProductItemIds: string[]
  productGroupIdWithItsData: Record<string, ProductType>[]
  productItemIdWithItsData: Record<string, ProductItemType>[]
  badStatusNotesList: { id: number; statusId: number; displayName: string }[]
  productTypeList: GetProductTypeResponseType['data']
  selectedProductType: GetProductTypeResponseType['data']
}

const initialState: SoAddProductDirectInterface = {
  isLoading: true,
  isLoadingProductItems: true,
  supplyOrderId: 0,
  needToRedirect: false,
  soType: '',
  modalProduct: {
    isOpen: false,
    indexProductList: -1,
  },
  queryParamsProductList: {
    pageSize: 20,
    pageIndex: 1,
    locationId: 0,
    statusId: 0,
  },
  queryParamsProductItems: {
    pageSize: 10,
    pageIndex: 1,
    locationId: 0,
    productId: 0,
  },
  numberOfElementsProductList: 0,
  numberOfElementsProductItems: 0,
  headerCheckboxProductListState: false,
  isHeaderProductItemChecked: false,
  isHeaderCheckboxIndeterminate: {
    productList: false,
    productItemList: false,
  },
  isHeaderChecked: {
    productItemList: false,
  },
  productList: [],
  productItemList: [],
  destinationId: 0,
  shouldFetchProductItemListForNextPage: false,
  selectedProductGroupIds: [],
  selectedProductItemIds: [],
  productGroupIdWithItsData: [],
  productItemIdWithItsData: [],
  badStatusNotesList: [],
  productTypeList: [],
  selectedProductType: [],
}

const supplyOrderAddProductDirectSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    setLocationId: (state, action: PayloadAction<{ originId: number; destinationId: number }>) => {
      const { originId } = action.payload
      state.queryParamsProductList.locationId = originId
    },
    setSoType: (state, action) => {
      state.soType = action.payload
    },
    setQueryParamsForProductList: (
      state,
      action: PayloadAction<{
        fieldName: keyof GetProductListForSoDirectRequestType
        value: string | number
      }>,
    ) => {
      const { fieldName, value } = action.payload
      state.queryParamsProductList[fieldName] = value as never
    },
    setQtyOfSelectedProductItems: (
      state,
      action: PayloadAction<{
        indexItem: number
        value: number
      }>,
    ) => {
      const { indexItem, value } = action.payload
      const {
        modalProduct: { indexProductList },
      } = state
      state.productList[indexProductList].selectedItemList[indexItem].qty = value
    },
    setQtyOfProduct: (
      state,
      action: PayloadAction<{
        indexItem: number
        value: number
        type: ProductListOrItemType
      }>,
    ) => {
      const { indexItem, value, type } = action.payload
      state[type][indexItem].qty = value
    },
    saveDataToTempVariable: (
      state,
      action: PayloadAction<{
        indexItem: number
        type: ProductListOrItemType
      }>,
    ) => {
      const { indexItem, type } = action.payload
      if (type === 'productList') {
        const { productId, productInventoryStatusId, productInventoryStatusNoteId } =
          state.productList[indexItem]
        const uniqueId =
          productInventoryStatusId === 1
            ? `${productInventoryStatusId}-${productId}`
            : `${productInventoryStatusId}-${productId}-${productInventoryStatusNoteId}`

        const idxFound = state.productGroupIdWithItsData.findIndex((item) => item[uniqueId])

        if (idxFound === -1)
          state.productGroupIdWithItsData.push({ [uniqueId]: state.productList[indexItem] })
        else {
          state.productGroupIdWithItsData[idxFound][uniqueId] = state.productList[indexItem]
        }
      } else if (type === 'productItemList') {
        const { productUniqueKey } = state.productItemList[indexItem]

        const idxFound = state.productItemIdWithItsData.findIndex((item) => item[productUniqueKey])
        if (idxFound === -1)
          state.productItemIdWithItsData.push({
            [productUniqueKey]: state.productItemList[indexItem],
          })
        else {
          state.productItemIdWithItsData[idxFound][productUniqueKey] =
            state.productItemList[indexItem]
        }
      }
    },
    setErrorMessageProduct: (
      state,
      action: PayloadAction<{
        indexItem: number
        errorMessage: string
        type: ProductListOrItemType | 'selectedItemList'
      }>,
    ) => {
      const { indexItem, errorMessage, type } = action.payload
      const {
        modalProduct: { indexProductList },
      } = state
      if (type === 'selectedItemList') {
        state.productList[indexProductList].selectedItemList[indexItem].errorMessage = errorMessage
      } else state[type][indexItem].errorMessage = errorMessage
    },
    toggleCheckboxProduct: (
      state,
      action: PayloadAction<{
        index: number
        type: ProductListOrItemType
        uniqueId: string
      }>,
    ) => {
      const { index, type, uniqueId } = action.payload
      if (state.selectedProductGroupIds.includes(uniqueId)) {
        state[type][index].isChecked = false
      } else {
        state[type][index].isChecked = true
      }
    },
    setQueryParamsForProductItems: (
      state,
      action: PayloadAction<Partial<SoAddProductDirectInterface['queryParamsProductItems']>>,
    ) => {
      state.queryParamsProductItems = { ...state.queryParamsProductItems, ...action.payload }
    },
    toggleModalProductItemListOpen: (
      state,
      action: PayloadAction<SoAddProductDirectInterface['modalProduct']>,
    ) => {
      state.modalProduct = action.payload
    },
    setListData: (
      state,
      action: PayloadAction<{
        type: 'productList' | 'productItemList' | 'selectedItemList'
        listData: unknown
      }>,
    ) => {
      const { type, listData } = action.payload
      if (type === 'selectedItemList') {
        const { indexProductList } = state.modalProduct
        state.productList[indexProductList].selectedItemList = listData as SelectedItemType[]
      } else if (type === 'productItemList') state[type] = listData as ProductItemType[]
      else state[type] = listData as ProductType[]
    },
    setIsHeaderChecked: (
      state,
      action: PayloadAction<{
        type: 'productItemList'
        isChecked: boolean
      }>,
    ) => {
      const { type, isChecked } = action.payload
      state.isHeaderChecked[type] = isChecked
    },
    setHeaderCheckboxProductListState: (state, action) => {
      state.headerCheckboxProductListState = action.payload
    },
    setIsHeaderCheckboxIndeterminate: (
      state,
      action: PayloadAction<{
        type: ProductListOrItemType
        isIndeterminate: boolean
      }>,
    ) => {
      const { type, isIndeterminate } = action.payload
      state.isHeaderCheckboxIndeterminate[type] = isIndeterminate
    },
    addTickedProductItemsToSelectedProduct: (
      state,
      action: PayloadAction<{ index: number; item: ProductItemType }>,
    ) => {
      const { index, item } = action.payload
      const { isChecked, errorMessage, ...rest } = item
      state.productList[index].selectedItemList.push({
        ...rest,
        isChecked: false,
        errorMessage: '',
      })
    },
    resetCheckboxAndQtyOfProductItem: (state) => {
      state.isHeaderCheckboxIndeterminate.productItemList = false
      state.isHeaderChecked.productItemList = false

      state.productItemIdWithItsData = []
    },
    setIfCheckboxCheckedOfProductListAtIndex: (state, action: PayloadAction<number>) => {
      const index = action.payload
      state.productList[index].isChecked = !!state.productList[index].selectedItemList.length
    },
    setSupplyOrderId: (state, action: PayloadAction<number>) => {
      state.supplyOrderId = action.payload
    },
    setSelectedProductGroupIds: (state, action: PayloadAction<string[]>) => {
      state.selectedProductGroupIds = action.payload
    },
    setSelectedProductItemIds: (state, action: PayloadAction<string[]>) => {
      state.selectedProductItemIds = action.payload
    },
    setProductGroupIdWithItsData: (
      state,
      action: PayloadAction<SoAddProductDirectInterface['productGroupIdWithItsData']>,
    ) => {
      state.productGroupIdWithItsData = action.payload
    },
    setProductItemIdWithItsData: (
      state,
      action: PayloadAction<SoAddProductDirectInterface['productItemIdWithItsData']>,
    ) => {
      state.productItemIdWithItsData = action.payload
    },
    setBadStatusNotesList: (
      state,
      action: PayloadAction<SoAddProductDirectInterface['badStatusNotesList']>,
    ) => {
      state.badStatusNotesList = action.payload
    },
    setDestinationId: (state, action) => {
      state.destinationId = action.payload
    },
    setSelectedProductType: (state, action) => {
      state.selectedProductType = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchProductList.fulfilled, (state, action) => {
        state.isLoading = false
        state.isHeaderCheckboxIndeterminate.productList = false
        state.headerCheckboxProductListState = false
        state.productList = action.payload.data as ProductType[]
        state.numberOfElementsProductList = action.payload.pagination.numberOfElements
      })
      .addCase(fetchProductList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchProductItemList.pending, (state) => {
        state.isLoadingProductItems = true
        state.productItemList = []
        state.isHeaderCheckboxIndeterminate.productItemList = false

        state.isHeaderChecked.productItemList = false
      })
      .addCase(fetchProductItemList.fulfilled, (state, action) => {
        const { data, pagination, shouldFetchProductItemListForNextPage } = action.payload
        state.isLoadingProductItems = false
        state.productItemList = data
        state.numberOfElementsProductItems = pagination.numberOfElements
        state.shouldFetchProductItemListForNextPage = shouldFetchProductItemListForNextPage

        if (shouldFetchProductItemListForNextPage) {
          state.queryParamsProductItems.pageIndex += 1
        }
      })
      .addCase(fetchProductItemList.rejected, (state) => {
        state.isLoadingProductItems = true
      })
      .addCase(addProductSo.pending, (state) => {
        state.isLoading = true
        state.needToRedirect = false
      })
      .addCase(addProductSo.fulfilled, (state, action) => {
        state.isLoading = false
        state.needToRedirect = true
        state.supplyOrderId = action.payload.data.supplyOrderId
      })
      .addCase(addProductSo.rejected, (state) => {
        state.isLoading = false
        state.needToRedirect = false
      })
      .addCase(updateProductSo.pending, (state) => {
        state.isLoading = true
        state.needToRedirect = false
      })
      .addCase(updateProductSo.fulfilled, (state, action) => {
        state.needToRedirect = true
        state.supplyOrderId = action.payload.data.supplyOrderId
        state.isLoading = false
      })
      .addCase(updateProductSo.rejected, (state) => {
        state.isLoading = false
        state.needToRedirect = false
      })
      .addCase(actGetConfigOfSupplyOrder.fulfilled, (state, action) => {
        state.queryParamsProductList.hideBusinessTaggingNull =
          action.payload.hide_business_tagging_null
        state.queryParamsProductList.businessTaggings = action.payload.business_taggings
      })
      .addCase(fetchProductType.fulfilled, (state, action) => {
        state.productTypeList = action.payload
        state.selectedProductType = action.payload
        state.queryParamsProductList.productTypeIds = action.payload
          .map((item) => item.product_type_id)
          ?.toString()
      })
  },
})

export default supplyOrderAddProductDirectSlice

export const {
  resetState,
  setLocationId,
  setQueryParamsForProductList,
  setQueryParamsForProductItems,
  setQtyOfSelectedProductItems,
  setQtyOfProduct,
  setErrorMessageProduct,
  setIfCheckboxCheckedOfProductListAtIndex,
  toggleCheckboxProduct,
  toggleModalProductItemListOpen,
  addTickedProductItemsToSelectedProduct,
  setIsHeaderCheckboxIndeterminate,
  setListData,
  setIsHeaderChecked,
  resetCheckboxAndQtyOfProductItem,
  setSupplyOrderId,
  setSelectedProductGroupIds,
  setSelectedProductItemIds,
  setProductGroupIdWithItsData,
  setProductItemIdWithItsData,
  setHeaderCheckboxProductListState,
  saveDataToTempVariable,
  setBadStatusNotesList,
  setSoType,
  setDestinationId,
  setSelectedProductType,
} = supplyOrderAddProductDirectSlice.actions
