import { memo, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Checkbox, TextBody } from 'components'
import { formatMoneyRMG } from 'helpers'
import imgNoData from 'assets/images/no-data.png'

function RowCampaignAddManual({ data, stateCollections, campaigAddManualData }) {
  const { selectedData } = stateCollections.getter
  const { setSelectedData } = stateCollections.setter

  const initChecked = false

  const [checked, setChecked] = useState(initChecked)

  useEffect(() => {
    const initCheckedOnFirstRender = selectedData.some((val) => {
      return val.uniqueId === data.uniqueId
    })
    if (initCheckedOnFirstRender) {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }, [campaigAddManualData, selectedData])

  const handleChecked = (e) => {
    const { value } = e.target
    const getCurrentRealValue = value === 'true' ? false : true

    setChecked((prev) => {
      return !prev
    })
    if (getCurrentRealValue) {
      setSelectedData((prev) => {
        return [...prev, data]
      })
    } else {
      setSelectedData((prev) => {
        const newSelectedData = [...prev].filter((items) => {
          return data.uniqueId !== items.uniqueId
        })
        return newSelectedData
      })
    }
  }

  return (
    <Tr>
      <Td>
        <WrapperCheck>
          <Checkbox value={checked} checked={checked} onChange={handleChecked} />
        </WrapperCheck>
      </Td>{' '}
      <Td>
        <ProductWrapper>
          <ImgProduct alt="img-product" src={data.product_image || imgNoData} />
        </ProductWrapper>
      </Td>
      <Td>
        <TextBody size="smaller" weight="light">
          {data?.locationName}
        </TextBody>
      </Td>
      <Td>
        <ProductTextWrapper>
          <TextBody size="smaller" weight="light">
            {data?.productName}
          </TextBody>
          <SKUWrapper>
            <TextBody style={{ width: '42px' }} size="smaller" weight="light">
              SKU :
            </TextBody>
            <TextBody size="smaller" weight="light">
              {data?.productSkuNumber}
            </TextBody>
          </SKUWrapper>
        </ProductTextWrapper>
        {/* <OverFlowSingle line="1">{data?.productName}</OverFlowSingle> */}
      </Td>
      <Td>
        <TextBody size="smaller" weight="light">
          {formatMoneyRMG(data?.productPrice, 'Rp')}
        </TextBody>
      </Td>
      <Td>
        <TextBody size="smaller" weight="light">
          {data?.productStock}
        </TextBody>
      </Td>
    </Tr>
  )
}

RowCampaignAddManual.defaultProps = {
  data: {},
}

export default memo(RowCampaignAddManual)

const Tr = styled.tr`
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
`
const Td = styled.td`
  color: ${({ theme: { colors }, color = 'textSoft' }) => colors[color]};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  padding: 16px;
  padding-right: 20px;
  min-width: ${({ min }) => min};
`

const WrapperCheck = styled.div`
  display: grid;
  place-items: center;
`

const ProductTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const SKUWrapper = styled.div`
  display: flex;
  margin-top: 6px;
`

const ImgProduct = styled.img`
  height: 54px;
  width: 54px;
  border-radius: 5px;
  background-color: ${({ theme: { colors } }) => colors.mainFaded};
  border: none;
`

const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`
