import { memo, useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { TextBody, Input, Button } from "components";
import icon_box from "assets/images/box-item.svg";
import { postPosition } from "storeContext/actions/setPosition/setPositionProduct";
import store from "storeContext";

function RowSetPositionProduct({ data }) {
  const { dispatch: dispatchSetPosition } = useContext(
    store.SetPositionContext
  );

  //STATE HOOKS
  const [formValRow, setFormValRow] = useState({});

  //FUNCTION HELPER
  const getParams = (paramsKey) => {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get(paramsKey);
    return myParam;
    //console.log(myParam);
  };

  //EFFECT HOOKS
  useEffect(() => {
    setFormValRow(data);
  }, [data]);

  //FUNCTION HANDLER
  const handleChangeForm = (e) => {
    const { value, name } = e.target;

    setFormValRow({
      ...formValRow,
      [name]: value //removeZeroOnFirst(numberOnly(value))
    });
  };

  const handleAddPosition = () => {
    const dataToSend = {
      category_id: getParams("categoryID"),
      product_id: data?.product_id,
      position: formValRow.position
    };
    //console.log(dataToSend);
    dispatchSetPosition(postPosition(dataToSend));
  };

  return (
    <Tr>
      <Td ratio="4">
        <ProductWrapper>
          <ImgCategory
            size="32px"
            alt="box"
            src={data?.product_image ? data?.product_image : icon_box}
          />
          <TextBody weight="light" color="textSoft">
            {data?.product_name}
          </TextBody>
        </ProductWrapper>
      </Td>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {data?.product_sku_number}
        </TextBody>
      </Td>
      <Td ratio="2">
        <InputWrapper>
          <Input
            type="number"
            name="position"
            value={formValRow?.position}
            onChange={handleChangeForm}
          />
        </InputWrapper>
      </Td>
      <Td ratio="1">
        <WrapperActions>
          {(formValRow?.position === 0
            ? 0
            : parseInt(formValRow?.position, 10)) !==
            (data?.position === 0 ? 0 : parseInt(data?.position, 10)) && (
            <Button size="small" onClick={handleAddPosition}>
              Simpan
            </Button>
          )}
        </WrapperActions>
      </Td>
    </Tr>
  );
}

export default memo(RowSetPositionProduct);

const Tr = styled.tr`
  display: flex;
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
`;
const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`;
const ImgCategory = styled.img`
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  border-radius: 5px;
  background-color: ${({ theme: { colors } }) => colors.mainFaded};
  border: none;
`;

const ProductWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const WrapperActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InputWrapper = styled.div`
  margin-right: 24px;
`;
