import { memo } from 'react'
import styled from 'styled-components'
import { RowServiceLevel } from 'components'

function TableServiceLevel({ serviceLevelData, handleClickEdit }) {
  return (
    <Container>
      <Thead>
        <Tr>
          <Th ratio="4">Nama Service Level</Th>
          <Th ratio="4">Coverage Area</Th>
          <Th ratio="4">Total Time</Th>
          <Th ratio="1">Action</Th>
        </Tr>
      </Thead>
      <Tbody>
        {serviceLevelData.map((items, index) => {
          return (
            <RowServiceLevel
              data={items}
              handleClickEdit={handleClickEdit}
              key={`${index}_osa${items.service_level_name}`}
            />
          )
        })}
      </Tbody>
    </Container>
  )
}

export default memo(TableServiceLevel)

const Container = styled.table`
  width: 100%;
`

const Thead = styled.thead`
  display: block;
`
const Tbody = styled.tbody`
  display: block;
`

const Tr = styled.tr`
  display: flex;
  padding: 16px 32px;
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`
