import {
  SET_IS_LOADING_CATALOGUE,
  SET_CATALOGS,
  SET_PAGINATION_DATA,
  SET_SHOW_POPUP_ADD,
  SET_SHOW_POPUP_EDIT,
  CATALOG_SEARCH,
  CATALOGUE_SET_CUSTOMER_LIST,
  CATALOGUE_SET_SELECTED_CUSTOMER_LIST,
  CATALOGUE_SET_TARGETING_TYPE_LIST,
} from 'storeContext/actionsType/catalogue'

export const initialStateCatalogue = {
  showPopupAdd: false,
  showPopupEdit: false,
  search: '',
  isLoading: false,
  catalogs: [],
  paginationData: {},

  // popup data
  customerList: [],
  selectedCustomerList: [],
  targetingTypeList: [],
}

export default function catalogue(state = initialStateCatalogue, { type, payload }) {
  switch (type) {
    case SET_IS_LOADING_CATALOGUE:
      return {
        ...state,
        isLoading: payload,
      }
    case SET_CATALOGS:
      return {
        ...state,
        catalogs: payload,
      }
    case SET_PAGINATION_DATA:
      return {
        ...state,
        paginationData: payload,
      }
    case SET_SHOW_POPUP_ADD:
      return {
        ...state,
        showPopupAdd: payload,
      }
    case SET_SHOW_POPUP_EDIT:
      return {
        ...state,
        showPopupEdit: payload,
      }
    case CATALOG_SEARCH:
      return {
        ...state,
        search: payload,
      }
    case CATALOGUE_SET_CUSTOMER_LIST:
      return {
        ...state,
        customerList: payload,
      }
    case CATALOGUE_SET_SELECTED_CUSTOMER_LIST:
      return {
        ...state,
        selectedCustomerList: payload,
      }
    case CATALOGUE_SET_TARGETING_TYPE_LIST:
      return {
        ...state,
        targetingTypeList: payload,
      }
    default:
      return state
  }
}
