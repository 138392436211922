import { memo } from "react";
import styled, { css } from "styled-components";
const staticRest = ["12", "13", "14", "15", "16", "17", "18", "19"];
function RowActivityMonitoringReceiving() {
  return (
    <>
      <Tr color="tableBlue">
        <Td>1.</Td>
        <Td>129181991291819</Td>
        <Td>89347343984773</Td>
        <Td min="250px">
          <Wrapper>
            Chitato Spicy Chicken Keripik [68 g] <Dot color="greenLegend" />
          </Wrapper>
        </Td>
        <Td>24</Td>
        <Td>Pieces</Td>
        {staticRest.map((el) => (
          <Td key={el} />
        ))}
      </Tr>
      <Tr color="white">
        <Td></Td>
        <Td>Actual</Td>
        <Td>89347343984775</Td>
        <Td>Chitato Spicy Chicken Keripik [68 g]</Td>
        <Td>24</Td>
        <Td>Pieces</Td>
        <Td min="170px">PLAST-202110140005</Td>
        <Td>20211001</Td>
        <Td>20211001</Td>
        <Td>Yes</Td>
        <Td>Defect</Td>
        <Td>918264587823091</Td>
        <Td>Stefanus</Td>
        <Td>Staging</Td>
        <Td></Td>
      </Tr>
    </>
  );
}

RowActivityMonitoringReceiving.defaultProps = {};

export default memo(RowActivityMonitoringReceiving);

const Tr = styled.tr`
  padding: 12px 32px;
  background-color: ${({ theme: { colors }, color }) => colors[color]};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
  .input-container {
    width: calc(100% - 20px);
  }
`;

const Td = styled.td`
  color: ${({ theme: { colors }, color = "textSoft" }) => colors[color]};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.toast};
  text-align: start;
  padding: 20px;
  min-width: ${({ min }) => min};
  ${({ center }) =>
    center &&
    css`
      text-align: center;
    `};
  .input-drop-down {
    width: 110px;
    input {
      padding: 6px 0;
    }
  }
`;
const Dot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ theme: { colors }, color }) => colors[color]};
  display: inline-block;
`;

const Wrapper = styled.div`
  /* width: 110px; */
  display: flex;
  align-items: center;
  gap: 7px;
`;
