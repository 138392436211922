export const SET_IS_START_SETPOS = "SET_IS_START_SETPOS";
export const SET_IS_FAILED_SETPOS = "SET_IS_FAILED_SETPOS";
export const SET_IS_SUCCESS_SETPOS = "SET_IS_SUCCESS_SETPOS";
export const SET_IS_PAGINATION_DATA_SETPOS = "SET_IS_PAGINATION_DATA_SETPOS";
export const SET_IS_SUCCESS_SETPOS_CATEGORY = "SET_IS_SUCCESS_SETPOS_CATEGORY";
export const SET_IS_PAGINATION_DATA_SETPOS_CATEGORY =
  "SET_IS_PAGINATION_DATA_SETPOS_CATEGORY";
export const SET_IS_SUCCESS_SETPOS_PRODUCT = "SET_IS_SUCCESS_SETPOS_PRODUCT";
export const SET_IS_PAGINATION_DATA_SETPOS_PRODUCT =
  "SET_IS_PAGINATION_DATA_SETPOS_PRODUCT";
export const SET_IS_NEEN_TO_RELOAD_SETPOS = "SET_IS_NEEN_TO_RELOAD_SETPOS";
export const SET_IS_ALL_PRODUCT_ENTRY_POINT_SETPOS_CATEGORY =
  "SET_IS_ALL_PRODUCT_ENTRY_POINT_SETPOS_CATEGORY";
