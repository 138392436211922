const breakpoints = ['0', '425px', '768px', '992px', '1200px']

breakpoints.xs = breakpoints[0]
breakpoints.sm = breakpoints[1]
breakpoints.md = breakpoints[2]
breakpoints.lg = breakpoints[3]
breakpoints.xl = breakpoints[4]

breakpoints.mobile = breakpoints[0]
breakpoints.tablet = breakpoints[2]
breakpoints.desktop = breakpoints[3]

const theme = {
  breakpoints,
  colors: {
    transparent: 'transparent',
    whiteFaded: 'rgba(255, 255, 255, 0.32)',
    white: '#FFFFFF',
    main: '#2455B0',
    green: '#00BA6C',
    text: '#40464E',
    secondaryText: '#778092',
    secondaryLight: '#D3D3D3',
    // mainFaded: "rgba(42, 191, 108, 0.12)",
    mainFaded: 'rgba(239, 243, 249, 1)',
    grey: '#AAB4C8',
    softGrey: '#F7F9FA',
    softGrey2: '#2455b014',
    softGrey3: '#BFC9D9',
    softGrey4: '#D6DFEB',
    blackFaded: 'rgba(0, 0, 0, 0.5)',
    red: '#EC465C',
    redFaded: 'rgba(236, 70, 92, .1)',
    tableHead: '#FAFCFE',
    body: '#F3F6FA',
    grey70: '#8D96AA',
    textSoft: '#525A67',
    border: '#e4eaf3',
    borderSpaceBlack: '#7A7A7A',
    mainSoft: '#76cd9f',
    orange: '#FA591D',
    orange1: '#FEF2E6',
    orange2: '#F97B06',
    orangeOr2: '#FFE7CC',
    yellow: '#FFF0B3',
    pink: '#FF5C84',
    pinkFaded: '#FFEAEF',
    grass: '#03AC0E',
    grassFaded: '#D6FFDE',
    alertBg: '#FFE163',
    yellowFaded: '#FFFAE6',
    yellowAlert: '#FFF1BA',
    black: '#333333',
    pureBlack: 'black',
    blueFaded: 'rgba(36, 85, 176, 0.1)',
    blue: '#2455B0',
    maroon: '#CC4339',
    redTag: '#DD342F',
    blueTag: '#246EE5',
    green100: '#098A4E',
    greySoft: '#6D7588',
    bgIcon: '#F3F4F5',
    blueSoft: '#0479B9',
    blueTosca: '#C5FCFF',
    mercuryGrey: '#F3F4F5',
    mainV2: '#246EE5',
    textV2: '#212121',
    greenLegend: '#2ABF6C',
    yellowLegend: '#FFE600',
    redLegend: '#FF0021',
    tableBlue: '#F4F9FF',
    green6: '#139D07',
    red6: '#E25450',
    green1: '#E7F5E6',
    green5: '#42B139',
    green9: '#009952',
    neutralGrey: '#F0F2F5',
    galaxyBlue: '#1553B6',
    blackToast: '#3E3E3E',
    grey64: '#646464',
    bgOrange: '#FF8500',
    lightBlue: '#E9F1FC',
    lightOrange: '#FFF3E6',
    lineActive: '#F0F3F7',
    strokeSubtle: '#E9E9E9',
  },
  fontSizes: {
    bigIcon: '2rem',
    headerBiggest: '1.7rem',
    headerBigger: '1.5rem',
    header: '1.3rem',
    headerSmaller: '1.2rem',
    bigger: '1rem',
    normal: '.9rem',
    smaller: '.8rem',
    textSm: '.75rem',
    toast: '.7rem',
    smallest: '.6rem',
    tiny: '.5rem',
  },
  fontWeights: {
    boldest: '700',
    bold: '700',
    normal: '600',
    light: '400',
    lightest: '300',
  },
  mediaQuesries: {
    small: `(max-width: 780px)`,
  },
  fontFamily: {
    monstreat: `'Montserrat', sans-serif`,
    nunito: `'Nunito', sans-serif`,
    nunitoSans: `'Nunito Sans', sans-serif`,
  },
}

export default theme
