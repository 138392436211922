import styled, { css } from 'styled-components'
import { FormControl, FormHelperText, InputLabel } from '@astro-ui/components'
import { TextField as MuiTextField, TextFieldProps as MuiTextFieldProps } from '@mui/material'

export type TextFieldPropsType = MuiTextFieldProps & {
  supportText?: string
  errorMessage?: string
  multiline?: boolean
}

const TextField = ({
  required,
  label,
  supportText,
  errorMessage,
  fullWidth,
  multiline = false,
  error,
  ...rest
}: TextFieldPropsType) => (
  <FormControl
    variant="standard"
    required={required}
    error={error || !!errorMessage}
    fullWidth={fullWidth}
  >
    {label && <InputLabel>{label}</InputLabel>}

    <TextFieldBase multiline={multiline} {...rest} error={error || !!errorMessage} />

    {(!!errorMessage || supportText) && (
      <FormHelperText>{errorMessage || supportText}</FormHelperText>
    )}
  </FormControl>
)

export default TextField

export const TextFieldBase = styled(MuiTextField)<TextFieldPropsType>`
  ${({ theme }) => css`
    label + & {
      margin-top: 16px;
    }
    & .MuiInputBase-root {
      border-radius: 8px;
      background-color: ${theme.colors.grey0};
    }
    & .MuiInputBase-input {
      padding: 12px 16px;
      color: ${theme.colors.grey9};
      font-family: ${theme.fontFamily.nunitoSans};
      font-size: 14px;
      ::placeholder {
        color: ${theme.colors.grey5};
      }
    }
    & .MuiOutlinedInput-root {
      fieldset {
        border-radius: 8px;
      }
    }
    & .Mui-focused {
      fieldset {
        border-width: 1px !important;
      }
    }
    & .Mui-disabled {
      background-color: ${theme.colors.grey1};
    }

    & .MuiInputBase-multiline {
      padding: 0px;
    }
  `}
`
