import { createAsyncThunk } from '@reduxjs/toolkit'
import { downloadFileUrl } from '@astronautsid/wpe-utils'

import { callErrorMsg } from 'helpers/errorMsg'
import {
  fetchSOParamsAPI,
  fetchSOSchedulesAPI,
  fetchSOReportsAPI,
  clearRemarksAPI,
  downloadSoReportAPI,
  deleteSoScheduleByIdAPI,
  deleteAllSoSchedulesAPI,
  uploadSoSchedulesAPI,
} from 'features/Enterprise/SOAutomation/services/apiList'

import {
  setIsLoading,
  setSoParamsData,
  setSoSchedulesData,
  setSoReportsData,
  setQuery,
} from './SOAutomationSlice'
import { downloadSoAutoCsv } from '../../helpers'

const sliceName = 'soAutomation'

export const fetchSOParams = createAsyncThunk(
  `${sliceName}/fetchSoParams`,
  async (withCsvDownload: boolean, { dispatch, getState }) => {
    try {
      dispatch(setIsLoading(true))
      const {
        soAutomation: { query },
      } = getState() as StoreStateType

      const soQuery = { ...query }
      if (withCsvDownload) {
        soQuery.page_size = 999999
        soQuery.page_index = 1
      }

      const { data } = await fetchSOParamsAPI(soQuery)

      if (withCsvDownload) {
        downloadSoAutoCsv('parameters', data.data)
      } else {
        dispatch(setSoParamsData(data.data))
        dispatch(setQuery(data.pagination))
      }
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const fetchSOSchedules = createAsyncThunk(
  `${sliceName}/fetchSOSchedules`,
  async (withCsvDownload: boolean, { dispatch, getState }) => {
    try {
      dispatch(setIsLoading(true))
      const {
        soAutomation: { query },
      } = getState() as StoreStateType

      const soQuery = { ...query }
      if (withCsvDownload) {
        soQuery.page_size = 999999
        soQuery.page_index = 1
      }

      const { data } = await fetchSOSchedulesAPI(soQuery)

      if (withCsvDownload) {
        downloadSoAutoCsv('schedules', data.data)
      } else {
        dispatch(setSoSchedulesData(data.data))
        dispatch(setQuery(data.pagination))
      }
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const uploadSoSchedules = createAsyncThunk(
  `${sliceName}/uploadSoSchedules`,
  async (file: File, { dispatch }) => {
    try {
      dispatch(setIsLoading(true))
      await uploadSoSchedulesAPI(file)
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const deleteSoSchedule = createAsyncThunk(
  `${sliceName}/deleteSoSchedule`,
  async (id: number, { dispatch }) => {
    try {
      dispatch(setIsLoading(true))
      if (id) {
        await deleteSoScheduleByIdAPI(id)
      } else {
        await deleteAllSoSchedulesAPI()
      }
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const fetchSOReport = createAsyncThunk(
  `${sliceName}/fetchSOReport`,
  async (withCsvDownload: boolean, { dispatch, getState }) => {
    try {
      dispatch(setIsLoading(true))
      const {
        soAutomation: { query },
      } = getState() as StoreStateType

      const soQuery = { ...query }
      if (withCsvDownload) {
        soQuery.page_size = 999999
        soQuery.page_index = 1
      }

      const { data } = await fetchSOReportsAPI(soQuery)

      if (withCsvDownload) {
        downloadSoAutoCsv('reports', data.data)
      } else {
        dispatch(setSoReportsData(data.data))
        dispatch(setQuery(data.pagination))
      }
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const clearRemarks = createAsyncThunk(
  `${sliceName}/fetchRemarks`,
  async (_, { dispatch, getState }) => {
    try {
      dispatch(setIsLoading(true))
      const {
        auth: {
          userData: { email },
        },
      } = getState() as StoreStateType
      await clearRemarksAPI(email)
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)

export const downloadSoReport = createAsyncThunk(
  `${sliceName}/downloadSoReport`,
  async ({ id, fileName }: { id: number; fileName: string }, { dispatch }) => {
    try {
      dispatch(setIsLoading(true))
      const { data } = await downloadSoReportAPI(id)
      downloadFileUrl(data.file_url, fileName)
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)
