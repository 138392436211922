import { memo, useState } from 'react'
import styled from 'styled-components'
import { Calendar } from 'react-date-range'
import dayjs from 'dayjs'
import { numberOnly } from 'helpers'

import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import './datePicker.css'

import {
  SystemIcon,
  Button,

  //TextBody
} from 'components'
/****************
 *  REMOVE DEFINED DATE WITH CSS
 */

function DatePicker({
  className,
  border,
  handleClickDatePicker,
  defaultValue,
  inputType,
  handleClickClearDatePicker,
}) {
  //  STATE HOOKS
  const [dateFormat] = useState('DD/MMM/YYYY')
  const [dFormat] = useState({
    day: 'DD',
    month: 'M',
    year: 'YYYY',
  })
  const [isShowDateRange, setIsShowDateRange] = useState(false)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [previewInput, setPreviewInput] = useState({
    date: defaultValue || dayjs(selectedDate).format(dateFormat),
    day: dayjs(selectedDate).format(dFormat.day),
    month: dayjs(selectedDate).format(dFormat.month),
    year: dayjs(selectedDate).format(dFormat.year),
  })

  // FUNCTION HANDLER
  const handleClickApply = () => {
    handleClickDatePicker(selectedDate)
    setPreviewInput((prev) => {
      return {
        ...prev,
        date: dayjs(selectedDate).format(dateFormat),
        day: Number(dayjs(selectedDate).format(dFormat.day)),
        month: Number(dayjs(selectedDate).format(dFormat.month)),
        year: Number(dayjs(selectedDate).format(dFormat.year)),
      }
    })
    setIsShowDateRange(false)
  }

  const handleClearDate = () => {
    setSelectedDate(new Date())
    setPreviewInput((prev) => {
      return {
        ...prev,
        date: dayjs(new Date()).format(dateFormat),
        day: dayjs().format(dFormat.day),
        month: dayjs().format(dFormat.month),
        year: dayjs().format(dFormat.year),
      }
    })
    handleClickClearDatePicker()
    setIsShowDateRange(false)
  }

  const handleSelect = (prop) => {
    //console.log({ time: dayjs(prop).unix() });
    // console.log(prop);
    setSelectedDate(prop)
  }

  const handleClickOpenPopupDatePicker = () => {
    setIsShowDateRange(!isShowDateRange)
  }

  const handleChangeDate = (e) => {
    const { value, name } = e.target

    const numValue = Number(numberOnly(value))

    setPreviewInput((prev) => {
      return { ...prev, [name]: numValue }
    })

    const d = name === 'day' ? numValue : previewInput.day
    const m = name === 'month' ? numValue : previewInput.month
    const y = name === 'year' ? numValue : previewInput.year

    setSelectedDate(new Date(y, m - 1, d))
  }

  //FUNCTON HELPER

  // FUNCTION RENDER

  const renderInput = () => {
    switch (inputType) {
      case 'GENERAL':
        return <InputGeneral previewInput={previewInput} setIsShowDateRange={setIsShowDateRange} />
      default:
        return <InputDMMYYY handleChangeDate={handleChangeDate} previewInput={previewInput} />
    }
  }

  return (
    <div>
      <InputContainer className={`${className} input-container`}>
        <Container iconPos="right" border={border}>
          <label>
            <IconWrapper
              onClick={handleClickOpenPopupDatePicker}
              className="icon-wrapper"
              iconPos="left"
              bgIcon="s"
            >
              <SystemIcon iconName="calendar" colorIcon="grey" />
            </IconWrapper>
          </label>
          {renderInput()}
        </Container>
      </InputContainer>
      {isShowDateRange ? (
        <RenderDateRangePicker
          handleClearDate={handleClearDate}
          handleSelect={handleSelect}
          handleClickApply={handleClickApply}
          setIsShowDateRange={setIsShowDateRange}
          selectedDate={selectedDate}
        />
      ) : (
        ''
      )}
    </div>
  )
}

DatePicker.defaultProps = {
  onChange: () => {},
  iconPos: 'right',
  border: true,
  customIcon: '',
  bgIcon: 'grey',
  handleClickIcon: () => {},
  value: '',
  handleClickDatePicker: () => {},
  isSingle: false,
  inputType: 'GENERAL',
  handleClickClearDatePicker: () => {},
}

function InputDMMYYY({ previewInput, handleChangeDate }) {
  const pixelWidth = (inData, initalWidth) => {
    return initalWidth + String(inData).length * 8 + 'px'
  }

  return (
    <>
      <InputDate
        type="text"
        value={previewInput.day}
        pr="5px"
        width={pixelWidth(previewInput.day, 20)}
        name="day"
        onChange={handleChangeDate}
      />
      <div style={{ paddingTop: '5px', paddingLeft: '2px', paddingRight: '2px' }}>/</div>

      <InputDate
        pr="0px"
        pl="5px"
        width={pixelWidth(previewInput.month, 15)}
        type="text"
        value={previewInput.month}
        name="month"
        onChange={handleChangeDate}
      />
      <div style={{ paddingTop: '5px' }}>/</div>
      <InputDate
        pl="5px"
        width={pixelWidth(previewInput.year, 30)}
        type="text"
        value={previewInput.year}
        name="year"
        onChange={handleChangeDate}
      />
    </>
  )
}

function InputGeneral({ previewInput, setIsShowDateRange }) {
  const textInputPreview = `${previewInput.day}/${previewInput.month}/${previewInput.year}`
  return (
    <Input
      autoComplete="off"
      type="text"
      // value={textInputPreview}
      value={textInputPreview}
      readOnly
      onClick={() => {
        setIsShowDateRange(true)
      }}
    />
  )
}

function RenderDateRangePicker({
  setIsShowDateRange,
  handleSelect,
  handleClearDate,
  handleClickApply,
  selectedDate,
}) {
  return (
    <>
      <DatePickerContainer className="isSingleDate">
        <DatePickerHeader>
          <IconCloseWrapper onClick={() => setIsShowDateRange(false)}>
            <SystemIcon iconName="plus" fontSize="headerBigger" colorIcon="text" />
          </IconCloseWrapper>
        </DatePickerHeader>
        <Calendar date={selectedDate} onChange={handleSelect} />

        <DatePickerFooter>
          <Button style={{ marginLeft: 'auto' }} onClick={handleClearDate}>
            Clear
          </Button>
          <Button style={{ marginLeft: '10px' }} onClick={handleClickApply}>
            Terapkan
          </Button>
        </DatePickerFooter>
      </DatePickerContainer>
    </>
  )
}

export default memo(DatePicker)

const IconCloseWrapper = styled.div`
  transform: rotate(45deg);
  cursor: pointer;
  display: inline-block;
  margin-left: auto;
}
`
const Container = styled.div`
  width: 100%;
  border-radius: 6px;
  display: flex;
  position: relative;
  background-color: ${({ theme: { colors } }) => colors.white};
  border: ${({ border }) => (border ? 'solid 1px #E4EAF3' : 'none')};
  flex-direction: ${({ iconPos }) => (iconPos === 'left' ? 'row-reverse' : 'row')};
`
const IconWrapper = styled.div`
  display: grid;
  padding: 10px;
  padding-top: 12px;
  place-items: center;
  background-color: ${({ theme: { colors }, bgIcon }) => colors[bgIcon]};
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.light};
  font-family: 'Noto Sans JP', sans-serif !important;
  /* padding-right: 0; */
`

const Input = styled.input`
  background-color: white;
  border: none;
  width: 100%;
  padding: 10px;
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  color: ${({ theme: { colors }, color }) => colors[color]};
  border-radius: 6px;
  &:focus {
    outline: none;
  }
  ::placeholder {
    opacity: 1;
    color: #aab4c8;
  }
  ::-webkit-calendar-picker-indicator {
    width: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    /* display: none;
        -webkit-appearance: none; */
  }
`

const InputDate = styled.input`
  background-color: white;
  border: none;

  width: ${({ width }) => (width ? width : '30px')};
  padding-left: ${({ pl }) => (pl ? pl : '10px')};
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: ${({ pr }) => (pr ? pr : '10px')};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  color: ${({ theme: { colors }, color }) => colors[color]};
  border-radius: 6px;
  &:focus {
    outline: none;
  }
  ::placeholder {
    opacity: 1;
    color: #aab4c8;
  }
  ::-webkit-calendar-picker-indicator {
    width: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    /* display: none;
        -webkit-appearance: none; */
  }
`

const InputContainer = styled.div`
  width: 100%;
`

const DatePickerContainer = styled.div`
  padding: 10px;
  position: absolute;
  background: white;
  box-shadow: 0px 30px 84px rgba(19, 10, 46, 0.08), 0px 8px 32px rgba(19, 10, 46, 0.07),
    0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13);
  border-radius: 12px;
  z-index: 10;
`

const DatePickerFooter = styled.div`
  display: flex;
`

const DatePickerHeader = styled.div`
  display: flex;
`
