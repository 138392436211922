export const SET_IS_START_HUB_DETAIL = 'SET_IS_START_HUB_DETAIL'
export const SET_IS_FAILED_HUB_DETAIL = 'SET_IS_FAILED_HUB_DETAIL'
export const SET_IS_SUCCESS_HUB_DETAIL = 'SET_IS_SUCCESS_HUB_DETAIL'
export const SET_IS_NEED_TO_RELOAD_HUB_DETAIL = 'SET_IS_NEED_TO_RELOAD_HUB_DETAIL'
export const SET_PAGINATION_DATA_HUB_DETAIL = 'SET_PAGINATION_DATA_HUB_DETAIL'
export const SET_SELECT_PRODUCT_DATA_HUB_DETAIL = 'SET_SELECT_PRODUCT_DATA_HUB_DETAIL'

export const SET_IS_START_HUB_DETAIL_HISTORY = 'SET_IS_START_HUB_DETAIL_HISTORY'
export const SET_IS_FAILED_HUB_DETAIL_HISTORY = 'SET_IS_FAILED_HUB_DETAIL_HISTORY'
export const SET_IS_SUCCESS_HUB_DETAIL_HISTORY = 'SET_IS_SUCCESS_HUB_DETAIL_HISTORY'
export const SET_PAGINATION_DATA_HUB_DETAIL_HISTORY = 'SET_PAGINATION_DATA_HUB_DETAIL_HISTORY'

export const SET_PAGINATION_INVENTORY_LOCATION = 'SET_PAGINATION_INVENTORY_LOCATION'

export const SET_PAGINATION_INVENTORY_STATUS = 'SET_PAGINATION_INVENTORY_STATUS'
export const SET_PAGINATION_INVENTORY_NOTE_BY_STATUS = 'SET_PAGINATION_INVENTORY_NOTE_BY_STATUS'

export const SET_IS_START_INVENTORY_LOCATION = 'SET_IS_START_INVENTORY_LOCATION'
export const SET_IS_FAILED_INVENTORY_LOCATION = 'SET_IS_FAILED_INVENTORY_LOCATION'
export const SET_IS_SUCCESS_INVENTORY_LOCATION = 'SET_IS_SUCCESS_INVENTORY_LOCATION'

export const SET_SAVE_INVENTORY_LOCATION_START = 'SET_SAVE_INVENTORY_LOCATION_START'
export const SET_SAVE_INVENTORY_LOCATION_SUCCESS = 'SET_SAVE_INVENTORY_LOCATION_SUCCESS'
export const SET_SAVE_INVENTORY_LOCATION_FAILED = 'SET_SAVE_INVENTORY_LOCATION_FAILED'

export const SET_UPDATE_INVENTORY_LOCATION_START = 'SET_UPDATE_INVENTORY_LOCATION_START'
export const SET_UPDATE_INVENTORY_LOCATION_SUCCESS = 'SET_UPDATE_INVENTORY_LOCATION_SUCCESS'
export const SET_UPDATE_INVENTORY_LOCATION_FAILED = 'SET_UPDATE_INVENTORY_LOCATION_FAILED'

export const SET_IS_START_INVENTORY_STATUS = 'SET_IS_START_INVENTORY_STATUS'
export const SET_IS_FAILED_INVENTORY_STATUS = 'SET_IS_FAILED_INVENTORY_STATUS'
export const SET_IS_SUCCESS_INVENTORY_STATUS = 'SET_IS_SUCCESS_INVENTORY_STATUS'

export const SET_IS_START_INVENTORY_NOTE_BY_STATUS = 'SET_IS_START_INVENTORY_NOTE_BY_STATUS'
export const SET_IS_FAILED_INVENTORY_NOTE_BY_STATUS = 'SET_IS_FAILED_INVENTORY_NOTE_BY_STATUS'
export const SET_IS_SUCCESS_INVENTORY_NOTE_BY_STATUS = 'SET_IS_SUCCESS_INVENTORY_NOTE_BY_STATUS'

export const SET_IS_START_INVENTORY_SAVE = 'SET_IS_START_INVENTORY_SAVE'
export const SET_IS_SUCCESS_INVENTORY_SAVE = 'SET_IS_SUCCESS_INVENTORY_SAVE'
export const SET_IS_FAILED_INVENTORY_SAVE = 'SET_IS_FAILED_INVENTORY_SAVE'

export const SET_MODAL_INFORMATION = 'hubDetail/SET_MODAL_INFORMATION'

export const SET_INVENTORY_NOTE = 'SET_INVENTORY_NOTE'
