import { createAsyncThunk } from '@reduxjs/toolkit'
import { getOrderLocation } from 'utils/api'
import { LocationType } from 'features/CategoryAndPosisi/@types/catalogue'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getCatalogueImageTypes,
  getCatalogueBackground,
  postCatalogueBackground,
  putCatalogueBackground,
  type PostCatalogueBackgroundPayloadType,
} from 'features/CategoryAndPosisi/services/catalogue'

export const SLICE_NAME = 'catalogue'

export const fetchLocations = createAsyncThunk(`${SLICE_NAME}/fetchLocations`, async () => {
  try {
    const { data } = await getOrderLocation()

    return data?.data || ([] as LocationType[])
  } catch (err) {
    callErrorMsg(err, 'Gagal mendapatkan daftar hub')

    return []
  }
})

export const fetchCatalogueImageTypes = createAsyncThunk(
  `${SLICE_NAME}/fetchCatalogueImageTypes`,
  async () => {
    try {
      const {
        data: { data },
      } = await getCatalogueImageTypes()
      return data
    } catch (error) {
      callErrorMsg(error)
      return []
    }
  },
)

export const fetchCatalogueBackground = createAsyncThunk(
  `${SLICE_NAME}/fetchCatalogueBackground`,
  async () => {
    try {
      const { data } = await getCatalogueBackground()

      return data
    } catch (err) {
      callErrorMsg(err, 'Gagal mendapatkan catalog background')

      return null
    }
  },
)

export const createCatalogueBackground = createAsyncThunk(
  `${SLICE_NAME}/createCatalogueBackground`,
  async (payload: PostCatalogueBackgroundPayloadType) => {
    try {
      await postCatalogueBackground(payload)

      return true
    } catch (err) {
      callErrorMsg(err, 'Gagal saat membuat catalog background.')

      return false
    }
  },
)

export const updateCatalogueBackground = createAsyncThunk(
  `${SLICE_NAME}/updateCatalogueBackground`,
  async ({ id, payload }: { id: number; payload: PostCatalogueBackgroundPayloadType }) => {
    try {
      await putCatalogueBackground(id, payload)

      return true
    } catch (err) {
      callErrorMsg(err, 'Gagal saat mengubah catalog background.')

      return false
    }
  },
)
