import { memo, useContext } from 'react'
import storeContext from 'storeContext'
import styled from 'styled-components'
import RowUserID from './RowUserID'

function TableUserID() {
  const {
    state: { listSelectedCustomers },
  } = useContext(storeContext.RefferalContext)

  return (
    <>
      <Container>
        <Table>
          <thead>
            <Tr>
              <Th ratio="2">User id</Th>
              <Th ratio="4">username</Th>
              <Th ratio="1"></Th>
            </Tr>
          </thead>
          <tbody>
            {listSelectedCustomers?.map((el) => (
              <RowUserID key={el.customer_id} data={el} />
            ))}
          </tbody>
        </Table>
      </Container>
    </>
  )
}
export default memo(TableUserID)

const Container = styled.div`
  margin-top: 12px;
  margin-bottom: 32px;
`
const Table = styled.table`
  width: 100%;
  /*border: 1px dashed #cad3e1;*/
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #bfc9d9;
  border-radius: 8px;
`

const Tr = styled.tr`
  display: flex;
  padding: 12px 24px;
  background-color: ${({ theme: { colors } }) => colors.tableHead};
  /* width: 100%; */
`

const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  font-weight: ${({ theme: { fontWeights } }) => fontWeights.light};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`
