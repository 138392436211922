import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import logoAstro from 'assets/images/logo-black.png'
import logoReplenish from 'assets/images/logo-replenish.png'
import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'
import {
  GetPrintReplenishmentTaskResponseType,
  GetReplenishmentTaskListRequestType,
  getStaffByRoleAndLocationAPI,
  getPrintReplenishmentTaskDetailAPI,
  getReplenishmentTaskListAPI,
  getWarehouseLocationsAPI,
  putReplenishmentTaskAPI,
  GetWarehouseLocationsResponseType,
  putUpdateStaffReplenishmentTaskAPI,
} from 'features/SupplyOrder/services/replenishment'
import { callErrorMsg } from 'helpers/errorMsg'
import { toastFailed, toastSuccess } from 'utils/toast'
import { dateFormat } from '@astronautsid/wpe-utils'
import { TDocumentDefinitions } from 'pdfmake/interfaces'
import { queryParamsURL } from 'utils/queryParamsURL'
import { fetchGetReplenishmentTaskDetail } from '../replenishmentTaskDetail'

export const SLICE_NAME = 'REPLENISHMENT_TASK'

export const fetchGetWarehouseLocations = createAsyncThunk(
  `${SLICE_NAME}/fetchGetWarehouseLocations`,
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        auth: { userData },
      } = getState() as StoreStateType

      const response = await getWarehouseLocationsAPI()

      const warehouseList: GetWarehouseLocationsResponseType = userData.isSuperAdmin
        ? response.data
        : response.data.filter((item) =>
            userData?.location_roles.some((auth) => item.location_id === auth.location_id),
          )

      return warehouseList
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchGetInventoryController = createAsyncThunk(
  `${SLICE_NAME}/fetchGetInventoryController`,
  async (params: Parameters<typeof getStaffByRoleAndLocationAPI>[0], { rejectWithValue }) => {
    try {
      const response = await getStaffByRoleAndLocationAPI(params)

      return response.data.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchGetReplenishmentTask = createAsyncThunk(
  `${SLICE_NAME}/fetchGetReplenishmentTask`,
  async (params: Parameters<typeof getReplenishmentTaskListAPI>[0], { rejectWithValue }) => {
    try {
      const response = await getReplenishmentTaskListAPI(params)

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const setReplenishmentTaskFilter = createAction<GetReplenishmentTaskListRequestType>(
  `${SLICE_NAME}/setFilter`,
)

export const actGetReplenishmentTaskFilter =
  (params?: GetReplenishmentTaskListRequestType) =>
  (dispatch: StoreDispatchType, getState: () => StoreStateType) => {
    const {
      auth: { userData },
    } = getState()

    const { location_ids } = queryParamsURL.get()

    const locationIds = params?.location_ids
      ? params.location_ids
      : location_ids ||
        userData.location_roles
          .filter((item) => item.location_type === 'WAREHOUSE')
          .map((item) => item.location_id)
          .toString()

    const qs = queryParamsURL.set({ ...params, location_ids: locationIds })
    dispatch(setReplenishmentTaskFilter(qs))
    dispatch(fetchGetReplenishmentTask(qs))
  }

export const fetchPutReplenishmentTask = createAsyncThunk(
  `${SLICE_NAME}/fetchPutReplenishmentTask`,
  async (
    { id, payload, meta }: Parameters<typeof putReplenishmentTaskAPI>[0],
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await putReplenishmentTaskAPI({
        id,
        payload,
      })

      if (meta?.callback) meta.callback()
      ;(dispatch as StoreDispatchType)(actGetReplenishmentTaskFilter())
      dispatch(fetchGetReplenishmentTaskDetail({ id }))

      toastSuccess(response.data.message)

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const fetchPutUpdateStaffReplenishmentTask = createAsyncThunk(
  `${SLICE_NAME}/fetchPutUpdateStaffReplenishmentTask`,
  async (
    { id, payload }: Parameters<typeof putUpdateStaffReplenishmentTaskAPI>[0],
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await putUpdateStaffReplenishmentTaskAPI({
        id,
        payload,
      })

      toastSuccess(response.data.message)
      ;(dispatch as StoreDispatchType)(actGetReplenishmentTaskFilter())
      dispatch(fetchGetReplenishmentTaskDetail({ id }))

      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

type ItemListType = {
  text: string | number
  margin: number
}[]

type PdfPrintFrameType = {
  frames: { printPdf: Window }
}

export const actPrintReplenishmentTask =
  (data: GetPrintReplenishmentTaskResponseType['data']) => () => {
    if (!window.pdfMake) {
      toastFailed('PdfMake is not ready')
      return
    }

    const { pdfMake } = window
    const { replenishment_task, replenishment_task_item_detail } = data

    const itemList: ItemListType[] = []

    const styleColumn = {
      margin: 4,
    }

    const getDate = (date: number) =>
      date
        ? dateFormat({
            date,
            format: 'DD MMM YYYY - HH:mm',
          })
        : ''

    replenishment_task_item_detail.forEach((item) => {
      itemList.push([
        {
          text: `${item.product_name} \n ${item.product_sku}`,
          ...styleColumn,
        },
        {
          text: item.from_rack,
          ...styleColumn,
        },
        {
          text: item.package_label,
          ...styleColumn,
        },
        {
          text: getDate(item.expiry_date),
          ...styleColumn,
        },
        {
          text: item.repl_qty,
          ...styleColumn,
        },
        {
          text: item.base_uom,
          ...styleColumn,
        },
        {
          text: item.to_rack,
          ...styleColumn,
        },
        {
          text: '',
          ...styleColumn,
        },
      ])
    })

    const tableHeader = [
      { text: 'Deskripsi SKU', style: 'tableHeader' },
      { text: 'From SLOC', style: 'tableHeader' },
      { text: 'Package ID', style: 'tableHeader' },
      { text: 'Expiry Date', style: 'tableHeader' },
      { text: 'Repl. Qty', style: 'tableHeader' },
      { text: 'UOM', style: 'tableHeader' },
      { text: 'To SLOC', style: 'tableHeader' },
      { text: 'Remark', style: 'tableHeader' },
    ]

    const replenishData = {
      slipTaskNo: replenishment_task.replenishment_task_no,
      slipDate: replenishment_task.created_at,
      operator: replenishment_task.staff?.full_name || '-',
      locationName: replenishment_task.location_name,
      notes: '',
      tableList: {
        table: {
          widths: [100, 60, 60, 70, 30, 40, 60, 60],
          body: [tableHeader, ...itemList],
        },
        style: 'tableSkuDetails',
        layout: {
          hLineColor: '#cdcaca',
          vLineColor: '#cdcaca',
        },
      },
    }

    const docDefinition: TDocumentDefinitions = {
      pageSize: 'A4',
      pageOrientation: 'portrait',
      pageMargins: [20, 70, 20, 50],
      header: (currentPage) => {
        if (currentPage === 1) {
          return {
            table: {
              headerRows: 1,
              widths: ['50%', '50%'],
              body: [
                [
                  {
                    image: logoAstro,
                    fit: [100, 100],
                    alignment: 'left',
                    margin: 10,
                  },
                  {
                    stack: [
                      {
                        image: logoReplenish,
                        fit: [200, 200],
                        alignment: 'right',
                        margin: [0, 10, 5, 0],
                      },
                      {
                        text: 'Slip',
                        alignment: 'right',
                        margin: [0, 5, 10, 10],
                      },
                    ],
                  },
                ],
              ],
            },
            layout: {
              hLineColor: '#cdcaca',
              vLineColor: 'white',
            },
          }
        }

        return {
          table: {
            headerRows: 1,
            widths: ['50%', '50%'],
            body: [
              [
                {
                  text: 'REPLENISHMENT TASK SLIP',
                  bold: true,
                  fontSize: 14,
                  alignment: 'left',
                  margin: 10,
                },
                {
                  text: [
                    {
                      text: 'No. Slip Task : ',
                      fontSize: 12,
                      bold: true,
                      color: '#6D7588',
                    },
                    {
                      text: replenishData.slipTaskNo,
                      fontSize: 12,
                      color: '#6D7588',
                    },
                  ],
                  margin: 10,
                  alignment: 'right',
                },
              ],
            ],
          },
          layout: {
            hLineColor: 'white',
            vLineColor: 'white',
          },
        }
      },
      footer: (currentPage, pageCount, pageSize) => {
        const pageTxt = `Hal ${currentPage.toString()} dari ${pageCount}`
        return [
          { text: pageTxt, alignment: 'center', marginTop: 12 },
          { canvas: [{ type: 'rect', x: 170, y: 32, w: pageSize.width - 170, h: 100 }] },
        ]
      },
      content: [
        {
          columns: [
            {
              width: '50%',
              columns: [
                [
                  {
                    columns: [
                      {
                        width: '30%',
                        text: 'No. Slip Task',
                        style: 'label',
                      },
                      {
                        width: '70%',
                        text: `: ${replenishData.slipTaskNo}`,
                        style: 'marginYSmall',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        width: '30%',
                        text: 'Inventory Controller',
                        style: 'label',
                      },
                      {
                        width: '70%',
                        text: `: ${replenishData.operator}`,
                        style: 'marginYSmall',
                      },
                    ],
                  },
                ],
              ],
            },
            {
              width: '50%',
              columns: [
                [
                  {
                    columns: [
                      {
                        width: '40%',
                        text: 'Tgl. Slip',
                        style: 'label',
                      },
                      {
                        width: '60%',
                        text: `: ${replenishData.slipDate}`,
                        style: 'marginYSmall',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        width: '40%',
                        text: 'Warehouse',
                        style: 'label',
                      },
                      {
                        width: '60%',
                        text: `: ${replenishData.locationName}`,
                        style: 'marginYSmall',
                      },
                    ],
                  },
                ],
              ],
            },
          ],
        },
        { ...replenishData.tableList },
        {
          columns: [
            {
              width: '50%',
              style: 'tableSignature',
              table: {
                widths: [250],
                heights: [120],
                headerRows: 1,
                body: [
                  [
                    {
                      text: '\n Memo / Notes:',
                      style: 'memo',
                      alignment: 'left',
                      marginLeft: 10,
                    },
                  ],
                ],
              },
              layout: {
                hLineColor: '#cdcaca',
                vLineColor: '#cdcaca',
              },
            },
            {
              width: '50%',
              style: 'tableSignature',
              table: {
                widths: [125, 125],
                heights: ['auto', 90],
                headerRows: 2,
                body: [
                  [
                    {
                      text: 'TTD PIC',
                      style: 'tableHeader',
                      colSpan: 2,
                      alignment: 'left',
                      marginLeft: 10,
                    },
                    {},
                  ],
                  [
                    {
                      text: [
                        {
                          text: `\n Operator Forklift,\n\n\n\n`,
                          alignment: 'center',
                          color: '#6D7588',
                          fontSize: 11,
                        },
                        {
                          text: `${replenishData.operator} \n\n`,
                          bold: true,
                          fontSize: 11,
                          alignment: 'center',
                        },
                      ],
                    },
                    {
                      text: [
                        {
                          text: '\n Diperiksa Oleh \n\n\n\n',
                          alignment: 'center',
                          color: '#6D7588',
                          fontSize: 11,
                        },
                        {
                          text: 'Nama: \n \n',
                          color: '#6D7588',
                          fontSize: 10,
                        },
                        {
                          text: `${replenishData.locationName} \n\n`,
                          alignment: 'center',
                          fontSize: 12,
                          bold: true,
                        },
                      ],
                    },
                  ],
                ],
              },
              layout: {
                hLineColor: '#cdcaca',
                vLineColor: '#cdcaca',
              },
            },
          ],
        },
      ],
      styles: {
        label: {
          bold: true,
          margin: [0, 5],
        },
        marginYSmall: {
          margin: [0, 5],
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black',
          fillColor: '#F3F4F5',
          margin: [0, 7, 0, 10],
        },
        memo: {
          fontSize: 10,
          color: '#6D7588',
        },
        tableSkuDetails: {
          color: '#212121',
          margin: [0, 20, 0, 0],
          fontSize: 10,
        },
        tableSignature: {
          margin: [0, 20, 0, 0],
        },
      },
    }

    pdfMake
      .createPdf(docDefinition)
      .print({}, (window as unknown as PdfPrintFrameType).frames.printPdf)
  }

export const fetchGetPrintReplenishmentTask = createAsyncThunk(
  `${SLICE_NAME}/fetchGetPrintReplenishmentTask`,
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await getPrintReplenishmentTaskDetailAPI({ id })

      dispatch(actPrintReplenishmentTask(response.data.data))
      toastSuccess('Replenishment Task Slip sedang dicetak', { autoClose: false })
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)
