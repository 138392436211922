import { Get, Post, Put } from 'config/apiServiceConfig'
import { MasterVariant as Variant } from 'features/Product/@types'

export const getMasterVariant = (params: Partial<Variant.QueryType>) =>
  Get<Variant.ResponseGetMasterVariantType>({
    url: '/api/ims/:version/master-variant',
    version: 'v1',
    params,
  })

export const getMasterVariantDetail = (id: number) =>
  Get<Variant.ResponseGetMasterVariantDetailType>({
    url: '/api/ims/:version/master-variant/:id',
    version: 'v1',
    urlParams: { id },
  })

export const postMasterVariant = (data: Variant.InitialStateType['form']) =>
  Post<Variant.ResponseWithMessageOnlyType>({
    url: '/api/ims/:version/master-variant',
    version: 'v1',
    data,
  })

export const putMasterVariant = (id: number, data: Variant.InitialStateType['form']) =>
  Put<Variant.ResponseWithMessageOnlyType>({
    url: '/api/ims/:version/master-variant/:id',
    version: 'v1',
    urlParams: { id },
    data,
  })
