import {
  SET_IS_LOADING_SO,
  SET_PRODUCTS,
  SET_SELECTED_PRODUCTS,
  SET_ORIGIN_HUBS,
  SET_DESTINATION_HUBS,
  SET_SELECTED_ORIGIN_HUB,
  SET_SELECTED_DESTINATION_HUB,
  SET_IS_CHECK_ALL,
  SET_DATA_PAGINATION_ADD,
  SET_SUPPLY_ORDERS,
  SET_DATA_PAGINATION_SO,
  SET_SELECTED_SO,
  SET_IS_POPUP_CHECK_QTY_SHOW,
  SET_LAST_QUERY,
  SET_PURCHASE_ORDER,
  SET_LAST_PARAMS,
  SET_VENDORS,
  SET_SELECTED_PURCHASE_ORDER,
  SET_LINK_TEMPLATE_CSV_PO,
  SET_TYPE,
  SET_IS_NEED_TO_RELOAD,
  SET_RAK_OPTIONS_PURCHASE_ORDER_LITE,
  SET_LIST_LOCATION_RAW,
  SET_REMARKS_SUPPLY_ORDER,
  SET_CHECKER,
  SET_FEATURE_FLAG,
  SET_PO_REFERENCE_DIALOG_STATE,
  SET_SELECTED_PO_REFERENCE_DATA,
  SET_CREATE_PO_REFERENCE_LOADING,
  SET_REASONS_SUPPLY_ORDER,
  TOGGLE_MODAL_CONFIRMATION_SUPPLY_ORDER,
  RESET_MODAL_CONFIRMATION_SUPPLY_ORDER,
  SET_ROUTE_MASTER_LIST,
  SET_CHECKED_SO_LIST,
} from 'storeContext/actionsType/supplyOrder'

export const initialStateSupplyOrder = {
  isLoading: false,
  products: [],
  selectedProducts: [],
  originHubs: [],
  destinationHubs: [],
  selectedOriginHub: {},
  selectedDestinationHub: {},
  isCheckAll: false,
  paginationDataAdd: {},
  supplyOrders: [],
  paginationDataSo: {},
  isPopupCheckQtyShow: false,
  selectedSo: {},
  lastQuery: '',
  purchaseOrders: [],
  lastParams: {},
  vendors: [],
  selectedPurchaseOrder: {},
  linkTemplateCsvPo: '',
  types: [],
  needToReload: 1,
  dataRakOptionsPOLite: [],
  listLocationRaw: [],
  listRemarks: [],
  listChecker: [],
  featureFlag: {},
  purchaseOrderDetail: {},
  poReference: {
    isLoading: false,
    selectedData: {},
    dialogState: 'CLOSED',
  },
  modalCancelConfirmation: {
    isModalConfirmCancelOpen: false,
    poNumberToCancel: '',
    locationId: 0,
    listReasons: [],
  },
  routeMasterList: [],
  checkedSOList: [],
}

export default function supplyOrder(state = initialStateSupplyOrder, { type, payload }) {
  switch (type) {
    case SET_IS_LOADING_SO:
      return {
        ...state,
        isLoading: payload,
      }
    case SET_PRODUCTS:
      return {
        ...state,
        products: payload,
      }
    case SET_SELECTED_PRODUCTS:
      return {
        ...state,
        selectedProducts: payload,
      }
    case SET_ORIGIN_HUBS:
      return {
        ...state,
        originHubs: payload,
      }
    case SET_DESTINATION_HUBS:
      return {
        ...state,
        destinationHubs: payload,
      }
    case SET_SELECTED_ORIGIN_HUB:
      return {
        ...state,
        selectedOriginHub: payload,
      }
    case SET_SELECTED_DESTINATION_HUB:
      return {
        ...state,
        selectedDestinationHub: payload,
      }
    case SET_IS_CHECK_ALL:
      return {
        ...state,
        isCheckAll: payload,
      }
    case SET_DATA_PAGINATION_ADD:
      return {
        ...state,
        paginationDataAdd: payload,
      }
    case SET_DATA_PAGINATION_SO:
      return {
        ...state,
        paginationDataSo: payload,
      }
    case SET_SUPPLY_ORDERS:
      return {
        ...state,
        supplyOrders: payload,
      }
    case SET_SELECTED_SO:
      return {
        ...state,
        selectedSo: payload,
      }
    case SET_IS_POPUP_CHECK_QTY_SHOW:
      return {
        ...state,
        isPopupCheckQtyShow: payload,
      }
    case SET_LAST_QUERY:
      return {
        ...state,
        lastQuery: payload,
      }
    case SET_LAST_PARAMS:
      return {
        ...state,
        lastParams: payload,
      }
    case SET_PURCHASE_ORDER:
      return {
        ...state,
        purchaseOrders: payload,
      }
    case SET_VENDORS:
      return {
        ...state,
        vendors: payload,
      }
    case SET_SELECTED_PURCHASE_ORDER:
      return {
        ...state,
        selectedPurchaseOrder: payload,
      }
    case SET_LINK_TEMPLATE_CSV_PO:
      return {
        ...state,
        linkTemplateCsvPo: payload,
      }
    case SET_TYPE:
      return {
        ...state,
        types: payload,
      }
    case SET_IS_NEED_TO_RELOAD:
      return {
        ...state,
        needToReload: state.needToReload + 1,
      }
    case SET_RAK_OPTIONS_PURCHASE_ORDER_LITE:
      return {
        ...state,
        dataRakOptionsPOLite: payload,
      }
    case SET_LIST_LOCATION_RAW:
      return {
        ...state,
        listLocationRaw: payload,
      }
    case SET_REMARKS_SUPPLY_ORDER:
      return {
        ...state,
        listRemarks: payload,
      }
    case SET_CHECKER:
      return {
        ...state,
        listChecker: payload,
      }
    case SET_FEATURE_FLAG:
      return {
        ...state,
        featureFlag: {
          ...state.featureFlag,
          ...payload,
        },
      }
    case SET_PO_REFERENCE_DIALOG_STATE:
      return {
        ...state,
        poReference: {
          ...state.poReference,
          dialogState: payload,
        },
      }
    case SET_SELECTED_PO_REFERENCE_DATA:
      return {
        ...state,
        poReference: {
          ...state.poReference,
          selectedData: payload,
        },
      }
    case SET_CREATE_PO_REFERENCE_LOADING:
      return {
        ...state,
        poReference: {
          ...state.poReference,
          isLoading: payload,
        },
      }
    case TOGGLE_MODAL_CONFIRMATION_SUPPLY_ORDER: {
      return {
        ...state,
        modalCancelConfirmation: {
          ...state.modalCancelConfirmation,
          isModalConfirmCancelOpen: payload.isModalConfirmCancelOpen,
          supplyOrderId: payload.supplyOrderId,
          poNumberToCancel: payload.poNumberToCancel,
          locationId: payload.locationId,
        },
      }
    }
    case SET_REASONS_SUPPLY_ORDER: {
      return {
        ...state,
        modalCancelConfirmation: {
          ...state.modalCancelConfirmation,
          listReasons: payload,
        },
      }
    }
    case RESET_MODAL_CONFIRMATION_SUPPLY_ORDER: {
      return {
        ...state,
        modalCancelConfirmation: initialStateSupplyOrder.modalCancelConfirmation,
      }
    }
    case SET_ROUTE_MASTER_LIST:
      return {
        ...state,
        routeMasterList: [...payload],
      }
    case SET_CHECKED_SO_LIST: {
      return {
        ...state,
        checkedSOList: [...payload],
      }
    }
    default:
      return state
  }
}
