export const SET_IS_LOADING = 'SET_IS_LOADING/inbound-page'
export const SET_QUERY = 'SET_QUERY/inbound-page'
export const RESET_QUERY = 'RESET_QUERY/inbound-page'
export const SET_RESET = 'SET_RESET/inbound-page'
export const SET_IS_OPEN_POPUP_CHECKER = 'SET_IS_OPEN_POPUP_CHECKER/inbound-page'
export const SET_INBOUNDS = 'SET_INBOUNDS/inbound-page'
export const SET_PAGINATION_DATA = 'SET_PAGINATION_DATA/inbound-page'
export const SET_SELECTED_INBOUND = 'SET_SELECTED_INBOUND/inbound-page'
export const SET_LAST_QUERY = 'SET_LAST_QUERY/inbound-page'
export const SET_HUBS = 'SET_HUBS/inbound-page'
export const SET_SELECTED_HUB = 'SET_SELECTED_HUB/inbound-page'
export const SET_LAST_PARAMS = 'SET_LAST_PARAMS/inbound-page'
export const SET_DATA_PRINT = 'SET_DATA_PRINT/inbound-page'
export const SET_DATA_SLOC_OPTIONS = 'SET_DATA_SLOC_OPTIONS/inbound-page'
export const SET_CHECKER = 'SET_CHECKER/inbound-page'
export const SET_SELECTED_CHECKER = 'SET_SELECTED_CHECKER/inbound-page'
export const SET_SELECTED_INVENTORY_CONTROLLER = 'SET_SELECTED_INVENTORY_CONTROLLER/inbound-page'
export const SET_MODAL_CONVERSION_RULE = 'SET_MODAL_CONVERSION_RULE/inbound-page'
export const RESET_MODAL_CONVERSION_RULE = 'RESET_MODAL_CONVERSION_RULE/inbound-page'
export const SET_MODAL_CONFIRM_START_UNLOADING = 'SET_MODAL_CONFIRM_START_UNLOADING/inbound-page'
export const RESET_MODAL_CONFIRM_START_UNLOADING =
  'RESET_MODAL_CONFIRM_START_UNLOADING/inbound-page'
