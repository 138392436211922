/* eslint-disable no-undef */
import axios from 'axios'
import { Get } from 'config/apiServiceConfig'
import AxiosInstance from 'config/apiServiceApiGateway'

const { REACT_APP_BASE_URL_API } = process.env
const baseUrl = REACT_APP_BASE_URL_API

type GenericStaffResponseType = {
  error: { status: boolean; message: string; code: number }
  message: string
}

export type GetCentralWarehouseStaffRequestType =
  | Partial<{
      pageIndex: number
      pageSize: number
      locationType: string
      name: string
      locationId: number
      role: string
    }>
  | undefined

export type GetCentralWarehouseStaffResponseType = {
  data: {
    driver_id: number
    driver_full_name: string
    driver_phone_number: string
    driver_plate_number: string
    driver_location_id: number
    driver_location: string
    driver_location_type?: string
    driver_card_number: string
    driver_status: string
    driver_active: boolean
    driver_verified: boolean
    role: {
      active: boolean
      authority_role: string
      role_name_fmt: string
    }[]
    tracker_id: string
    employment_type: string
    vehicle: {
      type: string
      sub_type: string
    } | null
    vendor: number
    bank_name: string
    account_name: string
    account_number: string
    join_date: string
    termination_date: string
    employee_detail: string
    department: string
    isBlacklist: boolean
    terminationReason: string
    terminationStatus: string
    terminationStatusCode: number
    approvalId: number
    flexible_checkout: boolean
    job_id: string
  }[]
  pagination: {
    pageIndex: number
    totalElement: number
  }
}

export type GetLocationsRequestType =
  | Partial<{
      name: string
      type: string
    }>
  | undefined

export type GetLocationsResponseType = {
  content: {
    location_id: number
    location_name: string
    location_type: string
  }[]
}

export type GetDriverRolesResponseType = {
  data: string[]
}

export type GetDriverRolesRequestType = {
  locationType: string
}

export type GetVehicleResponseType = {
  data: {
    vehicle_type: {
      key: string
      value: string
    }[]
    vehicle_sub_type: {
      key: string
      value: string
    }[]
  }
}

export type GetEmploymentTypesResponseType = {
  data: string[]
}

export type GetExportDriverResponseType = {
  data: {
    file_url: string
  }
}

export type PutDriverStatusRequestType = {
  id: number
  payload: {
    is_available: boolean
  }
}

export type PutDriverStatusResponseType = {
  data: GetCentralWarehouseStaffResponseType['data'][number]
}

export type PutDriverActiveRequestType = {
  id: number
  payload: {
    is_active: boolean
    termination_date?: string
  }
}

export type PutDriverActiveResponseType = {
  data: GetCentralWarehouseStaffResponseType['data'][number]
}

export type DriverRequestPayloadType = {
  staff_location_id: number
  staff_card_number: string
  staff_plate_number: string
  staff_phone_number: string
  role: string[]
  staff_full_name: string
  employment_type: string
  location_type: string
  vehicle_type: string
  vehicle_sub_type: string | null
  tracker_id: string
  staff_vendor_id?: number | null
  bank_name: string
  account_name: string
  account_number: string
  join_date: string
  termination_date: string
  employee_detail: string
  department: string
  termination_reason: string
  is_blacklist: boolean
  approval_ids: number[]
  is_flexible_checkout: boolean
  user_name: string
  staff_id?: number
  job_id: string
}

export type DriverAdminType = {
  driver_card_number: string
  driver_full_name: string
  driver_location_id: number
  driver_phone_number: string
  driver_plate_number: string
  flexible_checkout: boolean
  driver_id: number
}

export type PostDriverRequestType = DriverRequestPayloadType

export type PostDriverResponseType = GenericStaffResponseType

export type PutDriverRequestType = {
  id: number
  payload: DriverRequestPayloadType
}

export type PutDriverResponseType = GenericStaffResponseType

export type PutDriverAndInactiveRequestType = {
  driverParam: PutDriverRequestType
  statusChangeParam: PutDriverActiveRequestType
}

export type GetVendorStaffResponseType = {
  data: {
    data: {
      data: {
        id: number | null
        name: string
        status: 'inactive' | 'active'
      }[]
    }
  }
}

export type DepartementNameListType = {
  names: string[]
}

export type GetAllDepartementListResponseType = {
  data: { [key: string]: DepartementNameListType }
}

export type GetAllDepartementProcessedType = { content: string[]; name: string }

export type GetTerminationReasonsResponseType = {
  data: {
    [key: string]: {
      reasons: string[]
    }
  }
}

export type PutResignationPayloadType = {
  approval_ids: number[]
  location_id: number
  requester_roles: string[]
  termination_date?: string
  termination_reason?: string
  join_date?: string
  updated_by: string
  is_flexible_checkout: boolean
}

export type FetchStaffRequestType = {
  location_ids?: string[]
  location_types?: string[]
  module?: {
    bank_account: boolean
    department_location: boolean
    staff_attributes: boolean
    staff_vendor: boolean
    termination_status: boolean
    vehicle: boolean
  }
  name?: string
  page_index?: number
  page_size?: number
  phone_number?: string
  roles?: string[]
  staff_ids?: string[]
  status_active?: string
}

export type FetchStaffResponseType = {
  data: {
    location_id: number
    join_date: string
    created_by: string
    location_name: string
    status: string
    role: string
    id: number
    bank_account_data: {
      id: number
      account_number: string
      account_name: string
      staff_id: number
      bank_name: string
    }
    is_verified: boolean
    is_blacklist: boolean
    staff_termination_status_data: {
      termination_status_code: number
      approval_id: number
      termination_status: string
    }
    staff_attributes_data: {
      staff_id: number
      flexible_checkout: boolean
      working_location_id: number
      job_id: string
    }
    user_id: number
    staff_location_department_data: {
      staff_id: number
      location_type: string
      department: string
    }
    full_name: string
    staff_vendor_data: {
      staff_id: number
      vendor_id: number
      vendor_name: string
    }
    vehicle_type: string
    termination_date: string
    employment_type: string
    staff_role: {
      authority_role: string
      role_name_fmt: string
      active: boolean
    }[]
    is_active: boolean
    updated_by: string
    staff_app_version: string
    plate_number: string
    employee_detail: number | string
    vehicle_sub_type: string
    card_number: string
    termination_reason: string
    phone_number: string
    location_type: string
    tracker_id: string
  }[]
  pagination: {
    page_index: number
    total_elements: number
  }
}

export const getCentralWarehouseStaff = (params: GetCentralWarehouseStaffRequestType) =>
  axios.get(`${baseUrl}/api/driver-admin`, { params })

export const getLocations = (params: GetLocationsRequestType) =>
  axios.get(`${baseUrl}/api/location`, { params })

export const getDriverRoles = (locationType: string) =>
  axios.get(`${baseUrl}/api/driver-admin/role`, {
    params: {
      locationType: locationType || 'WAREHOUSE',
    },
  })

export const getVehicle = () => axios.get(`${baseUrl}/api/driver-admin/vehicle-type`)

export const getEmploymentTypes = () =>
  AxiosInstance.Get<{ data: string[] }, true>({
    url: '/staff-management/internal/:version/staff/employment-types',
    version: 'v1',
  })

export const getExportDriver = () =>
  axios.get(`${baseUrl}/api/driver-admin/export`, {
    params: {
      locationType: 'WAREHOUSE',
    },
  })

export const putDriverStatus = ({ id, payload }: PutDriverStatusRequestType) =>
  axios.put(`${baseUrl}/api/driver-admin/${id}/available`, payload)

export const putDriverActive = ({ id, payload }: PutDriverActiveRequestType) =>
  axios.put(`${baseUrl}/api/driver-admin/${id}/active`, payload)

export const getListVendorStaff = () =>
  axios.get(`${baseUrl}/api/staff-management/staff-vendors/list`)

export const getDepartementList = () =>
  axios.get(`${baseUrl}/api/staff-management/admin/all-department-list`)

export const getTerminationReasons = () =>
  Get<GetTerminationReasonsResponseType>({ url: '/api/staff-management/admin/all-reason-list' })

export const putTermination = (id: number, data: PutResignationPayloadType) =>
  AxiosInstance.Put({ url: `/staff-management/internal/v1/staff/${id}/resignation`, data })

export const putRejoin = (id: number, data: PutResignationPayloadType) =>
  AxiosInstance.Put({ url: `/staff-management/internal/v1/staff/${id}/rejoin`, data })

export const postDriver = (data: PostDriverRequestType) =>
  AxiosInstance.Post<PostDriverResponseType, true>({
    url: `/staff-management/internal/v1/staff`,
    data,
  })

export const putDriver = ({ id, payload }: PutDriverRequestType) =>
  AxiosInstance.Put<PutDriverResponseType, true>({
    url: `/staff-management/internal/v1/staff/${id}`,
    data: payload,
  })

export const fetchStaffList = (data: FetchStaffRequestType) =>
  AxiosInstance.Post<FetchStaffResponseType, true>({
    url: '/staff-management/internal/:version/staff/get-with-pagination',
    version: 'v1',
    data,
  })
