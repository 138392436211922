import { createAsyncThunk } from '@reduxjs/toolkit'

import { callErrorMsg } from 'helpers/errorMsg'
import { toastNeutral } from 'utils/toast'
import { getKoliLostAPI, getKoliLostDetailAPI, putKoliFoundAPI } from 'utils/apiList/koliLost'
import type { GetKoliLostRequestType, PutKoliFoundRequestType } from 'utils/apiList/koliLost'

export const SLICE_NAME = 'koliLost'

export const fetchGetKoliLost = createAsyncThunk(
  `${SLICE_NAME}/fetchGetKoliLost`,
  async (params: GetKoliLostRequestType) => {
    try {
      const response = await getKoliLostAPI(params)

      return response.data
    } catch (error) {
      callErrorMsg(error)
      return Promise.reject(error)
    }
  },
)

export const fetchGetKoliLostDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchGetKoliLostDetail`,
  async (payload: number) => {
    try {
      const response = await getKoliLostDetailAPI(payload)

      return { data: response.data.data, soID: payload }
    } catch (error) {
      callErrorMsg(error)
      return Promise.reject(error)
    }
  },
)

export const putKoliFound = createAsyncThunk(
  `${SLICE_NAME}/putKoliFound`,
  async (payload: PutKoliFoundRequestType & { koliNumbers: string }) => {
    try {
      const { koliNumbers, ...restPayload } = payload
      const response = await putKoliFoundAPI(restPayload)
      toastNeutral(`No. koli ${koliNumbers} berhasil ditemukan`)
      return response.data
    } catch (error) {
      callErrorMsg(error)
      return Promise.reject(error)
    }
  },
)
