import { Table as MuiTable, TableProps as MuiTableProps } from '@mui/material'
import styled from 'styled-components'
import {
  Box,
  Icon,
  Image,
  Checkbox,
  CheckboxPropsType,
  TableSortLabel,
  TableSortLabelPropsType,
  TableContainer,
  TableContainerPropsType,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@astro-ui/components'
import { NoDataImage } from '@astro-ui/assets'
import Paper from '@mui/material/Paper'

export type TablePropsType = {
  useCheckboxHeader?: boolean
  columnLength?: number
  hasNoData?: boolean
  renderNoDataTextOnly?: boolean
  checkboxHeaderProps?: CheckboxPropsType
  checkboxWidth?: string
  tableContainerProps?: TableContainerPropsType
  isSticky?: boolean
  tableHeaders: {
    value: string
    name: string
    width?: string
    minWidth?: string
    maxWidth?: string
    sortBy?: TableSortLabelPropsType['direction']
    align?: 'left' | 'right' | 'center'
  }[]
  onClickBtnSort?: (headerCellId: string, sortBy: SortType) => void
  TableHeaderComponent?: React.ReactNode
  TableFooterComponent?: React.ReactNode
  children: React.ReactNode
  className?: string
}

export type SortType = Omit<TableSortLabelPropsType['direction'], 'undefined'>

const IconSortAsc = () => (
  <Box display="inline-block" pb="1" mr="1">
    <Icon icon="triangle-up" size="9" />
  </Box>
)

const IconSortDesc = () => (
  <Box display="inline-block" mr="1">
    <Icon icon="triangle-down" size="9" />
  </Box>
)

const Table = ({
  tableHeaders,
  columnLength,
  tableContainerProps,
  isSticky,
  useCheckboxHeader,
  hasNoData,
  renderNoDataTextOnly,
  checkboxHeaderProps,
  checkboxWidth,
  TableHeaderComponent,
  TableFooterComponent,
  children,
  className,
  onClickBtnSort,
}: TablePropsType) => {
  const handleClickBtnSort = (headerValue: string, sortBy: SortType) => {
    let nextSortBy = ''
    if (sortBy === 'asc') nextSortBy = 'desc'
    else if (sortBy === 'desc') nextSortBy = 'asc'
    if (onClickBtnSort) onClickBtnSort(headerValue, nextSortBy as SortType)
  }

  const renderIconSort = (sortBy: SortType) => {
    if (sortBy === 'asc') return IconSortAsc
    if (sortBy === 'desc') return IconSortDesc
    return undefined
  }

  const renderDefaultTableHeaders = () => (
    <TableHead>
      <TableRow>
        {useCheckboxHeader && (
          <TableCell backgroundColor="tableHead" color="grey" sx={{ width: checkboxWidth }}>
            <Checkbox
              {...checkboxHeaderProps}
              sx={{ color: '#aab4c8', ...checkboxHeaderProps?.sx }}
            />
          </TableCell>
        )}
        {tableHeaders.map(({ value, name, width, minWidth, sortBy, align }) => (
          <TableCell
            key={value}
            fontWeight="bold"
            backgroundColor="tableHead"
            color="grey"
            sx={{ width, minWidth }}
            align={align}
          >
            {sortBy && (
              <TableSortLabel
                active={!!sortBy}
                direction={sortBy}
                onClick={() => handleClickBtnSort(value, sortBy)}
                IconComponent={renderIconSort(sortBy)}
              />
            )}
            {name}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )

  const containerProps = {
    component: Paper,
    ...tableContainerProps,
  }

  const getColumnLength = () => {
    if (useCheckboxHeader) return tableHeaders.length + 1
    if (columnLength) return columnLength
    return tableHeaders.length
  }

  const renderNoData = () => (
    <TableRow>
      <TableCell align="center" colSpan={getColumnLength()}>
        {renderNoDataTextOnly ? (
          <Typography color="grey">Tidak ada data</Typography>
        ) : (
          <>
            <Image src={NoDataImage} width="auto" height="70%" objectFit="contain" />
            <Typography variant="h3">Tidak ada data</Typography>
          </>
        )}
      </TableCell>
    </TableRow>
  )

  return (
    <TableContainer {...containerProps} className={className}>
      <TableBase stickyHeader={isSticky}>
        {TableHeaderComponent || renderDefaultTableHeaders()}
        <TableBody>
          {!hasNoData && children}
          {hasNoData && renderNoData()}
        </TableBody>
        {TableFooterComponent}
      </TableBase>
    </TableContainer>
  )
}
export default Table

export type TableBasePropsType = MuiTableProps
export const TableBase = styled(MuiTable)<TableBasePropsType>``
