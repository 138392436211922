import { Get } from 'config/apiServiceConfig'

export type GetLocationTypeResponseType = {
  data: {
    id: number
    name: string
  }[]
}

export type GetAllLocationByTypeRequestType = {
  type: string
}

export type GetAllLocationByTypeResponseType = {
  content: {
    location_id: number
    location_name: string
    location_type: string
  }[]
}

export type GetRoleByLocationTypeRequestType = {
  type: string
}

export type GetRoleByLocationTypeResponseType = {
  role_id: number
  role_name: string
}[]

export const getLocationTypeAPI = () =>
  Get<GetLocationTypeResponseType>({
    url: '/api/location/type',
  })

export const getAllLocationByTypeAPI = ({ type }: GetAllLocationByTypeRequestType) =>
  Get<GetAllLocationByTypeResponseType>({
    url: `/api/location`,
    params: {
      type,
      pageSize: 1000,
    },
  })

export const getRoleByLocationTypeAPI = ({ type }: GetRoleByLocationTypeRequestType) =>
  Get<GetRoleByLocationTypeResponseType>({
    url: `/api/location/:type/role`,
    urlParams: {
      type,
    },
  })
