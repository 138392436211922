import { PinProductPaginationType } from 'features/CategoryAndPosisi/@types/PinProduct'
import apiServices from 'config/apiServiceApiGateway'
import { QueryType } from 'features/CategoryAndPosisi/@types/onboardingMedia'

const { Get, Put } = apiServices

export type GetLoginStoriesResponseType = {
  data: {
    login_stories: {
      subtitle: string
      background_image_url: string
      illustration_image_url: string
      title: string
      position: number
      active: true
      id: number
      type: string
    }[]
  }
  pagination: PinProductPaginationType
}

type GetLoginStoriesRequestType = {
  params: Partial<QueryType>
}

export const getLoginStories = ({ params }: GetLoginStoriesRequestType) =>
  Get<GetLoginStoriesResponseType, true>({
    url: '/accounts/internal/v1/login-stories',
    version: 'v1',
    params,
  })

export type PutLoginStoryRequestType = {
  id: string
  payload: {
    active: boolean
    background_image_url: string
    illustration_image_url: string
    position: number
    subtitle: string
    title: string
    type: string
  }
}

export const putLoginStory = ({ payload, id }: PutLoginStoryRequestType) =>
  Put({
    url: `/accounts/internal/v1/login-stories/${id}`,
    version: 'v1',
    data: payload,
  })
