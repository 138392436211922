import { createAsyncThunk } from '@reduxjs/toolkit'
import { getModifiers } from 'features/Product/services/modifier'
import { callErrorMsg } from 'helpers/errorMsg'
import { getCleanQueryObj } from 'utils/queryParamsURL'
import { Modifier } from 'features/Product/@types'
import { SLICE_NAME, setIsLoading, setModifierData } from './slice'

export const fetchModifiers = createAsyncThunk(
  `${SLICE_NAME}/fetchModifiers`,
  async (_, { getState, dispatch }) => {
    const {
      modifier: { query },
    } = getState() as StoreStateType

    dispatch(setIsLoading(true))

    try {
      const { data } = await getModifiers(getCleanQueryObj(query) as Partial<Modifier.QueryType>)
      dispatch(setModifierData(data))
    } catch (err) {
      callErrorMsg(err)
    } finally {
      dispatch(setIsLoading(false))
    }
  },
)
