import {
  SET_IS_START_MASS_UPLOAD,
  SET_IS_FAILED_MASS_UPLOAD,
} from 'storeContext/actionsType/massUpload'

export const initialStateMassUpload = {
  isLoading: false,
  needToReload: 1,
}

export default function massUpload(state = initialStateMassUpload, { type, payload }) {
  switch (type) {
    case SET_IS_START_MASS_UPLOAD:
      return {
        ...state,
        isLoading: true,
      }
    case SET_IS_FAILED_MASS_UPLOAD:
      return {
        ...state,
        isLoading: false,
      }
    default:
      return {
        ...state,
      }
  }
}
