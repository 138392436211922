import { Delete, Get } from 'config/apiServiceConfig'

export type DeleteCatalogueRequestType = {
  id: number
}

export type GetHubListResponseType = {
  data: {
    location_id: number
    location_name: string
    product_id: number
    product_image: string
    product_name: string
    product_sku_number: string
    product_price: string
    product_total_stock: number
    product_stock: number
    product_discount_stock: number
    product_reversed_stock: number
    rack_name: null
    rack_position: number
    inventory_active: boolean
    cloud_kitchen: boolean
    bom_category: string
    is_virtual_bom_component: boolean
    location_type: string
  }[]
}

export const deleteCatalogue = ({ id }: DeleteCatalogueRequestType) =>
  Delete({ url: `/api/catalogue/${id}` })

export const getHubList = () =>
  Get<GetHubListResponseType>({
    url: `/api/locations/order`,
  })
