import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const POAssetCheckQuantityRBACPage = {
  pageID: '294b5d41ed52c53d5a8ccaf92ac8aea6',
  elementID: {
    AcceptOrRejectButton: 'd77a6839e1333d4cf054999a3a5b1cb1',
    SaveButton: '7f436c15dc508647b70140bd41b64ecf',
  },
} as const

export const usePOAssetCheckQuantityRBACPage = createUseRBAC(POAssetCheckQuantityRBACPage.elementID)

export type UsePOAssetCheckQuantityRBACPagePageType = ReturnType<
  typeof usePOAssetCheckQuantityRBACPage
>
