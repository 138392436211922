import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  GetXdockListResponseType,
  GetXdockListParamsType,
  GetStaffSorterByLocationResponseType,
} from 'features/SupplyOrder/services/xdock'

type XdockInitialStateType = {
  isLoading: boolean
  xdockList: GetXdockListResponseType['data']
  query: GetXdockListParamsType
  filter: {
    poNumber: string
    date: Date | null
  }
  modalAssignCrew: {
    type: string
    isOpen: boolean
    xdockId: number
    originalStaffList: GetStaffSorterByLocationResponseType[]
    staffList: GetStaffSorterByLocationResponseType[]
    selectedStaffId: number
  }
  totalData: number
}

const initialState: XdockInitialStateType = {
  isLoading: false,
  xdockList: [],
  filter: {
    poNumber: '',
    date: null,
  },
  query: {
    page_size: 20,
    page_index: 1,
    search_product: '',
    status: '',
  },
  modalAssignCrew: {
    isOpen: false,
    type: 'assign',
    xdockId: 0,
    originalStaffList: [],
    staffList: [],
    selectedStaffId: 0,
  },
  totalData: 0,
}

const SLICE_NAME = 'xdock'

const xdockSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setQuery: (state, action: PayloadAction<Partial<GetXdockListParamsType>>) => {
      state.query = {
        ...state.query,
        ...action.payload,
      }
    },
    setXdockList: (
      state,
      action: PayloadAction<{ listData: GetXdockListResponseType['data']; totalData: number }>,
    ) => {
      const { listData, totalData } = action.payload
      state.xdockList = listData
      state.totalData = totalData
    },
    setModalAssignCrew: (
      state,
      action: PayloadAction<Partial<XdockInitialStateType['modalAssignCrew']>>,
    ) => {
      state.modalAssignCrew = {
        ...state.modalAssignCrew,
        ...action.payload,
      }
    },
    setFilterXdockList: (
      state,
      action: PayloadAction<{
        key: keyof XdockInitialStateType['filter']
        value: string | Date | null
      }>,
    ) => {
      const { key, value } = action.payload
      state.filter[key] = value as never
    },
    resetModalAssignCrew: (state) => {
      state.modalAssignCrew = initialState.modalAssignCrew
    },
    resetXdockState: () => initialState,
  },
})

export const {
  setIsLoading,
  setQuery,
  setXdockList,
  setModalAssignCrew,
  setFilterXdockList,
  resetModalAssignCrew,
  resetXdockState,
} = xdockSlice.actions
export default xdockSlice.reducer
