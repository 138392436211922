import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { fetchCampaignTypeList } from './campaignThunk'

const SLICE_NAME = 'campaign'

export type CampaignTypeListType = {
  name: string
  label: string
  value: string
  uniqueKey: string
}

export type QueryType = {
  campaignType: { name: string; value: string }
}

export interface CampaignStateType {
  isLoading: boolean
  campaignTypeList: CampaignTypeListType[]
  query: QueryType
}

const initialState: CampaignStateType = {
  isLoading: false,
  campaignTypeList: [],
  query: { campaignType: { name: '', value: '' } },
}

const campaign = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setQuery: (
      state,
      action: PayloadAction<{
        name: keyof CampaignStateType['query']
        value: CampaignStateType['query']['campaignType']
      }>,
    ) => {
      const { name, value } = action.payload
      state.query[name] = value
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampaignTypeList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchCampaignTypeList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchCampaignTypeList.fulfilled, (state, actions) => {
        state.isLoading = false

        state.campaignTypeList = [
          {
            label: 'Semua',
            name: 'Semua',
            uniqueKey: window.crypto.randomUUID(),
            value: '',
          },
          ...actions.payload.content.map((el) => ({
            ...el,
            uniqueKey: window.crypto.randomUUID(),
            name: el.label,
          })),
        ]
      })
  },
})

export default campaign.reducer
export const { reset, setQuery } = campaign.actions
