import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getProducts,
  postModifier,
  CreateModifierPayloadType,
  getModifierDetail,
  getProductDetail,
  puttModifier,
} from 'features/Product/services/modifier'
import { toastSuccess } from 'utils/toast'
import { callErrorMsg } from 'helpers/errorMsg'
import { SLICE_NAME } from './slice'

export const fetchProducts = createAsyncThunk(
  `${SLICE_NAME}/fetchProducts`,
  async (params: { name?: string } = {}) => {
    try {
      const {
        data: { data = [] },
      } = await getProducts({ ...params, pageSize: 20, pageIndex: 1 })
      return data.map((d) => ({
        ...d,
        product_id: d.productID,
        product_name: d.productName,
        product_sku_number: d.productSkuNo,
      }))
    } catch (err) {
      return []
    }
  },
)

export const createModifier = createAsyncThunk(
  `${SLICE_NAME}/createModifier`,
  async (data: CreateModifierPayloadType) => {
    try {
      const {
        data: { message },
      } = await postModifier(data)

      toastSuccess(message)
      return true
    } catch (err) {
      callErrorMsg(err)
      return false
    }
  },
)

export const updateModifier = createAsyncThunk(
  `${SLICE_NAME}/updateModifier`,
  async ({ modifierId, data }: { modifierId: number; data: CreateModifierPayloadType }) => {
    try {
      const {
        data: { message },
      } = await puttModifier(modifierId, data)

      toastSuccess(message)
      return true
    } catch (err) {
      callErrorMsg(err)
      return false
    }
  },
)

export const fetchtModifierDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchtModifierDetail`,
  async (modifierId: number | string) => {
    try {
      const {
        data: { data },
      } = await getModifierDetail(modifierId)

      return data
    } catch (err) {
      callErrorMsg(err)
      return null
    }
  },
)

export const fetchProductDetail = createAsyncThunk(
  `${SLICE_NAME}/fetchProductDetail`,
  async (productrId: number) => {
    try {
      const { data } = await getProductDetail(productrId)

      return data
    } catch (err) {
      callErrorMsg(err)
      return null
    }
  },
)
