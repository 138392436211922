import dayjs from 'dayjs'
import {
  SET_IS_LOADING_LOCATION,
  SET_LOCATIONS,
  SET_DATA_PAGINATION,
  SET_SELECTED_LOCATION,
  SET_SELECTED_LOCATION_DOWNLOAD,
  SET_LAST_QUERY,
  SET_SUPERVISORS,
  SET_DATA_COVERAGE_AREA,
  SET_IS_POP_UP_DOWNLOAD,
  LOCATION_SET_LOCATION_DETAIL_LIST,
  LOCATION_SET_LOCATION_FLAG_LIST,
  SET_DATA_LOCATION_TYPE,
  SET_DATA_LOCATION_CLOSE_REASON,
  SET_DATA_AVAILABILITY_DRIVER,
} from 'storeContext/actionsType/location'

export const initialStateLocation = {
  isLoading: false,
  dataPagination: {},
  locations: [],
  selectedLocation: {},
  lastQuery: '',
  supervisors: [],
  dataCoverageArea: [],
  locationDetailList: [],
  locationFlagList: [],
  isPopUpDownload: false,
  locationType: [],
  locationCloseReason: [],
  selectedLocationDownload: {
    id: '',
    title: '',
    type: '',
    startDate: dayjs().format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
  },
  availabilityDriver: 0,
}

export default function location(state = initialStateLocation, { type, payload }) {
  switch (type) {
    case SET_IS_LOADING_LOCATION:
      return {
        ...state,
        isLoading: payload,
      }

    case SET_LOCATIONS:
      return {
        ...state,
        locations: payload,
      }
    case SET_DATA_PAGINATION:
      return {
        ...state,
        dataPagination: payload,
      }
    case SET_SELECTED_LOCATION:
      return {
        ...state,
        selectedLocation: payload,
      }
    case SET_LAST_QUERY:
      return {
        ...state,
        lastQuery: payload,
      }
    case SET_SUPERVISORS:
      return {
        ...state,
        supervisors: payload,
      }
    case SET_DATA_COVERAGE_AREA:
      return {
        ...state,
        dataCoverageArea: payload,
      }
    case LOCATION_SET_LOCATION_DETAIL_LIST:
      return {
        ...state,
        locationDetailList: payload,
      }
    case LOCATION_SET_LOCATION_FLAG_LIST:
      return {
        ...state,
        locationFlagList: payload,
      }
    case SET_SELECTED_LOCATION_DOWNLOAD:
      return {
        ...state,
        selectedLocationDownload: payload,
      }
    case SET_IS_POP_UP_DOWNLOAD:
      return {
        ...state,
        isPopUpDownload: payload,
      }
    case SET_DATA_LOCATION_TYPE:
      return {
        ...state,
        locationType: payload,
      }
    case SET_DATA_LOCATION_CLOSE_REASON:
      return {
        ...state,
        locationCloseReason: payload,
      }
    case SET_DATA_AVAILABILITY_DRIVER:
      return {
        ...state,
        availabilityDriver: payload,
      }
    default:
      return state
  }
}
