import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import { getTimeslotAvailable, postSubmitChangeTimeslot } from 'utils/apiList/changeTimeslot'
import { toastSuccess } from 'utils/toast'

const initialState = {
  isPopupTimeslotOpen: false,
  isPopupAuthOpen: false,
  selectedTimeslot: {},
  timeslotList: [],
  loadingTimeslot: false,
  orderData: {
    order_invoice: '',
    order_id: 0,
  },
}

const changeTimeslot = createSlice({
  name: 'changeTimeslot',
  initialState,
  reducers: {
    resetDataTimeslot: () => initialState,
    setIsPopupTimeslotOpen: (state, action) => {
      state.isPopupTimeslotOpen = action.payload
    },
    setLoading: (state, action) => {
      state.loadingTimeslot = action.payload
    },
    setTimeslotList: (state, action) => {
      state.timeslotList = action.payload
    },
    setSelectedTimeslot: (state, action) => {
      state.selectedTimeslot = action.payload
    },
    setIsPopupAuthOpen: (state, action) => {
      state.isPopupAuthOpen = action.payload
    },
    setOrderData: (state, action) => {
      state.orderData = action.payload
    },
  },
})

export const {
  setIsPopupTimeslotOpen,
  resetData,
  setTimeslotList,
  setLoading,
  setSelectedTimeslot,
  setIsPopupAuthOpen,
  setOrderData,
  resetDataTimeslot,
} = changeTimeslot.actions

export const fetchTimeslotListData = createAsyncThunk(
  'changeTimeslot/fetchTimeslotListData',
  async (orderData, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const { data } = await getTimeslotAvailable(orderData.order_id)
      dispatch(setTimeslotList(data.available_allotments))
      dispatch(setOrderData(orderData))
      dispatch(setIsPopupTimeslotOpen(true))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)
export const submitChangeTimeslot = createAsyncThunk(
  'changeTimeslot/submitChangeTimeslot',
  async (payload, { dispatch }) => {
    try {
      dispatch(setLoading(true))
      const { orderId, handleRefetch, ...restData } = payload
      const { data } = await postSubmitChangeTimeslot(orderId, restData)
      if (data) {
        dispatch(resetDataTimeslot())
        toastSuccess('Berhasil mengganti timeslot')
        handleRefetch()
      }
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export default changeTimeslot.reducer
