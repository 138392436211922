import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import { callErrorMsg } from 'helpers/errorMsg'
import { getFraudStatus_Fraud, patchFraudStatus_Fraud } from 'features/Fraud/services/fraud'
import { getRejectOrderReasonList } from 'features/Fraud/services/fraudStatus'
import { type PatchFraudStatusPayloadType } from 'features/Fraud/services/fraud'
import { toastSuccess } from 'utils/toast'
import { OrderFraudQueryType, OrderFraudType } from 'features/Fraud/@types/Fraud'
import { dateManipulation } from '@astronautsid/wpe-utils'

type FraudDetectionStateType = {
  isLoading: boolean
  isShow: boolean
  id?: number
  query: OrderFraudQueryType
  needToReload: number
  listFraudStatus: OrderFraudType[]
  rejectOrderReasonList: string[]
}

export const initialState: FraudDetectionStateType = {
  isLoading: false,
  isShow: false,
  id: undefined,
  query: {
    page_index: 0,
    page_size: 20,
    from_date: dateManipulation(new Date()).add(-1, 'month').unix() * 1000,
    to_date: new Date().getTime(),
    sort: 'id',
    direction: 'desc',
    status: 'In Review',
    search: '',
  },
  needToReload: 1,
  listFraudStatus: [],
  rejectOrderReasonList: [],
}

const SLICE_NAME = 'fraudDetectionSlice'

const fraudDetectionSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setFraudStatusId: (state, action: PayloadAction<number>) => {
      state.id = action.payload
    },
    setFraudStatusShow: (state, action: PayloadAction<boolean>) => {
      state.isShow = action.payload
    },
    setFraudQuery: (state, action: PayloadAction<OrderFraudQueryType>) => {
      state.query = { ...action.payload }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getFraudStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getFraudStatus.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(getFraudStatus.fulfilled, (state, action) => {
        const { payload } = action

        state.listFraudStatus = payload.data.data.order_frauds

        state.isLoading = false
      })
      .addCase(patchFraudStatus.pending, (state) => {
        state.isLoading = true
      })
      .addCase(patchFraudStatus.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(patchFraudStatus.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchRejectOrderReasonList.fulfilled, (state, action) => {
        state.rejectOrderReasonList = action.payload
      })
  },
})

export const { reset, setFraudStatusId, setFraudStatusShow, setFraudQuery } =
  fraudDetectionSlice.actions
export default fraudDetectionSlice.reducer

export const getFraudStatus = createAsyncThunk(
  `${SLICE_NAME}/getFraudStatus`,
  async (_, { rejectWithValue, getState }) => {
    const {
      fraudDetection: { query },
    } = getState() as StoreStateType

    try {
      const { data } = await getFraudStatus_Fraud(query)

      return {
        data,
        query,
      }
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const patchFraudStatus = createAsyncThunk(
  `${SLICE_NAME}/patchFraudStatus`,
  async (
    { id, data }: { id: number; data: PatchFraudStatusPayloadType },
    { rejectWithValue, dispatch },
  ) => {
    try {
      await patchFraudStatus_Fraud(id, data)

      toastSuccess('Status Fraud telah berhasil diubah')
      dispatch(getFraudStatus())

      return null
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)

export const fetchRejectOrderReasonList = createAsyncThunk(
  `${SLICE_NAME}/fetchRejectOrderReasonList`,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getRejectOrderReasonList()

      return [...data.data.reasons, 'Other']
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(error)
    }
  },
)
