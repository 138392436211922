import {
    SET_IS_START_INBOUND_WAREHOUSE,
    SET_IS_FAILED_INBOUND_WAREHOUSE,
    SET_IS_SUCCESS_INBOUND_WAREHOUSE,
    SET_IS_NEED_TO_RELOAD_INBOUND_WAREHOUSE,
    SET_PAGINATION_DATA_INBOUND_WAREHOUSE,
} from "storeContext/actionsType/inboundWarehouse"

export const initialStateInboundWarehouse = {
    isLoading: false,
    inboundWarehouseData: [],
    paginationData: {},
    needToReload: 1,
}

export default function inboundWarehouse( state = initialStateInboundWarehouse, { type, payload } ){
    switch(type){
        case SET_IS_START_INBOUND_WAREHOUSE:
            return {
                ...state,
                isLoading : true
            }
        case SET_IS_FAILED_INBOUND_WAREHOUSE:
            return{
                ...state,
                isLoading : false,
            }
        case SET_IS_SUCCESS_INBOUND_WAREHOUSE:
            return {
                ...state,
                isLoading : false,
                inboundWarehouseData : payload.content,
            }
        case SET_PAGINATION_DATA_INBOUND_WAREHOUSE:
            return {
                ...state,
                paginationData : payload,
            }
        case SET_IS_NEED_TO_RELOAD_INBOUND_WAREHOUSE:
            return {
                ...state,
                needToReload : state.needToReload + 1,
            }
        default : 
            return {...state};
    }
}