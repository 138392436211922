import { createAsyncThunk } from '@reduxjs/toolkit'

import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'
import { callErrorMsg } from 'helpers/errorMsg'

import {
  AssetPurchaseOrderDetailResponseInterface,
  UpdatePurchaseOrderParamsInterface,
} from 'features/AssetManagement/@types/typePurchaseOrder'

import {
  getPurchaseOrderDetailAPI,
  postUpdatePurchaseOrderAPI,
} from 'features/AssetManagement/services/purchaseOrder'
import { setPurchaseOrderDetail, setCheckQuantityItems } from './slice'

const SLICE_NAME = 'checkQuantityAsset'

export const getPurchaseOrderDetail = createAsyncThunk(
  `${SLICE_NAME}/getPurchaseOrderDetail`,
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const res = await getPurchaseOrderDetailAPI(Number(id))

      const resData: AssetPurchaseOrderDetailResponseInterface = res.data

      dispatch(setPurchaseOrderDetail(resData))

      return true
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const getPurchaseOrderDetailCheckQuantity = createAsyncThunk(
  `${SLICE_NAME}/getPurchaseOrderDetailCheckQuantity`,
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const res = await getPurchaseOrderDetailAPI(Number(id))

      const resData: AssetPurchaseOrderDetailResponseInterface = res.data

      const rowQuantityItem = resData.items?.map((item) => ({
        asset_sku: item.asset_sku,
        product_name: item.asset_name,
        product_qty: item.request_qty,
        uom: item.uom,
        product_type: item.asset_type,
        serial_numbers: [],
        real_qty: item.actual_qty,
        notes: '',
        asset_id: item.asset_id,
        product_id: item.id,
        price: item.price,
        saved: false,
      }))

      dispatch(setPurchaseOrderDetail(resData))
      dispatch(setCheckQuantityItems(rowQuantityItem))

      return true
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const getPurchaseOrderDetailAssetInventory = createAsyncThunk(
  `${SLICE_NAME}/getPurchaseOrderDetailAssetInventory`,
  async (id: number, { rejectWithValue }) => {
    try {
      const res = await getPurchaseOrderDetailAPI(Number(id))

      const resData: AssetPurchaseOrderDetailResponseInterface = res.data
      return resData.asset_inventories.map((inventory) => ({
        ...inventory,
        asset_name: resData.items.find((item) => item.asset_id === inventory.asset_id)?.asset_name,
      }))
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const postUpdateCheckQuantity = createAsyncThunk(
  `${SLICE_NAME}/postUpdateCheckQuantity`,
  async (param: UpdatePurchaseOrderParamsInterface, { rejectWithValue }) => {
    try {
      const res = await postUpdatePurchaseOrderAPI(param)

      return res.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)
