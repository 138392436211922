import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const AdminAccessUserRBAC = {
  pageID: '7b09b6533376538f03ec0b826d789866',
  elementID: {
    NonActivatedUser: 'd0b0701088c536988e3659d69f87e397',
    ViewUser: 'd3c1e526d2816eff1351872674a7ccd0',
    EditViewDetailUserButton: '801c5d79b1816efedbd757aa4b06eaab',
    AddUserButton: '4205c95b79289e7023520f9723ccc9a8',
    SaveEditUserButton: 'd6424721e724482a1be3d997945746f8',
    ChangeStatusUser: '5609ab84131b097d86edbc4bcf5fa4dc',
    ResetUser: '4ceb739bbc4393c6e12ae9e1055f6f36',
  },
} as const

export const useRBACAdminAccessUserPage = createUseRBAC(AdminAccessUserRBAC.elementID)

export type UseRBACAdminAccessUserPageType = ReturnType<typeof useRBACAdminAccessUserPage>
