import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  GetUVBenefitResponseType,
  GetUVMechanismTypeResponseType,
  GetUVTargetTypeResponseType,
} from 'utils/apiList/uniqueVoucher'
import {
  fetchUVDetailById,
  fetchUVBenefitType,
  fetchUVTargetType,
  fetchUVMechanismType,
  createUVPromo,
  updateUVPromo,
} from './uniqueVoucherPromoAddAndEditThunk'

export interface DataUniqueVoucherDetailByIdStateType {
  active: boolean
  amount: number
  benefitQty: number
  benefitMaxQty: number
  benefitType: string
  benefitTypeIcon: string
  mechanismType: string
  nominalType: string
  userTargetType: string
  is_quota_unlimited: boolean
  campaignName: string
  createdAt: string
  createdBy: string
  dateEnd: string
  dateEndDay: string
  dateEndHour: string
  dateStart: string
  dateStartDay: string
  dateStartHour: string
  discountPercentage: number
  id: number
  minimumPurchase: number
  prefix: string
  quantity: number
  quota: number
  status: string
  updatedAt: string
  updatedBy: string
}

export interface UVPromoAddAndEditStateType {
  isLoading: boolean
  dataUVDetailById: DataUniqueVoucherDetailByIdStateType
  optionsBenefitType: GetUVBenefitResponseType[]
  optionsTargetType: GetUVTargetTypeResponseType[]
  optionsMechanismType: GetUVMechanismTypeResponseType[]
}

const initialState: UVPromoAddAndEditStateType = {
  isLoading: false,
  dataUVDetailById: {
    active: false,
    amount: 0,
    benefitMaxQty: 0,
    benefitQty: 0,
    benefitType: '',
    benefitTypeIcon: '',
    nominalType: 'Rp',
    mechanismType: 'Input Code',
    userTargetType: '',
    is_quota_unlimited: false,
    campaignName: '',
    createdAt: '',
    createdBy: '',
    dateEnd: '',
    dateEndDay: '',
    dateEndHour: '',
    dateStart: '',
    dateStartDay: '',
    dateStartHour: '',
    discountPercentage: 0,
    id: 0,
    minimumPurchase: 0,
    prefix: '',
    quantity: 0,
    quota: 0,
    status: '',
    updatedAt: '',
    updatedBy: '',
  },
  optionsBenefitType: [],
  optionsMechanismType: [],
  optionsTargetType: [],
}

const UVPromoAddAndEditStateType = createSlice({
  name: 'UniqueVoucherPromoAddAndEdit',
  initialState,
  reducers: {
    reset: () => initialState,
    setUVDetailById: (
      state,
      action: PayloadAction<{
        fieldName: keyof UVPromoAddAndEditStateType['dataUVDetailById']
        value: string | number | boolean
      }>,
    ) => {
      const { fieldName, value } = action.payload

      state.dataUVDetailById[fieldName] = value as never
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUVDetailById.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchUVDetailById.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchUVDetailById.fulfilled, (state, action) => {
        const { data } = action.payload

        const benefitValue = () => {
          if (data.benefitType !== 'Free Ongkir' && data.discountPercentage) {
            return {
              benefitQty: data.discountPercentage,
              benefitMaxQty: data.amount,
            }
          }

          return {
            benefitQty: data.amount,
            benefitMaxQty: 0,
          }
        }

        state.isLoading = false
        const nominalType = data.discountPercentage > 0 ? '%' : 'Rp'

        state.dataUVDetailById = {
          dateEndDay: data.dateEnd.split(' ')[0],
          dateEndHour: data.dateEnd.split(' ')[1],
          dateStartDay: data.dateStart.split(' ')[0],
          dateStartHour: data.dateStart.split(' ')[1],
          nominalType,
          is_quota_unlimited: false,
          ...benefitValue(),
          benefitTypeIcon:
            state.optionsBenefitType.filter((el) => el.name === data.benefitType)[0]?.icon || '',
          ...data,
        }
      })
      .addCase(fetchUVBenefitType.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchUVBenefitType.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchUVBenefitType.fulfilled, (state, action) => {
        state.optionsBenefitType = action.payload
      })
      .addCase(fetchUVTargetType.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchUVTargetType.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchUVTargetType.fulfilled, (state, action) => {
        state.isLoading = false
        state.optionsTargetType = action.payload.filter(
          (el) => el.name === 'New User' || el.name === 'Global User',
        )
      })
      .addCase(fetchUVMechanismType.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchUVMechanismType.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchUVMechanismType.fulfilled, (state, action) => {
        state.isLoading = false
        state.optionsMechanismType = action.payload
      })
      .addCase(createUVPromo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createUVPromo.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(createUVPromo.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateUVPromo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateUVPromo.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(updateUVPromo.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export const { reset, setUVDetailById } = UVPromoAddAndEditStateType.actions

export default UVPromoAddAndEditStateType.reducer
