import { memo } from "react";
import styled, { css } from "styled-components";
import { NoData, RowActivityMonitoringReceivingDetail } from "components";

function TableActivityMonitoringReceivingDetail({ data, className }) {
  return (
    <>
      <Container className={className}>
        <thead>
          <Tr>
            <Th>No.</Th>
            <Th>Item ID</Th>
            <Th>Barcode</Th>
            <Th>Description</Th>
            <Th>Qty</Th>
            <Th>UOM</Th>
            <Th>To Package</Th>
            <Th>Mfg</Th>
            <Th>Exp Date</Th>
            <Th>Damage</Th>
            <Th>Remark</Th>
            <Th>Serial</Th>
            <Th>Resources</Th>
            <Th>Location</Th>
            <Th>Action</Th>
          </Tr>
        </thead>
        <tbody>
          {data.map((el) => (
            <RowActivityMonitoringReceivingDetail key={el} />
          ))}
        </tbody>
      </Container>
      {data.length === 0 && <NoData />}
    </>
  );
}

TableActivityMonitoringReceivingDetail.defaultProps = {
  data: []
};

export default memo(TableActivityMonitoringReceivingDetail);

const Container = styled.table`
  width: 100%;
  border-collapse: collapse;
  height: fit-content;
  border: 1px solid #d6dfeb;
  border-radius: 8px;
`;
const Tr = styled.tr`
  background-color: #f7f8f9;
`;
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.text};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  text-align: start;
  padding: 8px 20px;
  white-space: nowrap;
  ${({ center }) =>
    center &&
    css`
      text-align: center;
    `}
`;
